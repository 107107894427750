import apiUtil from "../utils/api.utils";
import config from "../utils/configs";
import moment from "moment";


export const getReports = async () => {
  try {
    const response = await apiUtil.client.get(`${config.reportService}/api/v1.0/reports`);
    return response.data;
  } catch (err) {
    return {error: true, message: err };
  }
}

export const downloadBirthdayReport = async (month, location) => {
  try {
    let url = `${config.reportService}/api/v1.0/generate/reports/birthdays/${month}?location=${location}`;
    const result = await apiUtil.client.get(url, { responseType: 'blob' })
    return result?.data;
  } catch (error) {
    alert(error);
  }
}

export const getBirthdayReport = async (month, location) => {
  try {
    const response =
      await apiUtil
        .client
        .get(`${config.residentService}/api/v1.0/residents/birthdays/${month}?location=${location}`);
    return response.data;
  } catch (err) {
    alert(err);
    return null;
  }
}


export const createReport = async (report) => {
  try {
    const response = await apiUtil.client.post(`${config.reportService}/api/v1.0/reports`, report);
    return response.data;
  } catch (err) {
    return {error: true, message: err};
  }
}

export const getResidentFacesheetReport = async (residentId) => {
  try {
    let url = `${config.reportService}/api/v1.0/generate/reports/residents/${residentId}/facesheet`;
    const result = await apiUtil.client.get(url, { responseType: 'blob' })
    return result?.data;
  } catch (error) {
    alert(error);
  }
}

export const getResidentMar = async (residentId, month, year) => {
  try {
    let url = `${config.reportService}/api/v1.0/generate/reports/residents/${residentId}/mar?month=${month}&year=${year}`;
    const result = await apiUtil.client.get(url, { responseType: 'blob' })
    return result?.data;
  } catch (error) {
    return { error: true, message: error }
  }
}


export const downloadCensusReport = async (tenantId, tenantLocationId, residentId, residentType, startDate, endDate) => {
  try {
    let url = `${config.reportService}/api/v1.0/generate/reports/census`
    url += `?tenantId=${tenantId}`;
    if (tenantLocationId && tenantLocationId !== -1) {
      url += `&tenantLocationId=${tenantLocationId}`;
    }
    if (residentId && residentId !== -1) {
      url += `&residentId=${residentId}`;
    }
    if (residentType) {
      url += `&residentType=${residentType}`;
    }
    url += `&startDate=${moment(startDate).toISOString()}`;
    url += `&endDate=${moment(endDate).toISOString()}`;
    const response = await apiUtil.client.get(url, { responseType: 'blob'});
    return response?.data;
  } catch (error) {
    return {error: true, message: error};
  }
}
