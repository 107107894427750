const model = {
  type: {
    title: "Interaction type",
    required: true,
    value: '',
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [
      { name: 'Select a type', value: '' },
      { name: 'Admission', value: 'Admission'},
      { name: 'Readmit', value: 'Readmit' },
      { name: 'Discharge', value: 'Discharge' },
      { name: 'LOA', value: 'LOA'}
    ],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
    isToggle: false
  },
  date: {
    title: "Date",
    required: true,
    value: Date.now(),
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    time: true,
  },
  transferTo: {
    hidden: true,
    title: "Transfer to",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
    isToggle: false,
  },
  startDate: {
    hidden: true,
    title: "Start Date",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    time: true,
  },
  endDate: {
    hidden: true,
    title: "End Date",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    time: true,
  },
  updateStatus: {
    title: "Update Resident Status",
    required: false,
    value: true,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
    checkbox: true
  },
  reason: {
    title: "Reason",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
    isToggle: false,
    multiline: true,
    multilineRows: 3
  },
  comment: {
    title: "Comment",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
    isToggle: false,
    multiline: true,
    multilineRows: 5
  },

}

export default model;
