import React from 'react';
import {Avatar, ListItem, ListItemAvatar, ListItemText, TextField, Typography} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {GlobalNotificationContext} from "../notification/globalnotificationprovider";
import {fuzzySearch} from "../../services/diagnosis.service";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  avatar: {
    background: theme.palette.primary.dark
  }
}))

const DiagnosisLookup = ({onSelected, defaultValue}) => {
  const classes = useStyles();
  const [codes, setCodes] = React.useState([]);
  const [term, setTerm] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const { addNotification } = React.useContext(GlobalNotificationContext);

  const onChange = (e, selectedCode) => {
    if (onSelected) {
      onSelected(selectedCode);
    }
  }

  const search = async (term) => {
    setLoading(true);
    const result = await fuzzySearch(term);
    if (result?.content) {
      setCodes(result.content);
    } else {
      addNotification('Unable to search', 'warning');
    }
    setLoading(false);
  }

  const onTermChange = async e => {
    setTerm(e.target.value);
    if (e.target.value.length > 2) {
      await search(e.target.value);
    } else if (e.target.value.length === 0) {
      setCodes([])
    }
  }

  return (
    <Autocomplete
      style={{ height: '3.5rem' }}
      labelId="autocomplete-label"
      title="Search"
      freeSolo
      id="combo-box-demo"
      options={codes}
      autoHighlight
      defaultValue={defaultValue}
      autoCorrect
      getOptionLabel={(option) => `${option.code} - ${option.shortDescription}`}
      onChange={onChange}
      renderOption={(option, { selected }) => (
        <ListItem>
          <ListItemAvatar>
            <Avatar color={"primary"}
                    variant={"circular"}
                    className={classes.avatar}
            >
              <Typography variant={"subtitle2"}
                          style={{fontSize: '.75rem'}}
              >
                {option.code}
              </Typography>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`${option.code} (${option.version})`}
            secondary={option.shortDescription}
          />
        </ListItem>
      )}
      loading={loading}
      renderInput={(params) => (
        <TextField {...params}
                   label="Search diagnosis"
                   variant="outlined"
                   value={term || ''}
                   onChange={onTermChange}
        />
      )}
    />
  )
}

export default DiagnosisLookup;
