import moment from "moment";

export const orderModelTemplate = {
    resident: {
        required: true,
        isValid: false,
        value: null,
        isDirty: false
    },
    orderDate: {
        required: true,
        isValid: true,
        value: moment(new Date()),
        isDirty: false
    },
    rxNumber: {
        required: true,
        isValid: false,
        value: null,
        isDirty: false
    },
    indication: {
        required: false,
        isValid: true,
        value: null,
        isDirty: false
    },
    direction: {
        required: false,
        isValid: true,
        value: null,
        isDirty: false
    },
    pillDescription: {
        required: false,
        isValid: true,
        value: null
    },
    alerts: {
        required: false,
        isValid: true,
        value: null,
        isDirty: false
    },
    allergies: {
        required: false,
        isValid: true,
        value: [],
        isDirty: false
    },
    orderQty: {
        required: false,
        isValid: true,
        value: null,
        isDirty: false
    },
    maxRefills: {
        required: false,
        isValid: true,
        value: null,
        isDirty: false
    },
    refillNotificationThreshold: {
        required: false,
        isValid: true,
        value: null,
        isDirty: false
    },
    maintainInventory: {
        required: false,
        isValid: true,
        value: false,
        isDirty: false
    },
    isPrn: {
        required: false,
        isValid: true,
        value: false,
        isDirty: false
    },
    chartVitals: {
        required: false,
        isValid: true,
        value: false,
        isDirty: false
    },
    chartVitalsList: {
      required: false,
      isValid: true,
      value: [],
      isDirty: false
    },
    marEnabled: {
        required: false,
        isValid: true,
        value: true
    },
    medication: {
        required: false,
        isValid: false,
        value: null,
        isDirty: false
    },
    physician: {
        required: false,
        isValid: false,
        value: null,
        isDirty: false
    },
    pharmacy: {
        required: false,
        isValid: false,
        value: null,
        isDirty: false
    },
    dosageInstructions: {
        required: false,
        isValid: true,
        value: [],
        isDirty: false
    },
}

export const validateForm = (form) => {
    if (form &&
        form.orderDate &&
        form.orderDate.value &&
        form.medication &&
        form.medication.value &&
        form.pharmacy &&
        form.pharmacy.value &&
        form.physician &&
        form.physician.value &&
        form.dosageInstructions &&
        form.dosageInstructions.value &&
        form.dosageInstructions.value.length > 0 &&
        form.resident &&
        form.rxNumber &&
        form.rxNumber.value) {
            return true;
        }

    return false;
}
