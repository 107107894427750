import React from 'react';
import {
  makeStyles,
  Avatar,
  Typography,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@material-ui/core';
import FaceIcon from '@material-ui/icons/Face';
import { Link } from 'react-router-dom';
import config from '../../../utils/configs';

const styles = makeStyles((theme) => ({
  root: {},
  avatar: {
    height: '80px',
    width: '80px',
    imageOrientation: 'from-image',
  },
  residentCard: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
    textDecoration: 'none',
  },
  defaultImg: {
    height: theme.spacing(5),
    width: theme.spacing(5),
  },
}));
const ResidentCard = ({ resident, size }) => {
  const [residentImage, setResidentImage] = React.useState();
  const classes = styles();

  React.useEffect(() => {
    if (resident && resident.location && resident.imageUrl) {
      buildImageUrlString(
        resident._id,
        resident.imageUrl,
        resident.location.tenant
      );
    }
  }, [resident]);

  const buildImageUrlString = (residentId, fileName, tenantId) => {
    const imageUrl = `${config.bucketObjectUrl}/${config.publicBucketName}/tenants/${tenantId}/residents/${residentId}/photo/${fileName}`;
    setResidentImage(imageUrl);
  };

  return (
    <ListItem divider
              component={Link}
              to={`/residents/${resident._id}`}>
      <ListItemAvatar>
        <Avatar
          alt={`${resident.firstName} ${resident.lastName}`}
          src={residentImage}>
          <FaceIcon className={classes.defaultImg}/>
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={`${resident.firstName} ${resident.lastName}`}
                    secondary={<>
                      <Typography variant={"subtitle2"}>{resident?.residentStatus?.name}</Typography>
                      <Typography variant={"subtitle2"}>Room: {resident?.roomNumber}</Typography>
                      </>
                    }
      />
    </ListItem>
  )
};

export default ResidentCard;
