import React from "react";
import {Chip, Grid, Typography} from "@material-ui/core";
import moment from 'moment';

export const CalendarAgendaEventComponent = ({event}) => {
  return (
    <div>
      <Grid container
            alignItems={"center"}
      >
        <Typography variant={"subtitle1"}>
          {event?.title}
        </Typography>
        <Chip style={{marginLeft: '.25rem', color: '#fff', backgroundColor: event?.color}}
              size={"small"}
              label={event?.calendarType} />
      </Grid>
      <Typography variant={"subtitle2"}>
        <div dangerouslySetInnerHTML={{__html: event?.noteHtml}} />
      </Typography>
    </div>
  )
}

export const CalendarEventComponent = ({event}) => {
  return (
    <div>
      <Typography variant={"subtitle1"}>{event?.title}</Typography>
      <Typography variant={"subtitle2"}>{moment(event.start).format('h:mm a')} - {moment(event.end).format('h:mm a')}</Typography>
    </div>
  )
}
