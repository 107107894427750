import React from "react";
import { getResidentInsurances } from "./resident.insurance.actions";
let ResidentInsurancesContext = React.createContext();
export const LOAD_RESIDENT_INSURANCES_START = "LOAD_RESIDENT_INSURANCES_START",
  LOAD_RESIDENT_INSURANCES_COMPLETE = "LOAD_RESIDENT_INSURANCES_COMPLETE",
  UPDATE_RESIDENT_INSURANCES_START = "UPDATE_RESIDENT_INSURANCES_START",
  UPDATE_RESIDENT_INSURANCES_COMPLETE = "UPDATE_RESIDENT_INSURANCES_COMPLETE",
  CREATE_RESIDENT_INSURANCE_START = "CREATE_RESIDENT_INSURANCE_START",
  CREATE_RESIDENT_INSURANCE_COMPLETE = "CREATE_RESIDENT_INSURANCE_COMPLETE",
  DELETE_RESIDENT_INSURANCE_START = "DELETE_RESIDENT_INSURANCE_START",
  DELETE_RESIDENT_INSURANCE_COMPLETE = "DELETE_RESIDENT_INSURANCE_COMPLETE",
  OPEN_INSURANCE_DIALOG = "OPEN_INSURANCE_DIALOG",
  CLOSE_INSURANCE_DIALOG = "CLOSE_INSURANCE_DIALOG",
  TOGGLE_ERROR = "TOGGLE_ERROR",
  TOGGLE_DELETE_CONFIRMATION = "TOGGLE_DELETE_CONFIRMATION"
;

const initialState = {
  error: false,
  errorMessage: null,
  loading: false,
  residentInsurances: [],
  openInsuranceDialog: false,
  showDeleteConfirmation: false
};
let reducer = (state, action) => {
  switch (action.type) {
    case LOAD_RESIDENT_INSURANCES_START:
      return { ...state, loading: true, error: false };
    case LOAD_RESIDENT_INSURANCES_COMPLETE:
      return {
        ...state,
        residentInsurances: action.payload,
        loading: false
      };
    case UPDATE_RESIDENT_INSURANCES_START:
      return { ...state };
    case UPDATE_RESIDENT_INSURANCES_COMPLETE:
      const residentInsurances =
        state.residentInsurances.map(pi => pi?._id === action.payload?._id ? action?.payload: pi);
      return { ...state, residentInsurances: residentInsurances };
    case OPEN_INSURANCE_DIALOG:
      return { ...state, openInsuranceDialog: true };
    case CLOSE_INSURANCE_DIALOG:
      return { ...state, openInsuranceDialog: false };
    case CREATE_RESIDENT_INSURANCE_START:
      return { ...state, loading: true };
    case CREATE_RESIDENT_INSURANCE_COMPLETE:
      return {
        ...state,
        loading: false,
        residentInsurances: [...state.residentInsurances, action.payload],
        openInsuranceDialog: false
      };
    case DELETE_RESIDENT_INSURANCE_START:
      return { ...state, loading: true };
    case DELETE_RESIDENT_INSURANCE_COMPLETE:
      const deletedInsurances = state.residentInsurances.filter(di => di?._id !== action?.payload);
      return {
        ...state,
        error: false,
        loading: false,
        residentInsurances: deletedInsurances
      };
    case TOGGLE_ERROR:
      return { ...state, loading: false, error: action?.payload?.error, errorMessage: action?.payload?.message };
    case TOGGLE_DELETE_CONFIRMATION:
      return { ...state, showDeleteConfirmation: action?.payload }
    default:
      return { ...state };
  }
};

const ResidentInsurancesProvider = ({ residentId, token, children }) => {
  let [state, dispatch] = React.useReducer(reducer, initialState);
  let value = { state, dispatch, residentId, token };

  React.useEffect(() => {
    if (residentId) {
      getResidentInsurances(dispatch, residentId);
    }
  }, [residentId]);

  return (
    <ResidentInsurancesContext.Provider value={value}>
      {children}
    </ResidentInsurancesContext.Provider>
  );
};

let ResidentInsurancesConsumer = ResidentInsurancesContext.Consumer;

export {
    ResidentInsurancesContext,
  ResidentInsurancesProvider,
  ResidentInsurancesConsumer
};
