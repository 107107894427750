import React from 'react';
import {
  Button, ButtonGroup, FormControlLabel, Grid,
  Input, makeStyles, MenuItem,
  Select, Switch,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField, Typography
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CreatePhone from "./create.phone";
import InputMask from "react-input-mask";
import PhoneTwoToneIcon from "@material-ui/icons/PhoneTwoTone";
import {getPhoneTypes} from "../../services/phone.service";
import {DeleteBtn} from "../utils/buttons/DeleteBtn";
import {PageTitle} from "../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 0)
  },
  phoneLogo: {
    margin: theme.spacing(0, 1),
    color: theme.palette.primary.main
  }
}));

const EntityPhoneList = ({ phones,
                           createPhone,
                           deletePhone,
                           updatePhone,
                           canEdit = true,
                           showPrimaryToggle = false,
                           hideNavigation = true
                         }) => {
  const [phoneTypes, setPhoneTypes] = React.useState([]);
  const [editPhoneEntity, setEditPhoneEntity] = React.useState(false);
  const [createPhoneModal, setCreatePhoneModal] = React.useState(false);

  const classes = useStyles();

  React.useEffect(() => {
    const getTypes = async () => {
      const typesResponse = await getPhoneTypes();
      setPhoneTypes(typesResponse);
    }
    getTypes();
  },[]);

  const onCreatePhone = e => {
    setCreatePhoneModal(false);
    createPhone(e);
  }

  const onEditPhone = phone => {
    setEditPhoneEntity(phone)
  }

  const onEditPhoneChange = e => {
    const obj = {...editPhoneEntity};
    obj.number = e.target.value;
    setEditPhoneEntity(obj);
  }

  const onEditPhoneTypeChange = e => {
    const obj = {...editPhoneEntity};
    obj.phoneType = e.target.value;
    setEditPhoneEntity(obj);
  }

  const onUpdatePhone = () => {
    updatePhone({...editPhoneEntity});
    setEditPhoneEntity(null);
  }

  const handleCheckChange = e => {
    const obj = {...editPhoneEntity};
    obj.isPrimary = e.target.checked;
    setEditPhoneEntity(obj);
  }


  return (
    <div className={classes.root}>
      {!hideNavigation &&
        <PageTitle title={
          <Grid container justify={"flex-start"} alignItems={"center"}>
            <PhoneTwoToneIcon className={classes.phoneLogo}/>
            <Typography variant={"subtitle1"}>Phones</Typography>
          </Grid>
        }/>
      }
      {hideNavigation &&
        <Grid container justify={"flex-start"} alignItems={"center"}>
          <PhoneTwoToneIcon className={classes.phoneLogo}/>
          <Typography variant={"subtitle1"}>Phones</Typography>
        </Grid>
      }
      {canEdit &&
        <Button
          onClick={() => setCreatePhoneModal(true)}
          startIcon={<AddIcon />}
        >
          Add
        </Button>}
      <CreatePhone
        onClose={() => setCreatePhoneModal(false)}
        onSave={onCreatePhone}
        open={createPhoneModal}
        toggleIsPrimary={true}
      ></CreatePhone>
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell width={150} />
            {showPrimaryToggle &&
              <TableCell width={200}>Primary</TableCell>
            }
            <TableCell width={200}>Number</TableCell>
            <TableCell width={200}>Type</TableCell>

          </TableRow>
        </TableHead>
        {phones?.map((phone, key) => (
          <TableRow>
            {editPhoneEntity?._id !== phone?._id && (
              <React.Fragment>
                <TableCell>
                  {canEdit &&
                    <ButtonGroup size={"small"}>
                      <Button
                        size="small"
                        variant={"text"}
                        onClick={() => onEditPhone(phone, key)}
                      >
                        Edit
                      </Button>
                      <DeleteBtn onClick={() => deletePhone(phone)} />
                    </ButtonGroup>
                  }
                </TableCell>
                {showPrimaryToggle &&
                  <TableCell>
                    {phone?.isPrimary ? 'Yes': null}
                  </TableCell>
                }
                <TableCell>
                  <InputMask
                    mask="(999) 999-9999"
                    value={phone.number}
                  >
                    {() => <Input disableUnderline />}
                  </InputMask>
                </TableCell>
                <TableCell>
                  {phone.phoneType}
                </TableCell>
              </React.Fragment>
            )}
            {editPhoneEntity?._id === phone?._id && (
              <React.Fragment>
                <TableCell>
                  <Button onClick={() => setEditPhoneEntity(null)}>
                    Cancel
                  </Button>
                  <Button
                    onClick={onUpdatePhone}
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                </TableCell>

                {showPrimaryToggle &&
                  <TableCell>
                    <FormControlLabel
                      className={classes.toggleContainer}
                      name={key}
                      control={
                        <Switch
                          name={"isPrimary"}
                          className={classes.toggle}
                          onChange={handleCheckChange}
                          color="primary"
                          checked={editPhoneEntity?.isPrimary}
                        />
                      }
                      labelPlacement="start"
                      label={"Is Primary"}
                    />
                  </TableCell>
                }

                <TableCell>
                  <InputMask
                    name="number"
                    mask="(999) 999-9999"
                    value={editPhoneEntity?.number}
                    onChange={onEditPhoneChange}
                  >
                    {() => <TextField fullWidth disableUnderline />}
                  </InputMask>
                </TableCell>
                <TableCell>
                  <Select
                    onChange={onEditPhoneTypeChange}
                    fullWidth
                    value={
                      editPhoneEntity?.phoneType || null
                    }
                  >
                    {phoneTypes &&
                    phoneTypes.map((type) => (
                      <MenuItem value={type.name}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </React.Fragment>
            )}
          </TableRow>
        ))}
      </Table>
    </TableContainer>
    </div>
  )
}


export default EntityPhoneList;
