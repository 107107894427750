import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import {Fab, ListItemIcon, makeStyles, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import {UserContext} from "../../context/usercontext";
import {ACTIONS, TimeEntryContext} from "../../context/TimeEntryContext/time.entry.context";
import {AccessAlarm, AlarmOff, AlarmOn, List} from "@material-ui/icons";
import useHasRole from "../../hooks/user.role.hook";

const useStyles = makeStyles((theme) => ({
    btn: {
        color: "#fff"
    }
}));


export function TimeClockSplitButton() {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {state, dispatch} = React.useContext(TimeEntryContext);
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [canClockIn, setCanClockIn] = React.useState(false);
    const [canClockOut, setCanClockOut] = React.useState(false);
    const { checkPermission } = useHasRole();
    const isClocker = checkPermission("TIME_ENTRY_CLOCKER");

    React.useEffect(() => {
        if (state?.currentTimeEntries) {
            let clockIN = true;
            if (typeof state?.currentTimeEntries?.filter !== 'undefined') {
                clockIN = state?.currentTimeEntries?.filter(c => !c.end)?.length < 1;
            }
            if (isClocker) {
                setCanClockIn(clockIN);
            } else {
                setCanClockIn(false);
            }
            let clockOUT = false;
            if (typeof state?.currentTimeEntries?.filter !== 'undefined') {
                clockOUT = state?.currentTimeEntries?.filter(c => !c.end)?.length > 0
            }
            if (isClocker) {
                setCanClockOut(clockOUT);
            } else {
                setCanClockOut(false);
            }
            if (isClocker) {
                dispatch({
                    type: ACTIONS.SET_CLOCK_OPTION_SELECTED_INDEX,
                    payload: !clockOUT && !clockIN ? 2 : clockIN ? 0 : 1
                });
            } else {
                dispatch({type: ACTIONS.SET_CLOCK_OPTION_SELECTED_INDEX, payload: 2});
            }
        } else {
            if (isClocker) {
                setCanClockIn(true);
                setCanClockOut(false);
                dispatch({type: ACTIONS.SET_CLOCK_OPTION_SELECTED_INDEX, payload: 0});
            } else {
                setCanClockIn(false);
                setCanClockOut(false);
                dispatch({type: ACTIONS.SET_CLOCK_OPTION_SELECTED_INDEX, payload: 2});
            }
        }
        // eslint-disable-next-line
    }, [state?.currentTimeEntries])

    const handleClick = () => {
        if (state?.clockOptionSelectedIndex === 0) {
            dispatch({type: ACTIONS.SET_VIEW_CLOCK_IN, payload: true});
        } else if (state?.clockOptionSelectedIndex === 1) {
            dispatch({type: ACTIONS.SET_VIEW_CLOCK_OUT, payload: true});
        } else {
            dispatch({type: ACTIONS.SET_VIEW_TIMECLOCK_TABLE, payload: true});
        }
    };

    const handleMenuItemClick = (event, index) => {
        dispatch({type: ACTIONS.SET_CLOCK_OPTION_SELECTED_INDEX, payload: index});
        setOpen(false);
        if (index === 0) {
            dispatch({type: ACTIONS.SET_VIEW_CLOCK_IN, payload: true});
        } else if (index === 1) {
            dispatch({type: ACTIONS.SET_VIEW_CLOCK_OUT, payload: true});
        } else {
            dispatch({type: ACTIONS.SET_VIEW_TIMECLOCK_TABLE, payload: true});
        }
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };
    return (
        <div style={{marginLeft: "1rem"}}>
            {!isMobile &&
                <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button"
                             className={classes.btn}>
                    <Button startIcon={state?.clockButtonOptions[state?.clockOptionSelectedIndex]?.icon}
                            onClick={handleClick}>
                        {state?.clockButtonOptions[state?.clockOptionSelectedIndex]?.label}
                    </Button>
                    <Button
                        color="primary"
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                    >
                        <ArrowDropDownIcon/>
                    </Button>
                </ButtonGroup>
            }
            {isMobile &&
                <Fab color={"primary"}
                     size={"large"}
                     aria-controls={open ? 'split-button-menu' : undefined}
                     aria-expanded={open ? 'true' : undefined}
                     aria-label="select merge strategy"
                     aria-haspopup="menu"
                     onClick={handleToggle}
                     variant={"extended"}>
                    <AccessAlarm/>
                    <Typography variant={"subtitle2"} style={{fontSize: 10}}>
                        Clock {!canClockIn ? " Out": " In"}
                    </Typography>
                </Fab>
            }
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">
                                    {isClocker &&
                                        <MenuItem
                                        key={"clock_in"}
                                        disabled={!canClockIn}
                                        onClick={(event) => handleMenuItemClick(event, 0)}
                                    >
                                        <ListItemIcon>
                                            <AlarmOn/>
                                        </ListItemIcon>
                                        {"Clock in"}
                                    </MenuItem>
                                    }
                                    {isClocker &&
                                        <MenuItem
                                        key={"clock_out"}
                                        disabled={!canClockOut}
                                        onClick={(event) => handleMenuItemClick(event, 1)}
                                    >
                                        <ListItemIcon>
                                            <AlarmOff/>
                                        </ListItemIcon>
                                        {"Clock out"}
                                    </MenuItem>
                                    }
                                    <MenuItem
                                        key={"View time entries"}
                                        onClick={(event) => handleMenuItemClick(event, 2)}
                                    >
                                        <ListItemIcon>
                                            <List/>
                                        </ListItemIcon>
                                        {"View time entries"}
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );

}

const TimeClockSplitButtonWrapper = () => {
    const {selectedTenant} = React.useContext(UserContext);

    if (selectedTenant?.features?.timeClock) {
        return (
            <TimeClockSplitButton/>
        )
    } else {
        return null;
    }
}

export default TimeClockSplitButtonWrapper;
