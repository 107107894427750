export const parseDate = (date) => {
  if (date && date.length === 3) {
    return `${date[1]}/${date[2]}/${date[0]}`;
  }
  return "N/A";
};

export const getDayOfWeek = day => {
  const dayInt = Number(day);
  if (!isNaN(dayInt)) {
    return ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"][dayInt];
  }
  return 'n/a';
}

export const getMonth = month => {
  const monthInt = Number(month);
  if (!isNaN(monthInt)) {
    return ["January","February","March","April","May","June","July","August","September","October","November","December"][monthInt];
  }
  return 'n/a';
}
