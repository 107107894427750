import React from 'react';
import {Button, makeStyles} from "@material-ui/core";
import {DeleteForeverTwoTone} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    }
  }
}));

export const DeleteBtn = ({buttonText = "Delete", ...p}) => {
  const classes = useStyles();
  return (
    <Button startIcon={<DeleteForeverTwoTone />} {...p}
            className={classes.root}
    >
      {buttonText || "Delete"}
    </Button>
  )
}
