import apiUtil from '../utils/api.utils';
import config from '../utils/configs';
import {parseFiltersToJson} from "../utils/filter.utils";
// import { parseFilters, parseFiltersToJson } from "../utils/filter.utils";
const apiUrl = `${config.medicationOrderService}/api/v1.0`;

const getResidentTreatments = async (residentId, prn, status) => {
  try {
    let url = `${apiUrl}/residents/${residentId}/treatments`;
    let prnParamPassed = false;
    if (typeof prn === 'boolean') {
      url += `?prn=${prn}`;
      prnParamPassed = true;
    }
    if (status !== null && typeof status === 'boolean') {
      if (prnParamPassed) {
        url += `&active=${status}`
      } else {
        url += `?active=${status}`
      }
    }
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (error) {
    return { error: true, message: error };
  }
};

const searchResidentTreatments = async (residentId, page, size, filters, sortBy, sortDirection) => {
  try {
    let url = `${apiUrl}/residents/${residentId}/treatments?page=${page}&size=${size}`;
    url += parseFiltersToJson(filters);
    url += `&sort=${sortDirection === "desc"? "-": "+"}${sortBy}`;

    const response = await apiUtil.client.get(url);
    return response?.data;
  } catch (error) {
    return {error: true, message: error };
  }
}

const getTreatmentEventsForMonth = async (residentId, year, month) => {
  try {
    let url = `${apiUrl}/residents/${residentId}/treatmentevents`;
    url += `?year=${year}&month=${month}`;
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

const createResidentTreatment = async (residentId, treatment) => {
  try {
    const response = await apiUtil.client.post(
      `${apiUrl}/residents/${residentId}/treatments`,
      treatment
    );
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
};

const updateResidentTreatment = async (residentId, treatmentId, treatment) => {
  try {
    const response = await apiUtil.client.put(
      `${apiUrl}/residents/${residentId}/treatments/${treatmentId}`,
      treatment
    );
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
};

const deleteResidentTreatment = async (residentId, treatmentId) => {
  try {
    const response = await apiUtil.client.put(
      `${apiUrl}/residents/${residentId}/treatments/${treatmentId}`
    );
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
};


let getResidentEtarsCancelToken;
const getResidenteTars = async (residentId, date, offset) => {
  try {
    if (typeof getResidentEtarsCancelToken != typeof undefined) {
      getResidentEtarsCancelToken.cancel("Cancel Request");
    }
    getResidentEtarsCancelToken = apiUtil.getCancelToken();
    const response = await apiUtil.client.get(
      `${apiUrl}/residents/${residentId}/etars?date=${date}&offset=${offset}`,
      {cancelToken: getResidentEtarsCancelToken.token}
    );
    return response.data;
  } catch (error) {
    if (apiUtil.isCancel(error)) {
      return { canceled: true };
    } else {
      return { error: true, message: error }
    }
  }
};

const updateResidentETar = async (residentId, treatmentId, etarId, etar) => {
  try {
    const response = await apiUtil.client.put(
      `${apiUrl}/residents/${residentId}/treatments/${treatmentId}/etars/${etarId}`,
      etar
    );
    return response.data;
  } catch (error) {
    return { error: true, message: false}
  }
};

const deleteResidentEtar = async (residentId, treatmentId, etarId) => {
  try {
    const response = await apiUtil.client.delete(
      `${apiUrl}/residents/${residentId}/treatments/${treatmentId}/etars/${etarId}`);
    return response.data;
  } catch (error) {
    return { error: true, message: false}
  }
}

const createResidentEtar = async (residentId, treatmentId, etar) => {
  try {
    const response = await apiUtil.client.post(
      `${apiUrl}/residents/${residentId}/treatments/${treatmentId}/etars/`,
      etar
    );
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
};

export {
  getResidentTreatments,
  searchResidentTreatments,
  updateResidentTreatment,
  createResidentTreatment,
  deleteResidentTreatment,
  getResidenteTars,
  updateResidentETar,
  deleteResidentEtar,
  createResidentEtar,
  getTreatmentEventsForMonth
};
