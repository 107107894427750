import React from 'react';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent, Grid,
  makeStyles, Paper, Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer, TableFooter,
  TableHead,
  TableRow, Tabs, TextField,
  Typography, withStyles
} from "@material-ui/core";
import moment from "moment";
import {
  TimeEntryContext,
  toggleViewTimeClock,
  ACTIONS, addEventToTimeClock, clockOutAction, deleteTimeClockEventAction, addTimeClockDayAction
} from "../../context/TimeEntryContext/time.entry.context";
import {getUserTimeEntryForWeek} from "../../services/timeentry.service";
import {Add, ChevronLeft, ChevronRight, Edit} from "@material-ui/icons";
import {TimeEntryForm} from "./form/time.entry.form";
import {SsaDialogTitle} from "../utils/dialogs/ssa.dialog.title";
import {MuiPickersUtilsProvider, KeyboardTimePicker} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import * as _ from 'lodash';
import {getStripedStyle} from "../utils/table.utils";
import {DeleteBtn} from "../utils/buttons/DeleteBtn";
import {TimeClockDayForm} from "./form/time.clock.day.form";

let hourFormat = "h:mm a";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    textAlign: "center"
  },
  dialogContent: {
    height: "70vh"
  },
  clockBtnContainer: {
    padding: "2rem",
    textAlign: "center"
  },
  clockInBtn: {
    height: "5rem"
  },
  btnContainer: {
    padding: theme.spacing(2, 0)
  },
  tabPanel: {
    backgroundColor: 'blue'
  }
}));

function TabPanel(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={classes.tabPanel}
      {...other}
    >
      {value === index && (
        <div>
          {children}
        </div>
      )}
    </div>
  );
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const EventDetailRow = ({editEvent,
                          event,
                          timeClock,
                          onClockInChange,
                          onClockOutChange,
                          onCommentChange,
                          onClockOut,
                          onDelete,
                          onSave,
                          onCancel,
                          onSelect
}) => {


  const isValid = () => {
    if (!editEvent?.clockOut) {
      return moment(editEvent?.clockIn).isValid();
    } else {
      return moment(editEvent?.clockIn).isValid() && moment(editEvent?.clockOut).isValid();
    }
  }

  return (
    <>
      <TableCell>
        {editEvent?._id === event?._id &&
          <Grid container justifyContent={"space-between"}>
            <Grid item>
              <DeleteBtn size={"small"} onClick={onDelete} />
            </Grid>
            <Grid item>
              <Button variant={"contained"}
                      size={"small"}
                      onClick={onSave}
                      disabled={!isValid()}
                      color={"primary"}>Update</Button>
              <Button onClick={onCancel}>Cancel</Button>
            </Grid>
          </Grid>
        }
        {editEvent?._id !== event?._id &&
          <Button variant={"text"}
                  onClick={onSelect}
                  startIcon={<Edit />}>Edit</Button>
        }
      </TableCell>
      <TableCell style={{border: "none"}}>
        {editEvent?._id === event?._id &&
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardTimePicker
              fullWidth
              style={{flex: 1}}
              label={"Clock In"}
              mask="__:__ _M"
              name={"clockIn"}
              onChange={onClockInChange}
              value={editEvent?.clockIn}
            />
          </MuiPickersUtilsProvider>
        }
        {editEvent?._id !== event?._id &&
          <>
            {moment(event?.clockIn)?.format(hourFormat)}
          </>
        }
      </TableCell>
      <TableCell style={{border: "none"}}>
        {editEvent?._id === event?._id &&
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardTimePicker
              fullWidth
              style={{flex: 1}}
              label={"Clock Out"}
              mask="__:__ _M"
              name={"clockOut"}
              onChange={onClockOutChange}
              value={editEvent?.clockOut}
            />
          </MuiPickersUtilsProvider>
        }
        {editEvent?._id !== event?._id &&
          <>
            {event?.clockOut ? moment(event?.clockOut)?.format(hourFormat):
              <Button onClick={() => onClockOut(timeClock, event)}
                      variant={"outlined"}
                      size={"small"}>
                Clock Out
              </Button>
            }
          </>
        }
      </TableCell>
      <TableCell style={{border: "none"}}>
        {editEvent?._id === event?._id &&
          <TextField onChange={onCommentChange} fullWidth variant={"outlined"} multiline minRows={10} value={editEvent?.comment} />
        }
        {editEvent?._id !== event?._id &&
          <>
            {event?.comment || null}
          </>
        }
      </TableCell>
    </>
  )
}

export const TimeClockTable = () => {
  const classes = useStyles();
  const { state, dispatch } = React.useContext(TimeEntryContext);
  const {viewTimeClockTable} = state;
  const [editEvent, setEditEvent] = React.useState();
  const [selectedTimeClock, setSelectedTimeClock] = React.useState();
  const [viewAddClockEventForm, setViewAddClockEventForm] = React.useState(false);
  const [viewAddTimeClockDayForm, setViewAddTimeClockDayForm] = React.useState(false);
  const [tabValue, setTabValue] = React.useState("MY_TIMECLOCK");

  React.useEffect(() => {
    if (viewTimeClockTable && state?.week && state?.year) {
      setViewAddClockEventForm(false);
      getWeeklyTimeSheets().then(() =>{});
    }
// eslint-disable-next-line
  }, [viewTimeClockTable, state?.week, state?.year])

  const getWeeklyTimeSheets = async (userId, year, week) => {
    dispatch({type: ACTIONS.TOGGLE_LOADING, payload: true});
    dispatch({type: ACTIONS.TOGGLE_LOADING, payload: true});
    const timeEntriesResponse = await getUserTimeEntryForWeek(state?.user?._id, state?.year, state?.week);
    if (!timeEntriesResponse?.error) {
      dispatch({type: ACTIONS.SET_TIME_CLOCKS, payload: timeEntriesResponse});
    } else {
      dispatch({type: ACTIONS.TOGGLE_ERROR, payload: true});
    }
    dispatch({type: ACTIONS.TOGGLE_LOADING, payload: false});
  }

  const onClose = () => {
    toggleViewTimeClock(dispatch, false);
  }

  const getCurrentWeek = () => {
    dispatch({type: ACTIONS.SET_WEEK_YEAR, payload: {week: moment().weeks(), year: moment().year()}});
  }

  const getNextWeek = () => {
    let newWeek = state?.week === 52 ? 1: state?.week + 1
    dispatch({
      type: ACTIONS.SET_WEEK_YEAR,
      payload: {week: newWeek, year: newWeek === 1 ? state.year + 1: state.year }
    });
  }
  const getPrevWeek = () => {
    let newWeek = state?.week === 1 ? 52: state?.week - 1;
    dispatch({
      type: ACTIONS.SET_WEEK_YEAR,
      payload: {week: newWeek, year: newWeek === 52 ? state.year - 1: state.year }
    });
  }

  const getWeekDisplay = (week) => {
    const date = moment().weeks(week);
    const startDate = date.clone().startOf('week');
    const endDate = date.clone().endOf('week');
    return `${startDate.format('M/D/YYYY')} - ${endDate.format('MM/DD/YYYY')}`
  }

  const onAddEvent = (timeClock) => {
    setSelectedTimeClock(timeClock);
    setViewAddClockEventForm(true);
  }

  const onAddClockEvent = async (clockEvent) => {
    await addEventToTimeClock(dispatch, selectedTimeClock, clockEvent);
    setViewAddClockEventForm(false);
  }

  const onAddDay = () => {
    setViewAddTimeClockDayForm(true);
  }

  const onEditEvent = (timeClock, clockEvent) => {
    setEditEvent(clockEvent);
  }

  const onClockOut = async (timeClock, clockEvent) => {
    await clockOutAction(dispatch, timeClock, clockEvent?._id, moment());
    setEditEvent(null);
  }

  const canAddEvent = timeClock => {
    const incompleteEvents = timeClock?.events?.filter(e => !e?.clockIn || !e?.clockOut);
    return incompleteEvents?.length === 0;
  }

  const onEditTimeoutChange = date => {
    const obj = {...editEvent};
    obj.clockOut = date;
    setEditEvent(obj);
  }

  const onEditClockInChange = date => {
    const obj = {...editEvent};
    obj.clockIn = date;
    setEditEvent(obj);
  }

  const onSaveEditEvent = async (timeClock) => {

    alert(JSON.stringify(editEvent, null, 2));

    // await editClockEventAction(dispatch, timeClock, editEvent?._id, editEvent);
    // setEditEvent(null);
  }

  const updateEditComment = e => {
    const obj = {...editEvent};
    obj.comment = e.target.value;
    setEditEvent(obj);
  }

  const onTabChange = (event, newValue) => {
    setTabValue(newValue);
  }

  const getTotalHours = () => {
    let timeClockEvents = _.flatten(state?.timeClocks?.map(tc => tc?.events));
    let duration = moment.duration(0);
    for (let event of timeClockEvents) {
      if (event?.clockOut) {
        let clockOutEvent = moment(event?.clockOut);
        clockOutEvent.set({seconds: 0, milliseconds: 0 });
        let clockInEvent = moment(event?.clockIn);
        clockInEvent.set({seconds: 0, milliseconds: 0});
        duration.add(moment.duration(clockOutEvent.diff(clockInEvent)));
      }
    }

    let hours = duration?.hours();
    let minutes = duration?.minutes();

    if (minutes === 60) {
      minutes = 0;
      hours += 1;
    }

    return `${hours}:${minutes < 10 ? `0${minutes}`: `${minutes}` }`;
  }

  const handleEventDelete = async (timeClock, event) => {
    setEditEvent(null);
    await deleteTimeClockEventAction(dispatch, timeClock, event?._id);
  }

  const handleOnAddDay = async timeClock => {
    if (timeClock) {
      timeClock.user = state?.user?._id;
      await addTimeClockDayAction(dispatch, timeClock);
      setViewAddTimeClockDayForm(false);
    }
  }

  return (
    <Dialog open={viewTimeClockTable}
            maxWidth={"xl"}
            fullWidth={true}
    >
      <SsaDialogTitle onClose={onClose}>
      <Tabs value={tabValue}
            onChange={onTabChange}
      >
        <Tab value={"MY_TIMECLOCK"} label={"My time clock"} />
        <Tab value={"TIMECLOCKS"} label={"Time clocks"} />
      </Tabs>
      </SsaDialogTitle>
      <DialogContent className={classes.dialogContent} dividers>
        <TabPanel value={tabValue} index={"MY_TIMECLOCK"}  className={classes.rootPanel}>
        {!viewAddClockEventForm && !viewAddTimeClockDayForm &&
          <div>
            <Paper>
              <div style={{textAlign: "center"}}>
                <Typography variant={"h6"}>
                  {getWeekDisplay(state?.week)}
                </Typography>
              </div>
              <ButtonGroup className={classes.btnContainer} fullWidth disabled={state.loading}>
                <Button color={"primary"}
                        variant={"contained"}
                        startIcon={<ChevronLeft />}
                        onClick={getPrevWeek}>
                  Prev Week
                </Button>
                <Button onClick={getCurrentWeek}>Current Week</Button>
                <Button color={"primary"}
                        variant={"contained"}
                        endIcon={<ChevronRight />}
                        onClick={getNextWeek}>
                  Next Week
                </Button>
              </ButtonGroup>
            </Paper>
          {!state?.loading &&
            <TableContainer component={Paper}>
            <Table size={"small"}>
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{border: "none"}}  />
                  <StyledTableCell style={{border: "none"}} >
                    Date
                  </StyledTableCell>
                  <StyledTableCell style={{border: "none"}}  colspan={5} />
                </TableRow>
                <TableRow>
                  <StyledTableCell colspan={4} />
                  <StyledTableCell>Clock In</StyledTableCell>
                  <StyledTableCell>Clock Out</StyledTableCell>
                  <StyledTableCell>Comment</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state?.timeClocks?.sort((a, b) => a?.date > b?.date ? -1: 1)?.map(timeClock =>
                  <>
                    <StyledTableRow>
                      <TableCell style={{border: "none"}} />
                      <TableCell style={{border: "none"}} >
                        {moment(timeClock.date).utc().format("M/D/YYYY")}
                      </TableCell>
                      <TableCell style={{border: "none"}} />
                      {timeClock?.events?.length > 1 &&
                        <TableCell style={{border: "none"}}  colspan={4} />
                      }
                      {timeClock?.events?.length === 1 && timeClock?.events?.map(event =>
                        <EventDetailRow event={event}
                                        editEvent={editEvent}
                                        timeClock={timeClock}
                                        onClockOut={() => onClockOut(timeClock, event)}
                                        onClockInChange={onEditClockInChange}
                                        onClockOutChange={onEditTimeoutChange}
                                        onCommentChange={updateEditComment}
                                        onCancel={() => setEditEvent(null)}
                                        onSave={() => onSaveEditEvent(timeClock)}
                                        onSelect={() => onEditEvent(timeClock, event)}
                                        onDelete={() => handleEventDelete(timeClock, event)}
                        />
                      )}
                    </StyledTableRow>
                    {timeClock?.events?.length > 1 && timeClock?.events?.map((event, index) =>
                      <TableRow style={getStripedStyle(index)}>
                        <TableCell style={{border: "none"}} colSpan={3} />
                        <EventDetailRow event={event}
                                        editEvent={editEvent}
                                        timeClock={timeClock}
                                        onClockOut={() => onClockOut(timeClock, event)}
                                        onClockInChange={onEditClockInChange}
                                        onClockOutChange={onEditTimeoutChange}
                                        onCommentChange={updateEditComment}
                                        onCancel={() => setEditEvent(null)}
                                        onSave={() => onSaveEditEvent(timeClock)}
                                        onSelect={() => onEditEvent(timeClock, event)}
                                        onDelete={() => handleEventDelete(timeClock, event)}
                        />
                      </TableRow>
                    )}
                      <TableRow>
                        <TableCell />
                        <TableCell />
                        <TableCell colspan={5}>
                          {canAddEvent(timeClock) &&
                            <Button size={"small"}
                                  onClick={() => onAddEvent(timeClock)}
                                  startIcon={<Add />}>
                            Add Clock Event
                          </Button>
                          }
                        </TableCell>
                      </TableRow>
                  </>
                )}
                {state?.timeClocks?.length !== 7 &&
                  <TableRow>
                    <TableCell />
                    <TableCell colspan={6}>
                      <Button onClick={onAddDay}
                              startIcon={<Add />}>Add Day</Button>
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={4} />
                  <TableCell>
                    Total Hours: {getTotalHours()}
                  </TableCell>
                  <TableCell colSpan={2} />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>}
          </div>}
          {viewAddClockEventForm &&
            <Paper style={{width: '30rem', margin: '0 auto'}}>
              <TimeEntryForm user={state?.user}
                             clockInOnly={true}
                             initalDate={moment(selectedTimeClock?.date)}
                             onCancel={() => setViewAddClockEventForm(false)}
                             onSave={onAddClockEvent} />
            </Paper>
          }
          {viewAddTimeClockDayForm &&
            <TimeClockDayForm week={state?.week}
                              timeClocks={state?.timeClocks}
                              onSave={handleOnAddDay}
                              onCancel={() => setViewAddTimeClockDayForm(false)}
            />
          }
        </TabPanel>
        <TabPanel value={tabValue} index={"TIMECLOCKS"}>
         <TableContainer>
           <Table size={"small"}>
             <TableHead>
               <TableCell />
               <TableCell>Week</TableCell>
               <TableCell>Hours</TableCell>
             </TableHead>
           </Table>
         </TableContainer>
        </TabPanel>
      </DialogContent>
      <DialogActions>
        {!editEvent &&
          <Button variant={"text"}
                  onClick={onClose}
                  size={"large"}>
            Close
          </Button>
        }
      </DialogActions>
    </Dialog>
  )
}
