import React from "react";
import {
  searchResidentAssessmentsByAssessmentName,
  getAssessments,
  createResidentAssessment
} from "../../../services/assessment.service";
import {
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TableContainer,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  Link
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";
import CreateResidentAssessment from "./create.resident.assessment";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  selectedRow: {
    backgroundColor: theme.palette.grey[300],
    borderColor: theme.palette.grey[500],
    borderWidth: '1px',
    borderStyle: 'solid'
  },
  link: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

const ResidentAssessments = ({
  resident,
  residentId,
  assessmentName,
  onNewAssessment,
  onViewAssessment,
  onEmptyList,
  updatePayload
}) => {
  const classes = useStyles();
  const [residentAssessments, setResidentAssessments] = React.useState([]);
  const [openNewDialog, setOpenNewDialog] = React.useState(false);
  const [assessments, setAssessments] = React.useState([]);
  const [selectedAssessment, setSelectedAssessment] = React.useState(null);

  React.useEffect(() => {
    const loadAssessments = async () => {
      const payload = await getAssessments();
      setAssessments(payload.content);
    }
    loadAssessments().then(() => {});
  }, []);


  React.useEffect(() => {
    if (updatePayload && updatePayload.type && updatePayload.type === "COMPLETION_UPDATE") {
      const obj = residentAssessments.map(residentAssessment => {
        if (residentAssessment._id === updatePayload.payload.assessmentInstanceId) {
          residentAssessment.completedAt = updatePayload.payload.completedAt;
          residentAssessment.isCompleted = updatePayload.payload.completedAt ? true: false;
        }
        return residentAssessment;
      });
      setResidentAssessments(obj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePayload])


  React.useEffect(() => {
    const getResidentAssessments = async () => {
      const response = await searchResidentAssessmentsByAssessmentName(residentId, assessmentName);
      if (!response?.error) {
        const assessmentsResponse = response?.content;
        setResidentAssessments(assessmentsResponse);
        if (assessmentsResponse?.length > 0) {
          setSelectedAssessment(assessmentsResponse[0]);
        } else if (assessmentName) {
          const foundIt = assessmentsResponse?.find(a => a?.name === assessmentName);
          if (foundIt) {
            const newAssessment = await saveNewOnInit(residentId, foundIt);
            setSelectedAssessment(newAssessment);
          }
        }
      }
    }
    if (assessments?.length > 0 && assessmentName && residentId) {
      getResidentAssessments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessments, assessmentName, residentId])


  const selectAssessment = residentAssessment => {
    setSelectedAssessment(residentAssessment);
    if (onViewAssessment) {
      onViewAssessment(residentAssessment);
    }
  };

  const createAssessment = () => {
    setOpenNewDialog(true);
  };

  const closeNewDialog = () => {
    setOpenNewDialog(false);
  };

  const saveNewOnInit = async (residentId, assessmentId) => {
    const response = await createResidentAssessment(residentId, assessmentId);
    const obj = [...residentAssessments, response];

    setResidentAssessments(obj);
    setSelectedAssessment(response);
    if (onViewAssessment) {
      onViewAssessment(response);
    }
    setOpenNewDialog(false);
    return response;
  }

  const saveNew = async (residentId, assessmentId) => {
    const response = await createResidentAssessment(residentId, assessmentId);
    const obj = [...residentAssessments, response];

    setResidentAssessments(obj);
    setSelectedAssessment(response);
    if (onViewAssessment) {
      onViewAssessment(response);
    }
    setOpenNewDialog(false);
  };

  const getRowStyle = row => {
    return selectedAssessment && row.assessmentInstanceId === selectedAssessment.assessmentInstanceId;
  }

  return (
    <div className={classes.root}>
      <ExpansionPanel>
        <ExpansionPanelSummary>
          <Typography>Resident Assessments</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Button
                      size="small"
                      color="primary"
                      variant="contained"
                      startIcon={<AddIcon />}
                      onClick={() => createAssessment()}
                    >
                      New
              </Button>
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell>Completed</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {residentAssessments?.map(residentAssessment => (
                    <TableRow className={getRowStyle(residentAssessment) ? classes.selectedRow : null}>
                      <TableCell>
                        <Link className={classes.link} onClick={() => selectAssessment(residentAssessment)}>View</Link>
                      </TableCell>
                      <TableCell>{residentAssessment?.assessment?.name}</TableCell>
                      <TableCell>{residentAssessment?.assessment?.type ? residentAssessment?.assessment?.type?.name: null}</TableCell>
                      <TableCell>
                        {residentAssessment?.createdAt &&
                          moment(residentAssessment?.createdAt).format(
                            "MM/DD/YYYY"
                          )}
                      </TableCell>
                      <TableCell>
                        {residentAssessment?.completedAt &&
                          moment(residentAssessment?.completedAt).format(
                            "MM/DD/YYYY"
                          )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <CreateResidentAssessment
        residentId={residentId}
        resident={resident}
        assessmentName="Resident Intake Assessment"
        assessments={assessments}
        open={openNewDialog}
        onCancel={closeNewDialog}
        onSave={saveNew}
      />
    </div>
  );
};

export default ResidentAssessments;
