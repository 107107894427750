import React from 'react';
import {AppBar, Dialog, DialogContent, IconButton, makeStyles, Toolbar, Typography} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {TimeEntryForm} from "../form/time.entry.form";
import moment from "moment";
import {ACTIONS, createTimeEntry, TimeEntryContext} from "../../../context/TimeEntryContext/time.entry.context";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(7)
    }
}));

const ClockInMobile = () => {
    const classes = useStyles();
    const {state, dispatch} = React.useContext(TimeEntryContext);
    const {user, viewClockIn} = state;


    const onClockIn = async (clockInEvent) => {
        const data = {
            user: user?._id,
            date: moment().format("MM/DD/YYYY"),
            ...clockInEvent
        };
        await createTimeEntry(dispatch, data, true);
    }

    const handleClose = () => {
        dispatch({type: ACTIONS.SET_VIEW_CLOCK_IN, payload: false});
    }

    return (
        <Dialog open={viewClockIn}
                fullScreen={true}
                className={classes.root}
        >
            <AppBar>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                    <Typography variant="h6">Clock In</Typography>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <TimeEntryForm user={user}
                               onCancel={handleClose}
                               initalDate={moment()}
                               clockInOnly={true}
                               isMobile={true}
                               onSave={onClockIn}/>
            </DialogContent>
        </Dialog>
    )
}

export default ClockInMobile;
