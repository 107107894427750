import React from 'react';
import {List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {ListAlt} from "@material-ui/icons";
import {Link} from 'react-router-dom';
import {PageTitle} from "../../components/utils";

export const Administration = () => {
  return (
    <div>
      <PageTitle title={"Administration"} />
      <List>
        <ListItem button divider component={Link} to={"/administration/careplans"}>
          <ListItemIcon>
            <ListAlt />
          </ListItemIcon>
          <ListItemText primary={"Careplan Statuses"} />
        </ListItem>
      </List>
    </div>
  )
}
