import * as constant from './intake.constants'
import {
  getResidentById,
  updateResident,
  createResident,
  createResidentImage, removeResidentImage
} from "../../../../../services/resident.service";
import {getMaritalStatuses} from "../../../../../services/maritalstatus.service";
import {getGenders} from "../../../../../services/gender.service";
import {getEthnicities} from "../../../../../services/ethnicity.service";
import {DetailsModel} from "../steps/details/details.model";
import {isModelValid} from "../../../../../utils/forms";
import {getCodeStatuses} from "../../../../../services/medication.service";
import {BackgroundModel, MedicalModel} from "../steps";
import AddressModel from "../steps/address/address.model";
import {createResidentAdmission} from "../../../../../services/admission.service";
import Compressor from "compressorjs";

export const setNewIntake = (dispatch, payload) => {
  dispatch({type: constant.SET_NEW_INTAKE, payload });
}

export const handleStepByName = (dispatch, newStep) => {
  dispatch({type: constant.SET_STEP_BY_NAME, payload: newStep});
}

export const setId = (dispatch, id) => {
  dispatch({type: constant.SET_RESIDENT_ID, payload: id});
}

export const getResident = async (dispatch, state, id) => {
  dispatch({type: constant.SET_ERROR, payload: false });
  dispatch({type: constant.SET_LOADING, payload: true });
  let resident = null;
  if (!state?.isNewIntake) {
    resident = await getResidentById(id);
    if (!resident?.error) {
      dispatch({type: constant.SET_RESIDENT, payload: resident});
    } else {
      dispatch({type: constant.SET_ERROR, payload: true});
    }
  }
  const updatedModel = await mergeValues(dispatch, state, state.model, resident);
  setOverallStatus(dispatch, { resident, model: updatedModel });
  dispatch({type: constant.SET_LOADING, payload: false });
}


export const mergeValues = async (
  dispatch,
  state,
  model,
  resident,
  defaultResidentStatus
) => {
  model = await getSuportValues(model, state);
  if (model && resident) {
    model._id.value = resident._id;
    model.firstName.value = resident.firstName;
    model.middleName.value = resident.middleName;
    model.lastName.value = resident.lastName;
    model.formerName.value = resident.formerName;
    model.gender.value = resident.gender;
    model.medicalRecordId.value = resident.medicalRecordId;
    model.countyCaseNumber.value = resident.countyCaseNumber;
    model.ssn.value = resident.ssn;
    model.maritalStatus.value = resident.maritalStatus;
    model.religiousPreference.value = resident.religiousPreference;
    model.citizenship.value = resident.citizenship;
    model.ethnicity.value = resident.ethnicity;
    model.primaryLanguage.value = resident.primaryLanguage;
    model.roomNumber.value = resident.roomNumber;
    model.residentStatusId.value = resident.residentStatus
      ? resident.residentStatus._id
      : defaultResidentStatus;
    model.tenantLocationId.value = resident?.location?._id ?? resident?.location;
    model.dob.value = resident.dob;
    model.education.value = resident.education;
    model.hobbies.value = resident.hobbies;
    model.specialInterests.value = resident.specialInterests;
    model.specialNeeds.value = resident.specialNeeds;
    model.livingWill.value = resident.livingWill === true ? 'y' : 'n';
    model.email.value = resident.email;
    model.transferedFrom.value = resident.transferedFrom;
    model.referredBy.value = resident.referredBy;
    model.occupation.value = resident.occupation;
    model.militaryHistory.value = resident.militaryHistory;
    model.codeStatus.value = resident.codeStatus;
    model.photo.value = resident.imageUrl;
  }
  dispatch({type: constant.SET_MODEL, payload: model });
  return model;
};


const getSuportValues = async (model, state) => {
  const obj = { ...model };
  if (
    !obj.maritalStatus.dropDownItems ||
    obj.maritalStatus.dropDownItems.length === 0
  ) {
    const maritalStatuses = await getMaritalStatuses();
    obj.maritalStatus.dropDownItems = maritalStatuses.content;
  }
  if (!obj.gender.dropDownItems || obj.gender.dropDownItems.length === 0) {
    const genders = await getGenders();
    obj.gender.dropDownItems = genders;
  }
  if (
    !obj.ethnicity.dropDownItems ||
    obj.ethnicity.dropDownItems.length === 0
  ) {
    const ethnicities = await getEthnicities();
    obj.ethnicity.dropDownItems = ethnicities;
  }
  if (obj?.codeStatus?.dropDownItems?.length < 1) {
    const codes = await getCodeStatuses();
    if (!codes?.error) {
      obj.codeStatus.dropDownItems = codes;
    }
  }

  if (
    !obj.tenantLocationId.dropDownItems ||
    obj.tenantLocationId.dropDownItems.length === 0
  ) {
    obj.tenantLocationId.dropDownItems = state.tenantLocations;
    // obj.tenantLocationId.value = state.selectedTenantLocationId;
  }
  return obj;
};

export const setStepStatus = (dispatch, step, completed) => {
  dispatch({type: constant.SET_STEP_STATUS, payload: { step, completed }});
}

export const blendObjects = (state, model) => {
  const obj = {...model};
  Object.keys(obj).forEach(key => {
    if (state?.model?.hasOwnProperty(key)) {
      obj[key].value = state.model[key].value;
      obj[key].dropDownItems = state.model[key].dropDownItems;
    }
  });
  return obj;
}

export const setOverallStatus = (dispatch, state) => {
  if (state?.resident) {
    const detailsModel = blendObjects(state, JSON.parse(JSON.stringify(DetailsModel)));
    const detailsCompleted = isModelValid(detailsModel);
    dispatch({type: constant.SET_STEP_STATUS, payload: {step: 'details', completed: detailsCompleted}});
    const medicalModel = blendObjects(state, JSON.parse(JSON.stringify(MedicalModel)));
    const medicalCompleted = isModelValid(medicalModel);
    dispatch({type: constant.SET_STEP_STATUS, payload: {step: 'medical', completed: medicalCompleted}});
    const backgroundModel = blendObjects(state, JSON.parse(JSON.stringify(BackgroundModel)));
    const backgroundCompleted = isModelValid(backgroundModel);
    dispatch({type: constant.SET_STEP_STATUS, payload: {step: 'background', completed: backgroundCompleted}});
    const addressModel = blendObjects(state, JSON.parse(JSON.stringify(AddressModel)));
    const addressCompleted = isModelValid(addressModel);
    dispatch({type: constant.SET_STEP_STATUS, payload: {step: 'address', completed: addressCompleted}});
    dispatch({type: constant.SET_STEP_STATUS, payload: {step: 'photo', completed: true}});
    dispatch({type: constant.SET_STEP_STATUS, payload: {step: 'contacts', completed: true}});
    dispatch({type: constant.SET_STEP_STATUS, payload: {step: 'assessment', completed: true}});
  } else {
    dispatch({type: constant.SET_STEP_STATUS, payload: {step: 'details', completed: false}});
    dispatch({type: constant.SET_STEP_STATUS, payload: {step: 'photo', completed: false}});
    dispatch({type: constant.SET_STEP_STATUS, payload: {step: 'medical', completed: false}});
    dispatch({type: constant.SET_STEP_STATUS, payload: {step: 'background', completed: false}});
    dispatch({type: constant.SET_STEP_STATUS, payload: {step: 'address', completed: false}});
    dispatch({type: constant.SET_STEP_STATUS, payload: {step: 'contacts', completed: false}});
    dispatch({type: constant.SET_STEP_STATUS, payload: {step: 'assessment', completed: false}});
  }
}

export const createResidentAction = async (dispatch, model, resident) => {
  dispatch({type: constant.SET_UPDATING, payload: true});
  const response = await createResident(resident);
  if (!response?.error) {
    dispatch({type: constant.SET_RESIDENT, payload: response });
    dispatch({type: constant.MERGE_MODEL_WITH_VALUES, payload: model });
  } else {

  }
  dispatch({type: constant.SET_UPDATING, payload: false});
}

export const updateResidentAction = async (dispatch, model, resident) => {
  dispatch({type: constant.SET_UPDATING, payload: true});
  const response = await updateResident(resident?._id, resident);
  if (!response?.error) {
    dispatch({type: constant.SET_RESIDENT, payload: response });
    dispatch({type: constant.MERGE_MODEL_WITH_VALUES, payload: model });
  }
  dispatch({type: constant.SET_UPDATING, payload: false});
};

export const updateMedicationAction = async (dispatch, model, state, data) => {
  dispatch({type: constant.SET_UPDATING, payload: true});
  const response = await updateResident(state?.id, data);
  if (!response?.error) {
    dispatch({type: constant.SET_RESIDENT, payload: response });
    dispatch({type: constant.MERGE_MODEL_WITH_VALUES, payload: model });
  }
  dispatch({type: constant.SET_UPDATING, payload: false});
}

export const createAdmissionAction = async (residentId, admission) => {
  await createResidentAdmission(residentId, admission);
}

export const uploadResidentPhoto = (dispatch, residentId, image) => {
  try {
    const formData = new FormData();
    new Compressor(image, {
      quality: 0.6,
      maxHeight: 300,
      maxWidth: 300,
      checkOrientation: true,
      success: async (compressed) => {
        formData.append('file', compressed, image.name);
        const fileName = await createResidentImage(residentId, formData);
        dispatch({type: constant.SET_PHOTO, payload: fileName});
      },
    });
  } catch (error) {

  } finally {
    dispatch({type: constant.SET_UPLOADING_PHOTO, payload: false });
  }
}

export const removeResidentPhoto = async (dispatch, residentId) => {
  dispatch({type: constant.SET_UPLOADING_PHOTO, payload: true });
  await removeResidentImage(residentId);
  dispatch({type: constant.SET_PHOTO, payload: null});
  dispatch({type: constant.SET_UPLOADING_PHOTO, payload: false });
}
