import moment from "moment";
import apiService from '../utils/api.utils';
import configs from '../utils/configs';

export const getAllTenants = async () => {
  try {
    const response = await apiService.client.get(`${configs.userService}/api/v1.0/tenants`);
    return response.data;
  } catch (error) {
    return { error: true, message: error }
  }
}

export const getAllLocations = async () => {
  try {
    const response = await apiService.client.get(`${configs.userService}/api/v1.0/locations`);
    return response.data;
  } catch (error) {
    return { error: true, message: error }
  }
}

export const getLocations = async () => {
  try {
    const response = await apiService.client.get(
      `${configs.coreService}/api/v1.0/tenantlocations`
    );
    return response.content;
  } catch (error) {
    alert(error);
    return Promise.resolve({ content: [] });
  }
};

export const getLocation = async (id) => {
  try {
    const response = await apiService.client.get(
      `${configs.userService}/api/v1.0/locations/${id}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    alert(error);
    return Promise.resolve({ content: [] });
  }
};

export const getUserTenants = async () => {
  const response = await apiService.client.get(
    `${configs.coreService}/api/v1.0/users/tenants`
  );
  return response.data;
};

export const getLocationRoles = async (id) => {
  try {
    const response = await apiService.client.get(
      `${configs.userService}/api/v1.0/locations/${id}/roles`
    );
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
};

export const getTenantLocations = async (tenantId) => {
  try {
    const response = await apiService.client.get(
      `${configs.userService}/api/v1.0/tenants/${tenantId}/locations`
    );
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
};

export const deactivateTenantLocation = async (tenantId, locationId) => {

}

export const createLocation = async (tenantId, location) => {
  try {
    const response = await apiService.client.post(
      `${configs.userService}/api/v1.0/tenants/${tenantId}/locations`,
      location
    );
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
};

export const deleteTenantLocation = async (tenantId, locationId) =>  {
  try {
    const response =
      await apiService.client.delete(`${configs.userService}/api/v1.0/tenants/${tenantId}/locations/${locationId}`);
    return response.data;
  } catch (error) {
    return { error: true, message: error.response.data }
  }
};

export const updateLocation = async (tenantId, locationId, location) => {
  try {
    const response = await apiService.client.put(
      `${configs.userService}/api/v1.0/tenants/${tenantId}/locations/${locationId}`,
      location
    );
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
};

export const updateTenant = async (tenantId, tenant) => {
  try {
    const response = await apiService.client.put(
      `${configs.userService}/api/v1.0/tenants/${tenantId}`,
      tenant
    );
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
};

export const getTenantLogo = async (id) => {
  try {
    const response = await apiService.client.get(
      `${configs.userService}/api/v1.0/tenants/${id}/logo`,
      { responseType: 'blob' }
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const uploadTenantLogo = async (id, formData) => {
  try {
    const response = await apiService.client.post(
      `${configs.userService}/api/v1.0/tenants/${id}/logo`,
      formData
    );
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
};

export const getTenants = async () => {
  try {
    const response = await apiService.client.get(
      `${configs.userService}/api/v1.0/tenants`
    );
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
};

export const getTenant = async (id) => {
  try {
    const response = await apiService.client.get(
      `${configs.userService}/api/v1.0/tenants/${id}`
    );
    return response.data;
  } catch (error) {
    return {error: true, message: error};
  }
};

export const getTenantFeatures = async (id) => {
  try {
    const response = await apiService.client.get(
      `${configs.userService}/api/v1.0/tenants/${id}/features`
    );
    return response.data;
  } catch (error) {
    return {error: true, message: error};
  }
};

export const createTenant = async (tenant) => {
  try {
    const response = await apiService.client.post(
      `${configs.userService}/api/v1.0/tenants`,
      tenant
    );
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
};

export const createLocationPhone = async (locationId, phone) => {
  try {
    const response = await apiService.client.post(
      `${configs.userService}/api/v1.0/locations/${locationId}/phones`,
      phone
    );
    return response.data;
  } catch (error) {
    alert(error);
    return {};
  }
};

export const updateLocationPhone = async (locationId, phoneId, phone) => {
  try {
    const response = await apiService.client.put(
      `${configs.userService}/api/v1.0/locations/${locationId}/phones/${phoneId}`,
      phone
    );
    return response.data;
  } catch (error) {
    alert(error);
    return {};
  }
};

export const deleteLocationPhone = async (locationId, phoneId) => {
  try {
    const response = await apiService.client.delete(
      `${configs.userService}/api/v1.0/locations/${locationId}/phones/${phoneId}`
    );
    return response.data;
  } catch (error) {
    alert(error);
    return {};
  }
};

export const createLocationContact = async (locationId, payload) => {
  try {
    const response = await apiService.client.post(
      `${configs.userService}/api/v1.0/locations/${locationId}/contacts`,
      payload
    );
    return response.data;
  } catch (error) {
    alert(error);
    return {};
  }
};

export const updateLocationContact = async (locationId, contactId, payload) => {
  try {
    const response = await apiService.client.put(
      `${configs.userService}/api/v1.0/locations/${locationId}/contacts/${contactId}`,
      payload
    );
    return response.data;
  } catch (error) {
    alert(error);
    return {};
  }
};

export const deleteLocationContact = async (locationId, contactId) => {
  try {
    const response = await apiService.client.delete(
      `${configs.userService}/api/v1.0/locations/${locationId}/contacts/${contactId}`
    );
    return response.data;
  } catch (error) {
    alert(error);
    return {};
  }
};

export const getTenantImage = async (tenantId) => {
  try {
    const response = await apiService.client.get(
      `${configs.userService}/api/v1.0/tenants/${tenantId}/logo`
    );
    return response.data;
  } catch (error) {
    alert(error);
    return {};
  }
};

export const createTenantImage = async (tenantId, formData) => {
  try {
    const response = await apiService.client.post(
      `${configs.userService}/api/v1.0/tenants/${tenantId}/logo`,
      formData
    );
    return response.data;
  } catch (error) {
    alert(error);
    return {};
  }
};

export const getTenantUsers = async (tenantId) => {
  try {
    const response = await apiService.client.get(
      `${configs.userService}/api/v1.0/tenants/${tenantId}/users`
    );
    return response.data;
  } catch (error) {
    return {error: true, message: error};
  }
};

export const getTenantLocationRoles = async (tenantId) => {
  try {
    const response = await apiService.client.get(
      `${configs.userService}/api/v1.0/tenants/${tenantId}/locationroles`
    );
    return response.data;
  } catch (error) {
    alert(error);
    return {};
  }
};

export const getTenantRoles = async (tenantId) => {
  try {
    const response = await apiService.client.get(
      `${configs.userService}/api/v1.0/tenants/${tenantId}/roles`
    );
    return response.data;
  } catch (error) {
    alert(error);
    return {};
  }
};

export const createTenantRole = async (tenantId, role) => {
  try {
    const response = await apiService.client.post(
      `${configs.userService}/api/v1.0/tenants/${tenantId}/roles`,
      role
    );
    return response.data;
  } catch (error) {
    alert(error);
    return {};
  }
};

export const updateTenantRole = async (tenantId, roleId, role) => {
  try {
    const response = await apiService.client.put(
      `${configs.userService}/api/v1.0/tenants/${tenantId}/roles/${roleId}`,
      role
    );
    return response.data;
  } catch (error) {
    alert(error);
    return {};
  }
};

export const createTenantLocationRole = async (tenantId, locationRole) => {
  try {
    const response = await apiService.client.post(
      `${configs.userService}/api/v1.0/tenants/${tenantId}/locationroles/`,
      locationRole
    );
    return response.data;
  } catch (error) {
    alert(error);
    return {};
  }
};

export const deleteTenantLocationRole = async (tenantId, locationRoleId) => {
  try {
    const response = await apiService.client.delete(
      `${configs.userService}/api/v1.0/tenants/${tenantId}/locationroles/${locationRoleId}`
    );
    return response.data;
  } catch (error) {
    return {
      error: true,
      message: error?.response?.data
    }
  }
};


export const getTenantLocationResidentNotes = async (tenantId,
                                                     locationId,
                                                     page = 1,
                                                     size = 25,
                                                     sort = 'createdAt',
                                                     filter = null,
                                                     start,
                                                     end) => {
  try {
    let url = `${configs.residentService}/api/v1.0/tenants/${tenantId}/locations/${locationId}/residentnotes?page=${page}&size=${size}&sort=${sort}`;
    if (filter) {
      url += `&filter=${filter}`;
    }
    url += `&start=${moment(start).format('MM-DD-YYYY')}&end=${moment(end).format('MM-DD-YYYY')}`
    const response = await apiService.client.get(url);
    return response.data;
  } catch (error) {
    return { error: true, message: error };
  }
};

export const getTenantLocationResidentAlerts = async (tenantId, locationId) => {
  try {
    const response = await apiService.client.get(
      `${configs.residentService}/api/v1.0/tenants/${tenantId}/locations/${locationId}/residentalerts`
    );
    return response.data;
  } catch (error) {
    return { error: true, message: error };
  }
};

export const getTenantLocationResidenAlertsForType = async (tenantId, locationId, type, page = 1, size = 10) => {
  try {
    let url = `${configs.residentService}/api/v1.0/tenants/${tenantId}/locations/${locationId}/residentalerts/${type}`
    url += `?page=${page}&size=${size}`
    const response = await apiService.client.get(url);
    return response.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const updateTenantFeatures = async (tenantId, features) => {
  try {
    let url = `${configs.userService}/api/v1.0/tenants/${tenantId}/features`;
    const response = await apiService.client.put(url, features);
    return response.data;
  } catch (error) {
    return {error: true, message: error};
  }
}
