export const defaultStatuses = [
  {
    "name": "Scheduled",
    "abbreviation": " ",
    "required": true,
    "sort": 1
  },
  {
    "name": "Given",
    "abbreviation": "G",
    "required": true,
    "sort": 2
  },
  {
    "name": "Late",
    "abbreviation": "L",
    "required": true,
    "sort": 3
  },
  {
    "name": "Missed",
    "abbreviation": "M",
    "required": true,
    "sort": 4
  },
  {
    "name": "Refused",
    "abbreviation": "R",
    "required": true,
    "sort": 5
  },
  {
    "name": "Hold",
    "abbreviation": "H",
    "sort": 6
  },
  {
    "name": "Refused & Destructed",
    "abbreviation": "RD",
    "sort": 7
  },
  {
    "name": "Self Administered",
    "abbreviation": "SA",
    "sort": 8
  },
  {
    "name": "Independent",
    "abbreviation": "I",
    "sort": 9
  },
  {
    "name": "Verbal prompt",
    "abbreviation": "VP",
    "sort": 10
  },
  {
    "name": "Physical Assist",
    "abbreviation": "PA",
    "sort": 11
  },
  {
    "name": "Minimal Assist",
    "abbreviation": "MA",
    "sort": 12
  },
  {
    "name": "Not Administered",
    "abbreviation": "NA",
    "sort": 13
  }
]
