import React from 'react';
import PrivateRoute from "../../components/PrivateRoute";
import {CareplansAdminPage} from "./careplans";
import {Switch} from "react-router-dom";
import {Administration} from "./administration";

export const AdministrationPage = () => {

  return (
    <div>
      <Switch>
        <PrivateRoute path={"/administration/careplans"} component={CareplansAdminPage} />
        <PrivateRoute path={"/administration*"} component={Administration} />
      </Switch>
    </div>
  )
}
