import React from 'react';
import {Document, Page, View, Text, StyleSheet} from "@react-pdf/renderer"
import {ResidentReportHeader} from "../../pdf/components/ResidentReportHeader";
import moment from "moment-timezone";

const BORDER_COLOR = '#bfbfbf'
const BORDER_STYLE = 'solid'

const styles = StyleSheet.create({
  page: {
    padding: '0 0 84px 0'
  },
  headerSection: {
    backgroundColor: '#2196f3',
    color: '#fff',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '10vh'
  },
  mainContent: {
    backgroundColor: 'white',
    padding: '0 5px'
  },
  body: {
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    width: '100%',
    margin: "auto",
    flexDirection: "row"
  },
  tableColHeader: {
    width: '33%',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
  },
  tableCol2Header: {
    width: '55px',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol1: {
    width: '125px',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol2: {
    width: '55px',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol: {
    width: '33%',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0
  },
  tableCellHeader: {
    marginTop: 5,
    marginBottom: 5,
    fontSize: 12,
    fontWeight: 500
  },
  tableCell: {
    margin: 5,
    fontSize: 10
  },
  titleContainer: {
    paddingTop: '15px'
  },
  pageCount: {
    paddingLeft: '5px',
    paddingTop: '15px'
  },
  headerText: {
    fontSize: '20px'
  },
  bodyText: {
    fontSize: '8px'
  },
  centerAlign: {
    textAlign: 'center'
  },
  infoSection: {
    margin: '10 0'
  },
  infoText: {
    fontSize: '12px',
    padding: '3 0'
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    padding: '5px'
  },
  footerContainer: {
    marginTop: '5px',
    fontSize: '8px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  marContainer: {
    padding: '5px'
  },
  marHeaderText: {

  },
  dosageInsructionContainer: {
    paddingTop: '5px'
  },
  hrText: {
    minHeight: '25px',
    textAlign: 'center',
  },
  marValueCell: {
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0
  },
  discontinuedDate: {
    width: '100%',
    padding: '5px',
    backgroundColor: "#9e9e9e",
    color: '#fff'
  },
  alerts: {
    width: '100%',
    padding: '5px',
    backgroundColor: "#e57373",
    color: '#fff'
  },
  hideOverflow: {
    textOverflow: "ellipsis"
  },
  freqContainer: {
    minHeight: '10px',
    textAlign: 'center',
  },
  paddedTableCol: {
    paddingTop: '10px',
    width: '20px',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  signatureSection: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20,

  },
  signaturePhysician: {
    flex: 2,
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomWidth: 1,
  },
  signatureDate: {
    flex: 1,
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomWidth: 1,
  }
});

export const CurrentResidentMedicationsReport = ({data, resident}) => {

  const getStripedStyle = (index) => {
    return { backgroundColor: index % 2 ? "#fafafa" : "#fff" };
  };

  const getFrequency = medicationOrder => {

    if (medicationOrder?.isPrn) {
      return `PRN`;
    }

    if (medicationOrder?.dosageInstructions?.length > 1) {
      return medicationOrder?.dosageInstructions?.map(dosageInstruction => {
        if (dosageInstruction?.frequency) {
          return `${dosageInstruction?.frequency?.name} - ${dosageInstruction?.frequency?.description}\n`;
        } else {
          return ''
        }
      })
    } else if (medicationOrder?.dosageInstructions?.length === 1) {
      const dosageInstruction = medicationOrder?.dosageInstructions[0];
      if (dosageInstruction?.frequency) {
        return `${dosageInstruction?.frequency?.name} - ${dosageInstruction?.frequency?.description}`;
      }
    }
    return '';
  }

  const getPhysicianPharmacy = medicationOrder => {
    let physicianPharmacy = '';
    const physician = medicationOrder?.physician;
    physicianPharmacy += `${physician?.title ?? ''} ${physician?.firstName ?? ''} ${physician?.lastName ?? ''}`
    const pharmacy = medicationOrder?.pharmacy;
    physicianPharmacy += `\n${pharmacy?.name ?? ''}`
    return physicianPharmacy;
  }

  const getInfo = medicationOrder => {
    const medication = medicationOrder?.medication;
    let info = medication?.tradeName || medication?.genericName;
    if (medication?.unit) {
      info += `\n${medication?.strength || null} ${medication?.unit?.name}`;
    }
    if (medicationOrder?.direction) {
      info += `\n${medicationOrder?.direction}`
    }
    if (medicationOrder?.indication) {
      info += `\n${medicationOrder?.indication}`
    }
    if (medicationOrder?.rxNumber) {
      info += `\nRX: ${medicationOrder?.rxNumber}`
    }
    return <Text style={[styles.tableCell, styles.bodyText, styles.hideOverflow]}>{info}</Text>;
  }

  return (
    <Document size={"a4"}>
      <Page size={"A4"}
            style={styles.page}>
        <ResidentReportHeader resident={resident}
                              location={resident?.location}
                              allUserLocations={false}
                              displayResidentInfo={true}
                              reportTitle={"Current Medication List"}
        />
        <View style={styles.mainContent}>
          <View style={styles.table}>
            <View style={styles.tableRow} fixed>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Medication</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Frequency</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Physician/Pharmacy</Text>
              </View>
            </View>
            {data?.map((medicationOrder, i) =>
              <View style={[styles.tableRow, getStripedStyle(i)]} wrap>
                <View style={[styles.tableCol, { paddingTop: '4px'}]}>
                  {getInfo(medicationOrder)}
                </View>
                <View style={[styles.tableCol, { paddingTop: '4px'}]}>
                  <Text style={[styles.tableCell, styles.bodyText, styles.hideOverflow]}>
                    {getFrequency(medicationOrder)}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, styles.bodyText, styles.hideOverflow]}>
                    {getPhysicianPharmacy(medicationOrder)}
                  </Text>
                </View>
              </View>
            )}
          </View>
          <View style={styles.signatureSection}>
            <View style={styles.signaturePhysician}>
              <Text style={[styles.bodyText, styles.hideOverflow]}>
                Physician Signature:
              </Text>
            </View>
            <View style={styles.signatureDate}>
              <Text style={[styles.bodyText, styles.hideOverflow]}>
                Date:
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.footer} fixed>
          <View style={styles.footerContainer}>
            <View>
              <Text>
                {moment().format("MM/DD/YYYY hh:mm a")}
              </Text>
            </View>
            <View>
              <Text render={({ pageNumber, totalPages }) => (
                `page ${pageNumber} of ${totalPages}`
              )} fixed />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}
