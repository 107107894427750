import React from "react";
import {
  TableContainer,
  Paper,
  makeStyles,
  Button,
  TableHead,
  TableRow,
  Table,
  TableCell,
  TableBody,
  Grid,
  TablePagination,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import filterTemplate from "./filter.model";
import {
  searchMedications,
  getMedicationUnits,
  getDosageForms,
  getRoutes,
} from "../../services/medication.service";
import { convertToFilters } from "../../utils/filter.utils";
import CreateMedication from "./create.medication";
import {Skeleton} from "@material-ui/lab";
import {SortFilterLabel, SortFilterSelectLabel} from "../utils/table.utils";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

const getStripedStyle = (index) => {
  return { background: index % 2 ? "#fafafa" : "#fff" };
};

const sizeOptions = [1, 5, 10, 25];

const MedicationSearchComponent = ({onSelectMedication}) => {
  const classes = useStyles();
  const [units, setUnits] = React.useState([]);
  const [dosageForms, setDosageForms] = React.useState([]);
  const [routes, setRoutes] = React.useState([]);
  const [medications, setMedications] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [filter, setFilter] = React.useState(JSON.parse(JSON.stringify(filterTemplate)));
  const [sortOrderBy, setSortOrderBy] = React.useState("name");
  const [sortOrderDirection, setSortOrderDirection] = React.useState("asc");
  const [openCreateMedicationModal, setCreateMedicationModal] = React.useState(false);

  React.useEffect(() => {
    search(page, size, filter, sortOrderBy, sortOrderDirection);
    initDropDowns();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setLoading(true);
    const timeOutId = setTimeout(async () => {
      await search(page, size, filter, sortOrderBy, sortOrderDirection);
    }, 1000);
    return () => clearTimeout(timeOutId);
  }, [filter, page, size, sortOrderBy, sortOrderDirection]);

  const initDropDowns = async () => {
    const response = await Promise.all([
      getMedicationUnits(),
      getDosageForms(),
      getRoutes(),
    ]);
    setUnits(response[0]);
    setDosageForms(response[1]);
    setRoutes(response[2]);
  };

  const createSortHandler = async (field) => {
    const sort = field;
    const sortDirection = sortOrderDirection === "asc" ? "desc" : "asc";
    setSortOrderBy(sort);
    setSortOrderDirection(sortDirection);
    // await search(page, size, filter, sort, sortDirection);
  };
  const filterTextChange = async (e) => {
    console.log(e.target);
    const obj = { ...filter };
    obj[e.target.name].value = e.target.value;
    if (
      String(obj[e.target.name].value).length === 0 ||
      String(obj[e.target.name].value).length >= obj[e.target.name].minValue
    ) {
      // await search(page, size, obj, sortOrderBy, sortOrderDirection);
    }
    setFilter(obj);
  };

  const filterSelectChange = async (e) => {
    const obj = { ...filter };
    obj[e.target.name].value = e.target.value;
    if (
      String(obj[e.target.name].value).length === 0 ||
      String(obj[e.target.name].value).length >= obj[e.target.name].minValue
    ) {
      // await search(page, size, obj, sortOrderBy, sortOrderDirection);
    }
    setFilter(obj);
  };

  const search = async (pg, sz, filt, sort, sortDirection) => {
    const filterObj = {...filt};
    Object.keys(filterObj).forEach(f => {
      if (filterObj[f].value === -1) {
        delete filterObj[f]
      }
    });
    const filters = convertToFilters(filterObj);
    const response = await searchMedications(
      pg,
      sz,
      filters,
      sort,
      sortDirection
    );
    setMedications(response.content);
    setSize(response.paginator.perPage);
    setPage(response.paginator.currentPage);
    setTotal(response.paginator.totalItems);
    setLoading(false);
  }

  const selectMedication = (medication) => {};



  const onChangeRowsPerPage = async (event) => {
    setSize(event.target.value);
    await search(
      page,
      event.target.value,
      filter,
      sortOrderBy,
      sortOrderDirection
    );
  };
  const handleChangePage = async (event, newPage) => {
    setPage(newPage + 1);
    // await search(newPage + 1, size, filter, sortOrderBy, sortOrderDirection);
  };

  const clearFilters = () => {
    const obj = JSON.parse(JSON.stringify(filterTemplate));
    setFilter(obj);
    search(1, 10, obj, "tradeName", "asc");
  };

  const medicationCreated = medication => {
    const obj = [...medications, medication];
    setMedications(obj);
    setCreateMedicationModal(false);
  }

  return (
    <Paper elevation={24} className={classes.root}>
      <Grid container justify="space-between" alignItems="center">
        <div>
          <Button onClick={() => setCreateMedicationModal(true)}>Create</Button>
          <CreateMedication open={openCreateMedicationModal} onClose={() => setCreateMedicationModal(false)} onSave={medicationCreated} />
          <Button onClick={clearFilters} startIcon={<HighlightOffIcon />}>
            Clear Filters
          </Button>
        </div>
        <TablePagination
          component="div"
          rowsPerPageOptions={sizeOptions}
          colSpan={5}
          count={total ? total : 0}
          rowsPerPage={size ? size : 0}
          page={page - 1}
          backIconButtonProps={{
            "aria-label": "previous page",
            disabled: page === 1 ? true : false,
          }}
          nextIconButtonProps={{
            "aria-label": "next page",
            disabled: page * size >= total ? true : false,
          }}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onChangePage={handleChangePage}
        ></TablePagination>
      </Grid>
      <TableContainer>
        <Table
          size="small"
          style={{ width: "100%" }}
          className={classes.table}
          id="medicationtable"
        >
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <SortFilterLabel label={"Name"}
                                 field={"tradeName"}
                                 filter={filter}
                                 sortOrderBy={sortOrderBy}
                                 sortDirection={sortOrderDirection}
                                 createSortHandler={createSortHandler}
                                 filterTextChange={filterTextChange} />
              </TableCell>
              <TableCell>
                <SortFilterLabel label={"Generic Name"}
                                 field={"genericName"}
                                 filter={filter}
                                 sortOrderBy={sortOrderBy}
                                 sortDirection={sortOrderDirection}
                                 createSortHandler={createSortHandler}
                                 filterTextChange={filterTextChange} />
              </TableCell>
              <TableCell>
                <SortFilterLabel label={"NDC Code"}
                                 field={"ndcCode"}
                                 filter={filter}
                                 sortOrderBy={sortOrderBy}
                                 sortDirection={sortOrderDirection}
                                 createSortHandler={createSortHandler}
                                 filterTextChange={filterTextChange} />
              </TableCell>
              <TableCell>
                <SortFilterLabel label={"Strength"}
                                 field={"strength"}
                                 filter={filter}
                                 sortOrderBy={sortOrderBy}
                                 sortDirection={sortOrderDirection}
                                 createSortHandler={createSortHandler}
                                 filterTextChange={filterTextChange} />
              </TableCell>
              <TableCell>
                <SortFilterSelectLabel label={"Unit"}
                                       field={"unit"}
                                       filter={filter}
                                       sortOrderBy={sortOrderBy}
                                       sortDirection={sortOrderDirection}
                                       createSortHandler={createSortHandler}
                                       options={units}
                                       optionLabel={"name"}
                                       optionValue={"_id"}
                                       onChange={filterSelectChange} />
              </TableCell>
              <TableCell>
                <SortFilterSelectLabel label={"Dosage Form"}
                                       field={"dosageForm"}
                                       filter={filter}
                                       sortOrderBy={sortOrderBy}
                                       sortDirection={sortOrderDirection}
                                       createSortHandler={createSortHandler}
                                       options={dosageForms}
                                       optionLabel={"name"}
                                       optionValue={"_id"}
                                       onChange={filterSelectChange} />
              </TableCell>
              <TableCell>
                <SortFilterSelectLabel label={"Route"}
                                       field={"route"}
                                       filter={filter}
                                       sortOrderBy={sortOrderBy}
                                       sortDirection={sortOrderDirection}
                                       createSortHandler={createSortHandler}
                                       options={routes}
                                       optionLabel={"name"}
                                       optionValue={"_id"}
                                       onChange={filterSelectChange} />
              </TableCell>
              <TableCell>
                <SortFilterSelectLabel label={"Is Supply"}
                                       field={"isSupply"}
                                       filter={filter}
                                       sortOrderBy={sortOrderBy}
                                       sortDirection={sortOrderDirection}
                                       createSortHandler={createSortHandler}
                                       options={filter?.isSupply.dropDownItems}
                                       optionLabel={"name"}
                                       optionValue={"value"}
                                       onChange={filterSelectChange} />
              </TableCell>
              <TableCell>
                <SortFilterSelectLabel label={"Is Narcotic"}
                                       field={"isNarcotic"}
                                       filter={filter}
                                       sortOrderBy={sortOrderBy}
                                       sortDirection={sortOrderDirection}
                                       createSortHandler={createSortHandler}
                                       options={filter?.isNarcotic.dropDownItems}
                                       optionLabel={"name"}
                                       optionValue={"value"}
                                       onChange={filterSelectChange} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ width: "100%" }}>
            {loading && [1,2,3,4,5,6,7,8].map(i =>
              <TableRow>
                <TableCell>
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
              </TableRow>
            )}
            {!loading &&
              medications?.map((medication, i) =>
                  <TableRow key={i} style={{ ...getStripedStyle(i) }}>
                    <TableCell>
                        <Button onClick={() => onSelectMedication(medication)}>Select</Button>
                    </TableCell>
                    <TableCell scope="row">
                      <Button
                        size="small"
                        onClick={() => selectMedication(medication)}
                      >
                        {medication.tradeName}
                      </Button>
                    </TableCell>
                    <TableCell>{medication.genericName}</TableCell>
                    <TableCell>{medication.ndcCode}</TableCell>
                    <TableCell>{medication.strength}</TableCell>
                    <TableCell>
                      {medication.unit ? medication.unit.name : null}
                    </TableCell>
                    <TableCell>
                      {medication.dosageForm
                        ? medication.dosageForm.name
                        : null}
                    </TableCell>
                    <TableCell>
                      {medication.route ? medication.route.name : null}
                    </TableCell>
                    <TableCell>{medication.isSupply ? "Yes" : "No"}</TableCell>
                    <TableCell>
                      {medication.isNarcotic ? "Yes" : "No"}
                    </TableCell>
                  </TableRow>
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default MedicationSearchComponent;
