import React from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox, FormLabel
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
  KeyboardDatePicker,
  KeyboardTimePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Alert from "@material-ui/lab/Alert";
import {AlertTitle} from "@material-ui/lab";
import moment from "moment";

const AssessmentQuestion = ({ question, style, onChange, disabled }) => {
  const MAX_LENGTH = 2000;
  const [current, setCurrent] = React.useState(question);

  React.useEffect(() => {
    if (question) {
      const obj = {...question};
      if (!obj?.value) {
        switch (obj?.type?.name) {
          case 'Date':
            obj.value = null;
            break;
          case 'DateTime':
            obj.value = null;
            break;
          case 'Time':
            obj.value = null;
            break;
          default:
            obj.value = ''
            break;
        }
      }
      setCurrent(obj);
    } else {
      setCurrent(null);
    }
  }, [question]);
  if (question === null) {
    return null;
  }

  const onDateChange = (date) => {
    const obj = { ...current };
    obj.value = date;
    if (obj?.required && !obj?.value) {
      obj.error = true;
    } else {
      obj.error = !moment(obj?.value).isValid();
    }
    setCurrent(obj);
    if (onChange) {
      onChange(obj);
    }
  }

  const onInputChange = value => {
    const obj = { ...current };
    obj.value = String(value);
    if (obj?.required && obj?.value === 'null') {
      obj.error = true;
    } else {
      obj.error = false;
    }
    setCurrent(obj);
    if (onChange) {
      onChange(obj);
    }
  };

  const onTextChange = e => {
    if (e?.target?.value?.length <= MAX_LENGTH) {
      const obj = { ...current };
      obj.value = e?.target?.value;
      setCurrent(obj);
      if (onChange) {
        onChange(obj);
      }
    }
  }

  const handleMultiChoiceChange = event => {
    if (event?.target?.checked) {
      const obj = {...current};
      let values = obj?.value?.split(', ');
      values = values?.filter(v => v.length > 0);
      if (values?.length >= 1) {
        values = [...values, event?.target?.name]
      } else {
        values = [ event?.target?.name ];
      }
      obj.value = values?.join(', ');
      setCurrent(obj);
      if (onChange) {
        onChange(obj);
      }
    } else {
      const obj = {...current};
      let values = obj?.value?.split(', ')?.filter(v => v !== event?.target?.name);
      obj.value = values?.join(', ');
      setCurrent(obj);
      if (onChange) {
        onChange(obj);
      }
    }
  }

  const isChecked = val => {
    return current?.value?.split(', ')?.filter(v => v?.length > 0)?.includes(val);
  }

  switch (current.type.name) {
    case "Number":
      return (
        <TextField
          key={question._id}
          disabled={disabled}
          type="number"
          name={String(current._id)}
          label={current.label}
          onChange={e => onInputChange(e.target.value)}
          style={style}
          required={current?.required}
          value={current.value || ""}
        />
      );
    case "Date":
      return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            fullWidth
            key={question._id}
            disabled={disabled}
            label={current.label}
            InputLabelProps={{ shrink: true }}
            onChange={(date) => onDateChange(date)}
            allowKeyboardControl="true"
            ampm={false}
            name={String(current._id)}
            required={current?.required}
            format="MM/DD/YYYY"
            value={current?.value}
            error={current?.error}
            helperText={current?.error ? `${current?.label} is required.` : null}
          />
        </MuiPickersUtilsProvider>
      );
    case "DateTime":
      return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDateTimePicker
            fullWidth
            key={question._id}
            disabled={disabled}
            label={current.label}
            InputLabelProps={{ shrink: true }}
            onChange={(date) => onDateChange(date)}
            allowKeyboardControl="true"
            mask="__/__/____ __:__ _m"
            format="MM/DD/YYYY hh:mm A"
            value={current?.value}
            name={String(current._id)}
            required={current?.required}
            error={current?.error}
            helperText={current?.error ? `${current?.label} is required.` : null}
          />
        </MuiPickersUtilsProvider>
      );
    case "Time":
      return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardTimePicker
            fullWidth
            key={question._id}
            disabled={disabled}
            label={current.label}
            InputLabelProps={{ shrink: true }}
            onChange={(date) => onDateChange(date)}
            mask="__:__ _M"
            allowKeyboardControl="true"
            value={current?.value}
            name={String(current._id)}
            required={current?.required}
            error={current?.error}
            helperText={current?.error ? `${current?.label} is required.` : null}
          />
        </MuiPickersUtilsProvider>
      );
    case "Boolean":
      return (
        <div>
          <FormControlLabel
            key={question._id}
            control={
              <Checkbox
                disabled={disabled}
                name={String(current._id)}
                color="primary"
                type="checkbox"
                checked={current.value === "true" ? true : false}
              />
            }
            label={current.label}
            labelPlacement="end"
            onChange={e => onInputChange(e.target.checked)}
            name={String(current._id)}
          />
        </div>
      );
    case "Option":
      return (
        <FormControl key={question._id}>
          <InputLabel id="select-label">{current.label}</InputLabel>
          <Select
            disabled={disabled}
            labelId="select-label"
            id="simple-select"
            onChange={e => onInputChange(e.target.value)}
            name={String(current._id)}
            value={current.value || ""}
          >
            {current.options &&
              current.options.map((option, i) => (
                <MenuItem key={`option-${i}`} value={option.value || ""}>{option.label}</MenuItem>
              ))}
          </Select>
        </FormControl>
      );
    case "Multiple Choice":
      return (
        <FormControl key={question._id}>
          <FormControl required={current?.required}>
            <FormLabel component={"legend"}>{current?.label}</FormLabel>
            {current?.options?.map(option =>
              <FormControlLabel
                control={<Checkbox checked={isChecked(option?.value)} onChange={handleMultiChoiceChange} name={option?.value} />}
                label={`${option?.label}`}
              />
            )}
          </FormControl>
        </FormControl>
      )
    default:
      return (
        <>
          <TextField
            key={question._id}
            disabled={disabled}
            label={current.label}
            fullWidth
            onChange={onTextChange}
            name={String(current._id)}
            value={current.value || ""}
            inputProps={{ maxLength: MAX_LENGTH }}
            multiline={true}
            maxRows={10}
          />
          {(current?.value?.length / MAX_LENGTH) > .75 &&
            <Alert severity={(current?.value?.length / MAX_LENGTH) === 1 ? "warning": "info"}>
              <AlertTitle>Character Limit {(current?.value?.length / MAX_LENGTH) === 1 ? "Met": null}</AlertTitle>
              You have entered {current?.value?.length ?? 0} of {MAX_LENGTH ?? 0} limit.
            </Alert>
          }
        </>
      );
  }
};

export default AssessmentQuestion;
