import React from 'react';
import {
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  TextField,
  FormGroup,
  FormControl,
  InputLabel,
  DialogTitle,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Transition from '../../../dialog/transition';
import IncidentModel from './incident.model';
import { isModelValid } from '../../../../utils/forms';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ChipInput from 'material-ui-chip-input';
import { ObjectId } from 'bson';
import moment from 'moment';
import {UserContext} from "../../../../context/usercontext";

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: '4rem',
  },
}));

const notificationModel = {
  type: null,
  name: null,
  date: null,
};

const CreateIncidentLog = ({ open, date, onSave, onClose }) => {
  const classes = useStyles();
  const [viewNotification, setViewNotification] = React.useState(false);
  const [users, setUsers] = React.useState();
  const [editNotification, setEditNotification] = React.useState(
    JSON.parse(JSON.stringify(notificationModel))
  );
  const [model, setModel] = React.useState(
    JSON.parse(JSON.stringify(IncidentModel))
  );
  const {getUsers, user} = React.useContext(UserContext);

  React.useEffect(() => {
    const setDefaultDate = async () => {
      const obj = JSON.parse(JSON.stringify(IncidentModel));
      obj.date.dirty = false;
      obj.date.value = Date.now();
      if (!users) {
        const response= await getUsers();
        obj.staff.dropDownItems = response;
        setUsers(response)
      } else {
        obj.staff.dropDownItems = users;
      }
      obj.staff.value = user?._id;
      setModel(obj);
    };

    if (open) {
      setDefaultDate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onCreate = () => {
    const log = {
      type: 'incident',
      incidentDate: model.incidentDate.value,
      date: model.date.value,
      category: model.category.value,
      location: model.location.value,
      staff: model.staff.value,
      nature: model.nature.value,
      description: model.description.value,
      witness: model.witness.value,
      conditionBefore: model.conditionBefore.value,
      conditionAfter: model.conditionAfter.value,
      evaluationOfPlan: model.evaluationOfPlan.value,
      notifications: model.notifications.value,
    };
    onSave(log);
  };

  const onDateChange = (date, field) => {
    const obj = { ...model };
    obj[field].value = date;
    setModel(obj);
  };
  const handleAdd = (...chips) => {
    const obj = { ...model };
    obj.category.value = [...obj.category.value, ...chips];
    setModel(obj);
  };

  const handleDelete = (deletedChip) => {
    const obj = { ...model };
    obj.category.value = obj.category.value.filter((c) => c !== deletedChip);
    setModel(obj);
  };

  const handleChange = (e) => {
    const obj = { ...model };
    obj[e.target.name].value = e.target.value;
    setModel(obj);
  };

  const onCreateNotification = () => {
    const obj = JSON.parse(JSON.stringify(notificationModel));
    obj.date = Date.now();
    setEditNotification(obj);
    setViewNotification(true);
  };

  const onEditChange = (e) => {
    const obj = { ...editNotification };
    obj[e.target.name] = e.target.value;
    setEditNotification(obj);
  };

  const onEditNotficationDateChange = (date) => {
    const obj = { ...editNotification };
    obj.date = date;
    setEditNotification(obj);
  };

  const upsertNotification = () => {
    const obj = { ...model };
    if (!editNotification._id) {
      editNotification._id = new ObjectId();
      obj.notifications.value = [...obj.notifications.value, editNotification];
    } else {
      obj.notifications.value = obj.notifications.map((n) =>
        n._id === editNotification._id ? editNotification : n
      );
    }
    setModel(obj);
    setViewNotification(false);
  };

  const onViewNotifiction = (e) => {
    setEditNotification(e);
    setViewNotification(true);
  };

  const onRemoveNotification = (notification) => {
    const obj = { ...model };
    obj.notifications.value = obj.notifications.value.filter(
      (nt) => nt._id !== notification._id
    );
    setModel(obj);
    setViewNotification(false);
  };

  const onStaffChange = e => {
    const obj = {...model};
    obj.staff.value = e.target.value;
    setModel(obj);
  }

  return (
    <Dialog open={open} fullScreen TransitionComponent={Transition}>
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => onClose()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Enter Incident Log
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.content}>
        <form noValidate>
          <FormGroup>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDateTimePicker
                size="small"
                fullWidth
                name="date"
                required={model.date.required}
                value={model.date.value}
                label={model.date.title}
                format="MM/DD/YYYY hh:mm a"
                onChange={(date) => onDateChange(date, 'date')}
                allowKeyboardControl="true"
                helperText={
                  model.date.required && model.date.value === ''
                    ? `${model.date.title} is required!`
                    : null
                }
                error={!model.date.isValid && model.date.isDirty}
              />
              <KeyboardDateTimePicker
                size="small"
                fullWidth
                name="incidentDate"
                required={model.incidentDate.required}
                value={model.incidentDate.value}
                label={model.incidentDate.title}
                format="MM/DD/YYYY hh:mm a"
                onChange={(date) => onDateChange(date, 'incidentDate')}
                allowKeyboardControl="true"
                helperText={
                  model.incidentDate.required && model.incidentDate.value === ''
                    ? `${model.incidentDate.title} is required!`
                    : null
                }
                error={
                  !model.incidentDate.isValid && model.incidentDate.isDirty
                }
              />
            </MuiPickersUtilsProvider>
            <ChipInput
              label="Category"
              fullWidth
              required
              value={model.category.value}
              onAdd={(chip) => handleAdd(chip)}
              onDelete={(deletedChip) => handleDelete(deletedChip)}
            />

            <FormControl>
              <InputLabel shrink>Staff</InputLabel>
              <Select fullWidth
                      displayEmpty
                      onChange={onStaffChange}
                      value={model?.staff?.value}
                      >
                <MenuItem>Staff</MenuItem>
                {users?.map(user => <MenuItem value={user?._id}>{user?.firstName} {user?.lastName}</MenuItem>)}
              </Select>
            </FormControl>


            <TextField
              size="small"
              fullWidth
              name="location"
              autoComplete="off"
              type={model.location.textType || 'text'}
              required={model.location.required}
              label={model.location.title}
              value={model.location.value || ''}
              multiline={model.location.multiline}
              rows={model.location.multilineRows}
              helperText={
                model.location.required && model.location.value === ''
                  ? `${model.location.title} is required!`
                  : null
              }
              error={!model.location.isValid && model.location.isDirty}
              onChange={handleChange}
            />
            <TextField
              size="small"
              fullWidth
              name="nature"
              autoComplete="off"
              type={model.nature.textType || 'text'}
              required={model.nature.required}
              label={model.nature.title}
              value={model.nature.value || ''}
              multiline={model.nature.multiline}
              rows={model.nature.multilineRows}
              helperText={
                model.nature.required && model.nature.value === ''
                  ? `${model.nature.title} is required!`
                  : null
              }
              error={!model.nature.isValid && model.nature.isDirty}
              onChange={handleChange}
            />
            <TextField
              size="small"
              fullWidth
              name="description"
              autoComplete="off"
              type={model.description.textType || 'text'}
              required={model.description.required}
              label={model.description.title}
              value={model.description.value || ''}
              multiline={true}
              minRows={5}
              helperText={
                model.description.required && model.description.value === ''
                  ? `${model.description.title} is required!`
                  : null
              }
              error={!model.description.isValid && model.description.isDirty}
              onChange={handleChange}
            />
            <TextField
              size="small"
              fullWidth
              name="witness"
              autoComplete="off"
              type={model.witness.textType || 'text'}
              required={model.witness.required}
              label={model.witness.title}
              value={model.witness.value || ''}
              multiline={model.witness.multiline}
              rows={model.witness.multilineRows}
              helperText={
                model.witness.required && model.witness.value === ''
                  ? `${model.witness.title} is required!`
                  : null
              }
              error={!model.witness.isValid && model.witness.isDirty}
              onChange={handleChange}
            />
            <TextField
              size="small"
              fullWidth
              name="evaluationOfPlan"
              autoComplete="off"
              type={model.evaluationOfPlan.textType || 'text'}
              required={model.evaluationOfPlan.required}
              label={model.evaluationOfPlan.title}
              value={model.evaluationOfPlan.value || ''}
              multiline={model.evaluationOfPlan.multiline}
              rows={model.evaluationOfPlan.multilineRows}
              helperText={
                model.evaluationOfPlan.required &&
                model.evaluationOfPlan.value === ''
                  ? `${model.witness.title} is required!`
                  : null
              }
              error={
                !model.evaluationOfPlan.isValid &&
                model.evaluationOfPlan.isDirty
              }
              onChange={handleChange}
            />
            <TextField
              size="small"
              fullWidth
              name="conditionBefore"
              autoComplete="off"
              type={model.conditionBefore.textType || 'text'}
              required={model.conditionBefore.required}
              label={model.conditionBefore.title}
              value={model.conditionBefore.value || ''}
              multiline={model.conditionBefore.multiline}
              rows={model.conditionBefore.multilineRows}
              helperText={
                model.conditionBefore.required &&
                model.conditionBefore.value === ''
                  ? `${model.conditionBefore.title} is required!`
                  : null
              }
              error={
                !model.conditionBefore.isValid && model.conditionBefore.isDirty
              }
              onChange={handleChange}
            />
            <TextField
              size="small"
              fullWidth
              name="conditionAfter"
              autoComplete="off"
              type={model.conditionAfter.textType || 'text'}
              required={model.conditionAfter.required}
              label={model.conditionAfter.title}
              value={model.conditionAfter.value || ''}
              multiline={model.conditionAfter.multiline}
              rows={model.conditionAfter.multilineRows}
              helperText={
                model.conditionAfter.required &&
                model.conditionAfter.value === ''
                  ? `${model.conditionAfter.title} is required!`
                  : null
              }
              error={
                !model.conditionAfter.isValid && model.conditionAfter.isDirty
              }
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <FormControl>
              <InputLabel shrink>Notifications</InputLabel>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Button onClick={onCreateNotification}>Add</Button>
                      </TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {model.notifications.value &&
                      model.notifications.value.map((notification) => (
                        <TableRow>
                          <TableCell>
                            <Button
                              onClick={() => onViewNotifiction(notification)}
                            >
                              View
                            </Button>
                            <Button
                              onClick={() => onRemoveNotification(notification)}
                            >
                              Remove
                            </Button>
                          </TableCell>
                          <TableCell>{notification.type}</TableCell>
                          <TableCell>{notification.name}</TableCell>
                          <TableCell>
                            {moment(notification.date).format(
                              'MM/DD/YYYY hh:mm a'
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Dialog
                maxWidth="lg"
                open={viewNotification}
                fullWidth
                onClose={() => setViewNotification(false)}
              >
                <DialogTitle>Notification</DialogTitle>
                <DialogContent>
                  <form noValidate>
                    <FormGroup>
                      <FormControl>
                        <InputLabel required shrink>
                          Type
                        </InputLabel>
                        <Select
                          name="type"
                          value={editNotification.type}
                          onChange={onEditChange}
                          fullWidth
                        >
                          {[
                            'Physician',
                            'Nurse',
                            'Administrator',
                            'Family member',
                          ].map((type) => (
                            <MenuItem value={type}>{type}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <TextField
                        name="name"
                        fullWidth
                        label="Name"
                        required
                        onChange={onEditChange}
                        value={editNotification.name}
                      />
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDateTimePicker
                          size="small"
                          fullWidth
                          name="editNotification"
                          required
                          value={editNotification.date}
                          label="Date"
                          format="MM/DD/YYYY hh:mm a"
                          onChange={onEditNotficationDateChange}
                          allowKeyboardControl="true"
                        />
                      </MuiPickersUtilsProvider>
                    </FormGroup>
                  </form>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setViewNotification(false)}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={upsertNotification}
                  >
                    Save
                  </Button>
                </DialogActions>
              </Dialog>
            </FormControl>
          </FormGroup>
        </form>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onCreate}
          disabled={!isModelValid(model)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateIncidentLog;
