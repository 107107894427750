import React from "react";
import { useAuth0 } from "../../../react-auth0-spa";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  TextField,
  FormGroup,
  Button,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import RefreshIcon from "@material-ui/icons/Refresh";
import AddIcon from "@material-ui/icons/Add";
import Config from "../../../utils/configs";
import axios from "axios";

const Role = ({entity}) => {
  return <div>
    {entity && entity.tenantLocationRole && 
      <span>{entity.tenantLocationRole.name}</span>
    }
  </div>
      
}

const UsersList = () => {
  const [users, setUsers] = React.useState([]);
  const { getTokenSilently } = useAuth0();
  const [firstName, setFirstName] = React.useState();
  const [lastName, setLastName] = React.useState();
  const [email, setEmail] = React.useState();
  const [userName, setUserName] = React.useState();
  const [showProcessing, setShowProcessing] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [auth0Data, setAuth0Data] = React.useState();
  const [errorMessage, setErrorMessage] = React.useState();
  const [viewAuthDetails, setViewAuthDetails] = React.useState();
  const [selectedUser, setSelectedUser] = React.useState();
  const [addNew, setAddNew] = React.useState(false);
  React.useEffect(() => {
    callApi();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  const callApi = async () => {
    try {
      setSelectedUser(null);
      setUsers([]);
      const token = await getTokenSilently();

      const response = await axios.get(
        `http://localhost:8080/api/v1.0/users`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );


      if (response && response.data) {
        setUsers(response.data.content);
      } else {
        setUsers([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createNew = async () => {
    setShowProcessing(true);
    setShowError(false);
    const token = await getTokenSilently();
    try {
    const value = await axios.post(
      `http://localhost:8080/api/v1.0/users`,
      { firstName, lastName, email, userName },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    if (value.data) {
      const obj = [...users, value.data];
      setUsers(obj);
    }
    } catch(err) {
      
      console.log(err);
      setShowProcessing(false);
      if (err && err.response && err.response.data && err.response.data.message) {
        setErrorMessage(err.response.data.message);
      } else {
        setErrorMessage(err.message);
      }      
      setShowError(true);
    }
  };

  const updateTenant = e => {
    const obj = { ...selectedUser };
    if (!obj.user_metadata) {
      obj.user_metadata = {};
    }
    obj.user_metadata.tenant = e.target.value;
    setSelectedUser(obj);
  };

  const updateUser = async () => {
    const token = await getTokenSilently();
    const value = await axios.put(
      `${Config.baseApiUrl}/assessment-service/api/v1.0/users/${selectedUser.user_id}`,
      { tenantId: selectedUser["user_metadata" || {}].tenant },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    if (value.data) {
      const obj = users.map(user => {
        if (user.id === selectedUser.id) {
          return value.data;
        }
        return user;
      });
      setUsers(obj);
    }
  };

  const block = async () => {
    const token = await getTokenSilently();
    let url = `${Config.baseApiUrl}/assessment-service/api/v1.0/users/${selectedUser.user_id}/`;
    url += selectedUser.blocked ? "unblock" : "block";
    const value = await axios.put(
      url,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    if (value.data) {
      const obj = users.map(user => {
        if (user.id === selectedUser.id) {
          return value.data;
        }
        return user;
      });
      setUsers(obj);
      setSelectedUser(value.data);
    }
  };
  
  const viewAuthInfo = async user => {
    setViewAuthDetails(false);
    try {
      const token = await getTokenSilently();
      const userInfo = await axios.get(`http://localhost:8080/api/v1.0/users/${user.id}`, { headers: { Authorization: `Bearer ${token}` }});
      setAuth0Data(userInfo.data);
      setViewAuthDetails(true); 
    } catch (err) {
      setAuth0Data(null);
    }
  }

  return (
    <div>
      <div style={{ marginBottom: "1rem", display: "flex" }}>
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={callApi}
          startIcon={<RefreshIcon />}
        >
          Reload Users
        </Button>
        <Button
          style={{ marginLeft: "1rem" }}
          variant="contained"
          size="large"
          color="primary"
          onClick={() => setAddNew(addNew => !addNew)}
          startIcon={<AddIcon />}
        >
          Create New
        </Button>
      </div>
      <Paper>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Roles</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {users &&
              users.map(user => (
                <TableRow>
                  <TableCell>{user.firstName} {user.lastName}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    { user.tenantLocationRoles && user.tenantLocationRoles.map(t => <Role entity={t} />)}
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => viewAuthInfo(user)}>View Auth Integration</Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
      {addNew && (
        <ExpansionPanel style={{ marginTop: "1rem" }} defaultExpanded="true">
          <ExpansionPanelSummary>CREATE USER</ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <form style={{ width: "100%" }}>
              <FormGroup>
                <TextField
                  fullWidth
                  label="First Name"
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Last Name"
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
                <TextField
                  fullWidth
                  label="User Name"
                  value={userName}
                  onChange={e => setUserName(e.target.value)}
                />
              </FormGroup>
              <Button color="primary" onClick={createNew}>
                Create
              </Button>
              <div hidden={!showError}>
                <Alert severity="error">{errorMessage}</Alert>
              </div>
            </form>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
      {selectedUser && (
        <ExpansionPanel defaultExpanded="true" style={{ marginTop: "1rem" }}>
          <ExpansionPanelSummary>Edit User</ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <form style={{ width: "100%" }}>
              <FormGroup>
                <TextField fullWidth label="Name" value={selectedUser.name} />
                <TextField
                  fullWidth
                  label="Tenant"
                  value={(selectedUser.user_metadata || {}).tenant || ""}
                  onChange={updateTenant}
                />
              </FormGroup>
              <FormGroup row style={{ justifyContent: "flex-end" }}>
                <Button
                  color="primary"
                  onClick={updateUser}
                  variant="contained"
                >
                  Save
                </Button>
                <Button color="secondary" onClick={block} variant="contained">
                  {selectedUser.blocked && (
                    <Typography variant="subtitle1">Unblock User</Typography>
                  )}
                  {!selectedUser.blocked && (
                    <Typography variant="subtitle1">Block User</Typography>
                  )}
                </Button>
              </FormGroup>
            </form>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
      <Dialog 
        open={showProcessing}
        onClose={() => setShowProcessing(false)}
        aria-labelledby="processing-dialog-title"
        aria-describedby="processing-dialog-description">
        <DialogTitle id="processing-dialog-title">{"Creating User"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="error-dialog-description">
            {"Creating User"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowProcessing(false)} color="primary" autoFocus>
             Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog 
        fullWidth="true"
        maxWidth="md"
        open={viewAuthDetails}
        onClose={() => setViewAuthDetails(false)}
        aria-labelledby="auth-details-dialog-title"
        aria-describedby="auth-details-dialog-description">
          <DialogTitle id="auth-details-dialog-title">
                    {"User Details"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="auth-details-dialog-description">
                    {auth0Data && JSON.stringify(auth0Data.authUser)}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setViewAuthDetails(false)} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
    </div>
  );
};

export default UsersList;
