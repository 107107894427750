import React from 'react';
import {
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  FormGroup,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Transition from '../../../dialog/transition';
import createActivityModel from './activity.model';
import { isModelValid } from '../../../../utils/forms/form.validator';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: '4rem',
  },
}));

const CreateActivityLog = ({ open, date, onSave, onClose }) => {
  const classes = useStyles();
  const [model, setModel] = React.useState(
    JSON.parse(JSON.stringify(createActivityModel))
  );

  React.useEffect(() => {
    const setDefaultDate = () => {
      const obj = { ...model };
      obj.date.dirty = false;
      obj.date.value = Date.now();
      setModel(obj);
    };

    if (open) {
      setDefaultDate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onModelChange = (e) => {
    const obj = { ...model };
    obj[e.target.name].dirty = true;
    obj[e.target.name].value = e.target.value;
    if (e.target.name === 'cashUsed') {
      obj.cashAmount.required = e.target.value === 'yes';
    }
    if (e.target.name === 'refusal') {
      obj.reason.required = e.target.value === 'yes';
    }
    setModel(obj);
  };

  const onDateChange = (e) => {
    const obj = { ...model };
    obj.date.dirty = true;
    obj.date.value = e;
    setModel(obj);
  };

  const onCreate = () => {
    const log = {
      type: 'activity',
      title: model.title.value,
      description: model.description.value,
      date: model.date.value,
      duration: model.duration.value,
      attendies: model.attendies.value,
      cashUsed: model.cashUsed.value,
      cashAmount: model.cashAmount.value,
      refusal: model.refusal.value,
      reason: model.reason.value,
    };
    onSave(log);
  };

  return (
    <Dialog open={open} fullScreen TransitionComponent={Transition}>
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => onClose()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Enter Activity Log
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.content}>
        <form noValidate>
          <FormGroup>
            <TextField
              label="Title"
              name="title"
              required={true}
              value={model.title.value}
              onChange={onModelChange}
              error={!model.title.value && model.title.dirty}
              helperText={
                !model.title.value && model.title.dirty
                  ? `Title is required`
                  : null
              }
            />
            <TextField
              label="Description"
              name="description"
              required={true}
              value={model.description.value}
              onChange={onModelChange}
              error={!model.description.value && model.description.dirty}
              helperText={
                !model.description.value && model.description.dirty
                  ? `Description is required`
                  : null
              }
            />
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                size="small"
                fullWidth
                name="date"
                required={true}
                value={model.date.value}
                label={'Date'}
                format="MM/DD/YYYY"
                onChange={onDateChange}
                allowKeyboardControl="true"
                ampm={false}
                helperText={
                  model.date.dirty && model.date.value === ''
                    ? `Date is required!`
                    : null
                }
                error={!model.date.isValid && model.date.dirty}
              />
            </MuiPickersUtilsProvider>
            <TextField
              label="Duration"
              name="duration"
              value={model.duration.value}
              onChange={onModelChange}
            />
            <TextField
              label="Attendies"
              name="attendies"
              value={model.attendies.value}
              onChange={onModelChange}
            />
            <FormControl>
              <InputLabel id="cash-used-label">Cash/Credit Used</InputLabel>
              <Select
                labelId="cash-used-label"
                id="cash-used-select"
                value={model.cashUsed.value}
                name="cashUsed"
                onChange={onModelChange}
              >
                {model.cashUsed.dropDownItems &&
                  model.cashUsed.dropDownItems.map((item) => (
                    <MenuItem value={item.value}>{item.label}</MenuItem>
                  ))}
              </Select>
            </FormControl>
            {model.cashUsed.value === 'yes' && (
              <TextField
                label="Cash/Credit amount"
                name="cashAmount"
                required={model.cashUsed.value === 'yes'}
                value={model.cashAmount.value}
                onChange={onModelChange}
                error={
                  model.cashUsed.value === 'yes' &&
                  !model.cashAmount.value &&
                  model.cashAmount.dirty
                }
                helperText={
                  model.cashUsed.value === 'yes' &&
                  !model.cashAmount.value &&
                  model.cashAmount.dirty
                    ? `Cash amount is required when yes is selected for cash used`
                    : null
                }
              />
            )}
            <FormControl>
              <InputLabel id="refusal-label">Refusal</InputLabel>
              <Select
                labelId="refusal-label"
                id="refusal-select"
                value={model.refusal.value}
                name="refusal"
                onChange={onModelChange}
              >
                {model.refusal.dropDownItems &&
                  model.refusal.dropDownItems.map((item) => (
                    <MenuItem value={item.value}>{item.label}</MenuItem>
                  ))}
              </Select>
            </FormControl>
            {model.refusal.value === 'yes' && (
              <TextField
                label="Refusal reason"
                name="reason"
                required={model.refusal.value === 'yes'}
                value={model.reason.value}
                onChange={onModelChange}
                error={
                  model.refusal.value === 'yes' &&
                  !model.reason.value &&
                  model.reason.dirty
                }
                helperText={
                  model.refusal.value === 'yes' &&
                  !model.reason.value &&
                  model.reason.dirty
                    ? `Reason is required when yes is selected for refusal`
                    : null
                }
              />
            )}
          </FormGroup>
        </form>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onCreate}
          disabled={!isModelValid(model)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateActivityLog;
