import React from 'react';
import {
  Accordion, AccordionDetails,
  AccordionSummary, Avatar, Button, Divider, makeStyles, Paper,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {AssessmentsContext} from "./assessments.context";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {onAddSectionAction, onSelectAssessmentObjectAction} from './assessment.actions';
import GroupQuestions from "./group.questions";

const useStyles = makeStyles((theme) => ({
  questionsContainer: {
    padding: theme.spacing(2, 0),
    width: '100%',
  },
  sectionNameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    background: theme.palette.grey[600],
    height: '2.5vh',
    width: '2.5vh',
    fontSize: '1rem'
  },
  sectionName: {
    marginLeft: theme.spacing(1)
  },
  selected: {
    backgroundColor: theme.palette.grey[300]
  }
}))


const QuestionGroups = ({canUpdate = false}) => {
  const classes = useStyles();
  const { state, dispatch } = React.useContext(AssessmentsContext);
  const [questionGroups, setQuestionGroups] = React.useState([]);

  React.useEffect(() => {
    setQuestionGroups(state?.assessment?.questionGroups);
  }, [state?.assessment?.questionGroups])

  const addSection = () => {
    onAddSectionAction(state.assessment, dispatch)
  }

  const onSelectSection = (evt, section) => {
    evt.stopPropagation();
    onSelectAssessmentObjectAction(section, dispatch);
  }


  return (
    <>
      {canUpdate &&
      <Paper elevation={24} style={{marginBottom: '.5rem'}}>
        <Button fullWidth
                variant={"outlined"}
                color={"primary"}
                onClick={addSection}
                startIcon={<AddIcon />}>
          Add Section
        </Button>
      </Paper>}
      {questionGroups?.sort((a, b) => a.sortOrder - b.sortOrder)?.map(group =>
        <Accordion elevation={24}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}
                            className={[state?.selectedObject?._id === group?._id ? classes.selected: null]}
          >
            <div onClick={(evt) => onSelectSection(evt, group)}
                 className={classes.sectionNameContainer}
            >
              <Avatar className={classes.avatar}>
                {group?.sortOrder}
              </Avatar>
              <span className={classes.sectionName}>{group?.name ? group.name: 'Untitled Section'}</span>
            </div>
          </AccordionSummary>
          <Divider />
          <AccordionDetails>
            <div className={classes.questionsContainer}>
              <GroupQuestions canUpdate={canUpdate} group={group} />
            </div>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  )
}

export default QuestionGroups;

