import React from 'react';
import {StepActions} from "../step.actions";
import {IntakeContext} from "../../context/intake.context";
import {useStepStyles} from "../styles";
import {useHistory} from "react-router-dom";
import {Divider, Typography} from "@material-ui/core";
import {blendObjects, setStepStatus, updateResidentAction} from "../../context/intake.actions";
import {BackgroundModel} from "./background.model";
import {FormFieldsGenerator} from "../../../../../../utils/forms";

export const BackgroundStep = () => {
  const { dispatch, state } = React.useContext(IntakeContext);
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(BackgroundModel)));
  const classes = useStepStyles();
  const history = useHistory();

  React.useEffect(() => {
    if (state?.resident) {
      const obj = blendObjects(state, JSON.parse(JSON.stringify(BackgroundModel)));
      setModel(obj);
    }
    // eslint-disable-next-line
  }, [state?.resident, state?.model]);

  const onChange = e => {
    setModel({...model, ...e});
  }

  const onSave = async () => {
    const data = {
      _id: state?.id,
      education: model?.education?.value,
      hobbies: model?.hobbies?.value,
      specialInterests: model?.specialInterests?.value || null,
      occupation: model?.occupation?.value,
      militaryHistory: model?.militaryHistory?.value
    }
    await updateResidentAction(dispatch, model, data);
    setStepStatus(dispatch, 'background', true);
    history.push(`/intake/${state.id}/address`);
  }

  return (
    <div>
      <div className={classes.titleContainer}>
        <Typography variant={"h4"}>
          Background
        </Typography>
        <Divider />
      </div>
      <div>
        <FormFieldsGenerator model={model} onChange={onChange} />
      </div>
      <div className={classes.btnContainer}>
        <StepActions onNext={onSave}
                     onPrev={() => history.push(`/intake/${state.id}/medical`)}
        />
      </div>
    </div>
  )
}
