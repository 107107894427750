import apiUtil from '../utils/api.utils';
import config from '../utils/configs';

const apiUrl = `${config.residentService}/api/v1.0/addresstypes/`;

export const getAllAddressTypes = async () => {
    try {
        const response = await apiUtil.client.get(apiUrl);
        return response.data;
    } catch (err) {
        alert(err);
        return [];
    }
}  