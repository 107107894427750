import React from 'react';
import {
    FormControl, InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow, TableSortLabel,
    OutlinedInput
} from "@material-ui/core";
import {
    getMedicationOrderHistories,
} from "../../../services/medication.order.history.service";
import {HistoryRow} from "./row";
import {RetryMessage} from "../../notification/retry.message";
import TableLoadingIndicator from "../../../utils/indicators/table.loading";
import {MedicationOrderHistoryModal} from "./medicationorder.history.modal";
import {convertToFilters} from "../../../utils/filter.utils";
import IconButton from "@material-ui/core/IconButton";
import {Clear} from "@material-ui/icons";

const filterTemplate = {
    action: {
        field: 'action',
        minValue: 3,
        value: null,
        operator: '*=*',
    },
}

export const MedicationOrderHistory = ({id}) => {
    const [totalItems, setTotalItems] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [size, setSize] = React.useState(25);
    const [error, setError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [viewHistoryModal, setViewHistoryModal] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState();
    const [sortBy, setSortBy] = React.useState('date');
    const [sortDirection, setSortDirection] = React.useState('desc');
    const [filter, setFilter] = React.useState(JSON.parse(JSON.stringify(filterTemplate)));

    React.useEffect(() => {
        if (id) {
            getData(id).then(() => {});
        }
    }, [id]);

    const getData = async (medicationOrderId,
                           pg = 1,
                           sz = 25,
                           filt = JSON.parse(JSON.stringify(filterTemplate)),
                           sort = "createdAt",
                           sortDir = "desc") => {
        setError(false);
        setLoading(true);
        const filters = convertToFilters(filt);
        const response = await getMedicationOrderHistories(medicationOrderId, pg, sz, filters, sort, sortDir);
        if (!response?.error) {
            setTotalItems(response?.paginator?.totalItems);
            setPage(response?.paginator?.currentPage);
            setSize(response?.paginator?.perPage);
            setData(response?.content);
        } else {
            setError(true);
        }
        setLoading(false);
    }

    const retry = async () => {
        await getData(id);
    }

    const onPageChange = async (e, newPage) => {
        await getData(id, newPage + 1, size);
    }

    const onRowsPerPageChange = async (e) => {
        await getData(id, 1, e.target.value);
    }

    const onRowSelected = row => {
        setSelectedRow(row);
        setViewHistoryModal(true);
    }

    const onFilterChange = async (event) => {
        const obj ={...filter};
        obj[event.target.name].value = event.target.value;
        setFilter(obj);
        if (
            String(obj[event.target.name].value).length === 0 ||
            String(obj[event.target.name].value).length >= obj[event.target.name].minValue
        ) {
            await getData(id, 1, 25, filter, sortBy, sortDirection);
        }
    }

    const createSortHandler = async (field) => {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        setSortBy(field);
        await getData(id, page, size, filter, field, sortDirection === 'asc' ? 'desc' : 'asc')
    }

    const clearFilters = async (field) => {
        const obj = {...filter};
        obj[field].value = null;
        setFilter(obj);
        await getData(id, page, size, obj, sortBy, sortDirection === 'asc' ? 'desc' : 'asc')
    }

    return (
        <TableContainer>
            <MedicationOrderHistoryModal open={viewHistoryModal}
                                         onClose={() => setViewHistoryModal(false)}
                                         historyRow={selectedRow}
            />
            <TablePagination count={totalItems}
                             component={"div"}
                             page={page - 1}
                             rowsPerPage={size}
                             onPageChange={onPageChange}
                             onRowsPerPageChange={onRowsPerPageChange} />
            <Table size={"small"} padding={"none"}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                active={sortBy === 'createdAt'}
                                direction={sortDirection}
                                onClick={() => createSortHandler('createdAt')}
                            >
                                Date
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <FormControl variant={"outlined"}>
                                <OutlinedInput
                                    id="standard-adornment-action"
                                    type={'text'}
                                    placeholder={"Action"}
                                    name={"action"}
                                    value={filter?.action?.value || ''}
                                    onChange={onFilterChange}
                                    disableUnderline={true}
                                    margin={"dense"}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => clearFilters('action')}
                                            >
                                                <Clear />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </TableCell>
                        <TableCell>User</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                {!error && loading && <TableLoadingIndicator rows={10} cols={4} bodyOnly={true} />}
                {!loading && error &&
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={4}>
                        <RetryMessage onRetry={retry}
                                      message={"Unable to load medication order history."}
                                      severity={"error"} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                }
                <TableBody>
                    {!loading && !error && data?.map((d, index) =>
                      <HistoryRow index={index} row={d} onSelected={onRowSelected} />)
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}
