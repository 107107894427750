import React from "react";
import {
  makeStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  Paper,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles(theme => ({
  root: {
    padding: '1rem',
    backgroundColor: theme.palette.grey[100]
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: ".75rem",
    flexDirection: "row"
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '.75rem'
  }
}));

const createSkeletons = (numberOfPanels) => {
    let skeletons = [];
    for(let i = 0; i < numberOfPanels; i++) {
        skeletons.push(
            <ExpansionPanel defaultExpanded="true">
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    style={{justifyContent: 'space-evenly'}}
                    >
                    <Skeleton width="25%" height={50} variant="text" />
                    <Skeleton width="45%" style={{marginLeft: '10%'}} variant="text" />
                </ExpansionPanelSummary>
            </ExpansionPanel>
        )
    }
    return skeletons;
}



export const LoadingIntake = ({ loading }) => {
  const classes = useStyles();
  const numberOfPanels = 5;
  return (
    <div hidden={!loading}>
      <Paper elevation={24} variant="elevation" className={classes.root} >
        <Typography variant="subtitle1" gutterBottom="true">Loading assessment</Typography>
      {createSkeletons(numberOfPanels)}
      </Paper>
    </div>
  );
};
