import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {TransferListColumn} from "./TransferListColumn";
import {intersection, not} from "./utils";
import { FastForward, FastRewind} from "@material-ui/icons";


const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    maxWidth: 500,
    minWidth: 250,
    height: "60vh",
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  category: {
    padding: theme.spacing(1)
  }
}));

export const TransferList = ({ choices, role, onSave }) => {
  const classes = useStyles();
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const [currentLeftChecked, setCurrentLeftChecked] = React.useState([]);
  const [currentRightChecked, setCurrentRightChecked] = React.useState([]);
  const leftChecked = intersection(currentLeftChecked, left);
  const rightChecked = intersection(currentRightChecked, right);

  React.useEffect(() => {
    if (choices && role) {
      const availables = choices?.filter(c => role?.permissions?.findIndex(p => p === c?.name) === -1)
      setLeft(availables ?? []);
      const current = choices?.filter(c => role?.permissions?.findIndex(p => p === c?.name) > -1);
      setRight(current ?? []);
    }
  }, [choices, role]);


  const handleCheckedRight = () => {
    const newPermissions = right.concat(leftChecked)
    setRight(newPermissions);
    setLeft(not(left, leftChecked));
    onSave({...role, permissions: newPermissions?.map(p => p?.name)})

  };

  const handleCheckedLeft = () => {
    const newPermissions = not(right, rightChecked);
    setRight(newPermissions);
    setLeft(left.concat(rightChecked));
    onSave({...role, permissions: newPermissions?.map(p => p?.name)})
  };

  const onLeftCheckedChanged = items => {
    setCurrentLeftChecked(items);
  }

  const onRightCheckedChanged = items => {
    setCurrentRightChecked(items);
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>
        <TransferListColumn title={"Available Permissions"}
                            items={left}
                            onCheckedChange={(checked) => onLeftCheckedChanged(checked)}
        />
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            <FastForward />
          </Button>
          <Button
            variant="outlined"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            <FastRewind />
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <TransferListColumn title={"Selected Permissions"}
                            items={right}
                            onCheckedChange={onRightCheckedChanged}
        />
      </Grid>
    </Grid>
  );
}
