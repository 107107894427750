import React from 'react';
import {Switch} from "react-router-dom";
import PrivateRoute from "../PrivateRoute";
import {CalendarsPage} from "./calendars.page";
import {CalendarProvider} from "./context/calendar.context";
import {UserContext} from "../../context/usercontext";

export const Calendars = () => {
  const { user } = React.useContext(UserContext);

  return (
    <CalendarProvider user={user}>
      <Switch>
        <PrivateRoute exact path={"/mycalendar"} component={CalendarsPage} />
      </Switch>
    </CalendarProvider>
  )
}
