import {Document, Page, View, Text, StyleSheet} from "@react-pdf/renderer"
import moment from 'moment-timezone';
import HeaderWithTenant from "../../../../../reports/pdf/components/headerwithtenant";
import ResidentCard from "../../../../../reports/pdf/components/residentCard";
import {getUniqueEventTemplates} from "../../../../../utils/table";
import {InitialLegend} from "./initials.legend";
import {StatusLegend} from "./status.legend";


const BORDER_COLOR = '#bfbfbf'
const BORDER_STYLE = 'solid'

const styles = StyleSheet.create({
  page: {
    padding: '0 0 85px 0'
  },
  headerSection: {
    backgroundColor: '#2196f3',
    color: '#fff',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '10vh'
  },
  mainContent: {
    backgroundColor: 'white',
    padding: '0 5px'
  },
  body: {
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    width: '100%',
    margin: "auto",
    flexDirection: "row"
  },
  tableCol1Header: {
    width: '125px',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol2Header: {
    width: '55px',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableColHeader: {
    width: '20px',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol1: {
    width: '125px',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol2: {
    width: '55px',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol: {
    width: '20px',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: 500
  },
  tableCell: {
    margin: 5,
    fontSize: 10
  },
  titleContainer: {
    paddingTop: '15px'
  },
  pageCount: {
    paddingLeft: '5px',
    paddingTop: '15px'
  },
  headerText: {
    fontSize: '20px'
  },
  bodyText: {
    fontSize: '8px'
  },
  centerAlign: {
    textAlign: 'center'
  },
  infoSection: {
    margin: '10 0'
  },
  infoText: {
    fontSize: '12px',
    padding: '3 0'
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    padding: '5px'
  },
  footerContainer: {
    marginTop: '5px',
    fontSize: '8px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  marContainer: {
    padding: '5px'
  },
  marHeaderText: {

  },
  dosageInsructionContainer: {
    paddingTop: '5px'
  },
  hrText: {
    minHeight: '25px',
    textAlign: 'center',
  },
  marValueCell: {
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    // height: "24px"
    paddingBottom: 2
  },
  discontinuedDate: {
    width: '100%',
    padding: '5px',
    backgroundColor: "#9e9e9e",
    color: '#fff'
  },
  alerts: {
    width: '100%',
    padding: '5px',
    backgroundColor: "#e57373",
    color: '#fff'
  },
  hideOverflow: {
    textOverflow: "ellipsis"
  },
  freqContainer: {
    minHeight: '10px',
    textAlign: 'center',
  },
  paddedTableCol: {
    paddingTop: '10px',
    width: '20px',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  approvedReviewedContainer: {
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    marginTop: 5,
    borderTopWidth: 1,
    borderBottomWidth: 0,
    padding: 1,
    fontSize: '8px',
  },
  approvedByContainer: {

  },
  reviewedByContainer: {

  },
  signatureBox: {
    marginTop: 10,
    height: 10,
    width: '100%',
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "black"
  },
  margin5Top: {
    marginTop: 5
  },
  title: {
    fontSize: 12,
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 2,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
  },
  detail: {
    fontSize: 8,
  }
});


const PrintableMarValues = ({key, dosageInstruction, day, timeZone, isPrn = false}) => {
  const everyXHourFrequencys = [ "QH", "Q2H", "Q3H", "Q4H", "Q6H", "Q8H","Q12H" ];
  const frequencyName = dosageInstruction?.frequency?.name;
  let isEveryXHoursFrequency = everyXHourFrequencys?.indexOf(frequencyName) > -1


  const getStatusLabel = status => {
    switch (status) {
      case 'Scheduled':
        return ' ';
      case 'Given':
        return 'G';
      case 'Missed':
        return 'M'
      case 'Refused':
        return 'R';
      case 'Hold':
        return 'H';
      case 'Refused & Destructed':
      case 'RD':
        return 'RD';
      case 'Self Administered':
      case 'SA':
        return 'SA';
      case 'Not Administered':
      case 'NA':
        return 'NA';
      case 'Late':
        return 'L'
      case 'Assist':
        return 'A';
      case 'Supervise':
        return 'S';
      default:
        return ' '
    }
  }


  const getPrnValue = () => {
    const events = dosageInstruction?.events?.filter(event => event?.status?.name &&
      event?.status?.name !== "Scheduled" &&
      moment(event.administeredTimestamp).tz(timeZone).date() === day);
    if (events?.length > 0) {
      return (
        <View style={[styles.marValueCell]}>
          {events?.map(event =>
            <View>
              <Text style={[styles.bodyText]}>{getStatusLabel(event?.status?.name)}</Text>
              {event?.administeredUser?.initials &&
              <Text style={[styles.bodyText]}>{event.administeredUser.initials}</Text>
              }
              {!event?.administeredUser?.initials &&
              <Text style={styles.bodyText}> </Text>
              }
              {event?.administeredTimestamp &&
              <Text style={styles.bodyText}>{moment(event.administeredTimestamp).tz(timeZone).format('k:mm')}</Text>
              }

            </View>
          )}
        </View>
      )
    }
  }

  const getValue = (hour, minute) => {
    let events = [];
    if (isEveryXHoursFrequency) {
      events = dosageInstruction?.events?.filter(event => moment(event.scheduledTime).tz(timeZone).date() === day);
    } else {
      events = dosageInstruction?.events?.filter(event =>
        moment(event.scheduledTime).tz(timeZone).date() === day &&
        moment(event.scheduledTime).tz(timeZone).hour() === +hour &&
        moment(event.scheduledTime).tz(timeZone).minute() === +minute
      );
    }


    if (events && events.length === 1) {
      return (
        <View style={[styles.marValueCell, {display: 'block', flexDirection: 'column'}]}>
          <Text style={styles.bodyText}>{getStatusLabel(events[0]?.status?.name)}</Text>
          {events[0]?.administeredUser?.initials &&
          <Text style={styles.bodyText}>{events[0].administeredUser.initials}</Text>
          }
          {!events[0]?.administeredUser?.initials &&
          <Text style={styles.bodyText}> </Text>
          }
        </View>
      )
    } else if (events?.length > isEveryXHoursFrequency) {
      return (
        <View>
          {events?.map(event =>
            <View style={[styles.marValueCell, {
              display: 'block',
              flexDirection: 'column',
              borderBottom: '1px solid gray',
              marginBottom: 2
            }]}>
              <Text style={styles.bodyText}>{getStatusLabel(events[0]?.status?.name)}</Text>
              <Text style={styles.bodyText}>{moment(event?.scheduledTime).format("H:mm")}</Text>
              {events[0]?.administeredUser?.initials &&
                <Text style={styles.bodyText}>{events[0].administeredUser.initials}</Text>
              }
              {!events[0]?.administeredUser?.initials &&
                <Text style={styles.bodyText}> </Text>
              }
            </View>
          )}
        </View>
      )
    } else if(!isPrn) {
      return (
        <View style={[styles.marValueCell, {display: 'block', flexDirection: 'column'}]}>
          <Text style={styles.bodyText}>X</Text>
          <Text style={styles.bodyText}> </Text>
        </View>)
    }
  }

  return (
    <View key={key} style={styles.dosageInsructionContainer}>
      {!isPrn &&  dosageInstruction?.eventTemplates?.map((template, i) =>
        <View key={i} style={styles.hrText}>
          {getValue(template?.hour, template?.minute, isPrn)}
        </View>
      )}
      {isPrn &&
      <View style={styles.hrText}>
        {getPrnValue()}
      </View>
      }
    </View>)
}

const getEventTemplateDisplayForDosageInstruction = (dosageInstruction, template) => {
  switch (dosageInstruction?.frequency?.name) {
    case "QH":
      return "Every Hour";
    case "Q2H":
      return "Every 2 Hours";
    case "Q3H":
      return "Every 3 Hours";
    case "Q4H":
      return "Every 4 Hours";
    case "Q6H":
      return "Every 6 Hours";
    case "Q8H":
      return "Every 8 Hours";
    case "Q12H":
      return "Every 12 Hours";
    default:
      return `${template?.hour}:${template?.minute}`;
  }
}


const PrintableDosageInstruction = ({dosageInstruction}) => {
  const everyXHourFrequencys = [ "QH", "Q2H", "Q3H", "Q4H", "Q6H", "Q8H","Q12H" ];
  const frequencyName = dosageInstruction?.frequency?.name;
  let isEveryXHoursFrequency = everyXHourFrequencys?.indexOf(frequencyName) > -1

  return (
    <View style={styles.dosageInsructionContainer}>
      <View style={styles.freqContainer}>
        <Text style={styles.bodyText}>
          {dosageInstruction?.frequency?.name}
        </Text>
      </View>
      {getUniqueEventTemplates(dosageInstruction?.eventTemplates)?.map((template, i) =>
        <View key={i} style={styles.hrText} >
          <Text style={[styles.bodyText]}>
            {isEveryXHoursFrequency ?
              getEventTemplateDisplayForDosageInstruction(dosageInstruction, template):
              `${template.hour}:${template.minute} Qty: ${template.passingQty}`
            }
          </Text>
        </View>
      )}
    </View>)
}


const MarRow = ({resident, medicationOrder, days, isPrn = false}) => {

  const getInfo = () => {
    const medication = medicationOrder?.medication;
    let info = medication?.tradeName || medication?.genericName;
    if (medication?.unit) {
      info += `\n${medication?.strength || null} ${medication?.unit?.name}`;
    }
    if (medicationOrder?.direction) {
      info += `\n${medicationOrder?.direction}`
    }
    if (medicationOrder?.indication) {
      info += `\n${medicationOrder?.indication}`
    }
    if (medicationOrder?.physician?._id) {
      const physician = medicationOrder.physician;
      info += `\n${physician.title ?? ''} ${physician.firstName} ${physician.middleName ?? ''} ${physician.lastName}`
    }
    if (medicationOrder?.rxNumber) {
      info += `\nRX: ${medicationOrder?.rxNumber}`
    }
    return <Text style={[styles.tableCell, styles.bodyText, styles.hideOverflow]}>{info}</Text>;
  }

  return (
    <View style={styles.tableRow} wrap={false}>
      <View style={styles.tableCol1} fixed>
        {getInfo()}
      </View>
      <View style={styles.tableCol2} fixed>
        {!isPrn && medicationOrder?.dosageInstructions?.map((dosageInstruction, i) =>
          <PrintableDosageInstruction key={i} dosageInstruction={dosageInstruction} />
        )}
        {isPrn &&
        <View style={styles.dosageInsructionContainer}>
          <View style={styles.hrText}>
            <Text style={styles.bodyText}>PRN</Text>
          </View>
        </View>
        }
      </View>
      {[...Array(days)].map((e, n) => (
        <View style={[styles.paddedTableCol]}>
          {medicationOrder?.dosageInstructions?.map((dosageInstruction, i) =>
            <PrintableMarValues key={i} timeZone={resident?.location?.timeZone}
                                isPrn={isPrn}
                                dosageInstruction={dosageInstruction} day={n + 1} />
          )}
        </View>
      ))}
    </View>
  )

}


const ResidentMarPdf = ({resident, data, reviewData, days, year, monthName }) => {
  const getUserLabel = user => {
    let val = '';
    if (user) {
      val = `${user?.firstName ?? ''} ${user?.lastName ?? ''} `
      if (user?.initials) {
        val = `${user?.initials}, ` + val;
      }
    }
    return val;
  }

  return (
    <Document size={"a4"} orientation={"landscape"} >
      <Page size={"A4"} orientation={'landscape'} style={styles.page}>
        <HeaderWithTenant
          reportTitle={`${monthName} ${year} MAR Report`}
          location={resident?.location}
          tenant={resident?.location?.tenant}
          allUserLocations={false}
        />
        <View style={styles.mainContent}>
          <StatusLegend />
          <View style={styles.table}>
            <View style={styles.tableRow} fixed>
              <View style={styles.tableCol1Header}>
                <Text style={styles.tableCellHeader}>Medication</Text>
              </View>
              <View style={styles.tableCol2Header}>
                <Text style={[styles.tableCellHeader, styles.centerAlign]}>HR</Text>
              </View>
              {[...Array(days)].map((e, i) => (
                <View key={i} style={styles.tableColHeader}>
                  <Text style={[styles.tableCellHeader, styles.bodyText, styles.centerAlign]}>{i + 1}</Text>
                </View>
              ))}
            </View>
            {data?.filter(m => !m.isPrn).sort((a, b) => a?.medication?.tradeName > b?.medication?.tradeName ? 1: -1)?.map(d =>
              <View key={d?._id} wrap>
                {d.discontinuedDate ?
                  <View style={styles.discontinuedDate}>
                    <Text style={styles.bodyText}>
                      Discontinued: {moment(d?.discontinuedDate).format("MM/DD/YYYY")}
                    </Text>
                  </View>: null }
                {d?.alerts?.length > 0 ?
                  <View style={styles.alerts}>
                    <Text style={styles.bodyText}>
                      Alert: {d?.alerts}
                    </Text>
                  </View>: null }
                <MarRow medicationOrder={d} days={days} resident={resident} />
              </View>
            )
            }
            {data?.filter(m => m?.isPrn)?.length > 0 &&
            <View>
              <View style={[styles.marContainer, { borderBottom: '1px solid black'}]}>
                <Text style={styles.tableCellHeader}>PRN</Text>
              </View>
              {
                data?.filter(m => m.isPrn).sort((a, b) => a?.medication?.tradeName > b?.medication?.tradeName ? 1 : -1)?.map(d =>
                  <MarRow medicationOrder={d} days={days} resident={resident} isPrn={true}/>
                )
              }
            </View>
            }
          </View>
        </View>
        <View break>
          <InitialLegend data={data} />
        </View>
        {(reviewData?.approvedAt || reviewData?.reviewedAt) &&
        <View style={styles.approvedReviewedContainer}>
          {reviewData?.approvedAt &&
              <View style={styles.margin5Top}>
                <View style={styles.reviewedByContainer}>
                  <Text>
                    Approved: {getUserLabel(reviewData?.approvedBy)} {moment(reviewData?.approvedAt).format("MM/DD/YYYY")}
                  </Text>
                </View>
                <View style={styles.signatureBox}>
                  <Text>Signature:</Text>
                </View>
              </View>
          }
          {reviewData?.reviewedAt &&
              <View style={styles.margin5Top}>
                <View style={styles.reviewedByContainer}>
                  <Text>
                    Reviewed: {getUserLabel(reviewData?.reviewedBy)} {moment(reviewData?.reviewedAt).format("MM/DD/YYYY")}
                  </Text>
                </View>
                <View style={styles.signatureBox}>
                  <Text>Signature:</Text>
                </View>
              </View>
          }
        </View>
        }
        <View style={styles.footer} fixed>
          <View>
            <ResidentCard resident={resident} />
          </View>
          <View style={styles.footerContainer}>
            <View>
              <Text>
                {moment().format("MM/DD/YYYY hh:mm a")}
              </Text>
            </View>
            <View>
              <Text render={({ pageNumber, totalPages }) => (
                `page ${pageNumber} of ${totalPages}`
              )} fixed />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default ResidentMarPdf;
