import apiUtil from "../utils/api.utils";
import config from '../utils/configs';

const apiUrl = `${config.timeEntryService}/api/v1.0`;

const getTimeEntriesReportFor = async (year, month, userId = null) => {
  try {
    let url = `${apiUrl}/timeentries?year=${year}&month=${month}`
    if (userId) {
      url += `&user=${userId}`;
    }
    const response = await apiUtil.client.get(url);
    return response?.data;
  } catch (error) {
    return {error: true, message: error};
  }
}

export {
  getTimeEntriesReportFor
}
