import React from 'react';
import {
    Box,
    Button, Chip,
    Divider,
    makeStyles,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow, Typography,
} from "@material-ui/core";
import {GetApp, Edit, Delete} from "@material-ui/icons";
import moment from "moment";
import filesize from 'filesize';
import {ResidentFileContext} from "../context/ResidentFilesContext";
import {onDeleteFileAction, onFileDownloadAction, onFileUpdatedAction} from "../context/Actions";
import {EditFileDialog} from "./dialog/EditFileDialog";
import ConfirmDialog from "../../../utils/dialogs/confirm.dialog";
import Alert from "@material-ui/lab/Alert";
import useHasRole from "../../../../hooks/user.role.hook";
import TableLoadingIndicator from "../../../../utils/indicators/table.loading";

const useStyles = makeStyles((theme) => ({
    indent: {
        paddingLeft: theme.spacing(5)
    },
    chip: {
        marginRight: theme.spacing(.5)
    }
}));

export const File = ({file}) => {
    const classes = useStyles();
    const [viewEditDialog, setViewEditDialog] = React.useState(false);
    const [viewDeleteDialog, setViewDeleteDialog] = React.useState(false);
    const {state, dispatch} = React.useContext(ResidentFileContext);
    const {checkPermission} = useHasRole();
    const canDelete = checkPermission("RESIDENT_FILE_DELETE");
    const canUpdate = checkPermission("RESIDENT_FILE_UPDATE");
    const canDownLoad = checkPermission("RESIDENT_FILE_DOWNLOAD");


    const handleDownload = async () => {
        await onFileDownloadAction(state?.residentId, file?._id);
    }

    const handleEditClick = () => {
        setViewEditDialog(true);
    }

    const handleUpdate = async data => {
        setViewEditDialog(false);
        const fileData = {...file, ...data};
        fileData.fileName = data?.label;
        await onFileUpdatedAction(dispatch, state?.residentId, fileData?._id, fileData);
    }

    const handleDelete = async () => {
        await onDeleteFileAction(state?.residentId, file?._id, dispatch);
    }

    return (
        <div>
            <ConfirmDialog onClose={() => setViewDeleteDialog(false)}
                           open={viewDeleteDialog}
                           title={`Delete ${file?.label}?`}
                           message={<Box>
                               <Alert severity={"warning"}>This action is permenant.</Alert>
                               <Typography variant={"subtitle2"} style={{marginTop: '.5rem'}}>
                                   {file?.label} will be permanently deleted from storage.
                               </Typography>
                           </Box>}
                           onConfirm={handleDelete}>

            </ConfirmDialog>
            <EditFileDialog open={viewEditDialog}
                            onSave={handleUpdate}
                            onClose={() => setViewEditDialog(false)} file={file}
            />
            <Box display={"flex"}>
                {canDownLoad &&
                    <Button size={"small"}
                            color={"primary"}
                            startIcon={<GetApp/>}
                            onClick={handleDownload}>
                        Download
                    </Button>
                }
                {canUpdate &&
                    <Button size={"small"}
                            color={"primary"}
                            startIcon={<Edit/>}
                            onClick={handleEditClick}>
                        Edit
                    </Button>
                }
                {canDelete &&
                    <Button startIcon={<Delete/>}
                            color={"primary"}
                            size={"small"}
                            onClick={() => setViewDeleteDialog(true)}>
                        Delete
                    </Button>
                }
            </Box>
            <Divider/>
            <Box>
                {state?.loading &&
                <TableLoadingIndicator rows={6} cols={1} bodyOnly={false} />
                }
                {!state?.loading &&
                    <TableContainer>
                        <Table size={"small"}>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={3} width={5}>
                                        Overview
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.indent}>
                                        Name
                                    </TableCell>
                                    <TableCell>
                                        {file?.label}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.indent}>
                                        Type
                                    </TableCell>
                                    <TableCell>
                                        {file?.fileType}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.indent}>
                                        Size
                                    </TableCell>
                                    <TableCell>
                                        {filesize(file?.size)}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.indent}>
                                        Tags
                                    </TableCell>
                                    <TableCell>
                                        {file?.tags?.map(t => <Chip className={classes.chip} label={t}/>)}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.indent}>
                                        Created
                                    </TableCell>
                                    <TableCell>
                                        {moment(file?.createdAt).format("M/D/YYYY h:mm a")}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </Box>
        </div>
    )
}
