import React from "react";
import ResidentList from "./resident.list";
import PrivateRoute from "../../PrivateRoute";
import { Switch } from "react-router-dom";
import Resident from "../page/resident";

const Residents = () => {
  return (
    <Switch>
      <PrivateRoute exact path="/residents" component={ResidentList} />
      <PrivateRoute path="/residents/:residentId" component={Resident} />
    </Switch>
  );
};

export default Residents;
