import React from 'react';
import {
  Paper,
  makeStyles,
  Typography,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Button,
  Dialog,
  AppBar,
  DialogActions,
  Toolbar,
  IconButton,
  TableContainer,
  DialogContent,
  Tabs,
  Tab, Grid, Divider, DialogTitle, DialogContentText,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Transition from '../dialog/transition';
import {
  getTenantLocations,
  updateLocation,
  createLocation,
  deleteTenantLocation

} from '../../services/tenant.service';
import LocationModel from './location.model';
import { FormFieldsGenerator, isModelValid } from '../../utils/forms';
import useTenantLocationHooks from '../../hooks/tenant.location.hook';
import LocationPhones from './location.phones';
import LocationContacts from './location.contacts';
import AddIcon from '@material-ui/icons/Add';
import useHasRole from "../../hooks/user.role.hook";
import {DeleteBtn} from "../utils/buttons/DeleteBtn";
import useGlobalNotification from "../../hooks/notification.hook";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 1),
  },
  contentContainer: {
    paddingTop: '5rem',
  },
}));

const LocationList = ({ tenantId }) => {
  const classes = useStyles();
  const [viewConfirmDelete, setConfirmDelete] = React.useState(false);
  const [viewEditLocation, setViewEditLocation] = React.useState(false);
  const [selectedLocation, setSelectedLocation] = React.useState();
  const [editLocation, setEditLocation] = React.useState(
    JSON.parse(JSON.stringify(LocationModel))
  );
  const [locations, setLocations] = React.useState([]);
  const [onLocationUpdate] = useTenantLocationHooks();
  const [locationTabIndex, setLocationTabIndex] = React.useState(0);
  const [showCreateLocationModel, setShowCreateLocationModal] = React.useState(
    false
  );
  const [createLocationModel, setCreateLocationModel] = React.useState(
    JSON.parse(JSON.stringify(LocationModel))
  );
  const { checkPermission } = useHasRole();
  const canUpdate = checkPermission("LOCATION_UPDATE");
  const canCreate = checkPermission("LOCATION_CREATE");
  const canDeleteLocation = checkPermission("LOCATION_DELETE");
  const { addNotification } = useGlobalNotification();

  React.useEffect(() => {
    if (tenantId) {
      loadData(tenantId);
    }
    // eslint-disable-next-line
  }, [tenantId]);

  const loadData = async (id) => {
    const locations = await getTenantLocations(id);
    setLocations(locations);
  };

  const saveLocation = async () => {
    let data = {};
    Object.keys(editLocation).forEach((k) => {
      if (
        ['address', 'address2', 'city', 'state', 'zipCode', 'phones'].indexOf(
          k
        ) < 0
      ) {
        data[k] = editLocation[k].value;
      } else if (k !== 'phones') {
        if (!data.address) {
          data.address = {};
        }
        data['address'][k] = editLocation[k].value;
      } else if (k === 'phones') {
        data.phones = editLocation[k].value;
      }
    });

    const entity = await updateLocation(
      selectedLocation.tenant
        ? selectedLocation.tenant._id
          ? selectedLocation.tenant._id
          : selectedLocation.tenant
        : null,
      selectedLocation._id,
      data
    );
    onLocationUpdate(entity);

    const obj = locations.map((location) =>
      location._id === entity._id ? entity : location
    );
    setLocations(obj);
    setViewEditLocation(false);
  };

  const onEditLocationUpdate = (e) => {
    const obj = { ...editLocation, ...e };
    setEditLocation(obj);
  };

  const locationSelected = (location) => {
    setSelectedLocation(location);
    parseLocation(location);
    setViewEditLocation(true);
  };

  const parseLocation = (loc) => {
    const obj = { ...editLocation };
    Object.keys(loc).forEach((k) => {
      if (obj[k] !== undefined && k !== 'address') {
        obj[k].value = loc[k];
      }
    });
    if (loc.address) {
      obj.address.value = loc.address.address;
      obj.address2.value = loc.address.address2;
      obj.city.value = loc.address.city;
      obj.state.value = loc.address.state;
      obj.zipCode.value = loc.address.zipCode;
    }

    setEditLocation(obj);
  };

  const handleChange = (event, newValue) => {
    setLocationTabIndex(newValue);
  };

  const addLocationPhone = (locationId, phone) => {
    const obj = locations.map((location) => {
      if (location._id === locationId) {
        location.phones = [...location.phones, phone];
      }
      return location;
    });
    setLocations(obj);
  };

  const updateLocationPhone = (locationId, phoneId, phone) => {
    const obj = locations.map((location) => {
      if (location._id === locationId) {
        location.phones = location.phones.map((ph) =>
          ph._id === phoneId ? phone : ph
        );
      }
      return location;
    });
    setLocations(obj);
  };

  const deleteLocationPhone = (locationId, phoneId) => {
    const obj = locations.map((location) => {
      if (location._id === locationId) {
        location.phones = location.phones.filter((ph) => ph._id !== phoneId);
      }
      return location;
    });
    setLocations(obj);
  };

  const setUpCreateLocationModal = () => {
    setShowCreateLocationModal(true);
    setCreateLocationModel(JSON.parse(JSON.stringify(LocationModel)));
  };

  const onCreateLocation = async () => {
    const data = {
      name: createLocationModel.name.value,
      email: createLocationModel.email.value,
      stateFacilityNumber: createLocationModel.stateFacilityNumber.value,
      billingEnabled: createLocationModel.billingEnabled.value,
      isActive: createLocationModel.isActive.value,
      address: {
        address: createLocationModel.address.value,
        address2: createLocationModel.address2.value,
        city: createLocationModel.city.value,
        state: createLocationModel.state.value,
        zipCode: createLocationModel.zipCode.value,
      },
      phones: createLocationModel.phones.value,
      timeZone: createLocationModel.timeZone.value,
    };
    const entity = await createLocation(tenantId, data);
    const obj = [...locations, entity];
    setLocations(obj);
    setShowCreateLocationModal(false);
  };

  const confirmDelete = () => {
    setConfirmDelete(true);
  }

  const onDeleteConfirm = async () => {
    const response = await deleteTenantLocation(tenantId, selectedLocation?._id);
    if (!response?.error) {
      setLocations(locations.filter(location => location._id !== selectedLocation?._id));
      setConfirmDelete(false);
      setViewEditLocation(false);
    } else if (response?.message?.residentsExist) {
      addNotification("Unable to delete location. There are currently residents assigned to this location. " +
        "Please assign residents to different location.", "warning");
    } else {
      addNotification("Unable to delete location", "error");
    }
  }

  return (
    <Paper elevation={24} className={classes.root}>
      <Typography variant="h6">Locations</Typography>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                {canCreate && <Button
                  onClick={setUpCreateLocationModal}
                  color="primary"
                  startIcon={<AddIcon />}
                >
                  Create
                </Button>}
              </TableCell>
              <TableCell>Location Name</TableCell>
              <TableCell>Tenant Name</TableCell>
              <TableCell>Time Zone</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {locations &&
              locations.map((location) => (
                <TableRow>
                  <TableCell>
                    <Button onClick={() => locationSelected(location)}>
                      View
                    </Button>
                  </TableCell>
                  <TableCell>{location.name}</TableCell>
                  <TableCell>
                    {location.tenant ? location.tenant.name : null}
                  </TableCell>
                  <TableCell>{location.timeZone}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        fullScreen
        open={viewEditLocation}
        onClose={() => setViewEditLocation(false)}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setViewEditLocation(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Location - {editLocation && editLocation.name.value}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.contentContainer}>
          <Tabs
            component={Paper}
            elevation={24}
            value={locationTabIndex}
            onChange={handleChange}
            variant="fullWidth"
          >
            <Tab label="Details" />
            <Tab label="Phone" />
            <Tab label="Contacts" />
          </Tabs>

          <Paper elevation={24} style={{ marginTop: '.5rem', padding: '1rem' }}>
            {locationTabIndex === 0 && editLocation && (
              <form noValidate>
                <FormFieldsGenerator
                  model={editLocation}
                  onChange={onEditLocationUpdate}
                  edit={canUpdate}
                />
              </form>
            )}
            {locationTabIndex === 1 && (
              <LocationPhones
                location={selectedLocation}
                onCreate={addLocationPhone}
                onUpdate={updateLocationPhone}
                onDelete={deleteLocationPhone}
                canUpdate={canUpdate}
              />
            )}
            {locationTabIndex === 2 && (
              <LocationContacts location={selectedLocation}
                                canUpdate={canUpdate} />
            )}
          </Paper>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Grid container justify={"space-between"}>
            <Grid item>
              {canDeleteLocation && <DeleteBtn buttonText={"Delete"} onClick={confirmDelete} />}
            </Grid>
            <Grid item>
              <Button onClick={() => setViewEditLocation(false)}>Close</Button>
              {canUpdate && <Button
                color="primary"
                variant="contained"
                onClick={saveLocation}
                disabled={!isModelValid(editLocation)}
              >
                Save
              </Button>}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showCreateLocationModel}
        onClose={() => setShowCreateLocationModal(false)}
        TransitionComponent={Transition}
        fullScreen
      >
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setShowCreateLocationModal(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Create Location
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.contentContainer}>
          <FormFieldsGenerator
            model={createLocationModel}
            onChange={(e) =>
              setCreateLocationModel({ ...createLocationModel, ...e })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => setShowCreateLocationModal(false)}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={onCreateLocation}
            disabled={!isModelValid(createLocationModel)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={viewConfirmDelete}
              fullWidth
              maxWidth={"md"}
      >
        <DialogTitle>{"Delete Location?"}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>{"Are you sure you want to delete this location?"}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDelete(false)}>Cancel</Button>
          <DeleteBtn buttonText={"Delete"} onClick={onDeleteConfirm} />
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default LocationList;
