import apiUtil from "../utils/api.utils";
import configs from "../utils/configs";
import {parseFiltersToJson} from "../utils/filter.utils";
const pharmacyEndpoint = `${configs.residentService}/api/v1.0/pharmacies`;

export const getPharmacies = async (
  page = 0,
  size = 25,
  filters = [],
  sort = "name",
  sortDirection = "asc"
) => {
  try {
    let sortArg = sortDirection === "asc" ? "+" : "-";
    sortArg += sort;
    let url = `${pharmacyEndpoint}?page=${page}&size=${size}&sort=${sortArg}`;
    if (filters?.length > 0) {
      url += parseFiltersToJson(filters);
    }
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (error) {
    return { error: true, message: error }
  }
};

export const createPharmacy = async (phamacy) => {
  try {
    const response = await apiUtil.client.post(pharmacyEndpoint, phamacy);
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
};

export const getPharmacy = async (pharmacyId) => {
  try {
    const response = await apiUtil.client.get(`${pharmacyEndpoint}/${pharmacyId}`);
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
}

export const updatePharmacy = async (pharmacyId, pharmacy) => {
  try {
    const response = await apiUtil.client.put(`${pharmacyEndpoint}/${pharmacyId}`, pharmacy);
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
}

export const createPharmacyPhone = async (pharmacyId, phone) => {
  try {
    const response = await apiUtil.client.post(`${pharmacyEndpoint}/${pharmacyId}/phones`, phone);
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
}

export const updatePharmacyPhone = async (pharmacyId, phoneId, phone) => {
  try {
    const response = await apiUtil.client.put(`${pharmacyEndpoint}/${pharmacyId}/phones/${phoneId}`, phone);
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
}

export const deletePharmacyPhone = async (pharmacyId, phoneId) => {
  try {
    const response = await apiUtil.client.delete(`${pharmacyEndpoint}/${pharmacyId}/phones/${phoneId}`);
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
}
