import React from 'react'
import PrivateRoute from "../PrivateRoute";
import { Switch } from "react-router-dom";
import MedicationOrderList from './medicationorder.list';
import MedicationOrder from './medicationorder';



const MedicationOrders = () => {
    return (
        <Switch>
          <PrivateRoute exact path="/medicationorders" component={MedicationOrderList} />
          <PrivateRoute exact path="/medicationorders/:id" component={MedicationOrder} />
        </Switch>
    )
}

export default MedicationOrders
