import React from 'react';
import {getPrnAlerts} from "../../../../../services/resident.service";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow, Typography
} from "@material-ui/core";
import TableLoadingIndicator from "../../../../../utils/indicators/table.loading";
import moment from "moment";
import {CancelPresentation} from "@material-ui/icons";

export const PrnAlerts = ({ residentId, prnId, onCancelAlert }) => {
  const [alerts, setAlerts] = React.useState();
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(10);
  const [totalItems, setTotalItems] = React.useState(0);

  React.useEffect(() => {
    if (residentId && prnId) {
      getData();
    }
    // eslint-disable-next-line
  },[residentId, prnId])

  const getData = async (pg, sz) => {
    setLoading(true);
    setError(false);
    const response = await getPrnAlerts(residentId, prnId, pg, sz);
    if (!response?.canceled && !response?.error) {
      setTotalItems(response?.totalDocs);
      setAlerts(response?.docs);
      setLoading(false);
    } else {
      setError(true);
      setLoading(false);
    }
  }

  const handleSizeChange = async (e) => {
    const newSize = e.target.value
    setSize(newSize);
    await getData(page, newSize);

  }

  const handlePageChange = async (e, page) => {
    const newPage = page + 1;
    setPage(newPage);
    await getData(newPage, size);
  }

  return (
    <TableContainer>
      <TablePagination count={totalItems}
                       component={"div"}
                       onChangePage={handlePageChange}
                       onChangeRowsPerPage={handleSizeChange}
                       page={page - 1}
                       rowsPerPage={size} />
      <Table size={"small"}>
        <TableHead>
          <TableRow>
            <TableCell width={50} />
            <TableCell>Medication</TableCell>
            <TableCell>Hours</TableCell>
            <TableCell>Alert Type</TableCell>
            <TableCell>Recheck Date</TableCell>
          </TableRow>
        </TableHead>
        {loading && <TableLoadingIndicator bodyOnly={true} />}
        {!loading && !error &&
          <TableBody>
            {alerts?.map(alert =>
              <TableRow>
                <TableCell>
                  <Button startIcon={<CancelPresentation />}
                          onClick={() => onCancelAlert(alert?._id)}
                          color={"primary"}>
                    Dismiss
                  </Button>
                </TableCell>
                <TableCell>
                  <Typography variant={"subtitle1"}>
                    {alert?.fkEntity?.medicationOrder?.medication?.tradeName}
                  </Typography>
                  <Typography variant={"subtitle1"}>
                    {alert?.fkEntity?.medicationOrder?.medication?.strength}{" "} {alert?.fkEntity?.medicationOrder?.medication?.unit?.name}{" "}
                    {alert?.fkEntity?.medicationOrder?.medication?.dosageForm?.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  {moment(alert?.fkEntity?.scheduledTime).format("MM/DD/YYYY hh:mm a")}
                </TableCell>
                <TableCell>
                  {alert?.type === "prn_recheck_medication" ? "Recheck": "Other"}
                </TableCell>
                <TableCell>
                  {moment(alert?.fkEntity?.recheckDateTime).format("MM/DD/YYYY hh:mm a")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        }
      </Table>
    </TableContainer>
  )
}
