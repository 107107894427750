import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button
} from "@material-ui/core";
import {ChevronRight} from "@material-ui/icons";
import {Link as BrowserLink} from "react-router-dom";
import {CareplanContext} from "./context";
import {ResidentContext} from "../../../context/residentcontext";
import {ACTIONS} from "./context/constants";
import AddIcon from "@material-ui/icons/Add";
import {viewCreateInterventionDialogAction} from "./context/actions";

export const ResidentCareplanGoalsTable = () => {
    const {resident} = React.useContext(ResidentContext);
    const {state, dispatch} = React.useContext(CareplanContext);

    const handleCreateGoal = () => {
        dispatch({type: ACTIONS.SET_VIEW_CREATE_GOAL_DIALOG, payload: true});
    }

    const handleAddIntervention = (goal) => {
        dispatch({type: ACTIONS.SET_GOAL, payload: goal});
        viewCreateInterventionDialogAction(dispatch);
    }
    return (
        <TableContainer>
            <Table size={"small"}>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={3}>
                            <Button onClick={handleCreateGoal}
                                    variant={"contained"}
                                    size={"small"}
                                    color={"primary"}
                                    startIcon={<AddIcon />}>
                                Add
                            </Button>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Goal</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Interventions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {state?.careplan?.goals?.map(goal =>
                        <TableRow>
                            <TableCell>
                                <Button
                                    startIcon={<ChevronRight />}
                                    component={BrowserLink}
                                    to={`/residents/${state?.residentId}/careplans/${state?.careplan?._id}/goals/${goal?._id}`}
                                >
                                    {goal?.name}
                                </Button>
                            </TableCell>
                            <TableCell>{goal?.description}</TableCell>
                            <TableCell>
                                {
                                    goal?.interventions?.map(intervention =>
                                    <div>
                                        <Button
                                            component={BrowserLink}
                                            startIcon={<ChevronRight />}
                                              to={
                                            `/residents/${resident?._id}/careplans/${state?.careplan?._id}/goals/${goal?._id}/interventions/${intervention?._id}`
                                        }
                                              style={{paddingRight: '.25rem', cursor: "pointer"}}
                                        >
                                            {intervention?.name} ({intervention?.frequency?.name})
                                        </Button>
                                    </div>
                                    )
                                }
                                <Button variant={"contained"}
                                        color={"primary"}
                                        size={"small"}
                                        startIcon={<AddIcon />}
                                        onClick={() => handleAddIntervention(goal)}>
                                    Add Intervention
                                </Button>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
