import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import {DeleteBtn} from "../../../utils/buttons/DeleteBtn";
import { HoldOrderTemplate } from './holdModel';
import {FormFieldsGenerator, isModelValid} from "../../../../utils/forms";
export const EditHold = ({open, onClose, onUpdate, onDelete, hold, users}) => {

  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(HoldOrderTemplate)));


  React.useEffect(() => {
    if (open && hold) {
      const obj =  JSON.parse(JSON.stringify(HoldOrderTemplate));
      obj.startDate.value = hold?.startDate;
      obj.endDate.value = hold?.endDate;
      obj.comment.value = hold?.comment;
      obj.createdBy.dropDownItems = users;
      obj.createdBy.value = hold?.createdBy?._id;
      setModel(obj);
    }
    // eslint-disable-next-line
  }, [open, hold]);

  const onChange = e => {
    setModel({...model, ...e})
  }

  const save = () => {
    let data = {}
    Object.keys(model).forEach(key => {
      data[key] = model[key].value;
    });
    onUpdate(hold?._id, data)
  }
  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={open}
      onClose={onClose}>
      <DialogTitle>
        {"Edit Hold"}
      </DialogTitle>
      <DialogContent dividers>
        <FormFieldsGenerator model={model} onChange={onChange} />
      </DialogContent>
      <DialogActions>
        <Grid container justify={"space-between"}>
          <Grid item>
            <DeleteBtn buttonText={"Remove"} onClick={onDelete} />
          </Grid>
          <Grid item>
            <Button variant={"text"}
                    onClick={onClose}
                    color={"primary"}>
              Cancel
            </Button>
            <Button variant={"contained"}
                    color={"primary"}
                    disabled={!isModelValid(model)}
                    onClick={save}
            >
              Save
            </Button>
          </Grid>
        </Grid>

      </DialogActions>
    </Dialog>
  )
}
