import React, {useContext} from 'react';
import {CareplanContext} from "./context";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Grid,
    IconButton,
    makeStyles, Table, TableBody,
    TableContainer
} from "@material-ui/core";
import {ACTIONS} from "./context/constants";
import CloseIcon from "@material-ui/icons/Close";
import {InterventionModel} from "./intervention.model";
import {FormFieldsGenerator, isModelValid} from "../../../utils/forms";
import BaseEvent from "../../medicationorders/create/base.event";
import moment from "moment/moment";
import {ObjectId} from "bson";
import SaveIcon from "@material-ui/icons/Save";
import {deleteInterentionAction, updateInterventionAction} from "./context/actions";
import {DeleteBtn} from "../../utils/buttons/DeleteBtn";
import ConfirmDialog from "../../utils/dialogs/confirm.dialog";


const useStyles = makeStyles((theme) => ({
    root: {
    },
    dialogContent: {
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

export const InterventionDialog = () => {
    const {state, dispatch} = useContext(CareplanContext);
    const classes = useStyles();
    const [frequency, setFrequency] = React.useState(null);
    const [viewConfirmDialog, setViewConfirmDialog] = React.useState(false);

    React.useEffect(() => {
        const obj = JSON.parse(JSON.stringify(InterventionModel));
        obj.name.value = state?.intervention?.name;
        obj.etask.value = state?.intervention?.etask;
        obj.startDate.value = state?.intervention?.startDate;
        if (moment(obj.startDate.value).startOf('d').isBefore(moment().startOf('d'))) {
            obj.startDate.disabled = true;
        }
        obj.startDate.maxDateValue = state?.intervention?.endDate;
        obj.endDate.value = state?.intervention?.endDate;
        obj.frequency.value = state?.intervention?.frequency?._id;
        obj.frequency.dropDownItems = state?.frequencies;
        obj.eventTemplates.value = state?.intervention?.eventTemplates;
        obj.discipline.dropDownItems = state?.roles;
        dispatch({type: ACTIONS.SET_INTERVENTION_MODEL, payload: obj});
        // eslint-disable-next-line
    }, [state?.intervention]);

    const handleClose = () => {
        dispatch({type: ACTIONS.SET_VIEW_INTERVENTION_DIALOG, payload: false});
    }

    const onFormChange = (e, field) => {
        const obj = {...state?.interventionModel, ...e};
        if (field === "endDate") {
            obj.startDate.maxDateValue = e.endDate.value;
        }
        dispatch({type: ACTIONS.SET_INTERVENTION_MODEL, payload: obj});
        if (field === 'frequency') {
            processDay(obj.frequency.value);
        }
    }

    const onEventChange = e => {
        const obj = { ...state?.interventionModel };
        if (obj.eventTemplates && obj.eventTemplates.value) {
            obj.eventTemplates.value = obj.eventTemplates.value.map((eventTemplate) =>
                eventTemplate._id === e._id ? e : eventTemplate
            );
            dispatch({type: ACTIONS.SET_INTERVENTION_MODEL, payload: obj});
        }
    }


    const processDay = (frequencyId) => {
        if (state?.frequencies) {
            const freq = state?.frequencies.find((f) => f._id === frequencyId);
            if (freq) {
                setFrequency(freq);
                const eventTemplates = [];
                const defaultHours = {
                    1: { 1: 8 },
                    2: { 1: 8, 2: 16 },
                    3: { 1: 8, 2: 12, 3: 16 },
                    4: { 1: 8, 2: 10, 3: 14, 4: 16 },
                    5: { 1: 8, 2: 10, 3: 12, 4: 14, 5: 16 },
                };

                for (let i = 1; i <= freq.frequencyEventCount; i++) {
                    const d = new Date();
                    // Auto increment hours
                    let calculatedHour = defaultHours[freq.frequencyEventCount][i];
                    const initStartDate = d.setHours(calculatedHour, 0, 0, 0);
                    const hour = moment(initStartDate).format('H');
                    const minute = moment(initStartDate).format('mm');
                    eventTemplates.push({
                        _id: new ObjectId(),
                        sort: i,
                        passingQty: 1,
                        hour,
                        minute,
                    });
                }
                const obj = { ...state?.interventionModel };
                obj.eventTemplates.value = eventTemplates;
                dispatch({type: ACTIONS.SET_INTERVENTION_MODEL, payload: obj});
            }
        } else {
        }
    };

    const handleUpdateIntervention = async () => {
        let data = {};
        Object.keys(state?.interventionModel).forEach(key => {
            data[key] = state.interventionModel[key].value
        });
        await updateInterventionAction(dispatch, state?.residentId, state?.careplan?._id, state?.goal?._id, state?.intervention?._id, data);
    }

    const handleConfirmDelete = async () => {
        await deleteInterentionAction(dispatch, state?.residentId, state?.careplan?._id, state?.goal?._id, state?.intervention?._id,)
        setViewConfirmDialog(false);
    }


    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={state?.viewInterventionDialog}
            fullWidth={true}
            maxWidth={'lg'}
        >
            <DialogTitle>
                Intervention: {state?.intervention?.name}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    className={classes.closeButton}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <FormFieldsGenerator model={state?.interventionModel}
                                     onChange={onFormChange}
                />
                {!state?.interventionModel?.isPrn?.value && (
                    <TableContainer>
                        <Table>
                            <TableBody>
                                {state?.interventionModel?.eventTemplates?.value?.map((event) => (
                                    <BaseEvent
                                        frequency={frequency}
                                        eventTemplate={event}
                                        onChange={onEventChange}
                                        showQty={false}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent={"space-between"}>
                    <Grid item>
                        <DeleteBtn onClick={() => setViewConfirmDialog(true)} />
                    </Grid>
                    <Grid item>
                        <Button onClick={handleClose}>Close</Button>
                        <Button disabled={!isModelValid(state?.interventionModel)}
                                variant={"contained"}
                                color={"primary"}
                                onClick={handleUpdateIntervention}
                                startIcon={<SaveIcon />}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
            <ConfirmDialog open={viewConfirmDialog}
                           title={"Delete Intervention"}
                           message={"Do you want to delete this intervention?"}
                           onClose={() => setViewConfirmDialog(false)}
                           onConfirm={handleConfirmDelete}
            />
        </Dialog>
    )
}
