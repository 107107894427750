import {ACTIONS} from "./constants";

export const reducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SET_RESIDENT_ID:
            return {...state, residentId: action?.payload};
        case ACTIONS.SET_LOADING:
            return {...state, loading: action?.payload};
        case ACTIONS.SET_ERROR:
            return {...state, error: action?.payload};
        case ACTIONS.SET_STATUS:
            return {...state, status: action?.payload};
        case ACTIONS.SET_MODEL:
            return {...state, model: action?.payload};
        case ACTIONS.SET_SELECT_ALL:
            return {...state, selectAll: action?.payload};
        case ACTIONS.SET_CAREPLANS:
            return {...state, careplans: [...action?.payload]};
        case ACTIONS.SET_CAREPLAN:
            return {...state, careplan: action?.payload};
        case ACTIONS.SET_GOAL:
            return {...state, goal: action?.payload};
        case ACTIONS.SET_INTERVENTION:
            return {...state, intervention: action?.payload};
        case ACTIONS.SET_GENERATING_PDF:
            return {...state, generatingPdf: action?.payload};
        case ACTIONS.SET_VIEW_CAREPLAN_DIALOG:
            return {...state, viewCareplanDialog: action?.payload};
        case ACTIONS.SET_VIEW_GOAL_DIALOG:
            return {...state, viewGoalDialog: action?.payload};
        case ACTIONS.SET_VIEW_INTERVENTION_DIALOG:
            return {...state, viewInterventionDialog: action?.payload};
        case ACTIONS.SET_VIEW_CREATE_CAREPLAN_DIALOG:
            return {...state, viewCreateCareplanDialog: action?.payload};
        case ACTIONS.SET_VIEW_CREATE_GOAL_DIALOG:
            return {...state, viewCreateGoalDialog: action?.payload};
        case ACTIONS.SET_VIEW_CREATE_INTERVENTION_DIALOG:
            return {...state, viewCreateInterventionDialog: action?.payload};
        case ACTIONS.SET_GOAL_MODEL:
            return {...state, goalModel: action?.payload};
        case ACTIONS.SET_INTERVENTION_MODEL:
            return {...state, interventionModel: action?.payload};
        case ACTIONS.SET_FREQUENCIES:
            return {...state, frequencies: action?.payload};
        case ACTIONS.SET_ROLES:
            return {...state, roles: action?.payload};
        case ACTIONS.ADD_GOAL:
            const addGoalCareplan = {...state?.careplan};
            addGoalCareplan.goals = [...addGoalCareplan.goals, action?.payload];
            return {...state,
                careplan: addGoalCareplan,
                careplans: state?.careplans?.map(c => c?._id === addGoalCareplan?._id ? addGoalCareplan: c)
            };
        case ACTIONS.ADD_INTERVENTION:
            const addInterventionCareplan = {...state?.careplan};
            addInterventionCareplan.goals = addInterventionCareplan?.goals?.map(goal => {
                if (goal?._id === action?.payload?.goalId) {
                    goal.interventions = [...goal.interventions, action?.payload?.intervention];
                };
                return goal;
            });
            const addInterventionGoal = {...state?.goal};
            addInterventionGoal.interventions = [...addInterventionGoal?.interventions, action?.payload?.intervention];
            return {
                ...state,
                careplan: addInterventionCareplan,
                goal: addInterventionGoal,
                careplans: state?.careplans?.map(c => c?._id === addInterventionCareplan?._id ? addInterventionCareplan: c)
            }
        case ACTIONS.UPDATE_INTERVENTION:
            const updateInterventionCareplan = {...state?.careplan};
            updateInterventionCareplan.goals = updateInterventionCareplan?.goals?.map(goal => {
                if (goal?._id === action?.payload?.goalId) {
                    goal.interventions =
                        goal?.interventions?.map(int => int?._id === action?.payload?.intervention?._id ?
                            action?.payload?.intervention:
                            int
                        );
                };
                return goal;
            });
            return {
                ...state,
                careplan: updateInterventionCareplan,
                careplans: state?.careplans?.map(c => c?._id === updateInterventionCareplan?._id ? updateInterventionCareplan: c)
            }
        case ACTIONS.REMOVE_INTERVENTION:
            const removeInterventionCareplan = {...state?.careplan};
            let removeInterventionGoal = state?.goal;
            removeInterventionCareplan.goals = removeInterventionCareplan?.goals?.map(goal => {
                if (goal?._id === action?.payload?.goalId) {
                    goal.interventions =
                        goal.interventions?.filter(intervention =>
                            intervention?._id !== action?.payload?.interventionId);
                    removeInterventionGoal = goal;
                };
                return goal;
            });
            return {
                ...state,
                careplan: removeInterventionCareplan,
                careplans: state?.careplans?.map(c => c?._id === removeInterventionCareplan?._id ? removeInterventionCareplan: c),
                goal: removeInterventionGoal
            }
        case ACTIONS.UPDATE_GOAL:
            const updateGoalCareplan = {...state?.careplan};
            updateGoalCareplan.goals =
                updateGoalCareplan?.goals?.map(goal => goal?._id === action?.payload?._id ? action?.payload: goal);
            return {...state,
                goal: action?.payload,
                careplan: updateGoalCareplan,
                careplans: state?.careplans?.map(c => c?._id === updateGoalCareplan?._id ? updateGoalCareplan: c)
            };
        case ACTIONS.REMOVE_GOAL:
            const removeGoalCareplan = {...state?.careplan};
            removeGoalCareplan.goals = removeGoalCareplan?.goals?.filter(goal => goal?._id !== action?.payload?.goalId);
            return {...state,
                goal: null,
                intervention: null,
                careplan: removeGoalCareplan,
                careplans: state?.careplans?.map(c => c?._id === removeGoalCareplan?._id ? removeGoalCareplan: c)
            };
        case ACTIONS.SET_UPDATING_CAREPLAN:
            return {...state, updatingCareplan: action?.payload};
        case ACTIONS.SET_UPDATING_GOAL:
            return {...state, updatingGoal: action?.payload};
        case ACTIONS.SET_UPDATING_INTERVENTION:
            return {...state, updatingIntervention: action?.payload};
        case ACTIONS.TOGGLE_CAREPLAN_PRINT:
            return {
                ...state,
                careplans: state?.careplans?.map(careplan => careplan?._id === action?.payload?._id ? action?.payload: careplan)
            }
        case ACTIONS.TOGGLE_CAREPLAN_OPEN:
            return {
                ...state,
                careplans: state?.careplans?.map(careplan => careplan?._id === action?.payload?._id ? action?.payload: careplan)
            };
        case ACTIONS.TOGGLE_GOAL_OPEN:
            return {
                ...state,
                careplans: state?.careplans?.map(careplan => {
                    if (careplan?._id === action?.payload?.careplan?._id) {
                        careplan.goals =
                            careplan?.goals?.map(goal => goal?._id === action?.payload?.goal?._id ? action?.payload?.goal: goal);
                    }
                    return careplan;
                })
            }
        case ACTIONS.SET_LOADING_INTERVENTION:
            return {
                ...state,
                loadingIntervention: action?.payload
            }
        case ACTIONS.SET_LOADING_GOAL:
            return {
                ...state,
                loadingGoal: action?.payload
            }
        case ACTIONS.ADD_CAREPLAN:
            return {
                ...state,
                careplans: [...state?.careplans, action?.payload]
            };
        case ACTIONS.REMOVE_CAREPLAN:
            return {
                ...state,
                careplans: state?.careplans?.filter(c => c?._id !== action?.payload)
            };
        default:
            return state;
    }
}
