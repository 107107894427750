import React from 'react'

const ContactList = () => {
    return (
        <div>
            <h1>List</h1>
        </div>
    )
}

export default ContactList
