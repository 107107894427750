// src/components/NavBar.js

import React from 'react';
import { useAuth0 } from '../../react-auth0-spa';
import {
  makeStyles,
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  Typography,
  Button,
  Avatar, Hidden,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import FaceIcon from '@material-ui/icons/Face';
import { Link } from 'react-router-dom';
import { UserContext } from '../../context/usercontext';
import Tenant from './Tenant';
import { createLoginActivityRecord } from '../../services/login.service';
import config from '../../utils/configs';
import UserPermissionsTooltip from "../permissions/user.permissions.tooltip";
import NavbarDrawer from "./NavbarDrawer";
import history from '../../utils/history';
import {RouterBreadcrumbs} from "./AppBreadcrumbs";
import TimeClockSplitButton from "../time.entry/time.clock.split.button";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 0, 0, 1)
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
  },
  appTitle: {
    textDecoration: 'none',
  },
  appTitleLink: {
    padding: 0,
    margin: 0,
    textDecoration: 'none',
    color: '#fff',
    fontFamily: 'Brush Script MT, Brush Script Std, cursive',
    "&:hover": {
      textDecoration: 'none'
    }
  },
  smAppTitle: {
    fontSize: '.9rem',
  },
  toolBar: {
    justifyContent: 'space-between',
  },
  list: {
    width: 250,
  },
  tenantSelectionContainer: {
    background: theme.palette.primary.dark,
  },
  locationSelect: {
    padding: '1rem',
    width: '100%',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  nested3rd: {
    paddingLeft: theme.spacing(8),
  },
  menuButton: {
  },
  loginBtn: {
    marginRight: '.25rem',
  },
  notificationButton: {
    color: theme.palette.primary.contrastText,
    marginRight: theme.spacing(1)

  },
  notificationBadge: {

  }
}));


const NavBar = () => {
  const classes = useStyles();
  const { isAuthenticated, logout, user } = useAuth0();
  const { state, dispatch } = React.useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDrawer, setOpenDrawer] = React.useState();
  const [viewIndex, setViewIndex] = React.useState(-1);
  const [calendarViewIndex, setCalendarViewIndex] = React.useState(-1);
  const [viewReportsItems, setViewReportsItems] = React.useState(-1);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isSystemAdmin = () => {
    return state?.user?.isSystemAdmin;
  };

  const tenantChange = async event => {
    dispatch({ type: 'CURRENT_TENANT_CHANGE', payload: event?.target?.value });
    history.push('/');
  }

  const locationChange = (event) => {
    dispatch({ type: 'selectTenantLocation', payload: event.target.value });
    setOpenDrawer(false);
  };

  const logoutUser = async () => {
    await createLoginActivityRecord('logout', user);
    logout({ returnTo: window.location.origin });
  };

  const buildImageUrlString = (tenantId, logo) => {
    return `${config.bucketObjectUrl}/${config.publicBucketName}/tenants/${tenantId}/logos/${logo}`;
  };

  return (
      <AppBar position={"sticky"} className={classes.root}>
        <Toolbar disableGutters>
          {user &&
          <IconButton edge="start"
                      className={classes.menuButton}
                      color="inherit"
                      onClick={() => setOpenDrawer((openDrawer) => !openDrawer)}
                      aria-label="menu">
            <MenuIcon />
          </IconButton>}
          <div style={{flex: 1, display: 'flex', alignItems: "center"}}>
            <Typography className={classes.appTitleLink}
                        variant="h3"
                        component={Link} to={"/"}>
              eR
            </Typography>
            <TimeClockSplitButton />
          </div>
          <Hidden xsDown>
            <div style={{flexGrow: .5}}>
              <Tenant />
            </div>
          </Hidden>
          {isAuthenticated && (
            <>
              <UserPermissionsTooltip />
              {/*<TenantLocationAlerts />*/}



              {state?.selectedTenant?.logo && (
                <Avatar
                  variant="square"
                  style={{ width: '5rem', marginTop: '.3rem' }}
                  src={buildImageUrlString(
                    state.selectedTenant._id,
                    state.selectedTenant.logo
                  )}
                  alt="Tenant Logo"
                />
              )}

              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <Avatar
                  alt="User Profile Image"
                  src={state?.user?.picture}
                >
                  <FaceIcon />
                </Avatar>
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem component={Link} to="/profile" onClick={handleClose}>
                  My account
                </MenuItem>
                <MenuItem component={Link} to={"/info"} onClick={handleClose}>
                  App Info
                </MenuItem>
                <MenuItem onClick={logoutUser}>Logout</MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
        <Hidden xsDown>
          <RouterBreadcrumbs />
        </Hidden>
        <NavbarDrawer openDrawer={openDrawer}
                      setOpenDrawer={setOpenDrawer}
                      classes={classes}
                      selectedTenant={state?.selectedTenant}
                      selectedTenantLocation={state?.selectedTenantLocation}
                      viewIndex={viewIndex}
                      setViewIndex={setViewIndex}
                      calendarViewIndex={calendarViewIndex}
                      setCalendarViewIndex={setCalendarViewIndex}
                      onTenantChange={tenantChange}
                      onLocationChange={locationChange}
                      isSystemAdmin={isSystemAdmin}
                      tenants={state?.tenants}
                      tenantLocations={state?.tenantLocations}
                      viewReportsItems={viewReportsItems}
                      setViewReportsItems={setViewReportsItems}
        />
      </AppBar>
  );
};

export default NavBar;
