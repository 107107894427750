import React from "react";
import DailyEvent from "./daily.event";
import WeeklyEvent from './weekly.event';
import MonthlyEvent from './monthly.event';
import YearlyEvent from "./yearly.event";
import { TableRow } from "@material-ui/core";




const BaseEvent = ({frequency, eventTemplate, onChange, onDelete, showQty = true, edit = true}) => {
  if (frequency && frequency.isMonthDateSelection) {
    return <TableRow><MonthlyEvent edit={edit} eventTemplate={eventTemplate} onChange={onChange} showQty={showQty} /></TableRow>;
  } else if (frequency && frequency.isDayOfWeekSelection) {
    return <TableRow><WeeklyEvent edit={edit} eventTemplate={eventTemplate} onChange={onChange} showQty={showQty} /></TableRow>;
  } else if (frequency && frequency.isYearlySelection) {
    return <TableRow><YearlyEvent edit={edit} eventTemplate={eventTemplate} onChange={onChange} showQty={showQty} /></TableRow>;
  } else {
    return <TableRow><DailyEvent edit={edit} eventTemplate={eventTemplate} onChange={onChange} showQty={showQty} /></TableRow>;
  }
};

export default BaseEvent;
