import React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from "@material-ui/core";
import ResidentSearch from "../../../resident/search/resident.search";
import {makeStyles} from "@material-ui/core/styles";
import {CloudDownload, PictureAsPdf, SkipNextOutlined, SkipPrevious} from "@material-ui/icons";
import {getResidentFacesheetReport} from "../../../../services/report.service";
import { Document, Page } from 'react-pdf';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0)
  }
}))

const ResidentFacesheetReport = () => {
  const classes = useStyles();
  const [viewReport, setViewReport] = React.useState(false);
  const [resident, setResident] = React.useState();
  const [report, setReport] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pages, setPages] = React.useState();


  const onResidentSelected = resident => {
    setResident(resident);
  }

  const handleClose = () => {
    setViewReport(false);
  }

  const onViewReport = async () => {
    setPageNumber(1);
    setReport(null);
    setLoading(true);
    setViewReport(true);
    const report = await getResidentFacesheetReport(resident?._id)
    const fileUrl = URL.createObjectURL(report);
    setReport(fileUrl);
    setLoading(false);
  }

  const onDocumentLoadSuccess = ( {numPages} ) => {
    setPages(numPages);
  }

  const downloadFile = () => {
    window.open(report);
  }

  return (
    <div className={classes.root}>
      <Typography variant={"h5"}>Resident Face Sheet</Typography>
      <ResidentSearch onSelect={onResidentSelected} />
      <Grid container justify={"flex-end"}>
        <Button disabled={!resident}
                startIcon={<PictureAsPdf />}
                onClick={onViewReport}
        >View Report</Button>
      </Grid>
      <Dialog open={viewReport}
              aria-labelledby="customized-dialog-title"
              fullWidth={true}
              onClose={handleClose}
              className={classes.dialog}
              maxWidth={"md"}>
        <DialogTitle>
          {"Report Preview"}
        </DialogTitle>
        <DialogContent dividers style={{background: '#efefef'}}>
          {loading &&
            <div style={{ textAlign: "center", height: '20vh', padding: '25%', border: '1px solid' }}>
              <CircularProgress />
              <Typography variant={"subtitle2"} component={"p"}>Loading report....</Typography>
            </div>
          }
          {!loading && !report &&
            <div style={{ textAlign: "center", height: '20vh', padding: '25%', border: '1px solid' }}>
              No Report found...
            </div>
          }
          {!loading && report &&
              <Document
                file={report}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={'Please wait, the document is loading!'}
                onLoadError={console.error}
              >
                <Page pageNumber={pageNumber} width={880} />
              </Document>
          }
        </DialogContent>
        <DialogActions>
          {pages > 1 &&
          <Grid container justify={"space-between"}>
            <div>
              <Button startIcon={<SkipPrevious />}
                      onClick={() => setPageNumber(pageNumber => pageNumber === 1 ? 1: pageNumber - 1)}

              >Previous</Button>
              <Button endIcon={<SkipNextOutlined />}
                      onClick={() => setPageNumber(pageNumber => pageNumber === pages ? pageNumber: pageNumber + 1)}
              >Next</Button>
            </div>
            <div>
              <Grid container>
                <Typography variant={"subtitle2"}>{pageNumber}</Typography>
                /
                <Typography variant={"subtitle2"}>{pages}</Typography>
              </Grid>
            </div>

            <div>
              <Button
                size={'small'}
                color={'primary'}
                onClick={handleClose}
              >
                Close
              </Button>
              <Button
                size={'small'}
                variant={'contained'}
                color={'primary'}
                onClick={downloadFile}
                startIcon={<CloudDownload />}
              >
                Download
              </Button>
            </div>
          </Grid>}
          {pages === 1 &&
          <div>
            <Button
              size={'small'}
              color={'primary'}
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              size={'small'}
              variant={'contained'}
              color={'primary'}
              onClick={downloadFile}
              startIcon={<CloudDownload />}
            >
              Download
            </Button>
          </div>
          }
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ResidentFacesheetReport;
