import apiUtil from '../utils/api.utils';
import config from '../utils/configs';
import {parseFiltersToJson} from "../utils/filter.utils";

const allergiesEndpoint = `${config.residentService}/api/v1.0/allergies`;

export const getAllergies = async (page = 1,
                                   size = 25,
                                   sort = "name",
                                   sortDirection = "+",
                                   filters = []) => {
    try {
      let url = `${allergiesEndpoint}?page=${page}&size=${size}`
      url += parseFiltersToJson(filters);
      let sortArg = sortDirection === 'asc' ? '+' : '-';
      sortArg += sort;
      url += `&sort=${sortArg}`;
      const response = await apiUtil.client.get(url);
      return response.data;
    } catch (error) {
      return null;
    }
}

export const createAllergy = async allergy => {
  try {
    const response = await apiUtil.client.post(allergiesEndpoint, allergy);
    return response.data;
  } catch (error) {
    return null;
  }
}

export const getAllergy = async id => {
  try {
    const response = await apiUtil.client.get(`${allergiesEndpoint}/${id}`);
    return response.data;
  } catch (error) {
    return null;
  }
}

export const updateAllergy = async (allergyId, allergy) => {
  try {
    const response = await apiUtil.client.put(`${allergiesEndpoint}/${allergyId}`, allergy);
    return response.data;
  } catch (error) {
    return null;
  }
}

export const deleteAllergy = async (allergyId) => {
  try {
    const response = await apiUtil.client.delete(`${allergiesEndpoint}/${allergyId}`);
    return response.data;
  } catch (error) {
    return null;
  }
}
