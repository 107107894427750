import React from 'react';
import {
  Button,
  FormGroup,
  Grid,
  InputAdornment,
  makeStyles,
  Paper,
  TablePagination,
  TextField,
  Typography
} from "@material-ui/core";
import history from "../../../utils/history";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import AddIcon from '@material-ui/icons/Add';
import CreateNoteDialog from "./create.note.dialog";
import {getResidentNotes} from "../../../services/resident.service";
import ResidentNoteItem from "./resident.note.item";
import {SearchTwoTone } from "@material-ui/icons";
import useGlobalNotification from "../../../hooks/notification.hook";
import {getCategories} from "../../../services/note.category.service";
import {UserContext} from "../../../context/usercontext";
import {ListLoading} from "../../../utils/indicators/list.loading";
import {RetryMessage} from "../../notification/retry.message";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(.5),
    overflowX: 'hidden'
  }
}));

const ResidentNotes = ({match}) => {
  const classes = useStyles();
  const { selectedTenant } = React.useContext(UserContext);
  const [categories, setCategories] = React.useState([]);
  const [residentId, setResidentId] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [notes, setNotes] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(5);
  const [totalItems, setTotalItems] = React.useState();
  const [showCreateDialog, setShowCreateDialog] = React.useState(false);
  const [filter, setFilter] = React.useState()
  const [filters, setFilters] = React.useState([]);
  const { addNotification } = useGlobalNotification();
  const sort = '-noteDate';


  React.useEffect(() => {
    if (match.params.residentId && selectedTenant) {
      setResidentId(match.params.residentId);
      getNotes(match.params.residentId);
    }
    // eslint-disable-next-line
  }, [match.params.residentId, selectedTenant])

  const getNotes = async (id, page = 1, size = 5, filters = []) => {
    const categories = await getCategories(selectedTenant?._id);
    setCategories(categories);
    setLoading(true);
    setError(false);
    const response = await getResidentNotes(id, page, size, sort, filters);
    if (!response?.error) {
      setNotes(response?.content);
      setPage(response?.paginator?.currentPage);
      setSize(response?.paginator?.perPage);
      setTotalItems(response?.paginator?.totalItems);
    } else {
      setError(true);
      setNotes([])
    }
    setLoading(false);
  }

  const onCreated = note => {
    setNotes([note, ...notes])
    setShowCreateDialog(false);
  }

  const onChangePage = async (e, newPage) => {
    const pg = newPage + 1;
    setPage(pg);
    await getNotes(residentId, pg, size, filters);
  }

  const onChangeRowsPerPage = async (e) => {
    setSize(e.target.value)
    await getNotes(residentId, page, e.target.value, filters);
  }

  const onFilterChange = e => {
    const newValue = e.target.value;
    setFilter(newValue);
    const filter = {
      field: 'text',
      operator: '*=*',
      value: newValue
    }
    setFilters([filter]);
    if (newValue && newValue.length > 2) {
      getNotes(residentId, page, size, [filter]);
    } else if (newValue.length === 0){
      getNotes(residentId, page, size, []);
    }

  }

  const onNoteDelete = noteId => {
    setNotes(notes.filter(note => noteId !== note._id));
    addNotification("Note deleted", "warning");
  }

  const retry = async () => {
    await getNotes(residentId, page, size, filters);
  }

  return <Paper elevation={24} className={classes.root}>
    <Grid container alignItems="center">
      <Button
        onClick={() => history.goBack()}
        className={classes.backBtn}
        startIcon={<NavigateBeforeIcon/>}
      >
        Back
      </Button>
      <Typography variant="h6">Resident eNotes</Typography>
    </Grid>
    <CreateNoteDialog open={showCreateDialog}
                      onClose={() => setShowCreateDialog(false)}
                      onSave={onCreated}
                      residentId={match.params.residentId}/>
    <Grid container>
      <Grid item md={1} sm={0} />
      <Grid item md={10} sm={12}>
        <Button onClick={() => setShowCreateDialog(true)}
                variant="contained" color="primary"
                startIcon={<AddIcon/>}>Add</Button>
        <TablePagination component="div"
                         rowsPerPageOptions={[1, 5, 10, 15, 25]}
                         page={page - 1}
                         rowsPerPage={size}
                         count={totalItems}
                         onPageChange={onChangePage}
                         onRowsPerPageChange={onChangeRowsPerPage}
        />
        <FormGroup style={{marginBottom: '1rem'}}>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            placeholder="Search"
            value={filter}
            onChange={onFilterChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchTwoTone />
                </InputAdornment>
              ),
            }} />
        </FormGroup>
        {loading && <ListLoading rows={5} />}
        {error && <RetryMessage message={"Unable to load resident notes"} onRetry={retry} />}

          {!error && !loading && notes?.map(note =>
            <ResidentNoteItem note={note}
                              categories={categories}
                              onDelete={onNoteDelete} />
          )}
      </Grid>
      <Grid md={1} sm={0} />
    </Grid>
  </Paper>
}

export default ResidentNotes;
