import React from 'react';
import {
  Button,
  Grid, makeStyles,
  Table, TableBody,
  TableCell, TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField, Typography
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import CreateAllergy from "./create.allergy";
import {Link} from "react-router-dom";
import {createAllergy, deleteAllergy, getAllergies} from "../../services/allergy.service";
import {GlobalNotificationContext} from "../notification/globalnotificationprovider";
import {convertToFilters} from "../../utils/filter.utils";
import { Switch } from 'react-router-dom';
import PrivateRoute from "../PrivateRoute";
import Allergy from "./allergy";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1),
    paddingTop: "4rem"
  },
  appBar: {
  },
  dialogContent: {
    marginTop: "4rem"
  },
  deleteBtn: {
    background: theme.palette.error.light,
    color: '#fff',
    '&:hover': {
      background: theme.palette.error.dark
    }
  }
}));

const filterTemplate = {
  name: {
    field: 'name',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  description: {
    field: 'description',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
}

const sizeOptions = [1, 5, 10, 25];

const Allergies = () => {
  const classes = useStyles();
  const { addNotification } = React.useContext(GlobalNotificationContext);
  const [viewCreateAllergy, setViewCreateAllergy] = React.useState(false);
  const [total, setTotal] = React.useState(0);
  const [size, setSize] = React.useState(25);
  const [page, setPage] = React.useState(1);
  const [allergies, setAllergies] = React.useState([]);
  const [sortOrderBy, setSortOrderBy] = React.useState("name");
  const [sortOrderDirection, setSortOrderDirection] = React.useState("asc");
  const [filter, setFilter] = React.useState(JSON.parse(JSON.stringify(filterTemplate)));


  const filterTextChange = e => {
    const obj = { ...filter };
    obj[e.target.name].value = e.target.value;
    setFilter(obj);
    if (
      String(obj[e.target.name].value).length === 0 ||
      String(obj[e.target.name].value).length >= obj[e.target.name].minValue
    ) {
      loadAllergies(page, size, sortOrderBy, sortOrderDirection, obj);
    }
  };

  const clearFilters = () => {
    setFilter(JSON.parse(JSON.stringify(filterTemplate)));
    loadAllergies(1, 25, 'name', '+', JSON.parse(JSON.stringify(filterTemplate)));
  }

  React.useEffect(() => {
      loadAllergies();
      setFilter(JSON.parse(JSON.stringify(filterTemplate)));
    // eslint-disable-next-line
  }, [  ]);

  const onChangeRowsPerPage = (e) => {
    setSize(e.target.value);
    loadAllergies(page, e.target.value, sortOrderBy, sortOrderDirection, filter);
  };

  const handleChangePage = (e, newPage) => {
    const pg = newPage + 1;
    setSize(pg);
    loadAllergies(pg, size, sortOrderBy, sortOrderDirection, filter);
  };

  const loadAllergies = async (pg = 1,
                               sz = 25,
                               srt = "name",
                               srtDirection = "+",
                               filter = {}) => {
    const filters = convertToFilters(filter);
    const response = await getAllergies(pg, sz, srt, srtDirection, filters);
    if (response?.content) {
      setAllergies(response.content.map(al => {
        al.isSelected = false;
        return al;
      }));
      setSize(response.paginator.perPage);
      setPage(response.paginator.currentPage);
      setTotal(response.paginator.totalItems);
    } else {
      addNotification('Unable to get allergies', 'error')
      setAllergies([])
    }
  }

  const createSortHandler = field => {
    const sort = field;
    const sortDirection = sortOrderDirection === "asc" ? "desc" : "asc";
    setSortOrderBy(sort);
    setSortOrderDirection(sortDirection);
    loadAllergies(page, size, sort, sortDirection, [])
  };

  const onAllergyCreated = async allergy => {
    const entity = await createAllergy(allergy);
    const obj = [...allergies, entity];
    setAllergies(obj);
    setViewCreateAllergy(false);
  }

  const onDelete = async (allergy) => {
    const response = await deleteAllergy(allergy._id);
    setAllergies(allergies?.filter(f => f._id !== response._id));
  }

  return (
    <TableContainer>
      <Grid container justify={"space-between"} alignContent={"center"}>
        <div>
          <Button variant={"contained"}
                  color={"primary"}
                  startIcon={<AddIcon />}
                  onClick={() => setViewCreateAllergy(true)}
                  style={{marginRight: '1rem'}}
                  size={"small"}>Create</Button>
          <Button size={"small"}
                  onClick={clearFilters}
                  startIcon={<CloseIcon />}>Clear Filters</Button>
          <CreateAllergy open={viewCreateAllergy}
                         onClose={() => setViewCreateAllergy(false)}
                         onSave={onAllergyCreated} />
        </div>
        <TablePagination
          component="div"
          rowsPerPageOptions={sizeOptions}
          colSpan={5}
          count={total ? total : 0}
          rowsPerPage={size ? size : 0}
          page={page - 1}
          backIconButtonProps={{
            "aria-label": "previous page",
            disabled: page === 1 ? true : false
          }}
          nextIconButtonProps={{
            "aria-label": "next page",
            disabled: page * size >= total ? true : false
          }}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onChangePage={handleChangePage}
        ></TablePagination>
      </Grid>
      <Table
        size="small"
        style={{ width: "100%" }}
        className={classes.table}
        id="pharmacy-table"
      >
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell className={classes.tableHeader}>
              <TableSortLabel
                active={sortOrderBy === "name"}
                direction={sortOrderDirection}
                onClick={() => createSortHandler("name")}
              >
                Name
              </TableSortLabel>
              <TextField
                name="name"
                margin="dense"
                size="small"
                placeholder="Search"
                variant="outlined"
                fullWidth={true}
                value={filter?.name?.value || ''}
                onChange={filterTextChange}
              ></TextField>
            </TableCell>
            <TableCell className={classes.tableHeader}>
              <TableSortLabel
                active={sortOrderBy === "description"}
                direction={sortOrderDirection}
                onClick={() => createSortHandler("description")}
              >
                Description
              </TableSortLabel>
              <TextField
                name="description"
                margin="dense"
                size="small"
                placeholder="Search"
                variant="outlined"
                fullWidth={true}
                value={filter?.description?.value || ''}
                onChange={filterTextChange}
              ></TextField>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allergies &&
          allergies.map(allergy => (
            <TableRow>
              <TableCell>
                <Button size={"small"}
                        startIcon={<DeleteIcon />}
                        className={classes.deleteBtn}
                        onClick={() => onDelete(allergy)}
                >
                  Delete
                </Button>
              </TableCell>
              <TableCell>
                <Typography component={Link} to={`/allergies/${allergy._id}`}>
                  {allergy.name}
                </Typography>
              </TableCell>
              <TableCell>
                {allergy.description}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const AlleryWrapper = () => {

  return (
    <Switch>
      <PrivateRoute
        path={"/allergies/:allergyId"}
        exact
        component={Allergy} />
      <PrivateRoute
        path={"/allergies"}
        exact
        component={Allergies} />
    </Switch>
  )
}

export default AlleryWrapper;
