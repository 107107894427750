import React from 'react';
import {Document, Page, View, Text, StyleSheet} from "@react-pdf/renderer";
import {ResidentReportHeader} from "../../../../../../reports/pdf/components/ResidentReportHeader";
import moment from "moment";
import {getStripedStyle} from "../../../../../../utils/table.utils";

const BORDER_COLOR = '#bfbfbf'
const BORDER_STYLE = 'solid'

const styles = StyleSheet.create({
  page: {
    padding: '0 0 84px 0'
  },
  headerSection: {
    backgroundColor: '#2196f3',
    color: '#fff',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '10vh'
  },
  mainContent: {
    backgroundColor: 'white',
    padding: '0 5px'
  },
  body: {
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    width: '100%',
    margin: "auto",
    flexDirection: "row"
  },
  tableColHeaderSmall: {
    width: "50px",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableColHeader: {
    width: "100px",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableColHeaderLarge: {
    width: "150px",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableColSmall: {
    width: "50px",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol: {
    width: "100px",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableColLarge: {
    width: "150px",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: 500
  },
  tableCell: {
    margin: 5,
    fontSize: 10
  },
  titleContainer: {
    paddingTop: '15px'
  },
  pageCount: {
    paddingLeft: '5px',
    paddingTop: '15px'
  },
  headerText: {
    fontSize: '20px'
  },
  bodyText: {
    fontSize: '8px'
  },
  centerAlign: {
    textAlign: 'center'
  },
  infoSection: {
    margin: '10 0'
  },
  infoText: {
    fontSize: '12px',
    padding: '3 0'
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    padding: '5px'
  },
  footerContainer: {
    marginTop: '5px',
    fontSize: '8px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  marContainer: {
    padding: '5px'
  },
  marHeaderText: {

  },
  dosageInsructionContainer: {
    paddingTop: '5px'
  },
  hrText: {
    minHeight: '25px',
    textAlign: 'center',
  },
  marValueCell: {
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0
  },
  discontinuedDate: {
    width: '100%',
    padding: '5px',
    backgroundColor: "#9e9e9e",
    color: '#fff'
  },
  alerts: {
    width: '100%',
    padding: '5px',
    backgroundColor: "#e57373",
    color: '#fff'
  },
  hideOverflow: {
    textOverflow: "ellipsis"
  },
  freqContainer: {
    minHeight: '10px',
    textAlign: 'center',
  },
  paddedTableCol: {
    paddingTop: '10px',
    width: '20px',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
});


export const ResidentEprnMarPdf = ( {resident, data, month, year}) => {
  return (

    <Document size={"a4"} orientation={"landscape"} >
      <Page size={"A4"} orientation={"landscape"} style={styles.page}>
        <ResidentReportHeader resident={resident}
                              location={resident?.location}
                              allUserLocations={false}
                              displayResidentInfo={true}
                              reportTitle={`ePrn MAR - ${month} ${year}`}
        />
        <View style={styles.mainContent}>
          <View style={styles.table}>
            <View style={styles.tableRow} fixed>
              <View style={styles.tableColHeaderSmall}>
                <Text style={styles.tableCellHeader}>Date</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Medication</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Dosage Form</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Strength</Text>
              </View>
              <View style={styles.tableColHeaderSmall}>
                <Text style={styles.tableCellHeader}>Hours</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Administered By</Text>
              </View>
              <View style={styles.tableColHeaderSmall}>
                <Text style={styles.tableCellHeader}>Qty</Text>
              </View>
              <View style={styles.tableColHeaderLarge}>
                <Text style={styles.tableCellHeader}>Reason</Text>
              </View>
              <View style={styles.tableColHeaderLarge}>
                <Text style={styles.tableCellHeader}>Outcomes</Text>
              </View>
            </View>
            {data?.map((data, i) =>
            <View style={[styles.tableRow, getStripedStyle(i)]} wrap>
              <View style={styles.tableColSmall}>
                <Text style={[styles.tableCell, styles.bodyText]}>
                  {moment(data?.administeredTimestamp).format("MM/DD/YYYY")}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.bodyText]}>
                  {data?.medicationOrder?.medication?.tradeName}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.bodyText]}>
                  {data?.medicationOrder?.medication?.dosageForm?.name}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.bodyText]}>
                  {data?.medicationOrder?.medication?.strength} {data?.medicationOrder?.medication?.unit?.name}
                </Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={[styles.tableCell, styles.bodyText]}>
                  {moment(data?.administeredTimestamp).format("hh:mm a")}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.bodyText]}>
                  {data?.administeredUser?.firstName}{" "}{data?.administeredUser?.lastName}
                </Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={[styles.tableCell, styles.bodyText]}>
                  {data?.passingQty}
                </Text>
              </View>
              <View style={styles.tableColLarge}>
                <Text style={[styles.tableCell, styles.bodyText]}>
                  {data?.reason}
                </Text>
              </View>
              <View style={styles.tableColLarge}>
                <Text style={[styles.tableCell, styles.bodyText]}>
                  {data?.outcomes?.sort((a,b) => a?.entryDate > b?.entryDate ? 1: -1)?.map(outcome =>
                    <View>
                      {outcome?.comment}
                      {outcome?.user ?
                        <Text> ({outcome?.user?.firstName}{' '}{outcome?.user?.lastName})</Text> :
                        null
                      }
                    </View>
                  )}
                </Text>
              </View>
            </View>
            )}
          </View>
        </View>
        <View style={styles.footer} fixed>
          <View style={styles.footerContainer}>
            <View>
              <Text>
                {moment().format("MM/DD/YYYY hh:mm a")}
              </Text>
            </View>
            <View>
              <Text render={({ pageNumber, totalPages }) => (
                `page ${pageNumber} of ${totalPages}`
              )} fixed />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}
