import React from 'react';
import {
  Button,
  CircularProgress, Collapse,
  FormControl,
  Grid, IconButton,
  InputLabel,
  LinearProgress,
  makeStyles,
  MenuItem,
  Select
} from "@material-ui/core";
import {UserContext} from "../../../../context/usercontext";
import ResidentLocationSearch from "../../../resident/search/resident.location.search";
import MomentUtils from "@date-io/moment";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment";
import {PictureAsPdf} from "@material-ui/icons";
import {downloadCensusReport} from "../../../../services/report.service";
import Alert from "@material-ui/lab/Alert";
import {AlertTitle} from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1)
  },
  formContainer: {
    width: '100%'
  },
  searchContainer: {
    marginTop: theme.spacing(1)
  },
  btnContainer: {
    padding: theme.spacing(1, 0)
  }
}));

export const ResidentCensus = () => {
  const classes = useStyles();
  const {selectedTenant, tenantLocations} = React.useContext(UserContext);
  const [tenantLocation, setTenantLocation] = React.useState();
  const [selectedResident, setSelectedResident] = React.useState();
  const [error, setError] = React.useState();
  const [startDate, setStartDate] = React.useState(moment());
  const [endDate, setEndDate] = React.useState(moment());
  const [residentType, setResidentType] = React.useState();
  const [dateType, setDateType] = React.useState("range");
  const [loadingReport, setLoadingReport] = React.useState(false);
  const [reportData, setReportData] = React.useState();

  const onTenantLocationChange = async e => {
    setTenantLocation(e.target.value);
  }

  const handleClear = () => {
    setSelectedResident(null);
  }

  const handleResidentSelect = e => {
    setSelectedResident(e);
  }

  const onStartDateChange = date => {
    setStartDate(date)
  }

  const onEndDateChange = date => {
    setEndDate(date)
  }

  const onResidentTypeChange = e => {
    setResidentType(e.target.value);
  }

  const onDateTypeChange = e => {
    setDateType(e.target.value);
    switch (e.target.value) {
      case 'range':
        setStartDate(moment().startOf('d'));
        setEndDate(moment().endOf('d'))
        break;
      case 'currentMonth':
        setStartDate(moment().startOf('month'));
        setEndDate(moment().endOf('month'));
        break;
      case 'lastMonth':
        setStartDate(moment().subtract(1, 'month').startOf('month'));
        setEndDate(moment().subtract(1, 'month').endOf('month'));
        break;
      case 'last6Months':
        setStartDate(moment().subtract(6, 'month').startOf('month'));
        setEndDate(moment().endOf('month'));
        break;
      case 'lastYear':
        setStartDate(moment().startOf('year'));
        setEndDate(moment().endOf('year'));
        break;
      case 'today':
        setStartDate(moment().startOf('d'));
        setEndDate(moment().endOf('d'));
        break;
      default:
        setStartDate(moment().startOf('d'));
        setEndDate(moment().endOf('d'));
        break;
    }
  }

  const isValid = () => {
    return startDate && endDate;
  }

  const onGenerateReport = async () => {
    setReportData(null);
    setError(false);
    setLoadingReport(true);
    const response = await downloadCensusReport(
      selectedTenant?._id,
      tenantLocation ?? -1,
      selectedResident?._id ?? -1,
      residentType,
      startDate,
      endDate);
      const fileUrl = URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute(
        'download',
        `census.pdf`,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    if (!response?.error) {
      setReportData(response);
    } else {
      setError(true);
    }
    setLoadingReport(false);
  }

  return (
    <div>
      <div className={classes.formContainer}>
        {loadingReport && <LinearProgress />}
        <Collapse in={error}>
          <Alert severity={"error"}
                 action={
                   <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     onClick={() => {
                       setError(false);
                     }}
                   >
                     <CloseIcon fontSize="inherit" />
                   </IconButton>
                 }
          >
            <AlertTitle>Unable to create report.</AlertTitle>
          </Alert>
        </Collapse>
        <FormControl fullWidth disabled={loadingReport}>
          <InputLabel shrink>Location</InputLabel>
          <Select fullWidth
                  displayEmpty
                  value={tenantLocation}
                  onChange={onTenantLocationChange}
          >
            <MenuItem>All</MenuItem>
            {tenantLocations?.map(location =>
              <MenuItem value={location?._id}>{location?.name}</MenuItem>)
            }
          </Select>
        </FormControl>
        <div className={classes.searchContainer}>
          <ResidentLocationSearch includeDischarged={true}
                                  onClear={handleClear}
                                  onSelect={handleResidentSelect}
                                  reportData={reportData}
                                  tenantLocationId={tenantLocation ?? -1}
                                  disabled={loadingReport}
          />
        </div>
        <FormControl fullWidth disabled={loadingReport}>
          <InputLabel shrink>Resident Type</InputLabel>
          <Select fullWidth
                  displayEmpty
                  value={residentType}
                  onChange={onResidentTypeChange}>
            <MenuItem>All</MenuItem>
            <MenuItem value={"current"}>Current</MenuItem>
            <MenuItem value={"discharge"}>Discharge</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth disabled={loadingReport}>
          <InputLabel shrink>Date Range Options</InputLabel>
          <Select fullWidth
                  displayEmpty
                  value={dateType}
                  onChange={onDateTypeChange}>
            <MenuItem value={"range"}>Date Range</MenuItem>
            <MenuItem value={"currentMonth"}>Current Month</MenuItem>
            <MenuItem value={"lastMonth"}>Last Month</MenuItem>
            <MenuItem value={"last6Months"}>Last 6 Months</MenuItem>
            <MenuItem value={"lastYear"}>Last Year</MenuItem>
            <MenuItem value={"today"}>Today</MenuItem>
          </Select>
        </FormControl>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDateTimePicker
            disabled={loadingReport}
            size="small"
            fullWidth
            name={"startDate"}
            required
            value={startDate}
            label={"Start Date"}
            format="MM/DD/YYYY hh:mm a"
            onChange={(date) => onStartDateChange(date)}
            allowKeyboardControl="true"
          />
          <KeyboardDateTimePicker
            disabled={loadingReport}
            size="small"
            fullWidth
            name={"endDate"}
            required
            value={endDate}
            label={"End Date"}
            format="MM/DD/YYYY hh:mm a"
            onChange={(date) => onEndDateChange(date)}
            allowKeyboardControl="true"
          />
        </MuiPickersUtilsProvider>
      </div>
      <Grid container
            className={classes.btnContainer}
            justifyContent={"flex-end"}>
        <Button startIcon={loadingReport ? <CircularProgress size={20} />: <PictureAsPdf />}
                variant={"contained"}
                disabled={loadingReport || !isValid()}
                onClick={onGenerateReport}
                color={"primary"}>
          Generate Report
        </Button>
      </Grid>
    </div>
  )
}
