import React from 'react';
import { UserContext } from '../../context/usercontext';
import { Typography } from '@material-ui/core';

const Tenant = () => {
  const { state } = React.useContext(UserContext);

  if (state && state.selectedTenant) {
    return (
      <div>
        <Typography variant="h6">{state.selectedTenant.name}</Typography>
        {state.selectedTenantLocation && (
          <Typography variant="subtitle1">
            {state.selectedTenantLocation.name}
          </Typography>
        )}
        {!state.selectedTenantLocation && (
          <Typography variant="subtitle2">All locations</Typography>
        )}
      </div>
    );
  } else {
    return null;
  }
};

export default Tenant;
