import React from 'react';
import {
    Button,
    ButtonGroup,
    Grid,
    Hidden,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import CreateIncontinenceLog from './create.incontinence.bm.log';
import BmLog from './bm.log';
import TableLoadingIndicator from "../../../../utils/indicators/table.loading";
import {DateRangeSortFilterLabel, SortFilterLabel, SortFilterSelectLabel} from "../../../utils/table.utils";
import model from "./bm.model";
import {ClearAllTwoTone, PictureAsPdf} from "@material-ui/icons";
import {DeleteBtn} from "../../../utils/buttons/DeleteBtn";
import {MobileLogs} from "../mobile/mobile.logs";
import {ListLoading} from "../../../../utils/indicators/list.loading";
import useHasRole from "../../../../hooks/user.role.hook";
import {PageTitle} from "../../../utils";
import BMReportModal from "./print";


const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(.5, 0),
        padding: theme.spacing(1),
    },
}));

const ResidentIncontinenceBMLogs = ({
                                        residentId,
                                        logs,
                                        onSave,
                                        onUpdateLog,
                                        onDeleteLog,
                                        date,
                                        canUpdate,
                                        filter,
                                        sortOrderBy,
                                        sortDirection,
                                        createSortHandler,
                                        filterTextChange,
                                        onDateRangeChange,
                                        loading,
                                        users,
                                        page,
                                        size,
                                        total,
                                        onChangePage,
                                        onChangeRowsPerPage,
                                        rowsPerPageOptions,
                                        onClearFilters,
                                        ...args
                                    }) => {
    const classes = useStyles();
    const [createIncontinence, setCreateIncontinence] = React.useState(false);
    const [viewLogModal, setViewLogModal] = React.useState(false);
    const [selectedLog, setSelectedLog] = React.useState();
    const [viewPrintModal, setViewPrintModal] = React.useState(false);
    const {checkPermission} = useHasRole();
    const canCreateIncontinence = checkPermission("INCONTINENCE_LOG_CREATE");
    const canDeleteIncontinence = checkPermission("INCONTINENCE_LOG_DELETE");

    const createIncontinenceLog = (log) => {
        onSave(log);
        setCreateIncontinence(false);
    };

    const selectLog = (e) => {
        setSelectedLog(e);
        setViewLogModal(true);
    };

    const onUpdate = (id, log) => {
        onUpdateLog(id, log);
        setViewLogModal(false);
    };

    const getTimeLabel = (time) => {
        if (time && model && model.time) {
            const item = model.time.dropDownItems.find(
                (t) => String(t.value) === String(time)
            );
            if (item) {
                return item.name;
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    return (
        <Paper elevation={24} className={classes.root} {...args}>
            <PageTitle title={"Incontinence Bowel Movement Logs"}>
                <Grid container justifyContent={"flex-end"}>
                    <Button startIcon={<PictureAsPdf/>}
                            onClick={() => setViewPrintModal(true)}
                    >
                        Print
                    </Button>
                </Grid>
            </PageTitle>
            <BMReportModal open={viewPrintModal}
                           residentId={residentId}
                           onClose={() => setViewPrintModal(false)}
            />
            {canCreateIncontinence &&
                <Button
                    startIcon={<AddIcon/>}
                    onClick={() => setCreateIncontinence(true)}
                >
                    Add New Log
                </Button>
            }
            <CreateIncontinenceLog
                open={createIncontinence}
                onSave={createIncontinenceLog}
                users={users}
                onClose={() => setCreateIncontinence(false)}
                date={date}
            />
            <BmLog
                open={viewLogModal}
                log={selectedLog}
                onClose={() => setViewLogModal(false)}
                onUpdate={onUpdate}
                canUpdate={canCreateIncontinence}
                users={users}
            />
            <Grid container justify={"flex-end"} alignItems={"center"}>
                <Hidden xsDown>
                    <Button onClick={onClearFilters}
                            size={"small"}
                            startIcon={<ClearAllTwoTone/>}
                    >
                        Clear Filters
                    </Button>
                </Hidden>
                <TablePagination component={"div"}
                                 count={total}
                                 onChangePage={onChangePage}
                                 onChangeRowsPerPage={onChangeRowsPerPage}
                                 rowsPerPageOptions={rowsPerPageOptions}
                                 page={page - 1}
                                 rowsPerPage={size}/>
            </Grid>
            <Hidden xsDown>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableCell/>
                            <TableCell>
                                <DateRangeSortFilterLabel onDateRangeChange={onDateRangeChange}
                                                          createSortHandler={createSortHandler}
                                                          sortDirection={sortDirection}
                                                          sortOrderBy={sortOrderBy}
                                                          filter={filter}
                                                          fromDate={filter?.date?.value?.start}
                                                          toDate={filter?.date?.value?.end}
                                                          field={"date"}
                                                          label={"Date"}/>
                            </TableCell>
                            <TableCell>Hour</TableCell>
                            <TableCell>
                                <SortFilterSelectLabel onChange={filterTextChange}
                                                       createSortHandler={createSortHandler}
                                                       sortDirection={sortDirection}
                                                       sortOrderBy={sortOrderBy}
                                                       filter={filter}
                                                       field={"pattern"}
                                                       label={model?.pattern?.title}
                                                       options={model?.pattern?.dropDownItems}
                                                       optionLabel={"name"}
                                                       optionValue={"value"}
                                />
                            </TableCell>
                            <TableCell>
                                <SortFilterSelectLabel onChange={filterTextChange}
                                                       createSortHandler={createSortHandler}
                                                       sortDirection={sortDirection}
                                                       sortOrderBy={sortOrderBy}
                                                       filter={filter}
                                                       field={"color"}
                                                       label={model?.color?.title}
                                                       options={model?.color?.dropDownItems}
                                                       optionLabel={"name"}
                                                       optionValue={"value"}
                                />
                            </TableCell>
                            <TableCell>
                                <SortFilterSelectLabel onChange={filterTextChange}
                                                       createSortHandler={createSortHandler}
                                                       sortDirection={sortDirection}
                                                       sortOrderBy={sortOrderBy}
                                                       filter={filter}
                                                       field={"consistency"}
                                                       label={model?.consistency?.title}
                                                       options={model?.consistency?.dropDownItems}
                                                       optionLabel={"name"}
                                                       optionValue={"value"}
                                />
                            </TableCell>
                            <TableCell>
                                <SortFilterLabel filterTextChange={filterTextChange}
                                                 createSortHandler={createSortHandler}
                                                 sortDirection={sortDirection}
                                                 sortOrderBy={sortOrderBy}
                                                 filter={filter}
                                                 field={"comments"}
                                                 label={"Comments"}/>
                            </TableCell>
                            <TableCell>
                                <SortFilterSelectLabel onChange={filterTextChange}
                                                       createSortHandler={createSortHandler}
                                                       sortDirection={sortDirection}
                                                       sortOrderBy={sortOrderBy}
                                                       filter={filter}
                                                       field={"time"}
                                                       label={"Time"}
                                                       options={model?.time?.dropDownItems}
                                                       optionLabel={"name"}
                                                       optionValue={"value"}
                                />
                            </TableCell>
                            <TableCell>
                                <SortFilterSelectLabel onChange={filterTextChange}
                                                       createSortHandler={createSortHandler}
                                                       sortDirection={sortDirection}
                                                       sortOrderBy={sortOrderBy}
                                                       filter={filter}
                                                       field={"staff"}
                                                       label={"Staff"}
                                                       options={users}
                                                       optionLabelFunc={(item) => `${item?.firstName} ${item?.lastName}`}
                                                       optionValue={"_id"}
                                />
                            </TableCell>
                        </TableHead>
                        {!loading &&
                            <TableBody>
                                {logs?.map((log) => (
                                    <TableRow>
                                        <TableCell>
                                            <ButtonGroup size={"small"}>
                                                <Button onClick={() => selectLog(log)}
                                                        variant={"contained"}
                                                        color={"primary"}
                                                >
                                                    View
                                                </Button>
                                                {canDeleteIncontinence &&
                                                    <DeleteBtn onClick={() => onDeleteLog(log._id)}/>
                                                }
                                            </ButtonGroup>
                                        </TableCell>
                                        <TableCell>{moment(log.date).format('MM/DD/YYYY')}</TableCell>
                                        <TableCell>{moment(log.hour).format('hh:mm a')}</TableCell>
                                        <TableCell>{log.pattern}</TableCell>
                                        <TableCell>{log.color}</TableCell>
                                        <TableCell>{log.consistency}</TableCell>
                                        <TableCell>{log.comments}</TableCell>
                                        <TableCell>{getTimeLabel(log.time)}</TableCell>
                                        <TableCell>{log.staff && `${log.staff.name}`}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>}
                        {loading && <TableLoadingIndicator cols={12} rows={5} bodyOnly={true}/>}
                    </Table>
                </TableContainer>
            </Hidden>
            <Hidden smUp>
                {!loading &&
                    <MobileLogs logs={logs}
                                primaryText={(log) => log?.pattern}
                                secondaryText={(log) => <>
                                    {moment(log?.date).format('MM/DD/YYYY h:mm a')}
                                    <Typography variant={"subtitle2"}>{log?.color} - {log?.consistency}</Typography>
                                </>}
                                canUpdate={canCreateIncontinence}
                                onDeleteLog={onDeleteLog}
                                selectLog={selectLog}
                    />}
                {loading &&
                    <ListLoading rows={4}/>
                }
            </Hidden>
        </Paper>
    );
};

export default ResidentIncontinenceBMLogs;
