import React from 'react';
import {
  Button,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Select,
  MenuItem,
  ButtonGroup,
  Avatar,
  List,
  TextField,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import moment from 'moment';
import DateNavigation from '../../medicationorders/date.navigation';
import {
  createResidentEtar,
  createResidentTreatment, deleteResidentEtar,
  getResidenteTars,
  getResidentTreatments,
  updateResidentETar,
} from '../../../../services/treatment.service';
import EditIcon from '@material-ui/icons/Edit';
import {
  red,
  yellow,
  green,
  grey,
  orange,
  blue,
} from '@material-ui/core/colors';
import MobileEtar from './mobile.tar';
import EtarDialog from './etar.dialog';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';
import CreateResidentTreatment from '../create.treatment';
import Skeleton from '@material-ui/lab/Skeleton';
import { ResidentContext } from '../../../../context/residentcontext';
import useHasRole from "../../../../hooks/user.role.hook";
import {PageTitle} from "../../../utils";
import {ListAlt, RadioButtonUncheckedRounded} from "@material-ui/icons";
import {Link as RouterLink} from "react-router-dom";
import VitalInput from "./VitalInput";
import {RetryMessage} from "../../../notification/retry.message";
import {UserContext} from "../../../../context/usercontext";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import ConfirmDialog from "../../../utils/dialogs/confirm.dialog";
import useGlobalNotification from "../../../../hooks/notification.hook";
import {validateVitals} from "../../../../utils/vitals/vitals.utils";


const contrast = 400;
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  container: {
    display: 'flex',
  },
  day: {
    flex: 1,
    border: '1px solid black',
    textAlign: 'center',
    padding: '.25rem',
  },
  tableDayColumn: {
    minWidth: '50px',
    maxWidth: '50px',
    textAlign: 'center',
    borderLeft: '1px solid gray',
    borderRight: '1px solid gray',
  },
  avatarSmall: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  scheduled: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: grey[500],
  },
  readyToPass: {
    background: grey[700],
  },
  readyToPassIcon: {
    color: grey[700]
  },
  given: {
    background: green[contrast],
  },
  givenIcon: {
    color: green[contrast]
  },
  late: {
    background: orange[contrast],
  },
  lateIcon: {
    color: orange[contrast]
  },
  missed: {
    background: red[contrast],
  },
  missedIcon: {
    color: red[contrast]
  },
  hold: {
    background: yellow[contrast],
  },
  holdIcon: {
    color: yellow[contrast]
  },
  sa: {
    background: blue[contrast],
  },
  saIcon: {
    color: blue[contrast]
  },
  na: {
    background: grey[contrast],
  },
  naIcon: {
    color: grey[contrast]
  },
  refused: {
    background: orange[contrast],
  },
  refusedIcon: {
    color: orange[contrast]
  },
  destruction: {
    background: yellow[contrast],
  },
  destructionIcon: {
    color: yellow[contrast]
  },
  statusName: {
    marginLeft: theme.spacing(1),
  },
  skeleton: {
    height: '3rem',
    margin: theme.spacing(1, 0),
  },
}));

const ResidentTars = ({ match }) => {
  const classes = useStyles();
  const [residentId, setResidentId] = React.useState();
  const [etars, setEtars] = React.useState([]);
  const [editTar, setEditTar] = React.useState(null);
  const [editTarIndex, setEditTarIndex] = React.useState(-1);
  const [viewConfirmDeleteEtarModal, setViewConfirmDeleteEtarModal] = React.useState(false);
  const [selectedTar, setSelectedTar] = React.useState(null);
  const [showTarDialog, setShowTarDialog] = React.useState(false);
  const [searchLoading, setSearchLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [prns, setPrns] = React.useState([]);
  const [date, setDate] = React.useState(new Date());
  const [
    showCreateTreatmentDialog,
    setShowCreateTreatmentDialog,
  ] = React.useState(false);
  const { checkPermission } = useHasRole();
  const canUpdate = checkPermission('ETAR_UPDATE');
  const canDelete = checkPermission('ETAR_DELETE');
  const [users, setUsers] = React.useState();
  const { getUsers } = React.useContext(UserContext);
  const { dispatch } = React.useContext(ResidentContext);
  const { addNotification } = useGlobalNotification();
  React.useEffect(() => {
    if (match.params.residentId) {
      setResidentId(match.params.residentId);
      loadData(match.params.residentId, new Date());
      loadPrns(match.params.residentId);
      loadUsers();
    }
    // eslint-disable-next-line
  }, [match.params.residentId]);

  const loadUsers = async () => {
    const userResponse = await getUsers();
    if (!userResponse?.error) {
      setUsers(userResponse);
    }
  }

  const loadPrns = async (residentId) => {
    const response = await getResidentTreatments(residentId, true);
    setPrns(response.content);
  };

  const onDateChange = (date) => {
    setDate(date);
    setEditTarIndex(-1);
    setEditTar(null);
    loadData(residentId, date);
  };

  const retry = async () => {
    await loadData(residentId, date);
  }

  const loadData = async (resId, date) => {
    setError(false);
    setSearchLoading(true);
    if (!users || users?.length < 1) {

    }

    const offset = new Date().getTimezoneOffset();
    const response = await getResidenteTars(
      resId,
      moment(date).format('YYYY-MM-DD'),
      offset
    );
    if (!response?.canceled && !response?.error) {
      setEtars(response);
      setSearchLoading(false);
    } else if (response.error) {
      setError(true);
      setSearchLoading(false);
    }
  };

  const edit = (etar, index) => {
    const obj = JSON.parse(JSON.stringify(etar));
    if (obj?.status?.name === "Scheduled" || obj?.status?.name === "Late" || obj?.status?.name === "Missed") {
      obj.status.name = "Given";
    }
    setEditTarIndex(index);
    setEditTar(obj);
  };

  const onEditStatus = (e) => {
    const obj = { ...editTar };
    if (obj) {
      if (obj.status) {
        obj.status.name = e.target.value;
      } else {
        obj.status = { name: e.target.value };
      }
    }
    setEditTar(obj);
  };

  const onUpdate = async (payload) => {
    if (payload._id) {
      const tar = await updateResidentETar(
        residentId,
        payload.treatment,
        payload._id,
        payload
      );
      const obj = etars.map((e) => (e._id === tar._id ? tar : e));
      setEtars(obj);
      setShowTarDialog(false);
    } else {
      // Create PRN
      // Update
      const tar = await createResidentEtar(
        residentId,
        payload.treatment,
        payload
      );
      const obj = etars.map((e) => (!e._id ? tar : e));
      setEtars(obj);
      setShowTarDialog(false);
    }
    dispatch({ type: 'REFRESH_ALERTS' });
  };

  const saveTar = async () => {
    if (editTar._id) {
      // Update
      const tar = await updateResidentETar(
        residentId,
        editTar.treatment,
        editTar._id,
        editTar
      );
      const obj = etars?.map(t => {
        if (t?._id === tar?._id) {
          t.status.name = tar?.status?.name;
          t.administeredTimestamp = tar?.administeredTimestamp;
          t.administeredUser = tar?.administeredUser;
          t.notes = tar?.notes;
          t.vital = tar?.vital;
        }
        return t;
      });
      setEtars(obj);
      setEditTarIndex(-1);
      addNotification("eTar Updated", "success");
    } else {
      // Create PRN
      // Update
      const tar = await createResidentEtar(
        residentId,
        editTar.treatment,
        editTar
      );
      const obj = etars.map((e) => (!e._id ? tar : e));
      setEtars(obj);
      setEditTarIndex(-1);
    }
    dispatch({ type: 'REFRESH_ALERTS' });
  };

  const canEdit = (event) => {
    if (!canUpdate) return false;
    if (moment(event.scheduledTime).subtract(1, 'hour') < moment()) {
      return true;
    } else {
      return false;
    }
  };

  const getItemClass = (event) => {
    if (event && event.status && event.status.name) {
      switch (event.status.name) {
        case 'Scheduled':
          if (canEdit(event)) {
            return classes.readyToPass;
          } else {
            return classes.scheduled;
          }
        case 'Given':
          return classes.given;
        case 'Late':
          return classes.late;
        case 'Missed':
          return classes.missed;
        case 'Hold':
          return classes.hold;
        case 'SA':
          return classes.sa;
        case 'NA':
          return classes.na;
        case 'Refused':
          return classes.refused;
        case 'Destruction':
          return classes.destruction;
        default:
          return classes.scheduled;
      }
    }
  };

  const onSelected = (etar) => {
    setSelectedTar(etar);
    setShowTarDialog(true);
  };

  const onNotesChange = (e) => {
    const obj = { ...editTar };
    obj.notes = e.target.value;
    setEditTar(obj);
  };

  const displayTime = (minutes) => {
    if (minutes >= 60) {
      const hours = minutes / 60;
      return `${hours} hour${minutes > 60 ? 's' : ''}`;
    } else {
      return `${minutes} minutes`;
    }
  };

  const onPrnSelect = (e, newValue) => {
    if (newValue) {
      // Create etar
      const etar = {
        name: newValue.name,
        description: newValue.description,
        scheduledTime: Date.now(),
        resident: match.params.residentId,
        time: newValue.time,
        treatment: newValue._id,
        status: {
          name: 'Scheduled',
        },
      };
      let obj = [etar, ...etars];
      // obj = obj.sort((a, b) => b.scheduledTime - a.scheduledTime);
      setEtars(obj);
      setEditTar(obj[0]);
      setEditTarIndex(0);
    }
  };

  const cancelEdit = () => {
    if (editTar && !editTar._id) {
      const obj = etars.filter((t) => t._id);
      setEtars(obj);
    }
    setEditTarIndex(-1);
  };

  const onCreateTreatment = async (e) => {
    const response = await createResidentTreatment(residentId, e);
    setPrns([response, ...prns]);
    setShowCreateTreatmentDialog(false);
  };

  const dialogCancel = (e) => {
    setShowTarDialog(false);
  };

  const updateVital = (etarId, payload) => {
    const obj = {...editTar};
    obj.updatedVital = payload;
    setEditTar(obj);
  }

  const onAdministeredDateChange = date => {
    const obj = {...editTar};
    obj.administeredTimestamp = date;
    obj.updateScheduledTime = date;
    setEditTar(obj);
  }
  const onAdministeredUserChange = e => {
    const obj = {...editTar};
    const user = users?.find(u => u._id === e.target.value);
    obj.administeredUser = user;
    obj.updateUser = user;
    setEditTar(obj);
  }


  const getItemIconClass = event => {
    if (event?.status?.name) {
      switch (event.status.name) {
        case 'Scheduled':
          if (canEdit(event)) {
            return classes.readyToPassIcon;
          } else {
            return classes.scheduledIcon;
          }
        case 'Given':
          return classes.givenIcon;
        case 'Late':
          return classes.lateIcon;
        case 'Missed':
          return classes.missedIcon;
        case 'Hold':
          return classes.holdIcon;
        case 'SA':
          return classes.saIcon;
        case 'NA':
          return classes.naIcon;
        case 'Refused':
          return classes.refusedIcon;
        case 'Destruction':
          return classes.destructionIcon;
        default:
          return classes.scheduledIcon;
      }
    }
  }

  const quickUpdate = async (etar, status) => {
    etar.status.name = status;
    const updatedEtar = await updateResidentETar(
      residentId,
      etar.treatment,
      etar._id,
      etar
    );
    if (!updatedEtar?.error) {
      setEtars(etars?.map(tar => {
        if (tar?._id === etar?._id) {
          tar.status.name = status;
          tar.administeredTimestamp = updatedEtar?.administeredTimestamp;
          tar.administeredUser = updatedEtar?.administeredUser;
        }
        return tar;
      }));
      addNotification("eTar Updated", "success");
    }
  }

  const onQuickUpdate = async (etar, status) => {
    await quickUpdate(etar, status);
  }

  const handleRowClick = (event, etar) => {
    event.preventDefault();
    if (canDelete) {
      setSelectedTar(etar);
      setViewConfirmDeleteEtarModal(true);
    }
  }

  const handleDeleteEtar = async () => {
    if (canDelete) {
      const response = await deleteResidentEtar(residentId, selectedTar?.treatment, selectedTar?._id);
      if (!response?.error) {
        addNotification("eTar Deleted", "success");
        const obj = etars?.filter(etar => etar?._id !== selectedTar?._id);
        setEtars(obj);
        setViewConfirmDeleteEtarModal(false);
      } else {
        addNotification("Unable to delete eTar");
      }
    }
  }

  const isValidTar = () => {
    if (editTar?.chartVitals?.length > 0) {
      if (!editTar?.vital && !editTar?.updatedVital) {
        return false;
      }
      if (!editTar.updatedVital && editTar.vital) {
        editTar.updatedVital = editTar.vital;
      }
      return validateVitals(editTar?.chartVitals, editTar?.updatedVital);
    } else {
      return true;
    }
  }

  return (
    <Paper className={classes.root} elevation={24}>
      <Grid container
            justify={"space-between"}>
        <Grid item>
          <PageTitle title={"Resident eTar"} />
        </Grid>
        <Grid item>
          <Button startIcon={<ListAlt/>}
                  component={RouterLink}
                  to={`/residents/${residentId}/etar/log`}
                  color={"primary"}>
            LOG
          </Button>
        </Grid>
      </Grid>
      <ConfirmDialog title={"Delete eTar?"}
                     message={"Do you really want to delete this eTar? If please confirm."}
                     open={viewConfirmDeleteEtarModal}
                     onClose={() => setViewConfirmDeleteEtarModal(false)}
                     onConfirm={handleDeleteEtar}
      />
      {canUpdate &&
      <div style={{ display: 'flex', width: '100%' }}>
        <div style={{ display: 'block', minWidth: '15rem', flex: 2 }}>
          <Autocomplete
            style={{ height: '3.5rem' }}
            labelId="autocomplete-label"
            title="PRN"
            freeSolo
            id="combo-box-demo"
            options={prns}
            autoHighlight
            autoCorrect
            getOptionLabel={(option) => option.name}
            onChange={onPrnSelect}
            renderOption={(option, { selected }) => (
              <ListItem>
                <ListItemAvatar>
                  <Avatar>T</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={option.name}
                  secondary={`${option.description} ${displayTime(
                    option.time
                  )}`}
                />
              </ListItem>
            )}
            loading={searchLoading}
            renderInput={(params) => (
              <TextField {...params} label="Add PRN" variant="outlined" />
            )}
          />
        </div>
        <Button
          style={{ flex: 1, height: '3.5rem' }}
          onClick={() => setShowCreateTreatmentDialog(true)}
          variant="outlined"
          startIcon={<AddIcon />}
        >
          Add
        </Button>
      </div>}
      <DateNavigation onDateChange={onDateChange} />
      {!searchLoading && error &&
        <RetryMessage severity={"error"} message={"Error loading etars"} onRetry={retry} />
      }
      {!error && !searchLoading && (
        <React.Fragment>
          <Hidden mdDown>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Treatment</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell />
                    <TableCell>Status</TableCell>
                    <TableCell>Administered</TableCell>
                    <TableCell>Administered By</TableCell>
                    <TableCell>Notes</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {etars?.map((etar, i) => (
                    <>
                      <TableRow onContextMenu={(event) => handleRowClick(event, etar)}
                                style={{cursor: 'context-menu'}}>
                        <TableCell>{etar.name}</TableCell>
                        <TableCell>{etar.description}</TableCell>
                        <TableCell>
                          <Tooltip
                            title={
                              <div>
                                <div>
                                  <Typography variant="subtitle1">
                                    {moment(etar.scheduledTime).format(
                                      'MM/DD/YYYY h:mm a'
                                    )}
                                  </Typography>
                                </div>
                                <div>
                                  <Typography variant="subtitle1">
                                    UTC:{' '}
                                    {moment(etar.scheduledTime)
                                      .utc()
                                      .format('MM/DD/YYYY h:mm a')}
                                  </Typography>
                                </div>
                                <div>
                                  <Typography variant="subtitle1">
                                    Start Local:{' '}
                                    {moment(etar.start).format(
                                      'MM/DD/YYYY h:mm a'
                                    )}
                                  </Typography>
                                </div>
                                <div>
                                  <Typography variant="subtitle1">
                                    Start:{' '}
                                    {moment(etar.start)
                                      .utc()
                                      .format('MM/DD/YYYY h:mm a')}
                                  </Typography>
                                </div>
                                <div>
                                  <Typography variant="subtitle1">
                                    End local:{' '}
                                    {moment(etar.end).format(
                                      'MM/DD/YYYY h:mm a'
                                    )}
                                  </Typography>
                                </div>
                                <div>
                                  <Typography variant="subtitle1">
                                    End:{' '}
                                    {moment(etar.end)
                                      .utc()
                                      .format('MM/DD/YYYY h:mm a')}
                                  </Typography>
                                </div>
                              </div>
                            }
                          >
                            <div>
                              {moment(etar.scheduledTime).format('h:mm a')}
                            </div>
                          </Tooltip>
                        </TableCell>
                        {editTarIndex === i && (
                          <React.Fragment>
                            <TableCell>
                              <ButtonGroup size="small">
                                <Button
                                  onClick={saveTar}
                                  color="primary"
                                  variant="contained"
                                  disabled={!isValidTar()}
                                >
                                  Save
                                </Button>
                                <Button onClick={cancelEdit}>Cancel</Button>
                              </ButtonGroup>
                            </TableCell>
                            <TableCell>
                              <Select
                                id="status"
                                label="Status"
                                value={editTar?.status?.name}
                                name="status"
                                onChange={onEditStatus}
                              >
                                <MenuItem value={'Scheduled'}>
                                  Scheduled
                                </MenuItem>
                                <MenuItem value={'Given'}>Given</MenuItem>
                                <MenuItem value={'Late'}>Late</MenuItem>
                                <MenuItem value={'Missed'}>Missed</MenuItem>
                                <MenuItem value={'Hold'}>Hold</MenuItem>
                                <MenuItem value={'SA'}>
                                  Self Administered
                                </MenuItem>
                                <MenuItem value={'NA'}>
                                  Not Administered
                                </MenuItem>
                                <MenuItem value={'Refused'}>Refused</MenuItem>
                              </Select>
                            </TableCell>
                            <TableCell>
                              <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDateTimePicker value={editTar?.administeredTimestamp}
                                                        format={"MM/DD/YYYY hh:mm a"}
                                                        onChange={onAdministeredDateChange}
                                />
                              </MuiPickersUtilsProvider>
                            </TableCell>
                            <TableCell>
                              <Select fullWidth
                                      value={editTar?.administeredUser?._id}
                                      onChange={onAdministeredUserChange}
                              >
                                {users?.sort((a, b) => a?.lastName > b?.lastName ? 1: -1)?.map(user =>
                                  <MenuItem value={user?._id}>{user?.firstName} {user?.lastName} ({user?.initials})</MenuItem>
                                )}
                              </Select>
                            </TableCell>
                            <TableCell>
                              <TextField
                                name="notes"
                                multiline
                                rows={3}
                                value={editTar.notes}
                                onChange={onNotesChange}
                              />
                            </TableCell>
                          </React.Fragment>
                        )}
                        {editTarIndex !== i && (
                          <React.Fragment>
                            <TableCell>
                              <div hidden={!canEdit(etar)}>
                                {etar?.chartVitals?.length < 1 && etar?.status?.name === etar?.defaultQuickStatus &&
                                  <IconButton onClick={() => quickUpdate(etar, 'Scheduled')}>
                                    <CheckCircleRoundedIcon className={getItemIconClass(etar)}/>
                                  </IconButton>
                                }
                                {etar?.chartVitals?.length < 1 && etar?.status?.name !== etar?.defaultQuickStatus &&
                                  <IconButton onClick={() => quickUpdate(etar, etar?.defaultQuickStatus)}>
                                    <RadioButtonUncheckedRounded/>
                                  </IconButton>
                                }
                                <IconButton
                                  size="small"
                                  onClick={() => edit(etar, i)}
                                >
                                  <EditIcon />
                                </IconButton>
                              </div>
                            </TableCell>
                            <TableCell>
                              <Grid container alignItems="center">
                                <Avatar
                                  alt="Treatments"
                                  className={[
                                    classes.avatarSmall,
                                    getItemClass(etar),
                                  ]}
                                >
                                  <i class="fas fa-band-aid"></i>
                                </Avatar>
                              </Grid>
                            </TableCell>
                            <TableCell>
                              {etar.administeredTimestamp
                                ? moment(etar.administeredTimestamp).format(
                                    'hh:mm a'
                                  )
                                : null}
                            </TableCell>
                            <TableCell>
                              {etar.administeredUser
                                ? etar.administeredUser.initials
                                : null}
                            </TableCell>
                            <TableCell>{etar.notes}</TableCell>
                          </React.Fragment>
                        )}
                      </TableRow>
                      {etar?.chartVitals?.length > 0 &&
                      <TableRow>
                        <TableCell colSpan={14}>
                          <VitalInput emar={etar}
                                      resident={residentId}
                                      onValueChange={updateVital}
                                      edit={editTarIndex === i} />
                        </TableCell>
                      </TableRow>
                      }
                      </>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Hidden>
          <Hidden lgUp>
            <List>
              {etars?.map((etar) => (
                  <MobileEtar etar={etar}
                              onSelected={onSelected}
                              onQuickUpdate={onQuickUpdate}
                  />
                ))}
            </List>
          </Hidden>
        </React.Fragment>
      )}
      {searchLoading && (
        <div>
          <Skeleton
            variant="rect"
            className={classes.skeleton}
            animation="wave"
          />
          <Skeleton
            variant="rect"
            className={classes.skeleton}
            animation="wave"
          />
          <Skeleton
            variant="rect"
            className={classes.skeleton}
            animation="wave"
          />
          <Skeleton
            variant="rect"
            className={classes.skeleton}
            animation="wave"
          />
        </div>
      )}
      <EtarDialog
        open={showTarDialog}
        onClose={dialogCancel}
        etar={selectedTar}
        onSave={onUpdate}
        canUpdate={canUpdate}
      />
      <CreateResidentTreatment
        open={showCreateTreatmentDialog}
        onClose={() => setShowCreateTreatmentDialog(false)}
        onSave={onCreateTreatment}
        isPrn={true}
      />
    </Paper>
  );
};

export default ResidentTars;
