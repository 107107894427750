const model = {
  date: {
    title: 'Date',
    required: true,
    value: Date.now(),
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: true,
  },
  hour: {
    title: 'Hour',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  pattern: {
    title: 'Bowel pattern',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [
      { name: 'NA', value: 'NA' },
      { name: 'Normal', value: 'Normal' },
      { name: 'Constipation', value: 'Constipation' },
      { name: 'Fecal Incontinence', value: 'Fecal Incontinence' },
      { name: 'Irritable Bowel Pattern', value: 'Irritable Bowel Pattern' },
      { name: 'Impaction', value: 'Impaction' },
    ],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  color: {
    title: 'Color',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [
      { name: 'NA', value: 'NA' },
      { name: 'Brown - Normal Color', value: 'Brown - Normal Color' },
      { name: 'Black', value: 'Black' },
      { name: 'Red', value: 'Red' },
      { name: 'Pale or Grey', value: 'Pale or Grey' },
      { name: 'Green', value: 'Green' },
      { name: 'Orange', value: 'Orange' },
    ],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  consistency: {
    title: 'Consistency',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [
      { name: 'NA', value: 'NA' },
      { name: 'Liquid - watery', value: 'Liquid - watery' },
      { name: 'Soft', value: 'Soft' },
      {
        name: 'Formed with cracks on surface',
        value: 'Formed with cracks on surface',
      },
      { name: 'Formed, smooth and soft', value: 'Formed, smooth and soft' },
      { name: 'Hard separate lumps', value: 'Hard separate lumps' },
      { name: 'Unformed', value: 'Unformed' },
    ],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  comments: {
    title: 'Comments',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  time: {
    title: 'Time',
    required: true,
    value: 2,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [
      { name: '1 minute', value: 1 },
      { name: '2 minutes', value: 2 },
      { name: '3 minutes', value: 3 },
      { name: '4 minutes', value: 4 },
      { name: '5 minutes', value: 5 },
      { name: '6 minutes', value: 6 },
      { name: '7 minutes', value: 7 },
      { name: '8 minutes', value: 8 },
      { name: '9 minutes', value: 9 },
      { name: '10 minutes', value: 10 },
      { name: '11 minutes', value: 11 },
      { name: '12 minutes', value: 12 },
      { name: '13 minutes', value: 13 },
      { name: '14 minutes', value: 14 },
      { name: '15 minutes', value: 15 },
      { name: '30 minutes', value: 30 },
      { name: '45 minutes', value: 45 },
      { name: '60 minutes', value: 60 },
      { name: '2 hrs', value: 120 },
      { name: '3 hrs', value: 180 },
    ],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  staff: {
    title: 'Staff',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
};

export default model;
