export const DetailsModel = {
  _id: {
    title: 'ID',
    required: false,
    value: null,
    dirty: false,
    isValid: false,
    dropDown: false,
    dropDownItems: [],
    date: false,
    hidden: true
  },
  residentStatusId: {
    title: 'Resident Status Id',
    required: false,
    value: 3,
    dirty: false,
    isValid: false,
    dropDown: false,
    dropDownItems: [],
    date: false,
    hidden: true
  },
  firstName: {
    title: 'First Name',
    required: true,
    value: null,
    dirty: false,
    isValid: false,
    dropDown: false,
    dropDownItems: [],
    date: false
  },
  middleName: {
    title: 'Middle Name',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    dropDown: false,
    dropDownItems: [],
    date: false
  },
  lastName: {
    title: 'Last Name',
    required: true,
    value: null,
    dirty: false,
    isValid: false,
    dropDown: false,
    dropDownItems: [],
    date: false
  },
  formerName: {
    title: 'Former Name',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    showSSN: false,
    dropDown: false,
    dropDownItems: [],
    date: false
  },
  dob: {
    title: 'Date of birth',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    dropDown: false,
    date: true,
    displayUtc: true
  },
  gender: {
    title: 'Gender',
    required: true,
    value: null,
    dirty: false,
    isValid: false,
    dropDown: true,
    dropDownItems: [],
    dropDownItemLabel: 'name',
    dropDownItemValue: 'name',
    date: false
  },
  countyCaseNumber: {
    title: 'County Case Number',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    dropDown: false,
    dropDownItems: [],
    date: false
  },
  ssn: {
    title: 'SSN/SIN',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    dropDown: false,
    dropDownItems: [],
    date: false
  },
  medicalRecordId: {
    title: 'Medical Record Id',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    dropDown: false,
    dropDownItems: [],
    date: false
  },
  maritalStatus: {
    title: 'Marital Status',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    dropDown: true,
    dropDownItems: [{name: 'Married'},{name: 'Single'}],
    dropDownItemLabel: 'name',
    dropDownItemValue: 'name',
    date: false
  },
  religiousPreference: {
    title: 'Religious Preference',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    dropDown: false,
    dropDownItems: [],
    date: false
  },
  citizenship: {
    title: 'Citizenship',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    dropDown: false,
    dropDownItems: [],
    date: false
  },
  ethnicity: {
    title: 'Ethnicity',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    dropDown: true,
    dropDownItems: [],
    dropDownItemLabel: 'name',
    dropDownItemValue: '_id',
    date: false
  },
  primaryLanguage: {
    title: 'Primary Language',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    dropDown: false,
    dropDownItems: [],
    date: false
  },
  roomNumber: {
    title: 'Room Number',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    dropDown: false,
    dropDownItems: [],
    date: false
  },
  tenantLocationId: {
    title: 'Location',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    dropDown: true,
    dropDownItems: [],
    dropDownItemLabel: 'name',
    dropDownItemValue: '_id',
    date: false
  }
}
