import React from "react";
import InputMask from "react-input-mask";
import { TextField } from "@material-ui/core";

const PhoneMask = ({ name, value, onChange, canEdit = true, ...p }) => {
  const [val, setVal] = React.useState(null);
  React.useEffect(() => {
    setVal(value);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  const onValueChange = (e) => {
    setVal(e.target.value);
    let phone = String(e.target.value);
    phone = phone.replace("(", "");
    phone = phone.replace(")", "");
    phone = phone.replace("-", "");
    phone = phone.replace("_", "");
    phone = phone.replace(" ", "");
    if (String(phone).length === 10) {
      const event = {
        target: {
          name: e.target.name,
          value: phone,
        },
      };
      onChange(event);
    } else {
      const event = {
        target: {
          name: e.target.name,
          value: null,
        },
      };
      onChange(event);
    }
  };
  return (
    <InputMask
      mask={"(999) 999-9999"}
      value={val || ""}
      onChange={onValueChange}
      disabled={!canEdit}
    >
      {() => <TextField size="small" id={name} name={name} fullWidth {...p} />}
    </InputMask>
  );
};

export default PhoneMask;
