import React from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import moment from 'moment';
import CreateResidenttest from "./create.residenttest";
import {createResidentTest, deleteResidentTest, updateResidentTest} from "../../../services/resident.service";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import {
  KeyboardDatePicker, MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {ResidentContext} from "../../../context/residentcontext";
import {getStripedStyle} from "../../utils/table.utils";
import {DeleteBtn} from "../../utils/buttons/DeleteBtn";

const Residentests = ({resident}) => {
  const [tests, setTests] = React.useState([]);
  const [editTest, setEditTest] = React.useState();
  const [openCreateTest, setOpenCreateTest] = React.useState(false);
  const [viewConfirmDeleteDialog, setViewConfirmDeleteDialog] = React.useState(false);
  const [confirmDeleteTest, setConfirmDeleteTest] = React.useState();
  const { dispatch } = React.useContext(ResidentContext);

  React.useEffect(() => {
    setTests(resident?.tests);
  }, [ resident ])

  const onCreateTest = async test => {
    const entity = await createResidentTest(resident?._id, test);
    const obj = [...tests, entity];
    setTests(obj);
    setOpenCreateTest(false);
    dispatch({ type: 'RESIDENT_TESTS_UPDATED', payload: obj});
  }

  const onEditChange = e => {
    const obj = {...editTest};
    obj[e.target.name] = e.target.value;
    setEditTest(obj);
  }

  const onDateChange = date => {
    const obj = {...editTest};
    obj.testDate = date
    setEditTest(obj);
  }

  const isValid = () => {
    return editTest?.name && editTest?.status && editTest?.testDate;
  }

  const onSetupConfirmDelete = test => {
    setConfirmDeleteTest(test);
    setViewConfirmDeleteDialog(true);
  }

  const onDeleteCancel = () => {
    setConfirmDeleteTest(null);
    setViewConfirmDeleteDialog(false);
  }

  const onConfirmDelete = async () => {
    await deleteResidentTest(resident?._id, confirmDeleteTest?._id);
    const obj = tests.filter(test => confirmDeleteTest?._id !== test._id)
    setTests(obj);
    setConfirmDeleteTest(null);
    setViewConfirmDeleteDialog(false);
    dispatch({ type: 'RESIDENT_TESTS_UPDATED', payload: obj});
  }

  const updateTest = async () => {
    const entity = await updateResidentTest(resident?._id, editTest?._id, editTest);
    const obj = tests.map(test => test._id === entity?._id ? entity: test);
    setTests(obj);
    setEditTest(null);
    dispatch({ type: 'RESIDENT_TESTS_UPDATED', payload: obj});
  }

  return (
    <>
      <Typography variant={"h6"}>Tests</Typography>
      <Button size={"small"}
              color={"primary"}
              variant={"contained"}
              onClick={() => setOpenCreateTest(true)}
              startIcon={<AddIcon />}>
        Add
      </Button>
      <CreateResidenttest open={openCreateTest} onClose={() => setOpenCreateTest(false)} onSave={onCreateTest}  />
      <TableContainer>
        <Table>
          <TableHead>
            <TableCell />
            <TableCell>Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Date</TableCell>
          </TableHead>
          <TableBody>
            {tests?.sort((a, b) => a?.testDate < b?.testDate ? 1: -1)?.map((test, index) =>
              <>
                {editTest?._id !== test?._id &&
                <TableRow style={getStripedStyle(index)}>
                  <TableCell>
                    <Button size={"small"}
                            startIcon={<EditIcon />}
                            onClick={() => setEditTest(test)}
                            variant={"text"}>
                      Edit
                    </Button>
                    <DeleteBtn size={"small"} onClick={() => onSetupConfirmDelete(test)} />
                  </TableCell>
                  <TableCell>{test.name}</TableCell>
                  <TableCell>{test.status}</TableCell>
                  <TableCell>{test.testDate && <span>{moment(test.testDate).format('MM/DD/YYYY')}</span>}</TableCell>
                </TableRow>
                }
                {editTest?._id === test?._id &&
                <TableRow>
                  <TableCell>
                    <Button size={"small"}
                            variant={"contained"}
                            color={"primary"}
                            disabled={!isValid()}
                            onClick={updateTest}
                            startIcon={<SaveIcon />}>Update</Button>
                    <Button size={"small"}
                            onClick={() => setEditTest(null)}>Cancel</Button>
                  </TableCell>
                  <TableCell>
                    <TextField fullWidth
                               value={editTest?.name}
                               name={"name"}
                               InputProps={{ disableUnderline: true }}
                               onChange={onEditChange} />
                  </TableCell>
                  <TableCell>
                    <TextField fullWidth
                               value={editTest?.status}
                               name={"status"}
                               InputProps={{ disableUnderline: true }}
                               onChange={onEditChange} /></TableCell>
                  <TableCell>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        size="small"
                        fullWidth
                        name={"testDate"}
                        required
                        value={editTest?.testDate}
                        format="MM/DD/YYYY"
                        onChange={onDateChange}
                        InputProps={{ disableUnderline: true }}
                        allowKeyboardControl="true"
                        ampm={false}
                      />
                    </MuiPickersUtilsProvider>
                  </TableCell>
                </TableRow>
                }
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={viewConfirmDeleteDialog} maxWidth={"sm"} fullWidth>
        <DialogTitle>{"Confirm delete"}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>{"Please confirm if you want to delete test."}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDeleteCancel} color={"primary"}>Cancel</Button>
          <Button onClick={onConfirmDelete} color={"primary"} variant={"contained"}>Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Residentests;
