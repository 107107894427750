import React from 'react';
import {
  Button,
  Checkbox,
  Hidden,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  FormControlLabel, Select, MenuItem, Grid,
} from '@material-ui/core';
import {
  createResidentPrnEvent,
  updateResidentPrn,
  getResidentPrnForDateRange,
} from '../../../../services/medication.order.service';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import DateNavigation from '../date.navigation';
import moment from 'moment';
import AddIcon from '@material-ui/icons/Add';
import CreatePrnEvent from './create.prn.event';
import OutcomesList from './outcomes.list';
import useHasRole from "../../../../hooks/user.role.hook";
import MobileEprnList from "./mobile.eprn.list";
import MobileEprn from "./mobile.eprn";
import {ResidentContext} from "../../../../context/residentcontext";
import * as _ from "lodash";
import TableLoadingIndicator from "../../../../utils/indicators/table.loading";
import {useParams, Link} from "react-router-dom";
import {RetryMessage} from "../../../notification/retry.message";
import {UserContext} from "../../../../context/usercontext";
import {getTenantUsers} from "../../../../services/tenant.service";
import useGlobalLoadingIndicator from "../../../../hooks/global.loading.hook";
import {ListLoading} from "../../../../utils/indicators/list.loading";
import {PageTitle} from "../../../utils";
import {ListAlt} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
  },
  backBtn: {},
  rowSkeleton: {
    height: '2rem',
  },
  recheckContainer: {
    display: 'flex',
    alignContent: 'center',
  },
}));

const ResidentEpnList = () => {
  const classes = useStyles();
  const { residentId } = useParams();
  const [date, setDate] = React.useState(new Date());
  const [events, setEvents] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [showCreatePrnModal, setShowCreatePrnModal] = React.useState(false);
  const [editIndex, setEditIndex] = React.useState(null);
  const [editEventModel, setEditEventModel] = React.useState(null);
  const [viewMobileEprn, setViewMobileEprn] = React.useState(false);
  const [selectedEprn, setSelectedEprn] = React.useState();
  const { resident } = React.useContext(ResidentContext);
  const {selectedTenant} = React.useContext(UserContext);
  const { checkPermission } = useHasRole();
  const { toggleLoading } = useGlobalLoadingIndicator();
  const canEdit = checkPermission('EMAR_UPDATE');

  React.useEffect(() => {
    if (residentId) {
      loadEprns(residentId, new Date());
    }
    // eslint-disable-next-line
  }, [residentId]);

  React.useEffect(() => {
    if (canEdit && selectedTenant?._id) {
      populateUsers();
    }
    // eslint-disable-next-line
  }, [canEdit, selectedTenant])

  const populateUsers = async () => {
    if (users?.length < 1) {
      const userResponse = await getTenantUsers(selectedTenant?._id);
      if (!userResponse?.error) {
        setUsers(userResponse);
      }
    }
  }

  const loadEprns = async (residentId, dt) => {
    setDate(dt);
    setError(false);
    setLoading(true);
    toggleLoading(true);
    const response = await getResidentPrnForDateRange(
      residentId,
      moment(dt).format("YYYY-MM-DDT00:00:00Z"),
      moment(dt).format("YYYY-MM-DDT23:59:59Z"),
    )
    if (!response?.canceled) {
      if (!response?.error) {
        const sorted = _.sortBy(response, ['scheduledTime', 'medication.tradeName'])
        setEvents(sorted);
      } else {
        setError(true);
      }
      setLoading(false);
      toggleLoading(false);
    }
  };

  const retry = async () => {
    setEditIndex(null);
    setEditEventModel(null);
    await loadEprns(residentId, date);
  }

  const onDateChange = async (dt) => {
    setDate(dt);
    setEditIndex(null);
    setEditEventModel(null);
    await loadEprns(residentId, dt);
  };

  const createNewEvent = async (event) => {
    const scheduledTime = moment(date);
    scheduledTime.set({
      minute: moment(event.scheduledTime).minute(),
      hour: moment(event.scheduledTime).hour(),
      year: moment(event.scheduledTime).year()
    });
    event.scheduledTime = scheduledTime;
    const response = await createResidentPrnEvent(residentId, event);
    let obj = [response, ...events];
    const sorted = _.sortBy(obj, ['scheduledTime', 'medication.tradeName'])
    setEvents(sorted);
    setShowCreatePrnModal(false);
  };

  const editEvent = (e) => {
    setEditEventModel(e);
    setEditIndex(e._id);
  };

  const cancelEdit = () => {
    setEditIndex(null);
    setEditEventModel(null);
  };

  const onEditChange = (e) => {
    const obj = { ...JSON.parse(JSON.stringify(editEventModel)) };
    obj[e.target.name] = e.target.value;
    setEditEventModel(obj);
  };

  const onEditAdministeredDate = (date) => {
    const obj = { ...JSON.parse(JSON.stringify(editEventModel)) };
    const scheduledTime = moment(date);
    scheduledTime.set({
      minute: moment(obj.scheduledTime).minute(),
      hour: moment(obj.scheduledTime).hour(),
      year: moment(obj.scheduledTime).year()
    });
    obj.scheduledTime = scheduledTime;
    obj.administeredTimestamp = date;
    setEditEventModel(obj);
  };

  const onEditStatusChange = (e) => {
    const obj = { ...JSON.parse(JSON.stringify(editEventModel)) };
    obj.status = {
      name: e.target.checked ? 'Given' : 'Scheduled',
    };
    setEditEventModel(obj);
  };

  const saveEvent = async () => {
    const event = await updateResidentPrn(
      residentId,
      editEventModel._id,
      editEventModel
    );
    const obj = events.map((e) => (e._id === event._id ? event : e));
    const sorted = _.sortBy(obj, ['scheduledTime', 'medication.tradeName'])
    setEvents(sorted);
    setEditIndex(null);
    setEditEventModel(null);
  };

  const onSaveOutcome = (eventId, outcome) => {
    const evts = events.map((e) => {
      if (e._id === eventId) {
        if (e.outcomes) {
          e.outcomes = [outcome, ...e.outcomes];
        } else {
          e.outcomes = [outcome];
        }
      }
      return e;
    });
    const sorted = _.sortBy(evts, ['scheduledTime', 'medication.tradeName'])
    setEvents(sorted);
  };

  const onRecheckDateChange = (date) => {
    alert(date);
    const obj = { ...editEventModel };
    obj.recheckDateTime = date;
    setEditEventModel(obj);
  };

  const handleRecheckChange = (e) => {
    const obj = { ...editEventModel };
    obj.isRecheck = e.target.checked;
    setEditEventModel(obj);
  };

  const onMobileSelected = eprn => {
    setSelectedEprn(eprn);
    setViewMobileEprn(true);
  }

  const onUpdateMobile = async eprn => {
    const event = await updateResidentPrn(
      residentId,
      eprn._id,
      eprn
    );
    const obj = events.map((e) => (e._id === event._id ? event : e));
    const sorted = _.sortBy(obj, ['scheduledTime', 'medication.tradeName'])
    setEvents(sorted);
    setViewMobileEprn(false);
  }


  const getAdministeredUserLabel = administeredUser => {
    let user = '';
    if (administeredUser) {
      user = `${administeredUser?.firstName ?? ''} ${administeredUser?.lastName ?? ''} `
      if (administeredUser?.initials) {
        user += ` (${administeredUser?.initials})`
      }
    }
    return user;
  }


  const onAdministeredUserChange = e => {
    const user = users?.find(user => user?._id === e.target.value);
    const obj = {...editEventModel};
    obj.administeredUser = user;
    setEditEventModel(obj);
  }

  const isValid = event => {
    if (!moment(event?.administeredTimestamp).isValid()) {
      return false;
    }

    return true;
  }


  return (
    <Paper elevation={24} className={classes.root}>
      <PageTitle title={"Resident ePrn"}>
        <Grid container justifyContent={"flex-end"}>
          <Button color={"primary"}
                  component={Link}
                  to={`/residents/${residentId}/eprns/mar`}
                  startIcon={<ListAlt />}>
            Prn MAR
          </Button>
        </Grid>
      </PageTitle>
      <DateNavigation onDateChange={onDateChange} />
      {canEdit && <>
        <Button
          startIcon={<AddIcon />}
          onClick={() => setShowCreatePrnModal(true)}
        >
          Add Prn
        </Button>
        <CreatePrnEvent
          onSave={createNewEvent}
          residentId={residentId}
          open={showCreatePrnModal}
          date={date}
          onClose={() => setShowCreatePrnModal(false)}
        />
      </>}
      <Hidden smDown>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <TableContainer>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Medication</TableCell>
                  <TableCell>Dosage Form</TableCell>
                  <TableCell>Strength</TableCell>
                  <TableCell>Hours</TableCell>
                  <TableCell>Administered By</TableCell>
                  <TableCell width={100}>Qty</TableCell>
                  <TableCell>Given</TableCell>
                  <TableCell>Reason</TableCell>
                  <TableCell>Outcomes</TableCell>
                  <TableCell>Schedule Re-check</TableCell>
                </TableRow>
              </TableHead>
              {!error && !loading && (
                <TableBody>
                  {events?.map((event, i) => (
                      <React.Fragment>
                        {editIndex === event._id && (
                          <TableRow>
                            <TableCell>
                              <Button
                                size="small"
                                variant="contained"
                                onClick={saveEvent}
                                color="primary"
                                disabled={!isValid(editEventModel)}
                              >
                                Save
                              </Button>
                              <Button size="small" onClick={cancelEdit}>
                                Cancel
                              </Button>
                            </TableCell>
                            <TableCell>
                              {event.medicationOrder?.medication?.tradeName ??'N/A'}
                            </TableCell>
                            <TableCell>
                              {event?.medicationOrder?.medication?.dosageForm?.name ?? 'N/A'}
                            </TableCell>
                            <TableCell>
                              {event.medicationOrder?.medication?.strength ?? ''}{' '}
                              {event?.medicationOrder?.medication?.unit?.name ?? ''}
                            </TableCell>
                            <TableCell>
                              <KeyboardDateTimePicker
                                placeholder="01/01/2022 08:00 AM"
                                format={"MM/DD/YYYY hh:mm a"}
                                value={editEventModel.administeredTimestamp}
                                onChange={onEditAdministeredDate}
                              />
                            </TableCell>
                            <TableCell>
                              <Select fullWidth
                                      displayEmpty
                                      onChange={onAdministeredUserChange}
                                      value={editEventModel?.administeredUser?._id}
                              >
                                <MenuItem>Select a User</MenuItem>
                                {users?.map(user =>
                                  <MenuItem value={user?._id}>
                                    {getAdministeredUserLabel(user)}
                                  </MenuItem>)
                                }
                              </Select>
                            </TableCell>
                            <TableCell>
                              <TextField
                                size="small"
                                variant="outlined"
                                name="passingQty"
                                value={editEventModel.passingQty}
                                onChange={onEditChange}
                              />
                            </TableCell>
                            <TableCell>
                              <Checkbox
                                onChange={onEditStatusChange}
                                name="status"
                                checked={
                                  editEventModel.status &&
                                  editEventModel.status.name === 'Given'
                                    ? true
                                    : false
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                id="outlined-multiline-static"
                                multiline
                                size="small"
                                variant="outlined"
                                value={editEventModel.reason}
                                onChange={onEditChange}
                                name="reason"
                              />
                            </TableCell>
                            <TableCell>
                              <OutcomesList
                                residentId={residentId}
                                event={event}
                                onSaveOutcome={onSaveOutcome}
                              />
                            </TableCell>
                            <TableCell>
                              <div className={classes.recheckContainer}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={editEventModel.isRecheck}
                                      onChange={handleRecheckChange}
                                      name="recheck"
                                    />
                                  }
                                  label="Recheck"
                                />

                                {editEventModel && editEventModel.isRecheck && (
                                  <KeyboardDateTimePicker
                                    variant="inline"
                                    ampm={true}
                                    value={editEventModel.recheckDateTime}
                                    onChange={onRecheckDateChange}
                                    disablePast
                                    format="MM/DD/YYYY hh:mm a"
                                  />
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                        {editIndex !== event._id && (
                          <TableRow>
                            <TableCell>
                              {canEdit &&
                              <Button
                                size="small"
                                onClick={() => editEvent(event)}
                              >
                                Edit
                              </Button>}
                            </TableCell>
                            <TableCell>
                              {event?.medicationOrder?.medication?.tradeName}
                            </TableCell>
                            <TableCell>
                              {event?.medicationOrder?.medication?.dosageForm?.name ?? 'N/A'}
                            </TableCell>
                            <TableCell>
                              {event?.medicationOrder?.medication?.strength ?? ''}{' '}
                              {event?.medicationOrder?.medication?.unit?.name ?? ''}
                            </TableCell>
                            <TableCell>
                              {moment(event?.administeredTimestamp).format(
                                'hh:mm a'
                              )}
                            </TableCell>
                            <TableCell>
                              {event?.administeredUser?.initials ?? ''}
                            </TableCell>
                            <TableCell>{event?.passingQty}</TableCell>
                            <TableCell>{event?.status?.name}</TableCell>
                            <TableCell>{event?.reason}</TableCell>
                            <TableCell>
                              <OutcomesList
                                residentId={residentId}
                                event={event}
                                onSaveOutcome={onSaveOutcome}
                                canEdit={canEdit}
                              />
                            </TableCell>
                            <TableCell>
                              {event.isRecheck && event.recheckDateTime
                                ? moment(event.recheckDateTime).format(
                                    'MM/DD/YYYY hh:mm a'
                                  )
                                : null}
                            </TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    ))}
                </TableBody>
              )}
              {!error && loading && <TableLoadingIndicator cols={11} rows={5} bodyOnly={true} />}
              {!loading && error &&
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={11}>
                      <RetryMessage message={"Unable to load ePrns, please try again"}
                                    severity={"error"}
                                    onRetry={retry} />
                    </TableCell>
                  </TableRow>

                </TableBody>
              }
            </Table>
          </TableContainer>
        </MuiPickersUtilsProvider>
      </Hidden>
      <Hidden mdUp>
        {!loading && !error &&
          <MobileEprnList eprns={events} onSelected={onMobileSelected}/>
        }
        {!error && loading &&
          <ListLoading rows={3} />
        }
        <MobileEprn open={viewMobileEprn}
                    onClose={() => setViewMobileEprn(false)}
                    eprn={selectedEprn}
                    resident={resident}
                    users={users}
                    onSave={onUpdateMobile} />
      </Hidden>
    </Paper>
  );
};

export default ResidentEpnList;
