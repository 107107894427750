import React, {useContext} from 'react';
import {
    Accordion, AccordionActions, AccordionDetails,
    AccordionSummary,
    Button,
    CircularProgress,
    FormGroup,
    Grid, LinearProgress,
    makeStyles,
    Table,
    TableBody,
    TableContainer, Typography
} from "@material-ui/core";
import {FormFieldsGenerator, isModelValid} from "../../../utils/forms";
import BaseEvent from "../../medicationorders/create/base.event";
import {PageTitle} from "../../utils";
import {CareplanContext} from "./context";
import {InterventionModel} from "./intervention.model";
import {ACTIONS} from "./context/constants";
import moment from "moment";
import {ObjectId} from "bson";
import {
    deleteInterentionAction,
    loadInterventionAction,
    updateInterventionAction
} from "./context/actions";
import history from "../../../utils/history";
import {DeleteBtn} from "../../utils/buttons/DeleteBtn";
import SaveIcon from "@material-ui/icons/Save";
import ConfirmDialog from "../../utils/dialogs/confirm.dialog";
import useGlobalNotification from "../../../hooks/notification.hook";
import {useParams} from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TableLoadingIndicator from "../../../utils/indicators/table.loading";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1)
    },
    formContainer: {
        margin: theme.spacing(0, 0, 3, 0)
    },
    btnContainer: {

    },
    accordianRoot: {
        width: "100%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    details: {
        width: "100%"
    },
    column: {

    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));
export const ResidentCareplanGoalIntervention = () => {
    const {state, dispatch} = useContext(CareplanContext);
    const classes = useStyles();
    const [frequency, setFrequency] = React.useState(null);
    const [viewConfirmDialog, setViewConfirmDialog] = React.useState(false);
    const {addNotification} = useGlobalNotification();
    const {residentId, careplanId, goalId, interventionId} = useParams();

    React.useEffect(() => {
        if (residentId && careplanId && goalId && interventionId) {
            loadInterventionAction(dispatch, residentId, careplanId, goalId, interventionId).then(() => {});
        }
        // eslint-disable-next-line
    }, [residentId, careplanId, goalId, interventionId])

    React.useEffect(() => {
        const obj = JSON.parse(JSON.stringify(InterventionModel));
        obj.name.value = state?.intervention?.name;
        obj.etask.value = state?.intervention?.etask;
        obj.startDate.value = state?.intervention?.startDate;
        obj.endDate.value = state?.intervention?.endDate;
        obj.frequency.value = state?.intervention?.frequency?._id;
        obj.frequency.dropDownItems = state?.frequencies;
        obj.eventTemplates.value = state?.intervention?.eventTemplates;
        obj.discipline.dropDownItems = state?.roles;
        dispatch({type: ACTIONS.SET_INTERVENTION_MODEL, payload: obj});
        processDay(state?.intervention?.frequency?._id)
        // eslint-disable-next-line
    }, [state?.intervention]);

    const onFormChange = (e, field) => {
        const obj = {...state?.interventionModel, ...e};
        dispatch({type: ACTIONS.SET_INTERVENTION_MODEL, payload: obj});
        if (field === 'frequency') {
            processDay(obj.frequency.value);
        }
    }

    const onEventChange = e => {
        const obj = { ...state?.interventionModel };
        if (obj.eventTemplates && obj.eventTemplates.value) {
            obj.eventTemplates.value = obj.eventTemplates.value.map((eventTemplate) =>
                eventTemplate._id === e._id ? e : eventTemplate
            );
            dispatch({type: ACTIONS.SET_INTERVENTION_MODEL, payload: obj});
        }
    }


    const processDay = (frequencyId) => {
        if (state?.frequencies) {
            const freq = state?.frequencies.find((f) => f._id === frequencyId);
            if (freq) {
                setFrequency(freq);
                const eventTemplates = [];
                const defaultHours = {
                    1: { 1: 8 },
                    2: { 1: 8, 2: 16 },
                    3: { 1: 8, 2: 12, 3: 16 },
                    4: { 1: 8, 2: 10, 3: 14, 4: 16 },
                    5: { 1: 8, 2: 10, 3: 12, 4: 14, 5: 16 },
                };

                for (let i = 1; i <= freq.frequencyEventCount; i++) {
                    const offset = new Date().getTimezoneOffset();
                    const d = new Date();
                    // Auto increment hours
                    let calculatedHour = defaultHours[freq.frequencyEventCount][i];
                    const initStartDate = d.setHours(calculatedHour, 0, 0, 0);
                    const offsetDate = moment(initStartDate).add(offset, 'minutes');
                    const hour = moment(offsetDate).format('H');
                    const minute = moment(offsetDate).format('mm');
                    eventTemplates.push({
                        _id: new ObjectId(),
                        sort: i,
                        passingQty: 1,
                        hour,
                        minute,
                        offset,
                    });
                }
                const obj = { ...state?.interventionModel };
                obj.eventTemplates.value = eventTemplates;
                dispatch({type: ACTIONS.SET_INTERVENTION_MODEL, payload: obj});
            }
        } else {
        }
    };

    const handleUpdateIntervention = async () => {
        let data = {};
        Object.keys(state?.interventionModel).forEach(key => {
            data[key] = state.interventionModel[key].value
        });
        const response =
            await updateInterventionAction(dispatch, state?.residentId, state?.careplan?._id, state?.goal?._id, state?.intervention?._id, data);
        if (!response?.error) {
            addNotification(`Intervention updated. ${response?.eventsCreated} event(s) created.`, "success");
        }
    }

    const handleConfirmDelete = async () => {
        await deleteInterentionAction(dispatch, state?.residentId, state?.careplan?._id, state?.goal?._id, state?.intervention?._id,)
        history.goBack();
    }

    const handleCancel = () => {

    }

    return (
        <div className={classes.root}>
            <Accordion defaultExpanded>
                {state?.loadingIntervention && <LinearProgress color={"primary"} />}
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                >
                    {!state?.loadingIntervention &&
                        <PageTitle title={!state?.loading ? `Intervention: ${state?.intervention?.name}`: null} />
                    }
                </AccordionSummary>
                <AccordionDetails>
                    {state?.loadingIntervention &&
                        <div style={{width: "100%"}}>
                            <TableLoadingIndicator rows={5} cols={1} />
                        </div>
                    }
                    {!state?.loadingIntervention &&
                    <FormGroup style={{width: "100%"}}>
                        <FormFieldsGenerator model={state?.interventionModel}
                                         onChange={onFormChange}
                                         edit={!state?.updatingIntervention}
                        />
                    </FormGroup>
                    }
                </AccordionDetails>
                {!state?.loadingIntervention &&
                    <AccordionActions>
                    <Grid container
                          className={classes.btnContainer}
                          justifyContent={"space-between"}>
                        <Grid item>
                            {!state?.updatingIntervention &&
                                <DeleteBtn onClick={() => setViewConfirmDialog(true)} />
                            }
                        </Grid>
                        <Grid item>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button disabled={!isModelValid(state?.interventionModel) || state?.updatingIntervention}
                                    variant={"contained"}
                                    color={"primary"}
                                    onClick={handleUpdateIntervention}
                                    startIcon={state?.updatingIntervention ? <CircularProgress size={20} />: <SaveIcon />}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </AccordionActions>
                }
            </Accordion>
            {!state?.loadingIntervention && !state?.updatingIntervention && !state?.interventionModel?.isPrn?.value && (
            <Accordion defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                >
                    <Typography variant={"h6"}>
                        Time/Day
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <TableContainer>
                    <Table>
                    <TableBody>
                    {state?.interventionModel?.eventTemplates?.value?.map((event) => (
                        <BaseEvent
                        frequency={frequency}
                        eventTemplate={event}
                        onChange={onEventChange}
                        showQty={false}
                        />
                    ))}
                    </TableBody>
                    </Table>
                </TableContainer>
                </AccordionDetails>
            </Accordion>
             )}
            <ConfirmDialog open={viewConfirmDialog}
                               title={"Delete Intervention"}
                               message={"Do you want to delete this intervention?"}
                               onClose={() => setViewConfirmDialog(false)}
                               onConfirm={handleConfirmDelete}
                />
        </div>
    )
}
