import React from 'react';
import MarRow from './mar.row';
import TarRow from './tar.row';
import TaskRow from './task.row';

const EscheduleRow = ({
  event,
  index,
  isEdit,
  selectEditEvent,
  cancelEdit,
  onUpdate,
}) => {
  if (event) {
    switch (event.type) {
      case 'emar':
        return (
          <MarRow
            event={event}
            index={index}
            isEdit={isEdit}
            selectEditEvent={selectEditEvent}
            cancelEdit={cancelEdit}
            onUpdate={onUpdate}
          />
        );
      case 'tar':
        return (
          <TarRow
            etar={event}
            index={index}
            isEdit={isEdit}
            selectEditEvent={selectEditEvent}
            cancelEdit={cancelEdit}
            onUpdate={onUpdate}
          />
        );
      case 'careplan_task':
        return (
          <TaskRow
            task={event}
            index={index}
            isEdit={isEdit}
            selectEditEvent={selectEditEvent}
            cancelEdit={cancelEdit}
            onTaskUpdated={onUpdate}
            onUpdate={onUpdate}
          />
        );
      default:
        return <div>{JSON.stringify(event)}</div>;
    }
  } else {
    return null;
  }
};

export default EscheduleRow;
