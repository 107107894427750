import React from 'react';
import { UserContext } from '../../context/usercontext';

let LocationsContext = React.createContext();

let initialState = {
  tenant: {},
  location: {},
  locations: [],
};

let reducer = (state, action) => {
  switch (action.type) {
    case 'setTenant':
      return { ...state, tenant: action.payload };
    case 'setLocation':
      return { ...state, location: action.payload };
    case 'setLocations':
      return { ...state, locations: action.payload };
    default:
      return { ...state };
  }
};

const LocationsContextProvider = ({ children }) => {
  const {
    selectedTenant,
    selectedTenantLocation,
    tenantLocations,
  } = React.useContext(UserContext);
  let [state, dispatch] = React.useReducer(reducer, initialState);
  let value = {
    state,
    dispatch,
  };

  React.useEffect(() => {
    dispatch({ type: 'setTenant', payload: selectedTenant });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenant]);
  React.useEffect(() => {
    dispatch({ type: 'setLocation', payload: selectedTenantLocation });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenantLocation]);
  React.useEffect(() => {
    dispatch({ type: 'setLocations', payload: tenantLocations });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantLocations]);

  return (
    <LocationsContext.Provider value={value}>
      {children}
    </LocationsContext.Provider>
  );
};

let LocationsContextConsumer = LocationsContext.Consumer;

export { LocationsContext, LocationsContextProvider, LocationsContextConsumer };
