import React from 'react';
import {
  Button,
  FormGroup,
  FormHelperText,
  Grid,
  makeStyles,
  MenuItem,
  Paper, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  TextField,
  Typography
} from "@material-ui/core";
import {PageTitle} from "../../../../utils";
import moment from "moment/moment";
import Months from "../../mar/month.values";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import {getResidentPrnForDateRange} from "../../../../../services/medication.order.service";
import {useParams} from "react-router-dom";
import {getStripedStyle} from "../../../../utils/table.utils";
import TableLoadingIndicator from "../../../../../utils/indicators/table.loading";
import {EprnMarPrintDialog } from './report';
import {ResidentContext} from "../../../../../context/residentcontext";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 0)
  },
  content: {
    padding: theme.spacing(1),
    height: '65vh'
  },
  tableBody: {
    height: '40vh',
    overflow: "scroll",
    border: '1px solid red'
  },
  dateSelectionContainer: {
    maxWidth: 345
  },
  tableContainer: {
  }
}));

export const EprnMARPage = () => {
  const classes = useStyles();
  const [months] = React.useState(Months);
  const [month, setMonth] = React.useState(moment().month() + 1);
  const [date, setDate] = React.useState(moment());
  const [year, setYear] = React.useState(moment().get("year"));
  const [years, setYears] = React.useState([]);
  const [viewPrnPrinDialog, setViewPrnPrinDialog] = React.useState(false);
  // const [days, setDays] = React.useState(0);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [prns, setPrns] = React.useState([]);
  const { residentId } = useParams();
  const { resident } = React.useContext(ResidentContext);

  React.useEffect(() => {
    generateYears(moment().get("year"));
    getData(moment().month() + 1, moment().year()).then(r => {});
    // eslint-disable-next-line
  },[]);


  const generateYears = (cur) => {
    const obj = [];
    for (let min = cur - 5; min <= cur; min++) {
      obj.push(min);
    }
    setYears(obj);
  };

  const changeYear = async (e) => {
    setYear(e.target.value);
    getData(month, e.target.value);

  }

  const changeMonth = async (e) => {
    setMonth(e.target.value);
    getData(e.target.value, year);
  }

  const getData = async (monthNumber, yearNumber) => {
    setError(false);
    setLoading(true);
    const date = moment().set({ year: yearNumber, month: monthNumber - 1 });
    setDate(date);
    const startDate = date.clone().startOf('month').format('YYYY-MM-DDT00:00:00Z');
    const endDate = date.clone().endOf('month').format('YYYY-MM-DDT23:59:59Z');
    const response = await getResidentPrnForDateRange(residentId, startDate, endDate);
    if (!response?.error) {
      setPrns(response);
    } else {
      setError(true);
    }
    setLoading(false);
  }

  const onViewPrnPrinDialog = () => {
    setViewPrnPrinDialog(true);
  }

  const monthName = (month) => {
    return moment({ month: +month - 1}).format('MMMM');
  }

  return (
    <Paper className={classes.root}>
      <PageTitle title={"PRN Medication Administration Record"}>
        <Grid container justifyContent={"flex-end"}>
          <Button color={"primary"}
                  disabled={loading}
                  onClick={onViewPrnPrinDialog}
                  startIcon={<PictureAsPdfIcon />}>
            Print
          </Button>
        </Grid>
      </PageTitle>
      <EprnMarPrintDialog open={viewPrnPrinDialog}
                          onClose={() => setViewPrnPrinDialog(false)}
                          resident={resident}
                          data={prns}
                          month={monthName(month)}
                          year={year}
      />
      <div className={classes.content}>
        <div className={classes.dateSelectionContainer}>
          <FormHelperText>
            <Typography variant="h6">ePRN: {date.format("MMMM YYYY")}</Typography>
          </FormHelperText>
          <FormGroup>
            <TextField
              onChange={changeYear}
              label="Year"
              fullwidth
              select
              value={year | ""}
            >
              {years?.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              onChange={changeMonth}
              label="Month"
              fullwidth
              select
              value={month | ""}
            >
              {months?.map((month) => (
                <MenuItem key={month.val} value={month.val}>
                  {month.name}
                </MenuItem>
              ))}
            </TextField>
          </FormGroup>
        </div>
        <div className={classes.tableContainer}>
          <TableContainer>
            <Table size={"small"} stickyHeader={true}>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Medication</TableCell>
                  <TableCell>Dosage Form</TableCell>
                  <TableCell>Strength</TableCell>
                  <TableCell>Hours</TableCell>
                  <TableCell>Administered By</TableCell>
                  <TableCell>Qty</TableCell>
                  <TableCell>Reason</TableCell>
                  <TableCell>Outcomes</TableCell>
                </TableRow>
              </TableHead>
              {!error && loading &&
                <TableLoadingIndicator bodyOnly={true} rows={10} cols={9} />
              }
              <TableBody>
                {!error && !loading &&
                  prns?.sort((a, b) => a?.administeredTimestamp > b?.administeredTimestamp ? 1: -1)
                  ?.map((prn, i) =>
                <TableRow style={getStripedStyle(i)}>
                  <TableCell>
                    {moment(prn?.administeredTimestamp).format('MM/DD/YYYY')}
                  </TableCell>
                  <TableCell>{prn?.medicationOrder?.medication?.tradeName}</TableCell>
                  <TableCell>{prn?.medicationOrder?.medication?.dosageForm?.name}</TableCell>
                  <TableCell>
                    {prn?.medicationOrder?.medication?.strength} {prn?.medicationOrder?.medication?.unit?.name}
                  </TableCell>
                  <TableCell>
                    {moment(prn?.administeredTimestamp).format('hh:mm a')}
                  </TableCell>
                  <TableCell>
                    {prn?.administeredUser?.firstName}{" "}{prn?.administeredUser?.lastName}
                  </TableCell>
                  <TableCell>
                    {prn?.passingQty}
                  </TableCell>
                  <TableCell>
                    {prn?.reason}
                  </TableCell>
                  <TableCell>
                    {prn?.outcomes?.sort((a,b) => a?.entryDate > b?.entryDate ? 1: -1)?.map(outcome =>
                      <div>
                        {outcome?.comment}
                        {outcome?.user ?
                        <span> ({outcome?.user?.firstName}{' '}{outcome?.user?.lastName})</span> :
                        null
                        }
                      </div>
                    )}
                  </TableCell>
                </TableRow>)
                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </Paper>
  )
}
