import React from 'react';
import {Box, CircularProgress, List, ListItem, ListItemIcon, ListItemText, makeStyles} from "@material-ui/core";
import {useParams} from "react-router-dom";
import {PageTitle} from "../../utils";
import {Paper} from "@material-ui/core";
import {FileBreadcrumbs} from "./breadcrumb";
import {FileToolbox} from "./toolbox";
import {ResidentFileContext, ResidentFileContextProvider} from "./context/ResidentFilesContext";
import {ResidentFilesTable} from "./table";
import {File} from './file';
import {CreateFolderDialog} from "./file/dialog/CreateFolderDialog";
import {CONSTANTS} from "./context/Constants";
import {
    getResidentFilesAction,
    onFilesAndFoldersDeleteAction,
    onFolderCreateAction,
    onFolderUpdateAction,
    onPermissionUpdateAction
} from "./context/Actions";
import Alert from "@material-ui/lab/Alert";
import ConfirmDialog from "../../utils/dialogs/confirm.dialog";
import {Description, Folder, Refresh} from "@material-ui/icons";
import {FileDrawer} from "./drawer";
import useGlobalNotification from "../../../hooks/notification.hook";
import {EditFolderDialog} from "./folder/dialog";
import {FileItemPermission} from "./permission";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
    breadcrumbContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    refreshBtn: {
      marginRight: theme.spacing(1)
    }
}));

export const InnerComponent = () => {
  const {state, dispatch} = React.useContext(ResidentFileContext);
  const {addNotification} = useGlobalNotification();
  const classes = useStyles();

  React.useEffect(() => {
    if (state?.errorMessage) {
      addNotification(state?.errorMessage, "error");
    }
  }, [state?.errorMessage, addNotification]);

  const handleCreateFolderModalClose = () => {
    dispatch({type: CONSTANTS.SET_CREATE_FOLDER_MODAL, payload: false});
  }

  const handleFolderSave = async folder => {
    await onFolderCreateAction(dispatch, state?.residentId, folder, state?.selectedFolder?._id);
  }

  const handleViewDeleteFolderModalClose = () => {
    dispatch({type: CONSTANTS.SET_DELETE_FILE_ITEM_MODAL, payload: false});
  }

  const onDeleteConfirmed = async () => {
    let files =  state?.selectedFiles?.filter(f => f?.checked)?.map(f => f?._id);
    await onFilesAndFoldersDeleteAction(dispatch, state?.residentId, files);
  }

  const getDeleteSummary = () => {
    return (
      <List>
        {state?.selectedFiles?.filter(f => f?.checked)?.map((item, index) =>
          <ListItem>
            <ListItemIcon>
              {item?.type === "file" ? <Description fontSize={"small"} />: <Folder fontSize={"small"} />}
            </ListItemIcon>
            <ListItemText primary={item?.label}
                          secondary={`${item?.type !== "file" ? "Files inside this folder will be deleted.": ""}`}
            />
          </ListItem>
        )}
      </List>
    )
  }

  const getDeleteTitle = () => {
    const filesForDelete = state?.selectedFiles?.filter(f => f?.checked);
    const files = filesForDelete?.filter(f => f?.type === "file");
    const folder = filesForDelete?.filter(f => f?.type !== "file");
    if (files?.length > 0 && folder?.length > 0) {
      return `Delete ${folder?.length} ${folder?.length > 1 ? 'folders': 'folder'} and ${files?.length} ${files?.length > 1 ? 'files': 'file'}?`;
    } else if (folder?.length > 0) {
      return `Delete ${folder?.length} ${folder?.length > 1 ? 'folders': 'folder'}?`;
    } else if (files?.length > 0) {
      return `Delete ${files?.length}  ${files?.length > 1 ? 'files': 'file'}?`;
    } else {
      return null;
    }
  }

  const onSaveEditFolder = async (folder, payload) => {
      await onFolderUpdateAction(dispatch, state?.residentId, folder?._id, {...folder, ...payload});
  }

  const onSavePermission = async (fileObject, payload) => {
      await onPermissionUpdateAction(dispatch, state?.residentId, fileObject?._id, {...fileObject, ...payload});
      dispatch({type: CONSTANTS.SET_VIEW_PERMISSION_MODAL, payload: false});
  }

  const onRefresh = async () => {
      await getResidentFilesAction(dispatch, state?.residentId);
  }

  return (
    <Paper elevation={24}>
      <PageTitle title={"eFile"} />
      <Box padding={1}>
        <Box className={classes.breadcrumbContainer}>
            <IconButton className={classes.refreshBtn}
                        onClick={onRefresh}
            >
                {state?.loading ? <CircularProgress size={20} />: <Refresh />}
            </IconButton>
          <FileBreadcrumbs />
        </Box>
        {!state?.selectedFile &&
        <Box>
          <FileToolbox />
          <ResidentFilesTable />
        </Box>
        }
        {state?.selectedFile &&
          <Box>
            <File file={state?.selectedFile}/>
          </Box>
        }
      </Box>
      <CreateFolderDialog open={state?.viewCreateFolderModal}
                          onClose={handleCreateFolderModalClose}
                          onSave={handleFolderSave}
      />
      <ConfirmDialog onClose={handleViewDeleteFolderModalClose}
                     open={state?.viewDeleteFileItemModal}
                     title={getDeleteTitle()}
                     message={<Box>
                       <Alert severity={"warning"}>This action is permenant.</Alert>
                       {getDeleteSummary()}
                     </Box>}
                     onConfirm={onDeleteConfirmed} />
      <FileDrawer />
      <EditFolderDialog open={state?.viewEditFolderModal}
                        folder={state?.selectedFolder}
                        onSave={onSaveEditFolder}
                        onClose={() => dispatch({type: CONSTANTS.SET_VIEW_EDIT_FOLDER_MODAL, payload: false})}
      />
      <FileItemPermission open={state?.viewPermissionModal}
                          fileObject={state?.selectedObject}
                          onSave={onSavePermission}
                          onClose={() => dispatch({type: CONSTANTS.SET_VIEW_PERMISSION_MODAL, payload: false})}
      />
    </Paper>
  )
}

export const ResidentFiles = () => {
  const {residentId} = useParams();

  return (
    <ResidentFileContextProvider id={residentId}>
      <InnerComponent />
    </ResidentFileContextProvider>
  )
}
