import React from 'react';
import {
  Paper,
  Typography,
  makeStyles,
  TextField,
  FormGroup,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
} from '@material-ui/core';
import { ResidentContext } from '../../../context/residentcontext';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { getEthnicities } from '../../../services/ethnicity.service';
import { updateResident } from '../../../services/resident.service';
import Skeleton from '@material-ui/lab/Skeleton';
import { GlobalNotificationContext } from '../../notification/globalnotificationprovider';
import { getGenders } from '../../../services/gender.service';
import ChipInput from 'material-ui-chip-input';
import { UserContext } from '../../../context/usercontext';
import useHasRole from '../../../hooks/user.role.hook';
import {ResidentListContext} from "../list/resident.list.context";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1),
  },
  formGroup: {
    padding: theme.spacing(1),
  },
  btnContainer: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyItems: 'center',
    justifyContent: 'flex-end',
  },
}));

const ResidentDetails = ({ match }) => {
  const classes = useStyles();
  const [backup, setBackup] = React.useState();
  const [resident, setResident] = React.useState();
  const { state, dispatch } = React.useContext(ResidentContext);
  const residentListContext = React.useContext(ResidentListContext);
  const { tenantLocations } = React.useContext(UserContext);
  const [ethnicities, setEthnicities] = React.useState([]);
  const [genders, setGenders] = React.useState([]);
  const { addNotification } = React.useContext(GlobalNotificationContext);

  const { checkPermission } = useHasRole();

  React.useEffect(() => {
    const getEm = async () => {
      const response = await Promise.all([getEthnicities(), getGenders()]);
      setEthnicities(response[0]);
      setGenders(response[1]);
    };
    getEm();
  }, []);

  React.useEffect(() => {
    const res = state.resident;
    if (!res.tags) {
      res.tags = [];
    }
    setBackup(JSON.parse(JSON.stringify(res)));
    setResident(res);
  }, [state.resident]);

  const onChange = (e) => {
    const obj = { ...resident };
    obj[e.target.name] = e.target.value;
    setResident(obj);
  };

  const onDOBChange = (e) => {
    const obj = { ...resident };
    obj.dob = e;
    setResident(obj);
  };

  const onUpdate = async () => {
    dispatch({ type: 'UPDATE_RESIDENT_START' });
    const response = await updateResident(resident._id, resident);
    dispatch({ type: 'UPDATE_RESIDENT_COMPLETE', payload: resident });
    if (!response?.error) {
      residentListContext.dispatch({type: 'RESIDENT_UPDATED', payload: resident });
      addNotification('Resident Updated', 'success');
    } else {
      addNotification('Error updating resident');
    }
  };

  const onCancel = () => {
    setResident(JSON.parse(JSON.stringify(backup)));
  };

  const isValid = () => {
    return (
      resident &&
      resident.firstName &&
      resident.lastName &&
      resident.gender &&
      resident.dob
    );
  };

  const handleAdd = (...chips) => {
    const obj = { ...resident };
    obj.tags = [...obj.tags, ...chips];
    setResident(obj);
  };

  const handleDelete = (deletedChip) => {
    const obj = { ...resident };
    obj.tags = obj.tags.filter((c) => c !== deletedChip);
    setResident(obj);
  };

  const updateLocation = (e) => {
    const obj = { ...resident };
    obj.location._id = e.target.value;
    setResident(obj);
  };

  const canEdit = checkPermission('RESIDENT_UPDATE');

  const nonChanged = () => {
    return canEdit && backup?.location?._id === resident?.location?._id;
  };

  const revertChange = () => {
    const obj = { ...resident };
    obj.location = backup?.location;
    setResident(obj);
  };

  const onUpdateLocation = async () => {
    dispatch({ type: 'UPDATE_RESIDENT_START' });
    const response = await updateResident(resident._id, resident);
    if (!response?.error) {
      const obj = { ...backup };
      obj.location = resident.location;
      setBackup(obj);
      dispatch({ type: 'UPDATE_RESIDENT_COMPLETE', payload: resident });
      residentListContext.dispatch({type: 'RESIDENT_UPDATED', payload: resident });
    }
  };

  return (
    <>
      <Paper elevation={24} className={classes.root}>
        <Typography variant="h5">Resident Details</Typography>
        {state.loadingResident && (
          <Grid container>
            <Grid item xs={4}>
              <Skeleton
                component="div"
                variant="rect"
                style={{ margin: '1rem' }}
                height="3rem"
              />
              <Skeleton
                component="div"
                variant="rect"
                style={{ margin: '1rem' }}
                height="3rem"
              />
              <Skeleton
                component="div"
                variant="rect"
                style={{ margin: '1rem' }}
                height="3rem"
              />
              <Skeleton
                component="div"
                variant="rect"
                style={{ margin: '1rem' }}
                height="3rem"
              />
            </Grid>
            <Grid item xs={4}>
              <Skeleton
                component="div"
                variant="rect"
                style={{ margin: '1rem' }}
                height="3rem"
              />
              <Skeleton
                component="div"
                variant="rect"
                style={{ margin: '1rem' }}
                height="3rem"
              />
              <Skeleton
                component="div"
                variant="rect"
                style={{ margin: '1rem' }}
                height="3rem"
              />
              <Skeleton
                component="div"
                variant="rect"
                style={{ margin: '1rem' }}
                height="3rem"
              />
            </Grid>
            <Grid item xs={4}>
              <Skeleton
                component="div"
                variant="rect"
                style={{ margin: '1rem' }}
                height="3rem"
              />
              <Skeleton
                component="div"
                variant="rect"
                style={{ margin: '1rem' }}
                height="3rem"
              />
              <Skeleton
                component="div"
                variant="rect"
                style={{ margin: '1rem' }}
                height="3rem"
              />
              <Skeleton
                component="div"
                variant="rect"
                style={{ margin: '1rem' }}
                height="3rem"
              />
            </Grid>
          </Grid>
        )}
        {!state.loadingResident && resident && (
          <form noValidate>
            <Grid container>
              <Grid item xs={12} sm={4} className={classes.formGroup}>
                <FormGroup>
                  <TextField
                    required
                    label="First Name"
                    value={resident.firstName || ''}
                    onChange={onChange}
                    name="firstName"
                    error={!resident.firstName}
                    disabled={!canEdit}
                  />
                  <TextField
                    label="Middle Name"
                    value={resident.middleName || ''}
                    onChange={onChange}
                    name="middleName"
                    disabled={!canEdit}
                  />
                  <TextField
                    required
                    label="Last Name"
                    value={resident.lastName || ''}
                    onChange={onChange}
                    name="lastName"
                    error={!resident.lastName}
                    disabled={!canEdit}
                  />
                  <TextField
                    label={"Nickname"}
                    value={resident?.nickname || ''}
                    onChange={onChange}
                    name={"nickname"}
                    disabled={!canEdit}
                  />
                  <TextField
                    label="Former Name"
                    value={resident.formerName || ''}
                    onChange={onChange}
                    name="formerName"
                    disabled={!canEdit}
                  />
                  <FormControl>
                    <InputLabel shrink={true}>Ethnicity</InputLabel>
                    <Select
                      value={resident.ethnicity || ''}
                      onChange={onChange}
                      name="ethnicity"
                      displayEmpty
                      disabled={!canEdit}
                    >
                      {ethnicities &&
                        ethnicities.map((ethnicity) => (
                          <MenuItem value={ethnicity.name}>
                            {ethnicity.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <TextField  label="Transfered From"
                              value={resident.transferedFrom || ''}
                              onChange={onChange}
                              name="transferedFrom"
                              disabled={!canEdit}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={4} className={classes.formGroup}>
                <FormGroup>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      size="small"
                      fullWidth
                      name="date"
                      required={true}
                      value={moment(resident.dob).utc()}
                      label={'Birthdate'}
                      format="MM/DD/YYYY"
                      onChange={onDOBChange}
                      allowKeyboardControl="true"
                      ampm={false}
                      helperText={
                        !resident.dob ? 'Birth date is required' : null
                      }
                      error={!resident.dob}
                      disabled={!canEdit}
                    />
                  </MuiPickersUtilsProvider>
                  <FormControl>
                    <InputLabel required error={!resident.gender}>
                      Gender
                    </InputLabel>
                    <Select
                      value={resident.gender || ''}
                      displayEmpty
                      onChange={onChange}
                      name="gender"
                      disabled={!canEdit}
                    >
                      {genders &&
                        genders.map((gender) => (
                          <MenuItem value={gender.name}>{gender.name}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <InputLabel shrink={true}>Marital Status</InputLabel>
                    <Select
                      value={resident.maritalStatus || ''}
                      onChange={onChange}
                      name="maritalStatus"
                      displayEmpty
                      disabled={!canEdit}
                    >
                      <MenuItem value="">Select Marital Status</MenuItem>
                      <MenuItem value="MARRIED">Married</MenuItem>
                      <MenuItem value="SINGLE">Single</MenuItem>
                      <MenuItem value="WIDOWED">Widowed</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    label="Religous Preference"
                    value={resident.religiousPreference || ''}
                    onChange={onChange}
                    name="religiousPreference"
                    disabled={!canEdit}
                  />
                  <TextField
                    label="Citizenship"
                    value={resident.citizenship || ''}
                    onChange={onChange}
                    name="citizenship"
                    disabled={!canEdit}
                  />
                <TextField  label="Referred By"
                            value={resident.referredBy || ''}
                            onChange={onChange}
                            name="referredBy"
                            disabled={!canEdit}
                            fullWidth
                />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={4} className={classes.formGroup}>
                <FormGroup>
                  <TextField
                    label="Room Number"
                    value={resident.roomNumber || ''}
                    onChange={onChange}
                    name="roomNumber"
                    disabled={!canEdit}
                  />

                  <TextField
                    label="SSN/SIN"
                    value={resident.ssn || ''}
                    onChange={onChange}
                    name="ssn"
                    disabled={!canEdit}

                  />
                  <TextField
                    label="County Case Number"
                    value={resident.countyCaseNumber || ''}
                    onChange={onChange}
                    name="countyCaseNumber"
                    disabled={!canEdit}
                  />
                  <TextField
                    label="Medical Record Id"
                    value={resident.medicalRecordId || ''}
                    onChange={onChange}
                    name="medicalRecordId"
                    disabled={!canEdit}
                  />
                  <ChipInput
                    label="Tags"
                    fullWidth
                    helperText="Tag care plan with keywords for better searching"
                    value={resident.tags}
                    onAdd={(chip) => handleAdd(chip)}
                    onDelete={(deletedChip) => handleDelete(deletedChip)}
                    blurBehavior={"add"}
                    disabled={!canEdit}
                  />
                </FormGroup>
              </Grid>
            </Grid>
            {canEdit &&
              <>
                <Divider/>
                <FormGroup row className={classes.btnContainer}>
                <Button onClick={onCancel}>Cancel</Button>
                <Button
                color="primary"
                variant="contained"
                onClick={onUpdate}
                disabled={!isValid()}
                >
                Update
                </Button>
                </FormGroup>
              </>
            }
          </form>
        )}
      </Paper>
      <Paper elevation={24} className={classes.root}>
        <Typography variant="h5">Location</Typography>
        <form noValidate>
          <FormGroup>
            <Select
              displayEmpty
              value={resident?.location?._id || ''}
              onChange={updateLocation}
              disabled={!canEdit}
            >
              {tenantLocations &&
                tenantLocations.map((location) => (
                  <MenuItem value={location._id}>{location.name}</MenuItem>
                ))}
            </Select>
          </FormGroup>
          {canEdit &&
            <FormGroup
              row
              style={{justifyContent: 'flex-end', marginTop: '.5rem'}}
            >
              <Button color="primary" onClick={revertChange}>
                Cancel
              </Button>
              <Button
                disabled={nonChanged()}
                color="primary"
                variant="contained"
                onClick={onUpdateLocation}
              >
                Update Location
              </Button>
            </FormGroup>
          }
        </form>
      </Paper>
    </>
  );
};

export default ResidentDetails;
