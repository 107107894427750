import React from 'react';
import moment from 'moment';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { getResidentEschedule } from '../../../services/eschedule.service';
import MobileEmar from '../../emar/mobile.emar';
import MobileEtar from '../treatments/tar/mobile.tar';
import EmarDialog from '../../eschedule/emar/emar.dialog';
import EtarDialog from '../treatments/tar/etar.dialog';
import { updateResidentEmar } from '../../../services/medication.order.service';
import { GlobalNotificationContext } from '../../notification/globalnotificationprovider';
import { updateResidentETar } from '../../../services/treatment.service';
import Skeleton from '@material-ui/lab/Skeleton';
import {RotateLeftTwoTone, WarningTwoTone} from "@material-ui/icons";
import MobileTask from "../task/mobile.task";
import {UserContext} from "../../../context/usercontext";
import {updateResidentTask} from "../../../services/careplan.service";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  headerContainer: {
    marginBottom: theme.spacing(1),
  },
  content: {
    minHeight: '20rem',
    maxHeight: '20rem',
    overflowY: 'scroll',
  },
  errorContent: {
    padding: theme.spacing(5),
    minHeight: '20rem',
    maxHeight: '20rem',
    overflowY: 'scroll'
  },
  retryBtn: {
    marginTop: theme.spacing(2),
    alignSelf: "flex-end"
  },
  errorLogo: {
    color: theme.palette.warning.dark,
    fontSize: '2rem'
  }
}));

const ResidentSchedule = ({ residentId }) => {
  const classes = useStyles();
  const [date, setDate] = React.useState(Date.now());
  const [events, setEvents] = React.useState([]);
  const [selectedTar, setSelectedTar] = React.useState(null);
  const [showTarDialog, setShowTarDialog] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const [selectedEvent, setSelectedEvent] = React.useState();
  const [showPassMeds, setShowPassMeds] = React.useState(false);
  const [showEvent, setShowEvent] = React.useState(false);
  const { addNotification } = React.useContext(GlobalNotificationContext);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [users, setUsers] = React.useState();
  const {getUsers} = React.useContext(UserContext);

  React.useEffect(() => {
    if (residentId && date) {
      loadUsers();
      loadData(residentId, date);
    }
    // eslint-disable-next-line
  }, [residentId, date]);

  const loadData = async (id, dt) => {
    setError(false);
    setLoading(true);
    setEvents([]);
    const data = await getResidentEschedule(
      id,
      moment(dt).format('YYYY-MM-DD')
    );
    if (!data?.error) {
      setEvents(data.content);
    } else {
      setError(true);
    }
    setLoading(false);
  };

  const loadUsers = async () => {
    if (users?.length < 1) {
      const userResponse = await getUsers();
      if (!userResponse?.error) {
        setUsers(userResponse);
      }
    }
  }

  const retry = async () => {
    await loadData(residentId, date);
  }

  const onPrevious = async () => {
    const obj = moment(date).subtract(1, 'day');
    setDate(obj);
  };

  const onNext = async () => {
    const obj = moment(date).add(1, 'day');
    setDate(obj);
  };

  const onTarSelected = (e) => {
    setSelectedTar(e);
    setShowTarDialog(true);
  };

  const eventSelected = (event, index) => {
    setSelectedIndex(index);
    setSelectedEvent(event);
    setShowPassMeds(true);
  };

  const updateEmar = async (event) => {
    const response = await updateResidentEmar(
      event.resident._id,
      event._id,
      event
    );
    if (!response?.error) {
      const obj = events.map(emarEvent => {
        if (emarEvent._id === response._id) {
          emarEvent.status = response.status;
          emarEvent.administeredTimestamp = response.administeredTimestamp;
          emarEvent.administeredUser = response.administeredUser;
          emarEvent.initials = response?.administeredUser?.initials;
        }
        return emarEvent;
      });
      addNotification('Event Status updated', 'success');
      setEvents(obj);
      setShowPassMeds(false);
    } else {
      addNotification("Unable to update emar", "error");
    }
  };

  const onTarUpdated = async (e) => {
    await updateResidentETar(e.resident._id, e.treatment, e._id, e);
    const obj = events.map((event) => (event._id === e._id ? e : event));
    setEvents(obj);
    setShowTarDialog(false);
  };

  const emarQuickUpdate = async (emar, status) => {
    if (emar) {
      emar.status.name = status;
      await updateEmar(emar);
    }
  }

  const updateRow = (e) => {
    const obj = events?.map((event) => (e?._id === event?._id ? e : event));
    setEvents(obj);
    // setEditEventIndex(-1);
    addNotification('Event Status updated', 'success');
  }

  const quickUpdateTask = async (entity, status) => {
    entity.status = status;
    await updateResidentTask(entity?.resident?._id, entity?._id, entity);
  }

  return (
    <Paper elevation={24} className={classes.root}>
      <Grid
        container
        justifyContent={"space-between"}
        className={classes.headerContainer}
      >
        <Typography variant="h5">
          {moment(date).format('MM/DD/YYYY')} Schedule
        </Typography>
        <ButtonGroup size="small">
          <Button
            variant="contained"
            color="primary"
            onClick={onPrevious}
            startIcon={<KeyboardArrowLeftIcon />}
          ></Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onNext}
            endIcon={<KeyboardArrowRightIcon />}
          ></Button>
        </ButtonGroup>
      </Grid>
      <Divider />
      {!error &&
      <div className={classes.content}>
        <List>
          {!loading && events?.map((e, i) => (
              <React.Fragment>
                {e.type === 'emar' && (
                  <MobileEmar
                    emar={e}
                    onSelected={eventSelected}
                    isSelected={selectedIndex === i}
                    quickUpdate={emarQuickUpdate}
                  />
                )}
                {e.type === 'tar' && (
                  <MobileEtar etar={e} onSelected={onTarSelected} />
                )}
                {e.type === "careplan_task" &&
                  <MobileTask task={e}
                              onTaskUpdated={updateRow}
                              users={users}
                              onQuickUpdate={quickUpdateTask}
                  />
                }
                <Divider />
              </React.Fragment>
            ))}
          {loading && (
            <React.Fragment>
              <ListItem>
                <ListItemAvatar>
                  <Skeleton
                    animation="wave"
                    variant="circle"
                    width={40}
                    height={40}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Skeleton
                      animation="wave"
                      height={10}
                      width="80%"
                      style={{ marginBottom: 6 }}
                    />
                  }
                  secondary={
                    <Skeleton
                      animation="wave"
                      height={10}
                      width="100%"
                      style={{ marginBottom: 6 }}
                    />
                  }
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemAvatar>
                  <Skeleton
                    animation="wave"
                    variant="circle"
                    width={40}
                    height={40}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Skeleton
                      animation="wave"
                      height={10}
                      width="80%"
                      style={{ marginBottom: 6 }}
                    />
                  }
                  secondary={
                    <Skeleton
                      animation="wave"
                      height={10}
                      width="100%"
                      style={{ marginBottom: 6 }}
                    />
                  }
                />
              </ListItem>
            </React.Fragment>
          )}
        </List>
      </div>}
      {error &&
      <div className={classes.errorContent}>
        <Grid container
              alignItems={"center"}
              justify={"center"}>
          <WarningTwoTone className={classes.errorLogo} />
          <Typography variant={"subtitle2"}>Whoops, something went wrong. Please retry to view schedule</Typography>
        </Grid>
        <Grid container
              justify={"flex-end"}
              className={classes.errorBtnContainer}
        >
          <Button color={"primary"}
                  className={classes.retryBtn}
                  variant={"contained"}
                  onClick={retry}
                  startIcon={<RotateLeftTwoTone />}>Retry</Button>
        </Grid>
      </div>
      }

      <Dialog open={showEvent}>
        <DialogTitle>
          {selectedEvent
            ? `${selectedEvent.residentFirstName} ${selectedEvent.residentLastName}`
            : null}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selectedEvent ? selectedEvent.medicationName : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowEvent(false)}>Cancel</Button>
          <Button>View</Button>
        </DialogActions>
      </Dialog>

      <EmarDialog
        open={showPassMeds}
        model={selectedEvent}
        onClose={() => setShowPassMeds(false)}
        onSave={updateEmar}
      />
      <EtarDialog
        open={showTarDialog}
        onClose={() => setShowTarDialog(false)}
        etar={selectedTar}
        onSave={onTarUpdated}
      />
    </Paper>
  );
};

export default ResidentSchedule;
