import React from 'react';
import {Dialog, DialogContent, DialogTitle, IconButton, LinearProgress, makeStyles} from "@material-ui/core";
import {PDFViewer} from "@react-pdf/renderer";
import CloseIcon from "@material-ui/icons/Close";
import {ResidentEprnMarPdf} from "./pdf";

const useStyles = makeStyles((theme) => ({
  root: {
  },
  dialogContent: {
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  mainContent: {
    minHeight: '50vh'
  }
}));

export const EprnMarPrintDialog = ({ open,
                                     onClose,
                                     resident,
                                     data,
                                     month,
                                     year
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      fullWidth
      maxWidth={"lg"}
    >
      <DialogTitle
        onClose={() => onClose()}>
        {'ePRN MAR Report'}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.mainContent}>
        {!resident && <LinearProgress />}
        {resident &&
          <PDFViewer style={{width: '100%', height: '70vh'}}>
            <ResidentEprnMarPdf resident={resident} data={data} month={month} year={year} />
          </PDFViewer>
        }
      </DialogContent>
    </Dialog>
  )
}
