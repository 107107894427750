import React from "react";
import {
  makeStyles,
  Typography,
  FormGroup,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import AssessmentQuestion from "./assessment.question";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

const useStyles = makeStyles(theme => ({
  root: {},
  form: {
    width: "100%"
  },
  title: {
    display: "flex",
    alignItems: "center"
  },
  assessmentName: {
    marginLeft: ".5rem"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: ".75rem",
    flexDirection: "row"
  },
  expansionPanel: {
    marginBottom: ".5rem"
  },
  statusIconComplete: {
    color: theme.palette.success.main,
    marginRight: '.5rem'
  },
  statusIconInComplete: {
    color: theme.palette.error.main,
    marginRight: '.5rem'
  }
}));

const AssessmentQuestionGroup = ({
  group,
  expanded,
  keyValue,
  handleChange,
  onSave,
  isAssessmentComplete
}) => {
  const classes = useStyles();
  const [currentGroup, setCurrentGroup] = React.useState(group);

  React.useEffect(() => {
    if (group) {
      setCurrentGroup(group);
      setInitSaved(group)
    } else {
      setCurrentGroup(null);
    }
  }, [group]);

  const onChange = q => {
    const obj = { ...group };
    obj.questions = group.questions.map(question => {
      if (question._id === q._id) {
        question.value = q.value;
        question.hasSavedValue = true;
      }
      return question;
    });
    setCurrentGroup(obj);
  };


  const setInitSaved = (grp) => {
    if (grp && grp.questions) {
      const hasSavedValues = grp.questions.filter(question => question.hasSavedValue || question.value);
      if (hasSavedValues && hasSavedValues.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <div hidden={!currentGroup} key={keyValue}>
      <ExpansionPanel
        className={classes.expansionPanel}
        expanded={true}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>
            {currentGroup.hasSavedValue ? (
              <CheckCircleOutlineIcon className={classes.statusIconComplete} />
            ) : (
              <RadioButtonUncheckedIcon className={classes.statusIconInComplete} />
            )}
            {currentGroup.name}
          </Typography>
          <Typography variant="subtitle1" className={classes.secondaryHeading}>
            {currentGroup.description}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <form className={classes.form}>
            <FormGroup className={classes.formGroup}>
              {currentGroup?.questions?.sort((a, b) => a?.sortOrder > b?.sortOrder ? 1: -1)?.map(question => (
                  <AssessmentQuestion key={question._id} question={question} onChange={onChange} disabled={isAssessmentComplete} />
                ))}
            </FormGroup>
          </form>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

export default AssessmentQuestionGroup;
