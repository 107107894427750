import React from 'react';
import {StyleSheet, Text, View} from "@react-pdf/renderer";
import moment from "moment/moment";

const styles = StyleSheet.create({
    eTarItemContainer: {
        paddingTop: 5
    },
    eTarItem: {
        height: 25,
        fontSize: '8px'
    },
})
export const PDFETarLogItem = ({etar, treatment}) => {
    const everyXHourFrequencys = [ "QH", "Q2H", "Q3H", "Q4H", "Q6H", "Q8H","Q12H" ];
    let isEveryXHoursFrequency = everyXHourFrequencys?.indexOf(treatment?.frequency?.name) > -1

    const getHour = () => {
        if (isEveryXHoursFrequency) {
            return moment(etar?.scheduledTime).format("H:mm");
        } else {
            return null;
        }
    }

    const getText = () => {
        switch (etar?.status?.name) {
            case "Given":
                return `G\n${etar?.administeredUser?.initials}`
            case "Refused":
                return `R\n${etar?.administeredUser?.initials}`
            case "Scheduled":
                return " ";
            case "Late":
                return "L";
            case "Missed":
                return "M";
            case "NA\n":
                return `NA\n${etar?.administeredUser?.initials}`;
            case "SA":
                return `SA\n${etar?.administeredUser?.initials}`;
            default:
                return `${etar?.status?.name[0]}\n${etar?.administeredUser?.initials}`
        }
    }

    return (
        <View style={[styles.eTarItemContainer]}>
            <Text style={[styles.eTarItem]}>
                {getHour()} {getText()}
            </Text>
        </View>
    )
}
