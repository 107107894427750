import {ACTIONS} from "./calendar.context";
import {
  updateCalendarType,
  createCalendarType,
  getCalendarTypes,
  getUserCalendarEvents,
  deleteCalendarType,
  createUserCalendarEvents, updateUserCalendarEvent, deleteUserCalendarEvent, deleteUserCalendarSeries
} from "../../../services/calendar.service";

export const getCalendarTypesAction = async (dispatch) => {
  try {
    dispatch({type: ACTIONS.TOGGLE_LOADING_TYPES, payload: true });
    dispatch({type: ACTIONS.TOGGLE_LOADING_TYPES_ERROR, payload: false });
    const response = await getCalendarTypes();
    if (!response?.error) {
      dispatch({type: ACTIONS.SET_CALENDAR_TYPES, payload: response});
    } else {
      dispatch({type: ACTIONS.TOGGLE_LOADING_TYPES_ERROR, payload: true });
    }
  } catch (error) {
    dispatch({type: ACTIONS.TOGGLE_LOADING_TYPES_ERROR, payload: true });
  }
  dispatch({type: ACTIONS.TOGGLE_LOADING_TYPES, payload: false });
}


export const createCalendarTypeAction = async (dispatch, calendarType, onError, onSuccess) => {
  try {
    const response = await createCalendarType(calendarType);
    if (!response?.error) {
      response.checked = true;
      dispatch({ type: ACTIONS.CREATE_CALENDAR_TYPE, payload: response });
      onSuccess?.call();
    } else {
      onError?.call();
    }
  } catch (error) {
    onError?.call();
  }
};

export const saveCalendarTypeAction = async (dispatch,
                                             calendarId,
                                             calendarType,
                                             checked = false,
                                             onError,
                                             onSuccess) => {
  try {
    const response = await updateCalendarType(calendarId, calendarType);
    if (!response?.error) {
      response.checked = checked;
      dispatch({ type: ACTIONS.UPDATE_CALENDAR_TYPE, payload: response });
      dispatch({ type: ACTIONS.SET_VIEW_EDIT_CALENDAR_TYPE_MODAL, payload: false });
      onSuccess?.call();
    } else {
      onError?.call();
    }
  } catch (error) {
    onError?.call();
  }
}

export const selectCalendarTypeToEditAction = async (dispatch, payload) => {
  dispatch({type: ACTIONS.SET_SELECTED_CALENDAR_TYPE, payload });
  dispatch({type: ACTIONS.SET_VIEW_EDIT_CALENDAR_TYPE_MODAL, payload: true });
}

export const updateCalendarFilterTypesAction = (dispatch, type) => {
  dispatch({type: ACTIONS.TOGGLE_FILTER_TYPE, payload: type });
}

export const deleteCalendarTypeAction = async (dispatch, type, onError, onSuccess) => {
  try {
    const response = await deleteCalendarType(type?._id);
    if (!response?.error) {
      dispatch({type: ACTIONS.DELETE_CALENDAR_TYPE, payload: type?._id});
      dispatch({type: ACTIONS.SET_VIEW_DELETE_CALENDAR_TYPE_MODAL, payload: false});
      dispatch({type: ACTIONS.SET_VIEW_EDIT_CALENDAR_TYPE_MODAL, payload: false});
      onSuccess?.call();
    } else {
      onError?.call();
    }
  } catch (error) {
    onError?.call();
  }
}

export const calendarSlotSelectedAction = (dispatch, slot) => {
  dispatch({type: ACTIONS.SET_SELECTED_CALENDAR_SLOT, payload: slot });
  dispatch({type: ACTIONS.SET_VIEW_CREATE_EVENT_MODAL, payload: true });
}

export const getCalendarEventsAction = async (dispatch, id, date ) => {
  dispatch({type: ACTIONS.GET_CALENDAR_EVENTS});
  dispatch({type: ACTIONS.SET_ERROR, payload: false});
  dispatch({type: ACTIONS.TOGGLE_PENDING_ACTION, payload: true });
  try {
    const response = await getUserCalendarEvents(id, date);
    if (!response?.error) {
      dispatch({type: ACTIONS.SET_CALENDAR_EVENTS, payload: response });
    } else {
      dispatch({type: ACTIONS.SET_ERROR, payload: true });
    }
  } catch (error) {
    dispatch({type: ACTIONS.SET_ERROR, payload: true });
  }
  dispatch({type: ACTIONS.TOGGLE_PENDING_ACTION, payload: false });
}

export const createCalendarEventsAction = async (dispatch, userId, calendar, onError, onSuccess ) => {
  try {
    const response = await createUserCalendarEvents(userId, calendar);
    if (!response?.error) {
      dispatch({type: ACTIONS.CREATE_CALENDAR_EVENT, payload: response });
      if (onSuccess) {
        onSuccess();
      }
    } else {
      if (onError) {
        onError();
      }
    }
  } catch (error) {
    dispatch({type: ACTIONS.SET_ERROR, payload: true });
  }
}

export const updateCalendarEventsAction = async (dispatch, userId, eventId, event, onError, onSuccess ) => {
  try {
    const response = await updateUserCalendarEvent(userId, eventId, event);
    if (!response?.error) {
      dispatch({type: ACTIONS.UPDATE_CALENDAR_EVENT, payload: response });
      if (onSuccess) {
        onSuccess();
      }
    } else {
      if (onError) {
        onError();
      }
    }
  } catch (error) {
    dispatch({type: ACTIONS.SET_ERROR, payload: true });
  }
}

export const deleteCalendarEventsAction = async (dispatch, userId, eventId, onError, onSuccess) => {
  try {
    const response = await deleteUserCalendarEvent(userId, eventId);
    if (!response?.error) {
      dispatch({type: ACTIONS.DELETE_CALENDAR_EVENT, payload: response?._id });
      if (onSuccess) {
        onSuccess();
      }
    } else {
      if (onError) {
        onError();
      }
    }
  } catch (error) {
    dispatch({type: ACTIONS.SET_ERROR, payload: true });
  }
}

export const selectCalendarEventAction = (dispatch, event) => {
  dispatch({type: ACTIONS.SET_SELECTED_CALENDAR_EVENT, payload: event });
  dispatch({type: ACTIONS.SET_VIEW_EDIT_EVENT_MODAL, payload: true})
}

export const deleteCalendarSeriesAction = async (dispatch, userId, seriesId, onError, onSuccess) => {
  try {
    const response = await deleteUserCalendarSeries(userId, seriesId);
    if (!response?.error) {
      dispatch({type: ACTIONS.DELETE_CALENDAR_SERIES, payload: seriesId });
      if (onSuccess) {
        onSuccess();
      }
    } else {
      if (onError) {
        onError();
      }
    }
  } catch (error) {
    dispatch({type: ACTIONS.SET_ERROR, payload: true });
  }
}
