import apiUtil from "../utils/api.utils";
import config from "../utils/configs";
import {parseFiltersToJson} from "../utils/filter.utils";

const baseUrl = `${config.medicationOrderService}/api/v1.0`;



let getMedicationOrderHistoriesCancelToken;
export const getMedicationOrderHistories = async (id, page = 1, size = 25, filters = [], sortBy = "date", sortDir = "desc") => {
    try {
        if (typeof getMedicationOrderHistoriesCancelToken != typeof undefined) {
            getMedicationOrderHistoriesCancelToken.cancel("Cancel Request");
        }
        getMedicationOrderHistoriesCancelToken = apiUtil.getCancelToken();

        let requestUrl = `${baseUrl}/medicationorders/${id}/histories?page=${page}&size=${size}`
        if (filters?.length > 0) {
            requestUrl += parseFiltersToJson(filters);
        }
        requestUrl += `&sort=${sortDir === "desc" ? '-': '+'}${sortBy}`
        const response = await apiUtil.client.get(requestUrl, {cancelToken: getMedicationOrderHistoriesCancelToken.token});
        return response.data;
    } catch (error) {
        if (apiUtil.isCancel(error)) {
            return {canceled: true};
        } else {
            return {error: true, message: error}
        }
    }
};

export const getMedicationOrderHistory = async (medicationOrderId, historyId) => {
    try {
        const response = await apiUtil.client.get(
            `${baseUrl}/medicationorders${medicationOrderId}/histories/${historyId}`
        );
        return response.data;
    } catch (error) {
        return { error: true, message: error }
    }
};
