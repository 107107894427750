import {
  Avatar,
  Button, CircularProgress,
  Divider,
  Hidden,
  IconButton,
  List,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField, Typography,
  withStyles,
} from '@material-ui/core';
import React from 'react';
import DateNavigation from '../../medicationorders/date.navigation';
import {getResidentEdars, updateResidentEdar,} from '../../../../services/diet.service';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
import * as _ from 'lodash';
import MobileEdar from './mobile.edar';
import useHasRole from "../../../../hooks/user.role.hook";
import {blue, green, grey, orange, red, yellow} from "@material-ui/core/colors";
import {getStripedStyle} from "../../../utils/table.utils";
import {PageTitle} from "../../../utils";
import TableLoadingIndicator from "../../../../utils/indicators/table.loading";
import {ListLoading} from "../../../../utils/indicators/list.loading";
import Alert from "@material-ui/lab/Alert";
import {AlertTitle} from "@material-ui/lab";
import useGlobalNotification from "../../../../hooks/notification.hook";

const contrast = 400;
export const SsaSlider = withStyles({
  root: {
    color: '#52af77',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1),
  },
  skeleton: {
    height: '3rem',
    margin: theme.spacing(1, 0),
  },
  readyToPass: {
    background: grey[700],
  },
  given: {
    background: green[contrast],
  },
  late: {
    background: orange[contrast],
  },
  missed: {
    background: red[contrast],
  },
  hold: {
    background: yellow[contrast],
  },
  sa: {
    background: blue[contrast],
  },
  na: {
    background: grey[contrast],
  },
  refused: {
    background: orange[contrast],
  },
  destruction: {
    background: yellow[contrast],
  },
}));


const ResidentEdars = ({match}) => {
  const classes = useStyles();
  const [date, setDate] = React.useState(new Date());
  const [edars, setEdars] = React.useState([]);
  // const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [updating, setUpdating] = React.useState(false);
  const [editIndex, setEditIndex] = React.useState(-1);
  const [editEdar, setEditEdar] = React.useState(null);
  const [totalRequiredCalories, setTotalRequiredCalories] = React.useState();
  const [consumedCalories, setConsumedCalories] = React.useState();
  const [percentConsumed, setPercentConsumed] = React.useState(0);
  const [totalFluids, setTotalFluids] = React.useState(0);
  const {checkPermission} = useHasRole();
  const canUpdate = checkPermission('EDAR_READ');
  const {addNotification} = useGlobalNotification();

  const onDateChange = (dt) => {
    setDate(dt);
  };

  React.useEffect(() => {
    if (date) {
      getEdars(match.params.residentId, date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, match.params.residentId]);

  const getEdars = async (residentId, date) => {
    setLoading(true);
    setEditIndex(-1);
    setEditEdar(null);
    const edars = await getResidentEdars(
      residentId,
      moment(date).format('YYYY-MM-DD')
    );
    aggregateTotals(edars);
    setEdars(edars);
    setLoading(false);
  };

  const aggregateTotals = (edars, updated = false) => {
    if (edars && edars.length > 0) {
      if (!updated) {
        setTotalRequiredCalories(edars[0]['totalRequiredCalories']);
      }
      const cals = _.sumBy(edars, 'calories');
      setConsumedCalories(cals);
      const totalPercent = _.sumBy(edars, 'percentConsumed');
      setPercentConsumed((totalPercent / (edars.length * 100)) * 100);
      const fluids = _.sumBy(edars, 'fluidsConsumed');
      setTotalFluids(fluids);
    } else {
    }
  };

  const edit = (edar, index) => {
    setEditIndex(index);
    setEditEdar(edar);
  };

  const onEditStatus = (e) => {
    const obj = {...editEdar};
    obj.status = e.target.value;
    setEditEdar(obj);
  };

  const onTextChange = (e) => {
    const obj = {...editEdar};
    obj[e.target.name] = e.target.value;
    setEditEdar(obj);
  };

  const update = async () => {
    setUpdating(true);
    // setError(false);
    const response = await updateResidentEdar(
      match.params.residentId,
      editEdar._id,
      editEdar
    );
    if (!response?.error) {
      const obj = edars.map((edar) =>
          edar._id === response._id ? response : edar
      );
      setEditIndex(-1);
      setEditEdar(null);
      setEdars(obj);
      aggregateTotals(obj, true);
      addNotification("eDar Updated", "success");
    } else {
      // setError(true);
      addNotification("Unable to update");
    }
    setUpdating(false);
  };

  const mobileUpdate = async (entity) => {
    const response = await updateResidentEdar(
      match.params.residentId,
      entity._id,
      entity
    );
    if (!response?.error) {
      const obj = edars.map((edar) =>
          edar._id === response._id ? response : edar
      );
      setEditIndex(-1);
      setEditEdar(null);
      setEdars(obj);
      aggregateTotals(obj);
      addNotification("eDar Updated", "success");
    } else {
      // setError(true);
      addNotification("Unable to update");
    }
  };

  const onPercentConsumedChanged = (e, percent) => {
    const obj = {...editEdar};
    obj.percentConsumed = percent;
    setEditEdar(obj);
  };

  const getDisplayStatusValue = status => {
    if (status && status.length > 0) {
      return String(status[0]).toUpperCase();
    } else {
      return 'D'
    }
  }

  const canEdit = () => {
    return true;
  }


  const getItemClass = (event) => {
    if (event && event.status) {
      switch (event.status) {
        case 'Scheduled':
          if (canEdit(event)) {
            return classes.readyToPass;
          } else {
            return classes.scheduled;
          }
        case 'Given':
          return classes.given;
        case 'Late':
          return classes.late;
        case 'Missed':
          return classes.missed;
        case 'Hold':
          return classes.hold;
        case 'SA':
          return classes.sa;
        case 'NA':
          return classes.na;
        case 'Refused':
          return classes.refused;
        case 'Destruction':
          return classes.destruction;
        default:
          return classes.scheduled;
      }
    }
  };

  const hasDietDescription = () => {
    if (edars?.length > 0) {
     if (edars[0].description) {
       return true;
     }
    }
    return false;
  }

  const getDietDescription = () => {
    if (edars?.length > 0) {
      return edars[0].description;
    }
    return null;
  }

  return (
    <Paper elevation={24} className={classes.root}>
      <PageTitle title={"Resident eDar"}/>
      <DateNavigation onDateChange={onDateChange}/>
      {hasDietDescription() &&
        <Alert severity={"info"}>
          <AlertTitle>{getDietDescription()}</AlertTitle>
        </Alert>
      }
      <Hidden smDown>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell width={25}></TableCell>
                <TableCell>Meal</TableCell>
                <TableCell>Time</TableCell>
                <TableCell/>
                <TableCell>Status</TableCell>
                <TableCell>Calories</TableCell>
                <TableCell>Percent Consumed</TableCell>
                <TableCell>Fluids Consumed (ml)</TableCell>
                <TableCell>Comments</TableCell>
                <TableCell>Administered</TableCell>
              </TableRow>
            </TableHead>
            {loading && <TableLoadingIndicator cols={10} rows={4} bodyOnly={true} />}
            <TableBody>
              {!loading && edars?.sort((a, b) => a?.scheduledTime > b?.scheduledTime ? 1: -1)?.map((edar, index) => (
                <TableRow key={index} style={getStripedStyle(index)}>
                  <TableCell>
                    <Avatar className={[
                      classes.avatarSmall,
                      getItemClass(edar),
                    ]}>{getDisplayStatusValue(edar?.status)}</Avatar>
                  </TableCell>
                  <TableCell>
                    <Typography variant={"subtitle1"}>
                      {edar.mealType}
                    </Typography>
                    {edar?.mealTypeComment &&
                      <div>
                        <Typography variant={"subtitle2"}>
                          {edar?.mealTypeComment}
                        </Typography>
                      </div>
                    }
                  </TableCell>
                  <TableCell>
                    {moment(edar.scheduledTime).format('h:mm a')}
                  </TableCell>
                  {editIndex !== index && (
                    <>
                      <TableCell>
                        {canUpdate &&
                          <IconButton
                            size="small"
                            onClick={() => edit(edar, index)}
                          >
                            <EditIcon/>
                          </IconButton>}
                      </TableCell>
                      <TableCell>{edar.status}</TableCell>
                      <TableCell>{edar.calories}</TableCell>
                      <TableCell>
                        {edar.percentConsumed && (
                          <span>{edar.percentConsumed}%</span>
                        )}
                      </TableCell>
                      <TableCell>{edar.fluidsConsumed}</TableCell>
                      <TableCell>{edar.comments}</TableCell>
                    </>
                  )}
                  {editIndex === index && (
                    <>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={update}
                          startIcon={updating ? <CircularProgress size={20} />: null}
                          disabled={updating}
                        >
                          Save
                        </Button>
                        <Button
                          size="small"
                          onClick={() => setEditIndex(-1)}
                          disabled={updating}
                        >
                          Cancel
                        </Button>
                      </TableCell>
                      <TableCell>
                        {' '}
                        <Select
                          id="status"
                          label="Status"
                          value={editEdar.status}
                          name="status"
                          onChange={onEditStatus}
                          disabled={updating}
                        >
                          <MenuItem value={'Scheduled'}>
                            Scheduled
                          </MenuItem>
                          <MenuItem value={'Given'}>Given</MenuItem>
                          <MenuItem value={'Late'}>Late</MenuItem>
                          <MenuItem value={'Missed'}>Missed</MenuItem>
                          <MenuItem value={'Hold'}>Hold</MenuItem>
                          <MenuItem value={'SA'}>
                            Self Administered
                          </MenuItem>
                          <MenuItem value={'NA'}>
                            Not Administered
                          </MenuItem>
                          <MenuItem value={'Refused'}>Refused</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell>
                        <TextField
                            type={"number"}
                            InputProps={{
                              inputProps: { min: 0 }
                            }}
                          value={editEdar.calories}
                          name="calories"
                          disabled={updating}
                          onChange={onTextChange}
                        />
                      </TableCell>
                      <TableCell>
                        <SsaSlider
                          valueLabelDisplay="auto"
                          aria-label="pretto slider"
                          value={editEdar.percentConsumed}
                          onChange={onPercentConsumedChanged}
                          disabled={updating}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                            type={"number"}
                            InputProps={{
                              inputProps: { min: 0 }
                            }}
                          value={editEdar.fluidsConsumed}
                          name="fluidsConsumed"
                          onChange={onTextChange}
                          disabled={updating}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          value={editEdar.comments}
                          name="comments"
                          onChange={onTextChange}
                          disabled={updating}
                          multiline
                          maxRows={4}
                        />
                      </TableCell>
                    </>
                  )}
                  <TableCell>
                    {edar.administeredTimestamp && (
                      <span>
                            {moment(edar.administeredTimestamp).format(
                              'h:mm a'
                            )}
                        {edar.administeredUser ? (
                          <span> - {edar.administeredUser.initials}</span>
                        ) : null}
                          </span>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={5}>Daily Totals</TableCell>
                <TableCell>
                  {consumedCalories} / {totalRequiredCalories}
                </TableCell>
                <TableCell>{Number(percentConsumed).toFixed()}%</TableCell>
                <TableCell>{totalFluids}</TableCell>
                <TableCell/>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Hidden>
      <Hidden mdUp>
        <Divider/>
        <List>
          {loading && <ListLoading rows={4} />}
          {!loading && edars?.map((edar) =>
            <>
              <MobileEdar edar={edar} onUpdate={mobileUpdate} canUpdate={canUpdate}/>
              <Divider />
            </>
          )}
        </List>
      </Hidden>
    </Paper>
  );
};

export default ResidentEdars;
