import React from 'react';
import {
  makeStyles,
  Avatar,
  Grid,
  Badge,
  Button,
  withStyles,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon, ListItemSecondaryAction, Hidden,
} from '@material-ui/core';
import {
  getResidentAlerts,
  dismissAlerts,
} from '../../../services/resident.service';
import { ResidentContext } from '../../../context/residentcontext';
import { TenantLocationAlertContext} from "../../../context/TenantLocationAlertContext";
import {ViewAlertsModal} from "./modals/ViewAlertsModal";
import {Notifications, WarningRounded} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  alertsContainer: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(0, 0, 0, .5),
    },
  },
  alert: {
    background: theme.palette.warning.main,
    cursor: 'pointer',
  },
  recheckAlert: {
    background: theme.palette.primary.dark,
    cursor: 'pointer',
  },
  missedAlert: {
    background: theme.palette.error.light,
    cursor: 'pointer',
  },
  tooltipContainer: {
    padding: theme.spacing(1, 0)
  },
  prnContainer: {
    marginRight: theme.spacing(1),
    borderColor: theme.palette.grey[400],
    borderWidth: 1,
    borderStyle: 'solid'
  },
  prnContentContainer: {
    padding: theme.spacing(1),
    background: theme.palette.grey[600],
    color: theme.palette.error.contrastText,
    cursor: 'pointer'
  },
  alertIcon: {
    color: theme.palette.primary.dark
  },
  alertBadge: {

  },
  alertMenu: {
    marginTop: '9vh'
  },
  alertMenuItem: {
    width: '30vh'
  },
  standout: {
    color: theme.palette.error.main
  },
  late: {
    background: theme.palette.warning.main,
    cursor: 'pointer',
  },
  missed: {
    background: theme.palette.error.light,
    cursor: 'pointer',
  },
}));

const AlertBadge = withStyles((theme) => ({
  badge: {
    background: theme.palette.error.dark,
    color: theme.palette.error.contrastText
  },
}))(Badge);

const ResidentAlerts = ({ residentId }) => {
  const [count, setCount] = React.useState(0);
  const [viewLate, setViewLate] = React.useState(false);
  const [missedCount, setMissedCount] = React.useState(0);
  const [recheckCount, setRecheckCount] = React.useState(0);
  const [viewMissed, setViewMissed] = React.useState(false);
  const [viewRecheck, setViewRecheck] = React.useState(false);
  const classes = useStyles();
  const { state, dispatch } = React.useContext(ResidentContext);
  const {getTenantLocationAlerts, tenantLocationAlertCountLoading} = React.useContext(TenantLocationAlertContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    const init = async () => {
      await loadAlerts(residentId);
      dispatch({ type: 'REFRESH_ALERTS_COMPLETE' });
    }
    if (state.refreshAlerts && residentId) {
      init();
    }
    // eslint-disable-next-line
  }, [state.refreshAlerts, residentId]);

  React.useEffect(() => {
    const init = async () => {
      dispatch({ type: 'INITAL_ALERT_LOAD' });
      await loadAlerts(residentId);
    }
    if (residentId) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [residentId]);

  React.useEffect(() => {
    loadAlerts(residentId);
    // eslint-disable-next-line
  },[tenantLocationAlertCountLoading]);

  React.useEffect(() => {
    if (recheckCount > 0) {
      setViewRecheck(true);
    }
  }, [recheckCount]);



  const loadAlerts = async (residentId) => {
    const alertsResponse = await getResidentAlerts(residentId);
    if (!alertsResponse?.canceled && !alertsResponse?.error) {
      const alerts = alertsResponse;
      const warnings = alerts.filter(alert => String(alert?.type)?.toLowerCase()?.indexOf('late') > -1 || String(alert?.type).toLowerCase()?.indexOf('current') > -1);
      calculateCount(warnings);
      const missed = alerts.filter(alert => String(alert?.type)?.toLowerCase()?.indexOf('missed') > -1);
      calculateMissedCount(missed);
      const recheckAlerts = alerts?.filter(alert => alert?.type === "prn_recheck_medication")
      calculateRecheckCount(recheckAlerts);
      dispatch({ type: 'REFRESH_ALERTS_COMPLETE' });
    } else if (alertsResponse?.error) {
      dispatch({ type: 'REFRESH_ALERTS_COMPLETE' });
    }
    return recheckCount;
  };

  const calculateRecheckCount = alerts => {
    if (alerts?.length) {
      const nonDismissed = alerts?.filter((alert) => !alert.dismissed).length;
      setRecheckCount(nonDismissed);
    } else {
      setRecheckCount(0);
    }
  }

  const calculateMissedCount = (list) => {
    if (list && list.length) {
      const nonDismissed = list.filter((alert) => !alert.dismissed).length;
      setMissedCount(nonDismissed);
    } else {
      setMissedCount(0);
    }
  };

  const calculateCount = (list) => {
    if (list?.length) {
      const nonDismissed = list.filter((alert) => !alert.dismissed).length;
      setCount(nonDismissed);
    } else {
      setCount(0);
    }
  };

  const onDismissAlerts = async (type) => {
    await dismissAlerts(residentId, type);
    dispatch({ type: 'REFRESH_ALERTS' });
    getTenantLocationAlerts();
    setViewLate(false);
    setViewMissed(false);
  }

  const getBadgeCount = () => {
    return count + missedCount + recheckCount;
  }

  return (
    <Grid container
          justify={"flex-end"}
    >
      {getBadgeCount() > 0 &&
        <Button color={"primary"}
                onClick={handleClick}
                startIcon={
                  <AlertBadge badgeContent={getBadgeCount()}
                  >
                    <Notifications className={classes.alertIcon}/>
                  </AlertBadge>
                }
        >
          <Hidden xsDown>Alerts</Hidden>
        </Button>
      }
      <Menu
        id="alerts-menu"
        className={classes.alertMenu}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        >
        <MenuItem className={classes.alertMenuItem}
                  disabled
                  divider
        >
          Resident Alerts
        </MenuItem>
        {recheckCount > 0 &&
          <MenuItem className={classes.alertMenuItem}
                    onClick={() => setViewRecheck(true)}
                    divider
          >
            <ListItemIcon>
              <Avatar>
                {recheckCount}
              </Avatar>
            </ListItemIcon>
            <ListItemText primary={"PRN Recheck"} />
            <Hidden xsDown>
              <ListItemSecondaryAction>
                <WarningRounded className={classes.standout} />
              </ListItemSecondaryAction>
            </Hidden>
          </MenuItem>
        }
        {count > 0 &&
          <MenuItem className={classes.alertMenuItem}
                    onClick={() => setViewLate(true)}
                    divider>
            <ListItemIcon>
              <Avatar className={classes.late}>
                {count}
              </Avatar>
            </ListItemIcon>
            <ListItemText primary={"Late"} />
          </MenuItem>
        }
        {missedCount > 0 &&
          <MenuItem className={classes.menuItem}
                    onClick={() => setViewMissed(true)}
                    divider>
            <ListItemIcon>
              <Avatar className={classes.missed}>
                {missedCount}
              </Avatar>
            </ListItemIcon>
            <ListItemText primary={"Missed"} />
          </MenuItem>
        }
      </Menu>

        <ViewAlertsModal open={viewLate}
                         type={"late"}
                         residentId={residentId}
                         onDismissAlerts={onDismissAlerts}
                         onClose={() => setViewLate(false)} />
        <ViewAlertsModal open={viewMissed}
                         type={"missed"}
                         residentId={residentId}
                         onDismissAlerts={onDismissAlerts}
                         onClose={() => setViewMissed(false)} />

        <ViewAlertsModal open={viewRecheck}
                         type={"recheck"}
                         residentId={residentId}
                         onDismissAlerts={onDismissAlerts}
                         onClose={() => setViewRecheck(false)} />
    </Grid>
  )
};

export default ResidentAlerts;

