import React from 'react';

let DashboardContext = React.createContext();
let initialState = {
  tabIndex: "eSchedule"
}

const reducer = (state, action) => {
  switch (action?.type) {
    case 'SET_INDEX':
      return {...state, tabIndex: action?.payload}
    default:
      return {...state}
  }
}


const DashboardProvider = ({children}) => {
  let [state, dispatch] = React.useReducer(reducer, initialState);

  const value = { state, dispatch, tabIndex: state?.tabIndex };
  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  )
}

let DashboardConsumer = DashboardContext.Consumer;

export { DashboardContext, DashboardConsumer, DashboardProvider }
