import React from 'react';
import {StepActions} from "../step.actions";
import {IntakeContext} from "../../context/intake.context";
import {useStepStyles} from "../styles";
import {useHistory} from "react-router-dom";
import {Divider, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography} from "@material-ui/core";
import AddressModel from "./address.model";
import {blendObjects, setStepStatus, updateResidentAction} from "../../context/intake.actions";
import {FormFieldsGenerator} from "../../../../../../utils/forms";
import IntakePhones from "../../../../phones/intake.phones";
import ResidentAddresses from "../../../../address/resident.addresses";


export const AddressStep = () => {
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(AddressModel)));
  const {dispatch, state} = React.useContext(IntakeContext);
  const classes = useStepStyles();
  const history = useHistory();

  React.useEffect(() => {
    if (state?.resident) {
      const obj = blendObjects(state, JSON.parse(JSON.stringify(AddressModel)));
      setModel(obj);
    }
    // eslint-disable-next-line
  }, [state?.resident, state?.model]);

  const onSave = async () => {
    const data = {
      _id: state?.resident?._id,
      email: model?.email?.value
    };
    await updateResidentAction(dispatch, model, data);
    setStepStatus(dispatch, 'address', true);
    history.push(`/intake/${state.id}/contacts`);
  }

  const onChange = e => {
    setModel({...model, ...e});
  }

  return (
    <div>
      <div className={classes.titleContainer}>
        <Typography variant={"h4"}>
          Address & Phone/Email
        </Typography>
        <Divider/>
      </div>
      <div>
        <ExpansionPanel defaultExpanded="true">
          <ExpansionPanelSummary>Email</ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{width: '100%'}}>
              <FormFieldsGenerator model={model} onChange={onChange}/>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel defaultExpanded="true">
          <ExpansionPanelSummary>Phone</ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <IntakePhones residentId={state?.id} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel defaultExpanded="true">
          <ExpansionPanelSummary>Address</ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <ResidentAddresses residentId={state?.id}/>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
      <div className={classes.btnContainer}>
        <StepActions onNext={onSave}
                     onPrev={() => history.push(`/intake/${state.id}/background`)}
        />
      </div>
    </div>
  )
}
