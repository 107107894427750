import React from 'react';
import {Button, Grid, LinearProgress, makeStyles} from "@material-ui/core";
import ResidentSearch from "../../../resident/search/resident.search";
import {Dashboard} from "@material-ui/icons";
import {getOrderStatuses, getResidentMedicationOrders} from "../../../../services/medication.order.service";
import filterTemplate from "../../../resident/medicationorders/filterTemplate";
import {convertToFilters} from "../../../../utils/filter.utils";
import { CurrentResidentMedicationsReport } from './CurrentResidentMedicationsReport';
import {getResidentById} from "../../../../services/resident.service";
import { PDFViewer } from '@react-pdf/renderer';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1)
  }
}))

export const CurrentResidentMedicationListReport = () => {
  const classes = useStyles();
  const [resident, setResident] = React.useState();
  const [data, setData] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [orderStatuses, setOrderStatuses] = React.useState([]);

  const onResidentSelected = async resident => {
    const response = await getResidentById(resident?._id);
    if (!response?.error) {
      setResident(response);
    }
  }

  const onResidentClear = () => {
    setResident(null);
  }

  const init = async () => {
    if (!orderStatuses || orderStatuses.length === 0) {
      const data = await getOrderStatuses();
      setOrderStatuses(data);
      return data.find((d) => d.name === 'Active');
    } else {
      return orderStatuses.find((d) => d.name === 'Active');
    }
  };

  const generateReport = async () => {
    setLoading(true);
    const activeStatus = await init();
    const obj = JSON.parse(JSON.stringify(filterTemplate));
    obj.status.value = activeStatus ? activeStatus._id : -1;
    const filters = convertToFilters(obj);
    const response = await getResidentMedicationOrders(resident?._id,1, 1000, filters, 'medication.tradeName', 'asc');
    if (!response?.error) {
      setData(response?.content);
    }
    setLoading(false);
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={10}>
          <ResidentSearch onSelect={onResidentSelected}
                          onClear={onResidentClear} />
        </Grid>
        <Grid item xs={2}>
          <Button variant={"contained"}
                  style={{width: '100%'}}
                  disabled={!resident}
                  onClick={generateReport}
                  startIcon={<Dashboard />}
          >
            Generate Report
          </Button>
        </Grid>

      </Grid>

      <div>
        {loading && <LinearProgress />}
        {!loading && data &&
          <PDFViewer style={{width: '100%', height: '70vh'}}>
            <CurrentResidentMedicationsReport data={data} resident={resident} />
          </PDFViewer>
        }
      </div>
    </div>
  )
}
