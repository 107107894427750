import React from 'react';
import {Button, CircularProgress, Grid, makeStyles, Typography} from "@material-ui/core";
import {getAssessmentInstancePdf} from "../../services/assessment.service";
import {GlobalNotificationContext} from "../notification/globalnotificationprovider";
import {Document, Page} from "react-pdf";
import {CloudDownload, SkipNextOutlined, SkipPrevious} from "@material-ui/icons";
import useHasRole from "../../hooks/user.role.hook";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    margin: theme.spacing(.5,0)
  },
  groupName: {
    width: '100%'
  },
  questionContainer: {
    width: '100%',
    paddingLeft: '2rem',
    display: 'flex',
    alignItems: 'center'
  },
  questionLabel: {
    marginRight: '1rem'
  },
  questionValue: {
  },
  pdfContainer: {
    maxHeight: "50vh",
    overflow: 'auto',
    backgroundColor: theme.palette.grey[200]
  },
  loadingContainer: {
    padding: theme.spacing(3)
  }
}));

const PdfPreview = ({resident, assessmentInstance }) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [pdf, setPdf] = React.useState();
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pages, setPages] = React.useState(0);
  const { addNotification } = React.useContext(GlobalNotificationContext);
  const { checkPermission } = useHasRole();
  const canPrint = checkPermission("REPORT_PRINTER")

  React.useEffect(() => {
    if (resident && assessmentInstance?._id) {
      fetchAssessment(resident?._id, assessmentInstance?._id);
    }
    // eslint-disable-next-line
  }, [resident, assessmentInstance]);

  const fetchAssessment = async (residentId, assessmentInstanceId) => {
    setLoading(true);
    const response = await getAssessmentInstancePdf(residentId, assessmentInstanceId);
    if (!response?.error) {
      const fileUrl = URL.createObjectURL(response);
      setPdf(fileUrl);
    } else {
      addNotification("Error loading pdf");
    }
    setLoading(false);
  }

  const onDocumentLoadSuccess = ( {numPages} ) => {
    setPages(numPages);
  }

  const downloadFile = () => {
    window.open(pdf);
  }

  return (
    <div className={classes.root}>
      {loading &&
        <Grid container
              className={classes.loadingContainer}
              justify={"center"}
        >
          <Grid container justify={"center"} alignItems={"center"} direction={"column"}>
            <CircularProgress />
            <Typography variant={"subtitle1"} style={{ marginTop: '.25rem'}}>Loading report...</Typography>
          </Grid>
        </Grid>
      }
      {!loading && pdf &&
        <Grid container justify={"center"} className={classes.pdfContainer}>
          <Document
            file={pdf}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={'Please wait, the document is loading!'}
            onLoadError={console.error}
          >
            <Page pageNumber={pageNumber} width={880} />
          </Document>

        </Grid>
      }
      {pages > 1 &&
      <Grid container justify={"space-between"}>
        <Grid>
          <Button startIcon={<SkipPrevious />}
                  onClick={() => setPageNumber(pageNumber => pageNumber === 1 ? 1: pageNumber - 1)}

          >Previous</Button>
          <Button endIcon={<SkipNextOutlined />}
                  onClick={() => setPageNumber(pageNumber => pageNumber === pages ? pageNumber: pageNumber + 1)}
          >Next</Button>
        </Grid>
        <div>
          <Grid container>
            <Typography variant={"subtitle2"}>{pageNumber}</Typography>
            /
            <Typography variant={"subtitle2"}>{pages}</Typography>
          </Grid>
        </div>
        {canPrint &&
          <Button
            size={'small'}
            variant={'contained'}
            color={'primary'}
            onClick={downloadFile}
            startIcon={<CloudDownload/>}
          >
            Download
          </Button>
        }
      </Grid>}
      {canPrint && pages === 1 &&
      <Grid container justify={"flex-end"}>
        <Button
          size={'small'}
          variant={'contained'}
          color={'primary'}
          onClick={downloadFile}
          startIcon={<CloudDownload />}
        >
          Download
        </Button>
      </Grid>
      }
    </div>
  )

}

export default PdfPreview;
