const model = {
  incidentDate: {
    title: 'Incident Date',
    required: true,
    value: Date.now(),
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    time: true,
  },
  date: {
    title: 'Date',
    required: true,
    value: Date.now(),
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    time: true,
  },
  category: {
    title: 'Category',
    required: true,
    value: [],
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  location: {
    title: 'Location',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  staff: {
    title: 'Staff',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  nature: {
    title: 'Nature of incident',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  description: {
    title: 'Description',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  witness: {
    title: 'Witness',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  conditionBefore: {
    title: 'Condition Before',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  conditionAfter: {
    title: 'Condition After',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  evaluationOfPlan: {
    title: 'Evaluation of plan',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  notifications: {
    title: 'Notifications',
    required: false,
    value: [],
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
};

export default model;
