import React from 'react'
import PrivateRoute from "../PrivateRoute";
import { Switch } from "react-router-dom";
import MedicalProviderList from './medical.provider.list';
import MedicalProvider from './medical.provider';

const MedicalProviders = () => {
    return (    
      <Switch>
        <PrivateRoute exact path="/medicalproviders" component={MedicalProviderList} />
        <PrivateRoute exact path="/medicalproviders/:id" component={MedicalProvider} />
      </Switch>
    )
}

export default MedicalProviders;