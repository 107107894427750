import React from 'react';
import {
  Dialog,
  Button,
  IconButton,
  DialogContent,
  Link,
  makeStyles,
  DialogActions, DialogTitle,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FormFieldsGenerator from '../../../utils/forms/form.fields.generator';
import { isModelValid } from '../../../utils/forms';
import {TransferList} from "./transferlist";

const modelTemplate = {
  name: {
    title: 'Name',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
};


const useStyles = makeStyles((theme) => ({
  root: {
  },
  dialogContent: {
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const CreateRoleModal = ({ open, permissions, onClose, onSave }) => {
  const classes = useStyles();
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(modelTemplate)));
  const [role, setRole] = React.useState();

  React.useEffect(() => {
    if (open) {
      initForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const initForm = async () => {
    const obj = JSON.parse(JSON.stringify(modelTemplate));
    setModel(obj);
    setRole({
      name: null,
      permissions: []
    });
  };

  const onChange = (e) => {
    const obj = { ...model, ...e };
    setModel(obj);
  };
  const save = () => {
    const data = {
      name: model.name.value,
      permissions: role?.permissions
    };
    onSave(data);
  };

  const onPermissionChanged = updatedRole => {
    const obj = {...role, permissions: updatedRole?.permissions }
    setRole(obj);
  }

  return (
    <Dialog
      fullWidth
      maxWidth={"lg"}
      open={open}
      onClose={onClose}
    >
      <DialogTitle
        onClose={() => onClose()}>
        {'Role Permissions'}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <FormFieldsGenerator model={model} onChange={onChange} />
        {role && permissions &&
          <TransferList choices={permissions}
                        role={role}
                        onSave={onPermissionChanged}
          />
        }
      </DialogContent>
      <DialogActions>
        <Link onClick={onClose}>Cancel</Link>
        <Button
          disabled={!isModelValid(model) || role?.permissions?.length === 0}
          onClick={save}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateRoleModal;
