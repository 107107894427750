import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import history from '../../utils/history';
import UsersList from '../admin/userManagement/users.list';
import Alert from '@material-ui/lab/Alert';
import NavBar from './NavBar';
import UserProfile from '../userprofile/user.profile';
import PrivateRoute from '../PrivateRoute';
import ExternalApi from '../../views/ExternalApi';
import { useAuth0 } from '../../react-auth0-spa';
import { UserContext } from '../../context/usercontext';
import Pharmacies from '../pharmacies/pharmacies';
import Physicians from '../physicians/physicians';
import MedicalProviders from '../medicalProviders/medical.providers';
import Insurances from '../insurances/insurances';
import Contacts from '../contacts/contacts';
import Medications from '../medications/medications';
import MedicationOrders from '../medicationorders/medicationorders';
import Residents from '../resident/list/residents';
import Dashboard from '../dashboard/dashboard';
import Locations from '../tenants/locations';
import Tenants from '../tenants/tenants';
import { UsersWrapper } from '../users';
import Permissions from '../permissions/permissions';
import NoteCategories from "../notecategory/notecategories";
import Reports from "../reports/reports";
import VersionComponent from "../VersionComponent";
import Allergies from "../allergy/allergies";
import Diagnosis from "../diagnosis/diagnosis";
import Assessments from "../assessments/Assessments";
import InactivePage from "./inactive.page";
import {UserTenantContextProvider} from "../../context/usertenantcontext";
import {ResidentListProvider} from "../resident/list/resident.list.context";
import DeactivatedNavbar from "./DeactivatedNavbar";
import {TenantLocationAlertContextProvider} from "../../context/TenantLocationAlertContext";
import {DashboardProvider} from "../dashboard/dashboard.context";
import {Calendars} from "../calendars";
import {TenantCalendarPageWrapper} from "../tenants/calendar";
import {TenantNotes} from "../notes";
import {LinearProgress} from "@material-ui/core";
import useGlobalLoadingIndicator from "../../hooks/global.loading.hook";
import { ResidentIntakePageWrapper } from '../resident/intake/page';
import {TimeEntryProvider} from "../../context/TimeEntryContext/time.entry.context";
import { TimeEntryComponent } from '../time.entry';
import TimeEntryPage from '../time.entry/page';
import {AdministrationPage} from "../../pages";


const Layout = () => {
  const { state, user } = React.useContext(UserContext);
  const { error, deactiveUser } = useAuth0();
  const { loading } = useGlobalLoadingIndicator();
    return (
          <Router history={history}>
            {deactiveUser === true &&
              <>
                <DeactivatedNavbar />
                <InactivePage />
              </>
            }
            {deactiveUser !== true &&
              <TimeEntryProvider user={user} tenant={state?.selectedTenant}>
                <TimeEntryComponent />
                <UserTenantContextProvider>
                  <TenantLocationAlertContextProvider>
                    <DashboardProvider>
                      <ResidentListProvider>
                        {loading && <LinearProgress  />}
                        <NavBar/>
                        {error && <Alert severity="error">{error}</Alert>}
                        <div hidden={!user?.isActive === true}>
                          <Switch>
                            {state && state.token && (
                              <React.Fragment>
                                <PrivateRoute path="/profile" component={UserProfile}/>
                                <PrivateRoute path="/external-api" component={ExternalApi}/>
                                <PrivateRoute path="/residents" component={Residents}/>
                                <PrivateRoute path="/admin/users" component={UsersList}/>
                                <PrivateRoute path="/pharmacies" component={Pharmacies}/>
                                <PrivateRoute path="/physicians" component={Physicians}/>
                                <PrivateRoute
                                  path="/medicalproviders"
                                  component={MedicalProviders}
                                />
                                <PrivateRoute path="/insurances" component={Insurances}/>
                                <PrivateRoute path="/contacts" component={Contacts}/>
                                <PrivateRoute path="/medications" component={Medications}/>
                                <PrivateRoute
                                  path="/medicationorders"
                                  component={MedicationOrders}
                                />
                                <PrivateRoute path="/dashboard" component={Dashboard}/>
                                <PrivateRoute path="/" exact component={Dashboard}/>
                                <PrivateRoute path="/locations" component={Locations}/>
                                <PrivateRoute path="/tenants" component={Tenants}/>
                                <PrivateRoute path="/users" component={UsersWrapper}/>
                                <PrivateRoute path="/permissions" component={Permissions}/>
                                <PrivateRoute path="/notecategories" component={NoteCategories}/>
                                <PrivateRoute path={"/reports"} component={Reports}/>
                                <PrivateRoute path={"/allergies"} component={Allergies}/>
                                <PrivateRoute path={"/diagnosis"} component={Diagnosis}/>
                                <PrivateRoute path={"/assessments"} component={Assessments}/>
                                <PrivateRoute path={"/mycalendar"} component={Calendars} />
                                <PrivateRoute path={"/facilitycalendar"} component={TenantCalendarPageWrapper} />
                                <PrivateRoute path={"/notes"} component={TenantNotes} />
                                <PrivateRoute path={"/intake"} component={ResidentIntakePageWrapper} />
                                <Route path={"/info"} component={VersionComponent}/>
                                <Route path={"/timeentry"} component={TimeEntryPage} />
                                <Route path={"/administration"} component={AdministrationPage} />
                              </React.Fragment>
                            )}
                          </Switch>
                        </div>
                      </ResidentListProvider>
                    </DashboardProvider>
                  </TenantLocationAlertContextProvider>
                </UserTenantContextProvider>
              </TimeEntryProvider>
              }
          </Router>
    );
};

export default Layout;
