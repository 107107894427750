import React from 'react';
import {Avatar, Button, ListItemIcon, ListItemText, makeStyles, Menu, MenuItem, Typography} from "@material-ui/core";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import draftToHtml from 'draftjs-to-html';
import {Delete, Edit} from "@material-ui/icons";
import {UserContext} from "../../../context/usercontext";
import useGlobalNotification from "../../../hooks/notification.hook";
import { Editor } from "react-draft-wysiwyg";
import {EditorState, convertToRaw, convertFromRaw} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {deleteNoteComment, updateNoteComment} from "../../../services/resident.service";

const useStyles = makeStyles((theme) => ({
  commentRoot: {
    display: 'flex',
    marginBottom: theme.spacing(1)
  },
  comment: {
    marginLeft: '1rem',
    width: '100%',
    borderRadius: '5px',
    padding: '.5rem',
    background: '#e0e0e0',
  },
  header: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between'
  },
  content: {
    width: '100%',
  }
}));

const Comment = ({note, comment, onDelete}) => {
  const classes = useStyles();
  const [menuEl, setMenuEl] = React.useState(null);
  const open = Boolean(menuEl);
  const { state } = React.useContext(UserContext);
  const { addNotification } = useGlobalNotification();
  const [edit, setEdit] = React.useState(false);
  const [editComment, setEditComment] = React.useState();
  const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());
  const [ currentComment, setCurrentComment] = React.useState();

  React.useState(() => {
    setCurrentComment(comment);
  }, [ comment ]);

  const handleClick = (event) => {
    setMenuEl(event.currentTarget);
  };

  const handleClose = () => {
    setMenuEl(null);
  };

  const handleEdit = () => {
    handleClose();
    const content = convertFromRaw(JSON.parse(currentComment?.content));
    setEditorState(EditorState.createWithContent(content));
    setEdit(true);
  }

  const handleDelete = async () => {
    handleClose();
    const status = await deleteNoteComment(note?.resident?._id, note?._id, currentComment?._id);
    if (status) {
      onDelete(currentComment?._id);
    } else {
      addNotification("Unabled to delete", "error");
    }
  }

  const canEdit = () => {
    return state?.user?._id === currentComment?.user?._id;
  }

  const cancelEditComment = () => {
    setEdit(false);
    setEditorState(EditorState.createEmpty());
    setEditComment(null);
  }

  const onUpdateComment = async () => {
    const payload = {
      message: editComment,
      text: editorState?.getCurrentContent()?.getPlainText(),
      html: draftToHtml(editComment)
    }
    const val = await updateNoteComment(note?.resident?._id, note?._id, currentComment?._id, payload);
    setCurrentComment(val);
    setEdit(false);
    setEditorState(EditorState.createEmpty());
    setEditComment(null);
  }

  const onChange = e => {
    setEditorState(e);
    const message = convertToRaw(e.getCurrentContent());
    setEditComment(message);
  }

  return (
    <div className={classes.commentRoot}>
      <div>
        <Avatar src={currentComment?.user?.picture} />
      </div>
      <div className={classes.comment}>
        <div className={classes.header}>
          <Typography variant={"subtitle2"}>
            <b>{currentComment?.user?.name}</b> commented on {moment(currentComment?.createdAt).format('MM/DD/YYYY h:mm a')}
          </Typography>
          {canEdit() &&
          <IconButton size={"small"} onClick={handleClick}>
            <MoreHorizIcon />
          </IconButton>}
          <Menu
            id="long-menu"
            anchorEl={menuEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: '20ch',
              },
            }}
          >
            <MenuItem onClick={handleEdit}>
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              <ListItemText primary={"Edit"} />
            </MenuItem>
            <MenuItem onClick={handleDelete}>
              <ListItemIcon>
                <Delete />
              </ListItemIcon>
              <ListItemText primary={"Delete"} />
            </MenuItem>
          </Menu>
        </div>
        <div>
        </div>
        {!edit &&
        <div className={classes.content} dangerouslySetInnerHTML={{__html: currentComment?.html}} />}
        {edit &&
        <div style={{marginBottom: '.75rem', background: "#fff", padding: '1rem'}}>
          <Editor editorState={editorState}
                  onEditorStateChange={text => onChange(text)}
                  hashtag={{
                    separator: ' ',
                    trigger: '#',
                  }} />
          <Button size={"small"} variant={"contained"} color={"primary"} onClick={onUpdateComment}>Update</Button>
          <Button size="small" color={"primary"} onClick={cancelEditComment}>Cancel</Button>
        </div>
        }
      </div>
    </div>
  )
}


export default Comment;
