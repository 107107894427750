import React from "react";
import {
  makeStyles,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  Typography,
  ListItemText, CircularProgress, Button,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import history from "../../../../utils/history";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import CancelIcon from "@material-ui/icons/Cancel";
import {updateAlert} from "../../../../services/resident.service";
import {RotateLeftTwoTone} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {},
  alert: {
    cursor: "pointer",
  },
  notDismissed: {
    background: red[400],
  },
}));

const MissedTreatmentAlert = ({
                                treatmentAlert,
                                onViewEntity,
                                onDismissedChange,
                                showResidentName = false}) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const viewMedication = () => {
    onViewEntity();
    history.push(`/residents/${treatmentAlert.resident}/etar`);
  };


  const onDismissedToggle = async () => {
    setError(false);
    setLoading(true);
    const residentId = treatmentAlert?.resident;
    const alertId = treatmentAlert?._id;
    const dismissed = !treatmentAlert?.dismissed;
    const result = await updateAlert(residentId, alertId, {dismissed});
    if (!result?.error) {
      onDismissedChange(result)
    } else {
      setError(true);
    }
    setLoading(false);
  }

  if (loading) {
    return (
      <ListItem className={classes.root}
                button
      >
        <ListItemAvatar>
          <CircularProgress />
        </ListItemAvatar>
        <ListItemText primary={"Processing...."} />
      </ListItem>
    )
  }

  if (!loading && error) {
    return (
      <ListItem className={classes.root}
                button
      >
        <ListItemAvatar>
          <Avatar
            className={[
              !treatmentAlert?.dismissed ? classes.notDismissed : null,
            ]}
          >
            <i className="fas fa-radiation"></i>
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={"Something went wrong..."} secondary={"Please click retry"} />
        <ListItemSecondaryAction>
          <Button startIcon={<RotateLeftTwoTone />}
                  onClick={onDismissedToggle}
                  color={"primary"}
                  variant={"contained"}
          >
            Retry
          </Button>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }

  if (treatmentAlert) {
    return (
      <ListItem
        divider
        className={classes.root}
        button
        onClick={() => viewMedication()}
      >
        <ListItemAvatar>
          <Avatar
            className={[
              !treatmentAlert.dismissed ? classes.notDismissed : null,
            ]}
          >
            <i class="fas fa-band-aid"></i>
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={`Missed Treatment ${showResidentName ? `- ${treatmentAlert?.fkEntity?.residentData?.firstName} ${treatmentAlert?.fkEntity?.residentData?.lastName}`: ''}`}
          secondary={
            <React.Fragment>
              {treatmentAlert && treatmentAlert.fkEntity && (
                <div>
                  <Typography variant="body">
                    {treatmentAlert.fkEntity.name}
                  </Typography>
                  {" "}
                  <Typography variant="body">
                    {treatmentAlert.fkEntity.description}
                  </Typography>

                  {treatmentAlert.scheduledTime && (
                    <Typography variant="body">
                      {" "}
                      {moment(treatmentAlert.scheduledTime).format(
                        "MM/DD/YYYY h:mm A"
                      )}
                    </Typography>
                  )}
                </div>
              )}
            </React.Fragment>
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="comments"
            onClick={onDismissedToggle}
          >
            {!treatmentAlert?.dismissed && <CancelIcon/>}
            {treatmentAlert?.dismissed && <SettingsBackupRestoreIcon/>}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  } else {
    return null;
  }
};

export default MissedTreatmentAlert;
