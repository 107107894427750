import React from 'react';
import {Box, Button} from "@material-ui/core";
import {Publish, GetApp, Add, Delete} from '@material-ui/icons/';
import {onFileDownloadAction, onFileUploadAction} from "../context/Actions";
import {ResidentFileContext} from "../context/ResidentFilesContext";
import {CONSTANTS} from "../context/Constants";
import useHasRole from "../../../../hooks/user.role.hook";

export const FileToolbox = () => {
  const {state, dispatch} = React.useContext(ResidentFileContext);
  const { checkPermission } = useHasRole();
  const canCreateFile = checkPermission("RESIDENT_FILE_CREATE");
  const canDelete = checkPermission("RESIDENT_FILE_DELETE");
  const canDownLoad = checkPermission("RESIDENT_FILE_DOWNLOAD");

  const onFileSelected = async (event) => {
    const files = event.target.files;
    await onFileUploadAction(state?.residentId, Array.from(files), state?.selectedFolder?._id, [], dispatch);
  }

  const selectedFiles = () => {
    return state?.selectedFiles?.filter(f => f?.checked && f?.type === "file")?.length > 0;
  }

  const selectedForDelete = () => {
    return state?.selectedFiles?.filter(f => f?.checked)?.length > 0;
  }

  const handleCreateFolderClick = () => {
    dispatch({type: CONSTANTS.SET_CREATE_FOLDER_MODAL, payload: true});
  }

  const handleDeleteClick = async () => {
    dispatch({type: CONSTANTS.SET_DELETE_FILE_ITEM_MODAL, payload: true});
  }

  const handleDownloadClick = async () => {
    const files = state?.selectedFiles?.filter(f => f?.checked && f?.type !== "folder");
    for (const file of files) {
      await onFileDownloadAction(state?.residentId, file?._id);
    }
  }

  return (
    <Box display={"flex"}
         alignItems={"center"}
    >
      <input
        type="file"
        multiple="multiple"
        style={{display: 'none'}}
        id="contained-button-file"
        onChange={onFileSelected}
      />
      {canCreateFile &&
        <label htmlFor="contained-button-file">
        <Button variant={"text"}
                color={"primary"}
                size={"small"}
                component="span"
                startIcon={<Publish/>}
        >
          Upload File
        </Button>
      </label>
      }
      {canCreateFile &&
      <Button variant={"text"}
              color={"primary"}
              size={"small"}
              onClick={handleCreateFolderClick}
              startIcon={<Add />}
      >
        Create Folder
      </Button>
      }
      {canDownLoad &&
      <Button disabled={!selectedFiles()}
              startIcon={<GetApp />}
              color={"primary"}
              size={"small"}
              onClick={handleDownloadClick}
      >
        Download
      </Button>
      }
      {canDelete &&
      <Button disabled={!selectedForDelete()}
              color={"primary"}
              onClick={handleDeleteClick}
              startIcon={<Delete />}
              size={"small"}>
        Delete
      </Button>
      }
    </Box>
  )
}
