import React from 'react';
import {Button, ButtonGroup, DialogActions, Grid, makeStyles, Paper, Typography} from "@material-ui/core";
import moment from "moment/moment";
import { MuiPickersUtilsProvider, KeyboardTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '90%',
    padding: '5rem',
    margin: '0 auto'
  }
}));

export const TimeClockDayForm = ({week, timeClocks, onCancel, onSave}) => {
  const classes = useStyles();
  const [startWeek, setStartWeek] = React.useState();
  const [endWeek, setEndWeek] = React.useState();
  const [days, setDays] = React.useState([]);
  const [clockIn, setClockIn] = React.useState();

  React.useEffect(() => {
    if (week && timeClocks) {
      setUpDays(week, timeClocks);
    }
  }, [week, timeClocks]);

  const setUpDays = (wk, clocks) => {
    let weekObj = moment().week(wk).utc();
    let startOfWeek = weekObj?.clone()?.startOf("week");
    setStartWeek(startOfWeek.clone())
    let endOfWeek = weekObj.clone()?.endOf("week");
    setEndWeek(endOfWeek.clone())
    let calucatedDays = [];
    while (startOfWeek.isBefore(endOfWeek)) {
      const dayOption = {
        date: startOfWeek.clone(),
        selected: false
      }
      calucatedDays = [...calucatedDays, dayOption]
      startOfWeek = startOfWeek.add(1, "day");
    }
    calucatedDays[0].selected = true;
    setDays(calucatedDays);
  }

  const dateExists = (date, clocks) => {
    return clocks?.findIndex(clock => moment(clock?.date).isSame(moment(date), "day")) > -1;
  }

  const selectDay = day => {
    const obj = days?.map(d => ({...d, selected: moment(d.date).isSame(day.date, "day")}));
    setDays(obj);
  }

  const onClockInChange = date => {
    setClockIn(date)
  }

  const isValid = () => {
    return moment(clockIn).isValid();
  }

  const save = () => {
    let selectedDay = days?.find(d => d.selected);
    if (selectedDay) {
      let selectedDate = moment(selectedDay?.date).clone();
      let dateTime = moment(clockIn).clone();
      dateTime.month(selectedDate.month());
      dateTime.date(selectedDate.date());
      dateTime.second(0);
      dateTime.millisecond(0);
      const data = {
        date: selectedDate,
        events: [{clockIn: dateTime}]
      }
      onSave(data);
    }
  }

  return (
    <Paper className={classes.root}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container>
          <Typography variant={"h6"}>
            Week: {week}&nbsp;
          </Typography>
          <Typography variant={"h6"}>
            {moment(startWeek).utc().format("MM/DD/YYYY")}
          </Typography>
          <Typography variant={"h6"}>
            &nbsp;-&nbsp;
          </Typography>
          <Typography variant={"h6"}>
            {moment(endWeek).utc().format("MM/DD/YYYY")}
          </Typography>
        </Grid>
        <ButtonGroup fullWidth>
          {days?.map(day =>
            <Button
              variant={day?.selected ? "contained": "outlined"}
              color={day?.selected ? "primary": null}
              disabled={dateExists(day?.date, timeClocks)}
              onClick={() => selectDay(day)}
            >
              {moment(day?.date)?.format("MM/D")}
            </Button>
          )}
        </ButtonGroup>


        <KeyboardTimePicker
          fullWidth
          mask="__:__ _M"
          label={"Clock In"}
          value={clockIn}
          name={"clockIn"}
          onChange={onClockInChange}
          style={{marginTop: '1rem', marginBottom: '1rem'}}
        />

        <DialogActions>
          <Button variant={"text"} onClick={onCancel}>Cancel</Button>
          <Button variant={"contained"}
                  color={"primary"}
                  disabled={!isValid()}
                  onClick={save}>Save</Button>
        </DialogActions>
      </MuiPickersUtilsProvider>
    </Paper>
  )
}
