import React from 'react';
import { useHistory, useParams, Link} from 'react-router-dom';
import { ObjectId } from 'bson';
import {CircularProgress, Tab, Tabs} from "@material-ui/core";
import {IntakeContext, IntakeProvider} from "./context/intake.context";
import {getResident, handleStepByName, setId, setNewIntake} from "./context/intake.actions";
import {
  DetailsStep,
  ResidentPhotoStep,
  MedicalStep,
  BackgroundStep,
  AddressStep,
  ContactsStep,
  AssessmentStep,
  SummaryStep
} from "./steps";
import useGlobalLoadingIndicator from "../../../../hooks/global.loading.hook";
import {CheckCircle} from "@material-ui/icons";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import {UserContext} from "../../../../context/usercontext";
import {useStepStyles} from "./steps/styles";
import {ListLoading} from "../../../../utils/indicators/list.loading";


const getDisabled = (step, steps) => {
  if (step?.completed) {
    return false;
  } else {
    if (steps && step?.prevIndex > -1) {
      const prev = steps[step.prevIndex];
      if (prev?.completed) {
        return false;
      }
    }
    return true;
  }
}


export const ResidentIntake = () => {
  let history = useHistory();
  const { id, step } = useParams();
  const { state, dispatch } = React.useContext(IntakeContext);
  const { toggleLoading } = useGlobalLoadingIndicator()
  const classes = useStepStyles();

  React.useEffect(() => {
    if (state?.loading) {
      toggleLoading(true);
    } else {
      toggleLoading(false);
    }
  }, [state?.loading, toggleLoading]);


  React.useEffect(() => {
    if (id && step) {
      setId(dispatch, id);
      handleStepByName(dispatch, step);
    } else if (!id && !step && state?.id) {
      history.push(`/intake/${state.id}/details`)
    } else if (!id && !state?.id) {
      const newId = new ObjectId().toHexString();
      setNewIntake(dispatch, true);
      setId(dispatch, newId);
      history.push(`/intake/${newId}/details`);
    } else if (!id && state?.id) {
      history.push(`/intake/${state.id}`)
    } else if (id && !step) {
      history.push(`/intake/${id}/details`);
    }
  }, [id, step, state.id, history, toggleLoading, dispatch]);


  React.useEffect(() => {
    if (id) {
      getResident(dispatch, state, id);
    }
    // eslint-disable-next-line
  }, [id, dispatch]);

  if (!id) {
    return null;
  }

  return (
    <div>
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        centered
        value={state?.step?.name}>
        {state?.steps?.map(step => !state?.loading ?
          <Tab value={step?.name}
               label={step?.title}
               icon={step?.completed ? <CheckCircle className={classes.completedStep} /> : <RadioButtonUncheckedIcon />}
               disabled={getDisabled(step, state?.steps)}
               component={Link}
               to={`/intake/${id}/${step.name}`}
          />:
          <Tab value={step?.name}
               label={step?.title}
               icon={<CircularProgress size={20}/>}
          />
        )}
      </Tabs>
      {(state?.loading || state?.updating) &&
        <ListLoading rows={5} />
      }
      {!state.loading && !state.updating &&
        <div className={classes.stepContainer}>
          {state?.step?.name === "details" && <DetailsStep/>}
          {state?.step?.name === "photo" && <ResidentPhotoStep />}
          {state?.step?.name === "medical" && <MedicalStep/>}
          {state?.step?.name === "background" && <BackgroundStep/>}
          {state?.step?.name === "address" && <AddressStep/>}
          {state?.step?.name === "contacts" && <ContactsStep/>}
          {state?.step?.name === "assessment" && <AssessmentStep/>}
          {state?.step?.name === "summary" && <SummaryStep/>}
        </div>
      }
    </div>
  )
}

export const ResidentIntakePage = () => {
  const { selectedTenant, selectedTenantLocation, tenantLocations } = React.useContext(UserContext);
  return (
    <IntakeProvider tenantLocation={selectedTenantLocation}
                    tenantLocations={tenantLocations}
                    tenant={selectedTenant}
    >
      <ResidentIntake />
    </IntakeProvider>
  )
}
