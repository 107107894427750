export const SET_STEP = "SET_STEP";
export const SET_STEP_BY_NAME = "SET_STEP_BY_NAME";
export const SET_RESIDENT_ID = "SET_RESIDENT_ID";
export const SET_RESIDENT = "SET_RESIDENT";
export const SET_MODEL = "SET_MODEL";
export const SET_LOADING = "SET_LOADING";
export const SET_UPDATING = "SET_UPDATING";
export const SET_ERROR = "SET_ERROR";
export const SET_NEW_INTAKE = "SET_NEW_INTAKE";
export const SET_TENANT_LOCATIONS = "SET_TENANT_LOCATIONS";
export const SET_TENANT_LOCATION = "SET_TENANT_LOCATION";
export const SET_STEP_STATUS = "SET_STEP_STATUS";
export const MERGE_MODEL_WITH_VALUES = "MERGE_MODEL_WITH_VALUES";
export const SET_TENANT = "SET_TENANT";
export const SET_UPLOADING_PHOTO = "SET_UPLOADING_PHOTO";
export const SET_PHOTO = "SET_PHOTO";

