import React from 'react';

import {
  Paper,
  makeStyles,
  Typography,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Divider,
} from '@material-ui/core';
import { getResidentVitalsForVital } from '../../../services/evital.service';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
} from 'recharts';
import moment from 'moment';

const vitalTypes = [
  { label: 'Temperature', field: 'temperature' },
  { label: 'Blood Pressure', field: 'bloodPressure' },
  { label: 'Pulse', field: 'pulse' },
  { label: 'RespiratoryRate', field: 'respiratoryRate' },
  { label: 'Oxygen', field: 'oxygen' },
  { label: 'BloodGlucose', field: 'bloodGlucose' },
  { label: 'Inr', field: 'inr' },
  { label: 'Weight', field: 'weight' },
  { label: 'BMI', field: 'bmi' },
  { label: 'PainLevel', field: 'painLevel' },
];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  header: {
    marginBottom: theme.spacing(1),
  },
  content: {
    minHeight: '20rem',
    maxHeight: '20rem',
    overflowY: 'scroll',
  },
  chartContainer: {
    margin: theme.spacing(3, 0),
  },
}));

const ResidentVitalsPanel = ({ residentId }) => {
  const classes = useStyles();
  const [metric, setMetric] = React.useState(vitalTypes[1].field);
  const [metrics] = React.useState(vitalTypes);
  const [vitals, setVitals] = React.useState([]);

  React.useEffect(() => {
    if (residentId && metric) {
      loadData(residentId, metric);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [residentId, metric]);

  const onMetricChange = (e) => {
    setMetric(e.target.value);
  };

  const loadData = async (id, field) => {
    const response = await getResidentVitalsForVital(id, field);

    if (field === 'bloodPressure') {
      const bps = response
        .filter((r) => !isNaN(r.systolicBloodPressure) && !isNaN(r.diastolicBloodPressure))
        .map((v) => {
          const obj = { ...v };
          obj.dateLabel = moment(v.date).format('MM/DD/YYYY');
          return obj;
        })
        .reverse();
      setVitals(bps);
    } else {
      const data = response
        .filter((v) => v[metric] && !isNaN(v[metric]))
        .map((v) => {
          const obj = { ...v };
          obj.dateLabel = moment(v.date).format('MM/DD/YYYY');
          return obj;
        })
        .reverse();
      setVitals(data);
    }
  };

  const isNumericMetric = (x) => {
    if (
      ['painLevel', 'weight', 'bloodGlucose', 'pulse', 'temperature'].indexOf(
        metric
      ) > -1
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Paper elevation={24} className={classes.root}>
      <Grid container justify="space-between" className={classes.header}>
        <Typography variant="h5">Resident Vitals</Typography>
      </Grid>
      <Divider />
      <div className={classes.content}>
        <FormGroup>
          <FormControl>
            <InputLabel>Metric</InputLabel>
            <Select value={metric} displayEmpty onChange={onMetricChange}>
              {metrics &&
                metrics.map((m) => (
                  <MenuItem value={m.field}>{m.label}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </FormGroup>
        <div
          className={classes.chartContainer}
          hidden={metric === 'bloodPressure'}
        >
          <ResponsiveContainer width="95%" height={200}>
            <LineChart data={vitals} margin={10}>
              <Line type="monotone" dataKey={metric} stroke="#2196f3" />
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="dateLabel" dateLabel="dateLabel" />
              {isNumericMetric(metric) && (
                <YAxis
                  type="number"
                  domain={['dataMin - 25', 'dataMax + 25']}
                />
              )}
              {!isNumericMetric(metric) && <YAxis />}
              <Tooltip />
              <Legend />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div
          className={classes.chartContainer}
          hidden={metric !== 'bloodPressure'}
        >
          <ResponsiveContainer width="95%" height={200}>
            <LineChart data={vitals} margin={10}>
              <Line
                type="monotone"
                dataKey={'systolicBloodPressure'}
                stroke="#2196f3"
              />
              <Line
                type="monotone"
                dataKey={'diastolicBloodPressure'}
                stroke="red"
              />
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="dateLabel" dateLabel="dateLabel" />
              <YAxis type="number" domain={['dataMin - 25', 'dataMax + 25']} />
              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </Paper>
  );
};

export default ResidentVitalsPanel;
