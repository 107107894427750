import React from 'react';
import {FormControl, FormGroup, Grid, InputLabel, makeStyles, MenuItem, Select} from "@material-ui/core";
import moment from "moment";
import {DailyOccurrence} from "./DailyOccurrence";

const useStyles = makeStyles((theme) => ({
  select: {
    minWidth: '7rem'
  }
}));

export const YearlyOccurrence = ({ model, onChange }) => {
  const classes = useStyles();
  const [days, setDays] = React.useState(0);

  const onMonthChange = e => {
    const month = moment().set({month: e.target.value}).startOf('month');
    const days = month.daysInMonth();
    setDays(days);
    const obj = {...model.occurrence};
    obj.value.day = 1;
    obj.value.month = e.target.value;
    onChange(obj);
  }

  const onDayChange = e => {
    const obj = {...model.occurrence};
    obj.value.day = e.target.value;
    onChange(obj);
  }

  return (

    <Grid container>
      <div style={{ flex: 1}}>
        <DailyOccurrence model={model} onChange={onChange} />
      </div>
      <FormGroup row={true}
                 style={{marginLeft: '.5rem', flex: 1}}
      >
        <FormControl className={classes.select}>
          <InputLabel shrink>Month</InputLabel>
          <Select onChange={onMonthChange}
                  value={model?.occurrence?.value?.month || ''}
          >
            {[...moment.months()].map((month, key) =>
              <MenuItem value={key}>{month}</MenuItem>
            )}
          </Select>
        </FormControl>
        <FormControl className={classes.select}>
          <InputLabel shrink>Day</InputLabel>
          <Select value={model?.occurrence?.value?.day || ''}
                  onChange={onDayChange}
          >
            {[...Array(days)].map((day, key) => <MenuItem value={key + 1}>{key + 1}</MenuItem>)}
          </Select>
        </FormControl>
      </FormGroup>
    </Grid>
  )
}
