import React from 'react';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
  TextField,
  ButtonGroup,
  TableSortLabel,
  TablePagination, Grid, Chip,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import HighlightOffSharpIcon from '@material-ui/icons/HighlightOffSharp';
import {red} from '@material-ui/core/colors';
import {
  getPermissions,
  updatePermission,
  createPermission,
  deletePermission,
} from '../../services/permission.service';
import CreatePermission from './create.permission';
import useGlobalNotification from '../../hooks/notification.hook';
import {PageTitle} from "../utils";
import {getStripedStyle} from "../utils/table.utils";
import {convertToFilters} from "../../utils/filter.utils";
import ConfirmDialog from "../utils/dialogs/confirm.dialog";
import ChipInput from "material-ui-chip-input";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0),
  },
  warning: {
    background: red[400],
    '&:hover': {
      background: red[800],
    },
  },
}));

const FilterTemplate = {
  name: {
    field: "name",
    minValue: 3,
    value: null,
    operator: "*=*",
  },
  category: {
    field: "category",
    minValue: 3,
    value: null,
    operator: "*=*",
  },
  tags: {
    field: "tags",
    minValue: 3,
    value: null,
    operator: "*=*",
  },
}

const Permissions = () => {
  const [permissions, setPermissions] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(25);
  const [sort, setSort] = React.useState('name');
  const [totalItems, setTotalItems] = React.useState();
  const [filter, setFilter] = React.useState(JSON.parse(JSON.stringify(FilterTemplate)));
  const [sortDirection, setSortDirection] = React.useState('asc');
  const [selectedPermission, setSelectedPermission] = React.useState();
  const [viewCreatePermissionModal, setViewCreatePermissionModal,] = React.useState(false);
  const [viewConfirmDialog, setViewConfirmDialog] = React.useState();
  const {addNotification} = useGlobalNotification();
  const classes = useStyles();
  React.useEffect(() => {
    const obj = JSON.parse(JSON.stringify(FilterTemplate));
    setFilter(obj);
    getData();
  }, []);

  const getData = async (pageArg = 1,
                         sizeArg = 25,
                         sortArg = 'name',
                         sortDirectionArg = 'asc',
                         filtersArg = []) => {

    const filters = convertToFilters(filtersArg);
    const response = await getPermissions(pageArg,
                                          sizeArg,
                                          sortArg,
                                          sortDirectionArg,
                                          filters);
    setPermissions(response?.content);
    setTotalItems(response?.paginator?.totalItems);
    setSize(response?.paginator?.perPage);
    setPage(response?.paginator?.currentPage);
  };


  const editPermission = (permission) => {
    setSelectedPermission(permission);
  };

  const cancelEdit = () => {
    setSelectedPermission(null);
  };

  const onChange = (e) => {
    const obj = {...selectedPermission};
    obj[e.target.name] = e.target.value;
    setSelectedPermission(obj);
  };

  const onUpdate = async () => {
    const entity = await updatePermission(
      selectedPermission._id,
      selectedPermission
    );
    const obj = permissions.map((perm) =>
      perm._id === entity._id ? entity : perm
    );
    setPermissions(obj);
    setSelectedPermission(null);
    addNotification('Permission updated', 'warning');
  };

  const onPermissionCreated = async (permission) => {
    const response = await createPermission(permission);
    if (!response?.error) {
      setPermissions([...permissions, response]);
      addNotification('Permission created', 'success');
    } else {
      addNotification(response?.message);
    }
    setViewCreatePermissionModal(false);
  };

  const onConfirmDelete = async () => {
    await deletePermission(selectedPermission?._id);
    setPermissions(permissions.filter((perm) => perm._id !== selectedPermission?._id));
    addNotification('Permission delected', 'warning');
    setViewConfirmDialog(false);
  }

  const sortHandler = async (field) => {
    setSort(field);
    const direction = sortDirection === 'asc' ? 'desc' : 'asc'
    setSortDirection(direction);
    await getData(page, size, field, direction, filter)
  }

  const onFilterChange = async e => {

    const obj = {...filter};
    obj[e.target.name].value = e.target.value;
    setFilter(obj);
    if (e.target.value.length > 2) {
      await getData(page, size, sort, sortDirection, obj);
    } else if (e.target.value.length === 0) {
      await getData(page, size, sort, sortDirection, []);
    }
  }


  const clearFilters = async () => {
    const obj = JSON.stringify(JSON.parse(FilterTemplate))
    setFilter(obj);
    setPage(1);
    setSize(25);
    setSort('name');
    setSortDirection('asc');
    await getData(1, 25, 'name', 'asc', obj);
  }

  const onChangePage = async (e, newPage) => {
    const pg = newPage + 1;
    setPage(pg);
    await getData(pg, size, sort, sortDirection, filter);
  }

  const onChangeRowsPerPage = async (e) => {
    setSize(e.target.value)
    await getData(page, e.target.value, sort, sortDirection, filter);
  }

  const handleTagAdd = tag => {
    const obj = {...selectedPermission};
    obj.tags = [...obj?.tags, tag]
    setSelectedPermission(obj);
  }

  const handleTagDelete = tag => {
    const obj = {...selectedPermission};
    obj.tags = obj?.tags?.filter((c) => c !== tag);
    setSelectedPermission(obj);
  }

  return (
    <Paper elevation={24} className={classes.root}>
      <PageTitle title={"Permissions"} />
      <Button
        size="small"
        color="primary"
        startIcon={<AddIcon/>}
        onClick={() => setViewCreatePermissionModal(true)}
        style={{ marginRight: '.25rem'}}
      >
        Create Permission
      </Button>
      <CreatePermission
        open={viewCreatePermissionModal}
        onClose={() => setViewCreatePermissionModal(false)}
        onSave={onPermissionCreated}
      />

      <ConfirmDialog title={"Delete Permission"}
                     open={viewConfirmDialog}
                     onClose={() => setViewConfirmDialog(false)}
                     onConfirm={onConfirmDelete}
      />
      <Grid container
            justifyContent={"flex-end"}>

        <Button onClick={clearFilters}
                size="small"
                startIcon={<HighlightOffSharpIcon />}>
          Clear Filters
        </Button>
        <TablePagination rowsPerPageOptions={[5, 10, 25]}
                         component="div"
                         count={totalItems}
                         onChangePage={onChangePage}
                         onChangeRowsPerPage={onChangeRowsPerPage}
                         page={page - 1}
                         rowsPerPage={size} />
      </Grid>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell width="300"/>
              <TableCell>
                <TableSortLabel active={sort === 'name'}
                                direction={sortDirection}
                                onClick={() => sortHandler('name')}>Name</TableSortLabel>
                <TextField fullWidth
                           name="name"
                           margin="dense"
                           size="small"
                           placeholder="Search"
                           variant="outlined"
                           value={filter?.name?.value}
                           onChange={onFilterChange}/>

              </TableCell>
              <TableCell>
                <TableSortLabel active={sort === "category"}
                                direction={sortDirection}
                                onClick={() => sortHandler('category')}
                                >
                  Category
                </TableSortLabel>
                <TextField fullWidth
                           name="category"
                           margin="dense"
                           size="small"
                           placeholder="Search"
                           variant="outlined"
                           value={filter?.category?.value}
                           onChange={onFilterChange}/>
              </TableCell>
              <TableCell>
                <TableSortLabel active={sort === "tags"}
                                direction={sortDirection}
                                onClick={() => sortHandler('tags')}
                >
                  Tags
                </TableSortLabel>
                <TextField fullWidth
                           name="tags"
                           margin="dense"
                           size="small"
                           placeholder="Search"
                           variant="outlined"
                           value={filter?.tags?.value}
                           onChange={onFilterChange}/>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {permissions?.map((permission, index) => (
              <TableRow key={index} style={getStripedStyle(index)}>
                {permission._id === selectedPermission?._id && (
                  <>
                    <TableCell>
                      <ButtonGroup>
                        <Button
                          size="small"
                          color="primary"
                          onClick={cancelEdit}
                          startIcon={<CancelIcon/>}
                        >
                          Cancel
                        </Button>
                        <Button
                          size="small"
                          color="primary"
                          variant="contained"
                          onClick={() => setViewConfirmDialog(true)}
                          className={classes.warning}
                          disabled={
                            !selectedPermission?.name ||
                            selectedPermission?.name?.length === 0
                          }
                          startIcon={<SaveIcon/>}
                        >
                          Delete
                        </Button>
                        <Button
                          size="small"
                          color="primary"
                          variant="contained"
                          onClick={onUpdate}
                          disabled={
                            !selectedPermission?.name ||
                            selectedPermission?.name?.length === 0
                          }
                          startIcon={<DeleteIcon/>}
                        >
                          Update
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="name"
                        InputProps={{disableUnderline: true}}
                        value={selectedPermission?.name || ''}
                        onChange={onChange}
                        fullWidth
                        variant="outlined"
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <TextField name={"category"}
                                 InputProps={{disableUnderline: true}}
                                 value={selectedPermission?.category}
                                 onChange={onChange}
                                 fullWidth
                                 variant="outlined"
                                 size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <ChipInput
                        color={"primary"}
                        label={"tags"}
                        fullWidth
                        value={selectedPermission?.tags}
                        onAdd={(chip) => handleTagAdd(chip)}
                        onDelete={(chip) => handleTagDelete(chip)}

                      />
                    </TableCell>
                  </>
                )}
                {permission._id !== selectedPermission?._id && (
                  <>
                    <TableCell>
                      <Button
                        size="small"
                        color="primary"
                        onClick={(evt) => editPermission(permission)}
                      >
                        Edit
                      </Button>
                    </TableCell>
                    <TableCell>
                      {permission.name}
                    </TableCell>
                    <TableCell>
                      {permission?.category}
                    </TableCell>
                    <TableCell>
                      {permission?.tags?.map(t =>
                        <Chip label={t}
                              size={"small"}
                        />
                      )
                      }
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default Permissions;
