import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControl,
  FormControlLabel, FormGroup, Grid, InputLabel, MenuItem,
  Select, TextField,
  Typography
} from "@material-ui/core";
import moment from 'moment';
import {Editor} from "react-draft-wysiwyg";
import { EventTemplate } from '../../../calendars/events/model';
import {convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import {ColorPicker, createColor} from "material-ui-color";
import { createCalendarEventsAction } from '../context/actions';
import {FormFieldsGenerator, isModelValid} from "../../../../utils/forms";
import {ACTIONS, ResidentCalendarContext} from "../context";
import {Occurrence} from "../../../calendars/events/occurrence/occurrence";
import useGlobalNotification from "../../../../hooks/notification.hook";


export const CreateCalendarEvent = () => {
  const { state, dispatch } = React.useContext(ResidentCalendarContext);
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(EventTemplate)));
  const [editNote, setEditNote] = React.useState();
  const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());
  const [color, setColor] = React.useState(createColor("#fff"));
  const { addNotification } = useGlobalNotification();

  React.useEffect(() => {
    if (state?.viewCreateEventModal && state?.selectedCalendarSlot) {
      const obj = JSON.parse(JSON.stringify(EventTemplate));
      obj.start.value = state?.selectedCalendarSlot?.start;
      obj.end.value = state?.selectedCalendarSlot?.end;
      obj.calendarType.dropDownItems = state.calendarTypes?.map(c => ({name: c.name, value: c.name }));
      obj.occurrence.value.startDate = moment(state?.selectedCalendarSlot?.start);
      obj.occurrence.value.endDate = obj.occurrence.value.startDate.clone().add(7, 'days')
      setModel(obj);
      setEditNote(null);
      setEditorState(EditorState.createEmpty());
      setColor(createColor("gray"));
    }
    // eslint-disable-next-line
  }, [ state?.viewCreateEventModal, state?.selectedCalendarSlot ]);

  const onEventChange = (e, field) => {
    const obj = {...model, ...e};
    if (field === "calendarType") {
      const type = state?.calendarTypes?.find(r => r.name === e[field].value);
      if (type) {
        setColor(type?.color);
      }
    }
    if (field === "start") {
      obj.occurrence.value.startDate = moment(obj.start.value);
      obj.occurrence.value.endDate = moment(obj.start.value).clone().add(7, 'days')
      if (obj.start.value > obj.end.value) {
        obj.end.value = moment(obj.start.value).clone().add(1, 'day');
      }
    }
    setModel(obj);
  }

  const onCadenceChange = e => {
    const obj = {...model};
    obj.occurrence.value.cadence = e.target.value;
    setModel(obj);
  }

  const onNoteChange = e => {
    setEditorState(e);
    const message = convertToRaw(e.getCurrentContent());
    setEditNote(message);
  }

  const save = async () => {
    const data = {}
    Object.keys(model).forEach(key => {
      data[key] = model[key].value
    });

    // Add note
    data.note = editNote;
    data.noteText = editorState?.getCurrentContent()?.getPlainText();
    data.noteHtml = draftToHtml(editNote);
    data.color = color?.hex ? `#${color.hex}`: color;
    await createCalendarEventsAction(
      dispatch,
      state?.residentId,
      data,
      () => addNotification("Unable to create calendar event", "error"),
      () => addNotification("Calendar event created", "success"));
  }

  const onColorChange = color => {
    //setColor(createColor(color));
    setColor(color);
  }

  const onRepeatChange = e => {
    const obj = {...model};
    obj.repeat.value = e.target.value;
    setModel(obj);
  }

  const onOccurrenceChange = occurrence => {
    setModel({...model, occurrence });
  }

  return (
    <Dialog open={state?.viewCreateEventModal}
            maxWidth={"xl"}
            fullWidth
            onClose={() => dispatch({type: ACTIONS.SET_VIEW_CREATE_EVENT_MODAL, payload: false })}
    >
      <DialogTitle>
        <Grid container justify={"space-between"}>
          <Typography variant={"subtitle1"}>Create Event</Typography>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        {model && <FormFieldsGenerator model={model} onChange={onEventChange} />}
        <FormGroup>
          <FormControl>
            <Grid container alignItems={"center"} style={{marginTop: '.5rem'}}>
              {model?.repeat?.value !== "Does not repeat" && <TextField label={"Occurs"}
                                                                        onChange={onCadenceChange}
                                                                        value={model?.occurrence?.value?.cadence} />
              }
              <FormControl>
                <InputLabel>Repeats</InputLabel>
                <Select value={model?.repeat?.value}
                        onChange={onRepeatChange}
                >
                  <MenuItem value="Does not repeat">Does not repeat</MenuItem>
                  <MenuItem value="Daily">Daily</MenuItem>
                  <MenuItem value="Weekly">Weekly</MenuItem>
                  <MenuItem value="Monthly">Monthly</MenuItem>
                  <MenuItem value="Yearly">Yearly</MenuItem>
                </Select>
              </FormControl>
              <div style={{flex: 2}}>
                <Occurrence model={model} onChange={onOccurrenceChange} />
              </div>
            </Grid>
          </FormControl>
          <div>
            <FormControlLabel control={<ColorPicker value={color}
                                                    hideTextfield
                                                    defaultValue="transparent"
                                                    onChange={onColorChange} />}
                              label={"Color"}
                              labelPlacement={"start"}
                              style={{ paddingTop: '1rem'}}
            />
          </div>
          <div elevation={1} style={{ marginTop: '2rem', padding: '0 1rem', minHeight: '20rem'}}>
            <Typography variant={"subtitle1"}>Event Notes</Typography>
            <Editor editorState={editorState}
                    onEditorStateChange={text => onNoteChange(text)}
                    hashtag={{
                      separator: ' ',
                      trigger: '#',
                    }}
            />
          </div>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch({type: ACTIONS.SET_VIEW_CREATE_EVENT_MODAL, payload: false })}>Cancel</Button>
        <Button color={"primary"}
                onClick={save}
                disabled={!isModelValid(model)}
                variant={"contained"}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}
