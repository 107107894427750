import {
  LOAD_RESIDENT_PHYSICIANS_START,
  LOAD_RESIDENT_PHYSICIANS_COMPLETE,
  CREATE_RESIDENT_PHYSICIAN_START,
  CREATE_RESIDENT_PHYSICIAN_COMPLETE,
  DELETE_RESIDENT_PHYSICIAN_START,
  DELETE_RESIDENT_PHYSICIAN_COMPLETE,
  UPDATE_RESIDENT_PHYSICIANS_START,
  UPDATE_RESIDENT_PHYSICIANS_COMPLETE,
  OPEN_PHYSICIAN_DIALOG,
  CLOSE_PHYSICIAN_DIALOG
} from "./resident.physicians.context";
import * as residentService from '../../../services/resident.service';

export const openPhysicianLookupDialog = () => ({type: OPEN_PHYSICIAN_DIALOG});
export const closePhysicianLookupDialog = () => ({type: CLOSE_PHYSICIAN_DIALOG});

export const getResidentPhysicians = async (dispatch, residentId) => {
  try {
    dispatch({ type: LOAD_RESIDENT_PHYSICIANS_START });
    const response = await residentService.getResidentPhysicians(residentId);
    dispatch({
      type: LOAD_RESIDENT_PHYSICIANS_COMPLETE,
      payload: response
    });
  } catch (error) {
    alert(error);
    dispatch({ type: LOAD_RESIDENT_PHYSICIANS_COMPLETE, payload: [] });
  }
};

export const createResidentPhysician = async (dispatch, residentId, physicianId, residentContextDispatch, isPrimary = false) => {
    try {
        dispatch({ type: CREATE_RESIDENT_PHYSICIAN_START });
        const response = await residentService.createResidentPhysician(residentId, physicianId, isPrimary);
        dispatch({
          type: CREATE_RESIDENT_PHYSICIAN_COMPLETE,
          payload: response
        });
        residentContextDispatch({type: 'ADD_RESIDENT_PHYSICIAN', payload: response});
      } catch (error) {
        alert(error);
        dispatch({ type: LOAD_RESIDENT_PHYSICIANS_COMPLETE, payload: null });
      }
  }

  export const deleteResidentPhysician = async (dispatch, residentId, physicianId, cb) => {
    try {
        dispatch({ type: DELETE_RESIDENT_PHYSICIAN_START });
        await residentService.deleteResidentPhysician(residentId, physicianId);
        dispatch({
          type: DELETE_RESIDENT_PHYSICIAN_COMPLETE,
          payload: physicianId
        });
        if (cb) {
          cb()
        }
      } catch (error) {
        alert(error);
        dispatch({ type: LOAD_RESIDENT_PHYSICIANS_COMPLETE, payload: null });
      }
  }

  export const updateResidentPhysician = async (dispatch, residentId, physicianId, isPrimary, residentDispatch) => {
    try {
        dispatch({ type: UPDATE_RESIDENT_PHYSICIANS_START });
        const response = await residentService.updateResidentPhysician(residentId, physicianId, isPrimary);
        dispatch({
          type: UPDATE_RESIDENT_PHYSICIANS_COMPLETE,
          payload: response
        });
        residentDispatch({type: 'UPDATE_RESIDENT_PHYSICIAN', payload: response});
      } catch (error) {
        alert(error);
        dispatch({ type: LOAD_RESIDENT_PHYSICIANS_COMPLETE, payload: null });
      }
  }
