import React from 'react';
import {
  Button, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControl, FormGroup, InputLabel, MenuItem, Select, TextField,
} from "@material-ui/core";

export const MobileFilterModal = ({open, onClose, filter, onFilterSelectChange, onFilterTextChange}) => {

  const parseItem = item => {
    if (item?.dropDownItems) {
      return (
        <FormControl>
          <InputLabel shrink>{item?.label}</InputLabel>
          <Select displayEmpty
                  value={item?.value}
                  name={item?.field}
                  onChange={onFilterSelectChange}
          >
            <MenuItem value={''}>All</MenuItem>
            {item?.dropDownItems?.map(i =>
            <MenuItem value={i[item?.dropDownItemValue]}>{i[item?.dropDownItemLabel]}</MenuItem>)}
          </Select>
        </FormControl>
      )
    } else {
      return (
        <TextField label={item?.label}
                   value={item?.value}
                   name={item?.field}
                   onChange={onFilterTextChange}
                   />
      )
    }
  }

  const parseItems = items => {
    return items.map(item => parseItem(item));
  }

  const parseFilter = () => {
    let items = Object.keys(filter).map(key => filter[key]).sort((a, b) => a?.sort > b?.sort ? 1: -1);
    return parseItems(items);
  }

  return (
    <Dialog open={open}
            fullScreen={true}
            onClose={onClose}>
      <DialogTitle>{"Filters"}</DialogTitle>
      <DialogContent dividers>
        <FormGroup>
          {parseFilter()}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button color={"primary"} onClick={onClose}>Close</Button>
      </DialogActions>

    </Dialog>
  )
}
