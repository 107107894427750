import React from 'react';

import { TextField, TableCell } from '@material-ui/core';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

const DailyEvent = (props) => {
  const [eventTemplate, setEventTemplate] = React.useState(
    props.eventTemplate || {}
  );
  const [passingQty, setPassingQty] = React.useState(1);
  const initDate = new Date();
  initDate.setHours(8, 0);

  const [selectedDate, handleDateChange] = React.useState(() => {
    if (props.eventTemplate && props.eventTemplate.hour) {
      let date = new Date();
      date.setHours(props.eventTemplate.hour, props.eventTemplate.minute);
      return date;
    } else {
      return initDate;
    }
  });
  React.useEffect(() => {
    setEventTemplate(props.eventTemplate || {});
    setPassingQty(props.eventTemplate ? props.eventTemplate.passingQty : null);
  }, [props.eventTemplate]);

  const onTimeChange = (date) => {
    handleDateChange(date);
    const hour = moment(date).format('H');
    const minute = moment(date).format('mm');
    const updatedEventTemplate = { ...eventTemplate, hour, minute };
    setEventTemplate(updatedEventTemplate);
    emitUpdate(updatedEventTemplate);
  };

  const onQtyChange = (e) => {
    if (!e.target.value ||  e.target.value === "." || !isNaN(e.target.value)) {
      let val = e.target.value.trim();
      setPassingQty(val);
      const updatedEventTemplate = {
        ...eventTemplate,
        passingQty: val,
      };
      setEventTemplate(updatedEventTemplate);
      emitUpdate(updatedEventTemplate);
    }
  };

  const emitUpdate = (eventTemplate) => {
    if (props.onChange) {
      props.onChange(eventTemplate);
    }
  };

  return (
    <React.Fragment>
      <TableCell>N/A</TableCell>
      <TableCell>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <TimePicker
            format="h:mm a (H:mm)"
            value={selectedDate}
            onChange={onTimeChange}
            ampm={false}
            disabled={!props.edit}
          />
        </MuiPickersUtilsProvider>
      </TableCell>
      {props?.showQty &&
          <TableCell>
            <TextField disabled={!props.edit}
                       value={passingQty}
                       onChange={onQtyChange} />
          </TableCell>
      }
    </React.Fragment>
  );
};

export default DailyEvent;
