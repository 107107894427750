export const ROOT_FOLDER = {label: "Root", _id: null }

export let InitialState = {
  residentId: null,
  files: [],
  selectedFiles: [],
  selectedFolder: ROOT_FOLDER,
  breadcrumbs: [ROOT_FOLDER],
  viewCreateFolderModal: false,
  viewDeleteFileItemModal: false,
  deletingFiles: false,
  viewCopyFileModal: false,
  viewMoveFileModal: false,
  viewRenameFileModal: false,
  selectedFileNode: null,
  loading: false
}
