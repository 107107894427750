import {ROOT_FOLDER} from "./InitialState";

export const computeBreadcrumbs = (file, files) => {
  if (file?._id === null || file?._id === "Root") {
    // this is root, get folders with NO parent
    return [file]
  } else {
    let parentFiles = []
    if (file?.parent) {
      parentFiles = getParentFiles(file, files, [])
    }
    return [ROOT_FOLDER, ...parentFiles, file]
  }
}

const getParentFiles = (file, files, parentList = []) => {
  if (file?.parent) {
    const parentFile = files?.find(f => f._id === file?.parent);
    parentList.unshift(parentFile);
    getParentFiles(parentFile, files, parentList);
  }
  return parentList;
}

