const modelTemplate = {
  type: {
    title: 'Meal',
    required: true,
    value: null,
    isDirty: false,
    isValid: false,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [
      {
        name: 'Breakfast',
      },
      {
        name: 'Lunch',
      },
      {
        name: 'Dinner',
      },
      {
        name: 'Other',
      },
    ],
    dropDownItemValue: 'name',
    dropDownItemLabel: 'name',
    date: false,
  },
  frequency: {
    title: 'Frequency',
    required: true,
    value: null,
    isDirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [
      {
        name: 'Daily',
        value: 'QD',
      },
      // {
      //   name: 'Twice a week',
      //   value: 'BIW',
      // },
      // {
      //   name: 'Three times a week',
      //   value: 'TIW',
      // },
      // {
      //   name: 'Four times a week',
      //   value: 'QIW',
      // },
      // {
      //   name: 'Five times a week',
      //   value: '5XW',
      // },
      // {
      //   name: 'Six times a week',
      //   value: '6XW',
      // },
    ],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: true,
  },
  templates: {
    title: 'Templates',
    hidden: true,
    required: true,
    isValid: false,
    isDirty: false,
    value: [],
  },
  /*
  time: {
    title: 'Time',
    required: true,
    value: moment(new Date()).add(1, 'year'),
    isDirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: true,
  },
  calories: {
    title: 'Calories',
    required: true,
    value: null,
    isDirty: false,
    isValid: false,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  comments: {
    title: 'Comments',
    required: true,
    value: null,
    isDirty: false,
    isValid: false,
    multiline: true,
    multilineRows: 4,
  },*/
};

export default modelTemplate;
