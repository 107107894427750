import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { marStatuses } from '../../Statuses'

// Create styles
const styles = StyleSheet.create({
  container: {
    fontFamily: 'Helvetica',
    padding: 2,
    borderBottomWidth: 1,
    borderColor: '#eaeaea',
    marginBottom: 10,
  },
  title: {
    fontSize: 12,
    marginBottom: 2,
  },
  itemsContainer: {
    display: "flex",
    flexDirection: "row",
    borderColor: '#eaeaea',
    borderWidth: '1px',
    padding: 2,
  },
  statusItem: {
    fontSize: 6,
    marginBottom: 5,
    display: "flex",
    flexDirection: "row",
    marginRight: 2
  },
});

export const StatusLegend = () => (
  <View style={styles.container}>
    <Text style={styles.title}>Status Legend</Text>
    <View style={styles.itemsContainer}>
      {marStatuses?.map((marStatus, index) =>
        <View style={styles.statusItem} key={index}>
          <Text>{marStatus.status}</Text>
          <Text>&nbsp;:&nbsp;</Text>
          <Text>{marStatus.label}</Text>
        </View>
      )}
    </View>
  </View>
);
