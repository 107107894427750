import React from 'react';
import {Button, Chip, Grid, makeStyles, Paper} from "@material-ui/core";
import {PageTitle} from "../../utils";
import {useParams} from "react-router-dom";
import {
  ACTIONS,
  calendarEventSelectedAction,
  calendarSlotSelectedAction, getTenantCalendarEventsAction,
  TenantCalendarContext,
  TenantCalendarProvider, updateCalendarFilterTypesAction
} from "./context";
import {FilterListTwoTone} from "@material-ui/icons";
import {calendarEventPropertySetter, invertColor} from "../../calendar/calendar.utils";
import {CalendarTypes} from "./types";
import {SsaCalendar} from "../../calendar";
import {CalendarAgendaEventComponent, CalendarEventComponent, WeekEventComponent} from "../../calendars/events";
import {CreateCalendarEvent} from "./events/create.event";
import {EditCalendarEvent} from "./events/edit.event";
import {RetryMessage} from "../../notification/retry.message";
import useHasRole from "../../../hooks/user.role.hook";


const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(.5, 0 , 0, 0),
  }
}));


export const TenantCalendarPage = () => {
  const classes = useStyles();
  const { dispatch, state } = React.useContext(TenantCalendarContext);
  const [showFilter, setShowFilter] = React.useState(false);
  const { checkPermission } = useHasRole();
  const canWriteTenantCalendar = checkPermission("TENANT_CALENDAR_WRITE");


  const calendarTypesFilterChanged = type => {
    updateCalendarFilterTypesAction(dispatch, type);
  }

  const onEventSelected = event => {
    calendarEventSelectedAction(dispatch, event);
  }

  const onSlotSelected = slot => {
    calendarSlotSelectedAction(dispatch, slot);
  }

  const getEvents = () => {
    const checkedTypes = state?.calendarTypes?.filter(c => c?.checked);
    if (checkedTypes?.length === state?.calendarTypes?.length) {
      if (state?.viewAllFilter) {
        return state?.events;
      }
      if (!state?.viewAllFilter) {
        return [];
      }
    } else {
      return state?.events?.filter(event =>
        checkedTypes?.findIndex(c => c.name === event?.calendarType) > -1);
    }
  }

  const reloadResidentEvents = async () => {
    await getTenantCalendarEventsAction(dispatch, state?.tenantId)
  }

  const onTimeframeNavigated = async ({ date }) => {
    await getTenantCalendarEventsAction(dispatch, state?.tenantId, date);
  }

  const onViewChanged = view => {
    dispatch({type: ACTIONS.SET_VIEW, payload: view });
  }



  return (
    <Paper elevation={24}
           className={classes.root}
    >
      <PageTitle title={"Facility Calendar"}>
        <Grid container alignItems={"center"}>
          <Button variant={"outlined"}
                  startIcon={<FilterListTwoTone />}
                  onClick={() => setShowFilter(filter => !filter)}>
            {showFilter ? "Hide":"Show"} Filters
          </Button>
          {state?.calendarTypes?.filter(c => c.checked).map(type =>
            <Chip size="small"
                  onDelete={() => calendarTypesFilterChanged(type)}
                  style={{
                    backgroundColor: type?.color,
                    color: invertColor(type?.color, true)
                  }}
                  className={classes.filterChip}
                  label={type.name} />
          )}
        </Grid>
      </PageTitle>
      <Grid container style={{ paddingTop: '.5rem'}}>
        <Grid item xs={showFilter ? 12: 0} sm={showFilter ? 2: 0}>
          {showFilter && <CalendarTypes />}
        </Grid>
        <Grid item xs={12} sm={showFilter ? 10: 12}>
          {!state?.loadingEventsError &&
          <SsaCalendar events={getEvents()}
                       loadingEvents={state.loadingEvents}
                       onSlot
                       height={"70vh"}
                       color={'black'}
                       calendarColor={'black'}
                       scrollToTime={Date.now()}
                       eventPropertySetter={calendarEventPropertySetter}
                       eventSelected={onEventSelected}
                       slotSelected={canWriteTenantCalendar && onSlotSelected}
                       weekEventComponent={WeekEventComponent}
                       customAgendaEventComponent={CalendarAgendaEventComponent}
                       onTimeframeNavigated={(event) => onTimeframeNavigated(event)}
                       view={state.view}
                       onView={onViewChanged}
                       customEventComponent={CalendarEventComponent}/>
          }
          <CreateCalendarEvent />
          <EditCalendarEvent />
          {/*{!state?.loadingEventsError && state?.loadingEvents &&*/}
          {/*<div style={{height: "70vh"}}>*/}
          {/*  <TableLoadingIndicator row={7} cols={1} />*/}
          {/*</div>*/}
          {/*}*/}
          {!state?.loadingEvents && state?.loadingEventsError &&
          <RetryMessage message={"Error loading facility calendar table"} severity={"error"} onRetry={reloadResidentEvents} />
          }
        </Grid>
      </Grid>
    </Paper>
  )
}

export const TenantCalendar = () => {
  const { tenantId } = useParams();

  return (
    <TenantCalendarProvider tenantId={tenantId}>
      <TenantCalendarPage />
    </TenantCalendarProvider>
  )
}
