import React, { useState, useCallback } from "react";

export const GlobalLoadingIndicatorContext = React.createContext({
  loading: false,
  toggleLoading: (loading) => {}
});

const GlobalLoadingIndicatorProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const toggleLoadingValue = (loading) => {
    setLoading(loading);
  };

  const contextValue = {
    loading,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    toggleLoading: useCallback(
      (loading) =>
        toggleLoadingValue(loading),
      []
    )
  };

  return (
    <GlobalLoadingIndicatorContext.Provider value={contextValue}>
      {children}
    </GlobalLoadingIndicatorContext.Provider>
  );
};

export default GlobalLoadingIndicatorProvider;
