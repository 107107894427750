import React from 'react';

import {
  makeStyles,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  MenuItem,
  Menu,
  ListItemIcon,
  ListItemText,
  Typography,
  Avatar,
  Button,
  Grid,
  FormGroup,
  TextField,
  FormControl, InputLabel, Select,
} from "@material-ui/core";
import moment from 'moment';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Editor } from "react-draft-wysiwyg";
import {EditorState, convertToRaw, convertFromRaw} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import IconButton from "@material-ui/core/IconButton";
import NoteComments from "./note.comments";
import {createNoteComment, deleteNote, toggleLocked, updateNote} from "../../../services/resident.service";
import {Delete, Edit, Lock, WarningTwoTone} from "@material-ui/icons";
import {UserContext} from "../../../context/usercontext";
import useHasRole from "../../../hooks/user.role.hook";
import classNames from 'classnames';
import draftToHtml from "draftjs-to-html";
import useGlobalNotification from "../../../hooks/notification.hook";
import NoteFiles from "./note.file.attachments";
import {grey} from "@material-ui/core/colors";
import MomentUtils from "@date-io/moment";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import ConfirmDialog from "../../utils/dialogs/confirm.dialog";


const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(2),
    background: '#fafafa',
    overflow: 'hidden',
    width: '100%'
  },
  summary: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  summarySubject: {
    display: 'flex',
    alignItems: 'center',
  },
  summaryContent: {
    marginLeft: theme.spacing(1)
  },
  summaryContainer: {
  },
  summaryAvatar: {
    marginRight: theme.spacing(1)
  },
  locked: {
  },
  draft: {

  },
  notLocked: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: grey[300]
  }
}));


const ResidentNoteItem = ({note, onDelete, categories}) => {
  const classes = useStyles();
  const [residentNote, setResidentNote] = React.useState();
  const [menuEl, setMenuEl] = React.useState(null);
  const open = Boolean(menuEl);
  const { state } = React.useContext(UserContext);
  const { checkPermission } = useHasRole();
  const canReadFiles = checkPermission("RESIDENT_NOTE_ATTACHMENT_READ");
  const hasResidentNoteAdminPriv = checkPermission("RESIDENT_NOTE_ADMIN");
  const [edit, setEdit] = React.useState(false);
  const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());
  const [editNote, setEditNote] = React.useState();
  const [editResidentNote, setEditResidentNote] = React.useState();
  const [viewConfirmDelete, setViewConfirmDelete] = React.useState(false);
  const { addNotification } = useGlobalNotification();

  const onChange = e => {
    setEditorState(e);
    const message = convertToRaw(e.getCurrentContent());
    setEditNote(message);
  }

  const handleClick = (event) => {
    setMenuEl(event.currentTarget);
  };

  const handleClose = () => {
    setMenuEl(null);
  };

  const handleDelete = async () => {
    handleClose();
    await deleteNote(note?.resident?._id, residentNote._id);
    onDelete(residentNote._id);
    setViewConfirmDelete(false);
  }

  const handleEdit = () => {
    handleClose();
    setEditResidentNote({...residentNote})
    setEditNote(JSON.parse(residentNote?.note));
    const content = convertFromRaw(JSON.parse(residentNote?.note));
    setEditorState(EditorState.createWithContent(content));
    setEdit(true)
  }

  const handleLock = async () => {
    handleClose();
    await toggleLocked(note?.resident?._id, residentNote._id, true);
    const obj = {...residentNote};
    obj.locked = true;
    setResidentNote(obj);
    addNotification("Note locked", "success");
  }

  const onEditChange = e => {
    const obj = {...editResidentNote};
    obj[e.target.name] = e.target.value;
    setEditResidentNote(obj);
  }

  const onEditDateChange = date => {
    const obj = {...editResidentNote};
    obj.noteDate = date;
    setEditResidentNote(obj);
  }

  const unlock = async () => {
    /*await toggleLocked(note.resident, residentNote._id, false);
    const obj = {...residentNote};
    obj.locked = false;
    setResidentNote(obj);
    addNotification("Note unlocked", "warning");*/
  }

  const onCommentSave = async payload => {
    const newComment = await createNoteComment(residentNote?.resident?._id, residentNote._id, payload);
    const obj = {...residentNote};
    obj.comments = [...obj.comments, newComment];
    setResidentNote(obj);
  }

  React.useEffect(() => {
    setResidentNote(note);
  }, [note]);

  const canEdit =  () => {
    if (hasResidentNoteAdminPriv) {
      return true;
    }
    return !residentNote?.locked && residentNote?.user?._id === state?.user?._id;
  }

  const cancelEditNote = () => {
    setEdit(false);
    setEditorState(EditorState.createEmpty());
    setEditResidentNote(null);
    setEditNote(null);
  }

  const onUpdateNote = async () => {
    const payload = {
      subject: editResidentNote.subject,
      category: editResidentNote.category,
      note: editNote,
      text: editorState?.getCurrentContent()?.getPlainText(),
      html: draftToHtml(editNote),
      noteDate: editResidentNote?.noteDate
    }
    const response = await updateNote(note?.resident?._id, residentNote?._id, payload);
    setEdit(false);
    setEditorState(EditorState.createEmpty());
    setEditNote(null);
    setEditResidentNote(null);
    setResidentNote(response);
    addNotification("Updated", "warning");
  }

  return (
    <Accordion
      expanded={true}
      square
      className={classes.root}>
      {!residentNote?.locked &&
      <Grid container className={classes.notLocked}>
        <WarningTwoTone style={{color: '#ffac33' }} />
        <Typography variant={"subtitle1"} style={{marginRight: '.5rem'}}>
          This note is not locked
        </Typography>
      </Grid>}
      <AccordionSummary className={classNames(residentNote?.locked ? classes.locked : classes.draft)}>
        <Grid container justify={"space-between"}>
          <Grid item xs={10}>
            <div className={classes.summarySubject}>
              <Avatar className={classes.summaryAvatar} src={residentNote?.user?.picture}></Avatar>
              <div style={{ width: '100%' }}>
                <Typography variant={"subtitle2"}>
                  <span>
                    {residentNote?.user?.name}
                  </span>
                  {residentNote?.user?.jobTitle &&
                    <span>
                      {", "}{residentNote?.user?.jobTitle}{", "}
                    </span>
                  }
                  <span>
                   created a note on {moment(residentNote?.noteDate).format('MM/DD/YYYY h:mm a')}
                  </span>
                </Typography>
                {!edit &&
                  <>
                    <Typography variant={"subtitle2"}>
                      Subject: <b>{residentNote?.subject}</b>
                    </Typography>
                    <Typography variant={"subtitle2"}>
                      Category: <b>{residentNote?.category}</b>
                    </Typography>
                  </>
                }
                {edit &&
                  <FormGroup style={{ width: '100%'}}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDateTimePicker label={"Created Date"}
                                              required={true}
                                              value={editResidentNote?.noteDate}
                                              onChange={onEditDateChange}
                                              format={"MM/DD/YYYY hh:mm a"}
                      />
                    </MuiPickersUtilsProvider>
                    <TextField label={"Subject"}
                               onChange={onEditChange}
                               required
                               name={"subject"}
                               value={editResidentNote?.subject || ''}
                               error={!editResidentNote?.subject}
                               fullWidth />
                    <FormControl>
                      <InputLabel shrink required>Category</InputLabel>
                      <Select value={editResidentNote?.category}
                              onChange={onEditChange}
                              name={"category"}>
                        {categories?.map(category =>
                          <MenuItem value={category?.name}>{category?.name}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </FormGroup>
                }
              </div>
            </div>
          </Grid>
          <Grid item xs={2}>
            <Grid container alignItems={'center'} justify={'flex-end'}>
              {residentNote?.locked &&
              <Lock style={{opacity: '.25', fontSize: '1.25rem'}} onClick={unlock} />
              }
              {canEdit() &&
              <IconButton size={"small"} onClick={handleClick}>
                <MoreHorizIcon />
              </IconButton>}
              <Menu
                id="long-menu"
                anchorEl={menuEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    width: '20ch',
                  },
                }}
              >
                {!residentNote?.locked &&
                <MenuItem onClick={handleLock}>
                  <ListItemIcon>
                    <Lock />
                  </ListItemIcon>
                  <ListItemText primary={"Lock"} />
                </MenuItem>
                }
                <MenuItem onClick={handleEdit}>
                  <ListItemIcon>
                    <Edit />
                  </ListItemIcon>
                  <ListItemText primary={"Edit"} />
                </MenuItem>
                <MenuItem onClick={() => setViewConfirmDelete(true)}>
                  <ListItemIcon>
                    <Delete />
                  </ListItemIcon>
                  <ListItemText primary={"Delete"} />
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ background: '#fff', width: '100%' }}>
          {!edit && <div dangerouslySetInnerHTML={{__html: residentNote?.html}} />}
          {edit &&
          <div style={{marginBottom: '.75rem'}}>
          <Editor editorState={editorState}
                  onEditorStateChange={text => onChange(text)}
                  hashtag={{
                    separator: ' ',
                    trigger: '#',
                  }} />
            <Button size={"small"} variant={"contained"} color={"primary"} onClick={onUpdateNote}>Update</Button>
            <Button size="small" color={"primary"} onClick={cancelEditNote}>Cancel</Button>
          </div>}
          <NoteComments note={residentNote} onSave={onCommentSave} />
          {canReadFiles && <NoteFiles note={note} />}
        </div>
      </AccordionDetails>
      <ConfirmDialog title={"Delete Note?"}
                     message={"Do you want to delete this note?"}
                     open={viewConfirmDelete}
                     onConfirm={handleDelete}
                     onClose={() => setViewConfirmDelete(false)}
      />
    </Accordion>
  )
}


export default ResidentNoteItem;
