import React from "react";
import {Button, Tooltip} from "@material-ui/core";
import * as _ from "lodash";
import {UserContext} from "../../context/usercontext";

const UserPermissionsTooltip = () => {
  const { state } = React.useContext(UserContext);
  const getUserPermissions = (user) => {

    return _.uniq(_.flatten(user?.roles.map(userRole => userRole.role.permissions)));
  }

  return (
    <Tooltip title={
      <pre>
          {JSON.stringify(getUserPermissions(state?.user), null, 2)}
        </pre>
    }>
      <Button color="primary"> </Button>
    </Tooltip>
  )
}

export default UserPermissionsTooltip;
