import React from 'react';
import {StepActions} from "../step.actions";
import {IntakeContext} from "../../context/intake.context";
import {FormFieldsGenerator, isModelValid} from "../../../../../../utils/forms";
import {useStepStyles} from "../styles";
import {useHistory} from "react-router-dom";
import {DetailsModel} from './details.model';
import {blendObjects, createResidentAction, setStepStatus, updateResidentAction} from "../../context/intake.actions";
import {Divider, Typography} from "@material-ui/core";
import moment from "moment/moment";

export const DetailsStep = () => {
  const {dispatch, state} = React.useContext(IntakeContext);
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(DetailsModel)));
  const classes = useStepStyles();
  const history = useHistory();

  React.useEffect(() => {
    const obj = blendObjects(state, JSON.parse(JSON.stringify(DetailsModel)));
    setModel(obj);
    // eslint-disable-next-line
  }, [state?.resident, state?.model]);

  const onChange = e => {
    setModel({...model, ...e});
  }

  const onSave = async () => {
    const updateModel = {};
    updateModel._id = model?._id?.value;
    updateModel.firstName = model?.firstName?.value;
    updateModel.middleName = model?.middleName?.value;
    updateModel.lastName = model?.lastName?.value;
    updateModel.formerName = model?.formerName?.value;
    updateModel.dob = moment(model?.dob?.value).format('YYYY/MM/DD');
    updateModel.gender = model?.gender?.value;
    updateModel.medicalRecordId = model?.medicalRecordId?.value;
    updateModel.countyCaseNumber = model?.countyCaseNumber?.value;
    updateModel.ssn = model?.ssn?.value;
    updateModel.maritalStatus = model?.maritalStatus?.value;
    updateModel.religiousPreference = model?.religiousPreference?.value;
    updateModel.citizenship = model?.citizenship?.value;
    updateModel.ethnicity = model?.ethnicity?.value;
    updateModel.primaryLanguage = model?.primaryLanguage?.value;
    updateModel.roomNumber = model?.roomNumber?.value;
    updateModel.location = model?.tenantLocationId?.value;
    updateModel.residentStatus = model?.residentStatusId?.value;
    updateModel.transferedFrom = model?.transferedFrom?.value;
    updateModel.referredBy = model?.referredBy?.value;
    if (state?.isNewIntake) {
      updateModel._id = state?.id;
      await createResidentAction(dispatch, model, updateModel);
    } else {
      await updateResidentAction(dispatch, model, updateModel);
    }
    setStepStatus(dispatch, 'details', true);
    history.push(`/intake/${state.id}/photo`)
  }

  return (
    <div>
      <div className={classes.titleContainer}>
        <Typography variant={"h4"}>
          Details
        </Typography>
        <Divider />
      </div>
      <div>
        <FormFieldsGenerator model={model} onChange={onChange} />
      </div>
      <div className={classes.btnContainer}>
        <StepActions onNext={onSave} disableNext={!isModelValid(model)} />
      </div>
    </div>
  )
}
