import React from 'react';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {ResidentContext} from "../../../context/residentcontext";
import AddIcon from "@material-ui/icons/Add";
import MedicalProviderLookup from "../../medicalProviders/medical.providers.lookup";
import {createResidentMedicalProvider, deleteResidentMedicalProvider} from "../../../services/provider.service";
import {GlobalNotificationContext} from "../../notification/globalnotificationprovider";
import DeleteForeverTwoToneIcon from "@material-ui/icons/DeleteForeverTwoTone";
import {Link} from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1)
  },
  deleteBtn: {
    background: theme.palette.error.light,
    color: theme.palette.error.contrastText,
    '&:hover': {
      background: theme.palette.error.main
    }
  }
}));

const ResidentMedicalProviders = ({match}) => {
  const classes = useStyles();
  const [viewLookup, setViewLookup] = React.useState(false);
  const [medicalProviders, setMedicalProviders] = React.useState([]);
  const { resident } = React.useContext(ResidentContext);
  const { addNotification } = React.useContext(GlobalNotificationContext);

  React.useEffect(() => {
    if (resident) {
      setMedicalProviders(resident.medicalProviders);
    }
  }, [ resident ])

  const onMedicalProviderSelected = async medicalProvider => {
    const exist = medicalProviders?.find(m => m._id === medicalProvider._id);
    if (!exist) {
      const entity = await createResidentMedicalProvider(resident?._id, medicalProvider);
      const obj = [...medicalProviders, entity];
      setMedicalProviders(obj);
      setViewLookup(false);
      addNotification('Medical provider added to resident', 'success');
    } else {
      setViewLookup(false);
      addNotification('Medical provider alredy exists for this resident', 'warning');
    }
  }

  const removeMedicalProvider = async medicalProvider => {
    const result = await deleteResidentMedicalProvider(resident?._id, medicalProvider?._id);
    if (result?.ok) {
      const obj = medicalProviders.filter(f => f._id !== medicalProvider._id);
      setMedicalProviders(obj);
      addNotification('Medical provider removed from resident', 'success');
    } else {
      addNotification('Unable to remove medical provider', 'warning');
    }
  }


  return (
    <Paper elevation={24} className={classes.root}>
      <Typography variant={"h6"}>Medical Providers</Typography>
      <Button size={"small"}
              color={"primary"}
              variant={"contained"}
              onClick={() => setViewLookup(true)}
              startIcon={<AddIcon />}>Add</Button>
      <MedicalProviderLookup open={viewLookup}
                             onSelect={onMedicalProviderSelected}
                             onClose={() => setViewLookup(false)}
      />
      <TableContainer>
        <Table size={"small"}>
          <TableHead>
            <TableCell width={50}></TableCell>
            <TableCell>Name</TableCell>
            <TableCell>
              Type
            </TableCell>
            <TableCell>
              Phone
            </TableCell>
            <TableCell>
              Address
            </TableCell>
          </TableHead>
          <TableBody>
            {medicalProviders?.map(medicalProvider =>
              <TableRow>
                <TableCell>
                  <Button size={"small"}
                          startIcon={<DeleteForeverTwoToneIcon />}
                          variant={"contained"}
                          className={classes.deleteBtn}
                          onClick={() => removeMedicalProvider(medicalProvider)}
                  >Remove</Button>
                </TableCell>
                <TableCell>
                  <Typography component={Link} to={`/medicalproviders/${medicalProvider._id}`}>
                    {medicalProvider.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  {medicalProvider.type}
                </TableCell>
                <TableCell>
                  {medicalProvider.phones?.map(phone => `${phone.phoneType}: ${phone.number}`).join(', ')}
                </TableCell>
                <TableCell>
                  {medicalProvider?.address?.address}{" "}
                  {medicalProvider?.address?.address2}
                  {" "}{medicalProvider?.address?.city},
                  {" "}{medicalProvider?.address?.state}
                  {" "}{medicalProvider?.address?.zipCode}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default ResidentMedicalProviders;
