import {Calendar, momentLocalizer } from "react-big-calendar";
import React from "react";
import moment from 'moment';
import {makeStyles} from "@material-ui/core";

const localizer = momentLocalizer(moment)

const useStyles = makeStyles((theme) => ({
  loading: {
    opacity: '.2'
  }
}));

export const SsaCalendar = ({
                              events,
                              loadingEvents,
                              height = 500,
                              color = "#fff",
                              slotSelected,
                              eventSelected,
                              eventPropertySetter = null,
                              customEventComponent,
                              weekEventComponent,
                              customAgendaEventComponent,
                              onTimeframeNavigated,
                              onView,
                              ...p }) => {

  const classes = useStyles();

  const onNavigate = (date, timeframe, action) => {
    const data = {
      date,
      timeframe,
      action
    };
    if (onTimeframeNavigated) {
      onTimeframeNavigated(data);
    }
  }

  const onViewChanged = view => {
    if (onView) {
      onView(view);
    }
  }

  return (
    <div className={loadingEvents ? classes.loading: null}>
      <Calendar
        onNavigate={onNavigate}
        onView={onViewChanged}
        selectable
        localizer={localizer}
        events={events}
        style={{ height }}
        startAccessor="start"
        endAccessor="end"
        eventPropGetter={eventPropertySetter}
        onSelectSlot={slotSelected}
        onSelectEvent={eventSelected}
        views={{ agenda: true, month: true, week: true, day: true }}
        {...p}
      />
    </div>
  )
}
