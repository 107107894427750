import moment from "moment";

export const EventTemplate = {
  title: {
    title: "Title",
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false
  },
  calendarType: {
    title: "Type",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [],
    dropDownItemValue: "name",
    dropDownItemLabel: "name",
    date: false
  },
  start: {
    title: "Start",
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    time: true
  },
  end: {
    title: "End",
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    time: true
  },
  tags: {
    title: 'Tags',
    isTagsComponent: true,
    hidden: false,
    value: [],
    required: false,
  },
  allDay: {
    title: "All day event",
    required: true,
    value: false,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    isToggle: true
  },
  repeat: {
    hidden: true,
    title: "Repeatable",
    required: true,
    value: "Does not repeat",
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [
      {
        name: "Does not repeat",
        value: "norepeat"
      },
      {
        name: "Every weekday",
        value: "eweekday"
      },
      {
        name: "Daily",
        value: "daily"
      },
      {
        name: "Weekly",
        value: "weekly"
      },
      {
        name: "Monthly",
        value: "monthly"
      },
      {
        name: "Yearly",
        value: "yearly"
      }
    ],
    dropDownItemValue: "name",
    dropDownItemLabel: "name",
  },
  occurrence: {
    hidden: true,
    value: {
      cadence: 1,
      days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
      day: new Date().getDay(),
      startDate: moment().toDate(),
      endDate: moment().add(7, 'days').toDate()
    }
  }

}
