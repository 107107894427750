import React from 'react';
import {StepActions} from "../step.actions";
import {IntakeContext} from "../../context/intake.context";
import {useStepStyles} from "../styles";
import {useHistory} from "react-router-dom";
import {Divider, Typography} from "@material-ui/core";
import {MedicalModel} from "./medical.model";
import {FormFieldsGenerator} from "../../../../../../utils/forms";
import {blendObjects, setStepStatus, updateMedicationAction} from "../../context/intake.actions";


export const MedicalStep = () => {
  const { dispatch, state } = React.useContext(IntakeContext);
  const [model, setModel] = React.useState((JSON.parse(JSON.stringify(MedicalModel))));
  const classes = useStepStyles();
  const history = useHistory();



  React.useEffect(() => {
    if (state?.resident) {
      const obj = blendObjects(state, JSON.parse(JSON.stringify(MedicalModel)));
      setModel(obj);
    }
  // eslint-disable-next-line
  }, [state?.resident, state?.model]);

  const onChange = e => {
    setModel({...model, ...e});
  }

  const onSave = async () => {
    const data = {
      livingWill: model?.livingWill?.value === 'y',
      codeStatus: model?.codeStatus?.value,
      specialNeeds: model?.specialNeeds?.value
    };
    await updateMedicationAction(dispatch, model, state, data);
    setStepStatus(dispatch, 'medical', true);
    history.push(`/intake/${state.id}/background`);
  }

  return (
    <div>
      <div className={classes.titleContainer}>
        <Typography variant={"h4"}>
          Medical
        </Typography>
        <Divider />
      </div>
      <div>
        <FormFieldsGenerator model={model} onChange={onChange} />
      </div>
      <div className={classes.btnContainer}>
        <StepActions onNext={onSave}
                     onPrev={() => history.push(`/intake/${state.id}/photo`)}
        />
      </div>
    </div>
  )
}
