import React from 'react';
import {Grid, makeStyles, Typography, List } from "@material-ui/core";
import {ListLoading} from "../../../utils/indicators/list.loading";
import * as _ from "lodash";
import moment from "moment/moment";
import {TimeEntryContext} from "../../../context/TimeEntryContext/time.entry.context";
import {TimeEntryMobileItem} from "./time.entry.mobile.item";

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: theme.palette.grey["200"],
        padding: theme.spacing(2)
    },
    weekContainer: {
        padding: theme.spacing(1)
    }
}));

export const TimeEntryMobileGroups = ({weekName, timeEntries}) => {
    const classes = useStyles();
    const {state} = React.useContext(TimeEntryContext);
    const [groups, setGroups] = React.useState([]);

    React.useEffect(() => {
        groupEntries(timeEntries);
    }, [timeEntries]);

    const groupEntries = entries => {
        const obj = _(entries).groupBy('date').map((items, group) => ({ date: group, entries: items})).value();
        setGroups(obj);
    }

    const getTotal = group => {
        const result = _.sumBy(group.entries, entry => entry?.totalMinutes);
        const duration = moment.duration(result, "minutes");
        return `${duration.hours()}:${duration.minutes().toLocaleString("en-us",
            {
                minimumIntegerDigits: 2,
                useGrouping: false
            })}`;
    }

    const getWeekTotal = () => {
        let entries = []
        for (var g of groups) {
            entries = [...entries, ...g.entries];
        }
        const totalMinutes = _.sumBy(entries, entry => entry?.totalMinutes);
        const duration = moment.duration(totalMinutes, "minutes");
        return `${Math.floor(totalMinutes /60)}:${moment.utc(duration.asMilliseconds()).format("mm")}`
    }

    return (
        <div>
            <Grid className={classes.weekContainer}
                  container justifyContent={"space-between"} alignItems={"center"}>
                <Typography variant={"subtitle1"}>
                    {weekName}
                </Typography>
                <Typography variant={"subtitle1"}>
                    Week Total: {getWeekTotal()}
                </Typography>
            </Grid>
            {state?.loading &&
                <ListLoading rows={5} />
            }
            {!state?.loading && groups?.sort((a, b) => a.date < b.date ? 1: -1)?.map(group =>
                <div>
                    <Grid container
                          alignItems={"center"}
                          justifyContent={"space-between"}
                          className={classes.header}>
                        <Typography variant={"subtitle1"}>
                            {moment(group?.date)?.utc()?.format("MM/DD/YYYY")}
                        </Typography>
                        <Typography variant={"subtitle1"}>
                            Total: {getTotal(group)}
                        </Typography>
                    </Grid>
                    <List disablePadding>
                        {group?.entries?.map((timeEntry, index) => <TimeEntryMobileItem timeEntry={timeEntry} />)}
                    </List>
                </div>
            )}
        </div>
    )
}
