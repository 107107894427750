import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, Grid, makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import {withStyles} from "@material-ui/core/styles";
import moment from "moment";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const useStyles = makeStyles((theme) => ({
    pre: {
        borderStyle: "solid",
        borderColor: theme.palette.grey["400"],
        borderWidth: 1,
        padding: theme.spacing(1),
        backgroundColor: "#d5ca92",
        height: '50vh',
        overflowY: 'scroll',
    }
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export const MedicationOrderHistoryModal = ({open, onClose, historyRow}) => {
    const classes = useStyles();

    const getAction = row => {
        switch (row?.action) {
            case "create":
                return "Created Medication Order";
            case "updatedMedicationOrder":
                return "Updated Medication Order";
            case "deletedMedicationOrder":
                return "Deleted Medication Order";
            case "createdDosageInstruction":
                return "Created Dosage Instruction";
            case "updatedDosageInstruction":
                return "Updated Dosage Instruction";
            case "deletedDosageInstruction":
            case "deleteDosageInstruction":
                return "Deleted Dosage Instruction";
            default:
                return row?.action;
        }
    }
    return (
        <Dialog open={open}
                onClose={onClose}
                maxWidth={"lg"}
                fullWidth={true}
        >
            <DialogTitle onClose={onClose}>
                {"Medication Order History"}
            </DialogTitle>
            <DialogContent dividers>
                <Grid container>
                    <Grid item xs={12} md={4}>
                        <Typography>Action: {getAction(historyRow)}</Typography>
                        <Typography>Date: {moment(historyRow?.createdAt).format("MM/DD/YYYY hh:mm a")}</Typography>
                        <Typography>User: {historyRow?.user?.firstName} {historyRow?.user?.lastName}</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Typography>YAML</Typography>
                        <pre className={classes.pre}>
                            {historyRow?.value}
                        </pre>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}
