import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  InputLabel, MenuItem, Select
} from "@material-ui/core";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {UserContext} from "../../../../context/usercontext";
import {getTenantUsers} from "../../../../services/tenant.service";
import TableLoadingIndicator from "../../../../utils/indicators/table.loading";

;

export const EditEmarAdministered = ({open, onClose, onSave, emar}) => {
  const { selectedTenant } = React.useContext(UserContext);
  const [emarEntity, setEmarEntity] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [users, setUsers] = React.useState([]);

  React.useEffect(() => {
    if (open && emar) {
      setEmarEntity(emar);
      setUpForm();
    }
    // eslint-disable-next-line
  }, [open, emar, selectedTenant]);

  const setUpForm = async () => {
    setLoading(true);
    const userResponse = await getTenantUsers(selectedTenant?._id);
    if (!userResponse?.error) {
      setUsers(userResponse?.sort((a, b) => a?.lastName > b?.lastName ? 1 : -1));
    }
    setLoading(false);
  }

  const onAdministeredTimestampChanged = date => {
    const obj = {...emarEntity};
    obj.administeredTimestamp = date;
    setEmarEntity(obj);
  }

  const onAdministeredUserChanged = e => {
    const user = users?.find(u => u._id === e.target.value);
    const obj = {...emarEntity};
    obj.administeredUser = user;
    setEmarEntity(obj);
  }

  const save = () => {
    const data = {...emarEntity};
    onSave(data);
  }

  return (
    <Dialog open={open}
            onClose={onClose}
            fullWidth={true}
            maxWidth={"sm"}
    >
      <DialogTitle>Update Adminstered Details</DialogTitle>
      {loading &&
        <DialogContent>
          <TableLoadingIndicator rows={1} cols={1} />
        </DialogContent>
      }
      {!loading &&
        <DialogContent dividers>
          <FormGroup>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDateTimePicker
                label={"Administered Date"}
                fullWidth
                variant={"inline"}
                inputSize={"small"}
                name={"administeredTime"}
                value={emarEntity?.administeredTimestamp}
                allowKeyboardControl="true"
                format={"MM/DD/YYYY hh:mm a"}
                onChange={onAdministeredTimestampChanged}
              />
            </MuiPickersUtilsProvider>
            <FormControl>
              <InputLabel shrink={true}>Administered By</InputLabel>
              <Select value={emarEntity?.administeredUser?._id}
                      onChange={onAdministeredUserChanged}
              >
                <MenuItem>Select a User</MenuItem>
                {users?.map(user =>
                  <MenuItem value={user?._id}>
                    {user?.firstName} {user?.lastName} ({user?.initials})
                  </MenuItem>)
                }
              </Select>
            </FormControl>
          </FormGroup>
        </DialogContent>
      }
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant={"contained"}
                color={"primary"}
                onClick={save}>Update</Button>
      </DialogActions>
    </Dialog>
  )
}
