import React from 'react';
import {
  makeStyles,
  Typography,
  Grid,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardMedia,
  CardContent,
  Hidden,
  Chip,
  IconButton,
  Drawer,
  ListItem,
  ListItemText,
  ListItemIcon,
  List,
  Collapse,
  ListItemAvatar,
  Avatar, CircularProgress, Tooltip,
} from '@material-ui/core';
import ResidentAlerts from '../alert/resident.alerts';
import moment from 'moment';
import FileSelector from '../../utils/fileselector/fileselector';
import { createResidentImage } from '../../../services/resident.service';
import { UserContext } from '../../../context/usercontext';
import config from '../../../utils/configs';
import { Link as RouterLink } from 'react-router-dom';
import Compressor from 'compressorjs';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import HomeTwoToneIcon from '@material-ui/icons/HomeTwoTone';
import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone';
import DnsTwoToneIcon from '@material-ui/icons/DnsTwoTone';
import LocalHospitalTwoToneIcon from '@material-ui/icons/LocalHospitalTwoTone';
import AssignmentIndTwoToneIcon from '@material-ui/icons/AssignmentIndTwoTone';
import LocationOnTwoToneIcon from '@material-ui/icons/LocationOnTwoTone';
import PhoneTwoToneIcon from '@material-ui/icons/PhoneTwoTone';
import ContactsTwoToneIcon from '@material-ui/icons/ContactsTwoTone';
import { ResidentContext } from '../../../context/residentcontext';
import useHasRole from "../../../hooks/user.role.hook";
import {
  AssessmentTwoTone, CalendarTodayTwoTone,
  FileCopyOutlined,
  LocalHospital,
  PhotoCameraTwoTone,
  RotateLeftTwoTone
} from "@material-ui/icons";
import {ResidentListContext} from "../list/resident.list.context";
import {GlobalNotificationContext} from "../../notification/globalnotificationprovider";
import {displayPhoneNumber} from "../../../utils/phone.display";

const cardStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(),
    padding: theme.spacing(1),
  },
  container: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textDecoration: 'none',
  },
  avatar: {
    height: '80px',
    width: '80px',
    imageOrientation: 'from-image',
    marginLeft: '1.5rem',
    cursor: 'pointer',
  },
  defaultImg: {
    height: theme.spacing(5),
    width: theme.spacing(5),
  },
  imageContainer: {
    flex: 1,
  },
  detailsContainer: {
    flex: 1,
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  detailsContainerGroup: {
    padding: theme.spacing(1),
  },
  alertContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  alerts: {
    background: 'red',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  card: {
    display: 'flex',
    width: '100%',
    height: '8rem',
    margin: 0,
  },
  cover: {
    flex: '0 0 8rem',
    cursor: 'pointer'
  },
  content: {
    padding: theme.spacing(0, .5),
    flex: 4
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  avatarSmall: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    backgroundColor: theme.palette.grey[500],
    fontSize: '14px',
  },
  noResidentRefreshBtn: {
    margin: theme.spacing(2),
    background: theme.palette.warning.main,
    color: '#fff',
    '&:hover': {
      background: theme.palette.warning.dark
    }
  },
  loadingAvatar: {
    margin: theme.spacing(2),
    background: '#fff'
  },
  residentImage: {
    height: '400px'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  residentStatus: {
  },
  residentStatusAdmitted: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    }
  },
  residentStatusDischarged: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    }
  },
  tagContainer: {
    padding: theme.spacing(.5, 0)
  },
  codeStatus: {
    marginLeft: theme.spacing(1),
    color: theme.palette.error.dark
  },
  emptyResidentImage: {
    height: '100%',
    width: '120px'
  },
  allergies: {
    color: theme.palette.error.dark
  },
  allergyContainer: {
    maxWidth: '325px',
    textOverflow: 'ellipsis',
    textDecoration: "underline"
  }
}));

// imageURL
// https://storage.googleapis.com/${publicBucket}/tenants/${tenantId}/residents/${residentId}/photo/${fileName};

const ResidentCard = () => {
  const classes = cardStyles();
  const [viewImageDialog, setViewImageDialog] = React.useState(false);
  const { state } = React.useContext(UserContext);
  const [uploadingImage, setUploadingImage] = React.useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [viewIndex, setViewIndex] = React.useState(2);
  const { checkPermission } = useHasRole();
  const canResidentRead = checkPermission('RESIDENT_READ');
  const canMedicationOrderRead = checkPermission('MEDICATION_ORDER_READ');
  const canTreatmentOrderRead = checkPermission('TREATMENT_ORDER_READ');
  const canCarePlanOrderRead = checkPermission('CAREPLAN_ORDER_READ');
  const canDietPlanOrderRead = checkPermission('DIET_ORDER_READ');
  const canResidentVitalsRead = checkPermission('RESIDENT_VITALS_READ');
  const canFileRead = checkPermission("RESIDENT_FILE_READ");
  const canEmarRead = checkPermission('EMAR_READ');
  const canEtarRead = checkPermission('ETAR_READ');
  const canELogRead = checkPermission('ELOG_READ');
  const canETaskRead = checkPermission('ETASK_READ');

  const { addNotification } = React.useContext(GlobalNotificationContext);
  const { resident, loadingResident, refresh, dispatch } = React.useContext(ResidentContext);
  const residentListContext = React.useContext(ResidentListContext);



  const buildImageUrlString = (fileName) => {
    return `${config.bucketObjectUrl}/${config.publicBucketName}/tenants/${state?.selectedTenant?._id}/residents/${resident._id}/photo/${fileName}`;
  };

  const displayPrimaryPhone = (res) => {
    if (res && res.phones) {
      const primary = res.phones.find((p) => p.isPrimary);
      if (primary) {
        return (
          <Typography variant="subtitle2">
            Primary Phone: {displayPhoneNumber(primary.number)}
          </Typography>
        );
      }
    }
    return <Typography variant="subtitle2">Primary Phone: N/A</Typography>;
  };

  const displayPrimaryInsurance = (res) => {
    if (res && res.residentInsurances) {
      const primary = res.residentInsurances.find((r) => r.isPrimary);
      if (primary && primary.insurance) {
        return (
          <Typography variant="subtitle2">
            Primary Insurance: {primary.insurance.name}
          </Typography>
        );
      }
    } else {
      return null;
    }
  };

  const displayPhysicianPrimaryPhone = entity => {
    let main = null
    if (entity?.physician?.phones?.length === 1) {
      main = entity?.physician?.phones[0];
    } else {
      main = entity?.physician?.phones?.find(p => p.phoneType === 'Main');
    }
    if (main) {
      return ` ${displayPhoneNumber(main?.number)}`;
    } else {
      return null;
    }
  }
  const displayContactPrimaryPhone = entity => {
    let main;
    if (entity?.contact?.phones?.length === 1) {
      main = entity?.contact?.phones[0]
    } else {
      main = entity?.contact?.phones?.find(p => p.phoneType === 'Mobile');

    }
    if (main) {
      return ` ${displayPhoneNumber(main?.number)}`;
    } else {
      return null;
    }
  }

  const displayPrimaryPhysician = (res) => {
    if (res?.residentPhysicians) {
      const primary = res.residentPhysicians.find(r => r?.isPrimary);
      if (primary && primary.physician) {
        return (
          <Typography variant="subtitle2" style={{padding: 0, margin: 0}}>
            Primary Physician: {primary.physician.firstName}{' '} {primary.physician.lastName} {displayPhysicianPrimaryPhone(primary)}
          </Typography>
        );
      }
    } else {
      return null;
    }
  };

  const displayPrimaryContact = (res) => {
    if (res && res.contacts) {
      const primary = res.contacts.find((r) => r?.isPrimary);
      if (primary && primary.contact) {
        return (
          <Typography variant="subtitle2">
            Primary Contact: {primary.contact.firstName}{' '}
            {primary.contact.lastName} {displayContactPrimaryPhone(primary)}
          </Typography>
        );
      }
    } else {
      return null;
    }
  };

  const viewImage = () => {
    setViewImageDialog(true);
  };

  const getResidentAdmissionDate = resident => {
    if (resident?.admissions?.length > 0) {
      const admissions = resident?.admissions?.filter(admission => admission?.type === "Admission");
      if (admissions?.length > 1) {
        const admission = admissions?.sort((a,b) => a?.date > b?.date ? -1: 1)[0];
        return (
          <Typography variant={"subtitle2"}>
            Admission Date: {moment(admission?.date).format("MM/DD/YYYY")}
          </Typography>
        )
      } else if (admissions?.length === 1) {
        const admission = admissions[0];
        return (
          <Typography variant={"subtitle2"}>
            Admission Date: {moment(admission?.date).format("MM/DD/YYYY")}
          </Typography>
        )
      }
    }
    return null
  }

  const selectedFile = async (event) => {
    setUploadingImage(true);
    try {
      if (event.target.files && event.target.files.length > 0) {
        setUploadingImage(true);
        const formData = new FormData();
        const file = event.target.files[0];
        new Compressor(file, {
          quality: 0.6,
          maxHeight: 300,
          maxWidth: 300,
          checkOrientation: true,
          success: async (compressed) => {
            formData.append('file', compressed, file.name);
            const fileName = await createResidentImage(resident._id, formData);
            const obj = {...resident};
            obj.imageUrl = fileName;
            dispatch({type: 'UPDATE_RESIDENT_COMPLETE', payload: obj});
            residentListContext.dispatch({type: 'RESIDENT_UPDATED', payload: obj});
            setViewImageDialog(false);
            addNotification('Resident image updated', 'success');
          },
        });
      }
    } catch (error) {
      addNotification('Unable to update resident image');
    } finally {
      setUploadingImage(false);
    }
  };

  if (loadingResident) {
    return (
      <Card elevation={24} className={classes.card}>
        <CardContent style={{ width: '100%'}}>
          <Grid container
                justify={"center"}
                alignItems={"center"}
          >
            <Avatar className={classes.loadingAvatar}>
              <CircularProgress />
            </Avatar>
            <Typography variant={"subtitle2"}>Loading Resident....</Typography>
          </Grid>
        </CardContent>
      </Card>
    )
  }

  if (!loadingResident && !resident) {
    return (
      <Card elevation={24} className={classes.card}>
        <CardContent style={{ width: '100%'}}>
          <Grid container
                justify={"center"}
                alignItems={"center"}
          >
            <IconButton className={classes.noResidentRefreshBtn}
                        onClick={refresh}
            >
              <RotateLeftTwoTone />
            </IconButton>
            <Typography variant={"subtitle2"}>Resident not found. Click refresh icon to reload.</Typography>
          </Grid>
        </CardContent>
      </Card>
    )
  }

  if (!loadingResident && resident) {
    return (
      <Card elevation={24} className={classes.card}>
        <Tooltip title={"Click to change resident image"}>
          <>
            {resident?.imageUrl &&
              <CardMedia
                onClick={viewImage}
                className={classes.cover}
                image={buildImageUrlString(resident?.imageUrl)}
              >
              </CardMedia>
            }
            {!resident?.imageUrl &&
              <Avatar className={classes.emptyResidentImage} variant="square"
                      onClick={viewImage}>
                {resident?.firstName && resident.firstName[0]} {resident?.lastName && resident?.lastName[0]}
              </Avatar>
            }
          </>
        </Tooltip>
        <CardContent className={classes.content}>
          <Grid container justifyContent={"space-between"}>
            <Grid item>
              <Typography variant="h6"  style={{padding: 0, margin: 0}}>
                {resident?.firstName}
                {resident?.nickname &&
                  <span>
                    {" "}"{resident?.nickname}"{" "}
                  </span>
                }
                {!resident?.nickname &&
                  <span>{" "}</span>
                }
                {resident?.lastName}
              </Typography>
              <Hidden xsDown>
                {resident.dob && (
                  <Typography variant="subtitle2">
                    DOB: {moment(resident.dob)?.utc().format('MM/DD/YYYY')}
                  </Typography>
                )}
                {resident.roomNumber && (
                  <Typography variant="subtitle2">
                    Room Number: {resident.roomNumber}
                  </Typography>
                )}
                {displayPrimaryPhone(resident)}
                {resident?.medicalRecordId &&
                  <Typography variant="subtitle2">
                    MRID: {resident.medicalRecordId}
                  </Typography>
                }
              </Hidden>
            </Grid>
            <Hidden xsDown>
              <Grid item>
                {displayPrimaryPhysician(resident)}
                {displayPrimaryContact(resident)}
                {displayPrimaryInsurance(resident)}
                {getResidentAdmissionDate(resident)}
                {resident?.allergies?.length > 0 &&
                  <div className={classes.allergyContainer}>
                    <Typography variant={"subtitle2"}
                                component={RouterLink}
                                to={`/residents/${resident?._id}/allergies`}
                                className={classes.allergies}>
                      Allergies: {resident?.allergies?.map(allergy => allergy?.name)?.join(', ')}
                    </Typography>
                  </div>
                }
              </Grid>
              <Grid item className={classes.tagContainer}>
                {resident && resident.tags && resident.tags.length > 0 ? (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {resident.tags.map((tag) => (
                      <Chip
                        label={tag}
                        color="primary"
                        style={{ marginRight: '.25rem' }}
                      />
                    ))}
                  </div>
                ) : null}
              </Grid>
            </Hidden>
            <Grid item>
              <Grid container justifyContent={"flex-end"}>
                <IconButton
                  color="inherit"
                  aria-label="Menu"
                  aria-haspopup="true"
                  size="small"
                  style={{ alignSelf: 'flex-end' }}
                  onClick={() => setOpenDrawer((openDrawer) => !openDrawer)}
                >
                  <MenuIcon />
                </IconButton>
                <ResidentAlerts residentId={resident._id} />
                <div style={{textAlign: "right"}}>
                  <Hidden xsDown>
                    {resident?.codeStatus?.length > 0 &&
                      <Typography className={classes.codeStatus}
                                  variant={"subtitle1"}
                      >
                        {resident?.codeStatus?.join(', ')}
                      </Typography>
                    }
                    <Typography style={{position: 'absolute', bottom: 0, right: 5, padding: '0 .1rem .1rem 0'}}
                                variant={"subtitle2"}>{resident?.residentStatus?.name}
                    </Typography>
                  </Hidden>
                  <Hidden smUp>
                    <div style={{position: 'absolute', bottom: 0, right: 0, padding: '0 .1rem .1rem 0'}}>
                      <Typography  variant={"subtitle2"}>{resident?.residentStatus?.name}</Typography>
                    </div>
                  </Hidden>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={viewImageDialog}
          onClose={() => setViewImageDialog(false)}
        >
          <DialogTitle>
            <Typography variant={"h6"}>Resident Image</Typography>
            <IconButton aria-label="close"
                        className={classes.closeButton}
                        onClick={() => setViewImageDialog(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            {!uploadingImage &&
              <Grid container
                    justify={"center"}>
                <img alt="resident"
                     className={classes.residentImage}
                     src={buildImageUrlString(resident?.imageUrl)}/>
              </Grid>}
            {uploadingImage &&
              <Grid container
                    justify={"center"}>
                <CircularProgress />
              </Grid>
            }
          </DialogContent>
          <DialogActions>
            <FileSelector label="Upload resident image"
                          onFileSelected={selectedFile}
                          variant={"text"}
                          startIcon={<PhotoCameraTwoTone />}
                          color={"primary"} />
          </DialogActions>
        </Dialog>
        <Drawer
          open={openDrawer}
          anchor={'right'}
          onClose={() => setOpenDrawer(false)}
        >
          <Grid container>
            <Grid item>
              <List>
                {resident && (
                  <React.Fragment>
                    <ListItem>
                      <ListItemIcon>
                        <AccountCircleTwoToneIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={`
                      ${resident.firstName} ${resident.lastName}`}
                      />
                      <IconButton
                        color="inherit"
                        aria-label="Menu"
                        aria-haspopup="true"
                        size="small"
                        onClick={() =>
                          setOpenDrawer((openDrawer) => !openDrawer)
                        }
                      >
                        <CloseIcon />
                      </IconButton>
                    </ListItem>
                    <Divider />
                    {true == false && (
                      <ListItem
                        button
                        component={RouterLink}
                        onClick={() => setOpenDrawer(false)}
                        to="/residents"
                        style={{ textAlign: 'center', display: 'block' }}
                      >
                        <Typography variant="body1">Close Resident</Typography>
                      </ListItem>
                    )}
                    <Divider />
                  </React.Fragment>
                )}
                {canResidentRead &&
                  <>
                    <ListItem
                      button
                      onClick={() => setViewIndex(viewIndex !== 0 ? 0 : -1)}
                    >
                      <ListItemText primary="Resident" />
                      {viewIndex === 0 ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={viewIndex === 0}>
                      <ListItem
                        button
                        className={classes.nested}
                        component={RouterLink}
                        onClick={() => setOpenDrawer(false)}
                        to={`/residents/${resident._id}`}
                      >
                        <ListItemIcon>
                          <HomeTwoToneIcon />
                        </ListItemIcon>
                        <ListItemText primary="Home" />
                      </ListItem>

                      {[
                        {
                          title: 'Details',
                          icon: <DnsTwoToneIcon />,
                          to: `/residents/${resident._id}/details`,
                        },
                        {
                          title: 'Admissions/Discharges',
                          icon: <i className="fas fa-2x fa-ticket-alt"></i>,
                          to: `/residents/${resident._id}/admissions`,
                        },
                        {
                          title: 'Calendar',
                          icon: <CalendarTodayTwoTone />,
                          to: `/residents/${resident._id}/calendar`,
                        },
                        {
                          title: 'Medical',
                          icon: <LocalHospitalTwoToneIcon />,
                          to: `/residents/${resident._id}/medical`,
                        },
                        {
                          title: 'Allergies',
                          icon: <i className="fas fa-2x fa-allergies"></i>,
                          to: `/residents/${resident._id}/allergies`,
                        },
                        {
                          title: 'Diagnosis',
                          icon: <i className="fas fa-diagnoses"></i>,
                          to: `/residents/${resident._id}/diagnosis`,
                        },
                        {
                          title: 'Medical Providers',
                          icon: <LocalHospital />,
                          to: `/residents/${resident?._id}/medicalproviders`,
                        },
                        {
                          title: 'Background',
                          icon: <AssignmentIndTwoToneIcon />,
                          to: `/residents/${resident._id}/background`,
                        },
                        {
                          title: 'Address',
                          icon: <LocationOnTwoToneIcon />,
                          to: `/residents/${resident._id}/address`,
                        },
                        {
                          title: 'Phones',
                          icon: <PhoneTwoToneIcon />,
                          to: `/residents/${resident._id}/phones`,
                        },
                        {
                          title: 'Pharmacies',
                          icon: <i class="fas fa-2x fa-prescription"></i>,
                          to: `/residents/${resident._id}/pharmacies`,
                        },
                        {
                          title: 'Physicians',
                          icon: <i class="fas fa-2x fa-user-md"></i>,
                          to: `/residents/${resident._id}/physicians`,
                        },
                        {
                          title: 'Contacts',
                          icon: <ContactsTwoToneIcon />,
                          to: `/residents/${resident._id}/contacts`,
                        },
                        {
                          title: 'Insurance',
                          icon: <DnsTwoToneIcon />,
                          to: `/residents/${resident._id}/insurance`,
                        },
                      ].map((item, index) => (
                        <ListItem
                          button
                          key={item.title}
                          className={classes.nested}
                          component={RouterLink}
                          onClick={() => setOpenDrawer(false)}
                          to={item.to}
                        >
                          <ListItemIcon>{item.icon}</ListItemIcon>
                          <ListItemText primary={item.title} />
                        </ListItem>
                      ))}
                    </Collapse>
                    <Divider />
                  </>}
                <ListItem
                  button
                  onClick={() => setViewIndex(viewIndex !== 1 ? 1 : -1)}
                >
                  <ListItemText primary="Orders" />
                  {viewIndex === 1 ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={viewIndex === 1} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {canMedicationOrderRead &&
                      <ListItem
                        button
                        className={classes.nested}
                        component={RouterLink}
                        onClick={() => setOpenDrawer(false)}
                        to={`/residents/${resident._id}/medications`}
                      >
                        <ListItemIcon>
                          <i class="fas fa-2x fa-prescription-bottle"></i>
                        </ListItemIcon>
                        <ListItemText primary="Medications" />
                      </ListItem>}
                    {canTreatmentOrderRead &&
                      <ListItem
                        button
                        className={classes.nested}
                        component={RouterLink}
                        onClick={() => setOpenDrawer(false)}
                        to={`/residents/${resident._id}/treatments`}
                      >
                        <ListItemIcon>
                          <i class="fas fa-2x fa-band-aid"></i>
                        </ListItemIcon>
                        <ListItemText primary="Treatments" />
                      </ListItem>}
                    {canCarePlanOrderRead &&
                      <ListItem
                        className={classes.nested}
                        button
                        component={RouterLink}
                        onClick={() => setOpenDrawer(false)}
                        to={`/residents/${resident._id}/careplans`}
                      >
                        <ListItemAvatar>
                          <Avatar
                            className={classes.avatarSmall}
                            variant="square"
                          >
                            CP
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Care Plans" />
                      </ListItem>}
                    {canDietPlanOrderRead &&
                      <ListItem
                        button
                        className={classes.nested}
                        component={RouterLink}
                        onClick={() => setOpenDrawer(false)}
                        to={`/residents/${resident._id}/diets`}
                      >
                        <ListItemIcon>
                          <i class="fas fa-2x fa-utensils"></i>
                        </ListItemIcon>
                        <ListItemText primary="Diet" />
                      </ListItem>}
                  </List>
                </Collapse>
                <Divider />
                <ListItem
                  button
                  onClick={() => setViewIndex(viewIndex !== 2 ? 2 : -1)}
                >
                  <ListItemText primary="eChart" />
                  {viewIndex === 2 ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={viewIndex === 2} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {canResidentVitalsRead &&
                      <ListItem
                        button
                        className={classes.nested}
                        component={RouterLink}
                        onClick={() => setOpenDrawer(false)}
                        to={`/residents/${resident._id}/evitals`}
                      >
                        <ListItemIcon>
                          <i class="fas fa-2x fa-heartbeat"></i>
                        </ListItemIcon>
                        <ListItemText primary="eVitals" />
                      </ListItem>}
                    {canEmarRead &&
                      <ListItem
                        button
                        className={classes.nested}
                        component={RouterLink}
                        onClick={() => setOpenDrawer(false)}
                        to={`/residents/${resident._id}/emar`}
                      >
                        <ListItemIcon>
                          <StarBorder />
                        </ListItemIcon>
                        <ListItemText primary="eMar" />
                      </ListItem>}
                    {canEmarRead &&
                      <ListItem
                        button
                        className={classes.nested}
                        component={RouterLink}
                        onClick={() => setOpenDrawer(false)}
                        to={`/residents/${resident._id}/eprns`}
                      >
                        <ListItemIcon>
                          <i class="fas fa-2x fa-prescription-bottle"></i>
                        </ListItemIcon>
                        <ListItemText primary="ePrn" />
                      </ListItem>}
                    {canEtarRead &&
                      <ListItem
                        button
                        className={classes.nested}
                        component={RouterLink}
                        onClick={() => setOpenDrawer(false)}
                        to={`/residents/${resident._id}/etar`}
                      >
                        <ListItemIcon>
                          <i class="fas fa-2x fa-band-aid"></i>
                        </ListItemIcon>
                        <ListItemText primary="eTar" />
                      </ListItem>}
                    {canELogRead &&
                      <ListItem
                        button
                        className={classes.nested}
                        component={RouterLink}
                        onClick={() => setOpenDrawer(false)}
                        to={`/residents/${resident._id}/elogs`}
                      >
                        <ListItemIcon>
                          <i class="fas fa-2x fa-clipboard-list"></i>
                        </ListItemIcon>
                        <ListItemText primary="eLog" />
                      </ListItem>}
                    {canETaskRead &&
                      <ListItem
                        button
                        className={classes.nested}
                        component={RouterLink}
                        onClick={() => setOpenDrawer(false)}
                        to={`/residents/${resident._id}/tasks`}
                      >
                        <ListItemIcon>
                          <i class="fas fa-2x fa-tasks"></i>
                        </ListItemIcon>
                        <ListItemText primary="eTask"/>
                      </ListItem>
                    }
                    <ListItem
                      button
                      className={classes.nested}
                      component={RouterLink}
                      onClick={() => setOpenDrawer(false)}
                      to={`/residents/${resident._id}/edar`}
                    >
                      <ListItemIcon>
                        <i class="fas fa-2x fa-utensil-spoon"></i>
                      </ListItemIcon>
                      <ListItemText primary="eDar" />
                    </ListItem>
                    <ListItem
                      button
                      className={classes.nested}
                      component={RouterLink}
                      onClick={() => setOpenDrawer(false)}
                      to={`/residents/${resident._id}/notes`}
                    >
                      <ListItemIcon>
                        <i class="fas fa-2x fa-comment-alt"></i>
                      </ListItemIcon>
                      <ListItemText primary="eNotes" />
                    </ListItem>
                    <ListItem
                      button
                      className={classes.nested}
                      component={RouterLink}
                      onClick={() => setOpenDrawer(false)}
                      to={`/residents/${resident._id}/assessments`}
                    >
                      <ListItemIcon>
                        <AssessmentTwoTone />
                      </ListItemIcon>
                      <ListItemText primary="Assessments" />
                    </ListItem>
                  </List>
                </Collapse>
                {canFileRead &&
                <>
                  <Divider />
                  <ListItem
                    button
                    onClick={() => setViewIndex(viewIndex !== 3 ? 3 : -1)}
                  >
                    <ListItemText primary="Files" />
                    {viewIndex === 3 ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={viewIndex === 3} timeout="auto" unmountOnExit>
                    <ListItem
                      button
                      className={classes.nested}
                      component={RouterLink}
                      onClick={() => setOpenDrawer(false)}
                      to={`/residents/${resident._id}/files`}
                    >
                      <ListItemIcon>
                        <FileCopyOutlined/>
                      </ListItemIcon>
                      <ListItemText primary="eFile"/>
                    </ListItem>
                  </Collapse>
                </>
                }
              </List>
            </Grid>
          </Grid>
        </Drawer>
      </Card>
    );
  }
};

export default ResidentCard;
