import React from 'react';
import { TablePagination } from '@material-ui/core';
import { ResidentListContext } from './resident.list.context';
import { getResidents } from './actions/resident.actions';
import { convertToFilters } from '../../../utils/filter.utils';

const ResidentListPagination = () => {
  const { state, dispatch } = React.useContext(ResidentListContext);
  const handleChangePage = async (event, pg) => {
    dispatch({ type: 'setPage', payload: pg });
    await search(pg, state.size, state.filter, state.sort, state.sortDirection);
  };
  const onChangeRowsPerPage = async (event) => {
    dispatch({ type: 'setSize', payload: event.target.value });
    await search(
      state.page,
      event.target.value,
      state.filter,
      state.sort,
      state.sortDirection
    );
  };

  const search = async (page, size, filter, sort, sortDirection) => {
    dispatch({ type: 'setLoading' });
    const filters = convertToFilters(filter);
    dispatch({ type: 'setLoading' });
    const data = await getResidents(page, size, filters, sort, sortDirection);
    if (!data?.error) {
      dispatch({ type: 'setResidents', payload: data });
    } else {
      dispatch({type: 'searchResidentsError'})
    }
  };

  return (
    <TablePagination
      component="div"
      rowsPerPageOptions={state.sizeOptions}
      colSpan={5}
      count={state.total ? state.total : 0}
      rowsPerPage={state.size ? state.size : 0}
      page={state.page}
      backIconButtonProps={{
        'aria-label': 'previous page',
        disabled: state.page === 0 ? true : false,
      }}
      nextIconButtonProps={{
        'aria-label': 'next page',
        disabled: state.page * state.size >= state.total ? true : false,
      }}
      onRowsPerPageChange={onChangeRowsPerPage}
      onPageChange={handleChangePage}
    />
  );
};

export default ResidentListPagination;
