import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography
} from "@material-ui/core";
import {FormFieldsGenerator, isModelValid} from "../../../utils/forms";
import {Editor} from "react-draft-wysiwyg";
import { EventTemplate } from './model';
import {convertFromRaw, convertToRaw, EditorState} from "draft-js";
import {DeleteBtn} from "../../utils/buttons/DeleteBtn";
import ConfirmDialog from "../../utils/dialogs/confirm.dialog";
import draftToHtml from "draftjs-to-html";
import {createColor} from "material-ui-color";
import {ACTIONS, CalendarContext} from "../context/calendar.context";
import {
  deleteCalendarEventsAction,
  deleteCalendarSeriesAction,
  updateCalendarEventsAction
} from "../context/calendar.actions";
import {UserContext} from "../../../context/usercontext";
import useGlobalNotification from "../../../hooks/notification.hook";
import useHasRole from "../../../hooks/user.role.hook";

export const EditCalendarEvent = () => {
  const { user } = React.useContext(UserContext);
  const { state, dispatch } = React.useContext(CalendarContext);
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(EventTemplate)));
  const [editNote, setEditNote] = React.useState();
  const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());
  const [color, setColor] = React.useState(createColor("#fff"));
  const { addNotification } = useGlobalNotification();
  const { checkPermission } = useHasRole();
  const canWriteUserCalendar = checkPermission("USER_CALENDAR_WRITE");
  const canDeleteUserCalendar = checkPermission("USER_CALENDAR_DELETE");

  React.useEffect(() => {
    if (state?.viewEditEventModal && state.selectedCalendarEvent) {
      const obj = JSON.parse(JSON.stringify(EventTemplate));
      Object.keys(state?.selectedCalendarEvent).forEach(key => {
        if (obj.hasOwnProperty(key)) {
          obj[key].value = state?.selectedCalendarEvent[key];
        }
        if (key === "calendarType") {
          obj[key].dropDownItems = state?.calendarTypes?.map(cal => ({name: cal.name}));
        }
      });
      if (state?.selectedCalendarEvent?.note) {
        const parsed = JSON.parse(state?.selectedCalendarEvent?.note)
        setEditNote(parsed);
        const content = convertFromRaw(parsed);
        setEditorState(EditorState.createWithContent(content));
      } else {
        setEditNote(null);
        setEditorState(EditorState.createEmpty());
      }
      setColor(createColor(state?.selectedCalendarEvent?.color));
      setModel(obj);
    }
    // eslint-disable-next-line
  }, [ state?.viewEditEventModal, state?.selectedCalendarEvent ]);

  const onEventChange = e => {
    setModel({...model, ...e});
  }

  const onNoteChange = e => {
    setEditorState(e);
    const message = convertToRaw(e.getCurrentContent());
    setEditNote(message);
  }

  const deleteEvent = async () => {
    await deleteCalendarEventsAction(
      dispatch,
      user?._id,
      state?.selectedCalendarEvent?._id,
      () => addNotification("Unable to delete calendar event", "error"),
      () => addNotification("Calendar event deleted", "success")
    );
  }

  const save = async () => {
    const data = {}
    Object.keys(model).forEach(key => {
      data[key] = model[key].value
    });

    // Add note
    data.note = editNote;
    data.noteText = editorState?.getCurrentContent()?.getPlainText();
    data.noteHtml = draftToHtml(editNote);
    data.color = color?.hex ? `#${color.hex}`: color;
    await updateCalendarEventsAction(dispatch,
      user?._id,
      state?.selectedCalendarEvent?._id,
      data,
      () => addNotification("Unable to update calendar event", "error"),
      () => addNotification("Calendar event updated", "success")
    );
  }

  const deleteSeriesEvent = async () => {
    await deleteCalendarSeriesAction(
      dispatch,
      user?._id,
      state?.selectedCalendarEvent?.seriesId,
      () => addNotification("Unable to delete calendar series", "error"),
      () => addNotification("Calendar series deleted", "success")
    );
  }

  return (
    <Dialog open={state?.viewEditEventModal}
            maxWidth={"xl"}
            fullWidth
            onClose={() => dispatch({type: ACTIONS.SET_VIEW_EDIT_EVENT_MODAL, payload: false })}
    >
      <DialogTitle>Calendar Event</DialogTitle>
      <DialogContent dividers>
        {model && <FormFieldsGenerator edit={canWriteUserCalendar} model={model} onChange={onEventChange} />}
        <Paper elevation={1} style={{ marginTop: '2rem', padding: '0 1rem', minHeight: '20rem'}}>
          <Typography variant={"subtitle1"}>Event Notes</Typography>
          <Editor editorState={editorState}
                  onEditorStateChange={text => onNoteChange(text)}
                  hashtag={{
                    separator: ' ',
                    trigger: '#',
                  }}
          />
        </Paper>
      </DialogContent>
      <DialogActions>
        <Grid container justify={model?.repeat?.value !== "Does not repeat" ? "space-between" : "flex-end"}>
          {model?.repeat?.value !== "Does not repeat" &&
            <DialogActions>
              <DeleteBtn onClick={() => dispatch({type: ACTIONS.TOGGLE_VIEW_DELETE_SERIES_CALENDAR_EVENT, payload: true })}
                         buttonText={"Delete series?"}
              />

            </DialogActions>}
          <DialogActions>
            <Button onClick={() => dispatch({type: ACTIONS.SET_VIEW_EDIT_EVENT_MODAL, payload: false })}>Cancel</Button>
            {canDeleteUserCalendar &&
            <DeleteBtn onClick={() => dispatch({type: ACTIONS.TOGGLE_VIEW_DELETE_CALENDAR_EVENT, payload: true})}/>
            }
            {canWriteUserCalendar &&
            <Button color={"primary"}
                    onClick={save}
                    disabled={!isModelValid(model)}
                    variant={"contained"}>
              Save
            </Button>
            }
          </DialogActions>
        </Grid>
      </DialogActions>
      <ConfirmDialog open={state?.viewConfirmDeleteSeriesDialog}
                     onClose={() => dispatch({type: ACTIONS.TOGGLE_VIEW_DELETE_SERIES_CALENDAR_EVENT, payload: false })}
                     title={"Delete entire series?"}
                     message={"Press delete delete all table related to this series."}
                     onConfirm={deleteSeriesEvent} />
      <ConfirmDialog open={state?.viewConfirmDeleteDialog}
                     onClose={() => dispatch({type: ACTIONS.TOGGLE_VIEW_DELETE_CALENDAR_EVENT, payload: false })}
                     title={"Delete Event?"}
                     message={"Press delete to remove this event."}
                     onConfirm={deleteEvent} />
    </Dialog>
  )
}
