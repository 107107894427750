import apiUtil from '../utils/api.utils';
import config from '../utils/configs';

const apiUrl = `${config.residentService}/api/v1.0`;

export const dischargeResident = async (residentId, admissionId, discharge) => {
  const url = `${apiUrl}/residents/${residentId}/discharge`;
  try {
    const response = await apiUtil.client.post(url, { admissionId, discharge });
    return response.data;
  } catch (error) {
    return { error: true, message: error }
  }
}

export const getAdmissionStatuses = async token => {
  const baseUrl = `${apiUrl}/admissionstatuses/`;
  try {
    const response = await apiUtil.client.get(baseUrl);
    return response.data;
  } catch (error) {
    return [];
  }
};

export const createResidentAdmission = async (
  residentId,
  residentAdmission
) => {
  const url = `${apiUrl}/residents/${residentId}/admissions`;
  try {
    const response = await apiUtil.client.post(url, residentAdmission);
    return response.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const deleteResidentAdmission = async (residentId, admissionId) => {
  try {
    const response = await apiUtil.client.delete(`${apiUrl}/residents/${residentId}/admissions/${admissionId}`);
    return response.data;
  } catch (error) {
    return { error: true, message: error }
  }
}

export const updateResidentAdmission = async (
  residentId,
  admissionId,
  admission
) => {
  const url = `${apiUrl}/residents/${residentId}/admissions/${admissionId}`;
  try {
    const response = await apiUtil.client.put(url, admission);
    return response.data;
  } catch (error) {
    return { error: true, message: error}
  }
};


export const createLoa = async (residentId, admissionId, payload) => {
  const url = `${apiUrl}/residents/${residentId}/admissions/${admissionId}/loas`;
  try {
    const response = await apiUtil.client.post(url, payload);
    return response.data;
  } catch (error) {
    return { error: true, message: error }
  }
}

export const updateLoa = async (residentId, admissionId, loaId, payload) => {
  const url = `${apiUrl}/residents/${residentId}/admissions/${admissionId}/loas/${loaId}`;
  try {
    const response = await apiUtil.client.put(url, payload);
    return response.data;
  } catch (error) {
    return { error: true, message: error }
  }
}

export const deleteLoa = async (residentId, admissionId, loaId) => {
  const url = `${apiUrl}/residents/${residentId}/admissions/${admissionId}/loas/${loaId}`;
  try {
    const response = await apiUtil.client.delete(url);
    return response.data;
  } catch (error) {
    return { error: true, message: error }
  }
}
