import * as residentService from '../../../../services/resident.service';

export const getResidents = async (page = 0,
                                   size = 25,
                                   filters = [],
                                   sort = 'lastName',
                                   sortDirection = 'asc') => {
  return await residentService.getResidents(page, size, filters, sort, sortDirection);
};

