import React from 'react';
import * as _ from 'lodash';
import apiService from '../utils/api.utils';
import {createLoginActivityRecord, getCurrentUser} from '../services/login.service';
import {getAllLocations, getAllTenants, getTenantUsers} from "../services/tenant.service";

let UserContext = React.createContext();
let initialState = {
  token: null,
  user: null,
  tenants: [],
  selectedTenant: null,
  allTenantLocations: [],
  tenantLocations: [],
  selectedTenantLocation: null,
  roles: [],
};


// setTenants // setTenantLocations

let reducer = (state, action) => {
  switch (action.type) {
    case 'setToken':
      apiService.setToken(action.payload);
      return { ...state, token: action.payload };
    case 'setTenants':
      let currentTenant = null;
      if (action && action.payload && action.payload.length > 0) {
        currentTenant = action.payload[0];
      }
      apiService.setTenant(currentTenant?._id);
      return {
        ...state,
        tenants: action.payload,
        selectedTenant: currentTenant,
      };
    case 'selectTenant': {
      const tenant = state.tenants.find(
        (tenant) => tenant.id === action.payload
      );
      apiService.setTenant(tenant?._id);
      return { ...state, selectedTenant: tenant };
    }
    case 'setUser':
      sessionStorage.setItem('user', JSON.stringify(action.payload));
      return {
        ...state,
        user: action.payload,
        roles: action.payload ? action.payload.roles : [],
      };
    case 'setTenantLocations':
      let selectedLocation = null;
      let locations = action.payload;
      if (locations?.length > 0) {
        locations = locations?.sort((a, b) => a?.name > b?.name ? 1: -1);
        selectedLocation = locations[0];
      }
      apiService.setLocation(selectedLocation?._id ?? -1);
      return {
        ...state,
        tenantLocations: action.payload,
        selectedTenantLocation: selectedLocation
      };
    case 'selectTenantLocation': {
      const location = state.tenantLocations.find(
        (tenantLocation) => tenantLocation._id === action.payload
      );
      apiService.setLocation(location?._id ?? -1);
      return {
        ...state,
        selectedTenantLocation: location
          ? location
          : { _id: -1, name: 'All Locations' },
      };
    }
    case 'CURRENT_TENANT_CHANGE': {
      // TODO: Add Logic to check for time clock logins when tenant is changed.
      const selectedTenant = state?.tenants?.find(t => t?._id === action?.payload);
      apiService.setTenant(selectedTenant?._id);
      const locations = state?.allTenantLocations?.filter(l => l?.tenant?._id === action.payload)
                                                 ?.sort((a, b) => a?.name > b?.name ? 1: -1);
      const selectedLocation = locations?.[0];
      apiService.setLocation(selectedLocation?._id ?? -1);
      return {...state,
        selectedTenant: selectedTenant,
        tenantLocations: locations,
        selectedTenantLocation: selectedLocation};
    }
    case 'ALL_TENANT_LOCATIONS': {
      return {...state, allTenantLocations: action?.payload };
    }
    case 'tenantUpdated': {
      if (action.payload && action.payload._id === state.selectedTenant._id) {
        return { ...state, selectedTenant: action.payload };
      } else {
        return { ...state };
      }
    }
    case 'locationUpdated': {
      return {
        ...state,
        tenantLocations: state.tenantLocations.map((location) =>
          location._id === action.payload._id ? action.payload : location
        ),
        selectedTenantLocation:
          state.selectedTenantLocation &&
          state.selectedTenantLocation._id === action.payload._id
            ? action.payload
            : state.selectedTenantLocation,
      };
    }
    case 'updateTenantLogo': {
      state.selectedTenant.logo = action.payload;
      return { ...state, selectedTenant: { ...state.selectedTenant } };
    }
    case 'setUserImage': {
      const obj = {...state.user};
      obj.picture = action.payload;
      return {...state, user: obj }
    }
    case "UPDATE_TENANT_FEATURES": {
      const updatedTenants = state.tenants?.map(t => {
        if (t?._id === action?.payload.tenantId) {
          t.features = action?.payload?.features;
        };
        return t;
      });
      let currentTenant = state?.selectedTenant;
      if (currentTenant?._id === action?.payload?.tenantId) {
        currentTenant.features = action?.payload?.features;
      }

      return {...state, selectedTenant: currentTenant, tenants: updatedTenants}


    }
    default:
      return { ...state };
  }
};

const setUpUserTenantLocationContext = async (user, dispatch) => {
  let selectedTenant = null;
  if (user?.isSystemAdmin) {
    // get Tenants
    const tenants = await getAllTenants();
    if (tenants?.length > 0) {
      selectedTenant = tenants[0];
    }
    dispatch({ type: 'setTenants', payload: tenants});
    const locations = await getAllLocations();
    dispatch({type: 'ALL_TENANT_LOCATIONS', payload: locations});
    const tenantLocations = locations?.filter(l => l.tenant?._id === tenants[0]._id);
    dispatch({ type: 'setTenantLocations', payload: tenantLocations });
  } else {
    const tenants = getTenants(user);
    if (tenants?.length > 0) {
      selectedTenant = tenants[0];
    }
    // getUserTenants
    dispatch({ type: 'setTenants', payload: tenants});
    // getTenantLocations
    const locations = getLocations(user);
    dispatch({type: 'ALL_TENANT_LOCATIONS', payload: locations});
    const tenantLocations = locations?.filter(l => l.tenant?._id === tenants[0]._id);
    dispatch({ type: 'setTenantLocations', payload: tenantLocations });
  }
  return selectedTenant;
}

const getLocations = (user) => {
  if (user && user.roles) {
    const uniqueLocations = _.uniqWith(
      user.roles.map((r) => r.location),
      _.isEqual
    );
    return uniqueLocations;
  }
  return [];
};

const getTenants = user => {
  if (user?.roles) {
    return _.uniqWith(user.roles.map(role => role?.location?.tenant), _.isEqual)
  }
  return [];
}

const UserContextProvider = ({ tokenGetter, user, tenants, children }) => {
  let [state, dispatch] = React.useReducer(reducer, initialState);

  const getUsers = () => {
     return getTenantUsers(state?.selectedTenant?._id);
  }


  let value = {
    state,
    user: state?.user,
    dispatch,
    selectedTenant: state.selectedTenant,
    selectedTenantLocation: state.selectedTenantLocation,
    tenantLocations: state.tenantLocations,
    roles: state.roles,
    getUsers
  };

  React.useEffect(() => {
    const initUser = async (user) => {
      const token = await tokenGetter();
      dispatch({ type: 'setToken', payload: token });
      const uzer = await getCurrentUser();
      dispatch({ type: 'setUser', payload: uzer });
      if (uzer?.isActive === false) {
        await createLoginActivityRecord('deactivated user login', user);
      } else {
        // TODO: Add Logic to check for time clock logins when tenant is changed.
        await setUpUserTenantLocationContext(uzer, dispatch);
      }
    };
    if (tokenGetter && user) {
      initUser(user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);



  return (
      <UserContext.Provider value={value}>
        {children}
      </UserContext.Provider>
  );
};

let UserContextConsumer = UserContext.Consumer;

export { UserContext, UserContextProvider, UserContextConsumer };
