export const parseFilters = (filters = [], joinChar = ',') => {
  if (filters && filters.length > 0) {
    let queryUrl = '&filter=';
    queryUrl += filters
      .filter((fl) => fl.value !== -1)
      .map((filter) => `${filter.field}${filter.operator}${filter.value}`)
      .join(joinChar);
    return queryUrl;
  }
  return '';
};

export const parseFiltersToJson = (filters = [], joinChar = ',') => {
  let queryObj = {};
  filters.forEach((filter) => {
    switch (filter?.operator) {
      case '*=*':
        queryObj = { ...queryObj, [filter.field]: { $regex: filter.value, $options: 'i' } };
        break;
      case '==':
        queryObj = { ...queryObj, [filter.field]: filter.value };
        break;
      case '=in=':
        queryObj = { ...queryObj, [filter.field]: { $in: filter.value } };
        break;
      case '*=':
        queryObj = { ...queryObj, [filter.field]: { $regex: filter.value } };
        break;
      case 'between':
        queryObj = {
          ...queryObj,
          [filter.field]: { $gte: filter.value.start, $lte: filter.value.end },
        };
        break;
      case '>':
        queryObj = { ...queryObj, [filter.field]: { $gte: filter.value } };
        break;
      case '<':
        queryObj = { ...queryObj, [filter.field]: { $lte: filter.value } };
        break;
      case 'boolean': {
        if (filter.value !== '0') {
          let val = filter.value === '-1' ? false: true;
          queryObj = {...queryObj, [filter.field] : val }
        }
        break;
      }
      default:
    }
  });
  return `&filter=${JSON.stringify(queryObj)}`;

  /*
  if (filters && filters.length > 0) {
    let queryUrl = '&filter=';
    queryUrl += filters.filter(fl => fl.value !== -1)
                       .map(filter => `${filter.field}${filter.operator}${filter.value}`).join(joinChar);
    return queryUrl;
  }
return '';
*/
};

export const convertToFilters = (filter) => {
  if (filter) {
    const filters = [];
    Object.keys(filter).forEach((key) => {
      const val = filter[key].value;

      if ((val || (val === true || val === false)) && String(filter[key].value).length >= filter[key].minValue) {
        filters.push({
          field: filter[key].field,
          operator: filter[key].operator,
          value: filter[key].value,
        });
      }
    });
    return filters;
  } else {
    return [];
  }
};
