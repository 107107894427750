import React from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import CommentIcon from "@material-ui/icons/Comment";
import AddIcon from "@material-ui/icons/Add";
import AddCommentIcon from "@material-ui/icons/AddComment";
import moment from "moment";
import { createPrnOutcome } from "../../../../services/medication.order.service";
import {ObjectId} from "bson";

const useStyles = makeStyles((theme) => ({
  root: {
  },
  list: {
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
}));

const OutcomesList = ({ residentId, event, onSaveOutcome, canEdit, isNew = false }) => {
  const classes = useStyles();
  const [viewOutcomes, setViewOutcomes] = React.useState(false);
  const [addComment, setAddComment] = React.useState(false);
  const [newOutcome, setNewOutcome] = React.useState(null);

  const toggleViewOutcomes = () => {
    setViewOutcomes(!viewOutcomes);
  };

  const addNewComment = () => {
    setNewOutcome(null);
    setAddComment(true);
  };

  const onChange = (e) => {
    setNewOutcome(e.target.value);
  };

  const saveOutcome = async () => {
    if (!isNew) {
      const newItem = await createPrnOutcome(residentId, event._id, {
        comment: newOutcome,
      });
      setAddComment(false);
      onSaveOutcome(event._id, newItem);
    } else {
      setAddComment(false);
      const newItem = {
        _id: new ObjectId(),
        comment: newOutcome,
        entryDate: new Date()
      }
      onSaveOutcome(null, newItem);

    }
  };

  return (
    <div>
      <Button
        onClick={toggleViewOutcomes}
        startIcon={<CommentIcon />}
        size="small"
      >
        Outcomes ({event && event.outcomes ? event.outcomes.length : 0})
      </Button>

      <Dialog open={viewOutcomes} maxWidth="lg" fullWidth className={classes.root} onClose={() => setViewOutcomes(false)}>
        <DialogTitle id="max-width-dialog-title">Outcomes</DialogTitle>
        <Divider />
        <DialogContent dividers={true}>
          <List className={classes.list}>
            {event &&
              event.outcomes &&
              event.outcomes.map((outcome) => (
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar alt="Comment image">
                      <CommentIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={outcome.comment}
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          color="textPrimary"
                        >
                          <b>
                            {outcome && outcome.user ? outcome.user.name : null}
                          </b>
                        </Typography>
                        <Typography variant={"subtitle2"}>
                          {outcome?.entryDate ?
                            ` - ${moment(outcome.entryDate).format("MM/DD/YYYY hh:mm a")}` :
                            null
                          }
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              ))}
            {canEdit && !addComment && (
              <ListItem button onClick={addNewComment}>
                <ListItemAvatar>
                  <Avatar>
                    <AddIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Add comment" />
              </ListItem>
            )}
            {addComment && (
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar>
                    <AddCommentIcon />
                  </Avatar>
                </ListItemAvatar>
                <TextField
                  autoFocus
                  style={{ flex: 2 }}
                  id="outlined-multiline-static"
                  multiline
                  rows={4}
                  variant="outlined"
                  value={newOutcome}
                  onChange={onChange}
                />
              </ListItem>
            )}
          </List>
        </DialogContent>
        <Divider />
        <DialogActions>



          {addComment && (
            <React.Fragment>
              <Button onClick={() => setAddComment(false)}>Cancel</Button>
              <Button color="primary" variant="contained" onClick={saveOutcome}>
                Save
              </Button>
            </React.Fragment>
          )}
          {!addComment && (
            <React.Fragment>
              <Button onClick={() => setViewOutcomes(false)}>Close</Button>
            </React.Fragment>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OutcomesList;
