import React from 'react';
import {makeStyles, Tab, Tabs, Typography} from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  groupName: {
    width: '100%'
  },
  questionContainer: {
    width: '100%',
    paddingLeft: '2rem',
    display: 'flex',
    alignItems: 'center'
  },
  questionLabel: {
    marginRight: '1rem'
  },
  questionValue: {
  }
}));

const Preview = ({assessmentEntity, assessmentInstance }) => {
  const classes = useStyles();
  return (
    <>
      <Tabs
        variant="fullWidth">
        <Tab label={assessmentEntity} />
        <Tab label={`Asssement Name: ${assessmentInstance?.assessment?.name}`} />
        <Tab label={`Created: ${assessmentInstance?.created ? moment(assessmentInstance?.created).format('MM/DD/YYYY') : ''}`} />
        <Tab label={`Completed: ${assessmentInstance?.isComplete ? moment(assessmentInstance?.completedAt).format('MM/DD/YYYY') : 'N/A'}`} />
      </Tabs>
      {assessmentInstance?.questionGroups?.sort((a,b) => a?.sortOrder > b?.sortOrder ? 1: -1)?.map(group =>
      <div>
        <div className={classes.groupName}>
          <Typography variant="h6">{group.name}</Typography>
        </div>
        {group.questions && group.questions?.sort((a,b) => a?.sortOrder > b?.sortOrder ? 1: -1).map(question =>
          <div className={classes.questionContainer}>
            <div className={classes.questionLabel}>
              <Typography variant="subtitle2">{question.label}</Typography>
            </div>
            <div className={classes.questionValue}>
              <Typography variant="subtitle1">{question.value === 'false' ? 'false' : question.value}</Typography>
            </div>
          </div>
        )}
      </div>
      )}
    </>
  )

}

export default Preview;
