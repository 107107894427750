import React from "react";
import {
  Dialog,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  DialogContent,
  Link,
  makeStyles,
  DialogActions,
} from "@material-ui/core";
import Transition from "../dialog/transition";
import CloseIcon from "@material-ui/icons/Close";
import FormFieldsGenerator from "../../utils/forms/form.fields.generator";
import modelTemplate from './model';
import { getAllAddressTypes } from "../../services/address.service";


const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    paddingTop: "4rem",
  },
  dialogContent: {
    marginTop: "4rem",
  },
}));

const CreateInsurance = ({ open, onClose, onSave }) => {
  const classes = useStyles();
  const [addressTypes, setAddressTypes] = React.useState(null);
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(modelTemplate)));

  React.useEffect(() => {
    if (open) {
        initForm();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const initForm = async () => {
      const obj = JSON.parse(JSON.stringify(modelTemplate));
      if (!addressTypes || addressTypes.length === 0) {
        const types = await getAllAddressTypes();
        setAddressTypes(types);
        obj.addressType.dropDownItems = types;
      } else {
        obj.addressType.dropDownItems = addressTypes;
      }
      setModel(obj);
  }


  const onChange = e => {
      const obj = {...model, ...e};
      setModel(obj);
  }
  const save = () => {
      const data = {
          name: model.name.value,
          address: {
            address: model.address.value,
            address2: model.address2.value,
            city: model.city.value,
            state: model.state.value,
            zipCode: model.zipCode.value,
            addressType: model.addressType.value
          }
      }
      onSave(data);
  }

  const isValid = () => {
    let valid = true;
    Object.keys(model).forEach(key => {
      if (model[key].required && !model[key].value) {
        valid = false;
      }
    });
    return valid;
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => onClose()}
      TransitionComponent={Transition}
    >
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => onClose()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Create Insurance</Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.dialogContent}>
          <form>
              <FormFieldsGenerator model={model} onChange={onChange} />
          </form>
      </DialogContent>
      <DialogActions>
          <Link onClick={onClose}>Cancel</Link>
          <Button disabled={!isValid()} onClick={save} variant="contained" color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateInsurance;
