import React from 'react';
import {Button, Grid, Typography} from "@material-ui/core";
import history from "../../../utils/history";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";

import {makeStyles} from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  backBtn: {
    marginRight: ".5rem",
  },
  childrenContainer: {
    flex: 1,
    padding: '0 0 0 1rem'
  }
}));

export const PageTitle = ({title, children, displayBackButton = true, queryParam = null}) => {
  const classes = useStyles();
  return (
    <Grid container
          alignItems={"center"}
    >
      {displayBackButton &&
        <Button size={"small"}
                className={classes.backBtn}
                onClick={() => history.goBack()}
                startIcon={<NavigateBeforeIcon/>}
        >
          Back
        </Button>
      }
      <Typography variant={"h6"}>{title}</Typography>
      <div className={classes.childrenContainer}>
        {children}
      </div>
    </Grid>
  )
}
