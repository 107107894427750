import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  IconButton,
  makeStyles
} from "@material-ui/core";
import {VitalsPdf} from "./VitalsPdf";
import { PDFViewer } from '@react-pdf/renderer';
import MomentUtils from "@date-io/moment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment-timezone";
import {getVitalsReportData} from "../../../../../services/evital.service";
import {ResidentContext} from "../../../../../context/residentcontext";
import CloseIcon from "@material-ui/icons/Close";
import TableLoadingIndicator from "../../../../../utils/indicators/table.loading";


const useStyles = makeStyles((theme) => ({
  root: {
  },
  dialogContent: {
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export const VItalsPrintModal = ({open, onClose, residentId}) => {
  const classes = useStyles();
  const [startDate, setStartDate] = React.useState(moment().subtract(7, 'days'));
  const [endDate, setEndDate] = React.useState(moment());
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const {resident} = React.useContext(ResidentContext);

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      setError(false);
      const response = await getVitalsReportData(residentId, startDate, endDate)
      if (!response?.error) {
        setData(response);
      } else {
        setError(true);
      }
      setLoading(false);
    }


    if (residentId && open && moment(startDate).isValid() && moment(endDate).isValid()) {
      getData();
    }
  }, [residentId, open, startDate, endDate]);

  const onStartDateChange = date => {
    setData(null);
    setStartDate(date);
  }

  const onEndDateChange = date => {
    setData(null)
    setEndDate(date);
  }


  return (
    <Dialog open={open}
            maxWidth={"lg"}
            fullWidth
            onClose={onClose}>
      <DialogTitle>
        {"Vitals Report"}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers style={{minHeight: '70vh'}}>
        {!error && loading &&
          <TableLoadingIndicator cols={10} rows={10} />
        }
        {!error && !loading && data &&
          <PDFViewer style={{width: '100%', height: '70vh'}}>
            <VitalsPdf startDate={startDate}
                       endDate={endDate}
                       resident={resident}
                       vitals={data}
            />
          </PDFViewer>
        }
      </DialogContent>
      <DialogActions>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <FormGroup row={true}>
            <KeyboardDatePicker
              label={"Start"}
              value={startDate}
              onChange={onStartDateChange}
              format="MM/DD/YYYY" />
            <KeyboardDatePicker
              value={endDate}
              label={"End"}
              onChange={onEndDateChange}
              format="MM/DD/YYYY" />
          </FormGroup>
        </MuiPickersUtilsProvider>
      </DialogActions>
    </Dialog>
  )
}
