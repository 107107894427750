import React from 'react';
import {Avatar, Grid, makeStyles, Paper, Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  avatar: {
    background: theme.palette.warning.main,
    marginRight: theme.spacing(2)
  }
}))

const InactivePage = () => {
  const classes = useStyles();

  React.useEffect(() => {}, [])
  return (
      <Paper elevation={24}
             className={classes.root}
      >
        <Grid container
              justify={"center"}
              alignItems={"center"}
        >
          <Avatar className={classes.avatar}>

          </Avatar>
          <div>
            <Typography variant={"h6"}>Your account has been deactivated</Typography>
            <Typography variant={"p"}>Please contact your system admin to reactivate your account.</Typography>
          </div>
        </Grid>
      </Paper>
  )
}

export default InactivePage;
