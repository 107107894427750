import React from "react";
import {convertToRaw, EditorState} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import {Button} from "@material-ui/core";
import draftToHtml from "draftjs-to-html";

const NewComment = ({onSave, onCancel, setFocus}) => {
  const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());

  const editorRef = React.useRef();

  React.useEffect(() => {
    if (setFocus && editorRef) {
      editorRef.current.focusEditor();
    }
  },[ setFocus ]);

  const onChange = e => {
    setEditorState(e);
  }
  const hasText = () => {
    return editorState?.getCurrentContent()?.hasText();
  }

  const save = () => {
    const message = convertToRaw(editorState.getCurrentContent());
    const payload = {
      message,
      text: editorState.getCurrentContent().getPlainText(),
      html: draftToHtml(message)
    }
    onSave(payload);
    setEditorState(EditorState.createEmpty());
  }

  return (
    <div style={{padding: '1rem'}}>
      <Editor editorState={editorState}
              onEditorStateChange={text => onChange(text)}
              placeholder={"Add Comment"}
              ref={editorRef}
              hashtag={{
                separator: ' ',
                trigger: '#',
              }}
      />
      <Button variant={"contained"}
              size={"small"}
              disabled={!hasText()}
              onClick={save}
              color={"primary"}>Comment</Button>
      <Button size={"small"}
              onClick={onCancel}
              color={"primary"}>Cancel</Button>
    </div>
  )
}

export default NewComment;
