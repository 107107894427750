import React from 'react';
import {Button, Dialog, DialogActions, DialogContent} from "@material-ui/core";
import {SsaDialogTitle} from "../../../../utils/dialogs/SsaDialogTItle";
import {FormFieldsGenerator, isModelValid} from "../../../../../utils/forms";

const MODEL = {
  label: {
    title: 'Label',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  fileType: {
    title: 'Type',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'name',
    dropDownItemLabel: 'name',
    date: false,
    edit: false
  },
  tags: {
    title: 'Tags',
    isTagsComponent: true,
    hidden: false,
    value: [],
    required: false,
  },
}


export const EditFileDialog = ({file, open, onSave, onClose}) => {
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(MODEL)));

  React.useEffect(() => {
    if (open && file) {
      const obj = JSON.parse(JSON.stringify(MODEL));
      obj.label.value = file?.label;
      obj.fileType.value = file?.fileType;
      obj.tags.value = file?.tags;
      setModel(obj);
    }
  },[open, file]);

  const onChange = (e) => {
    setModel({...model, ...e});
  }

  const handleSave = () => {
    const data = {}
    Object.keys(model).forEach(key => {
      data[key] = model[key].value;
    });
    onSave(data);
  }

  return (
    <Dialog maxWidth={"md"} fullWidth={true} open={open} onClose={onClose}>
      <SsaDialogTitle onClick={onClose}>
        Edit file metadata
      </SsaDialogTitle>
      <DialogContent>
      <FormFieldsGenerator model={model} onChange={onChange} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button disabled={!isModelValid(model)}
                variant={"contained"}
                color={"primary"}
                onClick={handleSave}>
            Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
