import React from 'react';
import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, KeyboardTimePicker, TimePicker } from "@material-ui/pickers";


const useStyles = makeStyles((theme) => ({
  mainContent: {
    textAlign: "center"
  },
  clockBtnContainer: {
    padding: "2rem",
    textAlign: "center"
  },
  clockInBtn: {
    height: "5rem"
  }
}));

export const TimeEntryForm = ({user, onSave, onCancel, clockInOnly = true, isMobile = false, initalDate = moment()}) => {
  const classes = useStyles();
  const [start, setStart] = React.useState(moment());
  const [end, setEnd] = React.useState();
  const [comment, setComment] = React.useState();

  React.useEffect(() => {
    if (initalDate) {
      // Initial Date is in UTC, but we need only the year, day, month part of it.
      let utcInitalDate = moment(initalDate)?.utc();
      let date = moment();
      date.set({year: utcInitalDate.year(), month: utcInitalDate.month(), day: utcInitalDate.day()});
      let now = moment();
      date.set({hour: now.hour(), minute: now.minute(), millisecond: now.millisecond()});
      setStart(date);
    }
  },[initalDate]);

  const onCommentChange = event => {
    setComment(event.target.value);
  }

  const handleSave = () => {
    onSave({
      start,
      end,
      comment
    });
  }

  const onClockInChange = (date) => {
    setStart(date);
  }

  const onClockOutChange = (date) => {
    setEnd(date);
  }

  const isValid = () => {
    return moment(start).isValid();
  }

  return (
    <div>
      <div className={classes.mainContent}>
        <Typography variant={"h2"}>Hello</Typography>
        <Typography variant={"h6"}>{user?.firstName} {user?.lastName}</Typography>
        <Typography variant={"h6"}>
          {start?.format("MMM DD, YYYY hh:mm a")}
        </Typography>
        <Typography variant={"subtitle2"}>
          Click to clock into time clock system.
        </Typography>
      </div>

      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container>
          <Grid item xs={clockInOnly ? 12: 6}>
            {!isMobile &&
            <KeyboardTimePicker
              fullWidth
              mask="__:__ _M"
              label={"Clock In"}
              value={start}
              name={"start"}
              disabled
              onChange={onClockInChange}
              style={{marginTop: '1rem', marginBottom: '1rem'}}
            />
            }
            {isMobile &&
                <TimePicker
                    fullWidth
                    mask="__:__ _M"
                    label={"Clock In"}
                    value={start}
                    name={"start"}
                    disabled
                    onChange={onClockInChange}
                    style={{marginTop: '1rem', marginBottom: '1rem'}}
                />
            }
          </Grid>
          {!clockInOnly && !isMobile &&
            <Grid item xs={6}>
              <KeyboardTimePicker
                fullWidth
                mask="__:__ _M"
                label={"Clock Out"}
                value={end}
                name={"end"}
                onChange={onClockOutChange}
                style={{marginTop: '1rem', marginBottom: '1rem'}}
              />
            </Grid>
          }
        </Grid>
      </MuiPickersUtilsProvider>

      <TextField fullWidth
                 variant={"outlined"}
                 minRows={10}
                 multiline
                 value={comment}
                 onChange={onCommentChange}
                 label={"Comment"} />
      <div className={classes.clockBtnContainer}>
        <Button variant={"text"}
                style={{marginRight: '1rem', width: '7rem'}}
                onClick={onCancel}
                className={classes.clockInBtn}>
          Cancel
        </Button>
        <Button variant={"contained"}
                className={classes.clockInBtn}
                color={"primary"}
                disabled={!isValid()}
                onClick={handleSave}
                size={"large"}>
          Clock In
        </Button>
      </div>
    </div>
  )
}
