import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserId, setUserProperties } from "firebase/analytics";
import { getPerformance } from 'firebase/performance';
import configs from "../configs";

const firebaseConfig = {
  apiKey: configs.analytics.apiKey,
  authDomain: configs.analytics.authDomain,
  databaseURL: configs.analytics.databaseUrl,
  projectId: configs.analytics.projectId,
  storageBucket: configs.analytics.storageBucket,
  messagingSenderId: configs.analytics.messagingSenderId,
  appId: configs.analytics.appId,
  measurementId: configs.analytics.measurementId
};

let analytics;
let perf;

export const initAnalytics = () => {
  const app = initializeApp(firebaseConfig);
  analytics = getAnalytics(app);
  perf = getPerformance(app);
  return analytics;
}

export const getAnalyticsPerformance = () => {
  if (!perf) {
    const app = initializeApp(firebaseConfig);
    perf = getPerformance(app);
  }
  return perf;
}

export const logAnalyticsEvent = (eventName, details) => {
  if (!analytics) {
    const app = initializeApp(firebaseConfig);
    analytics = getAnalytics(app);
  }
  logEvent(analytics, eventName, details)
}

export const setAnalyticsUserId = userId => {
  if (!analytics) {
    const app = initializeApp(firebaseConfig);
    analytics = getAnalytics(app);
  }
  setUserId(analytics, userId);
}


export const setAnalyticsUserProperties = props => {
  if (!analytics) {
    const app = initializeApp(firebaseConfig);
    analytics = getAnalytics(app);
  }
  setUserProperties(analytics, props);
}
