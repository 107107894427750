import React from 'react';
import { Image, View, Text, StyleSheet } from '@react-pdf/renderer'
import moment from 'moment';
import config from "../../../../utils/configs"

const styles = StyleSheet.create({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '10vh',
    backgroundColor: '#fff',
    borderTop: '1px solid #bfbfbf'
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  infoContainer: {
    flex: 1,
    padding: '0 2px'
  },
  image: {
    height: '64px',
    width: '64px'
  },
  largeText: {
    fontSize: '14px'
  },
  mediumText: {
    fontSize: '12px'
  },
  smallText: {
    fontSize: '10px'
  },
  xsmallText: {
    fontSize: '8px'
  },
  allergyContainer: {
    paddingBottom: 1,
  },
  allergyText: {
    fontSize: '8px',
    textOverflow: 'ellipsis',
    lineHeight: 1
  },
  xsmallTextComment: {
    fontSize: '8px',
    height: 10,
  },
  dietContainer: {
    paddingTop: 1
  },
  dietCommentText: {
    fontSize: 8,
    maxHeight: 20
  }
})

const displayPhoneNumber = (number) => {
  if (number) {
    const phone = number.replace(/[^\d]/g, "");

    //check if number length equals to 10
    if (phone.length === 10) {
      //reformat and return phone number
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    }

    return phone;
  } else {
    return '';
  }
}

const ResidentCard = ({resident}) => {

  const buildResidentImgUrl = () => {
    const url = `${config.publicBucketUrl}/${config.publicBucketName}/tenants/${resident?.location?.tenant?._id}/residents/${resident?._id}/photo/${resident?.imageUrl}`
    console.log(url);
    return url;
  }

  const displayResidentAddress = () => {
    return displayAddress(resident?.addresses);
  }

  const displayAddress = (addresses) => {
    if (addresses?.length > 0) {
      const address = addresses[0];
      return `${address?.address} ${address?.address2 ? address.address2: ''} ${address?.city}, ${address?.state} ${address?.zipCode}`
    } else {
      return '';
    }
  }

  const getPrimaryPhysicianNumber = () => {

    const primaryPhysician = resident?.residentPhysicians?.find(rp => rp?.isPrimary);
    if (primaryPhysician) {
      const phones = primaryPhysician?.physician?.phones;
      if (phones?.length === 1) {
        const ph = phones[0]
        return (
          <Text style={styles.xsmallText}>Physician Phone: {displayPhoneNumber(ph?.number)}</Text>
        )
      } else {
        const mainPhone = primaryPhysician?.physician?.phones?.find(p => p.phoneType === 'Main');
        if (mainPhone) {
          return (
            <Text style={styles.xsmallText}>Physician Phone: {displayPhoneNumber(mainPhone?.number)}</Text>
          )
        }
      }
    }
    return null;
  }

  const displayPrimaryPhysician = () => {
    const primary = resident?.residentPhysicians.find(resPhy => resPhy.isPrimary);
    const primaryPharmacy = resident?.residentPharmacies.find(resPhar => resPhar.isPrimary);
    return (
      <View>
        <Text style={styles.xsmallText}>Primary Physician: {primary?.physician?.title} {primary?.physician?.firstName} {primary?.physician?.lastName}</Text>
        <View>
          {getPrimaryPhysicianNumber()}
        </View>
        {primaryPharmacy &&
        <View>
          <Text style={styles.xsmallText}>
            Pharmacy: {primaryPharmacy?.pharmacy?.name}
          </Text>
        </View>
        }
        {getAdmitDate()}
        <Text style={styles.xsmallText}>Code Statuses: {resident?.codeStatus?.map(n => n).join(', ')}</Text>
      </View>
    )
  }

  const getAdmitDate = () => {
    if (resident?.admissions?.length > 0) {
      const admissions = resident?.admissions?.filter(admission => admission?.type === "Admission");
      if (admissions?.length > 1) {
        const admission = admissions?.sort((a, b) => a?.date > b?.date ? -1 : 1)[0];
        return (
          <Text style={styles.xsmallText}>Date of Admission: {moment(admission?.date).format("M/D/YYYY")}</Text>
        )
      } else if (admissions?.length === 1) {
        const admission = admissions[0];
        return (
          <Text style={styles.xsmallText}>Date of Admission: {moment(admission?.date).format("M/D/YYYY")}</Text>
        )
      }
    }
    return null;
  }

  const medicalDetails = () => {

    return (
      <View>
        <Text style={styles.xsmallText}>Diagnosis: {resident?.diagnosis?.map(diag => diag?.diagnosis?.code).join(', ')}</Text>
        <View>
          <Text style={styles.allergyText}>Allergies: {resident?.allergies?.map(allery => allery?.name).join(', ')}</Text>
        </View>
        <View style={styles.dietContainer}>
          <Text style={styles.xsmallText}>Diet: {resident?.diet?.join(", ")}</Text>
        </View>
        {resident?.dietComment &&
          <View>
            <Text style={styles.dietCommentText}>
              Comment: {resident?.dietComment}
            </Text>
          </View>
        }
      </View>
    )
  }



  return (
    <View style={styles.root}>
      <Image src={buildResidentImgUrl()} style={styles.image} />
      <View style={styles.infoContainer}>
        <Text style={[styles.smallText, { margin: '1px 0'}]}>
          {resident?.firstName} {resident?.lastName}
        </Text>
        <Text style={[styles.xsmallText, { margin: '1px 0'}]}>
          Address: {displayResidentAddress()}
        </Text>
        <Text style={[styles.xsmallText, { margin: '1px 0'}]}>
          Phone: {resident?.phones?.map(phone => `${phone.phoneType}: ${displayPhoneNumber(phone.number)}`).join(', ')}
        </Text>
        <Text style={[styles.xsmallText, { margin: '1px 0'}]}>
          DOB: {moment(resident?.dob).format('MM/DD/YYYY')}
        </Text>
        <Text style={[styles.xsmallText]}>
          Gender: {resident?.gender}
        </Text>
      </View>
      <View style={ { flex: 1, padding: '3px 0' }}>
        {displayPrimaryPhysician()}
      </View>
      <View style={ { flex: 1, padding: '3px 0' }}>
        {medicalDetails()}
      </View>
    </View>
  )
}

export default ResidentCard;
