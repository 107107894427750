import {
    createCareplan,
    createGoal, createIntervention, deleteGoal, deleteIntervention, deleteResidenCareplan,
    getCareplanReports, getGoal, getIntervention,
    getResidenCareplans,
    getResidentCareplan, syncInterventionWithGoal, updateGoal, updateIntervention,
    updateResidenCareplan
} from "../../../../services/careplan.service";
import {ACTIONS} from "./constants";

export const getCareplansAction = async (dispatch, residentId, status) => {
    dispatch({type: ACTIONS.SET_ERROR, payload: false});
    dispatch({type: ACTIONS.SET_LOADING, payload: false});
    const statusValue = status === 'current' ? true: status === 'all' ? null: false;
    const response = await getResidenCareplans(residentId, statusValue)
    if (!response?.error) {
        dispatch({type: ACTIONS.SET_CAREPLANS, payload: response});
    } else {
        dispatch({type: ACTIONS.SET_ERROR, payload: true});
    }
    dispatch({type: ACTIONS.SET_LOADING, payload: false});
}

export const toggleCareplanAction = (dispatch, careplans, careplan) => {
    const cps = careplans?.map(cp => {
        if (cp._id === careplan?._id) {
            cp.checked = !cp.checked;
        }
        return cp;
    });
    dispatch({type: ACTIONS.SET_CAREPLANS, payload: cps});
}

export const toggleAllCareplanAction = (dispatch, careplans, selectAll) => {
    const cps = careplans?.map(c => {
            c.checked = selectAll;
            return c;
        })
    dispatch({type: ACTIONS.SET_CAREPLANS, payload: cps});
}

export const printCareplansAction = async (dispatch, resident, careplans) => {
    dispatch({type: ACTIONS.SET_GENERATING_PDF, payload: true});
    const careplanIds = careplans?.filter(c => c.checked)?.map(c => c._id);
    const response = await getCareplanReports(resident?._id, careplanIds);
    let responseStatus = {error: false};
    if (!response?.error) {
        const link = document.createElement('a');
        const pdf = URL.createObjectURL(response);
        link.href = pdf;
        link.setAttribute(
            'download',
            `${resident?.firstName}-${resident?.lastName}-careplans.pdf`,
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    } else {
        responseStatus.error = true;
    }
    dispatch({type: ACTIONS.SET_GENERATING_PDF, payload: false});
    return responseStatus;
}

export const getCareplanAction = async (dispatch, residentId, careplanId) => {
    dispatch({type: ACTIONS.SET_LOADING, payload: true});
    dispatch({type: ACTIONS.SET_ERROR, payload: false});
    const response = await getResidentCareplan(residentId, careplanId);
    if (!response?.error) {
        dispatch({type: ACTIONS.SET_CAREPLAN, payload: response});
    } else {
        dispatch({type: ACTIONS.SET_ERROR, payload: true});
    }
    dispatch({type: ACTIONS.SET_LOADING, payload: false});
}

export const updateCareplanAction = async (dispatch, residentId, careplanId, careplan) => {
    dispatch({type: ACTIONS.SET_UPDATING_CAREPLAN, payload: true});
    dispatch({type: ACTIONS.SET_ERROR, payload: false});
    const response = await updateResidenCareplan(residentId, careplanId, careplan);
    if (!response?.error) {
        dispatch({type: ACTIONS.SET_CAREPLAN, payload: response});
    } else {
        dispatch({type: ACTIONS.SET_ERROR, payload: true});
    }
    dispatch({type: ACTIONS.SET_UPDATING_CAREPLAN, payload: false});
}

export const createGoalAction = async (dispatch, residentId, careplanId, goal) => {
    dispatch({type: ACTIONS.SET_LOADING, payload: true});
    dispatch({type: ACTIONS.SET_ERROR, payload: false});
    const response = await createGoal(residentId, careplanId, goal);
    if (!response?.error) {
        dispatch({type: ACTIONS.SET_VIEW_CREATE_GOAL_DIALOG, payload: false});
        dispatch({type: ACTIONS.ADD_GOAL, payload: response});
    } else {
        dispatch({type: ACTIONS.SET_ERROR, payload: true});
    }
    dispatch({type: ACTIONS.SET_LOADING, payload: false});
}

export const viewCreateInterventionDialogAction = dispatch => {
    dispatch({type: ACTIONS.SET_VIEW_CREATE_INTERVENTION_DIALOG, payload: true})
}

export const createInterventionAction = async (dispatch, residentId, careplanId, goalId, intervention) => {
    dispatch({type: ACTIONS.SET_LOADING, payload: true});
    dispatch({type: ACTIONS.SET_ERROR, payload: false});
    const response = await createIntervention(residentId, careplanId, goalId, intervention);
    if (!response?.error) {
        dispatch({type: ACTIONS.SET_VIEW_CREATE_INTERVENTION_DIALOG, payload: false});
        dispatch({type: ACTIONS.ADD_INTERVENTION, payload: {careplanId, goalId, intervention: response}});
    } else {
        dispatch({type: ACTIONS.SET_ERROR, payload: true});
    }
    dispatch({type: ACTIONS.SET_LOADING, payload: false});
    return response;
}

export const updateInterventionAction = async (dispatch, residentId, careplanId, goalId, interventionId, intervention) => {
    dispatch({type: ACTIONS.SET_UPDATING_INTERVENTION, payload: true});
    dispatch({type: ACTIONS.SET_ERROR, payload: false});
    const response = await updateIntervention(residentId, careplanId, goalId, interventionId, intervention);
    if (!response?.error) {
        dispatch({type: ACTIONS.SET_VIEW_CREATE_INTERVENTION_DIALOG, payload: false});
        dispatch({type: ACTIONS.UPDATE_INTERVENTION, payload: {careplanId, goalId, intervention: response}});
    } else {
        dispatch({type: ACTIONS.SET_ERROR, payload: true});
    }
    dispatch({type: ACTIONS.SET_UPDATING_INTERVENTION, payload: false});
    return response;
}

export const deleteInterentionAction = async (dispatch, residentId, careplanId, goalId, interventionId) => {
    dispatch({type: ACTIONS.SET_LOADING, payload: true});
    dispatch({type: ACTIONS.SET_ERROR, payload: false});
    const response = await deleteIntervention(residentId, careplanId, goalId, interventionId);
    if (!response?.error) {
        dispatch({type: ACTIONS.REMOVE_INTERVENTION, payload: {careplanId, goalId, interventionId }});
        dispatch({type: ACTIONS.SET_VIEW_INTERVENTION_DIALOG, payload: false});
    }
    dispatch({type: ACTIONS.SET_LOADING, payload: false});
}

export const updateGoalAction = async (dispatch, residentId, careplanId, goalId, goal) => {
    let success = false;
    dispatch({type: ACTIONS.SET_UPDATING_GOAL, payload: true});
    dispatch({type: ACTIONS.SET_ERROR, payload: false});
    const response = await updateGoal(residentId, careplanId, goalId, goal);
    if (!response?.error) {
        dispatch({type: ACTIONS.UPDATE_GOAL, payload: response});
        success = true;
    } else {
        dispatch({type: ACTIONS.SET_ERROR, payload: true});
    }
    dispatch({type: ACTIONS.SET_UPDATING_GOAL, payload: false});
    return success;
}

export const deleteGoalAction = async (dispatch, residentId, careplanId, goalId) => {
    let success = false;
    dispatch({type: ACTIONS.SET_UPDATING_GOAL, payload: true});
    dispatch({type: ACTIONS.SET_ERROR, payload: false});
    const response = await deleteGoal(residentId, careplanId, goalId);
    if (!response?.error) {
        dispatch({type: ACTIONS.REMOVE_GOAL, payload: {careplanId, goalId}});
        success = true;
    } else {
        dispatch({type: ACTIONS.SET_ERROR, payload: true});
    }
    dispatch({type: ACTIONS.SET_UPDATING_GOAL, payload: false});
    return success;
}

export const selectCareplanForPrintAction = (dispatch, careplan) => {
    dispatch({type: ACTIONS.TOGGLE_CAREPLAN_PRINT, payload: careplan});
}

export const toggleCareplanOpenAction = (dispatch, careplan) => {
    dispatch({type: ACTIONS.TOGGLE_CAREPLAN_OPEN, payload: careplan});
}

export const toggleGoalOpenAction = (dispatch, careplan, goal) => {
    dispatch({type: ACTIONS.TOGGLE_GOAL_OPEN, payload: {careplan, goal}});
}

export const createCareplanAction = async (dispatch, residentId, careplan) => {
    dispatch({type: ACTIONS.SET_LOADING, payload: true});
    dispatch({type: ACTIONS.SET_ERROR, payload: false});
    const response = await createCareplan(residentId, careplan);
    if (!response?.error) {
        dispatch({type: ACTIONS.ADD_CAREPLAN, payload: response});
        dispatch({type: ACTIONS.SET_VIEW_CREATE_CAREPLAN_DIALOG, payload: false});
    } else {
        dispatch({type: ACTIONS.SET_ERROR, payload: true});
    }
    dispatch({type: ACTIONS.SET_LOADING, payload: false});
}

export const loadGoalAction = async (dispatch, residentId, careplanId, goalId) => {
    dispatch({type: ACTIONS.SET_LOADING_GOAL, payload: true});
    dispatch({type: ACTIONS.SET_ERROR, payload: false});
    const response = await getGoal(residentId, careplanId, goalId);
    if (!response?.error) {
        dispatch({type: ACTIONS.SET_CAREPLAN, payload: response?.careplan});
        dispatch({type: ACTIONS.SET_GOAL, payload: response?.goal});
    } else {
        dispatch({type: ACTIONS.SET_ERROR, payload: true});
    }
    dispatch({type: ACTIONS.SET_LOADING_GOAL, payload: false});
}

export const loadInterventionAction = async (dispatch, residentId, careplanId, goalId, interventionId) => {
    dispatch({type: ACTIONS.SET_LOADING_INTERVENTION, payload: true});
    dispatch({type: ACTIONS.SET_ERROR, payload: false});
    const response = await getIntervention(residentId, careplanId, goalId, interventionId);
    if (!response?.error) {
        dispatch({type: ACTIONS.SET_CAREPLAN, payload: response?.careplan});
        dispatch({type: ACTIONS.SET_GOAL, payload: response?.parentGoal});
        dispatch({type: ACTIONS.SET_INTERVENTION, payload: response?.intervention});
    } else {
        dispatch({type: ACTIONS.SET_ERROR, payload: true});
    }
    dispatch({type: ACTIONS.SET_LOADING_INTERVENTION, payload: false});
}

export const syncInterventionToGoalAction = async (dispatch, residentId, careplanId, interventionId) => {
    dispatch({type: ACTIONS.SET_LOADING_INTERVENTION, payload: true});
    dispatch({type: ACTIONS.SET_ERROR, payload: false});
    const response = await syncInterventionWithGoal(residentId, careplanId, interventionId);
    if (!response?.error) {
        dispatch({type: ACTIONS.SET_CAREPLAN, payload: response?.careplan});
        dispatch({type: ACTIONS.SET_GOAL, payload: response?.goal});
        dispatch({type: ACTIONS.SET_INTERVENTION, payload: response?.intervention});
    } else {
        dispatch({type: ACTIONS.SET_ERROR, payload: true});
    }
    dispatch({type: ACTIONS.SET_LOADING_INTERVENTION, payload: false});
}

export const deleteCareplanAction = async (dispatch, residentId, careplanId) => {
    let result = {};
    dispatch({type: ACTIONS.SET_LOADING, payload: true});
    dispatch({type: ACTIONS.SET_ERROR, payload: false});
    const response = await  deleteResidenCareplan(residentId, careplanId);
    if (!response?.error) {
        dispatch({type: ACTIONS.REMOVE_CAREPLAN, payload: careplanId});
        dispatch({type: ACTIONS.SET_CAREPLAN, payload: null});
        dispatch({type: ACTIONS.SET_GOAL, payload: null});
        dispatch({type: ACTIONS.SET_INTERVENTION, payload: null});
        result.error = false;
    } else {
        dispatch({type: ACTIONS.SET_ERROR, payload: true});
        result.error = true;
    }
    dispatch({type: ACTIONS.SET_LOADING, payload: false});
    return result;
}
