import React from 'react';
import {Button, Grid, Paper} from "@material-ui/core";
import {PageTitle} from "../../../../utils";
import MomentUtils from "@date-io/moment";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment-timezone";
import {VItalsPrintModal} from "./VItalsPrintModal";


export const VitalsPrint = () => {
  const [viewModal, setViewModel] = React.useState(false);
  const [startDate, setStartDate] = React.useState(moment());
  const [endDate, setEndDate] = React.useState(moment().add(7, 'days'));

  const onStartDateChange = date => {
    if (moment(date).isValid()) {
      setStartDate(date);
    }
  }

  const onEndDateChange = date => {
    if (moment(date).isValid()) {
      setEndDate(date);
    }
  }

  return (
    <Paper elevation={24}>
      <PageTitle title={"Vitals Report"} />
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid container alignItems={"center"} style={{padding: 10}}>
              <Grid item>
                <KeyboardDateTimePicker
                  label={"Start"}
                  value={startDate}
                  onChange={onStartDateChange}
                  format="MM/DD/YYYY hh:mm a" />
              </Grid>
              <Grid item>
                <KeyboardDateTimePicker
                  value={endDate}
                  label={"End"}
                  onChange={onEndDateChange}
                  format="MM/DD/YYYY hh:mm a" />
              </Grid>
              <Grid item>
                <Button onClick={() => setViewModel(true)}>Print</Button>
              </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      <VItalsPrintModal open={viewModal} onClose={() => setViewModel(false)} startDate={startDate} endDate={endDate} />
    </Paper>
  )
}
