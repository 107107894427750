import React from 'react';
import {StepActions} from "../step.actions";
import {IntakeContext} from "../../context/intake.context";
import {useStepStyles} from "../styles";
import {useHistory} from "react-router-dom";
import ResidentAssessments from "../../../../assessment/resident.assessments";
import {LoadingIntake} from "../../../assessment/loading.intake";
import Assessment from "../../../../../assessment/assessment";
import {setStepStatus} from "../../context/intake.actions";
import {getResidentAssessment} from "../../../../../../services/assessment.service";



export const AssessmentStep = () => {
  const [resident, setResident] = React.useState({});
  const { dispatch, state } = React.useContext(IntakeContext);
  // eslint-disable-next-line
  const [currentAssessment, setCurrentAssessment] = React.useState(null);
  const [loadingIntake, setLoadingIntake] = React.useState(true);
  const [updatePayload, setUpdatePayload] = React.useState(null);
  const [assessmentEntity, setAssessmentEntity ]= React.useState(null);
  const classes = useStepStyles();
  const history = useHistory();

  React.useEffect(() => {
    setLoadingIntake(true);
  }, []);
  //
  // const init = async () => {
  //   setLoadingIntake(true);
  //   const x = await getResidentAssessment(state?.resident?._id, null);
  //   setCurrentAssessment(x);
  //   setLoadingIntake(false);
  // }

  React.useEffect(() => {
    setLoadingIntake(false);
  },[]);

  React.useEffect(() => {
    if (state?.resident?._id) {
      setResident({ id: state?.resident?._id,
        firstName: state?.resident?.firstName,
        lastName: state?.resident?.lastName
      });
      setAssessmentEntity(`${state?.resident?.firstName} ${state?.resident?.lastName}`)
    }
  }, [state?.resident?._id, state?.resident?.firstName, state?.resident?.lastName ])

  const onViewAssessment = async assessment => {
    setLoadingIntake(true);
    const x = await getResidentAssessment(
      state?.resident?._id,
      assessment._id
    );
    setCurrentAssessment(x);
    setLoadingIntake(false);
  };

  const onAssessmentUpdate = update => {
    const obj = {type: 'COMPLETION_UPDATE', payload: { assessmentInstanceId: update._id, completedAt: update.completedAt }};
    setUpdatePayload(obj);
  };

  const onNext = () => {
    setStepStatus(dispatch, "assessment", true);
    history.push(`/intake/${state.id}/summary`);
  }

    return (
      <div>
        {resident?.id &&
          <div>
            <ResidentAssessments
              assessmentName="Resident Intake Assessment"
              residentId={resident?.id}
              resident={resident}
              onViewAssessment={onViewAssessment}
              updatePayload={updatePayload}
            />
            {currentAssessment?.questionGroups && (
              <React.Fragment>
                <LoadingIntake loading={loadingIntake}/>
                {!loadingIntake && (
                  <Assessment
                    assessmentInstance={currentAssessment}
                    assessmentEntity={assessmentEntity}
                    onAssessmentUpdate={onAssessmentUpdate}
                  />
                )}
              </React.Fragment>
            )}
          </div>
        }
        <div className={classes.btnContainer}>
          <StepActions onNext={onNext}
                       onPrev={() => history.push(`/intake/${state.id}/contacts`)}
          />
        </div>
      </div>
    )
}
