const modelTemplate = {
    tradeName: {
        title: "Name",
        required: true,
        value: null,
        dirty: false,
        isValid: true,
        multiSelect: false,
        dropDown: false,
        dropDownItems: [],
        dropDownItemValue: "value",
        dropDownItemLabel: "name",
        date: false
    },
    genericName: {
        title: "Generic Name",
        required: false,
        value: null,
        dirty: false,
        isValid: true,
        multiSelect: false,
        dropDown: false,
        dropDownItems: [],
        dropDownItemValue: "value",
        dropDownItemLabel: "name",
        date: false
    },
    strength: {
        title: "Strength",
        required: false,
        value: null,
        dirty: false,
        isValid: true,
        multiSelect: false,
        dropDown: false,
        dropDownItems: [],
        dropDownItemValue: "value",
        dropDownItemLabel: "name",
        date: false
    },
    unit: {
      title: "Unit",
      required: false,
      value: null,
      dirty: false,
      isValid: true,
      multiSelect: false,
      dropDown: true,
      dropDownItems: [],
      dropDownItemValue: "_id",
      dropDownItemLabel: "description",
      date: false
    },
    ndcCode: {
        title: "NDC Code",
        required: false,
        value: null,
        dirty: false,
        isValid: true,
        multiSelect: false,
        dropDown: false,
        dropDownItems: [],
        dropDownItemValue: "value",
        dropDownItemLabel: "name",
        date: false
    },
    isSupply: {
        title: "Is Supply",
        required: false,
        value: null,
        dirty: false,
        isValid: true,
        multiSelect: false,
        dropDown: true,
        dropDownItems: [{ name: 'Yes', value: 'true' }, { name: 'No', value: 'false' }],
        dropDownItemValue: "value",
        dropDownItemLabel: "name",
        date: false
    },
    isNarcotic: {
        title: "Is Narcotic",
        required: false,
        value: null,
        dirty: false,
        isValid: true,
        multiSelect: false,
        dropDown: true,
        dropDownItems: [{ name: 'Yes', value: 'true' }, { name: 'No', value: 'false' }],
        dropDownItemValue: "value",
        dropDownItemLabel: "name",
        date: false
    },
    dosageForm: {
        title: "Dosage Form",
        required: false,
        value: null,
        dirty: false,
        isValid: true,
        multiSelect: false,
        dropDown: true,
        dropDownItems: [],
        dropDownItemValue: "_id",
        dropDownItemLabel: "name",
        date: false
    },
    route: {
        title: "Route",
        required: false,
        value: null,
        dirty: false,
        isValid: true,
        multiSelect: false,
        dropDown: true,
        dropDownItems: [],
        dropDownItemValue: "_id",
        dropDownItemLabel: "name",
        date: false
    },
    isActive: {
        title: "Is Active",
        required: false,
        value: null,
        dirty: false,
        isValid: true,
        multiSelect: false,
        dropDown: true,
        dropDownItems: [{ name: 'Yes', value: 'true' }, { name: 'No', value: 'false' }],
        dropDownItemValue: "value",
        dropDownItemLabel: "name",
        date: false
    }}
export default modelTemplate;
