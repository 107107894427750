import React from 'react';
import Section from "./section";
import Question from "./question";

const AssessmentObject = ({assessmentObject, canUpdate = true}) => {
  if (assessmentObject?.type === 'section') {
    return (
      <Section section={assessmentObject} canUpdate={canUpdate} />
    )
  } else if (assessmentObject?.objectType === 'question') {
    return (
      <Question question={assessmentObject} canUpdate={canUpdate} />
    )
  } else {
    return null;
  }
}

export default AssessmentObject;
