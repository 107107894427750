import apiUtil from "../utils/api.utils";
import config from "../utils/configs";
import { parseFilters, parseFiltersToJson } from "../utils/filter.utils";
const coreApiUrl = `${config.medicationOrderService}/api/v1.0`;
const medicationApiUrl = `${config.medicationOrderService}/api/v1.0/medications`;

export const getMedicationUnits = async (
  page = 0,
  size = 25,
  filters = [],
  sort = "name",
  sortDirection = "asc"
) => {
  try {
    let url = `${coreApiUrl}/units?page=${page}&size=${size}&sort=${sort},${sortDirection}`;
    url += parseFilters(filters);
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};
export const getDosageForms = async (
  page = 0,
  size = 25,
  filters = [],
  sort = "name",
  sortDirection = "asc"
) => {
  try {
    let url = `${coreApiUrl}/dosageforms`;
    url += parseFilters(filters);
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};
export const getRoutes = async (
  page = 0,
  size = 25,
  filters = [],
  sort = "name",
  sortDirection = "asc"
) => {
  try {
    let url = `${coreApiUrl}/routes?page=${page}&size=${size}&sort=${sort},${sortDirection}`;
    url += parseFilters(filters);
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};

let searchMedicationCancelToken;
export const searchMedications = async (
  page = 1,
  size = 10,
  filters = [],
  sort = "name",
  sortDirection = "asc"
) => {
  try {

    if (typeof searchMedicationCancelToken != typeof undefined) {
      searchMedicationCancelToken.cancel("Cancel Request");
    }
    searchMedicationCancelToken = apiUtil.getCancelToken();
    let url = `${medicationApiUrl}?page=${page}&size=${size}`;
    let sortArg = sortDirection === "asc" ? "+" : "-";
    sortArg += sort;
    url += `&sort=${sortArg}`;
    url += parseFiltersToJson(filters);
    const response = await apiUtil.client.get(url, {cancelToken: searchMedicationCancelToken.token});
    return response.data;
  } catch (error) {
    if (apiUtil.isCancel(error)) {
      return {canceled: true};
    }
    return { error: true, message: error };
  }
};

export const getMedicationById = async (medicationId) => {
  try {
    const response = await apiUtil.client.get(
      `${medicationApiUrl}/${medicationId}`
    );
    return response.data;
  } catch (error) {
    return { error: true, message: error };
  }
};

export const createMedication = async (medication) => {
  try {
    const response = await apiUtil.client.post(`${medicationApiUrl}`, medication);
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
}

export const updateMedication = async (medicationId, medication) => {
  try {
    const response = await apiUtil.client.put(`${medicationApiUrl}/${medicationId}`, medication);
    return response.data;
  } catch (error) {
    return { error: true, message: error };
  }
}


export const getCodeStatuses = async () => {
  try {
    const baseUrl = `${config.residentService}/api/v1.0/codestatuses/`;
    const response = await apiUtil.client.get(baseUrl);
    return response.data;
  } catch (error) {
    return {error: true, message: error};
  }
};
