import React from 'react';
import {
  Avatar,
  Button,
  ButtonGroup, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Hidden,
  IconButton,
  Link,
  List,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  deleteMedicationEvent,
  getResidentEmarsByDate,
  updateResidentEmar,
} from '../../../../services/medication.order.service';
import moment from 'moment';
import MuiAlert from '@material-ui/lab/Alert';
import {Link as RouterLink, useParams} from 'react-router-dom';
import {blue, green, grey, orange, red, yellow,} from '@material-ui/core/colors';
import EmarDialog from '../../../eschedule/emar/emar.dialog';
import EditIcon from '@material-ui/icons/Edit';
import MobileEmar from '../../../emar/mobile.emar';
import DateNavigation from '../date.navigation';
import {ResidentContext} from '../../../../context/residentcontext';
import {UserContext} from '../../../../context/usercontext';
import useHasRole from "../../../../hooks/user.role.hook";
import {
  ListAlt,
  RadioButtonUncheckedRounded,
  RotateLeftTwoTone, Visibility,
  VisibilityOff,
  WarningTwoTone
} from "@material-ui/icons";
import {Skeleton} from "@material-ui/lab";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import VitalInput from "./VitalInput";
import {displayHoldInfo, isMedicationOrderOnHold, isValid} from './utils'
import EmarList from "./list/emar.list";
import * as _ from 'lodash';
import useGlobalNotification from "../../../../hooks/notification.hook";
import {ListLoading} from "../../../../utils/indicators/list.loading";
import {EditEmarAdministered} from "./EditEmarAdministered";
import {getFrequencies} from "../../../../services/frequency.service";
import {PageTitle} from "../../../utils";

const contrast = 400;
const height = '31.33rem';
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1),
  },
  errorRoot: {
    padding: theme.spacing(7),
    height: '10rem'
  },
  icon: {
    marginRight: '.5rem',
  },
  backBtn: {
    marginRight: '.5rem',
  },
  gridContainer: {
    padding: theme.spacing(1),
  },
  listRoot: {
    backgroundColor: theme.palette.background.paper,
  },
  calendarBtn: {
    margin: theme.spacing(0, 1),
  },
  listItem: {
    borderBottom: '.25px solid',
    borderColor: theme.palette.grey[50],
    height: '10rem',
  },
  avatarContainer: {
    marginRight: theme.spacing(3),
  },
  avatarSmall: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  avatarLarge: {
    background: theme.palette.primary,
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  scheduled: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: grey[500],
  },
  readyToPass: {
    background: grey[700],
  },
  readyToPassIcon: {
    color: grey[700]
  },
  given: {
    background: green[contrast],
  },
  givenIcon: {
    color: green[contrast]
  },
  late: {
    background: orange[contrast],
  },
  lateIcon: {
    color: orange[contrast]
  },
  missed: {
    background: red[contrast],
  },
  missedIcon: {
    color: red[contrast]
  },
  hold: {
    background: yellow[contrast],
  },
  holdIcon: {
    color: yellow[contrast]
  },
  sa: {
    background: blue[contrast],
  },
  saIcon: {
    color: blue[contrast]
  },
  na: {
    background: grey[contrast],
  },
  naIcon: {
    color: grey[contrast]
  },
  refused: {
    background: orange[contrast],
  },
  refusedIcon: {
    color: orange[contrast]
  },
  destruction: {
    background: yellow[contrast],
  },
  destructionIcon: {
    color: yellow[contrast]
  },
  statusName: {
    marginLeft: theme.spacing(1),
  },
  errorContent: {
    padding: theme.spacing(5),
    minHeight: height,
    maxHeight: height,
    overflowY: 'scroll'
  },
  retryBtn: {
    margin: theme.spacing(0, 1),
  },
  errorLogo: {
    color: theme.palette.warning.dark,
    fontSize: '2rem'
  },
  vitalsRow: {
    backgroundColor: theme.palette.grey[200],
  },
  tableContainer: {
    padding: theme.spacing(2, 0, 25, 0),
    overflow: 'scroll',
    height: '70vh',
  },
  listContainer: {
    padding: theme.spacing(2, 0, 25, 0),
    overflow: 'scroll',
    height: '70vh',
  },
  sideBarEmar: {
    padding: theme.spacing(1)
  },
  emarListContainer: {
    borderLeftStyle: 'solid',
    borderLeftWidth: 1,
    borderLeftColor: theme.palette.grey[400],
  },
  maxScrollable: {
    maxHeight: '58vh',
    overflowY: 'scroll'
  },
  buttonRoot: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  holdAlert: {
    background: '#ffee58',
    color: theme.palette.warning.contrastText
  },
  isHold: {
    backgroundColor: yellow[100]
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ResidentEmar = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [instructions, setInstructions] = React.useState([]);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [selectedEmar, setSelectedEmar] = React.useState(false);
  const [showPassMeds, setShowPassMeds] = React.useState(false);
  const [frequencies, setFrequencyies] = React.useState([]);
  const [editEmar, setEditEmar] = React.useState();
  const [editEmarIndex, setEditEmarIndex] = React.useState(-1);
  const [selectedEvent, setSelectedEvent] = React.useState(null);
  const [state, setState] = React.useState({
    mouseX: null,
    mouseY: null,
  });
  const [confirmEventDelete, setConfirmEventDelete] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const {checkPermission} = useHasRole();
  const hasEditPermission = checkPermission('EMAR_UPDATE');
  const [date, setDate] = React.useState();
  const {dispatch} = React.useContext(ResidentContext);
  const {roles} = React.useContext(UserContext);
  const [expandMars, setExpandMars] = React.useState(true);
  const [onResidentStatusChangeEvent, setOnResidentStatusChangeEvent] = React.useState();
  const [updateEmarId, setUpdateEmarId] = React.useState(null);
  const [updating, setUpdating] = React.useState(false);
  const [viewEditAdministrationModal, setViewEditAdministrationModal] = React.useState(false);
  const { addNotification } = useGlobalNotification();



  React.useEffect(() => {
    if (id) {
      setDate(new Date());
      loadData(id, new Date());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const loadData = async (id, date) => {
    setLoading(true);
    setError(false);
    setEditEmarIndex(-1);
    const frequencyResponse = await getFrequencies();
    if (!frequencyResponse?.error) {
      setFrequencyies(frequencyResponse);
    }

    const offset = new Date().getTimezoneOffset();
    const response = await getResidentEmarsByDate(
      id,
      moment(date).format('YYYY-MM-DD'),
      offset
    );
    if (!response?.canceled && !response?.error) {
      const sorted = _.sortBy(response, ['scheduledTime', 'medication.tradeName'])
      setInstructions(sorted);
      setLoading(false);
    } else if (response?.error) {
      setError(true);
      setLoading(false);
    }
  };

  const handleSnackbarClose = (e) => {
    setOpenSnackbar(false);
  };

  const selectEmarToPass = (emar) => {
    setSelectedEmar(emar);
    setShowPassMeds(true);
  };

  const updateEmar = async (emar) => {
    setUpdateEmarId(emar?._id);
    setUpdating(true);
    const response = await updateResidentEmar(id, emar._id, emar);
    if (!response?.error) {
      const items = instructions.map((instruction) => {
        if (instruction._id === response._id) {
          instruction.administeredTimestamp = response.administeredTimestamp;
          instruction.administeredUser = response.administeredUser;
          instruction.status = response.status;
          instruction.passingQty = response.passingQty;
          instruction.initials = response.administeredUser
            ? response.administeredUser.initials
            : null;
          instruction.note = response.note;
          instruction.vital = response?.vital;
        }
        return instruction;
      });
      setInstructions(items);
      setOpenSnackbar(true);
      setShowPassMeds(false);
      dispatch({type: 'REFRESH_ALERTS'});
      setOnResidentStatusChangeEvent({
        _id: response?._id,
        status: response?.status?.name
      });
      setUpdateEmarId(null);
      setUpdating(false);
      return true;
    } else {
      addNotification("Unable to update emar", "error");
      setUpdating(false);
      return false;
    }
  };

  const canEdit = (event) => {
    if (!hasEditPermission) {
      return false;
    }
    const current = moment();
    const start = moment(event.startWindow);
    const end = moment(event.endWindow);

    let updateMissedMedicationPermissions = false;
    if (roles) {
      updateMissedMedicationPermissions =
        roles.filter(
          (userRole) =>
            userRole.role.permissions.indexOf('UPDATE_MISSED_MEDICATION') > -1
        ).length > 0;
    }
    if (updateMissedMedicationPermissions && current >= start) {
      return true;
    } else if (current >= start && current < end) {
      return true;
    } else {
      return false;
    }
  };

  const selectEditEmar = (emar, index) => {
    setEditEmar(emar);
    setEditEmarIndex(index);
  };

  const saveEmar = async () => {
    const success = await updateEmar(editEmar);
    if (success) {
      setEditEmarIndex(-1);
    }
  };

  const getItemIconClass = event => {
    if (event?.status?.name) {
      switch (event.status.name) {
        case 'Scheduled':
          if (canEdit(event)) {
            return classes.readyToPassIcon;
          } else {
            return classes.scheduledIcon;
          }
        case 'Given':
          return classes.givenIcon;
        case 'Late':
          return classes.lateIcon;
        case 'Missed':
          return classes.missedIcon;
        case 'Hold':
          return classes.holdIcon;
        case 'SA':
        case 'Supervise':
        case 'Assist':
          return classes.saIcon;
        case 'NA':
          return classes.naIcon;
        case 'Refused':
          return classes.refusedIcon;
        case 'Destruction':
          return classes.destructionIcon;
        default:
          return classes.scheduledIcon;
      }
    }
  }


  const getItemClass = (event) => {
    if (event?.status?.name) {
      switch (event.status.name) {
        case 'Scheduled':
          if (canEdit(event)) {
            return classes.readyToPass;
          } else {
            return classes.scheduled;
          }
        case 'Given':
          return classes.given;
        case 'Late':
          return classes.late;
        case 'Missed':
          return classes.missed;
        case 'Hold':
          return classes.hold;
        case 'SA':
        case 'Supervise':
        case 'Assist':
          return classes.sa;
        case 'NA':
          return classes.na;
        case 'Refused':
          return classes.refused;
        case 'Destruction':
          return classes.destruction;
        default:
          return classes.scheduled;
      }
    }
  };

  const isChartVitalsValid = (emar) => {
    return true;
  }


  const quickUpdate = async (emar, status) => {
    setEditEmar(emar);
    setError(false);
    setUpdating(true);
    if (emar) {
      emar.status.name = status;
      const success = await updateEmar(emar)
      if (success) {
        if (emar?.chartVitals && !isChartVitalsValid(emar)) {
          return;
        }
        emar.status.name = status;
      } else {
        setError(true);
      }
    }
    setUpdating(false);
  }


  const onEditEmarChange = (e) => {
    const obj = {...editEmar};
    obj[e.target.name] = e.target.value;
    setEditEmar(obj);
  };

  const onEditStatus = (e) => {
    const obj = {...editEmar};
    if (obj) {
      if (obj.status) {
        obj.status.name = e.target.value;
      } else {
        obj.status = {name: e.target.value};
      }
    }
    setEditEmar(obj);
  };

  const getEmarTooltip = (event) => {
    const scheduled = moment(event.scheduledTime).format('MM/DD/YYYY hh:mm a');
    const start = moment(event.startRange).format('MM/DD/YYYY hh:mm a');
    const end = moment(event.endRange).format('MM/DD/YYYY hh:mm a');
    return (
      <div>
        <p>{scheduled}</p>
        <p>Start: {start}</p>
        <p>End: {end}</p>
      </div>
    );
  };
  const onDateChange = async (dt) => {
    setDate(dt);
    await loadData(id, dt);
  };

  const handleClick = (event, order) => {
    setSelectedEvent(order._id);
    event.preventDefault();
    setState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleClose = () => {
    setState({
      mouseX: null,
      mouseY: null,
    });
    setSelectedEvent(null);
  };

  const handleSelect = () => {
    setState({
      mouseX: null,
      mouseY: null,
    });
    setConfirmEventDelete(true);
    // setSelectedEvent(null)
  };

  const deleteEvent = async (e) => {
    await deleteMedicationEvent(id, selectedEvent);
    setConfirmEventDelete(false);
    const obj = instructions.filter((i) => i._id !== selectedEvent);
    setInstructions(obj);
  };

  const retry = async () => {
    await loadData(id, date);
  }

  const onVitalsChange = (emarId, payload) => {
    const obj = {...editEmar};
    obj.updatedVital = payload;
    setEditEmar(obj);
  }

  const onAdministeredDetailsUpdated = payload => {
    const obj = {...editEmar };
    obj.administeredTimestamp = payload?.administeredTimestamp;
    obj.administeredUser = payload?.administeredUser;
    setEditEmar(obj);
    setViewEditAdministrationModal(false);
  }

  const getFrequencyDetails = emar => {
    if (emar?.dosageInstruction?.frequency?.name) {
      return `${emar.dosageInstruction?.frequency?.name} - ${emar?.dosageInstruction?.frequency?.description}`;
    } else {
      return null;
    }
  }

  return (
    <div>
      <Paper elevation={24} className={classes.root}>
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <PageTitle title={"Resident Emar"} />
          </Grid>
          <Grid item>
            <Button component={RouterLink}
                    to={`/residents/${id}/mar`}
                    startIcon={<ListAlt/>}
                    color={"primary"}>
              Mar
            </Button>
            <Button startIcon={expandMars ?  <VisibilityOff />: <Visibility />}
                    onClick={() => setExpandMars(expand => !expand)}
                    color={"primary"}>
              View Resident Mar List
            </Button>
          </Grid>
        </Grid>
        <DateNavigation onDateChange={onDateChange}/>
        {!loading && error &&
        <Paper elevation={24}
               className={classes.errorRoot}
        >
          <Grid container
                alignItems={"center"}
                justify={"center"}>
            <WarningTwoTone className={classes.errorLogo}/>
            <Typography variant={"subtitle2"}>Whoops, unable to load emar. Press retry to reload data.</Typography>
            <Button color={"primary"}
                    className={classes.retryBtn}
                    variant={"contained"}
                    onClick={retry}
                    startIcon={<RotateLeftTwoTone/>}>Retry</Button>
          </Grid>
        </Paper>
        }
        {loading &&
        <Paper elevation={24}
               className={classes.root}
        >
          <Skeleton height={50}/>
          <Skeleton height={50}/>
          <Skeleton height={50}/>
          <Skeleton height={50}/>
        </Paper>
        }
        {!loading && !error &&
        <>
          <Hidden mdDown>
            <Grid container>
              <Grid item xs={expandMars ? 10: 12}>
                <TableContainer className={classes.maxScrollable}>
                  <Table size="small" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Medication</TableCell>
                        <TableCell>Strength</TableCell>
                        <TableCell>Frequency</TableCell>
                        <TableCell>Time</TableCell>
                        <TableCell>Pill Description</TableCell>
                        <TableCell>Indication</TableCell>
                        <TableCell>Direction</TableCell>
                        <TableCell>Alerts</TableCell>
                        <TableCell/>
                        <TableCell>Qty</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Administered</TableCell>
                        <TableCell>Administered By</TableCell>
                        <TableCell>Note</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {instructions?.map((order, i) => (
                        <>
                          <TableRow
                            onContextMenu={(event) => handleClick(event, order)}
                            style={{cursor: 'context-menu'}}
                            className={isMedicationOrderOnHold(order?.medicationOrder, date) ? classes.isHold: null}

                          >
                            <TableCell>
                              {order && order.medicationOrder && (
                                <Link
                                  component={RouterLink}
                                  to={`/residents/${id}/medications/${order.medicationOrder._id}`}
                                >
                                  {order.medication
                                    ? order.medication.tradeName
                                    : null}
                                </Link>
                              )}
                            </TableCell>
                            <TableCell>
                              {order && order.medication && order.medication.strength}{' '}
                              {order &&
                              order.medication &&
                              order.medication.unit &&
                              order.medication.unit.name}
                            </TableCell>
                            <TableCell>
                              {getFrequencyDetails(order)}
                            </TableCell>
                            <Tooltip title={getEmarTooltip(order)} interactive>
                              <TableCell className={classes.tableDayColumn}>
                                {moment(order.scheduledTime).format('h:mma')}
                              </TableCell>
                            </Tooltip>
                            <TableCell>{order.pillDescription}</TableCell>
                            <TableCell>{order.indication}</TableCell>
                            <TableCell>{order.direction}</TableCell>
                            <TableCell>
                              {order.alerts}
                            </TableCell>
                            <TableCell>
                              {isMedicationOrderOnHold(order?.medicationOrder, date) &&
                                <>
                                  {displayHoldInfo(order?.medicationOrder, date)}
                                </>
                              }
                              {canEdit(order) && i !== editEmarIndex && (
                                <div className={classes.buttonRoot}>
                                  {!order?.chartVitals && order?.status?.name ===
                                    (order?.medicationOrder?.defaultQuickStatus ?? 'Given') &&
                                    <IconButton disabled={updating}
                                                onClick={() => quickUpdate(order, 'Scheduled')}>
                                      {
                                        updating && editEmar?._id === order?._id ?
                                          <CircularProgress size={20} />:
                                          <CheckCircleRoundedIcon className={getItemIconClass(order)}/>
                                      }
                                    </IconButton>
                                  }
                                  {!isMedicationOrderOnHold(order?.medicationOrder, date)
                                    && !order?.chartVitals && order?.status?.name !==
                                    (order?.medicationOrder?.defaultQuickStatus ?? 'Given') &&
                                    <IconButton style={{marginRight: '1rem'}}
                                                disabled={updating}
                                                onClick={() =>
                                                  quickUpdate(order, order?.medicationOrder?.defaultQuickStatus
                                                    ?? 'Given')}>
                                      {
                                        updating && editEmar?._id === order?._id ?
                                            <CircularProgress size={20} />:
                                            <RadioButtonUncheckedRounded/>
                                      }
                                    </IconButton>
                                  }
                                  <IconButton size="small"
                                              disabled={updating}
                                              onClick={() => selectEditEmar(order, i)}>
                                    <EditIcon/>
                                  </IconButton>
                                </div>
                              )}
                              {canEdit(order) && i === editEmarIndex && (
                                <ButtonGroup size="small">
                                  <Button
                                    onClick={saveEmar}
                                    disabled={!isValid(editEmar)}
                                    color="primary"
                                    variant="contained"
                                  >
                                    Save
                                  </Button>
                                  <Button onClick={() => setEditEmarIndex(-1)}>
                                    Cancel
                                  </Button>
                                </ButtonGroup>
                              )}
                            </TableCell>
                            <TableCell>
                              {i === editEmarIndex && !order?.medicationOrder?.isQtyEditable &&
                              <span>{order.passingQty}</span>}
                              {i !== editEmarIndex && <span>{order.passingQty}</span>}
                              {i === editEmarIndex && order?.medicationOrder?.isQtyEditable && (
                                <TextField
                                  value={editEmar.passingQty}
                                  label="Qty"
                                  onChange={onEditEmarChange}
                                  name="passingQty"
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {i !== editEmarIndex && (
                                <Grid container alignItems="center">
                                  <Avatar
                                    className={[
                                      classes.avatarSmall,
                                      getItemClass(order),
                                    ]}
                                  >
                                    <i class="fas fa-prescription-bottle"></i>
                                  </Avatar>
                                  <Typography
                                    className={classes.statusName}
                                    variant="subtitle2"
                                  >
                                    {order.medicationOrderEventStatusName}
                                  </Typography>
                                </Grid>
                              )}
                              {i === editEmarIndex && (
                                <Select
                                  id="status"
                                  label="Status"
                                  value={editEmar?.status?.name || ''}
                                  name="status"
                                  onChange={onEditStatus}
                                >
                                  <MenuItem value={'Scheduled'}>Scheduled</MenuItem>
                                  <MenuItem value={'Given'}>Given</MenuItem>
                                  <MenuItem value={'Late'}>Late</MenuItem>
                                  <MenuItem value={'Missed'}>Missed</MenuItem>
                                  <MenuItem value={'Hold'}>Hold</MenuItem>
                                  <MenuItem value={'SA'}>Self Administered</MenuItem>
                                  <MenuItem value={'NA'}>Not Administered</MenuItem>
                                  <MenuItem value={'Refused'}>Refused</MenuItem>
                                  <MenuItem value={'Assist'}>Assist</MenuItem>
                                  <MenuItem value={'Supervise'}>Supervise</MenuItem>
                                </Select>
                              )}
                            </TableCell>
                            <TableCell>
                              {i === editEmarIndex &&
                                <>
                                  <Button size={"small"}
                                          startIcon={<EditIcon />}
                                          onClick={() => setViewEditAdministrationModal(true)}
                                  >
                                    {editEmar?.administeredTimestamp &&
                                      moment(editEmar.administeredTimestamp).format('h:mma')
                                    }
                                    {!editEmar?.administeredTimestamp &&
                                      `Set`
                                    }
                                  </Button>
                                </>
                              }
                              {i !== editEmarIndex &&
                              <div>
                                {order.administeredTimestamp &&
                                  moment(order.administeredTimestamp).format('h:mma')}
                              </div>
                              }
                            </TableCell>
                            <TableCell>
                              {i === editEmarIndex &&
                                <Button size={"small"}
                                        startIcon={<EditIcon />}
                                        onClick={() => setViewEditAdministrationModal(true)}>
                                  {editEmar?.administeredUser?.initials}
                                </Button>
                              }
                              {i !== editEmarIndex &&
                                <div>
                                  {order?.administeredUser?.initials}
                                </div>
                              }
                            </TableCell>
                            <TableCell>
                              {i !== editEmarIndex && order.note}
                              {i === editEmarIndex && (
                                <TextField
                                  id="event-note"
                                  multiline
                                  rows={2}
                                  value={editEmar.note || ''}
                                  name="note"
                                  onChange={onEditEmarChange}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                          {order?.chartVitals &&
                          <TableRow className={classes.vitalsRow}>
                            <TableCell colSpan={14}>
                              <VitalInput emar={order}
                                          resident={id}
                                          onValueChange={onVitalsChange}
                                          edit={i === editEmarIndex}/>
                            </TableCell>
                          </TableRow>
                          }
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={expandMars ? 2: 0} className={classes.maxScrollable}>
                <EmarList date={date}
                          onResidentStatusChangeEvent={onResidentStatusChangeEvent}
                          expand={expandMars}
                          toggle={() => setExpandMars(val => !val)} />
              </Grid>
            </Grid>
          </Hidden>
          <Hidden lgUp>
            <div className={classes.listContainer}>
              <List dense>
                {instructions?.map((emar) => {
                  if (updateEmarId && updating && updateEmarId === emar?._id) {
                    return (
                      <ListLoading rows={1} />
                    )
                  } else {
                    return (
                      <MobileEmar emar={emar}
                                  quickUpdate={quickUpdate}
                                  onSelected={selectEmarToPass}
                                  canUpdate={hasEditPermission} frequencies={frequencies}
                                  date={date}
                      />
                    )
                  }
                  }
                )}
              </List>
            </div>
          </Hidden>
        </>}
        <Snackbar
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          key={`top,center`}
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity="success">
            Emar has successfully saved.
          </Alert>
        </Snackbar>
      </Paper>
      <EmarDialog
        open={showPassMeds}
        model={selectedEmar}
        onClose={() => setShowPassMeds(false)}
        onSave={updateEmar}
        canUpdate={hasEditPermission}
        date={date}
      />

      <Menu
        keepMounted
        open={state.mouseY !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          state.mouseY !== null && state.mouseX !== null
            ? {top: state.mouseY, left: state.mouseX}
            : undefined
        }
      >
        <MenuItem onClick={handleSelect}>Delete Event</MenuItem>
      </Menu>

      <Dialog open={confirmEventDelete}>
        <DialogTitle>Delete Medication Event?</DialogTitle>
        <DialogContent>
          Please confirm that you want to delete a Medication event.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmEventDelete(false)}>Cancel</Button>
          <Button onClick={deleteEvent}>Delete</Button>
        </DialogActions>
      </Dialog>
      <EditEmarAdministered open={viewEditAdministrationModal}
                            onClose={() => setViewEditAdministrationModal(false)}
                            onSave={onAdministeredDetailsUpdated}
                            emar={editEmar}
                            />
    </div>
  );
};

export default ResidentEmar;
