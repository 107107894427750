import React from 'react';
import {Button, Divider, Grid} from "@material-ui/core";
import {IntakeContext} from "../context/intake.context";
import {useStepStyles} from "./styles";

export const StepActions = ({
                              onNext,
                              onSave,
                              onPrev,
                              disableNext = false
                            }) => {
  const {state} = React.useContext(IntakeContext);
  const classes = useStepStyles();

  if (state?.step?.first) {
    return (
      <div>
        <Divider/>
        <Grid container justifyContent={"flex-end"} className={classes.innerContainer}>
          <Button disabled={disableNext} onClick={onNext} variant={"contained"} color={"primary"}>Next</Button>
        </Grid>
      </div>
    )
  }

  if (state?.step?.last) {
    return (
      <div>
        <Divider/>
        <Grid container justifyContent={"space-between"} className={classes.innerContainer}>
          <Button onClick={onPrev} variant={"text"}>Previous</Button>
          <Button disabled={disableNext} onClick={onSave} variant={"contained"} color={"primary"}>
            Submit
          </Button>
        </Grid>
      </div>
    )
  }

  if (!state?.step?.first && !state?.step?.last) {
    return (
      <div>
        <Divider/>
        <Grid container justifyContent={"space-between"} className={classes.innerContainer}>
          <Button onClick={onPrev} variant={"text"}>Previous</Button>
          <Button disabled={disableNext} onClick={onNext} variant={"contained"} color={"primary"}>Next</Button>
        </Grid>
      </div>
    )
  }
}
