import apiUtil from '../utils/api.utils';
import config from '../utils/configs';
import moment from "moment-timezone";

const baseUrl = `${config.residentService}/api/v1.0/residents`;

export const getResidentEVitals = async (residentId, page = 1, size = 10) => {
  try {
    const response = await apiUtil.client.get(
      `${baseUrl}/${residentId}/vitals?page=${page}&size=${size}`
    );
    return response.data;
  } catch (err) {
    return [];
  }
};

export const getVitalsReportData = async (residentId, startDate, endDate) => {
  try {
    const response = await apiUtil.client.get(
      `${baseUrl}/${residentId}/vitals-reportdata?start=${moment(startDate).format("YYYY-MM-DD")}&end=${moment(endDate).format("YYYY-MM-DD")}`
    );
    return response.data;
  } catch (error) {
    return {error: true, message: error}
  }
}

export const createResidentEVital = async (residentId, vital) => {
  try {
    const response = await apiUtil.client.post(
      `${baseUrl}/${residentId}/vitals`,
      vital
    );
    return response.data;
  } catch (error) {
    return {error: true, message: error};
  }
};

export const updateResidentEVital = async (residentId, vitalId, vital) => {
  try {
    const response = await apiUtil.client.put(
      `${baseUrl}/${residentId}/vitals/${vitalId}`,
      vital
    );
    return response.data;
  } catch (error) {
    return {error: true, message: error}
  }
};

export const getResidentLungSounds = async (residentId, page = 1, size = 25) => {
  try {
    const response = await apiUtil.client.get(
      `${baseUrl}/${residentId}/lungsounds?page=${page}&size=${size}`
    );
    return response.data;
  } catch (err) {
    return { error: true, message: err}
  }
};

export const updateResidentLungSound = async (residentId, lungSoundId, lungSound) => {
  try {
    const response = await apiUtil.client.put(
      `${baseUrl}/${residentId}/lungsounds/${lungSoundId}`,
      lungSound
    );
    return response.data;
  } catch (err) {
    return { error: true, message: err}
  }
};

export const deleteResidentLungSound = async (residentId, lungSoundId) => {
  try {
    const response = await apiUtil.client.delete(`${baseUrl}/${residentId}/lungsounds/${lungSoundId}`);
    return response.data;
  } catch (err) {
    return { error: true, message: err}
  }
};

export const createResidentLungSounds = async (residentId, lungSound) => {
  try {
    const response = await apiUtil.client.post(
      `${baseUrl}/${residentId}/lungsounds`,
      lungSound
    );
    return response.data;
  } catch (err) {
    return [];
  }
};

export const getResidentVentilatorReadings = async (residentId, page = 1, size = 25) => {
  try {
    const response = await apiUtil.client.get(`${baseUrl}/${residentId}/ventilatorreadings?page=${page}&size=${size}`);
    return response.data;
  } catch (error) {
    return {error: true, message: error};
  }
};

export const deleteResidentVentilatorReadings = async (residentId, ventilatorReadingId) => {
  try {
    const response = await apiUtil.client.delete(`${baseUrl}/${residentId}/ventilatorreadings/${ventilatorReadingId}`);
    return response.data;
  } catch (error) {
    return {error: true, message: error};
  }
};

export const updateResidentVentilatorReadings = async (residentId, ventilatorReadingId, payload) => {
  try {
    const response = await apiUtil.client.put(
      `${baseUrl}/${residentId}/ventilatorreadings/${ventilatorReadingId}`,
      payload);
    return response.data;
  } catch (error) {
    return {error: true, message: error};
  }
};

export const createResidentVentilatorReadings = async (
  residentId,
  lungSound
) => {
  try {
    const response = await apiUtil.client.post(
      `${baseUrl}/${residentId}/ventilatorreadings`,
      lungSound
    );
    return response.data;
  } catch (err) {
    return [];
  }
};

export const getResidentVitalsForVital = async (residentId, vital) => {
  try {
    const response = await apiUtil.client.get(
      `${baseUrl}/${residentId}/vitals/${vital}`
    );
    return response.data;
  } catch (err) {
    return [];
  }
};

export const getResidentLatestVitals = async (residentId) => {
  try {
    const response = await apiUtil.client.get(
      `${baseUrl}/${residentId}/vitals-latest`
    );
    return response.data;
  } catch (err) {
    return [];
  }
}
