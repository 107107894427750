import apiUtil from "../utils/api.utils";
import { parseFiltersToJson } from "../utils/filter.utils";
import configs from "../utils/configs";
const baseUrl = `${configs.residentService}`;

export const getAllContactTypes = async (token) => {
  const url = `${baseUrl}/api/v1.0/contacttypes/`;
  const response = await apiUtil.client.get(url);
  return response.data;
};

export const searchContacts = async (
  page = 1,
  size = 25,
  filters = [],
  sort = "id",
  sortDirection = "asc"
) => {
  try {
    let sortArg = sortDirection === "asc" ? "+" : "-";
    sortArg += sort;
    let url = `${baseUrl}/api/v1.0/contacts/?page=${page}&size=${size}&sort=${sortArg}`;
    if (filters && filters.length > 0) {
      url += parseFiltersToJson(filters);
    }
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (error) {
    alert(error);
    return {};
  }
};

export const getResidentContacts = async (residentId) => {
  const url = `${baseUrl}/api/v1.0/residents/${residentId}/contacts`;
  const response = await apiUtil.client.get(url);
  return response.data;
};

export const addResidentContact = async (
  residentId,
  contactId,
  contactTypeId,
  isPrimary
) => {
  try {
    const url = `${baseUrl}/api/v1.0/residents/${residentId}/contacts`;
    const data = {
      contactId,
      contactTypeId,
      isPrimary,
    };
    const response = await apiUtil.client.post(url, data);
    return response.data;
  } catch (err) {
    alert(err);
    return null;
  }
};

export const updateResidentContact = async (
  residentId,
  contactId,
  contactType,
  financialPowerOfAttorny,
  medicalPowerOfAttorny,
  responsiblePayer,
  isPrimary
) => {
  const url = `${baseUrl}/api/v1.0/residents/${residentId}/contacts/${contactId}`;
  const data = {
    contactType,
    financialPowerOfAttorny,
    medicalPowerOfAttorny,
    responsiblePayer,
    isPrimary
  };
  const response = await apiUtil.client.put(url, data);
  return response.data;
};

export const deleteResidentContact = async (residentId, contactId) => {
  const url = `${baseUrl}/api/v1.0/residents/${residentId}/contacts/${contactId}`;
  const response = await apiUtil.client.delete(url);
  return response.data;
};

export const createContact = async (firstName, lastName, email) => {
  try {
    const data = {
      firstName,
      lastName,
      email
    };
    const url = `${baseUrl}/api/v1.0/contacts/`;
    const response = await apiUtil.client.post(url, data);
    return response.data;
  } catch (error) {
    return { error: true, message: error };
  }
};

export const getContactById = async (contactId) => {
  try {
    const response = await apiUtil.client.get(
      `${baseUrl}/api/v1.0/contacts/${contactId}`
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};

export const createContactPhone = async (contactId, phone) => {
  try {
    const response = await apiUtil.client.post(
      `${baseUrl}/api/v1.0/contacts/${contactId}/phones`,
      phone
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};

export const updateContactPhone = async (contactId, phoneId, phone) => {
  try {
    const response = await apiUtil.client.put(
      `${baseUrl}/api/v1.0/contacts/${contactId}/phones/${phoneId}`,
      phone
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};

export const deleteContactPhone = async (contactId, phoneId) => {
  try {
    const response = await apiUtil.client.delete(
      `${baseUrl}/api/v1.0/contacts/${contactId}/phones/${phoneId}`
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};

export const updateContact = async (contactId, contact) => {
  try {
    const response = await apiUtil.client.put(
      `${baseUrl}/api/v1.0/contacts/${contactId}`,
      contact
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};

export const createContactAddress = async (contactId, address) => {
  try {
    const response = await apiUtil.client.post(
      `${baseUrl}/api/v1.0/contacts/${contactId}/addresses`,
      address
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};

export const updateContactAddress = async (contactId, addressId, address) => {
  try {
    const response = await apiUtil.client.put(
      `${baseUrl}/api/v1.0/contacts/${contactId}/addresses/${addressId}`,
      address
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};

export const deleteContactAddress = async (contactId, addressId) => {
  try {
    const response = await apiUtil.client.delete(
      `${baseUrl}/api/v1.0/contacts/${contactId}/addresses/${addressId}`
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};
