import React, {useContext} from 'react';
import {
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton, makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {ACTIONS} from "./context/constants";
import {CareplanContext} from "./context";
import {GoalModel} from "./goal.model";
import {FormFieldsGenerator, isModelValid} from "../../../utils/forms";
import {createGoalAction} from "./context/actions";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    dialogContent: {
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

export const CreateGoalDialog = () => {
    const {state, dispatch} = useContext(CareplanContext);
    const classes = useStyles();

    React.useEffect(() => {
        if (state?.viewCreateGoalDialog) {
            const obj = JSON.parse(JSON.stringify(GoalModel));
            dispatch({type: ACTIONS.SET_GOAL_MODEL, payload: obj});
        }
        // eslint-disable-next-line
    }, [state?.viewCreateGoalDialog]);

    const handleClose = () => {
        dispatch({type: ACTIONS.SET_VIEW_CREATE_GOAL_DIALOG, payload: false});
    }

    const handleChange = e => {
        const obj = {...state?.goalModel, ...e};
        dispatch({type: ACTIONS.SET_GOAL_MODEL, payload: obj});
    }

    const handleCreate = async () => {
        const data = {
            name: state?.goalModel?.name?.value,
            description: state?.goalModel?.description?.value
        };
        await createGoalAction(dispatch, state?.residentId, state?.careplan?._id, data);
    }

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={state?.viewCreateGoalDialog}
            fullWidth={true}
            maxWidth={'lg'}
        >
            <DialogTitle>
                Create Goal
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    className={classes.closeButton}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <FormFieldsGenerator onChange={handleChange} model={state?.goalModel} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                <Button onClick={handleCreate}
                        disabled={!isModelValid(state?.goalModel)}
                        variant={"contained"} color={"primary"}>
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    )
}
