/*
  administeredTimestamp: "2021-04-23T21:38:01.013Z"
  createdAt: "2021-04-23T21:58:47.655Z"
  isPrn: true
  isRecheck: false
  medicationOrder: {_id: "608341158036fe61885dc585", orderStatus: "5f480bf3298093711c898eea", allergies: [],…}
outcomes: [{_id: "608343458036fe61885dc58a", comment: "Test",…},…]
passingQty: 1
reason: "Because"
recheckDateTime: null
scheduledTime: "2021-04-23T21:38:01.013Z"
status: {name: "Given"}
updatedAt: "2021-04-23T21:59:41.847Z"
_id: "608343178036fe61885dc588"
 */

const model = {
  _id: {
    hidden: true,
    title: "ID",
    required: false,
    value: new Date(),
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    time: false,
  },
  administeredTimestamp: {
    title: "Administered",
    required: true,
    value: new Date(),
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    time: true,
  },
  administeredUser: {
    title: "Administered User",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [],
    dropDownItemValue: "_id",
    dropDownItemLabel: "name",
  },
  passingQty: {
    title: "Passing Qty",
    required: true,
    value: 1,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
  },
  given: {
    title: "Given",
    required: true,
    value: "given",
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
    checkbox: true
  },
  reason: {
    title: "Reason",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "_id",
    dropDownItemLabel: "name",
    date: false,
  },
  outcomes: {
    hidden: true,
    title: "Outcomes",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
  },
  isRecheck: {
    title: "Schedule Recheck",
    required: false,
    value: true,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
    isToggle: true
  },
  recheckDateTime: {
    hidden: true,
    title: 'Recheck date',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: true,
  }
};

export default model;
