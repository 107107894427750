import React from 'react';
import {
  AppBar,
  Avatar,
  Button, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormGroup,
  IconButton,
  InputLabel,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import Transition from '../../../dialog/transition';
import CloseIcon from '@material-ui/icons/Close';
import { SsaSlider } from './resident.edars';
import {
  red,
  yellow,
  green,
  grey,
  orange,
  blue,
} from '@material-ui/core/colors';

const contrast = 400;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1),
  },
  listItemContainer: {
    padding: theme.spacing(4),
  },
  detailsRightPanel: {
    padding: 0,
    margin: 0,
    lineHeight: 0,
  },
  avatarSmall: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  scheduled: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: grey[500],
  },
  readyToPass: {
    background: grey[700],
  },
  given: {
    background: green[contrast],
  },
  late: {
    background: orange[contrast],
  },
  missed: {
    background: red[contrast],
  },
  hold: {
    background: yellow[contrast],
  },
  sa: {
    background: blue[contrast],
  },
  na: {
    background: grey[contrast],
  },
  refused: {
    background: orange[contrast],
  },
  destruction: {
    background: yellow[contrast],
  },
  dialogContent: {
    marginTop: '4rem',
  },
  secondaryActions: {
    position: 'relative',
    right: '0',
    transform: 'translate(0)',
    paddingLeft: theme.spacing(3)
  },
}));

const EdarDetails = ({ edar }) => {
  const classes = useStyles();

  if (edar) {
    return (
      <>
        {edar.percentConsumed > 0 && (
          <Typography
            omponent="div"
            variant="caption"
            className={classes.detailsRightPanel}
          >
            Percent Consumed: {edar.percentConsumed}%
          </Typography>
        )}
        {edar.calories > 0 && (
          <Typography component="div" variant="caption">
            Calories consumed: {edar.calories}
          </Typography>
        )}
        {edar.fluidsConsumed > 0 && (
          <Typography component="div" variant="caption">
            Fluids consumed: {edar.fluidsConsumed}
          </Typography>
        )}
        {edar.administeredTimestamp && edar.administeredUser && (
          <Typography component="div" variant="caption">
            Administered:{' '}
            {moment(edar.administeredTimestamp).format('DD/MM/YYYY h:mm a')}{' '}
            {edar.administeredUser.initials}
          </Typography>
        )}
        {edar.comments && (
          <Typography component="div" variant="caption">
            Comments: {edar.comments}
          </Typography>
        )}
      </>
    );
  } else {
    return null;
  }
};

const MobileEdar = ({ edar, onUpdate, canUpdate }) => {
  const [viewEdar, setViewEdar] = React.useState(false);
  const [entity, setEntity] = React.useState(null);
  const [updating, setUpdating] = React.useState(false);

  const classes = useStyles();

  React.useEffect(() => {
    if (edar) {
      setEntity(edar);
      setUpdating(false);
    }
  }, [edar]);

  const onPercentConsumedChanged = (e, percent) => {
    const obj = { ...entity };
    obj.percentConsumed = percent;
    setEntity(obj);
  };

  const updateEntity = (e) => {
    const obj = { ...entity };
    obj[e.target.name] = e.target.value;
    setEntity(obj);
  };

  const getItemClass = (event) => {
    if (event && event.status) {
      switch (event.status) {
        case 'Scheduled':
          return classes.readyToPass;
        case 'Given':
          return classes.given;
        case 'Late':
          return classes.late;
        case 'Missed':
          return classes.missed;
        case 'Hold':
          return classes.hold;
        case 'SA':
          return classes.sa;
        case 'NA':
          return classes.na;
        case 'Refused':
          return classes.refused;
        case 'Destruction':
          return classes.destruction;
        default:
          return classes.scheduled;
      }
    }
  };

  const getAdministered = (item) => {
    if (item && item.administeredTimestamp) {
      let val = `${moment(item.administeredTimestamp).format(
        'MM/DD/YYYY h:mm a'
      )}`;
      if (item.administeredUser) {
        val += ` - ${item.administeredUser.initials}`;
      }
      return val;
    } else {
      return null;
    }
  };

  const save = () => {
    setUpdating(true);
    onUpdate(entity).then(() => {
      setUpdating(false);
      setViewEdar(false);
    })
  };

  if (entity) {
    return (
      <>
        <ListItem
          button
          onClick={() => setViewEdar(true)}
        >
          <ListItemAvatar>
            <Avatar
              alt="eDar"
              className={[classes.avatarSmall, getItemClass(entity)]}
            >
              {entity.status && entity.status[0]}
            </Avatar>
          </ListItemAvatar>
          <>
          <ListItemText
            primary={entity.mealType}
            secondary={
            <>
              {entity?.mealTypeComment &&
              <div>
                {entity?.mealTypeComment}
              </div>
              }
              <div>
                {moment(entity.scheduledTime).format('h:mm a')}
              </div>
            </>
          }
          />
            <ListItemSecondaryAction className={classes.secondaryActions}>
              <EdarDetails edar={entity} />
            </ListItemSecondaryAction>
          </>
        </ListItem>
        <Dialog
          open={viewEdar}
          TransitionComponent={Transition}
          onClose={() => setViewEdar(false)}
          fullScreen
        >
          <AppBar>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setViewEdar(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6">eDar</Typography>
            </Toolbar>
          </AppBar>
          <DialogContent style={{ marginTop: '4rem' }}>
            <form noValidate>
              <FormGroup>
                <TextField
                  label="Meal"
                  InputLabelProps={{ shrink: true }}
                  value={entity.mealType}
                  disabled
                />
                <TextField
                  label="Time"
                  InputLabelProps={{ shrink: true }}
                  value={moment(entity.scheduledTime).format('h:mm a')}
                  disabled
                />
                <FormControl>
                  <InputLabel shrink>Status</InputLabel>
                  <Select
                    id="status"
                    value={entity.status}
                    name="status"
                    onChange={updateEntity}
                    disabled={!canUpdate}
                  >
                    <MenuItem value={'Scheduled'}>Scheduled</MenuItem>
                    <MenuItem value={'Given'}>Given</MenuItem>
                    <MenuItem value={'Late'}>Late</MenuItem>
                    <MenuItem value={'Missed'}>Missed</MenuItem>
                    <MenuItem value={'Hold'}>Hold</MenuItem>
                    <MenuItem value={'SA'}>Self Administered</MenuItem>
                    <MenuItem value={'NA'}>Not Administered</MenuItem>
                    <MenuItem value={'Refused'}>Refused</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  label="Calories Consumed"
                  value={entity.calories}
                  onChange={updateEntity}
                  name="calories"
                  disabled={!canUpdate}
                />
                <SsaSlider
                  valueLabelDisplay="auto"
                  aria-label="pretto slider"
                  value={entity.percentConsumed}
                  onChange={onPercentConsumedChanged}
                  disabled={!canUpdate}
                />
                <TextField
                  label="Fluids Consumed (ml)"
                  value={entity.fluidsConsumed}
                  onChange={updateEntity}
                  name="fluidsConsumed"
                  disabled={!canUpdate}
                />
                <TextField
                  label="Comments"
                  value={entity.comments}
                  multiline
                  rowsMax={4}
                  onChange={updateEntity}
                  name="comments"
                  disabled={!canUpdate}
                />
                <TextField
                  label="Administered"
                  value={getAdministered(entity)}
                  disabled
                />
              </FormGroup>
            </form>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={() => setViewEdar(false)}>
              Close
            </Button>
            {canUpdate &&
            <Button color="primary"
                    variant="contained"
                    disabled={updating}
                    startIcon={updating ? <CircularProgress size={20} />: null}
                    onClick={save}>
              Update
            </Button>}
          </DialogActions>
        </Dialog>
      </>
    );
  } else {
    return null;
  }
};

export default MobileEdar;
