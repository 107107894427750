import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

export const ValueConfirmDialog = ({ open,
                                     onConfirm,
                                     onClose,
                                     maxWidth = "sm",
                                     fullWidth = true,
                                     title = "Please Confirm",
                                     message = "Press confirm to proceed with operation",
                                     confirmText = "Delete",
                                     confirmationValue = "delete" }) => {
  const [value, setValue] = React.useState();

  const handleChange = e => {
    setValue(e.target.value)
  }

  const isDisabled = () => {
    return value === confirmationValue;
  }


  return (
    <Dialog open={open}
            onClose={onClose}
            maxWidth={maxWidth}
            fullWidth={fullWidth}
    >
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{message}</DialogContentText>
        <TextField title={"Confirm"}
                   onChange={handleChange}
                   value={value}
                   size={"small"}
                   fullWidth
                   variant={"outlined"} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button disabled={!isDisabled()}
                variant={"contained"}
                size={"small"}
                startIcon={<DeleteIcon />}
                color={"primary"}
                onClick={onConfirm}>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )

}
