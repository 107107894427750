import React, {useContext} from 'react';
import {
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button, CircularProgress, Grid, Checkbox, FormControlLabel
} from "@material-ui/core";
import {ChevronRight } from '@material-ui/icons'
import {PageTitle} from "../../utils";
import {CareplanContext} from "./context";
import {Link as RouterLink} from 'react-router-dom';
import moment from "moment";
import AddIcon from "@material-ui/icons/Add";
import {ACTIONS} from "./context/constants";
import {printCareplansAction, selectCareplanForPrintAction, toggleAllCareplanAction} from "./context/actions";
import {GlobalNotificationContext} from "../../notification/globalnotificationprovider";
import {ResidentContext} from "../../../context/residentcontext";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import useHasRole from "../../../hooks/user.role.hook";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1)
    }
}));

export const ResidentCareplans = () => {
    const classes = useStyles();
    const {state, dispatch} = useContext(CareplanContext);
    const { resident } = useContext(ResidentContext);
    const { addNotification } = useContext(GlobalNotificationContext);
    const { checkPermission } = useHasRole();
    const canPrint = checkPermission("REPORT_PRINTER");


    const handleCreateCareplan = async () => {
        dispatch({type: ACTIONS.SET_VIEW_CREATE_CAREPLAN_DIALOG, payload: true});
    }

    const toggleChecked = (careplan) => {
        const obj = {
            ...careplan
        };
        obj.checked = !obj.checked;
        selectCareplanForPrintAction(dispatch, obj);
    }

    const toggleSelectAll = () => {
        const val = !state?.selectAll;
        dispatch({type: ACTIONS.SET_SELECT_ALL, payload: val});
        toggleAllCareplanAction(dispatch, state?.careplans, val);
    }

    const printCareplans = async () => {
        const response = await printCareplansAction(dispatch, resident, state?.careplans);
        if (response?.error) {
            addNotification("Unable to create report", "error");
        }
    }

    const careplansSelected = () => {
        return state?.careplans?.filter(c => c.checked)?.length > 0;
    }

    return (
        <Paper className={classes.root} elevation={24}>
            <PageTitle title={"Careplans"} />
            <Grid container
                  justifyContent={"space-between"}
                  >
                <Button variant={"contained"}
                        startIcon={<AddIcon />}
                        onClick={handleCreateCareplan}
                        color={"primary"}
                >
                    Add Careplan
                </Button>
                {careplansSelected() && canPrint &&
                    <Button startIcon={state?.generatingPdf ? <CircularProgress color={"#fff"} size={20} />: <PictureAsPdfIcon />}
                            variant={"contained"}
                            onClick={printCareplans}
                            disabled={state?.generatingPdf}
                            color={"primary"}>
                        Print {state?.careplans?.filter(c => c?.checked).length} Careplan(s)
                    </Button>
                }
            </Grid>
            <TableContainer>
                <Table size={"small"}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {canPrint &&
                                    <FormControlLabel
                                        value="bottom"
                                        control={<Checkbox color="primary"
                                                           checked={state?.selectAll}
                                                           onClick={toggleSelectAll} />}
                                        labelPlacement="bottom"
                                    />
                                }
                                Careplan
                            </TableCell>
                            <TableCell>
                                Area
                            </TableCell>
                            <TableCell>
                                Date
                            </TableCell>
                            <TableCell>
                                Review Date
                            </TableCell>
                            <TableCell>
                                Goals
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state?.careplans?.map((careplan, index) =>
                        <TableRow>
                            <TableCell>
                                <FormControlLabel
                                    value="bottom"
                                    control={<Checkbox color="primary"
                                                       checked={careplan.checked || ''}
                                                       onClick={() => toggleChecked(careplan)} />}
                                    labelPlacement="bottom"
                                />
                                <Button size={"small"}
                                        startIcon={<ChevronRight />}
                                        variant={"text"}
                                        component={RouterLink}
                                      to={`/residents/${state?.residentId}/careplans/${careplan?._id}`}>
                                    {careplan?.name}
                                </Button>
                            </TableCell>
                            <TableCell>
                                {careplan?.problemArea}
                            </TableCell>
                            <TableCell>
                                {moment(careplan?.careplanDate).format("MM/DD/YYYY")}
                            </TableCell>
                            <TableCell>
                                {careplan?.reviewDate && moment(careplan?.reviewDate).format("MM/DD/YYYY")}
                            </TableCell>
                            <TableCell>
                                {careplan?.goals?.map((goal, i) =>
                                    <div>
                                        <Button component={RouterLink}
                                                variant={"text"}
                                                startIcon={<ChevronRight />}
                                                size={"small"}
                                              to={`/residents/${state?.residentId}/careplans/${careplan?._id}/goals/${goal?._id}`}
                                        >
                                            {goal?.name}
                                        </Button>
                                    </div>
                                )}
                            </TableCell>
                        </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}
