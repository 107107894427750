import { ACTIONS } from './assessments.context';
import {
  createAssessment,
  createAssessmentSection,
  createSectionQuestion, createSectionQuestionOption,
  deleteAssessment,
  deleteAssessmentSection,
  deleteSectionQuestion, deleteSectionQuestionOption,
  getAssessment,
  getAssessments,
  getAssessmentTypes,
  getQuestionTypes,
  orderSection, orderSectionQuestion, orderSectionQuestionOption,
  updateAssessment,
  updateAssessmentSection,
  updateSectionQuestion, updateSectionQuestionOption
} from "../../services/assessment.service";
import {ObjectId} from "bson";
import {getQuestionParent} from "./utils";
import {convertToFilters} from "../../utils/filter.utils";
import {SET_LOADING} from "../resident/intake/page/context/intake.constants";

const selectAssessmentAction = async (dispatch) => {

}

const getAssessmentsAction = async (dispatch, page = 1, size = 25,  sort = 'name', sortDirection = 'asc', filter, tenantId) => {
  dispatch({type: SET_LOADING, payload: true});
  dispatch({type: ACTIONS.SET_ERROR, payload: false});
  const filters =  [...convertToFilters(filter), {
    field: 'tenant',
    operator: '==',
    value: tenantId
  }];
  const assessmentsResult = await getAssessments(page, size, filters, sort, sortDirection );
  if (!assessmentsResult?.error) {
    dispatch({type: ACTIONS.GET_ASSESSMENTS, payload: assessmentsResult, loading: false});
  } else {
    dispatch({type: ACTIONS.SET_ERROR, payload: true});
  }
  dispatch({type: SET_LOADING, payload: false});
}
const getAssessmentAction = async (id, dispatch, types) => {
  dispatch({type: ACTIONS.SELECT_ASSESSMENT_OBJECT, payload: null})
  dispatch({type: ACTIONS.LOADING})
  if (!types || types?.length === 0) {
    const responses = await Promise.all([
      getAssessment(id),
      getAssessmentTypes()
    ]);
    dispatch({type: ACTIONS.SET_ASSESSMENT_AND_TYPES, payload: { assessment: responses[0], types: responses[1]}});
  } else {
    const assessment = await getAssessment(id);
    dispatch({type: ACTIONS.GET_ASSESSMENT, payload: assessment});
  }
}
const updateAssessmentAction = async (id, assessment, dispatch) => {
  dispatch({type: ACTIONS.UPDATING_ASSESSMENT_SUMMARY})
  const result = await updateAssessment(id, assessment);
  dispatch({type: ACTIONS.UPDATE_ASSESSMENT, payload: result});
}
const deleteAssessmentAction = async (id, dispatch) => {
  dispatch({type: ACTIONS.LOADING})
  const result = await deleteAssessment(id);
  if (result?.ok) {
    dispatch({type: ACTIONS.DELETE_ASSESSMENT, payload: id});
  } else {

  }
}
const createAssessmentAction = async (assessment, dispatch) => {
  dispatch({type: ACTIONS.LOADING})
  const result = await createAssessment(assessment);
  if (result) {
    dispatch({type: ACTIONS.CREATE_ASSESSMENT, payload: result});
  }
}

const getAssessmentTypesAction = async (dispatch) => {
  const types = await getAssessmentTypes();
  dispatch({type: ACTIONS.GET_ASSESSMENT_TYPES, payload: types });
}

const onCloseCreateModalAction = (val, dispatch) => {
  dispatch({type: ACTIONS.SET_SHOW_CREATE_ASSESSMENT_MODAL, payload: val});
}

const onAddQuestionGroupClickAction = (dispatch) => {
  const newGroup = {
    isNew: true,
    _id: new ObjectId().toHexString(),
    name: 'New Group',
  }
  dispatch({type: ACTIONS.START_ADD_QUESTION_GROUP, payload: newGroup});
}

const onDeleteQuestionGroupClickAction = (id, dispatch) => {
  dispatch({type: ACTIONS.DELETE_QUESTION_GROUP, payload: id});
}

const onAddSectionAction = async (assessment, dispatch) => {
  const obj = {...assessment};
  if (!obj.questionGroups) {
    obj.questionGroups = [];
  }
  let maxValueOfSortOrder = obj?.questionGroups?.length + 1;
  if (isNaN(maxValueOfSortOrder)) {
    maxValueOfSortOrder = 1;
  }
  const newSection = { _id: new ObjectId().toHexString(), name: null, type: 'section' }
  newSection.sortOrder = maxValueOfSortOrder;
  const section = await createAssessmentSection(assessment?._id, newSection);
  dispatch({ type: ACTIONS.ADD_SECTION, payload: section });
}

const onSelectAssessmentObjectAction = (obj, dispatch) => {
  dispatch({type: ACTIONS.SELECT_ASSESSMENT_OBJECT, payload: obj});
}

const onSaveSectionAction = async (assessment, sectionId, section, dispatch) => {
  const response = await updateAssessmentSection(assessment?._id, sectionId, section);
  if (response) {
    dispatch({type: ACTIONS.UPDATE_SECTION_METADATA, payload: { sectionId, response }})
  } else {
    // error
  }
}

const onDeleteSectionAction = async (assessment, sectionId, dispatch) => {
  const result = await deleteAssessmentSection(assessment?._id, sectionId);
  if (result?.ok) {
    dispatch({type: ACTIONS.DELETE_SECTION, payload: sectionId})
  }
}

const onOrderSectionUpAction = async (section, assessment, dispatch) => {
  const response = await orderSection(assessment?._id, section?._id, section.sortOrder, section.sortOrder - 1);
  if (response) {
    const obj = {...assessment};
    const sortOrder = section.sortOrder - 1;
    obj.questionGroups = obj.questionGroups.map(group => {
      if (group.sortOrder === sortOrder) {
        group.sortOrder = group.sortOrder + 1;
      }
      if (group._id === section._id) {
        group.sortOrder = sortOrder;
      }
      return group;
    })
    dispatch({type: ACTIONS.ORDER_SECTION_UP, payload: obj});
  }
}

const onOrderSectionDownAction = async (section, assessment, dispatch) => {
  const response = await orderSection(assessment?._id, section?._id, section.sortOrder, section.sortOrder + 1);
  if (response) {
    const obj = {...assessment};
    const sortOrder = section.sortOrder + 1;
    obj.questionGroups = obj.questionGroups.map(group => {
      if (group.sortOrder === sortOrder) {
        group.sortOrder = group.sortOrder - 1;
      }
      if (group._id === section._id) {
        group.sortOrder = sortOrder;
      }
      return group;
    })
    dispatch({type: ACTIONS.ORDER_SECTION_DOWN, payload: obj});
  }
}

const onAddQuestion = async (assessment, section, dispatch) => {
  if (!section?.questions) {
    section.questions = [];
  }
  let maxValueOfSortOrder = 1;
  maxValueOfSortOrder = section.questions.length + 1;
  if (isNaN(maxValueOfSortOrder)) {
    maxValueOfSortOrder = 1;
  }
  const question = { _id: new ObjectId().toHexString(),
    label: null,
    objectType: 'question',
    type: { name: "Text", description: "Any type of text" },
    sortOrder: maxValueOfSortOrder,
    options: []
  }
  const response = await createSectionQuestion(assessment?._id, section?._id, question);
  if (response) {
    dispatch({type: ACTIONS.ADD_QUESTION, payload: { section, question: response }});
  }
}

const getQuestionTypesAction = async (dispatch) => {
  const types = await getQuestionTypes();
  dispatch({type: ACTIONS.SET_QUESTION_TYPES, payload: types });
}

const onUpdateQuestionAction = async (assessment, questionId, question, dispatch) => {
  const section = assessment?.questionGroups?.find(qg => qg?.questions?.findIndex(q => q?._id === questionId) > -1);
  const response = await updateSectionQuestion(assessment?._id, section?._id, questionId, question);
  dispatch({type: ACTIONS.UPDATE_QUESTION, payload: response });
}

const onDeleteQuestionAction = async (assessment, questionId, dispatch) => {
  const section = getQuestionParent(assessment, questionId);
  const response = await deleteSectionQuestion(assessment?._id, section?._id, questionId);
  if (response?.ok) {
    dispatch({type: ACTIONS.DELETE_QUESTION, payload: questionId });
  }
}

const onOrderQuestionUpAction = async (assessment, question, dispatch) => {
  const payload = {
    newSortOrder: question?.sortOrder - 1,
    oldSortOrder: question?.sortOrder
  }
  const section = getQuestionParent(assessment, question?._id);
  const response = await orderSectionQuestion(assessment?._id, section?._id, question?._id, payload);
  if (response) {
    dispatch({type: ACTIONS.ORDER_QUESTION_UP, payload: { assessment, question: response, payload }});
  }
}

const onOrderQuestionDownAction = async (assessment, question, dispatch) => {
  const payload = {
    newSortOrder: question?.sortOrder + 1,
    oldSortOrder: question?.sortOrder
  }
  const section = getQuestionParent(assessment, question?._id);
  const response = await orderSectionQuestion(assessment?._id, section?._id, question?._id, payload);
  if (response) {
    dispatch({type: ACTIONS.ORDER_QUESTION_DOWN, payload: { assessment, question: response, payload }});
  }
}

const setCreateOptionModelAction = (dispatch, val) => {
  dispatch({type: ACTIONS.SHOW_ADD_OPTION_MODAL, payload: val });
}

const createQuestionOptionAction = async (assessment, question, option, dispatch) => {
  const section = getQuestionParent(assessment, question?._id);
  const response = await createSectionQuestionOption(assessment?._id, section?._id, question?._id, option);
  if (response) {
    dispatch({type: ACTIONS.ADD_OPTION, payload: {question, option}});
  }
}

const updateQuestionOptionAction = async (assessment, question, optionId, option, dispatch) => {
  const section = getQuestionParent(assessment, question?._id);
  const response = await updateSectionQuestionOption(assessment?._id, section?._id, question?._id, optionId, option);
  if (response) {
    dispatch({type: ACTIONS.UPDATE_OPTION, payload: {question, option}});
  }
};

const deleteQuestionOptionAction = async (assessment, question, optionId, dispatch) => {
  const section = getQuestionParent(assessment, question?._id);
  const response = await deleteSectionQuestionOption(assessment?._id, section?._id, question?._id, optionId);
  if (response) {
    dispatch({type: ACTIONS.DELETE_OPTION, payload: {question, optionId}});
  }
}

const onOrderQuestionOptionUpAction = async (assessment, question, option, dispatch) => {
  if (isNaN(option?.sortOrder) || !option.sortOrder) {
    option.sortOrder = 2;
  }
  const payload = {
    newSortOrder: option?.sortOrder - 1,
    oldSortOrder: option?.sortOrder
  }
  const section = getQuestionParent(assessment, question?._id);
  const response = await orderSectionQuestionOption(assessment?._id, section?._id, question?._id, option?._id, payload);
  if (response) {
    dispatch({type: ACTIONS.ORDER_OPTION_UP, payload: { assessment, question: question, option: response, payload }});
  }
}

const onOrderQuestionOptionDownAction = async (assessment, question, option, dispatch) => {
  if (isNaN(option?.sortOrder || !option.sortOrder)) {
    option.sortOrder = 0;
  }
  const payload = {
    newSortOrder: option?.sortOrder + 1,
    oldSortOrder: option?.sortOrder
  }
  const section = getQuestionParent(assessment, question?._id);
  const response = await orderSectionQuestionOption(assessment?._id, section?._id, question?._id, option?._id, payload);
  if (response) {
    dispatch({type: ACTIONS.ORDER_OPTION_DOWN, payload: { assessment, question: question, option: response, payload }});
  }
}

const onCopyQuestionAction = async (assessment, question, dispatch) => {
  const section = getQuestionParent(assessment, question?._id);
  if (section) {
    const newQuestion = { ...question };
    newQuestion._id = new ObjectId();
    newQuestion.sortOrder = section?.questions?.length + 1;
    newQuestion.label = `Copy of (${question.label})`;
    const entity = await createSectionQuestion(assessment?._id, section?._id, newQuestion);
    if (entity) {
      const payload = {
        section,
        question: entity
      }
      dispatch({type: ACTIONS.COPY_QUESTION, payload });
    }
  }
}

const onSortAction = (field, direction, dispatch) => {
  dispatch({type: ACTIONS.SET_SORT_ORDER, payload: { field, direction }});
}

export {
  getAssessmentAction,
  getAssessmentsAction,
  updateAssessmentAction,
  deleteAssessmentAction,
  selectAssessmentAction,
  createAssessmentAction,
  getAssessmentTypesAction,
  onCloseCreateModalAction,
  onAddQuestionGroupClickAction,
  onDeleteQuestionGroupClickAction,
  onAddSectionAction,
  onSelectAssessmentObjectAction,
  onSaveSectionAction,
  onDeleteSectionAction,
  onOrderSectionUpAction,
  onOrderSectionDownAction,
  onAddQuestion,
  onUpdateQuestionAction,
  onDeleteQuestionAction,
  onOrderQuestionUpAction,
  onOrderQuestionDownAction,
  getQuestionTypesAction,
  setCreateOptionModelAction,
  createQuestionOptionAction,
  updateQuestionOptionAction,
  deleteQuestionOptionAction,
  onOrderQuestionOptionUpAction,
  onOrderQuestionOptionDownAction,
  onCopyQuestionAction,
  onSortAction
}
