import React from 'react';
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  makeStyles,
  TextField,
  TableCell,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

const days = [];
for (let i = 1; i < 32; i++) {
  days.push({ value: i, title: i });
}

const getStyles = makeStyles((theme) => ({
  root: {
    margin: '0 1rem 0 0',
    padding: theme.spacing(1),
    minWidth: '20rem',
  },
  form: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  formGroup: {
    marginTop: theme.spacing(1),
  },
  formControl: {
    width: '100%',
  },
}));

const MonthlyEvent = (props) => {
  const classes = getStyles();
  const [eventTemplate, setEventTemplate] = React.useState(
    props.eventTemplate || {}
  );
  const [passingQty, setPassingQty] = React.useState(
    props.eventTemplate ? props.eventTemplate.passingQty : null
  );
  const initDate = new Date();
  initDate.setHours(8, 0);
  const [selectedDate, handleDateChange] = React.useState(initDate);
  React.useEffect(() => {
    setEventTemplate(props.eventTemplate || {});
  }, [props.eventTemplate]);

  const onDayChange = (e) => {
    const updated = { ...eventTemplate };
    updated.day = e.target.value;
    setEventTemplate(updated);
    emitUpdate(updated);
  };

  const onTimeChange = (date) => {
    handleDateChange(date);
    const dt = moment(date);
    const hour = moment(dt).format('H');
    const minute = moment(dt).format('mm');
    const updated = { ...eventTemplate, hour, minute };
    setEventTemplate(updated);
    emitUpdate(updated);
  };

  const onQtyChange = (e) => {
    if (!e.target.value || e.target.value === "." || !isNaN(e.target.value)) {
      let val = e.target.value.trim();
      setPassingQty(val);
      const updated = {...eventTemplate, passingQty: val};
      setEventTemplate(updated);
      emitUpdate(updated);
    }
  };

  const emitUpdate = (eventTemplate) => {
    if (props.onChange) {
      props.onChange(eventTemplate);
    }
  };

  return (
    <React.Fragment>
      <TableCell>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="day">Day</InputLabel>
          <Select
            value={eventTemplate.day || null}
            onChange={onDayChange}
            inputProps={{
              name: `day`,
              id: `day`,
            }}
          >
            {days &&
              days.map((day, i) => {
                return (
                  <MenuItem key={i} value={day.value}>
                    {day.title}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <TimePicker
            label="Select Time"
            format="h:mm a"
            value={selectedDate}
            onChange={onTimeChange}
            ampm={false}
            disabled={!props.edit}
          />
        </MuiPickersUtilsProvider>
      </TableCell>
      <div hidden={!props.showQty}>
        <TableCell>
          <TextField
            label="Quantity"
            value={passingQty}
            onChange={onQtyChange}
            disabled={!props.edit}
          />
        </TableCell>
      </div>
    </React.Fragment>
  );
};

export default MonthlyEvent;
