import React from 'react';
import {Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@material-ui/core";
import TableLoadingIndicator from "../../../utils/indicators/table.loading";
import AddIcon from "@material-ui/icons/Add";
import {ConfirmHoldDates} from "./dialogs/ConfirmHoldDates";
import moment from "moment";
import {
  createMedicationOrderHold,
  deleteMedicationOrderHold,
  updateMedicationOrderHold
} from "../../../services/medication.order.service";
import useGlobalNotification from "../../../hooks/notification.hook";
import {Edit} from "@material-ui/icons";
import ConfirmDialog from "../../utils/dialogs/confirm.dialog";
import {EditHold} from "./dialogs/EditHold";
import {UserContext} from "../../../context/usercontext";

export const MedicationOrderHolds = ({medicationOrder, onHoldChange}) => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [addHoldModal, setAddHoldModal] = React.useState();
  const [order, setOrder] = React.useState();
  const [selectedHold, setSelectedHold] = React.useState();
  const [viewConfirmDeleteModal, setViewConfirmDeleteModal] = React.useState(false);
  const [editHoldModal, setEditHoldModal] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const { getUsers } = React.useContext(UserContext);
  const { addNotification } = useGlobalNotification();

  React.useEffect(() => {
    const getData = () => {
      setLoading(true);
      setError(false);
      if (medicationOrder) {
        setOrder(medicationOrder);
      } else {
        setOrder(null);
        setError(true);
      }
      setLoading(false);
    }
    const loadUsers = async () => {
      const usersResponse = await getUsers();
      if (!usersResponse?.error) {
        setUsers(usersResponse?.sort((a, b) => a?.lastName > b?.lastName ? 1: -1));
      }
    }
    if (medicationOrder) {
      getData(medicationOrder);
      if (users?.length < 1) {
        loadUsers();
      }
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [medicationOrder, users]);

  const onSaveHold = async payload => {
    const response = await createMedicationOrderHold(order?._id, {
      startDate: payload?.startDate,
      endDate: payload?.endDate,
      comment: payload?.comment
    })
    if (!response?.error) {
      setAddHoldModal(false);
      const obj = {...order};
      if (!obj?.holds) {
        obj.holds = []
      };
      obj.holds = [...obj.holds, response]
      setOrder(obj);
      onHoldChange(obj);
      setAddHoldModal(false);
    } else {
      addNotification("Unable to create hold");
    }
  }

  const onEditHold = hold => {
    setSelectedHold(hold);
    setEditHoldModal(true);
  }

  const onDeleteClick = () => {
    setEditHoldModal(false);
    setViewConfirmDeleteModal(true);
  }

  const onDeleteCofirmed = async () => {
    const response = await deleteMedicationOrderHold(medicationOrder?._id, selectedHold?._id);
    if (!response?.error) {
      const obj = {...order};
      if (!obj?.holds) {
        obj.holds = []
      };
      obj.holds = obj.holds.filter(h => h?._id !== selectedHold?._id);
      setOrder(obj);
      onHoldChange(obj);
      setSelectedHold(null);
      setViewConfirmDeleteModal(false);
    } else {

    }
  }

  const onHoldUpdate = async (id, hold) => {
    const response = await updateMedicationOrderHold(medicationOrder?._id, id, hold);
    if (!response?.error) {
      const obj = {...order};
      obj.holds = obj.holds.map(h => h?._id === response?._id ? response: h);
      setOrder(obj);
      onHoldChange(obj);
      setSelectedHold(null);
      setEditHoldModal(false);
    } else {

    }
  }

  return (
    <div>
      <Typography variant={"h6"}>
        Holds
      </Typography>
      <ConfirmDialog open={viewConfirmDeleteModal}
                     onClose={() => setViewConfirmDeleteModal(false)}
                     onConfirm={onDeleteCofirmed}
                     title={"Delete Hold"}
                     message={"Please confirm if you want to delete this hold."}

      />
      <EditHold onClose={() => setEditHoldModal(false)}
                open={editHoldModal}
                hold={selectedHold}
                onUpdate={onHoldUpdate}
                onDelete={onDeleteClick}
                users={users}
      />

      <TableContainer>
        <Table size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={5}>
                <Button variant={"text"}
                        color={"primary"}
                        onClick={() => setAddHoldModal(true)}
                        startIcon={<AddIcon />}>
                  Add Hold
                </Button>
                <ConfirmHoldDates open={addHoldModal}
                                  onSave={onSaveHold}
                                  users={users}
                                  onClose={() => setAddHoldModal(false)} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width={205}></TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Comment</TableCell>
              <TableCell>Created By</TableCell>
            </TableRow>
          </TableHead>
          {loading && !error && <TableLoadingIndicator rows={4} cols={4} bodyOnly={true} />}
          <TableBody>
            {!loading && !error && order?.holds?.map(hold =>
              <TableRow>
                <TableCell>
                  <Button variant={"text"}
                          size={"small"}
                          color={"primary"}
                          onClick={() => onEditHold(hold)}
                          startIcon={<Edit />}>
                    View
                  </Button>
                </TableCell>
                <TableCell>
                  {moment(hold?.startDate).format("MM/DD/YYYY hh:mma")}
                </TableCell>
                <TableCell>
                  {moment(hold?.endDate).format("MM/DD/YYYY hh:mma")}
                </TableCell>
                <TableCell>
                  {hold?.comment}
                </TableCell>
                <TableCell>
                  {hold?.createdBy?.firstName} {hold?.createdBy?.lastName}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
