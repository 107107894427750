import React from 'react'
import {
    TableCell,
    TableRow,
  } from "@material-ui/core";
  import { Skeleton } from "@material-ui/lab";

const height = 30;

const getColumns = (columns) => {
    const cols = [];
    for(let i = 0; i < columns; i++) {
        cols.push(<TableCell key={`skeleton-cell-${i}`}><Skeleton variant="text" height={height} /></TableCell>)
    }
    return cols
}

const getSkeltons = (rows, columns) => {
    const skeletons = [];
    for (let i = 0; i < rows; i++) {
        skeletons.push(
            <TableRow key={`skeleton-row-${i}`}>{getColumns(columns)}</TableRow>
        );
    }
    return skeletons;
}


const ResidentTableSkeleton = ({rows, columns}) => {
    return getSkeltons(rows, columns);
}
export default ResidentTableSkeleton;
