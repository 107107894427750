import React from "react";
import { View, Text, Image, StyleSheet } from "@react-pdf/renderer";
import config from '../../../../utils/configs';

const styles = StyleSheet.create({
  container: {
    padding: "5px",
    backgroundColor: "#2196f3",
    color: "#fff",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: 65,
  },
  reportTitle: {
    marginTop: 10,
    fontSize: 20,
  },
  reportSubtitle: {
    marginTop: 2,
    fontSize: 12,
  },
  addressContainer: {
    paddingRight: "5px",
  },
  address: {
    fontSize: "10px",
  },
  logoContainer: {
    margin: 5,
  },
  logo: {
    height: "35px",
    width: "60px",
  },
});

const HeaderWithTenant = ({ reportTitle, reportSubtitle = null, tenant, location, allUserLocations = false,
                          }) => {
  const buildImgSrc = () => {
    const imageUrl = `${config.publicBucketUrl}/${config.publicBucketName}/tenants/${tenant?._id}/logos/${tenant?.logo}`;
    return imageUrl;
  };

  return (
    <View fixed style={styles.container}>
      <View style={styles.reportTitle}>
        <Text>{reportTitle}</Text>
        {reportSubtitle &&
          <View style={styles.reportSubtitle}>
            <Text>{reportSubtitle}</Text>
          </View>
        }
      </View>
      <View style={{ display: "flex", flexDirection: "row" }}>
        <View style={styles.addressContainer}>
          <Text style={styles.address}>{tenant?.name}</Text>
          {!allUserLocations && (
            <Text style={styles.address}>{location?.name}</Text>
          )}
          <Text style={styles.address}>{tenant?.address?.address}</Text>
          {location?.address?.address2 && (
            <Text style={styles.address}>{tenant?.address?.address2}</Text>
          )}
          <Text style={styles.address}>
            {tenant?.address?.city}, {tenant?.address?.state}{" "}
            {tenant?.address?.zipCode}
          </Text>
        </View>
        <View style={styles.logoContainer}>
          <Image style={styles.logo} src={buildImgSrc()} />
        </View>
      </View>
    </View>
  );
};

export default HeaderWithTenant;
