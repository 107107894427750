export const isModelValid = (model) => {
  if (model) {
    let valid = true;
    Object.keys(model).forEach((key) => {
      if (
        model[key].required &&
        (model[key].value === null ||
          model[key].value === undefined ||
          String(model[key].value).length === 0)
      ) {
        valid = false;
      }
    });
    return valid;
  } else {
    return false;
  }
};
