import React from 'react';
import { Switch } from 'react-router-dom';
import { UserContext } from '../../context/usercontext';
import PrivateRoute from '../PrivateRoute';
import Tenant from './tenant';
import TenantList from './tenant.list';
import TenantWrapper from './tenant.wrapper';

const Tenants = () => {
  const { state } = React.useContext(UserContext);

  if (state && state.user && state.user.isSystemAdmin) {
    return (
      <>
        <Switch>
          <PrivateRoute exact path="/tenants" component={TenantList} />
          <PrivateRoute exact path="/tenants/:id" component={TenantWrapper} />
        </Switch>
      </>
    );
  } else {
    return <Tenant tenantId={state?.selectedTenant?._id} />;
  }
};

export default Tenants;
