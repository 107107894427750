import apiUtil from '../utils/api.utils';
import config from '../utils/configs';
import {parseFiltersToJson} from "../utils/filter.utils";
const physicianEndpoint = `${config.residentService}/api/v1.0/physicians`;

export const getPhysicians = async (
  page = 1,
  size = 25,
  filters = [],
  sort = 'lastName',
  sortDirection = 'asc'
) => {
  try {
    let sortArg = sortDirection === "asc" ? "+" : "-";
    sortArg += sort;
    let url = `${physicianEndpoint}?page=${page}&size=${size}&sort=${sortArg}`;
    if (filters && filters.length > 0) {
      url += parseFiltersToJson(filters);
    }
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (error) {
    return { error: true, message: error};
  }
};


export const createPhysician = async (physician) => {
  try {
    const response = await apiUtil.client.post(physicianEndpoint, physician);
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
};

export const getPhysician = async (physicianId) => {
  try {
    const response = await apiUtil.client.get(`${physicianEndpoint}/${physicianId}`);
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
}

export const updatePhysician = async (physicianId, physician) => {
  try {
    const response = await apiUtil.client.put(`${physicianEndpoint}/${physicianId}`, physician);
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
}

export const createPhysicianAddress = async (physicianId, address) => {
  try {
    const response = await apiUtil.client.post(`${physicianEndpoint}/${physicianId}/addresses`, address);
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
}

export const updatePhysicianAddress = async (physicianId, addressId, address) => {
  try {
    const response = await apiUtil.client.put(`${physicianEndpoint}/${physicianId}/addresses/${addressId}`, address);
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
}

export const deletePhysicianAddress = async (physicianId, addressId) => {
  try {
    const response = await apiUtil.client.delete(`${physicianEndpoint}/${physicianId}/addresses/${addressId}`);
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
}

export const createPhysicianMedicalProvider = async (physicianId, medicalProvider) => {
  try {
    const response = await apiUtil.client.post(`${physicianEndpoint}/${physicianId}/medicalproviders`, medicalProvider);
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
}

export const updatePhysicianMedicalProvider = async (physicianId, medicalProviderId, medicalProvider) => {
  try {
    const response = await apiUtil.client.put(`${physicianEndpoint}/${physicianId}/medicalproviders/${medicalProviderId}`, medicalProvider);
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
}

export const deletePhysicianMedicalProvider = async (physicianId, medicalProviderId) => {
  try {
    await apiUtil.client.delete(`${physicianEndpoint}/${physicianId}/medicalproviders/${medicalProviderId}`);
    return true;
  } catch (error) {
    alert(error);
    return false;
  }
}


export const createPhysicianPhone = async (physicianId, phone) => {
  try {
    const response = await apiUtil.client.post(`${physicianEndpoint}/${physicianId}/phones`, phone);
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
}

export const updatePhysicianPhone = async (physicianId, phoneId, phone) => {
  try {
    const response = await apiUtil.client.put(`${physicianEndpoint}/${physicianId}/phones/${phoneId}`, phone);
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
}

export const deletePhysicianPhone = async (physicianId, phoneId) => {
  try {
    const response = await apiUtil.client.delete(`${physicianEndpoint}/${physicianId}/phones/${phoneId}`);
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
}
