export const SummaryModel = {
  date: {
    title: 'Date',
    required: true,
    value: Date.now(),
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: null,
    dropDownItemLabel: null,
    time: true,
    showDescription: true
  },
  type: {
    title: 'Type',
    required: true,
    value: 'Admission',
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [
      { name: 'Select a type', disabled: true, value: '' },
      { name: 'Admission', value: 'Admission'},
      { name: 'Readmit', disabled: true, value: 'Readmit' },
      { name: 'Discharge', disabled: true, value: 'Discharge' },
      { name: 'Transfer', disabled: true, value: 'Transfer' },
      { name: 'LOA', disabled: true, value: 'LOA'}
    ],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
  },
  reason: {
    title: 'Reason',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: null,
    dropDownItemLabel: null,
    multiline: true,
    multilineRows: 5
  },
  comment: {
    title: 'Comment',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: null,
    dropDownItemLabel: null,
    multiline: true,
    multilineRows: 5
  },
  updateStatus: {
    hidden: true,
    title: "Update Resident Status",
    required: false,
    value: true,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
    checkbox: true
  },
};

