import React, {useContext} from 'react';
import {
    Button,
    ButtonGroup,
    DialogActions,
    Divider,
    FormGroup,
    makeStyles,
    TextField,
    Typography
} from "@material-ui/core";
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment";
import ChipInput from "material-ui-chip-input";
import {ACTIONS, TimeEntryContext} from "../../../context/TimeEntryContext/time.entry.context";
import MomentUtils from "@date-io/moment";
import {Add, ChevronLeft} from "@material-ui/icons";

const createTimeEntryModel = {
    date: moment().utc().set({hours: 0, minutes: 0, seconds: 0, milliseconds: 0}),
    comment: null,
    tags: [],
    totalMinutes: 0,
    totalMinutesInput: "0"
}

const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(1)
  },
  actions: {
    position: 'absolute',
    left: 0,
    right:0,
    bottom: 0
  }
}))

export const TimeEntryMobileCreate = ({onSave}) => {
  const classes = useStyles();
    const {dispatch, state} = useContext(TimeEntryContext);
    const [model, setModel] = React.useState(JSON.parse(JSON.stringify(createTimeEntryModel)));


    React.useEffect(() => {
        if (state?.viewTimeEntryCreateModal) {
            setModel(JSON.parse(JSON.stringify(createTimeEntryModel)));
        }
    }, [state?.viewTimeEntryCreateModal]);

    const closeModal = () => {
        dispatch({type: ACTIONS.SET_VIEW_TIME_ENTRY_CREATE_MODAL, payload: false});
    }

    const onCommentChange = e => {
        const obj = {...model};
        obj.comment = e.target.value;
        setModel(obj)
    }

    const onStartChange = date => {
        const obj = {...model};
        obj.start = date;
        setModel(obj)
    }

    const onEndChange = date => {
        const obj = {...model};
        obj.end = date;
        if (date === null || date === undefined) {
            obj.totalMinutes = null;
            obj.totalMinutesInput = "0"
        } else if (obj.start && moment(obj.start).isValid() && moment(date).isValid()) {
            let difMinutes = moment.duration(moment(date).diff(obj.start));
            obj.totalMinutes = difMinutes.asMinutes();
            obj.totalMinutesInput = `${difMinutes.hours()}:${difMinutes.minutes().toLocaleString("en-us",
                {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                })
            }`;
        }

        setModel(obj)
    }

    const onTotalChanged = (e) => {
        let obj = {...model};
        obj.totalMinutesInput = e.target.value;
        setModel(obj);
    }

    const validateTotalTime = () => {
        let durationInput = model?.totalMinutesInput;
        let hourOnly = false;
        if (Number(durationInput) === Math.floor(durationInput)) {
            hourOnly = true;
            durationInput = `${durationInput}:00`
        }
        let duration = moment.duration(durationInput);
        if (duration?.hours() > 0 || duration?.minutes() > 0) {
            let newEnd = moment(model?.start).clone();
            newEnd.add(duration.hours(), 'hours');
            newEnd.add(duration.minutes(), 'minutes');
            let obj = {...model};
            obj.totalMinutes = duration.asMinutes();
            obj.end = newEnd;
            if (hourOnly) {
                obj.totalMinutesInput = durationInput;
            }
            setModel(obj);
        } else {
            // Not valid, do not update.
            let existingDuration = moment.duration(model?.totalMinutes, "minutes");
            let obj = {...model};
            obj.totalMinutesInput = `${existingDuration.hours()}:${existingDuration.minutes().toLocaleString("en-us",
                {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                })
            }`;
            setModel(obj);
        }
    }

    const isValid = () => {
        return model?.start && moment(model.start).isValid() && (!model?.end || moment(model.end).isValid());
    }

    const onCreate = async () => {
        // set time to 0
        model.date = moment(model.date).utc().set({hours: 0, minutes: 0, seconds: 0, milliseconds: 0});
        model.start = moment(model.start).set({month: moment(model.date).month(), date: moment(model.date).date()});
        model.end = moment(model.end).set({month: moment(model.date).month(), date: moment(model.date).date()});
        await onSave(model);
        setModel(JSON.parse(JSON.stringify(createTimeEntryModel)));
        closeModal();
    }

    const onEventDateChange = date => {
        const obj = {...model};
        obj.date = date;
        setModel(obj);
    }

    const handleTagAdd = tag => {
        const obj = {...model};
        obj.tags = [...obj.tags, tag];
        setModel(obj);
    }

    const handleTagDelete = tag => {
        const obj = {...model};
        obj.tags = obj?.tags?.filter(t => t !== tag);
        setModel(obj);
    }

    const handleCancel = () => {
        setModel(JSON.parse(JSON.stringify(createTimeEntryModel)));
        closeModal();
    }

    const goBack = () => {
      dispatch({type: ACTIONS.SET_VIEW_TIME_ENTRY_CREATE_MODAL, payload: false });
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div className={classes.form}>
            <Typography variant={"h6"}>
              Add time entry
            </Typography>
            <Button startIcon={<ChevronLeft />} onClick={goBack}>
              Back to list
            </Button>
              <FormGroup>
                  <KeyboardDatePicker value={model?.date && moment(model.date).utc()}
                                      onChange={onEventDateChange}
                                      style={{margin: '.5rem 0'}}
                  />
                  <KeyboardTimePicker
                      size={"small"}
                      fullWidth
                      mask="__:__ _M"
                      placeholder={"08:00 AM"}
                      label={"Start"}
                      value={model?.start || null}
                      name={"start"}
                      onChange={onStartChange}
                      style={{margin: '.5rem 0'}}
                  />
                  <KeyboardTimePicker
                      size={"small"}
                      fullWidth
                      mask="__:__ _M"
                      placeholder={"05:00 PM"}
                      label={"End"}
                      value={model?.end || null}
                      name={"end"}
                      onChange={onEndChange}
                      style={{margin: '.5rem 0'}}
                  />
                  <TextField label={"Total"}
                             fullWidth
                             size={"small"}
                             onChange={onTotalChanged}
                             onBlur={validateTotalTime}
                             style={{margin: '.5rem 0'}}
                             value={model?.totalMinutesInput}/>
                  <ChipInput
                      size={"small"}
                      color={"primary"}
                      label={"Tags"}
                      fullWidth
                      InputLabelProps={{shrink: true}}
                      disableUnderline
                      fullWidthInput
                      blurBehavior={"add"}
                      value={model?.tags}
                      onAdd={handleTagAdd}
                      onDelete={handleTagDelete}
                      dataSource={null}
                      style={{margin: '.5rem 0'}}
                  />
                  <TextField size={"small"}
                             fullWidth
                             onChange={onCommentChange}
                             InputProps={{disableUnderline: true}}
                             InputLabelProps={{shrink: true}}
                             style={{margin: '.5rem 0'}}
                             label={"Comment"}
                             multiline={true}
                             value={model?.comment}/>
              </FormGroup>
          </div>
          <div className={classes.actions}>
            <Divider />
            <DialogActions>
                <ButtonGroup fullWidth>
                    <Button variant={"text"}
                            onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button color={"primary"}
                            variant={"contained"}
                            disabled={!isValid()}
                            onClick={onCreate}
                            startIcon={<Add />}>Save</Button>
                </ButtonGroup>
            </DialogActions>
          </div>
        </MuiPickersUtilsProvider>
    )
}
