export const ACTIONS = {
    SET_RESIDENT_ID: "SET_RESIDENT_ID",
    SET_LOADING: "SET_LOADING",
    SET_ERROR: "SET_ERROR",
    SET_STATUS: "SET_STATUS",
    SET_SELECT_ALL: "SET_SELECT_ALL",
    SET_CAREPLANS: "SET_CAREPLANS",
    SET_GENERATING_PDF: "GENERATING_PDF",
    SET_VIEW_CAREPLAN_DIALOG: "SET_VIEW_CAREPLAN_DIALOG",
    SET_VIEW_GOAL_DIALOG: "SET_VIEW_GOAL_DIALOG",
    SET_VIEW_INTERVENTION_DIALOG: "SET_VIEW_INTERVENTION_DIALOG",
    SET_VIEW_CREATE_CAREPLAN_DIALOG: "SET_VIEW_CREATE_CAREPLAN_DIALOG",
    SET_VIEW_CREATE_GOAL_DIALOG: "SET_VIEW_CREATE_GOAL_DIALOG",
    ADD_GOAL: "ADD_GOAL",
    UPDATE_GOAL: "UPDATE_GOAL",
    REMOVE_GOAL: "REMOVE_GOAL",
    ADD_INTERVENTION: "ADD_INTERVENTION",
    UPDATE_INTERVENTION: "UPDATE_INTERVENTION",
    ADD_CAREPLAN: "ADD_CAREPLAN",
    REMOVE_CAREPLAN: "REMOVE_CAREPLAN",
    SET_VIEW_CREATE_INTERVENTION_DIALOG: "SET_VIEW_CREATE_INTERVENTION_DIALOG",
    SET_CAREPLAN: "SET_CAREPLAN",
    SET_GOAL: "SET_GOAL",
    SET_INTERVENTION: "SET_INTERVENTION",
    SET_MODEL: "SET_MODEL",
    SET_GOAL_MODEL: "SET_GOAL_MODEL",
    SET_INTERVENTION_MODEL: "SET_INTERVENTION_MODEL",
    SET_FREQUENCIES: "SET_FREQUENCIES",
    SET_ROLES: "SET_ROLES",
    REMOVE_INTERVENTION: "REMOVE_INTERVENTION",
    SET_UPDATING_CAREPLAN: "SET_UPDATING_CAREPLAN",
    SET_UPDATING_GOAL: "SET_UPDATING_GOAL",
    SET_UPDATING_INTERVENTION: "SET_UPDATING_INTERVENTION",
    TOGGLE_CAREPLAN_PRINT: "TOGGLE_CAREPLAN_PRINT",
    TOGGLE_CAREPLAN_OPEN: "TOGGLE_CAREPLAN_OPEN",
    TOGGLE_GOAL_OPEN: "TOGGLE_GOAL_OPEN",
    SET_LOADING_INTERVENTION: "SET_LOADING_INTERVENTION",
    SET_LOADING_GOAL: "SET_LOADING_GOAL"
}
