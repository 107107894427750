import React from 'react';
import {Button, Grid, Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ModelTemplate from './diagnosis.model';
import {FormFieldsGenerator, isModelValid} from "../../utils/forms";
import {deleteDiagnosis, getDiagnosisById, updateDiagnosis} from "../../services/diagnosis.service";
import history from "../../utils/history";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import {GlobalNotificationContext} from "../notification/globalnotificationprovider";
import useHasRole from "../../hooks/user.role.hook";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1)
  },
  headerContainer: {
    margin: theme.spacing(1, 0)
  },
  backBtn: {
    marginRight: ".5rem",
  },
  btnContainer: {
    position: 'relative'
  },
  deleteBtn: {
    position: 'absolute',
    top: '0',
    left: '0',
    background: theme.palette.error.light,
    color: '#fff',
    '&:hover': {
      background: theme.palette.error.dark
    }
  }
}))


const Diag = ({match}) => {
  const classes = useStyles();
  const [diagnosis, setDiagnosis] = React.useState();
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(ModelTemplate)));
  const { addNotification } = React.useContext(GlobalNotificationContext);

  const { checkPermission } = useHasRole();
  const canEdit = checkPermission('DIAGNOSIS_UPDATE');
  const canDelete = checkPermission('DIAGNOSIS_DELETE');

  React.useEffect(() => {
    getData(match.params.diagnosisId);
    // eslint-disable-next-line
  }, [match.params.diagnosisId]);

  const getData = async (id) => {
    const entity = await getDiagnosisById(id);
    setDiagnosis(entity);
    merge(entity);
  }

  const merge = (entity) => {
    const obj = JSON.parse(JSON.stringify(ModelTemplate));
    obj.code.value = entity.code;
    obj.version.value = entity.version;
    obj.shortDescription.value = entity.shortDescription;
    obj.longDescription.value = entity.longDescription;
    setModel(obj);
  }

  const onChange = e => {
    setModel({...model, ...e});
  }

  const cancelEdit = () => {
    merge(diagnosis);
  }

  const onUpdate = async () => {
    const data = {
      code: model.code.value,
      version: model.version.value,
      shortDescription: model.shortDescription.value,
      longDescription: model.longDescription.value
    }
    const entity = await updateDiagnosis(diagnosis?._id, data);
    if (entity) {
      setDiagnosis(entity);
      merge(entity);
      addNotification('Diagnosis updated', 'success');
    } else {
      addNotification('Unable to update diagnosis', 'warning');
    }
  }

  const onDeleteDiagnosis = async () => {
    const result = await deleteDiagnosis(diagnosis?._id);
    if (result) {
      addNotification('Diagnosis deleted', 'success');
      history.goBack();
    } else {
      addNotification('Unable to delete diagnosis', 'warning');
    }
  }


  return (
    <Paper elevation={24} className={classes.root}>
      <Grid container className={classes.headerContainer}>
        <Button size={"small"}
                className={classes.backBtn}
                onClick={() => history.goBack()}
                startIcon={<NavigateBeforeIcon />}
        >
          Back
        </Button>
        <Typography variant={"h6"}>Diagnosis</Typography>
      </Grid>
      <form noValidate>
        <FormFieldsGenerator model={model} onChange={onChange} edit={canEdit} />
        {canEdit &&
          <div className={classes.btnContainer}>
            <Grid container justify={"flex-end"} alignContent={"center"} style={{marginTop: '1rem'}}>
              {canDelete && <Button className={classes.deleteBtn}
                      onClick={onDeleteDiagnosis}
                      startIcon={<DeleteIcon />}>
                DELETE
              </Button>}
              <Button color={"primary"}
                      onClick={cancelEdit}>Cancel</Button>
              <Button color={"primary"}
                      variant={"contained"}
                      onClick={onUpdate}
                      disabled={ !isModelValid(model)}>Update</Button>

            </Grid>
          </div>}
      </form>
    </Paper>
  )
}

export default Diag;
