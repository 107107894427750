import React from 'react';
import { Button, FormGroup, TextField, Typography } from '@material-ui/core';
import { Paper, makeStyles, Divider } from '@material-ui/core';
import { ResidentContext } from '../../../context/residentcontext';
import { updateResident } from '../../../services/resident.service';
import { GlobalNotificationContext } from '../../notification/globalnotificationprovider';
import useHasRole from "../../../hooks/user.role.hook";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1),
  },
  btnContainer: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyItems: 'center',
    justifyContent: 'flex-end',
  },
}));

const ResidentBackground = () => {
  const classes = useStyles();
  const [resident, setResident] = React.useState();
  const [backup, setBackup] = React.useState();
  const { state, dispatch } = React.useContext(ResidentContext);
  const { addNotification } = React.useContext(GlobalNotificationContext);
  const { checkPermission } = useHasRole();
  const canEdit = checkPermission('RESIDENT_UPDATE');

  React.useEffect(() => {
    const obj = { ...state.resident };
    if (obj && obj.livingWill) {
      obj.livingWill = obj.livingWill === true ? 'y' : 'n';
    }
    setBackup(obj);
    setResident(obj);
  }, [state.resident]);

  const onChange = (e) => {
    const obj = { ...resident };
    obj[e.target.name] = e.target.value;
    setResident(obj);
  };

  const onUpdate = async () => {
    const obj = { ...resident };
    obj.livingWill = resident.livingWill === 'y';
    dispatch({ type: 'UPDATE_RESIDENT_START' });
    await updateResident(resident._id, obj);
    dispatch({
      type: 'UPDATE_RESIDENT_COMPLETE',
      payload: resident,
    });
    addNotification('Resident Updated', 'success');
  };

  const onCancel = () => {
    setResident(JSON.parse(JSON.stringify(backup)));
  };

  return (
    <Paper elevation={24} className={classes.root}>
      <Typography variant="h5">Resident Background</Typography>
      {!state.loadingResident && resident && (
        <form noValidate>
          <FormGroup>
            <TextField
              label="Education"
              value={resident.education || ''}
              onChange={onChange}
              name="education"
              disabled={!canEdit}
            />
            <TextField
              label="Occupation"
              value={resident.occupation || ''}
              onChange={onChange}
              name="occupation"
              disabled={!canEdit}
            />
            <TextField
              label="Hobbies"
              value={resident.hobbies || ''}
              onChange={onChange}
              name="hobbies"
              disabled={!canEdit}
            />
            <TextField
              label="Special Interests"
              value={resident.specialInterests || ''}
              onChange={onChange}
              name="specialInterests"
              disabled={!canEdit}
            />
            <TextField
              label="Military History"
              value={resident.militaryHistory || ''}
              onChange={onChange}
              name="militaryHistory"
              disabled={!canEdit}
            />
          </FormGroup>
          <Divider />
          {canEdit &&
          <FormGroup row className={classes.btnContainer}>
            <Button onClick={onCancel}>Cancel</Button>
            <Button color="primary" variant="contained" onClick={onUpdate}>
              Update
            </Button>
          </FormGroup>
          }
        </form>
      )}
    </Paper>
  );
};

export default ResidentBackground;
