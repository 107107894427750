import React from 'react';
import { makeStyles, Button, ButtonGroup } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const CalendarToolbar = ({ label, onToday, onNext, onPrev }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ButtonGroup>
        <Button
          disableRipple
          disableTouchRipple
          disableFocusRipple
          style={{ border: 'none', cursor: 'text' }}
        >
          {label}
        </Button>
        <Button
          size="small"
          onClick={onPrev}
          startIcon={<NavigateBeforeIcon />}
          style={{ color: 'black' }}
        />
        <Button
          size="small"
          onClick={onToday}
          variant="contained"
          color="primary"
        >
          Today
        </Button>
        <Button size="small" onClick={onNext} endIcon={<NavigateNextIcon />} />
      </ButtonGroup>
    </div>
  );
};

export default CalendarToolbar;
