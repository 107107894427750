import React from "react";
import {
  AppBar,
  Dialog,
  DialogContent,
  Toolbar,
  makeStyles,
  IconButton,
  Typography,
  FormGroup,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  DialogActions,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import Transition from "../../../dialog/transition";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import { VitalsInput } from "./VitalInput";
import {UserContext} from "../../../../context/usercontext";
import MomentUtils from "@date-io/moment";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    marginTop: "3.5rem",
    padding: theme.spacing(1),
  },
}));

const displayTime = (minutes) => {
  if (minutes >= 60) {
    const hours = minutes / 60;
    return `${hours} hour${minutes > 60 ? "s" : ""}`;
  } else {
    return `${minutes} minutes`;
  }
};

const EtarDialog = ({ open, etar, onSave, onClose, canUpdate = true}) => {
  const classes = useStyles();
  const { getUsers } = React.useContext(UserContext);
  const [model, setModel] = React.useState(etar);
  const [users, setUsers] = React.useState([]);
  React.useEffect(() => {
    if (open && etar) {
      setupModel();
    }
    // eslint-disable-next-line
  }, [open, etar]);

  const setupModel = async () => {
    const users = await getUsers();
    setUsers(users);
    setModel(JSON.parse(JSON.stringify(etar)));

  }

  const handleStatusChange = (e) => {
    const obj = { ...model };
    if (obj) {
      if (obj.status) {
        obj.status.name = e.target.value;
      } else {
        obj.status = { name: e.target.value };
      }
    }
    setModel(obj);
  };

  const onChange = (e) => {
    const obj = { ...model };
    obj[e.target.name] = e.target.value;
    setModel(obj);
  };

  const onUpdateTreatment = () => {
    onSave(model);
  };

  const onVitalsChange = (etarId, vital) => {
    const obj = { ...model};
    obj.updatedVital = vital;
    setModel(obj);
  }

  const onAdministeredDateChange = date => {
    const obj = {...model};
    obj.administeredTimestamp = date;
    obj.updateScheduledTime = date;
    setModel(obj);
  }

  const onAdministeredUserChange = e => {
    const obj = {...model};
    const user = users?.find(u => u._id === e.target.value);
    obj.administeredUser = user;
    obj.updateUser = user;
    setModel(obj);
  }
  return (
    <Dialog
      TransitionComponent={Transition}
      fullScreen
      open={open}
      onClose={onClose}
    >
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Resident eTar
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.content}>
        <form noValidate>
          {model && (
            <FormGroup>
              {model.resident.firstName && model.resident.lastName && (
                <TextField
                  disabled
                  label="Resident"
                  value={
                    model.resident
                      ? `${model.resident.firstName} ${model.resident.lastName}`
                      : null
                  }
                />
              )}
              <TextField disabled label="Treatment" value={model.name} />
              <TextField
                disabled
                label="Description"
                value={model.description}
              />
              <TextField
                disabled
                label="Time"
                value={moment(model.scheduledTime).format("h:mm a")}
              />
              <TextField
                disabled
                label="Duration"
                value={displayTime(model.time)}
              />
              <FormControl className={classes.formControl}>
                <InputLabel id="status-select-label">Status</InputLabel>
                <Select
                  labelId="status-select-label"
                  id="status-select"
                  value={etar && model.status ? model.status.name : ""}
                  onChange={handleStatusChange}
                  disabled={!canUpdate}
                >
                  <MenuItem value={"Scheduled"}>Scheduled</MenuItem>
                  <MenuItem value={"Given"}>Given</MenuItem>
                  <MenuItem value={"Late"}>Late</MenuItem>
                  <MenuItem value={"Missed"}>Missed</MenuItem>
                  <MenuItem value={"Hold"}>Hold</MenuItem>
                  <MenuItem value={"SA"}>Self Administered</MenuItem>
                  <MenuItem value={"NA"}>Not Administered</MenuItem>
                  <MenuItem value={"Refused"}>Refused</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Notes"
                rows={3}
                name="notes"
                value={model.notes}
                onChange={onChange}
                multiline
                disabled={!canUpdate}
              />
              {!canUpdate &&
                <TextField
                  disabled
                  label="Administered By"
                  value={
                    model.administeredUser
                      ? model.administeredUser.initials
                      : null
                  }
                />
              }
              {canUpdate &&
                <FormControl>
                  <InputLabel shrink>Administered By</InputLabel>
                  <Select fullWidth
                          value={model?.administeredUser?._id}
                          onChange={onAdministeredUserChange}
                  >
                    {users?.sort((a, b) => a?.lastName > b?.lastName ? 1: -1)?.map(user =>
                      <MenuItem value={user?._id}>{user?.firstName} {user?.lastName} ({user?.initials})</MenuItem>
                    )}
                  </Select>
                </FormControl>
              }
              {!canUpdate &&
                <TextField
                  disabled
                  label="Administerd"
                  value={
                    model.administeredTimestamp
                      ? moment(model.administeredTimestamp).format("h:mm a")
                      : null
                  }
                />
              }
              {canUpdate &&
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDateTimePicker
                    label={"Administered Date"}
                    value={model?.administeredTimestamp}
                    format={"MM/DD/YYYY hh:mm a"}
                    onChange={onAdministeredDateChange}
                  />
                </MuiPickersUtilsProvider>
              }
            </FormGroup>
          )}
        </form>
        {etar?.chartVitals?.length > 0 &&
          <VitalsInput resident={etar?.resident?._id}
                       emar={etar}
                       edit={canUpdate}
                       onValueChange={onVitalsChange}
          />}
        <DialogActions>
          <ButtonGroup fullWidth>
            <Button onClick={onClose}>Close</Button>
            {canUpdate &&
            <Button
              variant="contained"
              color="primary"
              onClick={onUpdateTreatment}
            >
              Update
            </Button>}
          </ButtonGroup>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default EtarDialog;
