import apiUtil from '../utils/api.utils';
import configs from '../utils/configs';
import moment from 'moment';

export const getCalendarTypes = async () => {
  try {
    const response = await apiUtil.client.get(`${configs.userService}/api/v1.0/calendartypes`);
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const createCalendarType = async (calendar) => {
  try {
    const response = await apiUtil.client.post(`${configs.userService}/api/v1.0/calendartypes`, calendar);
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const getCalendarType = async (id) => {
  try {
    const response = await apiUtil.client.get(`${configs.userService}/api/v1.0/calendartypes/${id}`);
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const getCalendarEvents = async (id) => {
  try {
    const response = await apiUtil.client.get(`${configs.userService}/api/v1.0/calendartypes/${id}/events`);
    return response?.data?.map(d => {
      d.start = moment(d.start).toDate();
      d.end = moment(d.end).toDate();
      return d;
    })
  } catch (error) {
    return { error: true, message: error };
  }
}

export const updateCalendarType = async (id, calendar) => {
  try {
    const response = await apiUtil.client.put(`${configs.userService}/api/v1.0/calendartypes/${id}`, calendar);
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const deleteCalendarType = async (id) => {
  try {
    const response = await apiUtil.client.delete(`${configs.userService}/api/v1.0/calendartypes/${id}`);
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}


export const getUserCalendarEvents = async (id, date) => {
  try {
    let url = `${configs.userService}/api/v1.0/users/${id}/calendarevents`;
    if (date) {
      url += `?date=${moment(date).format("MM-DD-YYYY")}`
    }
    const response = await apiUtil.client.get(url);
    return response?.data?.map(d => {
      d.start = moment(d.start).toDate();
      d.end = moment(d.end).toDate();
      return d;
    })
  } catch (error) {
    return { error: true, message: error };
  }
}

export const createUserCalendarEvents = async (userId, calendarEvent) => {
  try {
    const response = await apiUtil.client.post(`${configs.userService}/api/v1.0/users/${userId}/calendarevents`, calendarEvent);
    return response?.data?.map(event => santizeDates(event));
  } catch (error) {
    return { error: true, message: error };
  }
}

const santizeDates = event => {
  if (event) {
    event.start = moment(event.start).toDate();
    event.end = moment(event.end).toDate();
    return event;
  } else {
    return null;
  }
}

export const updateUserCalendarEvent = async (userId, calendarEventId, calendarEvent) => {
  try {
    const response =
      await apiUtil.client.put(
        `${configs.userService}/api/v1.0/users/${userId}/calendarevents/${calendarEventId}`,
        calendarEvent
      );
    return santizeDates(response?.data);
  } catch (error) {
    return { error: true, message: error };
  }
}

export const deleteUserCalendarEvent = async (userId, calendarEventId) => {
  try {
    const response =
      await apiUtil.client.delete(
        `${configs.userService}/api/v1.0/users/${userId}/calendarevents/${calendarEventId}`
      );
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const deleteUserCalendarSeries = async (userId, seriesId) => {
  try {
    const response =
      await apiUtil.client.delete(
        `${configs.userService}/api/v1.0/users/${userId}/calendarseries/${seriesId}`
      );
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

/** Resident Calendar */
export const getResidentCalendarTypes = async residentId => {
  try {
    const response = await apiUtil.client.get(
      `${configs.userService}/api/v1.0/residents/${residentId}/calendartypes`
    );
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const createResidentCalendarType = async (residentId, type) => {
  try {
    const response = await apiUtil.client.post(
      `${configs.userService}/api/v1.0/residents/${residentId}/calendartypes`,
      type
    );
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const updateResidentCalendarType = async (residentId, id, type) => {
  try {
    const response = await apiUtil.client.put(
      `${configs.userService}/api/v1.0/residents/${residentId}/calendartypes/${id}`, type
    );
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const deleteResidentCalendarType = async (residentId, id) => {
  try {
    const response = await apiUtil.client.delete(
      `${configs.userService}/api/v1.0/residents/${residentId}/calendartypes/${id}`
    );
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const getResidentCalendarEvents = async (residentId, date) => {
  try {
    let url = `${configs.userService}/api/v1.0/residents/${residentId}/calendarevents`
    if (date) {
      url += `?date=${moment(date).format('MM-DD-YYYY')}`
    }
    const response = await apiUtil.client.get(url);
    return response?.data?.map(event => santizeDates(event));
  } catch (error) {
    return { error: true, message: error };
  }
}

export const createResidentCalendarEvent = async (residentId, event) => {
  try {
    const response = await apiUtil.client.post(
      `${configs.userService}/api/v1.0/residents/${residentId}/calendarevents`,
      event
    );
    return response?.data?.map(event => santizeDates(event));
  } catch (error) {
    return { error: true, message: error };
  }
}

export const updateResidentCalendarEvent = async (residentId, id, event) => {

  try {
    const response = await apiUtil.client.put(
      `${configs.userService}/api/v1.0/residents/${residentId}/calendarevents/${id}`, event
    );
    return santizeDates(response?.data);
  } catch (error) {
    return { error: true, message: error };
  }
}

export const deleteResidentCalendarEvent= async (residentId, id) => {
  try {
    const response = await apiUtil.client.delete(
      `${configs.userService}/api/v1.0/residents/${residentId}/calendarevents/${id}`
    );
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const deleteResidentCalendarSeries = async (residentId, seriesId) => {
  try {
    const response = await apiUtil.client.delete(
      `${configs.userService}/api/v1.0/residents/${residentId}/calendarseries/${seriesId}`
    );
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}


/** Tenant Calendar */
export const getTenantCalendarTypes = async tenantId => {
  try {
    const response = await apiUtil.client.get(
      `${configs.userService}/api/v1.0/tenants/${tenantId}/calendartypes`
    );
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const createTenantCalendarType = async (tenantId, type) => {
  try {
    const response = await apiUtil.client.post(
      `${configs.userService}/api/v1.0/tenants/${tenantId}/calendartypes`,
      type
    );
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const updateTenantCalendarType = async (tenantId, id, type) => {
  try {
    const response = await apiUtil.client.put(
      `${configs.userService}/api/v1.0/tenants/${tenantId}/calendartypes/${id}`, type
    );
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const deleteTenantCalendarType = async (tenantId, id) => {
  try {
    const response = await apiUtil.client.delete(
      `${configs.userService}/api/v1.0/tenants/${tenantId}/calendartypes/${id}`
    );
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const getTenantCalendarEvents = async (tenantId, date) => {
  try {
    let url = `${configs.userService}/api/v1.0/tenants/${tenantId}/calendarevents`
    if (date) {
      url += `?date=${moment(date).format('MM-DD-YYYY')}`
    }
    const response = await apiUtil.client.get(url);
    return response?.data?.map(event => santizeDates(event));
  } catch (error) {
    return { error: true, message: error };
  }
}

export const createTenantCalendarEvent = async (tenantId, event) => {
  try {
    const response = await apiUtil.client.post(
      `${configs.userService}/api/v1.0/tenants/${tenantId}/calendarevents`,
      event
    );
    return response?.data?.map(event => santizeDates(event));
  } catch (error) {
    return { error: true, message: error };
  }
}

export const updateTenantCalendarEvent = async (tenantId, id, event) => {

  try {
    const response = await apiUtil.client.put(
      `${configs.userService}/api/v1.0/tenants/${tenantId}/calendarevents/${id}`, event
    );
    return santizeDates(response?.data);
  } catch (error) {
    return { error: true, message: error };
  }
}

export const deleteTenantCalendarEvent= async (tenantId, id) => {
  try {
    const response = await apiUtil.client.delete(
      `${configs.userService}/api/v1.0/tenants/${tenantId}/calendarevents/${id}`
    );
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const deleteTenantCalendarSeries = async (tenantId, seriesId) => {
  try {
    const response = await apiUtil.client.delete(
      `${configs.userService}/api/v1.0/tenants/${tenantId}/calendarseries/${seriesId}`
    );
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}
