import React from "react";
import {
  Dialog,
  Button,
  AppBar,
  Grid,
  Toolbar,
  IconButton,
  Typography,
  DialogContent,
  TablePagination,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TextField,
  TableBody,
  Link,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import Transition from "../dialog/transition";
import { Link as RouterLink } from "react-router-dom";
import { convertToFilters } from "../../utils/filter.utils";
import StateDropdown from "../utils/dropdowns/state.dropdown";
import * as medicalProviderService from '../../services/provider.service';
import CreateMedicalProvider from "./create.medical.provider";


const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    paddingTop: "4rem",
  },
  dialogContent: {
    marginTop: "4rem",
  },
}));

const sizeOptions = [1, 5, 10, 25];

const filterTemplate = {
  name: {
    field: "name",
    minValue: 2,
    value: null,
    operator: "*=*",
  },
  description: {
    field: "description",
    minValue: 2,
    value: null,
    operator: "*=*",
  },
  type: {
    field: "type",
    minValue: 3,
    value: null,
    operator: "*=*",
  },
  address: {
    field: "address.address",
    minValue: 3,
    value: null,
    operator: "*=*",
  },
  address2: {
    field: "address.address2",
    minValue: 3,
    value: null,
    operator: "*=*",
  },
  city: {
    field: "address.city",
    minValue: 3,
    value: null,
    operator: "*=*",
  },
  state: {
    field: "address.state",
    minValue: 1,
    value: null,
    operator: "==",
  },
  zipCode: {
    field: "address.zipCode",
    minValue: 3,
    value: null,
    operator: "*=*",
  },
};

const MedicalProviderLookup = ({ onSelect, open, onClose }) => {
  const classes = useStyles();
  const [filter, setFilter] = React.useState(
    JSON.parse(JSON.stringify(filterTemplate))
  );
  const [total, setTotal] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [medicalProviders, setMedicalProviders] = React.useState([]);
  const [sortOrderBy, setSortOrderBy] = React.useState("name");
  const [sortOrderDirection, setSortOrderDirection] = React.useState("asc");
  const [openCreateMedicalProviderModal, setOpenCreateMedicalProviderModal] = React.useState(
    false
  );

  React.useEffect(() => {
    if (open) {
      search(page, size, filter, sortOrderBy, sortOrderDirection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onChangeRowsPerPage = async (event) => {
    setSize(event.target.value);
    await search(
      page,
      event.target.value,
      filter,
      sortOrderBy,
      sortOrderDirection
    );
  };
  const handleChangePage = async (event, newPage) => {
    const pg = newPage + 1;
    setPage(pg);
    await search(pg, size, filter, sortOrderBy, sortOrderDirection);
  };
  const filterTextChange = (e) => {
    const obj = { ...filter };
    obj[e.target.name].value = e.target.value;
    if (
      String(obj[e.target.name].value).length === 0 ||
      String(obj[e.target.name].value).length >= obj[e.target.name].minValue
    ) {
      search(page, size, obj, sortOrderBy, sortOrderDirection);
    }
    setFilter(obj);
  };

  const createSortHandler = async (field) => {
    const sort = field;
    const sortDirection = sortOrderDirection === "asc" ? "desc" : "asc";
    setSortOrderBy(sort);
    setSortOrderDirection(sortDirection);
    await search(page, size, filter, sort, sortDirection);
  };

  const search = async (pg, sz, filt, sort, sortDirection) => {
    const filters = convertToFilters(filt);
    const response = await medicalProviderService.searchMedicalProviders(pg, sz, filters, sort, sortDirection);
    setMedicalProviders(response.content);
    setSize(response.paginator.perPage);
    setPage(response.paginator.currentPage);
    setTotal(response.paginator.totalItems);
  };

  const selectProvider = (provider) => {
    if (onSelect) {
      onSelect(provider);
    }
  };

  const clearFilters = () => {
    const filt = JSON.parse(JSON.stringify(filterTemplate));
    setFilter(filt);
    search(1, 10, filt, "name", "asc");
  };

  const onCreateMedicalProvider = async (provider) => {
    const entity = await medicalProviderService.createMedicalProvider(provider);
    const obj = [...medicalProviders, entity];
    setMedicalProviders(obj);
    setOpenCreateMedicalProviderModal(false);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={() => onClose()}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => onClose()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Search Medical Providers
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.dialogContent}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <div>
              <Button onClick={() => setOpenCreateMedicalProviderModal(true)}>
                Create
              </Button>
              <Button startIcon={<HighlightOffIcon />} onClick={clearFilters}>
                Clear Filters
              </Button>
              <CreateMedicalProvider open={openCreateMedicalProviderModal} onClose={() => setOpenCreateMedicalProviderModal(false)}
              onSave={onCreateMedicalProvider} />
            </div>
            <TablePagination
              component="div"
              rowsPerPageOptions={sizeOptions}
              colSpan={5}
              count={total ? total : 0}
              rowsPerPage={size ? size : 0}
              page={page - 1}
              backIconButtonProps={{
                "aria-label": "previous page",
                disabled: page === 1 ? true : false,
              }}
              nextIconButtonProps={{
                "aria-label": "next page",
                disabled: page * size >= total ? true : false,
              }}
              onChangeRowsPerPage={onChangeRowsPerPage}
              onChangePage={handleChangePage}
            ></TablePagination>
          </Grid>
          <Table
            size="small"
            style={{ width: "100%" }}
            className={classes.table}
            id="pharmacy-table"
          >
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell className={classes.tableHeader}>
                  <TableSortLabel
                    active={sortOrderBy === "name"}
                    direction={sortOrderDirection}
                    onClick={() => createSortHandler("name")}
                  >
                    Name
                  </TableSortLabel>
                  <TextField
                    name="name"
                    margin="dense"
                    size="small"
                    placeholder="Search"
                    variant="outlined"
                    fullWidth={true}
                    value={filter.name.value || ""}
                    onChange={filterTextChange}
                  ></TextField>
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  <TableSortLabel
                    active={sortOrderBy === "type"}
                    direction={sortOrderDirection}
                    onClick={() => createSortHandler("type")}
                  >
                    Type
                  </TableSortLabel>
                  <TextField
                    name="type"
                    margin="dense"
                    size="small"
                    placeholder="Search"
                    variant="outlined"
                    fullWidth={true}
                    value={filter.type.value || ""}
                    onChange={filterTextChange}
                  ></TextField>
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  <TableSortLabel
                    active={sortOrderBy === "address.address"}
                    direction={sortOrderDirection}
                    onClick={() => createSortHandler("address.address")}
                  >
                    Address
                  </TableSortLabel>
                  <TextField
                    name="address"
                    margin="dense"
                    size="small"
                    placeholder="Search"
                    variant="outlined"
                    fullWidth={true}
                    value={filter.address.value || ""}
                    onChange={filterTextChange}
                  ></TextField>
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  <TableSortLabel
                    active={sortOrderBy === "address.address2"}
                    direction={sortOrderDirection}
                    onClick={() => createSortHandler("address.address2")}
                  >
                    Address 2
                  </TableSortLabel>
                  <TextField
                    name="address2"
                    margin="dense"
                    size="small"
                    placeholder="Search"
                    variant="outlined"
                    fullWidth={true}
                    value={filter.address2.value || ""}
                    onChange={filterTextChange}
                  ></TextField>
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  <TableSortLabel
                    active={sortOrderBy === "address.city"}
                    direction={sortOrderDirection}
                    onClick={() => createSortHandler("address.city")}
                  >
                    City
                  </TableSortLabel>
                  <TextField
                    name="city"
                    margin="dense"
                    size="small"
                    placeholder="Search"
                    variant="outlined"
                    fullWidth={true}
                    value={filter.city.value || ""}
                    onChange={filterTextChange}
                  ></TextField>
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  <TableSortLabel
                    active={sortOrderBy === "address.state"}
                    direction={sortOrderDirection}
                    onClick={() => createSortHandler("address.state")}
                  >
                    State
                  </TableSortLabel>
                  <StateDropdown
                    name="state"
                    fullWidth
                    value={filter.state.value || ""}
                    variant="outlined"
                    size="small"
                    margin="dense"
                    onChange={filterTextChange}
                  />
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  <TableSortLabel
                    active={sortOrderBy === "address.zipCode"}
                    direction={sortOrderDirection}
                    onClick={() => createSortHandler("address.zipCode")}
                  >
                    Zip
                  </TableSortLabel>
                  <TextField
                    name="zipCode"
                    margin="dense"
                    size="small"
                    placeholder="Search"
                    variant="outlined"
                    fullWidth={true}
                    value={filter.zipCode.value || ""}
                    onChange={filterTextChange}
                  ></TextField>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {medicalProviders &&
                medicalProviders.map((provider) => (
                  <TableRow>
                    <TableCell>
                      <Button onClick={() => selectProvider(provider)}>
                        Select
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Link
                        component={RouterLink}
                        to={`/medicalproviders/${provider._id}`}
                      >
                        {provider.name}
                      </Link>
                    </TableCell>
                    <TableCell>{provider.type}</TableCell>
                    <TableCell>
                      {provider?.address?.address}
                    </TableCell>
                    <TableCell>
                      {provider?.address?.address2}
                    </TableCell>
                    <TableCell>
                      {provider?.address?.city}
                    </TableCell>
                    <TableCell>
                      {provider?.address?.state}
                    </TableCell>
                    <TableCell>
                      {provider?.address?.zipCode}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default MedicalProviderLookup;
