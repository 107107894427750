import React from 'react';
import { tenantModel } from './assessment.model';
import { reducer } from './assessments.reducer';
import {getQuestionTypesAction } from './assessment.actions';
import filterTemplate from "./assessment.filter.template";

let AssessmentsContext = React.createContext();
let initialState = {
  assessments: [],
  page: 1,
  size: 25,
  totalItems: 0,
  types: [],
  assessment: {},
  assessmentModel: JSON.parse(JSON.stringify(tenantModel)),
  loading: false,
  error: false,
  updatingAssessmentSummary: false,
  showCreateModal: false,
  showCreateOptionModel: false,
  questionTypes: [],
  filter: JSON.parse(JSON.stringify(filterTemplate)),
  sortOrderBy: 'name',
  sortDirection: 'desc'
};

const ACTIONS = {
  LOADING: "LOADING",
  SET_LOADING: "SET_LOADING",
  SET_ERROR: "SET_ERROR",
  GET_ASSESSMENTS: "GET_ASSESSMENTS",
  GET_ASSESSMENT: "GET_ASSESSMENT",
  CREATE_ASSESSMENT: "CREATE_ASSESSMENT",
  UPDATE_ASSESSMENT: "UPDATE_ASSESSMENT",
  DELETE_ASSESSMENT: "DELETE_ASSESSMENT",
  SET_ASSESSMENT_MODEL: "SET_ASSESSMENT_MODEL",
  GET_ASSESSMENT_TYPES: "GET_ASSESSMENT_TYPES",
  SET_ASSESSMENT_AND_TYPES: "SET_ASSESSMENT_AND_TYPES",
  SET_SHOW_CREATE_ASSESSMENT_MODAL: "SET_SHOW_CREATE_ASSESSMENT_MODAL",
  START_ADD_QUESTION_GROUP: "START_ADD_QUESTION_GROUP",
  DELETE_QUESTION_GROUP: "DELETE_QUESTION_GROUP",
  UPDATING_ASSESSMENT_SUMMARY: "UPDATING_ASSESSMENT_SUMMARY",
  ADD_SECTION: "ADD_SECTION",
  SELECT_ASSESSMENT_OBJECT: "SELECT_ASSESSMENT_OBJECT",
  UPDATE_SECTION_METADATA: "UPDATE_SECTION_METADATA",
  DELETE_SECTION: "DELETE_SECTION",
  ORDER_SECTION_UP: "ORDER_SECTION_UP",
  ORDER_SECTION_DOWN: "ORDER_SECTION_DOWN",
  ADD_QUESTION: "ADD_QUESTION",
  SET_QUESTION_TYPES: "SET_QUESTION_TYPES",
  UPDATE_QUESTION: "UPDATE_QUESTION",
  DELETE_QUESTION: "DELETE_QUESTION",
  ORDER_QUESTION_UP: "ORDER_QUESTION_UP",
  ORDER_QUESTION_DOWN: "ORDER_QUESTION_DOWN",
  SHOW_ADD_OPTION_MODAL: "SHOW_ADD_OPTION_MODAL",
  ADD_OPTION: "ADD_OPTION",
  UPDATE_OPTION: "UPDATE_OPTION",
  DELETE_OPTION: "DELETE_OPTION",
  ORDER_OPTION_UP: "ORDER_OPTION_UP",
  ORDER_OPTION_DOWN: "ORDER_OPTION_DOWN",
  COPY_QUESTION: "COPY_QUESTION",
  SET_SORT_ORDER: "SET_SORT_ORDER",
  SET_FILTER: "SET_FILTER",
  RESET_FILTER: "RESET_FILTER",
  SET_PAGE: "SET_PAGE",
  SET_SIZE: "SET_SIZE"
}

const AssessmentsContextProvider = ({resident, children}) => {
  let [state, dispatch] = React.useReducer(reducer, initialState);
  const value = { state, dispatch};
  React.useEffect(() => {
    getQuestionTypesAction(dispatch)
  }, []);
  return (
    <AssessmentsContext.Provider value={value}>{children}</AssessmentsContext.Provider>
  )
}

const AssessmentsContextConsumer = AssessmentsContext.Consumer;

export { ACTIONS, AssessmentsContext, AssessmentsContextConsumer, AssessmentsContextProvider };
