import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  makeStyles
} from "@material-ui/core";
import {ColorPicker, createColor} from "material-ui-color";
import {ACTIONS } from '../context';

import {deleteCalendarTypeAction, ResidentCalendarContext, saveCalendarTypeAction} from "../context";
import {FormFieldsGenerator, isModelValid} from "../../../../utils/forms";
import {DeleteBtn} from "../../../utils/buttons/DeleteBtn";
import ConfirmDialog from "../../../utils/dialogs/confirm.dialog";
import {ResidentCalendarTypeTemplate} from "./model";
import useGlobalNotification from "../../../../hooks/notification.hook";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    height: '50vh'
  }
}));

export const EditCalendarType = () => {
  const { state, dispatch } = React.useContext(ResidentCalendarContext);
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(ResidentCalendarTypeTemplate)));
  const [color, setColor] = React.useState(createColor("#fff"));
  const classes = useStyles();
  const { addNotification } = useGlobalNotification();

  const onChange = e => {
    setModel({...model, ...e});
  }

  React.useEffect(() => {
    if (state?.viewEditCalendarTypeModal && state?.selectedCalendarType) {
      const obj = JSON.parse(JSON.stringify(ResidentCalendarTypeTemplate));
      Object.keys(state?.selectedCalendarType).forEach(key => {
        if (obj.hasOwnProperty(key)) {
          obj[key].value = state?.selectedCalendarType[key]
        }
      });
      setColor(createColor(state?.selectedCalendarType?.color))
      setModel(obj);
    }
  },[state?.viewEditCalendarTypeModal, state?.selectedCalendarType])

  const save = async () => {
    const data = {}
    Object.keys(model).forEach(key => {
      data[key] = model[key].value;
    });
    data.color = color?.hex ? `#${color.hex}`: color;
    await saveCalendarTypeAction(
      dispatch,
      state?.residentId,
      state?.selectedCalendarType,
      data,
      state?.selectedCalendarType?.checked,
      () => addNotification("Unable to update type", "error"),
      () => addNotification("Calendar type updated", "success")
    );
  }

  const onColorChange = color => {
    setColor(color);
  }

  const deleteType = async () => {
    await deleteCalendarTypeAction(
      dispatch,
      state.residentId,
      state.selectedCalendarType,
      () => addNotification("Unable to delete type", "error"),
      () => addNotification("Calendar type deleted", "success")
    )
  }

  return (
    <Dialog open={state?.viewEditCalendarTypeModal}
            maxWidth={"xl"}
            fullWidth={true}
            onClose={() => dispatch({type: ACTIONS.SET_VIEW_EDIT_CALENDAR_TYPE_MODAL, payload: false })}>
      <DialogTitle>Calendar Type</DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <FormFieldsGenerator model={model}
                             onChange={onChange} />
        <FormControlLabel control={<ColorPicker value={color}
                                                hideTextfield
                                                defaultValue="transparent"
                                                onChange={onColorChange} />}
                          label={"Color"}
                          labelPlacement={"start"}
                          style={{ paddingTop: '1rem'}}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch({type: ACTIONS.SET_VIEW_EDIT_CALENDAR_TYPE_MODAL, payload: false })}>
          Cancel
        </Button>
        <DeleteBtn onClick={() => dispatch({type: ACTIONS.SET_VIEW_DELETE_CALENDAR_TYPE_MODAL, payload: true })} />
        <ConfirmDialog title={"Delete Calendar"}
                       message={"Are you sure?"}
                       open={state?.viewDeleteCalendarTypeModal}
                       onClose={() => dispatch({type: ACTIONS.SET_VIEW_DELETE_CALENDAR_TYPE_MODAL, payload: false })}
                       onConfirm={deleteType}
        />
        <Button onClick={save}
                variant={"contained"}
                color={"primary"}
                disabled={!isModelValid(model)}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
