import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, TextField} from "@material-ui/core";
import {SsaDialogTitle} from "../../../../utils/dialogs/SsaDialogTItle";

export const CreateFolderDialog = ({open, onClose, onSave}) => {

  const [fileName, setFileName] = React.useState("");

  React.useEffect(() => {
    if (open) {
      setFileName("");
    }
  }, [open]);

  const handleSave = () => {
    onSave({
      label: fileName,
    });
  }

  const handleChange = e => {
    setFileName(e.target.value);
  }

  return (
    <Dialog open={open}
            maxWidth={"md"}
            fullWidth={true}
            onClose={onClose}>
      <SsaDialogTitle onClose={onClose}>
        Create Folder
      </SsaDialogTitle>
      <DialogContent>
        <TextField fullWidth
                   variant={"outlined"}
                   label={"Name"}
                   value={fileName}
                   onChange={handleChange}
                   required>

        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color={"primary"}
                disabled={fileName?.length < 3}
                onClick={handleSave}
                variant={"contained"}
                >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}
