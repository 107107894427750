import React from 'react';
import {PageTitle} from "../utils";
import {Button, Chip, Grid, makeStyles, Paper} from "@material-ui/core";
import {SsaCalendar} from "../calendar";
import {CalendarTypes} from "./calendar.types";
import {
  CalendarEventComponent,
  CalendarAgendaEventComponent,
  CreateCalendarEvent,
  EditCalendarEvent,
  WeekEventComponent
} from "./events";
import {ACTIONS, CalendarContext} from "./context/calendar.context";
import {
  calendarSlotSelectedAction, getCalendarEventsAction,
  selectCalendarEventAction,
  updateCalendarFilterTypesAction
} from "./context/calendar.actions";
import {FilterListTwoTone} from "@material-ui/icons";
import {calendarEventPropertySetter, invertColor} from "../calendar/calendar.utils";
import {RetryMessage} from "../notification/retry.message";
import moment from "moment";
import useHasRole from "../../hooks/user.role.hook";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1)
  },
  deleteBtn: {
    backgroundColor: theme.palette.error.light
  },
  content: {
    padding: theme.spacing(0, 1)
  },
  filterChip: {
    marginLeft: '.5rem'
  }
}));

export const CalendarsPage = () => {
  const classes = useStyles();
  const { state, dispatch } = React.useContext(CalendarContext);
  const [showFilter, setShowFilter] = React.useState(false);
  const { checkPermission } = useHasRole();
  const canReadUserCalendar = checkPermission("USER_CALENDAR_READ");
  const canWriteUserCalendar = checkPermission("USER_CALENDAR_WRITE");

  const onEventSelected = event => {
    selectCalendarEventAction(dispatch, event);
  }

  const onSlotSelected = slot => {
    calendarSlotSelectedAction(dispatch, slot);
  }

  const getEvents = () => {
    const checkedTypes = state?.calendarTypes?.filter(c => c?.checked);
    if (checkedTypes?.length === state?.calendarTypes?.length) {
      if (state?.viewAllFilter) {
        return state?.events;
      }
      if (!state?.viewAllFilter) {
        return [];
      }
    } else {
      return state?.events?.filter(event =>
        checkedTypes?.findIndex(c => c.name === event?.calendarType) > -1);
    }
  }

  const calendarTypesFilterChanged = type => {
    updateCalendarFilterTypesAction(dispatch, type);
  }

  const reloadResidentEvents = async () => {
    await getCalendarEventsAction(dispatch, state?.user?._id,
      moment().startOf('month'),
      moment().endOf('month'), [])
  }

  const onTimeframeNavigated = async ({ date }) => {
    await getCalendarEventsAction(dispatch, state?.user?._id, date);
  }

  const onViewChanged = view => {
    dispatch({type: ACTIONS.SET_VIEW, payload: view });
  }


  return (
    <Paper elevation={24}
           className={classes.root}
    >
      <PageTitle title={"Your Calendar"}>
        <Grid container alignItems={"center"}>
          <Button variant={"outlined"}
                  startIcon={<FilterListTwoTone />}
                  onClick={() => setShowFilter(filter => !filter)}>
            {showFilter ? "Hide":"Show"} Filters
          </Button>
          {state?.calendarTypes?.filter(c => c.checked).map(type =>
            <Chip size="small"
                  onDelete={() => calendarTypesFilterChanged(type)}
                  style={{
                    backgroundColor: type?.color,
                    color: invertColor(type?.color, true)
                  }}
                  className={classes.filterChip}
                  label={type.name} />
          )}
        </Grid>
      </PageTitle>
      <Grid container style={{ paddingTop: '.5rem'}}>
        <Grid item xs={showFilter ? 12: 0} sm={showFilter ? 2: 0}>
          {showFilter && <CalendarTypes />}
        </Grid>
        <Grid item xs={12} sm={showFilter ? 10: 12}>
          {!state?.error &&
          <SsaCalendar events={getEvents()}
                       loadingEvents={state?.pending}
                       defaultView={"week"}
                       height={"85vh"}
                       color={'black'}
                       calendarColor={'black'}
                       scrollToTime={Date.now()}
                       eventPropertySetter={calendarEventPropertySetter}
                       eventSelected={canReadUserCalendar && onEventSelected}
                       slotSelected={canWriteUserCalendar && onSlotSelected}
                       weekEventComponent={WeekEventComponent}
                       customAgendaEventComponent={CalendarAgendaEventComponent}
                       onTimeframeNavigated={(event) => onTimeframeNavigated(event)}
                       view={state.view}
                       onView={onViewChanged}
                       customEventComponent={CalendarEventComponent}/>
          }
          {state?.error &&
          <RetryMessage message={"Error loading your calendar"} severity={"error"} onRetry={reloadResidentEvents} />
          }
          <EditCalendarEvent />
          <CreateCalendarEvent  />
        </Grid>
      </Grid>
    </Paper>
  )
}
