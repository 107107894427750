import React from "react";
import {
  Dialog,
  Button,
  AppBar,
  Grid,
  Toolbar,
  IconButton,
  Typography,
  DialogContent,
  TablePagination,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TextField,
  TableBody,
  Link,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import Transition from "../dialog/transition";
import { Link as RouterLink } from "react-router-dom";
import { getPharmacies, createPharmacy } from "../../services/pharmacy.service";
import { convertToFilters } from "../../utils/filter.utils";
import StateDropdown from "../utils/dropdowns/state.dropdown";
import CreatePharmacy from "./create.pharmacy";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    paddingTop: "4rem",
  },
  appBar: {
    background: blue[500],
  },
  dialogContent: {
    marginTop: "4rem",
  },
}));

const sizeOptions = [1, 5, 10, 25];

const filterTemplate = {
  name: {
    field: "name",
    minValue: 2,
    value: null,
    operator: "*=*",
  },
  address: {
    field: "address.addressLine1",
    minValue: 3,
    value: null,
    operator: "*=*",
  },
  address2: {
    field: "address.addressLine2",
    minValue: 3,
    value: null,
    operator: "*=*",
  },
  city: {
    field: "address.city",
    minValue: 3,
    value: null,
    operator: "*=*",
  },
  state: {
    field: "address.state",
    minValue: 1,
    value: null,
    operator: "==",
  },
  zip: {
    field: "address.zip",
    minValue: 3,
    value: null,
    operator: "=*",
  },
};

const PharmacyLookup = ({ onSelect, open, onClose }) => {
  const classes = useStyles();
  const [filter, setFilter] = React.useState(
    JSON.parse(JSON.stringify(filterTemplate))
  );
  const [total, setTotal] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [pharmacies, setPharmacies] = React.useState([]);
  const [sortOrderBy, setSortOrderBy] = React.useState("name");
  const [sortOrderDirection, setSortOrderDirection] = React.useState("asc");
  const [openCreatePharmacyModal, setOpenCreatePharmacyModal] = React.useState(
    false
  );

  React.useEffect(() => {
    if (open) {
      search(page, size, filter, sortOrderBy, sortOrderDirection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onChangeRowsPerPage = async (event) => {
    setSize(event.target.value);
    await search(
      page,
      event.target.value,
      filter,
      sortOrderBy,
      sortOrderDirection
    );
  };
  const handleChangePage = async (event, newPage) => {
    setPage(newPage + 1);
    await search(newPage + 1, size, filter, sortOrderBy, sortOrderDirection);
  };

  const filterTextChange = async (e) => {
    const obj = { ...filter };
    obj[e.target.name].value = e.target.value;
    setFilter(obj);
    const filters = convertToFilters(obj);
    if (filters?.length > 0 || !e.target.value) {
      await search(page, size, obj, sortOrderBy, sortOrderDirection);
    }
  };

  const createSortHandler = async (field) => {
    const sort = field;
    const sortDirection = sortOrderDirection === "asc" ? "desc" : "asc";
    setSortOrderBy(sort);
    setSortOrderDirection(sortDirection);
    await search(page, size, filter, sort, sortDirection);
  };

  const search = async (pg, sz, filt, sort, sortDirection) => {
    const filters = convertToFilters(filt);
    const response = await getPharmacies(pg, sz, filters, sort, sortDirection);

    if (!response?.error) {
      setPharmacies(response?.content);
      setPage(response?.paginator?.currentPage);
      setSize(response?.paginator?.perPage);
      setTotal(response?.paginator?.totalItems);
    }

  };

  const selectPharmacy = (pharmacy) => {
    if (onSelect) {
      onSelect(pharmacy);
    }
  };

  const clearFilters = () => {
    const filt = JSON.parse(JSON.stringify(filterTemplate));
    setFilter(filt);
    search(1, 10, filt, "name", "asc");
  };

  const onCreatePharmacy = async (pharmacy) => {
    const newPharmacy = await createPharmacy(pharmacy);
    const obj = [...pharmacies, newPharmacy];
    setPharmacies(obj);
    setOpenCreatePharmacyModal(false);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={() => onClose()}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => onClose()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Search Pharmacies
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.dialogContent}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <div>
              <Button onClick={() => setOpenCreatePharmacyModal(true)}>
                Create
              </Button>
              <Button startIcon={<HighlightOffIcon />} onClick={clearFilters}>
                Clear Filters
              </Button>
              <CreatePharmacy
                open={openCreatePharmacyModal}
                onClose={() => setOpenCreatePharmacyModal(false)}
                onSave={onCreatePharmacy}
              />
            </div>
            <TablePagination
              component="div"
              rowsPerPageOptions={sizeOptions}
              colSpan={5}
              count={total ? total : 0}
              rowsPerPage={size ? size : 0}
              page={page - 1}
              backIconButtonProps={{
                "aria-label": "previous page",
                disabled: page === 1 ? true : false,
              }}
              nextIconButtonProps={{
                "aria-label": "next page",
                disabled: (page) * size >= total ? true : false,
              }}
              onChangeRowsPerPage={onChangeRowsPerPage}
              onChangePage={handleChangePage}
            ></TablePagination>
          </Grid>
          <Table
            size="small"
            style={{ width: "100%" }}
            className={classes.table}
            id="pharmacy-table"
          >
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell className={classes.tableHeader}>
                  <TableSortLabel
                    active={sortOrderBy === "name"}
                    direction={sortOrderDirection}
                    onClick={() => createSortHandler("name")}
                  >
                    Name
                  </TableSortLabel>
                  <TextField
                    name="name"
                    margin="dense"
                    size="small"
                    placeholder="Search"
                    variant="outlined"
                    fullWidth={true}
                    value={filter.name.value || ""}
                    onChange={filterTextChange}
                  ></TextField>
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  <TableSortLabel
                    active={sortOrderBy === "address.address"}
                    direction={sortOrderDirection}
                    onClick={() => createSortHandler("address.address")}
                  >
                    Address
                  </TableSortLabel>
                  <TextField
                    name="address"
                    margin="dense"
                    size="small"
                    placeholder="Search"
                    variant="outlined"
                    fullWidth={true}
                    value={filter.address.value || ""}
                    onChange={filterTextChange}
                  ></TextField>
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  <TableSortLabel
                    active={sortOrderBy === "address.address2"}
                    direction={sortOrderDirection}
                    onClick={() => createSortHandler("address.address2")}
                  >
                    Address 2
                  </TableSortLabel>
                  <TextField
                    name="address2"
                    margin="dense"
                    size="small"
                    placeholder="Search"
                    variant="outlined"
                    fullWidth={true}
                    value={filter.address2.value || ""}
                    onChange={filterTextChange}
                  ></TextField>
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  <TableSortLabel
                    active={sortOrderBy === "address.city"}
                    direction={sortOrderDirection}
                    onClick={() => createSortHandler("address.city")}
                  >
                    City
                  </TableSortLabel>
                  <TextField
                    name="city"
                    margin="dense"
                    size="small"
                    placeholder="Search"
                    variant="outlined"
                    fullWidth={true}
                    value={filter.city.value || ""}
                    onChange={filterTextChange}
                  ></TextField>
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  <TableSortLabel
                    active={sortOrderBy === "address.state"}
                    direction={sortOrderDirection}
                    onClick={() => createSortHandler("address.state")}
                  >
                    State
                  </TableSortLabel>
                  <StateDropdown
                    name="state"
                    fullWidth
                    value={filter.state.value || ""}
                    variant="outlined"
                    size="small"
                    margin="dense"
                    onChange={filterTextChange}
                  />
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  <TableSortLabel
                    active={sortOrderBy === "address.zip"}
                    direction={sortOrderDirection}
                    onClick={() => createSortHandler("address.zip")}
                  >
                    Zip
                  </TableSortLabel>
                  <TextField
                    name="zip"
                    margin="dense"
                    size="small"
                    placeholder="Search"
                    variant="outlined"
                    fullWidth={true}
                    value={filter.zip.value || ""}
                    onChange={filterTextChange}
                  ></TextField>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pharmacies &&
                pharmacies.map((pharmacy) => (
                  <TableRow>
                    <TableCell>
                      <Button onClick={() => selectPharmacy(pharmacy)}>
                        Select
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Link
                        component={RouterLink}
                        to={`/pharmacies/${pharmacy._id}`}
                      >
                        {pharmacy.name}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {pharmacy.address ? pharmacy.address.address : null}
                    </TableCell>
                    <TableCell>
                      {pharmacy.address ? pharmacy.address.address2 : null}
                    </TableCell>
                    <TableCell>
                      {pharmacy.address ? pharmacy.address.city : null}
                    </TableCell>
                    <TableCell>
                      {pharmacy.address ? pharmacy.address.state : null}
                    </TableCell>
                    <TableCell>
                      {pharmacy.address ? pharmacy.address.zipCode : null}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PharmacyLookup;
