import apiUtil from '../utils/api.utils';
import configs from '../utils/configs';
import {parseFiltersToJson} from "../utils/filter.utils";
const insuranceEndpoint = `${configs.residentService}/api/v1.0/insurances`;

export const getInsurances = async (
  page = 1,
  size = 25,
  filters = [],
  sort = 'name',
  sortDirection = 'asc'
) => {
  try {
    let sortArg = sortDirection === "asc" ? "+" : "-";
    sortArg += sort;
    let url = `${insuranceEndpoint}?page=${page}&size=${size}&sort=${sortArg}`;
    if (filters?.length > 0) {
      url += parseFiltersToJson(filters);
    }
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (error) {
    return { error: true, message: error }
  }
};

export const createInsurance = async (insurance) => {
  try {
    const response = await apiUtil.client.post(insuranceEndpoint, insurance);
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
}

export const updateInsurance = async (insuranceId, insurance) => {
  try {
    const response = await apiUtil.client.put(`${insuranceEndpoint}/${insuranceId}`, insurance);
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
}

export const getInsurance = async (insuranceId) => {
  try {
    const response = await apiUtil.client.get(
      `${insuranceEndpoint}/${insuranceId}`
    );
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
};

export const createInsurancePhone = async (insuranceId, phone) => {
  try {
    const response = await apiUtil.client.post(
      `${insuranceEndpoint}/${insuranceId}/phones`,
      phone
    );
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
}

export const updateInsurancePhone = async (insuranceId, phoneId, phone) => {
  try {
    const response = await apiUtil.client.put(
      `${insuranceEndpoint}/${insuranceId}/phones/${phoneId}`,
      phone
    );
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
}

export const deleteInsurancePhone = async (insuranceId, phoneId) => {
  try {
    const response = await apiUtil.client.delete(`${insuranceEndpoint}/${insuranceId}/phones/${phoneId}`);
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
}
