import React from 'react';
import {
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
  DialogContent,
  DialogActions,
  Button,
  FormGroup,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Transition from '../../../dialog/transition';
import VisitorModel from './visitor.model';
import { isModelValid } from '../../../../utils/forms/form.validator';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: '4rem',
  },
}));

const VisitorLog = ({ open, onUpdate, onClose, log, canUpdate }) => {
  const classes = useStyles();
  const [model, setModel] = React.useState(
    JSON.parse(JSON.stringify(VisitorModel))
  );

  React.useEffect(() => {
    if (open && log) {
      mergeValues(log);
    }
  }, [open, log]);

  const mergeValues = (e) => {
    const obj = JSON.parse(JSON.stringify(VisitorModel));
    obj.date.value = e.date;
    obj.hour.value = e.hour;
    obj.visitor.value = e.visitor;
    setModel(obj);
  };

  const onModelChange = (e) => {
    const obj = { ...model };
    obj[e.target.name].dirty = true;
    obj[e.target.name].value = e.target.value;
    setModel(obj);
  };

  const onHourChange = (e) => {
    const obj = { ...model };
    obj.hour.dirty = true;
    obj.hour.value = e;
    setModel(obj);
  };

  const onDateChange = (e) => {
    const obj = { ...model };
    obj.date.dirty = true;
    obj.date.value = e;
    setModel(obj);
  };

  const updateLog = () => {
    const updated = {
      _id: log._id,
      type: 'visitor',
      date: model.date.value,
      hour: model.hour.value,
      visitor: model.visitor.value,
    };
    onUpdate(log._id, updated);
  };

  return (
    <Dialog open={open} fullScreen TransitionComponent={Transition}>
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => onClose()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Visitor Log
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent dividers
                     className={classes.content}>
        <form noValidate>
          <FormGroup>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardTimePicker
                label="Hour"
                placeholder="08:00 AM"
                mask="__:__ _M"
                value={model.hour.value}
                ampm={true}
                disabled={!canUpdate}
                helperText={
                  model.hour.dirty && model.hour.value === ''
                    ? `Hour is required!`
                    : null
                }
                onChange={onHourChange}
                error={!model.hour.isValid && model.hour.dirty}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                size="small"
                fullWidth
                name="date"
                required={true}
                value={model.date.value}
                label={'Date'}
                format="MM/DD/YYYY"
                onChange={onDateChange}
                allowKeyboardControl="true"
                ampm={false}
                disabled={!canUpdate}
                helperText={
                  model.date.dirty && model.date.value === ''
                    ? `Date is required!`
                    : null
                }
                error={!model.date.isValid && model.date.dirty}
              />
            </MuiPickersUtilsProvider>
            <TextField
              label="Visitor"
              name="visitor"
              value={model.visitor.value}
              onChange={onModelChange}
              disabled={!canUpdate}
            />
          </FormGroup>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        {canUpdate &&
          <Button
            variant="contained"
            color="primary"
            onClick={updateLog}
            disabled={!isModelValid(model)}
          >
            Update
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
};

export default VisitorLog;
