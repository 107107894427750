import React, {useContext} from 'react';
import {
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton, makeStyles, Table, TableBody, TableContainer,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {ACTIONS} from "./context/constants";
import {CareplanContext} from "./context";
import {FormFieldsGenerator, isModelValid} from "../../../utils/forms";
import {createInterventionAction} from "./context/actions";
import {InterventionModel} from "./intervention.model";
import moment from "moment";
import {ObjectId} from "bson";
import BaseEvent from "../../medicationorders/create/base.event";
import useGlobalNotification from "../../../hooks/notification.hook";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    dialogContent: {
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

export const CreateInterventionDialog = () => {
    const {state, dispatch} = useContext(CareplanContext);
    const classes = useStyles();
    const [frequency, setFrequency] = React.useState(null);
    const {addNotification} = useGlobalNotification();

    React.useEffect(() => {
        if (state?.viewCreateInterventionDialog) {
            const obj = JSON.parse(JSON.stringify(InterventionModel));
            obj.frequency.dropDownItems = state?.frequencies;
            const onceADayFrequency = state?.frequencies?.find(f => f.name === "QD")
            obj.frequency.value = onceADayFrequency?._id;
            obj.eventTemplates.value = processDay(onceADayFrequency?._id);
            obj.discipline.dropDownItems = state?.roles;
            dispatch({type: ACTIONS.SET_INTERVENTION_MODEL, payload: obj});
        }
        // eslint-disable-next-line
    }, [state?.viewCreateInterventionDialog]);

    const handleClose = () => {
        dispatch({type: ACTIONS.SET_VIEW_CREATE_INTERVENTION_DIALOG, payload: false});
    }

    const handleChange = (e, field) => {
        const obj = {...state?.interventionModel, ...e};
        if (field === "endDate") {
            obj.startDate.maxDateValue = e.endDate.value;
        }
        dispatch({type: ACTIONS.SET_INTERVENTION_MODEL, payload: obj});
        if (field === "frequency") {
            processDay(obj.frequency.value);
        }
    }

    const handleCreate = async () => {
        let data = {};
        Object.keys(state?.interventionModel).forEach(key => {
            data[key] = state.interventionModel[key].value
        });
        const response = await createInterventionAction(dispatch, state?.residentId, state?.careplan?._id, state?.goal?._id, data);
        if (!response?.error) {
            addNotification(`Intervention created. ${response?.eventsCreated} event(s) created.`, "success");
        }
    }

    const processDay = (frequencyId) => {
        let eventTemplates = [];
        if (state?.frequencies) {
            const freq = state?.frequencies.find((f) => f._id === frequencyId);
            if (freq) {
                setFrequency(freq);
                const defaultHours = {
                    1: { 1: 8 },
                    2: { 1: 8, 2: 16 },
                    3: { 1: 8, 2: 12, 3: 16 },
                    4: { 1: 8, 2: 10, 3: 14, 4: 16 },
                    5: { 1: 8, 2: 10, 3: 12, 4: 14, 5: 16 },
                };

                for (let i = 1; i <= freq.frequencyEventCount; i++) {
                    const d = new Date();
                    // Auto increment hours
                    let calculatedHour = defaultHours[freq.frequencyEventCount][i];
                    const initStartDate = d.setHours(calculatedHour, 0, 0, 0);
                    const hour = moment(initStartDate).format('H');
                    const minute = moment(initStartDate).format('mm');
                    eventTemplates.push({
                        _id: new ObjectId(),
                        sort: i,
                        passingQty: 1,
                        hour,
                        minute,
                    });
                }
                const obj = { ...state?.interventionModel };
                obj.eventTemplates.value = eventTemplates;
                dispatch({type: ACTIONS.SET_INTERVENTION_MODEL, payload: obj});
            }
        }
        return eventTemplates;
    };


    const onEventChange = e => {
        const obj = { ...state?.interventionModel };
        if (obj.eventTemplates && obj.eventTemplates.value) {
            obj.eventTemplates.value = obj.eventTemplates.value.map((eventTemplate) =>
                eventTemplate._id === e._id ? e : eventTemplate
            );
            dispatch({type: ACTIONS.SET_INTERVENTION_MODEL, payload: obj});
        }
    }

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={state?.viewCreateInterventionDialog}
            fullWidth={true}
            maxWidth={'lg'}
        >
            <DialogTitle>
                Create Intervention
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    className={classes.closeButton}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <FormFieldsGenerator onChange={handleChange}
                                     model={state?.interventionModel}

                />
                {!state?.interventionModel?.isPrn?.value && (
                    <TableContainer>
                        <Table>
                            <TableBody>
                                {state?.interventionModel?.eventTemplates?.value?.map((event) => (
                                    <BaseEvent
                                        frequency={frequency}
                                        eventTemplate={event}
                                        onChange={onEventChange}
                                        showQty={false}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                <Button onClick={handleCreate}
                        disabled={!isModelValid(state?.interventionModel)}
                        variant={"contained"} color={"primary"}>
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    )
}
