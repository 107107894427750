import React from 'react';
import {
  Button, Chip, Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead, TablePagination,
  TableRow, TableSortLabel, TextField,
  Typography, Select, MenuItem
} from "@material-ui/core";
import {getStripedStyle} from "../utils/table.utils";
import AddIcon from "@material-ui/icons/Add";
import PrivateRoute from "../PrivateRoute";
import {Link, Switch} from "react-router-dom";
import Assessment from "./Assessment";
import CreateAssessment from "./create.assessment";
import {ACTIONS, AssessmentsContext, AssessmentsContextProvider} from "./assessments.context";
import {
  createAssessmentAction,
  getAssessmentsAction,
  onCloseCreateModalAction,
  onSortAction
} from './assessment.actions'
import {UserContext} from "../../context/usercontext";
import useHasRole from "../../hooks/user.role.hook";
import {HighlightOffTwoTone} from "@material-ui/icons";
import filterTemplate from './assessment.filter.template';
import TableLoadingIndicator from "../../utils/indicators/table.loading";
import {RetryMessage} from "../notification/retry.message";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(.5, 0),
    padding: theme.spacing(1)
  },
  createBtn: {
    marginLeft: theme.spacing(1)
  }
}))

const Assessments = () => {
  const classes = useStyles();
  const { checkPermission } = useHasRole();
  const { state, dispatch } = React.useContext(AssessmentsContext);
  const { selectedTenant } = React.useContext(UserContext);
  const canCreate = checkPermission('ASSESSMENT_CREATE');

  React.useEffect(() => {
    if (selectedTenant) {
      getData(state.page, state.size, state.filter, state.sort, state.sortDirection);
    }
    // eslint-disable-next-line
  }, [ selectedTenant ]);

  const getData = async (page = 1, size = 25, filter = null, sort = 'name', sortDirection = 'asc') => {
    await getAssessmentsAction(dispatch, page, size, sort, sortDirection, filter, selectedTenant?._id);
  }

  const onCreateAssessment = async assessment => {
    await createAssessmentAction(assessment, dispatch);
  }

  const createSortHandler = async field => {
    const direction = state.sortDirection === 'asc' ? 'desc' : 'asc'
    await onSortAction(field, direction, dispatch);
    await getData(state.page, state.size, state.filter, field, direction);
  }

  const filterTextChange = async e => {
    const obj = {...state.filter};
    obj[e.target.name].value = e.target.value;
    dispatch({type: ACTIONS.SET_FILTER, payload: obj });
    if (e.target.value.length >= obj[e.target.name].minValue) {
      await getData(state.page, state.size, obj, state.sort, state.sortDirection)
    } else if (e.target.value.length === 0) {
      await getData(state.page, state.size, obj, state.sort, state.sortDirection)
    }
  }

  const handleChangePage = async (event, pg) => {
    const newPage = pg + 1;
    dispatch({type: ACTIONS.SET_PAGE, payload: newPage });
    await getData(newPage, state.size, state.filter, state.sort, state.sortDirection);
  };

  const onChangeRowsPerPage = async (event) => {
    dispatch({type: ACTIONS.SET_SIZE, payload: event.target.value});
    await getData(state.page, event.target.value, state.filter, state.sort, state.sortDirection);
  };

  const clearFilters = async () => {
    dispatch({type: ACTIONS.RESET_FILTER});
    dispatch({type: ACTIONS.SET_SIZE, payload: 25});
    dispatch({type: ACTIONS.SET_PAGE, payload: 1});
    await onSortAction('name', 'asc', dispatch);
    await getData(1, 25, JSON.parse(JSON.stringify(filterTemplate)), 'name', 'asc');
  }

  const onChangeGlobal = async e => {
    const obj = {...state.filter};
    obj.isGlobal.value = e.target.value;
    dispatch({type: ACTIONS.SET_FILTER, payload: obj });
    await getData(state.page, state.size, obj, state.sort, state.sortDirection)
  }

  const onChangeStatusFilter = async e => {
    const obj = {...state.filter};
    obj.status.value = e.target.value === -1 ? null: e.target.value;
    dispatch({type: ACTIONS.SET_FILTER, payload: obj });
    await getData(state.page, state.size, obj, state.sort, state.sortDirection)
  }

  const retry = async () => {
    await getData(state.page, state.size, state.filter, state.sort, state.sortDirection);
  }

  return (
    <Paper elevation={24} className={classes.root}>
      <Grid container alignItems={"center"}>
        <Typography variant={"h6"}>Assessments</Typography>
        {canCreate &&
          <>
        <Button className={classes.createBtn}
                size={"small"}
                onClick={() => onCloseCreateModalAction(true, dispatch)}
                startIcon={<AddIcon />}>Create</Button>
        <CreateAssessment onSave={onCreateAssessment}
                          open={state.showCreateModal}
                          onClose={() => onCloseCreateModalAction(false, dispatch)} />
        </>}
        <Button startIcon={<HighlightOffTwoTone />}
                onClick={clearFilters}
                size={"small"}>Clear Filters</Button>
      </Grid>
      <TablePagination
        component="div"
        rowsPerPageOptions={[5, 10, 25, 50]}
        page={state.page - 1}
        rowsPerPage={state.size}
        count={state.totalItems}
        onRowsPerPageChange={onChangeRowsPerPage}
        onPageChange={handleChangePage}

      />
      <TableContainer>
        <Table size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={state.sortOrderBy === 'isGlobal'}
                  direction={state.sortDirection}
                  onClick={() => createSortHandler('isGlobal')}
                >
                  Global
                </TableSortLabel>
                <Select fullWidth
                        value={state.filter.isGlobal.value || ''}
                        onChange={onChangeGlobal}
                >
                  <MenuItem value={'0'}>All</MenuItem>
                  <MenuItem value={'1'}>Yes</MenuItem>
                  <MenuItem value={'-1'}>No</MenuItem>
                </Select>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={state.sortOrderBy === 'name'}
                  direction={state.sortDirection}
                  onClick={() => createSortHandler('name')}
                >
                  Name
                </TableSortLabel>
                <TextField
                  fullWidth
                  name="name"
                  margin="dense"
                  size="small"
                  placeholder="Search"
                  variant="outlined"
                  value={state.filter.name.value || ''}
                  onChange={filterTextChange}
                ></TextField>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={state.sortOrderBy === 'description'}
                  direction={state.sortDirection}
                  onClick={() => createSortHandler('description')}
                >
                  Description
                </TableSortLabel>
                <TextField
                  fullWidth
                  name="description"
                  margin="dense"
                  size="small"
                  placeholder="Search"
                  variant="outlined"
                  value={state.filter.description.value || ''}
                  onChange={filterTextChange}
                ></TextField>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={state.sortOrderBy === 'status'}
                  direction={state.sortDirection}
                  onClick={() => createSortHandler('status')}
                >
                  Status
                </TableSortLabel>
                <Select fullWidth
                        value={state.filter.status.value || ''}
                        onChange={onChangeStatusFilter}
                        displayEmpty
                >
                  <MenuItem value={"-1"}>All</MenuItem>
                  <MenuItem value={"Active"}>Active</MenuItem>
                  <MenuItem value={"Development in progress"}>Development in progress</MenuItem>
                  <MenuItem value={"Inactive"}>Inactive</MenuItem>
                </Select>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={state.sortOrderBy === 'tags'}
                  direction={state.sortDirection}
                  onClick={() => createSortHandler('tags')}
                >
                  Tags
                </TableSortLabel>
                <TextField
                  fullWidth
                  name="tags"
                  margin="dense"
                  size="small"
                  placeholder="Search"
                  variant="outlined"
                  value={state.filter.tags.value || ''}
                  onChange={filterTextChange}
                ></TextField>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={state.sortOrderBy === 'type'}
                  direction={state.sortDirection}
                  onClick={() => createSortHandler('type')}
                >
                  Type
                </TableSortLabel>
                <TextField
                  fullWidth
                  name="type"
                  margin="dense"
                  size="small"
                  placeholder="Search"
                  variant="outlined"
                  value={state.filter.type.value || ''}
                  onChange={filterTextChange}
                ></TextField>
              </TableCell>
            </TableRow>
          </TableHead>
          {!state?.error && state?.loading &&
            <TableLoadingIndicator bodyOnly={true} cols={6} rows={10} />
          }
          {!state?.loading && state?.error &&
              <TableBody>
                <TableRow>
                  <TableCell colSpan={6}>
                    <RetryMessage message={"Unable to load assessments"} onRetry={retry} />
                  </TableCell>
                </TableRow>
              </TableBody>
          }
          <TableBody>
            {!state?.error && !state?.loading && state?.assessments?.map((assessment, i) =>
              <TableRow key={i} style={getStripedStyle(i)}>
                <TableCell>
                  {assessment.isGlobal ? <Chip label={"YES"} />: <Chip label={"No"} /> }
                </TableCell>
                <TableCell>
                  <Button component={Link} to={`/assessments/${assessment._id}`} color={"primary"} size={"small"}>{assessment.name}</Button>
                </TableCell>
                <TableCell>{assessment.description}</TableCell>
                <TableCell>{assessment?.status}</TableCell>
                <TableCell>{assessment?.tags?.map(t => <Chip size={"small"} color={"primary"} label={t} />)}</TableCell>
                <TableCell>{assessment.type}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}


const AssessmentsWrapper = () => {
  return (
    <AssessmentsContextProvider>
      <Switch>
        <PrivateRoute exact path={"/assessments"} component={Assessments} />
        <PrivateRoute path={"/assessments/:id"} component={Assessment} />
      </Switch>
    </AssessmentsContextProvider>
  )
}

export default AssessmentsWrapper;
