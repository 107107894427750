import React from 'react';
import {LinearProgress, List, ListItem, ListItemAvatar, ListItemText} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";

export const ListLoading = ({rows, hideProgress = false, height = 10}) => {

  return (
    <div>
      {!hideProgress && <LinearProgress />}
      <List>
        {[...Array(rows)].map((i, index) =>
         <ListItem divider
                   key={`list-loading-${i}-${index}`}
                   style={{height: `${height}vh`}}
         >
           <ListItemAvatar>
             <Skeleton variant={"circle"}
                       height={40}
                       width={40}
             />
           </ListItemAvatar>
           <ListItemText primary={<Skeleton height={30} />}
                         secondary={
                           <>
                             <Skeleton height={10} />
                             <Skeleton height={10} />
                           </>
                         }
           />
         </ListItem>
        )}
      </List>
    </div>
  )

}
