import React from 'react'
import PrivateRoute from "../PrivateRoute";
import { Switch } from "react-router-dom";
import PharmaciesList from './pharmacies.list';
import Pharmacy from './pharmacy';

const Pharmacies = () => {
    return (    
      <Switch>
        <PrivateRoute exact path="/pharmacies" component={PharmaciesList} />
        <PrivateRoute exact path="/pharmacies/:id" component={Pharmacy} />
      </Switch>
    )
}

export default Pharmacies;
