import React from "react";
import {
  Avatar,
  TableCell,
  TableRow,
  makeStyles,
  Typography,
  IconButton,
  Button,
  TextField,
  ButtonGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import {
  green,
  yellow,
  red,
  grey,
  orange,
  blue,
} from "@material-ui/core/colors";
import EditIcon from "@material-ui/icons/Edit";
import { updateResidentETar } from "../../../services/treatment.service";

const contrast = 400;
const useStyles = makeStyles((theme) => ({
  avatarSmall: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  scheduled: {
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: grey[500],
  },
  readyToPass: {
    background: grey[700],
  },
  given: {
    background: green[contrast],
  },
  late: {
    background: orange[contrast],
  },
  missed: {
    background: red[contrast],
  },
  hold: {
    background: yellow[contrast],
  },
  sa: {
    background: blue[contrast],
  },
  na: {
    background: grey[contrast],
  },
  refused: {
    background: red[contrast],
  },
  destruction: {
    background: yellow[contrast],
  },
}));

const CommonCells = ({ etar }) => {
  const classes = useStyles();
  const getItemClass = (event) => {
    if (event.status.name) {
      switch (event.status.name) {
        case "Scheduled":
          return classes.readyToPass;
        case "Given":
          return classes.given;
        case "Late":
          return classes.late;
        case "Missed":
          return classes.missed;
        case "Hold":
          return classes.hold;
        case "SA":
          return classes.sa;
        case "NA":
          return classes.na;
        case "Refused":
          return classes.refused;
        case "Destruction":
          return classes.destruction;
        default:
          return classes.scheduled;
      }
    }
  };
  return (
    <React.Fragment>
      <TableCell>
        <Avatar className={[classes.avatarSmall, getItemClass(etar)]}>
          <i class="fas fas fa-band-aid"></i>
        </Avatar>
      </TableCell>
      <TableCell>{moment(etar.scheduledTime).format("h:mm a")}</TableCell>
      <TableCell>
        {etar.resident && (
          <Typography
            variant="subtitle2"
            component={RouterLink}
            to={`/residents/${etar.resident._id}/etar`}
          >
            {`${etar.resident.firstName} ${etar.resident.lastName}`}
          </Typography>
        )}
      </TableCell>
      <TableCell>
        <Typography variant="subtitle1">{etar.name}</Typography>
        <Typography variant="subtitle2">{etar.description}</Typography>
      </TableCell>
      <TableCell>
        {etar.administeredUser && etar.administeredUser.initials &&
          `${etar.administeredUser.initials} - ${moment(etar.administeredTimestamp).format(
            "h:mma"
          )}`}
      </TableCell>
    </React.Fragment>
  );
};

const TarRow = ({
  etar,
  index,
  isEdit,
  selectEditEvent,
  cancelEdit,
  onUpdate,
}) => {
  const [editTar, setEditTar] = React.useState(null);

  React.useEffect(() => {
    if (isEdit && etar) {
      setEditTar(etar);
    }
  }, [isEdit, etar]);

  const updateStatus = (e) => {
    const obj = { ...editTar };
    if (obj) {
      if (obj.status) {
        obj.status.name = e.target.value;
      } else {
        obj.status = { name: e.target.value };
      }
    }
    setEditTar(obj);
  };

  const updateNotes = (e) => {
    const obj = { ...editTar };
    obj.notes = e.target.value;
    setEditTar(obj);
  };

  const saveETar = async () => {
    await updateResidentETar(editTar.resident._id, editTar.treatment, editTar._id, editTar);
    onUpdate(editTar);
  }

  return (
    <TableRow>
      <CommonCells etar={etar} />
      {!isEdit && (
        <React.Fragment>
          <TableCell>
            <IconButton
              size="small"
              onClick={() => selectEditEvent(etar, index)}
            >
              <EditIcon />
            </IconButton>
          </TableCell>
          <TableCell />
          <TableCell>{etar.status ? etar.status.name : null}</TableCell>
          <TableCell>{etar.notes}</TableCell>
        </React.Fragment>
      )}
      {isEdit &&
        editTar && (
          <React.Fragment>
            <TableCell colSpan={2}>
              <ButtonGroup size="small" fullWidth>
                <Button size="small" color="primary" variant="contained" onClick={saveETar}>
                  Save
                </Button>
                <Button size="small" onClick={cancelEdit}>
                  Cancel
                </Button>
              </ButtonGroup>
            </TableCell>
            <TableCell>
              <FormControl>
                <InputLabel htmlFor="status">Status</InputLabel>
                <Select
                  id="status"
                  label="Status"
                  value={editTar && editTar.status ? editTar.status.name : ""}
                  onChange={updateStatus}
                >
                  <MenuItem value={"Scheduled"}>Scheduled</MenuItem>
                  <MenuItem value={"Given"}>Given</MenuItem>
                  <MenuItem value={"Late"}>Late</MenuItem>
                  <MenuItem value={"Missed"}>Missed</MenuItem>
                  <MenuItem value={"Hold"}>Hold</MenuItem>
                  <MenuItem value={"SA"}>Self Administered</MenuItem>
                  <MenuItem value={"NA"}>Not Administered</MenuItem>
                  <MenuItem value={"Refused"}>Refused</MenuItem>
                </Select>
              </FormControl>
            </TableCell>
            <TableCell>
              <TextField multiline row={3} value={editTar.notes} onChange={updateNotes} />
            </TableCell>
          </React.Fragment>
        )}
    </TableRow>
  );
};

export default TarRow;
