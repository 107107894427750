export const MEDICATION_ORDER_HISTORY_ACTIONS = {
    CREATE_MEDICATION_ORDER: "CREATE_MEDICATION_ORDER",
    READ_MEDICATION_ORDER: "READ_MEDICATION_ORDER",
    UPDATE_MEDICATION_ORDER: "UPDATE_MEDICATION_ORDER",
    DELETE_MEDICATION_ORDER: "DELETE_MEDICATION_ORDER",
    CREATE_MEDICATION_ORDER_MEDICATION: "CREATE_MEDICATION_ORDER_MEDICATION",
    UPDATE_MEDICATION_ORDER_MEDICATION: "UPDATE_MEDICATION_ORDER_MEDICATION",
    DELETE_MEDICATION_ORDER_MEDICATION: "DELETE_MEDICATION_ORDER_MEDICATION",
    CREATE_MEDICATION_ORDER_ALLERGIES: "CREATE_MEDICATION_ORDER_ALLERGIES",
    UPDATE_MEDICATION_ORDER_ALLERGIES: "UPDATE_MEDICATION_ORDER_ALLERGIES",
    DELETE_MEDICATION_ORDER_ALLERGIES: "DELETE_MEDICATION_ORDER_ALLERGIES",
    CREATE_MEDICATION_ORDER_PHYSICIAN: "CREATE_MEDICATION_ORDER_PHYSICIAN",
    UPDATE_MEDICATION_ORDER_PHYSICIAN: "UPDATE_MEDICATION_ORDER_PHYSICIAN",
    DELETE_MEDICATION_ORDER_PHYSICIAN: "DELETE_MEDICATION_ORDER_PHYSICIAN",
    CREATE_MEDICATION_ORDER_PHARMACY: "CREATE_MEDICATION_ORDER_PHARMACY",
    UPDATE_MEDICATION_ORDER_PHARMACY: "UPDATE_MEDICATION_ORDER_PHARMACY",
    DELETE_MEDICATION_ORDER_PHARMACY: "DELETE_MEDICATION_ORDER_PHARMACY",
    CREATE_DOSAGE_INSTRUCTION: "CREATE_DOSAGE_INSTRUCTION",
    UPDATE_DOSAGE_INSTRUCTION: "UPDATE_DOSAGE_INSTRUCTION",
    DELETE_DOSAGE_INSTRUCTION: "DELETE_DOSAGE_INSTRUCTION",
    CREATE_MECICATION_ORDER_HOLD: "CREATE_MECICATION_ORDER_HOLD",
    UPDATE_MECICATION_ORDER_HOLD: "UPDATE_MECICATION_ORDER_HOLD",
    DELETE_MECICATION_ORDER_HOLD: "DELETE_MECICATION_ORDER_HOLD",
}
