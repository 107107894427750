import apiService from '../utils/api.utils';
import configs from '../utils/configs';
import {parseFiltersToJson} from "../utils/filter.utils";

const getPermissions = async (page = 1,
                              size = 25,
                              sort = 'name',
                              sortDirection = 'asc',
                              filters = []) => {
  try {
    let url = `${configs.userService}/api/v1.0/permissions?page=${page}&size=${size}`;
    url += `&sort=${sortDirection !== 'asc' ? '-': ''}${sort}`;
    if (filters && filters.length > 0) {
      url += parseFiltersToJson(filters)
    }
    const response = await apiService.client.get(url);
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
};

const getAllPermissions = async () => {
  try {
    let url = `${configs.userService}/api/v1.0/permissions?size=500&sort=name`;
    const response = await apiService.client.get(url);
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
}



const getPermission = async (id) => {
  try {
    const response = await apiService.client.get(
      `${configs.userService}/api/v1.0/permissions/${id}`
    );
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
};

const createPermission = async (permission) => {
  try {
    const response = await apiService.client.post(
      `${configs.userService}/api/v1.0/permissions`,
      permission
    );
    return response.data;
  } catch (error) {
    return {error: true, message: error.response.data}
  }
};

const updatePermission = async (id, permission) => {
  try {
    const response = await apiService.client.put(
      `${configs.userService}/api/v1.0/permissions/${id}`,
      permission
    );
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
};

const deletePermission = async (id) => {
  try {
    const response = await apiService.client.delete(
      `${configs.userService}/api/v1.0/permissions/${id}`
    );
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
};

export {
  getPermissions,
  getAllPermissions,
  getPermission,
  createPermission,
  updatePermission,
  deletePermission,
};
