import React from 'react';
import { Paper, makeStyles } from '@material-ui/core';
import { ResidentContext } from '../../../context/residentcontext';
import ResidentPhones from './resident.phones';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1),
  },
  btnContainer: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyItems: 'center',
    justifyContent: 'flex-end',
  },
}));

const ResidentPhonePage = () => {
  const classes = useStyles();
  const { state } = React.useContext(ResidentContext);

  return (
    <Paper elevation={24} className={classes.root}>
      {state.resident && <ResidentPhones residentId={state.resident._id} />}
    </Paper>
  );
};

export default ResidentPhonePage;
