import React from 'react';
import {ACTIONS, createTimeEntry, TimeEntryContext} from "../../context/TimeEntryContext/time.entry.context";
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import {TimeEntryForm} from "./form/time.entry.form";
import moment from "moment/moment";


export const ClockInDialog = () => {
  const { state, dispatch } = React.useContext(TimeEntryContext);
  const { user, viewClockIn } = state;


  const onClockIn = async (clockInEvent) => {
    const data = {
      user: user?._id,
      date: moment().format("MM/DD/YYYY"),
      ...clockInEvent
    };
    await createTimeEntry(dispatch, data, true);
  }

  const handleClose = () => {
    dispatch({type: ACTIONS.SET_VIEW_CLOCK_IN, payload: false});
  }

  return (
      <Dialog open={viewClockIn}
              maxWidth={"md"}
              fullWidth={true}
      >
          <DialogTitle>Clock In</DialogTitle>
          <DialogContent dividers>
              <TimeEntryForm user={user}
                             onCancel={handleClose}
                             initalDate={moment()}
                             clockInOnly={true}
                             onSave={onClockIn}/>
          </DialogContent>
      </Dialog>
  )
}
