const model = {
  email: {
    title: "Email",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    date: false
  },
};

export default model;
