import React, { useState } from 'react';
import {Autocomplete} from "@material-ui/lab";
import {Avatar, ListItem, ListItemAvatar, ListItemText, TextField} from "@material-ui/core";
import {fuzzyUserSearch} from "../../../services/user.service";
import * as _ from 'lodash';

const UserSearch = ({onSelect, onClear}) => {
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState(null);
    const [searching, setSearching] = useState(false);

    const onSearchChange = (event) => {
        setSearchTerm(event.target.value);
        if (event?.target?.value === "*") {
            searchUsers("*");
        } else if (event?.target?.value.length > 2) {
            searchUsers(event?.target?.value);
        } else {
            setUsers([]);
        }
    }

    const searchUsers = async term => {
        setSearching(true);
        const response = await fuzzyUserSearch(term);
        if (!response?.error) {
            setUsers(response);
        } else {
            setUsers([]);
        }
        setSearching(false);
    }

    const getRoleNames = roles => {
        return _.uniq(roles?.map(r => r?.role?.name))?.join(', ');
    }

    const onUserChanged = (event, value) => {
        if (value) {
            onSelect(value);
        } else {
            onClear(true)
        }
    }

    return (
        <Autocomplete
            style={{ height: '3.5rem' }}
            labelId="autocomplete-label"
            title="PRN"
            freeSolo
            id="combo-box-demo"
            options={users}
            autoHighlight
            autoCorrect
            onChange={onUserChanged}
            loading={searching}
            getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
            renderOption={(option, { selected }) => (
                <ListItem divider>
                    <ListItemAvatar>
                        <Avatar src={option.picture}></Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={`${option.firstName} ${option.lastName}`}
                        secondary={getRoleNames(option?.roles)}
                    />
                </ListItem>
            )}
            renderInput={(params) => (
                <TextField color={"primary"}
                           {...params}
                           label="Search User...."
                           variant="outlined"
                           size={"small"}
                           value={searchTerm}
                           onChange={onSearchChange}
                />
            )}
        >
        </Autocomplete>
    )
}

export default UserSearch;
