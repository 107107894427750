import React from "react";
import {
  Button,
  FormGroup,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  DialogContent,
  makeStyles,
} from "@material-ui/core";
import Transition from "../../../dialog/transition";
import CloseIcon from "@material-ui/icons/Close";
import FormFieldsGenerator from "../../../../utils/forms/form.fields.generator";
import modelTemplate from "./modelTemplate";
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    marginTop: "4rem",
  },
}));

const EditResidentVital = ({ open, onClose, onSave, residentId, vital, canEdit, users }) => {
  const classes = useStyles();
  const [model, setModel] = React.useState(
    JSON.parse(JSON.stringify({}))
  );

  React.useEffect(() => {
    if (open && vital) {
        const obj = JSON.parse(JSON.stringify(modelTemplate));
        Object.keys(vital).forEach(key => {
          if (key === "staff") {
            if (vital[key]?._id) {
              obj[key].value = vital[key]._id
            } else {
              obj[key].value = vital[key]
            }
          } else if (obj[key]) {
            obj[key].value = vital[key]
          }
        });
        obj.staff.dropDownItems = users;
        setModel(obj);
    }
  }, [open, vital, users]);




  const onModelChange = (updatedModel) => {
    const obj = { ...model, ...updatedModel };
    setModel(obj);
  };

  const isValid = () => {
    let valid = false;
    Object.keys(model).forEach((key) => {
      if (model[key].value && String(model[key].value).length > 0) {
        valid = true;
      }
    });
    return valid;
  };

  const save = () => {
    let data = {};
    Object.keys(model).forEach(key => {
        if (model[key]) {
            data[key] = model[key].value
        }
    });
    onSave(residentId, vital._id, data);
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Edit Vital - {vital && moment(vital.createdAt).format('MM/DD/YYYY hh:mm:ss a')}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.dialogContent}>
        <form
          className={classes.form}
          style={{ width: "100%", marginBottom: "1rem" }}
          noValidate
        >
          <FormFieldsGenerator model={model} onChange={onModelChange} edit={canEdit} />
          <FormGroup
            row
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "1rem",
            }}
          >
            <Button onClick={onClose}>Close</Button>
            {canEdit &&
            <Button onClick={save} variant="contained" color="primary" disabled={!isValid()}>
              Save
            </Button>}
          </FormGroup>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditResidentVital;
