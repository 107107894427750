import React from 'react';
import {
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
  DialogContent,
  DialogActions,
  Button,
  FormGroup,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Transition from '../../../dialog/transition';
import createIncontinenceModel from './bm.model';
import { isModelValid } from '../../../../utils/forms/form.validator';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: '4rem',
  },
}));

const BmLog = ({ open, onUpdate, onClose, log, canUpdate, users }) => {
  const classes = useStyles();
  const [model, setModel] = React.useState(
    JSON.parse(JSON.stringify(createIncontinenceModel))
  );

  React.useEffect(() => {
    if (open && log) {
      mergeValues(log);
    }
    //eslint-disable-next-line
  }, [open, log]);

  const mergeValues = (e) => {
    const obj = JSON.parse(JSON.stringify(createIncontinenceModel));
    obj.date.value = e.date;
    obj.time.value = e.time;
    obj.hour.value = e.hour;
    obj.comments.value = e.comments;
    obj.pattern.value = e.pattern;
    obj.color.value = e.color;
    obj.consistency.value = e.consistency;
    obj.staff.value = e?.staff?._id ?? e?.staff;
    obj.staff.dropDownItems = users;
    setModel(obj);
  };

  const onModelChange = (e) => {
    const obj = { ...model };
    obj[e.target.name].dirty = true;
    obj[e.target.name].value = e.target.value;
    setModel(obj);
  };

  const onHourChange = (e) => {
    const obj = { ...model };
    obj.hour.dirty = true;
    obj.hour.value = e;
    setModel(obj);
  };

  const onDateChange = (e) => {
    const obj = { ...model };
    obj.date.dirty = true;
    obj.date.value = e;
    setModel(obj);
  };

  const updateLog = () => {
    const updated = {
      _id: log._id,
      type: 'bm',
      hour: model.hour.value,
      pattern: model.pattern.value,
      color: model.color.value,
      consistency: model.consistency.value,
      date: model.date.value,
      time: model.time.value,
      comments: model.comments.value,
      staff: model.staff.value,
    };
    onUpdate(log._id, updated);
  };

  return (
    <Dialog open={open} fullScreen TransitionComponent={Transition}>
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => onClose()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Incontinence BM Log
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent dividers className={classes.content}>
        <form noValidate>
          <FormGroup>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardTimePicker
                label="Hour"
                placeholder="08:00 AM"
                mask="__:__ _M"
                value={model.hour.value}
                ampm={true}
                disabled={!canUpdate}
                helperText={
                  model.hour.dirty && model.hour.value === ''
                    ? `Hour is required!`
                    : null
                }
                onChange={onHourChange}
                error={!model.hour.isValid && model.hour.dirty}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                size="small"
                fullWidth
                name="date"
                required={true}
                value={model.date.value}
                label={'Date'}
                format="MM/DD/YYYY"
                onChange={onDateChange}
                allowKeyboardControl="true"
                disabled={!canUpdate}
                ampm={false}
                helperText={
                  model.date.dirty && model.date.value === ''
                    ? `Date is required!`
                    : null
                }
                error={!model.date.isValid && model.date.dirty}
              />
            </MuiPickersUtilsProvider>
            <FormControl>
              <InputLabel required id="result-label">
                Bowel Pattern
              </InputLabel>
              <Select
                required
                labelId="pattern-label"
                id="pattern-select"
                value={model.pattern.value}
                name="pattern"
                onChange={onModelChange}
                disabled={!canUpdate}
              >
                {model.pattern.dropDownItems &&
                  model.pattern.dropDownItems.map((item) => (
                    <MenuItem value={item.value}>{item.name}</MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel required id="color-label">
                Color
              </InputLabel>
              <Select
                required
                labelId="color-label"
                id="color-select"
                value={model.color.value}
                name="color"
                onChange={onModelChange}
                disabled={!canUpdate}
              >
                {model.color.dropDownItems &&
                  model.color.dropDownItems.map((item) => (
                    <MenuItem value={item.value}>{item.name}</MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel required id="consistency-label">
                Consistency
              </InputLabel>
              <Select
                required
                labelId="consistency-label"
                id="consistency-select"
                value={model.consistency.value}
                name="consistency"
                onChange={onModelChange}
                disabled={!canUpdate}
              >
                {model.consistency.dropDownItems &&
                  model.consistency.dropDownItems.map((item) => (
                    <MenuItem value={item.value}>{item.name}</MenuItem>
                  ))}
              </Select>
            </FormControl>
            <TextField
              label="Comments"
              name="comments"
              value={model.comments.value}
              onChange={onModelChange}
              disabled={!canUpdate}
              multiline
              rows={4}
            />
            <FormControl>
              <InputLabel required id="time-label">
                Time
              </InputLabel>
              <Select
                required
                labelId="time-label"
                id="time-select"
                value={model.time.value}
                name="time"
                onChange={onModelChange}
                disabled={!canUpdate}
              >
                {model.time.dropDownItems &&
                  model.time.dropDownItems.map((item) => (
                    <MenuItem value={item.value}>{item.name}</MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel required id="time-label">
                Staff
              </InputLabel>
              <Select
                  required
                  labelId="staff-label"
                  id="staff-select"
                  value={model?.staff?.value}
                  name="staff"
                  onChange={onModelChange}
                  disabled={!canUpdate}
              >
                {model.staff.dropDownItems &&
                    model.staff.dropDownItems.map((item) => (
                        <MenuItem value={item?._id}>{item?.firstName} {item?.lastName}</MenuItem>
                    ))}
              </Select>
            </FormControl>
          </FormGroup>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        {canUpdate &&
        <Button
          variant="contained"
          color="primary"
          onClick={updateLog}
          disabled={!canUpdate && !isModelValid(model)}
        >
          Update
        </Button>
        }
      </DialogActions>
    </Dialog>
  );
};

export default BmLog;
