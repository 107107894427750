import React from 'react';
import {Document, Page, View, Text, StyleSheet} from "@react-pdf/renderer";
import {ResidentReportHeader} from "../../../../../reports/pdf/components/ResidentReportHeader";
import moment from "moment/moment";

const BORDER_COLOR = '#d0d0d0';
const BORDER_STYLE = 'solid';

const styles = StyleSheet.create({
  page: {
    padding: '0 0 84px 0'
  },
  titleContainer: {
    paddingTop: 5,
    paddingLeft: 5
  },
  titleText: {
    fontSize: 24,
    margin: '0 auto'
  },
  mainContent: {
    backgroundColor: 'white',
    paddingLeft: 50,
    paddingRight: 50
  },
  bold: {
    fontWeight: 800
  },
  staffContainer: {
    padding: 5,
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomWidth: 1,
  },
  staffGroup: {
    display: "flex",
    flexDirection: "row",
  },
  staffLabel: {
    color: 'black',
    width: 200,
    fontSize: 12,
    fontWeight: 800,
  },
  staffText: {
    color: 'black',
    fontSize: 12,
  },
  detailContainer: {
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderTopWidth: 1,
  },
  notificationGroup: {
    height: 50,
    display: "flex",
    flexDirection: "row"
  },
  notificationLabel: {
    height: 50,
    color: 'black',
    width: 200,
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderRightWidth: 1,
    fontSize: 12,
    fontWeight: 800,
    padding: 5
  },
  notificationText: {
    height: 50,
    width: 400,
    color: 'black',
    fontSize: 12,
    padding: 5,
    overflow: 'hidden',
    wordWrap: 'break-word'
  },
  descriptionGroup: {
    display: "flex",
    flexDirection: "row"
  },
  descriptionLabel: {
    color: 'black',
    width: 200,
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderRightWidth: 1,
    fontSize: 12,
    fontWeight: 800,
    padding: 5
  },
  descriptionText: {
    width: 400,
    color: 'black',
    fontSize: 12,
    padding: 5,
    overflow: 'hidden',
    wordWrap: 'break-word'
  },
  detailGroup: {
    height: 25,
    display: "flex",
    flexDirection: "row"
  },
  detailLabel: {
    color: 'black',
    width: 200,
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderRightWidth: 1,
    fontSize: 12,
    fontWeight: 800,
    padding: 5
  },
  detailText: {
    color: 'black',
    width: 400,
    fontSize: 12,
    padding: 5,
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    padding: '5px'
  },
  footerContainer: {
    marginTop: '5px',
    fontSize: '8px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  odd: {
    backgroundColor: '#e5e5e5'
  },
  signatureSection: {
    marginTop: 50
  },
  signatureContainer: {
    marginTop: 10,
    height: 25,
    display: "flex",
    flexDirection: "row",
    borderStyle: BORDER_STYLE,
    borderColor: 'black',
    borderBottomWidth: 1,
    fontSize: 10,
    fontWeight: 400
  },
  signarureLabel: {
    fontSize: 10,
    flex: 2
  },
  signature: {
    fontSize: 10,
    flex: 2
  },
  signatureDate: {
    fontSize: 10,
    flex: 1
  }
});


export const ResidentIncidentPdf = ({resident, log}) => {

  return (
    <Document size={"a4"}>
      <Page size={"A4"} style={styles.page}>
        <ResidentReportHeader resident={resident}
                              location={resident?.location}
                              allUserLocations={false}
                              displayResidentInfo={true}
                              reportTitle={`Incident Report`}
        />
        <View style={styles.titleContainer}>
          <Text style={styles.titleText}>
            Resident Incident Detail
          </Text>
        </View>
        <View style={styles.mainContent}>
          <View style={styles.staffContainer}>
            <View style={styles.staffGroup}>
              <Text style={styles.staffLabel}>Reporting Staff:</Text>
              <Text style={styles.staffText}>{log?.staff?.firstName}{" "}{log?.staff?.lastName}</Text>
            </View>
          </View>
          <View style={styles.detailContainer}>
            <View style={styles.detailGroup}>
              <Text style={[styles.detailLabel]}>Resident:</Text>
              <Text style={[styles.detailText]}>{resident?.firstName} {resident?.lastName}</Text>
            </View>
            <View style={[styles.detailGroup, styles.odd]}>
              <Text style={[styles.detailLabel]}>Incident date/time:</Text>
              <Text style={styles.detailText}>{moment(log?.date).format("MM/DD/YYYY hh:mm a")}</Text>
            </View>
            <View style={styles.detailGroup}>
              <Text style={[styles.detailLabel]}>Category:</Text>
              <Text style={styles.detailText}>{log?.category?.map(c => c)}</Text>
            </View>
            <View style={[styles.detailGroup, styles.odd]}>
              <Text style={[styles.detailLabel]}>Location:</Text>
              <Text style={styles.detailText}>{log?.location}</Text>
            </View>
            <View style={styles.detailGroup}>
              <Text style={[styles.detailLabel]}>Nature of incident:</Text>
              <Text style={styles.detailText}>{log?.nature}</Text>
            </View>
            <View style={[styles.descriptionGroup, styles.odd]}>
              <Text style={[styles.descriptionLabel]}>Description:</Text>
              <Text style={styles.descriptionText}>{log?.description}</Text>
            </View>
            <View style={styles.detailGroup}>
              <Text style={[styles.detailLabel]}>Witness:</Text>
              <Text style={styles.detailText}>{log?.witness}</Text>
            </View>
            <View style={[styles.detailGroup, styles.odd]}>
              <Text style={[styles.detailLabel]}>Evaluation of plan:</Text>
              <Text style={styles.detailText}>{log?.evaluationOfPlan}</Text>
            </View>
            <View style={styles.detailGroup}>
              <Text style={[styles.detailLabel]}>Condition before:</Text>
              <Text style={styles.detailText}>{log?.conditionBefore}</Text>
            </View>
            <View style={[styles.detailGroup, styles.odd]}>
              <Text style={[styles.detailLabel]}>Condition after:</Text>
              <Text style={styles.detailText}>{log?.conditionAfter}</Text>
            </View>
            <View style={styles.notificationGroup}>
              <Text style={styles.notificationLabel}>Notifications:</Text>
              <Text style={styles.notificationText}>
                {log?.notifications?.map(notification =>
                  <Text>{notification?.type}, {notification?.name} - {moment(notification?.date).format("MM/DD/YYYY hh:mm a")}{'\n'}</Text>
                )}
              </Text>
            </View>
          </View>

          <View style={styles.signatureSection}>
            <View style={styles.signatureContainer}>
              <View style={styles.signarureLabel}>
                <Text>Signature of Resident: </Text>
              </View>
              <View style={styles.signature}>
                <Text>Print Name:</Text>
              </View>
              <View style={styles.signatureDate}>
                <Text>Date:</Text>
              </View>
            </View>
            <View style={styles.signatureContainer}>
              <View style={styles.signarureLabel}>
                <Text>Signature of Staff: </Text>
              </View>
              <View style={styles.signature}>
                <Text>Print Name:</Text>
              </View>
              <View style={styles.signatureDate}>
                <Text>Date:</Text>
              </View>
            </View>
          </View>
        </View>


        <View style={styles.footer} fixed>
          <View style={styles.footerContainer}>
            <View>
              <Text>
                {moment().format("MM/DD/YYYY hh:mm a")}
              </Text>
            </View>
            <View>
              <Text render={({ pageNumber, totalPages }) => (
                `page ${pageNumber} of ${totalPages}`
              )} fixed />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}
