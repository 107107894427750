import React from 'react';
import {
    Box,
    Button, DialogActions, DialogContent,
    DialogTitle,
    Drawer,
    List,
    ListItem, ListItemIcon, ListItemSecondaryAction,
    ListItemText,
    makeStyles, MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {ChevronLeft, ChevronRight, Description, Folder} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    drawerRoot: {
        width: "30vw"
    },
    fileName: {
        position: "absolute",
        right: 0,
        bottom: 0,
        left: 0,
        padding: theme.spacing(2)
    }
}));

export const DestinationMoveField = ({file, files, breadcrumbs, onSelected, value, onChange, onParentChange}) => {
    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [topLevel, setTopLevel] = React.useState([]);
    const [selectedFolder, setSelectedFolder] = React.useState();
    const [selectedTopLevel, setSelectedTopLevel] = React.useState();
    const [children, setChildren] = React.useState([])
    const [selectedObject, setSelectedObject] = React.useState();

    React.useEffect(() => {
        if (drawerOpen) {
            // file parent
            const parent = [...breadcrumbs];
            setTopLevel(parent);
            setSelectedTopLevel(file?.parent ? file?.parent: 'root');
            setSelectedFolder(file?.parent ? file?.parent: 'root');
            onFolderSelect(file?.parent);
        }
        // eslint-disable-next-line
    },[drawerOpen]);

    const onFolderSelect = folderId => {
        if (folderId && String(folderId).toLowerCase() !== "root") {
            const childrenFolders = files?.filter(f => f?.parent === folderId);
            setChildren(childrenFolders);
        } else {
            const childrenFolders = files?.filter(f => !f?.parent);
            setChildren(childrenFolders);
        }
    }


    const handleCancel = () => {
        setDrawerOpen(false);
    }

    const handleSelectedFolder = folder => {
        if (folder?.type !== "file") {
            setSelectedObject(folder);
            setSelectedFolder(folder?._id);
            onParentChange(folder?._id)
        }
    }

    const navigateIntoFolder = (e, folder) => {
        e.preventDefault();
        e.stopPropagation();
        setSelectedObject(folder);
        // Add selected folder to bread crumbs
        const obj = [...topLevel, folder];
        setTopLevel(obj);
        setSelectedTopLevel(folder?._id);
        setSelectedFolder(folder?._id);
        onFolderSelect(folder?._id);
        onParentChange(folder?._id);
    }

    const handleSelected = () => {
        const data = {
            parent: selectedFolder
        }
        setDrawerOpen(false);
        onSelected(data);
    }

    const onTopLevelChange = e => {
        setSelectedTopLevel(e.target.value);
        onFolderSelect(e.target.value);
        onParentChange(e.target.value);
    }

    const goBack = () => {
        const getCurrentIndex = topLevel?.findIndex(f => f?._id === selectedTopLevel);
        if (getCurrentIndex > 0) {
            const newTopLevel = topLevel[getCurrentIndex - 1];
            setSelectedObject(newTopLevel);
            setSelectedTopLevel(newTopLevel?._id);
            onFolderSelect(newTopLevel?._id);
            setSelectedFolder(newTopLevel?._id);
            onParentChange(newTopLevel?._id);
            const obj = [...topLevel]
            obj.pop();
            setTopLevel(obj);
        } else {
            setSelectedFolder('root');
            onParentChange('root');
            setSelectedTopLevel('root');
            setSelectedObject(null);
        }
    }

    return (
        <>
        <TextField fullWidth
                   label={"Destination"}
                   variant={"outlined"}
                   size={"small"}
                   value={value}
                   onChange={onChange}
                   InputProps={{
                       margin: "none",
                       endAdornment: <Button onClick={() => setDrawerOpen(true)}>Browse</Button>,
                   }}
        />
        <Drawer anchor={"right"}
                open={drawerOpen}
                className={classes.drawerRoot}
                onClose={() => setDrawerOpen(false)}>
            <div className={classes.drawerRoot}>
                <DialogTitle>Select Object</DialogTitle>
                <DialogContent dividers style={{height: "85vh", position: "relative"}}>
                    <Box display={"flex"}>
                        <IconButton onClick={goBack}>
                            <ChevronLeft />
                        </IconButton>
                        <Select fullWidth
                                value={selectedTopLevel}
                                onChange={onTopLevelChange}
                        >
                            {topLevel?.map(tl =>
                                <MenuItem value={tl?._id}>{tl?.label}</MenuItem>
                            )}
                        </Select>
                    </Box>
                    <List>
                        {children?.map(child =>
                            <ListItem button
                                      selected={child?._id === selectedObject?._id}
                                      onClick={() => handleSelectedFolder(child)}>
                                <ListItemIcon>
                                    {child?.type !== "file" ? <Folder fontSize={"small"} />: <Description fontSize={"small"} />}
                                </ListItemIcon>
                                <ListItemText primary={child?.label} />
                                {child?.type !== "file" &&
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={(e) => navigateIntoFolder(e, child)}>
                                            <ChevronRight />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                }
                            </ListItem>
                        )}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button onClick={handleSelected}
                            variant={"contained"}
                            color={"primary"}>
                        Select
                    </Button>
                </DialogActions>
            </div>
        </Drawer>
        </>
    )
}
