import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import React from 'react';
import Transition from '../../dialog/transition';
import CloseIcon from '@material-ui/icons/Close';
import Model from './diet.model';
import { FormFieldsGenerator, isModelValid } from '../../../utils/forms';
import { ObjectId } from 'bson';
import Meals from './meals';

const CreateDiet = ({ open, onClose, onSave }) => {
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(Model)));
  React.useEffect(() => {
    if (open) {
      const obj = JSON.parse(JSON.stringify(Model));
      obj.meals.value = obj.meals.value.map((meal) => {
        meal._id = new ObjectId();
        return meal;
      });
      setModel(obj);
    }
  }, [open]);

  const onModelChange = (e) => {
    const obj = { ...model, ...e };

    setModel(obj);
  };

  const onDietTemplatesChange = (e) => {
    const obj = { ...model };
    const existing = obj.meals.value.find((dt) => dt._id === e._id);
    if (existing) {
      obj.meals.value = obj.meals.value.map((meal) =>
        meal._id === e._id ? e : meal
      );
    } else {
      obj.meals.value = [...obj.meals.value, e];
    }
    setModel(obj);
  };

  const save = () => {
    onSave({
      _id: new ObjectId(),
      type: model.type.value,
      startDate: model.startDate.value,
      endDate: model.endDate.value,
      description: model.description.value,
      calories: model.calories.value,
      tags: model.tags.value,
      meals: model.meals.value,
    });
  };

  const onRemoveMeal = (id) => {
    const obj = { ...model };
    obj.meals.value = obj.meals.value.filter((meal) => meal._id !== id);
    setModel(obj);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      fullScreen
    >
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Create Diet</Typography>
        </Toolbar>
      </AppBar>
      <DialogContent style={{ marginTop: '4rem' }}>
        <FormFieldsGenerator model={model} onChange={onModelChange} />
        <Meals
          meals={model.meals.value}
          onChange={onDietTemplatesChange}
          onRemove={onRemoveMeal}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!isModelValid(model)}
          onClick={save}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateDiet;
