import React from 'react';
import {
  Avatar,
  Badge,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid, Hidden,
  List,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  TablePagination,
  Tooltip,
  Typography,
  withStyles
} from "@material-ui/core";
import {NotificationImportant, RotateLeftTwoTone, Warning, WarningRounded, WarningTwoTone} from "@material-ui/icons";
import {
  TenantLocationAlertContext,
  TenantLocationAlertContextActions
} from "../../../context/TenantLocationAlertContext";
import {ListLoading} from "../../../utils/indicators/list.loading";
import ResidentAlert from "../../resident/alert/resident.alert";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";


const useStyles = makeStyles((theme) => ({
  notificationButton: {
    color: theme.palette.primary.contrastText,
    marginRight: theme.spacing(1)

  },
  notificationBadge: {},
  menu: {
    marginTop: '3vh'
  },
  menuItem: {
    width: '30vh'
  },
  spinner: {
    color: '#fff',
  },
  current: {
    background: theme.palette.primary.dark,
    cursor: 'pointer',
  },
  late: {
    background: theme.palette.warning.main,
    cursor: 'pointer',
  },
  missed: {
    background: theme.palette.error.light,
    cursor: 'pointer',
  },
  highlightedMenuItem: {
    width: '30vh',
  },
  standout: {
    color: theme.palette.error.main
  }
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    background: theme.palette.error.dark
  },
}))(Badge);


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const MyDialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const NotificationsButton = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { state, dispatch, toggleAlert, getAlertsForType, getTenantLocationAlerts } = React.useContext(TenantLocationAlertContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const retry = async () => {
    await viewAlerts(state?.type);
  }

  const refresh = async () => {
    await getTenantLocationAlerts()
  }

  const viewAlerts = async type => {
    setAnchorEl(null);
    dispatch({type: TenantLocationAlertContextActions.SHOW_ALERTS_DIALOG, payload: type });
    await getAlertsForType(type);
  }

  const onDialogClose = () => {
    dispatch({type: TenantLocationAlertContextActions.CLOSE_ALERTS_DIALOG});
  }

  const onDismissedChange = async alert => {
    await toggleAlert(alert);
  };

  const handlePageChange = async (e, pageNumber) => {
    await getAlertsForType(state.type, pageNumber + 1, state.size);
  }

  const handleSizeChange = async (event) => {
    await getAlertsForType(state.type, 1, event.target.value);
  }

  const getNotificationCount = () => {
    return state?.currentCount + state?.lateCount + state?.missedCount;
  }

  if (state?.loading) {
    return (
      <Button className={classes.notificationButton}
              disabled
              startIcon={<CircularProgress size={20} className={classes.spinner} color={"secondary"} />}>
        Notifications
      </Button>
    )
  }

  if (!state?.loading && state?.error) {
    return (
      <div className={classes.root}>
        <Tooltip title={<div className={classes.errorContent}>
          <Grid container
                alignItems={"center"}
                justifyContent={"center"}>
            <WarningTwoTone className={classes.errorLogo}/>
            <Typography variant={"subtitle2"}>Whoops, something went wrong.</Typography>
            <Typography variant={"subtitle1"}>Please click the retry icon to view alerts</Typography>
          </Grid>
        </div>}>

          <Button className={classes.notificationButton}
                  startIcon={<Warning/>}
                  onClick={refresh}>
            Notifications
          </Button>
        </Tooltip>
      </div>
    )
  }


  if (!state?.loading && !state?.error) {
    return (
      <>
        <Button className={classes.notificationButton}
                onClick={handleClick}
                startIcon={
                  <StyledBadge className={classes.notificationBadge}
                               overlap={"rectangular"}
                               badgeContent={getNotificationCount()}>
                    <NotificationImportant/>
                  </StyledBadge>
                }>
          <Hidden xsDown>Notifications</Hidden>
        </Button>
        <Menu
          id="notifications-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className={classes.menu}
        >
          <MenuItem className={classes.menuItem}
                    disabled
                    divider
          >
            Notifications
          </MenuItem>
          {state?.prnRecheckCount > 0 &&
            <MenuItem className={classes.highlightedMenuItem}
                      onClick={() => viewAlerts('prnRecheck')}
                      divider>
              <ListItemIcon>
                <Avatar className={classes.prnRecheck}>
                  {state?.prnRecheckCount}
                </Avatar>
              </ListItemIcon>
              <ListItemText primary={"Prn Recheck"} />
              <Hidden xsDown>
                <ListItemSecondaryAction>
                  <WarningRounded className={classes.standout} />
                </ListItemSecondaryAction>
              </Hidden>
            </MenuItem>}
          {state?.currentCount > 0 &&
            <MenuItem className={classes.menuItem}
                      onClick={() => viewAlerts('current')}
                      divider>
              <ListItemIcon>
                <Avatar className={classes.current}>
                  {state?.currentCount}
                </Avatar>
              </ListItemIcon>
              <ListItemText primary={"Current"} />
            </MenuItem>}
          {state?.lateCount > 0 &&
            <MenuItem className={classes.menuItem}
                      onClick={() => viewAlerts('late')}
                      divider>
              <ListItemIcon>
                <Avatar className={classes.late}>
                  {state?.lateCount}
                </Avatar>
              </ListItemIcon>
              <ListItemText primary={"Late"} />
            </MenuItem>}
          {state?.missedCount > 0 &&
            <MenuItem className={classes.menuItem}
                      onClick={() => viewAlerts('missed')}
                      divider>
              <ListItemIcon>
                <Avatar className={classes.missed}>
                  {state?.missedCount}
                </Avatar>
              </ListItemIcon>
              <ListItemText primary={"Missed"} />
            </MenuItem>}
        </Menu>
        <Dialog open={state?.showAlertsDialog}
                fullWidth
                maxWidth="md"
                onClose={onDialogClose}>
          {state?.type === "prnRecheck" ?
            <MyDialogTitle onClose={onDialogClose}>PRN RECHECK ALERTS</MyDialogTitle>:
            <MyDialogTitle onClose={onDialogClose}>{String(state?.type).toUpperCase()} ALERTS</MyDialogTitle>
          }
          {state?.loadingAlertTypes &&
            <DialogContent>
              <ListLoading rows={10} />
            </DialogContent>
          }
          {!state?.loadingAlertTypes && state?.loadingAlertsError &&
            <DialogContent dividers>
              <DialogContentText>
                <Grid container
                      alignItems={"center"}
                      justify={"center"}>
                  <WarningTwoTone className={classes.errorLogo} />
                  <Typography variant={"subtitle2"}>Whoops, something went wrong.</Typography>
                  <Typography variant={"subtitle1"}>Please click the retry button to view alerts</Typography>
                </Grid>
              </DialogContentText>
            </DialogContent>
          }
          {!state?.loadingAlertTypes && !state?.loadingAlertsError &&
            <DialogContent dividers>
              <TablePagination count={state?.totalItems}
                               page={state?.page - 1}
                               component={"div"}
                               rowsPerPageOptions={[5, 10, 25, 50]}
                               onChangePage={handlePageChange}
                               onChangeRowsPerPage={handleSizeChange}
                               rowsPerPage={state?.size}
              />
              <List className={classes.listContainer}>
                {state?.alerts?.map(alert=> (
                  <ResidentAlert
                    entity={alert}
                    onDismissedChange={onDismissedChange}
                    onViewEntity={onDialogClose}
                    type="late"
                    showResidentName={true}
                  />
                ))}
              </List>
            </DialogContent>
          }
          <DialogActions>
            {!state?.loadingAlertTypes && state?.loadingAlertsError &&
              <Button variant={"contained"}
                      color={"primary"}
                      onClick={retry}
                      startIcon={<RotateLeftTwoTone/>}>
                Retry
              </Button>
            }
          </DialogActions>
        </Dialog>
      </>
    )
  }
}
