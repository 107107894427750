import React from 'react';
import { UserContext } from '../context/usercontext';

const useTenantLocationHooks = () => {
  const { dispatch } = React.useContext(UserContext);

  const onLocationUpdate = (location) => {
    dispatch({ type: 'locationUpdated', payload: location });
  };

  const onTenantUpdate = (tenant) => {
    dispatch({ type: 'selectedTenantUpdated', payload: tenant });
  };

  return [onLocationUpdate, onTenantUpdate];
};

export default useTenantLocationHooks;
