import React from "react";
import {
  Dialog,
  Button,
  AppBar,
  Grid,
  Toolbar,
  IconButton,
  Typography,
  DialogContent,
  TablePagination,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TextField,
  TableBody,
  makeStyles,
} from "@material-ui/core";
import Transition from "../dialog/transition";
import CloseIcon from "@material-ui/icons/Close";
import { searchContacts } from "../../services/contact.service";
import CreateContact from "./create.contact";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    paddingTop: "4rem",
  },
  appBar: {},
  dialogContent: {
    marginTop: "4rem",
  },
}));

const sizeOptions = [1, 5, 10, 15, 25];

const getValidFilters = (state) => {
  if (state) {
    let filters = [];
    Object.keys(state).forEach((k) => {
      if (state[k] && state[k].operator === "==") {
        // Select Options
        if (state[k].value !== -1) {
          filters = [
            ...filters,
            {
              field: state[k].field,
              operator: state[k].operator,
              value: state[k].value,
            },
          ];
        }
      } else if (
        state[k] &&
        state[k].operator === "*=" &&
        state[k].value &&
        state[k].value.length > state[k].minLength
      ) {
        // Text Options
        filters = [
          ...filters,
          {
            field: state[k].field,
            operator: state[k].operator,
            value: state[k].value,
          },
        ];
      }
    });
    return filters;
  } else {
    return null;
  }
};

const ContactLookup = ({ onSelect, open, onClose }) => {
  const classes = useStyles();
  const [openCreateContactModal, setOpenCreateContactModal] = React.useState(
    false
  );
  const [contacts, setContacts] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [size, setSize] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [sortOrderBy, setSortOrderBy] = React.useState("id");
  const [sortOrderDirection, setSortOrderDirection] = React.useState("asc");
  const [filters, setFilters] = React.useState({
    firstName: {
      field: "firstName",
      operator: "*=",
      value: null,
      minLength: 2,
    },
    lastName: {
      field: "lastName",
      operator: "*=",
      value: null,
      minLength: 2,
    },
    email: {
      field: "email",
      operator: "*=",
      value: null,
      minLength: 2,
    },
  });
  React.useEffect(() => {
    const defaultSearch = async () => {
      const result = await searchContacts();
      setContacts(result.content);
      setTotal(result.paginator.totalItems);
      setSize(result.paginator.perPage);
      setPage(result.paginator.currentPage);
    };
    if (open) {
      defaultSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onChangeRowsPerPage = async (event) => {
    setSize(event.target.value);
    await lookupContacts(
      page,
      event.target.value,
      filters,
      sortOrderBy,
      sortOrderDirection
    );
  };
  const handleChangePage = async (event, pg) => {
    setPage(pg);
    await lookupContacts(
      pg + 1,
      size,
      filters,
      sortOrderBy,
      sortOrderDirection
    );
  };

  const clearFilters = async () => {
    const filts = { ...filters };
    Object.keys(filts).forEach((k) => {
      filts[k].value = null;
    });
    setFilters(filts);
    await lookupContacts(
      page,
      size,
      [],
      sortOrderBy,
      sortOrderDirection
    );
  };

  const filterTextChange = async (event) => {
    const obj = { ...filters };
    obj[event.target.name].value = event.target.value;
    setFilters(obj);
    const filterz = getValidFilters(obj);
    await lookupContacts(
      page,
      size,
      filterz,
      sortOrderBy,
      sortOrderDirection
    );
  };

  const createSortHandler = async (field) => {
    const sort = field;
    const sortDirection = sortOrderDirection === "asc" ? "desc" : "asc";
    setSortOrderBy(sort);
    setSortOrderDirection(sortDirection);
    await lookupContacts(page, size, filters, sort, sortDirection);
  };

  const lookupContacts = async (p, s, f, ss, sd) => {
    const result = await searchContacts( p, s, f, ss, sd);
    setContacts(result.content);
    setTotal(result.paginator.totalItems);
    setSize(result.paginator.perPage);
    setPage(result.paginator.currentPage);
  };

  const contactSaved = (contact) => {
    const obj = [...contacts];
    obj.unshift(contact);
    setContacts(obj);
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => onClose()}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => onClose()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Search Contacts
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.dialogContent}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <div>
            <Button onClick={() => setOpenCreateContactModal(true)}>
              Create
            </Button>
            <Button startIcon={<HighlightOffIcon />} onClick={clearFilters}>
              Clear Filters
            </Button>
            <CreateContact onSave={contactSaved} open={openCreateContactModal} onClose={() => setOpenCreateContactModal(false)} />
          </div>
          <TablePagination
            component="div"
            rowsPerPageOptions={sizeOptions}
            colSpan={5}
            count={total}
            rowsPerPage={size}
            page={page - 1}
            backIconButtonProps={{
              "aria-label": "previous page",
              disabled: page === 1 ? true : false,
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
              disabled: page * size > total ? true : false,
            }}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onChangePage={handleChangePage}
          ></TablePagination>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortOrderBy === "firstName"}
                  direction={sortOrderDirection}
                  onClick={() => createSortHandler("firstName")}
                >
                  First name
                </TableSortLabel>
                <TextField
                  name="firstName"
                  margin="dense"
                  size="small"
                  placeholder="Search"
                  variant="outlined"
                  fullWidth={true}
                  onChange={filterTextChange}
                  value={filters.firstName.value || ""}
                ></TextField>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortOrderBy === "lastName"}
                  direction={sortOrderDirection}
                  onClick={() => createSortHandler("lastName")}
                >
                  Last name
                </TableSortLabel>
                <TextField
                  name="lastName"
                  margin="dense"
                  size="small"
                  placeholder="Search"
                  variant="outlined"
                  fullWidth={true}
                  onChange={filterTextChange}
                  value={filters.lastName.value || ""}
                ></TextField>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortOrderBy === "email"}
                  direction={sortOrderDirection}
                  onClick={() => createSortHandler("email")}
                >
                  Email
                </TableSortLabel>
                <TextField
                  name="email"
                  margin="dense"
                  size="small"
                  placeholder="Search"
                  variant="outlined"
                  fullWidth={true}
                  onChange={filterTextChange}
                  value={filters.email.value || ""}
                ></TextField>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contacts &&
              contacts.map((contact) => (
                <TableRow>
                  <TableCell>
                    <Button size="small"
                      onClick={() => onSelect({ contact, contactType: {} })}
                    >
                      Select
                    </Button>
                  </TableCell>
                  <TableCell>{contact.firstName}</TableCell>
                  <TableCell>{contact.lastName}</TableCell>
                  <TableCell>{contact.email}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};

export default ContactLookup;
