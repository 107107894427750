import React from 'react';
import CurrentMedicationAlert from './medication/current.alert';
import LateMedicationAlert from './medication/late.alert';
import MissedMedicationAlert from './medication/missed.alert';
import CurrentTaskAlert from './task/current.task.alert';
import LateTaskAlert from './task/late.task.alert';
import MissedTaskAlert from './task/missed.task.alert';
import CurrentTreatmentAlert from './treatment/current.treatment.alert';
import LateTreatmentAlert from './treatment/late.treatment.alert';
import MissedTreatmentAlert from './treatment/missed.treatment.alert';
import {RecheckAlert} from "./medication/recheck.alert";

const ResidentAlert = ({ entity, onDismissedChange, onViewEntity, type, showResidentName = false }) => {
  const [model, setModel] = React.useState();

  React.useEffect(() => {
    if (entity) {
      setModel(entity);
    }
  }, [entity]);

  if (model && model.type) {
    switch (String(model.type).toLowerCase()) {
      case 'current_medication':
        return (
          <CurrentMedicationAlert
            medicationAlert={model}
            onViewEntity={onViewEntity}
            onDismissedChange={onDismissedChange}
            showResidentName={showResidentName}
          />
        );
      case 'current med':
        return (
          <CurrentMedicationAlert
            medicationAlert={model}
            onViewEntity={onViewEntity}
            onDismissedChange={onDismissedChange}
            showResidentName={showResidentName}
          />
        );
      case 'late med':
        return (
          <LateMedicationAlert
            medicationAlert={model}
            onViewEntity={onViewEntity}
            onDismissedChange={onDismissedChange}
            showResidentName={showResidentName}
          />
        );
      case 'missed med':
        return (
          <MissedMedicationAlert
            medicationAlert={model}
            onViewEntity={onViewEntity}
            onDismissedChange={onDismissedChange}
            showResidentName={showResidentName}
          />
        );
      case 'current_treatment':
        return (
          <CurrentTreatmentAlert
            treatmentAlert={model}
            onViewEntity={onViewEntity}
            onDismissedChange={onDismissedChange}
            showResidentName={showResidentName}
          />
        );
      case 'late_treatment':
        return (
          <LateTreatmentAlert
            treatmentAlert={model}
            onViewEntity={onViewEntity}
            onDismissedChange={onDismissedChange}
            showResidentName={showResidentName}
          />
        );
      case 'missed_treatment':
        return (
          <MissedTreatmentAlert
            treatmentAlert={model}
            onViewEntity={onViewEntity}
            onDismissedChange={onDismissedChange}
            showResidentName={showResidentName}
          />
        );
      case 'current_task':
        return (
          <CurrentTaskAlert
            alert={model}
            onViewEntity={onViewEntity}
            onDismissedChange={onDismissedChange}
            showResidentName={showResidentName}
          />
        );
      case 'late_task':
        return (
          <LateTaskAlert
            alert={model}
            onViewEntity={onViewEntity}
            onDismissedChange={onDismissedChange}
            showResidentName={showResidentName}
          />
        );
      case 'missed_task':
        return (
          <MissedTaskAlert
            alert={model}
            onViewEntity={onViewEntity}
            onDismissedChange={onDismissedChange}
            showResidentName={showResidentName}
          />
        );
      case 'prn_recheck_medication':
        return (
          <RecheckAlert
            alert={model}
            onViewEntity={onViewEntity}
            onDismissedChange={onDismissedChange}
            showResidentName={showResidentName}
          />
        )
      default:
        return null;
    }
  } else {
    return null;
  }
};

export default ResidentAlert;
