import React from 'react';
import {Switch} from "react-router-dom";
import PrivateRoute from "../../../PrivateRoute";
import {ResidentIntakePage} from "./resident.intake.page";
import NotFound from "../../../layout/NotFound";


export const ResidentIntakePageWrapper = () => {

  return (
      <Switch>
        <PrivateRoute path={"/intake/new"}
                      component={ResidentIntakePage}
                      exact
        />
        <PrivateRoute path={"/intake/:id/:step"}
                      component={ResidentIntakePage}
                      exact
        />
        <PrivateRoute path={"/intake/:id"}
                      component={ResidentIntakePage}
                      exact
        />
        <PrivateRoute path={"/intake"}
                      component={ResidentIntakePage}
                      exact
        />
        <PrivateRoute component={NotFound} path={"*"} />
      </Switch>
  )

}
