import apiUtil from '../../utils/api.utils';
import config from "../../utils/configs";



export const getResidentAssessments = async (residentId,
                                             page = 1,
                                             size = 25,
                                             sort = "CREATED_TIMESTAMP",
                                             sortDirection = "desc") => {
  let query = `${config.assessmentService}/api/v2/residents/`;
  query += `${residentId}/assessments?page=${page}&size=${size}&sort=${sort},${sortDirection}`;
  try {
    const response = await apiUtil.client.get(query);
    return response.data;
  } catch (error) {
    return { error: true, message: error?.response }
  }
};

export const getResidentAssessmentsByAssessment = async (residentId, assessmentId, page = 1, size = 25) => {
  let query = `${config.assessmentService}/api/v2/residents/`;
  query += `${residentId}/assessments/${assessmentId}?page=${page}&size=${size}`;
  try {
    const response = await apiUtil.client.get(query);
    return response.data;
  } catch (error) {
    return { error: true, message: error?.response }
  }
}
