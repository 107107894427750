import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  makeStyles,
  TablePagination
} from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ResidentAlert from "../resident.alert";
import {
  getCurrentLateResidentAlerts,
  getMissingResidentAlerts, getRecheckResidentAlerts
} from "../../../../services/resident.service";
import {ListLoading} from "../../../../utils/indicators/list.loading";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    height: '80vh',
    overflow: 'scroll'
  }
}))

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const ViewAlertsModal = ({open, onClose, residentId, onDismissAlerts, type = "late"}) => {
  const classes = useStyles();
  const [alerts, setAlerts] = React.useState();
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(10);
  const [totalItems, setTotalItems] = React.useState(0);

  React.useEffect(() => {
    if (open && residentId) {
      setPage(1);
      setSize(10);
      getData(1, 10);
    }
  // eslint-disable-next-line
  }, [open, residentId])

  const getData = async (pg, sz) => {
    setLoading(true);
    setError(false);
    let response;
    if (type === "late" || type === "current") {
      response = await getCurrentLateResidentAlerts(residentId, pg, sz);
    } else if (type === "recheck") {
      response = await getRecheckResidentAlerts(residentId, pg, sz);
    } else {
      response = await getMissingResidentAlerts(residentId, pg, sz);
    }
    if (!response?.canceled && !response?.error) {
      setTotalItems(response?.totalDocs);
      setAlerts(response?.docs);
      setLoading(false);
    } else {
      setError(true);
      setLoading(false);
    }
  }

  const handleSizeChange = async (e) => {
    const newSize = e.target.value
    setSize(newSize);
    await getData(page, newSize);

  }

  const handlePageChange = async (e, page) => {
    const newPage = page + 1;
    setPage(newPage);
    await getData(newPage, size);
  }

  const onDismissedChange = updated => {
    const obj = alerts?.map(alert => {
      if (alert?._id === updated?._id) {
        return updated;
      }
      return alert;
    });
    setAlerts(obj);
  }

  return (
    <Dialog
        fullWidth
        maxWidth="md"
        onClose={onClose}
        open={open}
      >
      {type === "late" && <DialogTitle onClose={onClose}>{"Current/Late Alerts"}</DialogTitle>}
      {type === "missed" && <DialogTitle onClose={onClose}>Missed Alerts</DialogTitle>}
      {type === "recheck" && <DialogTitle onClose={onClose}>Prn Recheck</DialogTitle>}
      <DialogContent dividers>
          <TablePagination count={totalItems}
                           component={"div"}
                           onChangePage={handlePageChange}
                           onChangeRowsPerPage={handleSizeChange}
                           page={page - 1}
                           rowsPerPage={size} />
          {!error && loading && <ListLoading rows={5} />}
          <List className={classes.contentContainer}>
            {!error && !loading  && alerts?.map((alert) => (
                <ResidentAlert
                  entity={alert}
                  onDismissedChange={onDismissedChange}
                  onViewEntity={onClose}
                  type="late"
                />
              ))}
          </List>
        </DialogContent>
        <DialogActions>
          {type === "late" &&
            <>
              <Button onClick={() => onDismissAlerts("current")}>Clear Current Alerts</Button>
              <Button onClick={() => onDismissAlerts("late")}>Clear Late Alerts</Button>
            </>
          }
          {type === "missed" &&
            <Button onClick={() => onDismissAlerts("missed")}>Clear Missed Alerts</Button>
          }
          <Button
            onClick={onClose}
            variant="contained"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
  )
}
