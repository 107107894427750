import React from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
  Divider, FormControl, FormGroup,
  Grid,
  IconButton, InputLabel, List,
  makeStyles, MenuItem, Select,
  TextField, Typography,
} from "@material-ui/core";
import {RetryMessage} from "../../notification/retry.message";
import {ResidentListContext} from "./resident.list.context";
import filterTemplate from "./filterTemplate";
import {convertToFilters} from "../../../utils/filter.utils";
import {getResidents} from "./actions/resident.actions";
import ResidentCard from "./resident.card";
import {Pagination} from "@material-ui/lab";
import {ListLoading} from "../../../utils/indicators/list.loading";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(.5, 0),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(1)
  },
  totalsContainer: {
    padding: theme.spacing(0, 1)
  },
  searchContainer: {
    padding: theme.spacing(1, 0)
  },
  resultContainer: {
    height: '75vh',
    overflow: 'scroll'
  }
}))

export const MobileResidentsList = () => {
  const classes = useStyles();
  const { state, dispatch } = React.useContext(ResidentListContext);
  const [showFilter, setShowFilter] = React.useState(false);

  const clearFilters = async () => {
    dispatch({ type: 'setLoading' });
    const locationFilter = state.filter.tenantLocationId;
    const obj = JSON.parse(JSON.stringify(filterTemplate));
    obj.tenantLocationId = locationFilter;
    dispatch({type: 'CLEAR_FILTERS'});
    dispatch({ type: 'setLoading' });
    const filters = convertToFilters(obj);
    const data = await getResidents(
      state.page,
      state.size,
      filters,
      state.sort,
      state.sortDirection
    );
    if (!data?.error) {
      dispatch({ type: 'setResidents', payload: data });
    } else {
      dispatch({type: 'searchResidentsError'})
    }
  };


  // const onAdmittedResident = async (newResident) => {
  //   dispatch({ type: 'addNewResident', payload: newResident });
  // };

  const filterSelectChange = async event => {
    dispatch({type: 'setPage', payload: 0});
    const obj = { ...state.filter };
    if (event.target.value === -1) {
      event.target.value = null;
    }
    obj[event.target.name].value = event.target.value;
    dispatch({ type: 'setFilters', payload: obj });
    await search(0, state.size, obj, state.sort, state.sortDirection);
  }

  const filterTextChange = async (e) => {
    dispatch({type: 'setPage', payload: 0});
    const obj = { ...state.filter };
    obj[e.target.name].value = e.target.value;
    dispatch({ type: 'setFilters', payload: obj });
    if (
      String(obj[e.target.name].value).length === 0 ||
      String(obj[e.target.name].value).length >= obj[e.target.name].minValue
    ) {
      await search(0, state.size, obj, state.sort, state.sortDirection);
    }
  };


  const search = async (page, size, filter, sort, sortDirection) => {
    dispatch({ type: 'setLoading' });
    const filters = convertToFilters(filter);
    const result = await getResidents(page, size, filters, sort, sortDirection);
    if (!result?.error) {
      dispatch({ type: 'setResidents', payload: result });
    } else {
      dispatch({type: 'searchResidentsError'})
    }
  };

  const handlePageChange = async (event, value) => {
    dispatch({type: 'setPage', payload: +value - 1});
    await search(+value - 1, state.size, state.filter, state.sort, state.sortDirection);
  };

  const onSizeChange = async e => {
    const newSize = e?.target?.value;
    dispatch({type: 'setSize', payload: newSize});
    await search(state.page, newSize, state.filter, state.sort, state.sortDirection);
  }

  return (
    <div>
      <div className={classes.header}>
        <div>
          {/*<ResidentIntake*/}
          {/*  token={state.token}*/}
          {/*  tenantId={state.tenantId}*/}
          {/*  tenantLocationId={state.tenantLocationId}*/}
          {/*  tenantLocations={state.tenantLocations}*/}
          {/*  selectedTenantLocationId={state.tenantLocationId}*/}
          {/*  onAdmittedResident={onAdmittedResident}*/}
          {/*/>*/}
        </div>
      </div>
      <Divider />
      <Grid container justify={"space-between"} alignItems={"center"} className={classes.searchContainer}>
        <div className={classes.totalsContainer}>
          <Typography variant={"subtitle2"}>{Number(state?.total).toLocaleString()} Resident(s)</Typography>
        </div>
        <div>
          <Button color={"primary"} onClick={clearFilters} startIcon={<i className="fas fa-ban"></i>}>
            Clear filters
          </Button>
          <IconButton color={"primary"} onClick={() => setShowFilter(true)}>
            <i className="fas fa-filter"></i>
          </IconButton>
        </div>
      </Grid>
      {state?.pageCount > 1 &&
      <Grid container justify={"center"} alignItems={"center"}>
        <Pagination count={state?.pageCount}
                    color="primary"
                    page={state?.page + 1}
                    onChange={handlePageChange}
        />
      </Grid>}
      <List className={classes.resultContainer}>
        {!state?.error &&!state.loading && state?.residents?.map((resident) =>
          <ResidentCard resident={resident} size={4} />
        )}
        {!state?.loading && state?.error && <RetryMessage onRetry={clearFilters} />}
        {state?.loading && <ListLoading rows={5} />}
      </List>
      <Dialog open={showFilter}
              fullScreen={true}
              onClose={() => setShowFilter(false)}>
        <DialogTitle>{"Filters"}</DialogTitle>
        <DialogContent dividers>
          <FormGroup>
            <FormControl>
              <InputLabel shrink>Page size</InputLabel>
              <Select
                name="size"
                fullWidth
                onChange={onSizeChange}
                defaultValue={-1}
                value={state.size || 0}
              >
                {state?.sizeOptions?.map((s, i) => (
                  <MenuItem key={i} value={s}>
                    {s}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormGroup>
          <FormGroup>
            <TextField
              label={"First name"}
              name="firstName"
              margin="dense"
              size="small"
              placeholder="Search first name"
              variant="outlined"
              value={state.filter.firstName.value || ''}
              onChange={filterTextChange}
            ></TextField>
            <TextField
              label={"Last name"}
              name="lastName"
              margin="dense"
              size="small"
              placeholder="Search last name"
              variant="outlined"
              value={state.filter.lastName.value || ''}
              onChange={filterTextChange}
            ></TextField>
            <FormControl>
              <InputLabel shrink>Status</InputLabel>
              <Select
                name="residentStatus"
                fullWidth
                onChange={filterSelectChange}
                defaultValue={-1}
                value={state.filter.residentStatus.value || ''}
              >
                <MenuItem value={-1}>All</MenuItem>
                {state?.residentStatuses?.map((status, i) => (
                  <MenuItem key={i} value={status._id}>
                    {status.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label={"Gender"}
              autoComplete="false"
              name="gender"
              margin="dense"
              size="small"
              placeholder="Search gender"
              variant="outlined"
              value={state.filter.gender.value || ''}
              onChange={(event) => filterTextChange(event, 1)}
            ></TextField>
            <TextField
              label={"Marital Status"}
              autoComplete="false"
              name="maritalStatus"
              margin="dense"
              size="small"
              placeholder="Search marital status"
              variant="outlined"
              value={state.filter.maritalStatus.value || ''}
              onChange={(event) => filterTextChange(event, 3)}
            ></TextField>
            <TextField
              label={"Tags"}
              name="tags"
              margin="dense"
              size="small"
              placeholder="Search tags"
              variant="outlined"
              value={state.filter.tags.value || ''}
              onChange={filterTextChange}
            ></TextField>
            <TextField
              label={"Religious preference"}
              autoComplete="false"
              name="religiousPreference"
              margin="dense"
              size="small"
              placeholder="Search religious preference"
              variant="outlined"
              value={state.filter.religiousPreference.value || ''}
              onChange={filterTextChange}
            ></TextField>
            <TextField
              label={"Room Number"}
              name="roomNumber"
              margin="dense"
              size="small"
              placeholder="Search room number"
              variant="outlined"
              fullWidth={true}
              value={state.filter.roomNumber.value || ''}
              onChange={filterTextChange}
            ></TextField>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button color={"primary"} onClick={() => setShowFilter(false)}>Close</Button>
          <Button variant={"contained"}
                  color={"primary"}
                  onClick={() => setShowFilter(false)}
                  >
            Apply
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  )
}
