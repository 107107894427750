import React from 'react';
import {
  Button,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/SaveTwoTone';
import DeleteIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import EditIcon from '@material-ui/icons/EditTwoTone';
import ViewIcon from '@material-ui/icons/PageviewTwoTone';
import ContactLookup from '../contacts/contact.lookup';
import { Link } from 'react-router-dom';
import PhoneMaskDisplay from '../utils/masks/phone.mask.display';
import {
  createLocationContact,
  deleteLocationContact,
  updateLocationContact,
} from '../../services/tenant.service';

const LocationContacts = ({ location, canUpdate }) => {
  const [addOpen, setAddOpen] = React.useState(false);
  const [locationContacts, setLocationContacts] = React.useState([]);
  const [editLocationContact, setEditLocationContact] = React.useState();
  const [editIndex, setEditIndex] = React.useState(-1);

  React.useEffect(() => {
    setLocationContacts(location.contacts);
  }, [location]);

  const selectContact = async (payload) => {
    const entity = await createLocationContact(location._id, {
      contact: payload.contact,
      type: null,
    });

    setLocationContacts([...locationContacts, entity]);
    setAddOpen(false);
  };

  // <PhoneMaskDisplay value={phone.number} />

  const displayPhones = (contact) => {
    if (!contact || !contact.phones || contact.phones.length === 0) {
      return 'N/A';
    } else {
      return contact.phones.map((phone) => (
        <>
          <PhoneMaskDisplay value={phone.number} />{' '}
          {phone.phoneType ? `(${phone.phoneType.name})` : null}
        </>
      ));
    }
  };

  const onCancelEditLocationContact = () => {
    setEditLocationContact(null);
    setEditIndex(null);
  };

  const onEditLocationContact = (locationContact) => {
    setEditLocationContact(locationContact);
    setEditIndex(locationContact._id);
  };

  const onEditContactChange = (e) => {
    const obj = { ...editLocationContact };
    obj[e.target.name] = e.target.value;
    setEditLocationContact(obj);
  };

  const onUpdateLocationContact = async () => {
    const entity = await updateLocationContact(
      location._id,
      editLocationContact.contact._id,
      editLocationContact
    );
    setLocationContacts(
      locationContacts.map((lc) => (lc._id === entity._id ? entity : lc))
    );
    setEditIndex(null);
    setEditLocationContact(null);
  };

  const onDeleteLocationContact = async () => {
    const contactId = editLocationContact?.contact?._id;

    alert(contactId);
    await deleteLocationContact(
      location._id,
      editLocationContact?.contact?._id
    );
    setLocationContacts(
      locationContacts.filter(
        (lc) => lc.contact._id !== editLocationContact?.contact?._id
      )
    );
    setEditIndex(null);
    setEditLocationContact(null);
  };

  return (
    <Paper>
      {canUpdate && <Button startIcon={<AddIcon />} onClick={() => setAddOpen(true)}>
        Add Contact
      </Button>}
      <ContactLookup
        open={addOpen}
        onClose={() => setAddOpen(false)}
        onSelect={selectContact}
      />

      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="left">Type</TableCell>
              <TableCell align="left">First Name</TableCell>
              <TableCell align="left">Last Name</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Phones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {locationContacts &&
              locationContacts.map((locationContact) => (
                <TableRow>
                  <TableCell>
                    {editIndex === locationContact._id && (
                      <>
                        <Button
                          color="primary"
                          size="small"
                          startIcon={<SaveIcon />}
                          onClick={onUpdateLocationContact}
                        >
                          Save
                        </Button>
                        <Button
                          color="primary"
                          size="small"
                          startIcon={<DeleteIcon />}
                          onClick={onDeleteLocationContact}
                        >
                          Delete
                        </Button>
                        <Button
                          size="small"
                          startIcon={<EditIcon />}
                          color="primary"
                          onClick={onCancelEditLocationContact}
                        >
                          Cancel
                        </Button>
                      </>
                    )}
                    {editIndex !== locationContact._id && (
                      <>
                        <Button
                          color="primary"
                          size="small"
                          startIcon={<ViewIcon />}
                          component={Link}
                          to={`/contacts/${locationContact.contact._id}`}
                        >
                          View
                        </Button>
                        {canUpdate && <Button
                          startIcon={<EditIcon />}
                          color="primary"
                          size="small"
                          onClick={() => onEditLocationContact(locationContact)}
                        >
                          Edit
                        </Button>}
                      </>
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {editIndex === locationContact._id && (
                      <>
                        <Select
                          value={editLocationContact?.type || ''}
                          onChange={onEditContactChange}
                          displayEmpty
                          name="type"
                        >
                          <MenuItem value={null}>N/A</MenuItem>
                          <MenuItem value="Primary">Primary</MenuItem>
                        </Select>
                      </>
                    )}
                    {editIndex !== locationContact._id && (
                      <span>{locationContact.type}</span>
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {locationContact.contact.firstName}
                  </TableCell>
                  <TableCell align="left">
                    {locationContact.contact.lastName}
                  </TableCell>
                  <TableCell align="left">
                    {locationContact.contact.email}
                  </TableCell>
                  <TableCell align="left">
                    {displayPhones(locationContact.contact)}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default LocationContacts;
