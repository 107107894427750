import React from "react";
import { getResidentPhysicians } from "./resident.physicians.actions";
let ResidentPhysiciansContext = React.createContext();
export const LOAD_RESIDENT_PHYSICIANS_START = "LOAD_RESIDENT_PHYSICIANS_START",
  LOAD_RESIDENT_PHYSICIANS_COMPLETE = "LOAD_RESIDENT_PHYSICIANS_COMPLETE",
  UPDATE_RESIDENT_PHYSICIANS_START = "UPDATE_RESIDENT_PHYSICIANS_START",
  UPDATE_RESIDENT_PHYSICIANS_COMPLETE = "UPDATE_RESIDENT_PHYSICIANS_COMPLETE",
  CREATE_RESIDENT_PHYSICIAN_START = "CREATE_RESIDENT_PHYSICIAN_START",
  CREATE_RESIDENT_PHYSICIAN_COMPLETE = "CREATE_RESIDENT_PHYSICIAN_COMPLETE",
  DELETE_RESIDENT_PHYSICIAN_START = "DELETE_RESIDENT_PHYSICIAN_START",
  DELETE_RESIDENT_PHYSICIAN_COMPLETE = "DELETE_RESIDENT_PHYSICIAN_COMPLETE",
  OPEN_PHYSICIAN_DIALOG = "OPEN_PHYSICIAN_DIALOG",
  CLOSE_PHYSICIAN_DIALOG = "CLOSE_PHYSICIAN_DIALOG";

const initialState = {
  loading: false,
  residentPhysicians: [],
  openPhysicianDialog: false
};
let reducer = (state, action) => {
  switch (action.type) {
    case LOAD_RESIDENT_PHYSICIANS_START:
      return { ...state, loading: true };
    case LOAD_RESIDENT_PHYSICIANS_COMPLETE:
      return {
        ...state,
        residentPhysicians: action.payload,
        loading: false
      };
    case UPDATE_RESIDENT_PHYSICIANS_START:
      return { ...state, loading: true };
    case UPDATE_RESIDENT_PHYSICIANS_COMPLETE:
      let phys = state.residentPhysicians;
      if (action.payload) {
        phys = state.residentPhysicians.map(residentPhysician => residentPhysician?._id === action?.payload?._id ?
          action.payload: residentPhysician);
      }
      return { ...state, loading: false, residentPhysicians: phys };
    case OPEN_PHYSICIAN_DIALOG:
      return { ...state, openPhysicianDialog: true };
    case CLOSE_PHYSICIAN_DIALOG:
      return { ...state, openPhysicianDialog: false };
    case CREATE_RESIDENT_PHYSICIAN_START:
      return { ...state, loading: true };
    case CREATE_RESIDENT_PHYSICIAN_COMPLETE:
      return {
        ...state,
        loading: false,
        residentPhysicians: [...state.residentPhysicians, action.payload],
        openPhysicianDialog: false
      };
    case DELETE_RESIDENT_PHYSICIAN_START:
      return { ...state, loading: true };
    case DELETE_RESIDENT_PHYSICIAN_COMPLETE:
      const deletedPhysicians = state.residentPhysicians.filter(rp => rp?.physician?._id !== action.payload);
      return {
        ...state,
        loading: false,
        residentPhysicians: deletedPhysicians
      };
    default:
      return { ...state };
  }
};

const ResidentPhysiciansProvider = ({ residentId, token, children }) => {
  let [state, dispatch] = React.useReducer(reducer, initialState);
  let value = { state, dispatch, residentId, token };

  React.useEffect(() => {
    if (residentId) {
      getResidentPhysicians(dispatch, residentId);
    }
  }, [residentId]);

  return (
    <ResidentPhysiciansContext.Provider value={value}>
      {children}
    </ResidentPhysiciansContext.Provider>
  );
};

let ResidentPhysiciansConsumer = ResidentPhysiciansContext.Consumer;

export {
  ResidentPhysiciansContext,
  ResidentPhysiciansProvider,
  ResidentPhysiciansConsumer
};
