import React from 'react';
import { getResidentLogs } from '../../../services/log.service';
import moment from 'moment';
import {
  Button,
  ButtonGroup,
  Divider,
  Grid,
  List,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import CurrentResidentLog from './current.resident.log';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import * as _ from 'lodash';
import {RotateLeftTwoTone, WarningTwoTone} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  header: {
    marginBottom: theme.spacing(1),
  },
  content: {
    minHeight: '20rem',
    maxHeight: '20rem',
    overflowY: 'scroll',
  },
  errorContent: {
    padding: theme.spacing(5),
    minHeight: '20rem',
    maxHeight: '20rem',
    overflowY: 'scroll'
  },
  retryBtn: {
    marginTop: theme.spacing(2),
    alignSelf: "flex-end"
  },
  errorLogo: {
    color: theme.palette.warning.dark,
    fontSize: '2rem'
  }
}));

const CurrentResidentLogs = ({ residentId }) => {
  const [logs, setLogs] = React.useState([]);
  const [logDate, setLogDate] = React.useState(Date.now());
  const [error, setError] = React.useState(false);
  const classes = useStyles();
  React.useEffect(() => {
    if (residentId) {
      loadData(residentId, Date.now());
    }
  }, [residentId]);

  const loadData = async (id, dt) => {
    setError(false);
    const userLogs = await getResidentLogs(
      id,
      moment(dt).format('YYYY-MM-DD'),
      null
    );
    if (!userLogs?.error) {
      let finalList = userLogs.filter((log) => log.type !== 'intakeoutput');
      const intakes = userLogs.filter((log) => log.type === 'intakeoutput');
      if (intakes) {
        const intakeAmount = _.sumBy(intakes, 'intakeAmount');
        const outputAmount = _.sumBy(intakes, 'outputAmount');
        const item = {
          type: 'intakeoutput',
          intake: intakeAmount,
          output: outputAmount,
        };
        finalList = [item, ...finalList];
      }
      setLogs(finalList);
    } else {
      setError(true);
      setLogs([]);
    }
  };

  const retry = async () => {
    await loadData(residentId, logDate);
  }

  const onPrevious = async () => {
    const obj = moment(logDate).subtract(1, 'day');
    setLogDate(obj);
    await loadData(residentId, obj);
  };

  const onNext = async () => {
    const obj = moment(logDate).add(1, 'day');
    setLogDate(obj);
    await loadData(residentId, obj);
  };

  return (
    <Paper elevation={24} className={classes.root}>
      <Grid container justify="space-between" className={classes.header}>
        <Typography variant="h5">
          {moment(logDate).format('MM/DD/YYYY')} Resident Logs
        </Typography>
        <ButtonGroup>
          <Button
            variant="contained"
            color="primary"
            onClick={onPrevious}
            startIcon={<KeyboardArrowLeftIcon />}
          ></Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onNext}
            endIcon={<KeyboardArrowRightIcon />}
          ></Button>
        </ButtonGroup>
      </Grid>
      <Divider />
      {!error &&
      <div className={classes.content}>
        <List>
          {logs && logs.map((log) => <CurrentResidentLog key={log?._id} log={log} />)}
        </List>
      </div>}
      {error &&
      <div className={classes.errorContent}>
        <Grid container
              alignItems={"center"}
              justify={"center"}>
          <WarningTwoTone className={classes.errorLogo} />
          <Typography variant={"subtitle2"}>Whoops, something went wrong. Please retry to view logs</Typography>
        </Grid>
        <Grid container
              justify={"flex-end"}
              className={classes.errorBtnContainer}
        >
          <Button color={"primary"}
                  className={classes.retryBtn}
                  variant={"contained"}
                  onClick={retry}
                  startIcon={<RotateLeftTwoTone />}>Retry</Button>
        </Grid>
      </div>
      }

    </Paper>
  );
};

export default CurrentResidentLogs;
