import React from 'react';
import moment from "moment";
import {
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  makeStyles,
  Menu,
  MenuItem,
  TextField,
  Typography
} from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import { DatePicker, KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {
  deleteTimeEntryAction,
  TimeEntryContext,
  updateTimeEntryAction
} from "../../../context/TimeEntryContext/time.entry.context";
import {Cancel, Delete, MoreVert, Today} from "@material-ui/icons";
import ChipInput from "material-ui-chip-input";
import useHasRole from "../../../hooks/user.role.hook";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
    borderTopStyle: "solid",
    borderTopWidth: 1,
    borderTopColor: theme.palette.grey["500"],
    "&:last-child": {
      borderBottomStyle: "solid",
      borderBottomWidth: 1,
      borderBottomColor: theme.palette.grey["500"],
    }
  }
}));

export const TimeEntryItem = ({displayDate = false, timeEntry}) => {
  const classes = useStyles();
  const { dispatch } = React.useContext(TimeEntryContext);
  const [timeEntryItem, setTimeEntryItem] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {checkPermission} = useHasRole();
  const canUpdate = checkPermission("TIME_ENTRY_UPDATE");
  const canDelete = checkPermission("TIME_ENTRY_DELETE");
  const canUpdateDateTimes = checkPermission("TIME_ENTRY_DATE_TIME_UPDATE");

  React.useEffect(() => {
    setTimeEntryItem(timeEntry);
  }, [timeEntry])

  const onTotalChanged = (event, e) => {
    let obj = {...timeEntryItem};
    obj.totalMinutesInput = e.target.value;
    setTimeEntryItem(obj);
  }


  const validateTotalTime = async (event) => {
    let durationInput = event?.totalMinutesInput;
    let hourOnly = false;
    if (Number(durationInput) === Math.floor(durationInput)) {
      hourOnly = true;
      durationInput = `${durationInput}:00`
    }
    let duration = moment.duration(durationInput);
    if (duration?.hours() > 0 || duration?.minutes() > 0) {
      let newEnd = moment(event?.start).clone();
      newEnd.add(duration.hours(), 'hours');
      newEnd.add(duration.minutes(), 'minutes');
      event.end = newEnd;
      const data = {...event}
      data.totalMinutes = duration.asMinutes();
      await updateTimeEntryAction(dispatch, data);

      let obj = {...timeEntryItem};
      obj.end = newEnd;
      if (hourOnly) {
        obj.totalMinutesInput = durationInput;
      }
      setTimeEntryItem(obj);
    } else {
      // Not valid, do not update.
      let existingDuration = moment.duration(event?.totalMinutes, "minutes");
      let obj = {...timeEntryItem};
      obj.totalMinutesInput = `${existingDuration.hours()}:${existingDuration.minutes().toLocaleString("en-us",
        {
          minimumIntegerDigits: 2,
          useGrouping: false
        })
      }`;
      setTimeEntryItem(obj);
    }
  }

  const onStartChange = async (event, date) => {

    if (moment(date).isValid()) {
      const startDate = moment(date).set({month: moment(event.start).month(), date: moment(event.start).date()});
      event.start = startDate;
      const data = {...event};
      data.start = startDate;
      let difMinutes = moment.duration(moment(data?.end).diff(startDate));
      data.totalMinutes = difMinutes.asMinutes();
      await updateTimeEntryAction(dispatch, data);
    }
    const obj = {...timeEntryItem};
    if (moment(date).isValid()) {
      const startDate = moment(date).set({month: moment(event.start).month(), date: moment(event.start).date()});
      // get Diff in minutes
      let difMinutes = moment.duration(moment(timeEntryItem?.end).diff(startDate));
      obj.totalMinutes = difMinutes.asMinutes();
      obj.totalMinutesInput = `${difMinutes.hours()}:${difMinutes.minutes().toLocaleString("en-us",
        {
          minimumIntegerDigits: 2,
          useGrouping: false
        })
      }`;
    }
    setTimeEntryItem(obj);
  }

  const onEndChange = async (event, date) => {
    if (date === null || date === undefined) {
      const data = {...event};
      data.end = null;
      data.totalMinutes = null;
      await updateTimeEntryAction(dispatch, data);
    } else if (moment(date).isValid()) {
      const endDate = moment(date).set({month: moment(event.start).month(), date: moment(event.start).date()});
      event.end = endDate;
      const data = {...event};
      data.end = endDate;
      let difMinutes = moment.duration(moment(endDate).diff(data.start));
      data.totalMinutes = difMinutes.asMinutes();
      await updateTimeEntryAction(dispatch, data);
    }
    const obj = {...timeEntryItem};
    if  (date && moment(date).isValid()) {
      let difMinutes = moment.duration(moment(date).diff(obj.start));
      obj.totalMinutes = difMinutes.asMinutes();
      obj.totalMinutesInput = `${difMinutes.hours()}:${difMinutes.minutes().toLocaleString("en-us",
        {
          minimumIntegerDigits: 2,
          useGrouping: false
        })
      }`;
    } else {
      obj.totalMinutes = 0;
      obj.totalMinutesInput = 0;
    }
    setTimeEntryItem(obj);
  }

  const onCommentChange = async e => {
    let obj = {...timeEntryItem}
    obj.comment = e.target.value;
    await updateTimeEntryAction(dispatch, obj);
    setTimeEntryItem(obj);
  }

  const onEventDateChange = async (date) => {
    const obj = {...timeEntryItem};
    obj.date = date;
    await updateTimeEntryAction(dispatch, obj);
  }

  const handleTagAdd = async tag => {
    const obj = {...timeEntryItem};
    obj.tags = [...obj?.tags, tag];
    await updateTimeEntryAction(dispatch, obj);
    setTimeEntryItem(obj);
  }
  const handleTagDelete = async tag => {
    const obj = {...timeEntryItem};
    obj.tags = obj.tags?.filter(t => t !== tag);
    await updateTimeEntryAction(dispatch, obj);
    setTimeEntryItem(obj);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    await deleteTimeEntryAction(dispatch, timeEntryItem?._id);
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid container justifyContent={"space-between"} alignItems={"center"} className={classes.root}>
        <Grid item xs={1}>
          {displayDate && <span>Date: {moment(timeEntryItem?.date)?.format("MM/DD")}</span>}
        </Grid>
        <Grid item xs={2}>
          <ChipInput
            size={"small"}
            variant={"outlined"}
            color={"primary"}
            label={"Tags"}
            disabled={!canUpdate}
            fullWidth
            fullWidthInput
            blurBehavior={"add"}
            value={timeEntryItem?.tags}
            onAdd={handleTagAdd}
            onDelete={handleTagDelete}
            dataSource={null}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField size={"small"}
                     fullWidth
                     onChange={onCommentChange}
                     variant={"outlined"}
                     disabled={!canUpdate}
                     InputLabelProps={{shrink: true}}
                     label={"Comment"}
                     multiline={true}
                     value={timeEntryItem?.comment} />
        </Grid>
        <Grid item xs={2}>
          <KeyboardTimePicker
            size={"small"}
            inputVariant="outlined"
            fullWidth
            disabled={!canUpdate && !canUpdateDateTimes}
            mask="__:__ _M"
            label={"Start"}
            value={timeEntryItem?.start}
            name={"start"}
            KeyboardButtonProps={{ disabled: true, style: { display: 'none' }}}
            onChange={(date) => onStartChange(timeEntryItem, date)}
            style={{marginTop: '1rem', marginBottom: '1rem'}}
          />
        </Grid>
        <Grid item xs={2}>
          <KeyboardTimePicker
            size={"small"}
            inputVariant="outlined"
            fullWidth
            disabled={!canUpdate && !canUpdateDateTimes}
            mask="__:__ _M"
            label={"End"}
            value={timeEntryItem?.end || null}
            name={"end"}
            KeyboardButtonProps={{ disabled: true, style: { display: 'none' }}}
            onChange={(date) => onEndChange(timeEntryItem, date)}
            style={{marginTop: '1rem', marginBottom: '1rem'}}
          />
        </Grid>
        <Grid item>
          <DatePicker TextFieldComponent={({onClick}) => <IconButton onClick={onClick}><Today /></IconButton>}
                      value={timeEntryItem?.date && moment(timeEntryItem.date).utc()}
                      onChange={onEventDateChange}
                      style={{margin: 'auto 0'}}
                      disabled={!canUpdate && !canUpdateDateTimes}
          />
        </Grid>
        <Grid item xs={1}>
          <TextField label={"Total"}
                     size={"small"}
                     variant={"outlined"}
                     disabled={!canUpdate && !canUpdateDateTimes}
                     onChange={(e) => onTotalChanged(timeEntryItem, e)}
                     onBlur={() => validateTotalTime(timeEntryItem)}
                     InputLabelProps={{ shrink: true }}
                     value={timeEntryItem?.totalMinutesInput} />
        </Grid>
        <Grid item>
          <IconButton onClick={handleClick}>
            <MoreVert />
          </IconButton>
        </Grid>
        <Menu
          id={`time-entry-options-menu-${timeEntryItem?._id}`}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <Cancel />
            </ListItemIcon>
            <Typography variant="inherit">Cancel</Typography>
          </MenuItem>
          <Divider />
          {canDelete &&
              <MenuItem onClick={handleDelete}>
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            <Typography variant="inherit">Delete</Typography>
          </MenuItem>
          }
        </Menu>
      </Grid>
    </MuiPickersUtilsProvider>
  )
}
