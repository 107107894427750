import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import {MoreVertOutlined} from "@material-ui/icons";
import {ResidentFileContext} from "../context/ResidentFilesContext";
import {Divider, Menu, MenuItem} from "@material-ui/core";
import {CONSTANTS} from "../context/Constants";
import useHasRole from "../../../../hooks/user.role.hook";

export const RowFolderMenu = ({folder}) => {
    const {dispatch} = React.useContext(ResidentFileContext);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { checkPermission } = useHasRole();
    const canUpdate = checkPermission("RESIDENT_FILE_UPDATE");
    const canHiddenUpdate = checkPermission("RESIDENT_FILE_HIDDEN_UPDATE");


    const onFolderOptionsClick = e => {
        e.preventDefault();
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    }

    const onEditClick = (folder) => {
        dispatch({type: CONSTANTS.SET_SELECTED_FOLDER, payload: folder});
        dispatch({type: CONSTANTS.SET_VIEW_EDIT_FOLDER_MODAL, payload: true});
        setAnchorEl(null);
    }

    const onPermissionClick = (folder) => {
        dispatch({type: CONSTANTS.SET_VIEW_PERMISSION_MODAL, payload: true});
        dispatch({type: CONSTANTS.SET_SELECTED_OBJECT, payload: folder});
        setAnchorEl(null);
    }

    if (canUpdate || canHiddenUpdate) {

        return (
            <>
                <IconButton size={"small"}
                            onClick={onFolderOptionsClick}
                            aria-controls={`${folder?._id}-folder-menu`}
                            aria-haspopup="true">
                    <MoreVertOutlined/>
                </IconButton>
                <Menu
                    id={`${folder?._id}-folder-menu`}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                >
                    {canUpdate && <MenuItem onClick={() => onEditClick(folder)}>Edit</MenuItem>}
                    <Divider/>
                    {canHiddenUpdate && <MenuItem onClick={() => onPermissionClick(folder)}>Permissions</MenuItem>}
                </Menu>
            </>
        )
    } else {
        return null;
    }
}
