import React from 'react';
import {
  Avatar,
  FormControl,
  FormGroup,
  Grid,
  makeStyles,
} from "@material-ui/core";
import {DailyOccurrence} from "./DailyOccurrence";

const useStyles = makeStyles((theme) => ({
  daysContainer: {
    marginTop: '1rem'
  },
  dayAvatar: {
    marginRight: '.25rem',
    cursor: 'pointer'
  },
  dayAvatarSelected: {
    backgroundColor: theme.palette.primary.main
  }
}));

export const EveryWeekdayOccurrence = ({model, onChange}) => {
  const [occurrence, setOccurrence] = React.useState();
  const classes = useStyles();

  React.useEffect(() => {
    if (!model?.occurrence) {
      setOccurrence({
        hidden: true,
        value: {
          start: Date.now(),
          frequency: 'Week',
          cadence: 1,
          days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]
        }
      });
    } else {
      setOccurrence(model?.occurrence);
    }
  }, [model]);

  const isSelectedDay = (day) => {
    if (occurrence?.value?.days) {
      return occurrence?.value?.days?.findIndex(d => d === day) > -1 ? classes.dayAvatarSelected: null;
    } else {
      return null;
    }
  }

  const onToggleDay = day => {
    const obj = {...occurrence};
    if (obj?.value?.days.findIndex(d => d === day) > -1) {
      obj.value.days = obj.value.days.filter(d => d !== day);
    } else {
      obj.value.days = [...obj.value.days, day];
    }
    onChange(obj);
  }

  return (
    <FormGroup style={{
      padding: '0 1rem'
    }}>
        <DailyOccurrence model={model} onChange={onChange} />
        <FormControl>
          <Grid container
                justify={"center"}
                className={classes.daysContainer}
          >
            {Array.of('Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday').map(day =>
              <Avatar className={[classes.dayAvatar, isSelectedDay(day)]}
                      onClick={() => onToggleDay(day)}
              >{day[0]}</Avatar>
            )}
          </Grid>
        </FormControl>
    </FormGroup>
  )
}
