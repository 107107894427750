import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import useGlobalNotification from "../../hooks/notification.hook";
import { makeStyles } from "@material-ui/core";

function Alert(props) {
  return <MuiAlert elevation={24} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const GlobalNotification = () => {
  const classes = useStyles();
  const { open, notification, removeNotification } = useGlobalNotification();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    removeNotification();
  };

  if (notification) {
    return (
      <div className={classes.root}>
        <Snackbar
          hidden={!notification}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: notification.vertical,
            horizontal: notification.horizontal,
          }}
          key={`${notification.vertical},${notification.horizontal}`}
        >
          <Alert onClose={handleClose} severity={notification.type}>
              {notification.message}
          </Alert>
        </Snackbar>
      </div>
    );
  } else {
    return null;
  }
};

export default GlobalNotification;
