import React from 'react';
import {
  Box, Button,
  Collapse,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {PageTitle} from "../../utils";
import {
  getResidentAssessments,
} from "../../../services/v2/resident.assessments.service";
import {useHistory, useParams} from "react-router-dom";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {getStripedStyle} from "../../utils/table.utils";
import {AssessmentsTableComponent} from "./assessments.table.component";
import TableLoadingIndicator from "../../../utils/indicators/table.loading";
import AddIcon from "@material-ui/icons/Add";
import CreateResidentAssessment from "./create.resident.assessment";
import {ResidentContext} from "../../../context/residentcontext";
import {createResidentAssessment} from "../../../services/assessment.service";


const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});


const AssessmentGroupRow = ({residentId, group, index}) => {
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  React.useEffect(() => {
    if (residentId && group && index === 0) {
      setOpen(true);
    }
  }, [residentId, group, index]);

  return (
    <>
      <TableRow className={classes.root} style={getStripedStyle(index)}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{group?._id?.name}</TableCell>
        <TableCell>{group?.count}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <AssessmentsTableComponent assessmentId={group?._id?._id} residentId={residentId} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

const ResidentAssessmentsList = () => {
  // eslint-disable-next-line
  const [loading, setLoading] = React.useState(false);
  // eslint-disable-next-line
  const [error, setError] = React.useState(false);
  const [groups, setGroups] = React.useState([]);
  const {residentId} = useParams();
  const [createAssessmentModal, setCreateAssessmentModal] = React.useState(false);
  const { resident } = React.useContext(ResidentContext);
  const history = useHistory();

  React.useEffect(() => {
      getData().then()
    // eslint-disable-next-line
  }, []);

  const getData = async (pg = 1, sz = 25) => {
    setGroups([])
    setError(null);
    setLoading(true);
    let assessmentGroups = await getResidentAssessments(residentId,  pg, sz);
    if (!assessmentGroups?.error) {
      setGroups(assessmentGroups);
    } else {
      setError(true);
    }

    setLoading(false);
  }

  const onCreateAssessment = async (residentId, assessmentId) => {
    const response = await createResidentAssessment(residentId, assessmentId);
    setCreateAssessmentModal(false);
    history.push(`/residents/${residentId}/assessments/${response?._id}`);
  }

  return (
    <Paper elevation={24}>
      <PageTitle title={"Assessments"} />
      <TableContainer>
        <Button color={"primary"}
                startIcon={<AddIcon/>}
                size={"small"}
                variant={"contained"}
                onClick={() => setCreateAssessmentModal(true)}
        >
          Add
        </Button>
        <CreateResidentAssessment
          residentId={residentId}
          resident={resident}
          open={createAssessmentModal}
          onCancel={() => setCreateAssessmentModal(false)}
          onSave={onCreateAssessment}
        />
        <Table size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell width={50} />
              <TableCell>Assessment</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          {!error && loading &&
            <TableLoadingIndicator rows={5} cols={3} bodyOnly={true} />
          }
          <TableBody>
            {!loading && !error && groups?.map((group, i) => <AssessmentGroupRow residentId={residentId} group={group} index={i} />)}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default ResidentAssessmentsList;
