import React from 'react';
import {CreateTimeEntry} from "./create.time.entry";
import {TimeEntryGroups} from "./time.entry.groups";
import {ACTIONS, createTimeEntry, TimeEntryContext} from "../../../context/TimeEntryContext/time.entry.context";
import {Button, ButtonGroup} from "@material-ui/core";
import {ChevronLeft, ChevronRight} from "@material-ui/icons";
import moment from "moment/moment";

export const TimeEntryList = () => {
  const { state, dispatch } = React.useContext(TimeEntryContext);


  const handleCreateTimeEntry = async timeEntry => {
    timeEntry.user = state?.user?._id;
    await createTimeEntry(dispatch, timeEntry);
  }

  const getNextWeek = () => {
    let newWeek = state?.week === 52 ? 1: state?.week + 1
    dispatch({
      type: ACTIONS.SET_WEEK_YEAR,
      payload: {week: newWeek, year: newWeek === 1 ? state.year + 1: state.year }
    });
  }
  const getPrevWeek = () => {
    let newWeek = state?.week === 1 ? 52: state?.week - 1;
    dispatch({
      type: ACTIONS.SET_WEEK_YEAR,
      payload: {week: newWeek, year: newWeek === 52 ? state.year - 1: state.year }
    });
  }

  const getCurrentWeek = () => {
    dispatch({type: ACTIONS.SET_WEEK_YEAR, payload: {week: moment().weeks(), year: moment().year()}});
  }

  const getWeekName = () => {
    if (moment().week() === state.week) {
      return "Current Week";
    } else {
      let firstDay = moment().set({years: state.year, weeks: state.week}).startOf('w');
      let lastDay = moment().set({years: state.year, weeks: state.week}).endOf('w');

      return `${firstDay?.format("MM/DD")} - ${lastDay?.format("MM/DD/YYYY")}`;
    }
  }

  return (
      <div>
        <ButtonGroup fullWidth disabled={state.loading} style={{marginBottom: '1rem'}}>
          <Button color={"primary"}
                  variant={"contained"}
                  startIcon={<ChevronLeft />}
                  onClick={getPrevWeek}>
            Prev Week
          </Button>
          <Button onClick={getCurrentWeek}>Current Week</Button>
          <Button color={"primary"}
                  variant={"contained"}
                  endIcon={<ChevronRight />}
                  onClick={getNextWeek}>
            Next Week
          </Button>
        </ButtonGroup>
        <CreateTimeEntry onSave={handleCreateTimeEntry} />
        <TimeEntryGroups weekName={getWeekName()}
                         timeEntries={state?.timeEntries}
        />
      </div>
  )
}
