import React from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableCell,
  Button,
  TableBody,
  TableRow, TablePagination,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";
import {
  getResidentVentilatorReadings,
  createResidentVentilatorReadings, deleteResidentVentilatorReadings, updateResidentVentilatorReadings,
} from "../../../../services/evital.service";
import CreateResidentLungSound from './create.ventilator.reading'
import TableLoadingIndicator from "../../../../utils/indicators/table.loading";
import {DeleteBtn} from "../../../utils/buttons/DeleteBtn";
import EditIcon from "@material-ui/icons/Edit";
import ConfirmDialog from "../../../utils/dialogs/confirm.dialog";
import useGlobalNotification from "../../../../hooks/notification.hook";
import {EditVentilatorReading} from "./EditVentilatorReading";
import {UserContext} from "../../../../context/usercontext";

const columns = [
  { field: "date", label: "Date" },
  { field: "ventMode", label: "Ventilator Mode" },
  { field: "respRate", label: "Respiratory Rate" },
  { field: "tidalVolume", label: "Tidal Volume" },
  { field: "inspTime", label: "Insp Time" },
  { field: "highPressure", label: "Pressure - High" },
  { field: "lowPressure", label: "Pressure - Low" },
  { field: "peep", label: "PEEP" },
  { field: "sensitivity", label: "Sensitivity" },
  { field: "humidifierTemp", label: "Humidifier Temp" },
  { field: "ipap", label: "IPAP" },
  { field: "epap", label: "EPAP" },
  { field: "staff", label: "Staff" },
];

const getStripedStyle = (index) => {
  return { background: index % 2 ? "#fafafa" : "#fff" };
};

const VentilatorSettingsReadingView = ({ match, canEdit }) => {
  const [cols] = React.useState(JSON.parse(JSON.stringify(columns)));
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(25);
  const [totalItems, setTotalItems] = React.useState(0);
  const [residentId, setResidentId] = React.useState(null);
  const [ventilatorReadings, setVentilatorReadings] = React.useState([]);
  const [viewConfirmDelete, setViewConfirmDelete] = React.useState(false);
  const [selectedReading, setSelectedReading] = React.useState();
  const [viewEditModal, setViewEditModal] = React.useState(false);
  const [users, setUsers] = React.useState();
  const {getUsers} = React.useContext(UserContext);
  const { addNotification } = useGlobalNotification();

  React.useEffect(() => {
    if (match.params.id && match.params.id !== residentId) {
      setResidentId(match.params.id);
    }
  }, [match.params.id, residentId]);

  React.useEffect(() => {
    if (residentId) {
        getReadings(residentId);
    }
    // eslint-disable-next-line
  }, [residentId]);

  const getReadings = async (residentId, pg = 1, sz = 25) => {
    if (!users || users?.length < 1) {
      const usersResponse = await getUsers();
      setUsers(usersResponse?.sort((a, b) => a?.lastName > b?.lastName ? 1: -1).map(u => ({
          name: `${u.firstName} ${u.lastName} (${u.initials})`,
          _id: u._id
        })
      ));
    }
    setLoading(true);
    setError(false);
    const response = await getResidentVentilatorReadings(residentId, pg, sz);
    if (!response?.error) {
      setPage(response?.paginator?.currentPage);
      setTotalItems(response?.paginator?.totalItems);
      setSize(response?.paginator?.perPage);
      setVentilatorReadings(response?.content);
    } else {
      setError(true);
    }
    setLoading(false);
  };

  const createLungSound = async (residentId, data) => {
      const response = await createResidentVentilatorReadings(residentId, data);
      const obj = [{...response}, ...ventilatorReadings];
      setVentilatorReadings(obj);
      setOpen(false);
  }

  const handleChangePage = async (event, pg) => {
    setPage(pg + 1);
    await getReadings(residentId, pg + 1, size);
  };

  const onChangeRowsPerPage = async (event) => {
    setSize(event.target.value);
    await getReadings(residentId, page, event.target.value);
  };

  const handleDeleteClick = reading => {
    setSelectedReading(reading);
    setViewConfirmDelete(true)
  }

  const handleEditClick = ventilatorReading => {
    setSelectedReading(ventilatorReading);
    setViewEditModal(true);
  }

  const deleteReading = async () => {
    const response = await deleteResidentVentilatorReadings(residentId, selectedReading?._id);
    if (!response?.error) {
      setViewConfirmDelete(false);
      setVentilatorReadings(ventilatorReadings?.filter(v => v._id !== selectedReading._id));
      addNotification("Reading deleted", "success");
    } else {
      addNotification("Unable to delete reading");
    }
  }

  const updateVentilatorReading = async (id, payload) => {
    const reading = ventilatorReadings?.find(v => v?._id === id);
    if (reading) {
      const data = {...reading, ...payload};
      const response = await updateResidentVentilatorReadings(residentId, id, data);
      if (!response?.error) {
        setVentilatorReadings(ventilatorReadings?.map(l => l?._id === response?._id ? response: l));
        setViewEditModal(false);
        addNotification("Reading Updated", "info");
      } else {
        addNotification("Unable to update")
      }
    } else {
      addNotification("Could not update")
    }
  }


  return (
    <div>
      {canEdit &&
      <Button startIcon={<AddIcon />} onClick={() =>  setOpen(true)}>
        Add new
      </Button>}
      <CreateResidentLungSound
        residentId={residentId}
        open={open}
        users={users}
        onClose={() => setOpen(false)}
        onSave={createLungSound}
      />
      <TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[1, 5, 10, 25, 50, 100]}
          page={page - 1}
          rowsPerPage={size}
          count={totalItems}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onChangePage={handleChangePage}
        />
        <Table size="small" aria-label="vitals table">
          <TableHead>
            <TableRow>
              <TableCell />
              {cols && cols.map((col) => <TableCell>{col.label}</TableCell>)}
            </TableRow>
          </TableHead>
          {!error && loading &&
            <TableLoadingIndicator cols={11} bodyOnly={true} />
          }
          <TableBody>
            {!error && !loading && ventilatorReadings?.map((vital, i) =>
              <TableRow key={i} style={{ ...getStripedStyle(i) }}>
                <TableCell>
                  <DeleteBtn size={"small"}
                             onClick={() => handleDeleteClick(vital)}
                  />
                  <Button size={"small"}
                          onClick={() => handleEditClick(vital)}
                          startIcon={<EditIcon />}
                  >
                    Edit
                  </Button>
                </TableCell>
                {columns?.map((col) => {
                    if (col.field === "date") {
                      return (
                        <TableCell style={{ textAlign: "left" }}>
                          {moment(vital[col.field]).format("MM/DD/YYYY hh:mm")}
                        </TableCell>
                      );
                    } else if (col.field === "staff") {
                      return (
                        <TableCell>
                          {vital?.staff?.firstName} {vital?.staff?.lastName}
                        </TableCell>
                      )
                    } else {
                      return (
                        <TableCell style={{ textAlign: "left" }}>
                          {vital[col.field]}
                        </TableCell>
                      );
                    }
                  })}
              </TableRow>

            )}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmDialog open={viewConfirmDelete}
                     onClose={() => setViewConfirmDelete(false)}
                     title={"Delete ventilator reading?"}
                     message={"Press delete to remove this reading."}
                     onConfirm={deleteReading} />
      <EditVentilatorReading open={viewEditModal}
                             onClose={() => setViewEditModal(false)}
                             onSave={updateVentilatorReading}
                             reading={selectedReading}
                             users={users}
      />
    </div>
  );
};

export default VentilatorSettingsReadingView;
