import React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import {
  downloadBirthdayReport,
} from '../../../../services/report.service';
import { UserContext } from '../../../../context/usercontext';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {PictureAsPdf} from '@material-ui/icons';
import { pdfjs, Document, Page } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const useStyle = makeStyles((theme) => ({
  dialog: {
    position: 'absolute',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const months = [
  { month: 1, name: 'January' },
  { month: 2, name: 'February' },
  { month: 3, name: 'March' },
  { month: 4, name: 'April' },
  { month: 5, name: 'May' },
  { month: 6, name: 'June' },
  { month: 7, name: 'July' },
  { month: 8, name: 'August' },
  { month: 9, name: 'September' },
  { month: 10, name: 'October' },
  { month: 11, name: 'November' },
  { month: 12, name: 'December' },
];

const BirthdayReport = () => {
  const [selectedMonth, setSelectedMonth] = React.useState();
  const [locations, setLocations] = React.useState([]);
  const [selectedLocation, setSelectedLocation] = React.useState(-1);
  const { tenantLocations } = React.useContext(UserContext);
  const [data, setData] = React.useState([]);
  const [viewReportModal, setViewReportModal] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [numberOfPages, setNumberOfPages] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(1);
  const classes = useStyle();
  React.useEffect(() => {
    setLocations(tenantLocations);
  }, [tenantLocations]);

  const onViewReport = async () => {
    setViewReportModal(true);
    setLoaded(false);
    const data = await downloadBirthdayReport(selectedMonth, selectedLocation);
    const fileUrl = URL.createObjectURL(data);
    setData(fileUrl);
    setLoaded(true);
  };

  const locationSelected = (e) => {
    setSelectedLocation(e.target.value);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumberOfPages(numPages);
  };

  const handleClose = () => {
    setViewReportModal(false);
  };

  return (
    <div style={{ position: 'relative' }}>
      <form noValidate style={{ marginBottom: '2rem' }}>
        <FormGroup>
          <FormControl>
            <InputLabel>Month</InputLabel>
            <Select
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
            >
              {months?.map((m) => (
                <MenuItem value={m.month}>{m.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel>Location</InputLabel>
            <Select value={selectedLocation} onChange={locationSelected}>
              <MenuItem value={-1}>All</MenuItem>
              {locations?.map((location) => (
                <MenuItem value={location._id}>{location.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </FormGroup>
        <div
          style={{
            padding: '1rem 0',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button color={'primary'}>Cancel</Button>
          <Button
            color={'primary'}
            variant={'contained'}
            onClick={onViewReport}
            disabled={!selectedMonth}
            startIcon={<PictureAsPdf />}
          >
            Download
          </Button>
        </div>
      </form>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={viewReportModal}
        fullWidth={true}
        className={classes.dialog}
        maxWidth={'md'}
      >
        <DialogTitle onClose={handleClose}>
          {'Birthday report'}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {loaded && data && (
            <Document
              file={data}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={'Please wait, the document is loading!'}
              onLoadError={console.error}
            >
              <Page pageNumber={pageNumber} width={800} />
            </Document>
          )}
          {!loaded && (
            <div style={{ textAlign: 'center' }}>
              <CircularProgress />
              <Typography variant={'subtitle1'} component={'p'}>
                Loading...
              </Typography>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          {numberOfPages > 1 && (
            <>
              <Button onClick={setPageNumber(pageNumber => pageNumber - 1)} disabled={pageNumber === 1}>Prev</Button>
              <Button onClick={setPageNumber(pageNumber => pageNumber + 1)}  disabled={pageNumber === numberOfPages}>Next</Button>
            </>
          )}
          <Button size={'small'} color={'primary'} onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BirthdayReport;
