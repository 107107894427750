const modelTemplate = {
    specialty: {
        title: "Specialty",
        required: false,
        value: null,
        dirty: false,
        isValid: true,
        multiSelect: false,
        dropDown: false,
        dropDownItems: [],
        dropDownItemValue: "value",
        dropDownItemLabel: "name",
        date: false
    },
    title: {
        title: "Title",
        required: false,
        value: null,
        dirty: false,
        isValid: true,
        multiSelect: false,
        dropDown: false,
        dropDownItems: [],
        dropDownItemValue: "value",
        dropDownItemLabel: "name",
        date: false
    },
    firstName: {
        title: "First Name",
        required: true,
        value: null,
        dirty: false,
        isValid: true,
        multiSelect: false,
        dropDown: false,
        dropDownItems: [],
        dropDownItemValue: "value",
        dropDownItemLabel: "name",
        date: false
    },
    middleName: {
        title: "Middle Name",
        required: false,
        value: null,
        dirty: false,
        isValid: true,
        multiSelect: false,
        dropDown: false,
        dropDownItems: [],
        dropDownItemValue: "value",
        dropDownItemLabel: "name",
        date: false
    },
    lastName: {
        title: "Last Name",
        required: true,
        value: null,
        dirty: false,
        isValid: true,
        multiSelect: false,
        dropDown: false,
        dropDownItems: [],
        dropDownItemValue: "value",
        dropDownItemLabel: "name",
        date: false
    },
    ssn: {
        title: "SSN",
        required: false,
        value: null,
        dirty: false,
        isValid: true,
        multiSelect: false,
        dropDown: false,
        dropDownItems: [],
        dropDownItemValue: "value",
        dropDownItemLabel: "name",
        date: false
    },
    email: {
        title: "Email",
        required: false,
        value: null,
        dirty: false,
        isValid: true,
        multiSelect: false,
        dropDown: false,
        dropDownItems: [],
        dropDownItemValue: "value",
        dropDownItemLabel: "name",
        date: false
    },
    gender: {
        title: "Gender",
        required: false,
        value: null,
        dirty: false,
        isValid: true,
        multiSelect: false,
        dropDown: true,
        dropDownItems: [],
        dropDownItemValue: "_id",
        dropDownItemLabel: "name",
        date: false
    },
    npi: {
        title: "NPI",
        required: false,
        value: null,
        dirty: false,
        isValid: true,
        multiSelect: false,
        dropDown: false,
        dropDownItems: [],
        dropDownItemValue: "value",
        dropDownItemLabel: "name",
        date: false,
        inputMask: 'zip'
    },
    upin: {
        title: "UPIN",
        required: false,
        value: null,
        dirty: false,
        isValid: true,
        multiSelect: false,
        dropDown: false,
        dropDownItems: [],
        dropDownItemValue: "id",
        dropDownItemLabel: "name",
        date: false
    }
};

export default modelTemplate;