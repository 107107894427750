export const StatusModel = {
  name: {
    title: 'Name',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  abbreviation: {
    title: 'Abbreviation',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
    maxCharaters: 2,
    placeholder: "Enter abbreviation (2 Character Limit)"
  },
}
