import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  FormGroup,
  TextField,
  ExpansionPanel,
  FormLabel,
  ExpansionPanelDetails,
  Checkbox,
  FormControl,
  Select,
  InputLabel,
  Input,
  MenuItem,
  ListItemText,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import Transition from '../../dialog/transition';
import CloseIcon from '@material-ui/icons/Close';
import model from './model';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { isModelValid } from '../../../utils/forms/form.validator';
import { getFrequencies } from '../../../services/frequency.service';
import PhysicianLookup from '../../physicians/physician.lookup';
import SearchIcon from '@material-ui/icons/Search';
import styled from 'styled-components';
import { UserContext } from '../../../context/usercontext';
import { getLocationRoles } from '../../../services/tenant.service';
import moment from 'moment';
import BaseEvent from '../../medicationorders/create/base.event';
import { getPhysician } from '../../../services/physician.service';
import TableLoadingIndicator from "../../../utils/indicators/table.loading";

const StyledTextField = styled(TextField)`
  label.Mui-focused {
    color: green;
  }
  .MuiInput-input {
    &.Mui-disabled {
      color: #000000;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-size: 14px;
      font-weight: 400;
    }
  }
`;

const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  content: {
    marginTop: '64px',
  },
  expandedPanel: {
    minHeight: '7rem',
  },
  expandedPanelExtended: {
    minHeight: '20rem',
  },
  panelSummary: {
    minHeight: '32px',
    padding: '12px 0 0 24px',
  },
  panelDetails: {
    padding: '0 24px 8px',
  },
  searchIconContainer: {
    display: 'flex',
  },
  searchIcon: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const EditResidentTreatment = ({ open, treatment, onClose, onSave }) => {
  const { state } = React.useContext(UserContext);
  const [loading, setLoading] = React.useState(false);
  const [openPhysicianSearch, setOpenPhysicianSearch] = React.useState(false);
  const [eventTemplates, setEventTemplates] = React.useState([]);
  const [selectedFrequency, setSelectedFreqency] = React.useState(null);
  const [treatmentModel, setTreatmentModel] = React.useState(
    JSON.parse(JSON.stringify(model))
  );
  const classes = useStyles();

  React.useEffect(() => {
    if (open && treatment) {
      setEventTemplates([]);
      loadData();
    }
    // eslint-disable-next-line
  }, [open, treatment]);

  const loadData = async () => {
    setLoading(true);
    const obj = JSON.parse(JSON.stringify(model));
    const response = await Promise.all([
      getFrequencies(),
      getLocationRoles(state.selectedTenantLocation._id),
    ]);
    obj.frequency.dropDownItems = response[0];
    obj.discipline.dropDownItems = response[1].map((locationRole) => ({
      name: locationRole.role.name,
      _id: locationRole._id,
    }));

    obj.name.value = treatment.name;
    obj.description.value = treatment.description;
    obj.startDate.value = treatment.startDate;
    obj.endDate.value = treatment.endDate;
    obj.chartVitals.value = treatment.chartVitals;
    obj.discipline.value = treatment.discipline;
    obj.time.value = treatment.time;
    obj.frequency.value = treatment.frequency._id;
    obj.physician.value = await getPhysician(treatment.physician);
    obj.isPrn.value = treatment.isPrn;
    obj.isActive = {
      value: treatment.isActive,
    };
    obj.defaultQuickStatus.value = treatment.defaultQuickStatus;
    setSelectedFreqency(treatment.frequency);
    setEventTemplates(treatment.eventTemplates);
    setTreatmentModel(obj);
    setLoading(false);
  };

  const onChange = (e) => {
    const obj = { ...treatmentModel };
    obj[e.target.name].value = e.target.value;
    obj[e.target.name].dirty = true;
    if (obj[e.target.name].required) {
      if (!e.target.value) {
        obj[e.target.name].isValid = false;
      }
    } else {
      obj[e.target.name].isValid = true;
    }
    obj[e.target.name].isValid = !(
      obj[e.target.name].required && obj[e.target.name].value === ''
    );
    setTreatmentModel(obj);
  };

  const onFrequencyChange = (e) => {
    const obj = { ...treatmentModel };
    obj.frequency.value = e.target.value;
    obj.frequency.dirty = true;
    obj.frequency.isValid = e.target.value ? true : false;
    setSelectedFreqency(
      obj.frequency.dropDownItems.find((f) => f._id === e.target.value)
    );
    setEventTemplates(populateTreatmentTimes(obj));
    setTreatmentModel(obj);
  };

  const populateTreatmentTimes = (entity) => {
    const freq = entity.frequency.dropDownItems.find(
      (f) => f._id === entity.frequency.value
    );
    return createDayEvents(freq);
  };

  const createDayEvents = (freq) => {
    if (freq) {
      const eventTemplates = [];
      const defaultHours = {
        1: { 1: 8 },
        2: { 1: 8, 2: 16 },
        3: { 1: 8, 2: 12, 3: 16 },
        4: { 1: 8, 2: 10, 3: 14, 4: 16 },
        5: { 1: 8, 2: 10, 3: 12, 4: 14, 5: 16 },
        6: { 1: 8, 2: 10, 3: 12, 4: 14, 5: 16, 6: 20 },
      };

      for (let i = 1; i <= freq.frequencyEventCount; i++) {
        const d = new Date();
        // Auto increment hours
        let calculatedHour = defaultHours[freq.frequencyEventCount][i];
        const initStartDate = d.setHours(calculatedHour, 0, 0, 0);
        const dt = moment(initStartDate);
        const hour = moment(dt).format('H');
        const minute = moment(dt).format('mm');
        eventTemplates.push({
          id: i,
          passingQty: 1,
          hour: hour,
          minute: minute,
        });
      }
      return eventTemplates;
    }
  };

  const onDateChange = (field, date) => {
    const obj = { ...treatmentModel };
    obj[field].value = date;
    obj[field].dirty = true;
    obj[field].isValid = date ? true : false;
    setTreatmentModel(obj);
  };

  const onPhysicianSelect = (physician) => {
    const obj = { ...treatmentModel };
    obj.physician.value = physician;
    setTreatmentModel(obj);
    setOpenPhysicianSearch(false);
  };

  const removePhysician = () => {
    const obj = { ...treatmentModel };
    obj.physician.value = null;
    setTreatmentModel(obj);
  };

  const handleVitalsChange = (e) => {
    const obj = { ...treatmentModel };
    if (
      e.target.value &&
      e.target.value.length > 0 &&
      e.target.value.indexOf('All') > -1
    ) {
      obj.chartVitals.value = obj.chartVitals.dropDownItems.map(
        (item) => item.name
      );
    } else {
      obj.chartVitals.value = e.target.value;
    }
    setTreatmentModel(obj);
  };

  const deleteEvent = (e) => {
    const obj = eventTemplates.filter(
      (eventTemplate) => eventTemplate.id !== e.id
    );
    setEventTemplates(obj);
  };

  const onEventChange = (e) => {
    const obj = eventTemplates.map((event) => {
      if (event.id === e.id) {
        event = e;
      }
      return event;
    });
    setEventTemplates(obj);
  };

  const updateTreatment = () => {
    const entity = {
      name: treatmentModel.name.value,
      description: treatmentModel.description.value,
      startDate: treatmentModel.startDate.value,
      endDate: treatmentModel.endDate.value,
      physician: treatmentModel.physician.value,
      chartVitals: treatmentModel.chartVitals.value,
      discipline: treatmentModel.discipline.value,
      time: treatmentModel.time.value,
      isPrn: treatmentModel.isPrn.value,
      frequency: selectedFrequency,
      eventTemplates: eventTemplates,
      isActive: treatmentModel.isActive.value,
      defaultQuickStatus: treatmentModel?.defaultQuickStatus?.value
    };
    onSave(entity);
  };

  const handleIsActiveChange = (e) => {
    const obj = { ...treatmentModel };
    obj.isActive.value = e.target.checked;
    setTreatmentModel(obj);
  };

  const handleIsPrnChange = (e) => {
    const obj = { ...treatmentModel };
    obj.isPrn.value = e.target.checked;
    setTreatmentModel(obj);
  };

  const onDefaultQuickStatusChange = e => {
    const obj = {...treatmentModel};
    obj.defaultQuickStatus.value = e.target.value;
    setTreatmentModel(obj);
  }

  return (
    <Dialog
      fullScreen
      TransitionComponent={Transition}
      open={open}
      onClose={onClose}
    >
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => onClose()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Edit Treatment
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent dividers className={classes.content}>
        {loading &&
          <TableLoadingIndicator rows={15} cols={1} />
        }
        {!loading &&
          <form noValidate>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <FormGroup>
                <TextField
                  size="small"
                  label="Name"
                  required
                  name="name"
                  value={treatmentModel.name.value || ''}
                  onChange={onChange}
                  helperText={treatmentModel.name.dirty && !treatmentModel.name.value ? `Name s required!` : null}
                  error={!treatmentModel.name.isValid && treatmentModel.name.dirty}
                />
                <TextField
                  size="small"
                  label="Description"
                  required
                  name="description"
                  value={treatmentModel.description.value || ''}
                  onChange={onChange}
                  helperText={
                    treatmentModel.description.dirty &&
                    !treatmentModel.description.value
                      ? `Description is required!`
                      : null
                  }
                  error={!treatmentModel.description.isValid && treatmentModel.description.dirty}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={treatmentModel.isActive.value}
                      onChange={handleIsActiveChange}
                      name="isActive"
                    />
                  }
                  label="Is Active"
                />
                <KeyboardDatePicker
                  size="small"
                  fullWidth
                  name="startDate"
                  required
                  value={treatmentModel.startDate.value || ''}
                  label="Start Date"
                  format="MM/DD/YYYY"
                  onChange={(date) => onDateChange('startDate', date)}
                  allowKeyboardControl="true"
                  ampm={false}
                  helperText={
                    !treatmentModel.startDate.value
                      ? `Startdate is required!`
                      : null
                  }
                  error={
                    !treatmentModel.startDate.isValid &&
                    treatmentModel.startDate.dirty
                  }
                />
                <KeyboardDatePicker
                  size="small"
                  fullWidth
                  name="endDate"
                  required
                  value={treatmentModel.endDate.value || ''}
                  label="End Date"
                  format="MM/DD/YYYY"
                  onChange={(date) => onDateChange('endDate', date)}
                  allowKeyboardControl="true"
                  ampm={false}
                  helperText={
                    !treatmentModel.endDate.value ? `End date is required!` : null
                  }
                  error={
                    !treatmentModel.endDate.isValid &&
                    treatmentModel.endDate.dirty
                  }
                />
                <ExpansionPanel
                  item
                  expanded="true"
                  className={classes.expandedPanel}
                >
                  <PhysicianLookup
                    open={openPhysicianSearch}
                    onSelect={onPhysicianSelect}
                    onClose={() => setOpenPhysicianSearch(false)}
                  />
                  <div className={classes.panelSummary}>
                    <Typography className={classes.searchIconContainer}>
                      <SearchIcon
                        className={classes.searchIcon}
                        onClick={() => setOpenPhysicianSearch(true)}
                      />
                      <FormLabel required>Physician</FormLabel>
                    </Typography>
                    <PhysicianLookup
                      open={openPhysicianSearch}
                      onSelect={onPhysicianSelect}
                      onClose={() => setOpenPhysicianSearch(false)}
                    />
                  </div>
                  <ExpansionPanelDetails className={classes.panelDetails}>
                    {!treatmentModel.physician.isValid &&
                      treatmentModel.physician.isDirty && (
                        <p className={classes.errorText}>Physician is required</p>
                      )}
                    {treatmentModel.physician.value && (
                      <form className={classes.form}>
                        <FormGroup>
                          <StyledTextField
                            disabled
                            fullWidth
                            id="physician-name"
                            label="Physician Name"
                            value={
                              treatmentModel.physician.value
                                ? `${treatmentModel.physician.value.firstName} ${treatmentModel.physician.value.lastName}`
                                : ''
                            }
                            margin="dense"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Button onClick={removePhysician}>
                            Remove Physician
                          </Button>
                        </FormGroup>
                      </form>
                    )}
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <FormControl className={classes.formControl}>
                  <InputLabel id="vitals-label">Vitals</InputLabel>
                  <Select
                    labelId="vitals-label"
                    id="vitals"
                    multiple
                    value={treatmentModel.chartVitals.value || ''}
                    onChange={handleVitalsChange}
                    input={<Input/>}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                  >
                    {treatmentModel.chartVitals.dropDownItems.map((item) => (
                      <MenuItem key={item.name} value={item.name}>
                        <Checkbox
                          checked={
                            treatmentModel.chartVitals.value.indexOf(item.name) >
                            -1
                          }
                        />
                        <ListItemText primary={item.name}/>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel shrink>Quick Status</InputLabel>
                  <Select value={treatmentModel.defaultQuickStatus.value}
                          onChange={onDefaultQuickStatusChange}
                  >
                    <MenuItem disabled>Select Default Quick Status</MenuItem>
                    <MenuItem value={'Given'}>Given</MenuItem>
                    <MenuItem value={'Late'}>Late</MenuItem>
                    <MenuItem value={'Missed'}>Missed</MenuItem>
                    <MenuItem value={'Hold'}>Hold</MenuItem>
                    <MenuItem value={'SA'}>Self Administered</MenuItem>
                    <MenuItem value={'NA'}>Not Administered</MenuItem>
                    <MenuItem value={'Refused'}>Refused</MenuItem>
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel id="discipline-label" shrink={true}>
                    Discipline
                  </InputLabel>
                  <Select
                    name="discipline"
                    labelId="discipline-label"
                    id="discipline"
                    displayEmpty
                    value={treatmentModel.discipline.value || ''}
                    onChange={onChange}
                  >
                    <MenuItem value="">None</MenuItem>
                    {treatmentModel.discipline.dropDownItems.map((item) => (
                      <MenuItem value={item._id}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel id="time-label" shrink={true}>
                    Time
                  </InputLabel>
                  <Select
                    name="time"
                    labelId="time-label"
                    id="time"
                    displayEmpty
                    value={treatmentModel.time.value || ''}
                    onChange={onChange}
                  >
                    <MenuItem value="">None</MenuItem>
                    {treatmentModel.time.dropDownItems.map((item) => (
                      <MenuItem value={item.value || ''}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControlLabel
                  control={
                    <Switch
                      checked={treatmentModel.isPrn.value}
                      onChange={handleIsPrnChange}
                      name="isPrn"
                    />
                  }
                  label="Is Prn"
                />

                {treatmentModel && !treatmentModel.isPrn.value && (
                  <React.Fragment>
                    <FormControl
                      className={classes.formControl}
                      required
                      error={
                        !treatmentModel.frequency.isValid &&
                        treatmentModel.frequency.dirty
                      }
                    >
                      <InputLabel id="freq-label" shrink={true}>
                        Frequency
                      </InputLabel>
                      <Select
                        name="frequency"
                        required
                        labelId="freq-label"
                        id="freq"
                        displayEmpty
                        value={treatmentModel.frequency.value || ''}
                        onChange={onFrequencyChange}
                      >
                        <MenuItem value="">Select a frequency</MenuItem>
                        {treatmentModel.frequency.dropDownItems.map((item) => (
                          <MenuItem value={item._id}>{item.description}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Day</TableCell>
                          <TableCell>Time</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {eventTemplates &&
                          eventTemplates.map((eventTemplate) => (
                            <BaseEvent
                              onDelete={deleteEvent}
                              frequency={selectedFrequency}
                              eventTemplate={eventTemplate}
                              onChange={onEventChange}
                              showQty={false}
                            />
                          ))}
                      </TableBody>
                    </Table>
                  </React.Fragment>
                )}
              </FormGroup>
            </MuiPickersUtilsProvider>
          </form>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!isModelValid(treatmentModel)}
          onClick={updateTreatment}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditResidentTreatment;
