import {stateProvidenceList} from '../address/providencesAndStates';
const modelTemplate = {
    name: {
        title: "Name",
        required: true,
        value: null,
        dirty: false,
        isValid: true,
        multiSelect: false,
        dropDown: false,
        dropDownItems: [],
        dropDownItemValue: "value",
        dropDownItemLabel: "name",
        date: false
    },
    description: {
        title: "Description",
        required: false,
        value: null,
        dirty: false,
        isValid: true,
        multiSelect: false,
        dropDown: false,
        dropDownItems: [],
        dropDownItemValue: "value",
        dropDownItemLabel: "name",
        date: false
    },
    type: {
        title: "Provider Type",
        required: true,
        value: null,
        dirty: false,
        isValid: true,
        multiSelect: false,
        dropDown: true,
        dropDownItems: [],
        dropDownItemValue: "name",
        dropDownItemLabel: "name",
        date: false
    },
    address: {
        title: "Address",
        required: true,
        value: null,
        dirty: false,
        isValid: true,
        multiSelect: false,
        dropDown: false,
        dropDownItems: [],
        dropDownItemValue: "value",
        dropDownItemLabel: "name",
        date: false
    },
    address2: {
        title: "Address 2",
        required: false,
        value: null,
        dirty: false,
        isValid: true,
        multiSelect: false,
        dropDown: false,
        dropDownItems: [],
        dropDownItemValue: "value",
        dropDownItemLabel: "name",
        date: false
    },
    city: {
        title: "City",
        required: true,
        value: null,
        dirty: false,
        isValid: true,
        multiSelect: false,
        dropDown: false,
        dropDownItems: [],
        dropDownItemValue: "value",
        dropDownItemLabel: "name",
        date: false
    },
    state: {
        title: "State",
        required: true,
        value: null,
        dirty: false,
        isValid: true,
        multiSelect: false,
        dropDown: true,
        dropDownItems: stateProvidenceList,
        dropDownItemValue: "abbreviation",
        dropDownItemLabel: "name",
        isStateProvidence: true
    },
    zipCode: {
        title: "Zip",
        required: true,
        value: null,
        dirty: false,
        isValid: true,
        multiSelect: false,
        dropDown: false,
        dropDownItems: [],
        dropDownItemValue: "value",
        dropDownItemLabel: "name",
        date: false
    },
    addressType: {
        title: "Address Type",
        required: true,
        value: null,
        dirty: false,
        isValid: true,
        multiSelect: false,
        dropDown: true,
        dropDownItems: [],
        dropDownItemValue: "_id",
        dropDownItemLabel: "name",
        date: false
    }
};

export default modelTemplate;
