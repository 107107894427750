import React from 'react';
import {PageTitle} from "../utils";
import {
  makeStyles,
  Paper,
} from "@material-ui/core";
import {SsaCalendar} from "../calendar";
import {CalendarEventComponent, CreateCalendarEvent, EditCalendarEvent} from "./events";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1)
  },
  deleteBtn: {
    backgroundColor: theme.palette.error.light
  },
  content: {
    padding: theme.spacing(0, 1)
  }
}));



export const Calendar = () => {
  const classes = useStyles();



  const onEventCreated = async event => {
  }

  const onEventDeleted = async event => {
  }

  const onEventSelected = event => {
  }

  const onSlotSelected = slot => {
  }

  return (
    <Paper elevation={24}
           className={classes.root}
    >
      <PageTitle title={"Calendars"} />
      <div className={classes.content}>
        <SsaCalendar
                     height={"70vh"}
                     onSave={onEventCreated}
                     onDelete={onEventDeleted}
                     eventSelected={onEventSelected}
                     slotSelected={onSlotSelected}
                     customEventComponent={CalendarEventComponent}
        />
        <EditCalendarEvent  />
        <CreateCalendarEvent  />
      </div>
    </Paper>
  )
}
