const modelTemplate = {
    firstName: {
        title: "First Name",
        required: true,
        value: null,
        dirty: false,
        isValid: true,
        multiSelect: false,
        dropDown: false,
        dropDownItems: [],
        dropDownItemValue: "value",
        dropDownItemLabel: "name",
        date: false
    },
    lastName: {
        title: "Last Name",
        required: true,
        value: null,
        dirty: false,
        isValid: true,
        multiSelect: false,
        dropDown: false,
        dropDownItems: [],
        dropDownItemValue: "value",
        dropDownItemLabel: "name",
        date: false
    },
    email: {
        title: "Email",
        required: false,
        value: null,
        dirty: false,
        isValid: true,
        multiSelect: false,
        dropDown: false,
        dropDownItems: [],
        dropDownItemValue: "value",
        dropDownItemLabel: "name",
        date: false
    }
}

export default modelTemplate;
