import React from 'react';
import {
  AppBar,
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormGroup,
  IconButton,
  InputLabel,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import {
  red,
  yellow,
  green,
  grey,
  orange,
  blue,
} from '@material-ui/core/colors';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import Transition from '../../dialog/transition';
import { Link as RouterLink } from 'react-router-dom';
import { updateResidentTask } from '../../../services/careplan.service';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import useHasRole from "../../../hooks/user.role.hook";
import Skeleton from "@material-ui/lab/Skeleton";
import {DatetimeInput} from "../../../utils/datetime/DatetimeInput";
import {getAbbreviationFromName, getStatusFromName} from "./utils";
const contrast = 400;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  avatarSmall: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  scheduled: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: grey[500],
  },
  readyToPass: {
    background: grey[700],
  },
  given: {
    background: green[contrast],
  },
  late: {
    background: orange[contrast],
  },
  missed: {
    background: red[contrast],
  },
  hold: {
    background: yellow[contrast],
  },
  sa: {
    background: blue[contrast],
  },
  na: {
    background: grey[contrast],
  },
  refused: {
    background: orange[contrast],
  },
  destruction: {
    background: yellow[contrast],
  },
  independent: {
    background: green[contrast],
  },
  verbalPrompt: {
    background: green[contrast],
  },
  physicalAssist: {
    background: green[contrast],
  },
  minimalAssist: {
    background: green[contrast],
  },
  dialogContent: {
    marginTop: '4rem',
  },
  secondaryActions: {

  },
}));

const PrimaryText = ({ task }) => {
  const click = (e) => {
    e.stopPropagation();
  };

  if (task && task.resident) {
    return (
      <Link
        component={RouterLink}
        to={`/residents/${task.resident._id}/tasks#${task._id}`}
        onClick={click}
      >
        {task.resident.firstName} {task.resident.lastName}
      </Link>
    );
  } else {
    return null;
  }
};

const SecondaryText = ({ task }) => {
  if (task && task.details) {
    return (
      <div style={{paddingRight: '.25rem'}}>
        <Typography variant="subtitle2">{task.details.intervention}</Typography>
        <Typography variant="subtitle2">
          {moment(task.scheduledTime).format('hh:mm a')}
        </Typography>
        {task?.discipline &&
        <Typography variant={"subtitle2"}>
          {task?.discipline?.role?.name} - {task?.discipline?.location?.name}
        </Typography>
        }
      </div>
    );
  } else {
    return null;
  }
};

const MobileTask = ({ task, customStatus, onUpdate, onTaskUpdated, onQuickUpdate, users }) => {
  const classes = useStyles();
  const [viewTask, setViewTask] = React.useState(false);
  const [editTask, setEditTask] = React.useState(null);
  const { checkPermission } = useHasRole();
  const [updating, setUpdating] = React.useState(false);
  const canUpdate = checkPermission('ETASK_UPDATE')

  const getItemClass = (event) => {

    const status = getStatusFromName(event?.status, customStatus)
    if (status?.isCustom) {
      return classes.sa;
    }

    if (event && event.status) {
      switch (event.status) {
        case 'Scheduled':
          return classes.readyToPass;
        case 'Given':
          return classes.given;
        case 'Late':
          return classes.late;
        case 'Missed':
          return classes.missed;
        case 'Hold':
          return classes.hold;
        case 'SA':
          return classes.sa;
        case 'NA':
          return classes.na;
        case 'Refused':
          return classes.refused;
        case 'Destruction':
          return classes.destruction;
        case 'Independent':
          return classes.independent;
        case 'Verbal prompt':
          return classes.verbalPrompt;
        case 'Physical Assist':
          return classes.physicalAssist;
        case 'Minimal Assist':
          return classes.minimalAssist;
        default:
          return classes.scheduled;
      }
    }
  };

  const onViewTask = () => {
    setEditTask(JSON.parse(JSON.stringify(task)));
    setViewTask(true);
  };

  const onChange = (e) => {
    const obj = { ...editTask };
    obj[e.target.name] = e.target.value;
    setEditTask(obj);
  };

  const canEdit = () => {
    if (
      editTask &&
      moment(editTask.scheduledTime).subtract(1, 'hour') < moment() &&
      canUpdate
    ) {
      return true;
    } else {
      return false;
    }
  };


  const canQuickUpdateEdit = () => {
    if (
      moment(task?.scheduledTime).subtract(1, 'hour') < moment() &&
      canUpdate
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isValid = () => {
    return editTask && editTask.status;
  };

  const save = async () => {
    const entity = await updateResidentTask(
      task.resident._id,
      task._id,
      editTask
    );
    setViewTask(false);
    onTaskUpdated(entity);
  };

  const onScheduledTimeChange = (date) => {
    const obj = { ...editTask };
    obj.scheduledTime = date;
    setEditTask(obj);
  };

  const handleQuickUpdate = (e, task, status) => {
    setUpdating(true);
    e.stopPropagation();
    if (onQuickUpdate) {
      onQuickUpdate(task, status).then(() => {
        setUpdating(false);
      });
    }
  }

  const onAdministeredUserChange = e => {
    const obj = {...editTask};
    const user = users?.find(u => u._id === e.target.value);
    obj.administeredUser = user;
    obj.updateUser = user;
    setEditTask(obj);
  }

  const onAdministeredChanged = date => {
    const obj = {...editTask};
    obj.administered = date;
    setEditTask(obj);
  }


  if (updating) {
    return (
      <ListItem divider
                style={{height: `10vh`}}
      >
        <ListItemAvatar>
          <Skeleton variant={"circle"}
                    height={40}
                    width={40}
          />
        </ListItemAvatar>
        <ListItemText primary={<Skeleton height={30} />}
                      secondary={
                        <>
                          <Skeleton height={10} />
                          <Skeleton height={10} />
                        </>
                      }
        />
      </ListItem>
    )
  }

  const getIconContent = task => {
    const abbreviation = getAbbreviationFromName(task?.status, customStatus)
    if (abbreviation) {
      return abbreviation;
    }

    switch (task?.status) {
      case 'Independent':
        return "I";
      case "Verbal prompt":
        return "VP";
      case "Physical Assist":
        return "PA";
      case "Minimal Assist":
        return "MA";
      default:
        return  (
          <i className="fas fa-tasks"></i>
        )
    }
  }

  return (
    <>
      <ListItem button onClick={onViewTask}>
        <ListItemAvatar>
          <Avatar
            alt="Tasks"
            className={[classes.avatarSmall, getItemClass(task)]}
          >
            {getIconContent(task)}
          </Avatar>
          {canQuickUpdateEdit() &&
            <div style={{marginTop: "1rem"}}>
              <Avatar className={getItemClass(task)}
                      onClick={(event) => handleQuickUpdate(event, task, task?.administered ? 'Scheduled': task?.defaultQuickStatus)}
              >
                {task?.administered && task?.status !== "Scheduled" && task?.status !== "Late" &&
                task?.status !== "Missed" ?
                  <i className={"fas fa-check"}></i> :
                  <i className={["fas fa-circle", getItemClass(task)]}></i>
                }
              </Avatar>
            </div>
          }

                {/*<IconButton onClick={() =>*/}
                {/*  quickUpdate(task, task?.administered ? 'Scheduled': task?.defaultQuickStatus)}>*/}
          {/*  {task?.administered ?*/}
          {/*    <CheckCircleRoundedIcon className={getItemIconClass(task)} /> :*/}
          {/*    <RadioButtonUncheckedRounded />*/}
          {/*  }*/}
          {/*</IconButton>*/}

          {/*{canQuickUpdateEdit() && task?.status === task?.defaultQuickStatus &&*/}
          {/*  <div style={{marginTop: '1rem'}}>*/}
          {/*    <Avatar className={[getItemClass(task)]}*/}
          {/*            onClick={(event) => handleQuickUpdate(event, task, 'Scheduled')}*/}
          {/*    >*/}
          {/*      <i className={"fas fa-check"}></i>*/}
          {/*    </Avatar>*/}
          {/*  </div>*/}
          {/*}*/}
          {/*{canQuickUpdateEdit() && task?.status !== task?.defaultQuickStatus &&*/}
          {/*  <div style={{marginTop: '1rem'}}>*/}
          {/*    <Avatar className={[getItemClass(task)]}*/}
          {/*            onClick={(event) => handleQuickUpdate(event, task, task?.defaultQuickStatus)}>*/}
          {/*      <i className={["fas fa-circle", getItemClass(task)]}></i>*/}
          {/*    </Avatar>*/}
          {/*  </div>*/}
          {/*}*/}
        </ListItemAvatar>
        <ListItemText
          primary={<PrimaryText task={task} />}
          secondary={<SecondaryText task={task} />}
        />
        <ListItemText style={{textAlign: 'right'}} primary={
          <div style={{minWidth: '4rem'}}>
            <Typography variant="subtitle2">
              {task?.administered && moment(task?.administered).format('hh:mm a')}
            </Typography>
            <Typography variant="subtitle2">
              {task?.administeredUser?.initials}
            </Typography>
          </div>
        } />
      </ListItem>
      <Dialog
        fullScreen
        open={viewTask}
        onClose={() => setViewTask(false)}
        TransitionComponent={Transition}
      >
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setViewTask(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Task
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.dialogContent}>
          {editTask && (
            <>
              <div style={{marginBottom: '1.5rem'}}>
                <Typography variant="h6">
                  {editTask.resident.firstName} {editTask.resident.lastName}
                </Typography>
                <Typography variant="subtitle1">
                  Careplan: {editTask.details.careplan}
                </Typography>
                <Typography variant="subtitle2">
                  Goal: {editTask.details.goal}
                </Typography>
                <Typography variant="subtitle2">
                  Intervention: {editTask?.details?.intervention}
                </Typography>
                <Typography variant="subtitle2">
                  Scheduled Time:{' '}
                  {moment(editTask.scheduledTime).format('MM/DD/YYYY hh:mm a')}
                </Typography>
                {editTask?.discipline &&
                  <Typography variant={"subtitle2"}>
                    Discipline: {task?.discipline?.role?.name} - {task?.discipline?.location?.name}
                  </Typography>
                }
              </div>
              <form noValidate>
                <FormGroup>
                  {editTask && editTask.isPrn && (
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDateTimePicker
                        size="small"
                        fullWidth
                        name="scheduledTask"
                        required
                        value={editTask.scheduledTime}
                        label="Scheduled Time"
                        format="MM/DD/YYYY hh:mm a"
                        onChange={onScheduledTimeChange}
                        allowKeyboardControl="true"
                        helperText={'Scheduled time is required.'}
                        error={!editTask.scheduledTime}
                        disabled={!canUpdate}
                      />
                    </MuiPickersUtilsProvider>
                  )}
                  <FormControl size="small" disabled={!canEdit()}>
                    <InputLabel required shrink>
                      Status
                    </InputLabel>
                    {(!customStatus || customStatus?.length < 1) &&
                      <Select
                      id="status"
                      label="Status"
                      value={editTask.status}
                      name="status"
                      onChange={onChange}
                    >
                      <MenuItem value={'Scheduled'}>Scheduled</MenuItem>
                      <MenuItem value={'Given'}>Given</MenuItem>
                      <MenuItem value={'Late'}>Late</MenuItem>
                      <MenuItem value={'Missed'}>Missed</MenuItem>
                      <MenuItem value={'Hold'}>Hold</MenuItem>
                      <MenuItem value={'SA'}>Self Administered</MenuItem>
                      <MenuItem value={'NA'}>Not Administered</MenuItem>
                      <MenuItem value={'Refused'}>Refused</MenuItem>
                      <MenuItem value={"Independent"}>Independent</MenuItem>
                      <MenuItem value={"Verbal prompt"}>Verbal prompt</MenuItem>
                      <MenuItem value={"Physical Assist"}>Physical Assist</MenuItem>
                      <MenuItem value={"Minimal Assist"}>Minimal Assist</MenuItem>
                    </Select>
                    }
                    {customStatus?.length > 0 &&
                      <Select
                        id="status"
                        label="Status"
                        value={editTask.status}
                        name="status"
                        onChange={onChange}
                      >
                        {customStatus?.map(status =>
                            <MenuItem value={status?.name}>{status?.name}</MenuItem>
                          )
                        }
                      </Select>
                    }
                  </FormControl>
                  <FormControl>
                    <InputLabel shrink required>Administered</InputLabel>
                    <Select fullWidth
                            disabled={!canEdit()}
                            value={editTask?.administeredUser?._id}
                            onChange={onAdministeredUserChange}
                    >
                      {users?.sort((a, b) => a?.lastName > b?.lastName ? 1: -1)?.map(user =>
                        <MenuItem value={user?._id}>{user?.firstName} {user?.lastName} ({user?.initials})</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <DatetimeInput value={editTask?.administered}
                                   onChange={onAdministeredChanged}
                                   disabled={!canEdit()}
                                   label={"Administered Date Time"}
                                   required
                                   format={"MM/DD/YYYY hh:mm"}
                    />
                  </FormControl>
                  <TextField
                    disabled={!canEdit()}
                    label="Notes"
                    value={editTask.notes}
                    onChange={onChange}
                    name="notes"
                    multiline
                    maxRows={3}
                  />
                </FormGroup>
              </form>
            </>
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          {!canEdit() && (
            <Button onClick={() => setViewTask(false)}>Close</Button>
          )}
          {canEdit() && (
            <>
              <Button onClick={() => setViewTask(false)}>Cancel</Button>
              <Button
                onClick={save}
                disabled={!isValid()}
                color="primary"
                variant="contained"
              >
                Save
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MobileTask;
