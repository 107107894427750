import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  FormGroup,
  TextField,
  ExpansionPanel,
  FormLabel,
  ExpansionPanelDetails,
  Checkbox,
  FormControl,
  Select,
  InputLabel,
  Input,
  MenuItem,
  ListItemText,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import Transition from '../../dialog/transition';
import CloseIcon from '@material-ui/icons/Close';
import model from './model';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { isModelValid } from '../../../utils/forms/form.validator';
import { getFrequencies } from '../../../services/frequency.service';
import PhysicianLookup from '../../physicians/physician.lookup';
import SearchIcon from '@material-ui/icons/Search';
import styled from 'styled-components';
import { UserContext } from '../../../context/usercontext';
import { getLocationRoles } from '../../../services/tenant.service';
import moment from 'moment';
import BaseEvent from '../../medicationorders/create/base.event';
import {ResidentContext} from "../../../context/residentcontext";
import TableLoadingIndicator from "../../../utils/indicators/table.loading";

const StyledTextField = styled(TextField)`
  label.Mui-focused {
    color: green;
  }
  .MuiInput-input {
    &.Mui-disabled {
      color: #000000;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-size: 14px;
      font-weight: 400;
    }
  }
`;

const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  content: {
    marginTop: '64px',
  },
  expandedPanel: {
    minHeight: '7rem',
  },
  expandedPanelExtended: {
    minHeight: '20rem',
  },
  panelSummary: {
    minHeight: '32px',
    padding: '12px 0 0 24px',
  },
  panelDetails: {
    padding: '0 24px 8px',
  },
  searchIconContainer: {
    display: 'flex',
  },
  searchIcon: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const CreateResidentTreatment = ({ open, onClose, onSave, isPrn = false }) => {
  const { state } = React.useContext(UserContext);
  const { resident } = React.useContext(ResidentContext);
  const [openPhysicianSearch, setOpenPhysicianSearch] = React.useState(false);
  const [eventTemplates, setEventTemplates] = React.useState([]);
  const [selectedFrequency, setSelectedFreqency] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [treatment, setTreatment] = React.useState(JSON.parse(JSON.stringify(model)));
  const classes = useStyles();

  React.useEffect(() => {
    if (open) {
      loadData();
    }
    // eslint-disable-next-line
  }, [open]);

  const loadData = async () => {
    setLoading(true);
    const obj = JSON.parse(JSON.stringify(model));
    setTreatment(obj);
    const response = await Promise.all([
      getFrequencies(),
      getLocationRoles(state.selectedTenantLocation._id),
    ]);
    obj.frequency.dropDownItems = response[0];
    const onceADay = obj.frequency.dropDownItems.find((f) => f.name === 'QD');
    obj.frequency.value = onceADay ? onceADay._id : null;
    obj.isPrn.value = isPrn;
    setSelectedFreqency(onceADay);
    obj.discipline.dropDownItems = response[1].map((locationRole) => ({
      name: locationRole.role.name,
      _id: locationRole._id,
    }));
    const primaryPhysician = resident?.residentPhysicians?.find(rp => rp?.isPrimary);
    obj.physician.value = primaryPhysician?.physician ?? null
    setEventTemplates(populateTreatmentTimes(obj));
    obj.defaultQuickStatus.value = "Given";
    setTreatment(obj);
    setLoading(false);
  };

  const onChange = (e) => {
    const obj = { ...treatment };
    obj[e.target.name].value = e.target.value;
    obj[e.target.name].dirty = true;
    if (obj[e.target.name].required) {
      if (!e.target.value) {
        obj[e.target.name].isValid = false;
      }
    } else {
      obj[e.target.name].isValid = true;
    }
    obj[e.target.name].isValid = !(
      obj[e.target.name].required && obj[e.target.name].value === ''
    );
    setTreatment(obj);
  };

  const onFrequencyChange = (e) => {
    const obj = { ...treatment };
    obj.frequency.value = e.target.value;
    obj.frequency.dirty = true;
    obj.frequency.isValid = e.target.value ? true : false;
    setSelectedFreqency(
      obj.frequency.dropDownItems.find((f) => f._id === e.target.value)
    );
    setEventTemplates(populateTreatmentTimes(obj));
    setTreatment(obj);
  };

  const populateTreatmentTimes = (entity) => {
    const freq = entity.frequency.dropDownItems.find(
      (f) => f._id === entity.frequency.value
    );
    return createDayEvents(freq);
  };

  const createDayEvents = (freq) => {
    if (freq) {
      const eventTemplates = [];
      const defaultHours = {
        1: { 1: 8 },
        2: { 1: 8, 2: freq?.isDayOfWeekSelection ? 8: 16 },
        3: { 1: 8, 2: freq?.isDayOfWeekSelection ? 8: 12, 3: freq?.isDayOfWeekSelection ? 8: 16 },
        4: { 1: 8, 2: freq?.isDayOfWeekSelection ? 8: 10, 3: freq?.isDayOfWeekSelection ? 8: 14, 4: freq?.isDayOfWeekSelection ? 8: 16 },
        5: { 1: 8, 2: freq?.isDayOfWeekSelection ? 8: 10, 3: freq?.isDayOfWeekSelection ? 8: 12, 4: freq?.isDayOfWeekSelection ? 8: 14, 5: freq?.isDayOfWeekSelection ? 8: 16 },
        6: { 1: 8, 2: freq?.isDayOfWeekSelection ? 8: 10, 3: freq?.isDayOfWeekSelection ? 8: 12, 4: freq?.isDayOfWeekSelection ? 8: 14, 5: freq?.isDayOfWeekSelection ? 8: 16, 6: freq?.isDayOfWeekSelection ? 8: 20 },
      };

      for (let i = 1; i <= freq.frequencyEventCount; i++) {
        const d = new Date();
        // Auto increment hours
        let calculatedHour = defaultHours[freq.frequencyEventCount][i];
        const initStartDate = d.setHours(calculatedHour, 0, 0, 0);
        const dt = moment(initStartDate);
        const hour = moment(dt).format('H');
        const minute = moment(dt).format('mm');
        eventTemplates.push({
          id: i,
          passingQty: 1,
          hour: hour,
          minute: minute,
        });
      }
      return eventTemplates;
    }
  };

  const onDateChange = (field, date) => {
    const obj = { ...treatment };
    obj[field].value = date;
    obj[field].dirty = true;
    obj[field].isValid = date ? true : false;
    setTreatment(obj);
  };

  const onPhysicianSelect = (physician) => {
    const obj = { ...treatment };
    obj.physician.value = physician;
    setTreatment(obj);
    setOpenPhysicianSearch(false);
  };

  const removePhysician = () => {
    const obj = { ...treatment };
    obj.physician.value = null;
    setTreatment(obj);
  };

  const handleVitalsChange = (e) => {
    const obj = { ...treatment };
    if (
      e.target.value &&
      e.target.value.length > 0 &&
      e.target.value.indexOf('All') > -1
    ) {
      obj.chartVitals.value = obj.chartVitals.dropDownItems.map(
        (item) => item.name
      );
    } else {
      obj.chartVitals.value = e.target.value;
    }
    setTreatment(obj);
  };

  const deleteEvent = (e) => {
    const obj = eventTemplates.filter(
      (eventTemplate) => eventTemplate.id !== e.id
    );
    setEventTemplates(obj);
  };

  const onEventChange = (e) => {
    const obj = eventTemplates.map((event) => {
      if (event.id === e.id) {
        event = e;
      }
      return event;
    });
    setEventTemplates(obj);
  };

  const createTreatment = () => {
    const entity = {
      name: treatment.name.value,
      description: treatment.description.value,
      startDate: treatment.startDate.value,
      endDate: treatment.endDate.value,
      physician: treatment.physician.value,
      chartVitals: treatment.chartVitals.value,
      discipline: treatment.discipline.value,
      time: treatment.time.value,
      frequency: selectedFrequency,
      eventTemplates: eventTemplates,
      isPrn: treatment.isPrn.value,
      defaultQuickStatus: treatment?.defaultQuickStatus?.value
    };

    onSave(entity);
  };

  const handleIsPrnChange = (e) => {
    const obj = { ...treatment };
    obj.isPrn.value = e.target.checked;
    setTreatment(obj);
  };

  const onDefaultQuickStatusChange = e => {
    const obj = {...treatment};
    obj.defaultQuickStatus.value = e.target.value;
    setTreatment(obj);
  }


  return (
    <Dialog
      fullScreen
      TransitionComponent={Transition}
      open={open}
      onClose={onClose}
    >
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => onClose()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Create Treatment
          </Typography>
        </Toolbar>
      </AppBar>
        <DialogContent className={classes.content}>
          {loading && <TableLoadingIndicator rows={10} cols={1} />}
          {!loading &&
          <form noValidate>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <FormGroup>
                <TextField
                  size="small"
                  label="Name"
                  required
                  name="name"
                  value={treatment.name.value}
                  onChange={onChange}
                  helperText={
                    treatment.name.dirty && !treatment.name.value
                      ? `Name s required!`
                      : null
                  }
                  error={!treatment.name.isValid && treatment.name.dirty}
                />
                <TextField
                  size="small"
                  label="Description"
                  required
                  name="description"
                  value={treatment.description.value}
                  onChange={onChange}
                  helperText={
                    treatment.description.dirty && !treatment.description.value
                      ? `Description is required!`
                      : null
                  }
                  error={
                    !treatment.description.isValid && treatment.description.dirty
                  }
                />
                <KeyboardDatePicker
                  size="small"
                  fullWidth
                  name="startDate"
                  required
                  value={treatment.startDate.value}
                  label="Start Date"
                  format="MM/DD/YYYY"
                  onChange={(date) => onDateChange('startDate', date)}
                  allowKeyboardControl="true"
                  ampm={false}
                  helperText={
                    !treatment.startDate.value ? `Startdate is required!` : null
                  }
                  error={
                    !treatment.startDate.isValid && treatment.startDate.dirty
                  }
                />
                <KeyboardDatePicker
                  size="small"
                  fullWidth
                  name="endDate"
                  required
                  value={treatment.endDate.value}
                  label="End Date"
                  format="MM/DD/YYYY"
                  onChange={(date) => onDateChange('endDate', date)}
                  allowKeyboardControl="true"
                  ampm={false}
                  helperText={
                    !treatment.endDate.value ? `End date is required!` : null
                  }
                  error={!treatment.endDate.isValid && treatment.endDate.dirty}
                />
                <ExpansionPanel
                  item
                  expanded="true"
                  className={classes.expandedPanel}
                >
                  <PhysicianLookup
                    open={openPhysicianSearch}
                    onSelect={onPhysicianSelect}
                    onClose={() => setOpenPhysicianSearch(false)}
                  />
                  <div className={classes.panelSummary}>
                    <Typography className={classes.searchIconContainer}>
                      <SearchIcon
                        className={classes.searchIcon}
                        onClick={() => setOpenPhysicianSearch(true)}
                      />
                      <FormLabel required>Physician</FormLabel>
                    </Typography>
                    <PhysicianLookup
                      open={openPhysicianSearch}
                      onSelect={onPhysicianSelect}
                      onClose={() => setOpenPhysicianSearch(false)}
                    />
                  </div>
                  <ExpansionPanelDetails className={classes.panelDetails}>
                    {!treatment.physician.isValid &&
                      treatment.physician.isDirty && (
                        <p className={classes.errorText}>Physician is required</p>
                      )}
                    {treatment.physician.value && (
                      <form className={classes.form}>
                        <FormGroup>
                          <StyledTextField
                            disabled
                            fullWidth
                            id="physician-name"
                            label="Physician Name"
                            value={
                              treatment.physician.value
                                ? `${treatment.physician.value.firstName} ${treatment.physician.value.lastName}`
                                : ''
                            }
                            margin="dense"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Button onClick={removePhysician}>
                            Remove Physician
                          </Button>
                        </FormGroup>
                      </form>
                    )}
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <FormControl className={classes.formControl}>
                  <InputLabel id="vitals-label">Vitals</InputLabel>
                  <Select
                    labelId="vitals-label"
                    id="vitals"
                    multiple
                    value={treatment.chartVitals.value}
                    onChange={handleVitalsChange}
                    input={<Input/>}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                  >
                    {treatment.chartVitals.dropDownItems.map((item) => (
                      <MenuItem key={item.name} value={item.name}>
                        <Checkbox
                          checked={
                            treatment.chartVitals.value.indexOf(item.name) > -1
                          }
                        />
                        <ListItemText primary={item.name}/>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel id="discipline-label" shrink={true}>
                    Discipline
                  </InputLabel>
                  <Select
                    name="discipline"
                    labelId="discipline-label"
                    id="discipline"
                    displayEmpty
                    value={treatment.discipline.value || ''}
                    onChange={onChange}
                  >
                    <MenuItem value="">None</MenuItem>
                    {treatment.discipline.dropDownItems.map((item) => (
                      <MenuItem value={item._id}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel shrink>Quick Status</InputLabel>
                  <Select value={treatment.defaultQuickStatus.value}
                          onChange={onDefaultQuickStatusChange}
                  >
                    <MenuItem disabled>Select Default Quick Status</MenuItem>
                    <MenuItem value={'Given'}>Given</MenuItem>
                    <MenuItem value={'Late'}>Late</MenuItem>
                    <MenuItem value={'Missed'}>Missed</MenuItem>
                    <MenuItem value={'Hold'}>Hold</MenuItem>
                    <MenuItem value={'SA'}>Self Administered</MenuItem>
                    <MenuItem value={'NA'}>Not Administered</MenuItem>
                    <MenuItem value={'Refused'}>Refused</MenuItem>
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel id="time-label" shrink={true}>
                    Time
                  </InputLabel>
                  <Select
                    name="time"
                    labelId="time-label"
                    id="time"
                    displayEmpty
                    value={treatment.time.value || ''}
                    onChange={onChange}
                  >
                    <MenuItem value="">None</MenuItem>
                    {treatment.time.dropDownItems.map((item) => (
                      <MenuItem value={item.value}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControlLabel
                  control={
                    <Switch
                      checked={treatment.isPrn.value}
                      onChange={handleIsPrnChange}
                      name="isPrn"
                    />
                  }
                  label="Is Prn"
                />
                {treatment && !treatment.isPrn.value && (
                  <React.Fragment>
                    <FormControl
                      className={classes.formControl}
                      required
                      error={
                        !treatment.frequency.isValid && treatment.frequency.dirty
                      }
                    >
                      <InputLabel id="freq-label" shrink={true}>
                        Frequency
                      </InputLabel>
                      <Select
                        name="frequency"
                        required
                        labelId="freq-label"
                        id="freq"
                        displayEmpty
                        value={treatment.frequency.value}
                        onChange={onFrequencyChange}
                      >
                        <MenuItem value="">Select a frequency</MenuItem>
                        {treatment.frequency.dropDownItems.map((item) => (
                          <MenuItem value={item._id}>{item.description}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Day</TableCell>
                          <TableCell>Time</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {eventTemplates?.map(eventTemplate =>
                          <BaseEvent
                            onDelete={deleteEvent}
                            frequency={selectedFrequency}
                            eventTemplate={eventTemplate}
                            onChange={onEventChange}
                            showQty={false}
                          />
                        )}
                      </TableBody>
                    </Table>
                  </React.Fragment>
                )}
              </FormGroup>
            </MuiPickersUtilsProvider>
          </form>
          }
        </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!isModelValid(treatment)}
          onClick={createTreatment}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateResidentTreatment;
