import React from 'react'
import {
  Dialog,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  DialogContent,
  Link,
  makeStyles,
  DialogActions,
} from "@material-ui/core";
import Transition from "../dialog/transition";
import CloseIcon from "@material-ui/icons/Close";
import FormFieldsGenerator from "../../utils/forms/form.fields.generator";
import { isModelValid } from '../../utils/forms/form.validator';
import modelTemplate from './physician.model';
import { UserContext } from '../../context/usercontext';
import { getGenders } from '../../services/gender.service';

const useStyles = makeStyles((theme) => ({
    root: {
      margin: theme.spacing(1),
      paddingTop: "4rem",
    },
    dialogContent: {
      marginTop: "4rem",
    },
  }));

const CreatePhysician = ({ open, onClose, onSave }) => {
    const classes = useStyles();
    const [model, setModel] = React.useState(JSON.parse(JSON.stringify(modelTemplate)));
    const [ genders, setGenders ] = React.useState([]);
    const { state } = React.useContext(UserContext);
  
    React.useEffect(() => {
      if (open) {
          initForm();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);
  
    const initForm = async () => {
      const obj = JSON.parse(JSON.stringify(modelTemplate));
      let gendersValue = genders;
      if (!gendersValue || gendersValue.length === 0) {
        const response = await getGenders();
        setGenders(response);
        gendersValue = response;
      }
      obj.gender.dropDownItems = gendersValue;
      setModel(obj);
    }
  
  
    const onChange = e => {
        const obj = {...model, ...e};
        setModel(obj);
    }
    const save = () => {
        const data = {
            specialty: model.specialty.value,
            title: model.title.value,
            firstName: model.firstName.value,
            middleName: model.middleName.value,
            lastName: model.lastName.value,
            ssn: model.ssn.value,
            email: model.email.value,
            gender: model.gender.value,
            npi: model.npi.value,
            upin: model.upin.value,
            tenantId: state.selectedTenant.id
        };
        onSave(data);
    }
    
    return (
      <Dialog
        fullScreen
        open={open}
        onClose={() => onClose()}
        TransitionComponent={Transition}
      >
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => onClose()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">Create Physician</Typography>
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.dialogContent}>
            <form>
                <FormFieldsGenerator model={model} onChange={onChange} />
            </form>
        </DialogContent>
        <DialogActions>
            <Link onClick={onClose}>Cancel</Link>
            <Button disabled={!isModelValid(model)} onClick={save} variant="contained" color="primary">Save</Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  export default CreatePhysician;
