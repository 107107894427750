import React from 'react';
import {
  Accordion, AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  makeStyles,
  Typography
} from "@material-ui/core";
import {AssessmentTwoTone, ExpandMoreTwoTone,} from "@material-ui/icons";
import {getAssessmentAction} from "./assessment.actions";
import {AssessmentsContext} from "./assessments.context";
import QuestionGroups from "./question.groups";
import AssessmentInfo from "./assessment.info";
import AssessmentObject from "./assessment.object";
import PreviewAssessment from "./preview.assessment";
import useHasRole from "../../hooks/user.role.hook";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(.5, 0),
    padding: theme.spacing(2)
  },
  backBtn: {
    marginRight: theme.spacing(2)
  },
  section: {
    padding: theme.spacing(1)
  },
  sectionPaper: {
    padding: theme.spacing(2)

  },
  preview: {
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(2),
    height: 250,
    width: '100%',
    overflow: 'auto'
  }
}))

const Assessment = ({match}) => {
  const classes = useStyles();
  const { dispatch, state } = React.useContext(AssessmentsContext);
  const [viewPreview, setViewPreview] = React.useState(false);
  const { checkPermission } = useHasRole();

  React.useEffect(() => {
    if (match?.params?.id) {
      getData(match.params.id);
    }
    // eslint-disable-next-line
  }, [match?.params?.id]);


  const getData = async id => {
    await getAssessmentAction(id, dispatch, state.types);
  }

  const canUpdateAssessment = () => {
    if (state?.assessment?.isGlobal && checkPermission('ASSESSMENT_GLOBAL_UPDATE')) {
      return true
    } else if (!state?.assessment?.isGlobal && checkPermission('ASSESSMENT_UPDATE')) {
      return true
    } else {
      return false;
    }
  }

  return (
    <>
      <Grid container justify={"space-evenly"}>
        <Grid item sm={state?.selectedObject ? 3: 6} className={classes.section}>
          <QuestionGroups canUpdate={canUpdateAssessment()} />
        </Grid>
        {state?.selectedObject &&
        <Grid item sm={6} className={classes.section}>
          <AssessmentObject assessmentObject={state?.selectedObject}
                            canUpdate={canUpdateAssessment()}
          />
        </Grid>}
        <Grid item sm={state?.selectedObject ? 3: 6} className={classes.section}>
          <Accordion elevation={24} className={classes.sectionPaper} defaultExpanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreTwoTone />}>
              <Typography variant={"subtitle1"}>Assessment Info</Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails>
              <AssessmentInfo canUpdate={canUpdateAssessment()} />
            </AccordionDetails>
          </Accordion>

          <Accordion elevation={24} className={classes.sectionPaper} style={{marginTop: '1rem'}}>
            <AccordionSummary expandIcon={<ExpandMoreTwoTone />}>
              <Typography variant={"subtitle1"}>Preview</Typography>
            </AccordionSummary>
            <AccordionDetails style={{height: 300, overflow: 'auto'}}>
                <pre className={classes.preview}>
                  {JSON.stringify(state?.assessment, null, 2)}
                </pre>
            </AccordionDetails>
            <Divider />
            <AccordionActions>
              <Button startIcon={<AssessmentTwoTone />}
                      onClick={() => setViewPreview(true)}
              >Preview Assessment</Button>
            </AccordionActions>
          </Accordion>
        </Grid>
      </Grid>
      <PreviewAssessment open={viewPreview} onClose={() => setViewPreview(false)} assessment={state?.assessment} />
    </>
  )
}

export default Assessment;
