const modelTemplate = {
  diagnosis: {
    hidden: true,
    title: "Code",
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false
  },
  admittingDiagnosis: {
    title: "Admitting Diagnosis",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
    isToggle: true
  },
  startDate: {
    title: "Start Date",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: true
  },
  endDate: {
    title: "End Date",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: true
  },
}
export default modelTemplate;
