import React from "react";
import {
  Dialog,
  Button,
  AppBar,
  Grid,
  Toolbar,
  IconButton,
  Typography,
  DialogContent,
  TablePagination,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TextField,
  TableBody,
  Link,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";
import Transition from "../dialog/transition";

import { convertToFilters } from "../../utils/filter.utils";
import * as physicianService from "../../services/physician.service";
import CreatePhysician from "./create.physician";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    paddingTop: "4rem",
  },
  appBar: {},
  dialogContent: {
    marginTop: "4rem",
  },
}));

const sizeOptions = [5, 10, 25];
const filterTemplate = {
  specialty: {
    field: 'specialty',
    minValue: 2,
    value: null,
    operator: '*=*'
  },
  firstName: {
    field: "firstName",
    minValue: 3,
    value: null,
    operator: "*=*",
  },
  lastName: {
    field: "lastName",
    minValue: 3,
    value: null,
    operator: "*=*",
  },
  email: {
    field: "email",
    minValue: 3,
    value: null,
    operator: "*=*",
  },
  provider: {
    field: "providers.name",
    minValue: 3,
    value: null,
    operator: "*=*",
  },
};

const PhysicianLookup = ({ onSelect, open, onClose, token }) => {
  const classes = useStyles();
  const [total, setTotal] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [filter, setFilter] = React.useState(
    JSON.parse(JSON.stringify(filterTemplate))
  );
  const [physicians, setPhysicians] = React.useState([]);
  const [sortOrderBy, setSortOrderBy] = React.useState("lastName");
  const [sortOrderDirection, setSortOrderDirection] = React.useState("asc");
  const [
    openCreatePhysicianModal,
    setOpenCreatePhysicianModal,
  ] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      await searchPhysicians(
        page,
        size,
        filter,
        sortOrderBy,
        sortOrderDirection
      );
    };
    if (open) {
      fetchData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const searchPhysicians = async (pg, sz, filt, sort, sortDirection) => {
    const filters = convertToFilters(filt);
    const response = await physicianService.getPhysicians(
      pg,
      sz,
      filters,
      sort,
      sortDirection
    );
    setPhysicians(response.content);
    setSize(response.paginator.perPage);
    setPage(response.paginator.currentPage);
    setTotal(response.paginator.totalItems);
  };

  const onChangeRowsPerPage = async (event) => {
    await searchPhysicians(page, event.target.value, filter, sortOrderBy, sortOrderDirection);
  };
  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    await searchPhysicians(newPage, size, filter, sortOrderBy, sortOrderDirection);
  };

  const filterTextChange = async (e) => {
    const obj = { ...filter };
    obj[e.target.name].value = e.target.value;
    setFilter(obj);
    const filterz = convertToFilters(obj);
    if (filterz?.length > 0) {
      await searchPhysicians(page, size, obj, sortOrderBy, sortOrderDirection);
    } else if (!e.target.value) {
      await searchPhysicians(page, size, obj, sortOrderBy, sortOrderDirection);
    }
  };

  const createSortHandler = async field => {
    const sort = field;
    const sortDirection = sortOrderDirection === "asc" ? "desc" : "asc";
    setSortOrderBy(sort);
    setSortOrderDirection(sortDirection);
    await searchPhysicians(page, size, filter, sort, sortDirection);
  };

  const selectPhysician = (physician) => {
    if (onSelect) {
      onSelect(physician);
    }
  };

  const clearFilters = () => {
    const reseted = JSON.parse(JSON.stringify(filterTemplate))
    setFilter(reseted);
    setSortOrderBy('lastName');
    setSortOrderDirection('asc');
    searchPhysicians(1, 10, reseted, 'lastName', 'asc');

  };

  const onCreatePhysician = async (physician) => {
    const newEntity = await physicianService.createPhysician(physician);
    const obj = [...physicians, newEntity];
    setPhysicians(obj);
    setOpenCreatePhysicianModal(false);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={() => onClose()}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => onClose()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Search Physicians
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.dialogContent}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <div>
              <Button onClick={() => setOpenCreatePhysicianModal(true)}>
                Create
              </Button>
              <Button startIcon={<HighlightOffIcon />} onClick={clearFilters}>
                Clear Filters
              </Button>
              <CreatePhysician
                open={openCreatePhysicianModal}
                onClose={() => setOpenCreatePhysicianModal(false)}
                onSave={onCreatePhysician}
              />
            </div>
            <TablePagination
              component="div"
              rowsPerPageOptions={sizeOptions}
              colSpan={5}
              count={total ? total : 0}
              rowsPerPage={size ? size : 0}
              page={page ? page - 1 : 0}
              backIconButtonProps={{
                "aria-label": "previous page",
                disabled: page === 1 ? true : false,
              }}
              nextIconButtonProps={{
                "aria-label": "next page",
                disabled: page * size > total ? true : false,
              }}
              onChangeRowsPerPage={onChangeRowsPerPage}
              onChangePage={handleChangePage}
            ></TablePagination>
          </Grid>
          <Table
            size="small"
            style={{ width: "100%" }}
            className={classes.table}
            id="pharmacy-table"
          >
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell className={classes.tableHeader}>
                  <TableSortLabel
                    active={sortOrderBy === "specialty"}
                    direction={sortOrderDirection}
                    onClick={() => createSortHandler("specialty")}
                  >
                    Specialty
                  </TableSortLabel>
                  <TextField
                    name="specialty"
                    margin="dense"
                    size="small"
                    placeholder="Search"
                    variant="outlined"
                    fullWidth={true}
                    onChange={filterTextChange}
                    value={filter.specialty.value || ''}
                  ></TextField>
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  <TableSortLabel
                    active={sortOrderBy === "firstName"}
                    direction={sortOrderDirection}
                    onClick={() => createSortHandler("firstName")}
                  >
                    First Name
                  </TableSortLabel>
                  <TextField
                    name="firstName"
                    margin="dense"
                    size="small"
                    placeholder="Search"
                    variant="outlined"
                    fullWidth={true}
                    onChange={filterTextChange}
                    value={filter.firstName.value || ''}
                  ></TextField>
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  <TableSortLabel
                    active={sortOrderBy === "lastName"}
                    direction={sortOrderDirection}
                    onClick={() => createSortHandler("lastName")}
                  >
                    Last Name
                  </TableSortLabel>
                  <TextField
                    name="lastName"
                    margin="dense"
                    size="small"
                    placeholder="Search"
                    variant="outlined"
                    fullWidth={true}
                    onChange={filterTextChange}
                    value={filter.lastName.value || ''}
                  ></TextField>
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  <TableSortLabel
                    active={sortOrderBy === "email"}
                    direction={sortOrderDirection}
                    onClick={() => createSortHandler("email")}
                  >
                    Email
                  </TableSortLabel>
                  <TextField
                    name="email"
                    margin="dense"
                    size="small"
                    placeholder="Search"
                    variant="outlined"
                    fullWidth={true}
                    onChange={filterTextChange}
                    value={filter.email.value || ''}
                  ></TextField>
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  <TableSortLabel
                    active={sortOrderBy === "providers.name"}
                    direction={sortOrderDirection}
                    onClick={() => createSortHandler("providers.name")}
                  >
                    Provider
                  </TableSortLabel>
                  <TextField
                    name="provider"
                    margin="dense"
                    size="small"
                    placeholder="Search"
                    variant="outlined"
                    fullWidth={true}
                    onChange={filterTextChange}
                    value={filter.provider.value || ''}
                  ></TextField>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {physicians &&
                physicians.map((physician) => (
                  <TableRow>
                    <TableCell>
                      <Button onClick={() => selectPhysician(physician)}>
                        Select
                      </Button>
                    </TableCell>
                    <TableCell>{physician.specialty}</TableCell>
                    <TableCell>
                      <Link
                        component={RouterLink}
                        to={`/physicians/${physician._id}`}
                      >
                        {physician.firstName}
                      </Link>
                    </TableCell>
                    <TableCell>{physician.lastName}</TableCell>
                    <TableCell>{physician.email}</TableCell>
                    <TableCell>
                      {physician.medicalProviders &&
                        physician.medicalProviders
                          .map((provider) => provider.name)
                          .join(", ")}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PhysicianLookup;
