import React from 'react';
import {
  Accordion, AccordionDetails, AccordionSummary,
  Divider, makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell, TableContainer,
  TableRow,
  Tooltip,
  Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {AssignmentLateRounded, CheckCircle} from "@material-ui/icons";
import CancelIcon from "@material-ui/icons/Cancel";
import moment from "moment-timezone";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: ".5rem",
  },
  container: {
    display: "flex",
  },
  day: {
    flex: 1,
    border: "1px solid black",
    textAlign: "center",
    padding: ".25rem",
  },
  tableDayColumn: {
    minWidth: "50px",
    maxWidth: "50px",
    textAlign: "center",
    borderLeft: "1px solid gray",
    borderRight: "1px solid gray",
  },
  late: {
    color: theme.palette.warning.main
  }
}));

const MarPrn = ({ medicationOrder, days, timeZone}) => {
  const classes = useStyles();

  const getStatusLabel = status => {
    switch (status) {
      case 'Scheduled':
        return ' ';
      case 'Given':
        return <CheckCircle color={"secondary"} />;
      case 'Missed':
        return <CancelIcon color={"error"} />;
      case 'Refused':
        return 'R';
      case 'Hold':
        return 'H';
      case 'Refused & Destructed':
      case 'RD':
        return 'RD';
      case 'Self Administered':
      case 'SA':
        return 'SA';
      case 'Not Administered':
      case 'NA':
        return 'NA';
      case 'Late':
        return <AssignmentLateRounded className={classes.late}  />
      default:
        return <CloseIcon />;
    }
  }

  const getDataForDay = (day, order) => {
    let prnDosageInstruction
    if (order?.dosageInstructions?.length === 1) {
      prnDosageInstruction = order?.dosageInstructions[0];
    } else {
      return;
    }

    return prnDosageInstruction?.events?.filter(event => moment(event.administeredTimestamp).date() === day &&
      event?.status?.name !== 'Scheduled')?.map(event => {
      event.processed = true;
      return (
        <Tooltip interactive
                 title={
                   <Paper elevation={24}
                   >
                     <div style={{ padding: '1rem', width: '1000px', minHeight: '400px'}}>
                       <Typography variant={"subtitle1"}>Resident: {order?.resident?.firstName} {order?.resident?.lastName}</Typography>
                       <Typography variant={"subtitle1"}>Medication: {order?.medication?.tradeName}</Typography>
                       <Typography variant={"subtitle1"}>Status: {event?.status?.name}</Typography>
                       {event?.administeredUser &&
                       <Typography variant={"subtitle1"}>Administered
                         By: {event.administeredUser?.firstName} {event.administeredUser?.lastName}</Typography>
                       }
                       {event?.administeredTimestamp &&
                       <Typography variant={"subtitle1"}>Administered: {moment(event.administeredTimestamp).format('MM/DD/YYYY hh:mm a')}</Typography>}
                       <Typography variant={"subtitle1"}>Qty: {event?.passingQty}</Typography>

                     </div>
                     <Divider />
                     <Accordion>
                       <AccordionSummary>
                         DEBUG INFO -- WILL REMOVE
                       </AccordionSummary>
                       <AccordionDetails>
                         <div>
                           <Typography variant={"subtitle1"}>Iterable (day): {day}</Typography>
                           <Typography variant={"subtitle1"}>{event?.dosageInstruction}</Typography>
                           <Typography variant={"subtitle1"}>{moment(event?.scheduledTime).format('MM/DD/YYYY hh:mm a')}</Typography>
                           <Typography variant={"subtitle1"}>{moment(event.scheduledTime).date()}</Typography>
                           <Typography variant={"subtitle1"}>{moment(event.scheduledTime).hour()}</Typography>
                           <Typography variant={"subtitle1"}>{moment(event.scheduledTime).minute()}</Typography>
                           <Typography variant={"subtitle1"}>{JSON.stringify(event?.processed)}</Typography>
                         </div>
                       </AccordionDetails>
                     </Accordion>
                   </Paper>
                 }
        >
          <div style={{ position: 'relative', height: '65px', width: '25px', textAlign: 'center'}}>
            {getStatusLabel(event?.status?.name)}
            {event?.administeredUser &&
            <div style={{position: 'absolute', right: '0', bottom: '0'}}>
              <Typography variant={"subtitle2"}>
               {event?.administeredUser?.initials}
              </Typography>
              <Typography variant={"subtitle2"}>
                {moment(event?.administeredTimestamp).format('k:mm')}
              </Typography>
            </div>
            }
          </div>
        </Tooltip>)
    });
  }

  const hasValue = (day) => {
    let prnDosageInstruction
    if (medicationOrder?.dosageInstructions?.length === 1) {
      prnDosageInstruction = medicationOrder?.dosageInstructions[0];
    } else {
      return;
    }
    const events = prnDosageInstruction?.events?.filter(event =>
      moment.tz(event.scheduledTime, timeZone ?? "US/Central").date() === day
    );
    if (events && events.length > 0) {
      return true
    } else {
      return false;
    }
  }

  return (
    <TableContainer>
      <Table size={"small"}>
        <TableBody>
            <TableRow>
              <TableCell style={{maxWidth: '55px', minWidth: '55px'}}>N/A
              </TableCell>
              {[...Array(days)].map((e, i) =>
                <TableCell className={classes.tableDayColumn} >
                  {hasValue(i + 1, medicationOrder) ? getDataForDay(i + 1, medicationOrder) :
                    <Tooltip interactive
                             title={
                               <Paper elevation={24}>
                                 <div style={{ padding: '1rem', width: '1000px', minHeight: '400px'}}>
                                   <Typography variant={"h6"}>Not scheduled</Typography>
                                 </div>
                                 <Divider />
                                 <Accordion>
                                   <AccordionSummary>
                                     DEBUG INFO -- WILL REMOVE
                                   </AccordionSummary>
                                   <AccordionDetails>
                                    <pre>
                                      {JSON.stringify(medicationOrder, null, 2)}
                                    </pre>
                                   </AccordionDetails>
                                 </Accordion>
                               </Paper>
                             }
                    >
                      <div style={{height: '50px', width: '100%', cursor: 'pointer'}}>
                        {" "}
                      </div>
                    </Tooltip>
                  }
                </TableCell>)
              }
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default MarPrn;
