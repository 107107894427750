import React from 'react';
import {
  Grid,
  Typography,
} from "@material-ui/core";
import modelTemplate from "./modelTemplate";
import {FormFieldsGenerator} from "../../../../utils/forms";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: theme.spacing(3, 1)
    }
  },
  titleContainer: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flex: 1
    },
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(5)
    }
  },
  form: {
    width: '100%',
    flex: 1
  }
}));


export const VitalsInput = ({ emar, edit = false, onValueChange, resident }) => {
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify({})));
  const [editModal, setEditModel] = React.useState(JSON.parse(JSON.stringify({})));
  const classes = useStyles();

  React.useEffect(() => {
    if (emar) {
      if (!emar?.vital) {
        emar.vital = {};
      }
      const obj = JSON.parse(JSON.stringify(modelTemplate));
      Object.keys(emar?.vital).forEach(key => {
        if (obj.hasOwnProperty(key)) {
          obj[key].value = emar.vital[key];
        }
      });
      Object.keys(obj).forEach(key => {
          if (key !== 'date') {
            obj[key].hidden = !(emar?.chartVitals?.indexOf(obj[key].key) > -1);
          }
      })
      setModel(obj);
      if (edit) {
        setEditModel(obj);
      }
    }
  }, [emar, emar?.vital, edit]);


  const onChange = e => {
    const obj = {...model, ...e};
    setEditModel(obj);
    let payload = {};
    Object.keys(editModal).forEach(key => {
      payload[key] = editModal[key].value;
    });
    payload.resident = resident;
    onValueChange(emar?._id, payload);
  }

  const displayModalValues = () => {
    let props = [];
    Object.keys(model).forEach(key => {
      if (key !== 'date' && !model[key].hidden) {
        props = [...props, model[key]]
      }
    })
    return props.map(p => `${p.key}: ${p.value ?? 'n/a'}`).join(', ');
  }

  return (
    <Grid container
          alignItems={"center"}
          className={classes.root}
    >
      <Grid item className={classes.titleContainer}>
        <Grid container
              alignItems={"center"}
        >
          <i className="fas fa-heartbeat" style={{marginRight: '.25rem'}}></i>
          <Typography variant={"subtitle1"}>Vitals</Typography>
        </Grid>
      </Grid>
      <Grid item className={classes.form}>
        {edit &&
          <FormFieldsGenerator model={editModal}
                               onChange={onChange}
                               edit={edit}
          />
        }
        {!edit &&
          <div>
            {displayModalValues()}
          </div>
        }
      </Grid>
    </Grid>
  )
}

export default VitalsInput;
