import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Hidden,
  makeStyles,
  Typography
} from "@material-ui/core";
import React from "react";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from 'moment-timezone'
import {ChevronLeft, ChevronRight } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  root: {
      width: "100%",
      display: 'flex',
      justifyContent: 'center',
      margin: theme.spacing(1, 0)
  },
  calendar: {
    height: "3.5rem",
    maxWidth: "15rem",
  },
  calendarBtn: {
    height: "3.5rem",
    maxWidth: "8rem",
    margin: theme.spacing(0, 1)
  },
  mobileDateContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  navIcon: {
    fontSize: '3rem'
  }
}));

const DateNavigation = ({onDateChange}) => {
  const classes = useStyles();
  const [showDateDialog, setShowDateDialog] = React.useState(false);
  const [currentDate, setCurrentDate] = React.useState(new Date());

  const prevDate = () => {
    const date = moment(currentDate).subtract(1, 'd');
    setCurrentDate(date);
    onDateChange(date);
  };
  const nextDate = () => {
    const date = moment(currentDate).add(1, "d");
    setCurrentDate(date);
    onDateChange(date);
  };
  const updateDate = (date) => {
    setCurrentDate(date);
    if (moment(date).isValid()) {
        onDateChange(date);
    }
  };

  const onUpdatedDate = (date) => {
    setCurrentDate(date);
    if (moment(date).isValid()) {
      onDateChange(date);
    }
    setShowDateDialog(false);
  }

  return (
    <>
      <Hidden xsDown>
        <div className={classes.root}>
        <Button
          className={classes.calendarBtn}
          size="large"
          fullWidth
          variant="contained"
          color="primary"
          startIcon={<KeyboardArrowLeftIcon />}
          aria-label="previous"
          onClick={prevDate}
        >
          Previous
        </Button>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          className={classes.calendar}
          autoOk
          fullWidth
          inputVariant="outlined"
          value={currentDate}
          format="MM/DD/YYYY"
          InputAdornmentProps={{ position: "start" }}
          onChange={(date) => updateDate(date)}
        />
      </MuiPickersUtilsProvider>
        <Button
          className={classes.calendarBtn}
          size="large"
          fullWidth
          variant="contained"
          color="primary"
          endIcon={<KeyboardArrowRightIcon />}
          aria-label="next"
          onClick={nextDate}
        >
          Next
        </Button>
        </div>
      </Hidden>
      <Hidden smUp>
        <Grid container justify={"space-between"}>
          <IconButton onClick={prevDate} aria-label="previous">
            <ChevronLeft className={classes.navIcon} />
          </IconButton>
          <div className={classes.mobileDateContainer}>
            <IconButton onClick={() => setShowDateDialog(true)}>
              <i className="far fa-calendar-alt"></i>
            </IconButton>
            <Typography variant={"subtitle2"}>
              {moment(currentDate).format("MM/DD/YYYY")}
            </Typography>
          </div>
          <IconButton onClick={nextDate} aria-label="next">
            <ChevronRight  className={classes.navIcon} />
          </IconButton>
        </Grid>
        <Dialog open={showDateDialog}
                fullWidth
                onClose={() => setShowDateDialog(false)}
        >
          <DialogContent style={{padding: 0}}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                autoOk
                variant="static"
                openTo="date"
                value={currentDate}
                onChange={onUpdatedDate}
              />
            </MuiPickersUtilsProvider>
          </DialogContent>
          <DialogActions>
            <Button color={"primary"} onClick={() => setShowDateDialog(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </Hidden>
      </>
  );
};

export default DateNavigation;
