import config from "../utils/configs";
import apiUtil from "../utils/api.utils";

export const getProductManual = async () => {
  try {
    let url = `${config.userService}/api/v1.0/documentation/product-manual`;
    const result = await apiUtil.client.get(url, { responseType: 'blob' })
    return result?.data;
  } catch (error) {
    alert(error);
  }
}
