const coreService = process.env.REACT_APP_CORE_SERVICE_URL;
const residentService = process.env.REACT_APP_RESIDENT_SERVICE_URL;
const assessmentService = process.env.REACT_APP_ASSESSMENT_SERVICE_URL;
const medicationOrderService = process.env.REACT_APP_MEDICATION_ORDER_SERVICE_URL;
const reportService = process.env.REACT_APP_REPORT_SERVICE_URL;
const timeEntryService = process.env.REACT_APP_TIME_ENTRY_SERVICE_URL;
const authClientId = process.env.REACT_APP_AUTH_CLIENTID;
const authAudience = process.env.REACT_APP_AUTH_AUDIENCE;
const authDomain = process.env.REACT_APP_AUTH_DOMAIN;
const userService = process.env.REACT_APP_USER_SERVICE_URL;
const publicBucketName = process.env.REACT_APP_PUBLIC_BUCKET_NAME;
const bucketObjectUrl = process.env.REACT_APP_BUCKET_URL;
const publicBuckerUrl = process.env.REACT_APP_PUBLIC_BUCKER_URL;
/* Analytics Condfigs */
const analyticsApiKey = process.env.REACT_APP_ANALYTICS_API_KEY;
const analyticsAuthDomain = process.env.REACT_APP_ANALYTICS_AUTH_DOMAIN;
const analyticsDatabaseUrl = process.env.REACT_APP_ANALYTICS_DATABSE_URL;
const analyticsProjectId = process.env.REACT_APP_ANALYTICS_PROJECT_ID;
const analyticsStorageBucket = process.env.REACT_APP_ANALYTICS_STORAGE_BUCKET;
const analyticsMeassagingSenderId = process.env.REACT_APP_ANALYTICS_MESSAGING_SENDER_ID;
const analyticsAppId = process.env.REACT_APP_ANALYTICS_APP_ID;
const analyticsMeasurementId = process.env.REACT_APP_ANALYTICS_MEASUREMENT_ID;
const enableAnaltyics = process.env.REACT_APP_ENABLE_ANALTYICS;
const isDebug = process.env.REACT_APP_IS_DEBUG


class ApplicationConfig {
    static authDomain = authDomain;
    static authClientId = authClientId;
    static authAudience = authAudience;
    static coreService = coreService;
    static residentService = residentService;
    static assessmentService = assessmentService;
    static medicationOrderService = medicationOrderService;
    static userService = userService;
    static reportService = reportService;
    static timeEntryService = timeEntryService;
    static publicBucketName = publicBucketName;
    static bucketObjectUrl = bucketObjectUrl;
    static publicBucketUrl = publicBuckerUrl;
    static analytics = {
      enabled: (/true/).test(enableAnaltyics),
      apiKey: analyticsApiKey,
      authDomain: analyticsAuthDomain,
      databaseUrl: analyticsDatabaseUrl,
      projectId: analyticsProjectId,
      storageBucket: analyticsStorageBucket,
      messagingSenderId: analyticsMeassagingSenderId,
      appId: analyticsAppId,
      measurementId: analyticsMeasurementId
    }
    static isDebug = (/true/).test(isDebug)

}

export default ApplicationConfig;
