import apiUtil from '../utils/api.utils';
import config from '../utils/configs';
import moment from "moment/moment";
const apiUrl = `${config.timeEntryService}/api/v1.0`;

const getUserTimeEntryForDate = async (userId, date = moment()) => {
  try {
    const url = `${apiUrl}/timeentries/users/${userId}/date/${date?.format('YYYY-MM-DD')}`;
    const response = await apiUtil.client.get(url);
    return response?.data;
  } catch (error) {
    return {
      error: true,
      message: error
    }
  }
}

const getUserTimeEntryForWeek = async (userId, year, week) => {
  try {
    const url = `${apiUrl}/timeentries/users/${userId}/years/${year}/weeks/${week}`;
    const response = await apiUtil.client.get(url);
    return response?.data;
  } catch (error) {
    return {
      error: true,
      message: error
    }
  }
}

const enhanceTotalMinutes = (timeClocks) => {
  if (Array.isArray(timeClocks)) {
    return timeClocks?.map(setTotalTimeInput);
  } else {
    return null;
  }
}

const setTotalTimeInput = event => {
  let duration = moment.duration(event?.totalMinutes, "minutes");
  event.totalMinutesInput = `${duration.hours()}:${duration.minutes().toLocaleString("en-us",
    {
      minimumIntegerDigits: 2,
      useGrouping: false
    })
  }`;
  return event;
}


const createTimeClock = async (userId, timeEntry) => {
  try {
    timeEntry.user = userId;
    const url = `${apiUrl}/timeentries`;
    const response = await apiUtil.client.post(url, timeEntry);
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

const createTimeClockEvent = async (userId, timeClockId, event) => {
  try {
    const url = `${apiUrl}/users/${userId}/clocks/${timeClockId}`;
    const response = await apiUtil.client.post(url, event);
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

const addEvent = async (timeClockId, event) => {
  try {
    const response = await apiUtil.client.post(`${apiUrl}/timeentries/${timeClockId}/events`,
      event);
    return response?.data;
  } catch (error) {
    return {error: true, message: error};
  }
}

const editTimeEntry = async (timeEntryId, timeEntry) => {
    try {
    const response = await apiUtil.client.put(`${apiUrl}/timeentries/${timeEntryId}`, timeEntry);
    return response?.data;
  } catch (error) {
    return {error: true, message: error};
  }
}

const changeEventDate = async (timeClockId, eventId, payload) => {
  try {
    const response = await apiUtil.client.put(`${apiUrl}/timeentries/${timeClockId}/events/${eventId}/date`, payload);
    return response?.data;
  } catch (error) {
    return {error: true, message: error};
  }
}

const deleteEvent = async (timeClockId, eventId, event) => {
  try {
    const response = await apiUtil.client.delete(`${apiUrl}/timeentries/${timeClockId}/events/${eventId}`);
    return response?.data;
  } catch (error) {
    return {error: true, message: error};
  }
}

const clockOut = async (timeEntryId, userId, endDateTime) => {
  try {
    const response = await apiUtil.client.post(
      `${apiUrl}/timeentries/${timeEntryId}/clockout`,
      {
        userId: userId,
        end: endDateTime,
        timeEntryId: timeEntryId
      });
    return response?.data;
  } catch (error) {
    return {error: true, message: error};
  }
}

const deleteTimeEntry = async (timeEntryId) => {
  try {
    const response = await apiUtil.client.delete(`${apiUrl}/timeentries/${timeEntryId}`);
    return response?.data;
  } catch (error) {
    return {error: true, message: error};
  }
}

const searchTimeEntries = async () => {
  try {
    const response = await apiUtil.client.get(`${apiUrl}/timeentries`);
    return response?.data;
  } catch (error) {
    return {error: true, message: error};
  }
}

export {
  getUserTimeEntryForDate,
  getUserTimeEntryForWeek,
  createTimeClock,
  createTimeClockEvent,
  addEvent,
  editTimeEntry,
  deleteTimeEntry,
  changeEventDate,
  deleteEvent,
  clockOut,
  enhanceTotalMinutes,
  setTotalTimeInput,
  searchTimeEntries
}
