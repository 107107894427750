import React from "react";
import {
  Dialog,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  DialogContent,
  makeStyles,
  DialogActions,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Transition from "../../dialog/transition";
import ResidentInsuranceModel from './ResidentInsurance.model';
import {FormFieldsGenerator} from "../../../utils/forms";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    paddingTop: "4rem",
  },
  appBar: {},
  dialogContent: {
    marginTop: "4rem",
  },
}));

const ResidentInsurance = ({ residentInsurance, open, onClose, onSave, canEdit = true }) => {
  const classes = useStyles();
  const [model, setModel] = React.useState({});
  React.useEffect(() => {
    if (residentInsurance) {
      const obj = JSON.parse(JSON.stringify(ResidentInsuranceModel));
      obj._id.value = residentInsurance._id;
      obj.insuranceId.value = residentInsurance?.insurance?._id;
      obj.isPrimary.value = residentInsurance?.isPrimary;
      obj.memberNumber.value = residentInsurance.memberNumber;
      obj.groupNumber.value = residentInsurance.groupNumber;
      obj.comment.value = residentInsurance.comment;
      obj.effectiveDate.value = residentInsurance.effectiveDate;
      setModel(obj);
    }
  },[residentInsurance]);

  const save = () => {
    onSave({
      _id: model._id.value,
      insuranceId: model.insuranceId.value,
      memberNumber: model.memberNumber.value,
      groupNumber: model.groupNumber.value,
      effectiveDate: model.effectiveDate.value,
      comment: model.comment.value,
      isPrimary: model.isPrimary.value
    });
  };

  const onChange = e => {


  /*  const obj = {...model}
    obj[e.target.name] = e.target.value;
    setModel(obj);*/
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => onClose()}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => onClose()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Resident Insurance
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.dialogContent}>
        <form>
          <FormFieldsGenerator model={model} onChange={onChange} edit={canEdit} />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Cancel</Button>
        {canEdit &&
        <Button variant="contained" color="primary" onClick={() => save()}>
          Save
        </Button>}
      </DialogActions>
    </Dialog>
  );
};

export default ResidentInsurance;
