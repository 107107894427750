import React from "react";
import {
  Button,
  FormGroup,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  DialogContent,
  makeStyles
} from "@material-ui/core";
import Transition from "../dialog/transition";
import CloseIcon from "@material-ui/icons/Close";
import { getPhoneTypes }  from "../../services/phone.service";
import FormFieldsGenerator from "../../utils/forms/form.fields.generator";

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1),
    paddingTop: "4rem"
  },
  appBar: {},
  form: {
    "& .Mui-required": {
      color: theme.palette.error.main
    },
    "& .MuiSelect-selectMenu": {
      textAlign: "left"
    }
  },
  dialogContent: {
    marginTop: "4rem"
  },
  btnContainer: {
    paddingTop: ".5rem",
    display: "flex",
    justifyContent: "flex-end"
  }
}));

const modelTemplate = {
  number: {
    title: "Phone Number",
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
    textType: "tel",
    inputMask: 'phone'
  },
  phoneType: {
    title: "Type",
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [],
    dropDownItemValue: "name",
    dropDownItemLabel: "name",
    date: false
  }
};


const PrimaryModelTemplate = {
  number: {
    title: "Phone Number",
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
    textType: "tel",
    inputMask: 'phone'
  },
  phoneType: {
    title: "Type",
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [],
    dropDownItemValue: "name",
    dropDownItemLabel: "name",
    date: false
  },
  isPrimary: {
    title: "Is Primary",
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    isToggle: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "name",
    dropDownItemLabel: "name",
    date: false
  }
};


const CreatePhone = ({ open, onClose, toggleIsPrimary = false, onSave, token }) => {
  const classes = useStyles();
  const [model, setModel] =
    React.useState(JSON.parse(JSON.stringify(toggleIsPrimary ? PrimaryModelTemplate: modelTemplate)));
  const [phoneTypes, setPhoneTypes] = React.useState([]);

  React.useEffect(() => {
    const getTypes = async () => {
      const types = await getPhoneTypes(token);
      setPhoneTypes(types);
      const obj = { ...model };
      obj.phoneType.dropDownItems = types;
      setModel(obj);
    };
    if (open) {
      if (!phoneTypes || phoneTypes.length === 0) {
        getTypes();
      }
    } else {
      const obj = JSON.parse(JSON.stringify(toggleIsPrimary ? PrimaryModelTemplate: modelTemplate));
      obj.phoneType.dropDownItems = phoneTypes;
      setModel(obj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const close = () => {
    if (onClose) {
      onClose();
    }
  };
  const save = () => {
    if (onSave) {
      onSave({
        number: model.number.value,
        phoneType: model.phoneType.value,
        isPrimary: model?.isPrimary?.value
      });
    }
  };

  const cancel = () => {
    if (onClose) {
      onClose();
    }
  };

  const onModelChange = updatedModel => {
    const obj = { ...model, ...updatedModel };
    setModel(obj);
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => close()}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => close()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Create Phone
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.dialogContent}>
        <form
          className={classes.form}
          style={{ width: "100%", marginBottom: "1rem" }}
          noValidate
        >
          <FormFieldsGenerator model={model} onChange={onModelChange} />
          <FormGroup
            row
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "1rem"
            }}
          >
            <Button onClick={() => cancel()}>Cancel</Button>
            <Button onClick={() => save()} variant="contained" color="primary">
              Save
            </Button>
          </FormGroup>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CreatePhone;
