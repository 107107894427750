import React from 'react';
import moment from 'moment';
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const CurrentResidentLog = ({ log }) => {
  const classes = useStyles();
  switch (log.type) {
    case 'activity':
      return (
        <ListItem className={classes.root}>
          <ListItemAvatar>
            <Avatar>A</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`Activity: ${log.title}`}
            secondary={log.description}
          />
        </ListItem>
      );
    case 'bladder':
      return (
        <ListItem className={classes.root}>
          <ListItemAvatar>
            <Avatar>BL</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`Bladder: ${moment(log.hour).format('h:mm a')}`}
            secondary={`${log.result} ${log.comments ? log.comments : ''}`}
          />
        </ListItem>
      );
    case 'bm':
      return (
        <ListItem className={classes.root}>
          <ListItemAvatar>
            <Avatar>BM</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`Bowl Movement: ${moment(log.hour).format('h:mm a')}`}
            secondary={`${log.pattern} ${log.color} ${log.consistency}`}
          />
        </ListItem>
      );
    case 'visitor':
      return (
        <ListItem className={classes.root}>
          <ListItemAvatar>
            <Avatar>V</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`Visitor: ${moment(log.hour).format('h:mm a')}`}
            secondary={`${log.visitor}`}
          />
        </ListItem>
      );
    case 'behavior':
      return (
        <ListItem className={classes.root}>
          <ListItemAvatar>
            <Avatar>B</Avatar>
          </ListItemAvatar>
          <ListItemText primary={`Behavior:`} secondary={`${log.comments}`} />
        </ListItem>
      );
    case 'substance':
      return (
        <ListItem className={classes.root}>
          <ListItemAvatar>
            <Avatar>S</Avatar>
          </ListItemAvatar>
          <ListItemText primary={`Substance:`} secondary={`${log.comments}`} />
        </ListItem>
      );
    case 'incident':
      return (
        <ListItem className={classes.root}>
          <ListItemAvatar>
            <Avatar>I</Avatar>
          </ListItemAvatar>
          <ListItemText primary={`Incident:`} secondary={`${log.incident}`} />
        </ListItem>
      );
    case 'intakeoutput':
      return (
        <ListItem className={classes.root}>
          <ListItemAvatar>
            <Avatar>I</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`Intake/Output (ml)`}
            secondary={`${log.intake}/${log.output}`}
          />
        </ListItem>
      );
    default:
      return <ListItem>{log.type}</ListItem>;
  }
};

export default CurrentResidentLog;
