const filterTemplate = {
  id: {
    field: 'id',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  tags: {
    field: 'tags',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  name: {
    field: 'name',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  type: {
    field: 'type',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  description: {
    field: 'description',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  isGlobal: {
    field: 'isGlobal',
    minValue: 0,
    value: '0',
    operator: 'boolean'
  },
  tenant: {
    field: 'tenant',
    minValue: 1,
    value: null,
    operator: '=in=',
  },
  status: {
    field: 'status',
    minValue: 3,
    value: "-1",
    operator: '*=*',
  },
};

export default filterTemplate;
