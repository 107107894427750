import React from 'react';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { ResidentContext } from '../../../context/residentcontext';
import DeleteForeverTwoToneIcon from '@material-ui/icons/DeleteForeverTwoTone';
import moment from 'moment';
import CreateDiet from './create.diet';
import {
  getResidentDiets,
  createResidentDiet,
  updateResidentDiet,
} from '../../../services/diet.service';
import EditDiet from './edit.diet';
import ChipInput from 'material-ui-chip-input';
import useHasRole from "../../../hooks/user.role.hook";
import useGlobalLoadingIndicator from "../../../hooks/global.loading.hook";
import {PageTitle} from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1),
  },
  discontinueBtn: {
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.error.main,
      background: theme.palette.grey[200],
    },
  },
  selectionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  selectionItem: {
    margin: theme.spacing(0, 1, 0, 0),
  },
}));

const ResidentDiets = () => {
  const { state } = React.useContext(ResidentContext);
  const classes = useStyles();
  const [orderType, setOrderType] = React.useState('current');
  const [showDiscontinue, setShowDiscontinue] = React.useState(false);
  const [selectedDiet, setSelectedDiet] = React.useState(null);
  const [residentId, setResidentId] = React.useState(null);
  const [showCreateDiet, setShowCreateDiet] = React.useState(false);
  const [showEditDiet, setShowEditDiet] = React.useState(false);
  const { toggleLoading } = useGlobalLoadingIndicator();
  const [rows, setRows] = React.useState([]);
  const { checkPermission } = useHasRole();
  const canEdit = checkPermission('DIET_ORDER_UPDATE');

  React.useEffect(() => {
    if (state.resident && state.resident._id && orderType) {
      setResidentId(state.resident._id);
      let discontinued = null;
      if (orderType === 'current') {
        discontinued = false;
      } else if (orderType === 'discontinued') {
        discontinued = true;
      }
      getData(state.resident._id, discontinued);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.resident, orderType]);

  const getData = async (id, discontinued) => {
    const data = await getResidentDiets(id, discontinued);
    setRows(data);
  };

  const onOrderTypeChange = (e) => {
    setOrderType(e.target.value);
  };

  const onDiscontinue = (diet) => {
    setSelectedDiet(diet);
    setShowDiscontinue(true);
  };

  const handleClose = () => {
    setShowDiscontinue(false);
  };

  const handleDiscontinue = async () => {
    setShowDiscontinue(false);
    const data = { ...selectedDiet };
    data.discontinued = true;
    const response = await updateResidentDiet(residentId, data._id, data);
    const obj = rows.filter((row) => row._id !== response._id);
    setRows(obj);
  };

  const onActivate = async (diet) => {
    const data = { ...diet };
    data.discontinued = false;
    const response = await updateResidentDiet(residentId, data._id, data);
    const obj = rows.map((row) => (row._id === response._id ? response : row));
    setRows(obj);
  };

  const onCreateDiet = async (diet) => {
    setShowCreateDiet(false);
    const data = await createResidentDiet(residentId, diet);
    const obj = [...rows, data];
    setRows(obj);
  };

  const onEditDiet = async (diet) => {
    setSelectedDiet(diet);
    setShowEditDiet(true);
  };

  const createDiet = () => {
    setSelectedDiet(null);
    setShowCreateDiet(true);
  };

  const updateDiet = async (dietId, diet) => {
    toggleLoading(true);
    const response = await updateResidentDiet(residentId, dietId, diet);
    const obj = rows.map((row) => (row._id === response._id ? response : row));
    setRows(obj);
    setShowEditDiet(false);
    toggleLoading(false);
  };

  return (
    <Paper elevation={24} className={classes.root}>
      <PageTitle title={"Resident Diet"} />
      <div className={classes.selectionContainer}>
        {canEdit &&
        <Button
          color="primary"
          variant="contained"
          style={{ height: '2rem' }}
          className={classes.selectionItem}
          onClick={createDiet}
        >
          Add Diet
        </Button>}
        <CreateDiet
          open={showCreateDiet}
          onClose={() => setShowCreateDiet(false)}
          onSave={onCreateDiet}
        />
        <EditDiet
          open={showEditDiet}
          onClose={() => setShowEditDiet(false)}
          onSave={updateDiet}
          diet={selectedDiet}
        />
      </div>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell width={50}>
                <FormControl
                  className={classes.selectionItem}
                  style={{ width: '8rem' }}
                >
                  <InputLabel shrink>Orders</InputLabel>
                  <Select
                    displayEmpty
                    disableUnderline
                    fullWidth
                    value={orderType}
                    onChange={onOrderTypeChange}
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="current">Current</MenuItem>
                    <MenuItem value="discontinued">Discontinued</MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell>Diet Type</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Calories</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Schedule</TableCell>
              <TableCell>Tags</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((diet) => (
                <TableRow>
                  <TableCell>
                    {canEdit &&
                    <ButtonGroup>
                      <Button
                        variant="text"
                        size="small"
                        style={{ marginRight: '1rem' }}
                        onClick={() => onEditDiet(diet)}
                      >
                        Edit
                      </Button>
                      {!diet.discontinued && (
                        <Button
                          startIcon={<DeleteForeverTwoToneIcon />}
                          variant="contained"
                          size="small"
                          className={classes.discontinueBtn}
                          onClick={() => onDiscontinue(diet)}
                        >
                          Discontinue
                        </Button>
                      )}
                      {diet.discontinued && (
                        <Button
                          startIcon={<DeleteForeverTwoToneIcon />}
                          variant="contained"
                          size="small"
                          onClick={() => onActivate(diet)}
                        >
                          Activate
                        </Button>
                      )}
                    </ButtonGroup>}
                  </TableCell>
                  <TableCell>{diet.type}</TableCell>
                  <TableCell>{diet.description}</TableCell>
                  <TableCell>{diet.calories}</TableCell>
                  <TableCell>
                    {moment(diet.startDate).format('MM/DD/YYYY')}
                  </TableCell>
                  <TableCell>
                    {moment(diet.endDate).format('MM/DD/YYYY')}
                  </TableCell>
                  <TableCell>
                    {diet.meals &&
                      diet.meals.map((meal) => (
                        <Typography variant="subtitle2">
                          {meal.type} {meal.frequency}{' '}
                          {meal.templates &&
                            meal.templates.map((template) => (
                              <span>
                                {moment(template.time).format('h:mma')}
                              </span>
                            ))}
                        </Typography>
                      ))}
                  </TableCell>
                  <TableCell>
                    <ChipInput value={diet.tags} disableUnderline disabled />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={showDiscontinue}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="discontinue-dialog-title"
        aria-describedby="discontinue-dialog-description"
      >
        <DialogTitle id="discontinue-dialog-title">
          {'Discontinue diet?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="discontinue-dialog-description">
            Are you sure you want to discontinue this diet?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={handleDiscontinue} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default ResidentDiets;
