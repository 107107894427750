import React from 'react';
import {
  AppBar,
  Button,
  Grid,
  makeStyles,
  Toolbar,
  Typography
} from "@material-ui/core";
import { useAuth0 } from '../../react-auth0-spa';
import backgroundImage from './caremanagement.webp';
import configs from "../../utils/configs";
import { Route, Router, Switch} from "react-router-dom";
import history from '../../utils/history';
import {AuthPage} from "../../pages";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 0, 0, 1)
  },
  appTitleLink: {
    flex: 1,
    padding: 0,
    margin: 0,
    textDecoration: 'none',
    color: '#fff',
    fontFamily: 'Brush Script MT, Brush Script Std, cursive',
    "&:hover": {
      textDecoration: 'none'
    }
  },
  mainContent: {
    background: `url(${backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: "100% 20vh",
    padding: theme.spacing(1),
    height: '50vh',
    [theme.breakpoints.up('sm')]: {
      height: '70vh',
      backgroundSize: "100% 40vh",
    },
  },
  footer: {
    backgroundColor: theme.palette.grey[100],
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0
  },
  item: {
    color: theme.palette.primary.dark,
    flex: 1
  },
  footerInfo: {
  },
  loginBtn: {
    width: '15rem'
  },
  btnSection: {

  },
  navLogin: {
    color: theme.palette.primary.contrastText
  }
}));

export const LandingPage = () => {
  const { loginWithRedirect } = useAuth0();
  const classes = useStyles();

  const login = async () => {
    if (configs.isDebug) {
      await loginWithRedirect();
    } else {
      await loginWithRedirect({prompt: 'login'});
    }
  };

  return (
    <>
      <AppBar position={"sticky"} className={classes.root}>
        <Toolbar>
          <Typography variant="h3" className={classes.appTitleLink}>
            eR
          </Typography>
          <Button startIcon={<i className="fas fa-sign-in-alt"></i>}
                  variant={"text"}
                  className={classes.navLogin}
                  onClick={login}>
            Login
          </Button>
        </Toolbar>
      </AppBar>
      <Grid container
            justifyContent={"center"}
            alignItems={"center"}
            direction={"column"}
            className={classes.mainContent}
      >
        <Grid item
              className={classes.item}
        >
          <Grid container
                direction={"column"}
                justifyContent={"center"}
                alignItems={"center"}>
            <Typography variant={"h3"}>eResidentCare</Typography>
            <Typography variant={"h6"}>Care management software</Typography>
          </Grid>
        </Grid>
        <Grid item
              className={classes.btnSection}
        >
          <Button startIcon={<i className="fas fa-sign-in-alt"></i>}
                  variant={"contained"}
                  className={classes.loginBtn}
                  onClick={login}
                  color={"primary"}>
            LOG IN
          </Button>
        </Grid>
      </Grid>
      <Grid className={classes.footer}
            container
            justifyContent={"center"}
            alignItems={"center"}
      >
        <Typography variant={"subtitle2"}
                    className={classes.footerInfo}
        >
          Powered by Software Solutions LLC
        </Typography>
      </Grid>
    </>
  )
}

export const LandingPageWrapper = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route path={"/auth"} component={AuthPage} />
        <Route path={"/"} component={LandingPage} />
      </Switch>
    </Router>
  )
}
