import React from 'react';
import {
  getMedicationOrder,
  deleteMedicationOrder,
  updateResidentMedicationOrder,
  updateDosageInstruction,
  createDosageInstruction,
  deleteDosageInstruction,
  updateMedicationOrderMedication,
  updateMedicationOrderPhysician,
  updateMedicationOrderPharmacy, updateMedicationOrderAllergies
} from '../../services/medication.order.service';
import {
  Paper,
  makeStyles,
  Grid,
  Button,
  FormGroup,
  TextField,
  Checkbox,
  FormControlLabel,
  DialogActions,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Hidden,
  DialogContentText,
  Divider,
  TableContainer,
  ListItemText,
  Input,
  FormHelperText,
  Typography,
  Tooltip,
  InputAdornment,
  IconButton, Tabs, Tab, CircularProgress,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import history from '../../utils/history';
import { GlobalNotificationContext } from '../notification/globalnotificationprovider';
import EventTemplate from './create/event.template';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { getFrequencies } from '../../services/frequency.service';
import { getOrderStatuses } from '../../services/medication.order.service';
import useHasRole from "../../hooks/user.role.hook";
import DeleteForeverTwoToneIcon from "@material-ui/icons/DeleteForeverTwoTone";
import {DateRange, History, PauseSharp, SettingsInputComponentSharp, WarningTwoTone} from "@material-ui/icons";
import {MedicationSearchModal} from "../medications";
import SaveIcon from "@material-ui/icons/Save";
import {Alert, AlertTitle} from "@material-ui/lab";
import {DeleteBtn} from "../utils/buttons/DeleteBtn";
import PhysicianLookup from "../physicians/physician.lookup";
import PharmacyLookup from "../pharmacies/pharmacy.lookup";
import {PageTitle} from "../utils";
import AllergyLookup from "../allergy/allergy.lookup";
import {MedicationOrderHolds} from "./hold/MedicationOrderHolds";
import ConfirmDialog from "../utils/dialogs/confirm.dialog";
import EditDosageInstructionModal from "./create/edit.dosage.instruction.modal";
import AddDosageInstructionModal from "./create/add.dosage.instruction.modal";
import {MedicationOrderHistory} from "./history";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  icon: {
    marginRight: '.5rem',
  },
  backBtn: {
    marginRight: '.5rem',
  },
  gridContainer: {
    padding: theme.spacing(1),
  },
  formGroupContainer: {
    padding: theme.spacing(0, 2),
  },
  deleteBtn: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.main
    }
  },
  infoTooltip: {
    color: theme.palette.info.dark,
    cursor: 'pointer'
  }
}));

const vitals = [
  'All',
  'Blood glucose',
  'Blood pressure',
  'INR',
  'Oxygen',
  'Pain',
  'Pulse',
  'Respiratory',
  'Temperature',
  'Weight'
]

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const MedicationOrder = ({ match }) => {
  const classes = useStyles();
  const [medicationOrder, setMedicationOrder] = React.useState();
  const { addNotification } = React.useContext(GlobalNotificationContext);
  const [frequencies, setFrequencies] = React.useState([]);
  const [openFrequencyModal, setOpenFrequencyModel] = React.useState(false);
  const [newDosageInstruction, setNewDosageInstruction] = React.useState({
    frequency: {},
  });
  const [original, setOriginal] = React.useState();
  const [confirmDirectionChange, setConfirmDirectionChange] = React.useState(
    false
  );
  const [
    confirmDiscontinueChange,
    setConfirmDiscontinueChange,
  ] = React.useState(false);
  const [
    confirmDeleteDosageInstruction,
    setConfirmDeleteDosageInstruction,
  ] = React.useState(false);
  const [
    deleteDosageInstructionEntity,
    setDeleteDosageInstrutionEntity,
  ] = React.useState(null);
  const [orderStatuses, setOrderStatuses] = React.useState([]);
  const [discontinueStatus, setDiscontinueStatus] = React.useState(null);
  const [discontinueDate, setDiscontinueDate] = React.useState(null);
  const [viewDeleteConfirmModal, setViewDeleteConfirmModal] = React.useState(false);
  const [viewMedicationSearchModal, setViewMedicationSearchModal] = React.useState(false);
  const [showMedicationSaveButton, setShowMedicationSaveButton] = React.useState(false);
  const [savingMedication, setSavingMedication] = React.useState(false);
  const [savingPharmacy, setSavingPharmacy] = React.useState(false);
  const [savingPhysician, setSavingPhysician] = React.useState(false);
  const [savingAllergies, setSavingAllergies] = React.useState(false);
  const [viewPharmacySearchModal, setViewPharmacySearchModal] = React.useState(false);
  const [viewPhysicianSearchModal, setViewPhysicianSearchModal] = React.useState(false);
  const [showPhysicianSaveButton, setShowPhysicianSaveButton] = React.useState(false);
  const [viewAllergySearchModal, setViewAllergySearchModal] = React.useState(false);
  const [showAllergySaveButton, setShowAllergySaveButton] = React.useState(false);
  const [backupMedication, setBackupMedication] = React.useState();
  const [backupPharmacy, setBackupPharmacy] = React.useState();
  const [backupPhysician, setBackupPhysician] = React.useState();
  const [backupAllergy, setBackupAllergy] = React.useState();
  const [showPharmacySaveButton, setShowPharmacySaveButton] = React.useState(false);
  const [tabValue, setTabValue] = React.useState("details");
  const { checkPermission } = useHasRole();
  const canEdit = checkPermission('MEDICATION_ORDER_UPDATE');
  const canDelete = checkPermission('MEDICATION_ORDER_DELETE');
  const [updatingOrder, setUpdatingOrder] = React.useState(false);
  const [viewCancelConfirmDialog, setViewCancelConfirmDialog] = React.useState(false);
  const [openAddDosageInstructioModal, setOpenAddDosageInstructioModal] = React.useState(false);



  React.useEffect(() => {
    if (match.params.id) {
      loadMedicationOrder(match.params.id);
    }
  }, [match.params.id]);

  React.useEffect(() => {
    if (!orderStatuses || orderStatuses.length === 0) {
      loadOrderStatuses();
    }
  }, [orderStatuses]);

  React.useEffect(() => {
    if (!frequencies || frequencies.length === 0) {
      loadFrequencies();
    }
  }, [frequencies]);

  const loadOrderStatuses = async () => {
    const response = await getOrderStatuses();
    setOrderStatuses(response);
  };

  const loadFrequencies = async () => {
    const freqs = await getFrequencies();
    setFrequencies(freqs);
  };

  const loadMedicationOrder = async (id) => {
    const response = await getMedicationOrder(id);
    setOriginal(response);
    setMedicationOrder(JSON.parse(JSON.stringify(response)));
  };

  const cancelOrderEdits = () => {
    setMedicationOrder(JSON.parse(JSON.stringify(original)));
  };

  const updateChecked = (e) => {
    const obj = { ...medicationOrder };
    obj[e.target.name] = e.target.checked;
    setMedicationOrder(obj);
  };


  const saveOrder = async () => {
    if (
      original &&
      medicationOrder &&
      (original.direction || '') !== (medicationOrder.direction || '')
    ) {
      setConfirmDirectionChange(true);
    } else if (original?.orderStatus?.name !== "Canceled" && medicationOrder?.orderStatus?.name === "Canceled") {
      setViewCancelConfirmDialog(true);
    } else {
      setUpdatingOrder(true);
      await save();
      setUpdatingOrder(false);
    }
  };

  const save = async () => {
    try {
      const response = await updateResidentMedicationOrder(
        medicationOrder._id,
        medicationOrder
      );
      setOriginal(JSON.parse(JSON.stringify(response)));
      setMedicationOrder(response);
      addNotification('Order successfully updated', 'success');
    } catch {
      addNotification('Unable to update your order.', 'error');
    }
  };

  const cancelDirectionUpdate = () => {
    const obj = { ...medicationOrder };
    obj.direction = original.direction;
    setMedicationOrder(obj);
    setConfirmDirectionChange(false);
  };

  const confirmDirectionUpdate = async () => {
    await save();
    setConfirmDirectionChange(false);
  };

  const textChange = (e) => {
    const obj = { ...medicationOrder };
    obj[e.target.name] = e.target.value;
    setMedicationOrder(obj);
  };

  const onDeleteDosageInstruction = async () => {
    // Delete dosage
    await deleteDosageInstruction(
      medicationOrder._id,
      deleteDosageInstructionEntity._id
    );
    const obj = { ...medicationOrder };
    if (deleteDosageInstructionEntity && obj.dosageInstructions) {
      obj.dosageInstructions = obj.dosageInstructions.filter(
        (di) => di._id !== deleteDosageInstructionEntity._id
      );
    }
    setMedicationOrder(obj);
    setDeleteDosageInstrutionEntity(null);
    setConfirmDeleteDosageInstruction(false);
  };

  const cancelDeleteDosageInstruction = () => {
    setDeleteDosageInstrutionEntity(null);
    setConfirmDeleteDosageInstruction(false);
  };

  const removeDosageInstruction = (dosageInstruction) => {
    setDeleteDosageInstrutionEntity(dosageInstruction);
    setConfirmDeleteDosageInstruction(true);
  };

  const editDosageInstruction = (dosageInstruction) => {
    setNewDosageInstruction(dosageInstruction);
    setOpenFrequencyModel(true);
  };

  const onDosageInstructionAdded = async (di) => {
    di?.eventTemplates.forEach((et) => {
      et.passingQty = +et.passingQty;
    });
    const { dosageInstruction, eventsCreated } = await createDosageInstruction(
      medicationOrder._id,
      di
    );
    const obj = { ...medicationOrder };
    obj.dosageInstructions = [...obj.dosageInstructions, dosageInstruction]
                                  .sort((a, b) => a.startDate - b.startDate);
    setMedicationOrder(obj);
    addNotification(`Created new dosage instruction, ${eventsCreated} events created`, 'success');
    setMedicationOrder(obj);
    setOpenAddDosageInstructioModal(false);
  }

  const addDosageInstruction = async (payload) => {
    try {
      const result = await updateDosageInstruction(
          medicationOrder._id,
          payload._id,
          payload
      );
      if (!result?.error) {
        addNotification(
            `Updated Dosage Instruction for Medication Order, ${result?.eventsCreated} events created`,
            'success'
        );
        const obj = { ...medicationOrder };
        obj.dosageInstructions = obj.dosageInstructions
            .map(di => di._id === result?.dosageInstruction._id ? result?.dosageInstruction: di);
        setMedicationOrder(obj);
        setOpenFrequencyModel(false);
      } else {
        addNotification(result?.message?.message, 'error');
      }
    } catch (error) {
        addNotification('Unable to update', 'error');
    }
  };

  const onStatusChange = (e) => {
    const status = orderStatuses.find((o) => o._id === e.target.value);
    if (status && status.name === 'Discontinued') {
      setDiscontinueDate(null);
      setDiscontinueStatus(status._id);
      setConfirmDiscontinueChange(true);
    } else {
      const obj = { ...medicationOrder };
      if (obj.orderStatus) {
        let status = orderStatuses?.find(o => o._id === e.target.value);
        obj.orderStatus = status;
      } else {
        obj.orderStatus = { _id: e.target.value };
      }
      setMedicationOrder(obj);
    }
  };

  const onQuickStatusChange = e => {
    const obj = {...medicationOrder};
    obj.defaultQuickStatus = e.target.value;
    setMedicationOrder(obj);
  }

  const onConfirmDiscontinueChange = () => {
    const obj = { ...medicationOrder };
    if (obj.orderStatus) {
      obj.orderStatus._id = discontinueStatus;
    } else {
      obj.orderStatus = { _id: discontinueStatus };
    }
    obj.discontinuedDate = discontinueDate;
    setMedicationOrder(obj);
    setConfirmDiscontinueChange(false);
  };

  const onDiscontinueDateChange = (date) => {
    setDiscontinueDate(date);
  };

  const onOrderDateChange = date => {
    const obj = {...medicationOrder};
    obj.orderDate = date;
    setMedicationOrder(obj);
  }

  const onDiscontinuedDateChange = date => {
    const obj = {...medicationOrder};
    obj.discontinuedDate = date;
    setMedicationOrder(obj);
  }

  const onDeleteOrderConfirmed = async () => {
    const result = await deleteMedicationOrder(medicationOrder?._id);
    if (!result?.error) {
      setViewDeleteConfirmModal(false);
      addNotification('Medication order deleted', 'success');
      history.goBack();
    } else {
      addNotification('Unable to delete medication order');
    }
  }

  const updateChartVitalsChecked = e => {
    const obj = { ...medicationOrder };
    obj.chartVitals = e.target.checked;

    if (obj.chartVitals && !Array.isArray(obj.chartVitalsList)) {
      obj.chartVitalsList = [];
    }

    if (!obj.chartVitals) {
      obj.chartVitalsList = [];
    }

    setMedicationOrder(obj);
  }

  const onChartVitalsListChange = e => {
    const obj = {...medicationOrder};
    const value = e.target.value;
    if (value[value.length - 1] === "All") {
      obj.chartVitalsList = obj?.chartVitalsList?.length === vitals?.length ? []: vitals;
    } else {
      obj.chartVitalsList = value;
    }
    setMedicationOrder(obj);
  }

  const onMedicationSelected = medication => {
    setBackupMedication(medicationOrder?.medication);
    setViewMedicationSearchModal(false);
    const obj = {...medicationOrder};
    obj.medication = medication;
    setMedicationOrder(obj);
    setShowMedicationSaveButton(true);
  }

  const cancelMedicationChanges = () => {
    const obj = {...medicationOrder};
    obj.medication = backupMedication;
    setMedicationOrder(obj);
    setShowMedicationSaveButton(false);
  }

  const onSaveMedicationConfirmed = async () => {
    setSavingMedication(true);
    const response =
      await updateMedicationOrderMedication(medicationOrder?.resident?._id, medicationOrder?._id, medicationOrder?.medication?._id);
    if (!response?.error) {
      addNotification("Medication updated", "success");
      setShowMedicationSaveButton(false);
    } else {
      addNotification("Unable to update medication on this order", "error");
      cancelMedicationChanges();
    }
    setSavingMedication(false);
  }

  const getPhysicianName = physician => {
    if (physician) {
      return `${physician?.title || ''} ${physician?.firstName} ${physician?.lastName}`;
    } else {
      return null;
    }
  }

  const onPhysicianSelected = physician => {
    setBackupPhysician(medicationOrder?.physician);
    const obj = {...medicationOrder};
    obj.physician = physician;
    setMedicationOrder(obj);
    setShowPhysicianSaveButton(true);
    setViewPhysicianSearchModal(false);
  }

  const onPharmacySelected = pharmacy => {
    setBackupPharmacy(medicationOrder?.pharmacy);
    const obj = {...medicationOrder};
    obj.pharmacy = pharmacy;
    setMedicationOrder(obj);
    setShowPharmacySaveButton(true);
    setViewPharmacySearchModal(false);
  }

  const cancelPhysicianChanges = () => {
    const obj = {...medicationOrder};
    obj.physician = backupPhysician;
    setMedicationOrder(obj);
    setShowPhysicianSaveButton(false);
  }

  const cancelPharmacyChanges = () => {
    const obj = {...medicationOrder};
    obj.pharmacy = backupPharmacy;
    setMedicationOrder(obj);
    setShowPharmacySaveButton(false);
  }

  const onSavePharmacyConfirmed = async () => {
    setSavingPharmacy(true);
    const response =
      await updateMedicationOrderPharmacy(medicationOrder?.resident?._id, medicationOrder?._id, medicationOrder?.pharmacy?._id);
    if (!response?.error) {
      addNotification("Pharmacy updated", "success");
      setShowPharmacySaveButton(false);
    } else {
      addNotification("Unable to update pharmacy on this order", "error");
      cancelPharmacyChanges();
    }
    setSavingPharmacy(false);
  }

  const onSavePhysicianConfirmed = async () => {
    setSavingPhysician(true);
    const response =
      await updateMedicationOrderPhysician(medicationOrder?.resident?._id, medicationOrder?._id, medicationOrder?.physician?._id);
    if (!response?.error) {
      addNotification("Physician updated", "success");
      setShowPhysicianSaveButton(false);
    } else {
      addNotification("Unable to update physician on this order", "error");
      cancelPhysicianChanges();
    }
      setSavingPhysician(false);
  }

  const onAllergySelected = async selectedAllergies => {
    setBackupAllergy(medicationOrder?.allergies);
    const obj = {...medicationOrder};
    obj.allergies = selectedAllergies;
    setMedicationOrder(obj);
    setViewAllergySearchModal(false);
    setShowAllergySaveButton(true);
  }

  const cancelAllergyChanges = () => {
    const obj = {...medicationOrder};
    obj.allergies = backupAllergy;
    setMedicationOrder(obj);
    setShowAllergySaveButton(false);
  }

  const onSaveAllergyChange = async () => {
    setSavingAllergies(true);
    const allergies = medicationOrder?.allergies
    const response =
      await updateMedicationOrderAllergies(medicationOrder?.resident?._id, medicationOrder?._id, allergies);
    if (!response?.error) {
      addNotification("Physician updated", "success");
      setShowAllergySaveButton(false);
    } else {
      addNotification("Unable to update physician on this order", "error");
      cancelPhysicianChanges();
    }
    setSavingAllergies(false);
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  }

  const onHoldChange = order => {
    const obj = {...medicationOrder};
    if (!obj?.holds) {
      obj.holds = [];
    }
    obj.holds = order?.holds;
    setMedicationOrder(obj);
  }

  const handleCancelOrder = async () => {
    setUpdatingOrder(true);
    setViewCancelConfirmDialog(false);
    await save();
    setUpdatingOrder(false);
  }

  const handleCancelOrderCanceled = () => {
    const obj = {...medicationOrder};
    let status = orderStatuses?.find(o => o._id === original?.orderStatus?._id);
    obj.orderStatus = status;
    setMedicationOrder(obj);
    setViewCancelConfirmDialog(false);
  }


  return (
    <Paper elevation={24} className={classes.root}>
      <PageTitle title={`Medication Order - ${medicationOrder?.medication?.tradeName}`}>
        {medicationOrder?.orderStatus?.name === "Hold" &&
          <Alert severity={"warning"}>
            <Typography variant={"subtitle1"}>
              Medication Order On Hold
            </Typography>
            {medicationOrder?.hold?.startDate && medicationOrder?.hold?.endDate &&
              <Typography variant={"subtitle2"}>
                <span>{" "}
                  {moment(medicationOrder?.hold?.startDate).format("MM/DD/YYYY hh:mma")} -
                  {moment(medicationOrder?.hold?.endDate).format("MM/DD/YYYY hh:mma")}
                </span>
              </Typography>
            }
            {medicationOrder?.hold?.comment &&
              <div>
                <Typography variant={"subtitle2"}>
                  {medicationOrder?.hold?.comment}
                </Typography>
              </div>
            }
          </Alert>
        }
      </PageTitle>

      <Tabs
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        value={tabValue}
        onChange={handleTabChange}
      >

        <Tab value={"details"} label={"Details"} icon={<SettingsInputComponentSharp />}  />
        <Tab value={"schedule"} label={"Schedule"} icon={<DateRange />} />
        <Tab value={"hold"} label={"Holds"} icon={<PauseSharp />} />
        <Tab value={"history"} label={"History"} icon={<History />} />
      </Tabs>
      <MedicationSearchModal open={viewMedicationSearchModal}
                             onClose={() => setViewMedicationSearchModal(false)}
                             onSelect={onMedicationSelected}
      />
      <PhysicianLookup open={viewPhysicianSearchModal}
                       onClose={() => setViewPhysicianSearchModal(false)}
                       onSelect={onPhysicianSelected}
      />
      <PharmacyLookup open={viewPharmacySearchModal}
                      onClose={() => setViewPharmacySearchModal(false)}
                      onSelect={onPharmacySelected}
      />
      <AllergyLookup open={viewAllergySearchModal}
                     onClose={() => setViewAllergySearchModal(false)}
                     onSelect={onAllergySelected}
                     items={medicationOrder?.allergies}
      />
      {tabValue === "details" && medicationOrder && (
        <form>
          <Grid container>
            <Grid md={4} sm={12} xs={12}>
              <FormGroup>
                <TextField
                  size="small"
                  label="Medication"
                  disabled
                  InputProps={{
                    startAdornment:
                      <InputAdornment position="start">
                        {showMedicationSaveButton &&
                          <IconButton
                            size={"small"}
                          >
                            <SaveIcon fontSize={"small"}/>
                          </IconButton>
                        }
                        {!showMedicationSaveButton &&
                          <IconButton
                            size={"small"}
                            onClick={() => setViewMedicationSearchModal(true)}
                          >
                            <EditIcon fontSize={"small"}/>
                          </IconButton>
                        }
                      </InputAdornment>,
                  }}
                  value={medicationOrder?.medication?.tradeName || ''}
                />
                <TextField
                  size="small"
                  label="Strength"
                  disabled
                  value={`${medicationOrder?.medication?.strength} ${medicationOrder?.medication?.unit?.name}`}
                />
                <TextField
                  size="small"
                  label="Dosage Form"
                  disabled
                  value={medicationOrder.medication?.dosageForm?.name || ''}
                />
                {showMedicationSaveButton &&
                  <Alert severity={"warning"}>
                    <AlertTitle>Unsaved medication changes. Please press save to make changes.</AlertTitle>
                    <DialogActions>
                      <Button onClick={cancelMedicationChanges}>Cancel</Button>
                      <Button startIcon={!savingMedication ? <SaveIcon />: <CircularProgress size={20} />}
                              variant={"contained"}
                              onClick={onSaveMedicationConfirmed}
                              disabled={savingMedication}
                              color={"primary"}>Save
                      </Button>
                    </DialogActions>
                  </Alert>
                }
                <TextField disabled
                           fullWidth
                           label={"Physician"}
                           value={getPhysicianName(medicationOrder?.physician)}
                           InputProps={{
                             startAdornment:
                               <InputAdornment position="start">
                                 {showPhysicianSaveButton &&
                                   <IconButton
                                     size={"small"}
                                   >
                                     <SaveIcon fontSize={"small"}/>
                                   </IconButton>
                                 }
                                 {!showPhysicianSaveButton &&
                                   <IconButton
                                     size={"small"}
                                     onClick={() => setViewPhysicianSearchModal(true)}
                                   >
                                     <EditIcon fontSize={"small"}/>
                                   </IconButton>
                                 }
                               </InputAdornment>,
                           }}
                />
                {showPhysicianSaveButton &&
                  <Alert severity={"warning"}>
                    <AlertTitle>Unsaved medication changes. Please press save to make changes.</AlertTitle>
                    <DialogActions>
                      <Button onClick={cancelPhysicianChanges}>Cancel</Button>
                      <Button startIcon={!savingPhysician ? <SaveIcon />: <CircularProgress size={20} />}
                              disabled={savingPhysician}
                              variant={"contained"}
                              onClick={onSavePhysicianConfirmed}
                              color={"primary"}>Save
                      </Button>
                    </DialogActions>
                  </Alert>
                }
                <TextField disabled
                           fullWidth
                           label={"Pharmacy"}
                           value={medicationOrder?.pharmacy?.name}
                           InputProps={{
                             startAdornment:
                               <InputAdornment position="start">
                                 {showPharmacySaveButton &&
                                   <IconButton
                                     size={"small"}
                                   >
                                     <SaveIcon fontSize={"small"}/>
                                   </IconButton>
                                 }
                                 {!showPharmacySaveButton &&
                                   <IconButton
                                     size={"small"}
                                     onClick={() => setViewPharmacySearchModal(true)}
                                   >
                                     <EditIcon fontSize={"small"}/>
                                   </IconButton>
                                 }
                               </InputAdornment>,
                           }}
                />
                {showPharmacySaveButton &&
                  <Alert severity={"warning"}>
                    <AlertTitle>Unsaved medication changes. Please press save to make changes.</AlertTitle>
                    <DialogActions>
                      <Button onClick={cancelPharmacyChanges}>Cancel</Button>
                      <Button startIcon={!savingPharmacy ? <SaveIcon />: <CircularProgress size={20} />}
                              disabled={savingPharmacy}
                              variant={"contained"}
                              onClick={onSavePharmacyConfirmed}
                              color={"primary"}>Save
                      </Button>
                    </DialogActions>
                  </Alert>
                }
                <FormControl>
                  <InputLabel>Order Status</InputLabel>
                  <Select
                    onChange={onStatusChange}
                    value={
                      medicationOrder?.orderStatus
                        ? medicationOrder?.orderStatus?._id
                        : ''
                    }
                    displayEmpty
                    disabled={!canEdit}
                  >
                    <MenuItem disabled>Select Status</MenuItem>
                    {orderStatuses?.map((status) => (
                      <MenuItem value={status._id}>{status.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {medicationOrder?.orderStatus?.name === "Discontinued" &&

                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      size="small"
                      fullWidth
                      name={"discontinuedDate"}
                      disabled={!canEdit}
                      value={medicationOrder?.discontinuedDate}
                      label={"Discontinued Date"}
                      format="MM/DD/YYYY"
                      onChange={onDiscontinuedDateChange}
                      allowKeyboardControl="true"
                      ampm={false}
                    />
                  </MuiPickersUtilsProvider>
                }


                <TextField label={"Allergies"}
                           disabled
                           InputProps={{
                             startAdornment:
                               <InputAdornment position="start">
                                 {showAllergySaveButton &&
                                   <IconButton
                                     size={"small"}
                                   >
                                     <SaveIcon fontSize={"small"}/>
                                   </IconButton>
                                 }
                                 {!showAllergySaveButton &&
                                   <IconButton
                                     size={"small"}
                                     onClick={() => setViewAllergySearchModal(true)}
                                   >
                                     <EditIcon fontSize={"small"}/>
                                   </IconButton>
                                 }
                               </InputAdornment>,
                           }}
                           value={medicationOrder?.allergies?.map(allergy => allergy.name).join(', ')}
                />

                {showAllergySaveButton &&
                  <Alert severity={"warning"}>
                    <AlertTitle>Unsaved medication changes. Please press save to make changes.</AlertTitle>
                    <DialogActions>
                      <Button onClick={cancelAllergyChanges}>Cancel</Button>
                      <Button startIcon={!savingAllergies ? <SaveIcon />: <CircularProgress size={20} />}
                              disabled={savingAllergies}
                              variant={"contained"}
                              onClick={onSaveAllergyChange}
                              color={"primary"}>Save
                      </Button>
                    </DialogActions>
                  </Alert>
                }
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={4} className={classes.formGroupContainer}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      name="isQtyEditable"
                      checked={medicationOrder?.isQtyEditable}
                      onChange={updateChecked}
                      disabled={!canEdit}
                    />
                  }
                  label="Qty Editable"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      name="isMaintainInventory"
                      checked={medicationOrder?.isMaintainInventory}
                      onChange={updateChecked}
                      disabled={!canEdit}
                    />
                  }
                  label="Maintain Inventory"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      name="chartVitals"
                      checked={medicationOrder?.chartVitals}
                      onChange={updateChartVitalsChecked}
                      disabled={!canEdit}
                    />
                  }
                  label="Chart Vitals"
                />
                {medicationOrder?.chartVitals &&
                <FormControl>
                  <InputLabel shrink
                              required>Vitals</InputLabel>
                  <Select fullWidth
                          multiple
                          value={medicationOrder?.chartVitalsList}
                          input={<Input />}
                          onChange={onChartVitalsListChange}
                          renderValue={selected => selected?.join(', ')}
                          MenuProps={MenuProps}
                  >
                    {vitals?.map(vital =>
                    <MenuItem value={vital} key={vital}>
                      <Checkbox checked={medicationOrder?.chartVitalsList?.indexOf(vital) > -1} />
                      <ListItemText primary={vital} />
                    </MenuItem>
                    )}

                  </Select>
                </FormControl>
                }
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      name="marEnabled"
                      checked={medicationOrder?.marEnabled}
                      onChange={updateChecked}
                      disabled={!canEdit}
                    />
                  }
                  label="MAR Enabled"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      name="isPrn"
                      checked={medicationOrder?.isPrn}
                      onChange={updateChecked}
                      disabled={!canEdit}
                    />
                  }
                  label="IS PRN"
                />
                {!medicationOrder?.chartVitals &&
                <FormControl>
                  <InputLabel>Default Quick Status</InputLabel>
                  <Select
                    onChange={onQuickStatusChange}
                    value={
                      medicationOrder?.defaultQuickStatus ?? ''
                    }
                    displayEmpty
                    disabled={!canEdit}
                  >
                    <MenuItem disabled>Select Default Quick Status</MenuItem>
                    <MenuItem value={'Given'}>Given</MenuItem>
                    <MenuItem value={'Late'}>Late</MenuItem>
                    <MenuItem value={'Missed'}>Missed</MenuItem>
                    <MenuItem value={'Hold'}>Hold</MenuItem>
                    <MenuItem value={'SA'}>Self Administered</MenuItem>
                    <MenuItem value={'NA'}>Not Administered</MenuItem>
                    <MenuItem value={'Refused'}>Refused</MenuItem>
                  </Select>
                  <FormHelperText>Status used for quick actions.</FormHelperText>
                </FormControl>}
                <TextField
                  size="small"
                  label="Rx"
                  value={medicationOrder?.rxNumber || ''}
                  name="rxNumber"
                  onChange={textChange}
                  disabled={!canEdit}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={4} className={classes.formGroupContainer}>
              <FormGroup>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    size="small"
                    fullWidth
                    name={"orderDate"}
                    disabled={!canEdit}
                    value={medicationOrder?.orderDate}
                    label={"Order Date"}
                    format="MM/DD/YYYY"
                    onChange={onOrderDateChange}
                    allowKeyboardControl="true"
                    ampm={false}
                  />
                </MuiPickersUtilsProvider>
                <TextField
                  size="small"
                  label="Order Qty"
                  value={medicationOrder?.orderQty || ''}
                  name="orderQty"
                  onChange={textChange}
                  disabled={!canEdit}
                />
                <TextField
                  size="small"
                  label="Max Refills"
                  value={medicationOrder?.maxRefills || ''}
                  name="maxRefills"
                  onChange={textChange}
                  disabled={!canEdit}
                />
                <TextField
                  size="small"
                  label="Refill Notification Threshold"
                  value={medicationOrder?.refillNotificationThreshold || ''}
                  name="refillNotificationThreshold"
                  onChange={textChange}
                  disabled={!canEdit}
                />
                <TextField
                  size="small"
                  label="Medication Alert"
                  value={medicationOrder?.alerts || ''}
                  name="alerts"
                  onChange={textChange}
                  disabled={!canEdit}
                />
                <TextField
                  size="small"
                  label="Pill Description"
                  value={medicationOrder.pillDescription || ''}
                  name="pillDescription"
                  onChange={textChange}
                  disabled={!canEdit}
                />
                <TextField
                  size="small"
                  label="Direction"
                  value={medicationOrder?.direction || ''}
                  name="direction"
                  onChange={textChange}
                  disabled={!canEdit}
                />
                <TextField
                  size="small"
                  label="Indication"
                  value={medicationOrder?.indication || ''}
                  name="indication"
                  onChange={textChange}
                  disabled={!canEdit}
                />
              </FormGroup>
            </Grid>
          </Grid>
          {canEdit &&
            <DialogActions>
              <Grid container justify={"space-between"}>
                <div>
                  {canDelete &&
                    <DeleteBtn buttonText={"Delete Order"}
                               onClick={() => setViewDeleteConfirmModal(true)}
                    />
                  }
                </div>
                <div>
                  <Button onClick={cancelOrderEdits}>Cancel</Button>
                  <Button variant="contained"
                          color="primary"
                          onClick={saveOrder}
                          disabled={showMedicationSaveButton ||
                              showPhysicianSaveButton ||
                              showPharmacySaveButton ||
                              showAllergySaveButton ||
                              updatingOrder}
                          startIcon={updatingOrder ? <CircularProgress size={20} />: null}
                  >
                    Save
                  </Button>
                </div>
              </Grid>
            </DialogActions>
          }
        </form>
      )}
      {tabValue === "schedule" && !medicationOrder?.isPrn && (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  {medicationOrder?.orderStatus?.name === 'Discontinued' &&
                  <Tooltip title={
                    <Typography variant={"subtitle2"}>
                      Unable to edit or create dosage instructions when order is discontinued
                    </Typography>
                  }>
                    <WarningTwoTone className={classes.infoTooltip} />
                  </Tooltip>
                  }
                </TableCell>
                <TableCell>Frequency</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <Hidden mdDown>
                  <TableCell>Times</TableCell>
                </Hidden>
              </TableRow>
            </TableHead>

            <TableBody>
              {medicationOrder?.dosageInstructions?.sort((a,b) => a.startDate - b.startDate)?.map((dosageInstruction) => {
                  return (
                    <TableRow>
                      <TableCell>
                        {medicationOrder?.orderStatus?.name !== 'Discontinued' && canEdit &&
                        <>
                          <DeleteIcon
                            onClick={() =>
                              removeDosageInstruction(dosageInstruction)
                            }
                          />
                          <EditIcon
                            onClick={() =>
                              editDosageInstruction(dosageInstruction)
                            }
                          />
                        </>
                        }
                      </TableCell>
                      <TableCell>
                        {dosageInstruction.frequency
                          ? `${dosageInstruction.frequency.name} (${dosageInstruction.frequency.description})`
                          : null}
                      </TableCell>
                      <TableCell>
                        {moment(dosageInstruction.startDate).format(
                          'MM/DD/YYYY'
                        )}
                      </TableCell>
                      <TableCell>
                        {moment(dosageInstruction.endDate).format('MM/DD/YYYY')}
                      </TableCell>
                      <Hidden mdDown>
                        <TableCell>
                          {dosageInstruction && (
                            <EventTemplate
                              frequency={dosageInstruction.frequency}
                              eventTemplates={dosageInstruction.eventTemplates}
                            />
                          )}
                        </TableCell>
                      </Hidden>
                    </TableRow>
                  );
                })}
              {medicationOrder?.orderStatus?.name !== 'Discontinued' && canEdit
                  && medicationOrder?.orderStatus?.name !== "Canceled" &&
              <TableRow>
                <TableCell colSpan="4">
                  <Button size="small" startIcon={<AddIcon />} onClick={() => setOpenAddDosageInstructioModal(true)}>Add New</Button>
                </TableCell>
              </TableRow>
              }
            </TableBody>
          </Table>

          <EditDosageInstructionModal
            open={openFrequencyModal}
            dosageInstruction={newDosageInstruction}
            frequencies={frequencies}
            onClose={() => setOpenFrequencyModel(false)}
            onSave={addDosageInstruction}
            existing={true}
          />
          <AddDosageInstructionModal open={openAddDosageInstructioModal}
                                     onClose={() => setOpenAddDosageInstructioModal(false)}
                                     frequencies={frequencies}
                                     onSave={onDosageInstructionAdded}
          />
          <Dialog open={confirmDeleteDosageInstruction}>
            <DialogTitle>{'Delete dosage instruction.'}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                By deleting dosage instruction you will delete any past, current and future scheduled medication events.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={cancelDeleteDosageInstruction}>Cancel</Button>
              <Button
                onClick={onDeleteDosageInstruction}
                variant="contained"
                color="primary"
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </TableContainer>
      )}
      <Dialog open={confirmDiscontinueChange}>
        <DialogTitle>
          {'Medication order discontinue confirmation.'}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            Please enter the date you want to discontinue this order. Once a
            date is selected, you can confirm. By confirming, all future
            scheduled medications, after the discontinue date, will be
            removed.
          </DialogContentText>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              fullWidth
              label="Discontinue Date"
              format="MM/DD/YYYY"
              value={discontinueDate}
              onChange={onDiscontinueDateChange}
            />
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDiscontinueChange(false)}>
            Cancel
          </Button>
          <Button
            onClick={onConfirmDiscontinueChange}
            variant="contained"
            color="primary"
            disabled={!discontinueDate}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmDialog title={"Cancel Order"}
                     message={"When canceling an order, all historical charting will be lost."}
                     open={viewCancelConfirmDialog}
                     onClose={handleCancelOrderCanceled}
                     onConfirm={handleCancelOrder}
                     confirmText={"Cancel Order"}
                     displayConfirmButton={true}
                     />

      <Dialog open={confirmDirectionChange}>
        <DialogTitle>
          {'Changes will overwrite the original directions.'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Changes will overwrite the original directions. If you are good
            with this, please accept, otherwise cancel.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDirectionUpdate}>Cancel</Button>
          <Button
            onClick={confirmDirectionUpdate}
            variant="contained"
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={viewDeleteConfirmModal}>
        <DialogTitle>
          {"Delete Medication Order"}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>{"Do you want to delete this medication order? Please confirm to delete."}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setViewDeleteConfirmModal(false)}>Cancel</Button>
          <Button onClick={onDeleteOrderConfirmed}
                  className={classes.deleteBtn}
                  startIcon={<DeleteForeverTwoToneIcon />}
                  variant={"contained"}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {tabValue === "hold" && <MedicationOrderHolds medicationOrder={medicationOrder} onHoldChange={onHoldChange} />}
      {tabValue === "history" && <MedicationOrderHistory id={medicationOrder?._id} />}
    </Paper>
  );
};

export default MedicationOrder;
