import React from 'react';
import {
  Button,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { ObjectId } from 'bson';
import moment from 'moment';
import AddIcon from '@material-ui/icons/Add';
import Meal from './meal.model';
import {EditMeal} from "./edit.meal";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
  },
}));

const DisplayMetaData = ({ meal }) => {
  if (
    meal.frequency === 'QD' &&
    meal.templates &&
    meal.templates.length === 1
  ) {
    const template = meal.templates[0];
    return (
      <>
        <div>
          {moment(template.time).format('h:mma')} {template.comments}
        </div>
      </>
    );
  } else {
    return 'n/a';
  }
};

const Meals = ({ meals, onChange, onRemove }) => {
  const classes = useStyles();
  const [showAddMeal, setShowAddMeal] = React.useState(false);
  const [meal, setMeal] = React.useState(JSON.parse(JSON.stringify(Meal)));

  const onEditDiet = (e) => {
    const obj = JSON.parse(JSON.stringify(Meal));
    obj._id = { value: e._id, hidden: true };
    obj.type.value = e.type;
    obj.frequency.value = e.frequency;
    obj.templates.value = e.templates;
    setMeal(obj);
    setShowAddMeal(true);
  };

  const onAddDiet = (e) => {
    const obj = JSON.parse(JSON.stringify(Meal));
    obj._id = { value: new ObjectId(), hidden: true };
    setMeal(obj);
    setShowAddMeal(true);
  };

  const saveTemplate = payload => {
    const data = {
      _id: payload._id.value,
      type: payload.type.value,
      frequency: payload.frequency.value,
      templates: payload.templates.value,
    };
    onChange(data);
    setShowAddMeal(false);
  };

  const sortMeals = (a, b) => {
    if (a?.templates?.length > 0 && b?.templates?.length > 0) {
      const templateA = a?.templates[0];
      const templateB = b?.templates[0];
      return templateA?.time > templateB?.time ? 1 : -1;
    } else
    return 1;
  }

  return (
    <TableContainer className={classes.root}>
      <EditMeal open={showAddMeal}
                onClose={() => setShowAddMeal(false)}
                onSave={saveTemplate}
                meal={meal}
                />
      <Typography variant="h6">Meals</Typography>
      <Divider />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Meal</TableCell>
            <TableCell>FrequencyAA</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {meals?.sort(sortMeals)?.map((m) => (
              <TableRow>
                <TableCell>
                  <Button color="primary" onClick={() => onEditDiet(m)}>
                    View
                  </Button>
                  <Button color="primary" onClick={() => onRemove(m._id)}>
                    Remove
                  </Button>
                </TableCell>
                <TableCell>{m.type}</TableCell>
                <TableCell>{m.frequency}</TableCell>
                <TableCell>
                  <DisplayMetaData meal={m} />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={6}>
              <Button
                size="small"
                startIcon={<AddIcon />}
                color="primary"
                onClick={onAddDiet}
              >
                Add
              </Button>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>

    </TableContainer>
  );
};

export default Meals;
