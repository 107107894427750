import React from 'react';
import {
  Collapse,
  Grid, List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  makeStyles, Paper,
  Typography
} from "@material-ui/core";
import moment from "moment";
import {getAgenda} from "../../../../../services/eschedule.service";
import {UserContext} from "../../../../../context/usercontext";
import {ListLoading} from "../../../../../utils/indicators/list.loading";
import {Link} from "react-router-dom";
import * as _ from 'lodash';
import {blue, green, grey, orange, red, yellow} from "@material-ui/core/colors";
import useHasRole from "../../../../../hooks/user.role.hook";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {FiberManualRecord} from "@material-ui/icons";
import {ObjectId} from "bson";


const contrast = 400;
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 1),
    borderWidth: '1px',
    borderColor: theme.palette.grey[500],
    borderStyle: 'solid',
    margin: theme.spacing(1)
  },
  listRoot: {
  },
  scheduled: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: grey[500],
  },
  readyToPass: {
    background: grey[700],
  },
  readyToPassIcon: {
    color: grey[700]
  },
  given: {
    background: green[contrast],
  },
  givenIcon: {
    color: green[contrast]
  },
  late: {
    background: orange[contrast],
  },
  lateIcon: {
    color: orange[contrast]
  },
  missed: {
    background: red[contrast],
  },
  missedIcon: {
    color: red[contrast]
  },
  hold: {
    background: yellow[contrast],
  },
  holdIcon: {
    color: yellow[contrast]
  },
  sa: {
    background: blue[contrast],
  },
  saIcon: {
    color: blue[contrast]
  },
  na: {
    background: grey[contrast],
  },
  naIcon: {
    color: grey[contrast]
  },
  refused: {
    background: orange[contrast],
  },
  refusedIcon: {
    color: orange[contrast]
  },
  destruction: {
    background: yellow[contrast],
  },
  destructionIcon: {
    color: yellow[contrast]
  },

}))

const EmarList = ({date, toggle, onResidentStatusChangeEvent, expand = true}) => {
  const classes = useStyles();
  const {selectedTenant, selectedTenantLocation} = React.useContext(UserContext);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [emars, setEmars] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(1000);
  const {checkPermission} = useHasRole();
  const hasEditPermission = checkPermission('EMAR_UPDATE');
  const {roles} = React.useContext(UserContext);
  const refs = React.useRef()

  React.useEffect(() => {
    if (refs?.current) {
      refs.current.scrollIntoView(false, {behavior: 'auto', block: 'end' });
    }
    // eslint-disable-next-line
  }, [refs?.current]);

  React.useEffect(() => {
    if (onResidentStatusChangeEvent && onResidentStatusChangeEvent?._id) {
      updateEmar(onResidentStatusChangeEvent)
    }
    // eslint-disable-next-line
  },[onResidentStatusChangeEvent])

  React.useEffect(() => {
    if (expand && date && selectedTenantLocation && selectedTenant) {
      getEvents(date, page, rowsPerPage);
    } else {
      setEmars([]);
    }
    // eslint-disable-next-line
  }, [ expand, date, selectedTenant, selectedTenantLocation,  ]);

  const getEvents = async (dt, pg = 0, size = 1000) => {
    setLoading(true)
    setError(false);
    setEmars({});
    const start = moment(dt).startOf('day').unix();
    const end = moment(dt).endOf('day').unix();
    const events = await getAgenda(start,
      end,
      pg,
      size,
      'emar',
      true,
      selectedTenant?._id,
      selectedTenantLocation?._id);
    if (events?.error) {
      setError(true);
      setEmars([]);
    } else {
      setUpList(events?.content)
      setPage(parseInt(events.paginator.currentPage, 0));
      setRowsPerPage(parseInt(events.paginator.perPage, 0));
    }
    setLoading(false)
  };

  const setUpList = (data) => {
    let list = _(data).groupBy('scheduledTime')
                .map((value, key) => ({
                  scheduledTime: key,
                  emars: value,
                  id: new ObjectId(),
                  scrollTo: moment(key) >= moment().subtract(1, 'hour') && moment(key) <= moment().add(1, 'hour'),
                  expanded: moment(key) >= moment().subtract(1, 'hour') && moment(key) <= moment().add(1, 'hour')
                })).value();
    setEmars(list);
  }

  const updateEmar = emarEvent => {
    if (Array.isArray(emars)) {
      const data = emars?.map(group => {
        group.emars = group?.emars.map(emar => {
          if (emar?._id === emarEvent?._id) {
            emar.status = {name: emarEvent?.status}
          }
          return emar;
        });
        return group;
      });
      setEmars(data);
    }
  }

  const getLabel = emar => {
    return (
      <Typography variant={"subtitle2"}>
        {moment(emar?.scheduledTime).format('h:mma')}{" "}
        {emar?.resident?.firstName}{" "}
        {emar?.resident?.lastName}{" "}
        {emar?.resident?.roomNumber || ''}
      </Typography>
    )
  }

  const canEdit = (event) => {
    if (!hasEditPermission) {
      return false;
    }
    const current = moment();
    const start = moment(event.startWindow);
    const end = moment(event.endWindow);

    let updateMissedMedicationPermissions = false;
    if (roles) {
      updateMissedMedicationPermissions =
        roles.filter(
          (userRole) =>
            userRole.role.permissions.indexOf('UPDATE_MISSED_MEDICATION') > -1
        ).length > 0;
    }
    if (updateMissedMedicationPermissions && current >= start) {
      return true;
    } else if (current >= start && current < end) {
      return true;
    } else {
      return false;
    }
  };

  const getItemIconClass = event => {
    if (event?.status?.name) {
      switch (event.status.name) {
        case 'Scheduled':
          if (canEdit(event)) {
            return classes.readyToPassIcon;
          } else {
            return classes.scheduledIcon;
          }
        case 'Given':
          return classes.givenIcon;
        case 'Late':
          return classes.lateIcon;
        case 'Missed':
          return classes.missedIcon;
        case 'Hold':
          return classes.holdIcon;
        case 'SA':
          return classes.saIcon;
        case 'NA':
          return classes.naIcon;
        case 'Refused':
          return classes.refusedIcon;
        case 'Destruction':
          return classes.destructionIcon;
        default:
          return classes.scheduledIcon;
      }
    }
  }

  const toggleGroupExpand = group => {
    const data = emars?.map(emar => {
      if (emar?.scheduledTime === group?.scheduledTime) {
        if (!emar?.expanded) {
          emar.expanded = true
        } else {
          emar.expanded = false
        }
      }
      return emar;
    })
    setEmars(data);
  }

  return (
      <Paper elevation={15} className={classes.root}>
        <Grid container justify={"center"} alignItems={"center"}>
          <Typography variant={"subtitle1"}>Resident MAR List</Typography>
        </Grid>
        {!error && loading &&
        <div>
          <ListLoading rows={5} />
        </div>
        }
        <List className={classes.listRoot}>
        {expand && !loading && !error && emars?.map(group =>
          <>
            {
              moment(group?.key) >= moment().subtract(1, 'hour')
              && moment(group?.key) <= moment().add(1, 'hour') &&
              <div ref={refs}></div>
            }
            <ListItem divider key={group?.scheduledTime}
                      button
                      inputRef={el => this.inputElement = el}
                      onClick={() => toggleGroupExpand(group)}>
              <ListItemIcon>
                {group?.expanded ? <ExpandLess />: <ExpandMore />}
              </ListItemIcon>
              <ListItemText primary={`${moment(group?.scheduledTime).format('h:mma')}`} />
            </ListItem>
            <Collapse in={group?.expanded}>
              <List>
                {group?.emars?.map(emar =>
                  <ListItem component={Link} to={`/residents/${emar?.resident?._id}/emar`} divider key={emar?._id} button>
                    <ListItemAvatar>
                      <FiberManualRecord className={getItemIconClass(emar)}/>
                    </ListItemAvatar>
                    <ListItemText secondary={getLabel(emar)} />
                  </ListItem>)}
              </List>
            </Collapse>
            </>
        )}
        </List>
      </Paper>
  )
}

export default EmarList;
