import React from 'react';
import {
  Paper,
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogActions,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  DialogContent,
  Divider,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { createTenant, getTenants } from '../../services/tenant.service';
import { Link } from 'react-router-dom';
import Transition from '../dialog/transition';
import Model from './tenant.model';
import { FormFieldsGenerator, isModelValid } from '../../utils/forms';
import useHasRole from "../../hooks/user.role.hook";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0, 1, 0),
    padding: theme.spacing(1),
  },
  dialogContainer: {
    paddingTop: '4rem',
  },
  formContainer: {
    padding: theme.spacing(2),
  },
}));

const TenantList = () => {
  const [tenants, setTenants] = React.useState([]);
  const [showCreateTenant, setShowCreateTenant] = React.useState(false);
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(Model)));
  const classes = useStyles();
  const { checkPermission } = useHasRole();
  const canCreate = checkPermission('LOCATION_CREATE');


  React.useEffect(() => {
    getData();
  }, []);

  React.useEffect(() => {
    if (showCreateTenant) {
      setModel(JSON.parse(JSON.stringify(Model)));
    }
  }, [showCreateTenant]);

  const getData = async () => {
    const data = await getTenants();
    setTenants(data);
  };

  const onChange = (e) => {
    setModel({ ...model, ...e });
  };

  const onCreateTenant = async () => {
    const data = {
      name: model.name.value,
      email: model.email.value,
      medicaid: model.medicaid.value,
      medicare: model.medicare.value,
      tin: model.tin.value,
      isActive: model.isActive.value,
      address: {
        address: model.address.value,
        address2: model.address2.value,
        city: model.city.value,
        state: model.state.value,
        zipCode: model.state.value,
      },
    };

    const newItem = await createTenant(data);
    setTenants([...tenants, newItem]);
    setShowCreateTenant(false);
  };

  return (
    <Paper elevation={24} className={classes.root}>
        {canCreate &&
        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setShowCreateTenant(true)}
        >
          Create Tenant
        </Button>}
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Name</TableCell>
              <TableCell>Medicaid</TableCell>
              <TableCell>Medicare</TableCell>
              <TableCell>TIN</TableCell>
              <TableCell>Is Active</TableCell>
              <TableCell>Address</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tenants &&
              tenants.map((tenant) => (
                <TableRow>
                  <TableCell>
                    <Button
                      component={Link}
                      to={`/tenants/${tenant._id}`}
                      color="primary"
                    >
                      View
                    </Button>
                  </TableCell>
                  <TableCell>{tenant.name}</TableCell>
                  <TableCell>{tenant.medicaid}</TableCell>
                  <TableCell>{tenant.medicare}</TableCell>
                  <TableCell>{tenant.tin}</TableCell>
                  <TableCell>{tenant.isActive ? 'Yes' : 'No'}</TableCell>
                  <TableCell>
                    {tenant.address && (
                      <span>
                        {tenant.address.address} {tenant.address.address2}{' '}
                        {tenant.address.city} {tenant.address.state}{' '}
                        {tenant.address.zipCode}{' '}
                      </span>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={showCreateTenant}
        TransitionComponent={Transition}
        fullScreen
        onClose={() => setShowCreateTenant(false)}
        className={classes.dialogContainer}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setShowCreateTenant(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Create Tenant
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.formContainer}>
          <form noValidate>
            <FormFieldsGenerator model={model} onChange={onChange} />
          </form>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button color="primary" onClick={() => setShowCreateTenant(false)}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={onCreateTenant}
            disabled={!isModelValid(model)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default TenantList;
