/* eslint-disable no-unused-vars */
// src/App.js

import React from 'react';
import {createTheme, ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import {CssBaseline} from '@material-ui/core';
import {useAuth0} from './react-auth0-spa';
import {UserContextProvider,} from './context/usercontext';
import Layout from './components/layout/layout';
import IdleTimer from 'react-idle-timer';
import {createLoginActivityRecord} from './services/login.service';
import {NavigationProvider} from "./context/NavigationContext";
import {LandingPage, LandingPageWrapper} from "./components/layout/landing.page";
import { Beforeunload } from 'react-beforeunload';
import { initAnalytics } from './utils/analytics';
import configs from "./utils/configs";
import GlobalLoadingIndicatorProvider from "./context/GlobalLoadingIndicatorContext";
import GlobalNotificationProvider from "./components/notification/globalnotificationprovider";
import GlobalNotification from "./components/notification/global.notification";

const theme = createTheme({
  palette: {
    primary: blue,
    secondary: {
      main: '#4caf50',
    },
  }
});

const App = () => {
  const {user, getTokenSilently, logout} = useAuth0();
  let idleTimer = null;

  React.useEffect(() => {
    if (configs.analytics.enabled) {
      initAnalytics();
    }
  }, []);

  const onIdle = async () => {
    await createLoginActivityRecord('logout - timed out', user);
    logout({
      returnTo: window.location.origin,
    });
  };

  const catchUnload = async event => {
    await logout({federated: true});
  }

  return (
    <GlobalLoadingIndicatorProvider>
      <GlobalNotificationProvider>
        <NavigationProvider>
          <UserContextProvider user={user} tokenGetter={getTokenSilently}>
            {user &&
              <Beforeunload onBeforeunload={(e) => catchUnload(e)} >
                <div className="App">
                  {user && (
                    <div>
                      <IdleTimer
                        ref={(ref) => {
                          idleTimer = ref;
                        }}
                        element={document}
                        onIdle={onIdle}
                        debounce={10}
                        timeout={900000}
                      />
                    </div>
                  )}
                  <Layout/>
                </div>
              </Beforeunload>}
            {!user && <LandingPageWrapper /> }
          </UserContextProvider>
        </NavigationProvider>
        <GlobalNotification />
      </GlobalNotificationProvider>
    </GlobalLoadingIndicatorProvider>
  );
}

export default App;
