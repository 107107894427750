import React from 'react';
import {
  Paper,
  makeStyles,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TablePagination,
} from '@material-ui/core';
import { getUserLogins } from '../../../services/user.service';
import moment from 'moment';
import UserLoginDialog from './user.login.dialog';
import {getStripedStyle} from "../../utils/table.utils";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    margin: theme.spacing(1, 0),
  },
}));

const UserLogins = ({ user }) => {
  const classes = useStyles();
  const [logins, setLogins] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [viewLoginInfo, setViewLoginInfo] = React.useState(false);
  const [selectedLogin, setSelectedLogin] = React.useState();

  React.useEffect(() => {
    if (user) {
      getData(user._id);
    }
  }, [user]);

  const getData = async (id, pg = 0, pageSize = 25) => {
    setLoading(true);
    const response = await getUserLogins(id, pg, pageSize);
    setLogins(response?.content);
    setPage(response?.paginator?.currentPage - 1);
    setRowsPerPage(response?.paginator?.perPage);
    setTotalCount(response?.paginator?.totalItems);
    setLoading(false);
  };

  const handleChangePage = (e, newPage) => {
    getData(user._id, newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPage);
    getData(user._id, page, newRowsPage);
  };

  const selectLogin = (login) => {
    setSelectedLogin(login);
    setViewLoginInfo(true);
  };

  return (
    <Paper elevation={24} className={classes.root}>
      <Typography variant="h6">{user?.name} Logins</Typography>
      {loading && <Typography variant="h4">Loading...</Typography>}
      {!loading && (
        <TableContainer>
          <TablePagination
            component="div"
            count={totalCount}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 15, 25, 50, 100, 250]}
          />
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Action</TableCell>
                <TableCell>IP Address</TableCell>
                <TableCell>City</TableCell>
                <TableCell>Lat</TableCell>
                <TableCell>Long</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logins &&
                logins.map((login, index) => (
                  <TableRow key={login?._id}
                            style={getStripedStyle(index)}
                  >
                    <TableCell>
                      <Button
                        size="small"
                        color="primary"
                        onClick={() => selectLogin(login)}
                      >
                        View
                      </Button>
                    </TableCell>
                    <TableCell>
                      {moment(login.createdAt).format('MM/DD/YYYY h:mm a')}
                    </TableCell>
                    <TableCell>{login.action}</TableCell>
                    <TableCell>{login?.geoIp?.ip}</TableCell>
                    <TableCell>{login?.geoIp?.city_name}</TableCell>
                    <TableCell>{login?.geoIp?.latitude}</TableCell>
                    <TableCell>{login?.geoIp?.longitude}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <UserLoginDialog
        open={viewLoginInfo}
        onClose={() => setViewLoginInfo(false)}
        login={selectedLogin}
      />
    </Paper>
  );
};

export default UserLogins;
