import React from 'react';
import {
  Dialog,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  DialogContent,
  Link,
  makeStyles,
  DialogActions,
} from '@material-ui/core';
import Transition from '../dialog/transition';
import CloseIcon from '@material-ui/icons/Close';
import FormFieldsGenerator from '../../utils/forms/form.fields.generator';
import { getAllAddressTypes } from '../../services/address.service';
import {stateProvidenceList} from "../address/providencesAndStates";

const modelTemplate = {
  name: {
    title: 'Name',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  address: {
    title: 'Address',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  address2: {
    title: 'Address 2',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  city: {
    title: 'City',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  state: {
    title: 'State',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: stateProvidenceList,
    dropDownItemValue: "abbreviation",
    dropDownItemLabel: "name",
    isStateProvidence: true
  },
  zipCode: {
    title: 'Zip',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false
  },
  addressType: {
    title: 'Address Type',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [],
    dropDownItemValue: '_id',
    dropDownItemLabel: 'name',
    date: false,
  },
  phone: {
    title: 'Phone Number',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'id',
    dropDownItemLabel: 'name',
    date: false,
    inputMask: 'phone',
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    paddingTop: '4rem',
  },
  dialogContent: {
    marginTop: '4rem',
  },
}));

const CreatePharmacy = ({ open, onClose, onSave }) => {
  const classes = useStyles();
  const [model, setModel] = React.useState(
    JSON.parse(JSON.stringify(modelTemplate))
  );
  const [types, setTypes] = React.useState([]);

  React.useEffect(() => {
    if (open) {
      initForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const initForm = async () => {
    const obj = JSON.parse(JSON.stringify(modelTemplate));
    if (!types || types.length === 0) {
      const allTypes = await getAllAddressTypes();
      obj.addressType.dropDownItems = allTypes;
      setTypes(allTypes);
    } else {
      obj.addressType.dropDownItems = types;
    }
    setModel(obj);
  };

  const onChange = (e) => {
    const obj = { ...model, ...e };
    setModel(obj);
  };
  const save = () => {
    const data = {
      name: model.name.value,
      address: {
        address: model.address.value,
        address2: model.address2.value,
        city: model.city.value,
        state: model.state.value,
        zipCode: model.zipCode.value,
        addressType: model.addressType.value,
      },
      phone: { number: model.phone.value },
    };
    onSave(data);
  };

  const isValid = () => {
    let valid = true;
    Object.keys(model).forEach((key) => {
      if (model[key].required && !model[key].value) {
        valid = false;
      }
    });
    return valid;
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => onClose()}
      TransitionComponent={Transition}
    >
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => onClose()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Create Pharmacy</Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.dialogContent}>
        <form>
          <FormFieldsGenerator model={model} onChange={onChange} />
        </form>
      </DialogContent>
      <DialogActions>
        <Link onClick={onClose}>Cancel</Link>
        <Button
          disabled={!isValid()}
          onClick={save}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreatePharmacy;
