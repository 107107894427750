import tzMoment from 'moment-timezone';
import {stateProvidenceList} from "../address/providencesAndStates";

const model = {
  name: {
    title: 'Name',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  email: {
    title: 'Email',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  stateFacilityNumber: {
    title: 'State Facility Number',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  billingEnabled: {
    title: 'Billing',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    isToggle: true,
  },
  isActive: {
    title: 'Is Active',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    isToggle: true,
  },
  address: {
    title: 'Address',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  address2: {
    title: 'Address Line 2',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  city: {
    title: 'City',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  state: {
    title: 'State',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: stateProvidenceList,
    dropDownItemValue: "abbreviation",
    dropDownItemLabel: "name",
    isStateProvidence: true
  },
  zipCode: {
    title: 'Zip Code',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false
  },
  phones: {
    title: 'Phones',
    hidden: true,
    required: false,
    value: [],
    dirty: false,
    isValid: true,
  },
  timeZone: {
    title: 'Time Zone',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: tzMoment.tz.names().map((zone) => ({ name: zone })),
    dropDownItemValue: 'name',
    dropDownItemLabel: 'name',
    date: false,
  },
};

export default model;
