import React from "react";
import {
  ResidentPharmaciesProvider,
  ResidentPharmaciesContext,
} from "./resident.pharmacies.context";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import RadioButtonUncheckedRoundedIcon from "@material-ui/icons/RadioButtonUncheckedRounded";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import AddIcon from "@material-ui/icons/Add";
import {
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Typography,
  TableContainer,
  Button,
  Input,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import InputMask from "react-input-mask";
import { Link as RouterLink } from 'react-router-dom';
import {
  updateResidentPharmacy,
  createResidentPharmacy,
  openPharmacyLookupDialog,
  closePharmacyLookupDialog,
  deleteResidentPharmacy,
} from "./resident.pharmacies.actions";
import PharmacyLookup from "../../pharmacies/pharmacy.lookup";
import useHasRole from '../../../hooks/user.role.hook';
import {ResidentContext} from "../../../context/residentcontext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  table: {
    width: "100%",
  },
  iconButton: {
    padding: 0,
    margin: 0,
  },
  actionIcon: {
    height: "1.5rem",
    width: "1.5rem",
  },
}));
const ResidentPharmaciesTable = () => {
  const classes = useStyles();
  const { state, dispatch, resident } = React.useContext(ResidentPharmaciesContext);
  const residentContext = React.useContext(ResidentContext);
  const toggleIsPrimary = async (residentPharmacy) => {
    await updateResidentPharmacy(
      dispatch,
      resident?._id,
      residentPharmacy.pharmacy._id,
      !residentPharmacy.isPrimary,
      residentContext.dispatch
    );
  };

  const removePharmacy = async (residentPharmacy) => {
    await deleteResidentPharmacy(
      dispatch,
      resident?._id,
      residentPharmacy.pharmacy._id,
      residentContext.dispatch
    );
  };

  const onPharmacieselected = async (pharmacy) => {
    const hasPrimary = resident?.residentPharmacies?.filter(r => r?.isPrimary)?.length > 0;
    await createResidentPharmacy(dispatch, resident?._id, pharmacy._id, !hasPrimary, residentContext.dispatch);
  };

  const { checkPermission } = useHasRole();
  const canEdit = checkPermission('RESIDENT_UPDATE');

  return (
    <div className={classes.root}>
      {state?.loading &&
      <div>
        <Skeleton variant="text" height={40}/>
        <Skeleton variant="text" height={40}/>
        <Skeleton variant="text" height={40}/>
        <Skeleton variant="text" height={40}/>
      </div>
      }
      {!state?.loading &&
      <div>
        <Typography variant="subtitle1">Pharmacies</Typography>
        {canEdit &&
        <Button
          startIcon={<AddIcon/>}
          onClick={() => dispatch(openPharmacyLookupDialog())}
        >
          Add Pharmacy
        </Button>}
        <PharmacyLookup
          open={state.openPharmacyDialog}
          onClose={() => dispatch(closePharmacyLookupDialog())}
          onSelect={onPharmacieselected}
        />
        <TableContainer>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell align="left" width={200}/>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Phone</TableCell>
                <TableCell align="left">Address</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resident?.residentPharmacies?.map((residentPharmacy) => (
                <TableRow>
                  <TableCell align="left">
                    <IconButton className={classes.iconButton} component={RouterLink}
                                to={`/pharmacies/${residentPharmacy?.pharmacy?._id}`}>
                      <VisibilityRoundedIcon className={classes.actionIcon}/>
                    </IconButton>
                    {canEdit &&
                    <>
                      <IconButton
                        className={classes.iconButton}
                        aria-label="delete"
                        onClick={() => removePharmacy(residentPharmacy)}
                      >
                        <DeleteRoundedIcon
                          className={classes.actionIcon}
                          color="error"
                        />
                      </IconButton>
                      <IconButton
                        className={classes.iconButton}
                        aria-label="Toggle primary"
                        onClick={() => toggleIsPrimary(residentPharmacy)}
                      >
                        {residentPharmacy.isPrimary ? (
                          <CheckCircleRoundedIcon
                            className={classes.actionIcon}
                            color="secondary"
                          />
                        ) : (
                          <RadioButtonUncheckedRoundedIcon/>
                        )}
                        <Typography variant="subtitle2">Primary</Typography>
                      </IconButton>
                    </>
                    }
                  </TableCell>
                  <TableCell align="left">
                    {residentPharmacy?.pharmacy?.name}
                  </TableCell>
                  <TableCell align="left">
                    {residentPharmacy?.pharmacy?.phones.map((phone) => (
                      <InputMask mask="(999) 999-9999" value={phone?.number}>
                        {() => <Input disableUnderline/>}
                      </InputMask>
                    ))}
                  </TableCell>
                  <TableCell>
                    {residentPharmacy?.pharmacy?.address && (
                      <div>
                        {residentPharmacy?.pharmacy?.address.address}{" "}
                        {residentPharmacy?.pharmacy?.address.address2}{" "}
                        {residentPharmacy?.pharmacy?.address.city}{" "}
                        {residentPharmacy?.pharmacy?.address.state}{", "}
                        {residentPharmacy?.pharmacy?.address.zip}{" "}
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      }
    </div>
  );
};

const ResidentPharmacies = () => {
  return (
    <ResidentPharmaciesProvider>
      <ResidentPharmaciesTable />
    </ResidentPharmaciesProvider>
  );
};

export default ResidentPharmacies;
