import React from 'react'
import {useHistory, useParams} from "react-router-dom";
import {deleteCareplanAction, getCareplanAction, updateCareplanAction} from "./context/actions";
import {CareplanContext} from "./context";
import {
    Button, CircularProgress, Divider,
    FormControl,
    FormGroup,
    Grid, LinearProgress,
    makeStyles,
    Typography
} from "@material-ui/core";
import {PageTitle} from "../../utils";
import {CareplanModel} from "./careplan.model";
import {ACTIONS} from "./context/constants";
import {FormFieldsGenerator} from "../../../utils/forms";
import useHasRole from "../../../hooks/user.role.hook";
import ChipInput from "material-ui-chip-input";
import {ResidentCareplanGoalsTable} from "./resident.careplan.goals.table";
import SaveIcon from "@material-ui/icons/Save";
import {DeleteBtn} from "../../utils/buttons/DeleteBtn";
import ConfirmDialog from "../../utils/dialogs/confirm.dialog";
import useGlobalNotification from "../../../hooks/notification.hook";

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TableLoadingIndicator from "../../../utils/indicators/table.loading";

const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(1)
    },
    paperContainer: {
       padding: theme.spacing(1)
    },
    goalsContainer: {
        margin: theme.spacing(1, 0),
        padding: theme.spacing(1)
    },
    btnContainer: {
        margin: theme.spacing(2, 0)
    },
    accordianRoot: {
      width: "100%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    details: {
        width: "100%"
    },
    column: {

    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

export const ResidentCareplan = () => {
    const classes = useStyles();
    const {residentId, careplanId} = useParams();
    const {dispatch, state} = React.useContext(CareplanContext);
    const { checkPermission } = useHasRole();
    const canEdit = checkPermission('CAREPLAN_ORDER_UPDATE');
    const canDelete = checkPermission("CAREPLAN_ORDER_DELETE");
    const [viewConfirmDeleteDialog, setViewConfirmDeleteDialog] = React.useState(false);
    const {addNotification} = useGlobalNotification();
    const history = useHistory();

    React.useEffect(() => {
        if (state?.careplan) {
            const obj = JSON.parse(JSON.stringify(CareplanModel));
            obj.name.value = state?.careplan?.name;
            obj.problemArea.value = state?.careplan?.problemArea;
            obj.careplanDate.value = state?.careplan?.careplanDate;
            obj.reviewDate.value = state?.careplan?.reviewDate;
            obj.goals.value = state?.careplan?.goals;
            obj.tags.value = state?.careplan?.tags;
            dispatch({type: ACTIONS.SET_MODEL, payload: obj});
        }
        // eslint-disable-next-line
    }, [state?.careplan]);

    React.useEffect(() => {
        if (residentId && careplanId) {
            getCareplanAction(dispatch, residentId, careplanId).then(() => {
            });
        }
        // eslint-disable-next-line
    },[dispatch, residentId, careplanId]);

    const onChange = e => {
        const obj = { ...state?.model, ...e };
        dispatch({type: ACTIONS.SET_MODEL, payload: obj});
    }

    const handleAdd = (...chips) => {
        const obj = { ...state?.model };
        obj.tags.value = [...obj.tags.value, ...chips];
        dispatch({type: ACTIONS.SET_MODEL, payload: obj});
    };

    const handleDelete = (deletedChip) => {
        const obj = { ...state?.model };
        obj.tags.value = obj.tags.value.filter((c) => c !== deletedChip);
        dispatch({type: ACTIONS.SET_MODEL, payload: obj});
    };

    const handleUpdateCareplan = async () => {
        const data = {
            name: state?.model?.name?.value,
            problemArea: state?.model?.problemArea?.value,
            careplanDate: state?.model?.careplanDate?.value,
            reviewDate: state?.model?.reviewDate?.value,
            tags: state?.model?.tags?.value,
            // goals: state?.model?.goals?.value
        };
        await updateCareplanAction(dispatch, state?.residentId, state?.careplan?._id, data);
    }

    const handleDeleteCareplan = async () => {
        const result = await deleteCareplanAction(dispatch, state?.residentId, state?.careplan?._id);
        if (!result?.error) {
            addNotification("Careplan deleted", "success");
            history.push(`/residents/${state?.residentId}/careplans`);
        } else {
            addNotification("Unable to delete careplan", "error");
        }
    }

    return (
        <div className={classes.root}>
            <Accordion defaultExpanded>
                {state?.loading && <LinearProgress color={"primary"} />}
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                >
                    {!state?.loading && <PageTitle title={!state?.loading ? `Careplan: ${state?.careplan?.name}`: null} />}
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    {state?.loading &&
                        <div style={{width: "100%"}}>
                            <TableLoadingIndicator rows={5} cols={1} />
                        </div>
                    }
                    {!state?.loading &&
                        <FormGroup style={{width: "100%"}}>
                            <FormControl>
                                <FormFieldsGenerator model={state?.model}
                                                     onChange={onChange}
                                                     edit={canEdit && !state?.updatingCareplan} />
                                <ChipInput
                                    blurBehavior={"add"}
                                    label="Tags"
                                    fullWidth
                                    helperText="Tag care plan with keywords for better searching"
                                    value={state?.model?.tags?.value}
                                    onAdd={(chip) => handleAdd(chip)}
                                    onDelete={(deletedChip) => handleDelete(deletedChip)}
                                    disabled={!canEdit}
                                />
                            </FormControl>
                    </FormGroup>
                    }
                </AccordionDetails><Divider />
                {!state?.loading &&
                    <AccordionActions>
                    <Grid container
                          className={classes.btnContainer}
                          justifyContent={canDelete ? "space-between": "flex-end"}
                    >

                        {canDelete && <DeleteBtn onClick={() => setViewConfirmDeleteDialog(true)} />}
                        <Button disabled={!canEdit || state?.updatingCareplan}
                                onClick={handleUpdateCareplan}
                                startIcon={state?.updatingCareplan ? <CircularProgress size={20} />: <SaveIcon />}
                                color={"primary"}>
                            Update
                        </Button>
                    </Grid>
                </AccordionActions>
                }
            </Accordion>
            {!state?.loading &&
                <Accordion defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="goals-content"
                    id="goals-header"
                >
                    <Typography variant={"h6"}>Careplan Goals</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    <ResidentCareplanGoalsTable />
                </AccordionDetails>
            </Accordion>
            }
            <ConfirmDialog title={"Delete Careplan"}
                           message={"By deleting this careplan, you will lose all related task charting data."}
                           open={viewConfirmDeleteDialog}
                           onConfirm={handleDeleteCareplan}
                           onClose={() => setViewConfirmDeleteDialog(false)}
            />
        </div>
    )
}
