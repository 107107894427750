import React, {useContext} from 'react';
import {
  Box,
  Button, ButtonGroup, CircularProgress, DialogActions,
  FormControl,
  FormGroup,
  InputLabel, MenuItem,
  Paper, Select, Tab,
  Table, TableBody, TableCell,
  TableContainer,
  TableHead, TableRow, Tabs, TextField,
  Typography
} from "@material-ui/core";
import {PageTitle} from "../../../../utils";
import {makeStyles} from "@material-ui/core/styles";
import {useParams} from "react-router-dom";
import {
  createPrnOutcome,
  deletePrnOutcome,
  getResidentPrn,
  updatePrnOutcome,
  updateResidentPrn
} from "../../../../../services/medication.order.service";
import TableLoadingIndicator from "../../../../../utils/indicators/table.loading";
import {RetryMessage} from "../../../../notification/retry.message";
import {EPRN_MODEL} from "./model";
import {FormFieldsGenerator} from "../../../../../utils/forms";
import {getTenantUsers} from "../../../../../services/tenant.service";
import {UserContext} from "../../../../../context/usercontext";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/Add";
import {DeleteBtn} from "../../../../utils/buttons/DeleteBtn";
import MomentUtils from "@date-io/moment";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {CreateOutcomeModal} from "./CreateOutcomeModal";
import {PrnAlerts} from "./PrnAlerts";
import {getPrnAlerts, updateAlert} from "../../../../../services/resident.service";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1)
  },
  customControlInput: {
    margin: theme.spacing(2, 0)
  },
  outcomes: {
    margin: theme.spacing(2, 0)
  }
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const Eprn = () => {
  const classes = useStyles();
  const { selectedTenant } = useContext(UserContext);
  const { residentId, id } = useParams();
  const [prn, setPrn] = React.useState();
  const [editOutcome, setEditOutcome] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [updating, setUpdating] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(EPRN_MODEL)));
  const [users, setUsers] = React.useState([]);
  const [tabValue, setTabValue] = React.useState(0);
  const [viewCreateOutcomeModal, setViewCreateOutcomeModal] = React.useState(false);
  const [alerts, setAlerts] = React.useState([]);

  React.useEffect(() => {
    if (id) {
      getData(id);
    }
    // eslint-disable-next-line
  }, [id]);


  const getData = async () => {
    setLoading(true);
    setError(false);
    const data = JSON.parse(JSON.stringify(EPRN_MODEL));
    if (!users || users?.length < 1) {
      const usersResponse = await getTenantUsers(selectedTenant?._id);
      setUsers(usersResponse);
      data.administeredBy.dropDownItems = usersResponse?.map(user => ({_id: user?._id, name: `${user?.firstName} ${user?.lastName}`}))
    } else {
      data.administeredBy.dropDownItems = users?.map(user => ({_id: user?._id, name: `${user?.firstName} ${user?.lastName}`}))
    }


    const response = await Promise.all([
      getResidentPrn(residentId, id),
      getPrnAlerts(residentId, id)
    ]);
    const prnResponse = response[0];
    if (!prnResponse?.error) {
      setPrn(prnResponse)
      data._id.value = prnResponse._id;
      data.scheduledTime.value = prnResponse?.scheduledTime;
      data.administeredBy.value = prnResponse?.administeredUser?._id;
      data.reason.value = prnResponse?.reason;
      data.qty.value = prnResponse?.passingQty;
      data.outcomes.value = prnResponse?.outcomes;
      setModel(data);
    } else {
      setError(true);
    }
    const prnAlerts = response[1];
    if (!prnAlerts?.error) {
      setAlerts(prnAlerts);
    }

    setLoading(false);
  }

  const onModelChange = (e, field) => {
    setModel({...model}, {...e});
  }

  const getMedicationDetails = () => {
    return (
      <FormControl style={{ width: '100%' }}>
        <InputLabel shrink>Medication</InputLabel>
        <div className={classes.customControlInput}>
          <Typography variant={"subtitle1"}>
            {prn?.medicationOrder?.medication?.tradeName}
          </Typography>
          <Typography variant={"subtitle1"}>
            {prn?.medicationOrder?.medication?.strength}{" "} {prn?.medicationOrder?.medication?.unit?.name}{" "}
            {prn?.medicationOrder?.medication?.dosageForm?.name}
          </Typography>
        </div>
      </FormControl>
    )
  }


  const getUser = outcome => {
    if (users) {
      const found = users?.find(u => u?._id === outcome?.user);
      if (found) {
        return `${found?.firstName} ${found?.lastName}`
      }
    }
    return null;
  }

  const outcomeChanged = e => {
    const obj = {...editOutcome};
    obj[e.target.name] = e.target.value;
    setEditOutcome(obj);
  }

  const saveOutcome = async () => {
    const updateResponse = await updatePrnOutcome(residentId, prn?._id, editOutcome?._id, editOutcome);
    if (!updateResponse?.error) {
      const obj = {...model};
      obj.outcomes.value = obj.outcomes.value?.map(outcome => outcome?._id === updateResponse?._id ? updateResponse: outcome);
      setModel(obj);
      setEditOutcome(null);
    }
  }

  const deleteOutcome = async outcome => {
    const response = await deletePrnOutcome(residentId, prn?._id, outcome?._id);
    if (!response?.error) {
      const obj = {...model};
      obj.outcomes.value = obj.outcomes.value?.filter(o => o?._id !== outcome?._id);
      setModel(obj);
      setEditOutcome(null);
    }
  }

  const onOutcomeEntryDateChange = date => {
    const obj = {...editOutcome};
    obj.entryDate = date;
    setEditOutcome(obj);
  }

  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  }

  const updateDetails = async () => {
    setUpdating(true);
    const data = {...prn};
    data.scheduledTime = model.scheduledTime.value;
    data.reason = model.reason.value;
    data.passingQty = model.qty.value;
    data.administeredUser = model.administeredBy.value;
    await updateResidentPrn(residentId, prn?._id, data);
    setUpdating(false);
  }

  const onOutcomeSaved = async (newOutcome) => {
    const outcomeResponse = await createPrnOutcome(residentId, prn?._id, newOutcome);
    if (!outcomeResponse?.error) {
      const obj = {...model};
      obj.outcomes.value = [...obj.outcomes.value, outcomeResponse];
      setModel(obj);
    }
    setViewCreateOutcomeModal(false);
  }

  const onCancelAlert = async alertId => {
    setLoading(true);
    const result = await updateAlert(residentId, alertId, {dismissed: true});
    if (!result?.error) {
      await getData();
    } else {
      setError(true);
    }
    setLoading(false);
  }

  return (
    <Paper elevation={24} className={classes.root}>
      <PageTitle title={"PRN"} />
      {loading && <TableLoadingIndicator rows={4} cols={3} />}
      {!loading && error &&
        <RetryMessage onRetry={getData} message={"Unable to load PRN"} severity={"error"} />
      }
      {!loading && !error &&
        <>
          <Tabs variant={"fullWidth"}
                value={tabValue}
                onChange={handleTabChange}>
            <Tab label={"PRN Details"} />
            <Tab label={`Outcomes (${model?.outcomes?.value?.length})`} />
            <Tab label={`Alerts (${alerts?.totalDocs})`} />
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            {getMedicationDetails()}
            <FormGroup>
              <FormFieldsGenerator edit={!updating} model={model} onChange={onModelChange}/>
            </FormGroup>
            <DialogActions>
              <Button>Cancel</Button>
              <Button variant={"contained"}
                      color={"primary"}
                      onClick={updateDetails}
                      disabled={updating}
                      startIcon={updating ? <CircularProgress size={20} />: <SaveIcon />}
              >
                Update
              </Button>
            </DialogActions>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <div className={classes.outcomes}>
              <FormControl style={{ width: '100%' }}>
                <InputLabel shrink>Outcomes</InputLabel>
                <div className={classes.customControlInput}>
                  <TableContainer>
                    <Table size={"small"}>
                      <TableHead>
                        <TableRow>
                          <TableCell width={200}>
                            <Button startIcon={<AddIcon />}
                                    size={"small"}
                                    onClick={() => setViewCreateOutcomeModal(true)}
                                    color={"primary"}
                            >
                              Add Outcome
                            </Button>
                            <CreateOutcomeModal open={viewCreateOutcomeModal}
                                                onClose={() => setViewCreateOutcomeModal(false)}
                                                onSave={onOutcomeSaved}
                                                users={users}
                            />
                          </TableCell>
                          <TableCell width={300}>Date</TableCell>
                          <TableCell width={300}>Created By</TableCell>
                          <TableCell>Outcome</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {model?.outcomes?.value?.map((outcome, i) =>
                          <>
                            {outcome?._id !== editOutcome?._id &&
                              <TableRow>
                                <TableCell>
                                  <ButtonGroup>
                                    <DeleteBtn onClick={() => deleteOutcome(outcome)} />
                                    <Button startIcon={<EditIcon />}
                                            onClick={() => setEditOutcome(outcome)}
                                            size={"small"}>
                                      Edit
                                    </Button>
                                  </ButtonGroup>
                                </TableCell>
                                <TableCell>{moment(outcome.entryDate).format("MM/DD/YYYY hh:mm a")}</TableCell>
                                <TableCell>{getUser(outcome)}</TableCell>
                                <TableCell>{outcome.comment}</TableCell>
                              </TableRow>}
                            {outcome?._id === editOutcome?._id &&
                              <TableRow>
                                <TableCell>
                                  <ButtonGroup>
                                    <Button startIcon={<SaveIcon />}
                                            onClick={saveOutcome}
                                            color={"primary"}
                                            variant={"contained"}
                                            size={"small"}>
                                      Save
                                    </Button>
                                    <Button startIcon={<CancelIcon />}
                                            onClick={() => setEditOutcome(null)}
                                            size={"small"}>
                                      Cancel
                                    </Button>
                                  </ButtonGroup>
                                </TableCell>
                                <TableCell>
                                  <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDateTimePicker value={editOutcome?.entryDate}
                                                            onChange={onOutcomeEntryDateChange}
                                                            format={"MM/DD/YYYY hh:mm a"}

                                    />
                                  </MuiPickersUtilsProvider>
                                </TableCell>
                                <TableCell>
                                  <Select name={"user"}
                                          onChange={outcomeChanged}
                                          value={editOutcome?.user}>
                                    {users?.map(user =>
                                      <MenuItem value={user?._id}>
                                        {user?.firstName} {user?.lastName}
                                      </MenuItem>
                                    )}
                                  </Select>
                                </TableCell>
                                <TableCell>
                                  <TextField multiline={true}
                                             name={"comment"}
                                             fullWidth
                                             minRows={5}
                                             onChange={outcomeChanged}
                                             value={editOutcome?.comment}
                                  />
                                </TableCell>
                              </TableRow>
                            }
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </FormControl>
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <PrnAlerts residentId={residentId}
                       prnId={id}
                       paginatedAlerts={alerts}
                       onCancelAlert={onCancelAlert}
            />
          </TabPanel>
        </>
      }
    </Paper>
  )


}
