import React from 'react';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Checkbox from "@material-ui/core/Checkbox";
import {Chip, IconButton, InputAdornment, ListItemSecondaryAction, TextField} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {makeStyles} from "@material-ui/core/styles";
import {intersection, not, union} from "./utils";
import * as _ from "lodash";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {Clear} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    maxWidth: 500,
    minWidth: 500,
    height: "60vh",
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    border: 'none'
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  category: {
    padding: theme.spacing(0)
  },
  categoryItem: {
    //
  }
}));

export const TransferListColumn = ({title, items, onCheckedChange}) => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [filter, setFilter] = React.useState();
  const [permissions, setPermissions] = React.useState([]);

  React.useEffect(() => {
    if (filter) {
      setPermissions(filterPermissions(filter))
    } else {
      setPermissions(items);
    }
    // eslint-disable-next-line
  }, [items, filter]);

  const numberOfChecked = (items) => intersection(checked, items)?.length;

  const handleToggleAll = (items) => () => {
    let obj = [];
    if (numberOfChecked(items) === items?.length) {
      obj = not(checked, items);
      setChecked(obj);
    } else {
      obj = union(checked, items)
      setChecked(obj);
    }
    onCheckedChange(obj);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked?.findIndex(c => c?._id === value?._id);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    onCheckedChange(newChecked);
  };


  const toggleGroup = (group) => {
    const matching = intersection(checked, group?.permissions);
    let obj = [];
    if (matching?.length === 0) {
      obj = [...checked, ...group?.permissions];
      setChecked(obj);
    } else if (matching?.length < group?.permissions?.length) {
      const missing = not(group?.permissions, checked);
      setChecked([...checked, ...missing]);
    } else if (matching?.length === group?.permissions?.length) {
      obj = checked?.filter(c => matching?.findIndex(m => m?._id === c?._id) === -1);
      setChecked(obj);
    }
    onCheckedChange(obj);
  }


  const displayItems = items => {
    return _(items)
      .groupBy('category')
      .map(function(x, category) {
        return {
          category,
          permissions: x
        };
      }).value().map((group, groupIndex) =>
        <div>
          <ListItem key={groupIndex}
                    className={classes.category}
                    onClick={() => toggleGroup(group)}
                    button
          >
            <ListItemIcon>
              <Checkbox
                checked={intersection(checked, group?.permissions)?.length === group?.permissions?.length}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': `transfer-list-group-all-item-${group?.category}-label` }}
              />
            </ListItemIcon>
            <ListItemText id={`transfer-list-group-all-item-${group?.category}-label`}
                          primary={group?.category === "undefined" ? "No Category": `${group?.category} Permissions`} />
          </ListItem>
          <Divider />
          {group?.permissions?.sort((a, b) => a?.name > b?.name ? 1: -1)?.map((item) => {
            const labelId = `transfer-list-all-item-${item?.name}-label`;
            return (
              <ListItem key={item?._id}
                        role="listitem"
                        button
                        className={classes.categoryItem}
                        onClick={handleToggle(item)}>
                <ListItemIcon>
                  <Checkbox
                    checked={checked.findIndex(i => i?._id === item?._id) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={item?.name} />
                <ListItemSecondaryAction>
                  {item?.tags?.map(tag => <Chip size={"small"} label={tag} />)}
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
          <ListItem />
        </div>
      );
  }

  const filterPermissions = filter => {
    return items?.filter(permission =>
      permission?.name?.toLowerCase().includes(filter?.toLowerCase()) ||
      permission?.category?.toLowerCase().includes(filter?.toLowerCase())
    );
  }

  const onFilterChange = e => {
    setFilter(e.target.value);
  }


  return (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(permissions)}
            checked={numberOfChecked(items) === items?.length && items?.length !== 0}
            indeterminate={numberOfChecked(items) !== items?.length && numberOfChecked(items) !== 0}
            disabled={items?.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items?.length} selected`}
      />
      <TextField variant={"outlined"}
                 size={"small"}
                 placeholder={"Filter"}
                 fullWidth
                 onChange={onFilterChange}
                 value={filter || ''}
                 InputProps={{
                   endAdornment:
                     <InputAdornment position="end">
                       <IconButton
                        aria-label="clear filter"
                        onClick={() => setFilter(null)}
                       >
                        <Clear />
                      </IconButton>
                     </InputAdornment>
                 }}
      />

      <Divider />
      <List className={classes.list}
            dense
            component="div"
            role="list">
        {displayItems(permissions)}
        <ListItem />
      </List>
    </Card>
  );
}
