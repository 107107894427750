import React from 'react';
import {
  Button, Checkbox, CircularProgress, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl, FormControlLabel,
  FormGroup,
  InputLabel, makeStyles,
  MenuItem,
  Select,
  Table, TableBody, TableCell, TableHead, TableRow
} from "@material-ui/core";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import BaseEvent from "./base.event";
import {ObjectId} from "bson";
import Alert from "@material-ui/lab/Alert";
import {AlertTitle} from "@material-ui/lab";
import SaveIcon from "@material-ui/icons/Save";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    marginTop: theme.spacing(8),
  }
}))

const AddDosageInstructionModal = ({open, onClose, frequencies, onSave}) => {
  const classes = useStyles();
  const [model, setModel] = React.useState();
  const [includeHistory, setIncludeHistory] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (open && frequencies) {
      const obj = {
        _id: new ObjectId(),
        startDate: new Date(),
        endDate: moment().add(1, 'year')
      };
      const freq = frequencies.find(f => f.name === 'QD');
      updateWithFreq(obj, freq);
      setLoading(false);
    }
  }, [open, frequencies]);

  const onFrequencyChange = (e) => {
    const freq = frequencies.find(
      (frequency) => frequency._id === e.target.value
    );
    updateWithFreq({...model}, freq);
  };

  const updateWithFreq = (obj, freq) => {
    if (freq) {
      const eventTemplates = [];
      const defaultHours = {
        1: { 1: 8 },
        2: { 1: 8, 2: 16 },
        3: { 1: 8, 2: 12, 3: 16 },
        4: { 1: 8, 2: 10, 3: 14, 4: 16 },
        5: { 1: 8, 2: 10, 3: 12, 4: 14, 5: 16 },
        6: { 1: 8, 2: 10, 3: 12, 4: 14, 5: 16, 6: 20 },
      };

      for (let i = 1; i <= freq.frequencyEventCount; i++) {
        const d = new Date();
        // Auto increment hours
        let calculatedHour = defaultHours[freq.frequencyEventCount][i];
        const initStartDate = d.setHours(calculatedHour, 0, 0, 0);
        const hour = moment(initStartDate).format('H');
        const minute = moment(initStartDate).format('mm');
        eventTemplates.push({
          id: i,
          passingQty: 1,
          hour: hour,
          minute: minute,
        });
      }
      obj.frequency = freq;
      obj.eventTemplates = eventTemplates;
      setModel(obj);
    }
  };

  const save = () => {
    setLoading(true);
    model.includeHistory = includeHistory;
    onSave(model);
  }

  const deleteEvent = (event) => {
    const obj = { ...model };
    obj.eventTemplates = obj?.eventTemplates?.filter(
      (eventTemplate) => eventTemplate?.id !== event?.id
    );
    setModel(obj);
  };

  const onEventChange = (eventTemplate) => {
    if (model?.eventTemplates) {
      const obj = { ...model };
      obj.eventTemplates = obj?.eventTemplates?.map((event) => {
        if (event?.id === eventTemplate?.id) {
          event = eventTemplate;
        }
        return event;
      });
      setModel(obj);
    }
  };

  const isValid = () => {
    if (!moment(model?.startDate).isValid() || !moment(model?.endDate).isValid()) {
      return false;
    }

    if (moment(model?.endDate).isBefore(moment(model?.startDate), "day")) {
      return false;
    }

    if (model?.eventTemplates) {
      let isValid = true;
      model?.eventTemplates?.forEach((event) => {
        if (!event?.passingQty || !event?.hour || !event?.minute) {
          isValid = false;
        }
      });
      return isValid;
    }
    return false;
  };

  const onDateChange = (property, date) => {
    const obj = {...model};
    obj[property] = date;
    setModel(obj);
  }

  const startDateIsCurrentDay = () => {
    return moment(model?.startDate).isSameOrBefore(moment(), 'day');
  }

  const handleIncludeHistory = (e, name) => {
    if (name === 'in') {
      setIncludeHistory(e.target.checked);
    } else {
      setIncludeHistory(!e.target.checked);
    }
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={open}
      onClose={onClose}
    >
      <DialogTitle id="alert-dialog-slide-title">
        {'Add Passing'}
      </DialogTitle>
      <DialogContent className={classes.contentContainer}
                     dividers
      >
        <form>
          <FormGroup>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="frequency-id">Repeats</InputLabel>
              <Select
                multiple={false}
                value={ model?.frequency?._id || '' }
                onChange={onFrequencyChange}
                inputProps={{
                  name: 'frequency',
                  id: 'frequency-id',
                }}
              >
                {frequencies?.map((freq) =>
                  <MenuItem key={freq._id} value={freq._id}>
                    {freq.name} - {freq.description}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </FormGroup>
          <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                fullWidth
                animateYearScrolling
                margin="normal"
                name="startDate"
                id="start-date"
                label="Start Date"
                format="MM/DD/YYYY"
                value={model?.startDate || ''}
                onChange={(date) => onDateChange("startDate", date)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              <KeyboardDatePicker
                fullWidth
                animateYearScrolling
                margin="normal"
                name="endDate"
                id="end-date"
                label="End Date"
                format="MM/DD/YYYY"
                minDate={moment(model?.startDate)}
                minDateMessage={"End date cannot be earlier than start date."}
                value={model?.endDate || ''}
                onChange={(date) => onDateChange("endDate", date)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
          </MuiPickersUtilsProvider>

          {startDateIsCurrentDay() &&
              <Alert severity={"error"}>
                <AlertTitle>
                  {`Do you want to create events scheduled in the past?`}
                </AlertTitle>
                <FormControlLabel
                    control={
                      <Checkbox
                          size="small"
                          name="includeHistorical"
                          checked={includeHistory}
                          onChange={(evt) => handleIncludeHistory(evt, 'in')}
                      />
                    }
                    label="Yes"
                />
                <FormControlLabel
                    control={
                      <Checkbox
                          size="small"
                          defaultChecked={true}
                          name="doNoIncludeHistorical"
                          checked={!includeHistory}
                          onChange={(evt) => handleIncludeHistory(evt, 'doNot')}
                      />
                    }
                    label="No"
                />
              </Alert>
          }

          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Day</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Quantity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {model?.eventTemplates?.map(
                (eventTemplate) => (
                  <BaseEvent
                    onDelete={deleteEvent}
                    frequency={model?.frequency}
                    eventTemplate={eventTemplate}
                    onChange={onEventChange}
                  />
                )
              )}
            </TableBody>
          </Table>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
        <Button
          disabled={loading || !isValid()}
          startIcon={loading ? <CircularProgress size={20} />: <SaveIcon />}
          onClick={save}
          color="primary"
          variant={"contained"}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddDosageInstructionModal;
