import React from 'react';
import {EveryWeekdayOccurrence} from "./EveryWeekdayOccurrence";
import {MonthlyOccurrence} from "./MonthlyOccurrence";
import {YearlyOccurrence} from "./YearlyOcccurrence";
import {DailyOccurrence} from "./DailyOccurrence";

export const Occurrence = ({model, onChange}) => {
  switch (model?.repeat?.value) {
    case 'Daily':
      return (
        <DailyOccurrence model={model} onChange={onChange} />
      )
    case 'Weekly':
      return (
        <EveryWeekdayOccurrence model={model} onChange={onChange} />
      )
    case 'Monthly':
      return (
        <MonthlyOccurrence model={model} onChange={onChange} />
      )
    case 'Yearly':
      return (
        <YearlyOccurrence model={model} onChange={onChange} />
      )
    default:
      return null
  }
}
