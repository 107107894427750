import React from 'react';
import {Switch, Link} from "react-router-dom";
import PrivateRoute from "../PrivateRoute";
import {createDiagnosis, getDiagnosis} from "../../services/diagnosis.service";
import {convertToFilters} from "../../utils/filter.utils";
import {
  Button,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead, TablePagination,
  TableRow, TableSortLabel, TextField,
  Typography
} from "@material-ui/core";
import HighlightOffSharpIcon from "@material-ui/icons/HighlightOffSharp";
import Diag from "./diag";
import AddIcon from "@material-ui/icons/Add";
import useHasRole from "../../hooks/user.role.hook";
import CreateDiagnosisModal from "./create.diagnosis.modal";
import { getStripedStyle } from '../utils/table.utils';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1)
  }
}));

const sizeOptions = [1,5,25,50];

const FilterTemplate = {
  code: {
    field: 'code',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  version: {
    field: 'version',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  shortDescription: {
    field: 'shortDescription',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  longDescription: {
    field: 'longDescription',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
}

const Diagnosis = () => {
  const classes = useStyles();
  const [codes, setCodes] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(25);
  const [sort, setSort] = React.useState('code');
  const [sortDirection, setSortDirection] = React.useState('asc');
  const [total, setTotal] = React.useState(0);
  const [filter, setFilter] = React.useState(JSON.parse(JSON.stringify(FilterTemplate)));
  const [viewCreateDiagnosisDialog, setViewCreateDiagnosisDialog] = React.useState(false);

  const { checkPermission } = useHasRole();
  const canCreate = checkPermission('DIAGNOSIS_CREATE');

  React.useEffect(() => {
    setFilter(JSON.parse(JSON.stringify(FilterTemplate)));
    search();
  },[]);

  const clearFilter = async () => {
    setFilter(JSON.parse(JSON.stringify(FilterTemplate)));
    setPage(1);
    setSize(25);
    setSort('code');
    setSortDirection('asc');
    await search();
  }

  const search = async (pg = 1, sz = 25, srt = 'code', srtDirection = 'asc', filter = {}) => {
    const filters = convertToFilters(filter);
    const result = await getDiagnosis(pg, sz, srt, srtDirection, filters);
    setCodes(result?.content);
    setPage(result?.paginator?.currentPage);
    setSize(result?.paginator?.perPage);
    setTotal(result?.paginator?.totalItems)
  }

  const onChangeRowsPerPage = async e => {
    setSize(e.target.value);
    search(page, e.target.value, sort, sortDirection, filter);
  }

  const handleChangePage = async (e, newPage) => {
    const pg = newPage + 1;
    setPage(pg);
    search(pg, size, sort, sortDirection, filter);
  }

  const createSortHandler = async field => {
    const srt = field;
    setSort(srt);
    const srtDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(srtDirection);
    await search(page, size, srt, srtDirection, filter);
  }

  const filterTextChange = async e => {
    const obj = { ...filter };
    obj[e.target.name].value = e.target.value;
    setFilter(obj);
    if (
      String(obj[e.target.name].value).length === 0 ||
      String(obj[e.target.name].value).length >= obj[e.target.name].minValue
    ) {
      await search(page, size, sort, sortDirection, obj);
    }
  }

  const onDiagnosisCreated = async diagnosis => {
    const entity = await createDiagnosis(diagnosis);
    setCodes([entity, ...codes]);
    setViewCreateDiagnosisDialog(false);
  }

  return (
    <Paper className={classes.root}
           elevation={24}
    >
      <Typography variant={"h6"}>Diagnosis</Typography>
      <TableContainer>
        <Grid container justify={"space-between"} alignContent={"center"}>
          <div>
            {canCreate && <Button size={"small"}
                    startIcon={<AddIcon />}
                    color={"primary"}
                    variant={"contained"}
                    onClick={() => setViewCreateDiagnosisDialog(true)}>
              Add
            </Button>}
            <Button size={"small"}
                    startIcon={<HighlightOffSharpIcon />}
                    onClick={clearFilter}
            >
              Clear Filters
            </Button>
            <CreateDiagnosisModal open={viewCreateDiagnosisDialog}
                                  onClose={() => setViewCreateDiagnosisDialog(false)}
                                  onSave={onDiagnosisCreated}
                                  />
          </div>
          <TablePagination
            component="div"
            rowsPerPageOptions={sizeOptions}
            colSpan={5}
            count={total ? total : 0}
            rowsPerPage={size ? size : 0}
            page={page - 1}
            backIconButtonProps={{
              'aria-label': 'previous page',
              disabled: page === 1 ? true : false,
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
              disabled: page * size > total ? true : false,
            }}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onChangePage={handleChangePage}
          />

        </Grid>
        <Table size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <TableSortLabel
                  active={sort === 'code'}
                  direction={sortDirection}
                  onClick={() => createSortHandler('code')}
                >
                  Code
                </TableSortLabel>
                <TextField
                  fullWidth
                  name="code"
                  margin="dense"
                  size="small"
                  placeholder="Search"
                  variant="outlined"
                  value={filter.code.value || ''}
                  onChange={filterTextChange}
                ></TextField>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sort === 'version'}
                  direction={sortDirection}
                  onClick={() => createSortHandler('version')}
                >
                  Version
                </TableSortLabel>
                <TextField
                  fullWidth
                  name="version"
                  margin="dense"
                  size="small"
                  placeholder="Search"
                  variant="outlined"
                  value={filter.version.value || ''}
                  onChange={filterTextChange}
                ></TextField>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sort === 'shortDescription'}
                  direction={sortDirection}
                  onClick={() => createSortHandler('shortDescription')}
                >
                  Description
                </TableSortLabel>
                <TextField
                  fullWidth
                  name="shortDescription"
                  margin="dense"
                  size="small"
                  placeholder="Search"
                  variant="outlined"
                  value={filter.shortDescription.value || ''}
                  onChange={filterTextChange}
                ></TextField>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sort === 'longDescription'}
                  direction={sortDirection}
                  onClick={() => createSortHandler('longDescription')}
                >
                  Description
                </TableSortLabel>
                <TextField
                  fullWidth
                  name="longDescription"
                  margin="dense"
                  size="small"
                  placeholder="Search"
                  variant="outlined"
                  value={filter.longDescription.value || ''}
                  onChange={filterTextChange}
                ></TextField>
                </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {codes?.map((code, index) =>
            <TableRow style={{ ...getStripedStyle(index) }}>
              <TableCell></TableCell>
              <TableCell>
                <Typography variant={"subtitle2"}
                            component={Link}
                            to={`/diagnosis/${code._id}`}

                >
                  {code.code}
                </Typography>
              </TableCell>
              <TableCell>{code.version}</TableCell>
              <TableCell>{code.shortDescription}</TableCell>
              <TableCell>{code.longDescription}</TableCell>
            </TableRow>)}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

const Wrapper = () => {
  return (
    <Switch>
      <PrivateRoute
        path={"/diagnosis/:diagnosisId"}
        exact
        component={Diag} />
      <PrivateRoute exact
                    path={"/diagnosis"}
                    component={Diagnosis}
      />
    </Switch>
  )
}

export default Wrapper;
