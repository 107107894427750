import React from 'react';
import {List, ListItem, ListItemAvatar, ListItemText, makeStyles, Typography} from "@material-ui/core";
import GroupQuestion from './group.question';
import {AddTwoTone} from "@material-ui/icons";
import {AssessmentsContext} from "./assessments.context";
import {onAddQuestion} from "./assessment.actions";

const useStyles = makeStyles((theme) => ({
  questionsContainer: {
    height: '20vh',
    overflow: 'auto',
    padding: theme.spacing(2, 0)
  },
  avatar: {
    height: '.5rem'
  }
}))

const GroupQuestions = ({group, canUpdate = false}) => {
  const classes = useStyles();
  const { state, dispatch } = React.useContext(AssessmentsContext);

  const addQuestion = e => {
    onAddQuestion(state?.assessment, group, dispatch);
  }

  return (
    <div style={{width: '100%'}}>
      <Typography variant={"subtitle1"}>Questions</Typography>
      <List style={{width: '100%'}}>
        {canUpdate && <ListItem button onClick={addQuestion}>
          <ListItemAvatar>
            <AddTwoTone />
          </ListItemAvatar>
          <ListItemText primary={"Add Question"} />
        </ListItem>}
        <div className={classes.questionsContainer}>
          {group?.questions?.sort((a, b) => a?.sortOrder - b?.sortOrder)
            ?.map(question => <GroupQuestion question={question} canUpdate={canUpdate} />)}
        </div>
      </List>
    </div>
  )
}

export default GroupQuestions;
