import { ACTIONS } from './context';
import {
  createTenantCalendarEvent,
  deleteTenantCalendarEvent,
  deleteTenantCalendarSeries,
  getTenantCalendarEvents,
  getTenantCalendarTypes,
  updateTenantCalendarEvent,
  createTenantCalendarType,
  deleteTenantCalendarType,
  updateTenantCalendarType,
} from "../../../../services/calendar.service";


export const calendarEventSelectedAction = (dispatch, event) => {
  dispatch({type: ACTIONS.SET_SELECTED_CALENDAR_EVENT, payload: event });
  dispatch({type: ACTIONS.SET_VIEW_EDIT_EVENT_MODAL, payload: true})
}

export const calendarSlotSelectedAction = (dispatch, slot) => {
  dispatch({type: ACTIONS.SET_SELECTED_CALENDAR_SLOT, payload: slot });
  dispatch({type: ACTIONS.SET_VIEW_CREATE_EVENT_MODAL, payload: true });
}

export const updateCalendarFilterTypesAction = (dispatch, type) => {
  dispatch({type: ACTIONS.TOGGLE_FILTER_TYPE, payload: type });
}

export const getTenantCalendarEventsAction = async (dispatch, tenantId, date = null) => {
  try {
    dispatch({type: ACTIONS.TOGGLE_LOADING_EVENTS, payload: true });
    dispatch({type: ACTIONS.TOGGLE_LOADING_EVENTS_ERROR, payload: false });
    dispatch({type: ACTIONS.SET_TENANT_ID, payload: tenantId});
    const response = await getTenantCalendarEvents(tenantId, date);
    if (!response?.error) {
      dispatch({type: ACTIONS.SET_CALENDAR_EVENTS, payload: response });
    } else {
      dispatch({type: ACTIONS.TOGGLE_LOADING_EVENTS_ERROR, payload: true });
    }
  } catch (error) {
    dispatch({type: ACTIONS.TOGGLE_LOADING_EVENTS_ERROR, payload: true });
  }
  dispatch({type: ACTIONS.TOGGLE_LOADING_EVENTS, payload: false });
}

export const createCalendarEventsAction = async (dispatch,
                                                 tenantId,
                                                 event,
                                                 onError = null,
                                                 onSuccess= null) => {
  try {
    dispatch({type: ACTIONS.SET_ERROR, payload: false });
    const response = await createTenantCalendarEvent(tenantId, event);
    if (!response?.error) {
      dispatch({type: ACTIONS.CREATE_CALENDAR_EVENT, payload: response });
      onSuccess?.call();
    } else {
      dispatch({type: ACTIONS.SET_ERROR, payload: true });
      onError?.call();
    }
  } catch (error) {
    dispatch({type: ACTIONS.SET_ERROR, payload: true });
  }
}

export const updateCalendarEventsAction = async (dispatch, tenantId, eventId, event, onError, onSuccess) => {
  try {
    dispatch({type: ACTIONS.SET_ERROR, payload: false });
    dispatch({type: ACTIONS.TOGGLE_PENDING_ACTION, payload: true});
    const response = await updateTenantCalendarEvent(tenantId, eventId, event);
    if (!response?.error) {
      dispatch({type: ACTIONS.UPDATE_CALENDAR_EVENT, payload: response });
      if (onSuccess) {
        onSuccess()
      }
    } else {
      dispatch({type: ACTIONS.SET_ERROR, payload: true });
      if (onError) {
        onError();
      }
    }
  } catch (error) {
    dispatch({type: ACTIONS.SET_ERROR, payload: true });
  }
  dispatch({type: ACTIONS.TOGGLE_PENDING_ACTION, payload: false});
}

export const deleteCalendarEventsAction = async (dispatch, tenantId, eventId, onError, onSuccess) => {
  try {
    dispatch({type: ACTIONS.TOGGLE_PENDING_ACTION, payload: true});
    const response = await deleteTenantCalendarEvent(tenantId, eventId);
    if (!response?.error) {
      dispatch({type: ACTIONS.DELETE_CALENDAR_EVENT, payload: response?._id });
      if (onSuccess) {
        onSuccess();
      }
    } else {
      dispatch({type: ACTIONS.SET_ERROR, payload: true });
      if (onError) {
        onError();
      }
    }
  } catch (error) {
    dispatch({type: ACTIONS.SET_ERROR, payload: true });
  }
  dispatch({type: ACTIONS.TOGGLE_PENDING_ACTION, payload: false});
}

export const selectCalendarEventAction = (dispatch, event) => {
  dispatch({type: ACTIONS.SET_SELECTED_CALENDAR_EVENT, payload: event });
  dispatch({type: ACTIONS.SET_VIEW_EDIT_EVENT_MODAL, payload: true})
}

export const deleteCalendarSeriesAction = async (dispatch, tenantId, seriesId, onError, onSuccess) => {
  try {
    const response = await deleteTenantCalendarSeries(tenantId, seriesId);
    if (!response?.error) {
      dispatch({type: ACTIONS.DELETE_CALENDAR_SERIES, payload: seriesId });
      if (onSuccess) {
        onSuccess();
      }
    } else {
      dispatch({type: ACTIONS.SET_ERROR, payload: true });
      if (onError) {
        onError();
      }
    }
  } catch (error) {
    dispatch({type: ACTIONS.SET_ERROR, payload: true });
  }
}

export const getTenantCalendarTypesAction = async (dispatch, tenantId) => {
  try {
    dispatch({type: ACTIONS.TOGGLE_LOADING_TYPES_ERROR, payload: false });
    dispatch({type: ACTIONS.TOGGLE_LOADING_TYPES, payload: true});
    const response = await getTenantCalendarTypes(tenantId);
    if (!response?.error) {
      dispatch({type: ACTIONS.SET_CALENDAR_TYPES, payload: response });
    } else {
      dispatch({type: ACTIONS.TOGGLE_LOADING_TYPES_ERROR, payload: true });
    }
  } catch (error) {
    dispatch({type: ACTIONS.TOGGLE_LOADING_TYPES_ERROR, payload: true });
  }
  dispatch({type: ACTIONS.TOGGLE_LOADING_TYPES, payload: false});
}

export const createCalendarTypeAction = async (dispatch, tenantId, type, onError, onSuccess) => {
  dispatch({type: ACTIONS.TOGGLE_PENDING_ACTION, payload: true });
  try {
    const response = await createTenantCalendarType(tenantId, type);
    if (!response?.error) {
      dispatch({ type: ACTIONS.CREATE_CALENDAR_TYPE, payload: response });
      onSuccess?.call();
    } else {
      onError?.call();
    }
  } catch (error) {
    dispatch({type: ACTIONS.TOGGLE_PENDING_ACTION, payload: false });
  }
}

export const selectCalendarTypeToEditAction = async (dispatch, payload) => {
  dispatch({type: ACTIONS.SET_SELECTED_CALENDAR_TYPE, payload });
  dispatch({type: ACTIONS.SET_VIEW_EDIT_CALENDAR_TYPE_MODAL, payload: true });
}


export const saveCalendarTypeAction = async (dispatch,
                                             tenantId,
                                             type,
                                             calendarType,
                                             checked = false,
                                             onError,
                                             onSuccess) => {
  dispatch({type: ACTIONS.TOGGLE_PENDING_ACTION, payload: true });
  try {
    const response = await updateTenantCalendarType(tenantId, type?._id, calendarType);
    if (!response?.error) {
      response.checked = checked;
      dispatch({ type: ACTIONS.UPDATE_CALENDAR_TYPE, payload: response });
      dispatch({type: ACTIONS.SET_VIEW_EDIT_CALENDAR_TYPE_MODAL, payload: false });
      onSuccess?.call();
    } else {
      onError?.call();
    }
  } catch (error) {
    onError?.call();
  }
}

export const deleteCalendarTypeAction = async (dispatch, tenantId, type, onError, onSuccess) => {
  try {
    const response = await deleteTenantCalendarType(tenantId, type?._id);
    if (!response?.error) {
      dispatch({type: ACTIONS.DELETE_CALENDAR_TYPE, payload: type?._id });
      dispatch({type: ACTIONS.SET_VIEW_DELETE_CALENDAR_TYPE_MODAL, payload: false });
      dispatch({type: ACTIONS.SET_VIEW_EDIT_CALENDAR_TYPE_MODAL, payload: false });
      onSuccess?.call();
    } else {
      onError?.call();
    }
  } catch (error) {
    onError?.call();
  }
}
