import React from 'react';
import {
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  FormGroup,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Transition from '../../../dialog/transition';
import VisitorModel from './visitor.model';
import { isModelValid } from '../../../../utils/forms/form.validator';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: '4rem',
  },
}));

const CreateVistorLog = ({ open, date, onSave, onClose }) => {
  const classes = useStyles();
  const [model, setModel] = React.useState(
    JSON.parse(JSON.stringify(VisitorModel))
  );

  React.useEffect(() => {
    const setDefaultDate = () => {
      const obj = JSON.parse(JSON.stringify(VisitorModel));
      obj.date.dirty = false;
      obj.date.value = Date.now();
      obj.hour.value = Date.now();
      setModel(obj);
    };

    if (open) {
      setDefaultDate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onModelChange = (e) => {
    const obj = { ...model };
    obj[e.target.name].dirty = true;
    obj[e.target.name].value = e.target.value;
    setModel(obj);
  };

  const onHourChange = (e) => {
    const obj = { ...model };
    obj.hour.dirty = true;
    obj.hour.value = e;
    setModel(obj);
  };

  const onDateChange = (e) => {
    const obj = { ...model };
    obj.date.dirty = true;
    obj.date.value = e;
    setModel(obj);
  };

  const onCreate = () => {
    const log = {
      type: 'visitor',
      hour: model.hour.value,
      date: model.date.value,
      visitor: model.visitor.value,
    };
    onSave(log);
  };

  return (
    <Dialog open={open} fullScreen TransitionComponent={Transition}>
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => onClose()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Enter Visitor Log
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.content}>
        <form noValidate>
          <FormGroup>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardTimePicker
                label="Hour"
                placeholder="08:00 AM"
                mask="__:__ _M"
                value={model.hour.value}
                ampm={true}
                helperText={
                  model.hour.dirty && model.hour.value === ''
                    ? `Hour is required!`
                    : null
                }
                onChange={onHourChange}
                error={!model.hour.isValid && model.hour.dirty}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                size="small"
                fullWidth
                name="date"
                required={true}
                value={model.date.value}
                label={'Date'}
                format="MM/DD/YYYY"
                onChange={onDateChange}
                allowKeyboardControl="true"
                ampm={false}
                helperText={
                  model.date.dirty && model.date.value === ''
                    ? `Date is required!`
                    : null
                }
                error={!model.date.isValid && model.date.dirty}
              />
            </MuiPickersUtilsProvider>
            <TextField
              label="Visitor"
              name="visitor"
              value={model.visitor.value}
              onChange={onModelChange}
            />
          </FormGroup>
        </form>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onCreate}
          disabled={!isModelValid(model)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateVistorLog;
