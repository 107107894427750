import React from 'react';
import {getOrderStatuses, getResidentMedicationOrders} from "../../../../services/medication.order.service";
import filterTemplate from "../filterTemplate";
import {convertToFilters} from "../../../../utils/filter.utils";
import { pdf } from '@react-pdf/renderer'
import {CurrentResidentMedicationsReport} from "../../../reports/resident/medications/CurrentResidentMedicationsReport";
import { saveAs } from 'file-saver'
import useGlobalNotification from "../../../../hooks/notification.hook";
import {Button} from "@material-ui/core";
import {Dashboard} from "@material-ui/icons";

export const MobileMedicationOrdersDownload = ({resident}) => {
    const [loading, setLoading] = React.useState(false);
    const [orderStatuses, setOrderStatuses] = React.useState([]);
    const {addNotification} = useGlobalNotification();

    const init = async () => {
        if (!orderStatuses || orderStatuses.length === 0) {
            const data = await getOrderStatuses();
            setOrderStatuses(data);
            return data.find((d) => d.name === 'Active');
        } else {
            return orderStatuses.find((d) => d.name === 'Active');
        }
    };

    const handleDownload = async () => {
        setLoading(true);
        const activeStatus = await init();
        const obj = JSON.parse(JSON.stringify(filterTemplate));
        obj.status.value = activeStatus ? activeStatus._id : -1;
        const filters = convertToFilters(obj);
        const response = await getResidentMedicationOrders(resident?._id,1, 1000, filters, 'medication.tradeName', 'asc');
        if (!response?.error) {
            const blob =
                await pdf(<CurrentResidentMedicationsReport resident={resident} data={response?.content}/>).toBlob()
            saveAs(blob, `${resident?.firstName}.${resident?.lastName}.medications.pdf`);
        } else {
            addNotification("Unable to download pdf", "error");
        }
        setLoading(false);
    }

    return (

        <Button startIcon={<Dashboard />}
                disabled={loading}
                onClick={handleDownload}
        >
            Current Medication Report
        </Button>
    )
}
