import React from "react";
import {ResidentContext} from "../../../context/residentcontext";
let ResidentPharmaciesContext = React.createContext();
export const LOAD_RESIDENT_PHARMACIES_START = "LOAD_RESIDENT_PHARMCIES_START",
  LOAD_RESIDENT_PHARMACIES_COMPLETE = "LOAD_RESIDENT_PHARMCIES_COMPLETE",
  UPDATE_RESIDENT_PHARMACIES_START = "UPDATE_RESIDENT_PHARMCIES_START",
  UPDATE_RESIDENT_PHARMACIES_COMPLETE = "UPDATE_RESIDENT_PHARMCIES_COMPLETE",
  CREATE_RESIDENT_PHARMACY_START = "CREATE_RESIDENT_PHARMACY_START",
  CREATE_RESIDENT_PHARMACY_COMPLETE = "CREATE_RESIDENT_PHARMACY_COMPLETE",
  DELETE_RESIDENT_PHARMACY_START = "DELETE_RESIDENT_PHARMACY_START",
  DELETE_RESIDENT_PHARMACY_COMPLETE = "DELETE_RESIDENT_PHARMACY_COMPLETE",
  OPEN_PHARMACY_DIALOG = "OPEN_PHARMACY_DIALOG",
  CLOSE_PHARMACY_DIALOG = "CLOSE_PHARMACY_DIALOG",
  SET_RESIDENT = "SET_RESIDENT";

const initialState = {
  resident: null,
  loading: false,
  residentPharmacies: [],
  openPharmacyDialog: false
};
let reducer = (state, action) => {
  switch (action.type) {
    case LOAD_RESIDENT_PHARMACIES_START:
      return { ...state, loading: true };
    case LOAD_RESIDENT_PHARMACIES_COMPLETE:
      return {
        ...state,
        residentPharmacies: action.payload,
        loading: false
      };
    case UPDATE_RESIDENT_PHARMACIES_START:
      return { ...state, loading: true };
    case UPDATE_RESIDENT_PHARMACIES_COMPLETE:
      const pharmacies = state.residentPharmacies.map(pharmacy => {
        if (pharmacy._id === action.payload._id) {
          return action.payload;
        }
        return pharmacy;
      });
      return { ...state, loading: false, residentPharmacies: pharmacies };
    case OPEN_PHARMACY_DIALOG:
      return { ...state, openPharmacyDialog: true };
    case CLOSE_PHARMACY_DIALOG:
      return { ...state, openPharmacyDialog: false };
    case CREATE_RESIDENT_PHARMACY_START:
      return { ...state, loading: true };
    case CREATE_RESIDENT_PHARMACY_COMPLETE:
      return {
        ...state,
        loading: false,
        residentPharmacies: [...state.residentPharmacies, action.payload],
        openPharmacyDialog: false
      };
    case DELETE_RESIDENT_PHARMACY_START:
      return { ...state, loading: true };
    case DELETE_RESIDENT_PHARMACY_COMPLETE:
      const deletedpharmacs = state.residentPharmacies.filter(
        residentPharmacy => residentPharmacy.pharmacy._id !== action.payload
      );
      return {
        ...state,
        loading: false,
        residentPharmacies: deletedpharmacs
      };
    case SET_RESIDENT:
      return { ...state, resident: action?.payload };
    default:
      return { ...state };
  }
};

const ResidentPharmaciesProvider = ({ children }) => {
  let { resident } = React.useContext(ResidentContext);
  let [state, dispatch] = React.useReducer(reducer, initialState);
  let value = { state, dispatch, resident };

  React.useEffect(() => {
    dispatch({type: SET_RESIDENT, payload: resident})
  }, [resident]);

  return (
    <ResidentPharmaciesContext.Provider value={value}>
      {children}
    </ResidentPharmaciesContext.Provider>
  );
};

let ResidentPharmaciesConsumer = ResidentPharmaciesContext.Consumer;

export {
  ResidentPharmaciesContext,
  ResidentPharmaciesProvider,
  ResidentPharmaciesConsumer
};
