import React from 'react';
import {
  ACTIONS,
  TAB_VALUES,
  TimeEntryContext,
  toggleViewTimeClock
} from "../../../context/TimeEntryContext/time.entry.context";
import {Dialog, DialogContent, makeStyles, Tab, Tabs} from "@material-ui/core";
import {SsaDialogTitle} from "../../utils/dialogs/ssa.dialog.title";
import {TimeEntryList} from "../list/time.entry.list";
import {enhanceTotalMinutes, getUserTimeEntryForWeek} from "../../../services/timeentry.service";
import {TimeEntrySearch} from "../list/time.entry.search";


const useStyles = makeStyles((theme) => ({
  mainContent: {
    textAlign: "center"
  },
  dialogContent: {
    height: "70vh"
  },
  clockBtnContainer: {
    padding: "2rem",
    textAlign: "center"
  },
  clockInBtn: {
    height: "5rem"
  },
  btnContainer: {
    padding: theme.spacing(2, 0)
  },
  tabPanel: {
    backgroundColor: 'blue'
  }
}));


export const TimeEntryModal = () => {
  const classes = useStyles();
  const { state, dispatch } = React.useContext(TimeEntryContext);
  const {viewTimeClockTable} = state;

  React.useEffect(() => {
    if (viewTimeClockTable && state?.week && state?.year) {
      dispatch({type: ACTIONS.TOGGLE_LOADING, payload: true});
      getWeeklyTimeSheets().then(() =>{
        dispatch({type: ACTIONS.TOGGLE_LOADING, payload: false});
      });
    }
// eslint-disable-next-line
  }, [viewTimeClockTable, state?.week, state?.year])

  const getWeeklyTimeSheets = async () => {
    dispatch({type: ACTIONS.TOGGLE_LOADING, payload: true});
    dispatch({type: ACTIONS.TOGGLE_LOADING, payload: true});
    let timeEntriesResponse = await getUserTimeEntryForWeek(state?.user?._id, state?.year, state?.week);
    timeEntriesResponse = enhanceTotalMinutes(timeEntriesResponse);
    if (!timeEntriesResponse?.error) {
      dispatch({type: ACTIONS.SET_TIME_ENTRIES, payload: timeEntriesResponse});
    } else {
      dispatch({type: ACTIONS.TOGGLE_ERROR, payload: true});
    }
    dispatch({type: ACTIONS.TOGGLE_LOADING, payload: false});
  }

  const onClose = () => {
    toggleViewTimeClock(dispatch, false);
  }

  const onTabChange = (event, newValue) => {
    dispatch({type: ACTIONS.SET_TAB, payload: newValue})
  }

  return (
    <Dialog open={viewTimeClockTable}
            maxWidth={"xl"}
            fullWidth={true}
    >
      <SsaDialogTitle onClose={onClose}>
        <Tabs value={state?.tab}
              onChange={onTabChange}
        >
          <Tab value={TAB_VALUES.TIMECLOCK} label={"Time Clock"} />
          <Tab value={TAB_VALUES.SEARCH} label={"Search"} />
        </Tabs>
      </SsaDialogTitle>
      <DialogContent className={classes.dialogContent} dividers>
        {state?.tab === TAB_VALUES.TIMECLOCK && <TimeEntryList />}
        {state?.tab === TAB_VALUES.SEARCH && <TimeEntrySearch />}
      </DialogContent>
    </Dialog>
  )
}
