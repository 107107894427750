import React from "react";
import modelTemplate from "./model";
import FormFieldsGenerator from "../../utils/forms/form.fields.generator";
import {
  Table,
  TableBody,
  TableRow,
  Select,
  MenuItem,
  TableHead,
  TableCell,
  makeStyles,
  Paper,
  Grid,
  Button,
  Typography,
  DialogActions,
  Link,
  TableContainer,
  TextField,
} from "@material-ui/core";
import history from "../../utils/history";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import SmartphoneIcon from "@material-ui/icons/Smartphone";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import AddIcon from "@material-ui/icons/Add";
import * as contactService from "../../services/contact.service";
import { getAllAddressTypes } from "../../services/address.service";
import { getPhoneTypes } from "../../services/phone.service";
import CreatePhone from "../phone/create.phone";
import PhoneMaskDisplay from "../utils/masks/phone.mask.display";
import PhoneMask from "../utils/masks/phone.mask";
import { isModelValid } from "../../utils/forms/form.validator";
import CreateAddress from "../address/create.address";
import StateDropdown from "../utils/dropdowns/state.dropdown";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  icon: {
    marginRight: ".5rem",
  },
  backBtn: {
    marginRight: ".5rem",
  },
  gridContainer: {
    padding: theme.spacing(1),
  },
}));

const Contact = ({ match }) => {
  const classes = useStyles();
  const [model, setModel] = React.useState(
    JSON.parse(JSON.stringify(modelTemplate))
  );
  const [createPhoneModal, setCreatePhoneModal] = React.useState(false);
  const [contact, setContact] = React.useState(null);
  const [phoneTypes, setPhoneTypes] = React.useState(null);
  const [editPhoneKey, setEditPhoneKey] = React.useState(-1);
  const [editPhone, setEditPhone] = React.useState(null);
  const [addressTypes, setAddressTypes] = React.useState([]);
  const [createAddressModal, setCreateAddressModal] = React.useState(false);
  const [editAddress, setEditAddress] = React.useState(null);
  const [editAddressKey, setEditAddressKey] = React.useState(-1);

  React.useEffect(() => {
    if (match.params.id) {
      getContact(match.params.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]);

  const getContact = async (contactId) => {
    const response = await contactService.getContactById(contactId);
    merge(response);
  };

  const merge = async (entity) => {
    if (!addressTypes || addressTypes.length === 0) {
      const types = await getAllAddressTypes();
      setAddressTypes(types);
    }
    if (entity) {
      const obj = JSON.parse(JSON.stringify(modelTemplate));
      obj.firstName.value = entity.firstName;
      obj.lastName.value = entity.lastName;
      obj.email.value = entity.email;
      setContact(entity);
      setModel(obj);
    }
  };


  const onChange = (e) => {
    const obj = { ...model, ...e };
    setModel(obj);
  };

  const onEditTypeChange = (e) => {
    const obj = { ...editPhone };
    obj.phoneType = e.target.value;
    setEditPhone(obj);
  };
  const onEditChange = (e) => {
    const obj = { ...editPhone };
    obj.number = e.target.value;
    setEditPhone(obj);
  };

  const updatePhone = async () => {

    const phone = {
      number: editPhone.number,
      phoneType: editPhone.phoneType
    };


    const response = await contactService.updateContactPhone(
      contact._id,
      editPhone._id,
      phone
    );
    const obj = { ...contact };
    obj.phones = obj.phones.map((ph) =>
      ph._id === response._id ? response : ph
    );
    setContact(obj);
    setEditPhoneKey(-1);
    setEditPhone(null);
  };
  const onEditPhone = async (phone, key) => {
    if (!phoneTypes || phoneTypes.length === 0) {
      const phTypes = await getPhoneTypes();
      setPhoneTypes(phTypes);
    }
    setEditPhone(phone);
    setEditPhoneKey(key);
  };
  const onDeletePhone = async (phone) => {
    await contactService.deleteContactPhone(contact._id, phone._id);
    const obj = { ...contact };
    obj.phones = obj.phones.filter((ph) => ph._id !== phone._id);
    setContact(obj);
  };

  const onCreateProviderPhone = async (phone) => {
    const entity = await contactService.createContactPhone(contact._id, phone);
    const obj = { ...contact };
    obj.phones = [...obj.phones, entity];
    setContact(obj);
    setCreatePhoneModal(false);
  };

  const editPhoneValid = () => {
    return (
      editPhone &&
      String(editPhone.number).length === 10 &&
      editPhone.phoneType
    );
  };

  const save = async () => {
    const data = {
      firstName: model.firstName.value,
      lastName: model.lastName.value,
      email: model.email.value,
    };
    const response = await contactService.updateContact(contact._id, data);
    setContact(response);
  };

  const onAddressSave = async (address) => {
    const data = {
      address: address.address,
      address2: address.address2,
      city: address.city,
      state: address.state,
      zipCode: address.zipCode,
      addressType: address.addressType
    };
    const response = await contactService.createContactAddress(
      contact._id,
      data
    );
    const obj = { ...contact };
    obj.addresses = [...obj.addresses, response];
    setContact(obj);
    setCreateAddressModal(false);
  };

  const onEditAddress = (address, key) => {
    setEditAddress(address);
    setEditAddressKey(key);
  };

  const onEditAddressTypeChange = (e) => {
    const obj = { ...editAddress };
    obj.addressType = e.target.value;
    setEditAddress(obj);
  };

  const onEditAddressChange = (e) => {
    const obj = { ...editAddress };
    obj[e.target.name] = e.target.value;
    setEditAddress(obj);
  };

  const updateAddress = async () => {
    const data = {
      _id: editAddress._id,
      address: editAddress.address,
      address2: editAddress.address2,
      city: editAddress.city,
      state: editAddress.state,
      zipCode: editAddress.zipCode,
      addressType: editAddress.addressType,
    };
    const response = await contactService.updateContactAddress(
      contact._id,
      editAddress._id,
      data
    );
    const obj = { ...contact };
    obj.addresses = obj.addresses.map((address) => {
      if (address._id === response._id) {
        return response;
      } else {
        return address;
      }
    });
    setContact(obj);
    setEditAddressKey(-1);
  };

  const onDeleteAddress = async (address, key) => {
    await contactService.deleteContactAddress(contact._id, address._id);
    const obj = {...contact}
    obj.addresses = obj.addresses.filter(addr => addr._id !== address._id);
    setContact(obj);
    setEditAddress(null);
    setEditAddressKey(-1);
  }

  return (
    <Paper elevation={24} className={classes.root}>
      {contact && (
        <Grid container>
          <Grid item md={6} xs={12}>
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              className={classes.gridContainer}
            >
              <Button
                onClick={() => history.goBack()}
                className={classes.backBtn}
                startIcon={<NavigateBeforeIcon />}
              >
                Back
              </Button>
              <Typography variant="h5">
                {contact.firstName} {contact.lastName}
              </Typography>
            </Grid>
            <Grid className={classes.gridContainer}>
              <form>
                <FormFieldsGenerator model={model} onChange={onChange} />
                <DialogActions>
                  <Link>Cancel</Link>
                  <Button
                    onClick={save}
                    disabled={!isModelValid(model)}
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                </DialogActions>
              </form>
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              className={classes.gridContainer}
            >
              <SmartphoneIcon color="primary" className={classes.icon} />
              <Typography variant="h6">Phone</Typography>
            </Grid>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableCell>
                    <Button
                      onClick={() => setCreatePhoneModal(true)}
                      startIcon={<AddIcon />}
                    >
                      Add
                    </Button>
                    <CreatePhone
                      open={createPhoneModal}
                      onClose={() => setCreatePhoneModal(false)}
                      onSave={onCreateProviderPhone}
                    />
                  </TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Number</TableCell>
                </TableHead>
                <TableBody>
                  {contact &&
                    contact.phones &&
                    contact.phones.map((phone, key) => (
                      <TableRow key={key}>
                        {editPhoneKey === key && (
                          <React.Fragment>
                            <TableCell style={{ display: "flex" }}>
                              <Button
                                size="small"
                                onClick={() => setEditPhoneKey(-1)}
                              >
                                Cancel
                              </Button>
                              <Button
                                size="small"
                                onClick={() => updatePhone()}
                                variant="contained"
                                color="primary"
                                disabled={!editPhoneValid()}
                              >
                                Save
                              </Button>
                            </TableCell>
                            <TableCell style={{ padding: 0 }}>
                              <Select
                                name="type"
                                value={editPhone.phoneType}
                                onChange={onEditTypeChange}
                              >
                                {phoneTypes &&
                                  phoneTypes.map((type) => (
                                    <MenuItem value={type.name}>
                                      {type.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </TableCell>
                            <TableCell>
                              <PhoneMask
                                value={editPhone.number}
                                onChange={onEditChange}
                              />
                            </TableCell>
                          </React.Fragment>
                        )}
                        {editPhoneKey !== key && (
                          <React.Fragment>
                            <TableCell>
                              <Button
                                size="small"
                                onClick={() => onEditPhone(phone, key)}
                              >
                                Edit
                              </Button>
                              <Button
                                size="small"
                                variant="contained"
                                color="error"
                                onClick={() => onDeletePhone(phone)}
                              >
                                Remove
                              </Button>
                            </TableCell>
                            <TableCell>
                              {phone.phoneType}
                            </TableCell>
                            <TableCell>
                              <PhoneMaskDisplay value={phone.number} />
                            </TableCell>
                          </React.Fragment>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            <div style={{ width: "100%" }}>
              <Grid
                container
                alignItems="center"
                className={classes.gridContainer}
              >
                <MyLocationIcon
                  color="primary"
                  style={{ marginRight: ".5rem" }}
                />
                <Typography variant="subtitle2">Address</Typography>
              </Grid>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableCell>
                      <Button onClick={() => setCreateAddressModal(true)}>
                        Add
                      </Button>
                      <CreateAddress
                        open={createAddressModal}
                        onSave={onAddressSave}
                        onClose={() => setCreateAddressModal(false)}
                      />
                    </TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Address</TableCell>
                    <TableCell>Address 2</TableCell>
                    <TableCell>City</TableCell>
                    <TableCell>State</TableCell>
                    <TableCell>Zip</TableCell>
                  </TableHead>
                  <TableBody>
                    {contact &&
                      contact.addresses &&
                      contact.addresses.map((address, key) => (
                        <TableRow key={key}>
                          {editAddressKey === key && (
                            <React.Fragment>
                              <TableCell style={{ display: "flex" }}>
                                <Button
                                  size="small"
                                  onClick={() => setEditAddressKey(-1)}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  size="small"
                                  onClick={() => updateAddress()}
                                  variant="contained"
                                  color="primary"
                                >
                                  Save
                                </Button>
                              </TableCell>
                              <TableCell style={{ padding: 0 }}>
                                <Select
                                  labelId="addressType"
                                  name="addressType"
                                  value={editAddress.addressType}
                                  onChange={onEditAddressTypeChange}
                                >
                                  {addressTypes &&
                                    addressTypes.map((type) => (
                                      <MenuItem value={type.name}>
                                        {type.name}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </TableCell>
                              <TableCell>
                                <TextField
                                  name="address"
                                  value={editAddress.address}
                                  fullWidth
                                  onChange={onEditAddressChange}
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  name="address2"
                                  value={editAddress.address2}
                                  fullWidth
                                  onChange={onEditAddressChange}
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  name="city"
                                  fullWidth
                                  onChange={onEditAddressChange}
                                  value={editAddress.city}
                                />
                              </TableCell>
                              <TableCell>
                                <StateDropdown
                                  name="state"
                                  fullWidth
                                  value={editAddress.state}
                                  onChange={onEditAddressChange}
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  name="zipCode"
                                  onChange={onEditAddressChange}
                                  fullWidth
                                  value={editAddress.zipCode}
                                />
                              </TableCell>
                            </React.Fragment>
                          )}
                          {editAddressKey !== key && (
                            <React.Fragment>
                              <TableCell>
                                <Button
                                  size="small"
                                  onClick={() => onEditAddress(address, key)}
                                >
                                  Edit
                                </Button>
                                <Button
                                  size="small"
                                  variant="contained"
                                  color="error"
                                  onClick={() => onDeleteAddress(address, key)}
                                >
                                  Delete
                                </Button>
                              </TableCell>
                              <TableCell>
                                {address?.addressType}
                              </TableCell>
                              <TableCell>{address.address}</TableCell>
                              <TableCell>{address.address2}</TableCell>
                              <TableCell>{address.city}</TableCell>
                              <TableCell>{address.state}</TableCell>
                              <TableCell>{address.zipCode}</TableCell>
                            </React.Fragment>
                          )}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

export default Contact;
