import React from 'react';
import {
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle, Divider,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField
} from "@material-ui/core";
import {FormFieldsGenerator, isModelValid} from "../../utils/forms";
import REPORT_MODEL from "./report.model";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { ObjectId } from 'bson';

const CreateReport = ({ open, save, onClose}) => {
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(REPORT_MODEL)));
  const [newParameter, setNewParameter] = React.useState({name: null, type: null, _id: null});
  const [parameters, setParameters] = React.useState([]);
  const [editParameter, setEditParameter] = React.useState();

  React.useEffect(() => {
    setModel(JSON.parse(JSON.stringify(REPORT_MODEL)));
    setParameters([]);
  }, [open]);

  const onModelChange = e => {
    setModel({...model, ...e});
  }

  const onSave = () => {
    const data = {
      _id: new ObjectId(),
      name: model.name.value,
      parameters: parameters
    };

    save(data);
  }

  const onNewParameterUpdate = e => {
    const obj = {...newParameter};
    obj[e.target.name] = e.target.value;
    setNewParameter(obj);
  }

  const onCreateParameter = () => {
   newParameter._id = new ObjectId();
    setParameters([...parameters, newParameter]);
    setNewParameter({name: null, type: null, _id: null});
  }

  const onEditParameter = parameter => {
    setEditParameter(parameter);
  }

  const onEditParameterChange = e => {
    const obj = {...editParameter};
    obj[e.target.name] = e.target.value;
    setEditParameter(obj);
  }

  const onUpdateEditParameter = () => {
    const obj = parameters.map(parameter => parameter._id === editParameter._id ? editParameter: parameter);
    setParameters(obj);
    setEditParameter(null);
  }

  return (
    <Dialog open={open}
            fullWidth={true}
            maxWidth={"lg"}
            onClose={onClose}>
      <DialogTitle>{"Create report"}</DialogTitle>
      <DialogContent style={{minHeight: '10rem'}}>
        <form noValidate>
          <FormFieldsGenerator model={model} onChange={onModelChange} />
          <div style={{marginTop: '.5rem'}}>
            <TableContainer>
              <Table size={"small"}>
                <TableHead>
                  <TableRow>
                    <TableCell>Parameters</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {parameters?.map(parameter =>
                    <TableRow>
                      <TableCell>
                        {parameter._id !== editParameter?._id ?
                          <>
                            <Button size={"small"}
                                    variant={"contained"}
                                    onClick={() => onEditParameter(parameter)}
                                    startIcon={<EditIcon />}>Edit</Button>
                          </>
                          :
                          <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <Button variant={"contained"} size={"small"}>Delete</Button>
                            <div>
                              <Button size={"small"}
                                      color={"primary"}
                                      disabled={!editParameter?.name || !editParameter?.type}
                                      onClick={onUpdateEditParameter}
                                      variant={"contained"}>Update</Button>
                              <Button size={"small"}
                                      color={"primary"}
                                      onClick={() => setEditParameter(null)}>Cancel</Button>
                            </div>
                          </div>
                        }
                      </TableCell>
                      <TableCell>
                        {parameter._id !== editParameter?._id ?
                          <span>{parameter.name}</span> :
                          <TextField variant={"outlined"}
                                     value={editParameter?.name || ''}
                                     size={"small"}
                                     name={"name"}
                                     onChange={onEditParameterChange}
                                     fullWidth />
                        }
                      </TableCell>
                      <TableCell>
                        {parameter._id !== editParameter?._id ?
                          <span>{parameter.type}</span> :
                          <TextField variant={"outlined"}
                                     value={editParameter?.type || ''}
                                     size={"small"}
                                     name={"type"}
                                     onChange={onEditParameterChange}
                                     fullWidth />
                        }
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell>
                      <Button size={"small"}
                              variant={"contained"}
                              color={"primary"}
                              disabled={!newParameter?.name || !newParameter?.type}
                              onClick={onCreateParameter}
                              startIcon={<AddIcon />}>Add</Button>
                      <Button size={"small"} color={"primary"}>Cancel</Button>
                    </TableCell>
                    <TableCell>
                      <TextField value={newParameter?.name || ''}
                                 name={"name"}
                                 onChange={onNewParameterUpdate}
                                 size={"small"}
                                 variant={"outlined"}
                                 fullWidth />
                    </TableCell>
                    <TableCell>
                      <TextField value={newParameter?.type || ''}
                                 name={"type"}
                                 onChange={onNewParameterUpdate}
                                 size={"small"}
                                 variant={"outlined"}
                                 fullWidth />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </form>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button color={"primary"} onClick={onClose}>Cancel</Button>
        <Button color={"primary"} onClick={onSave} variant={"contained"} disabled={!isModelValid(model)}>Save</Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateReport;
