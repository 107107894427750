import React from "react";
import {
  Dialog,
  Button,
  AppBar,
  Grid,
  Toolbar,
  IconButton,
  Typography,
  DialogContent,
  TablePagination,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TextField,
  TableBody,
  TableContainer,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import Transition from "../dialog/transition";
import * as insuranceService from "../../services/insurance.service";
import CreateInsurance from "./create.insurance";
import { convertToFilters } from "../../utils/filter.utils";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    paddingTop: "4rem",
  },
  appBar: {
    background: blue[500],
  },
  dialogContent: {
    marginTop: "4rem",
  },
}));

const sizeOptions = [5, 10, 25];


const filterTemplate = {
  name: {
    field: "name",
    minValue: 2,
    value: null,
    operator: "*=*",
  }
};

const InsuranceLookup = ({ onSelect, open, onClose, token }) => {
  const classes = useStyles();
  const [total, setTotal] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [filter, setFilter] = React.useState(JSON.parse(JSON.stringify(filterTemplate)));
  const [insurances, setInsurances] = React.useState([]);
  const [sortOrderBy, setSortOrderBy] = React.useState("name");
  const [sortOrderDirection, setSortOrderDirection] = React.useState("asc");
  const [
    openCreateInsuranceModal,
    setOpenCreateInsuarnceModal,
  ] = React.useState(false);

  React.useEffect(() => {
    if (open) {
      fetchData(page, size, {}, sortOrderBy, sortOrderDirection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const fetchData = async (pg, sz, filt, sort, sortDirection) => {
    const filters = convertToFilters(filt);
    const response = await insuranceService.getInsurances(
      pg,
      sz,
      filters,
      sort,
      sortDirection
    );
    if (!response?.error) {
      setInsurances(response.content);
      setSize(response.paginator.perPage);
      setPage(response.paginator.currentPage);
      setTotal(response.paginator.totalItems);
    }
  };

  const onChangeRowsPerPage = async event => {
    setSize(event.target.value);
    await fetchData(
      page,
      event.target.value,
      filter,
      sortOrderBy,
      sortOrderDirection
    );
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage + 1);
    await fetchData(newPage + 1, size, filter, sortOrderBy, sortOrderDirection);
  };
  const filterTextChange = async e => {
    const obj = { ...filter };
    obj[e.target.name].value = e.target.value;
    setFilter(obj);
    const filters = convertToFilters(obj);
    if (filters?.length > 0 || !e.target.value) {
      await fetchData(page, size, obj, sortOrderBy, sortOrderDirection);
    }
  };

  const createSortHandler = async (field) => {
    const sort = field;
    const sortDirection = sortOrderDirection === "asc" ? "desc" : "asc";
    setSortOrderBy(sort);
    setSortOrderDirection(sortDirection);
    await fetchData(page, size, filter, sort, sortDirection);
  };

  const selectInsurance = (insurance) => {
    if (onSelect) {
      onSelect(insurance);
    }
  };

  const clearFilters = async () => {
    const filt = JSON.parse(JSON.stringify(filterTemplate));
    setFilter(filt);
    setPage(1);
    setSize(10);
    setSortOrderBy('name');
    setSortOrderDirection('asc');
    await fetchData(1, 10, filt, "name", "asc");
  };


  const onCreateInsurance = async (insurance) => {
    const response = await insuranceService.createInsurance(insurance);
    const obj = [...insurances, response];
    setInsurances(obj);
    setOpenCreateInsuarnceModal(false);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={() => onClose()}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => onClose()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Search Insurances
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.dialogContent}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <div>
              <Button onClick={() => setOpenCreateInsuarnceModal(true)}>
                Create
              </Button>
              <Button startIcon={<HighlightOffIcon />}
                      onClick={clearFilters}
              >Clear Filters</Button>
              <CreateInsurance
                open={openCreateInsuranceModal}
                onClose={() => setOpenCreateInsuarnceModal(false)}
                onSave={onCreateInsurance}
              />
            </div>
            <TablePagination
              component="div"
              rowsPerPageOptions={sizeOptions}
              colSpan={5}
              count={total ? total : 0}
              rowsPerPage={size ? size : 0}
              page={page - 1}
              backIconButtonProps={{
                "aria-label": "previous page",
                disabled: page === 1 ? true : false,
              }}
              nextIconButtonProps={{
                "aria-label": "next page",
                disabled: page * size > total ? true : false,
              }}
              onChangeRowsPerPage={onChangeRowsPerPage}
              onChangePage={handleChangePage}
            ></TablePagination>
          </Grid>
          <TableContainer>
            <Table
              size="small"
              style={{ width: "100%" }}
              className={classes.table}
              id="pharmacy-table"
            >
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell className={classes.tableHeader}>
                    <TableSortLabel
                      active={sortOrderBy === "name"}
                      direction={sortOrderDirection}
                      onClick={() => createSortHandler("name")}
                    >
                      Name
                    </TableSortLabel>
                    <TextField
                      name="name"
                      margin="dense"
                      size="small"
                      placeholder="Search"
                      variant="outlined"
                      fullWidth={true}
                      value={filter?.name?.value || ''}
                      onChange={filterTextChange}
                    ></TextField>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {insurances &&
                  insurances.map((insurance) => (
                    <TableRow>
                      <TableCell>
                        <Button onClick={() => selectInsurance(insurance)}>
                          Select
                        </Button>
                      </TableCell>
                      <TableCell>{insurance.name}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default InsuranceLookup;
