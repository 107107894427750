
export const marStatuses = [
  {
    status: 'Given',
    label: 'G'
  },
  {
    status: 'Missed',
    label: 'M'
  },
  {
    status: 'Refused',
    label: 'R'
  },
  {
    status: 'Hold',
    label: 'H'
  },
  {
    status: 'Refused & Destructed',
    label: 'RD'
  },
  {
    status: 'Refused & Destructed',
    label: 'RD'
  },
  {
    status: 'Self Administered',
    label: 'SA'
  },
  {
    status: 'SA',
    label: 'SA'
  },
  {
    status: 'Not Administered',
    label: 'NA'
  },
  {
    status: 'NA',
    label: 'NA'
  },
  {
    status: 'Late',
    label: 'L'
  },
  {
    status: 'Assist',
    label: 'A'
  },
  {
    status: 'Supervise',
    label: 'S'
  }
]
