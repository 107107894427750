import apiUtil from '../utils/api.utils';
import configs from '../utils/configs';
import moment from "moment";

let searchNotesCancelToken;
export const getTenantLocationNotesPaginiate = async (
  tenantId,
  locationIds = null,
  category = null,
  author = null,
  startDate,
  endDate,
  residentId,
  page = 1,
  size = 1,
  sort = '-createdAt') => {
  try {

    if (typeof searchNotesCancelToken != typeof undefined) {
      searchNotesCancelToken.cancel("Cancel Request");
    }
    searchNotesCancelToken = apiUtil.getCancelToken();
    let url = `${configs.residentService}/api/v1.0/tenants/${tenantId}/notes/paginate?page=${page}&size=${size}&sort=${sort}`
    url += `&start=${moment(startDate).format('MM-DD-YYYY')}&end=${moment(endDate).format('MM-DD-YYYY')}`
    if (locationIds) {
      url += `&locationIds=${locationIds}`;
    }
    if (category) {
      url += `&category=${category}`;
    }
    if (author) {
      url += `&author=${author}`;
    }
    if (residentId) {
      url += `&residentId=${residentId}`;
    }
    const response = await apiUtil.client.get(url, {cancelToken: searchNotesCancelToken.token});
    return response.data;
  } catch (error) {
    if (apiUtil.isCancel(error)) {
      return {canceled: true};
    }
    return { error: true, message: error?.response }
  }
}

export const getTenantLocationNotes = async (tenantId,
                                             locationId = null,
                                             category = null,
                                             author = null,
                                             startDate,
                                             endDate,
                                             residentId) => {
  try {
    let url = `${configs.residentService}/api/v1.0/tenants/${tenantId}/notes`
    url += `?start=${moment(startDate).format('MM-DD-YYYY')}&end=${moment(endDate).format('MM-DD-YYYY')}`
    if (locationId) {
      url += `&locationId=${locationId}`;
    }
    if (category) {
      url += `&category=${category}`;
    }
    if (author) {
      url += `&author=${author}`;
    }
    if (residentId) {
      url += `&residentId=${residentId}`;
    }
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (error) {
    return { error: true, message: error }
  }
}

export const getTenantLocationNotesPdf = async (tenantId,
                                                locationIds = null,
                                                category = null,
                                                author = null,
                                                startDate,
                                                endDate,
                                                residentId,
                                                inCludeDx = false) => {
  try {
    let url = `${configs.reportService}/api/v1.0/generate/reports/tenants/${tenantId}/notes`
    url += `?start=${moment(startDate).format('MM-DD-YYYY')}&end=${moment(endDate).format('MM-DD-YYYY')}`
    if (locationIds) {
      url += `&locationIds=${locationIds}`;
    }
    if (category) {
      url += `&category=${category}`;
    }
    if (author) {
      url += `&author=${author}`;
    }
    if (residentId) {
      url += `&residentId=${residentId}`;
    }
    if (inCludeDx) {
      url += `&includeDx=${inCludeDx}`;
    }
    const response = await apiUtil.client.get(url, { responseType: 'blob' });
    return response.data;
  } catch (error) {
    return { error: true, message: error }
  }
}
