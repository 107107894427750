import React from 'react'

const InsuranceList = () => {
    return (
        <div>
            <h1>List</h1>
        </div>
    )
}

export default InsuranceList;