const model = {
  picture: {
    hidden: false,
    isFile: true,
    size: '80',
    title: 'User Photo',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  _id: {
    hidden: true,
    required: false,
    value: null,
    dirty: false,
    isValid: true,
  },
  firstName: {
    title: 'First Name',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  middleName: {
    title: 'Middle Name',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  lastName: {
    title: 'Last Name',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  dob: {
    title: 'Date of birth',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: true,
  },
  jobTitle: {
    title: 'Job title',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  gender: {
    title: 'Gender',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [
      { name: 'Male', value: 'Male' },
      { name: 'Female', value: 'Female' },
      { name: 'Male to Female', value: 'Male to Female' },
      { name: 'Female to Male', value: 'Female to Male' },
      { name: 'Transgender', value: 'Transgender' }
    ],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  email: {
    edit: false,
    title: 'Email',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  emailVerified: {
    hidden: true,
    title: 'Emailed Verified',
    required: false,
    value: null,
    isToggle: true,
    edit: false
  },
  blocked: {
    hidden: true,
    title: 'Blocked',
    required: false,
    value: null,
    isToggle: true,
    edit: false
  },
  nickname: {
    title: 'Nickname',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  username: {
    edit: false,
    title: 'Username',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  tags: {
    title: 'Tags',
    isTagsComponent: true,
    hidden: false,
    value: [],
    required: false,
  },
  address: {
    hidden: true,
    title: 'Address',
    required: false,
    value: [],
    dirty: false,
    isValid: true,
  },
  phones: {
    hidden: true,
    title: 'Phones',
    required: false,
    value: [],
    dirty: false,
    isValid: true,
  }
  ,roles: {
    hidden: true,
    title: 'Roles',
    required: false,
    value: [],
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  }/*,
  sub: {
    hidden: true,
    title: 'sub',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  }*/
  ,initials: {
    title: 'initials',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  isActive: {
    hidden: true,
    value: true
  }
  /*,isSystemAdmin: {
    hidden: true,
    title: 'System Admin',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
    isToggle: true,
  },*/
};
export default model;
