const filterTemplate = {
  id: {
    field: 'id',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  tags: {
    field: 'tags',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  firstName: {
    field: 'firstName',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  lastName: {
    field: 'lastName',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  sub: {
    field: 'sub',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  email: {
    field: 'email',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  username: {
    field: 'username',
    minValue: 3,
    value: null,
    operator: '*=*',
  }
};

export default filterTemplate;
