import React from 'react';
import Transition from "../../dialog/transition";
import {
  AppBar,
  Button,
  Dialog, DialogActions,
  DialogContent, Divider, FormControl, FormGroup,
  IconButton, InputLabel,
  makeStyles, MenuItem, Paper, Select, TextField,
  Toolbar as TB,
  Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import draftToHtml from 'draftjs-to-html';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {createNote} from "../../../services/resident.service";
import {getCategories} from "../../../services/note.category.service";
import {UserContext} from "../../../context/usercontext";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(.5),
    padding: theme.spacing(1)
  },
  dialogContent: {
    marginTop: '4rem',
    border: '1px solid',
    paddingTop: '2rem'
  }
}));

const CreateNoteDialog = ({open, residentId, onClose, onSave}) => {
  const classes = useStyles();
  const [subject, setSubject] = React.useState();
  const [note, setNote] = React.useState();
  const [noteDate, setNoteDate] = React.useState();
  const [category, setCategory] = React.useState();
  const [categories, setCategories] = React.useState();
  const { selectedTenant } = React.useContext(UserContext);

  React.useEffect(() => {
    const getData = async () => {
      const response = await getCategories(selectedTenant?._id);
      setCategories(response);
    }

    if (open) {
      if (!categories || categories.length === 0) {
        getData();
      }
      setNoteDate(new Date());
      setEditorState(EditorState.createEmpty());
      setSubject(null);
      setCategory(null);
    }
    // eslint-disable-next-line
  }, [open]);

  const [editorState, setEditorState] = React.useState(
    () => EditorState.createEmpty(),
  );

  const onChange = e => {
    setEditorState(e);
    const message = convertToRaw(e.getCurrentContent());
    setNote(message);
  }

  const save = async () => {
    const payload = {
      noteDate,
      subject,
      category,
      note,
      text: editorState?.getCurrentContent()?.getPlainText(),
      html: draftToHtml(note)
    }
    const response = await createNote(residentId, payload);
    onSave(response);
  }

  const isValid = () => {
    return category && subject && subject.length > 0 && editorState && editorState.getCurrentContent() &&
      editorState.getCurrentContent().hasText() && moment(noteDate).isValid();
  }

  const onCategoryChange = e => {
    setCategory(e.target.value);
  }

  const handelNoteDateChange = date => {
    setNoteDate(date);
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <TB>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Create Note
          </Typography>
        </TB>
      </AppBar>
      <DialogContent className={classes.dialogContent}>
        <Paper elevation={24} style={{padding: '1rem'}}>
          <form novalidate>
            <FormGroup>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDateTimePicker label={"Date"}
                                        required={true}
                                        value={noteDate}
                                        onChange={handelNoteDateChange}
                                        format={"MM/DD/YYYY hh:mm a"}
                />
              </MuiPickersUtilsProvider>
              <FormControl>
                <InputLabel required shrink>Category</InputLabel>
                <Select fullwidth value={category} onChange={onCategoryChange} displayEmpty fullWidth>
                  <MenuItem>Select a category</MenuItem>
                  {categories?.map(category => <MenuItem value={category.name}>{category.name}</MenuItem>)}
                </Select>
              </FormControl>
              <TextField required fullWidth
                         label="Subject"
                         value={subject} onChange={(e) => setSubject(e.target.value)}  />
            </FormGroup>
          </form>
        </Paper>
        <Paper elevation={24} style={{ marginTop: '.5rem', padding: '0 1rem', minHeight: '25rem'}}>
          <Editor editorState={editorState}
                  onEditorStateChange={text => onChange(text)}
                  hashtag={{
                    separator: ' ',
                    trigger: '#',
                  }}
          />
        </Paper>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button color="primar" onClick={onClose}>Close</Button>
        <Button color="primary" variant="contained" onClick={save} disabled={!isValid()}>Save</Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateNoteDialog;
