export const getAbbreviationFromName = (name, statuses) => {
  let status = getStatusFromName(name, statuses);
  if (status) {
    return status?.abbreviation ?? null;
  }
  return null;
}

export const getStatusFromName = (name, statuses) => {
  if (statuses) {
    let status = statuses.find(s => s.name === name && !s.required);
    return status ?? null;
  }

  return null;
}
