import React from 'react';
import {
  Paper,
  makeStyles,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  Button,
  TableBody,
  TableRow,
  TablePagination, Grid, ButtonGroup, Hidden,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CreateActivityLog from './create.activity.log';
import moment from 'moment';
import ActivityLog from './activity.log';
import TableLoadingIndicator from "../../../../utils/indicators/table.loading";
import {
  DateRangeSortFilterLabel,
  getStripedStyle,
  SortFilterLabel,
  SortFilterSelectLabel
} from "../../../utils/table.utils";
import model from "./activity.model";
import {ClearAllTwoTone} from "@material-ui/icons";
import {DeleteBtn} from "../../../utils/buttons/DeleteBtn";
import {MobileLogs} from "../mobile/mobile.logs";
import {ListLoading} from "../../../../utils/indicators/list.loading";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(.5),
  },
  deleteIcon: {
    color: theme.palette.error.main
  }
}));

const ResidentActivityLogs = ({ logs,
                                onSave,
                                onUpdateLog,
                                onDeleteLog,
                                date,
                                canUpdate,
                                filter,
                                sortOrderBy,
                                sortDirection,
                                createSortHandler,
                                filterTextChange,
                                onDateRangeChange,
                                loading,
                                users,
                                page,
                                size,
                                total,
                                onChangePage,
                                onChangeRowsPerPage,
                                rowsPerPageOptions,
                                onClearFilters,
                                ...args}) => {
  const classes = useStyles();
  const [createActivity, setCreateActivity] = React.useState(false);
  const [viewLogModal, setViewLogModal] = React.useState(false);
  const [selectedLog, setSelectedLog] = React.useState();

  const createActivityLog = (log) => {
    onSave(log);
    setCreateActivity(false);
  };

  const selectLog = (e) => {
    setSelectedLog(e);
    setViewLogModal(true);
  };

  const onUpdate = (id, log) => {
    onUpdateLog(id, log);
    setViewLogModal(false);
  };

  return (
    <Paper elevation={24} className={classes.root} {...args}>
      <Typography variant="h6">Activities</Typography>
      {canUpdate &&
      <Button startIcon={<AddIcon />}
              color={"primary"}
              variant={"contained"}
              onClick={() => setCreateActivity(true)}>
        Add New Log
      </Button>}
      <CreateActivityLog
        open={createActivity}
        onSave={createActivityLog}
        onClose={() => setCreateActivity(false)}
        date={date}
      />
      <ActivityLog
        open={viewLogModal}
        log={selectedLog}
        onClose={() => setViewLogModal(false)}
        onUpdate={onUpdate}
        canUpdate={canUpdate}
      />
      <Grid container justify={"flex-end"} alignItems={"center"}>
        <Hidden xsDown>
          <Button onClick={onClearFilters}
                  size={"small"}
                  startIcon={<ClearAllTwoTone />}
          >
            Clear Filters
          </Button>
        </Hidden>
        <TablePagination component={"div"}
                         count={total}
                         onChangePage={onChangePage}
                         onChangeRowsPerPage={onChangeRowsPerPage}
                         rowsPerPageOptions={rowsPerPageOptions}
                         page={page - 1}
                         rowsPerPage={size} />
      </Grid>
      <Hidden xsDown>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableCell/>
              <TableCell>
                <DateRangeSortFilterLabel onDateRangeChange={onDateRangeChange}
                                          createSortHandler={createSortHandler}
                                          sortDirection={sortDirection}
                                          sortOrderBy={sortOrderBy}
                                          filter={filter}
                                          fromDate={filter?.date?.value?.start}
                                          toDate={filter?.date?.value?.end}
                                          field={"date"}
                                          label={"Date"} />
              </TableCell>
              <TableCell>
                <SortFilterLabel filterTextChange={filterTextChange}
                                 createSortHandler={createSortHandler}
                                 sortDirection={sortDirection}
                                 sortOrderBy={sortOrderBy}
                                 filter={filter}
                                 field={"title"}
                                 label={"Title"}/>
              </TableCell>
              <TableCell>
                <SortFilterLabel filterTextChange={filterTextChange}
                                 createSortHandler={createSortHandler}
                                 sortDirection={sortDirection}
                                 sortOrderBy={sortOrderBy}
                                 filter={filter}
                                 field={"description"}
                                 label={"Description"}/>
              </TableCell>
              <TableCell>
                <SortFilterLabel filterTextChange={filterTextChange}
                                 createSortHandler={createSortHandler}
                                 sortDirection={sortDirection}
                                 sortOrderBy={sortOrderBy}
                                 filter={filter}
                                 field={"duration"}
                                 label={"duration"}/>
              </TableCell>
              <TableCell>
                <SortFilterLabel filterTextChange={filterTextChange}
                                 createSortHandler={createSortHandler}
                                 sortDirection={sortDirection}
                                 sortOrderBy={sortOrderBy}
                                 filter={filter}
                                 field={"attendies"}
                                 label={"Attendies"}/>
              </TableCell>
              <TableCell>
                <SortFilterSelectLabel onChange={filterTextChange}
                                       createSortHandler={createSortHandler}
                                       sortDirection={sortDirection}
                                       sortOrderBy={sortOrderBy}
                                       filter={filter}
                                       field={"cashUsed"}
                                       label={"Cash/Card Used"}
                                       options={model.cashUsed.dropDownItems}
                                       optionLabel={"label"}
                                       optionValue={"value"}
                />
              </TableCell>
              <TableCell>
                <SortFilterLabel filterTextChange={filterTextChange}
                                 createSortHandler={createSortHandler}
                                 sortDirection={sortDirection}
                                 sortOrderBy={sortOrderBy}
                                 filter={filter}
                                 field={"cashAmount"}
                                 label={"Amount Used"}/>
              </TableCell>
              <TableCell>
                <SortFilterSelectLabel onChange={filterTextChange}
                                       createSortHandler={createSortHandler}
                                       sortDirection={sortDirection}
                                       sortOrderBy={sortOrderBy}
                                       filter={filter}
                                       field={"refusal"}
                                       label={"Refusal"}
                                       options={model.refusal.dropDownItems}
                                       optionLabel={"label"}
                                       optionValue={"value"}
                />
              </TableCell>
              <TableCell>
                <SortFilterLabel filterTextChange={filterTextChange}
                                 createSortHandler={createSortHandler}
                                 sortDirection={sortDirection}
                                 sortOrderBy={sortOrderBy}
                                 filter={filter}
                                 field={"reason"}
                                 label={"Reason"}/>
              </TableCell>
              <TableCell>
                <SortFilterLabel filterTextChange={filterTextChange}
                                 createSortHandler={createSortHandler}
                                 sortDirection={sortDirection}
                                 sortOrderBy={sortOrderBy}
                                 filter={filter}
                                 field={"alternativeOffers"}
                                 label={"Alternative Offers"}/>
              </TableCell>
              <TableCell>
                <SortFilterSelectLabel onChange={filterTextChange}
                                       createSortHandler={createSortHandler}
                                       sortDirection={sortDirection}
                                       sortOrderBy={sortOrderBy}
                                       filter={filter}
                                       field={"staff"}
                                       label={"Staff"}
                                       options={users}
                                       optionLabelFunc={(item) => `${item?.firstName} ${item?.lastName}`}
                                       optionValue={"_id"}
                />
              </TableCell>
            </TableHead>
            {!loading &&
            <TableBody>
              {logs?.map((log, index) => (
                <TableRow style={getStripedStyle(index)}>
                  <TableCell>
                    <ButtonGroup size={"small"}>
                      <Button onClick={() => selectLog(log)}
                              variant={"contained"}
                              color={"primary"}
                      >
                        View
                      </Button>
                      {canUpdate &&
                        <DeleteBtn onClick={() => onDeleteLog(log._id)} />
                      }
                    </ButtonGroup>
                  </TableCell>
                  <TableCell>{moment(log.date).format('MM/DD/YYYY')}</TableCell>
                  <TableCell>{log.title}</TableCell>
                  <TableCell>{log.description}</TableCell>
                  <TableCell>{log.duration}</TableCell>
                  <TableCell>{log.attendies}</TableCell>
                  <TableCell>{log.cashUsed}</TableCell>
                  <TableCell>{log.cashAmount}</TableCell>
                  <TableCell>{log.refusal}</TableCell>
                  <TableCell>{log.reason}</TableCell>
                  <TableCell/>
                  <TableCell>{log.staff && `${log.staff.name}`}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            }
            {loading && <TableLoadingIndicator cols={12} rows={5} bodyOnly={true} /> }
          </Table>
        </TableContainer>
      </Hidden>
      <Hidden smUp>
        {!loading &&
        <MobileLogs logs={logs}
                    primaryText={(log) => log?.title}
                    secondaryText={(log) =>
                      <>
                        {moment(log?.date).format('MM/DD/YYYY h:mm a')}
                        <Typography variant={"subtitle2"}>{log?.description}</Typography>
                      </>}
                    canUpdate={canUpdate}
                    onDeleteLog={onDeleteLog}
                    selectLog={selectLog}
        />}
        {loading &&
        <ListLoading rows={4} />
        }
      </Hidden>
    </Paper>
  );
};

export default ResidentActivityLogs;
