import React from 'react';
import {StepActions} from "../step.actions";
import {IntakeContext} from "../../context/intake.context";
import {useStepStyles} from "../styles";
import {useHistory} from "react-router-dom";
import {setStepStatus} from "../../context/intake.actions";
import IntakeContacts from "../../../../contacts/intake.contacts";


export const ContactsStep = () => {
  const { dispatch, state } = React.useContext(IntakeContext);
  const classes = useStepStyles();
  const history = useHistory();

  const onNext = () => {
    setStepStatus(dispatch, 'contacts', true);
    history.push(`/intake/${state.id}/assessment`)
  }

  return (
    <div>
      <div>
        <IntakeContacts residentId={state?.id} />
      </div>
      <div className={classes.btnContainer}>
        <StepActions onNext={onNext}
                     onPrev={() => history.push(`/intake/${state.id}/address`)}
        />
      </div>
    </div>
  )
}
