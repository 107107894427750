import { Switch } from 'react-router-dom';
import PrivateRoute from '../PrivateRoute';
import { LocationsContextProvider } from './locations.context';
import LocationList from './location.list';
import Location from './location';

const Locations = () => {
  return (
    <LocationsContextProvider>
      <Switch>
        <PrivateRoute exact path="/locations" component={LocationList} />
        <PrivateRoute path="/locations/:id" component={Location} />
      </Switch>
    </LocationsContextProvider>
  );
};

export default Locations;
