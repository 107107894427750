import React from 'react';
import {
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
  DialogContent,
  DialogActions,
  Button,
  Divider,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Transition from '../../../dialog/transition';
import SubstanceModel from './substance.model';
import { isModelValid, FormFieldsGenerator } from '../../../../utils/forms';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: '4rem',
  },
}));

const CreateSubstanceLog = ({ open, date, onSave, onClose }) => {
  const classes = useStyles();
  const [model, setModel] = React.useState(
    JSON.parse(JSON.stringify(SubstanceModel))
  );

  React.useEffect(() => {
    const setDefaultDate = () => {
      const obj = JSON.parse(JSON.stringify(SubstanceModel));
      obj.date.dirty = false;
      obj.date.value = Date.now();
      setModel(obj);
    };

    if (open) {
      setDefaultDate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onCreate = () => {
    const log = {
      type: 'substance',
      date: model.date.value,
      incident: model.incident.value,
      comments: model.comments.value,
    };
    onSave(log);
  };

  const onFormChange = (e) => {
    const obj = { ...model, ...e };
    setModel(obj);
  };

  return (
    <Dialog open={open} fullScreen TransitionComponent={Transition}>
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => onClose()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Enter Substance Log
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.content}>
        <form noValidate>
          <FormFieldsGenerator model={model} onChange={onFormChange} />
        </form>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onCreate}
          disabled={!isModelValid(model)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateSubstanceLog;
