import React from 'react';
import {Button, TableCell, TableRow} from "@material-ui/core";
import {getStripedStyle} from "../../../utils/table.utils";
import moment from "moment/moment";
import {MEDICATION_ORDER_HISTORY_ACTIONS} from "./constants";

export const HistoryRow = ({row, index, onSelected}) => {

    const getFriendlyLabel = () => {
        switch (row?.action) {
            case MEDICATION_ORDER_HISTORY_ACTIONS.CREATE_MEDICATION_ORDER:
                return "Medication order created.";
            case MEDICATION_ORDER_HISTORY_ACTIONS.READ_MEDICATION_ORDER:
                return "Medication order viewed.";
            case MEDICATION_ORDER_HISTORY_ACTIONS.UPDATE_MEDICATION_ORDER:
                return "Medication order updated.";
            case MEDICATION_ORDER_HISTORY_ACTIONS.DELETE_MEDICATION_ORDER:
                return "Medication order deleted";
            case MEDICATION_ORDER_HISTORY_ACTIONS.CREATE_DOSAGE_INSTRUCTION:
                return "Dosage instruction created.";
            case MEDICATION_ORDER_HISTORY_ACTIONS.UPDATE_DOSAGE_INSTRUCTION:
                return "Dosage instruction updated.";
            case MEDICATION_ORDER_HISTORY_ACTIONS.DELETE_DOSAGE_INSTRUCTION:
                return "Dosage instruction deleted";
            case MEDICATION_ORDER_HISTORY_ACTIONS.CREATE_MEDICATION_ORDER_PHARMACY:
                return "Pharmacy created.";
            case MEDICATION_ORDER_HISTORY_ACTIONS.UPDATE_MEDICATION_ORDER_PHARMACY:
                return "Pharmacy updated";
            case MEDICATION_ORDER_HISTORY_ACTIONS.DELETE_MEDICATION_ORDER_PHARMACY:
                return "Pharmacy deleted.";
            case MEDICATION_ORDER_HISTORY_ACTIONS.CREATE_MEDICATION_ORDER_PHYSICIAN:
                return "Physician created.";
            case MEDICATION_ORDER_HISTORY_ACTIONS.UPDATE_MEDICATION_ORDER_PHYSICIAN:
                return "Physician updated";
            case MEDICATION_ORDER_HISTORY_ACTIONS.DELETE_MEDICATION_ORDER_PHYSICIAN:
                return "Physician deleted";
            case MEDICATION_ORDER_HISTORY_ACTIONS.CREATE_MEDICATION_ORDER_ALLERGIES:
                return "Allergies created.";
            case MEDICATION_ORDER_HISTORY_ACTIONS.UPDATE_MEDICATION_ORDER_ALLERGIES:
                return "Allergies updated.";
            case MEDICATION_ORDER_HISTORY_ACTIONS.DELETE_MEDICATION_ORDER_ALLERGIES:
                return "Allergies deleted";
            case MEDICATION_ORDER_HISTORY_ACTIONS.CREATE_MEDICATION_ORDER_MEDICATION:
                return "Medication created.";
            case MEDICATION_ORDER_HISTORY_ACTIONS.UPDATE_MEDICATION_ORDER_MEDICATION:
                return "Medication updated.";
            case MEDICATION_ORDER_HISTORY_ACTIONS.DELETE_MEDICATION_ORDER_MEDICATION:
                return "Medication deleted.";
            case MEDICATION_ORDER_HISTORY_ACTIONS.CREATE_MECICATION_ORDER_HOLD:
                return "Medication hold created.";
            case MEDICATION_ORDER_HISTORY_ACTIONS.UPDATE_MECICATION_ORDER_HOLD:
                return "Medication hold updated.";
            case MEDICATION_ORDER_HISTORY_ACTIONS.DELETE_MECICATION_ORDER_HOLD:
                return "Medication hold deleted."
            default:
                return row?.action;
        }
    }


    return (
        <TableRow key={row?._id} style={getStripedStyle(index)}>
            <TableCell>
                <Button variant={"text"}
                        color={"primary"}
                        onClick={() => onSelected(row)}
                >
                    {moment(row?.createdAt).format("MM/DD/YYYY h:mm:ss a")}
                </Button>
            </TableCell>
            <TableCell>
                {getFriendlyLabel()}
            </TableCell>
            <TableCell>
                {row?.user?.firstName} {row?.user?.lastName}
            </TableCell>
            <TableCell />
        </TableRow>
    )
}
