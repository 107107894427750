import React from 'react';
import {
  Button,
  Input,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import InputMask from 'react-input-mask';
import { ObjectId } from 'bson';
import { getPhoneTypes } from '../../services/phone.service';
import {
  createLocationPhone,
  deleteLocationPhone,
  updateLocationPhone,
} from '../../services/tenant.service';
import { GlobalNotificationContext } from '../notification/globalnotificationprovider';

const LocationPhones = ({ location, onCreate, onUpdate, onDelete, canUpdate }) => {
  const [phones, setPhones] = React.useState([]);
  const [editPhone, setEditPhone] = React.useState(null);
  const [createPhone, setCreatePhone] = React.useState(null);
  const [phoneTypes, setPhoneTypes] = React.useState(null);
  const { addNotification } = React.useContext(GlobalNotificationContext);

  React.useEffect(() => {
    if (!phoneTypes?.length || phoneTypes?.length === 0) {
      loadTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadTypes = async () => {
    const types = await getPhoneTypes();
    setPhoneTypes(types);
  };

  React.useEffect(() => {
    if (location) {
      setPhones(location.phones);
    } else {
      setPhones([]);
    }
  }, [location]);

  const addNewPhone = () => {
    const newPhone = { _id: String(new ObjectId()) };
    setCreatePhone(newPhone);
    setPhones([...phones, newPhone]);
  };

  const deletePhone = async (phoneId) => {
    const result = await deleteLocationPhone(location._id, phoneId);
    if (result) {
      setPhones(phones.filter((phone) => phone._id !== phoneId));
    }
    onDelete(location._id, phoneId);
    addNotification('Phone Deleted', 'info');
  };

  const isValid = () => {
    if (
      editPhone &&
      editPhone.number &&
      editPhone.number.length === 14 &&
      editPhone.type
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isCreatePhoneValid = () => {
    if (
      createPhone &&
      createPhone.number &&
      createPhone.number.length === 14 &&
      createPhone.type
    ) {
      return true;
    } else {
      return false;
    }
  };
  const createPhoneUpdate = (e) => {
    const obj = { ...createPhone };
    obj[e.target.name] = e.target.value;
    setCreatePhone(obj);
  };
  const editPhoneUpdate = (e) => {
    const obj = { ...editPhone };
    obj[e.target.name] = e.target.value;
    setEditPhone(obj);
  };

  const saveCreatePhone = async () => {
    const entity = await createLocationPhone(location._id, createPhone);
    const obj = phones.map((phone) =>
      phone._id === entity._id ? entity : phone
    );
    setPhones(obj);
    setCreatePhone(null);
    onCreate(location._id, entity);
    addNotification('Phone Created', 'success');
  };

  const saveEditPhone = async () => {
    const entity = await updateLocationPhone(
      location._id,
      editPhone._id,
      editPhone
    );
    const obj = phones.map((phone) =>
      phone._id === entity._id ? entity : phone
    );
    setPhones(obj);
    setEditPhone(null);
    onUpdate(location._id, entity._id, entity);
    addNotification('Phone Updated', 'success');
  };

  const cancelPhoneCreate = () => {
    if (createPhone) {
      setPhones(phones.filter((phone) => phone._id !== createPhone._id));
      setCreatePhone(null);
    }
  };

  return (
    <div>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Number</TableCell>
              <TableCell>Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {phones &&
              phones.map((phone) => (
                <>
                  {createPhone && createPhone._id === phone._id && (
                    <TableRow>
                      <TableCell>
                        <Button
                          size="small"
                          color="primary"
                          variant="contained"
                          disabled={!isCreatePhoneValid()}
                          onClick={saveCreatePhone}
                        >
                          Save
                        </Button>
                        <Button
                          size="small"
                          onClick={cancelPhoneCreate}
                          color="primary"
                        >
                          Cancel
                        </Button>
                      </TableCell>
                      <TableCell>
                        <InputMask
                          name="number"
                          size="small"
                          mask="(999) 999-9999"
                          value={createPhone.number}
                          onChange={createPhoneUpdate}
                        >
                          {() => <Input name="number" />}
                        </InputMask>
                      </TableCell>
                      <TableCell>
                        <Select
                          value={createPhone.type}
                          onChange={createPhoneUpdate}
                          name="type"
                        >
                          {phoneTypes &&
                            phoneTypes.map((type) => (
                              <MenuItem value={type.name}>{type.name}</MenuItem>
                            ))}
                        </Select>
                      </TableCell>
                    </TableRow>
                  )}
                  {editPhone && editPhone._id === phone._id && (
                    <TableRow>
                      <TableCell>
                        <Button
                          size="small"
                          color="primary"
                          variant="contained"
                          disabled={!isValid()}
                          onClick={saveEditPhone}
                        >
                          Save
                        </Button>
                        <Button
                          size="small"
                          onClick={() => setEditPhone(null)}
                          color="primary"
                        >
                          Cancel
                        </Button>
                      </TableCell>
                      <TableCell>
                        <InputMask
                          name="number"
                          size="small"
                          mask="(999) 999-9999"
                          value={editPhone.number}
                          onChange={editPhoneUpdate}
                        >
                          {() => <Input name="number" />}
                        </InputMask>
                      </TableCell>
                      <TableCell>
                        <Select
                          value={editPhone.type}
                          onChange={editPhoneUpdate}
                          name="type"
                        >
                          {phoneTypes &&
                            phoneTypes.map((type) => (
                              <MenuItem value={type.name}>{type.name}</MenuItem>
                            ))}
                        </Select>
                      </TableCell>
                    </TableRow>
                  )}
                  {(!editPhone || editPhone._id !== phone._id) &&
                    (!createPhone || createPhone._id !== phone._id) && (
                      <TableRow>
                        <TableCell>
                          {canUpdate &&
                          <>
                          <Button
                            color="primary"
                            size="small"
                            onClick={() => setEditPhone(phone)}
                            startIcon={<EditIcon />}
                          >
                            Edit
                          </Button>
                          <Button
                            color="primary"
                            size="small"
                            onClick={() => deletePhone(phone._id)}
                            startIcon={<DeleteIcon />}
                          >
                            Delete
                          </Button>
                            </>}
                        </TableCell>
                        <TableCell>{phone.number}</TableCell>
                        <TableCell>{phone.type}</TableCell>
                      </TableRow>
                    )}
                </>
              ))}
            {canUpdate && <TableRow>
              <TableCell colSpan={3}>
                <Button
                  size="small"
                  onClick={addNewPhone}
                  startIcon={<AddIcon />}
                >
                  Add Phone
                </Button>
              </TableCell>
            </TableRow>}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default LocationPhones;
