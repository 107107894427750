import React from 'react';
import {
  Button, ButtonGroup,
  Grid,
  Paper,
  Table, TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, TextField,
  Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import history from "../../utils/history";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import {createCategory, deleteCategory, getCategories, updateCategory} from "../../services/note.category.service";
import {UserContext} from "../../context/usercontext";
import {red} from "@material-ui/core/colors";
import useGlobalNotification from "../../hooks/notification.hook";

const useStlyes = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1)
  }
}));

const NoteCategories = () => {
  const classes = useStlyes();
  const [categories, setCategories] = React.useState([]);
  const [onCreate, setOnCreate] = React.useState(false);
  const [newCategory, setNewCategory] = React.useState();
  const { selectedTenant } = React.useContext(UserContext);
  const [editModel, setEditModel] = React.useState()
  const { addNotification } = useGlobalNotification();

  React.useEffect(() => {
    const getData = async () => {
      const response = await getCategories(selectedTenant?._id);

      setCategories(response === 'OK' ? []: response);
    }
    getData();
    // eslint-disable-next-line
  },[]);

  const onCreateClick = () => {
    setNewCategory(null);
    setOnCreate(true);
  }

  const onCancel = () => {
    setNewCategory(null);
    setOnCreate(false);
  }

  const onSave = async () => {
    const category = await createCategory(selectedTenant?._id, { name: newCategory });
    setNewCategory(null);
    setOnCreate(false);
    setCategories([...categories, category])
    addNotification("Category created", "success");
  }

  const editCategory = category => {
    setEditModel(category);
  }

  const cancelEdit = () => {
    setNewCategory(null);
    setOnCreate(false);
    setEditModel(null);
  }

  const onEditChange = e => {
    const obj = {...editModel};
    obj.name = e.target.value;
    setEditModel(obj);
  }

  const onUpdateCategory = async () => {
    const category = await updateCategory(selectedTenant?._id, editModel?._id, editModel);
    setCategories(categories.map(cat => cat._id === category._id ? category: cat));
    cancelEdit();
    addNotification("Category updated", "success");
  }

  const onDelete = async () => {
    const result = await deleteCategory(selectedTenant?._id, editModel?._id);
    if (result.ok) {
      setCategories(categories.filter(cat => cat._id !== editModel?._id));
      cancelEdit();
      addNotification("Category deleted", "warning");
    } else {
      addNotification("Unable to delete", "error");
    }
  }

  return (<Paper className={classes.root} elevation={24}>
    <Grid container alignItems={"center"}>
      <Button
        onClick={() => history.goBack()}
        className={classes.backBtn}
        startIcon={<NavigateBeforeIcon />}
      >
        Back
      </Button>
      <Typography variant={"h6"}>Note Categories</Typography>
    </Grid>
    <TableContainer>
      <Table size={"small"}>
        <TableHead>
          <TableRow>
            <TableCell width={200} />
            <TableCell>Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {categories?.map(category =>
            <TableRow>
              {editModel?._id !== category._id &&
              <>
                <TableCell>
                  <Button onClick={() => editCategory(category)} size={"small"} color={"primary"}>Edit</Button>
                </TableCell>
                <TableCell>{category.name}</TableCell>
              </>
              }
              {editModel?._id === category._id &&
              <>
                <TableCell>
                  <ButtonGroup size={"small"}>
                    <Button size={"small"}
                            disabled={editModel?.name?.length < 3}
                            onClick={onUpdateCategory}
                            color={"primary"}
                            variant={"contained"}>Save</Button>
                    <Button size={"small"}
                            style={{ color: '#fff', background: red[400]}}
                            onClick={onDelete}
                            variant={"contained"}>Delete</Button>
                    <Button size={"small"} color={"primary"} onClick={cancelEdit}>Cancel</Button>
                  </ButtonGroup>
                </TableCell>
                <TableCell>
                  <TextField fullWidth value={editModel?.name || ''}
                             onChange={onEditChange}
                             variant={"outlined"}
                             size={"small"}
                             InputProps={{ disableUnderline: true }}
                  />
                </TableCell>
              </>
              }
            </TableRow>
          )}
          <TableRow>
            {!onCreate &&
            <TableCell colSpan={2}>
              <Button size={"small"} variant={"contained"} color={"primary"} onClick={onCreateClick}>Create New</Button>
            </TableCell>}
            {onCreate &&
              <>
                <TableCell>
                  <Button disabled={!newCategory || newCategory.length < 3 }
                          size={"small"}
                          variant={"contained"} color={"primary"} onClick={onSave}>Save</Button>
                  <Button size={"small"} color={"primary"} onClick={onCancel}>Cancel</Button>
                </TableCell>
                <TableCell>
                  <TextField fullWidth
                             variant={"outlined"}
                             size={"small"}
                             value={newCategory} onChange={(e) => setNewCategory(e.target.value)} />
                </TableCell>
              </>
            }
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </Paper>)
}

export default NoteCategories;
