// src/index.js

import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "./react-auth0-spa";
import config from './utils/configs';
import history from "./utils/history";
import App from "./App";
import {createTheme} from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
import {CssBaseline, MuiThemeProvider} from "@material-ui/core";

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const theme = createTheme({
  palette: {
    primary: blue,
    secondary: {
      main: '#4caf50',
    },
  }
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
      <Auth0Provider domain={config.authDomain}
                     client_id={config.authClientId}
                     redirect_uri={window.location.origin}
                     audience={config.authAudience}
                     onRedirectCallback={onRedirectCallback}
                     useRefreshTokens={true}
      >
        <App />
      </Auth0Provider>
  </MuiThemeProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
