import React from 'react';
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  makeStyles,
  TextField,
  TableCell,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

const days = [
  { value: '0', title: 'Sunday' },
  { value: 1, title: 'Monday' },
  { value: 2, title: 'Tuesday' },
  { value: 3, title: 'Wendnesday' },
  { value: 4, title: 'Thursday' },
  { value: 5, title: 'Friday' },
  { value: 6, title: 'Saturday' },
];

const getStyles = makeStyles((theme) => ({
  root: {
    margin: '0 1rem 0 0',
    padding: theme.spacing(1),
    minWidth: '20rem',
  },
  form: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  formGroup: {
    marginTop: theme.spacing(1),
  },
  formControl: {
    width: '100%',
  },
}));

const WeeklyEvent = (props) => {
  const classes = getStyles();
  const [eventTemplate, setEventTemplate] = React.useState(
    props.eventTemplate || {}
  );
  const [passingQty, setPassingQty] = React.useState(
    props.eventTemplate ? props.eventTemplate.passingQty : null
  );

 const initDate = new Date();
 initDate.setHours(8, 0);

  const [selectedDate, handleDateChange] = React.useState(() => {
    if (props.eventTemplate && props.eventTemplate.hour) {
      let date = new Date();
      date.setHours(props.eventTemplate.hour, props.eventTemplate.minute);
      return date;
    } else {
      return initDate;
    }
  });



  React.useEffect(() => {
    setEventTemplate(props.eventTemplate || {});
  }, [props.eventTemplate]);

  const onDayChange = (e) => {
    const updated = { ...eventTemplate };
    updated.day = e.target.value;
    setEventTemplate(updated);
    emitUpdate(updated);
  };

  const onTimeChange = (date) => {
    handleDateChange(date);
    const dt = moment(date);
    const hour = moment(dt).format('H');
    const minute = moment(dt).format('mm');
    const updated = { ...eventTemplate, hour, minute };
    setEventTemplate(updated);
    emitUpdate(updated);
  };

  const onQtyChange = (e) => {
    if (!e.target.value || e.target.value === "." || !isNaN(e.target.value)) {
      let val = e.target.value.trim();
      setPassingQty(val);
      const updated = {...eventTemplate, passingQty: val};
      setEventTemplate(updated);
      emitUpdate(updated);
    }
  };

  const emitUpdate = (eventTemplate) => {
    if (props.onChange) {
      props.onChange(eventTemplate);
    }
  };

  return (
    <React.Fragment>
      <TableCell>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="day">Day</InputLabel>
          <Select
            style={{ width: '100%' }}
            value={eventTemplate.day || null}
            onChange={onDayChange}
            disabled={!props.edit}
            inputProps={{
              name: `day`,
              id: `day`,
            }}
          >
            {days &&
              days.map((day, i) => {
                return (
                  <MenuItem key={i} value={day.value}>
                    {day.title}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <TimePicker
            format="h:mm a (H:mm)"
            value={selectedDate}
            onChange={onTimeChange}
            ampm={false}
            disabled={!props.edit}
          />
        </MuiPickersUtilsProvider>
      </TableCell>
      <div hidden={!props.showQty}>
        <TableCell>
          <TextField disabled={!props.edit} value={passingQty} onChange={onQtyChange} />
        </TableCell>
      </div>
    </React.Fragment>
  );
};

export default WeeklyEvent;
