import apiUtil from "../utils/api.utils";
import config from "../utils/configs";
import { parseFiltersToJson } from "../utils/filter.utils";

const medicationOrderServiceApiUrl = `${config.medicationOrderService}/api/v1.0`;

export const getOrderStatuses = async () => {
  try {
    const response = await apiUtil.client.get(
      `${medicationOrderServiceApiUrl}/medicationorderstatuses`
    );
    return response.data;
  } catch (error) {
    return { error: true, message: error }
  }
};

export const getResidentMedicationOrders = async (
  residentId,
  page = 1,
  size = 10,
  filters = [],
  sort = "orderDate",
  sortDirection = "desc"
) => {
  try {
    let sortArg = sortDirection === "asc" ? "+" : "-";
    sortArg += sort;
    let url = `${medicationOrderServiceApiUrl}/medicationorders/resident/${residentId}?page=${page}&size=${size}&sort=${sortArg}`;

    if (filters && filters.length > 0) {
      filters = filters.filter((f) => f.value !== -1);
    }

    url += parseFiltersToJson(filters);

    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (error) {
    return {error: true, message: error };
  }
};

export const getMedicationOrder = async (medicationOrderId) => {
  try {
    const response = await apiUtil.client.get(
      `${medicationOrderServiceApiUrl}/medicationorders/${medicationOrderId}`
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};

export const deleteMedicationOrder = async (medicationOrderId) => {
  try {
    const response = await apiUtil.client.delete(
      `${medicationOrderServiceApiUrl}/medicationorders/${medicationOrderId}`
    );
    return response.data;
  } catch (error) {
    return { error: true, message: error };
  }
};

export const createMedicationOrder = async (medicationOrder) => {
  try {
    const response = await apiUtil.client.post(
      `${medicationOrderServiceApiUrl}/medicationorders`,
      medicationOrder
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};


let emarGetCancelToken;
export const getResidentEmarsByDate = async (residentId, date, offset) => {
  try {
    if (typeof emarGetCancelToken != typeof undefined) {
      emarGetCancelToken.cancel("Cancel Request");
    }
    emarGetCancelToken = apiUtil.getCancelToken();
    const response = await apiUtil.client.get(
      `${medicationOrderServiceApiUrl}/medicationorders/resident/${residentId}/emar?date=${date}&offset=${offset}`,
      {cancelToken: emarGetCancelToken.token}
    );
    return response.data;
  } catch (error) {
    if (apiUtil.isCancel(error)) {
      return { canceled: true };
    } else {
      return { error: true, message: error }
    }
  }
};

export const updateResidentEmar = async (
  residentId,
  medicationOrderEventId,
  emar
) => {
  try {
    const response = await apiUtil.client.put(
      `${medicationOrderServiceApiUrl}/medicationorders/resident/${residentId}/emar/${medicationOrderEventId}`,
      emar
    );
    return response.data;
  } catch (error) {
    return { error: true, message: error }
  }
};

export const createResidentEmar = async (residentId, emar) => {
  try {
    const response = await apiUtil.client.post(
      `${medicationOrderServiceApiUrl}/medicationorders/resident/${residentId}/emar`,
      emar
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};

export const updateResidentMedicationOrder = async (
  medicationOrderId,
  medicationOrder
) => {
  const response = await apiUtil.client.put(
    `${medicationOrderServiceApiUrl}/medicationorders/${medicationOrderId}`,
    medicationOrder
  );
  return response.data;
};

export const updateDosageInstruction = async (
  medicationOrderId,
  dosageInstructionId,
  dosageInstruction
) => {
  try {
    const response = await apiUtil.client.put(
      `${medicationOrderServiceApiUrl}/medicationorders/${medicationOrderId}/dosageinstructions/${dosageInstructionId}`,
      dosageInstruction
    );
    return response.data;
  } catch (error) {
    return { error: true, message: error.response.data }
  }
};

export const createDosageInstruction = async (
  medicationOrderId,
  dosageInstruction
) => {
  try {
    const response = await apiUtil.client.post(
      `${medicationOrderServiceApiUrl}/medicationorders/${medicationOrderId}/dosageinstructions/`,
      dosageInstruction
    );
    return response.data;
  } catch (err) {
    alert(err);
    return {};
  }
};

export const deleteDosageInstruction = async (
  medicationOrderId,
  dosageInstructionId
) => {
  try {
    const response = await apiUtil.client.delete(
      `${medicationOrderServiceApiUrl}/medicationorders/${medicationOrderId}/dosageinstructions/${dosageInstructionId}`
    );
    return response.data;
  } catch (err) {
    alert(err);
    return {};
  }
};


let prnCancelToken;
export const getResidentPrn = async (residentId, prnId) => {
  //Check if there are any previous pending requests
  if (typeof prnCancelToken != typeof undefined) {
    prnCancelToken.cancel("Cancel Request");
  }
  prnCancelToken = apiUtil.getCancelToken();
  try {
    const response = await apiUtil.client.get(
      `${medicationOrderServiceApiUrl}/medicationorders/resident/${residentId}/eprns/${prnId}`,
      { cancelToken: prnCancelToken.token }
    );
    return response.data;
  } catch (error) {
    if (apiUtil.isCancel(error)) {
      return { canceled: true };
    }
    return { error: true, message: error }
  }
};


let cancelToken;
export const getResidentPrnEvents = async (residentId, date, offset) => {
  //Check if there are any previous pending requests
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel("Cancel Request");
  }
  cancelToken = apiUtil.getCancelToken();
  try {
    const response = await apiUtil.client.get(
      `${medicationOrderServiceApiUrl}/medicationorders/resident/${residentId}/eprns?date=${date}&offset=${offset}`,
       { cancelToken: cancelToken.token }
    );
    return response.data;
  } catch (error) {
    if (apiUtil.isCancel(error)) {
      return { canceled: true };
    }
    return { error: true, message: error }
  }
};

export const getResidentPrnForDateRange = async (residentId, startDate, endDate) => {
  try {
    const response = await apiUtil.client.get(
      `${medicationOrderServiceApiUrl}/medicationorders/resident/${residentId}/eprns?start=${startDate}&end=${endDate}`);
    return response?.data;
  } catch (error) {
    return {error: true, message: error};
  }
}

export const updateResidentPrn = async (residentId, eventId, prn) => {
  try {
    const response = await apiUtil.client.put(
      `${medicationOrderServiceApiUrl}/medicationorders/resident/${residentId}/eprns/${eventId}`,
      prn
    );
    return response.data;
  } catch (error) {
    return {error: true, message: error};
  }
};

export const createPrnOutcome = async (residentId, eventId, outcome) => {
  try {
    const response = await apiUtil.client.post(
      `${medicationOrderServiceApiUrl}/medicationorders/resident/${residentId}/eprns/${eventId}/outcomes`,
      outcome
    );
    return response.data;
  } catch (error) {
    return { error: true, message: error};
  }
};

export const deletePrnOutcome = async (residentId, eventId, outcomeId) => {
  try {
    const response = await apiUtil.client.delete(
      `${medicationOrderServiceApiUrl}/medicationorders/resident/${residentId}/eprns/${eventId}/outcomes/${outcomeId}`);
    return response.data;
  } catch (error) {
    return { error: true, message: error};
  }
};

export const updatePrnOutcome = async (residentId, eventId, outcomeId, outcome) => {
  try {
    const response = await apiUtil.client.put(
      `${medicationOrderServiceApiUrl}/medicationorders/resident/${residentId}/eprns/${eventId}/outcomes/${outcomeId}`,
      outcome
      );
    return response.data;
  } catch (error) {
    return { error: true, message: error};
  }
};


export const createResidentPrnEvent = async (residentId, prn) => {
  try {
    const response = await apiUtil.client.post(
      `${medicationOrderServiceApiUrl}/medicationorders/resident/${residentId}/eprns`,
      prn
    );
    return response.data;
  } catch (error) {
    return { error: true, message: error}
  }
};

export const getResidentPrnOrders = async (residentId) => {
  try {
    const response = await apiUtil.client.get(
      `${medicationOrderServiceApiUrl}/medicationorders/resident/${residentId}/prnorders`
    );
    return response.data;
  } catch (err) {
    alert(err);
    return {};
  }
};

export const deleteMedicationEvent = async (residentId, eventId) => {
  try {
    const response = await apiUtil.client.delete(
      `${medicationOrderServiceApiUrl}/medicationorders/resident/${residentId}/emar/${eventId}`
    );
    return response.data;
  } catch (err) {
    alert(err);
    return {};
  }
};

export const updateMedicationOrderMedication = async (residentId, medicationOrderId, medicationId) => {
  try {
    const response = await apiUtil.client.put(
      `${medicationOrderServiceApiUrl}/medicationorders/${medicationOrderId}/medication`,
      { residentId, medicationOrderId, medicationId }
    );
    return response.data;
  } catch (error) {
    return { error: true, message: error};
  }
}

export const updateMedicationOrderAllergies = async (residentId, medicationOrderId, allergies) => {
  try {
    const response = await apiUtil.client.put(
      `${medicationOrderServiceApiUrl}/medicationorders/${medicationOrderId}/allergies`, { allergies });
    return response.data;
  } catch (error) {
    return { error: true, message: error};
  }
}

export const updateMedicationOrderPhysician = async (residentId, medicationOrderId, physicianId) => {
  try {
    const response = await apiUtil.client.put(
      `${medicationOrderServiceApiUrl}/medicationorders/${medicationOrderId}/physician`,
      { residentId, medicationOrderId, physicianId }
    );
    return response.data;
  } catch (error) {
    return { error: true, message: error};
  }
}

export const updateMedicationOrderPharmacy = async (residentId, medicationOrderId, pharmacyId) => {
  try {
    const response = await apiUtil.client.put(
      `${medicationOrderServiceApiUrl}/medicationorders/${medicationOrderId}/pharmacy`,
      { residentId, medicationOrderId, pharmacyId }
    );
    return response.data;
  } catch (error) {
    return { error: true, message: error};
  }
}


export const updateMedicationOrderHold = async (medicationOrderId, holdId, hold) => {
  try {
    const response = await apiUtil.client.put(
      `${medicationOrderServiceApiUrl}/medicationorders/${medicationOrderId}/hold/${holdId}`,
      hold
    );
    return response?.data;
  } catch (error) {
    return { error: true, message: error}
  }
}

export const createMedicationOrderHold = async (medicationOrderId, { startDate, endDate, comment}) => {
  try {
    const response = await apiUtil.client.post(
      `${medicationOrderServiceApiUrl}/medicationorders/${medicationOrderId}/hold`,
      {
        startDate,
        endDate,
        comment
      }
    );
    return response?.data;
  } catch (error) {
    return { error: true, message: error}
  }
}

export const deleteMedicationOrderHold = async (medicationOrderId, holdId) => {
  try {
    const response = await apiUtil.client.delete(
      `${medicationOrderServiceApiUrl}/medicationorders/${medicationOrderId}/hold/${holdId}`);
    return response?.data;
  } catch (error) {
    return { error: true, message: error}
  }
}

