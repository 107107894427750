import React from 'react';
import {
    AppBar,
    Button, ButtonGroup,
    Dialog, DialogActions,
    DialogContent,
    IconButton,
    makeStyles,
    Tab,
    Tabs,
    Toolbar,
    Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
    ACTIONS, createTimeEntry, deleteTimeEntryAction,
    TAB_VALUES,
    TimeEntryContext,
    toggleViewTimeClock, updateTimeEntryAction
} from "../../../context/TimeEntryContext/time.entry.context";
import {enhanceTotalMinutes, getUserTimeEntryForWeek} from "../../../services/timeentry.service";
import {TimeEntryMobileList} from "../list/time.entry.mobile.list";
import {ChevronLeft, Save} from "@material-ui/icons";
import {TimeEntryMobile} from "../list/time.entry.mobile";
import {DeleteBtn} from "../../utils/buttons/DeleteBtn";
import ConfirmDialog from "../../utils/dialogs/confirm.dialog";
import {TimeEntryMobileCreate} from "../list/time.entry.mobile.create";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(7)
    },
    dialogContainer: {
        padding: theme.spacing(1, 0)
    }
}));

const TimeEntryMobileModal = () => {
    const classes = useStyles();
    const {state, dispatch} = React.useContext(TimeEntryContext);
    const {viewTimeClockTable} = state;

    React.useEffect(() => {
        if (viewTimeClockTable && state?.week && state?.year) {
            dispatch({type: ACTIONS.SET_VIEW_TIME_ENTRY_CREATE_MODAL, payload: false});
            dispatch({type: ACTIONS.SET_TIME_ENTRY, payload: false});
            dispatch({type: ACTIONS.TOGGLE_LOADING, payload: true});
            getWeeklyTimeSheets().then(() => {
                dispatch({type: ACTIONS.TOGGLE_LOADING, payload: false});
            });
        }
        // eslint-disable-next-line
    }, [viewTimeClockTable, state?.week, state?.year])

    const getWeeklyTimeSheets = async () => {
        dispatch({type: ACTIONS.TOGGLE_LOADING, payload: true});
        dispatch({type: ACTIONS.TOGGLE_LOADING, payload: true});
        let timeEntriesResponse = await getUserTimeEntryForWeek(state?.user?._id, state?.year, state?.week);
        timeEntriesResponse = enhanceTotalMinutes(timeEntriesResponse);
        if (!timeEntriesResponse?.error) {
            dispatch({type: ACTIONS.SET_TIME_ENTRIES, payload: timeEntriesResponse});
        } else {
            dispatch({type: ACTIONS.TOGGLE_ERROR, payload: true});
        }
        dispatch({type: ACTIONS.TOGGLE_LOADING, payload: false});
    }

    const onClose = () => {
        toggleViewTimeClock(dispatch, false);
    }

    const onTabChange = (event, newValue) => {
        dispatch({type: ACTIONS.SET_TAB, payload: newValue})
    }

    const clearSelected = () => {
        dispatch({type: ACTIONS.SET_TIME_ENTRY, payload: null});
    }

    const handleDeleteItem = timeEntry => {
        dispatch({type: ACTIONS.SET_VIEW_CONFIRM_DELETE, payload: true});
    }

    const onCloseDeleteConfirm = () => {
        dispatch({type: ACTIONS.SET_VIEW_CONFIRM_DELETE, payload: false});
    }

    const onConfirmedDelete = async () => {
        await deleteTimeEntryAction(dispatch, state?.selectedTimeEntry?._id);
        dispatch({type: ACTIONS.SET_TIME_ENTRY, payload: null});
        dispatch({type: ACTIONS.SET_VIEW_CONFIRM_DELETE, payload: false});
    }

    const handleCreate = async timeEntry => {
        timeEntry.user = state?.user?._id;
        await createTimeEntry(dispatch, timeEntry);
    }

    const handleUpdate = async () => {
        await updateTimeEntryAction(dispatch, state?.editTimeEntryModel);
        dispatch({type: ACTIONS.SET_TIME_ENTRY, payload: null});
    }

    const onClearSelected = () => {
        dispatch({type: ACTIONS.SET_TIME_ENTRY, payload: null});
    }

    const onClearViewTimeEntryCreateModal = () => {
        dispatch({type: ACTIONS.SET_VIEW_TIME_ENTRY_CREATE_MODAL, payload: false});
    }

    return (
        <Dialog open={viewTimeClockTable}
                fullScreen={true}
                className={classes.root}
        >
            <AppBar>
                {!state?.viewTimeEntryCreateModal && !state?.selectedTimeEntry &&
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={onClose}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant="h6">Time Entries</Typography>
                    </Toolbar>
                }
                {!state?.viewTimeEntryCreateModal && state?.selectedTimeEntry &&
                    <Toolbar
                        onClick={onClearSelected}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="close"
                        >
                            <ChevronLeft />
                        </IconButton>
                        <Typography variant="h6">Back to list</Typography>
                    </Toolbar>
                }
                {state?.viewTimeEntryCreateModal &&
                    <Toolbar
                        onClick={onClearViewTimeEntryCreateModal}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="close"
                        >
                            <ChevronLeft />
                        </IconButton>
                        <Typography variant="h6">Back to list</Typography>
                    </Toolbar>
                }
            </AppBar>
            {!state?.viewTimeEntryCreateModal && !state?.selectedTimeEntry &&
                <DialogContent className={classes.dialogContainer}>
                    <Tabs value={state?.tab}
                          onChange={onTabChange}
                          variant={"fullWidth"}
                    >
                        <Tab value={TAB_VALUES.TIMECLOCK} label={"Time Clock"}/>
                        {/*<Tab value={TAB_VALUES.SEARCH} label={"Search"}/>*/}
                    </Tabs>
                    {state?.tab === TAB_VALUES.TIMECLOCK && <TimeEntryMobileList/>}
                    {state?.tab === TAB_VALUES.SEARCH && <div>SEARCH</div>}
                    {/*<TimeEntryMobileCreate onSave={handleCreate} />*/}
                </DialogContent>
            }
            {state?.viewTimeEntryCreateModal && <TimeEntryMobileCreate onSave={handleCreate} />}
            {!state?.viewTimeEntryCreateModal && state?.selectedTimeEntry &&
                <>
                    <DialogContent className={classes.dialogContainer} dividers>
                        <TimeEntryMobile />
                    </DialogContent>
                    <DialogActions>
                        <ButtonGroup fullWidth>
                            <DeleteBtn onClick={handleDeleteItem} />
                            <Button variant={"text"} onClick={clearSelected}>Close</Button>
                            <Button variant={"contained"}
                                    startIcon={<Save />}
                                    onClick={handleUpdate}
                                    color={"primary"}>
                                Update
                            </Button>
                        </ButtonGroup>
                    </DialogActions>
                    <ConfirmDialog open={state?.viewConfirmDelete}
                                   onClose={onCloseDeleteConfirm}
                                   onConfirm={onConfirmedDelete}
                                   message={"Click delete again to delete this time entry item."}
                                   title={"Delete time entry"} />
                </>
            }
        </Dialog>
    )
}

export default TimeEntryMobileModal;
