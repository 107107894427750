import React from 'react';
import {
  Button, Divider, Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {CreateCalendar} from "./create.calendar";
import {EditCalendar} from "./edit.calendar";
import {ListLoading} from "../../utils/indicators/list.loading";
import {
  AllInboxRounded,
  RadioButtonChecked,
  RadioButtonUnchecked,
  VisibilityOffRounded
} from "@material-ui/icons";
import {ACTIONS, CalendarContext} from "./context/calendar.context";
import {
  getCalendarTypesAction,
  selectCalendarTypeToEditAction, updateCalendarFilterTypesAction
} from "./context/calendar.actions";
import {RetryMessage} from "../notification/retry.message";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1)
  },
  deleteBtn: {
    backgroundColor: theme.palette.error.light
  },
  content: {
    padding: theme.spacing(0, 1)
  }
}));


export const CalendarTypes = () => {
  const classes = useStyles();
  const { state, dispatch } = React.useContext(CalendarContext);

  const calendarTypesFilterChanged = type => {
    updateCalendarFilterTypesAction(dispatch, type);
  }

  const onRetry = async () => {
    await getCalendarTypesAction(dispatch);
  }

  return (
    <div className={classes.content}>
      <CreateCalendar />
      <EditCalendar />
      <List>
        <Divider />
        <ListItem button
                  disableGutters
                  divider
                  onClick={() => dispatch({type: ACTIONS.SET_VIEW_CREATE_CALENDAR_TYPE_MODAL, payload: true})}
        >
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary={"Add Type"} />
        </ListItem>
        <ListItem button
                  divider
                  disableGutters
                  onClick={() => dispatch({type: ACTIONS.TOGGLE_FILTER_VIEW_ALL})}
        >
          <ListItemIcon>
            {!state.viewAllFilter && <AllInboxRounded />}
            {state.viewAllFilter && <VisibilityOffRounded />}
          </ListItemIcon>
          <ListItemText primary={`${state.viewAllFilter ? "Hide": "View"} All`} />
        </ListItem>
        {state?.loadingTypesError && !state?.loadingTypes &&
        <RetryMessage message={"Unable to get calendar types"}
                      severity={"error"}
                      onRetry={onRetry}
                      />
        }
        {state.loadingTypes && !state.loadingTypesError &&
        <ListLoading rows={5} />
        }
        {!state.loadingTypesError && !state.loadingTypes && state?.calendarTypes?.map((type, i) =>
        <ListItem button
                  divider
                  disableGutters
                  onClick={() => calendarTypesFilterChanged(type)}>
          <Hidden smDown>
          <ListItemIcon>
            {type?.checked ? <RadioButtonChecked />: <RadioButtonUnchecked />}
          </ListItemIcon>
          </Hidden>
          <ListItemText primary={type?.name} secondary={type.description} />
          <ListItemSecondaryAction>
            <Button color="primary"
                    onClick={() => selectCalendarTypeToEditAction(dispatch, type)}
                    variant={"outlined"}>
              Edit
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
        )}
      </List>
    </div>
  )
}
