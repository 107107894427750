import React from 'react';
import {
  Paper,
  makeStyles,
  DialogActions,
  Button,
} from '@material-ui/core';
import {
  getMedicationById,
  getMedicationUnits,
  getDosageForms,
  getRoutes,
  updateMedication
} from '../../services/medication.service';
import modelTemplate from './model';
import FormFieldsGenerator from '../../utils/forms/form.fields.generator';
import TableLoadingIndicator from "../../utils/indicators/table.loading";
import {PageTitle} from "../utils";
import SaveIcon from "@material-ui/icons/Save";
import {RetryMessage} from "../notification/retry.message";
import {useParams} from "react-router-dom";
import useHasRole from "../../hooks/user.role.hook";
import useGlobalNotification from "../../hooks/notification.hook";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(.5, 0),
    padding: theme.spacing(1),
  },
  form: {
    width: '100%',
  },
  headerContainer: {
    marginBottom: theme.spacing(2),
  },
}));

const Medication = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(modelTemplate)));
  const [units, setUnits] = React.useState([]);
  const [dosageForms, setDosageForms] = React.useState([]);
  const [routes, setRoutes] = React.useState([]);
  const { addNotification } = useGlobalNotification();
  const { checkPermission } = useHasRole();
  const canEdit = checkPermission("GLOBAL_MEDICATION_UPDATE");
  React.useEffect(() => {
    if (id) {
      getMedication(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const initDropDowns = async () => {
    if (!units || units.length === 0) {
      const response = await Promise.all([
        getMedicationUnits(),
        getDosageForms(),
        getRoutes(),
      ]);
      setUnits(response[0]);
      setDosageForms(response[1]);
      setRoutes(response[2]);
      return [response[0], response[1], response[2]];
    } else {
      return [units, dosageForms, routes];
    }
  };

  const merge = async (medication) => {
    const vals = await initDropDowns();
    const obj = { ...model };
    obj.tradeName.value = medication?.tradeName;
    obj.genericName.value = medication?.genericName;
    obj.dosageForm.value = medication?.dosageForm?._id;
    obj.strength.value = medication.strength;
    obj.dosageForm.dropDownItems = vals[1];
    obj.isActive.value = String(medication?.isActive);
    obj.isNarcotic.value = String(medication?.isNarcotic);
    obj.isSupply.value = String(medication?.isSupply);
    obj.ndcCode.value = medication?.ndcCode;
    obj.route.value = medication?.route?._id;
    obj.route.dropDownItems = vals[2];
    obj.unit.dropDownItems = vals[0];
    obj.unit.value = medication?.unit?._id;
    setModel(obj);
  };

  const getMedication = async (medicationId) => {
    setLoading(true);
    setError(false);
    const response = await getMedicationById(medicationId);
    if (!response?.error) {
      merge(response);
    } else {
      setError(true);
    }
    setLoading(false);
  };

  const onChange = (e) => {
    const obj = { ...model, ...e };
    setModel(obj);
  };

  const retry = async () => {
    await getMedication(id);
  }

  const saveMedication = async () => {
    let data = {};
    Object.keys(model).forEach(key => {
      data[key] = model[key].value;
    });
    const response = updateMedication(id, data);
    if (!response?.error) {
      addNotification("Medication updated", "success");
    } else {
      addNotification("Unable to update medication", "warning");
    }
  }

  return (
    <Paper className={classes.root} elevation={24}>
      {loading && <TableLoadingIndicator rows={10} cols={1} />}
      {!loading && error &&
        <RetryMessage message={"Unable to load medcation, try again"}
                      severity={"error"}
                      onRetry={retry}
        />
      }
      {!loading &&
        <div>
          <PageTitle title={"Medication"} />
          <form className={classes.form}>
            <FormFieldsGenerator model={model} onChange={onChange} />
          </form>
          {canEdit &&
            <DialogActions>
              <Button>Cancel</Button>
              <Button variant={"contained"}
                      color={"primary"}
                      onClick={saveMedication}
                      startIcon={<SaveIcon/>}
              >
                Save
              </Button>
            </DialogActions>
          }
        </div>
      }
    </Paper>
  );
};

export default Medication;
