export const OutcomeModel = {
  _id: {
    hidden: true,
      title: "Medication Name",
      required: true,
      value: null,
      dirty: false,
      isValid: true,
  },
  entryDate: {
    title: "Date",
      required: true,
      value: new Date(),
      dirty: false,
      isValid: true,
      time: true
  },
  user: {
    title: "User",
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [],
    dropDownItemValue: "_id",
    dropDownItemLabel: "name",
    date: false
  },
  comment: {
    title: "Outcome",
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiline: true,
    multilineRows: 5
  },
}
