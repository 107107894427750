let reference = {};

const model = {
  type: {
    title: 'type',
    required: true,
    value: 'type',
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  title: {
    title: 'Title',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  description: {
    title: 'Description',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  date: {
    title: 'Date',
    required: true,
    value: Date.now(),
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: true,
  },
  duration: {
    title: 'Duration',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  attendies: {
    title: 'Attendies',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  cashUsed: {
    title: 'Cash/Credit used',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' },
    ],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'label',
    date: false,
  },
  cashAmount: {
    title: 'Cash/Credit amount',
    required: reference.cashUsed && reference.cashUsed.value ? true : false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  refusal: {
    title: 'Refusal',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' },
    ],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'label',
    date: false,
  },
  reason: {
    title: 'Reason',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  alternativeOffers: {
    title: 'Alternative Offers',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
};

reference = { ...model };

export default model;
