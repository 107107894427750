import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, IconButton, makeStyles} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import TableLoadingIndicator from "../../../../../utils/indicators/table.loading";
import {PDFViewer} from "@react-pdf/renderer";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment-timezone";
import {searchResidentLogs} from "../../../../../services/log.service";
import {convertToFilters} from "../../../../../utils/filter.utils";
import {ResidentContext} from "../../../../../context/residentcontext";
import {BMLogReport} from "./BMLogReport";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    dialogContent: {
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

const FilterTemplate = {
    date: {
        field: 'date',
        minValue: 10,
        value: {
            start: moment().startOf('month').subtract(6, 'month'),
            end: moment().endOf('month')
        },
        operator: 'between',
    },
    type: {
        field: 'type',
        minValue: 1,
        value: "bm",
        operator: '==',
    },
}

const BMPrintModal = ({open, onClose}) => {
    const classes = useStyles();
    const [startDate, setStartDate] = React.useState(moment().startOf("month"));
    const [endDate, setEndDate] = React.useState(moment().endOf("month"));
    const [data, setData] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);
    const { resident } = React.useContext(ResidentContext);

    React.useEffect(() => {
        if (open && resident && moment(startDate).isValid() && moment(endDate).isValid()) {
            getData(resident?._id, startDate, endDate).then(() => {});
        }

        // eslint-disable-next-line
    }, [open, resident, startDate, endDate]);

    const getData = async (id, start, end) => {
        setLoading(true);
        setError(false);
        let filters = JSON.parse(JSON.stringify(FilterTemplate));
        filters.date.value.start = moment(startDate).startOf('day');
        filters.date.value.end = moment(endDate).endOf('day');
        filters = convertToFilters(filters);
        const response = await searchResidentLogs(id, 1, 500, filters);
        if (!response?.error) {
            setData(response?.content);
        } else {
            setError(true);
        }
        setLoading(false);
    }


    const onStartDateChange = date => {
        setData(null);
        setStartDate(date);
    }

    const onEndDateChange = date => {
        setData(null)
        setEndDate(date);
    }

    return (
        <Dialog open={open}
                maxWidth={"lg"}
                fullWidth
                onClose={onClose}>
            <DialogTitle>
                {"Vitals Report"}
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers style={{minHeight: '70vh'}}>
                {!error && loading &&
                    <TableLoadingIndicator cols={10} rows={10} />
                }
                {!error && !loading && data &&
                    <PDFViewer style={{width: '100%', height: '70vh'}}>
                        <BMLogReport resident={resident}
                                     logs={data}
                                     startDate={startDate}
                                     endDate={endDate} />
                    </PDFViewer>
                }
            </DialogContent>
            <DialogActions>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <FormGroup row={true}>
                        <KeyboardDatePicker
                            label={"Start"}
                            value={startDate}
                            onChange={onStartDateChange}
                            format="MM/DD/YYYY" />
                        <KeyboardDatePicker
                            value={endDate}
                            label={"End"}
                            onChange={onEndDateChange}
                            format="MM/DD/YYYY" />
                    </FormGroup>
                </MuiPickersUtilsProvider>
            </DialogActions>
        </Dialog>
    )
}

export default BMPrintModal;
