import apiUtil from '../utils/api.utils';
import config from '../utils/configs';

const medicationOrderServiceApiUrl = `${config.medicationOrderService}/api/v1.0`;

let freqs = []

export const getFrequencies = async () => {
    try {
      if (!freqs || freqs?.length < 1) {
        const response = await apiUtil.client.get(`${medicationOrderServiceApiUrl}/frequencies?size=100`);
        freqs = response.data;
        return response.data;
      } else {
        return freqs;
      }
    } catch (error) {
       return {error: true, message: error };
    }
}

let frequencyCancelToken;

export const getFrequenciesV2 = async () => {
  try {
    if (typeof frequencyCancelToken != typeof undefined) {
      frequencyCancelToken.cancel("Cancel Request");
    }
    frequencyCancelToken = apiUtil.getCancelToken();
    if (!freqs || freqs?.length < 1) {
      const response = await apiUtil.client.get(`${medicationOrderServiceApiUrl}/frequencies?size=100`,
        {cancelToken: frequencyCancelToken});
      freqs = response.data;
      return response.data;
    } else {
      return freqs;
    }
  } catch (error) {
    console.log('Freq Error', error);
    if (apiUtil.isCancel(error)) {
      return { canceled: true };
    } else {
      return {error: true, message: error };
    }
  }
}
