import React from 'react';
import moment from 'moment';
import {
  Button,
  Card, CardActions, CardContent, makeStyles,
  Typography
} from "@material-ui/core";
import gitInfo from '../_git_commit.json';
import {CloudDownload} from "@material-ui/icons";
import {getProductManual} from "../services/documentation.service";


const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const VersionComponent = () => {
  const classes = useStyles();

  const handleDocumentationDownload = async () => {
    const response = await getProductManual();
    const fileUrl = URL.createObjectURL(response);
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute(
      'download',
      `product_manual.pdf`,
    );
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }

  return (
    <Card>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Application Information
        </Typography>
        <Typography variant="h5" component="h2">
          Branch: {gitInfo.branch}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          Build Date: {moment.unix(gitInfo.date).format('M/D/YY hh:mm a')}
        </Typography>
        <Typography variant="body2" component="p">
          Commit: {gitInfo.commitId}<br/>
          <img alt="pipeline status"
               src={`https://gitlab.com/eresidentcare/ssa-web-app/badges/${gitInfo.branch}/pipeline.svg`}/>
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small"
                variant={"outlined"}
                onClick={handleDocumentationDownload}
                startIcon={<CloudDownload />} color={"primary"}>
          View Product Manual
        </Button>
      </CardActions>
    </Card>);
};

export default VersionComponent;
