import React from 'react'
import PrivateRoute from "../PrivateRoute";
import { Switch } from "react-router-dom";
import ContactList from './contact.list';
import Contact from './contact';

const Contacts = () => {
    return (    
      <Switch>
        <PrivateRoute exact path="/contacts" component={ContactList} />
        <PrivateRoute exact path="/contacts/:id" component={Contact} />
      </Switch>
    )
}

export default Contacts
