import React from "react";
import {
  makeStyles,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Transition from "../dialog/transition";
import modelTemplate from "./medication.model";
import FormFieldsGenerator from "../../utils/forms/form.fields.generator";
import {
  getRoutes,
  getMedicationUnits,
  getDosageForms,
  createMedication,
} from "../../services/medication.service";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    marginTop: theme.spacing(10),
  },
}));

const CreateMedication = ({ open, onClose, onSave }) => {
  const classes = useStyles();
  const [routes, setRoutes] = React.useState([]);
  const [units, setUnits] = React.useState([]);
  const [dosageForms, setDosageForms] = React.useState([]);
  const [model, setModel] = React.useState(
    JSON.parse(JSON.stringify(modelTemplate))
  );

  React.useEffect(() => {
    if (open) {
      resetModel();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const resetModel = async () => {
    const obj = JSON.parse(JSON.stringify(modelTemplate));
    if (!routes || routes.length === 0 || !units || units.length === 0 || dosageForms || dosageForms.length === 0) {
      const data = await getData();
      obj.unit.dropDownItems = data[1];
      obj.route.dropDownItems = data[0];
      obj.dosageForm.dropDownItems = data[2];
    } else {
      obj.unit.dropDownItems = units;
      obj.route.dropDownItems = routes;
      obj.dosageForm.dropDownItems = dosageForms;
    }
    setModel(obj);    
  };

  const getData = async () => {
    const responses = await Promise.all([
      getRoutes(),
      getMedicationUnits(),
      getDosageForms(),
    ]);
    setRoutes(responses[0].content);
    setUnits(responses[1].content);
    setDosageForms(responses[2].content);
    return responses;
  };

  const onChange = (e) => {
    const obj = { ...model, ...e };
    setModel(obj);
  };

  const isValid = () => {
    let valid = true;
    Object.keys(model).forEach((key) => {
      if (model[key].required && !model[key].value) {
        valid = false;
      }
    });
    return valid;
  };

  const saveMedication = async () => {
    const medication = {
        tradeName: model.name.value,
        genericName: model.genericName.value,
        strength: model.strength.value,
        ndcCode: model.ndcCode.value,
        unit: model.unit.value,
        isSupply: model.isSupply.value === "yes",
        isNarcotic: model.isNarcotic.value === "yes",
        dosageForm: model.dosageForm.value,
        route: model.route.value
    };
    const response = await createMedication(medication);  
    onSave(response);
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => onClose()}
      TransitionComponent={Transition}
    >
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => onClose()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Create Medication
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.dialogContent}>
        <FormFieldsGenerator model={model} onChange={onChange} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>
        <Button
          onClick={saveMedication}
          variant="contained"
          color="primary"
          disabled={!isValid()}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateMedication;
