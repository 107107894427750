import React from 'react';
import { ResidentListContext } from './resident.list.context';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  Button,
  TextField,
  TableContainer,
  Select,
  MenuItem,
  Grid,
  Avatar, makeStyles,
} from '@material-ui/core';
import { getResidents } from './actions/resident.actions';
import ResidentTableSkeleton from './resident.table.skeleton';
import moment from 'moment';
import ResidentCard from './resident.card';
import { convertToFilters } from '../../../utils/filter.utils';
import config from '../../../utils/configs';
import {getStripedStyle} from "../../utils/table.utils";

const useStyles = makeStyles((theme) => ({
  residentImage: {
    height: 70,
    width: 70
  }
}));



const ResidentsTable = ({
  onSelected,
  minView = false,
  forceMaxHeight = false,
}) => {
  const { state, dispatch } = React.useContext(ResidentListContext);
  const classes = useStyles();
  const createSortHandler = async (field) => {
    const sort = field;
    const sortDirection = state.sortDirection === 'asc' ? 'desc' : 'asc';
    dispatch({ type: 'setSort', payload: sort });
    dispatch({ type: 'setSortOrderDirection', payload: sortDirection });
    await search(state.page, state.size, state.filter, sort, sortDirection);
  };

  const filterSelectChange = async (event) => {
    const obj = { ...state.filter };
    if (event.target.value === -1) {
      event.target.value = null;
    }
    obj[event.target.name].value = event.target.value;
    dispatch({ type: 'setFilters', payload: obj });
    await search(state.page, state.size, obj, state.sort, state.sortDirection);
  };

  const filterTextChange = async (e, minLength = 3) => {
    const obj = { ...state.filter };
    obj[e.target.name].value = e.target.value;
    dispatch({ type: 'setFilters', payload: obj });
    if (
      String(obj[e.target.name].value).length === 0 ||
      String(obj[e.target.name].value).length >= obj[e.target.name].minValue
    ) {
      await search(state.page, state.size, obj, state.sort, state.sortDirection);
    }
  };

  const search = async (page, size, filter, sort, sortDirection) => {
    dispatch({ type: 'setLoading' });
    const filters = convertToFilters(filter);
    const result = await getResidents(page, size, filters, sort, sortDirection);
    if (!result?.error) {
      dispatch({ type: 'setResidents', payload: result });
    } else {
      dispatch({type: 'searchResidentsError'})
    }
  };

  const onResidentSelect = (resident) => {
    if (onSelected) {
      onSelected(resident);
    }
  };

  const getResidentUrl = (resident) => {
    if (resident.imageUrl && resident.location && resident.location.tenant) {
      // alert(resident?.imageUrl);
      return `${config.bucketObjectUrl}/${config.publicBucketName}/tenants/${resident?.location?.tenant?._id ? resident?.location?.tenant?._id: resident?.location?.tenant}/residents/${resident._id}/photo/${resident.imageUrl}`;
    } else {
      return null;
    }
  };

  return (
    <TableContainer style={{ maxHeight: forceMaxHeight ? '35rem' : null }}>
      <Table id="residentTable" size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell>
              <TableSortLabel
                active={state.sortOrderBy === 'tags'}
                direction={state.sortDirection}
                onClick={() => createSortHandler('tags')}
              >
                Tags
              </TableSortLabel>
              <TextField
                name="tags"
                margin="dense"
                size="small"
                placeholder="Search"
                variant="outlined"
                value={state.filter.tags.value || ''}
                onChange={filterTextChange}
              ></TextField>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={state.sortOrderBy === 'firstName'}
                direction={state.sortDirection}
                onClick={() => createSortHandler('firstName')}
              >
                First Name
              </TableSortLabel>
              <TextField
                name="firstName"
                margin="dense"
                size="small"
                placeholder="Search"
                variant="outlined"
                value={state.filter.firstName.value || ''}
                onChange={filterTextChange}
              ></TextField>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={state.sortOrderBy === 'lastName'}
                direction={state.sortDirection}
                onClick={() => createSortHandler('lastName')}
              >
                Last Name
              </TableSortLabel>
              <TextField
                name="lastName"
                margin="dense"
                size="small"
                placeholder="Search"
                variant="outlined"
                value={state.filter.lastName.value || ''}
                onChange={filterTextChange}
              ></TextField>
            </TableCell>
            {!minView && (
              <>
                <TableCell>
                  <TableSortLabel
                    active={state.sortOrderBy === 'dob'}
                    direction={state.sortDirection}
                    onClick={() => createSortHandler('dob')}
                  >
                    DOB
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={state.sortOrderBy === 'gender'}
                    direction={state.sortDirection}
                    onClick={() => createSortHandler('gender')}
                  >
                    Gender
                  </TableSortLabel>
                  <TextField
                    autoComplete="false"
                    name="gender"
                    margin="dense"
                    size="small"
                    placeholder="Search"
                    variant="outlined"
                    value={state.filter.gender.value || ''}
                    onChange={(event) => filterTextChange(event, 1)}
                  ></TextField>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={state.sortOrderBy === 'maritalStatus'}
                    direction={state.sortDirection}
                    onClick={() => createSortHandler('maritalStatus')}
                  >
                    Marital Status
                  </TableSortLabel>
                  <TextField
                    autoComplete="false"
                    name="maritalStatus"
                    margin="dense"
                    size="small"
                    placeholder="Search"
                    variant="outlined"
                    value={state.filter.maritalStatus.value || ''}
                    onChange={(event) => filterTextChange(event, 3)}
                  ></TextField>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={state.sortOrderBy === 'religiousPreference'}
                    direction={state.sortDirection}
                    onClick={() => createSortHandler('religiousPreference')}
                  >
                    Religious Preference
                  </TableSortLabel>
                  <TextField
                    autoComplete="false"
                    name="religiousPreference"
                    margin="dense"
                    size="small"
                    placeholder="Search"
                    variant="outlined"
                    value={state.filter.religiousPreference.value || ''}
                    onChange={filterTextChange}
                  ></TextField>
                </TableCell>
              </>
            )}
            <TableCell>
              <TableSortLabel
                active={state.sortOrderBy === 'roomNumber'}
                direction={state.sortDirection}
                onClick={() => createSortHandler('roomNumber')}
              >
                Room Number
              </TableSortLabel>
              <TextField
                name="roomNumber"
                margin="dense"
                size="small"
                placeholder="Search"
                variant="outlined"
                fullWidth={true}
                value={state.filter.roomNumber.value || ''}
                onChange={filterTextChange}
              ></TextField>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={state.sortOrderBy === 'residentStatusId'}
                direction={state.sortDirection}
                onClick={() => createSortHandler('residentStatusId')}
              >
                Status
              </TableSortLabel>
              <Select
                name="residentStatus"
                fullWidth
                onChange={filterSelectChange}
                defaultValue={-1}
                value={state.filter.residentStatus.value || ''}
              >
                <MenuItem value={-1}>All</MenuItem>
                {state?.residentStatuses?.map((status, i) => (
                    <MenuItem key={i} value={status._id}>
                      {status.name}
                    </MenuItem>
                  ))}
              </Select>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {state.displayTable && !state.loading && state?.residents?.map((resident, index) => {
              return (
                <TableRow key={index} style={{ ...getStripedStyle(index) }}>
                  <TableCell scope="row">
                    <Button onClick={() => onResidentSelect(resident)}>
                      Select
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Avatar variant="square"
                            className={classes.residentImage}
                            src={getResidentUrl(resident)}>
                      {resident?.firstName && resident.firstName[0]} {resident?.lastName && resident?.lastName[0]}
                    </Avatar>
                  </TableCell>
                  <TableCell>
                    {resident.tags ? resident.tags.join(', ') : null}
                  </TableCell>
                  <TableCell>{resident.firstName}</TableCell>
                  <TableCell>{resident.lastName}</TableCell>
                  {!minView && (
                    <>
                      <TableCell>
                        {moment(resident.dob)?.utc()?.format('M/D/YYYY')}
                      </TableCell>
                      <TableCell>{resident.gender}</TableCell>
                      <TableCell>{resident.maritalStatus}</TableCell>
                      <TableCell>{resident.religiousPreference}</TableCell>
                    </>
                  )}
                  <TableCell>{resident.roomNumber}</TableCell>
                  <TableCell>
                    {resident.residentStatus
                      ? resident.residentStatus.name
                      : 'N/A'}
                  </TableCell>
                </TableRow>
              );
            })}
          {state.loading && <ResidentTableSkeleton rows={10} columns={9} />}
        </TableBody>
      </Table>
      {!state.loading && !state.displayTable && (
        <Grid container>
          {state?.residents?.map((resident) => (
              <ResidentCard resident={resident} size={4} />
            ))}
        </Grid>
      )}
    </TableContainer>
  );
};

export default ResidentsTable;
