import React from 'react';
import {
  Avatar, IconButton,
  ListItem,
  ListItemAvatar, ListItemSecondaryAction,
  ListItemText,
  makeStyles
} from "@material-ui/core";
import {AssessmentsContext} from "./assessments.context";
import {onCopyQuestionAction, onSelectAssessmentObjectAction} from "./assessment.actions";
import {FileCopyTwoTone} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: '2.5vh',
    width: '2.5vh',
    fontSize: '1rem',
    background: theme.palette.grey[600]
  },
  required: {
    background: theme.palette.primary.main
  },
  selected: {
    background: theme.palette.grey[300]
  }
}))

const GroupQuestion = ({question, canUpdate = true}) => {
  const classes = useStyles();
  const { state, dispatch } = React.useContext(AssessmentsContext);

  const onQuestionSelected = e => {
    e.stopPropagation();
    onSelectAssessmentObjectAction(question, dispatch);
  }

  const onCopyQuestion = async e => {
    e.stopPropagation();
    await onCopyQuestionAction(state?.assessment, question, dispatch);
  }

  return (
    <ListItem button
              onClick={onQuestionSelected}
              className={[question?._id === state?.selectedObject?._id ? classes.selected: null]}
    >
      <ListItemAvatar>
        <Avatar className={[classes.avatar, question?.required ? classes.required: null]}>
          {question?.sortOrder}
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={question?.label ?? 'Untitled'} />
      {canUpdate &&
      <ListItemSecondaryAction>
        <IconButton onClick={onCopyQuestion}>
          <FileCopyTwoTone />
        </IconButton>
      </ListItemSecondaryAction>}
    </ListItem>
  )
}

export default GroupQuestion;
