import React, { useState, useCallback } from "react";

export const GlobalNotificationContext = React.createContext({
  open: true,
  notification: null,
  addNotification: (message, type = 'error', vertical = "top", horizontal = "center") => {},
  removeNotification: () => {},
});

const GlobalNotificationProvider = ({ children }) => {
  const [open, setOpen] = useState(true);
  const [notification, setNotification] = useState(null);

  const addNotification = (message, type = 'error', vertical = "top", horizontal = "center") => {
    setNotification({ message, type, vertical, horizontal });
    setOpen(true);
  };

  const contextValue = {
    notification,
    open,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    addNotification: useCallback(
      (message, type = 'error', vertical = "top", horizontal = "center") =>
        addNotification(message, type, vertical, horizontal),
      []
    ),
    removeNotification: useCallback(() => {
      setNotification(null);
      setOpen(false);
    }, []),
  };

  return (
    <GlobalNotificationContext.Provider value={contextValue}>
      {children}
    </GlobalNotificationContext.Provider>
  );
};

export default GlobalNotificationProvider;
