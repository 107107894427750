import React from 'react';
import {UserContext} from "../../../context/usercontext";
import {TenantCalendarProvider} from "./context";
import {TenantCalendarPage} from "./tenant.calendar";

export const TenantCalendarPageWrapper = () => {
  const { state } = React.useContext(UserContext);
  const [id, setId] = React.useState();

  React.useEffect(() => {
    if (state?.selectedTenant) {
      setId(state?.selectedTenant?._id)
    }
  }, [ state?.selectedTenant ])

  return (
      <TenantCalendarProvider tenantId={id}>
        <TenantCalendarPage />
      </TenantCalendarProvider>
  )
}
