import moment from "moment";
const model = {
  name: {
    title: "Treatment",
    required: true,
    isValid: false,
    value: null,
    dirty: false,
  },
  description: {
    title: "Description",
    required: true,
    isValid: false,
    value: null,
    dirty: false,
  },
  physician: {
    title: "Physician",
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [],
    dropDownItemValue: "_id",
    dropDownItemLabel: "name",
    date: false,
  },
  startDate: {
    title: "Order date",
    required: true,
    isValid: true,
    value: moment(new Date()),
    dirty: false,
    date: true,
  },
  endDate: {
    title: "End date",
    required: true,
    isValid: true,
    value: moment(new Date()).add(1,'y'),
    dirty: false,
    date: true,
  },
  diagnosis: {
    title: "Diagnosis",
    required: false,
    isValid: false,
    value: null,
    dirty: false,
    multiSelect: true,
    dropDown: true,
    dropDownItems: [],
    dropDownItemValue: "_id",
    dropDownItemLabel: "name",
    date: false,
  },
  allergies: {
    title: "Allergies",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: true,
    dropDown: true,
    dropDownItems: [],
    dropDownItemValue: "_id",
    dropDownItemLabel: "name",
    date: false,
  },
  frequency: {
    title: "Frequency",
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [],
    dropDownItemValue: "_id",
    dropDownItemLabel: "description",
    date: false,
  },
  chartVitals: {
    title: "Chart Vitals",
    required: false,
    value: [],
    isValid: true,
    dirty: false,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [
      { name: 'All' },
      { name: 'Blood Glucose' },
      { name: 'Blood Pressure' },
      { name: 'INR' },
      { name: 'Oxygen'},
      { name: 'Pain Level' },
      { name: 'Pulse' },
      { name: 'Respiratory' },
      { name: 'Temperature' },
      { name: 'Weight' },
    ],
    dropDownItemValue: "_id",
    dropDownItemLabel: "name",
    date: false,
  },
  isActive: {
    value: true,
    isValid: true
  },
  discipline: {
    title: "Discipline",
    required: false,
    value: null,
    isValid: true,
    dirty: false,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "_id",
    dropDownItemLabel: "name",
    date: false,
  },
  time: {
    title: "Time",
    required: false,
    value: "",
    isValid: true,
    dirty: false,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [{ name: "1 minute", value: 1 },
    { name: "2 minutes", value: 2 },
    { name: "3 minutes", value: 3 },
    { name: "4 minutes", value: 4 },
    { name: "5 minutes", value: 5 },
    { name: "6 minutes", value: 6 },
    { name: "7 minutes", value: 7 },
    { name: "8 minutes", value: 8 },
    { name: "9 minutes", value: 9 },
    { name: "10 minutes", value: 10 },
    { name: "11 minutes", value: 11},
    { name: "12 minutes", value: 12 },
    { name: "13 minutes", value: 13 },
    { name: "14 minutes", value: 14 },
    { name: "15 minutes", value: 15 },
    { name: "30 minutes", value: 30 },
    { name: "45 minutes", value: 45 },
    { name: "60 minutes", value: 60 },
    { name: "2 hrs", value: 120 },
    { name: "3 hrs", value: 180 },

    ],
    dropDownItemValue: "_id",
    dropDownItemLabel: "name",
    date: false,
  },
  isPrn: {
    value: false,
    required: false,

  },
  defaultQuickStatus: {
    value: false,
    required: false,
  }
};

export default model;
