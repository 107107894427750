import apiUtil from '../utils/api.utils';
import config from '../utils/configs';
import moment from 'moment';
const escheduleUrl = `${config.medicationOrderService}/api/v1.0/eschedule`;

let escheduleCancelToken;
export const getAgenda = async (
  start,
  end,
  page = 1,
  size = 10,
  type = null,
  locateCurrentPage = false,
  refreshCache = false,
  tenantId = null,
  tenantLocationId = -1
) => {
  try {
    if (typeof escheduleCancelToken != typeof undefined) {
      escheduleCancelToken.cancel("Cancel Request");
    }
    escheduleCancelToken = apiUtil.getCancelToken();
    let url = `${escheduleUrl}?tenant=${tenantId}&tenantLocation=${tenantLocationId}`;
    let startSeconds = start;
    if (startSeconds) {
    } else {
      startSeconds = moment().startOf('day').unix();
    }
    url += `&start=${startSeconds}`;
    let endSeconds = end;
    if (endSeconds) {
    } else {
      endSeconds = moment().endOf('day').unix();
    }
    url += `&end=${endSeconds}`;
    url += `&page=${page}&size=${size}`;
    if (type) {
      url += `&type=${type}`;
    }
    if (locateCurrentPage) {
      url += '&locatecurrent=true'
    }
    if (refreshCache) {
      url += '&refreshcache=true'
    }
    url += `&sort=e.SCHEDULED_TIMESTAMP,asc`;
    const response = await apiUtil.client.get(url,  {cancelToken: escheduleCancelToken.token});
    return response.data;
  } catch (error) {
    if (apiUtil.isCancel(error)) {
      return { canceled: true };
    } else {
      return { error: true, message: error }
    }
  }
};

export const getResidentEschedule = async (residentId, date) => {
  try {
    const response = await apiUtil.client.get(
      `${config.medicationOrderService}/api/v1.0/residents/${residentId}/eschedules?date=${date}`
    );
    return response.data;
  } catch (error) {
    return { error: true, message: error };
  }
};
