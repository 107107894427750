import React from "react";
import {
  Dialog,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  DialogContent,
  Link,
  makeStyles,
  DialogActions,
} from "@material-ui/core";
import Transition from "../dialog/transition";
import CloseIcon from "@material-ui/icons/Close";
import FormFieldsGenerator from "../../utils/forms/form.fields.generator";
import modelTemplate from './allergy.model';
import {isModelValid} from "../../utils/forms";


const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  dialogContent: {
    marginTop: "4rem",
  },
}));

const CreateAllergy = ({ open, onClose, onSave }) => {
  const classes = useStyles();
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(modelTemplate)));

  React.useEffect(() => {
    if (open) {
      initForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const initForm = async () => {
    const obj = JSON.parse(JSON.stringify(modelTemplate));
    setModel(obj);
  }

  const onChange = e => {
    const obj = {...model, ...e};
    setModel(obj);
  }

  const save = () => {
    const data = {
      name: model.name.value,
      description: model.description.value
    }
    onSave(data);
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => onClose()}
      TransitionComponent={Transition}
    >
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => onClose()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Create Allergy</Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.dialogContent}>
        <form>
          <FormFieldsGenerator model={model} onChange={onChange} />
        </form>
      </DialogContent>
      <DialogActions>
        <Link onClick={onClose}>Cancel</Link>
        <Button disabled={!isModelValid(model)}
                onClick={save}
                variant="contained"
                color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateAllergy;
