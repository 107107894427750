import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import FormFieldsGenerator from "../../../../utils/forms/form.fields.generator";
import SaveIcon from "@material-ui/icons/Save";
import {model as Model} from './modelTemplate';

export const EditVentilatorReading = ({ users, reading, onClose, open, onSave }) => {
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(Model)));

  React.useEffect(() => {
    if (users && open && reading) {
      const obj = JSON.parse(JSON.stringify(Model));
      Object.keys(reading).forEach(key => {
        if (obj.hasOwnProperty(key)) {
          if (key === "staff") {
            obj[key].value = reading?.staff?._id;
          } else {
            obj[key].value = reading[key];
          }
        }
      });
      obj.staff.dropDownItems = users;
      setModel(obj);
    }
  }, [open, users, reading])

  const setupOnSave = () => {
    let data = {};
    Object.keys(model).forEach(key => {
      data[key] = model[key].value;
    });
    onSave(reading?._id, data);
  }

  const onChange = e => {
    setModel({...model, ...e});
  }

  return (
    <Dialog open={open}
            maxWidth={"xl"}
            fullWidth
            onClose={onClose}
    >
      <DialogTitle>Edit ventilator reading</DialogTitle>
      <DialogContent dividers>
        <FormFieldsGenerator model={model} onChange={onChange} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color={"primary"}
                startIcon={<SaveIcon />}
                onClick={setupOnSave}
                variant={"contained"}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
