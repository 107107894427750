import React from 'react'
import PrivateRoute from "../PrivateRoute";
import { Switch } from "react-router-dom";
import PhysicianList from './physician.list';
import Physician from './physician';

const Physicians = () => {
    return (
        <Switch>
          <PrivateRoute exact path="/physicians" component={PhysicianList} />
          <PrivateRoute exact path="/physicians/:id" component={Physician} />
        </Switch>
    )
}

export default Physicians
