import apiService from '../utils/api.utils';
import configs from '../utils/configs';
import {parseFiltersToJson} from "../utils/filter.utils";


export const getUsers = async (page = 1, size = 25, sort = "+lastName", filters = []) => {
  try {
    let url = `${configs.userService}/api/v1.0/users?page=${page}&size=${size}&sort=${sort}`
    url += parseFiltersToJson(filters);
    const response = await apiService.client.get(url);
    return response.data;
  } catch (ex) {
    console.log(ex);
    return {
      content: [],
      page: 1,
      size: 25,
      totalItems: 0
    };
  }
};

export const fuzzyUserSearch = async (term) => {
  try {
    let url = `${configs.userService}/api/v1.0/users/fuzzy?term=${term}`;
    const response = await apiService.client.get(url);
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}


export const updateUserDetails = async (userId, payload) => {
  try {
    const response = await apiService.client.put(
      `${configs.userService}/api/v1.0/users/${userId}`,
      payload
    );
    return response.data;
  } catch (ex) {
    alert(ex);
    return null;
  }
};

export const getUser = async (userId) => {
  try {
    const response = await apiService.client.get(
      `${configs.userService}/api/v1.0/users/${userId}`
    );
    return response.data;
  } catch (ex) {
    alert(ex);
    return null;
  }
};

export const createUser = async (user) => {
  try {
    const response = await apiService.client.post(
      `${configs.userService}/api/v1.0/users/`,
      user
    );
    return response.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const addUserRole = async (userId, roleId) => {
  try {
    const response = await apiService.client.post(
      `${configs.userService}/api/v1.0/users/${userId}/roles`,
      { role: roleId }
    );
    return response.data;
  } catch (error) {
    return { error: true, message: error };
  }
};

export const removeUserRole = async (userId, roleId) => {
  try {
    const response = await apiService.client.delete(
      `${configs.userService}/api/v1.0/users/${userId}/roles/${roleId}`
    );
    return response.data;
  } catch (error) {
    return { error: true, message: error };
  }
};

export const getUserLogins = async (userId, page = 0, pageSize = 25) => {
  try {
    let url = `${configs.userService}/api/v1.0/users/${userId}/logins`;
    url += `?page=${page + 1}=page&size=${pageSize}`;
    const response = await apiService.client.get(url);
    return response.data;
  } catch (ex) {
    alert(ex);
    return null;
  }
};

export const uploadUserProfilePicture = async (userId, imageFile) => {
  try {
    const formData = new FormData();
    formData.append('file', imageFile);
    const response = await apiService.client.post(
      `${configs.userService}/api/v1.0/users/${userId}/photo`,
      formData
    );
    return response.data;
  } catch (ex) {
    alert(ex);
    return null;
  }
};

export const setUserBlocked = async (userId, blocked) => {
  try {
    const response = await apiService.client.put(
      `${configs.userService}/api/v1.0/users/${userId}/blocked`,
      { blocked }
    );
    return response.data;
  } catch (ex) {
    return null;
  }
};

export const resetPassword = async (user) => {
  try {
    const response = await apiService.client.post(
      `${configs.userService}/api/v1.0/users/${user?._id}/resetpassword`,
      { email: user?.email }
    );
    return response.data;
  } catch (ex) {
    return null;
  }
}

export const searchEmails = async email => {
  try {
    const response = await apiService.client.get(
      `${configs.userService}/api/v1.0/users/emails/${email}`,
    );
    return response.data;
  } catch (ex) {
    return null;
  }
}

export const createUserAddress = async (userId, address) => {
  try {
    const response = await apiService.client.post(`${configs.userService}/api/v1.0/users/${userId}/addresses`, address);
    return response.data;
  } catch (ex) {
    return null;
  }
}

export const deleteUserAddress = async (userId, addressId) => {
  try {
    const response = await apiService.client.delete(`${configs.userService}/api/v1.0/users/${userId}/addresses/${addressId}`);
    return response.data;
  } catch (ex) {
    return null;
  }
}

export const updateUserAddress = async (userId, addressId, address) => {
  try {
    const response = await apiService.client.put(
      `${configs.userService}/api/v1.0/users/${userId}/addresses/${addressId}`,address);
    return response.data;
  } catch (ex) {
    return null;
  }
}

export const createUserPhone = async (userId, phone) => {
  try {
    const response = await apiService.client.post(`${configs.userService}/api/v1.0/users/${userId}/phones`, phone);
    return response.data;
  } catch (ex) {
    return null;
  }
}

export const deleteUserPhone = async (userId, phoneId) => {
  try {
    const response = await apiService.client.delete(`${configs.userService}/api/v1.0/users/${userId}/phones/${phoneId}`);
    return response.data;
  } catch (ex) {
    return null;
  }
}

export const updateUserPhone = async (userId, phoneId, phone) => {
  try {
    const response = await apiService.client.put(
      `${configs.userService}/api/v1.0/users/${userId}/phones/${phoneId}`,phone);
    return response.data;
  } catch (ex) {
    return null;
  }
}

export const deactivateUser = async (userId, payload) => {
  try {
    const response = await apiService.client.put(
      `${configs.userService}/api/v1.0/users/${userId}/deactivate`, payload);
    return response.data;
  } catch (ex) {
    return null;
  }
}

export const updateAccountInfo = async (sub, payload) => {
  try {
    const response = await apiService.client.put(
      `${configs.userService}/api/v1.0/users/${sub}/accountinfo`, payload);
    return response.data;
  } catch (ex) {
    return null;
  }
}
