import apiUtil from "../utils/api.utils";
import configs from "../utils/configs";
const baseUrl = `${configs.residentService}/api/v1.0/phonetypes/`;


export const getPhoneTypes = async (token) => {
    try {
        const response = await apiUtil.client.get(baseUrl);
        return response.data;
    } catch (error) {
        alert(error);
        return [];
    }
    
  };
  