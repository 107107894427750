import React from 'react';
import {Dialog, DialogContent, DialogTitle, IconButton, TableContainer, Table, TableCell, TableHead, TableBody, TableRow} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export const PermissionsModal = ({open, onClose, role}) => {

    return (
        <Dialog
            open={open}
            onClose={() => onClose()}
            fullWidth
            maxWidth={"lg"}
        >
            <DialogTitle
                onClose={() => onClose()}>
                {'Permissions'}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableCell>
                                Permission
                            </TableCell>
                        </TableHead>
                        <TableBody>
                            {role?.permissions?.map(permission =>
                                <TableRow>
                                    <TableCell>
                                        {permission}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
        </Dialog>
    )
}
