import React from 'react'
import { Tab } from '@material-ui/core';
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

const AssessmentTab = ({ group, onClick, index }) => {

    const isSavedGroup = () => {
        if (group && group.questions) {
            const nonSaved = group.questions.filter(question => !question.hasSavedValue);
            return nonSaved.length === 0;
        }
        return false;
    }

    return <Tab icon={isSavedGroup() ? <CheckCircleOutlineIcon style={{ color: 'green' }} /> : <RadioButtonUncheckedIcon style={{ color: 'red' }} />}
                label={group.name}
                value={index}
                onClick={onClick} />;
}

export default AssessmentTab;
