import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Grid, MenuItem,
  Paper, Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead, TableRow,
  Typography
} from "@material-ui/core";
import history from "../../../utils/history";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import AddIcon from "@material-ui/icons/Add";
import CreateResidentDiagnosisModal from "./create.resident.diagnosis.modal";
import {ResidentContext} from "../../../context/residentcontext";
import moment from 'moment';
import DeleteIcon from "@material-ui/icons/Delete";
import {
  createResidentDiagnosis,
  deleteResidentDiagnosis,
  updateResidentDiagnosis
} from "../../../services/diagnosis.service";
import {GlobalNotificationContext} from "../../notification/globalnotificationprovider";
import EditIcon from "@material-ui/icons/Edit";
import DiagnosisLookup from "../../diagnosis/diagnosis.lookup";
import MomentUtils from "@date-io/moment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {getStripedStyle} from "../../utils/table.utils";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1)
  },
  deleteBtn: {
    color: theme.palette.error.light,
    '&:hover': {
      color: theme.palette.error.dark
    }
  }
}));

const ResidentDiagnosis = () => {
  const classes = useStyles();
  const [viewCreateResidentDiagnosisModal, setViewCreateResidentDiagnosisModal] = React.useState(false);
  const [residentDiagnosis, setResidentDiagnosis] = React.useState([]);
  const [viewConfirmDelete, setViewConfirmDelete] = React.useState(false);
  const { addNotification } = React.useContext(GlobalNotificationContext);
  const [deleteEntity, setDeleteEntity] = React.useState();
  const { resident, dispatch } = React.useContext(ResidentContext);
  const [editDiagnosis, setEditDiagnosis] = React.useState();

  React.useState(() => {
    setResidentDiagnosis(resident?.diagnosis);
  }, [ resident ]);


  const onCreated = async (rd) => {
    const entity = await createResidentDiagnosis(resident?._id, rd);
    const obj = [entity, ...residentDiagnosis]
    setResidentDiagnosis(obj);
    dispatch({type: 'RESIDENT_DIAGNOSIS_UPDATED', payload: obj});
    setViewCreateResidentDiagnosisModal(false);
  }

  const onDeleteClicked = rd => {
    setDeleteEntity(rd);
    setViewConfirmDelete(true);
  }

  const onDeleteConfirmed = async () => {
    const response = await deleteResidentDiagnosis(resident?._id, deleteEntity?._id);
    if (response?.ok) {
      const obj = residentDiagnosis?.filter(rd => deleteEntity?._id !== rd?._id)
      setResidentDiagnosis(obj);
      dispatch({type: 'RESIDENT_DIAGNOSIS_UPDATED', payload: obj});
      addNotification('Diagnosis deleted', 'success');
    } else {
      addNotification('Unable to delete diagnosis', 'warning');
    }
    setDeleteEntity({});
    setViewConfirmDelete(false);
  }

  const onEditClicked = (diagnosis) => {
    setEditDiagnosis(diagnosis);
  }

  const onUpdateEdit = e => {
    const obj  = {...editDiagnosis};
    obj[e.target.name] = e.target.value;
    setEditDiagnosis(obj);
  }

  const onEditDiagnosisSelected = diagnosis => {
    const obj  = {...editDiagnosis};
    obj.diagnosis = diagnosis;
    setEditDiagnosis(obj);
  }

  const onDateChange = (date, field) => {
    const obj  = {...editDiagnosis};
    obj[field] = date;
    setEditDiagnosis(obj);
  }

  const onUpdateClicked = async () => {
    const entity = await updateResidentDiagnosis(resident?._id, editDiagnosis?._id, editDiagnosis);
    if (entity) {
      const obj = residentDiagnosis.map(rd => rd._id === entity?._id ? entity: rd);
      dispatch({type: 'RESIDENT_DIAGNOSIS_UPDATED', payload: obj});
      setResidentDiagnosis(obj);
      setEditDiagnosis(null);
      addNotification('Resident diagnosis updated', 'success');
    } else {
      setEditDiagnosis(null);
      addNotification('Unable to update resident diagnosis', 'warning');
    }
  }

  return (
    <Paper elevation={24}
           className={classes.root}>
      <Grid container className={classes.headerContainer}>
        <Button size={"small"}
                className={classes.backBtn}
                onClick={() => history.goBack()}
                startIcon={<NavigateBeforeIcon />}
        >
          Back
        </Button>
        <Typography variant={"h6"}>Diagnosis</Typography>
      </Grid>
      <TableContainer>
        <Table size={"small"}>
          <TableHead>
            <TableCell width={200}>
              <Button size={"small"}
                      variant={"contained"}
                      color={"primary"}
                      onClick={() => setViewCreateResidentDiagnosisModal(true)}
                      startIcon={<AddIcon />}>
                Add
              </Button>
              <CreateResidentDiagnosisModal open={viewCreateResidentDiagnosisModal}
                                            onClose={() => setViewCreateResidentDiagnosisModal(false)}
                                            onSave={onCreated} />
            </TableCell>
            <TableCell>Admitting</TableCell>
            <TableCell>Diagnosis</TableCell>
            <TableCell>Start</TableCell>
            <TableCell>End</TableCell>
          </TableHead>
          <TableBody>
            {residentDiagnosis?.map((rd, index) =>
                <>
            {editDiagnosis?._id !== rd?._id &&
              <TableRow style={{ ...getStripedStyle(index) }}>
                <TableCell>
                  <Button startIcon={<EditIcon />}
                          size={"small"}
                          onClick={() => onEditClicked(rd)}
                          color={"primary"}>
                    Edit
                  </Button>
                  <Button className={classes.deleteBtn}
                          size={"small"}
                          onClick={() => onDeleteClicked(rd)}
                          startIcon={<DeleteIcon />}>
                    Delete
                  </Button>
                </TableCell>
                <TableCell>
                  {rd?.admittingDiagnosis ? 'Y': null}
                </TableCell>
                <TableCell>
                  {rd?.diagnosis?.code} - {rd?.diagnosis?.shortDescription}
                </TableCell>
                <TableCell>
                  {rd?.startDate ? moment(rd?.startDate).format('MM/DD/YYYY'): null}
                </TableCell>
                <TableCell>
                  {rd?.endDate ? moment(rd?.endDate).format('MM/DD/YYYY'): null}
                </TableCell>
              </TableRow>
            }
            {editDiagnosis?._id === rd?._id &&
            <TableRow style={{ ...getStripedStyle(index) }}>
                <TableCell>
                  <Button startIcon={<EditIcon />}
                          size={"small"}
                          color={"primary"}
                          variant={"contained"}
                          onClick={onUpdateClicked}
                  >
                    Update
                  </Button>
                  <Button color={"primary"}
                          size={"small"}
                          onClick={() => setEditDiagnosis(null)}>
                          Cancel
                </Button>
              </TableCell>
              <TableCell>
                <Select value={editDiagnosis?.admittingDiagnosis}
                        name={"admittingDiagnosis"}
                        onChange={onUpdateEdit}>
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </TableCell>
              <TableCell>
                <DiagnosisLookup onSelected={onEditDiagnosisSelected} defaultValue={editDiagnosis?.diagnosis} />
              </TableCell>
              <TableCell>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    size="small"
                    fullWidth
                    name={"startDate"}
                    value={editDiagnosis?.startDate}
                    format="MM/DD/YYYY"
                    onChange={(date) => onDateChange(date, 'startDate')}
                    allowKeyboardControl="true"
                    ampm={false}
                  />
                </MuiPickersUtilsProvider>
              </TableCell>
              <TableCell>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    size="small"
                    fullWidth
                    name={"endDate"}
                    value={editDiagnosis?.endDate}
                    format="MM/DD/YYYY"
                    onChange={(date) => onDateChange(date, 'endDate')}
                    allowKeyboardControl="true"
                    ampm={false}
                  />
                </MuiPickersUtilsProvider>
              </TableCell>
              </TableRow>}
            </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={viewConfirmDelete}
              onClose={() => setViewConfirmDelete(false)}>
        <DialogTitle>{"Delete Resident Diagnosis"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {"Confirm if you to delete this resident diagnosis"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color={"primary"}
                  onClick={() => setViewConfirmDelete(false)}>
            Cancel
          </Button>
          <Button color={"primary"}
                  variant={"contained"}
                  onClick={onDeleteConfirmed}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </Paper>
  )
}

export default ResidentDiagnosis;
