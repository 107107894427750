import React from 'react';
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from "@material-ui/core";
import moment from 'moment';
import {makeStyles} from "@material-ui/core/styles";
import {blue, green, grey, orange, red, yellow} from "@material-ui/core/colors";
import useHasRole from "../../../../hooks/user.role.hook";
import OutcomesList from "./outcomes.list";
import {ResidentContext} from "../../../../context/residentcontext";
const contrast = 400;
const useStyles = makeStyles((theme) => ({
    scheduled: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: grey[500],
  },
  readyToPass: {
  background: grey[700],
  },
  given: {
    background: green[contrast],
  },
  late: {
    background: orange[contrast],
  },
  missed: {
    background: red[contrast],
  },
  hold: {
    background: yellow[contrast],
  },
  sa: {
    background: blue[contrast],
  },
  na: {
    background: grey[contrast],
  },
  refused: {
    background: orange[contrast],
  },
  destruction: {
    background: yellow[contrast],
  },
  status: {
    textAlign: 'center',
      marginTop: theme.spacing(1),
  },
}))

const MobileEprnList = ({eprns, onSelected}) => {
  const classes = useStyles();
  const [events, setEvents] = React.useState([]);
  const { checkPermission } = useHasRole();
  const canEdit = checkPermission('EMAR_UPDATE');
  const { resident } = React.useContext(ResidentContext);

  React.useEffect(() => {
    setEvents(events);
    // eslint-disable-next-line
  }, [eprns]);

  const getItemClass = (event) => {
    if (event && event.status && event.status.name) {
      switch (event.status.name) {
        case 'Scheduled':
          if (canEdit) {
            return classes.readyToPass;
          } else {
            return classes.scheduled;
          }
        case 'Given':
          return classes.given;
        case 'Late':
          return classes.late;
        case 'Missed':
          return classes.missed;
        case 'Hold':
          return classes.hold;
        case 'SA':
          return classes.sa;
        case 'NA':
          return classes.na;
        case 'Refused':
          return classes.refused;
        case 'Destruction':
          return classes.destruction;
        default:
          return classes.scheduled;
      }
    }
  };

  const onSaveOutcome = (eventId, outcome) => {
    const obj = eprns?.map(event => {
      if (event._id === eventId) {
        event.outcomes = [...event.outcomes, outcome];
      }
      return event;
    });
    setEvents(obj);
  }

  return (

    <List>
      {eprns?.map(event =>
        <ListItem button
                  divider
                  key={event?._id}
                  onClick={() => onSelected(event)}
        >
          <ListItemAvatar>
            <Avatar className={[getItemClass(event)]}>{
              event?.status?.name === 'Given' ? <i className="fas fa-calendar-check"></i>:
                <i className="fas fa-calendar-times"></i>
            }</Avatar>
          </ListItemAvatar>
          <ListItemText primary={event?.medicationOrder?.medication?.tradeName}
                        secondary={
                          <div>
                            <Typography variant={"subtitle2"}>
                              {event?.medicationOrder?.medication?.strength} {event?.medicationOrder?.medication?.unit?.name},
                              Qty: {event?.passingQty}
                            </Typography>
                            <Typography variant={"subtitle1"}>
                              {moment(event?.scheduledTime).format('hh:mm a')}
                            </Typography>
                          </div>
                        }
          />
          <ListItemSecondaryAction>
            <OutcomesList
              residentId={resident?._id}
              event={event}
              onSaveOutcome={onSaveOutcome}
              canEdit={canEdit}
            />
          </ListItemSecondaryAction>
        </ListItem>
      )}
    </List>
  )
}

export default MobileEprnList;
