export const PermissionModel = {
  name: {
    title: "Name",
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false
  },
  category: {
    title: "Category",
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false
  },
  tags: {
    title: 'Tags',
    isTagsComponent: true,
    hidden: false,
    value: [],
    required: false,
  },
}
