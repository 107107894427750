import apiUtil from '../utils/api.utils';
import configs from '../utils/configs';

export const getCategories = async (tenantId) => {
  try {
    const response = await apiUtil.client.get(
      `${configs.residentService}/api/v1.0/tenants/${tenantId}/notecategories`
    );
    return response.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const createCategory = async (tenantId, category) => {
  try {
    const response = await apiUtil.client.post(
      `${configs.residentService}/api/v1.0/tenants/${tenantId}/notecategories`,
      category
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
}

export const updateCategory = async (tenantId, categoryId, category) => {
  try {
    const response = await apiUtil.client.put(
      `${configs.residentService}/api/v1.0/tenants/${tenantId}/notecategories/${categoryId}`,
      category
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
}

export const deleteCategory = async (tenantId, categoryId) => {
  try {
    const response = await apiUtil.client.delete(
      `${configs.residentService}/api/v1.0/tenants/${tenantId}/notecategories/${categoryId}`
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
}
