import React from 'react';
import {
  Button,
  Divider,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText
} from "@material-ui/core";
import {
  ACTIONS,
  TenantCalendarContext,
  selectCalendarTypeToEditAction,
  updateCalendarFilterTypesAction, getTenantCalendarTypesAction
} from "../context"
import AddIcon from "@material-ui/icons/Add";
import {CreateCalendarType} from "./create.type";
import {ListLoading} from "../../../../utils/indicators/list.loading";
import {AllInboxRounded, RadioButtonChecked, RadioButtonUnchecked, VisibilityOffRounded} from "@material-ui/icons";
import {EditCalendarType} from "./edit.type";
import {RetryMessage} from "../../../notification/retry.message";

export const CalendarTypes = () => {
  const { state, dispatch } = React.useContext(TenantCalendarContext);

  const calendarTypesFilterChanged = type => {
    updateCalendarFilterTypesAction(dispatch, type);
  }

  const onRetry = async () => {
    await getTenantCalendarTypesAction(dispatch, state?.tenantId);
  }


  return (
    <>
      <CreateCalendarType />
      <EditCalendarType />
      <List>
        <Divider />
        <ListItem button
                  disableGutters
                  divider
                  onClick={() => dispatch({type: ACTIONS.SET_VIEW_CREATE_CALENDAR_TYPE_MODAL, payload: true})}
        >
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary={"Add Type"} />
        </ListItem>
        <ListItem button
                  divider
                  disableGutters
                  onClick={() => dispatch({type: ACTIONS.TOGGLE_FILTER_VIEW_ALL})}
        >
          <ListItemIcon>
            {!state.viewAllFilter && <AllInboxRounded />}
            {state.viewAllFilter && <VisibilityOffRounded />}
          </ListItemIcon>
          <ListItemText primary={`${state.viewAllFilter ? "Hide": "View"} All`} />
        </ListItem>
        {state.loadingTypes && <ListLoading rows={5} />}
        {!state.loadingTypes && state.loadingTypesError &&
        <RetryMessage onRetry={onRetry}
                      severity={"error"}
                      message={"Unable to load types"} />
        }
        {!state.loadingTypesError && !state.loadingTypes && state?.calendarTypes?.map((type, i) =>
        <ListItem button
                  divider
                  disableGutters
                  onClick={() => calendarTypesFilterChanged(type)}>
          <Hidden smDown>
            <ListItemIcon>
              {type?.checked ? <RadioButtonChecked />: <RadioButtonUnchecked />}
            </ListItemIcon>
          </Hidden>
          <ListItemText primary={type?.name} secondary={type.description} />
          <ListItemSecondaryAction>
            <Button color="primary"
                    onClick={() => selectCalendarTypeToEditAction(dispatch, type)}
                    variant={"outlined"}>
              Edit
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
      )}
      </List>
    </>
  )
}
