import moment from 'moment';

export const InterventionModel = {
  name: {
    title: 'Name',
    required: true,
    value: null,
    dirty: false,
    isValid: false,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  etask: {
    title: 'eTask',
    required: false,
    value: true,
    dirty: false,
    isValid: false,
    checkbox: true,
  },
  discipline: {
    title: 'Discipline',
    required: false,
    value: null,
    dirty: false,
    isValid: false,
    dropDown: true,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
  },
  defaultQuickStatus: {
    hidden: true,
    title: 'Default Quick Status',
    required: false,
    value: 'Given',
    dirty: false,
    isValid: false,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [{
      name: 'Given',
      value: 'Given'
    },{
      name: 'Late',
      value: 'Late'
    }, {
      name: 'Missed',
      value: 'Missed'
    }, {
      name: 'Hold',
      value: 'Hold'
    }, {
      name: 'Self Administered',
      value: 'Self Administered'
    }, {
      name: 'Not Administered',
      value: 'Not Administered'
    }, {
      name: 'Refused',
      value: 'Refused'
    }],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  startDate: {
    title: 'Start',
    required: true,
    value: Date.now(),
    dirty: false,
    isValid: false,
    checkbox: false,
    date: true,
    maxDate: true,
    maxDateValue: moment(Date.now()).add(1, 'year')
  },
  endDate: {
    title: 'End',
    required: true,
    value: moment(Date.now()).add(1, 'year'),
    dirty: false,
    isValid: false,
    checkbox: false,
    date: true,
  },
  isPrn: {
    title: 'PRN',
    required: false,
    value: false,
    dirty: false,
    isValid: false,
    checkbox: true,
  },
  frequency: {
    isHidden: { field: 'isPrn', value: true },
    title: 'Frequency',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [],
    dropDownItemValue: '_id',
    dropDownItemLabel: 'description',
    date: false,
  },
  eventTemplates: {
    hidden: true,
    value: null,
    required: true,
  }
};
