import React from 'react';
import {reducer} from "./reducer";
import {ACTIONS} from "./constants";
import {getCareplansAction} from "./actions";
import {InterventionModel} from "../intervention.model";
import {getFrequencies} from "../../../../services/frequency.service";
import {getTenantLocationRoles} from "../../../../services/tenant.service";
import {UserContext} from "../../../../context/usercontext";
import {ResidentContext} from "../../../../context/residentcontext";

let CareplanContext = React.createContext();

let initialState = {
    residentId: null,
    tabIndex: 0,
    careplans: [],
    selectAll: false,
    status: "current",
    generatingPdf: false,
    viewCareplanDialog: false,
    viewGoalDialog: false,
    viewInterventionDialog: false,
    viewCreateCareplanDialog: false,
    viewCreateGoalDialog: false,
    viewCreateInterventionDialog: false,
    model: {},
    goalModel: {},
    interventionModel: JSON.parse(JSON.stringify(InterventionModel)),
    careplan: null,
    goal: null,
    intervention: null,
    frequencies: [],
    roles: [],
    updatingGoal: false,
    updatingIntervention: false,
    updatingCareplan: false,
    loadingIntervention: false,
    loadingGoal: false,
};

const CareplanContextProvider = ({residentId, children}) => {
    let [state, dispatch] = React.useReducer(reducer, initialState);
    const { resident } = React.useContext(ResidentContext);
    const {selectedTenant} = React.useContext(UserContext);
    const value = {state, dispatch};

    React.useEffect(() => {
        getSupportingData().then(() => {});
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        dispatch({type: ACTIONS.SET_RESIDENT_ID, payload: residentId});
        getCareplansAction(dispatch, residentId, "current").then(() => {});
    }, [residentId]);

    const getSupportingData = async () => {
        const response = await Promise.all([
            getFrequencies(),
            getTenantLocationRoles(selectedTenant?._id)
        ])
        dispatch({type: ACTIONS.SET_FREQUENCIES, payload: response[0]});
        let mappedRoles = response[1]?.filter(tl => tl?.location?._id === resident?.location?._id)
                    ?.map(tl => (
                        {...tl,
                            name: `${tl?.role?.name} - ${tl?.location?.name}`,
                            value: `${tl?._id}`
                        })).sort((a, b) => a?.name > b?.name ? 1: -1);
        dispatch({type: ACTIONS.SET_ROLES, payload: mappedRoles});
    }

    return (
        <CareplanContext.Provider value={value}>
            {children}
        </CareplanContext.Provider>
    )
}

export { CareplanContext, CareplanContextProvider };
