import React from 'react';
import {Dialog, DialogContent, DialogTitle, IconButton, makeStyles} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { PDFViewer } from '@react-pdf/renderer';
import {TreatmentReport} from "./TreatmentReport";

const useStyles = makeStyles((theme) => ({
  root: {
  },
  dialogContent: {
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export const PrintTreatmentLogDiaglog = ({open, onClose, month, year, days, treatments, resident}) => {
  const classes = useStyles();

  return (

    <Dialog
      open={open}
      onClose={() => onClose()}
      fullWidth
      maxWidth={"lg"}
    >
      <DialogTitle
        onClose={() => onClose()}>
        {'Resident Treatment Report'}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent} dividers style={{background: '#efefef'}}>
        <PDFViewer style={{width: '100%', height: '70vh'}}>
          <TreatmentReport treatments={treatments}
                           month={month}
                           year={year}
                           days={days}
                           resident={resident} />
        </PDFViewer>
      </DialogContent>
    </Dialog>
  )

}
