const model = {
  date: {
    title: 'Date',
    required: true,
    value: Date.now(),
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: true,
  },
  hour: {
    title: 'Hour',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  result: {
    title: 'Result',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [
      { name: 'Continent', value: 'Continent' },
      { name: 'Incontinent', value: 'Incontinent' },
      { name: 'No results', value: 'No results' },
    ],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  comments: {
    title: 'Comments',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  time: {
    title: 'Time',
    required: true,
    value: 2,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [
      { name: '1 minute', value: 1 },
      { name: '2 minutes', value: 2 },
      { name: '3 minutes', value: 3 },
      { name: '4 minutes', value: 4 },
      { name: '5 minutes', value: 5 },
      { name: '6 minutes', value: 6 },
      { name: '7 minutes', value: 7 },
      { name: '8 minutes', value: 8 },
      { name: '9 minutes', value: 9 },
      { name: '10 minutes', value: 10 },
      { name: '11 minutes', value: 11 },
      { name: '12 minutes', value: 12 },
      { name: '13 minutes', value: 13 },
      { name: '14 minutes', value: 14 },
      { name: '15 minutes', value: 15 },
      { name: '30 minutes', value: 30 },
      { name: '45 minutes', value: 45 },
      { name: '60 minutes', value: 60 },
      { name: '2 hrs', value: 120 },
      { name: '3 hrs', value: 180 },
    ],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  staff: {
    title: 'Staff',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
};

export default model;
