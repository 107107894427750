import React from 'react';
import {
  Button,
  FormGroup, Grid,
  Typography,
} from '@material-ui/core';
import { Paper, makeStyles } from '@material-ui/core';
import { ResidentContext } from '../../../context/residentcontext';
import { getCodeStatuses } from '../intake/context/resident.intake.actions';
import { updateResident } from '../../../services/resident.service';
import { GlobalNotificationContext } from '../../notification/globalnotificationprovider';
import useHasRole from '../../../hooks/user.role.hook';
import {FormFieldsGenerator, isModelValid} from "../../../utils/forms";
import MedicalModel from './medical.model';
import Residentests from "./residentests";
import Vaccinations from "./vaccinations";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0)
  },
  btnContainer: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyItems: 'center',
    justifyContent: 'flex-end',
  },
  testContainer: {
    margin: theme.spacing(1, 0)
  },
  gridItem: {
    padding: theme.spacing(1)
  }
}));

const ResidentMedical = () => {
  const classes = useStyles();
  const [codes, setCodes] = React.useState([]);
  const [backup, setBackup] = React.useState();
  const { state, dispatch } = React.useContext(ResidentContext);
  const { addNotification } = React.useContext(GlobalNotificationContext);
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(MedicalModel)));

  React.useEffect(() => {
    if (state?.resident) {
      mergeData(state?.resident);
    }
    // eslint-disable-next-line
  }, [state.resident]);

  const mergeData = async (entity) => {
    setBackup(state?.resident);
    const obj = JSON.parse(JSON.stringify(MedicalModel));
    obj.specialNeeds.value = entity?.specialNeeds;
    obj.diet.value = Array.isArray(entity?.diet) ? entity?.diet: [];
    obj.dietComment.value = entity?.dietComment;
    obj.livingWill.value =
      entity?.livingWill === true || entity?.livingWill === 'y' ? 'y' : 'n';
    if (!codes || codes.length === 0) {
      const response = await getCodeStatuses();
      setCodes(response);
      obj.codeStatus.dropDownItems = response;
    } else {
      obj.codeStatus.dropDownItems = codes;
    }
    obj.codeStatus.value = entity?.codeStatus ? entity?.codeStatus: [];
    setModel(obj);
  }


  const onUpdate = async () => {
    const obj = { ...state?.resident };
    obj.specialNeeds = model?.specialNeeds?.value;
    obj.livingWill = model?.livingWill?.value === 'y' ? true: false;
    obj.codeStatus = model?.codeStatus?.value;
    obj.diet = model?.diet?.value;
    obj.dietComment = model?.dietComment?.value;
    dispatch({ type: 'UPDATE_RESIDENT_START' });
    await updateResident(state?.resident?._id, obj);
    dispatch({
      type: 'UPDATE_RESIDENT_COMPLETE',
      payload: obj,
    });
    addNotification('Resident Updated', 'success');
  };

  const onCancel = () => {
    mergeData(backup);
  };


  const { checkPermission } = useHasRole();
  const canEdit = checkPermission('RESIDENT_UPDATE');

  const onModelChange = e => {
    setModel({...model, ...e});
  }


  return (
    <Grid container className={classes.root}>
      <Grid xs={12} component={Paper} elevation={24} className={classes.gridItem}>
        <Typography variant="h5">Resident Medical</Typography>
        {!state.loadingResident && model && (
          <form noValidate>
            <FormFieldsGenerator model={model} onChange={onModelChange} />
            {canEdit &&
            <FormGroup row className={classes.btnContainer}>
              <Button onClick={onCancel}>Cancel</Button>
              <Button color="primary" variant="contained" onClick={onUpdate} disabled={!isModelValid(model)}>
                Update
              </Button>
            </FormGroup>
            }
          </form>
        )}
      </Grid>
      <Grid container component={Paper} elevation={24} className={classes.testContainer}>
        <Grid xs={12} md={6} className={classes.gridItem}>
          <Residentests resident={state?.resident} />
        </Grid>
        <Grid xs={12} md={6} className={classes.gridItem}>
          <Vaccinations resident={state?.resident} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ResidentMedical;
