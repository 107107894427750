import React from 'react';
import {Button, Grid, Paper} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {getAllergy, updateAllergy} from "../../services/allergy.service";
import TemplateModel from './allergy.model';
import {FormFieldsGenerator, isModelValid} from "../../utils/forms";
import {PageTitle} from "../utils";

const useStyles = makeStyles((theme => ({
  root: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1),
  },
  btnContainer: {
    margin: theme.spacing(2, 0, 0, 0)
  },
  headerContainer: {
    margin: theme.spacing(1, 0)
  },
  backBtn: {
    marginRight: ".5rem",
  },
})));

const Allergy = ({match}) => {
  const classes = useStyles();
  const [allergy, setAllergy] = React.useState();
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(TemplateModel)));

  React.useEffect(() => {
    if (match.params.allergyId) {
      getData(match.params.allergyId);
    }
    // eslint-disable-next-line
  }, [match.params.alergyId]);

  const getData = async (id) => {
    const entity = await getAllergy(id)
    setAllergy(entity);
    mergeData(entity);
  }

  const mergeData = (entity) => {
    const obj = JSON.parse(JSON.stringify(TemplateModel));
    obj.name.value = entity?.name;
    obj.description.value = entity?.description;
    setModel(obj);
  }

  const onChange = e => {
    setModel({...model, ...e});
  }

  const cancelEdit = () => {
    mergeData(allergy);
  }

  const onUpdateAllergy = async () => {
    const data = {
      name: model?.name?.value,
      description: model?.description?.value
    };
    const entity = await updateAllergy(allergy?._id, data);
    setAllergy(entity);
  }

  return (
    <Paper elevation={24} className={classes.root}>
      <PageTitle title={"Allergies"} />
      <form noValidate>
        <FormFieldsGenerator model={model} onChange={onChange} />
        <Grid container
              className={classes.btnContainer}
              justify={"flex-end"}>
          <Button size={"small"}
                  color={"primary"}
                  onClick={cancelEdit}
          >Cancel</Button>
          <Button size={"small"}
                  color={"primary"}
                  variant={"contained"}
                  onClick={onUpdateAllergy}
                  disabled={!isModelValid(model)}
          >Update</Button>
        </Grid>
      </form>
    </Paper>
  )
}

export default Allergy;
