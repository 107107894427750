import React from 'react';
import {
  Dialog,
  DialogContent, DialogTitle, IconButton,
  makeStyles,
} from "@material-ui/core";
import { PDFViewer } from '@react-pdf/renderer';
import ResidentMarPdf from "./pdf/resident.mar.pdf";
import moment from 'moment';
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
  },
  dialogContent: {
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const PrintMarDialog = ({open, onClose, days, month, year, data, reviewData, resident, loading}) => {
  const classes = useStyles();

  const getMonthName = () => {
    return moment({ month: +month - 1}).format('MMMM');
  }

  return (
        <Dialog
      open={open}
      onClose={() => onClose()}
      fullWidth
      maxWidth={"lg"}
    >
          <DialogTitle
            onClose={() => onClose()}>
            {'Mar Report'}
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        <DialogContent className={classes.dialogContent} dividers style={{background: '#efefef'}}>
          {!loading && resident && data && days && open && month &&
          <PDFViewer style={{width: '100%', height: '70vh'}}>
            <ResidentMarPdf name={"blue"}
                            resident={resident}
                            data={data}
                            reviewData={reviewData}
                            year={year}
                            monthName={getMonthName()}
                            days={days}/>
          </PDFViewer>
          }
        </DialogContent>
        </Dialog>
  )
}

export default PrintMarDialog;
