import React from 'react';
import {CareplanContextProvider} from "./context";
import {useParams, Switch} from "react-router-dom";
import PrivateRoute from "../../PrivateRoute";
import {ResidentCareplan} from "./resident.careplan";
import {ResidentCareplanGoal} from "./resident.careplan.goal";
import {InterventionDialog} from "./InterventionDialog";
import {CreateGoalDialog} from "./CreateGoalDialog";
import {CreateInterventionDialog} from "./CreateInterventionDialog";
import {ResidentCareplanGoalIntervention} from "./resident.careplan.goal.intervention";
import {CreateCareplanDialog} from "./CreateCareplanDialog";
import {ResidentCareplanIntervention} from "./ResidentCareplanIntervention";
import {ResidentCareplans} from "./ResidentCareplans";

const ResidentCareplansComponent = () => {
    return (
        <div>
            <Switch>
                <PrivateRoute path={"/residents/:residentId/careplans/:careplanId/goals/:goalId/interventions/:interventionId"}
                              component={ResidentCareplanGoalIntervention} />
                <PrivateRoute path={"/residents/:residentId/careplans/:careplanId/goals/:goalId"}
                              component={ResidentCareplanGoal} />
                <PrivateRoute path={"/residents/:residentId/careplans/:careplanId/interventions/:interventionId"}
                              component={ResidentCareplanIntervention} />
                <PrivateRoute path={"/residents/:residentId/careplans/:careplanId/goals"}
                              component={ResidentCareplan} />
                <PrivateRoute path={"/residents/:residentId/careplans/:careplanId"}
                              component={ResidentCareplan} />
                <PrivateRoute exact
                              path={"/residents/:residentId/careplans"}
                              component={ResidentCareplans} />
            </Switch>
            <InterventionDialog />
            <CreateCareplanDialog />
            <CreateGoalDialog />
            <CreateInterventionDialog />
        </div>
    )
}

const Wrapper = () => {
    const {residentId} = useParams();
    return (
        <CareplanContextProvider residentId={residentId}>
            <ResidentCareplansComponent />
        </CareplanContextProvider>
    )
}

export default Wrapper;
