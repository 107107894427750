import React from 'react';
import {getOrderStatuses, getResidentMedicationOrders} from "../../../../services/medication.order.service";
import filterTemplate from "../../../resident/medicationorders/filterTemplate";
import {convertToFilters} from "../../../../utils/filter.utils";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from "@material-ui/core";
import {PDFViewer} from "@react-pdf/renderer";
import {CurrentResidentMedicationsReport} from "./CurrentResidentMedicationsReport";

export const CurrentResidentMedicationReportDialog = ({open, resident, onClose}) => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState();
  const [orderStatuses, setOrderStatuses] = React.useState([]);


  React.useEffect(() => {
    if (open && resident?._id) {
      generateReport();
    }
    // eslint-disable-next-line
  }, [open, resident]);

  const init = async () => {
    if (!orderStatuses || orderStatuses.length === 0) {
      const data = await getOrderStatuses();
      setOrderStatuses(data);
      return data.find((d) => d.name === 'Active');
    } else {
      return orderStatuses.find((d) => d.name === 'Active');
    }
  };

  const generateReport = async () => {
    setLoading(true);
    const activeStatus = await init();
    const obj = JSON.parse(JSON.stringify(filterTemplate));
    obj.status.value = activeStatus ? activeStatus._id : -1;
    const filters = convertToFilters(obj);
    const response = await getResidentMedicationOrders(resident?._id,1, 1000, filters, 'medication.tradeName', 'asc');
    if (!response?.error) {
      setData(response?.content);
    }
    setLoading(false);
  }

  return (
    <Dialog open={open}
            onClose={onClose}
            fullWidth
            maxWidth={"lg"}
            >
      <DialogTitle>{"Current Resident Medications Report"}</DialogTitle>
        <DialogContent>
          {loading && <LinearProgress />}
          {!loading && data &&
            <PDFViewer style={{width: '100%', height: '70vh'}}>
              <CurrentResidentMedicationsReport data={data} resident={resident} />
            </PDFViewer>
          }
        </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
