import React from 'react'
import PrivateRoute from "../PrivateRoute";
import { Switch } from "react-router-dom";
import Medication from './medication';
import MedicationList from './medication.list';

const Medications = () => {
    return (    
      <Switch>
        <PrivateRoute exact path="/medications" component={MedicationList} />
        <PrivateRoute exact path="/medications/:id" component={Medication} />
      </Switch>
    )
}

export default Medications
