const filterTemplate = {
  id: {
    field: 'id',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  tags: {
    field: 'tags',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  firstName: {
    field: 'firstName',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  lastName: {
    field: 'lastName',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  gender: {
    field: 'gender',
    minValue: 1,
    value: null,
    operator: '*=*',
  },
  maritalStatus: {
    field: 'maritalStatus',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  religiousPreference: {
    field: 'religiousPreference',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  roomNumber: {
    field: 'roomNumber',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  residentStatus: {
    field: 'residentStatus',
    minValue: 12,
    value: -1,
    operator: '==',
  },
  tenantLocationId: {
    field: 'location',
    minValue: 1,
    value: null,
    operator: '=in=',
  },
};

export default filterTemplate;
