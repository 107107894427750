import React from 'react';
import {Link, makeStyles, Menu, MenuItem} from "@material-ui/core";
const ITEM_HEIGHT = 48;

const useStyles = makeStyles((theme) => ({
  userAnchor: {
    cursor: 'pointer'
  }
}))

export const ETarUserSelect = ({eTar, users, user, onChange}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const userOpen = Boolean(anchorEl);

  const handleToggleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateUser = userId => {
    setAnchorEl(null);
    onChange(userId);
  }

  return (
    <>
      <Link className={classes.userAnchor}
            onClick={handleToggleMenu}>{user?.initials || 'User'}</Link>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={userOpen}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '30ch',
          },
        }}
      >
        <MenuItem onClick={() => updateUser(null)}>
          No User
        </MenuItem>
        {users?.sort((a, b) => a.lastName > b.lastName ? 1: -1)?.map(user =>
          <MenuItem key={user?._id}
                    selected={eTar?.administeredUser?._id === user?._id}
                    onClick={() => updateUser(user?._id)}
          >
            {user?.firstName} {user?.lastName} ({user?.initials})
          </MenuItem>
        )}
      </Menu>
    </>
  )
}
