import React from 'react';
import Transition from "../../dialog/transition";
import {
  AppBar, Avatar,
  Button, Card, CardActions, CardContent, Checkbox,
  Dialog,
  DialogContent, FormControl, FormControlLabel, FormLabel, Grid,
  IconButton,
  List, ListItem, ListItemText, makeStyles,
  Toolbar,
  Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {FormFieldsGenerator, isModelValid} from "../../../utils/forms";
import UserModel from './create.model';
import {addUserRole, createUser, searchEmails} from "../../../services/user.service";
import FileSelector from "../../utils/fileselector/fileselector";
import {WarningTwoTone} from "@material-ui/icons";
import {getTenantLocationRoles} from "../../../services/tenant.service";
import * as _ from "lodash";
import history from "../../../utils/history";
import useGlobalNotification from "../../../hooks/notification.hook";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  dialogContent: {
    marginTop: '4rem',
  },
  gridItem: {
    padding: theme.spacing(2)
  },
  warning: {
    padding: theme.spacing(0, 1)
  },
  existingUserWarning: {
    backgroundColor: theme.palette.warning.light,
    padding: theme.spacing(1)
  }
}));



export const CreateUserModal = ({ open, tenant, onClose, save}) => {
  const classes = useStyles();
  const [ userRoles, setUserRoles] = React.useState([]);
  const [resolutedUser, setResolutedUser] = React.useState();
  const [emailConflicts, setEmailConflicts] = React.useState([]);
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(UserModel)));
  const { addNotification } = useGlobalNotification();

  React.useEffect(() => {
    const getData = async id => {
      let roles = await getTenantLocationRoles(id);
      const sorted = _.orderBy(roles,
        [
          item => item?.location?.name,
          item => item?.role?.name
        ], [ 'asc', 'asc']);
      setUserRoles(sorted);
    }
    if (open) {
      getData(tenant?._id);
      setModel(JSON.parse(JSON.stringify(UserModel)));
      setEmailConflicts([]);
      setResolutedUser(null)
    }
    // eslint-disable-next-line
  }, [open]);

  const onChange = async (e, field) => {
    setEmailConflicts([])
    const merged = {...model, ...e};
    setModel(merged);
  };

  const isUserValid = () => {
    const re = /\S+@\S+\.\S+/;
    const validEmail = re.test(model?.email?.value);
    if (emailConflicts?.length > 0 && !resolutedUser) {
      return false;
    }
    return isModelValid(model) && validEmail && model?.roles?.value?.length > 0;
  }

  const validateAndSubmit = async () => {
    if (resolutedUser && model?._id?.value) {
      history.push([`/users/${model?._id?.value}`]);
    } else {
      const emails = await searchEmails(model?.email?.value);
      if (emails?.length > 0) {
        setEmailConflicts(emails);
      } else {
        setResolutedUser(null);
        setEmailConflicts([]);
        await create();
      }
    }
  }

  const create = async () => {
    const obj = {};
    Object.keys(model).forEach((k) => {
      obj[k] = model[k].value;
    });
    obj.name = `${obj.firstName} ${obj.lastName}`;
    const createUserResponse = await createUser(obj);
    if (createUserResponse?.ok) {
      save(createUserResponse.content);
    } else {
      addNotification(createUserResponse?.message, 'error');
    }
  }

  const enterNewEmail = () => {
    const obj = {...model};
    obj.email.value = null;
    obj._id.value = null;
    setResolutedUser(null)
    setModel(obj);
    setEmailConflicts([]);
  }

  const conflictSelected = async conflict => {
    const roles = userRoles?.filter(ur => ur?.checked).map(ur => ur?._id);
    const response = await addUserRole(conflict?._id, roles);
    if (!response?.error) {
      addNotification('Existing user assigned to location', 'success');
      save(conflict);
    } else {
      addNotification('Unable to assing user role', 'error');
    }
  }

  const handleChange = userRole => {
    const obj = userRoles?.map(ur => {
      if (ur?._id === userRole?._id) {
        ur.checked = !ur.checked
      }
      return ur;
    });
    setUserRoles(obj);
    const mdl = {...model};
    mdl.roles.value = obj?.filter(item => item?.checked);
    setModel(mdl);
  }

  const onFileSelected = event => {
    const obj = {...model};
    if (event?.target?.files?.length === 1) {
      const file = event.target.files[0];
      obj.picture.value = URL.createObjectURL(file);
    } else {
      obj.picture.value = null;
    }
    setModel(obj);
  }

  const doesUserExistForThisTenant = () => {
    if (resolutedUser) {
      const tenantRoles = userRoles?.filter(ur => ur?.location?.tenant === tenant?._id && ur?.preexisting === true);
      return tenantRoles?.length > 0;
    } else {
      return false;
    }
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Create User</Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.dialogContent}>

        <Card elevation={24}>
          <CardContent>
            {doesUserExistForThisTenant() &&
              <div className={classes.existingUserWarning}>
                <Typography variant={"h6"}>This user currently exists for {tenant?.name}</Typography>
              </div>
            }
            <Grid container>
              {emailConflicts?.length > 0 &&
              <div>
                <Grid container>
                  <Grid item className={classes.warning}>
                    <Avatar>
                      <WarningTwoTone />
                    </Avatar>
                  </Grid>
                  <Grid item>
                    <Typography variant={"subtitle1"}>A user with this email is already in the system.</Typography>
                    <Typography variant={"subtitle2"}>If you want to give this user access, please select the user, otherwise enter a new email address.</Typography>
                  </Grid>
                </Grid>
                <List>
                  <ListItem button onClick={enterNewEmail}>
                    <ListItemText primary={"Enter new email address"} />
                  </ListItem>
                  {emailConflicts?.map(conflict =>
                    <ListItem button
                              onClick={() => conflictSelected(conflict)}
                    >
                      <ListItemText primary={conflict.email}
                                    secondary={`${conflict.firstName} ${conflict.lastName}`}
                      />
                    </ListItem>)}
                </List>
              </div>
              }
              {(!emailConflicts || emailConflicts?.length === 0) &&
                <>
              <Grid item xs={12} sm={3} md={2} className={classes.gridItem}>
                <Avatar src={model?.picture?.value}
                        style={{height: 140, width: 140 }}
                        variant={"square"}
                />
                <FileSelector
                  label={'User Photo'}
                  onFileSelected={onFileSelected}
                  style={{ width: 140 }}
                  color={"primary"}
                />
              </Grid>
              <Grid item xs={12} sm={9} md={5} className={classes.gridItem}>
                <form noValidate>
                  <FormLabel>User Information</FormLabel>
                  <FormFieldsGenerator model={model} onChange={onChange} />
                </form>
              </Grid>
              <Grid item sm={12} md={5} className={classes.gridItem}>
                <div>
                  <Typography variant={"h6"}>
                    ROLES
                  </Typography>
                  <FormControl>
                    <FormLabel label={"Roles"} />
                    {userRoles?.map(userRole =>
                      <FormControlLabel label={`${userRole?.role?.name} (${userRole?.location?.name})`}
                                        control={<Checkbox checked={userRole?.checked}
                                                           onChange={() => handleChange(userRole)} name="gilad" />}
                      />
                    )}
                  </FormControl>
                </div>
              </Grid>
              </>
                }
            </Grid>
          </CardContent>
          {emailConflicts?.length === 0 &&
          <CardActions>
            <Grid container justify={"flex-end"}>
            <Button variant={"text"} onClick={onClose}>Cancel</Button>
            <Button disabled={!isUserValid()}
                    variant={"contained"}
                    onClick={validateAndSubmit}
                    color={"primary"}>
              Save
            </Button>
            </Grid>
          </CardActions>}
        </Card>
      </DialogContent>
    </Dialog>
  )
}

