import React from 'react';
import {Button, DialogActions, DialogContent, DialogTitle, FormGroup, makeStyles, TextField} from "@material-ui/core";
import {ResidentFileContext} from "../context/ResidentFilesContext";
import {CONSTANTS} from "../context/Constants";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "30vw"
    }
}));

export const RenameFile = ({onRename}) => {
    const classes = useStyles();
    const {state, dispatch} = React.useContext(ResidentFileContext);
    const [fileName, setFileName] = React.useState();

    React.useEffect(() => {
        if (state?.viewRenameFileModal && state?.selectedFileNode) {
            setFileName(state?.selectedFileNode?.fileName);
        } else {
            setFileName(null)
        }
    }, [state?.viewRenameFileModal, state?.selectedFileNode]);

    const handleCancel = () => {
        dispatch({type: CONSTANTS.SET_VIEW_RENAME_MODAL, payload: false});
    }

    const onChange = e => {
        setFileName(e.target.value);
    }

    const isValid = () => {
        const matchingFiles = state?.files?.filter(f => f?.label === fileName);
        return fileName !== state?.selectedFileNode?.fileName && matchingFiles?.length === 0;
    }

    const handleOnRename = () => {
        onRename({
            file: state?.selectedFileNode,
            fileName: fileName
        });
    }

    return (
        <div className={classes.root}>
            <DialogTitle>
                Rename File
            </DialogTitle>
            <DialogContent dividers style={{height: "85vh"}}>
                <FormGroup className={classes.formRoot}>
                    <TextField fullWidth
                               variant={"outlined"}
                               label={"Source"}
                               readonly
                               value={fileName}
                               onChange={onChange}
                    />

                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleOnRename}
                        variant={"contained"}
                        disabled={!isValid()}
                        color={"primary"}>
                    Rename
                </Button>
            </DialogActions>
        </div>
    )
}
