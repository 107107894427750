import React from 'react';
import {
  makeStyles,
  FormGroup,
  Button,
  Tabs,
  Tab,
} from '@material-ui/core';
import { UserContext } from '../../context/usercontext';
import { FormFieldsGenerator, isModelValid } from '../../utils/forms';
import TenantModel from './tenant.model';
import {
  createTenantImage,
  getTenant,
  updateTenant,
} from '../../services/tenant.service';
import LocationList from './location.list';
import { DropzoneDialog } from 'material-ui-dropzone';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import config from '../../utils/configs';
import TenantRoles from './roles/tenant.roles';
import useHasRole from "../../hooks/user.role.hook";
import {TenantCalendar} from "./calendar";
import {PageTitle} from "../utils";
import {getAllPermissions} from "../../services/permission.service";
import {TenantFeatures} from "./features";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0, 1, 0),
    padding: theme.spacing(1),
  },
  container: {},
  formContainer: {
    padding: theme.spacing(2),
  },
}));

const Tenants = ({ tenantId }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState("tenant");
  const [permissions, setPermissions] = React.useState();
  const [logo, setLogo] = React.useState();
  const [backup, setBackup] = React.useState();
  const [cropLogo, setCropLogo] = React.useState();
  const [crop, setCrop] = React.useState();
  const [imageRef, setImageRef] = React.useState();
  const [showUpload, setShowUpload] = React.useState(false);
  const { user, dispatch } = React.useContext(UserContext);
  const [selectedFile, setSelectedFile] = React.useState();
  const [imgSrc, setImgSrc] = React.useState();
  const [model, setModel] = React.useState(
    JSON.parse(JSON.stringify(TenantModel))
  );
  const { checkPermission } = useHasRole();
  const canTenantUpdate = checkPermission("TENANT_UPDATE");
  const canRoleRead = checkPermission("ROLE_READ");

  React.useEffect(() => {
    if (tenantId) {
      getTenantData(tenantId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId]);

  const getTenantData = async (id) => {
    const data = await getTenant(id);
    const permissionResponse = await getAllPermissions();
    setPermissions(permissionResponse?.content)
    setBackup(data);
    parseTenant(data);
    getPhoto(id);
  };

  const buildImageUrlString = (tenantId, logo) => {
    return `${config.bucketObjectUrl}/${config.publicBucketName}/tenants/${tenantId}/logos/${logo}`;
  };

  const getPhoto = async (id) => {
    const imageUrl = buildImageUrlString(id, backup?.logo);
    setImgSrc(imageUrl);
  };

  const parseTenant = (tenant) => {
    const obj = { ...model };
    if (tenant) {
      obj.name.value = tenant.name;
      obj.email.value = tenant.email;
      obj.medicaid.value = tenant.medicaid;
      obj.medicare.value = tenant.medicare;
      obj.tin.value = tenant.tin;
      obj.isActive.value = tenant.isActive;
      obj.address.value = tenant.address.address;
      obj.address2.value = tenant.address.address2;
      obj.city.value = tenant.address.city;
      obj.state.value = tenant.address.state;
      obj.zipCode.value = tenant.address.zipCode;
    }
    setModel(obj);
  };

  const onChange = (e) => {
    setModel({ ...model, ...e });
  };

  const cancelEdit = () => {
    parseTenant(backup);
  };

  const onSave = async () => {
    const data = {
      name: model.name.value,
      email: model.email.value,
      medicaid: model.medicaid.value,
      medicare: model.medicare.value,
      tin: model.tin.value,
      isActive: model.isActive.value,
      address: {
        address: model.address.value,
        address2: model.address2.value,
        city: model.city.value,
        state: model.state.value,
        zipCode: model.zipCode.value,
      },
    };
    const tenant = await updateTenant(backup._id, data);
    dispatch({ type: 'tenantUpdated', payload: tenant });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const loadPhoto = (loadedFiles) => {
    if (loadedFiles && loadedFiles.length > 0) {
      const file = loadedFiles[0];
      setSelectedFile(file);
      setLogo(URL.createObjectURL(file));
      setCropLogo(URL.createObjectURL(file));
      setShowUpload(false);
    }
  };

  const onCropChange = (cp) => {
    // this.setState({ crop });
    setCrop(cp);
  };

  const saveLogo = async () => {
    if (crop && crop.height > 0) {
      const newLogo = getCroppedImg(imageRef, crop);
      const croppedBlob = await getCroppedImgBlob(
        imageRef,
        crop,
        selectedFile.name
      );
      setLogo(newLogo);
      setCropLogo(null);
      setCrop({});
      const formData = new FormData();
      formData.append('file', croppedBlob, selectedFile.name);
      await createTenantImage(tenantId, formData);
      dispatch({
        type: 'updateTenantLogo',
        payload: selectedFile.name,
      });
    } else {
      const formData = new FormData();
      formData.append('file', selectedFile, selectedFile.name);
      await createTenantImage(tenantId, formData);
      dispatch({
        type: 'updateTenantLogo',
        payload: selectedFile.name,
      });
    }
  };

  const onImageLoaded = (img) => {
    setImageRef(img);
  };

  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;

    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const base64Image = canvas.toDataURL('image/png');
    return base64Image;
  };

  const getCroppedImgBlob = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;

    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            console.error('Canvas is empty');
            return;
          }
          blob.name = fileName;
          resolve(blob);
        },
        'image/png',
        1
      );
    });
  };

  const cancelSave = () => {
    setLogo(null);
  };

  return (
    <>
      <div className={classes.root}>
        <PageTitle title={"Tenant"} />
        <Tabs value={value}
              onChange={handleChange}
              variant="fullWidth">
          <Tab label="Tenant" value={"tenant"} />
          <Tab label="Tenant Logo" value={"logo"} />
          <Tab label="Locations" value={"locations"} />
          {canRoleRead && <Tab label="Roles" value={"roles"} />}
          <Tab label={"Calendar"} value={"calendar"} />
          {user?.isSystemAdmin && <Tab label={"Features"} value={"features"} />}
        </Tabs>
      </div>
      <div className={classes.container}>
        <div hidden={value !== "tenant"} className={classes.formContainer}>
          <form noValidate>
            <FormFieldsGenerator model={model} onChange={onChange} edit={canTenantUpdate} />
            <FormGroup
              row
              style={{ justifyContent: 'flex-end', marginTop: '.5rem' }}
            >
              <Button color="primary" onClick={cancelEdit}>
                Cancel
              </Button>
              {canTenantUpdate && <Button
                color="primary"
                variant="contained"
                disabled={!isModelValid(model)}
                onClick={onSave}
              >
                Save
              </Button>}
            </FormGroup>
          </form>
        </div>
        <div hidden={value !== "logo"}>
          {!logo && !cropLogo && imgSrc && (
            <img
              src={buildImageUrlString(tenantId, backup?.logo)}
              alt="Tenant"
            />
          )}
          {logo && !cropLogo && (
            <>
            {canTenantUpdate && <Button
                onClick={() => {
                  setLogo(null);
                  setShowUpload(true);
                }}
              >
                Select File
              </Button>}
              <img src={logo} alt="logo" />
            </>
          )}
          {!logo && (
            <>
            {canTenantUpdate && <Button onClick={() => setShowUpload(true)}>Select File</Button>}
              <DropzoneDialog
                open={showUpload}
                onClose={() => setShowUpload(false)}
                onSave={loadPhoto}
                filesLimit={1}
                showAlerts={false}
                acceptedFiles={['image/*']}
              />
            </>
          )}
          {cropLogo && (
            <form>
              <FormGroup>
                <ReactCrop
                  src={cropLogo}
                  crop={crop}
                  onImageLoaded={onImageLoaded}
                  onChange={onCropChange}
                />
              </FormGroup>
              <FormGroup
                row
                style={{ justifyContent: 'center', marginTop: '1rem' }}
              >
                <Button color="primary" onClick={cancelSave}>
                  Cancel
                </Button>
                <Button color="primary" variant="contained" onClick={saveLogo}>
                  Crop and Save
                </Button>
              </FormGroup>
            </form>
          )}
        </div>
        {value === "locations" && <LocationList tenantId={tenantId} />}
        {value === "roles" && <TenantRoles tenant={backup} permissions={permissions} />}
        {value === "calendar" && <TenantCalendar />}
        {value === "features" && <TenantFeatures tenantId={tenantId} />}
      </div>
    </>
  );
};

export default Tenants;
