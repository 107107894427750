import React from 'react';
import {Button, Checkbox, FormControlLabel, TableCell, TableRow} from "@material-ui/core";
import {Link} from 'react-router-dom';
import {getStripedStyle} from "../../utils/table.utils";
import moment from "moment";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";
import DeleteIcon from "@material-ui/icons/Delete";
import {ValueConfirmDialog} from "../../utils/dialogs/value.confirm.dialog";
import {deleteResidentAssessment} from "../../../services/assessment.service";

export const AssessmentTableRowComponent = ({index, residentId, residentAssessment, onDelete, onSelected}) => {
  const [viewConfirmDialog, setViewConfirmDialog] = React.useState(false);

  const handleOnConfirm = async () => {
      const response = await deleteResidentAssessment(residentId, residentAssessment?._id);
      if (!response?.error) {
        onDelete(residentAssessment?._id);
        setViewConfirmDialog(false);
      }
  }

  return (
    <>
      <ValueConfirmDialog open={viewConfirmDialog}
                          confirmationValue={residentAssessment?.assessment?.name}
                          onClose={() => setViewConfirmDialog(false)}
                          onConfirm={handleOnConfirm}
                          message={`Enter assessment name to delete: ${residentAssessment?.assessment?.name}`}
                          title={"Delete Assessment"} />

      <TableRow style={getStripedStyle(index)}>
        <TableCell>
          <FormControlLabel
            control={
              <Checkbox onChange={(evt) => onSelected(evt, residentAssessment)}
                        checked={residentAssessment?.selected || ''}
                        name={`select-${residentAssessment?._id}`}
              />
            }
          />
          <Button size={"small"}
                  component={Link}
                  to={`/residents/${residentId}/assessments/${residentAssessment?._id}`}
                  startIcon={<ImageSearchIcon/>}>
            View
          </Button>
          <Button size={"small"}
                  onClick={() => setViewConfirmDialog(true)}
                  startIcon={<DeleteIcon fontSize={"small"}/>}>
            Delete
          </Button>
        </TableCell>
        <TableCell>{moment(residentAssessment?.createdAt).format('MM/DD/YYYY hh:mm')}</TableCell>
        <TableCell>{residentAssessment?.isComplete ? moment(residentAssessment?.completedAt)?.format("MM/DD/YYYY"): "" }</TableCell>
      </TableRow>
    </>
  )
}
