import moment from "moment";
import {Grid, Typography} from "@material-ui/core";
import {WarningTwoTone} from "@material-ui/icons";

export const isValid = (editEmar) => {
  if (!editEmar?.chartVitals) {
    return true;
  }
  const obj = { ...editEmar?.vital, ...editEmar?.updatedVital };

  if (editEmar?.chartVitalsList?.indexOf('Blood glucose') > -1 && !obj?.bloodGlucose) {
    return false;
  }

  if (editEmar?.chartVitalsList?.indexOf('INR') > -1 && !obj?.inr) {
    return false;
  }

  if (editEmar?.chartVitalsList?.indexOf('Oxygen') > -1 && !obj?.oxygen) {
    return false
  }

  if (editEmar?.chartVitalsList?.indexOf('Pain') > -1 && !obj?.painLevel) {
    return false
  }

  if (editEmar?.chartVitalsList?.indexOf('Pulse') > -1 && !obj?.pulse) {
    return false
  }

  if (editEmar?.chartVitalsList?.indexOf('Respiratory') > -1 && !obj?.respiratoryRate) {
    return false
  }

  if (editEmar?.chartVitalsList?.indexOf('Temperature') > -1 && !obj?.temperature) {
    return false
  }
  if (editEmar?.chartVitalsList?.indexOf('Weight') > -1 && !obj?.weight) {
    return false
  }

  if (editEmar?.chartVitalsList?.indexOf('Blood pressure') > -1 &&
    (!obj?.systolicBloodPressure || ! obj?.diastolicBloodPressure)) {
    return false;
  }

  return true;
}


export const isMedicationOrderOnHold = (medicationOrder, date) => {
  if (Array.isArray(medicationOrder?.holds) &&  medicationOrder?.holds?.length > 0) {
    let isHold = false;
    medicationOrder.holds.forEach(hold => {
      if (moment(date).isBetween(hold?.startDate, hold?.endDate, 'second', '[]')) {
        isHold = true;
        return;
      }
    });
    return isHold;
  } else {
    return false;
  }
}

export const getCurrentHold = (medicationOrder, date) => {
  if (Array.isArray(medicationOrder?.holds) &&  medicationOrder?.holds?.length > 0) {
    return medicationOrder.holds.find(hold => moment(date).isBetween(hold?.startDate, hold?.endDate, 'day', '[]'))
  };
  return null;
}

export const displayHoldInfo = (medicationOrder, date, isMobile = false) => {
  const hold = getCurrentHold(medicationOrder, date);
  if (hold && !isMobile) {
    return (
      <>
        <Grid container>
          <Grid>
            <Typography variant={"subtitle1"}>
              On Hold
            </Typography>
          </Grid>
        </Grid>
        {hold?.startDate && hold?.endDate &&
          <>
            <Typography variant={"subtitle2"}>
              {moment(hold.startDate).format("MM/DD/YYYY")}
            </Typography>
            <Typography variant={"subtitle2"}>
              {moment(hold.endDate).format("MM/DD/YYYY")}
            </Typography>
          </>
        }
        {hold?.comment &&
          <Typography variant={"subtitle2"}>
            {hold?.comment}
          </Typography>
        }
      </>
    )
  } else if (hold && isMobile) {
    return (
      <>
        <>
          <Typography variant={"subtitle1"}>
            <WarningTwoTone style={{marginRight: '.25rem'}}/>
          </Typography>
          <Typography variant={"subtitle2"}>
            Hold
          </Typography>
        </>
      </>
    )
  } else {
    return null;
  }
}
