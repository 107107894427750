import {
  AppBar,
  Button, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton, LinearProgress,
  Toolbar,
  Typography,
} from '@material-ui/core';
import React from 'react';
import Transition from '../../dialog/transition';
import CloseIcon from '@material-ui/icons/Close';
import Model from './diet.model';
import { FormFieldsGenerator, isModelValid } from '../../../utils/forms';
import Meals from './meals';
import useGlobalLoadingIndicator from "../../../hooks/global.loading.hook";

const EditDiet = ({ open, diet, onClose, onSave }) => {
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(Model)));
  const [updating, setUpdating] = React.useState(false);
  const { loading } = useGlobalLoadingIndicator();


  React.useEffect(() => {
    setUpdating(false);
    if (diet && open) {
      const obj = JSON.parse(JSON.stringify(Model));
      obj._id = { value: diet._id, hidden: true };
      obj.startDate.value = diet.startDate;
      obj.endDate.value = diet.endDate;
      obj.discontinued.value = diet.discontinued;
      obj.type.value = diet.type;
      obj.description.value = diet.description;
      obj.calories.value = diet.calories;
      obj.meals.value = diet.meals;
      obj.tags.value = diet.tags;
      setModel(obj);
    }
  }, [open, diet]);

  const onModelChange = (e) => {
    const obj = { ...model, ...e };

    setModel(obj);
  };

  const onDietTemplatesChange = (e) => {
    const obj = { ...model };
    const existing = obj.meals.value.find((dt) => dt._id === e._id);
    if (existing) {
      obj.meals.value = obj.meals.value.map((meal) =>
        meal._id === e._id ? e : meal
      );
    } else {
      obj.meals.value = [...obj.meals.value, e];
    }
    setModel(obj);
  };

  const save = () => {
    setUpdating(true);
    onSave(model._id.value, {
      _id: model._id.value,
      type: model.type.value,
      startDate: model.startDate.value,
      endDate: model.endDate.value,
      description: model.description.value,
      calories: model.calories.value,
      tags: model.tags.value,
      meals: model.meals.value,
      discontinued:
        model.discontinued.value === null
          ? false
          : Boolean(model.discontinued.value),
    }).then(() => {
      setUpdating(false);
    });
  };

  const onRemoveMeal = (id) => {
    const obj = { ...model };
    obj.meals.value = obj.meals.value.filter((meal) => meal._id !== id);
    setModel(obj);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      fullScreen
    >
      <AppBar>
        {loading && <LinearProgress />}
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Edit Diet</Typography>
        </Toolbar>
      </AppBar>
      <DialogContent style={{ marginTop: '4rem' }}>
        <FormFieldsGenerator model={model} onChange={onModelChange} edit={!updating} />
        <Meals
          meals={model.meals.value}
          onChange={onDietTemplatesChange}
          onRemove={onRemoveMeal}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={updating}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!isModelValid(model) || updating}
          onClick={save}
          startIcon={updating ? <CircularProgress size={20} />: null}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDiet;
