import React from 'react';
import {
  makeStyles,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Transition from '../../dialog/transition';
import GoogleMapReact from 'google-map-react';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    paddingTop: '4rem',
  },
  dialogContent: {
    marginTop: '4rem',
  },
}));

const LoginMarker = ({ text }) => (
  <div
    style={{
      color: 'red',
      background: 'transparent',
      padding: '15px 10px',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '100%',
      transform: 'translate(-50%, -50%)',
    }}
  >
    <div class="fas fa-map-marker fa-2x" />
    <Typography
      variant="subtitle2"
      gutterBottom
      gutterTop
      style={{ color: 'black' }}
    >
      {text}
    </Typography>
  </div>
);

const UserLoginDialog = ({ login, open, onClose }) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      TransitionComponent={Transition}
    >
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => onClose()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Login Information</Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.dialogContent}>
        {login && login.geoIp && (
          <div style={{ width: '100%', height: '20rem' }}>
            <Typography variant="subtitle1">
              Date: {moment(login.createdAt).format('MM/DD/YYYY h:mm a')}{' '}
            </Typography>
            <Typography variant="subtitle1">Action : {login.action}</Typography>
            <Typography variant="subtitle1">
              IP Address: {login?.geoIp?.ip}
            </Typography>
            <Typography variant="subtitle1">Location</Typography>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: 'AIzaSyDv2vUwDrK1QHgLq_Vps211gTEpHkVbblw',
              }}
              defaultCenter={{
                lat: login?.geoIp?.latitude,
                lng: login?.geoIp?.longitude,
              }}
              defaultZoom={12}
            >
              <LoginMarker
                lat={login?.geoIp?.latitude}
                lng={login?.geoIp?.longitude}
                text="Login Location"
              />
            </GoogleMapReact>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UserLoginDialog;
