import React from 'react';
import {ClockInDialog, ClockOutDialog} from './';
import {TimeEntryModal} from "./modal/time.entry.modal";
import {useMediaQuery, useTheme} from "@material-ui/core";
import ClockInMobile from "./clockin/mobile";
import TimeEntryMobileModal from "./modal/time.entry.mobile.modal";

export const TimeEntryComponent = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {isMobile ? <ClockInMobile />: <ClockInDialog />}
      <ClockOutDialog />
      {isMobile ? <TimeEntryMobileModal />: <TimeEntryModal />}
    </>
  )
};
