import apiUtil from '../utils/api.utils';
import configs from '../utils/configs';

export const getResidentCareplan = async (residentId, careplanId) => {
  try {
    const url = `${configs.residentService}/api/v1.0/residents/${residentId}/careplans/${careplanId}`;
    const response = await apiUtil.client.get(url);
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const getResidenCareplans = async (residentId, isActive) => {
  try {
    let url = `${configs.residentService}/api/v1.0/residents/${residentId}/careplans`;
    if (isActive !== null) {
      url += `?active=${isActive}`
    }
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (error) {
    return {error: true, message: error };
  }
};

export const getResidentCareplanLog = async (residentId, year, month) => {
  try {
    const url = `${configs.residentService}/api/v1.0/residents/${residentId}/careplanlog?year=${year}&month=${month}`;
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (error) {
    return {error: true, message: error};
  }
}

export const getResidentCareplanLogEvents = async (residentId, year, month) => {
  try {
    const url = `${configs.residentService}/api/v1.0/residents/${residentId}/careplanlog/events?year=${year}&month=${month}`;
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (error) {
    return {error: true, message: error};
  }
}

export const reprocessResidentCareplanLogEvents = async (residentId, year, month) => {
  try {
    const url = `${configs.residentService}/api/v1.0/residents/${residentId}/careplanlog/reprocess`;
    const response = await apiUtil.client.put(url, {year, month});
    return response.data;
  } catch (error) {
    return {error: true, message: error};
  }
}

export const createCareplan = async (residentId, careplan) => {
  try {
    const url = `${configs.residentService}/api/v1.0/residents/${residentId}/careplans`;
    const response = await apiUtil.client.post(url, careplan);
    return response?.data;
  } catch (error) {
    return {error: true, message: error};
  }
};

export const updateResidenCareplan = async (
  residentId,
  careplanId,
  careplan
) => {
  try {
    const url = `${configs.residentService}/api/v1.0/residents/${residentId}/careplans/${careplanId}`;
    const response = await apiUtil.client.put(url, careplan);
    return response.data;
  } catch (error) {
    return { error: true, message: error };
  }
};

export const deleteResidenCareplan = async (residentId, careplanId) => {
  try {
    const url = `${configs.residentService}/api/v1.0/residents/${residentId}/careplans/${careplanId}`;
    const response = await apiUtil.client.delete(url);
    return response.data;
  } catch (error) {
    return { error: true, message: error };
  }
};

export const getResidentTasks = async (residentId, date) => {
  try {
    const url = `${configs.residentService}/api/v1.0/residents/${residentId}/tasks?date=${date}`;
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (error) {
    return null;
  }
};

export const updateResidentTask = async (residentId, taskId, task) => {
  try {
    const url = `${configs.residentService}/api/v1.0/residents/${residentId}/tasks/${taskId}`;
    const response = await apiUtil.client.put(url, task);
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getResidentPrnTasks = async (residentId) => {
  try {
    const url = `${configs.residentService}/api/v1.0/residents/${residentId}/prntasks`;
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (error) {
    return null;
  }
};

export const createResidentPrnTask = async (residentId, task) => {
  try {
    const url = `${configs.residentService}/api/v1.0/residents/${residentId}/prntasks`;
    const response = await apiUtil.client.post(url, task);
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getCareplanReport = async (residentId, careplanId) => {
  try {
    const url = `${configs.reportService}/api/v1.0/generate/reports/residents/${residentId}/careplan/${careplanId}`;
    const response = await apiUtil.client.get(url, { responseType: 'blob' });
    return response.data;
  } catch (error) {
    return { error: true, message: error};
  }
}

export const getCareplanReports = async (residentId, careplanIds) => {
  try {
    const url = `${configs.reportService}/api/v1.0/generate/reports/residents/${residentId}/careplans?ids=${careplanIds?.join(',')}`;
    const response = await apiUtil.client.get(url, { responseType: 'blob' });
    return response.data;
  } catch (error) {
    return { error: true, message: error};
  }
}

export const createGoal = async (residentId, careplanId, goal) => {
  try {
    const url = `${configs.residentService}/api/v1.0/residents/${residentId}/careplans/${careplanId}/goals`;
    const response = await apiUtil.client.post(url, goal);
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const updateGoal = async (residentId, careplanId, goalId, goal) => {
  try {
    const url = `${configs.residentService}/api/v1.0/residents/${residentId}/careplans/${careplanId}/goals/${goalId}`;
    const response = await apiUtil.client.put(url, goal);
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const deleteGoal = async (residentId, careplanId, goalId) => {
  try {
    const url = `${configs.residentService}/api/v1.0/residents/${residentId}/careplans/${careplanId}/goals/${goalId}`;
    const response = await apiUtil.client.delete(url);
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const createIntervention = async (residentId, careplanId, goalId, intervention) => {
  try {
    const url = `${configs.residentService}/api/v1.0/residents/${residentId}/careplans/${careplanId}/goals/${goalId}/interventions`;
    const response = await apiUtil.client.post(url, intervention);
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const updateIntervention = async (residentId, careplanId, goalId, interventionId, intervention) => {
  try {
    const url = `${configs.residentService}/api/v1.0/residents/${residentId}/careplans/${careplanId}/goals/${goalId}/interventions/${interventionId}`;
    const response = await apiUtil.client.put(url, intervention);
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const deleteIntervention= async (residentId, careplanId, goalId, interventionId) => {
  try {
    const url = `${configs.residentService}/api/v1.0/residents/${residentId}/careplans/${careplanId}/goals/${goalId}/interventions/${interventionId}`;
    const response = await apiUtil.client.delete(url);
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const getIntervention = async (residentId, careplanId, goalId, interventionId) => {
  try {
    const url = `${configs.residentService}/api/v1.0/residents/${residentId}/careplans/${careplanId}/goals/${goalId}/interventions/${interventionId}`;
    const response = await apiUtil.client.get(url);
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const getGoal = async (residentId, careplanId, goalId) => {
  try {
    const url = `${configs.residentService}/api/v1.0/residents/${residentId}/careplans/${careplanId}/goals/${goalId}`;
    const response = await apiUtil.client.get(url);
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const syncInterventionWithGoal = async (residentId, careplanId, interventionId) => {
  try {
    const url = `${configs.residentService}/api/v1.0/residents/${residentId}/careplans/${careplanId}/interventions/${interventionId}`;
    const response = await apiUtil.client.put(url);
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const getCareplansStatus = async (tenantId) => {
  try {
    const url = `${configs.residentService}/api/v1.0/tenants/${tenantId}/careplans/status`
    const response = await apiUtil.client.get(url);
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const createCareplanStatus = async (tenantId, status) => {
  try {
    const url = `${configs.residentService}/api/v1.0/tenants/${tenantId}/careplans/status`
    const response = await apiUtil.client.post(url, status);
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const updateCareplanStatus = async (tenantId, statusId, status) => {
  try {
    const url = `${configs.residentService}/api/v1.0/tenants/${tenantId}/careplans/status/${statusId}`
    const response = await apiUtil.client.put(url, status);
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const deleteCareplanStatus = async (tenantId, statusId) => {
  try {
    const url = `${configs.residentService}/api/v1.0/tenants/${tenantId}/careplans/status/${statusId}`
    const response = await apiUtil.client.delete(url);
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const resetCareplanStatus = async (tenantId) => {
  try {
    const url = `${configs.residentService}/api/v1.0/tenants/${tenantId}/careplans/status/reset`
    const response = await apiUtil.client.get(url);
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
}
