import apiUtil from '../utils/api.utils';
import configs from '../utils/configs';

const getResidentDiets = async (residentId, discontinued = null) => {
  try {
    let url = `${configs.residentService}/api/v1.0/residents/${residentId}/diets`;
    if (discontinued !== null) {
      url += `?discontinued=${discontinued}`;
    }
    let response = await apiUtil.client.get(url);
    return response.data;
  } catch (error) {
    return null;
  }
};

const createResidentDiet = async (residentId, diet) => {
  try {
    const response = await apiUtil.client.post(
      `${configs.residentService}/api/v1.0/residents/${residentId}/diets`,
      diet
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

const updateResidentDiet = async (residentId, dietId, diet) => {
  try {
    const response = await apiUtil.client.put(
      `${configs.residentService}/api/v1.0/residents/${residentId}/diets/${dietId}`,
      diet
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

const getResidentEdars = async (residentId, date) => {
  try {
    const response = await apiUtil.client.get(
      `${configs.residentService}/api/v1.0/residents/${residentId}/edars?date=${date}`
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

const updateResidentEdar = async (residentId, edarId, edar) => {
  try {
    const response = await apiUtil.client.put(
      `${configs.residentService}/api/v1.0/residents/${residentId}/edars/${edarId}`,
      edar
    );
    return response.data;
  } catch (error) {
    return {error: true, message: error};
  }
};

export {
  getResidentDiets,
  createResidentDiet,
  updateResidentDiet,
  getResidentEdars,
  updateResidentEdar,
};
