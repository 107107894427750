const model = {
  livingWill: {
    title: 'Living Will',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [
      { name: 'Select', value: null},
      { name: 'Yes', value: 'y'},
      { name: 'No', value: 'n'}
    ],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  codeStatus: {
    title: 'Code Status',
    required: false,
    value: [],
    dirty: false,
    isValid: true,
    multiSelect: true,
    dropDown: true,
    dropDownItems: [ ],
    dropDownItemValue: 'name',
    dropDownItemLabel: 'name',
    date: false,
  },
  specialNeeds: {
    title: 'Special Needs',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  diet: {
    title: 'Diet',
    required: false,
    value: [],
    dirty: false,
    isValid: true,
    multiSelect: true,
    dropDown: true,
    dropDownItems: [
      { name: 'Regular' },
      { name: 'Diabetic' },
      { name: 'Low sodium' },
      { name: 'Low fiber' },
      { name: 'high fiber' },
      { name: 'high protein' },
      { name: 'low cholesterol' },
      { name: 'No added salt' },
      { name: 'Pureed diet' },
      { name: 'liquid diet' },
      { name: 'Dysphagia I' },
      { name: 'Dysphagia II' },
      { name: 'Dysphagia III' },
      { name: 'Mechanically Altered' },
      { name: 'Tube feeding' },
      { name: 'Gluten free' },
      { name: 'Thickened liquids' },
      { name: 'Renal' }
    ],
    dropDownItemValue: 'name',
    dropDownItemLabel: 'name',
    date: false,
  },
  dietComment: {
    title: 'Diet Comment',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    date: false,
    multiline: true,
    multilineRows: 3
  },
}

export default model;
