import { ObjectId } from 'bson';
import moment from 'moment';

const modelTemplate = {
  type: {
    title: 'Type',
    required: true,
    value: 'Regular',
    isDirty: false,
    isValid: false,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [
      {
        name: 'Regular',
      },
      {
        name: 'Diabetic',
      },
      {
        name: 'Diabetic 2000 cal',
      },
      {
        name: 'Diabetic 2500 cal',
      },
      {
        name: 'Low sodium',
      },
      {
        name: 'Low fiber',
      },
      {
        name: 'High fiber',
      },
      {
        name: 'High protein',
      },
      {
        name: 'Low cholestrol',
      },
      {
        name: 'No salt added',
      },
      {
        name: 'Pureed diet',
      },
      {
        name: 'Liquid diet',
      },
      {
        name: 'Disphagia 1',
      },
      {
        name: 'Disphagia 2',
      },
      {
        name: 'Disphagia 3',
      },
      {
        name: 'Mechanically altered',
      },
      {
        name: 'Tube feeding',
      },
      {
        name: 'Gluten free',
      },
      {
        name: 'Thickened liquids',
      },
      {
        name: 'Renal',
      },
    ],
    dropDownItemValue: 'name',
    dropDownItemLabel: 'name',
    date: false,
  },
  startDate: {
    title: 'Start Date',
    required: true,
    value: new Date(),
    isDirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: true,
  },
  endDate: {
    title: 'End Date',
    required: true,
    value: moment(new Date()).add(1, 'year'),
    isDirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: true,
  },
  calories: {
    title: 'Calories',
    required: false,
    value: 2500,
    isDirty: false,
    isValid: false,
  },
  description: {
    title: 'Description',
    required: false,
    value: null,
    isDirty: false,
    isValid: false,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
    multiline: true,
    multilineRows: 4,
  },
  tags: {
    title: 'Tags',
    isTagsComponent: true,
    hidden: false,
    value: [],
    required: false,
  },
  meals: {
    title: 'meals',
    hidden: true,
    required: true,
    value: [
      {
        _id: new ObjectId(),
        type: 'Breakfast',
        frequency: 'QD',
        templates: [
          {
            time: moment({ hour: 8, minute: 0 }),
          },
        ],
      },
      {
        _id: new ObjectId(),
        type: 'Other',
        frequency: 'QD',
        templates: [
          {
            time: moment({ hour: 10, minute: 0 }),
            comments: 'Snack',
          },
        ],
      },
      {
        _id: new ObjectId(),
        type: 'Lunch',
        frequency: 'QD',
        templates: [
          {
            time: moment({ hour: 12, minute: 0 }),
          },
        ],
      },
      {
        _id: new ObjectId(),
        type: 'Dinner',
        frequency: 'QD',
        templates: [
          {
            time: moment({ hour: 17, minute: 0 }),
          },
        ],
      },
    ],
    isDirty: false,
    isValid: false,
  },
  discontinued: {
    title: 'Discontinued',
    value: false,
    required: true,
    isToggle: true,
  },
};

export default modelTemplate;
