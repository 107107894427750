import React from 'react';
import {Grid, Hidden, makeStyles, Paper, Tab, Tabs} from '@material-ui/core';
import ESchedule from '../eschedule/eschedule';
import ResidentList from '../resident/list/resident.list';
import ResidentNotes from "../notes/ResidentNotes";
import {MobileDashboard} from "./mobile.dashboard";
import {TenantCalendarPageWrapper} from "../tenants/calendar";
import {DateRangeIcon} from "@material-ui/pickers/_shared/icons/DateRangeIcon";
import {DashboardTwoTone} from "@material-ui/icons";
import useHasRole from "../../hooks/user.role.hook";
import Reports from "../reports/reports";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";


const useStyles = makeStyles((theme) => ({
  root: {},
  dashboardItem: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 0),
    },
    padding: theme.spacing(1),
  },
  mockPaper: {
    padding: theme.spacing(2),
  },
  exmample: {},
  exampleHeader: {
    padding: theme.spacing(1),
    height: theme.spacing(6),
  },
  exampleContent: {
    padding: theme.spacing(1),
    height: '38.25rem',
    overflow: 'scroll',
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = React.useState("main");
  const { checkPermission } = useHasRole();
  const canReadTenantCalendar = checkPermission("TENANT_CALENDAR_READ");
  const isReportViewer = checkPermission("REPORT_VIEWER");
  const onTabChange = (event, index) => {
    setTabIndex(index);
  }

  return (
    <>
      <Hidden xsDown>
        <Tabs variant={"fullWidth"}
              textColor={"primary"}
              indicatorColor={"primary"}
              onChange={onTabChange}
              value={tabIndex}>
          <Tab label={"Main Dashboard"} value={"main"} icon={<DashboardTwoTone />} />
          {canReadTenantCalendar &&
            <Tab label={"Calendar"} value={"calendar"} icon={<DateRangeIcon/>}/>
          }
          {isReportViewer &&
            <Tab label={"Reports"} value={"reports"} icon={<PictureAsPdfIcon/>}/>
          }
        </Tabs>
        {tabIndex === "main" &&
        <Grid container className={classes.root}>
          <Grid item xs={12} md={4} className={classes.dashboardItem}>
            <ESchedule defaultView="day" showList={true} forceMaxHeight={true}/>
          </Grid>
          <Grid item xs={12} md={5} className={classes.dashboardItem}>
            <Paper elevation={24}>
              <ResidentList minView={true} forceMaxHeight={true}/>
            </Paper>
          </Grid>
          <Grid item xs={12} md={3} className={classes.dashboardItem}>
            <ResidentNotes/>
          </Grid>
        </Grid>}
        {canReadTenantCalendar && tabIndex === "calendar" &&
        <Grid container className={classes.root}>
          <Grid item xs={12}>
            <TenantCalendarPageWrapper />
          </Grid>
        </Grid>
        }
        {tabIndex === "reports" &&
          <Reports viewOnly={true} />
        }
      </Hidden>
      <Hidden smUp>
        <MobileDashboard/>
      </Hidden>
    </>
  );
};

export default Dashboard;
