import React from 'react';
import {AppBar, Dialog, DialogContent, IconButton, Toolbar, Typography} from "@material-ui/core";
import Transition from "../dialog/transition";
import CloseIcon from "@material-ui/icons/Close";
import MedicationSearchComponent from "./medication.search.component";

export const MedicationSearchModal = ({open, onClose, onSelect}) => {

  return (
    <Dialog open={open}
            fullScreen
            TransitionComponent={Transition}
            onClose={onClose}>
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">
            Search Medications
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent style={{marginTop: '6vh'}}>
        <MedicationSearchComponent
          onSelectMedication={onSelect}
        />
      </DialogContent>
    </Dialog>
  )

}
