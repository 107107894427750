import React from 'react';
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent, FormControl, FormGroup,
  IconButton, InputLabel,
  makeStyles, MenuItem, Paper, Select, TextField,
  Toolbar,
  Typography
} from "@material-ui/core";
import Transition from "../dialog/transition";
import CloseIcon from "@material-ui/icons/Close";
import ResidentSearch from "../resident/search/resident.search";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import {getCategories} from "../../services/note.category.service";
import {UserContext} from "../../context/usercontext";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import {createNote} from "../../services/resident.service";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment/moment";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    marginTop: theme.spacing(10),
  },
}));


const CreateNoteDialog = ({open, onClose, onSave}) => {
  const classes = useStyles();
  const [resident, setResident] = React.useState();
  const [subject, setSubject] = React.useState();
  const [note, setNote] = React.useState();
  const [noteDate, setNoteDate] = React.useState();
  const [category, setCategory] = React.useState();
  const [categories, setCategories] = React.useState();
  const { selectedTenant } = React.useContext(UserContext);

  React.useEffect(() => {
    const getData = async () => {
      const response = await getCategories(selectedTenant?._id);
      setCategories(response);
    }

    if (open) {
      if (!categories || categories.length === 0) {
        getData();
      }
      const html = '<span> </span>';
      const contentBlock = htmlToDraft(html);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      setEditorState(EditorState.createWithContent(contentState));
      setNoteDate(moment());
      setSubject(null);
      setCategory(null);
      setResident(null);
    }
    // eslint-disable-next-line
  }, [open]);

  const [editorState, setEditorState] = React.useState(
      () => EditorState.createEmpty()
  );

  const onCategoryChange = e => {
    setCategory(e.target.value);
  }

  const onChange = e => {
    setEditorState(e);
    const message = convertToRaw(e.getCurrentContent());
    setNote(message);
  }

  const onResidentSelected = res => {
    setResident(res);
  }

  const save = async () => {
    const payload = {
      noteDate,
      subject,
      category,
      note,
      text: editorState?.getCurrentContent()?.getPlainText(),
      html: draftToHtml(note)
    }
    const response = await createNote(resident?._id, payload);
    onSave(response);
  }

  const isValid = () => {
    return category && subject && subject.length > 0 && editorState && editorState.getCurrentContent() &&
        editorState.getCurrentContent().hasText() && moment(noteDate).isValid();
  }

  const handelNoteDateChange = date => {
    setNoteDate(date);
  }

  return (
      <Dialog
          fullScreen
          open={open}
          onClose={() => onClose()}
          TransitionComponent={Transition}
      >
        <AppBar>
          <Toolbar>
            <IconButton
                edge="start"
                color="inherit"
                onClick={() => onClose()}
                aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Create Resident Note
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.dialogContent}>
          <ResidentSearch onSelect={onResidentSelected} />
          <div hidden={!resident}>
            <Paper elevation={24} style={{padding: '1rem'}}>
              <form novalidate>
                <FormGroup>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDateTimePicker label={"Date"}
                                            required={true}
                                            value={noteDate}
                                            onChange={handelNoteDateChange}
                                            format={"MM/DD/YYYY hh:mm a"}
                    />
                  </MuiPickersUtilsProvider>
                  <FormControl>
                    <InputLabel required shrink>Category</InputLabel>
                    <Select fullwidth value={category} onChange={onCategoryChange} displayEmpty fullWidth>
                      <MenuItem>Select a category</MenuItem>
                      {categories?.map(category => <MenuItem value={category.name}>{category.name}</MenuItem>)}
                    </Select>
                  </FormControl>
                  <TextField required fullWidth
                             label="Subject"
                             value={subject} onChange={(e) => setSubject(e.target.value)}  />
                </FormGroup>
              </form>
            </Paper>
            <Paper elevation={24} style={{ marginTop: '.5rem', padding: '0 1rem', minHeight: '25rem'}}>
              <Editor editorState={editorState}
                      onEditorStateChange={text => onChange(text)}
                      placeholder={"Enter Note Here"}
                      hashtag={{
                        separator: ' ',
                        trigger: '#',
                      }}
              />
            </Paper>
          </div>
        </DialogContent>
        <DialogActions>
          <DialogActions>
            <Button color="primar" onClick={onClose}>Close</Button>
            <Button color="primary" variant="contained" onClick={save} disabled={!isValid()}>Save</Button>
          </DialogActions>
        </DialogActions>
      </Dialog>
  )
}

export default CreateNoteDialog;
