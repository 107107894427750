import React from 'react';
import { DropzoneDialog } from 'material-ui-dropzone';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider, Grid, Hidden,
  Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {
  AttachFile,
  Attachment,
  DeleteForever,
  GetAppTwoTone,
  AttachFileTwoTone
} from "@material-ui/icons";
import {deleteNoteFile, getNoteFile, uploadNoteFile} from "../../../services/resident.service";
import FileViewer from 'react-file-viewer';
import filesize from 'filesize';
import {grey, red} from "@material-ui/core/colors";
import useGlobalNotification from "../../../hooks/notification.hook";
import useHasRole from "../../../hooks/user.role.hook";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  fileList: {
    flexWrap: 'wrap',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: 2,
    overflowWrap: 'break-word'
  },
  btnContainer: {
    width: '8rem',
  },
  file: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0, 1),
    '&:hover': {
      cursor: 'pointer'
    }
  },
  fileText: {
    wordBreak: 'break-all'
  },
  dropZone: {
    width: '100%',

  },
  header: {
    padding: theme.spacing(1),
    background: grey[300]
  },
  headerFlex: {
    display: 'flex',
    padding: theme.spacing(1),
    justifyContent: 'space-between',
    background: grey[300]
  },
  noPreview: {
    height: '20rem',
    textAlign: 'center',
    paddingTop: '5rem'
  },
  deleteBtn: {
    background: red[300],
    color: '#fff',
    '&:hover': {
      background: red[500]
    }
  }
}))

const FileAttachment = ({note, file, deleteFile}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [base64, setBase64] = React.useState();
  const [textContent, setTextContent] = React.useState();
  const { checkPermission } = useHasRole();
  const canDeleteFile = checkPermission("RESIDENT_NOTE_ATTACHMENT_DELETE");
  const canDownloadFile = checkPermission("RESIDENT_NOTE_ATTACHMENT_DOWNLOAD");

  React.useEffect(() => {
    if (open) {
      loadFile();
    }
    // eslint-disable-next-line
  }, [open]);

  const loadFile = async () => {
    try {
      let reader;
      if (isImage() || isPdf() || isWord() || isExcel() || isCsv()) {
        const result = await getNoteFile(note?.resident?._id, note?._id, file?._id);
        const blob = new Blob([result]);
        reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function() {
          var base64data = reader.result;
          setBase64(base64data);
        }
        // setFileBlob(blob);
      }  else if(isText()) {
        const result = await getNoteFile(note?.resident?._id, note?._id, file?._id);
        const text = await result.text();
        setTextContent(text);
      } else {
        setBase64(null);
        setTextContent(null);
      }
    } catch (error) {
      alert(error);
    }
  }

  const downloadFile = () => {
      setOpen(true);
  }

  const isImage = () => {
    return file?.type?.indexOf('image/') > -1;
  }

  const isPdf = () => {
    return file?.type?.indexOf('pdf') > -1;
  }

  const isWord = () => {
    return file?.type?.indexOf('application/vnd.openxmlformats-officedocument.wordprocessingml.document') > -1;
  }

  const isExcel = () => {
    return file?.type?.indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') > -1;
  }

  const isCsv = () => {
    return file?.type?.indexOf('text/csv') > -1;
  }

  const isText = () => {
    return file?.type?.indexOf('text/plain') > -1;
  }

  const onError = err => {
    alert(err);
  }

  const download = async () => {
    const blob = await getNoteFile(note?.resident?._id, note?._id, file?._id);
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      file.fileName,
    );
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }

  const onDelete = () => {
    deleteFile(file);
    setOpen(false);
  }

  return (
    <div>
      <div className={classes.file} onClick={downloadFile}>
        <Attachment  style={{fontSize: '1rem', marginRight: '.25rem'}} />
        <Typography variant={"subtitle2"} className={classes.fileText}>{file.fileName}</Typography>
      </div>
    <Dialog open={open} fullWidth={true} maxWidth={"lg"} onClose={() => setOpen(false)}>
      <Hidden smDown>
        <div className={classes.headerFlex}>
          <Typography variant={"subtitle2"}>{`Attachment: ${file.fileName}`}</Typography>
          <Typography variant={"subtitle2"}>{`Type: ${file.type}`}</Typography>
          <Typography variant={"subtitle2"}>{`Size: ${filesize(file.size)}`}</Typography>
        </div>
      </Hidden>
      <Hidden mdUp>
        <div className={classes.header}>
          <Typography variant={"subtitle2"}>{`Attachment: ${file.fileName}`}</Typography>
          <Typography variant={"subtitle2"}>{`Type: ${file.type}`}</Typography>
          <Typography variant={"subtitle2"}>{`Size: ${filesize(file.size)}`}</Typography>
        </div>
      </Hidden>
      <Divider />
      <DialogContent style={{ height: '30rem' }}>
        {isImage() && base64 &&
        <FileViewer fileType={file?.type?.replace('image/', '')}
                    filePath={base64 || ''}
                    onError={onError} />
        }

        {isPdf() && base64 &&
            <FileViewer fileType="pdf"
                        filePath={base64 || ''}
                        onError={onError} />
        }
        {isWord() && base64 && <div>
          <FileViewer
            fileType={"docx"}
            filePath={base64 || ''}
            onError={onError}/>
        </div>}
        {isExcel() && base64 && <div>
          <FileViewer
            fileType="xlsx"
            filePath={base64 || ''}
            onError={onError}/>
        </div>}
        {isCsv() && base64 && <div>
          <FileViewer
            fileType="csv"
            filePath={base64 || ''}
            onError={onError}/>
        </div>}
        {isText() && textContent && <div>
          <pre>{textContent}</pre>
        </div>}
        {!textContent && !base64 &&
        <div className={classes.noPreview}>
          <AttachFileTwoTone style={{fontSize: '5rem'}}/>
          <Typography variant={"subtitle2"}>No preview available.</Typography>
        </div>}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid container justify={"space-between"}>
          <Grid item>
            {canDeleteFile &&
              <Button className={classes.deleteBtn}
                      size={"small"}
                      variant={"contained"}
                      onClick={onDelete}
                      startIcon={<DeleteForever/>}>Delete</Button>
            }
          </Grid>
          <Grid item>
            <Button size={"small"}
                    color={"primary"}
                    onClick={() => setOpen(false)}>Close</Button>
            {canDownloadFile &&
              <Button size={"small"}
                      variant={"contained"}
                      color={"primary"}
                      onClick={download}
                      startIcon={<GetAppTwoTone/>}>Download</Button>
            }
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
    </div>
  )
}

const NoteFiles = ({note}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const { addNotification } = useGlobalNotification();
  const { checkPermission } = useHasRole();
  const canCreateFile = checkPermission("RESIDENT_NOTE_ATTACHMENT_CREATE");

  React.useEffect(() => {
    setFiles(note?.files);
  }, [note]);

  const saveFiles = async (payload) => {
    const file = await uploadNoteFile(note?.resident?._id, note?._id, payload);
    setFiles([...files, ...file])
    setOpen(false);
  }

  const deleteFile = async file => {
    const result = await deleteNoteFile(note?.resident?._id, note?._id, file._id);
    if (result) {
      setFiles(files.filter(f => f._id !== file._id));
      addNotification("File deleted", "warning");
    } else {
      addNotification("Unable to delete file", "error");
    }
  }

  return (
    <div className={classes.root}>
      <div style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}>
        <div className={classes.fileList}>
          {files?.map(file => <FileAttachment note={note} file={file} deleteFile={deleteFile} />)}
        </div>
        {canCreateFile &&
          <Button size={"small"} className={classes.btnContainer} onClick={() => setOpen(true)}>
          <AttachFile  style={{fontSize: '1rem'}} /> <Typography variant={"subtitle2"}>Attach a file</Typography>
        </Button>}
      </div>
      <DropzoneDialog open={open} onClose={() => setOpen(false)} onSave={saveFiles} />
    </div>
  )
}

export default NoteFiles;

