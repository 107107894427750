import React from 'react';
import {
  NoSsr,
  Grid,
  Typography,
  FormGroup,
  makeStyles,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  DialogContent,
  Button,
  FormControlLabel,
  Switch,
  Chip,
  FormLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  AccordionActions,
  FormControl,
  DialogActions,
  ButtonGroup, InputLabel, Select, Input, MenuItem, Checkbox, ListItemText, CircularProgress, LinearProgress,
} from '@material-ui/core';
import styled from 'styled-components';
import moment from 'moment';
import EventTemplate from './event.template.js';
import ResidentSearchDialogButton from '../../resident/search/resident.search';
import Transition from '../../dialog/transition';
import MedicationSearchComponent from '../../medications/medication.search.component';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import PharmacyLookup from '../../pharmacies/pharmacy.lookup';
import PhysicianLookup from '../../physicians/physician.lookup';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { createMedicationOrder } from '../../../services/medication.order.service';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { orderModelTemplate, validateForm } from './order.model';
import { getFrequencies } from '../../../services/frequency.service';
import AllergyLookup from '../../allergy/allergy.lookup';
import {ObjectId} from "bson";
import AddDosageInstructionModal from "./add.dosage.instruction.modal";
import EditDosageInstructionModal from "./edit.dosage.instruction.modal";
import SaveIcon from "@material-ui/icons/Save";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const vitals = [
  'All',
  'Blood glucose',
  'Blood pressure',
  'INR',
  'Oxygen',
  'Pain',
  'Pulse',
  'Respiratory',
  'Temperature',
  'Weight'
]

const BoldStyledTextField = styled(TextField)`
  label.Mui-focused {
    color: green;
  }
  .MuiInput-input {
      font-weight: 700;
    &.Mui-disabled {
      color: #000000;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-size: 16px;
      font-weight: 700;
    }
  }
`;

const StyledTextField = styled(TextField)`
  label.Mui-focused {
    color: green;
  }
  .MuiInput-input {
    &.Mui-disabled {
      color: #000000;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-size: 14px;
      font-weight: 400;
    }
  }
`;
const styles = makeStyles((theme) => ({
  pre: {
    width: '100%',
    height: '20rem',
    marginTop: '0',
    marginBottom: '1rem',
    overflow: 'auto',
    display: 'block',
    fontSize: '87.5%',
    color: '#212529',
    wordBreak: 'normal',
  },
  root: {
    width: '100%',
    padding: theme.spacing(1),
    marginTop: '5rem',
  },
  dialogContent: {
    padding: theme.spacing(10, 1),
  },
  paper: {
    padding: theme.spacing(2, 3),
    margin: '0 auto',
  },
  listSubHeader: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  listItem: {
    padding: theme.spacing(0),
    margin: theme.spacing(0, 2),
  },
  total: {
    fontWeight: '700',
  },
  title: {
    marginTop: theme.spacing(2),
  },
  passingTimesList: {
    marginLeft: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0),
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(4),
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(4),
    },
  },
  panelSummary: {
    minHeight: '32px',
    padding: '12px 0 0 24px',
  },
  panelDetails: {
    width: '100%',
    overflowX: 'hidden'
  },
  form: {
    width: '100%',
  },
  textField: {
    '&:disabled': {
      color: 'green',
    },
  },
  toggleShowBtn: {
    position: 'absolute',
    top: '80px',
    left: '5px',
  },
  searchIconContainer: {
    display: 'flex',
  },
  searchIcon: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  addFrequencyContainer: {
    display: 'flex',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  appBar: {},
  expandedPanel: {
    minHeight: '7rem',
  },
  expandedPanelExtended: {
    minHeight: '20rem',
  },
  btnContainer: {
    paddingTop: '1rem',
  },
  switch: {
    marginTop: '.5rem',
    marginLeft: '0',
    marginRight: '1rem',
    flexDirection: 'row',
  },
  allergyContainer: {
    display: 'flex',
  },
  errorText: {
    color: '#f44336',
    margin: '0',
    fontSize: '0.75rem',
    marginTop: '8px',
    minHeight: '1em',
    textAlign: 'left',
    fontFamily: 'Roboto Helvetica Arial sans-serif',
    fontWeight: '400',
    lineHeight: '1em',
    letterSpacing: '0.03333em',
  },
  contentContainer: {
    marginTop: theme.spacing(8),
  },
  deleteBtn: {
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      background: theme.palette.error.dark,
    }
  },
  deleteBtnIcon: {
    color: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.dark,
    }
  }
}));

function CreateMedicationOrder({ open, resident, onClose, onSave, prn }) {
  const classes = styles();
  const [orderModel, setOrderModel] = React.useState(
    JSON.parse(JSON.stringify(orderModelTemplate))
  );
  const [openResidentModel, setOpenResidentModel] = React.useState(false);
  const [openMedicationSearch, setOpenMedicationSearch] = React.useState(false);
  const [openPharmacySearch, setOpenPharmacySearch] = React.useState(false);
  const [openPhysicianSearch, setOpenPhysicianSearch] = React.useState(false);
  const [openFrequencyModal, setOpenFrequencyModel] = React.useState(false);
  const [frequencies, setFrequencies] = React.useState([]);
  const [openAllergy, setOpenAllergy] = React.useState(false);
  const [openEditDosageInstructionModal, setOpenEditDosageInstructionModal] = React.useState();
  const [editDosageInstructionModel, setEditDosageInstructionModel] = React.useState()
  const [loading, setloading] = React.useState(false);

  React.useEffect(() => {
    const obj = { ...orderModel };
    obj.isPrn.value = prn;
    setOrderModel(obj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prn]);

  React.useEffect(() => {
    const getAllFrequencies = async () => {
      try {
        const frequencies = await getFrequencies();
        setFrequencies(frequencies);
      } catch (err) {
        alert('Error getting frequencies');
      }
    };

    if (!frequencies || frequencies.length === 0) {
      getAllFrequencies();
    }

    const order = JSON.parse(JSON.stringify(orderModelTemplate));
    order.resident.value = resident;
    if (open && resident && resident.residentPharmacies) {
      const primaryResidentPharmacy = resident.residentPharmacies.find(
        (pharmacy) => pharmacy.isPrimary
      );
      if (primaryResidentPharmacy) {
        // alert(JSON.stringify(primaryResidentPharmacy, null, 2))
        order.pharmacy.value = primaryResidentPharmacy.pharmacy;
        order.pharmacy.isValid = true;
      }
    }
    if (resident && resident.residentPhysicians) {
      const residentPhysician = resident.residentPhysicians.find(
        (physician) => physician.isPrimary
      );
      if (residentPhysician) {
        order.physician.value = residentPhysician.physician;
        order.physician.isValid = true;
      }
    }
    order.resident.value = resident;
    order.resident.isValid = true;

    if (prn) {
      order.isPrn.value = prn;
    }

    if (frequencies) {
      const freq = frequencies.find((f) => f.name === 'QD');
      if (freq) {
        const d = new Date();
        const initStartDate = moment(d.setHours(8, 0, 0, 0));
        const hour = moment(initStartDate).format('H');
        const minute = moment(initStartDate).format('mm');
        const eTemps = [{ id: 1, passingQty: 1, hour: hour, minute: minute }];
        const endDate = moment(initStartDate).add(1, 'YEAR');

        order.dosageInstructions.value = [
          {
            _id: new ObjectId().toHexString(),
            startDate: initStartDate,
            endDate: endDate,
            frequency: freq,
            eventTemplates: eTemps,
          },
        ];
      }
    }
    setOrderModel(order);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, resident, frequencies]);

  const residentName =
    orderModel && orderModel.resident.value
      ? `${orderModel.resident.value.firstName} ${orderModel.resident.value.lastName}`
      : null;

  const physicianName =
    orderModel && orderModel.physician.value
      ? `${orderModel.physician.value.firstName} ${orderModel.physician.value.lastName}`
      : null;

  const onResidentSelected = (resident) => {
    setOpenResidentModel(false);
    const order = { ...orderModel };
    order.resident.value = resident;
    order.resident.isValid = true;
    setOrderModel(order);
  };

  const searchResident = () => {
    setOpenResidentModel(true);
  };

  const selectMedication = (medication) => {
    setOpenMedicationSearch(false);
    const order = { ...orderModel };
    order.medication.value = medication;
    order.medication.isValid = true;
    setOrderModel(order);
  };

  const searchMedication = () => {
    orderModel.medication.isDirty = true;
    setOpenMedicationSearch(true);
  };

  const onPhysicianSelect = (physician) => {
    const order = { ...orderModel };
    order.physician.value = physician;
    order.physician.isValid = true;
    setOrderModel(order);
    setOpenPhysicianSearch(false);
  };

  const searchPhysician = () => {
    setOpenPhysicianSearch(true);
  };

  const onPharmacySelect = (pharmacy) => {
    const order = { ...orderModel };
    order.pharmacy.value = pharmacy;
    order.pharmacy.isValid = true;
    setOrderModel(order);
    setOpenPharmacySearch(false);
  };

  const searchPharmacy = () => {
    setOpenPharmacySearch(true);
  };

  const handleOrderDate = (date) => {
    const updatedForm = { ...orderModel };
    updatedForm.orderDate.value = date.utc().valueOf();
    updatedForm.orderDate.isValid = true;
    setOrderModel(updatedForm);
  };



  const handleChange = (e) => {
    const updatedForm = { ...orderModel };
    let value = e.target.value;
    if (
      e.target.name === 'maintainInventory' ||
      e.target.name === 'isPrn' ||
      e.target.name === 'chartVitals' ||
      e.target.name === 'marEnabled'
    ) {
      value = e.target.checked;
    }
    updatedForm[e.target.name].value = value;
    updatedForm[e.target.name].isDirty = true;
    if (
      (updatedForm[e.target.name].required &&
        updatedForm[e.target.name].value) ||
      !updatedForm[e.target.name].required
    ) {
      updatedForm[e.target.name].isValid = true;
    } else {
      updatedForm[e.target.name].isValid = false;
    }
    setOrderModel(updatedForm);
  };

  const onAddDosageInstruction = dosageInstruction => {
    const obj = { ...orderModel };
    if (!obj.dosageInstructions.value) {
      obj.dosageInstructions.value = [];
    }
    obj.dosageInstructions.value = [
      ...obj.dosageInstructions.value,
      dosageInstruction,
    ];
    setOrderModel(obj);
    setOpenFrequencyModel(false);
  }

  const removeDosageInstruction = (dosageInstruction) => {
    const obj = { ...orderModel };
    if (obj.dosageInstructions.value) {
      obj.dosageInstructions.value = obj.dosageInstructions.value.filter(
        (di) => di._id !== dosageInstruction._id
      );
    }
    setOrderModel(obj);
  };



  const editDosageInstruction = (dosageInstruction) => {
    setEditDosageInstructionModel(dosageInstruction);
    setOpenEditDosageInstructionModal(true);
  };

  const onEditDosageInstructionSave = dosageInstruction => {
    const obj = {...orderModel};
    obj.dosageInstructions.value = obj?.dosageInstructions?.value?.map(di => di?._id === dosageInstruction?._id ? dosageInstruction: di);
    setOpenEditDosageInstructionModal(false);
    setOrderModel(obj);
  }

  const saveOrder = async () => {
    setloading(true);
    const payload = {
      resident: orderModel.resident.value._id,
      // resident: orderModel.resident.value,
      allergies: orderModel.allergies.value,
      alerts: orderModel.alerts.value,
      // medication: orderModel.medication.value,
      direction: orderModel.direction.value,
      indication: orderModel.indication.value,
      medication: orderModel.medication.value._id,
      pharmacy: orderModel.pharmacy.value._id,
      physician: orderModel.physician.value._id,
      orderDate: moment(orderModel.orderDate.value).format('YYYY-MM-DDT00:00:00'),
      isPrn: orderModel.isPrn.value,
      chartVitals: orderModel.chartVitals.value,
      chartVitalsList: orderModel?.chartVitalsList?.value,
      controlledSubstanceId: orderModel.controlledSubstanceId,
      isQtyEditable: false,
      rxNumber: orderModel.rxNumber.value,
      isControlled: false,
      isMaintainInventory: orderModel.maintainInventory.value,
      refillNotificationThreshold: orderModel.refillNotificationThreshold.value,
      maxRefills: orderModel.maxRefills.value,
      marEnabled: orderModel.marEnabled.value,
      orderQty: orderModel.orderQty.value,
      dosageInstructions: orderModel.dosageInstructions.value
    };

    const result = await createMedicationOrder(payload);
    setloading(false);
    onSave(result);
  };

  const cancelOrder = () => {
    onClose();
  };

  const searchAllergy = () => {
    setOpenAllergy(true);
  };

  const onAllergySelection = (allergies) => {
    const obj = { ...orderModel };
    if (!obj.allergies.value) {
      obj.allergies.value = [];
    }
    const items = [...new Set([...allergies])];
    obj.allergies.value = items;
    setOrderModel(obj);
    setOpenAllergy(false);
  };

  const onAllergyClose = () => {
    setOpenAllergy(false);
  };

  const deleteAllergy = (e) => {
    const obj = { ...orderModel };
    obj.allergies.value = obj.allergies.value.filter(allergy => allergy.id !== e.id);
    setOrderModel(obj);
  };

  const orderIsValid = () => {
    return validateForm(orderModel);
  };

  const removeMedication = () => {
    const obj = { ...orderModel };
    obj.medication.value = null;
    obj.medication.isValid = false;
    obj.medication.isDirty = true;
    setOrderModel(obj);
  };

  const removePhysician = () => {
    const obj = { ...orderModel };
    obj.physician.value = null;
    obj.physician.isValid = false;
    obj.physician.isDirty = true;
    setOrderModel(obj);
  };

  const removePharmacy = () => {
    const obj = { ...orderModel };
    obj.pharmacy.value = null;
    obj.pharmacy.isValid = false;
    obj.pharmacy.isDirty = true;
    setOrderModel(obj);
  };

  const onChartVitalsListChange = e => {
    const obj = {...orderModel};
    const value = e.target.value;
    if (value[value.length - 1] === "All") {
      obj.chartVitalsList.value = obj?.chartVitalsList?.value?.length === vitals?.length ? []: vitals;
    } else {
      obj.chartVitalsList.value = value;
    }
    setOrderModel(obj);
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => onClose()}
      TransitionComponent={Transition}
    >
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => onClose()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">
            Create Medication Order
          </Typography>
        </Toolbar>
        {loading && <LinearProgress variant={"indeterminate"} />}
      </AppBar>
      <DialogContent className={classes.dialogContent}
                     dividers
      >
        <NoSsr>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="space-between"
          >
            <Grid item xs={12} sm={4}>
              <Accordion elevation={24}
                         expanded="true">
                <AccordionSummary>
                  <Typography>Order Details</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.panelDetails}>
                  <form
                    noValidate
                    autoComplete="false"
                  >
                      <ResidentSearchDialogButton
                        open={openResidentModel}
                        onResidentSelected={onResidentSelected}
                      />
                    <FormGroup>
                      <StyledTextField
                        disabled
                        fullWidth
                        size={"small"}
                        id="resident-name"
                        label="Resident Name"
                        value={residentName || ''}
                        onClick={searchResident}
                        required={orderModel.resident.required}
                        helperText={
                          orderModel.resident.value === ''
                            ? 'Resident is required!'
                            : ''
                        }
                        error={
                          !orderModel.resident.isValid &&
                          orderModel.resident.isDirty
                        }
                      />
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          animateYearScrolling
                          margin="normal"
                          size={"small"}
                          name="orderDate"
                          id="order-date"
                          label="Order Date"
                          format="MM/DD/YYYY"
                          value={orderModel.orderDate.value}
                          onChange={handleOrderDate}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                    </MuiPickersUtilsProvider>
                      <StyledTextField
                        name="rxNumber"
                        fullWidth
                        id="rx-number"
                        label="RX Number"
                        value={orderModel.rxNumber.value || ''}
                        size={"small"}
                        onChange={handleChange}
                        required={orderModel.rxNumber.required}
                        helperText={
                          orderModel.rxNumber.value === ''
                            ? 'RxNumber is required!'
                            : ''
                        }
                        error={
                          !orderModel.rxNumber.isValid &&
                          orderModel.rxNumber.isDirty
                        }
                      />
                      <StyledTextField
                        name="indication"
                        fullWidth
                        id="indication"
                        label="Indication"
                        value={orderModel.indication.value || ''}
                        size={"small"}
                        onChange={handleChange}
                      />
                      <StyledTextField
                        name="direction"
                        fullWidth
                        id="direction"
                        label="Direction"
                        value={orderModel.direction.value || ''}
                        size={"small"}
                        onChange={handleChange}
                      />
                      <StyledTextField
                        name="pillDescription"
                        fullWidth
                        id="pill-description"
                        label="Pill Description"
                        value={orderModel.pillDescription.value || ''}
                        size={"small"}
                        onChange={handleChange}
                      />
                      <StyledTextField
                        name="alerts"
                        fullWidth
                        id="alerts"
                        label="Alerts"
                        value={orderModel.alerts.value || ''}
                        size={"small"}
                        onChange={handleChange}
                      />
                    <FormControl style={{marginTop: '.75rem'}}>
                      <FormLabel>Allergies <AddIcon onClick={searchAllergy} /></FormLabel>
                      <FormControlLabel
                        control={
                          <div
                            style={{ marginLeft: '.4rem', paddingTop: '.1rem' }}
                            className={classes.allergyContainer}
                          >
                            {
                              orderModel?.allergies?.value?.map((allergy) => {
                                return (
                                  <Chip
                                    label={allergy.name}
                                    onDelete={() => deleteAllergy(allergy)}
                                  />
                                );
                              })}
                          </div>
                        }
                      ></FormControlLabel>
                    </FormControl>
                      <StyledTextField
                        name="orderQty"
                        fullWidth
                        id="order-qty"
                        label="Order Quantity"
                        value={orderModel.orderQty.value || ''}
                        margin="dense"
                        onChange={handleChange}
                      />
                      <StyledTextField
                        name="maxRefills"
                        fullWidth
                        id="max-refills"
                        label="Max Refills"
                        value={orderModel.maxRefills.value || ''}
                        margin="dense"
                        onChange={handleChange}
                      />
                      <StyledTextField
                        name="refillNotificationThreshold"
                        fullWidth
                        id="refill-notification-threshold"
                        label="Refill Notification Threshold"
                        value={
                          orderModel.refillNotificationThreshold.value || ''
                        }
                        margin="dense"
                        onChange={handleChange}
                      />
                    </FormGroup>
                      <FormControlLabel
                        className={classes.switch}
                        value={orderModel.maintainInventory.value || ''}
                        control={
                          <Switch
                            color="primary"
                            name="maintainInventory"
                            checked={orderModel.maintainInventory.value || ''}
                            value={orderModel.maintainInventory.value || ''}
                            onChange={handleChange}
                          />
                        }
                        label="Maintain Inventory"
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        className={classes.switch}
                        value={orderModel.isPrn.value || ''}
                        control={
                          <Switch
                            color="primary"
                            name="isPrn"
                            checked={orderModel.isPrn.value || ''}
                            value={orderModel.isPrn.value || ''}
                            onChange={handleChange}
                          />
                        }
                        label="Is PRN"
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        className={classes.switch}
                        value={orderModel.chartVitals.value || ''}
                        control={
                          <Switch
                            color="primary"
                            name="chartVitals"
                            checked={orderModel.chartVitals.value || ''}
                            value={orderModel.chartVitals.value || ''}
                            onChange={handleChange}
                          />
                        }
                        label="Chart on Vitals"
                        labelPlacement="start"
                      />

                    {orderModel?.chartVitals?.value &&
                      <FormControl fullWidth>
                        <InputLabel shrink
                                    required>Vitals</InputLabel>
                        <Select fullWidth
                                multiple
                                value={orderModel?.chartVitalsList?.value}
                                input={<Input />}
                                onChange={onChartVitalsListChange}
                                renderValue={selected => selected?.join(', ')}
                                MenuProps={MenuProps}
                        >
                          {vitals?.map(vital =>
                            <MenuItem value={vital} key={vital}>
                              <Checkbox checked={orderModel?.chartVitalsList?.value?.indexOf(vital) > -1} />
                              <ListItemText primary={vital} />
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    }
                      <FormControlLabel
                        className={classes.switch}
                        value={orderModel.marEnabled.value || ''}
                        control={
                          <Switch
                            color="primary"
                            name="marEnabled"
                            checked={orderModel.marEnabled.value || ''}
                            value={orderModel.marEnabled.value || ''}
                            onChange={handleChange}
                          />
                        }
                        label="eMar Enabled"
                        labelPlacement="start"
                      />
                  </form>

                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12} sm={8} style={{ padding: '0 0 0 1rem' }}>
              <Accordion expanded={true}
                         elevation={24}
              >
                <AccordionSummary>
                  <Button startIcon={<SearchIcon />}
                          onClick={searchMedication}>
                    <FormLabel required>Medication</FormLabel>
                  </Button>
                </AccordionSummary>
                <AccordionDetails>
                  {!orderModel.medication.isValid &&
                  orderModel.medication.isDirty &&
                  <Typography variant={"subtitle2"}
                              className={classes.errorText}>
                    Medication is required
                  </Typography>
                  }
                  {orderModel?.medication?.value &&
                      <Grid container>
                        <BoldStyledTextField
                          disabled
                          fullWidth
                          id="medication-name"
                          label="Name"
                          value={orderModel?.medication?.value?.tradeName || ''}
                          margin="dense"
                          size={"small"}
                        />
                        <Grid item xs={6}>
                          <Grid container>
                            <Grid item xs={6}>
                              <StyledTextField
                                disabled
                                fullWidth
                                id="unit"
                                label="Unit"
                                value={orderModel?.medication?.value?.unit?.name || '' }
                                margin="dense"
                                size={"small"}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <StyledTextField
                                disabled
                                fullWidth
                                id="strength"
                                label="Strength"
                                value={orderModel.medication.value.strength || ''}
                                margin="dense"
                                size={"small"}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          <Grid container>
                            <Grid item xs={6}>
                              <StyledTextField
                                disabled
                                fullWidth
                                inputProps={{disableUnderline: true}}
                                id="dosage-form"
                                label="Dosage Form"
                                value={ orderModel?.medication?.value?.dosageForm?.name || ''  }
                                margin="dense"
                                size={"small"}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <StyledTextField
                                disabled
                                fullWidth
                                id="route"
                                label="Route"
                                value={ orderModel?.medication?.value?.route?.name || '' }
                                margin="dense"
                                size={"small"}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                  }
                </AccordionDetails>
                {orderModel?.medication?.value?._id &&
                <AccordionActions>
                  <Button startIcon={<DeleteIcon />}
                          className={classes.deleteBtn}
                          variant={"contained"}
                          onClick={() => removeMedication()}
                  >
                    Remove Medication
                  </Button>
                </AccordionActions>}
              </Accordion>

              <Accordion expanded={true}
                         elevation={24}>
                <AccordionSummary>
                  <Button startIcon={<SearchIcon />}
                          onClick={searchPhysician}>
                    <FormLabel required>Physician</FormLabel>
                  </Button>
                  <PhysicianLookup
                    open={openPhysicianSearch}
                    onSelect={onPhysicianSelect}
                    onClose={() => setOpenPhysicianSearch(false)}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  {!orderModel?.physician?.isValid &&
                  orderModel?.physician?.isDirty &&
                    <Typography variant={"subtitle2"}
                                className={classes.errorText}>
                      Physician is required
                    </Typography>
                  }
                  {orderModel?.physician?.value && (
                    <Grid container>
                      {!orderModel.physician.isValid &&
                      orderModel.physician.isDirty && (
                        <p className={classes.errorText}>
                          Physician is required
                        </p>
                      )}
                      {orderModel?.physician?.value && (
                        <Grid container
                              alignContent={"center"}
                        >
                          {orderModel?.physician?.value?._id &&
                          <IconButton onClick={removePhysician}
                                      className={classes.deleteBtnIcon}
                          >
                            <DeleteIcon />
                          </IconButton>}
                          <FormGroup style={{flex: 1}}>
                            <StyledTextField
                              disabled
                              fullWidth
                              id="pharmacy-name"
                              label="Pharmacy Name"
                              value={physicianName || ''}
                              margin="dense"
                            />
                          </FormGroup>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </AccordionDetails>
              </Accordion>
              <Accordion
                elevation={24}
                expanded={true}
              >
                <AccordionSummary>
                  <Button startIcon={<SearchIcon />}
                          onClick={searchPharmacy}>
                    <FormLabel required>Pharmacy</FormLabel>
                  </Button>
                  <PharmacyLookup
                    open={openPharmacySearch}
                    onSelect={onPharmacySelect}
                    onClose={() => setOpenPharmacySearch(false)}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  {!orderModel.pharmacy.isValid &&
                  orderModel.pharmacy.isDirty &&
                  <Typography variant={"subtitle2"}
                              className={classes.errorText}>
                    Pharmacy is required
                  </Typography>
                  }
                  {orderModel?.pharmacy?.value && (
                    <Grid container
                          alignContent={"center"}
                    >
                      {orderModel?.pharmacy?.value?._id &&
                      <IconButton onClick={removePharmacy}
                                  className={classes.deleteBtnIcon}
                      >
                        <DeleteIcon />
                      </IconButton>}
                      <FormGroup style={{flex: 1}}>
                        <StyledTextField
                          disabled
                          fullWidth
                          id="pharmacy-name"
                          label="Pharmacy Name"
                          value={orderModel.pharmacy.value.name || ''}
                          margin="dense"
                        />
                      </FormGroup>
                    </Grid>
                  )}
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={true}
                         elevation={24}
                         hidden={orderModel?.isPrn?.value}
              >
                <AccordionSummary>
                  <Grid container
                        justify={"flex-start"}
                        alignItems={"center"}
                  >
                    <Typography variant={"subtitle1"}>Passing Times</Typography>
                    <Button onClick={() => setOpenFrequencyModel(true)}
                            style={{marginLeft: '1rem'}}
                            startIcon={<AddIcon />}>Add New</Button>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>Frequency</TableCell>
                          <TableCell>Start Date</TableCell>
                          <TableCell>End Date</TableCell>
                          <TableCell>Times</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {orderModel &&
                        orderModel.dosageInstructions &&
                        orderModel.dosageInstructions.value.map(
                          (dosageInstruction) => {
                            return (
                              <TableRow>
                                <TableCell>
                                  <ButtonGroup size={"small"}>
                                    <Button startIcon={<DeleteIcon />}
                                            className={classes.deleteBtn}
                                            variant={"contained"}
                                            onClick={() => removeDosageInstruction(dosageInstruction)}
                                    >
                                      Delete
                                    </Button>
                                    <Button startIcon={<EditIcon />}
                                            color={"primary"}
                                            variant={"text"}
                                            onClick={() => editDosageInstruction(dosageInstruction)}

                                    >
                                      Edit
                                    </Button>
                                  </ButtonGroup>
                                </TableCell>
                                <TableCell>
                                  {dosageInstruction.frequency
                                    ? `${dosageInstruction.frequency.name} (${dosageInstruction.frequency.description})`
                                    : null}
                                </TableCell>
                                <TableCell>
                                  {moment(dosageInstruction?.startDate).format('MM/DD/YYYY')}
                                </TableCell>
                                <TableCell>
                                  {moment(dosageInstruction?.endDate).format('MM/DD/YYYY')}
                                </TableCell>
                                <TableCell>
                                  {dosageInstruction && (
                                    <EventTemplate
                                      frequency={dosageInstruction.frequency}
                                      eventTemplates={
                                        dosageInstruction.eventTemplates
                                      }
                                    />
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </NoSsr>

        <Dialog
          fullScreen
          open={openMedicationSearch}
          onClose={() => setOpenMedicationSearch(false)}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setOpenMedicationSearch(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6">
                Search Medications
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent className={classes.contentContainer}>
            <MedicationSearchComponent
              onSelectMedication={selectMedication}
            />
          </DialogContent>
        </Dialog>

        <EditDosageInstructionModal open={openEditDosageInstructionModal}
                                    onClose={() => setOpenEditDosageInstructionModal(false)}
                                    frequencies={frequencies}
                                    dosageInstruction={editDosageInstructionModel}
                                    onSave={onEditDosageInstructionSave}
        />

        <AddDosageInstructionModal open={openFrequencyModal}
                                   onClose={() => setOpenFrequencyModel(false)}
                                   frequencies={frequencies}
                                   onSave={onAddDosageInstruction}
        />


        <AllergyLookup
          open={openAllergy}
          onClose={onAllergyClose}
          onSelect={onAllergySelection}
          items={orderModel.allergies.value}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={cancelOrder}>Cancel</Button>
        <Button
          variant={"contained"}
          color="primary"
          onClick={saveOrder}
          disabled={loading || !orderIsValid()}
          startIcon={loading ? <CircularProgress size={20} />: <SaveIcon />}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateMedicationOrder;
