import React from 'react';
import {Button, Dialog, DialogActions, DialogContent} from "@material-ui/core";
import {SsaDialogTitle} from "../../../../utils/dialogs/SsaDialogTItle";
import {FormFieldsGenerator, isModelValid} from "../../../../../utils/forms";


const MODEL = {
    tags: {
        title: 'Tags',
            isTagsComponent: true,
            hidden: false,
            value: [],
            required: false,
    },
};

export const EditFolderDialog = ({folder, open, onSave, onClose}) => {
    const [model, setModel] = React.useState(JSON.parse(JSON.stringify(MODEL)));

    React.useEffect(() => {
        if (open && folder) {
            const obj = JSON.parse(JSON.stringify(MODEL));
            obj.tags.value = folder?.tags;
            setModel(obj);
        }

    }, [folder, open])

    const onChange = (e) => {
        setModel({...model, ...e});
    }

    const handleSave = () => {
        const data = {}
        Object.keys(model).forEach(key => {
            data[key] = model[key].value;
        });
        onSave(folder, data);
    }

    return (
        <Dialog open={open} maxWidth={"md"} fullWidth>
            <SsaDialogTitle onClose={onClose}>
                Edit folder metadata
            </SsaDialogTitle>
            <DialogContent dividers>
                <FormFieldsGenerator model={model} onChange={onChange} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button disabled={!isModelValid(model)}
                        variant={"contained"}
                        color={"primary"}
                        onClick={handleSave}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}
