import moment from 'moment';

export const FilterTemplate = {
  date: {
    field: 'date',
    minValue: 10,
    value: {
      start: moment().startOf('month').subtract(6, 'month'),
      end: moment().endOf('month')
    },
    operator: 'between',
  },
  type: {
    field: 'type',
    minValue: 1,
    value: null,
    operator: '==',
  },
  title: {
    field: 'title',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  description: {
    field: 'description',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  duration: {
    field: 'duration',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  attendies: {
    field: 'attendies',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  cashUsed: {
    field: 'cashUsed',
    minValue: 1,
    value: '',
    operator: '==',
  },
  cashAmount: {
    field: 'cashAmount',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  refusal: {
    field: 'refusal',
    minValue: 1,
    value: '',
    operator: '==',
  },
  reason: {
    field: 'reason',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  alternativeOffers: {
    field: 'alternativeOffers',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  hour: {
    field: 'hour',
    minValue: 3,
    value: null,
    operator: '==',
  },
  result: {
    field: 'result',
    minValue: 1,
    value: '',
    operator: '==',
  },
  comments: {
    field: 'comments',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  time: {
    field: 'time',
    minValue: 1,
    value: '',
    operator: '*=*',
  },
  pattern: {
    field: 'pattern',
    minValue: 1,
    value: '',
    operator: '==',
  },
  color: {
    field: 'color',
    minValue: 1,
    value: '',
    operator: '==',
  },
  consistency: {
    field: 'consistency',
    minValue: 1,
    value: '',
    operator: '*=*',
  },
  visitor: {
    field: 'visitor',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  staff: {
    field: 'staff',
    minValue: 3,
    value: '',
    operator: '==',
  },
  consequence: {
    field: 'consequence',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  behavior: {
    field: 'behavior',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  antecedent: {
    field: 'antecedent',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  incident: {
    field: 'incident',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  incidentDate: {
    field: 'incidentDate',
    minValue: 10,
    value: {
      start: moment().startOf('month').subtract(6, 'months'),
      end: moment().endOf('month')
    },
    operator: 'between',
  },
  category: {
    field: 'category',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  location: {
    field: 'location',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  nature: {
    field: 'nature',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  witness: {
    field: 'witness',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  conditionBefore: {
    field: 'conditionBefore',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  conditionAfter: {
    field: 'conditionAfter',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  notifications: {
    field: 'notifications',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  evaluationOfPlan: {
    field: 'evaluationOfPlan',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  intakeMethod: {
    field: 'intakeMethod',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  intakeAmount: {
    field: 'intakeAmount',
    minValue: 2,
    value: null,
    operator: '==',
  },
  outputMethod: {
    field: 'outputMethod',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  outputAmount: {
    field: 'outputAmount',
    minValue: 2,
    value: null,
    operator: '==',
  }
}
