import React from 'react';
import {Button, Grid, makeStyles, Paper, Typography} from "@material-ui/core";
import history from '../../../utils/history';
import {getResidentNote} from "../../../services/resident.service";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import ResidentNoteItem from "./resident.note.item";
import {getCategories} from "../../../services/note.category.service";
import {UserContext} from "../../../context/usercontext";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(.5),
    overflowX: 'hidden'
  }
}));

const ResidentNotePage = ({match}) => {
  const classes = useStyles();
  const { selectedTenant } = React.useContext(UserContext);
  const [categories, setCategories] = React.useState();
  const [note, setNote] = React.useState();


  React.useEffect(() => {
    if (match.params.residentNoteId && match.params.residentId && selectedTenant) {
      getNote(match?.params?.residentId, match?.params?.residentNoteId);
    }
    // eslint-disable-next-line
  },[match.params.residentId, match.params.residentNoteId, selectedTenant]);

  const getNote = async (residentId, residentNoteId) => {
    const categories = await getCategories(selectedTenant?._id);
    setCategories(categories);
    const response = await getResidentNote(residentId, residentNoteId);
    if (response.error) {
    } else {
      setNote(response);
    }
  }

  return (
    <Paper elevation={24}>
      <Grid container alignItems="center">
        <Button
          onClick={() => history.goBack()}
          className={classes.backBtn}
          startIcon={<NavigateBeforeIcon/>}
        >
          Back
        </Button>
        <Typography variant="h6">Resident Note</Typography>
      </Grid>
      {note && <ResidentNoteItem note={note} onDelete={() => history.goBack()} categories={categories} />}
    </Paper>
  )
}

export default ResidentNotePage;
