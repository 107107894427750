import React from "react";
import modelTemplate from "./model";
import FormFieldsGenerator from "../../utils/forms/form.fields.generator";
import {
  Table,
  TableBody,
  TableRow,
  Select,
  MenuItem,
  TableHead,
  TableCell,
  makeStyles,
  Paper,
  Grid,
  Button,
  Typography,
  DialogActions,
  Link,
  TableContainer,
} from "@material-ui/core";
import history from "../../utils/history";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import SmartphoneIcon from "@material-ui/icons/Smartphone";
import AddIcon from "@material-ui/icons/Add";
import * as insuranceService from "../../services/insurance.service";
import { getAllAddressTypes } from "../../services/address.service";
import { getPhoneTypes } from "../../services/phone.service";
import CreatePhone from "../phone/create.phone";
import PhoneMaskDisplay from "../utils/masks/phone.mask.display";
import PhoneMask from "../utils/masks/phone.mask";
import { isModelValid } from '../../utils/forms/form.validator';


const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  icon: {
    marginRight: ".5rem",
  },
  backBtn: {
    marginRight: ".5rem",
  },
  gridContainer: {
    padding: theme.spacing(1),
  },
}));

const Insurance = ({ match }) => {
  const classes = useStyles();
  const [model, setModel] = React.useState(
    JSON.parse(JSON.stringify(modelTemplate))
  );
  const [createPhoneModal, setCreatePhoneModal] = React.useState(false);
  const [insurance, setInsurance] = React.useState(null);
  const [phoneTypes, setPhoneTypes] = React.useState(null);
  const [editPhoneKey, setEditPhoneKey] = React.useState(-1);
  const [editPhone, setEditPhone] = React.useState(null);
  const [addressTypes, setAddressTypes] = React.useState([]);

  React.useEffect(() => {
    if (match.params.id) {
      getInsurance(match.params.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]);

  const getInsurance = async insuranceId => {
    const response = await insuranceService.getInsurance(insuranceId);
    merge(response);
  };

  const merge = async (entity) => {
    if (entity) {
      const obj = JSON.parse(JSON.stringify(modelTemplate));
      obj.name.value = entity.name;
      if (entity.address) {
        obj.address.value = entity.address.address;
        obj.address2.value = entity.address.address2;
        obj.city.value = entity.address.city;
        obj.state.value = entity.address.state;
        obj.zipCode.value = entity.address.zipCode;
        obj.addressType.value = entity.address.addressType;
      }
      obj.addressType.dropDownItems = await getTypes();
      setInsurance(entity);
      setModel(obj);
    }
  };

  const getTypes = async () => {
    if (!addressTypes || addressTypes.length === 0) {
      const types = await getAllAddressTypes();
      setAddressTypes(types);
      return types;
    } else {
      return addressTypes;
    }
  };

  const onChange = (e) => {
    const obj = { ...model, ...e };
    setModel(obj);
  };

  const onEditTypeChange = (e) => {
    const obj = { ...editPhone };
    obj.phoneType = e.target.value;
    setEditPhone(obj);
  };
  const onEditChange = (e) => {
    const obj = { ...editPhone };
    obj.number = e.target.value;
    setEditPhone(obj);
  };

  const updatePhone = async () => {
    const response = await insuranceService.updateInsurancePhone(insurance._id, editPhone._id, editPhone);
    const obj = {...insurance};
    obj.phones = obj.phones.map(ph => ph._id === response._id ? response: ph);
    setInsurance(obj);
    setEditPhoneKey(-1);
    setEditPhone(null);
  };
  const onEditPhone = async (phone, key) => {
    if (!phoneTypes || phoneTypes.length === 0) {
      const phTypes = await getPhoneTypes();
      setPhoneTypes(phTypes);
    }
    setEditPhone(phone);
    setEditPhoneKey(key);
  };
  const onDeletePhone = async (phone) => {
    await insuranceService.deleteInsurancePhone(insurance._id, phone._id);
    const obj = { ...insurance };
    obj.phones = obj.phones.filter((ph) => ph._id !== phone._id);
    setInsurance(obj);
  };

  const onCreateProviderPhone = async (phone) => {
    const entity = await insuranceService.createInsurancePhone(
      insurance._id,
      phone
    );
    const obj = { ...insurance };
    obj.phones = [...obj.phones, entity];
    setInsurance(obj);
    setCreatePhoneModal(false);
  };

  const editPhoneValid = () => {
    return (
      editPhone &&
      String(editPhone.number).length === 10 &&
      editPhone.phoneType
    );
  };

  const save = async () => {
    const data = {
        name: model.name.value,
        address: {
            address: model.address.value,
            address2: model.address2.value,
            city: model.city.value,
            state: model.state.value,
            zipCode: model.zipCode.value,
            addressType: model.addressType.value
        }
    }
    const response = await insuranceService.updateInsurance(insurance._id, data);
    setInsurance(response);
  }

  return (
    <Paper elevation={24} className={classes.root}>
      {insurance && (
        <Grid container>
          <Grid item md={6} xs={12}>
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              className={classes.gridContainer}
            >
              <Button
                onClick={() => history.goBack()}
                className={classes.backBtn}
                startIcon={<NavigateBeforeIcon />}
              >
                Back
              </Button>
              <Typography variant="h5">{insurance.name}</Typography>
            </Grid>
            <Grid className={classes.gridContainer}>
              <form>
                <FormFieldsGenerator model={model} onChange={onChange} />
                <DialogActions>
                  <Link>Cancel</Link>
                  <Button onClick={save} disabled={!isModelValid(model)} variant="contained" color="primary">
                    Save
                  </Button>
                </DialogActions>
              </form>
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              className={classes.gridContainer}
            >
              <SmartphoneIcon color="primary" className={classes.icon} />
              <Typography variant="h6">Phone</Typography>
            </Grid>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableCell>
                    <Button
                      onClick={() => setCreatePhoneModal(true)}
                      startIcon={<AddIcon />}
                    >
                      Add
                    </Button>
                    <CreatePhone
                      open={createPhoneModal}
                      onClose={() => setCreatePhoneModal(false)}
                      onSave={onCreateProviderPhone}
                    />
                  </TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Number</TableCell>
                </TableHead>
                <TableBody>
                  {insurance &&
                    insurance.phones &&
                    insurance.phones.map((phone, key) => (
                      <TableRow key={key}>
                        {editPhoneKey === key && (
                          <React.Fragment>
                            <TableCell style={{ display: "flex" }}>
                              <Button
                                size="small"
                                onClick={() => setEditPhoneKey(-1)}
                              >
                                Cancel
                              </Button>
                              <Button
                                size="small"
                                onClick={() => updatePhone()}
                                variant="contained"
                                color="primary"
                                disabled={!editPhoneValid()}
                              >
                                Save
                              </Button>
                            </TableCell>
                            <TableCell style={{ padding: 0 }}>
                              <Select
                                name="type"
                                value={editPhone.phoneType}
                                onChange={onEditTypeChange}
                              >
                                {phoneTypes &&
                                  phoneTypes.map((type) => (
                                    <MenuItem value={type.name}>
                                      {type.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </TableCell>
                            <TableCell>
                              <PhoneMask
                                value={editPhone.number}
                                onChange={onEditChange}
                              />
                            </TableCell>
                          </React.Fragment>
                        )}
                        {editPhoneKey !== key && (
                          <React.Fragment>
                            <TableCell>
                              <Button
                                size="small"
                                onClick={() => onEditPhone(phone, key)}
                              >
                                Edit
                              </Button>
                              <Button
                                size="small"
                                variant="contained"
                                color="error"
                                onClick={() => onDeletePhone(phone)}
                              >
                                Remove
                              </Button>
                            </TableCell>
                            <TableCell>
                              {phone.phoneType }
                            </TableCell>
                            <TableCell>
                              <PhoneMaskDisplay value={phone.number} />
                            </TableCell>
                          </React.Fragment>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

export default Insurance;
