import React from 'react';
import {
  Button,
  Chip,
  FormControl, InputLabel, MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import moment from "moment";
import {ACTIONS, TAB_VALUES, TimeEntryContext} from "../../../context/TimeEntryContext/time.entry.context";

const getWeekName = (weekNumber, year) => {
  let firstDay = moment().set({years: year, weeks: weekNumber}).startOf('w');
  let lastDay = moment().set({years: year, weeks: weekNumber}).endOf('w');

  return `${firstDay?.format("MM/DD")} - ${lastDay?.format("MM/DD/YYYY")}`;
}

const generateYears = (cur) => {
  const obj = [];
  for (let min = cur - 5; min <= cur; min++) {
    obj.push(min);
  }
  return obj;
};

export const TimeEntrySearch = () => {
  const { state, dispatch } = React.useContext(TimeEntryContext);
  const [year, setYear] = React.useState();
  const [years, setYears] = React.useState([]);
  const [week, setWeek] = React.useState();
  const [weeks, setWeeks] = React.useState([]);

  React.useEffect(() => {
    setYears(generateYears(moment().year()));
    setYear(moment().year());
    setWeek(moment().week());
  }, []);

  React.useEffect(() => {
    if (year) {
      setWeeks(weeks);
    }
    // eslint-disable-next-line
  }, [year]);

  React.useEffect(() => {
    if (year) {
      // setWeeks(Array.from(Array(52).keys()).map(i => ({week: i, name: getWeekName(i, year)})))
      setWeeks(
        Array.from({length: 52}, (_, index) => index + 1).map(
          i => (
            {
              week: i,
              name: getWeekName(i, year)
            }
            )
        )
      );
    }

  }, [year]);

  const onYearChage = e => {
    setYear(e.target.value);
  }

  const onWeekSelect = e => {
    setWeek(e.target.value);
    dispatch({
      type: ACTIONS.SET_WEEK_YEAR,
      payload: {week: e.target.value, year: year }
    });
  }

  const viewTimeSheet = () => {
    dispatch({type: ACTIONS.SET_TAB, payload: TAB_VALUES.TIMECLOCK});
  }

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel shrink>Year</InputLabel>
        <Select value={year || ''}
                onChange={onYearChage}
                variant={"outlined"}>
          {years?.map(y => <MenuItem value={y}>{y}</MenuItem>)}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel shrink>Week</InputLabel>
        <Select value={week || ''}
                onChange={onWeekSelect}
                variant={"outlined"}>
          {weeks?.map(w => <MenuItem value={w.week}>Week {w.week}: {w.name}</MenuItem>)}
        </Select>
      </FormControl>
      <TableContainer>
        <Table size={"small"}
               stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                Date
              </TableCell>
              <TableCell>
                Start
              </TableCell>
              <TableCell>
                End
              </TableCell>
              <TableCell>
                Comments
              </TableCell>
              <TableCell>
                Tags
              </TableCell>
              <TableCell>
                Total
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state?.timeEntries?.map(timeEntry =>
              <TableRow>
                <TableCell>
                  <Button style={{textDecoration: "underline"}} onClick={viewTimeSheet}>
                    {moment(timeEntry.date)?.utc().format("MM/DD/YYYY")}
                  </Button>
                </TableCell>
                <TableCell>
                  {moment(timeEntry.start)?.format("h:mm a")}
                </TableCell>
                <TableCell>
                  {moment(timeEntry.end)?.format("h:mm a")}
                </TableCell>
                <TableCell>
                  {timeEntry?.comment}
                </TableCell>
                <TableCell>
                  {timeEntry?.tags?.map(tag => <Chip label={tag} />)}
                </TableCell>
                <TableCell>
                  {timeEntry?.totalMinutes > 0 && moment.duration(timeEntry?.totalMinutes, "minutes").humanize()}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
