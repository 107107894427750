import {CONSTANTS} from "./Constants";
import {
  copyFile,
  createFiles,
  createFolder,
  deleteFileItem,
  deleteFileItems,
  downloadFile,
  getFiles,
  updateFileItem,
} from "../../../../services/resident.service";
import {ROOT_FOLDER} from "./InitialState";
import {getFolderChildren} from "./file.utils";
import {computeBreadcrumbs} from "./breadcrumb.utils";

export const onBreadcrumbSelectedAction = (dispatch, breadcrumb, files) => {
  const breadcrumbs = computeBreadcrumbs(breadcrumb, files);
  dispatch({type: CONSTANTS.SET_BREADCRUMB, payload: breadcrumbs});
  if (breadcrumb?._id === "null" || breadcrumb?.type === "folder") {
    dispatch({type: CONSTANTS.SET_SELECTED_FOLDER, payload: breadcrumb});
  }
}

export const getResidentFilesAction = async (dispatch, residentId) => {
  dispatch({type: CONSTANTS.SET_LOADING, payload: true});
  const response = await getFiles(residentId);
  if (!response?.error) {
    // Clear Selected Files/Folders
    dispatch({type:CONSTANTS.SET_SELECTED_FILE, payload: null});
    dispatch({type:CONSTANTS.SET_SELECTED_FOLDER, payload: null});
    dispatch({type:CONSTANTS.SET_SELECTED_FILE_NODE, payload: null});
    dispatch({type:CONSTANTS.SET_SELECTED_OBJECT, payload: null});
    dispatch({type: CONSTANTS.SET_FILES, payload: response});
    // SET ROOT
    dispatch({type: CONSTANTS.SET_BREADCRUMB, payload: [ROOT_FOLDER]})
    // SET SELECTED FILES
    getFolderChildrenFilesAction(dispatch, ROOT_FOLDER, response);
  }
  dispatch({type: CONSTANTS.SET_LOADING, payload: false});
}

export const getFolderChildrenFilesAction = (dispatch, folder, files) => {
  const childFiles = getFolderChildren(folder, files);
  dispatch({type: CONSTANTS.SET_SELECTED_FILES, payload: childFiles});
  onBreadcrumbSelectedAction(dispatch, folder, files);
}

export const setResidentAction = async (dispatch, residentId) => {
  dispatch({type: CONSTANTS.SET_RESIDENT_ID, payload: residentId});
  await getResidentFilesAction(dispatch, residentId);
}

export const selectFileAction = (dispatch, file, files) => {
  dispatch({type: CONSTANTS.SET_SELECTED_FILE, payload: file});
  onBreadcrumbSelectedAction(dispatch, file, files);
}

export const onFileDownloadAction = async (residentId, fileId) => {
  const result = await downloadFile(residentId, fileId);
  const buffer = Buffer.from(result?.content.data);
  const blob = new Blob([buffer], {type: result?.file?.fileType});
  const fileUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute(
    'download',
    result?.file?.fileName,
  );
  link.href = fileUrl;
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
}

export const onDeleteFileAction = async (residentId, fileId, dispatch) => {
  dispatch({type: CONSTANTS.SET_LOADING, payload: true});
  const result = await deleteFileItem(residentId, fileId);
  if (result === 'OK') {
    dispatch({type: CONSTANTS.DELETED_FILE, payload: fileId });
  }
  dispatch({type: CONSTANTS.SET_LOADING, payload: false});
}

export const onFileUploadAction = async (residentId, files, parent = null, tags = [], dispatch) => {
  dispatch({type: CONSTANTS.SET_LOADING, payload: true});
  const response = await createFiles(residentId, files, parent, tags);
  if (response?.status === 201) {
    dispatch({type: CONSTANTS.CREATED_FILE, payload: response?.files});
  } else {
    dispatch({type: CONSTANTS.SET_ERROR_MESSAGE, payload: response?.message});
  }
  dispatch({type: CONSTANTS.SET_LOADING, payload: false});
}

export const onFolderCreateAction = async (dispatch, residentId, folder, parent) => {
  dispatch({type: CONSTANTS.SET_LOADING, payload: true});
  folder.parent = parent;
  const response = await createFolder(residentId, folder);
  if (!response?.error) {
    dispatch({type: CONSTANTS.CREATED_FOLDER, payload: response});
    dispatch({type: CONSTANTS.SET_CREATE_FOLDER_MODAL, payload: false});
  } else {
    // catch error
    dispatch({type: CONSTANTS.SET_ERROR_MESSAGE, payload: response?.message});
  }
  dispatch({type: CONSTANTS.SET_LOADING, payload: false});
}

export const onFilesAndFoldersDeleteAction = async (dispatch, residentId, files) => {
  dispatch({type: CONSTANTS.SET_DELETING_FILES, payload: true});
  dispatch({type: CONSTANTS.SET_DELETE_FILE_ITEM_MODAL, payload: false});
  const response = await deleteFileItems(residentId, files);
  if (!response?.error) {
    dispatch({type: CONSTANTS.DELETED_FILES, payload: response});
  } else {

  }
  dispatch({type: CONSTANTS.SET_DELETING_FILES, payload: false });
}

export const onViewCopyFileModalAction = (dispatch, view, file = null) => {
  dispatch({type: CONSTANTS.SET_VIEW_COPY_MODAL, payload: view});
  dispatch({type: CONSTANTS.SET_SELECTED_FILE_NODE, payload: file});
}

export const onViewMoveFileModalAction = (dispatch, view, file = null) => {
  dispatch({type: CONSTANTS.SET_VIEW_MOVE_MODAL, payload: view});
  dispatch({type: CONSTANTS.SET_SELECTED_FILE_NODE, payload: file});
}

export const onViewRenameFileModalAction = (dispatch, view, file = null) => {
  dispatch({type: CONSTANTS.SET_VIEW_RENAME_MODAL, payload: view});
  dispatch({type: CONSTANTS.SET_SELECTED_FILE_NODE, payload: file});
}

export const onFileCopyAction = async (dispatch, parentId, residentId, fileId, destination, fileName, breadcrumbs) => {
  dispatch({type: CONSTANTS.SET_LOADING, payload: true});
  dispatch({type: CONSTANTS.SET_VIEW_COPY_MODAL, payload: false});
  const response = await copyFile(residentId, parentId, fileId, destination, fileName);
  if (!response?.error) {
    const parent = breadcrumbs?.pop();
    if (parent?._id === parentId) {
      dispatch({type: CONSTANTS.CREATED_FILE, payload: [response]});
    } else {
      dispatch({type: CONSTANTS.CREATED_FILE_OUTSIDE_OF_PARENT, payload: [response]});
    }
  } else {

  }
  dispatch({type: CONSTANTS.SET_LOADING, payload: false});
}

export const onFileMoveAction = async (dispatch, residentId, file, parentId) => {
  dispatch({type: CONSTANTS.SET_LOADING, payload: true});
  dispatch({type: CONSTANTS.SET_VIEW_MOVE_MODAL, payload: false});
  const response = await updateFileItem(residentId, file?._id, {...file, parent: parentId});
  if (!response?.error) {
    dispatch({type: CONSTANTS.MOVED_FILE, payload: response?.file});
  } else {
    dispatch({type: CONSTANTS.SET_ERROR_MESSAGE, payload: response?.message});
  }
  dispatch({type: CONSTANTS.SET_LOADING, payload: false});
}

export const onFileUpdatedAction = async (dispatch, residentId, fileId, file) => {
  dispatch({type: CONSTANTS.SET_LOADING, payload: true});
  const response = await updateFileItem(residentId, file?._id, file);
  if (!response?.error) {
    dispatch({type: CONSTANTS.UPDATED_FILE, payload: response?.file});
  } else {
    dispatch({type: CONSTANTS.SET_ERROR_MESSAGE, payload: response?.message});
  }
  dispatch({type: CONSTANTS.SET_LOADING, payload: false});
}

export const onFileRenameAction = async (dispatch, residentId, file, fileName) => {
  dispatch({type: CONSTANTS.SET_LOADING, payload: true});
  dispatch({type: CONSTANTS.SET_VIEW_RENAME_MODAL, payload: false});
  file.fileName = fileName;
  file.label = fileName;
  const response = await updateFileItem(residentId, file?._id, file);
  if (!response?.error) {
    dispatch({type: CONSTANTS.RENAMED_FILE, payload: response?.file});
  } else {
    dispatch({type: CONSTANTS.SET_ERROR_MESSAGE, payload: response?.message});
  }
  dispatch({type: CONSTANTS.SET_LOADING, payload: false});
}

export const onFolderUpdateAction = async (dispatch, residentId, folderId, folder) => {
  dispatch({type: CONSTANTS.SET_LOADING, payload: true});
  dispatch({type: CONSTANTS.SET_VIEW_EDIT_FOLDER_MODAL, payload: false});
  const response = await updateFileItem(residentId, folderId, folder);
  if (!response?.error) {
    dispatch({type: CONSTANTS.UPDATED_FOLDER, payload: response?.file});
  } else {
    dispatch({type: CONSTANTS.SET_ERROR_MESSAGE, payload: response?.message});
  }
  dispatch({type: CONSTANTS.SET_LOADING, payload: false});
}

export const onPermissionUpdateAction = async (dispatch, residentId, fileObjectId, fileObject) => {
  dispatch({type: CONSTANTS.SET_LOADING, payload: true});
  const response = await updateFileItem(residentId, fileObjectId, fileObject);
  if (!response?.error) {
    dispatch({type: CONSTANTS.UPDATED_FOLDER, payload: response?.file});
  } else {
    dispatch({type: CONSTANTS.SET_ERROR_MESSAGE, payload: response?.message});
  }
  dispatch({type: CONSTANTS.SET_LOADING, payload: false});
}
