import React from 'react';
import {
  Avatar,
  Button,
  Checkbox, CircularProgress, FormControl,
  FormControlLabel, FormGroup,
  Grid, LinearProgress, ListItemIcon,
  makeStyles, Menu, MenuItem,
  Paper, Tab,
  Tabs, TextField,
  Typography
} from "@material-ui/core";
import {
  CallToAction,
  PauseTwoTone,
  RotateLeftTwoTone, WarningRounded
} from "@material-ui/icons";
import {UserContext} from "../../../context/usercontext";
import {
  addUserRole,
  deactivateUser,
  getUser, removeUserRole,
  resetPassword,
  updateUserDetails, uploadUserProfilePicture
} from "../../../services/user.service";
import UserModel from './user.model';
import {FormFieldsGenerator, isModelValid} from "../../../utils/forms";
import {getTenantLocationRoles} from "../../../services/tenant.service";
import UserLogins from "../../user/analytics/user.logins";
import UserContactInfo from "./contact.info";
import useGlobalNotification from "../../../hooks/notification.hook";
import useHasRole from "../../../hooks/user.role.hook";
import {PageTitle} from "../../utils";
import SaveIcon from "@material-ui/icons/SaveTwoTone";
import history from "../../../utils/history";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 1)
  },
  tabContainer: {
    padding: theme.spacing(1)
  },
  btnContainer: {
    padding: theme.spacing(2, 0),
    justifyContent: 'flex-end'
  },
  actionBtn: {
    width: '15rem',
    margin: theme.spacing(0, 0, 1, 0)
  },
  blockBtn: {
    width: '15rem',
    margin: theme.spacing(0, 0, 1, 0),
    background: theme.palette.warning.main,
    color: '#fff',
    '&:hover': {
      background: theme.palette.warning.dark,
    }

  },
  deactivateBtn: {
    width: '15rem',
    margin: theme.spacing(0, 0, 1, 0),
    background: theme.palette.error.main,
    color: '#fff',
    '&:hover': {
      background: theme.palette.error.dark,
    }

  },
  progress: {
    margin: theme.spacing(2, 0)
  },
  avatar: {
    background: theme.palette.warning.main,
    marginRight: theme.spacing(2)
  }
}))

export const User = ({ match }) => {
  const classes = useStyles();
  const {selectedTenant} = React.useContext(UserContext);
  const [user, setUser] = React.useState();
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(UserModel)));
  const [tabValue, setTabValue] = React.useState(0);
  const [tenantRoles, setTenantRoles] = React.useState([]);
  const [userRoles, setUserRoles] = React.useState();
  const [processing, setProcessing] = React.useState(false);
  const [profileFile, setProfileFile] = React.useState();
  const [updating, setUpdating] = React.useState(false);
  const {addNotification} = useGlobalNotification();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {checkPermission} = useHasRole();
  const canReadUsers = checkPermission('USER_READ');
  const canWriteUsers = checkPermission('USER_WRITE');
  const userManagementWrite = checkPermission('USER_MANAGEMENT_WRITE');


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (match.params.id && selectedTenant?._id) {
      getData(match.params.id, selectedTenant?._id)
    }

    // eslint-disable-next-line
  }, [match.params.id, selectedTenant]);

  React.useEffect(() => {
    if (user && selectedTenant) {
      setUpUserRoles(selectedTenant, user);
    }
    // eslint-disable-next-line
  }, [user, selectedTenant])

  const setUpUserRoles = async (selectedTenant, uzer) => {
    let tRoles = [];
    if (!tenantRoles || tenantRoles?.length === 0) {
      const response = await getTenantLocationRoles(selectedTenant?._id);
      setTenantRoles(response);
      tRoles = [...response];
    }
    const obj = tRoles?.map(ur => {
      const existing = uzer?.roles?.find(a => a?._id === ur?._id);
      if (existing) {
        ur.checked = true;
      } else {
        ur.checked = false;
      }
      return ur;
    });
    setUserRoles(obj);
  }

  const getData = async (userId, tenantId) => {
    const user = await getUser(userId);
    setUser(user);
    mergeData(user);
  }

  const mergeData = (userObj) => {
    const obj = JSON.parse(JSON.stringify(UserModel));
    Object.keys(obj).forEach(key => {
      if (userObj.hasOwnProperty(key)) {
        obj[key].value = userObj[key]
      }
    });
    if (!obj?.tags?.value) {
      obj.tags.value = [];
    }
    setModel(obj);
  }

  const onModelChange = (changeEvent, field, extra) => {
    if (field === 'picture') {
      setProfileFile(extra);
    }
    setModel({...model, ...changeEvent})
  }

  const handleChange = async (userRole, checked) => {
    let response = null;
    if (checked) {
      response = await addUserRole(model?._id?.value, userRole?._id);
    } else {
      response = await removeUserRole(model?._id?.value, userRole?._id);
    }
    if (!response?.error) {
      const obj = userRoles?.map(ur => {
        if (ur?._id === userRole?._id) {
          ur.checked = !ur.checked;
        }
        return ur;
      });
      setUserRoles(obj);
      const mdl = {...model};
      mdl.roles.value = obj?.filter(item => item?.checked);
      setModel(mdl);
      addNotification('Update user role(s)', 'success');
    } else {
      addNotification('Unable to update role')
    }
  }

  const updateUser = async () => {
    setUpdating(true);
    let data = {};
    Object.keys(model).forEach((key, i) => {
      data[key] = model[key].value;
    });

    // Check if image changed
    if (profileFile) {
      // get url back from post
      const url = await uploadUserProfilePicture(user?._id, profileFile);
      data.picture = url;
    }
    await updateUserDetails(user?._id, data);
    setProfileFile(null);
    setUpdating(false);
    history.goBack();
  }

  const cancelUpdate = () => {
    mergeData(user);
  }

  const onToggleDeactivateUser = async () => {
    setAnchorEl(null);
    setProcessing(true);
    let isActive = model?.isActive?.value === false;
    const response = await deactivateUser(model?._id?.value, {isActive: isActive});
    const obj = {...model};
    obj.blocked.value = response?.blocked;
    setModel(obj);
    setProcessing(false);
    addNotification(`${model?.firstName?.value} ${model?.lastName?.value} has been ${isActive ? 'Activated' : 'deactivated'}.`, 'success');
  }

  const onResetPassword = async () => {
    setAnchorEl(null);
    const response = await resetPassword({
      _id: model?._id?.value,
      email: model?.email?.value
    });
    if (response.success) {
      addNotification('Password request successfully create. An email will arrive in the users inbox in a few minutes to reset password', 'success');
    } else {
      addNotification(response.message, 'error')
    }
  }

  if (canReadUsers) {
    return (
      <Paper elevation={24} className={classes.root}>
        {updating && <LinearProgress />}
        <PageTitle title={"User"} />
        <Tabs value={tabValue}
              onChange={(e, newValue) => setTabValue(newValue)}
              variant={"fullWidth"}
              indicatorColor="primary"
              textColor="primary"
              centered
        >
          <Tab label={"User Information"} index={0}/>
          <Tab label={"Contact Information"} index={1}/>
          <Tab label={"Account"} index={2}/>
          <Tab label={"Role"} index={3}/>
          <Tab label={"Activity"} index={4}/>
        </Tabs>
        {tabValue === 0 &&
        <div className={classes.tabContainer}>
          <FormFieldsGenerator model={model} onChange={onModelChange} edit={canWriteUsers} />
          {canWriteUsers &&
              <Grid container className={classes.btnContainer}>
                <Button color={"primary"}
                        onClick={cancelUpdate}
                >Cancel</Button>
                <Button color={"primary"}
                        variant={"contained"}
                        onClick={updateUser}
                        startIcon={updating ? <CircularProgress color={"#fff"} size={20} />: <SaveIcon />}
                        disabled={updating || !isModelValid(model)}>Update</Button>
            </Grid>}
        </div>}
        <UserContactInfo model={model} hidden={tabValue !== 1} canEdit={canWriteUsers}/>
        {tabValue === 2 &&
        <div>
          <div hidden={!processing}>
            <LinearProgress className={classes.progress}/>
            <Grid container
                  alignItems={"center"}
            >
              <Typography style={{margin: '0 auto'}} variant={"h6"}>Processing...</Typography>
            </Grid>
          </div>
          <div hidden={processing}>
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={model?.emailVerified?.value || null}/>}
                                label={"Email Verified"}/>
              <FormControlLabel control={
                <Checkbox checked={model?.isActive?.value || null}
                />
              }
                                label={"Active"}/>
              <TextField label={"Username"}
                         disabled
                         InputProps={{disableUnderline: true}}
                         value={model?.username?.value}/>
              <TextField label={"email"}
                         disabled
                         InputProps={{disableUnderline: true}}
                         value={model?.email?.value}/>
            </FormGroup>
            {userManagementWrite &&
            <div className={classes.accountActions}>
              <Button aria-controls="simple-menu"
                      aria-haspopup="true"
                      className={classes.actionBtn}
                      variant={"contained"}
                      color={"primary"}
                      startIcon={<CallToAction/>}
                      onClick={handleClick}>
                Account Actions
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={onToggleDeactivateUser}>
                  <ListItemIcon>
                    <PauseTwoTone/>
                  </ListItemIcon>
                  <Typography
                    variant="inherit">{model?.isActive?.value === false ? 'Activate' : 'Deactivate'}</Typography>
                </MenuItem>
                <MenuItem onClick={onResetPassword}>
                  <ListItemIcon>
                    <RotateLeftTwoTone/>
                  </ListItemIcon>
                  <Typography variant="inherit">Reset Password</Typography>
                </MenuItem>
              </Menu>
            </div>}
          </div>
        </div>
        }
        {tabValue === 3 && <div className={classes.tabContainer}>
          <FormControl>
            <Typography variant={"h6"}>
              Roles
            </Typography>
            {userRoles?.map(userRole =>
              <FormControlLabel label={`${userRole?.role?.name} (${userRole?.location?.name})`}
                                control={<Checkbox checked={userRole?.checked}
                                                   disabled={!canWriteUsers}
                                                   onChange={(event) => handleChange(userRole, event?.target?.checked)}/>}
              />
            )}
          </FormControl>
        </div>}
        {tabValue === 4 && <UserLogins user={user}/>}
      </Paper>
    )
  } else {
    return (
      <Paper elevation={24}
             className={classes.root}
      >
        <Grid container
              justifyContent={"center"}
              alignItems={"center"}
        >
          <Avatar className={classes.avatar}>
            <WarningRounded/>
          </Avatar>
          <div>
            <Typography variant={"h6"}>Your account does not have the permissions view users.</Typography>
            <Typography variant={"p"}>Please contact your system admin.</Typography>
          </div>
        </Grid>
      </Paper>
    )
  }
}

