import apiUtil from '../utils/api.utils';
import config from '../utils/configs';
import {parseFiltersToJson} from "../utils/filter.utils";
const apiUrl = `${config.medicationOrderService}/api/v1.0`;
const residentLogUrl = `${config.residentService}/api/v1.0`;

const createResidentLog = async (residentId, log) => {
  try {
    const response = await apiUtil.client.post(
      `${residentLogUrl}/residents/${residentId}/logs`,
      log
    );
    return response.data;
  } catch (error) {
    return { error: true, message: error };
  }
};

const updateResidentLog = async (residentId, logId, Log) => {
  try {
    const response = await apiUtil.client.put(
      `${residentLogUrl}/residents/${residentId}/logs/${logId}`,
      Log
    );
    return response.data;
  } catch (error) {
    return { error: true, message: error };
  }
};

const deleteResidentLog = async (residentId, logId) => {
  try {
    const response = await apiUtil.client.delete(
      `${residentLogUrl}/residents/${residentId}/logs/${logId}`
    );
    return response.data;
  } catch (error) {
    return { error: true, message: error };
  }
};

const getResidentLogs = async (residentId, date, type, offset) => {
  try {
    let url = `${apiUrl}/residents/${residentId}/logs?date=${date}`;
    if (type) {
      url += `&type=${type}`;
    }
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (error) {
    return { error: true, message: error};
  }
};

const searchResidentLogs = async (residentId,
                                  page = 1,
                                  size = 25,
                                  filters = [],
                                  sort = 'date',
                                  sortDirection = 'desc') => {
  try {
    let url = `${residentLogUrl}/residents/${residentId}/logs?page=${page}&size=${size}`;
    url += parseFiltersToJson(filters);
    let sortArg = sortDirection === 'asc' ? '+' : '-';
    sortArg += sort;
    url += `&sort=${sortArg}`;
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (error) {
    return { error: true, message: error};
  }
}

export {
  searchResidentLogs,
  getResidentLogs,
  deleteResidentLog,
  updateResidentLog,
  createResidentLog,
};
