export const CareplanModel = {
  name: {
    title: 'Care Plan Name',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  problemArea: {
    title: 'Problem Area',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  careplanDate: {
    title: 'Care Plan Date',
    required: true,
    value: null,
    isValid: true,
    dropDown: false,
    date: true,
  },
  reviewDate: {
    title: 'Review Date',
    required: false,
    value: null,
    isValid: true,
    dropDown: false,
    date: true,
  },
  tags: {
    title: 'Tags',
    hidden: true,
    value: [],
    required: false,
  },
  goals: {
    title: 'Goals',
    hidden: true,
    value: [],
    required: false,
  },
};
