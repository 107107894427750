import {makeStyles} from "@material-ui/core";

export const useStepStyles = makeStyles((theme) => ({
  btnContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  innerContainer: {
    padding: theme.spacing(2, 1)
  },
  titleContainer: {
    padding: theme.spacing(0, 0, 2, 0)
  },
  stepContainer: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 1),
    height: '75vh',
    overflow: 'scroll'
  },
  completedStep: {
    color: theme.palette.success.dark
  },
  summaryRoot: {
    display: 'flex',
    padding: '1rem',
  },
  imgContainer: {
    textAlign: 'center',
  },
  form: {
    flexGrow: 1,
    padding: '1rem',
  },
  img: {
    height: '240px',
  },
  cardRoot: {
    maxWidth: 450,
    margin: '0 auto'
  },
  cardMedia: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
}));
