import React from 'react';
import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
  DialogActions,
  Divider,
} from '@material-ui/core';
import Transition from '../../components/dialog/transition';
import CloseIcon from '@material-ui/icons/Close';
import {PermissionModel} from './PermissionModel';
import {FormFieldsGenerator, isModelValid} from "../../utils/forms";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    paddingTop: '4rem',
  },
  dialogContent: {
    marginTop: '4rem',
  },
}));



const CreatePermission = ({ open, onClose, onSave }) => {
  const classes = useStyles();
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(PermissionModel)));

  React.useEffect(() => {
    setModel(JSON.parse(JSON.stringify(PermissionModel)))
  }, [open]);

  const save = () => {
    let data = {};
    Object.keys(model).forEach(key => {
      data[key] = model[key]?.value;
    })
    onSave(data);
  };

  const onChange = (e) => {
    setModel({...model, ...e})
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullScreen
      onClose={onClose}
    >
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Create Permission</Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.dialogContent}>
          <FormFieldsGenerator model={model} onChange={onChange} />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary"
                variant="contained"
                disabled={!isModelValid(model)}
                onClick={save}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreatePermission;
