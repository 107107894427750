import React from 'react'
import {AppBar, Button, makeStyles, Toolbar, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import {useAuth0} from "../../react-auth0-spa";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '4rem',
    overflow: 'hidden',
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
  },
  appTitle: {
    textDecoration: 'none',
  },
  appTitleLink: {
    textDecoration: 'none',
    color: '#fff',
    fontFamily: 'Brush Script MT, Brush Script Std, cursive',
    "&:hover": {
      textDecoration: 'none'
    }
  },
  smAppTitle: {
    fontSize: '.9rem',
  },
  toolBar: {
    justifyContent: 'space-between',
  },
  list: {
    width: 250,
  }
}));

const DeactivatedNavbar = () => {
  const classes = useStyles();
  const { loginWithRedirect } = useAuth0();

  const login = async () => {
    await loginWithRedirect();
  };

  return (
    <AppBar position={"sticky"}>
      <Toolbar className={classes.toolBar}>
        <div className={classes.logo}>
          <Typography className={classes.appTitleLink} variant="h3"
                      component={Link} to={"/"}>
            eR
          </Typography>
        </div>
        <Button color="secondary" variant="contained" onClick={login}>
        Log in
      </Button>
      </Toolbar>
    </AppBar>
  )
}

export default DeactivatedNavbar;
