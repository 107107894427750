import React from "react";
import { reducer, initialState } from './intake.reducer';
import * as constant from './intake.constants';

let IntakeContext = React.createContext();

const IntakeProvider = ({tenantLocation, tenantLocations, tenant, children}) => {
  let [state, dispatch] = React.useReducer(reducer, initialState);
  let value = { state, dispatch };

  React.useEffect(() => {
    dispatch({type: constant.SET_TENANT_LOCATION, payload: tenantLocation});
  }, [tenantLocation]);

  React.useEffect(() => {
    dispatch({type: constant.SET_TENANT_LOCATIONS, payload: tenantLocations});
  }, [tenantLocations]);

  React.useEffect(() => {
    dispatch({type: constant.SET_TENANT, payload: tenant})
  }, [tenant]);

  return (
    <IntakeContext.Provider value={value}>
      {children}
    </IntakeContext.Provider>
  )
}

let IntakeConsumer = IntakeContext.Consumer;

export {
  IntakeContext,
  IntakeConsumer,
  IntakeProvider
}
