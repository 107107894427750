import React from 'react';
import {
  Paper,
  makeStyles,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  Button,
  TableBody,
  TableRow, Grid, TablePagination, ButtonGroup, Hidden,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import CreateIntakeoutputLog from './create.intakeoutput.log';
import IntakeoutputLog from './intakeoutput.log';
import TableLoadingIndicator from "../../../../utils/indicators/table.loading";
import {DateRangeSortFilterLabel, SortFilterLabel} from "../../../utils/table.utils";
import {ClearAllTwoTone} from "@material-ui/icons";
import {DeleteBtn} from "../../../utils/buttons/DeleteBtn";
import {MobileLogs} from "../mobile/mobile.logs";
import {ListLoading} from "../../../../utils/indicators/list.loading";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(.5, 0),
    padding: theme.spacing(1),
  },
}));

const ResidentIntakeoutputLogs = ({
  logs,
  onSave,
  onUpdateLog,
  onDeleteLog,
  date,
                                    canUpdate,
                                    filter,
                                    sortOrderBy,
                                    sortDirection,
                                    createSortHandler,
                                    filterTextChange,
                                    onDateRangeChange,
                                    loading,
                                    users,
                                    page,
                                    size,
                                    total,
                                    onChangePage,
                                    onChangeRowsPerPage,
                                    rowsPerPageOptions,
  onClearFilters,
  ...args
}) => {
  const classes = useStyles();
  const [createIncontinence, setCreateIncontinence] = React.useState(false);
  const [viewLogModal, setViewLogModal] = React.useState(false);
  const [selectedLog, setSelectedLog] = React.useState();

  const createIncontinenceLog = (log) => {
    onSave(log);
    setCreateIncontinence(false);
  };

  const selectLog = (e) => {
    setSelectedLog(e);
    setViewLogModal(true);
  };

  const onUpdate = (id, log) => {
    onUpdateLog(id, log);
    setViewLogModal(false);
  };

  return (
    <Paper elevation={24} className={classes.root} {...args}>
      <Typography variant="h6">Intake/Output Logs</Typography>
      <Button
        startIcon={<AddIcon />}
        onClick={() => setCreateIncontinence(true)}
      >
        Add New Log
      </Button>
      <CreateIntakeoutputLog
        open={createIncontinence}
        onSave={createIncontinenceLog}
        onClose={() => setCreateIncontinence(false)}
        date={date}
      />
      <IntakeoutputLog
        open={viewLogModal}
        log={selectedLog}
        onClose={() => setViewLogModal(false)}
        onUpdate={onUpdate}
      />
      <Grid container justify={"flex-end"} alignItems={"center"}>
        <Hidden xsDown>
          <Button onClick={onClearFilters}
                  size={"small"}
                  startIcon={<ClearAllTwoTone />}
          >
            Clear Filters
          </Button>
        </Hidden>
        <TablePagination component={"div"}
                         count={total}
                         onChangePage={onChangePage}
                         onChangeRowsPerPage={onChangeRowsPerPage}
                         rowsPerPageOptions={rowsPerPageOptions}
                         page={page - 1}
                         rowsPerPage={size} />
      </Grid>
      <Hidden xsDown>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableCell />
              <TableCell>
                <DateRangeSortFilterLabel onDateRangeChange={onDateRangeChange}
                                          createSortHandler={createSortHandler}
                                          sortDirection={sortDirection}
                                          sortOrderBy={sortOrderBy}
                                          filter={filter}
                                          fromDate={filter?.date?.value?.start}
                                          toDate={filter?.date?.value?.end}
                                          field={"date"}
                                          label={"Date"} />
              </TableCell>
              <TableCell>
                <SortFilterLabel filterTextChange={filterTextChange}
                                 createSortHandler={createSortHandler}
                                 sortDirection={sortDirection}
                                 sortOrderBy={sortOrderBy}
                                 filter={filter}
                                 field={"intakeMethod"}
                                 label={"Intake Method"} />
              </TableCell>
              <TableCell>
                <SortFilterLabel filterTextChange={filterTextChange}
                                 createSortHandler={createSortHandler}
                                 sortDirection={sortDirection}
                                 sortOrderBy={sortOrderBy}
                                 filter={filter}
                                 field={"intakeAmount"}
                                 label={"Intake Amount"} />
              </TableCell>
              <TableCell>
                <SortFilterLabel filterTextChange={filterTextChange}
                                 createSortHandler={createSortHandler}
                                 sortDirection={sortDirection}
                                 sortOrderBy={sortOrderBy}
                                 filter={filter}
                                 field={"outputMethod"}
                                 label={"Output Method"} />
              </TableCell>
              <TableCell>
                <SortFilterLabel filterTextChange={filterTextChange}
                                 createSortHandler={createSortHandler}
                                 sortDirection={sortDirection}
                                 sortOrderBy={sortOrderBy}
                                 filter={filter}
                                 field={"outputAmount"}
                                 label={"Output Amount"} />
              </TableCell>
              <TableCell>
                <SortFilterLabel filterTextChange={filterTextChange}
                                 createSortHandler={createSortHandler}
                                 sortDirection={sortDirection}
                                 sortOrderBy={sortOrderBy}
                                 filter={filter}
                                 field={"comments"}
                                 label={"Comments"} />
              </TableCell>
            </TableHead>
            {!loading &&
            <TableBody>
              {logs?.map((log) => (
                  <TableRow>
                    <TableCell>
                      <ButtonGroup size={"small"}>
                        <Button onClick={() => selectLog(log)}
                                variant={"contained"}
                                color={"primary"}
                        >
                          View
                        </Button>
                        {canUpdate &&
                        <DeleteBtn onClick={() => onDeleteLog(log._id)} />
                        }
                      </ButtonGroup>
                    </TableCell>
                    <TableCell>{moment(log.date).format('MM/DD/YYYY')}</TableCell>
                    <TableCell>{log.intakeMethod}</TableCell>
                    <TableCell>{log.intakeAmount}</TableCell>
                    <TableCell>{log.outputMethod}</TableCell>
                    <TableCell>{log.outputAmount}</TableCell>
                    <TableCell>{log.comments}</TableCell>
                  </TableRow>
                ))}
            </TableBody>}
            {loading && <TableLoadingIndicator cols={7} rows={5} bodyOnly={true} /> }
          </Table>
        </TableContainer>
      </Hidden>
      <Hidden smUp>
        {!loading &&
        <MobileLogs logs={logs}
                    primaryText={(log) =>
                      <>
                        {log?.intakeMethod ?
                          <Typography variant={"subtitle2"}>{log?.intakeMethod} {log?.intakeAmount}</Typography>: null}
                        {log?.outputMethod ?
                          <Typography variant={"subtitle2"}>{log?.outputMethod} {log?.outputAmount}</Typography>: null}
                      </>
                      }
                    secondaryText={(log) =><>
                      {moment(log?.date).format('MM/DD/YYYY h:mm a')}
                      <Typography variant={"subtitle2"}>{log?.comments}</Typography>
                    </>}
                    canUpdate={canUpdate}
                    onDeleteLog={onDeleteLog}
                    selectLog={selectLog}
        />}
        {loading &&
          <ListLoading rows={4} />
        }
      </Hidden>
    </Paper>
  );
};

export default ResidentIntakeoutputLogs;
