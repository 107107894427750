import React from 'react';
import {
  Paper,
  makeStyles,
  Grid,
  TablePagination,
  Button,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TextField,
  TableBody,
  TableRow,
  Chip, Hidden, List, ListItem, ListItemText, ListItemIcon, Select, MenuItem, useTheme, useMediaQuery,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import history from '../../../utils/history';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import filterTemplate from './filterTemplate';
import {
  getOrderStatuses,
  getResidentMedicationOrders,
} from '../../../services/medication.order.service';
import CreateMedicationOrder from '../../medicationorders/create/create.medication.order';
import moment from 'moment';
import { convertToFilters } from '../../../utils/filter.utils';
import queryString from 'query-string';
import { ResidentContext } from '../../../context/residentcontext';
import useHasRole  from '../../../hooks/user.role.hook';
import {GlobalNotificationContext} from "../../notification/globalnotificationprovider";
import {getDayOfWeek} from "../../../utils/date.utils";
import {getStripedStyle, SortFilterLabel, SortFilterSelectLabel} from "../../utils/table.utils";
import {Dashboard, Error } from "@material-ui/icons";
import {Skeleton} from "@material-ui/lab";
import {
  CurrentResidentMedicationReportDialog
} from "../../reports/resident/medications/CurrentResidentMedicationReportDialog";
import TableLoadingIndicator from "../../../utils/indicators/table.loading";
import {isMedicationOrderOnHold} from "./emar/utils";
import {yellow} from "@material-ui/core/colors";
import {MobileMedicationOrdersDownload} from "./print/MobileMedicationOrdersDownload";


const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(.25, 0),
    padding: theme.spacing(1),
  },
  icon: {
    marginRight: '.5rem',
  },
  backBtn: {
    marginRight: '.5rem',
  },
  gridContainer: {
    padding: theme.spacing(1),
  },
  isHold: {
    backgroundColor: yellow[300]
  }
}));

export const DosageInstructions = ({ hideDate = false, dosageInstructions }) => {

  const isPerDay = frequency => {
    const names = ["QD", "BID", "TID", "QID", "5XDAY"];
    return names.includes(frequency);
  }


  return (
    <div>
      {dosageInstructions &&
      dosageInstructions.map((dosage) => {
        return (
          <Grid container>
            {!hideDate &&
              <Grid xs={12}>
                {moment(dosage.startDate).format('MM/DD/YYYY')} - {moment(dosage.endDate).format('MM/DD/YYYY')}
              </Grid>
            }
            <Grid xs={12}>
                Frequency: {dosage?.frequency?.name}
            </Grid>
            <Grid xs={12}>
                {dosage?.eventTemplates?.map(event => {
                  const time = moment({ hour: event?.hour, minute: event?.minute});
                  return (
                    <div>
                      {event.month && !isPerDay(dosage?.frequency?.name) && (
                        <span>
                              Month {event.month}
                          {' '}
                            </span>
                      )}
                      {event.day && !isPerDay(dosage?.frequency?.name) && (
                        <span>
                              Day {getDayOfWeek(event.day)}
                          {' '}
                            </span>
                      )}
                      {time && (
                        <span>{moment(time).format('H:mm A')} </span>
                      )}
                      {event.passingQty && (
                        <span> Qty {event?.passingQty} </span>
                      )}
                    </div>
                  );
                })}
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
};



const sizeOptions = [1, 5, 10, 25];

const ResidentMedicationOrders = ({ match, location }) => {
  const classes = useStyles();
  const [filter, setFilter] = React.useState(JSON.parse(JSON.stringify(filterTemplate)));
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [total, setTotal] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [medicationOrders, setMedicationOrders] = React.useState([]);
  const [sortOrderBy, setSortOrderBy] = React.useState('medication.tradeName');
  const [sortOrderDirection, setSortOrderDirection] = React.useState('asc');
  const [openCreateOrderModal, setOpenCreateOrderModal] = React.useState(false);
  const [orderStatuses, setOrderStatuses] = React.useState([]);
  const [viewCurrentMedicationReportModal, setViewCurrentMedicationReportModal] = React.useState(false);
  const [isPrn, setIsPrn] = React.useState(false);
  const { resident } = React.useContext(ResidentContext);
  const { checkPermission } = useHasRole();
  const canEdit = checkPermission('MEDICATION_ORDER_UPDATE');
  const { addNotification } = React.useContext(GlobalNotificationContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  React.useEffect(() => {
    const load = async (id) => {
      const activeStatuses = await init();
      const obj = JSON.parse(JSON.stringify(filterTemplate));
      obj.status.value = activeStatuses?.map(a => a._id);
      setFilter(obj);
      await search(id, 1, 10, obj, 'medication.tradeName', 'asc');
      const queryParams = queryString.parse(location.search);
      if (queryParams.prn) {
        setIsPrn(true);
        setOpenCreateOrderModal(true);
        history.replace(`/residents/${match.params.id}/medications`);
      } else {
        setIsPrn(false);
      }
    };

    if (match.params.id) {
      load(match.params.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]);

  const init = async () => {
    if (!orderStatuses || orderStatuses.length === 0) {
      const data = await getOrderStatuses();
      setOrderStatuses(data);
      return data.filter((d) => d.name === 'Active' || d.name === 'Hold' );
    } else {
      return orderStatuses.filter((d) => d.name === 'Active' || d.name === 'Hold');
    }
  };

  const createSortHandler = async (field) => {
    const sort = field;
    const sortDirection = sortOrderDirection === 'asc' ? 'desc' : 'asc';
    setSortOrderBy(sort);
    setSortOrderDirection(sortDirection);
    await search(resident?._id, page, size, filter, sort, sortDirection);
  };

  const filterTextChange = async (e) => {
    const obj = { ...filter };
    obj[e.target.name].value = e.target.value;
    if (
      String(obj[e.target.name].value).length === 0 ||
      String(obj[e.target.name].value).length >= obj[e.target.name].minValue
    ) {
      await search(
        resident?._id,
        page,
        size,
        obj,
        sortOrderBy,
        sortOrderDirection
      );
    }
    setFilter(obj);
  };

  const search = async (residentId, pg, sz, filter, sort, sortDirection) => {
    setLoading(true);
    setError(false);
    const filters = convertToFilters(filter);
    const response = await getResidentMedicationOrders(
      residentId,
      pg,
      sz,
      filters,
      sort,
      sortDirection
    );
    if (!response?.error) {
      setMedicationOrders(response.content);
      setSize(response?.perPage);
      setPage(response?.currentPage);
      setTotal(response?.totalItems);
    } else {
      setError(true);
    }
    setLoading(false);
  };

  const onPrnChange = async e => {
    const obj = {...filter};
    const val = e.target.value;
    if (val === "true" || val === "false") {
      obj.isPrn.value = JSON.parse(val);
    } else {
      obj.isPrn.value = null;
    }
    setFilter(obj);

    await search(
      resident?._id,
      page,
      size,
      obj,
      sortOrderBy,
      sortOrderDirection
    );
  }

  const clearFilters = async () => {
    const obj = JSON.parse(JSON.stringify(filterTemplate));
    setFilter(obj);
    setSortOrderBy('orderDate');
    setSortOrderDirection('desc');
    await search(resident?._id, 1, 10, obj, 'orderDate', 'desc');
  };

  const onChangeRowsPerPage = async (event) => {
    setSize(event.target.value);
    await search(
      resident?._id,
      page,
      event.target.value,
      filter,
      sortOrderBy,
      sortOrderDirection
    );
  };
  const handleChangePage = async (event, newPage) => {
    const pg = newPage + 1;
    setPage(pg);
    await search(
      resident?._id,
      pg,
      size,
      filter,
      sortOrderBy,
      sortOrderDirection
    );
  };

  const createMedicationOrder = ({medicationOrder, eventsCreated}) => {
    const obj = [...medicationOrders, medicationOrder];
    setOpenCreateOrderModal(false);
    setMedicationOrders(obj);
    addNotification(`Order created, ${eventsCreated} events created.`, "success");

  };

  const getPhysicianLabel = (physician) => {
    if (physician) {
      return ` ${physician.title ?? ''} ${physician?.firstName} ${physician?.lastName}`;
    } else {
      return null;
    }
  }

  return (
    <Paper elevation={24} className={classes.root}>
      <Grid container alignItems="center" justify="space-between">
        <Grid item>
          <Grid container>
            <Button
              onClick={() => history.goBack()}
              className={classes.backBtn}
              startIcon={<NavigateBeforeIcon />}
            >
              Back
            </Button>
            <Typography variant="h5">Medication Orders</Typography>
          </Grid>
          <div>
            {canEdit &&
            <Button
              onClick={() => setOpenCreateOrderModal(true)}
              size="small"
              variant="contained"
              color="primary"

            >
              Create New
            </Button>}
            <Button
              startIcon={<HighlightOffIcon />}
              size="small"
              onClick={clearFilters}
            >
              Clear Filters
            </Button>
            <CreateMedicationOrder
              open={openCreateOrderModal}
              resident={resident}
              onClose={() => setOpenCreateOrderModal(false)}
              onSave={createMedicationOrder}
              prn={isPrn}
            />
          </div>
        </Grid>
        <Grid item>
        </Grid>
        <Grid item>
          {!isMobile &&
              <Button startIcon={<Dashboard />}
                  onClick={() => setViewCurrentMedicationReportModal(true)}
              >
                Current Medication Report
              </Button>
          }
          {isMobile &&
            <MobileMedicationOrdersDownload resident={resident} />
          }

          <CurrentResidentMedicationReportDialog resident={resident}
                                                 onClose={() => setViewCurrentMedicationReportModal(false)}
                                                 open={viewCurrentMedicationReportModal}
          />
          <TablePagination
            component="div"
            rowsPerPageOptions={sizeOptions}
            colSpan={5}
            count={total ? total : 0}
            rowsPerPage={size ? size : 0}
            page={page - 1}
            backIconButtonProps={{
              'aria-label': 'previous page',
              disabled: page === 1 ? true : false,
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
              disabled: page * size >= total ? true : false,
            }}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onChangePage={handleChangePage}
          ></TablePagination>
        </Grid>
      </Grid>
      <Hidden xsDown>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableCell style={{ padding: 0}}></TableCell>
              <TableCell>
                Is Prn
                <Select fullWidth
                        displayEmpty={true}
                        onChange={onPrnChange}
                        value={filter.isPrn.value}
                >
                  <MenuItem value={null}>
                    All
                  </MenuItem>
                  <MenuItem value={"true"}>Yes</MenuItem>
                  <MenuItem value={"false"}>No</MenuItem>
                </Select>
              </TableCell>
              <TableCell>
                <SortFilterLabel filterTextChange={filterTextChange}
                                 createSortHandler={createSortHandler}
                                 sortDirection={sortOrderDirection}
                                 sortOrderBy={sortOrderBy}
                                 filter={filter}
                                 field={"medicationName"}
                                 label={"Medication"}/>
              </TableCell>
              <TableCell>Dosage Instruction</TableCell>
              <TableCell>
                <SortFilterSelectLabel onChange={filterTextChange}
                                       createSortHandler={createSortHandler}
                                       sortDirection={sortOrderDirection}
                                       sortOrderBy={sortOrderBy}
                                       filter={filter}
                                       field={"status"}
                                       label={"Status"}
                                       options={orderStatuses}
                                       optionLabel={"name"}
                                       optionValue={"_id"}
                                       multipleSelect={true}
                />
              </TableCell>
              <TableCell>Discontinued</TableCell>
            </TableHead>
            {!error && loading &&
              <TableLoadingIndicator rows={5}
                                     cols={6}
                                     bodyOnly={true}
              />
            }
            <TableBody>
              {!error && !loading && medicationOrders?.map((order, index) => (
                <TableRow style={getStripedStyle(index)}>
                  <TableCell style={{ padding: 0}}>
                    {resident && (
                      <Button
                        size="small"
                        component={RouterLink}
                        to={`/residents/${resident?._id}/medications/${order?._id}`}
                      >
                        View
                      </Button>
                    )}
                  </TableCell>
                  <TableCell style={{padding: 0}}>
                    {order?.isPrn && <Chip size={"small"} label={"PRN"} color={"primary"} />}
                    {isMedicationOrderOnHold(order, new Date()) &&
                      <Chip size={"small"} label={"On Hold"} className={classes.isHold}/>
                    }
                  </TableCell>
                  <TableCell>
                    <Grid container>
                      <Grid item>
                        <Typography variant={"subtitle1"}>
                          {order?.medication?.tradeName} {order?.medication?.strength}{' '}
                          {order?.medication?.unit ? order?.medication?.unit?.name : null}
                        </Typography>
                        <Typography variant={"subtitle2"}>
                          RX: {order?.rxNumber}{getPhysicianLabel(order?.physician)}
                        </Typography>
                        {order?.direction &&
                        <Typography variant={"subtitle2"}>
                          Direction: {order?.direction}
                        </Typography>
                        }
                        {order?.indication &&
                        <Typography variant={"subtitle2"}>
                          Indication: {order?.indication}
                        </Typography>
                        }
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    {!order?.isPrn &&
                        <DosageInstructions
                            dosageInstructions={order?.dosageInstructions}
                        />}
                    {order?.isPrn && <span>N/A</span>}
                  </TableCell>
                  <TableCell>
                    {order?.orderStatus ? order?.orderStatus?.name : 'Active'}
                  </TableCell>
                  <TableCell>
                    {order?.orderStatus?.name === "Discontinued" && moment(order?.discontinuedDate).isValid() &&
                      moment(order?.discontinuedDate).format("MM/DD/YYYY")
                    }
                  </TableCell>
                </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Hidden>
      <Hidden smUp>
        <TextField fullWidth
                   name="medicationName"
                   variant={"outlined"}
                   value={filter.medicationName.value || ''}
                   onChange={filterTextChange}
                   placeholder={"Search Medication Name"}
        ></TextField>
        <List>
          {error &&
          <ListItem>
            <ListItemIcon>
              <Error />
            </ListItemIcon>
            <ListItemText primary={"Error getting medication orders"}
                          secondary={"Click to refresh"}
            />
          </ListItem>}
          {!error && loading && [...Array(4)].map(i =>
            <ListItem>
              <ListItemText primary={<Skeleton height={40} />}
                            secondary={<div>
                              <Skeleton />
                              <Skeleton />
                              <Skeleton />
                            </div>}
              />
            </ListItem>
          )}
          {!error && !loading && medicationOrders?.map(medicationOrder =>
            <ListItem divider button
                      component={RouterLink}
                      to={`/residents/${resident?._id}/medications/${medicationOrder?._id}`}>
              <ListItemIcon>
                {medicationOrder?.isPrn ?
                  <Chip size={"small"} label={"PRN"} color={"primary"} />:
                  <i className="fas fa-prescription-bottle"></i>
                }
              </ListItemIcon>
              <ListItemText primary={medicationOrder?.medication?.tradeName}
                            secondary={<DosageInstructions dosageInstructions={medicationOrder?.dosageInstructions} />}
              />
            </ListItem>
          )}
        </List>
      </Hidden>
    </Paper>
  );
};

export default ResidentMedicationOrders;
