import React from 'react';
import {
  Grid,
  Typography,
} from "@material-ui/core";
import modelTemplate from "./modelTemplate";
import {FormFieldsGenerator} from "../../../../utils/forms";




const VitalsInput = ({ emar, edit = false, onValueChange, resident }) => {
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify({})));
  const [editModal, setEditModel] = React.useState(JSON.parse(JSON.stringify({})));

  React.useEffect(() => {
    if (emar) {
      const obj = JSON.parse(JSON.stringify(modelTemplate));
      Object.keys(emar?.vital).forEach(key => {
        if (obj.hasOwnProperty(key)) {
          obj[key].value = emar.vital[key];
        }
      });
      Object.keys(obj).forEach(key => {
          if (key !== 'date') {
            obj[key].hidden = !(emar?.chartVitalsList?.indexOf(obj[key].key) > -1);
          }
      })
      setModel(obj);
    }
  }, [emar, emar?.vital]);

  React.useEffect(() => {
    if (edit) {
      setEditModel(JSON.parse(JSON.stringify(model)));
    }
    // eslint-disable-next-line
  }, [edit]);


  const onChange = e => {
    const obj = {...model, ...e};
    setEditModel(obj);
    let payload = {};
    Object.keys(editModal).forEach(key => {
      payload[key] = editModal[key].value;
    });
    payload.resident = resident;
    onValueChange(emar?._id, payload);
  }

  const displayModalValues = () => {
    let props = [];
    Object.keys(model).forEach(key => {
      if (key !== 'date' && !model[key].hidden) {
        props = [...props, model[key]]
      }
    })
    return props.map(p => `${p.key}: ${p.value ?? 'n/a'}`).join(', ');
  }

  return (
    <Grid container
          justify={"flex-end"}
          alignItems={"center"}
    >
      <Grid item style={{ padding: '0 1rem'}}>
        <Grid container
              alignItems={"center"}
        >
          <i className="fas fa-heartbeat" style={{marginRight: '.25rem'}}></i>
          <Typography variant={"subtitle1"}>Vitals</Typography>
        </Grid>
      </Grid>


      <Grid item style={{ minWidth: '40rem' }}>
        {edit &&
            <FormFieldsGenerator model={editModal}
                                 onChange={onChange}
                                 edit={edit}  />
        }
        {!edit &&
          <div>
            {displayModalValues()}
          </div>
        }
      </Grid>
    </Grid>
  )
}

export default VitalsInput;
