import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from "@material-ui/core";
import Transition from "../dialog/transition";
import CloseIcon from "@material-ui/icons/Close";
import { getAllAddressTypes } from "../../services/address.service";
import { stateProvidenceList } from "./providencesAndStates";
import FormFieldsGenerator from "../../utils/forms/form.fields.generator";

const modelTemplate = {
  address: {
    title: "Address",
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
  },
  address2: {
    title: "Address 2",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
  },
  city: {
    title: "City",
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
    textType: "tel",
  },
  state: {
    title: "State",
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: stateProvidenceList,
    dropDownItemValue: "abbreviation",
    dropDownItemLabel: "name",
    date: false,
    textType: "tel",
    isStateProvidence: true
  },
  zipCode: {
    title: "Zip",
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
  },
  addressType: {
    title: "Address Type",
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [],
    dropDownItemValue: "name",
    dropDownItemLabel: "name",
    date: false,
  },
  isCurrent: {
    value: null,
    hidden: true
  },
  _id: {
    value: null,
    hidden: true
  }
};

const EditAddress = ({ open, address, onUpdate, onClose, canEdit = true }) => {
  const [model, setModel] = React.useState(
    JSON.parse(JSON.stringify(modelTemplate))
  );

  React.useEffect(() => {
    const getTypes = async () => {
        const types = await getAllAddressTypes();
        modelTemplate.addressType.dropDownItems = types;
        const obj = {...model};
        obj.addressType.dropDownItems = types;
        setModel(obj);
    }
    getTypes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  React.useEffect(() => {
    if (address) {
      const obj = {...model};
      obj.address.value = address.address;
      obj.address2.value = address.address2;
      obj.city.value = address.city;
      obj.state.value = address.state;
      obj.zipCode.value = address.zipCode;
      obj.addressType.value = address.addressType;
      obj.isCurrent.value = address.isCurrent;
      obj._id.value = address._id;

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onModelChange = (updatedModel) => {
    const obj = { ...model, ...updatedModel };
    setModel(obj);
  };

  const update = () => {
    onUpdate({
      _id: model._id.value,
      address: model.address.value,
      address2: model.address2.value,
      city: model.city.value,
      state: model.state.value,
      zipCode: model.zipCode.value,
      addressType: model.addressType.value,
      isCurrent: model.isCurrent.value
    });
  };

  const cancel = () => {
      setModel(JSON.parse(JSON.stringify(modelTemplate)));
      onClose();
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={cancel}
      TransitionComponent={Transition}
    >
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => cancel()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Update Address</Typography>
        </Toolbar>
      </AppBar>
      <DialogContent style={{ marginTop: "65px" }} dividers>
        <form>
          <FormFieldsGenerator model={model} onChange={onModelChange} edit={canEdit} />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel}>Cancel</Button>
        {canEdit &&
        <Button color="primary"
                variant={"contained"}
                onClick={update}>
          Update
        </Button>
        }
      </DialogActions>
    </Dialog>
  );
};

export default EditAddress;
