export const HoldOrderTemplate = {
  startDate: {
    title: 'Hold Start Date',
    required: true,
    isValid: false,
    value: null,
    isDirty: false,
    time: true
  },
  endDate: {
    title: 'Hold End Date',
    required: true,
    isValid: true,
    value: null,
    isDirty: false,
    time: true
  },
  comment: {
    title: 'Comments',
    required: false,
    isValid: true,
    value: null,
    multiline: true,
    multilineRows: 5
  },
  createdBy: {
    title: "Create By",
    required: true,
    value: null,
    dropDown: true,
    dropDownItemValue: "_id",
    dropDownItemLabel: "name"
  }
}
