import React from 'react';
import {Document, Page, View, Text, StyleSheet} from "@react-pdf/renderer";
import HeaderWithTenant from "../../../../reports/pdf/components/headerwithtenant";
import ResidentCard from "../../../../reports/pdf/components/residentCard";
import moment from "moment-timezone";
import {getUniqueEventTemplates} from "../../../../utils/table";
import {getAbbreviationFromName} from "../../utils";
import {StatusLegend} from "../../../../reports/pdf/components/StatusLegend";
import {defaultStatuses} from '../../../../reports/pdf/components/defaultStatuses';


const BORDER_COLOR = '#bfbfbf'
const BORDER_STYLE = 'solid'

const styles = StyleSheet.create({
  page: {
    padding: '0 0 84px 0'
  },
  headerSection: {
    backgroundColor: '#2196f3',
    color: '#fff',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '10vh'
  },
  mainContent: {
    backgroundColor: 'white',
    padding: '0 5px'
  },
  body: {
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    width: '100%',
    margin: "auto",
    flexDirection: "row"
  },
  tableCol1Header: {
    width: '125px',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol2Header: {
    width: '55px',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableColHeader: {
    width: '20px',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol1: {
    width: '125px',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  hrContainer: {
    paddingTop: '10px',
    width: '55px',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0

  },
  tableCol2: {
    width: '55px',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol: {
    width: '20px',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: 500
  },
  tableCell: {
    margin: 5,
    fontSize: 10
  },
  titleContainer: {
    paddingTop: '15px'
  },
  pageCount: {
    paddingLeft: '5px',
    paddingTop: '15px'
  },
  headerText: {
    fontSize: '20px'
  },
  bodyText: {
    fontSize: '8px'
  },
  centerAlign: {
    textAlign: 'center'
  },
  infoSection: {
    margin: '10 0'
  },
  infoText: {
    fontSize: '12px',
    padding: '3 0'
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    padding: '5px'
  },
  footerContainer: {
    marginTop: '5px',
    fontSize: '8px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  marContainer: {
    padding: '5px'
  },
  marHeaderText: {

  },
  dosageInsructionContainer: {
    paddingTop: '5px'
  },
  hrText: {
    minHeight: '25px',
    textAlign: 'center',
  },
  marValueCell: {
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0
  },
  discontinuedDate: {
    width: '100%',
    padding: '5px',
    backgroundColor: "#9e9e9e",
    color: '#fff'
  },
  alerts: {
    width: '100%',
    padding: '5px',
    backgroundColor: "#e57373",
    color: '#fff'
  },
  hideOverflow: {
    textOverflow: "ellipsis"
  },
  freqContainer: {
    minHeight: '10px',
    textAlign: 'center',
  },
  paddedTableCol: {
    paddingTop: '10px',
    width: '20px',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  taskValueEveryX: {
    fontSize: '8px',
    height: '35px'
  },
  taskValue: {
    fontSize: '8px',
    height: '25px'
  }
});

export const CareplanTaskReport = ({days, resident, year, month, etasks, etaskEvents, customStatus = []}) => {

  const getPrnEvents = (etask, day) => {
    return etaskEvents?.filter(event =>
        etask?._id === event?.intervention &&
        moment.tz(event.scheduledTime, event?.timeZone ?? "US/Central").date() === +day
    );
  }

  const getPrnValue = (etask, day) => {
    const events = getPrnEvents(etask, day);
    return extractValue(events);
  }

  const getStatus = status => {
    const abbreviation = getAbbreviationFromName(status, customStatus)
    if (abbreviation) {
      return abbreviation;
    }

    switch (status) {
      case "Scheduled":
        return " ";
      case "Given":
        return "G";
      case "Missed":
        return "M";
      case "Refused":
        return "R";
      case "Hold":
        return "H";
      case "Refused & Destructed":
      case "RD":
        return "RD";
      case "Self Administered":
      case "SA":
        return 'SA';
      case "Independent":
        return "I";
      case "Verbal prompt":
        return "VP";
      case "Physical Assist":
        return "PA";
      case "Minimal Assist":
        return "MA";
      case "Not Administered":
      case "NA":
        return "NA";
      case "Late":
        return "L"
      default:
        return "X";
    }
  }

  const getValue = (etask, eventTemplate, day) => {
    const everyXHourFrequencys = [ "QH", "Q2H", "Q3H", "Q4H", "Q6H", "Q8H","Q12H" ];
    const frequencyName = etask?.frequency?.name;
    let events = [];
    let isEveryXHoursFrequency = everyXHourFrequencys?.indexOf(frequencyName) > -1
    if (isEveryXHoursFrequency) {
      events = etaskEvents?.filter(event =>
          etask?._id === event?.intervention &&
          moment.tz(event.scheduledTime, event?.timeZone ?? "US/Central").date() === +day
      );
    } else {
      events = etaskEvents?.filter(event =>
          etask?._id === event?.intervention &&
          moment.tz(event.scheduledTime, event?.timeZone ?? "US/Central").date() === +day &&
          moment.tz(event.scheduledTime, event?.timeZone ?? "US/Central").hour() === +eventTemplate.hour &&
          moment.tz(event.scheduledTime, event?.timeZone ?? "US/Central").minute() === +eventTemplate.minute
      );
    }

    if (events?.length > 0) {
      return events?.map(event =>
          <View style={isEveryXHoursFrequency ? styles.taskValueEveryX: styles.taskValue}>
            <View style={{width: "100%"}}>
              <Text>
                {getStatus(event?.status)}
              </Text>
            </View>
            {isEveryXHoursFrequency &&
                <View style={{width: "100%"}}>
                  <Text>
                    {moment(event?.scheduledTime).format("H:mm")}
                  </Text>
                </View>
            }
            {event?.administeredUserInitials &&
                <View style={{width: "100%"}}>
                  <Text>
                    {event?.administeredUserInitials}
                  </Text>
                </View>
            }
          </View>)
    } else {
      return (
          <View style={styles.taskValue}>
            <Text>X</Text>
          </View>
      )
    }
  }

  const extractValue = (events) => {
    if (events?.length === 1) {
      const event = events[0];
      if (event?.status === "Given" && event?.administeredUserInitials) {
        return (
            <View>
              <Text>{`G\n${event?.administeredUserInitials}`}</Text>
            </View>
        )
      } else if (event?.status === "Refused") {
        return (
            <View>
              <View>
                <Text>{`R\n${event?.administeredUserInitials}`}</Text>
              </View>
            </View>
        )
      } else if (event?.status === "Scheduled") {
        return " "
      } else if (event?.status === "Late") {
        return "L"
      } else if (event?.status === "Missed") {
        return "M"
      } else if (event?.status === "NA") {
        return (
            <View>
              <Text>{`NA\n${event?.administeredUserInitials}`}</Text>
            </View>
        )
      } else if (event?.status === "SA") {
        return (
            <View>
              <Text>{`SA\n${event?.administeredUserInitials}`}</Text>
            </View>
        )
      } else if (event?.status === "Independent") {
        return (
            <View>
              <Text>{`I\n${event?.administeredUserInitials}`}</Text>
            </View>
        )
      } else if (event?.status === "Verbal prompt") {
        return (
            <View>
              <Text>{`VP\n${event?.administeredUserInitials}`}</Text>
            </View>
        )
      } else if (event?.status === "Physical Assist") {
        return (
            <View>
              <Text>{`PA\n${event?.administeredUserInitials}`}</Text>
            </View>
        )
      } else if (event?.status === "Minimal Assist") {
        return (
            <View>
              <Text>{`MA\n${event?.administeredUserInitials}`}</Text>
            </View>
        )
      } else {
        return (
            <View>
              <Text>{`${event?.status[0]}\n${event?.administeredUserInitials}`}</Text>
            </View>
        )
      }
    }
    if (events?.length > 1) {
      return (
          <View>
            <Text>{`${events[0]?.status[0]}\n${events[0]?.status[0]?.administeredUserInitials}`}</Text>
          </View>
      )
    } else {
      return "X"
    }
  }

  const getEventTemplateDisplayForTemplate = (frequency, template) => {
    switch (frequency) {
      case "QH":
        return "Every Hour";
      case "Q2H":
        return "Every 2 Hours";
      case "Q3H":
        return "Every 3 Hours";
      case "Q4H":
        return "Every 4 Hours";
      case "Q6H":
        return "Every 6 Hours";
      case "Q8H":
        return "Every 8 Hours";
      case "Q12H":
        return "Every 12 Hours";
      default:
        return `${template?.hour}:${template?.minute}`;
    }
  }

  return (
      <Document size={"a4"} orientation={"landscape"} >
        <Page size={"A4"} orientation={'landscape'} style={styles.page}>
          <HeaderWithTenant
              reportTitle={`Careplan Tasks`}
              reportSubtitle={`${resident?.firstName} ${resident?.lastName}\n${year} - ${moment({month: month - 1}).format("MMMM")}`}
              location={resident?.location}
              tenant={resident?.location?.tenant}
              allUserLocations={false}
          />
          <View style={styles.mainContent}>
            <StatusLegend statuses={customStatus?.length > 0 ? customStatus: defaultStatuses} />
            <View style={styles.table}>
              <View style={styles.tableRow} fixed>
                <View style={styles.tableCol1Header}>
                  <Text style={styles.tableCellHeader}>Task</Text>
                </View>
                <View style={styles.tableCol2Header}>
                  <Text style={[styles.tableCellHeader, styles.centerAlign]}>HR</Text>
                </View>
                {[...Array(days)].map((e, i) => (
                    <View key={i} style={styles.tableColHeader}>
                      <Text style={[styles.tableCellHeader, styles.bodyText, styles.centerAlign]}>{i + 1}</Text>
                    </View>
                ))}
              </View>
              {etasks?.filter(etask => !etask?.isPrn)?.map(etask =>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol1} fixed>
                      <Text style={[styles.tableCell, styles.bodyText, styles.hideOverflow]}>
                        {etask.name}
                      </Text>
                    </View>
                    <View style={styles.hrContainer}>
                      {getUniqueEventTemplates(etask?.eventTemplates)?.map((template, i) =>
                          <View key={i} style={styles.hrText}>
                            <Text style={[styles.bodyText]}>
                              {getEventTemplateDisplayForTemplate(etask?.frequency?.name, template)}
                            </Text>
                          </View>
                      )}
                    </View>

                    {[...Array(days)].map((e, day) => (
                        <View  style={styles.paddedTableCol}>
                          {getUniqueEventTemplates(etask?.eventTemplates)?.map((eventTemplate, i) =>
                              <View style={styles.hrText}>
                                {getValue(etask, eventTemplate, day + 1)}
                              </View>
                          )}
                        </View>
                    ))}
                  </View>
              )}
              {etasks?.filter(etask => etask?.isPrn)?.length > 0 &&
                  <View>
                    {/* eslint-disable-next-line no-sequences */}
                    <View style={[styles.marContainer, { borderBottom: '1px solid black'}]}>
                      <Text style={styles.tableCellHeader}>PRN</Text>
                    </View>
                  </View>
              }
              {etasks?.filter(etask => etask?.isPrn)?.map(etask =>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol1} fixed>
                      <Text style={[styles.tableCell, styles.bodyText, styles.hideOverflow]}>
                        {etask.name}
                      </Text>
                    </View>
                    <View style={styles.hrContainer}>
                      <View style={styles.hrText}>
                        <Text style={[styles.bodyText]}>NA</Text>
                      </View>
                    </View>
                    {[...Array(days)].map((e, day) => (
                        <View  style={styles.paddedTableCol}>
                          <View style={styles.hrText}>
                            <Text style={styles.bodyText}>{getPrnValue(etask, day + 1)}</Text>
                          </View>
                        </View>
                    ))}
                  </View>
              )}
            </View>
          </View>
          <View style={styles.footer} fixed>
            <View>
              <ResidentCard resident={resident} />
            </View>
            <View style={styles.footerContainer}>
              <View>
                <Text>
                  {moment().format("MM/DD/YYYY hh:mm a")}
                </Text>
              </View>
              <View>
                <Text render={({ pageNumber, totalPages }) => (
                    `page ${pageNumber} of ${totalPages}`
                )} fixed />
              </View>
            </View>
          </View>
        </Page>
      </Document>
  )
}
