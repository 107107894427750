import React from 'react';
import {CircularProgress, makeStyles, Menu, MenuItem, Typography, IconButton} from "@material-ui/core";
import {AssignmentLateRounded, CheckCircle} from "@material-ui/icons";
import ScheduleIcon from "@material-ui/icons/Schedule";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {updateResidentETar} from "../../../../../services/treatment.service";
import moment from "moment";
import {ETarUserSelect} from "./ETarUserSelect";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        minHeight: '5vh',
    },
    tableDayColumn: {
        minWidth: "50px",
        maxWidth: "50px",
        textAlign: "center",
        borderLeft: "1px solid gray",
        borderRight: "1px solid gray",
    },
    legendContainer: {
        padding: theme.spacing(1)
    },
    legendItem: {
        display: 'flex',
        padding: theme.spacing(0, .1)
    },
    legendValue: {
        margin: theme.spacing(0, .2)
    },
    late: {
        color: theme.palette.warning.main
    },
    eTarTemplateContainer: {
        height: 45
    },
    eTarButton: {
        cursor: 'pointer',
        padding: 0
    },
    menuLegendItem: {
        padding: 0,
        margin: theme.spacing(0, 1)
    },
    bottomBorder: {
      marginBottom: theme.spacing(1)
    }
}));


const ITEM_HEIGHT = 48;

export const ETarLogItem = ({residentId, treatment, eTar, onUpdated, users, isEveryXHoursFrequency = false}) => {
    const classes = useStyles();
    const [updating, setUpdating] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleToggleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const updateStatus = async status => {
        setUpdating(true);
        setAnchorEl(null);
        const obj = {...eTar, ...{status: {name: status}}};
        const response = await updateResidentETar(residentId, treatment?._id, eTar?._id, obj);
        onUpdated({_id: eTar?._id, payload: response});
        setUpdating(false);
    }

    const updateUser = async userId => {
        setUpdating(true);
        const obj = {...eTar, ...{updateUser: {_id: userId}}};
        const response = await updateResidentETar(residentId, treatment?._id, eTar?._id, obj);
        onUpdated({_id: eTar?._id, payload: response});
        setUpdating(false);

    }

    const canEdit = () => {
        return moment() >= moment(eTar?.scheduledTime)
    }

    const getLabel = () => {
        if (eTar?.status?.name === "Given" && eTar?.administeredUser) {
            return (
                <div style={{textAlign: "center"}}>
                    <CheckCircle color={"secondary"} className={classes.legendItem}/>
                    <ETarUserSelect user={eTar?.administeredUser}
                                    users={users}
                                    eTar={eTar}
                                    onChange={updateUser}
                    />
                </div>
            )
        } else if (eTar?.status?.name === "Refused") {
            return (
                <div style={{textAlign: "center"}}>
                    <div>R</div>
                    <ETarUserSelect user={eTar?.administeredUser}
                                    users={users}
                                    eTar={eTar}
                                    onChange={updateUser}
                    />
                </div>
            )
        } else if (eTar?.status?.name === "Scheduled") {
            return <ScheduleIcon className={classes.legendItem}/>
        } else if (eTar?.status?.name === "Late") {
            return <AssignmentLateRounded className={[classes.legendItem, classes.late]}/>
        } else if (eTar?.status?.name === "Missed") {
            return <FiberManualRecordIcon color={"error"} className={classes.legendItem} />
        } else if (eTar?.status?.name === "NA") {
            return (
                <div style={{textAlign: "center"}}>
                    <div>NA</div>
                    <ETarUserSelect user={eTar?.administeredUser}
                                    users={users}
                                    eTar={eTar}
                                    onChange={updateUser}
                    />
                </div>
            )
        } else if (eTar?.status?.name === "SA") {
            return (
                <div style={{textAlign: "center"}}>
                    <div>SA</div>
                    <ETarUserSelect user={eTar?.administeredUser}
                                    users={users}
                                    eTar={eTar}
                                    onChange={updateUser}
                    />
                </div>
            )
        }
        return (
            <div style={{textAlign: "center"}}>
                <div>{eTar?.status?.name}</div>
                <ETarUserSelect user={eTar?.administeredUser}
                                users={users}
                                eTar={eTar}
                                onChange={updateUser}
                />
            </div>
        )
    }

    if (updating) {
        return (
            <CircularProgress size={35}/>
        )
    }

    if (!updating) {
        return (
            <div className={isEveryXHoursFrequency ? classes.bottomBorder: null}>
                {isEveryXHoursFrequency &&
                    <Typography style={{fontSize: 10}} variant={"subtitle2"}>
                        {moment(eTar?.scheduledTime)?.format("H:mm")}
                    </Typography>
                }
                <div className={classes.root}>
                    {getLabel()}
                    {canEdit() &&
                        <IconButton size={"small"}
                                    className={classes.eTarButton}
                                    onClick={handleToggleMenu}
                        >
                            <MoreVertIcon/>
                        </IconButton>
                    }
                    <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '20ch',
                            },
                        }}
                    >
                        <MenuItem key={'Scheduled'}
                                  selected={eTar?.status?.name === "Scheduled"}
                                  onClick={() => updateStatus("Scheduled")}>
                            <ScheduleIcon className={classes.menuLegendItem}/> {'Scheduled'}
                        </MenuItem>
                        <MenuItem key={'Given'}
                                  selected={eTar?.status?.name === "Given"}
                                  onClick={() => updateStatus("Given")}>
                            <CheckCircle className={classes.menuLegendItem} color={"secondary"}/> {'Given'}
                        </MenuItem>
                        <MenuItem key={'Late'}
                                  selected={eTar?.status?.name === "Late"}
                                  onClick={() => updateStatus("Late")}>
                            <AssignmentLateRounded className={[classes.menuLegendItem, classes.late]}/> {'Late'}
                        </MenuItem>
                        <MenuItem key={'Missed'}
                                  selected={eTar?.status?.name === "Missed"}
                                  onClick={() => updateStatus("Missed")}>
                            <FiberManualRecordIcon color={"error"} className={classes.menuLegendItem}/> {'Missed'}
                        </MenuItem>
                        <MenuItem key={'Hold'}
                                  selected={eTar?.status?.name === "Hold"}
                                  onClick={() => updateStatus("Hold")}>
                            {'Hold'}
                        </MenuItem>
                        <MenuItem key={'SA'}
                                  selected={eTar?.status?.name === "SA"}
                                  onClick={() => updateStatus("SA")}>
                            {'Self Administered'}
                        </MenuItem>
                        <MenuItem key={'NA'}
                                  selected={eTar?.status?.name === "NA"}
                                  onClick={() => updateStatus("NA")}>
                            {'Not Administered'}
                        </MenuItem>
                        <MenuItem key={'Refused'}
                                  selected={eTar?.status?.name === "Refused"}
                                  onClick={() => updateStatus("Refused")}>
                            {'Refused'}
                        </MenuItem>
                    </Menu>
                </div>
            </div>
        )
    }
}
