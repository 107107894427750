import React from 'react';
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControlLabel,
  Checkbox, Box, makeStyles, CircularProgress, Button, Chip,
} from "@material-ui/core";
import filesize from 'filesize';
import {ResidentFileContext} from "../context/ResidentFilesContext";
import {CONSTANTS} from "../context/Constants";
import {
  getFolderChildrenFilesAction,
  onFileDownloadAction, onFileMoveAction,
  selectFileAction
} from "../context/Actions";
import moment from "moment";
import {Folder, Description} from "@material-ui/icons";
import {yellow, grey, green} from "@material-ui/core/colors";
import {GetApp} from "@material-ui/icons/";
import useHasRole from "../../../../hooks/user.role.hook";
import {RowFileMenu} from "./RowFileMenu";
import TableLoadingIndicator from "../../../../utils/indicators/table.loading";
import {RowFolderMenu} from "./RowFolderMenu";

const useStyles = makeStyles((theme) => ({
  folder: {
    color: yellow[800],
    marginRight: theme.spacing(1)
  },
  file: {
    color: grey[600],
    marginRight: theme.spacing(1)
  }
}))


export const ResidentFilesTable = () => {
  const {state, dispatch} = React.useContext(ResidentFileContext);
  const [allChecked, setAllChecked] = React.useState(false);
  const classes = useStyles();
  const { checkPermission } = useHasRole();
  const canDownLoad = checkPermission("RESIDENT_FILE_DOWNLOAD");

  React.useEffect(() => {
    const val = state?.selectedFiles?.filter(f => f?.checked);
    if (state?.selectedFiles?.length > 0 && val?.length === state?.selectedFiles?.length) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
  }, [state?.selectedFiles]);

  const handleRowChecked = e => {
    const files = state?.selectedFiles?.map(f => {
      if (f?._id === e.target.name) {
        f.checked = !f.checked;
      }
      return f;
    });
    dispatch({type: CONSTANTS.SET_SELECTED_FILES, payload: files});
  }

  const handleRowSelected = (event, file) => {
    dispatch({type: CONSTANTS.SET_ALL_SELECTED_FILES_CHECKED, payload: false});
    event.preventDefault();
    if (file?.type === "folder") {
      getFolderChildrenFilesAction(dispatch, file, state?.files);
    } else {
      selectFileAction(dispatch, file, state?.files);
    }
  }

  const handleAllRowsChecked = () => {
    const newAllCheckedValue = !allChecked;
    setAllChecked(newAllCheckedValue);
    const obj = state?.selectedFiles?.map(f => {
      f.checked = newAllCheckedValue;
      return f;
    });
    dispatch({type: CONSTANTS.SET_SELECTED_FILES, payload: obj});
  }

  const handleDownloadClick = async (file) => {
    await onFileDownloadAction(state?.residentId, file?._id);
  }

  const onDrag = (file) => {
    dispatch({type: CONSTANTS.SET_DRAG_FILE, payload: file});
  }

  const onDragOverEvent = (e, file) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch({type: CONSTANTS.SET_DRAG_OVER_FILE, payload: file});
  }

  const onDrop = async (e) => {
    if (state?.dragOverFile?._id !== state?.dragFile?._id) {
      await onFileMoveAction(dispatch, state?.residentId, state?.dragFile, state?.dragOverFile?._id);
      dispatch({type: CONSTANTS.SET_DRAG_FILE, payload: null});
      dispatch({type: CONSTANTS.SET_DRAG_OVER_FILE, payload: null});
    }
  }

  const onDragEnd = () => {
    dispatch({type: CONSTANTS.SET_DRAG_FILE, payload: null});
    dispatch({type: CONSTANTS.SET_DRAG_OVER_FILE, payload: null});
  }


  const getIsDroppable = (file) => {
    return file?.type !== "file" &&
        file?._id === state?.dragOverFile?._id && state?.dragFile?._id !== state?.dragOverFile?._id;
  }

  const getRowStyle = (file) => {
    return {
      backgroundColor: getIsDroppable(file) ? green[100]: '#fff'
    }
  }

  return (
    <TableContainer>
      <Table size={"small"}>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormControlLabel
                control={<Checkbox checked={allChecked}
                                   onChange={handleAllRowsChecked}
                                   name={"allChecked"} />
                }
                label={
                  <Link style={{cursor: "pointer"}}>

                  </Link>
                }
              />
              Name
            </TableCell>
            <TableCell>
              Size
            </TableCell>
            <TableCell>
              Type
            </TableCell>
            <TableCell width={250} />
            <TableCell>
              Created
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        {state?.loading && <TableLoadingIndicator bodyOnly={true} cols={5} rows={5} />}
        <TableBody>
          {!state?.loading && state?.selectedFiles?.map((file, index) =>
          <TableRow key={file?._id}
                    style={getRowStyle(file)}>
            <TableCell width={350}
                       draggable={file?.type !== "folder"}
                       onDrag={() => onDrag(file)}
                       onDragOver={(e) => onDragOverEvent(e, file)}
                       onDrop={onDrop}
                       onDragEnd={onDragEnd}>
              <FormControlLabel
              control={
                file?.checked && state?.deletingFiles ?
                  <CircularProgress size={20} color={"primary"} /> :
                  <Checkbox checked={file?.checked || ''} onChange={handleRowChecked} name={file?._id} />
              }
              label={
                <Link style={{cursor: "pointer"}}
                      onClick={(event) => handleRowSelected(event, file)}
                >
                  <Box display={"flex"} alignItems={"center"}>
                    {file?.type !== "folder" ?
                      <Description fontSize={"small"} className={classes.file} />:
                      <Folder fontSize={"small"} className={classes.folder} />
                    }
                    {file?.label}
                  </Box>
                </Link>
              }
            />
            </TableCell>
            <TableCell>
              {file?.type === "file" && filesize(file?.size)}
            </TableCell>
            <TableCell>
              {file?.fileType}
            </TableCell>
            <TableCell>
              {file?.tags?.map(t => <Chip label={t} style={{marginLeft: ".25rem"}} />)}
            </TableCell>
            <TableCell>
              {moment(file?.createdAt).format("M/D/YYYY h:mm a")}
            </TableCell>
            <TableCell width={100}>
              <Box width={"100%"}
                   display={"flex"}
                   alignItems={"center"}
                   justifyContent={"flex-end"}
              >
                {canDownLoad && file?.type === "file" &&
                  <Button startIcon={<GetApp />} onClick={() => handleDownloadClick(file)}></Button>
                }
                {canDownLoad && file?.type === "file" && <RowFileMenu file={file} />}
                {file?.type !== "file" && <RowFolderMenu folder={file} />}
              </Box>
            </TableCell>
          </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
