import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core'
import Preview from "./preview";

const AssessmentPreview = ({ open, assessmentEntity, created, completed, assessment, onClose }) => {
    const [model, setModel] = React.useState(null);
    React.useEffect(() => {
        if (open) {
            if (assessment && assessment.questionGroups) {
                setModel(assessment);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <Dialog fullWidth="true" maxWidth="md" open={open} onClose={onClose}>
            <DialogTitle>Assessment Preview</DialogTitle>
            <DialogContent dividers>
                <Preview assessmentInstance={model} assessmentEntity={assessmentEntity} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AssessmentPreview
