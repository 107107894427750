import React from 'react';
import {
  ResidentInsurancesProvider,
  ResidentInsurancesContext,
} from './resident.insurance.context';
import {
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Typography,
  TableContainer,
  Button,
  Link,
} from '@material-ui/core';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import RadioButtonUncheckedRoundedIcon from '@material-ui/icons/RadioButtonUncheckedRounded';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import AddIcon from '@material-ui/icons/Add';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  updateResidentInsurance,
  deleteResidentInsurance,
  openInsuranceLookupDialog,
  closeInsuranceLookupDialog,
  createResidentInsurance,
} from './resident.insurance.actions';
import InsuranceLookup from '../../insurances/insurance.lookup';
import { Link as RouterLink } from 'react-router-dom';
import ResidentInsurance from './resident.insurance';
import useHasRole from '../../../hooks/user.role.hook';
import {GlobalNotificationContext} from "../../notification/globalnotificationprovider";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  table: {
    width: '100%',
  },
  iconButton: {
    padding: 0,
    margin: 0,
  },
  actionIcon: {
    height: '1.5rem',
    width: '1.5rem',
  },
}));

const ResidentInsurancesTable = () => {
  const classes = useStyles();
  const { addNotification } = React.useContext(GlobalNotificationContext);
  const [viewResidentInsurance, setViewResidentInsurance] = React.useState(
    false
  );
  const [
    selectedResidentInsurance,
    setSelectedResidentInsurance,
  ] = React.useState({});
  const { state, dispatch, residentId, token } = React.useContext(
    ResidentInsurancesContext
  );

  React.useEffect(() => {
    if (state?.error && state?.errorMessage) {
      addNotification(state?.errorMessage);
    }
    // eslint-disable-next-line
  }, [state?.error, state?.errorMessage]);

  const onDeleteResidentInsurance = (residentInsurance) => {
    deleteResidentInsurance(
      dispatch,
      residentId,
      residentInsurance._id
    );
  };

  const toggleIsPrimary = async (residentInsurance) => {
    const data = {
      insuranceId: residentInsurance.insurance._id,
      isPrimary: !residentInsurance.isPrimary,
      memberNumber: residentInsurance.memberNumber,
      groupNumber: residentInsurance.groupNumber,
      comment: residentInsurance.comment,
    };
    await updateResidentInsurance(
      dispatch,
      residentId,
      residentInsurance?._id,
      data
    );
  };

  const addInsurance = (insurance) => {
    let isPrimary = true;
    if (state?.residentInsurances?.length > 0) {
      isPrimary = false;
    }
    createResidentInsurance(dispatch, residentId, insurance._id, isPrimary);
  };

  const selectInsurance = (residentInsurance) => {
    setSelectedResidentInsurance(residentInsurance);
    setViewResidentInsurance(true);
  };

  const updateInsurance = async (data) => {
    await updateResidentInsurance(dispatch, residentId, data?._id, data);
    setViewResidentInsurance(false);
  };

  const { checkPermission } = useHasRole();
  const canEdit = checkPermission('RESIDENT_UPDATE');

  return (
    <div className={classes.root}>
      <div hidden={!state.loading}>
        <Skeleton variant="text" height={40} />
        <Skeleton variant="text" height={40} />
        <Skeleton variant="text" height={40} />
        <Skeleton variant="text" height={40} />
      </div>
      <div hidden={state.loading}>
        <Typography variant="subtitle1">Insurances</Typography>
        {canEdit &&
          <>
        <Button
          onClick={() => dispatch(openInsuranceLookupDialog())}
          startIcon={<AddIcon />}
        >
          Add Insurance
        </Button>
        </>}
        <InsuranceLookup
          open={state.openInsuranceDialog}
          token={token}
          onClose={() => dispatch(closeInsuranceLookupDialog())}
          onSelect={addInsurance}
        />
        <TableContainer>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell align="left" width={200} />
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Member Id</TableCell>
                <TableCell align="left">Group Number</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state?.residentInsurances?.map((residentInsurance) => (
                  <TableRow>
                    <TableCell align="left">
                      <IconButton
                        className={classes.iconButton}
                        onClick={() => selectInsurance(residentInsurance)}
                      >
                        <VisibilityRoundedIcon className={classes.actionIcon} />
                      </IconButton>
                      {canEdit && <>
                      <IconButton
                        className={classes.iconButton}
                        aria-label="delete"
                        onClick={() =>
                          onDeleteResidentInsurance(residentInsurance)
                        }
                      >
                        <DeleteRoundedIcon
                          className={classes.actionIcon}
                          color="error"
                        />
                      </IconButton>
                      <IconButton
                        className={classes.iconButton}
                        aria-label="Toggle primary"
                        onClick={() => toggleIsPrimary(residentInsurance)}
                      >
                        {residentInsurance.isPrimary ? (
                          <CheckCircleRoundedIcon
                            className={classes.actionIcon}
                            color="secondary"
                          />
                        ) : (
                          <RadioButtonUncheckedRoundedIcon />
                        )}
                        <Typography variant="subtitle2">Primary</Typography>
                      </IconButton>
                      </>}
                    </TableCell>
                    <TableCell align="left">
                      <Link
                        component={RouterLink}
                        to={`/insurances/${residentInsurance.insurance._id}`}
                      >
                        {residentInsurance.insurance.name}
                      </Link>
                    </TableCell>
                    <TableCell>{residentInsurance.memberNumber}</TableCell>
                    <TableCell>{residentInsurance.groupNumber}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <ResidentInsurance
        open={viewResidentInsurance}
        residentInsurance={selectedResidentInsurance}
        onClose={() => setViewResidentInsurance(false)}
        onSave={updateInsurance}
        canEdit={canEdit}
      />
    </div>
  );
};

const ResidentInsurances = ({ residentId }) => {
  return (
    <ResidentInsurancesProvider residentId={residentId}>
      <ResidentInsurancesTable />
    </ResidentInsurancesProvider>
  );
};

export default ResidentInsurances;
