import apiUtil from '../utils/api.utils';
import config from '../utils/configs';
import {parseFiltersToJson} from "../utils/filter.utils";
import configs from "../utils/configs";

const baseUrl = `${config.residentService}/api/v1.0/diagnosis`;


export const getDiagnosis = async (
  page = 1,
  size = 25,
  sort = 'id',
  sortDirection = 'asc',
  filters = [],
) => {
  try {
    let url = `${baseUrl}?page=${page}&size=${size}`;
    url += parseFiltersToJson(filters);
    let sortArg = sortDirection === 'asc' ? '+' : '-';
    sortArg += sort;
    url += `&sort=${sortArg}`;
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (error) {
    console.log(error);
    alert(error);
    return {};
  }
};

export const fuzzySearch = async (term) => {
  try {
    const response = await apiUtil.client.get(`${baseUrl}/fuzzy?term=${term}`);
    return response.data;
  } catch (error) {
    console.log(error);
    alert(error);
    return {};
  }
};

export const getDiagnosisById = async (id) => {
  try {
    const response = await apiUtil.client.get(`${baseUrl}/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
    alert(error);
    return {};
  }
};


export const updateDiagnosis = async (id, diagnosis) => {
  try {
    const response = await apiUtil.client.put(`${baseUrl}/${id}`, diagnosis);
    return response.data;
  } catch (error) {
    console.log(error);
    alert(error);
    return {};
  }
};

export const createDiagnosis = async (diagnosis) => {
  try {
    const response = await apiUtil.client.post(baseUrl, diagnosis);
    return response.data;
  } catch (error) {
    console.log(error);
    alert(error);
    return {};
  }
};

export const deleteDiagnosis = async (id) => {
  try {
    const response = await apiUtil.client.delete(`${baseUrl}/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
    alert(error);
    return {};
  }
};

export const createResidentDiagnosis = async (residentId, residentDiagnosis) => {
  try {
    const response = await apiUtil.client.post(
      `${configs.residentService}/api/v1.0/residents/${residentId}/residentdiagnosis`,
      residentDiagnosis
    );
    return response.data;
  } catch (error) {
    console.log(error);
    alert(error);
    return {};
  }
}

export const updateResidentDiagnosis = async (residentId, residentDiagnosisId, residentDiagnosis) => {
  try {
    const response = await apiUtil.client.put(
      `${configs.residentService}/api/v1.0/residents/${residentId}/residentdiagnosis/${residentDiagnosisId}`,
      residentDiagnosis
    );
    return response.data;
  } catch (error) {
    console.log(error);
    alert(error);
    return {};
  }
}

export const deleteResidentDiagnosis = async (residentId, residentDiagnosisId) => {
  try {
    const response = await apiUtil.client.delete(
      `${configs.residentService}/api/v1.0/residents/${residentId}/residentdiagnosis/${residentDiagnosisId}`);
    return response.data;
  } catch (error) {
    console.log(error);
    alert(error);
    return {};
  }
}
