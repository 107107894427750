import React from 'react';
import {Alert} from "@material-ui/lab";
import {Button} from "@material-ui/core";
import {RotateLeftTwoTone} from "@material-ui/icons";

export const RetryMessage = ({onRetry, severity = null,  message = null,  ...p}) => {
  return (
    <Alert severity={severity ?? "warning"}
           action={<Button onClick={onRetry}
                           startIcon={<RotateLeftTwoTone />}>Retry</Button>
           }
    >
      {message ?? 'Unable to load data. Press retry to reload.'}
    </Alert>
  )
}
