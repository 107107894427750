import React from 'react';
import * as _ from 'lodash';
import { View, Text, StyleSheet } from '@react-pdf/renderer';


const styles = StyleSheet.create({
  root: {
    width: '100%',
    backgroundColor: '#fff',
  },
  container: {
    display: 'flex',
    flexDirection: 'row'
  },
  chunkContainer: {
    padding: '5px',
    border: '1px solid #bfbfbf'
  },
  headerText: {
    fontSize: '10px'
  },
  legendItem: {
    fontSize: '8px',
    width: '100px'
  }
});


export const StatusLegend = ({statuses}) => {
  console.log("Status Lengend", statuses)


  if (Array.isArray(statuses)) {
    let chunks = _.chunk(statuses, 8);
    return (
      <View style={styles.root}>
        <Text style={styles.headerText}>Status Legend</Text>
        <View style={styles.chunkContainer}>
          {chunks?.map((chunk, i) =>
            <View key={i} style={styles.container}>
              {chunk?.map((status, index) =>
                <View key={index} style={styles.legendItem}>
                  <Text>{status?.name} : {status.abbreviation}</Text>
                </View>)
              }
            </View>
          )}
        </View>
      </View>
    )
  } else {
    return null;
  }
}
