import React from 'react';
import {
  Button,
  Chip, DialogActions,
  FormControl, FormGroup, Grid, IconButton, InputLabel,
  makeStyles, MenuItem,
  Paper, Select,
  Step,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
  Typography
} from "@material-ui/core";
import {ResidentContext} from "../../../context/residentcontext";
import moment from "moment";
import ResidentAdmissions from "./resident.admissions";
import EditIcon from "@material-ui/icons/Edit";
import {getResidentStatuses, updateResident} from "../../../services/resident.service";
import {Alert} from "@material-ui/lab";



const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(.5, 0),
    padding: theme.spacing(1)
  },
  currentStatusContainer: {
    padding: theme.spacing(1)
  },
  admitted: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    }
  },
  readmitted: {
    backgroundColor: theme.palette.secondary.light,
    color: '#fff',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    }
  },
  discharged: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    }
  },
  transfered: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.contrastText,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    }
  },
  loa: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.contrastText,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    }
  }
}));


function AdmittedStepperComponent(props) {
  const classes = useStyles();
  return (
    <Chip size={"small"}
          className={classes.admitted}
          label={"Admitted"} />
  )
}
function ReadmittedStepperComponent(props) {
  const classes = useStyles();
  return (
    <Chip size={"small"}
          className={classes.readmitted}
          label={"Readmitted"} />
  )
}
function DischargedStepperComponent(props) {
  const classes = useStyles();
  return (
    <Chip size={"small"}
          className={classes.discharged}
          label={"Discharged"} />
  )
}
function TransferedStepperComponent(props) {
  const classes = useStyles();
  return (
    <Chip size={"small"}
          className={classes.transfered}
          label={"Transfered"} />
  )
}
function LoaStepperComponent(props) {
  const classes = useStyles();
  return (
    <Chip size={"small"}
          className={classes.loa}
          label={"LOA"} />
  )
}
function CurrentStepperComponent(props) {
  return (
    <Chip size={"small"}
          label={"Current"} />
  )
}

const ResidentAdmissionsPage = () => {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = React.useState(0);
  const [interactions, setInteractions] = React.useState([]);
  const [editMode, setEditMode] = React.useState(false);
  const { resident, refresh } = React.useContext(ResidentContext);
  const [residentStatuses, setResidentStatuses] = React.useState([]);
  const [editResident, setEditResident] = React.useState();

  React.useEffect(() => {
    const populate = async () => {
      const statuses = await getResidentStatuses();
      setResidentStatuses(statuses);
    }
    populate();
  },[])

  React.useEffect(() => {
    if (resident?.admissions?.length === 1 && resident?.admissions[0].type === 'Admission') {
      setInteractions([...resident.admissions, { type: 'Current', date: Date.now()}]);
    } else {
      setInteractions(resident?.admissions)
    }
    if (resident) {
      setEditResident(JSON.parse(JSON.stringify(resident)));
    }
  }, [resident]);

  const onUpdateResidentStatus = e => {
    const status = residentStatuses?.find(rs => rs?._id === e.target.value);
    const obj = {...editResident};
    obj.residentStatus = status;
    setEditResident(obj);
  }

  const onSave = async () => {
    const obj = {...resident};
    obj.residentStatus = editResident?.residentStatus;
    await updateResident(resident?._id, obj);
    refresh();
    setEditMode(false);
  }

  return (
    <Paper elevation={24}
           className={classes.root}
    >
      <Tabs value={tabIndex}
            onChange={(e, newIndex) => setTabIndex(newIndex)}
      >
        <Tab label={"Current Admission Status"} />
        <Tab label={"Admissions/Discharges"} />
      </Tabs>
      {tabIndex === 0 &&
        <div className={classes.currentStatusContainer}>
          {!editMode && <Grid container alignItems={"center"}>
            <Typography variant={"h6"}>Current Status:</Typography>
            <IconButton onClick={() => setEditMode(true)}>
              <EditIcon fontSize={"small"}   />
            </IconButton>
            <Typography variant={"h6"}>{resident?.residentStatus?.name}</Typography>
          </Grid>}
          {editMode &&
            <div style={{maxWidth: '30rem'}}>
              <Alert severity={"info"} variant={"outlined"}>
                <Typography variant={"subtitle2"}>Manually updating status will not create an admission record. If you
                  want to change status and create record, goto Admissions/Discharges tab.
                </Typography>
              </Alert>
              <FormGroup >
                <FormControl>
                  <InputLabel shrink required>Status</InputLabel>
                  <Select value={editResident?.residentStatus?._id}
                          onChange={onUpdateResidentStatus}
                  >
                    {residentStatuses?.map(r => <MenuItem key={r?._id} value={r._id}>{r.name}</MenuItem>)}
                  </Select>
                </FormControl>
              </FormGroup>
              <DialogActions>
                <Button color={"primary"}
                        onClick={() => setEditMode(false)}
                >Cancel</Button>
                <Button variant={"contained"}
                        disabled={!editResident?.residentStatus?._id}
                        onClick={onSave}
                        color={"primary"}>Save</Button>
              </DialogActions>
            </div>
          }
          <Stepper alternativeLabel>
            {interactions?.map(interaction =>
              <Step key={interaction?.type}>
                {interaction?.type === 'Admission' &&
                  <StepLabel StepIconComponent={AdmittedStepperComponent}>
                    <Typography variant={"subtitle2"}>{moment(interaction?.date).format("MM/DD/YYYY")}</Typography>
                  </StepLabel>
                }
                {interaction?.type === 'Discharge' &&
                <StepLabel StepIconComponent={DischargedStepperComponent}>
                  <Typography variant={"subtitle2"}>{moment(interaction?.date).format("MM/DD/YYYY")}</Typography>
                </StepLabel>
                }
                {interaction?.type === 'Readmit' &&
                <StepLabel StepIconComponent={ReadmittedStepperComponent}>
                  <Typography variant={"subtitle2"}>{moment(interaction?.date).format("MM/DD/YYYY")}</Typography>
                </StepLabel>
                }
                {interaction?.type === 'LOA' &&
                <StepLabel StepIconComponent={LoaStepperComponent}>
                  <Typography variant={"subtitle2"}>{moment(interaction?.startDate).format("MM/DD/YYYY")}</Typography>
                  <Typography variant={"subtitle2"}>{moment(interaction?.endDate).format("MM/DD/YYYY")}</Typography>
                </StepLabel>
                }
                {interaction?.type === 'Transfer' &&
                <StepLabel StepIconComponent={TransferedStepperComponent}>
                  <Typography variant={"subtitle2"}>{moment(interaction?.date).format("MM/DD/YYYY")}</Typography>
                </StepLabel>
                }
                {interaction?.type === "Current" &&
                <StepLabel StepIconComponent={CurrentStepperComponent}>
                  <Typography variant={"subtitle2"}>{moment(interaction?.date).format("MM/DD/YYYY")}</Typography>
                </StepLabel>
                }
              </Step>
            )}
          </Stepper>
        </div>
      }
      {tabIndex === 1 && <ResidentAdmissions />}
    </Paper>
  )
}

export default ResidentAdmissionsPage;
