import React from "react";
import {
  getResidentPhones,
  updateResidentPhone,
  createResidentPhone,
  deleteResidentPhone,
} from "../../../services/resident.service";
import useHasRole from '../../../hooks/user.role.hook';
import EntityPhoneList from "../../phone/entity.phone.list";

const IntakePhones = ({ residentId, token }) => {
  const [residentPhones, setResidentPhones] = React.useState([]);
  const { checkPermission } = useHasRole();

  React.useEffect(() => {
    const getPhones = async () => {
      const phones = await getResidentPhones(token, residentId);
      setResidentPhones(phones);
    };
    if (residentId) {
      getPhones();
    } else {
      setResidentPhones([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [residentId]);

  const createPhone = async (phone) => {
    const response = await createResidentPhone(residentId, phone);
    const obj = [...residentPhones, response];
    setResidentPhones(obj);
  };

  const onResidentPhoneSave = async (phone) => {
    const updatedPhone = await updateResidentPhone(residentId, phone._id, phone);
    const obj = residentPhones.map((resPhone) => resPhone._id === updatedPhone._id ? updatedPhone : resPhone);
    setResidentPhones(obj);
  };

  const onDeletePhone = async (phone) => {
    await deleteResidentPhone(residentId, phone?._id);
    const obj = residentPhones.filter(rp => rp._id !== phone?._id);
    setResidentPhones(obj);
  }

  const canEdit = checkPermission('RESIDENT_UPDATE');

  return (
    <EntityPhoneList canEdit={canEdit}
                     phones={residentPhones}
                     updatePhone={onResidentPhoneSave}
                     createPhone={createPhone}
                     deletePhone={onDeletePhone}
                     showPrimaryToggle={true}

    />
  )
};

export default IntakePhones;
