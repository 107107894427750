import React from 'react';
import InputMask from "react-input-mask";
import { Input } from '@material-ui/core';

const PhoneMaskDisplay = ({value}) => {
    return <InputMask mask="(999) 999-9999" value={value}>
        {() => <Input disableUnderline />}
      </InputMask>    
}

export default PhoneMaskDisplay
