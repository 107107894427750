import React from 'react';
import {Box, Button, Grid, makeStyles} from '@material-ui/core';
import CurrentResidentLogs from '../logs/current.resident.logs';
import ResidentVitalsPanel from '../vitals/resident.vitals.panel';
import ResidentSchedule from '../schedule/resident.schedule';
import { ResidentContext } from '../../../context/residentcontext';
import Skeleton from "@material-ui/lab/Skeleton";
import {ResidentCalendarProvider} from "../calendar/context";
import {ResidentCalendarPage} from '../../resident/calendar';
import {Link as RouterLink} from 'react-router-dom';
import useHasRole from "../../../hooks/user.role.hook";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
  gridItem: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(.5, 0, 0, 0),
    },
  },
}));

const ResidentPage = () => {
  const { resident, loadingResident } = React.useContext(ResidentContext);
  const classes = useStyles();
  const { checkPermission } = useHasRole();
  const canReadResidentCalendar = checkPermission("RESIDENT_CALENDAR_READ");

  if (loadingResident) {
    return (
      <Grid className={classes.root} container justify="space-between">
        {[1,2,3].map(i =>
          <Grid xs={12} sm={6} lg={4} item className={classes.gridItem}>
            <Box pt={0.5}>
              <Skeleton width={'100%'} height={50} />
              <Skeleton variant="rect" width={'100%'} height={200} />
              <Skeleton width={'100%'} />
              <Skeleton width={'100%'} />
              <Skeleton width={'100%'} />
            </Box>
          </Grid>
        )}
      </Grid>
    )
  }

  if (resident) {
    return (
      <Grid className={classes.root} container justify="space-between">
        <Grid xs={12} sm={6} lg={4} item className={classes.gridItem}>
          <ResidentSchedule residentId={resident._id} />
        </Grid>
        <Grid xs={12} sm={6} lg={3} item className={classes.gridItem}>
          <ResidentVitalsPanel residentId={resident._id} />
        </Grid>
        {canReadResidentCalendar &&
        <Grid xs={12} sm={6} lg={5} item className={classes.gridItem}>
          <ResidentCalendarProvider resident={resident}>
            <ResidentCalendarPage defaultView={"month"}
                                  height={"30vh"}
                                  navigationComponent={
                                    <Button variant={"text"} component={RouterLink}
                                            to={`/residents/${resident?._id}/calendar`}>Resident Calendar</Button>
                                  }
                                  showNav={false}/>
          </ResidentCalendarProvider>
        </Grid>
        }
        <Grid xs={12} sm={6} lg={4} item className={classes.gridItem}>
          <CurrentResidentLogs residentId={resident._id} />
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
};

export default ResidentPage;
