import React from 'react';
import {
  FormControlLabel,
  makeStyles,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import useHasRole from "../../../hooks/user.role.hook";
import {getTenantFeatures, updateTenantFeatures} from "../../../services/tenant.service";
import useGlobalNotification from "../../../hooks/notification.hook";
import {UserContext} from "../../../context/usercontext";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1)
  }
}));

export const SSASlider = ({label, name, onChange, value, disabled = false}) => {
  return (
    <FormControlLabel
      style={{
        alignSelf: 'flex-start',
        margin: '1rem 0 0 0',
      }}
      name={name}
      control={
        <Switch
          name={name}
          onChange={onChange}
          color="primary"
          checked={value}
          disabled={disabled}
        />
      }
      label={label}
      labelPlacement="start"
    />
  )
}

const FEATURES_MODEL = {
  timeClock: false
}

export const TenantFeatures = ({tenantId}) => {
  const {dispatch} = React.useContext(UserContext);
  const classes = useStyles();
  const {checkPermission} = useHasRole();
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(FEATURES_MODEL)));
  const [tenant, setTenant] = React.useState();
  const canTenantUpdate = checkPermission("TENANT_UPDATE");
  const { addNotification } = useGlobalNotification();

  React.useEffect(() => {
    if (tenantId) {
      getData(tenantId).then(() =>{});
    }
  }, [tenantId]);

  const getData = async (id) => {
    const response = await getTenantFeatures(id);
    if (!response?.error) {
      setTenant(response);
      const obj = JSON.parse(JSON.stringify(FEATURES_MODEL));
      obj.timeClock = response?.timeClock;
      setModel(obj);
    }
  }

  const onTimeClockChange = async event => {
    const value = event.target.checked;
    const obj = {...model};
    obj.timeClock = value;
    update(obj);
  }

  const update = async (tenantModel) => {
    let data = {}
    Object.keys(tenantModel).forEach(key => {
      data[key] = tenantModel[key];
    });
    const response = await updateTenantFeatures(tenantId, data);
    if (!response?.error) {
      setModel(tenantModel);
      addNotification("Tenant features updated.", "success");
      dispatch({type: "UPDATE_TENANT_FEATURES", payload: { tenantId, features: data }});
      // Update Tenant Context?
    } else {
      addNotification("Unable to update tenant feature", "error");
    }
  }

  return (
    <div className={classes.root}>
      <Typography variant={"h6"}>
        Tenant Features
      </Typography>
      <TableContainer>
        <Table size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell>Feature</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Enabled</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                Time Clock
              </TableCell>
              <TableCell>
                Description here..
              </TableCell>
              <TableCell>
                {canTenantUpdate ? <SSASlider
                                              name={"timeclock"}
                                              value={model?.timeClock}
                                              onChange={onTimeClockChange}
                /> : <span>{tenant?.features?.timeClock ? "YES" : "NO"}</span>}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
