import React from 'react';
import { StepActions } from '../step.actions';
import {useStepStyles} from "../styles";
import { useHistory } from 'react-router-dom';
import {IntakeContext} from "../../context/intake.context";
import {Divider, Paper, Typography} from "@material-ui/core";
import {SummaryModel} from "./SummaryModel";
import FormFieldsGenerator from "../../../../../../utils/forms/form.fields.generator";
import {isModelValid} from "../../../../../../utils/forms";
import config from "../../../../../../utils/configs";
import {createAdmissionAction} from "../../context/intake.actions";
import useUpdateResidentInList from "../../../../../../hooks/update.resident.in.list.hook";
import {getResidentStatuses} from "../../../../../../services/resident.service";

export const SummaryStep = () => {
  const classes = useStepStyles();
  const history = useHistory();
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(SummaryModel)));
  const { updateResidentList } = useUpdateResidentInList();
  const { state } = React.useContext(IntakeContext);

  React.useEffect(() => {
    const obj = JSON.parse(JSON.stringify(SummaryModel));
    obj.date.value = new Date();
    setModel(obj);
  }, []);

  const onChange = e => {
    setModel({...model, ...e});
  }

  const buildImageUrlString = () => {
    return `${config.bucketObjectUrl}/${config.publicBucketName}/tenants/${state?.tenant?._id}/residents/${state?.id}/photo/${state.model.photo.value}`;
  };

  const onSave = async () => {
    const data = {};
    Object.keys(model).forEach(key => {
      data[key] = model[key].value;
    });
    await createAdmissionAction(state?.id, data);
    if (data?.type === "Admission") {
      const statuses = await getResidentStatuses();
      const admittedStatus = statuses?.find(s => s.name === "Admitted");
      const obj = {...state?.resident};
      obj.residentStatus = admittedStatus;
      updateResidentList(obj);
    }
    history.push(`/residents/${state?.id}`);
  }

  return (
    <div>
      <div>
        <Paper className={classes.summaryRoot}>
          <div className={classes.imgContainer}>
            <img
              src={buildImageUrlString()}
              alt="Resident"
              className={classes.img}
            />
            <Typography variant="h5">
              {state?.resident?.firstName}{' '}{state?.resident?.middleName}{' '}{state?.resident?.lastName}
            </Typography>
          </div>
          <div className={classes.form}>
            <Typography variant="h6">Admit Resident</Typography>
            <Divider />
            <FormFieldsGenerator model={model} onChange={onChange} />
          </div>
        </Paper>
      </div>
      <div className={classes.btnContainer}>
        <StepActions onPrev={() => history.push(`/intake/${state.id}/assessment`)}
                     onSave={onSave}
                     disableNext={!isModelValid(model)}

        />
      </div>
    </div>
  )
}
