import React from 'react'
import {
  Button,
  Checkbox, CircularProgress, FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from "@material-ui/core";
import {getResidentAssessmentsByAssessment} from "../../../services/v2/resident.assessments.service";
import TableLoadingIndicator from "../../../utils/indicators/table.loading";
import {AssessmentTableRowComponent} from "./assessment.table.row.component";
import {PictureAsPdf} from "@material-ui/icons";
import PDFMerger from "pdf-merger-js/browser";
import {getAssessmentInstancePdf} from "../../../services/assessment.service";

export const AssessmentsTableComponent = ({assessmentId, residentId}) => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(25);
  const [totalItems, setTotalItems] = React.useState(0);
  // eslint-disable-next-line
  const [error, setError] = React.useState(false);
  // eslint-disable-next-line
  const [loading, setLoading] = React.useState(false);
  const [assessments, setAssessments] = React.useState([]);
  const [loadingReport, setLoadingReport] = React.useState(false);


  React.useEffect(() => {
    if (residentId && assessmentId) {
      getData().then(() => {})
    }
    // eslint-disable-next-line
  }, [residentId, assessmentId]);

  const getData = async (pg = 1, sz = 25) => {
    setError(false);
    setLoading(true);
    setTotalItems(-1);
    const response = await getResidentAssessmentsByAssessment(residentId, assessmentId, pg, sz);
    if (!response?.error) {
      setAssessments(response?.content);
      setPage(response?.paginator?.currentPage);
      setSize(response?.paginator?.perPage);
      setTotalItems(response?.paginator?.totalItems);
    } else {
      setError(true);
    }
    setLoading(false);
  }

  const handleRowsPerPageChange = async (event) => {
    let sz = event?.target?.value
    setSize(event?.target?.value);
    await getData(page, sz);
  }

  const handlePageChange = async (event, pg) => {
    setPage(pg + 1);
    await getData(pg + 1, size);
  }

  const handleDeleteAssessment = (id) => {
    setAssessments(assessments?.filter(p => p?._id !== id));
  }

  const handleAssessmentSelected = (e, ra) => {
    ra.selected = e.target.checked;
    setAssessments(assessments?.map(assessment => assessment?._id === ra?._id ? ra: assessment));
  }

  const toggleAll = (e) => {
    const obj = assessments?.map(a => {
      a.selected = e.target.checked;
      return a;
    });
    setAssessments(obj);
  }

  const getPrintLabel = () => {
    let selectedLength = assessments?.filter(r => r?.selected > 0)?.length;
    if (selectedLength > 0) {
      return `(${selectedLength} selected)`
    }
    return null;
  }

  const handlePrintClick = async () => {
    setLoadingReport(true);
    let printableAssessments = assessments?.filter(r => r?.selected);
    if (printableAssessments?.length > 1) {
      const merger = new PDFMerger();
      // let files = []
      for (var assessment of assessments?.filter(r => r?.selected)) {
        const response = await getAssessmentInstancePdf(residentId, assessment?._id);
        // const fileUrl = URL.createObjectURL(response);
        // files.push(response);
        await merger.add(response);
      }
      await merger.setMetadata({
        producer: "pdf-merger-js based script"
      });
      const mergedPdf = await merger.saveAsBlob();
      const url = URL.createObjectURL(mergedPdf);
      await window.open(url);

    } else {
      for (let assessment of assessments?.filter(r => r?.selected)) {
        const response = await getAssessmentInstancePdf(assessmentId, assessment?._id);
        const fileUrl = URL.createObjectURL(response);
        await window.open(fileUrl);
      }
    }
    setLoadingReport(false);
  }

  return (
    <>
      <TablePagination count={totalItems}
                       onRowsPerPageChange={handleRowsPerPageChange}
                       onPageChange={handlePageChange}
                       page={page - 1}
                       rowsPerPage={size}
                       component={"div"}
      />
      <TableContainer>
        <Table size="small" aria-label="purchases">
          <TableHead>
            <TableRow>
              <TableCell width={300}>
                <FormControlLabel
                  control={
                    <Checkbox onChange={toggleAll}
                              name={`select-${assessmentId}`}
                    />
                  }
                />
                <Button disabled={loadingReport || assessments?.filter(ra => ra?.selected)?.length === 0}
                        startIcon={loadingReport ? <CircularProgress size={20} color={"primary"} />: <PictureAsPdf />}
                        color={"primary"}
                        variant={"outlined"}
                        onClick={handlePrintClick}
                >
                  Print {getPrintLabel()}
                </Button>
              </TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Completed</TableCell>
            </TableRow>
          </TableHead>
          {!error && loading &&
            <TableLoadingIndicator bodyOnly={true} cols={3} rows={5} />
          }
          <TableBody>
            {!error && !loading && assessments?.map((residentAssessment, index) =>
              <>
              <AssessmentTableRowComponent index={index}
                                           residentAssessment={residentAssessment}
                                           onDelete={handleDeleteAssessment}
                                           onSelected={handleAssessmentSelected}
                                           selected={residentAssessment?.selected}
                                           residentId={residentId} />
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
