import React from 'react';
import moment from 'moment';
import {getDayOfWeek} from "../../../utils/date.utils";

const EventTemplate = ({ frequency, eventTemplates }) => {
  if (frequency && eventTemplates) {
    if (frequency.isDayOfWeekSelection) {
      return (
        <div>
          {eventTemplates &&
            eventTemplates.map((eventTemplate) => {
              const time = moment()
                .hour(eventTemplate.hour)
                .minute(eventTemplate.minute);
              return (
                <div>
                  Qty {eventTemplate.passingQty} {getDayOfWeek(eventTemplate?.day)}{' '}
                  Time {moment(time).format('h:mm a')} (
                  {moment(time).format('H:mm')})
                </div>
              );
            })}
        </div>
      );
    } else if (frequency.isMonthDateSelection) {
      return (
        <div>
          {eventTemplates &&
            eventTemplates.map((eventTemplate) => {
              const time = moment()
                .hour(eventTemplate.hour)
                .minute(eventTemplate.minute);
              return (
                <div>
                  Qty {eventTemplate.passingQty} Day {eventTemplate.day} Time{' '}
                  {moment(time).format('h:mm a')} ({moment(time).format('H:mm')}
                  )
                </div>
              );
            })}
        </div>
      );
    } else if (frequency.isYearlySelection) {
      return (
        <div>
          {eventTemplates &&
            eventTemplates.map((eventTemplate) => {
              const time = moment()
                .hour(eventTemplate.hour)
                .minute(eventTemplate.minute);
              const x = eventTemplate.month - 1;
              const month = moment(new Date().setMonth(x)).format('MMMM');
              return (
                <div>
                  Qty {eventTemplate.passingQty} Month {month} Day{' '}
                  {eventTemplate.day} Time {moment(time).format('h:mm a')} (
                  {moment(time).format('H:mm')}) )
                </div>
              );
            })}
        </div>
      );
    } else {
      return (
        <div>
          {eventTemplates &&
            eventTemplates.map((eventTemplate) => {
              const time = moment()
                .hour(eventTemplate.hour)
                .minute(eventTemplate.minute);
              return (
                <div>
                  Qty {eventTemplate.passingQty} Time{' '}
                  {moment(time).format('h:mm a')} ({moment(time).format('H:mm')}
                  )
                </div>
              );
            })}
        </div>
      );
    }
  } else {
    return null;
  }
};

export default EventTemplate;
