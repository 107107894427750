import {
  LOAD_RESIDENT_PHARMACIES_START,
  LOAD_RESIDENT_PHARMACIES_COMPLETE,
  CREATE_RESIDENT_PHARMACY_START,
  CREATE_RESIDENT_PHARMACY_COMPLETE,
  DELETE_RESIDENT_PHARMACY_START,
  DELETE_RESIDENT_PHARMACY_COMPLETE,
  UPDATE_RESIDENT_PHARMACIES_START,
  UPDATE_RESIDENT_PHARMACIES_COMPLETE,
  OPEN_PHARMACY_DIALOG,
  CLOSE_PHARMACY_DIALOG
} from "./resident.pharmacies.context";
import * as residentService from '../../../services/resident.service';

export const openPharmacyLookupDialog = () => ({type: OPEN_PHARMACY_DIALOG});
export const closePharmacyLookupDialog = () => ({type: CLOSE_PHARMACY_DIALOG});

export const getResidentPharmacies = async (dispatch, resident) => {
  try {
    dispatch({ type: LOAD_RESIDENT_PHARMACIES_START });
    const response = await residentService.getResidentPharmacies(resident?._id);
    dispatch({
      type: LOAD_RESIDENT_PHARMACIES_COMPLETE,
      payload: response
    });
  } catch (error) {
    alert(error);
    dispatch({ type: LOAD_RESIDENT_PHARMACIES_COMPLETE, payload: [] });
  }
};

export const createResidentPharmacy = async (dispatch, residentId, pharmacyId, isPrimary = false, residentContextDispatch) => {
    try {
        dispatch({ type: CREATE_RESIDENT_PHARMACY_START });
        const response = await residentService.createResidentPharmacy(residentId, pharmacyId, isPrimary);
        dispatch({
          type: CREATE_RESIDENT_PHARMACY_COMPLETE,
          payload: response
        });

        residentContextDispatch({type: 'ADD_RESIDENT_PHARMACY', payload: response });
      } catch (error) {
        alert(error);
        dispatch({ type: LOAD_RESIDENT_PHARMACIES_COMPLETE, payload: null });
      }
  }

  export const deleteResidentPharmacy = async (dispatch, residentId, pharmacyId, residentContextDispatch) => {
    try {
        dispatch({ type: DELETE_RESIDENT_PHARMACY_START });
        await residentService.deleteResidentPharmacy(residentId, pharmacyId);
        dispatch({
          type: DELETE_RESIDENT_PHARMACY_COMPLETE,
          payload: pharmacyId
        });
        residentContextDispatch({type: 'DELETE_RESIDENT_PHARMACY', payload: pharmacyId})
      } catch (error) {
        alert(error);
        dispatch({ type: LOAD_RESIDENT_PHARMACIES_COMPLETE, payload: pharmacyId });
      }
  }

  export const updateResidentPharmacy = async (dispatch, residentId, pharmacyId, isPrimary, residentContextDispatch) => {
    try {
        dispatch({ type: UPDATE_RESIDENT_PHARMACIES_START });
        const response = await residentService.updateResidentPharmacy(residentId, pharmacyId, isPrimary);
        dispatch({
          type: UPDATE_RESIDENT_PHARMACIES_COMPLETE,
          payload: response
        });
        residentContextDispatch({type: 'UPDATE_RESIDENT_PHARMACY', payload: response});
      } catch (error) {
        alert(error);
        dispatch({ type: LOAD_RESIDENT_PHARMACIES_COMPLETE, payload: null });
      }
  }
