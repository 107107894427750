import React from "react";
import {
  Typography,
  Paper,
  makeStyles,
  Grid,
  Button,
  Table,
  TableHead,
  TableCell,
  TableContainer,
  DialogActions,
  TableRow,
  Input,
  TextField,
  MenuItem,
  Select,
} from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import SmartphoneIcon from "@material-ui/icons/Smartphone";
import history from "../../utils/history";
import * as pharmacyService from "../../services/pharmacy.service";
import modelTemplate from "./model.template";
import FormFieldsGenerator from "../../utils/forms/form.fields.generator";
import { getAllAddressTypes } from "../../services/address.service";
import InputMask from "react-input-mask";
import AddIcon from "@material-ui/icons/Add";
import { getPhoneTypes } from "../../services/phone.service";
import { isModelValid } from "../../utils/forms/form.validator";
import CreatePhone from "../phone/create.phone";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  icon: {
    marginRight: ".5rem",
  },
  backBtn: {
    marginRight: ".5rem",
  },
  gridContainer: {
    padding: theme.spacing(1),
  },
}));

const Pharmacy = ({ match }) => {
  const classes = useStyles();
  const [model, setModel] = React.useState(null);
  const [addressTypes, setAddressTypes] = React.useState([]);
  const [pharmacy, setPharmacy] = React.useState(null);
  const [phoneTypes, setPhoneTypes] = React.useState([]);
  const [editPhoneEntity, setEditPhoneEntity] = React.useState(null);
  const [editKey, setEditKey] = React.useState(-1);
  const [createPhoneModal, setCreatePhoneModal] = React.useState(null);

  React.useEffect(() => {
    const getPTypes = async () => {
      const response = await getPhoneTypes();
      setPhoneTypes(response);
    };
    getPTypes();
  }, []);

  React.useEffect(() => {
    const get = async (id) => {
      const response = await pharmacyService.getPharmacy(id);
      merge(response);
    };
    if (match.params.id) {
      get(match.params.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]);

  const merge = async (entity) => {
    if (entity) {
      const obj = JSON.parse(JSON.stringify(modelTemplate));
      delete obj.phone;
      obj.name.value = entity.name;
      if (entity.address) {
        obj.address.value = entity.address.address;
        obj.address2.value = entity.address.address2;
        obj.city.value = entity.address.city;
        obj.state.value = entity.address.state;
        obj.zipCode.value = entity.address.zipCode;
        if (entity.address.addressType) {
          obj.addressType.value = entity.address.addressType;
        }
      }
      obj.addressType.dropDownItems = await getTypes();
      setModel(obj);
      setPharmacy(entity);
    }
  };

  const getTypes = async () => {
    if (!addressTypes || addressTypes.length === 0) {
      const types = await getAllAddressTypes();
      setAddressTypes(types);
      return types;
    } else {
      return addressTypes;
    }
  };

  const onChange = (e) => {
    const obj = { ...model, ...e };
    setModel(obj);
  };

  const back = () => {
    history.goBack();
  };

  const editPhone = (phone, key) => {
    setEditPhoneEntity(JSON.parse(JSON.stringify(phone)));
    setEditKey(key);
  };

  const onEditPhoneChange = (e) => {
    const obj = { ...editPhoneEntity };
    obj.number = e.target.value;
    setEditPhoneEntity(obj);
  };
  const onEditPhoneTypeChange = (e) => {
    const obj = { ...editPhoneEntity };
    obj.phoneType = e.target.value;
    setEditPhoneEntity(obj);
  };
  const updatePharmacy = async () => {
    const data = {
      name: model.name.value,
      address: {
        address: model.address.value,
        address2: model.address2.value,
        city: model.city.value,
        state: model.state.value,
        zipCode: model.zipCode.value,
        addressType: model.addressType.value,
      },
    };
    const updated = await pharmacyService.updatePharmacy(pharmacy._id, data);
    setPharmacy(updated);
  };

  const createPhone = async (phone) => {
    const data = {
      number: phone.number,
      phoneType: phone.phoneType
    };
    const newPhone = await pharmacyService.createPharmacyPhone(
      pharmacy._id,
      data
    );
    const obj = { ...pharmacy };
    obj.phones = [...obj.phones, newPhone];
    setPharmacy(obj);
    setCreatePhoneModal(false);

  };

  const deletePhone = async (phone) => {
    const response = await pharmacyService.deletePharmacyPhone(
      pharmacy._id,
      phone._id
    );
    if (response) {
      const obj = { ...pharmacy };
      obj.phones = obj.phones.filter((ph) => ph._id !== phone._id);
      setPharmacy(obj);
    }
  };

  const updatePhone = async () => {
    const data = {
      _id: editPhoneEntity._id,
      number: editPhoneEntity.number,
      phoneType: editPhoneEntity.phoneType,
    };
    const response = await pharmacyService.updatePharmacyPhone(
      pharmacy._id,
      editPhoneEntity._id,
      data
    );

    if (response) {
      const obj = { ...pharmacy };
      obj.phones = obj.phones.map((ph) =>
        ph._id === response._id ? response : ph
      );
      setPharmacy(obj);
    }
    setEditKey(-1);
  };

  return (
    <Paper elevation={24} className={classes.root}>
      <Grid container>
        <Grid item md={6} xs={12}>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            className={classes.gridContainer}
          >
            <Button
              onClick={back}
              className={classes.backBtn}
              startIcon={<NavigateBeforeIcon />}
            >
              Back
            </Button>
            <Typography variant="h5">
              Pharmacy {pharmacy ? pharmacy.name : null}
            </Typography>
          </Grid>
          <Grid className={classes.gridContainer}>
            {model && (
              <form>
                <FormFieldsGenerator model={model} onChange={onChange} />
                <DialogActions>
                  <Button
                    disabled={!isModelValid(model)}
                    variant="contained"
                    color="primary"
                    onClick={updatePharmacy}
                  >
                    Save
                  </Button>
                </DialogActions>
              </form>
            )}
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            className={classes.gridContainer}
          >
            <SmartphoneIcon color="primary" className={classes.icon} />
            <Typography variant="h6">Phone</Typography>
          </Grid>
          <Grid className={classes.gridContainer}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableCell>
                    <Button
                      onClick={() => setCreatePhoneModal(true)}
                      startIcon={<AddIcon />}
                    >
                      Add
                    </Button>
                    <CreatePhone
                      onClose={() => setCreatePhoneModal(false)}
                      onSave={createPhone}
                      open={createPhoneModal}
                    ></CreatePhone>
                  </TableCell>
                  <TableCell>Number</TableCell>
                  <TableCell>Type</TableCell>
                </TableHead>
                {pharmacy &&
                  pharmacy.phones &&
                  pharmacy.phones.map((phone, key) => (
                    <TableRow>
                      {key !== editKey && (
                        <React.Fragment>
                          <TableCell>
                            <Button
                              size="small"
                              onClick={() => editPhone(phone, key)}
                            >
                              Edit
                            </Button>
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => deletePhone(phone)}
                            >
                              Delete
                            </Button>
                          </TableCell>
                          <TableCell>
                            <InputMask
                              mask="(999) 999-9999"
                              value={phone.number}
                            >
                              {() => <Input disableUnderline />}
                            </InputMask>
                          </TableCell>
                          <TableCell>
                            {phone?.phoneType}
                          </TableCell>
                        </React.Fragment>
                      )}
                      {key === editKey && editPhone && (
                        <React.Fragment>
                          <TableCell>
                            <Button onClick={() => setEditKey(-1)}>
                              Cancel
                            </Button>
                            <Button
                              onClick={updatePhone}
                              variant="contained"
                              color="primary"
                            >
                              Save
                            </Button>
                          </TableCell>
                          <TableCell>
                            <InputMask
                              name="number"
                              mask="(999) 999-9999"
                              value={editPhoneEntity.number}
                              onChange={onEditPhoneChange}
                            >
                              {() => <TextField fullWidth disableUnderline />}
                            </InputMask>
                          </TableCell>
                          <TableCell>
                            <Select
                              onChange={onEditPhoneTypeChange}
                              fullWidth
                              value={editPhoneEntity.phoneType}
                            >
                              {phoneTypes &&
                                phoneTypes.map((type) => (
                                  <MenuItem value={type.name}>
                                    {type.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </TableCell>
                        </React.Fragment>
                      )}
                    </TableRow>
                  ))}
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Pharmacy;
