// src/components/PrivateRoute.js

import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "../react-auth0-spa";
import { useLocation } from "react-router-dom";
import { logAnalyticsEvent, setAnalyticsUserId } from '../utils/analytics';

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect, user } = useAuth0();
  let location = useLocation();

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }

    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: path }
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  const render = props => {
    if (isAuthenticated === true) {
      setAnalyticsUserId(user?._id);
      const page_path = location.pathname + location.search;
      logAnalyticsEvent('page_view', { page_path  })
      return (
        <Component {...props} />
      )
    } else {
      return null;
    }
  }

  return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;
