import React from "react";
import { Select, MenuItem } from "@material-ui/core";
import { stateProvidenceList } from "../../address/providencesAndStates";

const StateDropdown = ({
  name,
  onChange,
  value,
  variant,
  size = "small",
  margin = "dense",
  showAll = false,
  ...p
}) => {

  const getStateProvidenceOptions = () => {
    let items = []
    stateProvidenceList?.forEach(item => {
      items = [...items, {...item, isSubHeader: true }, ...item?.providencesStates];

    })
    return items?.map(item =>
      <MenuItem value={item?.abbreviation}
                key={item?.abbreviation}
                disabled={item?.disabled || item?.isSubHeader}>
        {item?.name}
      </MenuItem>
    )
  }

  return (
    <Select
      variant={variant}
      fullWidth
      name={name}
      onChange={onChange}
      value={value}
      size={size}
      margin={margin}
      p
    >
      {getStateProvidenceOptions()}
    </Select>
  );
};

export default StateDropdown;
