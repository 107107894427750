export const CONSTANTS = {
  SET_RESIDENT_ID: "SET_RESIDENT_ID",
  SET_FILES: "SET_FILES",
  SET_SELECTED_FOLDER: "SET_SELECTED_FOLDER",
  SET_SELECTED_FILE: "SET_SELECTED_FILE",
  SET_SELECTED_FILES: "SET_SELECTED_FILES",
  SET_SELECTED_FILE_NODE: "SET_SELECTED_FILE_NODE",
  SET_BREADCRUMB: "SET_BREADCRUMB",
  DELETED_FILE: "DELETED_FILE",
  DELETED_FILES: "DELETED_FILES",
  CREATED_FILE: "CREATED_FILE",
  CREATED_FILE_OUTSIDE_OF_PARENT: "CREATED_FILE_OUTSIDE_OF_PARENT",
  CREATED_FOLDER: "CREATED_FOLDER",
  DELETED_FOLDER: "DELETED_FOLDER",
  SET_CREATE_FOLDER_MODAL: "SET_CREATE_FOLDER_MODAL",
  SET_DELETE_FILE_ITEM_MODAL: "SET_DELETE_FILE_ITEM_MODAL",
  SET_DELETING_FILES: "SET_DELETING_FILES",
  SET_VIEW_COPY_MODAL: "SET_VIEW_COPY_MODAL",
  SET_VIEW_MOVE_MODAL: "SET_VIEW_MOVE_MODAL",
  SET_VIEW_RENAME_MODAL: "SET_VIEW_RENAME_MODAL",
  SET_COPY_FILE_DESTINATION: "SET_COPY_FILE_DESTINATION",
  SET_MOVE_FILE_DESTINATION: "SET_MOVE_FILE_DESTINATION",
  MOVED_FILE: "MOVED_FILE",
  RENAMED_FILE: "RENAMED_FILE",
  UPDATED_FILE: "UPDATED_FILE",
  SET_DRAG_FILE: "SET_DRAG_FILE",
  SET_DRAG_OVER_FILE: "SET_DRAG_OVER_FILE",
  SET_LOADING: "SET_LOADING",
  SET_ERROR_MESSAGE: "SET_ERROR_MESSAGE",
  SET_VIEW_EDIT_FOLDER_MODAL: "SET_VIEW_EDIT_FOLDER_MODAL",
  UPDATED_FOLDER: "UPDATED_FOLDER",
  SET_VIEW_PERMISSION_MODAL: "SET_VIEW_PERMISSION_MODAL",
  SET_SELECTED_OBJECT: "SET_SELECTED_OBJECT",
}
