import React from 'react';
import {Button, DialogActions, DialogContent, DialogTitle, FormGroup, makeStyles, TextField} from "@material-ui/core";
import {ResidentFileContext} from "../context/ResidentFilesContext";
import {CONSTANTS} from "../context/Constants";
import {DestinationMoveField} from "./DestinationMoveField";
import {computeBreadcrumbs} from "../context/breadcrumb.utils";
import {copyArrayWithRoot} from "./utils";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "30vw"
    },
    formRoot: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
    },
}));

export const MoveFile = ({onMove}) => {
    const classes = useStyles();
    const {state, dispatch} = React.useContext(ResidentFileContext);
    const [destination, setDestination] = React.useState();
    const [parent, setParent] = React.useState();

    React.useEffect(() => {
        if (state?.selectedFileNode && state?.files) {
            computeDestination(state?.selectedFileNode, state?.files)
            setParent(state?.selectedFileNode?.parent ? state?.selectedFileNode?.parent: 'root');
        }
        // eslint-disable-next-line
    },[state?.selectedFileNode, state?.files]);

    const handleCancel = () => {
        dispatch({type: CONSTANTS.SET_VIEW_MOVE_MODAL, payload: false});
    }

    const computeDestination = (file, files) => {
        const breadcrumbs = computeBreadcrumbs(file, files);
        if (breadcrumbs) {
            let destName = breadcrumbs?.filter(f => f?._id !== file?._id)?.map(f=>f?.label).join('/');
            destName = `${destName}/${state?.selectedFileNode?.label}`;
            setDestination(destName);
        } else {
            setDestination("NA");
        }
    }

    const computeParentDetination = (parent, files) => {
        const breadcrumbs = computeBreadcrumbs(parent, files);
        if (breadcrumbs) {
            let destName = breadcrumbs?.map(f=>f?.label).join('/');
            destName = `${destName}/${state?.selectedFileNode?.label}`;
            setDestination(destName);
        } else {
            setDestination("NA");
        }
    }

    const onParentChange = val => {
        setParent(val);
    }

    const onDestinationSelected = (payload) => {
        if (!payload?.parent || payload?.parent === "root") {
            computeDestination(null, state?.files);
        } else {
            const parent = state?.files?.find(f => f?._id === payload?.parent);
            computeParentDetination(parent, state?.files);
        }
    }

    const onMoveClicked = () => {
        onMove({
            file: state?.selectedFileNode,
            parent: parent
        });
    }

    return (
        <div className={classes.root}>
            <DialogTitle>
                Move File
            </DialogTitle>
            <DialogContent dividers style={{height: "85vh"}}>
                <FormGroup className={classes.formRoot}>
                    <TextField fullWidth
                               variant={"outlined"}
                               label={"Source"}
                               readonly
                               value={`${state?.selectedFileNode?.fileName}`}
                    />
                    <pre>
                        PARENT: {parent}
                    </pre>
                    <DestinationMoveField file={state?.selectedFileNode}
                                          breadcrumbs={copyArrayWithRoot(state?.breadcrumbs)}
                                          files={state?.files}
                                          onSelected={onDestinationSelected}
                                          value={destination}
                                          onParentChange={onParentChange}
                    />
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button variant={"contained"}
                        color={"primary"}
                        onClick={onMoveClicked}

                >
                    Move
                </Button>
            </DialogActions>
        </div>
    )
}
