import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormGroup, LinearProgress, TextField
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/SaveTwoTone";
import moment from "moment/moment";
import useHasRole from "../../../hooks/user.role.hook";
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import ChipInput from "material-ui-chip-input";
import MomentUtils from "@date-io/moment";

const timeEntryModel = {
    _id: null,
    user: null,
    date: moment().utc().set({hours: 0, minutes: 0, seconds: 0, milliseconds: 0}),
    comment: null,
    tags: [],
    totalMinutes: 0,
    totalMinutesInput: "0"
}

export const EditTimeEntryModal = ({open, onSave, onClose, timeEntry}) => {
    const [model, setModel] = React.useState(JSON.parse(JSON.stringify(timeEntryModel)));
    const [loading, setLoading] = React.useState(false);
    const {checkPermission} = useHasRole();
    const canEdit = checkPermission("TIME_ENTRY_UPDATE");

    React.useEffect(() => {
        if (open && timeEntry) {
            setLoading(true);
            const obj = JSON.parse(JSON.stringify(timeEntryModel));
            obj._id = timeEntry?._id;
            obj.date = timeEntry?.date;
            obj.comment = timeEntry?.comment;
            obj.tags = timeEntry?.tags;
            obj.totalMinutes = timeEntry?.totalMinutes;
            obj.start = timeEntry?.start;
            obj.end = timeEntry?.end;
            obj.user = timeEntry?.user;
            if (obj.end === null || obj.end === undefined) {
                obj.totalMinutes = null;
                obj.totalMinutesInput = "0"
            } else if (obj.start && moment(obj.start).isValid() && moment(obj.end).isValid()) {
                let difMinutes = moment.duration(moment(obj.end).diff(obj.start));
                obj.totalMinutes = difMinutes.asMinutes();
                obj.totalMinutesInput = `${difMinutes.hours()}:${difMinutes.minutes().toLocaleString("en-us",
                    {
                        minimumIntegerDigits: 2,
                        useGrouping: false
                    })
                }`;
            }
            setModel(obj);
            setLoading(false);
        }
    }, [timeEntry, open])

    const onCommentChange = e => {
        const obj = {...model};
        obj.comment = e.target.value;
        setModel(obj)
    }

    const onStartChange = date => {
        const obj = {...model};
        obj.start = date;
        setModel(obj)
    }

    const onEndChange = date => {
        const obj = {...model};
        obj.end = date;
        if (date === null || date === undefined) {
            obj.totalMinutes = null;
            obj.totalMinutesInput = "0"
        } else if (obj.start && moment(obj.start).isValid() && moment(date).isValid()) {
            let difMinutes = moment.duration(moment(date).diff(obj.start));
            obj.totalMinutes = difMinutes.asMinutes();
            obj.totalMinutesInput = `${difMinutes.hours()}:${difMinutes.minutes().toLocaleString("en-us",
                {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                })
            }`;
        }

        setModel(obj)
    }

    const onTotalChanged = (e) => {
        let obj = {...model};
        obj.totalMinutesInput = e.target.value;
        setModel(obj);
    }

    const validateTotalTime = () => {
        let durationInput = model?.totalMinutesInput;
        let hourOnly = false;
        if (Number(durationInput) === Math.floor(durationInput)) {
            hourOnly = true;
            durationInput = `${durationInput}:00`
        }
        let duration = moment.duration(durationInput);
        if (duration?.hours() > 0 || duration?.minutes() > 0) {
            let newEnd = moment(model?.start).clone();
            newEnd.add(duration.hours(), 'hours');
            newEnd.add(duration.minutes(), 'minutes');
            let obj = {...model};
            obj.totalMinutes = duration.asMinutes();
            obj.end = newEnd;
            if (hourOnly) {
                obj.totalMinutesInput = durationInput;
            }
            setModel(obj);
        } else {
            // Not valid, do not update.
            let existingDuration = moment.duration(model?.totalMinutes, "minutes");
            let obj = {...model};
            obj.totalMinutesInput = `${existingDuration.hours()}:${existingDuration.minutes().toLocaleString("en-us",
                {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                })
            }`;
            setModel(obj);
        }
    }

    const isValid = () => {
        return model?.start && moment(model.start).isValid() && (!model?.end || moment(model.end).isValid());
    }

    const onEventDateChange = date => {
        const obj = {...model};
        obj.date = date;
        setModel(obj);
    }

    const handleTagAdd = tag => {
        const obj = {...model };
        obj.tags = [...obj.tags, tag];
        setModel(obj);
    }

    const handleTagDelete = tag => {
        const obj = {...model };
        obj.tags = obj?.tags?.filter(t => t !== tag);
        setModel(obj);
    }

    const save = () => {
        model.date = moment(model.date).utc().set({hours: 0, minutes: 0, seconds: 0, milliseconds: 0});
        model.start = moment(model.start).set({month: moment(model.date).month(), date: moment(model.date).date()});
        model.end = moment(model.end).set({month: moment(model.date).month(), date: moment(model.date).date()});
        onSave(model);
    }

    return (
        <Dialog open={open}
                onClose={onClose}
                maxWidth={"lg"}
                fullWidth={true}
        >
            <DialogTitle>
                {"Time Entry"}
            </DialogTitle>
            <DialogContent dividers>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    {loading &&
                        <LinearProgress />
                    }
                    {!loading &&
                    <FormGroup>
                        <KeyboardDatePicker value={model?.date && moment(model.date).utc()}
                                            onChange={onEventDateChange}
                                            style={{margin: '.5rem 0'}}
                                            disabled={!canEdit}
                        />
                        <KeyboardTimePicker
                            size={"small"}
                            fullWidth
                            mask="__:__ _M"
                            placeholder={"08:00 AM"}
                            label={"Start"}
                            value={model?.start || null}
                            name={"start"}
                            KeyboardButtonProps={{ disabled: true, style: { display: 'none' }}}
                            onChange={onStartChange}
                            style={{margin: '.5rem 0'}}
                            disabled={!canEdit}
                        />
                        <KeyboardTimePicker
                            size={"small"}
                            fullWidth
                            mask="__:__ _M"
                            placeholder={"05:00 PM"}
                            label={"End"}
                            value={model?.end || null}
                            name={"end"}
                            KeyboardButtonProps={{ disabled: true, style: { display: 'none' }}}
                            onChange={onEndChange}
                            style={{margin: '.5rem 0'}}
                            disabled={!canEdit}
                        />
                        <TextField label={"Total"}
                                   fullWidth
                                   disabled={!canEdit}
                                   size={"small"}
                                   onChange={onTotalChanged}
                                   onBlur={validateTotalTime}
                                   style={{margin: '.5rem 0'}}
                                   value={model?.totalMinutesInput} />
                        <ChipInput
                            size={"small"}
                            color={"primary"}
                            label={"Tags"}
                            fullWidth
                            InputLabelProps={{shrink: true}}
                            disableUnderline
                            fullWidthInput
                            blurBehavior={"add"}
                            value={model?.tags}
                            onAdd={handleTagAdd}
                            onDelete={handleTagDelete}
                            dataSource={null}
                            style={{margin: '.5rem 0'}}
                            disabled={!canEdit}
                        />
                        <TextField size={"small"}
                                   fullWidth
                                   onChange={onCommentChange}
                                   InputProps={{disableUnderline: true}}
                                   InputLabelProps={{shrink: true}}
                                   style={{margin: '.5rem 0'}}
                                   label={"Comment"}
                                   multiline={true}
                                   disabled={!canEdit}
                                   value={model?.comment} />
                    </FormGroup>
                    }
                </MuiPickersUtilsProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                {canEdit &&
                    <Button color={"primary"}
                        onClick={save}
                        disabled={!isValid()}
                        startIcon={<SaveIcon />}
                        variant={"contained"}>
                    Save
                </Button>
                }
            </DialogActions>
        </Dialog>
    )
}
