import React from 'react';
import './week.event.css';

export const WeekEventComponent = ({ event }) => {

  return (
    <div>
      {event?.title}
    </div>
  )
}
