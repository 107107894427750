import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import moment from 'moment';
import CreateVaccination from "./create.vaccination";
import {
  createResidentVaccination,
  deleteResidentVaccination,
  updateResidentVaccination
} from "../../../services/resident.service";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {ResidentContext} from "../../../context/residentcontext";
import {Info} from "@material-ui/icons";
import {getStripedStyle} from "../../utils/table.utils";
import {DeleteBtn} from "../../utils/buttons/DeleteBtn";

const useStyles = makeStyles((theme) => ({
  cdcListContainer: {
    border: '1px solid',
    padding: theme.spacing(0, 1, 1, 1),
    backgroundColor: theme.palette.grey[200]
  },
  cdcList: {
    lineHeight: '1.1',
    marginBlockStart: 0,
    marginBlockEnd: 0,
  },
  infoIcon: {
    color: theme.palette.info.main
  }
}));

const Vaccinations = ({resident}) => {
  const classes = useStyles();
  const [vaccinations, setVaccinations] = React.useState([]);
  const [editVaccination, setEditVaccination] = React.useState();
  const [openCreateVaccination, setOpenCreateVaccination] = React.useState(false);
  const [viewConfirmDeleteDialog, setViewConfirmDeleteDialog] = React.useState(false);
  const [confirmDeleteVaccination, setConfirmDeleteVaccination] = React.useState();
  const {dispatch} = React.useContext(ResidentContext);

  React.useEffect(() => {
    setVaccinations(resident?.vaccinations);
  }, [resident])

  const onCreateVaccination = async vaccination => {
    const entity = await createResidentVaccination(resident?._id, vaccination);
    const obj = [...vaccinations, entity];
    setVaccinations(obj);
    setOpenCreateVaccination(false);
    dispatch({type: 'RESIDENT_VACCINATIONS_UPDATED', payload: obj});
  }

  const onEditChange = e => {
    const obj = {...editVaccination};
    obj[e.target.name] = e.target.value;
    setEditVaccination(obj);
  }

  const onDateChange = date => {
    const obj = {...editVaccination};
    obj.administeredDate = date
    setEditVaccination(obj);
  }

  const isValid = () => {
    return editVaccination?.name && editVaccination?.administeredDate;
  }

  const onSetupConfirmDelete = vaccination => {
    setConfirmDeleteVaccination(vaccination);
    setViewConfirmDeleteDialog(true);
  }

  const onDeleteCancel = () => {
    setConfirmDeleteVaccination(null);
    setViewConfirmDeleteDialog(false);
  }

  const onConfirmDelete = async () => {
    await deleteResidentVaccination(resident?._id, confirmDeleteVaccination?._id);
    const obj = vaccinations.filter(vaccination => confirmDeleteVaccination?._id !== vaccination._id)
    setVaccinations(obj);
    setConfirmDeleteVaccination(null);
    setViewConfirmDeleteDialog(false);
    dispatch({type: 'RESIDENT_VACCINATIONS_UPDATED', payload: obj});
  }

  const updateVaccination = async () => {
    const entity = await updateResidentVaccination(resident?._id, editVaccination?._id, editVaccination);
    const obj = vaccinations.map(vaccination => vaccination._id === entity?._id ? entity : vaccination);
    setVaccinations(obj);
    setEditVaccination(null);
    dispatch({type: 'RESIDENT_VACCINATIONS_UPDATED', payload: obj});
  }

  return (
    <>
      <Grid container
      >
        <Grid item style={{flex: 1}} sm={6} xs={12}>
          <Typography variant={"h6"}>Vaccinations</Typography>
          <Button size={"small"}
                  color={"primary"}
                  variant={"contained"}
                  onClick={() => setOpenCreateVaccination(true)}
                  startIcon={<AddIcon/>}>
            Add
          </Button>
        </Grid>
        <Grid container
              className={classes.cdcListContainer}
              sm={9}
              xs={12}
              item>
          <Grid container justify={"center"} alignItems={"center"} item xs={12}>
            <Info className={classes.infoIcon}/>
            <Typography variant={"subtitle1"} style={{textAlign: "center"}}>
              CDC recommended vaccine list
            </Typography>
          </Grid>
          <Grid xs={6}>
            <Typography variant={"subtitle2"}>
              <ul className={classes.cdcList}>
                {
                  ["Influenza",
                    "MMR (Measles, Mumps & Rubella)",
                    "Varicella (Chickenpox)"].map(a =>
                    <li>{a}</li>
                  )
                }
              </ul>
            </Typography>
          </Grid>
          <Grid xs={6}>
            <Typography variant={"subtitle2"}>
              <ul className={classes.cdcList}>
                {
                  ["Tdap (tetanus, diphtheria, pertussis)",
                    "Meningococcal"].map(a =>
                    <li>{a}</li>
                  )
                }
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <CreateVaccination open={openCreateVaccination} onClose={() => setOpenCreateVaccination(false)}
                         onSave={onCreateVaccination}/>
      <TableContainer>
        <Table>
          <TableHead>
            <TableCell/>
            <TableCell>Name</TableCell>
            <TableCell>Date</TableCell>
          </TableHead>
          <TableBody>
            {vaccinations?.sort((a, b) => a?.administeredDate < b?.administeredDate ? 1 : -1)?.map((vaccination, index) =>
              <>
                {editVaccination?._id !== vaccination?._id &&
                  <TableRow style={getStripedStyle(index)}>
                    <TableCell>
                      <Button size={"small"}
                              startIcon={<EditIcon/>}
                              onClick={() => setEditVaccination(vaccination)}
                              variant={"text"}>
                        Edit
                      </Button>
                      <DeleteBtn size={"small"}
                                 onClick={() => onSetupConfirmDelete(vaccination)} />
                    </TableCell>
                    <TableCell>{vaccination.name}</TableCell>
                    <TableCell>{vaccination.administeredDate &&
                      <span>{moment(vaccination.administeredDate).format('MM/DD/YYYY')}</span>}</TableCell>
                  </TableRow>
                }
                {editVaccination?._id === vaccination?._id &&
                  <TableRow style={getStripedStyle(index)}>
                    <TableCell>
                      <Button size={"small"}
                              variant={"contained"}
                              color={"primary"}
                              disabled={!isValid()}
                              onClick={updateVaccination}
                              startIcon={<SaveIcon/>}>Update</Button>
                      <Button size={"small"}
                              onClick={() => setEditVaccination(null)}>Cancel</Button>
                    </TableCell>
                    <TableCell>
                      <TextField fullWidth
                                 value={editVaccination?.name}
                                 name={"name"}
                                 InputProps={{disableUnderline: true}}
                                 onChange={onEditChange}/>
                    </TableCell>
                    <TableCell>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          size="small"
                          fullWidth
                          name={"administeredDate"}
                          required
                          value={editVaccination?.administeredDate}
                          format="MM/DD/YYYY"
                          onChange={onDateChange}
                          InputProps={{disableUnderline: true}}
                          allowKeyboardControl="true"
                          ampm={false}
                        />
                      </MuiPickersUtilsProvider>
                    </TableCell>
                  </TableRow>
                }
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={viewConfirmDeleteDialog} maxWidth={"sm"} fullWidth>
        <DialogTitle>{"Confirm delete"}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>{"Please confirm if you want to delete Vaccination."}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDeleteCancel} color={"primary"}>Cancel</Button>
          <Button onClick={onConfirmDelete} color={"primary"} variant={"contained"}>Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Vaccinations;
