import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Model from './interaction.model';
import {FormFieldsGenerator, isModelValid} from "../../../utils/forms";

const CreateInteraction = ({open, onClose, onSave}) => {
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(Model)));

  React.useEffect(() => {
    if (open) {
      setModel(JSON.parse(JSON.stringify(Model)));
    }
  }, [open]);

  const onChange = (e, field) => {
    const obj = {...model};
    if (field === 'type') {
      switch (e.type.value) {
        case 'LOA':
          obj.date.hidden = true;
          obj.date.required = false;
          obj.date.value = null;
          obj.startDate.hidden = false;
          obj.startDate.required = true;
          obj.endDate.hidden = false;
          obj.endDate.required = true;
          obj.transferTo.hidden = true;
          break;
        case 'Transfer':
          obj.date.hidden = false;
          obj.date.required = true;
          obj.startDate.hidden = true;
          obj.startDate.required = false;
          obj.startDate.value = null;
          obj.endDate.value = null;
          obj.endDate.hidden = true;
          obj.endDate.required = false;
          obj.transferTo.hidden = false;
          break;
        default:
          obj.date.hidden = false;
          obj.date.required = true;
          obj.startDate.hidden = true;
          obj.startDate.required = false;
          obj.startDate.value = null;
          obj.endDate.value = null;
          obj.endDate.hidden = true;
          obj.endDate.required = false;
          obj.transferTo.hidden = true;
      }
    }
    setModel({...obj, ...e});
  }

  const save = () => {
    let data = {};
    Object.keys(model).forEach((key) => {
      if (!model[key].hidden) {
        data[key] = model[key].value;
      }
    });
    onSave(data);
  }

  return (
    <Dialog open={open}
            onClose={onClose}
            fullWidth
            maxWidth={"md"}
            >
      <DialogTitle>{"Create Interaction"}</DialogTitle>
      <DialogContent dividers>
        <FormFieldsGenerator model={model} onChange={onChange} />
      </DialogContent>
      <DialogActions>
        <Button color={"primary"}
                variant={"text"}
                onClick={onClose}>
          Cancel
        </Button>
        <Button color={"primary"}
                variant={"contained"}
                disabled={!isModelValid(model)}
                onClick={save}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateInteraction;
