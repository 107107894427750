export const model = {
  date: {
    title: 'Date/Time',
    required: false,
    value: new Date(),
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
    key: 'date',
    time: true
  },
  ventMode: {
    title: "Ventilator Mode",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
  },
  respRate: {
    title: "Respiratory Rate",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [ ],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
  },
  tidalVolume: {
    title: "Tidal Volume",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [ ],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
  },
  inspTime: {
    title: "Insp Time",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [ ],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
  },
  highPressure: {
    title: "Pressure - High",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [ ],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
  },
  lowPressure: {
    title: "Pressure - Low",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [ ],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
  },
  peep: {
    title: "PEEP",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [ ],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
  },
  sensitivity: {
    title: "Sensitivity",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [ ],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
  },
  humidifierTemp: {
    title: "Humidifier Temp",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [ ],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
  },
  ipap: {
    title: "IPAP",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [ ],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
  },
  epap: {
    title: "EPAP",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [ ],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
  },
  staff: {
    title: 'Staff',
    require: false,
    value: null,
    dirty: false,
    isValid: true,
    dropDown: true,
    dropDownItems: [],
    dropDownItemValue: "_id",
    dropDownItemLabel: "name",
    key: 'staff'
  },
};

export default model;
