import React from 'react';
import {
  Avatar,
  ButtonGroup,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
} from "@material-ui/core";
import {
  ArrowDownwardTwoTone,
  ArrowUpwardTwoTone,
  FileCopyTwoTone,
  SaveTwoTone
} from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import {AssessmentsContext} from "./assessments.context";
import {
  onDeleteQuestionAction,
  onUpdateQuestionAction,
  onOrderQuestionUpAction,
  onOrderQuestionDownAction, onCopyQuestionAction
} from "./assessment.actions";
import QuestionOptions from "./question.options";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  nameContainer: {
    flex: 1,
    padding: theme.spacing(0, 1)
  },
  toggleContainer: {
    alignSelf: 'flex-start',
    margin: '1rem 0 0 0',
  },
  toggle: {},
  avatar: {
    background: theme.palette.grey[600],
    height: '2.5vh',
    width: '2.5vh',
    fontSize: '1rem'
  },
  required: {
    background: theme.palette.primary.main
  }
}))

const Question = ({question, canUpdate = false}) => {
  const classes = useStyles();
  const { state, dispatch } = React.useContext(AssessmentsContext);
  const [model, setModel] = React.useState();

  React.useEffect(() => {
    if (question) {
      setModel(question);
    }
  },[question]);

  const onNameChange = e =>{
    const obj = {...model};
    obj.label = e.target.value;
    setModel(obj);
  }

  const moveUp = async () => {
    await onOrderQuestionUpAction(state?.assessment, question, dispatch)
  }

  const moveDown = async () => {
    await onOrderQuestionDownAction(state?.assessment, question, dispatch)
  }

  const onDeleteQuestion = async () => {
    await onDeleteQuestionAction(state?.assessment, question?._id, dispatch);
  }

  const save = async () => {
    await onUpdateQuestionAction(state?.assessment, question?._id, model, dispatch);
  }

  const onTypeChange = async e => {
    const obj = {...model};
    obj.type = state?.questionTypes?.find(q => q.name === e.target.value);;
    setModel(obj);
    await onUpdateQuestionAction(state?.assessment, question?._id, obj, dispatch);
  }

  const handleCheckChange = async event => {
    const obj = {...model};
    obj.required = event.target.checked;
    setModel(obj);
    await onUpdateQuestionAction(state?.assessment, question?._id, obj, dispatch);
  }

  const onCopyQuestion = async () => {
    await onCopyQuestionAction(state?.assessment, question, dispatch);
  }

  return (
    <>
      <Paper elevation={24} className={classes.root}>
        <Grid container justify={"space-between"} alignItems={"center"}>
          <Avatar className={[classes.avatar, model?.required ? classes.required: null ]}>
            {model?.sortOrder}
          </Avatar>
          <div className={classes.nameContainer}>
            <TextField label={"Question"}
                       fullWidth
                       InputLabelProps={{ shrink: true }}
                       placeholder={"Untitled"}
                       value={model?.label || ''}
                       onChange={onNameChange}
                       disabled={!canUpdate}
            />
          </div>
          <ButtonGroup variant={"contained"}
                       disabled={!canUpdate}
          >
            <IconButton onClick={moveUp}
            >
              <ArrowUpwardTwoTone />
            </IconButton>
            <IconButton onClick={moveDown}
            >
              <ArrowDownwardTwoTone />
            </IconButton>
            <IconButton onClick={onDeleteQuestion}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton onClick={save}
            >
              <SaveTwoTone />
            </IconButton>
            <IconButton onClick={onCopyQuestion}>
              <FileCopyTwoTone />
            </IconButton>

          </ButtonGroup>
        </Grid>
      </Paper>
      <Paper elevation={24} className={classes.root} style={{marginTop: '1rem'}}>
        <FormGroup>
          <FormControl>
            <InputLabel shrink required>Type</InputLabel>
            <Select value={model?.type?.name || ''}
                    disabled={!canUpdate}
                    onChange={onTypeChange}>
              {state?.questionTypes?.map(qType =>
                <MenuItem key={qType._id} value={qType?.name}>{qType.name}</MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControlLabel
            className={classes.toggleContainer}
            control={
              <Switch
                name={'required'}
                className={classes.toggle}
                disabled={!canUpdate}
                onChange={handleCheckChange}
                color="primary"
                checked={model?.required || ''}
              />
            }
            label={"Required"}
            labelPlacement="start"
          />
        </FormGroup>
      </Paper>
      {(model?.type?.name === 'Option' || model?.type?.name === "Multiple Choice") &&
      <Paper elevation={24} className={classes.root} style={{marginTop: '1rem'}}>
        <QuestionOptions question={model} options={model?.options} canUpdate={canUpdate} />
      </Paper>
      }
      </>
  )
}

export default Question;
