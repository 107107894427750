import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import {getAssessments} from "../../../services/assessment.service";
import {convertToFilters} from "../../../utils/filter.utils";

const CreateResidentAssessment = ({
                                    resident,
                                    residentId,
                                    assessmentName,
                                    open,
                                    onSave,
                                    onCancel
                                  }) => {
  const [disableSelection, setDisableSelection] = React.useState(false);
  const [assessments, setAssessments] = React.useState([]);
  const [model, setModel] = React.useState({
    residentId: residentId,
    assessmentId: null
  });

  const fetechAssessments = async () => {
    const filters = convertToFilters({
      status: {
        field: 'status',
        operator: '==',
        value: "Active",
        minValue: 3,
      }
    });
    const assessmentResult = await getAssessments(1, 100, filters, 'name', 'asc');
    setAssessments(assessmentResult?.content);
  }

  React.useEffect(() => {
    if (open && (!assessments || assessments?.length === 0)) {
      fetechAssessments();
    }
    // eslint-disable-next-line
  }, [ open ])

  React.useEffect(() => {
    const obj = {...model};
    let updated = false;
    if (residentId) {
      obj.residentId = residentId;
      updated = true;
    }
    if (assessmentName && assessments?.length > 0) {
      const current = assessments.find(assessment => assessment.name === assessmentName);
      if (current) {
        obj.assessmentId = current._id;
        updated = true;
        setDisableSelection(true);
      }
    }
    if (updated) {
      setModel(obj);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [residentId]);

  const onAssessmentChange = e => {
    const obj = {...model};
    obj.assessmentId = e.target.value;
    setModel(obj);
  }

  return (
    <Dialog open={open}
            onClose={onCancel}
            fullWidth={true}>
      <DialogTitle>New Assessment</DialogTitle>
      <DialogContent>
        <form>
          <FormGroup>
            <TextField
              disabled
              label="Resident"
              value={`${resident?.firstName} ${resident?.lastName}`}
            />
          </FormGroup>
          <FormGroup>
            <Select label="Assessment"
                    value={model?.assessmentId}
                    onChange={onAssessmentChange}
                    displayEmpty
                    disabled={disableSelection}>
              <MenuItem value={null}>Select an assessment</MenuItem>
              {!assessmentName && assessments?.map((type, key) => (
                <MenuItem value={type._id}>{type.name}</MenuItem>
              ))}
              {assessments?.filter(assessment => assessment?.name === assessmentName)?.map((type, key) => (
                  <MenuItem value={type._id}>{type.name}</MenuItem>
              ))}
            </Select>
          </FormGroup>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={() => onSave(residentId, model.assessmentId)}
                disabled={!model?.assessmentId || !model?.residentId}
                color="primary" variant="contained">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateResidentAssessment;
