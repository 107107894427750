import React from "react";
import {
  makeStyles,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  Typography,
  ListItemText, CircularProgress, Button,
} from "@material-ui/core";
import { orange } from "@material-ui/core/colors";
import history from "../../../../utils/history";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import CancelIcon from "@material-ui/icons/Cancel";
import {updateAlert} from "../../../../services/resident.service";
import {RotateLeftTwoTone} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {},
  alert: {
    cursor: "pointer",
  },
  notDismissed: {
    background: orange[400],
  },
}));

const LateMedicationAlert = ({
  medicationAlert,
  onViewEntity,
  onDismissedChange,
                               showResidentName = false
}) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const viewMedication = () => {
    onViewEntity();
    history.push(`/residents/${medicationAlert.resident}/emar`);
  };

  const onDismissedToggle = async () => {
    setError(false);
    setLoading(true);
    const residentId = medicationAlert?.resident;
    const alertId = medicationAlert?._id;
    const dismissed = !medicationAlert?.dismissed;
    const result = await updateAlert(residentId, alertId, {dismissed});
    if (!result?.error) {
      onDismissedChange(result)
    } else {
      setError(true);
    }
    setLoading(false);
  }

  if (loading) {
    return (
      <ListItem className={classes.root}
                button
      >
        <ListItemAvatar>
          <CircularProgress />
        </ListItemAvatar>
        <ListItemText primary={"Processing...."} />
      </ListItem>
    )
  }

  if (!loading && error) {
    return (
      <ListItem className={classes.root}
                button
      >
        <ListItemAvatar>
          <Avatar
            className={[
              !medicationAlert?.dismissed ? classes.notDismissed : null,
            ]}
          >
            <i className="fas fa-radiation"></i>
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={"Something went wrong..."} secondary={"Please click retry"} />
        <ListItemSecondaryAction>
          <Button startIcon={<RotateLeftTwoTone />}
                  onClick={onDismissedToggle}
                  color={"primary"}
                  variant={"contained"}
          >
            Retry
          </Button>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }

  if (!loading && !error) {
    return (
      <ListItem
        divider
        className={classes.root}
        button
        onClick={() => viewMedication()}
      >
        <ListItemAvatar>
          <Avatar
            className={[
              !medicationAlert?.dismissed ? classes.notDismissed : null,
            ]}
          >
            <i class="fas fa-prescription-bottle"></i>
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={`Late Medication ${showResidentName ? `- ${medicationAlert?.fkEntity?.resident?.firstName} ${medicationAlert?.fkEntity?.resident?.lastName}`: ''}`}
          secondary={
            <React.Fragment>
              {medicationAlert?.fkEntity?.medication && (
                  <div>
                    <Typography variant="body">
                      {medicationAlert?.fkEntity?.medication?.tradeName}
                    </Typography>
                    {medicationAlert?.fkEntity?.medication?.genericName && (
                      <Typography variant="body">
                        ({medicationAlert?.fkEntity?.medication?.genericName})
                      </Typography>
                    )}
                    {medicationAlert?.fkEntity?.medication?.strength && (
                        <Typography variant="body">
                          {" "}
                          {medicationAlert?.fkEntity?.medication?.strength}{" "}
                          {medicationAlert?.fkEntity?.medication?.unit?.name}
                        </Typography>
                      )}
                    {medicationAlert.scheduledTime && (
                      <Typography variant="body">
                        {" "}
                        {moment(medicationAlert?.scheduledTime).format(
                          "MM/DD/YYYY h:mm A"
                        )}
                      </Typography>
                    )}
                  </div>
                )}
            </React.Fragment>
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="comments"
            onClick={onDismissedToggle}
          >
            {!medicationAlert?.dismissed && <CancelIcon/>}
            {medicationAlert?.dismissed && <SettingsBackupRestoreIcon/>}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
};

export default LateMedicationAlert;
