import moment from "moment/moment";

const AdmitModel = {
    _id: {
      hidden: true,
      required: false,
      value: null,
      dirty: false,
      isValid: true
    },
    firstName: {
      title: "First Name",
      required: true,
      value: null,
      dirty: false,
      isValid: false,
      dropDown: false,
      dropDownItems: [],
      date: false
    },
    middleName: {
      title: "Middle Name",
      required: false,
      value: null,
      dirty: false,
      isValid: true,
      dropDown: false,
      dropDownItems: [],
      date: false
    },
    lastName: {
      title: "Last Name",
      required: true,
      value: null,
      dirty: false,
      isValid: false,
      dropDown: false,
      dropDownItems: [],
      date: false
    },
    formerName: {
      title: "Former Name",
      required: false,
      value: null,
      dirty: false,
      isValid: true,
      showSSN: false,
      dropDown: false,
      dropDownItems: [],
      date: false
    },
    dob: {
      title: "Date of birth",
      required: true,
      value: moment().set({h: 0, m: 0, ms: 0}).utc(),
      dirty: false,
      isValid: true,
      dropDown: false,
      date: true
    },
    gender: {
      title: "Gender",
      required: true,
      value: null,
      dirty: false,
      isValid: false,
      dropDown: true,
      dropDownItems: [],
      dropDownItemLabel: "name",
      dropDownItemValue: "name",
      date: false
    },
    countyCaseNumber: {
      title: "County Case Number",
      required: false,
      value: null,
      dirty: false,
      isValid: true,
      dropDown: false,
      dropDownItems: [],
      date: false
    },
    ssn: {
      title: "SSN/SIN",
      required: false,
      value: null,
      dirty: false,
      isValid: true,
      dropDown: false,
      dropDownItems: [],
      date: false
    },
    medicalRecordId: {
      title: "Medical Record Id",
      required: false,
      value: null,
      dirty: false,
      isValid: true,
      dropDown: false,
      dropDownItems: [],
      date: false
    },
    maritalStatus: {
      title: "Marital Status",
      required: false,
      value: null,
      dirty: false,
      isValid: true,
      dropDown: true,
      dropDownItems: [],
      dropDownItemLabel: "name",
      dropDownItemValue: "name",
      date: false
    },
    religiousPreference: {
      title: "Religious Preference",
      required: false,
      value: null,
      dirty: false,
      isValid: true,
      dropDown: false,
      dropDownItems: [],
      date: false
    },
    citizenship: {
      title: "Citizenship",
      required: false,
      value: null,
      dirty: false,
      isValid: true,
      dropDown: false,
      dropDownItems: [],
      date: false
    },
    ethnicity: {
      title: "Ethnicity",
      required: false,
      value: null,
      dirty: false,
      isValid: true,
      dropDown: true,
      dropDownItems: [],
      dropDownItemLabel: "name",
      dropDownItemValue: "_id",
      date: false
    },
    primaryLanguage: {
      title: "Primary Language",
      required: false,
      value: null,
      dirty: false,
      isValid: true,
      dropDown: false,
      dropDownItems: [],
      date: false
    },
    roomNumber: {
      title: "Room Number",
      required: false,
      value: null,
      dirty: false,
      isValid: true,
      dropDown: false,
      dropDownItems: [],
      date: false
    },
    photo: {
      title: 'Photo',
      hidden: true,
      required: false,
      value: null,
      dirty: false,
      isValid: true
    },
    residentStatusId: {
      hidden: true,
      required: true,
      value: null,
      dirty: false,
      isValid: true
    },
    tenantLocationId: {
      title: 'Location',
      required: true,
      value: null,
      dirty: false,
      isValid: true,
      dropDown: true,
      dropDownItems: [],
      dropDownItemLabel: 'name',
      dropDownItemValue: '_id',
    },
    physician: {
      hidden: true,
      required: false,
      value: null,
      dirty: false,
      isValid: true
    },
    pharmacy: {
      hidden: true,
      required: false,
      value: null,
      dirty: false,
      isValid: true
    },
    insurance: {
      hidden: true,
      required: false,
      value: null,
      dirty: false,
      isValid: true
    },
    livingWill: {
      hidden: true,
      required: false,
      value: null,
      dirty: false,
      isValid: true,
      dropDownItems: [
        { name: "Yes", value: "y" },
        { name: "No", value: "n" }
      ],
    },
    codeStatus: {
      hidden: true,
      required: false,
      value: [],
      dirty: false,
      isValid: true,
      dropDownItems: []
    },
    specialNeeds: {
      hidden: true,
      required: false,
      value: null,
      dirty: false,
      isValid: true
    },
    education: {
      hidden: true,
      required: false,
      value: null,
      dirty: false,
      isValid: true
    },
    hobbies: {
      hidden: true,
      required: false,
      value: null,
      dirty: false,
      isValid: true
    },
    specialInterests: {
      hidden: true,
      required: false,
      value: null,
      dirty: false,
      isValid: true
    },
    contacts: {
      hidden: true,
      required: false,
      value: [],
      dirty: false,
      isValid: true
    },
    email: {
      hidden: true,
      required: false,
      value: null,
      dirty: false,
      isValid: true
    },
    occupation: {
      hidden: true,
      required: false,
      value: null,
      dirty: false,
      isValid: true
    },
    transferedFrom: {
      title: "Transfered from",
      required: false,
      value: null,
      dirty: false,
      isValid: true
    },
    referredBy: {
      title: "Referred by",
      required: false,
      value: null,
      dirty: false,
      isValid: true
    },
    militaryHistory: {
      hidden: true,
      required: false,
      value: null,
      dirty: false,
      isValid: true
    }
  };

  export default AdmitModel;
