import React from 'react';
import {Tab, Tabs} from "@material-ui/core";
import {MobileResidentsList} from "../resident/list/mobile.residents.list";
import ESchedule from "../eschedule/eschedule";
import ResidentNotes from "../notes/ResidentNotes";
import {DashboardContext} from "./dashboard.context";
import {TenantCalendarPageWrapper} from "../tenants/calendar";
import useHasRole from "../../hooks/user.role.hook";
import Reports from "../reports/reports";

const Dashboard = () => {
  const { state, dispatch } = React.useContext(DashboardContext);
  const { checkPermission } = useHasRole();
  const canReadTenantCalendar = checkPermission("TENANT_CALENDAR_READ");
  const handleChange = (event, newValue) => {
    dispatch({type: "SET_INDEX", payload: newValue})
  };
  return (
    <>
      <Tabs variant={"fullWidth"}
            value={state?.tabIndex}
            indicatorColor={"primary"}
            textColor={"primary"}
            onChange={handleChange}
      >
        <Tab label={"eSchedule"} value={"eSchedule"} />
        <Tab label={"Notes"} value={"Notes"} />
        <Tab label={"Resident"} value={"Resident"} />
        {canReadTenantCalendar && <Tab label={"Calendar"} />}
        <Tab label={"Reports"} value={"Reports"} />
      </Tabs>
      {state?.tabIndex === "eSchedule" && <ESchedule />}
      {state?.tabIndex === "Notes" && <ResidentNotes />}
      {state?.tabIndex === "Resident" && <MobileResidentsList minView={true} forceMaxHeight={true}/>}
      {state?.tabIndex === "Calendar" && <TenantCalendarPageWrapper />}
      {state?.tabIndex === "Reports" && <Reports viewOnly={true} />}

    </>
  )
}

export const MobileDashboard = () => {

  return (
    <Dashboard />
  )
}
