import apiUtil from '../utils/api.utils';
import configs from '../utils/configs';
const baseUrl = `${configs.residentService}`;

export const getGenders = async token => {
    try {
        const response = await apiUtil.client.get(`${baseUrl}/api/v1.0/genders`);
        return response.data;
    } catch (err) {
        alert(err);
        return {content:[]};
    }
}