import {CONSTANTS} from "./Constants";

export const residentFilesContextReducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.SET_RESIDENT_ID:
      return {...state, residentId: action?.payload};
    case CONSTANTS.SET_FILES:
      return {...state, files: action?.payload};
    case CONSTANTS.SET_SELECTED_FILE:
      return {...state, selectedFile: action?.payload}
    case CONSTANTS.SET_SELECTED_FILES:
      return {...state, selectedFiles: action?.payload};
    case CONSTANTS.DELETED_FILES:
      return {...state,
        files: state?.files?.filter(f => action?.payload?.indexOf(f?._id) < 0),
        selectedFiles: state?.selectedFiles?.filter(f => action?.payload?.indexOf(f?._id) < 0),
      }
    case CONSTANTS.SET_BREADCRUMB:
      return {
        ...state,
        // selectedFolder: action?.payload,
        breadcrumbs: action?.payload,
      }
    case CONSTANTS.DELETED_FILE:
      return {
        ...state,
        files: state?.files?.filter(f => f?._id !== action?.payload),
        selectedFiles: state?.selectedFiles?.filter(f => f?._id !== action?.payload),
        selectedFile: null
      }
    case CONSTANTS.SET_SELECTED_FOLDER:
      return {...state, selectedFolder: action?.payload};
    case CONSTANTS.CREATED_FILE:
      return {...state, files: [...state.files, ...action?.payload], selectedFiles: [...state?.selectedFiles, ...action?.payload]};
    case CONSTANTS.CREATED_FILE_OUTSIDE_OF_PARENT:
      return {...state, files: [...state.files, ...action?.payload]};
    case CONSTANTS.SET_CREATE_FOLDER_MODAL:
      return {...state, viewCreateFolderModal: action?.payload};
    case CONSTANTS.CREATED_FOLDER:
      return {...state, files: [...state.files, action?.payload], selectedFiles: [...state?.selectedFiles, action?.payload]};
    case CONSTANTS.SET_DELETE_FILE_ITEM_MODAL:
      return {...state, viewDeleteFileItemModal: action?.payload};
    case CONSTANTS.SET_VIEW_COPY_MODAL:
      return {...state, viewCopyFileModal: action?.payload};
    case CONSTANTS.SET_VIEW_MOVE_MODAL:
      return {...state, viewMoveFileModal: action?.payload};
    case CONSTANTS.SET_VIEW_RENAME_MODAL:
      return {...state, viewRenameFileModal: action?.payload};
      // Used to set selected file without viewing page. I.E. select file in table to move, copy, or rename
    case CONSTANTS.SET_SELECTED_FILE_NODE:
      return {...state, selectedFileNode: action?.payload};
    case CONSTANTS.SET_COPY_FILE_DESTINATION:
      return {...state, copyFileDestination: action?.payload};
    case CONSTANTS.SET_MOVE_FILE_DESTINATION:
      return {...state, moveFileDestination: action?.payload};
    case CONSTANTS.MOVED_FILE:
      return {...state,
        files: state?.files?.map(f => f?._id === action?.payload?._id ? action?.payload: f),
        selectedFiles: state?.selectedFiles?.filter(f => f?._id !== action?.payload?._id)
      };
    case CONSTANTS.RENAMED_FILE:
      return {...state,
        files: state?.files?.map(f => f?._id === action?.payload ? action?.payload: f),
        selectedFiles: state?.selectedFiles?.map(f => f?._id === action?.payload?._id ? action?.payload: f)
      };
    case CONSTANTS.UPDATED_FILE:
      return {
        ...state,
        selectedFile: action?.payload,
        breadcrumbs: state?.breadcrumbs?.map(b => b?._id === action?.payload?._id ? action?.payload: b),
        files: state?.files?.map(f => f?._id === action?.payload?._id ? action?.payload: f),
        selectedFiles: state?.selectedFiles?.map(f => f?._id === action?.payload?._id ? action?.payload: f)
      }
    case CONSTANTS.SET_DRAG_FILE:
      return {...state, dragFile: action?.payload};
    case CONSTANTS.SET_DRAG_OVER_FILE:
      return {...state, dragOverFile: action?.payload};
    case CONSTANTS.SET_LOADING:
      return {...state, loading: action?.payload};
    case CONSTANTS.SET_ERROR_MESSAGE:
      return {...state, errorMessage: action?.payload};
    case CONSTANTS.SET_VIEW_EDIT_FOLDER_MODAL:
      return {...state, viewEditFolderModal: action?.payload};
    case CONSTANTS.UPDATED_FOLDER:
      return {...state,
        selectedFolder: null,
        files: state?.files?.map(f => f?._id === action?.payload?._id ? action?.payload: f),
        selectedFiles: state?.selectedFiles?.map(f => f?._id === action?.payload?._id ? action?.payload: f)
      };
    case CONSTANTS.SET_VIEW_PERMISSION_MODAL:
      return {...state,
        viewPermissionModal: action?.payload
      };
    case CONSTANTS.SET_SELECTED_OBJECT:
      return {...state,
        selectedObject: action?.payload
      };
    default:
      return {...state };
  }
}
