export const model = {
  date: {
    title: 'Date/Time',
    required: false,
    value: new Date(),
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
    key: 'date',
    time: true
  },
  ccWaterAir: {
    title: "CC’s of Water/Air in Cuff",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
  },
  co2Levels: {
    title: "C02 Levels",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [
      { name: "None", value: null },
      { name: "Absent", value: "Absent" },
      { name: "Clear", value: "Clear" },
      { name: "Coarse", value: "Coarse" },
      { name: "Crackles", value: "Crackles" },
      { name: "Diminished", value: "Crackles" },
      { name: "Other", value: "Other" },
      { name: "Wheezes", value: "Wheezes" },
    ],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
  },
  rul: {
    title: "RUL",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [
      { name: "None", value: null },
      { name: "Absent", value: "Absent" },
      { name: "Clear", value: "Clear" },
      { name: "Coarse", value: "Coarse" },
      { name: "Crackles", value: "Crackles" },
      { name: "Diminished", value: "Crackles" },
      { name: "Other", value: "Other" },
      { name: "Wheezes", value: "Wheezes" },
    ],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
  },
  lul: {
    title: "LUL",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [
      { name: "None", value: null },
      { name: "Absent", value: "Absent" },
      { name: "Clear", value: "Clear" },
      { name: "Coarse", value: "Coarse" },
      { name: "Crackles", value: "Crackles" },
      { name: "Diminished", value: "Crackles" },
      { name: "Other", value: "Other" },
      { name: "Wheezes", value: "Wheezes" },
    ],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
  },
  rml: {
    title: "RML",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [
      { name: "None", value: null },
      { name: "Absent", value: "Absent" },
      { name: "Clear", value: "Clear" },
      { name: "Coarse", value: "Coarse" },
      { name: "Crackles", value: "Crackles" },
      { name: "Diminished", value: "Crackles" },
      { name: "Other", value: "Other" },
      { name: "Wheezes", value: "Wheezes" },
    ],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
  },
  rll: {
    title: "RLL",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [
      { name: "None", value: null },
      { name: "Absent", value: "Absent" },
      { name: "Clear", value: "Clear" },
      { name: "Coarse", value: "Coarse" },
      { name: "Crackles", value: "Crackles" },
      { name: "Diminished", value: "Crackles" },
      { name: "Other", value: "Other" },
      { name: "Wheezes", value: "Wheezes" },
    ],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
  },
  lll: {
    title: "LLL",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [
      { name: "None", value: null },
      { name: "Absent", value: "Absent" },
      { name: "Clear", value: "Clear" },
      { name: "Coarse", value: "Coarse" },
      { name: "Crackles", value: "Crackles" },
      { name: "Diminished", value: "Crackles" },
      { name: "Other", value: "Other" },
      { name: "Wheezes", value: "Wheezes" },
    ],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
  },
  staff: {
    title: 'Staff',
    require: false,
    value: null,
    dirty: false,
    isValid: true,
    dropDown: true,
    dropDownItems: [],
    dropDownItemValue: "_id",
    dropDownItemLabel: "name",
    key: 'staff'
  },
};

export default model;
