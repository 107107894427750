import React from 'react';
import {
  Accordion, AccordionDetails, AccordionSummary,
  Divider,
  makeStyles, Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography
} from "@material-ui/core";
import moment from 'moment-timezone';
import CloseIcon from "@material-ui/icons/Close";
import {AssignmentLateRounded, CheckCircle} from "@material-ui/icons";
import CancelIcon from "@material-ui/icons/Cancel";
import {getUniqueEventTemplates} from "../../../utils/table";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: ".5rem",
  },
  container: {
    display: "flex",
  },
  day: {
    flex: 1,
    border: "1px solid black",
    textAlign: "center",
    padding: ".25rem",
  },
  tableDayColumn: {
    padding: 0,
    textAlign: 'center',
    minWidth: "50px",
    maxWidth: "50px",
    borderLeft: "1px solid gray",
    borderRight: "1px solid gray",
  },
  late: {
    color: theme.palette.warning.main
  }
}));

const MarDosageInstruction = ({medicationOrder, dosageInstruction, days}) => {
  const classes = useStyles();

  const getStatusLabel = status => {
    switch (status) {
      case 'Scheduled':
        return ' ';
      case 'Given':
        return <CheckCircle color={"secondary"} />;
      case 'Missed':
        return <CancelIcon color={"error"} />;
      case 'Refused':
        return 'R';
      case 'Hold':
        return 'H';
      case 'Refused & Destructed':
      case 'RD':
        return 'RD';
      case 'Self Administered':
      case 'SA':
        return 'SA';
      case 'Not Administered':
      case 'NA':
        return 'NA';
      case 'Late':
        return <AssignmentLateRounded className={classes.late}  />
      case 'Assist':
        return 'A'
      case 'Supervise':
        return 'S'
      default:
        return <CloseIcon />;
    }
  }

  const getDataForDay = (day, hour, minute, dosageInstruction) => {
    const everyXHourFrequencys = [ "QH", "Q2H", "Q3H", "Q4H", "Q6H", "Q8H","Q12H" ];
    const frequencyName = dosageInstruction?.frequency?.name;
    let events = [];
    let isEveryXHoursFrequency = everyXHourFrequencys?.indexOf(frequencyName) > -1
    if (isEveryXHoursFrequency) {
      events = dosageInstruction?.events?.filter(event =>
        moment.tz(event.scheduledTime, medicationOrder?.resident?.location?.timeZone ?? "US/Central").date() === +day
      );
    } else {
      events = dosageInstruction?.events?.filter(event =>
        moment.tz(event.scheduledTime, medicationOrder?.resident?.location?.timeZone ?? "US/Central").date() === +day &&
        moment.tz(event.scheduledTime, medicationOrder?.resident?.location?.timeZone ?? "US/Central").hour() === +hour &&
        moment.tz(event.scheduledTime, medicationOrder?.resident?.location?.timeZone ?? "US/Central").minute() === +minute
      );
    }

    if (events?.length > 0) {

      return events?.map(event => {
        event.processed = true;
        return (
          <Tooltip interactive
                   title={
                     <Paper elevation={24}
                     >
                       <div style={{padding: '1rem', width: '1000px', minHeight: '400px'}}>
                         <Typography
                           variant={"subtitle1"}>Resident: {medicationOrder?.resident?.firstName} {medicationOrder?.resident?.lastName}</Typography>
                         <Typography
                           variant={"subtitle1"}>Medication: {medicationOrder?.medication?.tradeName}</Typography>
                         <Typography variant={"subtitle1"}>Status: {event?.status?.name}</Typography>
                         <Typography
                           variant={"subtitle1"}>Scheduled: {moment(event.scheduledTime).format('MM/DD/YYYY hh:mm a')}</Typography>
                         {event?.administeredUser &&
                           <Typography variant={"subtitle1"}>Administered
                             By: {event.administeredUser?.firstName} {event.administeredUser?.lastName}</Typography>
                         }
                         {event?.administeredTimestamp &&
                           <Typography
                             variant={"subtitle1"}>Administered: {moment(event.administeredTimestamp).format('MM/DD/YYYY hh:mm a')}</Typography>}
                         <Typography variant={"subtitle1"}>Qty: {event?.passingQty}</Typography>

                       </div>
                       <Divider/>
                       <Accordion>
                         <AccordionSummary>
                           DEBUG INFO -- WILL REMOVE
                         </AccordionSummary>
                         <AccordionDetails>
                           <div>
                             <Typography variant={"subtitle1"}>Hour: {hour}</Typography>
                             <Typography variant={"subtitle1"}>Minute: {minute}</Typography>
                             <Typography variant={"subtitle1"}>Iterable (day): {day}</Typography>
                             <Typography variant={"subtitle1"}>{event?.dosageInstruction}</Typography>
                             <Typography
                               variant={"subtitle1"}>{moment(event?.scheduledTime).format('MM/DD/YYYY hh:mm a')}</Typography>
                             <Typography variant={"subtitle1"}>{moment(event.scheduledTime).date()}</Typography>
                             <Typography variant={"subtitle1"}>{moment(event.scheduledTime).hour()}</Typography>
                             <Typography variant={"subtitle1"}>{moment(event.scheduledTime).minute()}</Typography>
                             <Typography variant={"subtitle1"}>{JSON.stringify(event?.processed)}</Typography>
                           </div>
                         </AccordionDetails>
                       </Accordion>
                     </Paper>
                   }
          >
            <>
              <div style={{
                position: 'relative',
                height: isEveryXHoursFrequency ? '80px': '50px', width: '100%'}}>
                {getStatusLabel(event?.status?.name)}
                {isEveryXHoursFrequency &&
                  <div style={{width: '100%'}}>
                    {moment(event?.scheduledTime).format("H:mm")}
                  </div>
                }
                {event?.administeredUser &&
                  <div style={{position: 'absolute', right: '0', bottom: '0', marginTop: isEveryXHoursFrequency ? "-10px": 0}}>
                    <Typography variant={"subtitle2"}
                                style={{margin: 0, padding: 0, width: '50px', textAlign: "center"}}
                    >
                      {event?.administeredUser?.initials}
                    </Typography>
                  </div>
                }
              </div>
              {isEveryXHoursFrequency ? <Divider />: null}
            </>
          </Tooltip>)
      });
    } else {
      return (
        <Tooltip interactive
                 title={
                   <Paper elevation={24}>
                     <div style={{ padding: '1rem', width: '1000px', minHeight: '400px'}}>
                       <Typography variant={"h6"}>Not scheduled</Typography>
                     </div>
                     <Divider />
                     <Accordion>
                       <AccordionSummary>
                         DEBUG INFO -- WILL REMOVE
                       </AccordionSummary>
                       <AccordionDetails>
                         <div>
                           <Typography variant={"subtitle1"}>Hour: {hour}</Typography>
                           <Typography variant={"subtitle1"}>Minute: {minute}</Typography>
                           <Typography variant={"subtitle1"}>Iterable (day): {day}</Typography>

                         </div>
                       </AccordionDetails>
                     </Accordion>
                   </Paper>
                 }
        >
          <div style={{height: '50px', textAlign: 'center' }}>
            <CloseIcon style={{verticalAlign: 'center'}} />
          </div>
        </Tooltip>
      )
    }
  }

  const getEventTemplateDisplayForDosageInstruction = (dosageInstruction, template) => {
    switch (dosageInstruction?.frequency?.name) {
      case "QH":
        return "Every Hour";
      case "Q2H":
        return "Every 2 Hours";
      case "Q3H":
        return "Every 3 Hours";
      case "Q4H":
        return "Every 4 Hours";
      case "Q6H":
        return "Every 6 Hours";
      case "Q8H":
        return "Every 8 Hours";
      case "Q12H":
        return "Every 12 Hours";
      default:
        return `${template?.hour}:${template?.minute}`;
    }
  }

  return (
    <TableContainer>
      <Table size={"small"}>
        <TableBody>
          {getUniqueEventTemplates(dosageInstruction?.eventTemplates)?.map(template =>
            <TableRow>
              <TableCell style={{padding: 0, maxWidth: '55px', minWidth: '55px', textAlign: "center"}}>
                {getEventTemplateDisplayForDosageInstruction(dosageInstruction, template)}
              </TableCell>
              {[...Array(days)].map((e, i) =>
                <TableCell className={classes.tableDayColumn}>
                  {getDataForDay(i + 1, template.hour, template.minute, dosageInstruction)}
                </TableCell>)
              }
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default MarDosageInstruction;
