import React from 'react';
import {
  AppBar, Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton, Link,
  makeStyles,
  Toolbar,
  Typography
} from "@material-ui/core";
import Transition from "../../dialog/transition";
import CloseIcon from "@material-ui/icons/Close";
import FormFieldsGenerator from "../../../utils/forms/form.fields.generator";
import {isModelValid} from "../../../utils/forms";

const ModelTemplate = {
  name: {
    title: "Name",
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false
  },
  status: {
    title: "Status",
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false
  },
  testDate: {
    title: "Date",
    required: true,
    value: new Date(),
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: true
  },
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    paddingTop: "4rem",
  },
  dialogContent: {
    marginTop: "4rem",
  },
}));

const CreateResidenttest = ({ open, onClose, onSave }) => {
  const classes = useStyles();
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(ModelTemplate)));

  React.useEffect(() => {
    if (open) {
      initForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const initForm = async () => {
    const obj = JSON.parse(JSON.stringify(ModelTemplate));
    setModel(obj);
  }


  const onChange = e => {
    const obj = {...model, ...e};
    setModel(obj);
  }
  const save = () => {
    const data = {
      name: model.name.value,
      status: model.status.value,
      testDate: model.testDate.value
    }
    onSave(data);
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => onClose()}
      TransitionComponent={Transition}
    >
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => onClose()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Add Test</Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.dialogContent}>
        <form>
          <FormFieldsGenerator model={model} onChange={onChange} />
        </form>
      </DialogContent>
      <DialogActions>
        <Link onClick={onClose}>Cancel</Link>
        <Button disabled={!isModelValid(model)} onClick={save} variant="contained" color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateResidenttest;
