import React from 'react';
import {SsaDialogTitle} from "../../../utils/dialogs/SsaDialogTItle";
import {Button, Dialog, DialogActions, DialogContent} from "@material-ui/core";
import {FormFieldsGenerator, isModelValid} from "../../../../utils/forms";

const MODEL = {
    isHidden: {
        title: 'Hidden',
        isToggle: true,
        hidden: false,
        value: false,
        required: false,
    },
};

export const FileItemPermission = ({open, fileObject, onSave, onClose}) => {
    const [model, setModel] = React.useState(JSON.parse(JSON.stringify(MODEL)));

    React.useEffect(() => {
        if (open && fileObject) {
            const obj = JSON.parse(JSON.stringify(MODEL));
            obj.isHidden.value = fileObject?.isHidden;
            setModel(obj);
        }
    }, [open, fileObject]);

    const onChange = (e) => {
        setModel({...model, ...e});
    }

    const handleSave = () => {
        const data = {}
        Object.keys(model).forEach(key => {
            data[key] = model[key].value;
        });
        onSave(fileObject, data);
    }

    return (
        <Dialog open={open}
                maxWidth={"md"}
                fullWidth>
            <SsaDialogTitle onClose={onClose}>
                Edit Permission
            </SsaDialogTitle>
            <DialogContent dividers>
                <FormFieldsGenerator model={model} onChange={onChange} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button disabled={!isModelValid(model)}
                        variant={"contained"}
                        color={"primary"}
                        onClick={handleSave}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}
