import React, {useContext} from 'react';
import {CareplanContext} from "./context";
import {Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import moment from "moment";
import {ACTIONS} from "./context/constants";
import AddIcon from "@material-ui/icons/Add";
import {viewCreateInterventionDialogAction} from "./context/actions";
import {ChevronRight} from "@material-ui/icons";
import {Link as RouterLink} from 'react-router-dom';


export const ResidentCareplanGoalInterventions = ({careplan, goal}) => {
    const {state, dispatch} = useContext(CareplanContext);

    const handleInterventionClick = (intervention) => {
        dispatch({type: ACTIONS.SET_CAREPLAN, payload: careplan});
        dispatch({type: ACTIONS.SET_GOAL, payload: goal});
        dispatch({type: ACTIONS.SET_INTERVENTION, payload: intervention});
    }

    return (
        <TableContainer>
            <Table size={"small"}>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={3}>
                            <Button onClick={() => viewCreateInterventionDialogAction(dispatch)}
                                    variant={"contained"}
                                    color={"primary"}
                                    size={"small"}
                                    startIcon={<AddIcon />}>
                                Add
                            </Button>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Frequency</TableCell>
                        <TableCell>Start</TableCell>
                        <TableCell>End</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {state?.goal?.interventions?.map(intervention =>
                    <TableRow key={intervention?._id}>
                        <TableCell style={{cursor: "pointer"}}>
                            <Button onClick={() => handleInterventionClick(intervention)}
                                    startIcon={<ChevronRight />}
                                    component={RouterLink}
                                    to={`/residents/${state?.residentId}/careplans/${careplan?._id}/goals/${goal?._id}/interventions/${intervention?._id}`}
                                    size={"small"}
                            >
                                {intervention?.name}
                            </Button>
                        </TableCell>
                        <TableCell>
                            {intervention?.frequency?.name} ({intervention?.frequency?.name})
                        </TableCell>
                        <TableCell>{moment(intervention?.startDate).format("MM/DD/YYYY")}</TableCell>
                        <TableCell>{moment(intervention?.endDate).format("MM/DD/YYYY")}</TableCell>
                    </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
