import { ACTIONS } from './context';
import {
  createResidentCalendarEvent,
  deleteResidentCalendarEvent,
  deleteResidentCalendarSeries,
  getResidentCalendarEvents,
  getResidentCalendarTypes,
  updateResidentCalendarEvent,
  createResidentCalendarType,
  deleteResidentCalendarType,
  updateResidentCalendarType

} from "../../../../services/calendar.service";
import moment from "moment";


export const calendarEventSelectedAction = (dispatch, event) => {
  dispatch({type: ACTIONS.SET_SELECTED_CALENDAR_EVENT, payload: event });
  dispatch({type: ACTIONS.SET_VIEW_EDIT_EVENT_MODAL, payload: true})
}

export const calendarSlotSelectedAction = (dispatch, slot) => {
  dispatch({type: ACTIONS.SET_SELECTED_CALENDAR_SLOT, payload: slot });
  dispatch({type: ACTIONS.SET_VIEW_CREATE_EVENT_MODAL, payload: true });
}

export const getResidentCalendarEventsAction = async (dispatch, residentId, date) => {
  try {
    dispatch({type: ACTIONS.TOGGLE_LOADING, payload: true});
    dispatch({type: ACTIONS.SET_ERROR, payload: false });
    dispatch({type: ACTIONS.SET_RESIDENT_ID, payload: residentId});
    const response = await getResidentCalendarEvents(residentId, date);
    if (!response?.error) {
      dispatch({type: ACTIONS.SET_CALENDAR_EVENTS, payload: response });
    } else {
      dispatch({type: ACTIONS.SET_ERROR, payload: true });
    }
  } catch (error) {
    dispatch({type: ACTIONS.SET_ERROR, payload: true });
  }
  dispatch({type: ACTIONS.TOGGLE_LOADING, payload: false});
}

export const createCalendarEventsAction = async (dispatch, residentId, event, onError, onSuccess) => {
  try {
    const response = await createResidentCalendarEvent(residentId, event);
    if (!response?.error) {
      dispatch({type: ACTIONS.CREATE_CALENDAR_EVENT, payload: response });
      if (onSuccess) {
        onSuccess();
      }
    } else {
      if (onError) {
        onError();
      }
    }
  } catch (error) {
    dispatch({type: ACTIONS.SET_VIEW_CREATE_EVENT_MODAL, payload: true});
    dispatch({type: ACTIONS.SET_ERROR, payload: true });
  }
  dispatch({type: ACTIONS.TOGGLE_PENDING_ACTION, payload: false});
  dispatch({type: ACTIONS.TOGGLE_LOADING, payload: false});
}

export const updateCalendarEventsAction = async (dispatch, residentId, eventId, event, onError, onSuccess) => {
  try {
    dispatch({type: ACTIONS.TOGGLE_PENDING_ACTION, payload: true});
    const response = await updateResidentCalendarEvent(residentId, eventId, event);
    if (!response?.error) {
      dispatch({type: ACTIONS.UPDATE_CALENDAR_EVENT, payload: response });
      if (onSuccess) {
        onSuccess();
      }
    } else {
      if (onError) {
        onError();
      }
    }
  } catch (error) {
    dispatch({type: ACTIONS.SET_ERROR, payload: true });
  }
  dispatch({type: ACTIONS.TOGGLE_PENDING_ACTION, payload: false});
  dispatch({type: ACTIONS.TOGGLE_LOADING, payload: false});
}

export const deleteCalendarEventsAction = async (dispatch, residentId, eventId, onError, onSuccess) => {
  try {
    dispatch({type: ACTIONS.TOGGLE_PENDING_ACTION, payload: true});
    const response = await deleteResidentCalendarEvent(residentId, eventId);
    if (!response?.error) {
      dispatch({type: ACTIONS.DELETE_CALENDAR_EVENT, payload: response?._id });
      if (onSuccess) {
        onSuccess();
      }
    } else {
      if (onError) {
        onError();
      }
    }
  } catch (error) {
    dispatch({type: ACTIONS.SET_ERROR, payload: true });
  }
  dispatch({type: ACTIONS.TOGGLE_PENDING_ACTION, payload: false});
  dispatch({type: ACTIONS.TOGGLE_LOADING, payload: false});
}

export const selectCalendarEventAction = (dispatch, event) => {
  dispatch({type: ACTIONS.SET_SELECTED_CALENDAR_EVENT, payload: event });
  dispatch({type: ACTIONS.SET_VIEW_EDIT_EVENT_MODAL, payload: true})
}

export const deleteCalendarSeriesAction = async (dispatch, residentId, seriesId, onError, onSuccess) => {
  try {
    const response = await deleteResidentCalendarSeries(residentId, seriesId);
    if (!response?.error) {
      dispatch({type: ACTIONS.DELETE_CALENDAR_SERIES, payload: seriesId });
      if (onSuccess) {
        onSuccess();
      }
    } else {
      if (onError) {
        onError();
      }
    }
  } catch (error) {
    dispatch({type: ACTIONS.SET_ERROR, payload: true });
  }
}

export const getResidentCalendarTypesAction = async (dispatch, residentId, onError, onSuccess) => {
  try {
    dispatch({type: ACTIONS.TOGGLE_LOADING_TYPES_ERROR, payload: false });
    dispatch({type: ACTIONS.TOGGLE_LOADING_TYPES, payload: true });
    const response = await getResidentCalendarTypes(residentId);
    if (!response?.error) {
      dispatch({type: ACTIONS.SET_CALENDAR_TYPES, payload: response });
    } else {
      dispatch({type: ACTIONS.TOGGLE_LOADING_TYPES_ERROR, payload: true });
    }
  } catch (error) {
    dispatch({type: ACTIONS.TOGGLE_LOADING_TYPES_ERROR, payload: true });
  }
  dispatch({type: ACTIONS.TOGGLE_LOADING_TYPES, payload: false });
}

export const createCalendarTypeAction = async (dispatch, residentId, type, onError, onSuccess) => {
  try {
    const response = await createResidentCalendarType(residentId, type);
    if (!response?.error) {
      dispatch({ type: ACTIONS.CREATE_CALENDAR_TYPE, payload: response });
      onSuccess?.call();
    } else {
      onError?.call();
    }
  } catch (error) {
    onError?.call();
  }
}

export const updateCalendarFilterTypesAction = (dispatch, type) => {
  dispatch({type: ACTIONS.TOGGLE_FILTER_TYPE, payload: type });
}

export const selectCalendarTypeToEditAction = async (dispatch, payload) => {
  dispatch({type: ACTIONS.SET_SELECTED_CALENDAR_TYPE, payload });
  dispatch({type: ACTIONS.SET_VIEW_EDIT_CALENDAR_TYPE_MODAL, payload: true });
}


export const saveCalendarTypeAction = async (dispatch,
                                             residentId,
                                             type,
                                             calendarType,
                                             checked = false,
                                             onError,
                                             onSuccess) => {
  try {
    const response = await updateResidentCalendarType(residentId, type?._id, calendarType);
    if (!response?.error) {
      response.checked = checked;
      dispatch({ type: ACTIONS.UPDATE_CALENDAR_TYPE, payload: response });
      dispatch({ type: ACTIONS.SET_VIEW_EDIT_CALENDAR_TYPE_MODAL, payload: false });
      onSuccess?.call();
    } else {
      onError?.call();
    }
  } catch (error) {
    onError?.call();
  }
}

export const deleteCalendarTypeAction = async (dispatch, residentId, type, onError, onSuccess) => {
  try {
    const response = await deleteResidentCalendarType(residentId, type?._id);
    if (!response?.error) {
      dispatch({type: ACTIONS.DELETE_CALENDAR_TYPE, payload: type?._id });
      dispatch({type: ACTIONS.SET_VIEW_DELETE_CALENDAR_TYPE_MODAL, payload: false });
      dispatch({type: ACTIONS.SET_VIEW_EDIT_CALENDAR_TYPE_MODAL, payload: false });
      onSuccess?.call();
    } else {
      onError?.call();
    }
  } catch (error) {
    onError?.call();
  }
}


export const setResidentBirthdayAction = async (dispatch, resident) => {
  try {
    if (resident?.dob) {
      let birthday = moment(resident?.dob)?.utc();
      birthday = birthday.set( { year: moment().year() });
      const birthdayEvent = {
        title: "Birthday",
        type: "resident",
        start: birthday.endOf('day').toDate(),
        end: birthday.endOf('day').toDate(),
        allDay: true,
        color: '#2196f3'
      }
      dispatch({type: ACTIONS.SET_RESIDENT_BIRTHDAY, payload: birthdayEvent });
    } else {
      dispatch({type: ACTIONS.SET_RESIDENT_BIRTHDAY, payload: null });
    }
  } catch (error) {
    dispatch({type: ACTIONS.SET_RESIDENT_BIRTHDAY, payload: null });
  }
}
