import React from 'react';
import {useStepStyles} from "../styles";
import {StepActions} from "../step.actions";
import {useHistory} from "react-router-dom";
import {removeResidentPhoto, setStepStatus, uploadResidentPhoto} from "../../context/intake.actions";
import {IntakeContext} from "../../context/intake.context";
import config from "../../../../../../utils/configs";
import FileSelector from "../../../../../utils/fileselector/fileselector";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardHeader,
  CardMedia,
  Grid, LinearProgress,
} from "@material-ui/core";
import {Clear, Person, PhotoCamera} from "@material-ui/icons";
import * as constant from "../../context/intake.constants";

export const ResidentPhotoStep = () => {
  const { dispatch, state } = React.useContext(IntakeContext);
  const classes = useStepStyles();
  const history = useHistory();

  const buildImageUrlString = () => {
    return `${config.bucketObjectUrl}/${config.publicBucketName}/tenants/${state?.tenant?._id}/residents/${state?.id}/photo/${state?.model?.photo?.value}`;
  };

  const onNext = () => {
    setStepStatus(dispatch, 'photo', true);
    history.push(`/intake/${state.id}/medical`);
  }

  const onPhotoChange = e => {
    if (e?.target?.files?.length > 0) {
      dispatch({type: constant.SET_UPLOADING_PHOTO, payload: true });
      uploadResidentPhoto(dispatch, state?.id, e.target.files[0])
    }
  }

  const clearPhoto = async () => {
    await removeResidentPhoto(dispatch, state?.id);
  }


  return (
    <div>
      <Card className={classes.cardRoot}>
        <CardHeader
          title={`${state?.resident?.firstName} ${state?.resident?.lastName}`}
          subheader={"Upload a photo for this resident"}
          avatar={
            <Avatar
              alt="Resident Image"
              src={buildImageUrlString()}
            >
              <Person />
            </Avatar>
          }
        />
        {state?.uploadingPhoto && <LinearProgress />}
          <CardMedia
          className={classes.cardMedia}
          image={state?.uploadingPhoto ? null: buildImageUrlString()}
          title={"Resident Photo"}
        />
        <CardActions disableSpacing>
          <Grid container justifyContent={"flex-end"} >
            <Button variant={"text"}
                    onClick={clearPhoto}
                    size={"small"}
                    disabled={state.uploadingPhoto}
                    startIcon={<Clear />}>
              Clear
            </Button>
            <FileSelector
              size={"small"}
              startIcon={<PhotoCamera />}
              label="Select"
              onFileSelected={onPhotoChange}
              disabled={state?.uploadingPhoto}
              color="primary"
              variant="contained"
            />
          </Grid>
        </CardActions>
      </Card>
      <div className={classes.btnContainer}>
        <StepActions onNext={onNext}
                     onPrev={() => history.push(`/intake/${state.id}/details`)}
        />
      </div>
    </div>
  )
}
