export const model = {
  date: {
    title: 'Date/Time',
    required: false,
    value: Date.now(),
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
    key: 'date',
    time: true
  },
  temperature: {
    title: 'Temperature (oF) (normal 98.6)',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
    key: 'Temperature'
  },
  systolicBloodPressure: {
    title: 'Blood Pressure (Systolic/Diastolic) (normal 120)',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
    key: 'Blood pressure'
  },
  diastolicBloodPressure: {
    title: 'Blood Pressure (Systolic/Diastolic) (normal 80)',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
    key: 'Blood pressure'
  },
  pulse: {
    title: 'Pulse (normal 60-100 bpm)',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
    key: 'Pulse'
  },
  respiratoryRate: {
    title: 'Respiratory Rate (per min) (normal 14-20 bpm)',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
    key: 'Respiratory'
  },
  oxygen: {
    title: 'Oxygen (normal 90-99%)',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
    key: 'Oxygen'
  },
  bloodGlucose: {
    title: 'Blood Glucose (mg/dl) (normal 70-120 mg/dl)',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
    key: 'Blood glucose'
  },
  inr: {
    title: 'INR Levels',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
    key: 'INR'
  },
  weight: {
    title: 'Weight (lb)',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
    key: 'Weight'
  },
  bmi: {
    title: 'BMI',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
    key: 'BMI'
  },
  heightFeet: {
    title: 'Height - Feet',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [
      { value: null },
      { value: 3 },
      { value: 4 },
      { value: 5 },
      { value: 6 },
      { value: 7 },
    ],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'value',
    date: false,
    key: 'Height'
  },
  heightInches: {
    title: 'Height - Inches',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [
      { value: null },
      { value: 0 },
      { value: 1 },
      { value: 2 },
      { value: 3 },
      { value: 4 },
      { value: 5 },
      { value: 6 },
      { value: 7 },
      { value: 8 },
      { value: 9 },
      { value: 10 },
      { value: 11 },
    ],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'value',
    date: false,
  },
  painLevel: {
    title: 'Pain Level',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [
      { value: '0', label: '0' },
      { value: '1', label: '1' },
      { value: '2', label: '2' },
      { value: '3', label: '3' },
      { value: '4', label: '4' },
      { value: '5', label: '5' },
      { value: '6', label: '6' },
      { value: '7', label: '7' },
      { value: '8', label: '8' },
      { value: '9', label: '9' },
      { value: '10', label: '10' },
    ],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'label',
    date: false,
    key: 'Pain'
  },
  comment: {
    title: 'Comments',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
    key: 'Comment'
  },
};

export default model;
