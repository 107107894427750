import apiUtil from "../utils/api.utils";
import config from "../utils/configs";

const baseUrl = config.userService;

export const createLoginActivityRecord = async (action, user) => {
    await apiUtil.client.post(`${baseUrl}/api/v1.0/login`, { action, user });
    return true;
}

export const getCurrentUser = async () => {
    const user = await apiUtil.client.get(`${baseUrl}/userinfo`);
    return user.data;
}