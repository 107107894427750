import React from 'react';
import {
  makeStyles,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Chip,
  FormControlLabel,
  Checkbox,
  Switch
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import PhoneMask from '../../components/utils/masks/phone.mask';
import ZipMask from '../../components/utils/masks/zip.mask';
import ChipInput from 'material-ui-chip-input';
import FileSelector from '../../components/utils/fileselector/fileselector';
import CheckBlankIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckIcon from '@material-ui/icons/CheckBoxOutlined';
import InputMask from 'react-input-mask';
import {Alert, AlertTitle} from "@material-ui/lab";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiSelect-selectMenu': {
      textAlign: 'left',
    },
  },
  form: {
    '& .Mui-required': {
      color: theme.palette.error.main,
    },
  },
  formControl: {
    width: '100%',
  },
  btnContainer: {
    justifyContent: 'flex-end',
    padding: '1rem',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  toggleContainer: {
    alignSelf: 'flex-start',
    margin: '1rem 0 0 0',
  },
  toggle: {},
  listSubHeader: {
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(1, 4)
  }
}));
const FormFieldsGenerator = ({
  model,
  onChange,
  requireTitle = false,
  edit = true,
}) => {
  const classes = useStyles();
  const [formModel, setFormModel] = React.useState(model);

  React.useEffect(() => {
    setFormModel(model);
  }, [model]);

  const handleCheckChange = (event) => {
    const value = event.target.checked;
    const obj = { ...formModel };
    obj[event.target.name].value = value;
    obj[event.target.name].isDirty = true;
    if (obj[event.target.name].required) {
      if (obj[event.target.name].value === '') {
        obj[event.target.name].isValid = false;
      } else {
        obj[event.target.name].isValid = true;
      }
    } else {
      obj[event.target.name].isValid = true;
    }
    setFormModel(obj);
    if (onChange) {
      onChange(obj, event.target.name);
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    const obj = { ...formModel };




    obj[event.target.name].value = value;
    obj[event.target.name].isDirty = true;
    if (obj[event.target.name].required) {
      if (obj[event.target.name].value === '') {
        obj[event.target.name].isValid = false;
      } else {
        obj[event.target.name].isValid = true;
      }
    } else {
      obj[event.target.name].isValid = true;
    }
    setFormModel(obj);
    if (onChange) {
      onChange(obj, event.target.name);
    }
  };

  const onDateChange = (date, key) => {
    const obj = { ...formModel };
    obj[key].value = date;
    obj[key].isDirty = true;
    if (obj[key].required) {
      if (obj[key].value === '' || !obj[key].value) {
        obj[key].isValid = false;
      } else {
        console.log('date is', date);
        obj[key].isValid = moment(date).isValid();
      }
    } else {
      obj[key].isValid = true;
    }
    setFormModel(obj);
    if (onChange) {
      onChange(obj, key);
    }
  };

  const getLabel = (value, modelItem) => {
    try {
      const item = modelItem.dropDownItems.find(
        (item) => item[modelItem.dropDownItemValue] === value
      );
      if (item) {
        return `${item[modelItem.dropDownItemLabel]} ${
          modelItem.showDescription && item.description
            ? `(${item.description})`
            : ''
        }`;
      } else {
        return null;
      }
    } catch (err) {
      console.log('value', value);
      console.log('modelItem', modelItem);
    }
  };

  const handleTagAdd = (field, tag) => {
    const obj = { ...formModel };
    obj[field].isDirty = true;
    obj[field].value = [...obj[field].value, tag];
    if (obj[field].required) {
      if (obj[field].value === '') {
        obj[field].isValid = false;
      } else {
        obj[field].isValid = true;
      }
    } else {
      obj[field].isValid = true;
    }
    setFormModel(obj);
    if (onChange) {
      onChange(obj, field);
    }
  };

  const handleTagDelete = (field, tag) => {
    const obj = { ...formModel };
    obj[field].isDirty = true;
    obj[field].value = obj[field].value.filter((c) => c !== tag);
    if (obj[field].required) {
      if (obj[field].value === '') {
        obj[field].isValid = false;
      } else {
        obj[field].isValid = true;
      }
    } else {
      obj[field].isValid = true;
    }
    setFormModel(obj);
    if (onChange) {
      onChange(obj, field);
    }
  };

  const onFileSelected = (e, field) => {
    const obj = { ...formModel };
    let file = null;
    obj[field].isDirty = true;
    if (e.target.files && e.target.files.length === 1) {
      file = e.target.files[0];
      obj[field].value = URL.createObjectURL(file);
    } else {
      obj[field].value = null;
    }
    obj[field].isDirty = true;
    if (obj[field].required) {
      if (obj[field].value === '') {
        obj[field].isValid = false;
      } else {
        obj[field].isValid = true;
      }
    } else {
      obj[field].isValid = true;
    }
    onChange(obj, field, file);
  };

  const isSelected = (selectVal, item) => {
    // return item?.value?.findIndex(selectVal) > -1;
    return item?.value?.findIndex((s) => s === selectVal) > -1;
  };

  const getStateProvidenceLabel = (value, modelItem) => {
    try {
      let items = [];
      modelItem?.dropDownItems?.forEach(item => {
        items = [...items, item, ...item?.providencesStates]
      })
      console.log(items)
      const item = items?.find(item => item[modelItem.dropDownItemValue] === value);
      console.log('item is', item, value)
      if (item) {
        return `${item[modelItem.dropDownItemLabel]} ${
          modelItem.showDescription && item.description
            ? `(${item.description})`
            : ''
        }`;
      } else {
        return null;
      }
    } catch (err) {
      console.log('value', value);
      console.log('modelItem', modelItem);
    }
  };

  const getStateProvidenceOptions = model => {
    let items = []
    model?.dropDownItems?.forEach(item => {
      items = [...items, {...item, isSubHeader: true }, ...item?.providencesStates];

    })
    return items?.map(item =>
      <MenuItem value={item[model.dropDownItemValue]}
                key={item[model.dropDownItemValue]}
                disabled={item?.disabled || item?.isSubHeader}>
        {item[model.dropDownItemLabel]}
      </MenuItem>
    )
  }

  const getDateValue = (dateProperty) => {
    if (dateProperty?.value) {
      if (dateProperty?.displayUtc) {
        return moment(dateProperty.value).utc()
      } else {
        return dateProperty.value;
      }
    } else {
      return null;
    }
  }

  return (
    <div className={classes.root}>
      {Object.keys(model)
        .filter(
          (key) =>
            !model[key].hidden &&
            (!requireTitle || (requireTitle && model[key].title))
        )
        .filter(
          (key) =>
            !model[key].isHidden ||
            (model[key].isHidden &&
              model[model[key].isHidden.field].value !==
                model[key].isHidden.value)
        )
        .map((key, index) => (
          <FormGroup key={index}>
            {model[key].isStateProvidence ? (
              <FormControl className={classes.formControl}>
                <InputLabel
                  id={`${key}-select-label`}
                  required={model[key].required}
                  shrink
                >
                  {model[key].title}
                </InputLabel>
                <Select
                  name={key}
                  fullWidth
                  labelId={`${key}-select-label`}
                  id={`${key}-select`}
                  value={model[key].value || ''}
                  renderValue={(value) => getStateProvidenceLabel(value, model[key])}
                  onChange={handleChange}
                  disabled={!edit}
                  displayEmpty
                >
                  {getStateProvidenceOptions(model[key])}
                </Select>
              </FormControl>
              ) : model[key].isToggle ? (
              <FormControlLabel
                className={classes.toggleContainer}
                name={key}
                control={
                  <Switch
                    name={key}
                    className={classes.toggle}
                    onChange={handleCheckChange}
                    color="primary"
                    checked={model[key].value || ''}
                    disabled={!edit || model[key].edit === false}
                  />
                }
                label={model[key].title}
                labelPlacement="start"
              />
            ) : model[key].isFile ? (
              <div style={{ margin: '1rem 0' }}>
                <img
                  src={model[key].value}
                  alt="User"
                  height={`${model[key].size ? `${model[key].size}` : '120'}`}
                />
                <br />
                <FileSelector
                  label={model[key].title}
                  onFileSelected={(evt) => onFileSelected(evt, key)}
                  disabled={!edit}
                />
              </div>
            ) : model[key].isTagsComponent ? (
              <ChipInput
                disabled={!edit}
                color={"primary"}
                label={model[key].title}
                fullWidth
                helperText={
                  model[key].required && !model[key].value
                    ? `${model[key].title} is required!`
                    : null
                }
                error={!model[key].isValid && model[key].isDirty}
                required={model[key].required}
                value={model[key].value}
                blurBehavior={"add"}
                onAdd={(chip) => handleTagAdd(key, chip)}
                onDelete={(chip) => handleTagDelete(key, chip)}
                dataSource={
                  model[key].dataSource ? model[key].dataSource : null
                }
              />
            ) : model[key].dropDown && !model[key].multiSelect ? (
              <FormControl className={classes.formControl}>
                <InputLabel
                  id={`${key}-select-label`}
                  required={model[key].required}
                  shrink
                >
                  {model[key].title}
                </InputLabel>
                <Select
                  name={key}
                  fullWidth
                  labelId={`${key}-select-label`}
                  id={`${key}-select`}
                  value={model[key].value || ''}
                  renderValue={(value) => getLabel(value, model[key])}
                  onChange={handleChange}
                  disabled={!edit}
                  displayEmpty
                >
                  {model[key].dropDownItems &&
                    model[key].dropDownItems.map((item) => (
                      <MenuItem value={item[model[key].dropDownItemValue]} disabled={item?.disabled}>
                        {item[model[key].dropDownItemLabel]}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            ) : model[key].checkbox ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={model[key].value || ''}
                    onChange={handleCheckChange}
                    name={key}
                    id={`${key}-checkbox`}
                    disabled={!edit}
                  />
                }
                label={model[key].title}
              />
            ) : model[key].dropDown && model[key].multiSelect ? (
              <FormControl className={classes.formControl}>
                <InputLabel
                  id={`${key}-select-label`}
                  required={model[key].required}
                >
                  {model[key].title}
                </InputLabel>
                <Select
                  name={key}
                  fullWidth
                  labelId={`${key}-select-label`}
                  id={`${key}-select`}
                  value={model[key].value || ''}
                  onChange={handleChange}
                  disabled={!edit}
                  multiple
                  renderValue={(selected) => (
                    <>
                      {model[key].optionDisplay !== 'list' &&
                      <div className={classes.chips}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={getLabel(value, model[key])}
                            className={classes.chip}
                          />
                        ))}
                      </div>}
                      {model[key].optionDisplay === 'list' && selected.map(value => getLabel(value, model[key])).join(', ') }
                    </>
                  )}
                >
                  {model[key].dropDownItems &&
                    model[key].dropDownItems.map((item) => (
                      <MenuItem value={item[model[key].dropDownItemValue]}>
                        <div style={{ marginRight: '.5rem' }}>
                          {isSelected(
                            item[model[key].dropDownItemValue],
                            model[key]
                          ) ? (
                            <CheckIcon color="primary" />
                          ) : (
                            <CheckBlankIcon />
                          )}
                        </div>
                        <div>
                          {`${item[model[key].dropDownItemLabel]} ${
                            model[key].showDescription && item.description
                              ? `(${item.description})`
                              : ''
                          }`}
                        </div>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            ) : model[key].time ? (
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDateTimePicker
                  size="small"
                  fullWidth
                  name={key}
                  disabled={!edit}
                  required={model[key].required}
                  value={model[key].value}
                  label={model[key].title}
                  format="MM/DD/YYYY hh:mm a"
                  onChange={(date) => onDateChange(date, key)}
                  allowKeyboardControl="true"
                  helperText={
                    model[key].required && !model[key].value
                      ? `${model[key].title} is required!`
                      : null
                  }
                  error={!model[key].isValid && model[key].isDirty}
                />
              </MuiPickersUtilsProvider>
            ) : model[key].date ? (
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  size="small"
                  fullWidth
                  name={key}
                  disabled={!edit || model[key].disabled}
                  required={model[key].required}
                  value={getDateValue(model[key])}
                  label={model[key].title}
                  format="MM/DD/YYYY"
                  onChange={(date) => onDateChange(date, key)}
                  allowKeyboardControl="true"
                  ampm={false}
                  InputLabelProps={{ shrink: true }}
                  helperText={
                    model[key].required && !model[key].value
                      ? `${model[key].title} is required!`
                      : null
                  }
                  maxDate={model[key].maxDate ? model[key].maxDateValue: undefined}
                  error={!model[key].isValid && model[key].isDirty}
                />
                {model[key]?.showAlert &&
                  <Alert severity={"error"}>
                    <AlertTitle>{model[key]?.alertText ?? `Alert`}</AlertTitle>
                  </Alert>
                }
              </MuiPickersUtilsProvider>
            ) : model[key].inputMask === 'phone' ? (
              <PhoneMask
                name={key}
                canEdit={edit}
                required={model[key].required}
                label={model[key].title}
                onChange={handleChange}
                value={model[key].value || ''}
                helperText={
                  model[key].required && model[key].value === ''
                    ? `${model[key].title} is required!`
                    : null
                }
                error={!model[key].isValid && model[key].isDirty}
              />
            ) : model[key].inputMask === 'zip' ? (
              <ZipMask
                name={key}
                required={model[key].required}
                label={model[key].title}
                onChange={handleChange}
                value={model[key].value || ''}
                helperText={
                  model[key].required && model[key].value === ''
                    ? `${model[key].title} is required!`
                    : null
                }
                error={!model[key].isValid && model[key].isDirty}
              />
            ) : model[key].inputMask === 'ssn' ? (
              <InputMask mask="999-99-9999"
                         name={key}
                         onChange={handleChange}
                         label={model[key].title}
                         value={model[key].value || ''}
                         disabled={!edit || model[key].edit === false}
                         maskChar="">
                {() =>
                  <TextField size="small"
                             fullWidth
                             name={key}
                             required={model[key].required}
                             label={model[key].title}
                  />
                }
              </InputMask>
            ) : (
              <TextField
                size="small"
                fullWidth
                name={key}
                disabled={!edit || model[key].edit === false}
                autoComplete="off"
                type={model[key].textType || 'text'}
                required={model[key].required}
                label={model[key].title}
                value={model[key].value || ''}
                multiline={model[key].multiline}
                minRows={model[key].multilineRows}
                inputProps={{ maxLength: model[key].maxCharaters ?? null }}
                placeholder={
                  model[key].placeholder ? model[key].placeholder : null
                }
                helperText={
                  model[key].required && model[key].value === ''
                    ? `${model[key].title} is required!`
                    : null
                }
                error={!model[key].isValid && model[key].isDirty}
                onChange={handleChange}
              />
            )}
          </FormGroup>
        ))}
    </div>
  );
};

export default FormFieldsGenerator;
