import apiUtil from "../utils/api.utils";
import config from "../utils/configs";

const baseUrl = `${config.medicationOrderService}/api/v1.0`;


export const getResidentMar = async (residentId, month, year) => {
  try {
    const response = await Promise.all([
      getResidentMarOrders(residentId, month, year),
      getResidentMarData(residentId, month, year)
    ])

    let medicationOrders = response[0];
    const events = response[1];
    medicationOrders = medicationOrders.map(medicationOrder => {
      if (!medicationOrder?.isPrn) {
        if (medicationOrder?.dosageInstructions) {
          medicationOrder.dosageInstructions = medicationOrder.dosageInstructions.map(dosageInstruction => {
            dosageInstruction.events = events.filter(event => event.dosageInstruction === dosageInstruction._id);
            return dosageInstruction;
          })
        }
      } else {
        const prnEvents = events.filter(event => (event.medicationOrder === medicationOrder?._id ||
          event?.medicationOrder?._id === medicationOrder?._id));
        medicationOrder.dosageInstructions = [{ events: prnEvents }];
      }
      return medicationOrder;
    });

    return medicationOrders;


    // let url = `${baseUrl}/residents/${residentId}/mar?month=${month}&year=${year}`;
    // const response = await apiUtil.client.get(url);
    // return response.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const getResidentMarOrders = async (residentId, month, year) => {
  try {
    let url = `${baseUrl}/residents/${residentId}/mar?month=${month}&year=${year}`;
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const getResidentMarData = async (residentId, month, year) => {
  try {
    let url = `${baseUrl}/residents/${residentId}/mar/events?month=${month}&year=${year}`;
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const getResidentMarReviewedBy = async (residentId, year, month) => {
  try {
    let url = `${baseUrl}/residents/${residentId}/mar/${year}/${month}`;
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const updateResidentMarReviewedBy = async (residentId, year, month, payload) => {
  try {
    let url = `${baseUrl}/residents/${residentId}/mar/${year}/${month}`;
    const response = await apiUtil.client.put(url, payload);
    return response.data;
  } catch (error) {
    return { error: true, message: error };
  }
}
