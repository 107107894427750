import React from 'react';
import {
  Button, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControl, InputLabel, MenuItem, Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, TextField, Typography
} from "@material-ui/core";
import {FormFieldsGenerator, isModelValid} from "../../../utils/forms";
import days from "./days.json";
import {MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment/moment";
import {ObjectId} from "bson";

export const EditMeal = ({meal, open, onClose, onSave}) => {
  const [model, setModel] = React.useState()

  React.useEffect(() => {
    if (open && meal) {
      setModel(JSON.parse(JSON.stringify(meal)));
    }
  }, [open, meal]);

  const onTemplateChange = (templateId, e) => {
    const obj = { ...model };
    obj.templates.value = obj.templates.value.map((template) => {
      if (template._id === templateId) {
        template[e.target.name] = e.target.value;
      }
      return template;
    });
    setModel(obj);
  }

  const onTimeChange = (templateId, time) => {
    const obj = { ...model };
    obj.templates.value = obj.templates.value.map((template) => {
      if (template._id === templateId) {
        template.time = time;
        template.hour = moment(time).hour;
        template.minute = moment(time).minute;
      }
      return template;
    });
    setModel(obj);
  }

  const onDayChange = (e, id) => {
    const obj = { ...model };
    obj.templates.value = meal.templates.value.map((template) => {
      if (template._id === id) {
        template.day = e.target.value;
      }
      return template;
    });
    setModel(obj);
  };

  const onFormChange = (e, field) => {
    const obj = { ...meal, ...e };
    if (field === 'frequency') {
      switch (obj.frequency.value) {
        case 'QD':
          obj.templates.value = [
            {
              _id: new ObjectId(),
              time: moment({ hour: 8, minute: 0 }),
              hour: 8,
              minute: 0,
              comments: null,
            },
          ];
          break;
        case 'BIW':
          obj.templates.value = [
            {
              _id: new ObjectId(),
              day: '0',
              time: moment({ hour: 8, minute: 0 }),
              hour: 8,
              minute: 0,
              comments: null,
            },
            {
              _id: new ObjectId(),
              day: 2,
              time: moment({ hour: 8, minute: 0 }),
              hour: 8,
              minute: 0,
              comments: null,
            },
          ];
          break;
        case 'TIW':
          obj.templates.value = [
            {
              _id: new ObjectId(),
              day: 0,
              time: moment({ hour: 8, minute: 0 }),
              hour: 8,
              minute: 0,
              comments: null,
            },
            {
              _id: new ObjectId(),
              day: 2,
              time: moment({ hour: 8, minute: 0 }),
              hour: 8,
              minute: 0,
              comments: null,
            },
            {
              _id: new ObjectId(),
              day: 4,
              time: moment({ hour: 8, minute: 0 }),
              hour: 8,
              minute: 0,
              comments: null,
            },
          ];
          break;
        case '4XW':
          obj.templates.value = [
            {
              _id: new ObjectId(),
              day: 0,
              time: moment({ hour: 8, minute: 0 }),
              hour: 8,
              minute: 0,
              comments: null,
            },
            {
              _id: new ObjectId(),
              day: 2,
              time: moment({ hour: 8, minute: 0 }),
              hour: 8,
              minute: 0,
              comments: null,
            },
            {
              _id: new ObjectId(),
              day: 4,
              time: moment({ hour: 8, minute: 0 }),
              hour: 8,
              minute: 0,
              comments: null,
            },
            {
              _id: new ObjectId(),
              day: 6,
              time: moment({ hour: 8, minute: 0 }),
              hour: 8,
              minute: 0,
              comments: null,
            },
          ];
          break;
        case '5XW':
          obj.templates.value = [
            {
              _id: new ObjectId(),
              day: 1,
              time: moment({ hour: 8, minute: 0 }),
              hour: 8,
              minute: 0,
              comments: null,
            },
            {
              _id: new ObjectId(),
              day: 2,
              time: moment({ hour: 8, minute: 0 }),
              hour: 8,
              minute: 0,
              comments: null,
            },
            {
              _id: new ObjectId(),
              day: 3,
              time: moment({ hour: 8, minute: 0 }),
              hour: 8,
              minute: 0,
              comments: null,
            },
            {
              _id: new ObjectId(),
              day: 4,
              time: moment({ hour: 8, minute: 0 }),
              hour: 8,
              minute: 0,
              comments: null,
            },
            {
              _id: new ObjectId(),
              day: 5,
              time: moment({ hour: 8, minute: 0 }),
              hour: 8,
              minute: 0,
              comments: null,
            },
          ];
          break;
        case '6XW':
          obj.templates.value = [
            {
              _id: new ObjectId(),
              day: 1,
              time: moment({ hour: 8, minute: 0 }),
              hour: 8,
              minute: 0,
              comments: null,
            },
            {
              _id: new ObjectId(),
              day: 2,
              time: moment({ hour: 8, minute: 0 }),
              hour: 8,
              minute: 0,
              comments: null,
            },
            {
              _id: new ObjectId(),
              day: 3,
              time: moment({ hour: 8, minute: 0 }),
              hour: 8,
              minute: 0,
              comments: null,
            },
            {
              _id: new ObjectId(),
              day: 4,
              time: moment({ hour: 8, minute: 0 }),
              hour: 8,
              minute: 0,
              comments: null,
            },
            {
              _id: new ObjectId(),
              day: 5,
              time: moment({ hour: 8, minute: 0 }),
              hour: 8,
              minute: 0,
              comments: null,
            },
            {
              _id: new ObjectId(),
              day: 6,
              time: moment({ hour: 8, minute: 0 }),
              hour: 8,
              minute: 0,
              comments: null,
            },
          ];
          break;
        default:
      }
    }
    setModel(obj);
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        {'Meal'}
      </DialogTitle>
      <DialogContent dividers>
        <FormFieldsGenerator model={model} onChange={onFormChange} />
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Day</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Comments</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {model?.templates?.value?.sort((a, b) => a?.time < b?.time ? 1: -1)?.map((template) => (
                <TableRow>
                  <TableCell>
                    {template.day && (
                      <FormControl style={{ width: '100%' }}>
                        <InputLabel htmlFor="day">Day</InputLabel>
                        <Select
                          disableUnderline={true}
                          fullWidth
                          style={{ width: '100%' }}
                          value={template.day || null}
                          onChange={(evt) => onDayChange(evt, template._id)}
                          inputProps={{
                            name: `day`,
                            id: `day`,
                          }}
                        >
                          {days &&
                            days.map((day, i) => {
                              return (
                                <MenuItem key={i} value={day.value}>
                                  {day.title}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    )}
                    {!template.day && (
                      <Typography variant="subtitle1">N/A</Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <TimePicker
                        format="h:mm a (H:mm)"
                        value={template.time}
                        onChange={(time) =>
                          onTimeChange(template._id, time)
                        }
                        ampm={false}
                      />
                    </MuiPickersUtilsProvider>
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={template.comments}
                      name="comments"
                      onChange={(e) => onTemplateChange(template._id, e)}
                    ></TextField>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={!isModelValid(model)}
          onClick={() => onSave(model)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
