import React from 'react';
import {
  Paper,
  makeStyles,
  Typography,
  Grid,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Avatar,
  IconButton,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Hidden,
  List,
  ListItemAvatar,
  ListItemText,
  ListItem,
    Link
} from '@material-ui/core';
import DateNavigation from '../medicationorders/date.navigation';
import moment from 'moment';
import {
  createResidentPrnTask, getCareplansStatus,
  getResidentPrnTasks,
  getResidentTasks,
  updateResidentTask,
} from '../../../services/careplan.service';
import {
  red,
  yellow,
  green,
  grey,
  orange,
  blue,
} from '@material-ui/core/colors';
import EditIcon from '@material-ui/icons/Edit';
import MobileTask from './mobile.task';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { GlobalNotificationContext } from '../../notification/globalnotificationprovider';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { ResidentContext } from '../../../context/residentcontext';
import useHasRole from "../../../hooks/user.role.hook";
import {PageTitle} from "../../utils";
import {ListAlt, RadioButtonUncheckedRounded} from "@material-ui/icons";
import {Link as RouterLink} from "react-router-dom";
import {DatetimeInput} from "../../../utils/datetime/DatetimeInput";
import {UserContext} from "../../../context/usercontext";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import TableLoadingIndicator from "../../../utils/indicators/table.loading";
import {ListLoading} from "../../../utils/indicators/list.loading";
import {getTenant} from "../../../services/tenant.service";
import {getAbbreviationFromName, getStatusFromName} from "./utils";

const contrast = 400;
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  avatarSmall: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  scheduled: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: grey[500],
  },
  readyToPass: {
    background: grey[700],
  },
  readyToPassIcon: {
    color: grey[700]
  },
  given: {
    background: green[contrast],
  },
  givenIcon: {
    color: green[contrast]
  },
  late: {
    background: orange[contrast],
  },
  lateIcon: {
    color: orange[contrast]
  },
  missed: {
    background: red[contrast],
  },
  missedIcon: {
    color: red[contrast]
  },
  hold: {
    background: yellow[contrast],
  },
  holdIcon: {
    color: yellow[contrast]
  },
  sa: {
    background: blue[contrast],
  },
  saIcon: {
    color: blue[contrast]
  },
  na: {
    background: grey[contrast],
  },
  naIcon: {
    color: grey[contrast]
  },
  refused: {
    background: orange[contrast],
  },
  refusedIcon: {
    color: orange[contrast]
  },
  destruction: {
    background: yellow[contrast],
  },
  destructionIcon: {
    background: yellow[contrast]
  },
  independent: {
    background: green[contrast],
  },
  verbalPrompt: {
    background: green[contrast],
  },
  physicalAssist: {
    background: green[contrast],
  },
  minimalAssist: {
    background: green[contrast],
  }
}));



const ResidentTasks = ({ match }) => {
  const classes = useStyles();
  const {selectedTenant} = React.useContext(UserContext);
  const [residentId, setResidentId] = React.useState(null);
  const [tasks, setTasks] = React.useState([]);
  const [prns, setPrns] = React.useState([]);
  const [editIndex, setEditIndex] = React.useState(-1);
  const [editTask, setEditTask] = React.useState(null);
  const { addNotification } = React.useContext(GlobalNotificationContext);
  const { dispatch } = React.useContext(ResidentContext);
  const { getUsers } = React.useContext(UserContext);
  const [users, setUsers] = React.useState([]);
  const {checkPermission} = useHasRole();
  const canUpdate = checkPermission('ETASK_UPDATE')
  const [loading, setLoading] = React.useState(false);
  const [customStatus, setCustomStatus] = React.useState();
  const [currentTenant, setCurrentTenant] = React.useState();

  React.useEffect(() => {
    if (selectedTenant && match.params.residentId) {
      setResidentId(match.params.residentId);
      loadData(
        match.params.residentId,
        moment(Date.now()).format('YYYY-MM-DD')
      );
    }
    // eslint-disable-next-line
  }, [match.params.residentId, selectedTenant]);

  React.useEffect(() => {
    if (currentTenant?.features?.careplans?.customStatus?.enabled) {
      setLoading(true)
      getCustomStatuses().then(() => {});
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [currentTenant?.features?.careplans?.customStatus?.enabled]);

  const getCustomStatuses = async () => {
    setLoading(true);
    const response = await getCareplansStatus(currentTenant?._id);
    if (!response?.error) {
      setCustomStatus(response);
    }
    setLoading(false);
  }

  const loadData = async (id, date) => {
    setLoading(true);
    const response = await Promise.all([
      getResidentTasks(id, date),
      getResidentPrnTasks(id),
      getTenant(selectedTenant?._id)
    ]);
    setTasks(response[0]);
    setPrns(response[1]);
    setCurrentTenant(response[2])
    if (users?.length < 1) {
      await loadUsers();
    }
    setLoading(false);
  };


  const loadUsers = async () => {
    const userResponse = await getUsers();
    if (!userResponse?.error) {
      setUsers(userResponse);
    }
  }

  const onDateChange = async (date) => {
    setEditIndex(-1);
    setEditTask(null);
    await loadData(residentId, moment(date).format('YYYY-MM-DD'));
  };

  const onAdministeredChanged = date => {
    const obj = { ...editTask };
    obj.administered = date;
    setEditTask(obj);
  }


  const getItemClass = (event) => {
    const status = getStatusFromName(event?.status, customStatus)
    if (status?.isCustom) {
      return classes.sa;
    }

    if (event?.status) {
      switch (event.status) {
        case 'Scheduled':
          return classes.readyToPass;
        case 'Given':
          return classes.given;
        case 'Late':
          return classes.late;
        case 'Missed':
          return classes.missed;
        case 'Hold':
          return classes.hold;
        case 'SA':
          return classes.sa;
        case 'NA':
          return classes.na;
        case 'Refused':
          return classes.refused;
        case 'Destruction':
          return classes.destruction;
        case 'Independent':
          return classes.independent;
        case 'Verbal prompt':
          return classes.verbalPrompt;
        case 'Physical Assist':
          return classes.physicalAssist;
        case 'Minimal Assist':
          return classes.minimalAssist;
        default:
          return classes.scheduled;
      }
    }
  };

  const edit = (task, index) => {
    setEditIndex(index);
    setEditTask(task);
  };

  const onEditChange = (e) => {
    const obj = { ...editTask };
    obj[e.target.name] = e.target.value;
    setEditTask(obj);
  };

  const cancelEdit = () => {
    setEditIndex(-1);
    setEditTask(null);
  };

  const updateTask = async () => {
    const task = await updateResidentTask(residentId, editTask._id, editTask);
    const obj = tasks.map((t) => (t._id === task._id ? task : t));
    setTasks(obj);
    setEditIndex(-1);
    setEditTask(null);
    dispatch({ type: 'REFRESH_ALERTS' });
  };

  const onTaskUpdated = (updated) => {
    const obj = tasks.map((t) => (t._id === updated._id ? updated : t));
    setTasks(obj);
    setEditIndex(-1);
    setEditTask(null);
    dispatch({ type: 'REFRESH_ALERTS' });
  };

  const canEdit = (tk) => {
    if (!canUpdate) return false;
    if (tk && moment(tk.scheduledTime).subtract(1, 'hour') < moment()) {
      return true;
    } else {
      return false;
    }
  };

  const addPrn = async (e, newValue) => {
    if (newValue) {
      const item = {
        careplan: newValue.careplanId,
        goalId: newValue.goalId,
        intervention: newValue.interventionId,
        scheduledTime: Date.now(),
        name: newValue.interventionName,
        status: 'Scheduled',
        resident: newValue.resident,
        residentId: newValue.resident._id,
        details: {
          careplan: newValue.careplanName,
          goal: newValue.goalName,
          intervention: newValue.interventionName,
        },
        isPrn: true,
        notes: `Added PRN on ${moment().format('MM/DD/YYYY hh:mm a')}`,
      };
      const task = await createResidentPrnTask(residentId, item);
      if (task) {
        const obj = [...tasks, task];
        setTasks(obj);
      } else {
        addNotification('Error creating prn task', 'error');
      }
    }
  };

  const onScheduledTimeChange = (date) => {
    const obj = { ...editTask };
    obj.scheduledTime = date;
    setEditTask(obj);
  };

  const onAdministeredUserChange = e => {
    const obj = {...editTask};
    const user = users?.find(u => u._id === e.target.value);
    obj.administeredUser = user;
    obj.updateUser = user;
    setEditTask(obj);
  }

  const onQuickUpdate = async (task, status) => {
    await quickUpdate(task, status);
  }

  const quickUpdate = async (entity, status) => {
    entity.status = status;
    const task = await updateResidentTask(residentId, entity._id, entity);
    const obj = tasks.map((t) => (t._id === task._id ? task : t));
    setTasks(obj);
  }

  const getItemIconClass = event => {
    const status = getStatusFromName(event?.status, customStatus)
    if (status?.isCustom) {
      return classes.saIcon;
    }


    if (event?.status) {
      switch (event.status) {
        case 'Scheduled':
          if (canEdit(event)) {
            return classes.readyToPassIcon;
          } else {
            return classes.scheduledIcon;
          }
        case 'Given':
          return classes.givenIcon;
        case 'Late':
          return classes.lateIcon;
        case 'Missed':
          return classes.missedIcon;
        case 'Hold':
          return classes.holdIcon;
        case 'SA':
          return classes.saIcon;
        case 'NA':
          return classes.naIcon;
        case 'Refused':
          return classes.refusedIcon;
        case 'Destruction':
          return classes.destructionIcon;
        case 'Independent':
          return 'I'
        case 'Verbal prompt':
          return 'VP';
        case 'Physical Assist':
          return 'PA';
        case 'Minimal Assist':
          return 'MA';
        default:
          return classes.scheduledIcon;
      }
    }
  }

  const getIconContent = task => {

    const abbreviation = getAbbreviationFromName(task?.status, customStatus)
    if (abbreviation) {
      return abbreviation;
    }

    switch (task?.status) {
      case 'Independent':
        return "I";
      case "Verbal prompt":
        return "VP";
      case "Physical Assist":
        return "PA";
      case "Minimal Assist":
        return "MA";
      case "Refused":
        return "R";
      default:
          return  (
            <i className="fas fa-tasks"></i>
          )
    }
  }

  const getLink = (task) => {
    if (task?.goal) {
      return (
          <Link component={RouterLink}
                to={`/residents/${residentId}/careplans/${task?.careplan?._id}/goals/${task?.goal}/interventions/${task?.intervention}`}>
            {task?.details?.intervention ?? 'N/A'}
          </Link>

      )
    } else {
      return (
          <Link component={RouterLink}
                to={`/residents/${residentId}/careplans/${task?.careplan?._id}/interventions/${task?.intervention}`}>
            {task?.details?.intervention ?? 'N/A'}
          </Link>
      )
    }
  }

  return (
    <Paper elevation={24} className={classes.root}>
      <Grid container
            justifyContent={"space-between"}>
        <Grid item>
          <PageTitle title={"Resident eTask"} />
        </Grid>
        <Grid item>
          <Button startIcon={<ListAlt/>}
                  component={RouterLink}
                  to={`/residents/${residentId}/tasks/log`}
                  color={"primary"}>
            LOG
          </Button>
        </Grid>
      </Grid>
      {canUpdate &&
      <Autocomplete
        fullWidth
        style={{ height: '3.5rem' }}
        labelId="autocomplete-label"
        title="PRN"
        freeSolo
        id="combo-box-demo"
        options={prns}
        autoHighlight
        autoCorrect
        onChange={addPrn}
        getOptionLabel={(option) => option.interventionName}
        renderOption={(option, { selected }) => (
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <i class="fas fa-tasks"></i>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={option.interventionName}
              secondary={
                <>
                  <Typography variant="subtitle1">
                    Careplan: {option.careplanName}
                  </Typography>
                  <Typography variant="subtitle2">
                    Goal: {option.goalName}
                  </Typography>
                </>
              }
            />
          </ListItem>
        )}
        renderInput={(params) => (
          <TextField {...params} label="Add PRN" variant="outlined" />
        )}
      />}
      <DateNavigation onDateChange={onDateChange} />
      <Hidden smDown>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableCell>Task</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Discipline</TableCell>
              <TableCell />
              <TableCell>Status</TableCell>
              <TableCell>Administered</TableCell>
              <TableCell>Administered By</TableCell>
              <TableCell>Notes</TableCell>
            </TableHead>
            {loading &&
              <TableLoadingIndicator bodyOnly={true}
                                     rows={5}
                                     cols={7} />
            }
            <TableBody>
              {!loading && tasks?.sort((a, b) => a?.scheduledTime > b?.scheduledTime ? 1: -1)?.map((task, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      {getLink(task)}
                    </TableCell>
                    <TableCell>
                      {!task.isPrn && (
                        <>{moment(task.scheduledTime).format('hh:mm a')}</>
                      )}
                      {task.isPrn && editIndex !== i && (
                        <>{moment(task.scheduledTime).format('hh:mm a')}</>
                      )}
                      {task.isPrn && editIndex === i && (
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDateTimePicker
                            size="small"
                            fullWidth
                            name="scheduledTask"
                            required
                            value={editTask.scheduledTime}
                            label="Scheduled Time"
                            format="MM/DD/YYYY hh:mm a"
                            onChange={onScheduledTimeChange}
                            allowKeyboardControl="true"
                            helperText={'Scheduled time is required.'}
                            error={!editTask.scheduledTime}
                          />
                        </MuiPickersUtilsProvider>
                      )}
                    </TableCell>
                    <TableCell>
                      {task?.discipline &&
                      <span>
                      {task?.discipline?.role?.name} - {task?.discipline?.location?.name}
                      </span>
                      }
                    </TableCell>
                    {editIndex !== i && (
                      <>
                        <TableCell>
                          {canEdit(task) && (
                            <>
                            <IconButton onClick={() =>
                              quickUpdate(task, task?.administered ? 'Scheduled': task?.defaultQuickStatus)}>
                              {task?.administered && task?.status !== "Scheduled" && task?.status !== "Late" &&
                                task?.status !== "Missed" ?
                                <CheckCircleRoundedIcon className={getItemIconClass(task)} /> :
                                <RadioButtonUncheckedRounded />
                              }
                            </IconButton>
                            <IconButton
                              hidden={true}
                              size="small"
                              onClick={() => edit(task, i)}
                            >
                              <EditIcon />
                            </IconButton>
                            </>
                          )}
                        </TableCell>
                        <TableCell>
                          <Avatar
                            alt="Tasks"
                            className={[
                              classes.avatarSmall,
                              getItemClass(task),
                            ]}
                          >
                            {getIconContent(task)}
                          </Avatar>
                        </TableCell>
                        <TableCell>
                          {task.administered
                            ? moment(task.administered).format('hh:mm a')
                            : null}
                        </TableCell>
                        <TableCell>
                          {task.administeredUser
                            ? `${task.administeredUser.initials}`
                            : null}
                        </TableCell>
                        <TableCell>{task.notes}</TableCell>
                      </>
                    )}

                    {editIndex === i && (
                      <>
                        <TableCell>
                          <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            onClick={updateTask}
                          >
                            Save
                          </Button>
                          <Button size="small" onClick={cancelEdit}>
                            Cancel
                          </Button>
                        </TableCell>
                        <TableCell>
                          <FormControl size="small">
                            {currentTenant?.features?.careplans?.customStatus?.enabled &&
                              <Select
                                id="status"
                                label="Status"
                                value={editTask.status}
                                name="status"
                                onChange={onEditChange}
                              >
                                {currentTenant?.features?.careplans?.customStatus?.enabled &&
                                  customStatus?.map(status =>
                                    <MenuItem value={status?.name}>{status?.name}</MenuItem>
                                  )
                                }
                              </Select>
                            }
                            {!currentTenant?.features?.careplans?.customStatus?.enabled &&
                            <Select
                              id="status"
                              label="Status"
                              value={editTask.status}
                              name="status"
                              onChange={onEditChange}
                            >
                                  <MenuItem value={'Scheduled'}>Scheduled</MenuItem>
                                  <MenuItem value={'Given'}>Given</MenuItem>
                                  <MenuItem value={'Late'}>Late</MenuItem>
                                  <MenuItem value={'Missed'}>Missed</MenuItem>
                                  <MenuItem value={'Hold'}>Hold</MenuItem>
                                  <MenuItem value={'SA'}>Self Administered</MenuItem>
                                  <MenuItem value={'NA'}>Not Administered</MenuItem>
                                  <MenuItem value={'Refused'}>Refused</MenuItem>
                                  <MenuItem value={"Independent"}>Independent</MenuItem>
                                  <MenuItem value={"Verbal prompt"}>Verbal prompt</MenuItem>
                                  <MenuItem value={"Physical Assist"}>Physical Assist</MenuItem>
                                  <MenuItem value={"Minimal Assist"}>Minimal Assist</MenuItem>
                            </Select>}
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <DatetimeInput value={editTask?.administered}
                                         onChange={onAdministeredChanged}
                                         format={"MM/DD/YYYY hh:mm"}
                          />
                        </TableCell>
                        <TableCell>
                          <Select fullWidth
                                  value={editTask?.administeredUser?._id}
                                  onChange={onAdministeredUserChange}
                          >
                            {users?.sort((a, b) => a?.lastName > b?.lastName ? 1: -1)?.map(user =>
                              <MenuItem value={user?._id}>{user?.firstName} {user?.lastName} ({user?.initials})</MenuItem>
                            )}
                          </Select>
                        </TableCell>
                        <TableCell>
                          <TextField
                            size="small"
                            name="notes"
                            multiline
                            rows={3}
                            value={editTask.notes}
                            onChange={onEditChange}
                          />
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Hidden>
      <Hidden mdUp>
        {loading &&
        <ListLoading rows={5} />
        }
        <List>
        {!loading && tasks?.sort((a,b) => a?.scheduledTime > b?.scheduledTime ? 1: -1)?.map(task =>
          <MobileTask task={task}
                      onTaskUpdated={onTaskUpdated}
                      onQuickUpdate={onQuickUpdate}
                      users={users}
                      customStatus={customStatus}
                      canUpdate={canUpdate} />
        )}
        </List>
      </Hidden>
    </Paper>
  );
};

export default ResidentTasks;
