import React from 'react';
import {
  Breadcrumbs, Link,
} from "@material-ui/core";
import {ResidentFileContext} from "../context/ResidentFilesContext";
import {getFolderChildrenFilesAction, onBreadcrumbSelectedAction, selectFileAction} from "../context/Actions";
import {CONSTANTS} from "../context/Constants";

export const FileBreadcrumbs = () => {
  const {state, dispatch} = React.useContext(ResidentFileContext);

  const handleBreadcrumbSelect = (breadcrumb) => {
    if (breadcrumb?.type !== "file") {
      onBreadcrumbSelectedAction(dispatch, breadcrumb, state?.files);
      getFolderChildrenFilesAction(dispatch, breadcrumb, state?.files);
      dispatch({type: CONSTANTS.SET_SELECTED_FILE, payload: null});
    } else {
      selectFileAction(dispatch, breadcrumb, state?.files);
    }

  }

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {state?.breadcrumbs?.map(crumb =>
        <Link color="inherit"
              style={{cursor: "pointer"}}
              onClick={() => handleBreadcrumbSelect(crumb)}>
          {crumb?.label}
        </Link>)
      }
    </Breadcrumbs>
  )
}
