import React from "react";
import { View, Text, Image, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";

import config from '../../../../utils/configs';


const styles = StyleSheet.create({
  container: {
    backgroundColor: "#2196f3",
    color: "#fff",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: 70,
  },
  reportTitle: {
    marginTop: 10,
    fontSize: 16,
  },
  addressContainer: {
    padding: 5
  },
  address: {
    fontSize: 12,
  },
  logoContainer: {
    margin: 5,
  },
  logo: {
    height: "35px",
    width: "60px",
  },
  residentImageContainer: {
    display: 'flex',
    flexDirection: "row",
    justifyContent: "space-between",
  },
  residentImage: {
    height: 70,
    width: 60
  },
  text: {
    fontSize: 12,
    color: '#fff'
  },
  residentInfo: {
    padding: 5
  }
});

export const ResidentReportHeader = ({
                                       reportTitle,
                                       location,
                                       allUserLocations = false,
                                       resident,
                                       displayResidentInfo = false
                                     }) => {
  const buildImgSrc = () => {
    const imageUrl = `${config.publicBucketUrl}/${config.publicBucketName}/tenants/${location?.tenant?._id}/logos/${location?.tenant?.logo}`;
    return imageUrl;
  };

  const buildImageUrlString = () => {
    return `${config.bucketObjectUrl}/${config.publicBucketName}/tenants/${resident?.location?.tenant?._id}/residents/${resident?._id}/photo/${resident?.imageUrl}`;
  };

  return (
    <View fixed style={styles.container}>
      <View style={styles.residentImageContainer}>
        <Image style={styles.residentImage} src={buildImageUrlString()} />
        <View style={styles.residentInfo}>
          <View>
            <Text style={styles.text}>{resident?.firstName} {resident?.lastName}</Text>
          </View>
          {resident?.dob &&
            <View>
              <Text style={styles.text}>DOB: {moment(resident?.dob)?.utc()?.format("MM-DD-YYYY")}</Text>
            </View>
          }
          <View style={styles.reportTitle}>
            <Text>{reportTitle}</Text>
          </View>
        </View>
      </View>
      <View style={{ display: "flex", flexDirection: "row" }}>
        <View style={styles.addressContainer}>
          <Text style={styles.address}>{location?.tenant?.name}</Text>
          {!allUserLocations && (
            <Text style={styles.address}>{location?.name}</Text>
          )}
          <Text style={styles.address}>{location?.address?.address}</Text>
          {location?.address?.address2 && (
            <Text style={styles.address}>{location?.address?.address2}</Text>
          )}
          <Text style={styles.address}>
            {location?.address?.city}, {location?.address?.state}{" "}
            {location?.address?.zipCode}
          </Text>
        </View>
        <View style={styles.logoContainer}>
          <Image style={styles.logo} src={buildImgSrc()} />
        </View>
      </View>
    </View>
  );
};
