import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
    makeStyles, Typography
} from "@material-ui/core";
import {FormFieldsGenerator} from "../../../../utils/forms";
import moment from "moment";
import {UserContext} from "../../../../context/usercontext";
import SaveIcon from "@material-ui/icons/Save";
import useHasRole from "../../../../hooks/user.role.hook";

const MARReviewedModel = {
    approvedBy: {
        title: 'Approved By',
        required: false,
        value: null,
        dirty: false,
        isValid: true,
        multiSelect: false,
        dropDown: true,
        dropDownItems: [],
        dropDownItemValue: 'value',
        dropDownItemLabel: 'name',
        date: false,
        hidden: true
    },
    approvedAt: {
        title: "Approved Date",
        required: false,
        value: null,
        date: true,
        dirty: false,
        isValid: true,
        hidden: true
    },
    reviewedBy: {
        title: 'Reviewed By',
        required: true,
        value: null,
        dirty: false,
        isValid: true,
        multiSelect: false,
        dropDown: true,
        dropDownItems: [],
        dropDownItemValue: 'value',
        dropDownItemLabel: 'name',
        date: false,
    },
    reviewedAt: {
        title: "Reviewed Date",
        required: true,
        value: null,
        date: true,
        dirty: false,
        isValid: true,
    }
}

const useStyles = makeStyles((theme) => ({
    loading: {
        height: "10vh"
    }
}));

export const ReviewedByModal = ({residentId, data, date, open, onSave, onClose}) => {
    const classes = useStyles();
    const [marDate, setMarDate] = React.useState();
    const [model, setModel] = React.useState(JSON.parse(JSON.stringify(MARReviewedModel)));
    const { getUsers } = React.useContext(UserContext);
    const [loading, setLoading] = React.useState(false);
    const { checkPermission } = useHasRole();
    const canReview = checkPermission("MAR_REVIEW_UPDATE");

    React.useEffect(() => {
        if (open && moment(date).isValid()) {
            let dt = moment(date).startOf("month")
            setMarDate(dt);
            getData(dt, data).then(() => {});
        }
        // eslint-disable-next-line
    }, [open, date, data]);

    const getData = async (dt, data) => {
        setLoading(true);
        const responses = await Promise.all([
            getUsers()
        ]);
        let users = responses[0];
        let marReviewed = data;
        users = users.map(u => ({ value: u._id, name: getUserLabel(u)}));
        const obj = JSON.parse(JSON.stringify(MARReviewedModel));
        obj.approvedBy.dropDownItems = users;
        obj.reviewedBy.dropDownItems = users;
        if (marReviewed) {
            obj.approvedBy.value = marReviewed.approvedBy?._id;
            obj.approvedAt.value = marReviewed.approvedAt;
            obj.reviewedBy.value = marReviewed.reviewedBy?._id;
            obj.reviewedAt.value = marReviewed.reviewedAt;
        }
        setModel(obj);
        setLoading(false);
    }

    const save = () => {
        let data = {};
        data.month = moment(marDate).month() + 1;
        data.year = moment(marDate).year();
        Object.keys(model).forEach((key) => {
           data[key] = model[key].value;
        });
        data.resident = residentId;
        onSave(data);
    }

    const onFormChanged = e => {
        setModel({...model, ...e});
    }

    const getUserLabel = user => {
        let val = '';
        if (user) {
            val = `${user?.firstName ?? ''} ${user?.lastName ?? ''} `
            if (user?.initials) {
                val += ` (${user?.initials})`
            }
        }
        return val;
    }

    return (
        <Dialog open={open}
                onClose={onClose}
                maxWidth={"lg"}
                fullWidth={true}
        >
            <DialogTitle>
                {`${moment(date).format("MMMM YYYY")} | Reviewed By`}
            </DialogTitle>
            <DialogContent dividers>
                {!canReview &&
                    <>
                        <Typography variant={"subtitle2"}>
                            Reviewed By: {getUserLabel(data?.reviewedBy)}
                        </Typography>
                        <Typography variant={"subtitle2"}>
                            Reviewed At: {data?.reviewedAt ? moment(data?.reviewedAt)?.format("MM/DD/YYYY"): null}
                        </Typography>
                    </>
                }
                {canReview && !loading && <FormFieldsGenerator edit={canReview} model={model} onChange={onFormChanged} />}
                {loading &&
                    <div className={classes.loading}>
                        <LinearProgress />
                    </div>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                {canReview &&
                    <Button variant={"contained"}
                        color={"primary"}
                        startIcon={<SaveIcon />}
                        onClick={save}>
                    Save
                </Button>
                }
            </DialogActions>
        </Dialog>
    )
}
