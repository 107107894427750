import React from 'react'
import PrivateRoute from "../PrivateRoute";
import { Switch } from "react-router-dom";
import InsuranceList from './insurance.list';
import Insurance from './insurance';

const Insurances = () => {
    return (    
      <Switch>
        <PrivateRoute exact path="/insurances" component={InsuranceList} />
        <PrivateRoute exact path="/insurances/:id" component={Insurance} />
      </Switch>
    )
}

export default Insurances;