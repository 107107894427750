import React from 'react';
import {
  Paper,
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  Button,
  TableBody,
  TableRow,
  Chip,
  TablePagination, Select, MenuItem, TableSortLabel,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CreateResidentTreatment from './create.treatment';
import {
  createResidentTreatment,
  updateResidentTreatment, searchResidentTreatments,
} from '../../../services/treatment.service';
import moment from 'moment';
import useHasRole from "../../../hooks/user.role.hook";
import EditResidentTreatment from './edit.treatment';
import {getDayOfWeek} from "../../../utils/date.utils";
import TableLoadingIndicator from "../../../utils/indicators/table.loading";
import {SortFilterLabel, SortFilterSelectLabel} from "../../utils/table.utils";
import {FilterTemplate} from "./FilterTemplate";
import {convertToFilters} from "../../../utils/filter.utils";
import {PageTitle} from "../../utils";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  inactiveChip: {
    background: theme.palette.warning.light,
    color: theme.palette.warning.contrastText

  }
}));

const displayEventTemplates = (eventTemplates) => {
  return (
    <div style={{ padding: '0 .25rem' }}>
      {eventTemplates?.map((event) => {
          const time = moment();
          time.set({ hour: event.hour, minute: event.minute });
          return (
            <div>
              {event.month && (
                <span>
                  Month {event.month}
                  {', '}
                </span>
              )}
              {event.day && (
                <span>
                  Day {getDayOfWeek(event.day)}
                  {', '}
                </span>
              )}
              {time && <span>Time: {moment(time).format('H:mm A')} </span>}
            </div>
          );
        })}
    </div>
  );
};

const ResidentTreatments = ({ match }) => {
  const classes = useStyles();
  const [residentId, setResidentId] = React.useState();
  const [treatments, setTreatments] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(25);
  const [totalItems, setTotalItems] = React.useState(0);
  const [sortOrderBy, setSortOrderBy] = React.useState('treatment');
  const [filter, setFilter] = React.useState(JSON.parse(JSON.stringify(FilterTemplate)));
  const [sortOrderDirection, setSortOrderDirection] = React.useState('asc');
  const [error, setError] = React.useState(false);
  const [editTreatment, setEditTreatment] = React.useState(null);
  const [
    showCreateTreatmentDialog,
    setShowCreateTreatmentDialog,
  ] = React.useState(false);
  const [showEditTreatmentDialog, setShowEditTreatmentDialog] = React.useState(
    false
  );
  const { checkPermission } = useHasRole();
  const canEdit = checkPermission('TREATMENT_ORDER_UPDATE');

  React.useEffect(() => {
    const initLoad = async (residentId) => {

      const obj = JSON.parse(JSON.stringify(FilterTemplate));
      setFilter(obj);
      await loadData(residentId, page, size, filter);
    }
    if (match.params.residentId) {
      initLoad(match.params.residentId);
    }
    // eslint-disable-next-line
  }, [match.params.residentId]);

  const isPrnChange = async (e) => {
    const obj = {...filter};
    if (e.target.value === "true" || e.target.value === "false") {
      obj.isPrn.value = e.target.value;
    } else {
      obj.isPrn.value = null;
    }
    setFilter(obj);
    const payload = {...obj}
    if (payload.isPrn.value === "true" || payload.isPrn.value === "false") {
      payload.isPrn.value = JSON.parse(payload.isPrn.value);
    }
    await loadData(residentId, page, size, payload);
  }



  const clearFilters = async () => {
    const filters = JSON.parse(JSON.stringify(FilterTemplate));
    setFilter(filters);
    await loadData(residentId, 1, 25, filters);
  }

  const loadData = async (residentId, pg, sz, filterParam, srt = "orderDate", srtDir = "asc") => {
    setResidentId(residentId);
    setLoading(true);
    setError(false);

    const filters = convertToFilters(filterParam);
    const response = await searchResidentTreatments(residentId, pg, sz, filters, srt, srtDir);
      // await getResidentTreatments(residentId, null, statusValue);
    if (!response?.error) {
      setTreatments(response?.content);
      setSize(response?.paginator?.perPage);
      setPage(response?.paginator?.currentPage);
      setTotalItems(response?.paginator?.totalItems);
    } else {
      setTreatments([]);
      setError(true);
    }
    setLoading(false);
  };


  const onCreateTreatment = async (treatment) => {
    const response = await createResidentTreatment(residentId, treatment);
    setTreatments([response, ...treatments]);
    setShowCreateTreatmentDialog(false);
  };

  const onEditTreatment = (treatment) => {
    setEditTreatment(treatment);
    setShowEditTreatmentDialog(true);
  };

  const updateTreatment = async (entity) => {
    const updated = await updateResidentTreatment(
      residentId,
      editTreatment._id,
      entity
    );
    const obj = treatments.map((treatment) =>
      treatment._id === updated._id ? updated : treatment
    );
    setTreatments(obj);
    setEditTreatment(null);
    setShowEditTreatmentDialog(false);
  };

  const onRowsPerPageChange = async e => {
    setSize(e.target.value);
    setPage(1);
    await loadData(residentId, 1, e.target.value, filter);

  }

  const onPageChange = async (e, newPage) => {
    setPage(newPage + 1);
    await loadData(residentId, newPage + 1, size, filter);
  }

  const createSortHandler = async (field) => {
    const sort = field;
    const sortDirection = sortOrderDirection === 'asc' ? 'desc' : 'asc';
    setSortOrderBy(sort);
    setSortOrderDirection(sortDirection);
    await loadData(residentId, page, size, filter, sort, sortDirection);
  };


  const filterTextChange = async e => {
    const obj = {...filter};
    obj[e.target.name].value = e.target.value;
    setFilter(obj);
    await loadData(residentId, page, size, obj);
  }

  const filterSelectChange = async e => {
    const obj = {...filter};
    obj[e.target.name].value = e.target.value;
    setFilter(obj);
    await loadData(residentId, page, size, obj);
  }

  return (
    <Paper elevation={24} className={classes.root}>
      <PageTitle title={"Treatments"} />
      {canEdit &&
      <Button
        variant="contained"
        color="primary"
        onClick={() => setShowCreateTreatmentDialog(true)}
        startIcon={<AddIcon />}
      >
        Create Treatment
      </Button>}
      <Button variant={"text"}
              startIcon={<HighlightOffIcon />}
              onClick={clearFilters}>Clear Filters</Button>
      <CreateResidentTreatment
        open={showCreateTreatmentDialog}
        onClose={() => setShowCreateTreatmentDialog(false)}
        onSave={onCreateTreatment}
      />
      <TablePagination count={totalItems}
                       page={page - 1}
                       component={"div"}
                       rowsPerPage={size}
                       rowsPerPageOptions={[1,10,25, 50]}
                       onRowsPerPageChange={onRowsPerPageChange}
                       onPageChange={onPageChange}
      />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableCell />
            <TableCell>
              <SortFilterSelectLabel label={"Status"}
                                     field={"isActive"}
                                     filter={filter}
                                     sortOrderBy={sortOrderBy}
                                     sortDirection={sortOrderDirection}
                                     createSortHandler={createSortHandler}
                                     options={filter?.isActive?.dropDownItems}
                                     optionLabel={"name"}
                                     optionValue={"name"}
                                     onChange={filterSelectChange} />
            </TableCell>
            <TableCell>
              <SortFilterLabel label={"Treatment"}
                               filter={filter}
                               createSortHandler={createSortHandler}
                               sortDirection={sortOrderDirection}
                               sortOrderBy={sortOrderBy}
                               filterTextChange={filterTextChange}
                               field={"treatment"}
              />
            </TableCell>
            <TableCell>
              <SortFilterLabel label={"Description"}
                               filter={filter}
                               createSortHandler={createSortHandler}
                               sortDirection={sortOrderDirection}
                               sortOrderBy={sortOrderBy}
                               filterTextChange={filterTextChange}
                               field={"description"}
              />
            </TableCell>
            <TableCell>
              Is Prn
              <Select fullWidth
                      displayEmpty
                      value={filter.isPrn.value}
                      onChange={isPrnChange}
              >
                <MenuItem>All</MenuItem>
                <MenuItem value={"true"}>Yes</MenuItem>
                <MenuItem value={"false"}>No</MenuItem>
              </Select>

            </TableCell>
            <TableCell>Schedule</TableCell>
            <TableCell>
              <TableSortLabel
                active={sortOrderBy === "orderDate"}
                direction={sortOrderDirection}
                onClick={() => createSortHandler("orderDate")}
              >
                {"Order Date"}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sortOrderBy === "endDate"}
                direction={sortOrderDirection}
                onClick={() => createSortHandler("endDate")}
              >
                {"End Date"}
              </TableSortLabel>
            </TableCell>
          </TableHead>
          {!error && loading &&
            <TableLoadingIndicator cols={8} rows={4} bodyOnly={true} />
          }
          <TableBody>
            {!loading && !error && treatments?.map((treatment) => (
                <TableRow>
                  <TableCell>
                    {canEdit &&
                    <Button
                      onClick={() => onEditTreatment(treatment)}
                      size="small"
                    >
                      Edit
                    </Button>}
                  </TableCell>
                  <TableCell>
                    {treatment?.isActive ?
                      <Chip label={"Active"}
                            size={"small"}
                            color={"primary"} />:
                      <Chip label={"Inactive"}
                            size={"small"}
                      />
                    }
                  </TableCell>
                  <TableCell>
                    {treatment.name}
                  </TableCell>
                  <TableCell>{treatment.description}</TableCell>
                  <TableCell>
                    {treatment.frequency && !treatment.isPrn ? (
                      `${treatment.frequency.description} (${treatment.frequency.name})`
                    ) : (
                      <Chip color="primary" label="PRN" />
                    )}
                  </TableCell>
                  <TableCell>
                    {!treatment.isPrn &&
                      displayEventTemplates(treatment.eventTemplates)}
                  </TableCell>
                  <TableCell>
                    {moment(treatment.startDate).format('MM/DD/YYYY')}
                  </TableCell>
                  <TableCell>
                    {moment(treatment.endDate).format('MM/DD/YYYY')}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EditResidentTreatment
        open={showEditTreatmentDialog}
        onClose={() => setShowEditTreatmentDialog(false)}
        treatment={editTreatment}
        onSave={updateTreatment}
      />
    </Paper>
  );
};

export default ResidentTreatments;
