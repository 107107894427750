import React from "react";
import {residentFilesContextReducer} from "./ResidentFilesContextReducer";
import {InitialState} from "./InitialState";
import {setResidentAction} from "./Actions";

let ResidentFileContext = React.createContext();

const ResidentFileContextProvider = ({ id, children }) => {
  let [state, dispatch] = React.useReducer(residentFilesContextReducer, InitialState);
  let value = { state, dispatch };

  React.useEffect(() => {
    setResidentAction(dispatch, id).then(() => {});
  }, [id]);


  return (
    <ResidentFileContext.Provider value={value}>
      {children}
    </ResidentFileContext.Provider>
  )
}

let ResidentFileContextConsumer = ResidentFileContext.Consumer;

export { ResidentFileContext, ResidentFileContextProvider, ResidentFileContextConsumer};
