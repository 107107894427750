import React from 'react';
import MomentUtils from "@date-io/moment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {Grid} from "@material-ui/core";

export const DailyOccurrence = ({model, onChange}) => {
  const onDateChange = (date, field) => {
    const obj = { ...model?.occurrence};
    obj.value[field] = date;
    onChange(obj);
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid container alignItems={"center"}
            style={{ padding: '.2rem 0 0 .2rem'}}
      >
        <KeyboardDatePicker
          style={{flex: 1}}
          size="small"
          name={"startDate"}
          required
          value={model?.occurrence?.value?.startDate}
          label={"Start Date"}
          format="MM/DD/YYYY"
          onChange={date => onDateChange(date, 'startDate')}
          allowKeyboardControl="true"
          ampm={false}
        />
        <KeyboardDatePicker
          style={{flex: 1}}
          size="small"
          name={"endDate"}
          required
          value={model?.occurrence?.value?.endDate}
          label={"End Date"}
          format="MM/DD/YYYY"
          onChange={date => onDateChange(date, 'endDate')}
          allowKeyboardControl="true"
          ampm={false}
        />
      </Grid>
    </MuiPickersUtilsProvider>

  )
}
