import React, {useEffect, useState} from 'react';
import {PageTitle} from "../../utils";
import {
  Avatar,
  Checkbox, Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import moment from "moment";
import {getTimeEntriesReportFor} from "../../../services/time.entry.report.service";
import TableLoadingIndicator from "../../../utils/indicators/table.loading";
import FaceIcon from "@material-ui/icons/Face";
import * as _ from 'lodash';
import {getStripedStyle} from '../../utils/table.utils';
import UserSearch from "../../user/search";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import useHasRole from "../../../hooks/user.role.hook";
import {CreateTimeEntry} from "../list/create.time.entry";
import {createTimeClock, deleteTimeEntry, editTimeEntry} from "../../../services/timeentry.service";
import ConfirmDialog from "../../utils/dialogs/confirm.dialog";
import {EditTimeEntryModal} from "./edit.time.entry.modal";
import useGlobalNotification from "../../../hooks/notification.hook";


const months = [
  { month: 1, name: 'January' },
  { month: 2, name: 'February' },
  { month: 3, name: 'March' },
  { month: 4, name: 'April' },
  { month: 5, name: 'May' },
  { month: 6, name: 'June' },
  { month: 7, name: 'July' },
  { month: 8, name: 'August' },
  { month: 9, name: 'September' },
  { month: 10, name: 'October' },
  { month: 11, name: 'November' },
  { month: 12, name: 'December' },
];

const useStyles = makeStyles((theme) => ({
  defaultImg: {
    height: theme.spacing(5),
    width: theme.spacing(5),
  }
}));

const TimeEntryPage = () => {
  const [selectedMonth, setSelectedMonth] = useState(moment().month() === 11 ? 12: moment().month() + 1);
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [years, setYears] = useState();
  const [selectedTimeentry, setSelectedTimeentry] = useState();
  const [viewTimeentryModal, setViewTimeentryModal] = useState(false);
  const [viewDeleteTimeentryModal, setViewDeleteTimeentryModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [allUsers, setAllUsers] = useState(true);
  const [rawData, setRawData] = React.useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const {checkPermission} = useHasRole();
  const canUpdateTimeEntry = checkPermission("TIME_ENTRY_ADMIN_UPDATE");
  const canDeleteTimeEntry = checkPermission("TIME_ENTRY_ADMIN_DELETE");
  const canCreateTimeEntry = checkPermission("TIME_ENTRY_ADMIN_CREATE");
  const { addNotification } = useGlobalNotification();

  const classes = useStyles();

  useEffect(() => {
    let startYear = moment().year() - 3;
    let currentYear = moment().year() + 1;
    let yrs = Array.from(
      { length: (currentYear - startYear) / 1 },
      (value, index) => startYear + index * 1
    );
    setYears(yrs);
  }, []);

  useEffect(() => {
    if (selectedYear && selectedMonth) {
      getData(selectedYear, selectedMonth, selectedUser?._id).then(() =>{});
    }
    // eslint-disable-next-line
  }, [selectedYear, selectedMonth, selectedUser]);

  const getData = async (year, month, userId) => {
    setLoading(true);
    const response = await getTimeEntriesReportFor(year, month, userId);
    if (!response?.error) {
      setRawData(response);
      let groupedData = groupData(response);
      groupedData = _.sortBy(groupedData, ['user.lastName', 'user.firstName'])
      setData(groupedData);
    } else {
      setError(true);
    }
    setLoading(false);
  }

  const groupData = data => {
    return _(data)
        .groupBy('user._id')
        .map(group => ({
            user: _.head(group).user,
            timeEntries: _.map(group, o => ({
              ...o,
              user: o?.user?._id
            }))
        }))
        .value();
  }

  const getMonthName = monthNumber => {
    return months[monthNumber - 1]?.name;
  }

  const updateAllUsersCheckbox = e => {
    setSelectedUser(null);
    setAllUsers(e.target.checked);
  }

  const totalHours = entries => {
    let totalMinutes = _.sumBy(entries, 'totalMinutes');
    if (totalMinutes > 0) {
      return `${moment.duration(totalMinutes, "minutes").asHours()?.toFixed(2)} Hours`;
    } else {
      return null;
    }
  }

  const onUserSelected = user => {
    setSelectedUser(user);
  }

  const onUserCleared = () => {
    setSelectedUser(null);
  }

  const handleNewTimeEntry = async timeEntry => {
    const response = await createTimeClock(timeEntry?.user, timeEntry);
    if (!response?.error) {
      const obj = [...rawData, response];
      setRawData(obj);
      let groupedData = groupData(obj);
      groupedData = _.sortBy(groupedData, ['user.lastName', 'user.firstName'])
      setData(groupedData);
    }
  }

  const handleEditTimeentry = timeEntry => {
    setSelectedTimeentry(timeEntry);
    setViewTimeentryModal(true);
  }

  const updateTimeEntry = async timeEntry => {
    const response = await editTimeEntry(timeEntry?._id, timeEntry);
    const obj = data?.map(group => {
      if (group?.user?._id === response?.user) {
        group.timeEntries = group.timeEntries?.map(t => t?._id === response?._id ? response: t);
      }
      return group;
    });
    setData(obj);
    setViewTimeentryModal(false);
    addNotification("Time entry updated", "success");
  }

  const handleDeleteTimeentry = timeEntry => {
    setSelectedTimeentry(timeEntry);
    setViewDeleteTimeentryModal(true);
  }

  const onConfirmDelete = async () => {
    await deleteTimeEntry(selectedTimeentry?._id);
    const obj = data?.map(group => {
      if (group?.user?._id === selectedTimeentry?.user) {
        group.timeEntries = group.timeEntries?.filter(t => t?._id !== selectedTimeentry?._id);
      }
      return group;
    });
    setData(obj);
    setViewDeleteTimeentryModal(false);
    addNotification("Time entry deleted", "success");
  }

  return (
    <div>
      <PageTitle title={"Time Entry"} />
      <Paper elevation={24} style={{margin: ".25rem 0", padding: "1rem"}}>
        <FormGroup>
          <FormControl>
            <InputLabel>Year</InputLabel>
            <Select
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
            >
              {years?.map((year) => (
                <MenuItem value={year}>{year}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel>Month</InputLabel>
            <Select
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
            >
              {months?.map((m) => (
                <MenuItem value={m.month}>{m.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                name="isQtyEditable"
                checked={allUsers}
                onChange={updateAllUsersCheckbox}
              />
            }
            label="All Users"
          />
          {!allUsers &&
          <FormControl style={{padding: "1rem 0"}}>
            <UserSearch onSelect={onUserSelected} onClear={onUserCleared} />
          </FormControl>
          }
        </FormGroup>
      </Paper>
      <Paper elevation={24}
             style={{margin: ".25rem 0", padding: "1rem"}}
      >
        <Typography variant={"h6"}>{selectedYear} | {getMonthName(selectedMonth)}</Typography>
        <TableContainer>
          <Table size={"small"}>
            <TableHead>
              {canCreateTimeEntry &&
                  <TableRow>
                    <CreateTimeEntry onSave={handleNewTimeEntry}
                                     selectUser={true}
                    />
                  </TableRow>
              }
              <TableRow>
                <TableCell width={200} />
                <TableCell>
                  Date
                </TableCell>
                <TableCell>
                  Clock In
                </TableCell>
                <TableCell>
                  Clock Out
                </TableCell>
                <TableCell>
                    Total
                </TableCell>
                <TableCell>
                  Comments
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            {loading && !error && <TableLoadingIndicator bodyOnly={true} cols={5} rows={10} />}
            <TableBody>
              {!error && !loading && data?.map(group =>
                  <>
                    <TableRow>
                      <TableCell colSpan={4}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                          <Avatar
                              alt={`${group?.user?.firstName} ${group?.user?.lastName}`}
                              src={group?.user?.picture}>
                            <FaceIcon className={classes.defaultImg}/>
                          </Avatar>
                          <div style={{marginLeft: "1rem"}}>
                            {group?.user?.firstName} {group?.user?.lastName}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell colSpan={2}>
                          {totalHours(group?.timeEntries)}
                      </TableCell>
                      <TableCell />
                    </TableRow>
                    {group?.timeEntries?.sort((a, b) => a?.date > b?.date ? 1: -1)?.map((timeEntry, index) =>
                    <TableRow style={{ ...getStripedStyle(index)}} key={timeEntry?._id}>
                      <TableCell />
                      <TableCell>
                        {canUpdateTimeEntry &&
                        <IconButton size={"small"}
                                    onClick={() => handleEditTimeentry(timeEntry)}
                        >
                          <EditIcon fontSize="small"  />
                        </IconButton>
                        }
                        {canDeleteTimeEntry &&
                        <IconButton aria-label="delete"
                                    onClick={() => handleDeleteTimeentry(timeEntry)}
                                    color="primary">
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                        }
                        {moment(timeEntry?.date).utc().format("MM/DD/YYYY")}
                      </TableCell>
                      <TableCell>
                        {timeEntry?.start ? moment(timeEntry?.start).format("hh:mma z"): null}
                      </TableCell>
                      <TableCell>
                        {timeEntry?.end ? moment(timeEntry?.end).format("hh:mma z"): null}
                      </TableCell>
                      <TableCell>
                        {moment.duration(timeEntry?.totalMinutes, "minutes").asHours()?.toFixed(2)}
                      </TableCell>
                      <TableCell>
                        {timeEntry?.comment}
                      </TableCell>
                      <TableCell>
                        {timeEntry?.tags?.map(t => <Chip label={t} />)}
                      </TableCell>
                    </TableRow>
                    )}
                  </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <ConfirmDialog onConfirm={onConfirmDelete}
                     onClose={() => setViewDeleteTimeentryModal(false)}
                     title={"Delete Time Entry"}
                     message={"Press OK to delete time entry."}
                     open={viewDeleteTimeentryModal}
      />
      <EditTimeEntryModal open={viewTimeentryModal}
                          onSave={updateTimeEntry}
                          timeEntry={selectedTimeentry}
                          onClose={() => setViewTimeentryModal(false)} />
    </div>
  )
};


export default TimeEntryPage;
