import React from 'react';
import {Chip, ListItem, ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import moment from "moment";
import {ACTIONS, TimeEntryContext} from "../../../context/TimeEntryContext/time.entry.context";

export const TimeEntryMobileItem = ({timeEntry}) => {
    const { dispatch } = React.useContext(TimeEntryContext);

    const getTimeEntryDisplay = () => {
        if (!timeEntry?.end) {
            return `${moment(timeEntry?.start).format("h:mmA")}`;
        } else {
            return `${moment(timeEntry?.start).format("h:mmA")} - ${moment(timeEntry?.end).format("h:mmA")}`;
        }
    }

    const onSelected = () => {
        dispatch({type: ACTIONS.SET_TIME_ENTRY, payload: timeEntry});
    }

    return (
        <ListItem button
                  divider
                  onClick={onSelected}>
            <ListItemText primary={getTimeEntryDisplay()} secondary={timeEntry?.comment} />
            <ListItemSecondaryAction>
            {timeEntry?.tags?.map(tag => <Chip label={tag} />)}
            </ListItemSecondaryAction>
        </ListItem>
    )
}
