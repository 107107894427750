import React from 'react';
import {
  Avatar,
  Button,
  ButtonGroup,
  FormControl,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField, Tooltip, Typography,
} from '@material-ui/core';
import {
  red,
  yellow,
  green,
  grey,
  orange,
  blue,
} from '@material-ui/core/colors';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
import { updateResidentTask } from '../../../services/careplan.service';

const contrast = 400;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  avatarSmall: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  scheduled: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: grey[500],
  },
  readyToPass: {
    background: grey[700],
  },
  given: {
    background: green[contrast],
  },
  late: {
    background: orange[contrast],
  },
  missed: {
    background: red[contrast],
  },
  hold: {
    background: yellow[contrast],
  },
  sa: {
    background: blue[contrast],
  },
  na: {
    background: grey[contrast],
  },
  refused: {
    background: orange[contrast],
  },
  destruction: {
    background: yellow[contrast],
  },
}));

const TaskRow = ({
  task,
  index,
  isEdit,
  selectEditEvent,
  cancelEdit,
  onUpdate,
}) => {
  const classes = useStyles();
  const [editTask, setEditTask] = React.useState(null);

  React.useEffect(() => {
    if (isEdit && task) {
      setEditTask(JSON.parse(JSON.stringify(task)));
    }
  }, [isEdit, task]);

  const onChange = (e) => {
    const obj = { ...editTask };
    obj[e.target.name] = e.target.value;
    setEditTask(obj);
  };

  const getItemClass = (event) => {
    if (event && event.status) {
      switch (event.status) {
        case 'Scheduled':
          return classes.readyToPass;
        case 'Given':
          return classes.given;
        case 'Late':
          return classes.late;
        case 'Missed':
          return classes.missed;
        case 'Hold':
          return classes.hold;
        case 'SA':
          return classes.sa;
        case 'NA':
          return classes.na;
        case 'Refused':
          return classes.refused;
        case 'Destruction':
          return classes.destruction;
        default:
          return classes.scheduled;
      }
    }
  };

  const updateTask = async () => {
    await updateResidentTask(task.resident._id, task._id, editTask);
    onUpdate(editTask);
  };

  return (
    <TableRow key={task._id}>
      <TableCell>
        <Avatar
          alt="Tasks"
          className={[classes.avatarSmall, getItemClass(task)]}
        >
          <i class="fas fa-tasks"></i>
        </Avatar>
      </TableCell>
      <TableCell>
        <Tooltip title={
          <div>
            <Typography variant={"subtitle1"}>
              Event Id: {task?._id}
            </Typography>
            <Typography variant={"subtitle1"}>
              Start window: {moment(task?.startWindow).format('MM/DD/YYYY hh:mm a')}
            </Typography>
            <Typography variant={"subtitle1"}>
              Scheduled: {moment(task?.scheduledTime).format('MM/DD/YYYY hh:mm a')}
            </Typography>
            <Typography variant={"subtitle1"}>
              End window: {moment(task?.endWindow).format('MM/DD/YYYY hh:mm a')}
            </Typography>
          </div>
        }>
          <Typography variant={"subtitle1"}>
            {moment(task.scheduledTime).format('hh:mm a')}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell>
        {task.resident.firstName} {task.resident.lastName}
      </TableCell>
      <TableCell>
        {task.details && (
          <>
            <div>Careplan: {task.details.careplan}</div>
            <div>Goal: {task.details.goal}</div>
            <div>Intervention: {task.details.intervention}</div>
          </>
        )}
      </TableCell>
      <TableCell>
        {task.administered && task.administeredUser
          ? `${task.administeredUser.initials} - ${moment(
              task.administered
            ).format('h:mma')}`
          : null}
      </TableCell>
      {isEdit && editTask && (
        <>
          <TableCell colSpan={2}>
            <ButtonGroup size="small" fullWidth>
              <Button
                color="primary"
                variant="contained"
                size="small"
                disabled={!editTask.status}
                onClick={updateTask}
              >
                Save
              </Button>
              <Button size="small" onClick={cancelEdit}>
                Cancel
              </Button>
            </ButtonGroup>
          </TableCell>
          <TableCell>
            <FormControl size="small">
              <InputLabel required htmlFor="status">
                Status
              </InputLabel>
              <Select
                id="status"
                label="Status"
                value={editTask ? editTask.status : ''}
                onChange={onChange}
                name="status"
              >
                <MenuItem value={'Scheduled'}>Scheduled</MenuItem>
                <MenuItem value={'Given'}>Given</MenuItem>
                <MenuItem value={'Late'}>Late</MenuItem>
                <MenuItem value={'Missed'}>Missed</MenuItem>
                <MenuItem value={'Hold'}>Hold</MenuItem>
                <MenuItem value={'SA'}>Self Administered</MenuItem>
                <MenuItem value={'NA'}>Not Administered</MenuItem>
                <MenuItem value={'Refused'}>Refused</MenuItem>
              </Select>
            </FormControl>
          </TableCell>
          <TableCell>
            <TextField
              name="notes"
              size="small"
              multiline
              rowsMax={3}
              value={editTask.notes || ''}
              label="Notes"
              onChange={onChange}
            />
          </TableCell>
        </>
      )}
      {!isEdit && (
        <>
          <TableCell colSpan={2}>
            <IconButton onClick={() => selectEditEvent(task, index)}>
              <EditIcon />
            </IconButton>
          </TableCell>
          <TableCell>{task.status}</TableCell>
          <TableCell>{task.notes}</TableCell>
        </>
      )}
    </TableRow>
  );
};

export default TaskRow;
