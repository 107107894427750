import React from 'react';
import {
  Avatar,
  ButtonGroup,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import {ArrowDownwardTwoTone, ArrowUpwardTwoTone, SaveTwoTone} from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import {AssessmentsContext} from "./assessments.context";
import {
  onDeleteSectionAction,
  onOrderSectionDownAction,
  onOrderSectionUpAction,
  onSaveSectionAction
} from "./assessment.actions";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  nameContainer: {
    flex: 1,
    padding: theme.spacing(0, 1)
  },
  avatar: {
    background: theme.palette.grey[600],
    height: '2.5vh',
    width: '2.5vh',
    fontSize: '1rem'
  }
}))

const Section = ({section, canUpdate = false}) => {
  const classes = useStyles();
  const [model, setModel] = React.useState();
  const { state, dispatch } = React.useContext(AssessmentsContext);

  React.useEffect(() => {
    if (section) {
      setModel(section);
    }
  },[section]);

  const onNameChange = e => {
    const obj = {...model};
    obj.name = e.target.value;
    setModel(obj);
  }

  const save = async () => {
    await onSaveSectionAction(state.assessment, section._id, model, dispatch)
  }

  const deleteSection = async () => {
    await onDeleteSectionAction(state.assessment, section._id, dispatch);
  }

  const moveUp = async () => {
    await onOrderSectionUpAction(section, state.assessment, dispatch);
  }
  const moveDown = async () => {
    await onOrderSectionDownAction(section, state.assessment, dispatch);
  }



  return (
    <Paper elevation={24} className={classes.root}>
      <Grid container justify={"space-between"} alignItems={"center"}>
        <Avatar className={classes.avatar}>
          {model?.sortOrder}
        </Avatar>
        <div className={classes.nameContainer}>
          <TextField label={"Name"}
                     fullWidth
                     value={model?.name || ''}
                     onChange={onNameChange}
                     disabled={!canUpdate}
          />
        </div>
        <ButtonGroup variant={"contained"} disabled={!canUpdate}>
          <IconButton startIcon={<ArrowUpwardTwoTone />}
                      onClick={moveUp}
          >
            <ArrowUpwardTwoTone />
          </IconButton>
          <IconButton startIcon={<ArrowUpwardTwoTone />}
                      onClick={moveDown}
          >
            <ArrowDownwardTwoTone />
          </IconButton>
          <IconButton startIcon={<ArrowUpwardTwoTone />}
                      onClick={deleteSection}
          >
            <DeleteIcon />
          </IconButton>
          <IconButton startIcon={<ArrowUpwardTwoTone />}
                      onClick={save}
          >
            <SaveTwoTone />
          </IconButton>
        </ButtonGroup>
      </Grid>
    </Paper>

  )
}

export default Section;
