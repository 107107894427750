// import { parseFilters } from '../../../../utils/filter.utils';
import {
  SET_TOKEN,
  OPEN_DIALOG,
  RESET,
  CLOSE_DIALOG,
  SET_ACTIVE_STEP,
  SET_MODEL,
  LOAD_RESIDENT_COMPLETE,
  SELECT_RESIDENT,
  MERGE_VALUES_START,
  MERGE_VALUES_COMPLETE,
  SET_TENANT_LOCATIONS,
  SET_SELECTED_TENANT_LOCATION,
  UPSERT_RESIDENT_START,
  UPSERT_RESIDENT_COMPLETE,
  RESIDENT_PHOTO_LOAD_STARTED,
  RESIDENT_PHOTO_LOAD_COMPLETED,
  RESIDENT_PHOTO_UPLOAD_STARTED,
  RESIDENT_PHOTO_UPLOAD_COMPLETED,
  UPDATE_BACKGROUND_INFORMATION_STARTED,
  UPDATE_BACKGROUND_INFORMATION_COMPLETED,
  UPDATE_MEDICAL_STARTED,
  UPDATE_MEDICAL_COMPLETE,
  ADMIT_RESIDENT_START,
  ADMIT_RESIDENT_COMPLETE,
  SET_TENANT_ID,
  SET_RESIDENT
} from './resident.intake.constants';
import { getMaritalStatuses } from '../../../../services/maritalstatus.service';
import { getGenders } from '../../../../services/gender.service';
import { getEthnicities } from '../../../../services/ethnicity.service';
import { createResidentAdmission } from '../../../../services/admission.service';
import {
  getResidentImage,
  createResidentImage,
  removeResidentImage,
  getResidentById,
} from '../../../../services/resident.service';
import config from '../../../../utils/configs';
import apiUtil from '../../../../utils/api.utils';
import Compressor from 'compressorjs';

let residentUrl = `${config.residentService}/api/v1.0/residents`;

export const openDialog = () => ({ type: OPEN_DIALOG });
export const closeDialog = () => ({ type: CLOSE_DIALOG });
export const setActiveStep = (id) => ({ type: SET_ACTIVE_STEP, payload: id });
export const reset = () => ({ type: RESET });
export const setToken = (token) => ({ type: SET_TOKEN, payload: token });
export const updateModel = (payload) => ({ type: SET_MODEL, payload: payload });
export const setTenantLocations = (locations) => ({
  type: SET_TENANT_LOCATIONS,
  payload: locations,
});
export const setSelectedTenantLocationId = (tenantLocationId) => ({
  type: SET_SELECTED_TENANT_LOCATION,
  payload: tenantLocationId,
});

export const setUpEmptyModel = async (dispatch, state) => {
  dispatch({ type: MERGE_VALUES_START });
  const model = await getSuportValues(state.emptyModel, state);
  dispatch({ type: MERGE_VALUES_COMPLETE, payload: model });
};

const getSuportValues = async (model, state) => {
  const obj = { ...model };
  if (
    !obj.maritalStatus.dropDownItems ||
    obj.maritalStatus.dropDownItems.length === 0
  ) {
    const maritalStatuses = await getMaritalStatuses();
    obj.maritalStatus.dropDownItems = maritalStatuses.content;
  }
  if (!obj.gender.dropDownItems || obj.gender.dropDownItems.length === 0) {
    const genders = await getGenders();
    obj.gender.dropDownItems = genders;
  }
  if (
    !obj.ethnicity.dropDownItems ||
    obj.ethnicity.dropDownItems.length === 0
  ) {
    const ethnicities = await getEthnicities();
    obj.ethnicity.dropDownItems = ethnicities;
  }
  if (
    !obj.tenantLocationId.dropDownItems ||
    obj.tenantLocationId.dropDownItems.length === 0
  ) {
    obj.tenantLocationId.dropDownItems = state.tenantLocations;
    obj.tenantLocationId.value = state.selectedTenantLocationId;
  }
  return obj;
};

export const mergeValues = async (
  dispatch,
  state,
  model,
  resident,
  defaultResidentStatus
) => {
  dispatch({ type: MERGE_VALUES_START });
  model = await getSuportValues(model, state);
  if (model && resident) {
    model._id.value = resident._id;
    model.firstName.value = resident.firstName;
    model.middleName.value = resident.middleName;
    model.lastName.value = resident.lastName;
    model.formerName.value = resident.formerName;
    model.gender.value = resident.gender;
    model.medicalRecordId.value = resident.medicalRecordId;
    model.countyCaseNumber.value = resident.countyCaseNumber;
    model.ssn.value = resident.ssn;
    model.maritalStatus.value = resident.maritalStatus;
    model.religiousPreference.value = resident.religiousPreference;
    model.citizenship.value = resident.citizenship;
    model.ethnicity.value = resident.ethnicity;
    model.primaryLanguage.value = resident.primaryLanguage;
    model.roomNumber.value = resident.roomNumber;
    model.residentStatusId.value = resident.residentStatus
      ? resident.residentStatus._id
      : defaultResidentStatus;
    model.tenantLocationId.value = resident.location;
    model.dob.value = resident.dob;
    model.education.value = resident.education;
    model.hobbies.value = resident.hobbies;
    model.specialInterests.value = resident.specialInterests;
    model.specialNeeds.value = resident.specialNeeds;
    model.livingWill.value = resident.livingWill === true ? 'y' : 'n';
    model.email.value = resident.email;
    model.transferedFrom.value = resident.transferedFrom;
    model.referredBy.value = resident.referredBy;
    model.occupation.value = resident.occupation;
    model.militaryHistory.value = resident.militaryHistory;
    model.codeStatus.value = resident.codeStatus;
    model.photo.value = resident.imageUrl;
  }
  dispatch({ type: MERGE_VALUES_COMPLETE, payload: model });
  dispatch({ type: SET_RESIDENT, payload: resident})
};

/*
export const buildImageUrlString = (fileName) => {
  return `${config.bucketObjectUrl}/${config.publicBucketName}/tenants/${state.selectedTenant._id}/residents/${resident._id}/photo/${fileName}`;
};
*/

export const selectResident = (resident) => {
  return { type: SELECT_RESIDENT, payload: resident };
};

export const loadResident = async (
  dispatch,
  state,
  model,
  id,
  defaultResidentStatus
) => {
  try {
    //dispatch({ type: LOAD_RESIDENT_START });
    const resident = await getResidentById(id);
    // dispatch({ type: LOAD_RESIDENT_COMPLETE, payload: resident.data });
    mergeValues(dispatch, state, state.model, resident, defaultResidentStatus);
    dispatch(openDialog());
  } catch (err) {
    alert(err);
    dispatch({ type: LOAD_RESIDENT_COMPLETE, payload: null });
  }
};

export const saveResident = async (dispatch, token, updatedModel) => {
  dispatch({ type: UPSERT_RESIDENT_START });
  try {
    if (updatedModel._id) {
      const response = await updateResident(
        updatedModel._id,
        updatedModel,
        token
      );
      const updatedResident = response.data;
      dispatch({ type: UPSERT_RESIDENT_COMPLETE, payload: updatedResident });
    } else {
      const result = await createResident(updatedModel);
      const newResident = result.data;
      dispatch({ type: UPSERT_RESIDENT_COMPLETE, payload: newResident });
    }
  } catch (err) {
    alert(err);
    return null;
  }
};

export const createResident = async (resident) => {
  try {
    const response = await apiUtil.client.post(`${residentUrl}`, resident);
    return response?.data;
  } catch (error) {
    return {error: true, message: error};
  }
};

export const updateResident = async (id, resident) => {
  try {
    const response = await apiUtil.client.put(`${residentUrl}/${id}`, resident);
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
};

export const getPhoto = async (dispatch, id) => {
  try {
    dispatch({ type: RESIDENT_PHOTO_LOAD_STARTED });

    const image = await getResidentImage(id);
    dispatch({ type: RESIDENT_PHOTO_LOAD_COMPLETED, payload: image });
  } catch (error) {
    alert(error);
    dispatch({ type: RESIDENT_PHOTO_LOAD_COMPLETED, payload: null });
  }
};

export const updatePhoto = async (dispatch, id, image) => {
  try {
    dispatch({ type: RESIDENT_PHOTO_UPLOAD_STARTED });
    const formData = new FormData();
    new Compressor(image, {
      quality: 0.6,
      maxHeight: 300,
      maxWidth: 300,
      checkOrientation: true,
      success: async (compressed) => {
        formData.append('file', compressed, image.name);
        const fileName = await createResidentImage(id, formData);
        dispatch({ type: RESIDENT_PHOTO_UPLOAD_COMPLETED, payload: fileName });
      },
    });
  } catch (error) {
    alert(error);
    dispatch({ type: RESIDENT_PHOTO_UPLOAD_COMPLETED, payload: null });
  }
};

export const removePhoto = async (dispatch, id) => {
  dispatch({ type: RESIDENT_PHOTO_LOAD_STARTED });
  await removeResidentImage(id);
  dispatch({ type: RESIDENT_PHOTO_UPLOAD_COMPLETED, payload: null });
};

export const updateBackgroundInformation = async (
  dispatch,
  residentId,
  data
) => {
  try {
    dispatch({ type: UPDATE_BACKGROUND_INFORMATION_STARTED });
    const response = await apiUtil.client.put(
      `${config.residentService}/api/v1.0/residents/${residentId}`,
      data
    );
    dispatch({
      type: UPDATE_BACKGROUND_INFORMATION_COMPLETED,
      payload: response.data,
    });
  } catch (error) {
    alert(error);
    dispatch({ type: UPDATE_BACKGROUND_INFORMATION_COMPLETED, payload: null });
  }
};

export const getCodeStatuses = async () => {
  try {
    const baseUrl = `${config.residentService}/api/v1.0/codestatuses/`;
    const response = await apiUtil.client.get(baseUrl);
    return response.data;
  } catch (error) {
    return {error: true, message: error};
  }
};

export const updateMedicalInformation = async (dispatch, residentId, data) => {
  try {
    dispatch({ type: UPDATE_MEDICAL_STARTED });
    await apiUtil.client.put(
      `${config.residentService}/api/v1.0/residents/${residentId}`,
      data
    );
    dispatch({ type: UPDATE_MEDICAL_COMPLETE, payload: data });
  } catch (error) {}
};

export const createNewResidentAdmission = async (
  dispatch,
  residentId,
  model
) => {
  dispatch({ type: ADMIT_RESIDENT_START });
  const response = await createResidentAdmission(residentId, model);
  if (response?.error) {
    dispatch({ type: 'DISPLAY_ERROR_MESSAGE', payload: 'Unable to submit admission' });
  } else {
    dispatch({ type: ADMIT_RESIDENT_COMPLETE, payload: { residentId: residentId, admission: response}});
  }
};

export const setTenantId = (tenantId) => {
  return { type: SET_TENANT_ID, payload: tenantId };
};
