import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  makeStyles
} from "@material-ui/core";
import {FormFieldsGenerator, isModelValid} from "../../../../utils/forms";
import {ColorPicker, createColor} from "material-ui-color";
import {ACTIONS, createCalendarTypeAction, ResidentCalendarContext} from "../context";
import {ResidentCalendarTypeTemplate} from "./model";
import useGlobalNotification from "../../../../hooks/notification.hook";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    height: '50vh'
  }
}));

export const CreateCalendarType = () => {
  const {  state, dispatch } = React.useContext(ResidentCalendarContext);
  const classes = useStyles()
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(ResidentCalendarTypeTemplate)));
  const [color, setColor] = React.useState(createColor("#fff"));
  const { addNotification } = useGlobalNotification();


  React.useEffect(() => {
    setModel(JSON.parse(JSON.stringify(ResidentCalendarTypeTemplate)));
    setColor(createColor("#fff"));
  },[state.viewCreateCalendarTypeModal]);

  const onChange = e => {
    setModel({...model, ...e});
  }

  const save = async () => {
    const data = {}
    Object.keys(model).forEach(key => {
      data[key] = model[key].value;
    });

    data.color = color?.hex ? `#${color.hex}`: color;
    await createCalendarTypeAction(
      dispatch,
      state?.residentId,
      data,
      () => addNotification("Unable to create type", "error"),
      () => addNotification("Calendar type created", "success")
    );
  }

  const onColorChange = color => {
    setColor(color);
  }

  return (
    <Dialog open={state.viewCreateCalendarTypeModal}
            maxWidth={"xl"}
            fullWidth={true}
            onClose={() => dispatch({type: ACTIONS.SET_VIEW_CREATE_CALENDAR_TYPE_MODAL, payload: false})}>
      <DialogTitle>Create Calendar Type</DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <FormFieldsGenerator model={model}
                             onChange={onChange} />

        <FormControlLabel control={<ColorPicker hideTextfield value={color} defaultValue="transparent" onChange={onColorChange} />}
                          label={"Color"}
                          labelPlacement={"start"}
                          style={{ paddingTop: '1rem'}}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch({type: ACTIONS.SET_VIEW_CREATE_CALENDAR_TYPE_MODAL, payload: false})}>
          Cancel
        </Button>
        <Button onClick={save}
                variant={"contained"}
                color={"primary"}
                disabled={!isModelValid(model)}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
