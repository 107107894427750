import React from 'react'
import {Avatar, FormGroup, ListItem, ListItemAvatar, ListItemText, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import moment from 'moment';
import {fuzzySearch} from "../../../services/resident.service";
import config from "../../../utils/configs";
import {UserContext} from "../../../context/usercontext";

const ResidentSearch = ({onSelect, onClear, includeDischarged= false}) => {
  const [searchTerm, setSearchTerm] = React.useState();
  const [residents, setResidents] = React.useState([]);
  const [searchLoading, setSearchLoading] = React.useState(false);
  const { selectedTenant, selectedTenantLocation } = React.useContext(UserContext);

  const onSearcTermChange = e => {
    setSearchTerm(e.target.value);
    if (e?.target?.value?.length > 2) {
      searchResidents(e.target.value)
    } else {
      setResidents([])
    }
  }

  const searchResidents = async (query) => {
    setSearchLoading(true);
    const response = await fuzzySearch(query, selectedTenant?._id, selectedTenantLocation?._id, includeDischarged)
    setResidents(response);
    setSearchLoading(false);
  }

  const buildImageUrlString = (resident) => {
    return `${config.bucketObjectUrl}/${config.publicBucketName}/tenants/${selectedTenant._id}/residents/${resident?._id}/photo/${resident.imageUrl}`;
  };

  const onResidentChange = (event, value) => {
    if (value) {
      onSelect(value);
    } else {
      onClear(true)
    }
  }

  return (
      <div>
        <form noValidate onSubmit={(e) => e.preventDefault()}>
          <FormGroup>
            <Autocomplete
              style={{ height: '3.5rem' }}
              labelId="autocomplete-label"
              title="PRN"
              freeSolo
              id="combo-box-demo"
              options={residents}
              autoHighlight
              autoCorrect

              onChange={onResidentChange}
              loading={searchLoading}
              getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
              renderOption={(option, { selected }) => (
                <ListItem divider>
                  <ListItemAvatar>
                    <Avatar src={buildImageUrlString(option)}></Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${option.firstName} ${option.lastName}`}
                    secondary={`DOB: ${moment(option.dob).format('MM/DD/yyyy')} Room: ${option.roomNumber}`}
                  />
                </ListItem>
              )}
              renderInput={(params) => (
              <TextField color={"primary"}
                         {...params}
                         label="Search Resident...."
                         variant="outlined"
                         size={"small"}
                         value={searchTerm}
                         onChange={onSearcTermChange}
              />
            )} />
          </FormGroup>
        </form>
      </div>
  )
}

export default ResidentSearch
