import React from 'react';
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Toolbar,
  Typography
} from "@material-ui/core";
import Transition from "../../../dialog/transition";
import CloseIcon from "@material-ui/icons/Close";
import {FormFieldsGenerator} from "../../../../utils/forms";
import ModelTemplate from './model';
import {makeStyles} from "@material-ui/core/styles";
import useHasRole from "../../../../hooks/user.role.hook";
import MomentUtils from "@date-io/moment";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    marginTop: "3.5rem",
  },
  medInfoContainer: {
    margin: theme.spacing(1, 0)
  }
}));


const MobileEprn = ({eprn, open, onSave, onClose, resident, users}) => {
  const [model, setModel] = React.useState();
  const { checkPermission } = useHasRole();
  const canEdit = checkPermission('EMAR_UPDATE');
  const classes = useStyles();

  const getAdministeredUserLabel = administeredUser => {
    let user = '';
    if (administeredUser) {
      user = `${administeredUser?.firstName ?? ''} ${administeredUser?.lastName ?? ''} `
      if (administeredUser?.initials) {
        user += ` (${administeredUser?.initials})`
      }
    }
    return user;
  }

  React.useEffect(() => {
    const setUpModel = async () => {
      const obj = JSON.parse(JSON.stringify(ModelTemplate));
      Object.keys(eprn).forEach(key => {
        if (key in obj) {
          obj[key].value = eprn[key];
        }
        obj.given.value = eprn?.status?.name === 'Given';
      });
      if (canEdit && users) {
        obj.administeredUser.dropDownItems =
          [{name: "Select a User", _id: null },
            ...users?.sort((a, b) => a?.lastName > b?.lastName ? 1: -1)?.map(user => ({
              name: getAdministeredUserLabel(user),
              _id: user?._id
            }))
        ];
      }
      obj.administeredUser.value = eprn?.administeredUser?._id;
      setModel(obj);
    }
    if (eprn && users) {
      setUpModel();
    }
    // eslint-disable-next-line
  },[eprn, users]);

  const onChange = (e, field) => {
    setModel({...model, ...e});
  }

  const onRecheckDate = date => {
    const obj = {...model};
    obj.recheckDateTime.value = date;
    obj.recheckDateTime.dirty = true;
    obj.recheckDateTime.isValid = date ? true: false;
    setModel(obj);
  }

  const updateEprn = () => {
    const obj = {}
    Object.keys(model).forEach(key => {
      obj[key] = model[key].value;
    });
    if (model?.given?.value === true) {
      obj.status = { name: 'Given' };
    } else {
      obj.status = { name: 'Scheduled' };
    }
    onSave(obj);
  }

  const isValid = () => {
    if (!moment(model?.administeredTimestamp?.value).isValid()) {
      return false;
    }
    return true;
  }


  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            ePrn
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.dialogContent}
                     dividers
      >
        <Typography variant={"h6"}>
          {resident?.firstName} {resident?.lastName}
        </Typography>
        <Typography variant={"subtitle1"}>
          {eprn?.medicationOrder?.medication?.tradeName}
        </Typography>
        <Typography variant={"subtitle1"}>
          {eprn?.medicationOrder?.medication?.strength} {eprn?.medicationOrder?.medication?.unit?.name}
        </Typography>
        <div className={classes.medInfoContainer}>
          <Divider />
          <Typography variant={"subtitle2"}>
            Description: {eprn?.medicationOrder?.pillDescription}
          </Typography>
          <Typography variant={"subtitle2"}>
            Indication: {eprn?.medicationOrder?.indication}
          </Typography>
          <Typography variant={"subtitle2"}>
            Direction: {eprn?.medicationOrder?.direction}
          </Typography>
          <Typography variant={"subtitle2"}>
            Alerts: {eprn?.medicationOrder?.alerts}
          </Typography>
          <Divider />
        </div>
        <form>
          <FormFieldsGenerator model={model} onChange={onChange} />
          {model?.isRecheck?.value &&
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDateTimePicker
              size="small"
              fullWidth
              name={"recheckDateTime"}
              required={model?.isRecheck?.value}
              value={model?.recheckDateTime?.value}
              label={"Recheck date"}
              format="MM/DD/YYYY hh:mm a"
              onChange={onRecheckDate}
              allowKeyboardControl={true}
              helperText={
                model?.isRecheck?.value === true && !model?.recheckDateTime?.value && model?.recheckDateTime?.dirty
                  ? `Recheck date is required!`
                  : null
              }
              error={model?.isRecheck?.value === true && !model?.recheckDateTime?.value && model?.recheckDateTime?.dirty}
            />
          </MuiPickersUtilsProvider>
          }
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        {canEdit &&
        <Button variant="contained"
                color="primary"
                disabled={!isValid(model)}
                onClick={updateEprn}>
          Save
        </Button>}
      </DialogActions>
    </Dialog>
  )
}

export default MobileEprn;
