import React from 'react';
import {
  Button, CircularProgress,
  Dialog, DialogActions, DialogContent, DialogTitle, Divider,
  FormControl,
  FormGroup, Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs,
  Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {
  getReports,
  createReport
} from "../../services/report.service";
import FileViewer from "react-file-viewer";
import {CloudDownload} from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import CreateReport from "./create.report";
import BirthdayReport from "./resident/birthday/birthday.report";
import ResidentFacesheetReport from "./resident/facesheet/resident.facesheet";
import {CurrentResidentMedicationListReport} from "./resident/medications/CurrentResidentMedicationListReport";
import Skeleton from "@material-ui/lab/Skeleton";
import {TenantNotes} from "../notes";
import useHasRole from "../../hooks/user.role.hook";
import {ResidentCensus} from "./resident/census";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(.5, 0),
    padding: theme.spacing(1)
  },
  progress: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

const Reports = ({viewOnly = false}) => {
  const classes = useStyles();
  const [viewCreateModal, setViewCreateModal] = React.useState(false);
  const [reports, setReports] = React.useState([]);
  const [report, setReport] = React.useState('-1');
  const [reportObjectUrl, setReportObjectUrl] = React.useState();
  const [reportName, setReportName] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [value, setValue] = React.useState("reports");
  const { checkPermission } = useHasRole();
  const canManageReports = checkPermission("REPORT_CREATE");


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const getData = async () => {
    try {
      setError(false);
      setLoading(true);
      const reports = await getReports();
      if (!reports?.error) {
        setReports(reports);
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    getData();
  }, []);


  const onReportSelect = e => {
    setReport(e.target.value);
  }

  const onError = err => {
    alert(err);
  }

  const download = async () => {
    const link = document.createElement('a');
    link.href = reportObjectUrl;
    link.setAttribute(
      'download',
      `${reportName}.pdf`,
    );
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }

  const onAddReport = () => {
    setViewCreateModal(true);
  }

  const onCreateReport = async payload => {
    const response = await createReport(payload);
    if (!response?.error) {
      const obj = [...reports, response];
      setReports(obj);
      setViewCreateModal(false);
    }
  }

  const onReportLoaded = ({ name, objectUrl }) => {
    setReportObjectUrl(objectUrl);
    setReportName(name);
  }

  const onReportRequested = () => {
    setReportObjectUrl(null);
    setOpen(true);
  }


  return (
    <Paper elevation={24}
           className={classes.root}>

      <CreateReport open={viewCreateModal}
                    onClose={() => setViewCreateModal(false)}
                    save={onCreateReport}
      />

      <div style={{display: 'flex', alignItems: 'center'}}>
        {canManageReports &&
          <Button onClick={onAddReport} style={{marginRight: '.5rem'}} startIcon={<AddIcon />}>Add</Button>
        }

        <Typography variant={"h6"}>Reports</Typography>
      </div>

      {!viewOnly &&
        <Tabs value={value} onChange={handleChange} textColor={"primary"} indicatorColor={"primary"}>
          <Tab label={"Reports"} value={"reports"}/>
          {canManageReports &&
            <Tab label={"Management"} value={"management"}/>
          }
        </Tabs>
      }

      <div style={{ padding: '1rem 0'}}>
        {value === "reports" &&
        <>
          <form noValidate>
            <FormGroup>
            {!loading && !error &&
                <FormControl>
                  <InputLabel shrink={true}>Report</InputLabel>
                  <Select value={report || ''}
                          displayEmpty
                          onChange={onReportSelect}>
                    <MenuItem value={'-1'}>Select a report</MenuItem>
                    {reports?.map(report => <MenuItem value={report.name}>{report.name}</MenuItem>)}
                  </Select>
                </FormControl>
            }
            {loading && !error &&
              <FormControl>
                <Skeleton height={20} width={50} />
                <Skeleton height={50} width={"100%"} />
              </FormControl>
            }
            </FormGroup>
          </form>
          {report === 'Birthdays' && <BirthdayReport onReportLoaded={onReportLoaded}
                                                     onReportRequested={onReportRequested} />}
          {report === "Resident Facesheet" && <ResidentFacesheetReport />}
          {report === "Current Resident Medication List" && <CurrentResidentMedicationListReport />}
          {report === "Resident Notes" && <TenantNotes displayBackButton={false} />}
          {report === "Census" && <ResidentCensus />}

          <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth={"lg"}>
            <DialogTitle>{"Report"}</DialogTitle>
            <Divider />
            <DialogContent style={{minHeight: '10rem'}}>
              {reportObjectUrl && <FileViewer
                filePath={reportObjectUrl || ''}
                fileType="pdf"
                onError={onError}
              />}
              {!reportObjectUrl &&
              <div className={classes.progress}>
                <CircularProgress />
              </div>}
            </DialogContent>
            <Divider />
            <DialogActions>
              <Grid container justify={"space-between"}>
                <Button onClick={download} startIcon={<CloudDownload />} color={"primary"} variant={"contained"}>Download</Button>
                <Button color={"primary"} onClick={() => setOpen(false)}>Close</Button>
              </Grid>
            </DialogActions>
          </Dialog>
        </>}
        {value === "management" &&
        <div>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reports?.map(report =>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>{report.name}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        }
      </div>

    </Paper>
  )
}

export default Reports;
