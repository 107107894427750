import React, {useContext} from 'react';
import {
    Accordion, AccordionActions,
    AccordionDetails,
    Button,
    CircularProgress, Divider,
    FormGroup,
    Grid, LinearProgress,
    makeStyles, Typography,
} from "@material-ui/core";
import {PageTitle} from "../../utils";
import {FormFieldsGenerator, isModelValid} from "../../../utils/forms";
import {CareplanContext} from "./context";
import {ACTIONS} from "./context/constants";
import {GoalModel} from "./goal.model";
import {ResidentCareplanGoalInterventions} from "./resident.careplan.goal.interventions";
import SaveIcon from "@material-ui/icons/Save";
import {DeleteBtn} from "../../utils/buttons/DeleteBtn";
import {deleteGoalAction, loadGoalAction, updateGoalAction} from "./context/actions";
import useGlobalNotification from "../../../hooks/notification.hook";
import ConfirmDialog from "../../utils/dialogs/confirm.dialog";
import history from "../../../utils/history";
import {useParams} from "react-router-dom";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TableLoadingIndicator from "../../../utils/indicators/table.loading";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1)
    },
    formContainer: {
        margin: theme.spacing(0, 0, 3, 0)
    },
    btnContainer: {

    },
    accordianRoot: {
        width: "100%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    details: {
        width: "100%"
    },
    column: {

    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

export const ResidentCareplanGoal = () => {
    const classes = useStyles();
    const {dispatch, state} = useContext(CareplanContext);
    const [viewConfirmDialog, setViewConfirmDialog] = React.useState(false);
    const { addNotification } = useGlobalNotification();
    const {residentId, careplanId, goalId} = useParams();

    React.useEffect(() => {
        if (residentId && careplanId && goalId) {
            loadGoalAction(dispatch, residentId, careplanId, goalId).then(() => {});
        }
        // eslint-disable-next-line
    }, [residentId, careplanId, goalId])



    React.useEffect(() => {
        if (state?.goal) {
            const obj = JSON.parse(JSON.stringify(GoalModel));
            obj.name.value = state?.goal?.name;
            obj.description.value = state?.goal?.description;
            dispatch({type: ACTIONS.SET_GOAL_MODEL, payload: obj});
        }
        // eslint-disable-next-line
    },[state?.goal]);

    const onChange = e => {
        const obj = {...state?.goalModel, ...e};
        dispatch({type: ACTIONS.SET_GOAL_MODEL, payload: obj});
    }

    const handleUpdate = async () => {
        let data = {
            name: state?.goalModel?.name?.value,
            description: state?.goalModel?.description?.value
        };
        const success = await updateGoalAction(dispatch, state?.residentId, state?.careplan?._id, state?.goal?._id, data);
        if (success) {
            addNotification("Goal Updated", "success");
        } else {
            addNotification("Unable to update goal", "error");
        }

    }

    const handleConfirmDelete = async () => {
        await deleteGoalAction(dispatch, state?.residentId, state?.careplan?._id, state?.goal?._id);
        setViewConfirmDialog(false);
        history.goBack();
    }

    return (
        <div className={classes.root}>
            <Accordion defaultExpanded>
                {state?.loadingGoal && <LinearProgress color={"primary"} />}
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                >
                    {!state?.loadingGoal &&
                        <PageTitle title={!state?.loading ? `Goal: ${state?.goal?.name ?? null}`: null} />
                    }
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    {state?.loadingGoal &&
                        <div style={{width: "100%"}}>
                            <TableLoadingIndicator rows={5} cols={1} />
                        </div>
                    }
                    {!state?.loadingGoal &&
                        <FormGroup style={{width: "100%"}}>
                            <FormFieldsGenerator model={state?.goalModel} onChange={onChange} />
                        </FormGroup>
                    }
                </AccordionDetails>
                {!state?.loadingGoal &&
                <AccordionActions>
                    <Divider />
                     <Grid container
                          style={{padding: ".5rem"}}
                          justifyContent={"space-between"}
                          alignContent={"center"}
                    >
                        <Grid item>
                            <DeleteBtn onClick={() => setViewConfirmDialog(true)}
                                       disabled={state?.updatingGoal}
                            />
                        </Grid>
                        <Grid item>
                            <Button variant={"text"}>
                                Cancel
                            </Button>
                            <Button variant={"contained"}
                                    startIcon={state?.updatingGoal ? <CircularProgress size={20} />: <SaveIcon />}
                                    disabled={!isModelValid(state?.goalModel) || state?.updatingGoal}
                                    onClick={handleUpdate}
                                    color={"primary"}>
                                Update
                            </Button>
                        </Grid>
                    </Grid>
                </AccordionActions>
                }
            </Accordion>
            {!state?.loadingGoal &&
                <Accordion defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="goals-content"
                    id="goals-header"
                >
                    <Typography variant={"h6"}>Careplan Interventions</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    <ResidentCareplanGoalInterventions careplan={state?.careplan} goal={state?.goal} />
                </AccordionDetails>
            </Accordion>
            }
            <ConfirmDialog
                open={viewConfirmDialog}
                title={"Delete Goal"}
                message={"Do you want to delete this goal? All related interventions and events will be deleted."}
                onClose={() => setViewConfirmDialog(false)}
                onConfirm={handleConfirmDelete}
            />
        </div>
    )
}
