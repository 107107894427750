import {Link as RouterLink, Route, useLocation } from "react-router-dom";
import {Breadcrumbs, Grid, makeStyles, Typography} from "@material-ui/core";
import React from "react";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import {NavigationContext} from "../../context/NavigationContext";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 0, 0, 1),
    margin: 0
  },
  link: {
    color: theme.palette.primary.contrastText,
    padding: 0,
    margin: 0,
    textDecoration: 'none'
  }
}))

export const RouterBreadcrumbs = () => {
  let location = useLocation();
  const { resident } = React.useContext(NavigationContext);
  const classes = useStyles();

  const getLabel = value => {
    if (resident?._id === value) {
      return `${resident?.firstName} ${resident?.lastName}`;
    } else {
      return value;
    }
  }

  return (
    <Grid container
          className={classes.root}
          justifyContent={"flex-start"}
          alignItems={"center"}>
      <Route>
        {() => {
          const pathnames = location.pathname.split('/').filter((x) => x);
          return (
            <Breadcrumbs aria-label="breadcrumb"
                         separator={<NavigateNextIcon style={{ color: '#fff'}} fontSize="small" />}
            >
              {pathnames.map((value, index) => {
                let route = value;
                if (index === 1) {
                  route = `${pathnames[index - 1]}/${pathnames[index]}`
                }
                if (index === 2) {
                  route = `${pathnames[index - 2]}/${pathnames[index - 1]}/${pathnames[index]}`
                }
                if (index === 3) {
                  route = `${pathnames[index - 3]}/${pathnames[index - 2]}/${pathnames[index - 1]}/${pathnames[index]}`
                }
                if (index === 4) {
                  route = `${pathnames[index - 4]}/${pathnames[index - 3]}/${pathnames[index - 2]}/${pathnames[index - 1]}/${pathnames[index]}`
                }
                if (index === 5) {
                  route = `${pathnames[index - 5]}/${pathnames[index - 4]}/${pathnames[index - 3]}/${pathnames[index - 2]}/${pathnames[index - 1]}/${pathnames[index]}`
                }
                if (index === 6) {
                  route = `${pathnames[index - 6]}/${pathnames[index - 5]}/${pathnames[index - 4]}/${pathnames[index - 3]}/${pathnames[index - 2]}/${pathnames[index - 1]}/${pathnames[index]}`
                }
                if (index === 7) {
                  route = `${pathnames[index - 7]}/${pathnames[index - 6]}/${pathnames[index - 5]}/${pathnames[index - 4]}/${pathnames[index - 3]}/${pathnames[index - 2]}/${pathnames[index - 1]}/${pathnames[index]}`
                }
                return (
                  <Typography component={RouterLink}
                              to={`/${route}`}
                              className={classes.link}
                              variant={"subtitle2"}>
                    {getLabel(value)}
                  </Typography>
                )})}
            </Breadcrumbs>
          );
        }}
      </Route>
    </Grid>
  );
}
