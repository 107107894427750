import React from "react";
import MaskedInput from 'react-text-mask'
import {Input, InputLabel} from "@material-ui/core";

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'_'}
      showMask
    />
  );
}

const ZipMask = ({ name, value, onChange, canEdit, label, required, ...p }) => {
  const [val, setVal] = React.useState(null);
  React.useEffect(() => {
    setVal(value);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  React.useEffect(() => {
    setVal(value)
  }, [ value ])


  const onValueChange = (e) => {
    setVal(e.target.value);
    onChange(e);
  };
  return (
    <React.Fragment>
      <InputLabel htmlFor="formatted-text-mask-input" shrink required={required}>{label}</InputLabel>
      <Input
        disabled={canEdit}
        value={val}
        onChange={onValueChange}
        name={name}
        id="formatted-text-mask-input"
        inputComponent={TextMaskCustom}
      />
    </React.Fragment>
  );
};

export default ZipMask;
