import React from 'react';
import { Button, FormGroup, makeStyles, Paper } from '@material-ui/core';
import { getLocation, updateLocation } from '../../services/tenant.service';
import { FormFieldsGenerator, isModelValid } from '../../utils/forms';
import { LocationsContext } from './locations.context';
import LocationModel from './location.model';
import useTenantLocationHooks from '../../hooks/tenant.location.hook';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  contentContainer: {
    paddingTop: '5rem',
  },
}));

const Location = ({ match }) => {
  const classes = useStyles();
  const { dispatch } = React.useContext(LocationsContext);
  const [model, setModel] = React.useState(
    JSON.parse(JSON.stringify(LocationModel))
  );
  const [location, setLocation] = React.useState();

  const [onLocationUpdate] = useTenantLocationHooks();

  React.useEffect(() => {
    if (match.params.id) {
      getData(match.params.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]);

  const getData = async (id) => {
    const entity = await getLocation(id);
    setLocation(entity);
    dispatch({ type: 'setLocation', payload: entity });
    parseLocation(entity);
  };

  const parseLocation = (loc) => {
    const obj = { ...model };
    Object.keys(loc).forEach((k) => {
      if (obj[k] !== undefined && k !== 'address') {
        obj[k].value = loc[k];
      }
    });
    if (loc.address) {
      obj.address.value = loc.address.address;
      obj.address2.value = loc.address.address2;
      obj.city.value = loc.address.city;
      obj.state.value = loc.address.state;
      obj.zipCode.value = loc.address.zipCode;
    }

    setModel(obj);
  };

  const onChange = (e) => {
    setModel({ ...model, ...e });
  };

  const cancelEdit = () => {
    parseLocation(location);
  };

  const onSave = async () => {
    let data = {};
    Object.keys(model).forEach((k) => {
      if (
        ['address', 'address2', 'city', 'state', 'zipCode', 'phones'].indexOf(
          k
        ) < 0
      ) {
        data[k] = model[k].value;
      } else if (k !== 'phones') {
        if (!data.address) {
          data.address = {};
        }
        data['address'][k] = model[k].value;
      } else if (k === 'phones') {
        data.phones = model[k].value;
      }
    });
    const entity = await updateLocation(location.tenant, location._id, data);
    onLocationUpdate(entity);
  };

  return (
    <Paper className={classes.root} elevation={24}>
      <form noValidate>
        <FormFieldsGenerator model={model} onChange={onChange} />
        <FormGroup
          row
          style={{ justifyContent: 'flex-end', marginTop: '.5rem' }}
        >
          <Button color="primary" onClick={cancelEdit}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={!isModelValid(model)}
            onClick={onSave}
          >
            Save
          </Button>
        </FormGroup>
      </form>
    </Paper>
  );
};

export default Location;
