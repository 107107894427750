import React from 'react';
import {FormGroup, MenuItem, TextField} from "@material-ui/core";
import moment from "moment-timezone";
import {MONTHS} from "./months";

export const MonthDropdown = ({onSelection, showYear = true}) => {
  const [years, setYears] = React.useState([]);
  const [year, setYear] = React.useState();
  const [month, setMonth] = React.useState();

  React.useEffect(() => {
    const current = moment();
    generateYears(current.get("year"));
    setMonth(current.get('month') + 1);
    setYear(current.get('year'));
    onSelection({year: current?.get('year'), month: current.get('month') + 1});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const generateYears = (cur) => {
    const obj = [];
    for (let min = cur - 5; min <= cur; min++) {
      obj.push(min);
    }
    setYears(obj);
  };

  const changeYear = event => {
    setYear(event.target.value);
    onSelection({year: event.target.value, month: month})
  }

  const changeMonth = event => {
    setMonth(event.target.value);
    onSelection({year: year, month: event.target.value})
  }

  return (
    <FormGroup>
      <TextField
        onChange={changeYear}
        label="Year"
        fullwidth
        select
        value={year | ""}
      >
        {years?.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
      </TextField>
      <TextField
        onChange={changeMonth}
        label="Month"
        fullwidth
        select
        value={month | ""}
      >
        {MONTHS?.map((month) => (
            <MenuItem key={month.val} value={month.val}>
              {month.name}
            </MenuItem>
          ))}
      </TextField>
    </FormGroup>
  )
}
