import React from 'react';
import {Dialog, DialogContent, DialogTitle, IconButton, makeStyles} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {ResidentIncidentPdf} from "./pdf";
import {PDFViewer} from "@react-pdf/renderer";

const useStyles = makeStyles((theme) => ({
  root: {
  },
  dialogContent: {
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  mainContent: {
    minHeight: '50vh'
  }
}));

export const IncidentReportDialog = ({ open, onClose, resident, data }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      fullWidth
      maxWidth={"lg"}>
      <DialogTitle
        onClose={() => onClose()}>
        {"Incident Report"}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <PDFViewer style={{width: '100%', height: '70vh'}}>
          <ResidentIncidentPdf resident={resident} log={data} />
        </PDFViewer>
      </DialogContent>
    </Dialog>
  )
}
