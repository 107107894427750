import apiUtil from '../utils/api.utils';
import configs from '../utils/configs';
const baseUrl = `${configs.residentService}`;


export const getEthnicities = async () => {
    try {
        const response = await apiUtil.client.get(`${baseUrl}/api/v1.0/ethnicities`);
        return response.data;
    } catch (err) {
        alert(err);
        return {content:[]};
    }
}