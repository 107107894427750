const filterTemplate = {
  isPrn: {
    field: 'isPrn',
    value: null,
    operator: "==",
    minValue: 1,
  },
  medicationName: {
    field: "medication.tradeName",
    minValue: 3,
    value: null,
    operator: "*=*",
  },
  status: {
    field: "orderStatus",
    minValue: 1,
    value: [],
    operator: "==",
  },
}

export default filterTemplate;
