import React from 'react';
import Transition from "../dialog/transition";
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent, FormControl,
  IconButton, InputLabel,
  Link, makeStyles, MenuItem, Select,
  Toolbar,
  Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { tenantModel, globalModel } from './assessment.model'
import {FormFieldsGenerator, isModelValid} from "../../utils/forms";
import {ACTIONS, AssessmentsContext} from "./assessments.context";
import {getAssessmentTypesAction} from "./assessment.actions";
import {getTenants} from "../../services/tenant.service";
import {UserContext} from "../../context/usercontext";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    marginTop: theme.spacing(8)
  }
}))

const CreateAssessment = ({open, onClose, onSave}) => {
  const classes = useStyles();
  const {user} = React.useContext(UserContext);
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(tenantModel)));
  const { state, dispatch } = React.useContext(AssessmentsContext);
  const {selectedTenant} = React.useContext(UserContext);

  React.useEffect( () => {
    if (open) {
      if (!state?.types || state.types.length === 0) {
        callGetAssessmentTypes();
      } else {
        setupModel();
      }
    }
    // eslint-disable-next-line
  }, [open, state?.types, selectedTenant]);

  const setupModel = async () => {
    let obj;
    if (user?.isSystemAdmin) {
      obj = JSON.parse(JSON.stringify(globalModel));
      const tenants = await getTenants();
      obj.tenant.dropDownItems = tenants;
    } else {
      obj = JSON.parse(JSON.stringify(tenantModel));
      obj.tenant.value = selectedTenant._id;
      obj.tenant.isValid = true;
    }
    obj.type.dropDownItems = state.types;
    dispatch({type: ACTIONS.SET_ASSESSMENT_MODEL, payload: obj })
    setModel(obj);
  }

  const callGetAssessmentTypes = async () => {
    await getAssessmentTypesAction(dispatch);
  }


  const onChange = e => {
    const obj = {...model, ...e};
    setModel(obj);
  }

  const save = () => {
    const obj = {}
    Object.keys(model).forEach(key => {
      obj[key] = model[key].value;
    });
    onSave(obj);
  }

  const onTenantChange = e => {
    const obj = {...model};
    obj.tenant.value = e.target.value;
    setModel(obj);
  }

  const checkIsModelValid = () => {
    if (!isModelValid(model)) {
      return false;
    }
    if (user?.isSystemAdmin) {
      if (model?.isGlobal?.value === true) {
        return true;
      } else if (model?.tenant?.value) {
        return true;
      } else {
        return false;
      }
    } else {
      return isModelValid(model);
    }
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => onClose()}
      TransitionComponent={Transition}
    >
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => onClose()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Create Assessment</Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.dialogContent}>
        <FormFieldsGenerator model={model} onChange={onChange} />
        {user?.isSystemAdmin && !model?.isGlobal?.value &&
        <FormControl fullWidth>
          <InputLabel shrink>Tenant</InputLabel>
          <Select fullWidth
                  value={model?.tenant?.value}
                  label={"Tenant"}
                  displayEmpty
                  onChange={onTenantChange}

          >
            <MenuItem>Select One</MenuItem>
            {model?.tenant?.dropDownItems?.map(item =>
              <MenuItem value={item._id}>{item.name}</MenuItem>

            )}
          </Select>
        </FormControl>}
      </DialogContent>
      <DialogActions>
        <Link onClick={onClose}>Cancel</Link>
        <Button
          variant="contained"
          color="primary"
          disabled={!checkIsModelValid()}
          onClick={save}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateAssessment;
