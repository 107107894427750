import React from 'react';
import {
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {
  getTenantLocationRoles,
  getTenantRoles,
  createTenantRole,
  updateTenantRole,
  createTenantLocationRole,
  deleteTenantLocationRole,
  getTenantLocations,
} from '../../../services/tenant.service';
import CreateRoleModal from './create.role.modal';
// import useHasRole from "../../../hooks/user.role.hook";
import {getStripedStyle} from "../../utils/table.utils";
import Checkbox from "@material-ui/core/Checkbox";
import {RolePermissionModal} from "./RolePermissionModal";
import useGlobalNotification from "../../../hooks/notification.hook";
import useGlobalLoadingIndicator from "../../../hooks/global.loading.hook";
import {Add} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  tableBody: {
    height: "30vh",
    overflow: "auto"
  }
}));

const TenantRoles = ({ tenant, permissions }) => {
  const classes = useStyles();
  const [roles, setRoles] = React.useState([]);
  const [locations, setLocations] = React.useState();
  const [showCreateRoleModal, setShowCreateRoleModal] = React.useState(false);
  const [tenantRoles, setTenantRoles] = React.useState([]);
  const [selectedRole, setSelectedRole] = React.useState();
  const [viewRolePermissionModal, setViewRolePermissionModal] = React.useState(false);
  const { toggleLoading } = useGlobalLoadingIndicator();
  const { addNotification } = useGlobalNotification();
  // const { checkPermission } = useHasRole();
  // const canUpdate = checkPermission("ROLE_UPDATE");

  React.useEffect(() => {
    const getData = async (id) => {
      const response = await Promise.all([
        getTenantLocationRoles(id),
        getTenantRoles(id),
        getTenantLocations(id)
      ]);
      setTenantRoles(response[0]);
      setRoles(response[1]);
      setLocations(response[2]);
    };
    if (tenant) {
      getData(tenant._id);
    }
  }, [tenant]);

  const onRoleCreate = async (role) => {
    toggleLoading(true);
    const entity = await createTenantRole(tenant._id, role);
    setRoles([...roles, entity]);
    setShowCreateRoleModal(false);
    toggleLoading(false);
    addNotification("Role Created", "success");
  };

  const onRoleSave = async payload => {
    toggleLoading(true);
    const entity = await updateTenantRole(tenant?._id, payload?._id, payload);
    setRoles(roles?.map(r => r?._id === entity?._id ? entity: r));
    toggleLoading(false);
    addNotification("Permission Updated", "success");

  }

  const onLocationRoleChange = async (location, role, event) => {
    toggleLoading(true);
    if (event?.target?.checked) {
      const entity = await createTenantLocationRole(tenant._id, {
        location: location?._id,
        role: role?._id
      });
      setTenantRoles([...tenantRoles, entity]);
      addNotification(`${role?.name} assigned to location ${location?.name}`, "success");
    } else {
      const locationRole = tenantRoles?.find(tr => tr?.location?._id === location?._id && tr?.role?._id === role?._id);
      if (locationRole) {
        const response = await deleteTenantLocationRole(tenant._id, locationRole._id);
        if (!response?.error) {
          setTenantRoles(tenantRoles.filter((tr) => tr._id !== locationRole._id));
          addNotification(`${role?.name} removed from location ${location?.name}`, "success");
        } else {
          addNotification(`There are users associated to for this role at this location, you are unable to uncheck.`)
        }
      }
    }
    toggleLoading(false);
  }

  const getCellContent = (location, role) => {
      return (
        <Checkbox
          checked={tenantRoles?.findIndex(tr => tr?.location?._id === location?._id && tr?.role?._id === role?._id) > -1}
          tabIndex={-1}
          disableRipple
          inputProps={{ 'aria-labelledby':  'location' }}
          onChange={(evt) => onLocationRoleChange(location, role, evt)}
        />
      )
  }

  const handleViewPermissionsForRole = role => {
    setSelectedRole(role);
    setViewRolePermissionModal(true);
  }

  return (
    <div>
      <CreateRoleModal
        open={showCreateRoleModal}
        onClose={() => setShowCreateRoleModal(false)}
        onSave={onRoleCreate}
        permissions={permissions}
      />
      <RolePermissionModal open={viewRolePermissionModal}
                           onClose={() => setViewRolePermissionModal(false)}
                           role={selectedRole}
                           permissions={permissions}
                           onSave={onRoleSave}
      />
      <Button startIcon={<Add />}
              onClick={() => setShowCreateRoleModal(true)}
              variant={"text"}>
        Create Role
      </Button>
      <TableContainer>
        <Table size={"small"}
        >
          <TableHead>
            <TableRow>
              <TableCell width={400}>Name</TableCell>
              {locations?.map(location =>
              <TableCell>
                {location?.name}
              </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {roles?.map((role, index) =>
              <TableRow style={getStripedStyle(index)}>
                <TableCell>
                  <Button variant={"text"}
                          size={"small"}
                          onClick={() => handleViewPermissionsForRole(role)}
                  >
                    {role?.name} {role?.permissions?.length > 0 ? `(${role?.permissions?.length} Permissions)`: null}
                  </Button>
                </TableCell>
                {locations?.map(location =>
                  <TableCell>
                    {getCellContent(location, role)}
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
};

export default TenantRoles;
