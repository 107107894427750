import React from 'react';
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

export const DatetimeInput = ({value, onChange, format, ...p}) => {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDateTimePicker value={value}
                              format={format}
                              onChange={onChange}
                              {...p}
      />
    </MuiPickersUtilsProvider>
  )
}
