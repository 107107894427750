import {View, Text, StyleSheet} from "@react-pdf/renderer";
import * as _ from "lodash";

const BORDER_COLOR = '#bfbfbf';
const BORDER_STYLE = 'solid';

const styles = StyleSheet.create({
    page: {
        padding: 2,
        marginTop: 1
    },
    title: {
        fontSize: 12,
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 2,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderTopWidth: 0,
    },
    detail: {
        fontSize: 8,
    }
});

export const InitialLegend = ({data}) => {

    const extractUsers = () => {
        return _.uniqBy(
        _.flattenDeep(
            data.map(order => order?.dosageInstructions?.map(di =>
                di?.events?.filter(e => e?.administeredUser)?.map(e => e?.administeredUser)
                )
            )
        ), "_id");
    }

    return (
        <View style={styles.page}>
            <View style={styles.title}>
                <Text>Initials Legend</Text>
            </View>
            {extractUsers()?.map(user =>
                <View style={styles.detail}>
                    <Text>
                        {user?.initials}, {user?.firstName} {user?.lastName}
                    </Text>
                </View>
            )}
        </View>
    )
}
