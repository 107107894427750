import {
  LOAD_RESIDENT_INSURANCES_START,
  LOAD_RESIDENT_INSURANCES_COMPLETE,
  UPDATE_RESIDENT_INSURANCES_START,
  UPDATE_RESIDENT_INSURANCES_COMPLETE,
  CREATE_RESIDENT_INSURANCE_START,
  CREATE_RESIDENT_INSURANCE_COMPLETE,
  DELETE_RESIDENT_INSURANCE_START,
  DELETE_RESIDENT_INSURANCE_COMPLETE,
  OPEN_INSURANCE_DIALOG,
  CLOSE_INSURANCE_DIALOG,
  TOGGLE_ERROR
} from "./resident.insurance.context";
import * as residentService from '../../../services/resident.service';

export const openInsuranceLookupDialog = () => ({
  type: OPEN_INSURANCE_DIALOG
});
export const closeInsuranceLookupDialog = () => ({
  type: CLOSE_INSURANCE_DIALOG
});

export const getResidentInsurances = async (dispatch, residentId) => {
  try {
    dispatch({ type: LOAD_RESIDENT_INSURANCES_START });
    const response = await residentService.getResidentInsurances(residentId);
    dispatch({
      type: LOAD_RESIDENT_INSURANCES_COMPLETE,
      payload: response
    });
  } catch (error) {
    alert(error);
    dispatch({ type: LOAD_RESIDENT_INSURANCES_COMPLETE });
  }
};

export const createResidentInsurance = async (
  dispatch,
  residentId,
  insuranceId,
  isPrimary,
) => {
  try {
    dispatch({ type: CREATE_RESIDENT_INSURANCE_START });
    const response = await residentService.createResidentInsurance(residentId, insuranceId,  isPrimary);
    dispatch({
      type: CREATE_RESIDENT_INSURANCE_COMPLETE,
      payload: response
    });
  } catch (error) {
    alert(error);
    dispatch({ type: LOAD_RESIDENT_INSURANCES_COMPLETE });
  }
};

export const updateResidentInsurance = async (
  dispatch,
  residentId,
  residentInsuranceId,
  insurance
) => {
  try {
    dispatch({type: TOGGLE_ERROR, payload: { error: false, message: null }});
    dispatch({ type: UPDATE_RESIDENT_INSURANCES_START });
    const response = await residentService.updateResidentInsurance(residentId, residentInsuranceId, insurance);
    if (!response?.error) {
      dispatch({
        type: UPDATE_RESIDENT_INSURANCES_COMPLETE,
        payload: response
      });
    } else {
      dispatch({type: TOGGLE_ERROR, payload: { error: true, message: 'Error updating resident insurance' }})
    }
  } catch (error) {
    dispatch({ type: UPDATE_RESIDENT_INSURANCES_COMPLETE });
  }
};

export const deleteResidentInsurance = async (
  dispatch,
  residentId,
  residentInsuranceId
) => {
  try {
    dispatch({ type: DELETE_RESIDENT_INSURANCE_START });
    const result = await residentService.deleteResidentInsurance(residentId, residentInsuranceId);
    if(!result?.error) {
      dispatch({
        type: DELETE_RESIDENT_INSURANCE_COMPLETE,
        payload: residentInsuranceId
      });
    } else {
      dispatch({type: TOGGLE_ERROR, payload: { error: true, message: 'Unable to delete'} });
    }
  } catch (error) {
    dispatch({ type: DELETE_RESIDENT_INSURANCE_COMPLETE });
  }
};
