import React from "react";
import {
  Button,
  FormGroup,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  DialogContent,
  makeStyles,
} from "@material-ui/core";
import Transition from "../../../dialog/transition";
import CloseIcon from "@material-ui/icons/Close";
import FormFieldsGenerator from "../../../../utils/forms/form.fields.generator";
import modelTemplate from "./modelTemplate";
import {UserContext} from "../../../../context/usercontext";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    marginTop: "4rem",
  },
}));

const CreateResidentLungSound = ({ open, users, onClose, onSave, residentId }) => {
  const classes = useStyles();
  const { user } = React.useContext(UserContext);
  const [model, setModel] = React.useState(
    JSON.parse(JSON.stringify({}))
  );

  React.useEffect(() => {
    if (open && users && user) {
        const obj = JSON.parse(JSON.stringify(modelTemplate));
        obj.date.value = new Date();
        obj.staff.dropDownItems = users;
        obj.staff.value = user?._id;
        setModel(obj);
    }
  }, [open, user, users]);


  const onModelChange = (updatedModel) => {
    const obj = { ...model, ...updatedModel };
    setModel(obj);
  };

  const isValid = () => {
    let valid = false;
    Object.keys(model).forEach((key) => {
      if (model[key].value && String(model[key].value).length > 0) {
        valid = true;
      }
    });
    return valid;
  };

  const save = () => {
    let data = {};
    Object.keys(model).forEach(key => {
        if (model[key] && model[key].value && String(model[key].value).length > 0) {
            data[key] = model[key].value
        }
    });
    onSave(residentId, data);
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
           Create Lung Sound
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.dialogContent}>
        <form
          className={classes.form}
          style={{ width: "100%", marginBottom: "1rem" }}
          noValidate
        >
          <FormFieldsGenerator model={model} onChange={onModelChange} />
          <FormGroup
            row
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "1rem",
            }}
          >
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={save} variant="contained" color="primary" disabled={!isValid()}>
              Save
            </Button>
          </FormGroup>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateResidentLungSound;
