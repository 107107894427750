import React from 'react';
import {Drawer, makeStyles} from "@material-ui/core";
import {CONSTANTS} from "../context/Constants";
import {ResidentFileContext} from "../context/ResidentFilesContext";
import {CopyFile} from "./CopyFile";
import {MoveFile} from "./MoveFile";
import {RenameFile} from "./RenameFile";
import {onFileCopyAction, onFileMoveAction, onFileRenameAction} from "../context/Actions";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "30vw"
    }
}));

export const FileDrawer = () => {
    const classes = useStyles();
    const {state, dispatch} = React.useContext(ResidentFileContext);

    const handleDrawerClose = () => {
        dispatch({type: CONSTANTS.SET_VIEW_COPY_MODAL, payload: false});
        dispatch({type: CONSTANTS.SET_VIEW_MOVE_MODAL, payload: false});
        dispatch({type: CONSTANTS.SET_VIEW_RENAME_MODAL, payload: false});
    }

    const onRename = async (payload) => {
        await onFileRenameAction(dispatch,
            state?.residentId,
            payload?.file,
            payload?.fileName);
    }

    const onMove = async (payload) => {
        await onFileMoveAction(
            dispatch,
            state?.residentId,
            payload?.file,
            payload?.parent
        );
    }

    const onCopy = async (payload) => {
        await onFileCopyAction(dispatch,
            payload?.parent,
            state?.residentId,
            payload?.file?._id,
            payload?.destination,
            payload?.fileName,
            JSON.parse(JSON.stringify(state?.breadcrumbs))
        );
    }

    return (
        <Drawer anchor={"right"}
                className={classes.root}
                open={state?.viewCopyFileModal || state?.viewMoveFileModal || state?.viewRenameFileModal}
                onClose={handleDrawerClose}>
            {state?.viewCopyFileModal && <CopyFile onCopy={onCopy} />}
            {state?.viewMoveFileModal && <MoveFile onMove={onMove} />}
            {state?.viewRenameFileModal && <RenameFile onRename={onRename} />}

        </Drawer>
    )
}
