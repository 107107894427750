const maritalStatus = [
    { name: "" },
    { name: "Divorced" },
    { name: "Legally Separated" },
    { name: "Married" },
    { name: "Single" },
    { name: "Widowed" }
  ];
  
export const getMaritalStatuses = async () => {
    return Promise.resolve({ content: maritalStatus });
}