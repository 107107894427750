export const tenantModel = {
  name: {
    title: 'Name',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  description: {
    title: 'Description',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  type: {
    title: 'Type',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [],
    dropDownItemValue: 'name',
    dropDownItemLabel: 'name',
    date: false,
  },
  tags: {
    title: 'Tags',
    isTagsComponent: true,
    hidden: false,
    value: [],
    required: false,
  },
  tenant: {
    hidden: true,
    title: 'Tenant',
    required: true
  },
  status: {
    hidden: false,
    title: 'Status',
    dropDown: true,
    required: true,
    dropDownItems: [{ name: "Active"}, {name: "Development in progress"}, { name:"Inactive"}],
    dropDownItemValue: 'name',
    dropDownItemLabel: 'name',
  }
}

export const globalModel = {
  name: {
    title: 'Name',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  description: {
    title: 'Description',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  type: {
    title: 'Type',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [],
    dropDownItemValue: 'name',
    dropDownItemLabel: 'name',
    date: false,
  },
  tags: {
    title: 'Tags',
    isTagsComponent: true,
    hidden: false,
    value: [],
    required: false,
  },
  isGlobal: {
    required: false,
    title: "Is Global",
    value: true,
    isToggle: true
  },
  tenant: {
    hidden: true,
    title: 'Tenant',
    dropDown: true,
    required: false,
    dropDownItems: [],
    dropDownItemValue: '_id',
    dropDownItemLabel: 'name',
  },
  status: {
    hidden: false,
    title: 'Status',
    dropDown: true,
    required: true,
    dropDownItems: [{ name: "Active"}, {name: "Development in progress"}, { name:"Inactive"}],
    dropDownItemValue: 'name',
    dropDownItemLabel: 'name',
  }
}
