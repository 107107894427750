export const validateVitals = (vitalNames, vital) => {
  console.log(vitalNames);
  const fields = mapVitalNamesToFields(vitalNames);
  console.log('feilds', fields);
  if (!vital) {
    return false;
  }
  let isValid = true;
  console.log(vital);
  for (const field of fields) {
    console.log('field', field);
    const val = vital[field]
    console.log(val);
    if (val === null || val === undefined || val === '') {
      console.log('faild');
      isValid = false;
    }
  }
  return isValid;
}

export const mapVitalNamesToFields = vitalNames => {
  let mappedVitalFields = [];
  let vals = vitalNames?.filter(name => name !== "All");
  for (const name of vals) {
    switch (name) {
      case "Temperature":
        mappedVitalFields = [...mappedVitalFields, "temperature"];
        break;
      case "Blood Pressure":
        mappedVitalFields = [...mappedVitalFields, ...["systolicBloodPressure", "diastolicBloodPressure"]];
        break;
      case "Blood Glucose":
        mappedVitalFields = [...mappedVitalFields, "bloodGlucose"];
        break;
      case "Pulse":
        mappedVitalFields = [...mappedVitalFields, "pulse"];
        break;
      case "Respiratory":
        mappedVitalFields = [...mappedVitalFields, "respiratoryRate"];
        break;
      case "Oxygen":
        mappedVitalFields = [...mappedVitalFields, "oxygen"];
        break;
      case "INR":
        mappedVitalFields = [...mappedVitalFields, "inr"];
        break;
      case "Weight":
        mappedVitalFields = [...mappedVitalFields, "weight"];
        break;
      case "BMI":
        mappedVitalFields = [...mappedVitalFields, "bmi"];
        break;
      case "Height":
        mappedVitalFields = [...mappedVitalFields, "height"];
        break;
      case "Pain Level":
        mappedVitalFields = [...mappedVitalFields, "painLevel"];
        break;
      default:
        console.log('Missed Mapping', name);
    }
  }
  return mappedVitalFields;
}
