import React, {useContext} from 'react';
import {Link, makeStyles, Paper, Typography} from "@material-ui/core";
import {useHistory, useParams, Link as RouterLink} from 'react-router-dom';
import {CareplanContext} from "./context";
import {syncInterventionToGoalAction} from "./context/actions";


const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(.5, 0),
        padding: theme.spacing(1),
        minHeight: '40vh'
    },
    createContainer: {
        marginTop: '4rem',
    },
}));

export const ResidentCareplanIntervention = () => {
    const classes = useStyles();
    const history = useHistory();
    const {dispatch, state} = useContext(CareplanContext);
    const {residentId, careplanId, interventionId} = useParams();

    React.useEffect(() => {
        if (residentId && careplanId && interventionId && dispatch) {
            syncInterventionToGoalAction(dispatch, residentId, careplanId, interventionId).then(() =>{})
        }
    },[residentId, careplanId, interventionId, dispatch]);

    React.useEffect(() => {
        if (state?.residentId && state?.careplan?._id && state?.goal?._id && state?.intervention?._id) {
            history.push(`/residents/${state.residentId}/careplans/${state?.careplan?._id}/goals/${state?.goal?._id}/interventions/${state?.intervention?._id}`);
        }
        // eslint-disable-next-line
    },[state?.residentId, state?.careplan, state?.goal, state?.intervention])

    return (
        <Paper elevation={24} className={classes.root}>
            {state?.loadingIntervention && <Typography variant={"h5"}>Loading Intervention.</Typography>}
            {!state?.loadingIntervention && state?.error &&
                <Typography variant={"p"}>
                    Unable to find Careplan, goal, and intervention. Click {" "}
                    <Link component={RouterLink} to={`/residents/${residentId}/careplans/${careplanId}`}>here</Link>
                    {" "}
                    to navigate to careplan.
                </Typography>

            }
        </Paper>
    )
}
