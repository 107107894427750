import React from "react";
import { Button, FormGroup, TextField } from "@material-ui/core";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  DialogContent,
  makeStyles
} from "@material-ui/core";
import Transition from "../dialog/transition";
import CloseIcon from "@material-ui/icons/Close";
import { createContact } from '../../services/contact.service';
import {GlobalNotificationContext} from "../notification/globalnotificationprovider";


const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1),
    paddingTop: "4rem"
  },
  appBar: {},
  dialogContent: {
    marginTop: "4rem"
  },
  btnContainer: {
    paddingTop: ".5rem",
    display: "flex",
    justifyContent: "flex-end"
  }
}));

const contactModel = {
  firstName: {
    required: true,
    value: null,
    dirty: false,
    valid: false
  },
  lastName: {
    required: true,
    value: null,
    dirty: false,
    valid: false
  },
  email: {
    required: false,
    value: null,
    dirty: false,
    valid: true
  }
};

const CreateContact = ({ onClose, open, onSave }) => {
  const classes = useStyles();
  const [model, setModel] = React.useState(contactModel);
  const { addNotification } = React.useContext(GlobalNotificationContext);

  React.useEffect(() => {
    if (open) {
      const obj = JSON.parse(JSON.stringify(contactModel));
      setModel(obj);
    }
  }, [open]);

  const handleChange = e => {
    const obj = { ...model };
    obj[e.target.name].value = e.target.value;
    obj[e.target.name].dirty = true;
    if (obj[e.target.name].required) {
        obj[e.target.name].valid = e.target.value ? true : false;
    }
    setModel(obj);
  };

  const formIsValid = () => {
      let valid = true;
      Object.keys(model).forEach(k => {
        if (!model[k].valid) {
            valid = false;
        }
      });

      return valid;
  }

  const saveContact = async () => {
      if (formIsValid()) {
          const response = await createContact(
              model.firstName.value,
              model.lastName.value,
              model.email.value
          );
          if (!response.error) {
            onSave(response);
            onClose();
          } else {
            addNotification("Unable to create contact")
          }
      }
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={() => onClose()}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => onClose()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Create Contact
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.dialogContent}>
          <form noValidate>
            <FormGroup>
              <TextField
                onChange={handleChange}
                required
                name="firstName"
                label="First Name"
                value={model.firstName.value}
                helperText={
                  model.firstName.value === "" ? "First name is required!" : " "
                }
                error={!model.firstName.valid && model.firstName.dirty}
              />
            </FormGroup>
            <FormGroup>
              <TextField
                onChange={handleChange}
                required
                name="lastName"
                label="Last Name"
                value={model.lastName.value}
                helperText={
                  model.lastName.value === "" ? "Last name is required!" : " "
                }
                error={!model.lastName.valid && model.lastName.dirty}
              />
            </FormGroup>
            <FormGroup>
              <TextField
                onChange={handleChange}
                name="email"
                label="Email"
                value={model.email.value}
                helperText=" "
              />
            </FormGroup>
            <div className={classes.btnContainer}>
              <Button onClick={() => onClose()}>Cancel</Button>
              <Button onClick={() => saveContact()} disabled={!formIsValid()} variant="contained" color="primary">
                Save
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateContact;
