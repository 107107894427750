const model = {
  name: {
    title: "Name",
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
  },
  genericName: {
    title: "Generic Name",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
  },
  ndcCode: {
    title: "Ndc Code",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
  },
  strength: {
    title: "Strength",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
  },
  unit: {
    title: "Unit",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [],
    dropDownItemValue: "_id",
    dropDownItemLabel: "description",
    date: false,
  },
  dosageForm: {
    title: "Dosage Form",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [],
    dropDownItemValue: "_id",
    dropDownItemLabel: "description",
    date: false,
  },
  route: {
    title: "Route",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [],
    dropDownItemValue: "_id",
    dropDownItemLabel: "description",
    date: false,
  },
  isSupply: {
    title: "Is supply",
    required: false,
    value: "no",
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [{name: 'Yes', value: 'yes'}, {name: 'No', value: 'no'}],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
  },
  isNarcotic: {
    title: "Is Narcotic",
    required: false,
    value: "no",
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [{name: 'Yes', value: 'yes'}, {name: 'No', value: 'no'}],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false,
  },
};

export default model;
