import React from 'react'
import {Button, Divider, Grid, makeStyles, MobileStepper, Paper, Tabs, Typography} from '@material-ui/core'
import moment from 'moment';
import AssessmentTab from '../assessment/assessment.tab';
import AssessmentQuestionGroup from '../assessment/assessment.question.group';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import {
  completeResidentAssessment,
  reopenResidentAssessment,
  updateAssessmentQuestionGroup
} from '../../services/assessment.service';
import AssessmentPreview from './assessment.preview';
import {InfoTwoTone } from "@material-ui/icons";
import SaveIcon from "@material-ui/icons/Save";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "1rem",
    backgroundColor: theme.palette.grey[100]
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: ".75rem",
    flexDirection: "row"
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: ".75rem"
  },
  previewContainer: {
    backgroundColor: theme.palette.info.light,
    padding: theme.spacing(1),
    color: theme.palette.info.contrastText
  }
}));

const Assessment = ({assessmentInstance, assessmentEntity, onAssessmentUpdate, isPreview = false}) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [currentAsessment, setCurrentAssessment] = React.useState(null);
  const [currentGroup, setCurrentGroup] = React.useState(null);
  const [previewAssessmentModal, setPreviewAssessmentModal] = React.useState(false);
  const [questionGroups, setQuestionGroups] = React.useState([]);

  React.useEffect(() => {
    if (questionGroups) {
      setCurrentGroup(questionGroups[activeStep])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  React.useEffect(() => {
    setCurrentAssessment(assessmentInstance);
    if (assessmentInstance?.questionGroups) {
      const groups = assessmentInstance?.questionGroups?.sort((a, b) => a?.sortOrder > b?.sortOrder ? 1: -1)
      setQuestionGroups(groups)
      setCurrentGroup(groups[0])
    }
  }, [assessmentInstance])

  const handleIndexChange = (index) => {
    setActiveStep(index);
  }

  const saveQuestionGroup = async questionGroup => {
    questionGroup.questions.forEach(question => question.hasSavedValue = true);
    await updateAssessmentQuestionGroup(assessmentInstance.residentId, assessmentInstance._id, questionGroup._id, questionGroup);
  }

  const handleNext = async () => {
    if (!assessmentInstance.isComplete && !isPreview) {
      await saveQuestionGroup(currentGroup);
    }
    if (questionGroups?.length > (activeStep + 1)) {
      setActiveStep(activeStep => activeStep + 1);
    }
  }


  const handleBack = async () => {
    if (!assessmentInstance.isComplete && !isPreview) {
      await saveQuestionGroup(currentGroup);
    }
    setActiveStep(activeStep => activeStep - 1);
  }

  const getIsIncompleteForSubmission = () => {
    if (assessmentInstance && assessmentInstance.questionGroups) {
      // getCound of any group with unsaved questions
      const unsavedGroups = assessmentInstance.questionGroups
        .filter(questionGroup => questionGroup.questions.filter(question => !question.hasSavedValue).length > 0);
      return unsavedGroups.length > 0;
    } else {
      return true;
    }
  }

  const reopenAssessment = async () => {
    const result = await reopenResidentAssessment(assessmentInstance.residentId, assessmentInstance._id);
    onAssessmentUpdate(result);
    const obj = {...currentAsessment};
    obj.completedAt = result.completedAt;
    obj.isComplete = result.isComplete;
    setCurrentAssessment(obj);
  };
  const completeAssessment = async () => {
    if (!isPreview) {
      const result = await completeResidentAssessment(assessmentInstance.residentId, assessmentInstance._id);
      onAssessmentUpdate(result);
      const obj = {...currentAsessment};
      obj.completedAt = result.completedAt;
      obj.isComplete = result.isComplete;
      setCurrentAssessment(obj);
    }
  };

  return (
    <Paper
      elevation={24}
      variant="elevation"
      className={classes.root}
    >
      {currentAsessment &&
      <React.Fragment>
        <div className={classes.header}>
          {assessmentEntity && (
            <div>
              <Typography variant="subtitle1">
                {assessmentEntity}
              </Typography>
            </div>
          )}
          <div>
            <Typography variant="subtitle1">
              Assessment Name: {currentAsessment?.assessment?.name}
            </Typography>
          </div>
          <div>
            <Typography variant="subtitle1">
              Created:{" "}
              {moment(
                currentAsessment.createdAt
              ).format("M/D/YYYY hh:mm A")}
            </Typography>
          </div>
          <div>
            <Typography variant="subtitle1">
              Completed:{" "}
              {currentAsessment.completedAt
                ? moment(
                  currentAsessment.completedAt
                ).format("M/D/YYYY hh:mm A")
                : "n/a"}
            </Typography>
          </div>
        </div>
        {isPreview &&
        <Grid container
              className={classes.previewContainer}
              alignItems={"center"}
              justify={"center"}>
          <InfoTwoTone fontSize={"large"} style={{ marginRight: ".25rem"}} />
          <Typography variant={"subtitle1"}>Preview Mode</Typography>
        </Grid>}
        <Divider/>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example">
          {currentAsessment?.questionGroups?.sort((a, b) => a?.sortOrder > b?.sortOrder ? 1: -1)?.map((group, key) =>
            <AssessmentTab group={group} index={key} onClick={() => handleIndexChange(key)}/>
          )}
        </Tabs>
        {currentGroup &&
          <AssessmentQuestionGroup group={currentGroup} isAssessmentComplete={assessmentInstance.isComplete}/>
        }
        <MobileStepper
          variant="dots"
          steps={currentAsessment?.questionGroups?.length}
          position="static"
          activeStep={activeStep}
          className={classes.root}
          nextButton={
            <Button size="small"
                    onClick={handleNext}
                    endIcon={activeStep === currentAsessment?.questionGroups?.length - 1 ?
                      <SaveIcon />: <KeyboardArrowRight />}>
              {isPreview ? "Next": activeStep === currentAsessment?.questionGroups?.length - 1 ? "Save": "Save/Next"}
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
              <KeyboardArrowLeft/>
              {isPreview ? "Next": "Save/Back"}
            </Button>
          }
        />
        <div className={classes.btnContainer}>
          <AssessmentPreview
            assessmentEntity={assessmentEntity}
            created={currentAsessment?.createdAt}
            completed={currentAsessment?.completedAt}
            assessment={currentAsessment}
            open={previewAssessmentModal}
            onClose={() => setPreviewAssessmentModal(false)}/>
          {currentAsessment?.completedAt &&
          <Button onClick={reopenAssessment}>Reopen Assessment</Button>
          }
          {!currentAsessment?.completedAt &&
          <Button onClick={completeAssessment} disabled={getIsIncompleteForSubmission()}>Submit Assessment</Button>
          }

        </div>
      </React.Fragment>
      }
    </Paper>
  )
}

export default Assessment
