import React from 'react';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, TextField
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {ArrowDownwardTwoTone, ArrowUpwardTwoTone, DeleteTwoTone, EditTwoTone} from "@material-ui/icons";
import {
  createQuestionOptionAction,
  deleteQuestionOptionAction,
  onOrderQuestionOptionDownAction,
  onOrderQuestionOptionUpAction,
  setCreateOptionModelAction,
  updateQuestionOptionAction
} from "./assessment.actions";
import {AssessmentsContext} from "./assessments.context";
import {FormFieldsGenerator, isModelValid} from "../../utils/forms";
import OptionModel from './option.model';
import {ObjectId} from "bson";
import {getStripedStyle} from "../utils/table.utils";

const QuestionOptions = ({question, canUpdate}) => {
  const { state, dispatch } = React.useContext(AssessmentsContext);
  const [questionModel, setQuestionModel] = React.useState();
  const [editIndex, setEditIndex] = React.useState(-1);
  const [editOption, setEditOption] = React.useState();
  const [optionModel, setOptionModel] = React.useState(JSON.parse(JSON.stringify(OptionModel)));

  React.useEffect(() => {
    const obj = {...question};
    setQuestionModel(obj);
    setEditIndex(-1)
    // eslint-disable-next-line
  }, [ question?.options]);

  const onAddClick = () => {
    setOptionModel(JSON.parse(JSON.stringify(OptionModel)))
    setCreateOptionModelAction(dispatch, true)
  }

  const onOptionChange = e => {
    const obj = {...optionModel, ...e}
    setOptionModel(obj);
  }

  const onCloseModal = () => {
    setCreateOptionModelAction(dispatch, false)
  }

  const onEditOption = option => {
    setEditOption({...option});
    setEditIndex(option?._id);
  }

  const createOption = async () => {
    if (!questionModel?.options) {
      questionModel.options = [];
    }
    let maxValueOfSortOrder = 1
    if (questionModel?.options) {
      maxValueOfSortOrder = questionModel?.options?.length + 1;
    }
    if (isNaN(maxValueOfSortOrder)) {
      maxValueOfSortOrder = 1;
    }
    const option = {
      _id: new ObjectId().toHexString(),
      label: optionModel.label.value,
      value: optionModel.value.value,
      sortOrder: maxValueOfSortOrder
    }
    await createQuestionOptionAction(state?.assessment, questionModel, option, dispatch);
  }

  const onEditChange = e => {
    const obj = {...editOption};
    obj[e.target.name] = e.target.value;
    setEditOption(obj);
  }

  const onUpdate = async (option) => {
    await updateQuestionOptionAction(state?.assessment, questionModel, option?._id, editOption, dispatch);
  }

  const deleteOption = async (option) => {
    await deleteQuestionOptionAction(state?.assessment, questionModel, option?._id, dispatch);
  }

  const orderUp = async (option) => {
    await onOrderQuestionOptionUpAction(state?.assessment, questionModel, option, dispatch);
  }

  const orderDown = async (option) => {
    await onOrderQuestionOptionDownAction(state?.assessment, questionModel, option, dispatch);
  }

  return (
    <TableContainer>
      <Table size={"small"}>
        <TableHead>
          {canUpdate && <TableRow>
            <TableCell colSpan={5}>
              <Button color={"primary"}
                      variant={"contained"}
                      onClick={onAddClick}
                      startIcon={<AddIcon />}>Add</Button>
            </TableCell>
          </TableRow>}
          <TableRow>
            <TableCell />
            <TableCell>Label</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {questionModel?.options?.sort?.((a,b) => a?.sortOrder - b?.sortOrder).map((option, i) =>
            <TableRow key={option?._id} style={getStripedStyle(i)}>
              {editIndex !== option?._id &&
              <>
                <TableCell>
                  <ButtonGroup variant={"outlined"}
                               disabled={!canUpdate}
                               size={"small"}>
                    <IconButton>
                      <ArrowUpwardTwoTone onClick={() => orderUp(option)} />
                    </IconButton>
                    <IconButton>
                      <ArrowDownwardTwoTone onClick={() => orderDown(option)} />
                    </IconButton>
                    <IconButton onClick={() => onEditOption(option)}>
                      <EditTwoTone />
                    </IconButton>
                    <IconButton>
                      <DeleteTwoTone onClick={() => deleteOption(option)} />
                    </IconButton>
                  </ButtonGroup>
                </TableCell>
                <TableCell>{option.label}</TableCell>
                <TableCell>{option.value}</TableCell>
              </>}
              {editIndex === option?._id &&
              <>
                <TableCell>
                  <Button size={"small"}
                          color={"primary"}
                          onClick={() => setEditIndex(-1)}>
                    Cancel
                  </Button>
                  <Button size={"small"}
                          color={"primary"}
                          variant={"contained"}
                          disabled={!editOption?.label || !editOption?.value}
                          onClick={() => onUpdate(option)}>
                    Save
                  </Button>
                </TableCell>
                <TableCell>
                  <TextField name="label" value={editOption?.label || ''} fullWidth onChange={onEditChange} />
                </TableCell>
                <TableCell>
                  <TextField name={"value"} value={editOption?.value || ''} fullWidth onChange={onEditChange} />
                </TableCell>
              </>
              }
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Dialog open={state?.showCreateOptionModel}
              fullWidth
              onClose={onCloseModal}
              maxWidth={"md"}
      >
        <DialogTitle>{"Add Option"}</DialogTitle>
        <DialogContent dividers>
          <FormFieldsGenerator model={optionModel} onChange={onOptionChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseModal} color={"primary"}>Cancel</Button>
          <Button color={"primary"}
                  disabled={!isModelValid(optionModel)}
                  onClick={createOption}
                  variant={"contained"}>Save</Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  )
}

export default QuestionOptions;
