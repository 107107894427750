import React from 'react';
import {
  Paper,
  Typography,
  makeStyles,
  Grid,
  Button,
  Tabs,
  Tab,
  Hidden,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import history from '../../../utils/history';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ResidentActivityLogs from './activity/activity.logs';
import {
  createResidentLog,
  deleteResidentLog,
  searchResidentLogs,
  updateResidentLog,
} from '../../../services/log.service';
import ResidentIncontinenceBladderLogs from './incontinence-bladder/incontinence.bladder.logs';
import ResidentIncontinenceBMLogs from './incontinence-bm/incontinence.bm.logs';
import ResidentVisitorLogs from './visitor/visitor.logs';
import ResidentBehaviorLogs from './behavior/behavior.logs';
import ResidentSubstanceLogs from './substance/substance.logs';
import ResidentIncidentLogs from './incident/incident.logs';
import ResidentIntakeoutputLogs from './intakeoutput/intakeoutput.logs';
import useHasRole from "../../../hooks/user.role.hook";
import {RetryMessage} from "../../notification/retry.message";
import {convertToFilters} from "../../../utils/filter.utils";
import {FilterTemplate} from "./FilterTemplate";
import {getTenantUsers} from "../../../services/tenant.service";
import {UserContext} from "../../../context/usercontext";
import {GlobalNotificationContext} from "../../notification/globalnotificationprovider";
import ConfirmDialog from "../../utils/dialogs/confirm.dialog";

const filters = [
  { index: 0, type: 'activity', label: 'Activities' },
  { index: 1, type: 'bladder', label: 'Incontinence Bladder' },
  { index: 2, type: 'bm', label: 'Incontinence Bowel Movement' },
  { index: 3, type: 'behavior', label: 'Behavior' },
  { index: 4, type: 'visitor', label: 'Visitor' },
  { index: 5, type: 'substance', label: 'Substance Abuse' },
  { index: 6, type: 'incident', label: 'Incident' },
  { index: 7, type: 'intakeoutput', label: 'Intake/Output' },
];


const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(.5, 0),
    padding: theme.spacing(1),
  },
  mainContainer: {},
}));

const rowsPerPageOptions = [1, 5, 10, 25, 50];

const ResidentLogs = ({ match }) => {
  const classes = useStyles();
  const [filter, setFilter] = React.useState(JSON.parse(JSON.stringify(FilterTemplate)));
  const [logType, setLogType] = React.useState(filters[0].type);
  const [residentId, setResidentId] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(25);
  const [total, setTotal] = React.useState(0);
  const [sort, setSort] = React.useState('date');
  const [sortDirection, setSortDirection] = React.useState('desc');
  const [error, setError] = React.useState(false);
  const [logs, setLogs] = React.useState([]);
  const [selectedLogId, setSelectedLogId] = React.useState();
  const [viewConfirmDeleteLog, setViewConfirmDeleteLog] = React.useState();
  const { checkPermission } = useHasRole();
  const canUpdate = checkPermission('ELOG_UPDATE');
  const [users, setUsers] = React.useState([]);
  const { selectedTenant } = React.useContext(UserContext);
  const { addNotification } = React.useContext(GlobalNotificationContext);
  const timeout = React.useRef();

  React.useEffect(() => {
    setResidentId(match.params.residentId);
  }, [match.params.residentId]);

  React.useEffect(() => {
    const getUserData = async () => {
      const result = await getTenantUsers(selectedTenant?._id);
      setUsers(result)
    }
    if (selectedTenant) {
      getUserData();
    }
  }, [selectedTenant]);

  React.useEffect(() => {
    if (residentId && logType) {
      setPage(1);
      setSize(25);
      setSort('date');
      setSortDirection('desc');
      loadData(residentId, page, size, logType, JSON.parse(JSON.stringify(FilterTemplate)), 'date', 'desc')
    }
    // eslint-disable-next-line
  }, [residentId, logType]);


  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setLoading(true);
    setTabValue(newValue);
    setLogType(filters[newValue].type);
  };

  const handleSelectChange = (e) => {
    setTabValue(e.target.value);
    setLogType(filters[e.target.value].type);
  };

  const retry = async () => {
    await loadData(residentId,  page, size, logType, filter, sort, sortDirection);
  }

  const loadData = (id, pg, size, type, query, sort, sortDesc) => {
    setLoading(true);
    clearTimeout(timeout.current);
    timeout.current = setTimeout(async () => {
      setError(false);
      if (!query) {
        query = JSON.parse(JSON.stringify(FilterTemplate));
      }
      query.type.value = type;
      let filters = convertToFilters(query);
      if (logType !== 'incident') {
        filters = filters?.filter(f => f?.field !== 'incidentDate');
      }
      const response = await searchResidentLogs(id, pg, size, filters, sort, sortDesc);
      if (!response?.error) {
        setLogs(response?.content ?? []);
        setPage(response?.paginator?.currentPage);
        setSize(response?.paginator?.perPage ?? 25);
        setTotal(response?.paginator?.totalItems || 0);
      } else {
        setError(true);
      }
      setLoading(false);
    }, 500);
  }

  const onSave = async (log) => {
    const entity = await createResidentLog(residentId, log);
    if (!entity?.error) {
      const obj = [entity, ...logs];
      setLogs(obj);
      addNotification('Log created', 'success');
    } else {
      addNotification('Unable to create log')
    }
  };

  const onUpdateLog = async (id, log) => {
    const entity = await updateResidentLog(residentId, id, log);
    if (!entity?.error) {
      const obj = logs.map((lg) => (lg._id === entity._id ? entity : lg));
      setLogs(obj);
      addNotification('Log updated', 'success');
    } else {
      addNotification('Unable to update log')
    }
  };

  const onDeleteLogConfirmed = async () => {
    const result = await deleteResidentLog(residentId, selectedLogId);
    if (!result?.error) {
      const obj = logs.filter((lg) => lg._id !== selectedLogId);
      setLogs(obj);
      addNotification('Log deleted', 'success');
      setSelectedLogId(null);
      setViewConfirmDeleteLog(false);
    } else {
      addNotification('Unable to delete log')
    }
  };

  const onDeleteLog = id => {
    setSelectedLogId(id);
    setViewConfirmDeleteLog(true);
  }

  const createSortHandler = async (field) => {
    const srt = field;
    setSort(srt);
    const sortDir = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(sortDir)
    await loadData(residentId, page, size, logType, filter, srt, sortDir);
  };





  const filterTextChange = async e => {
    console.log('filt cha')
      const obj = { ...filter };
      obj[e?.target?.name].value = e?.target?.value;
      setFilter(obj);
      if (
        String(obj[e?.target?.name].value).length === 0 ||
        String(obj[e?.target?.name].value).length >= obj[e?.target?.name].minValue
      ) {
        await loadData(residentId, page, size, logType, obj, sort, sortDirection);
      }
  };

  const onChangePage  = async (event, pg) => {
    setPage(pg + 1);
    await loadData(residentId, pg + 1, size, logType, filter, sort, sortDirection);
  }

  const onChangeRowsPerPage = async (event) => {
    setSize(event?.target?.value);
    setPage(1);
    await loadData(residentId, 1, event?.target?.value, logType, filter, sort, sortDirection);
  }

  const onClearFilters = async () => {
    const obj = JSON.parse(JSON.stringify(FilterTemplate));
    setFilter(obj);
    await loadData(residentId, page, size, logType, obj, sort, sortDirection);
  }

  const onDateRangeChange = async dateRangeChangeEvent => {
    if (dateRangeChangeEvent?.start && dateRangeChangeEvent?.end) {
      const obj = {...filter};
      obj[dateRangeChangeEvent.field].value.start = dateRangeChangeEvent?.start;
      obj[dateRangeChangeEvent.field].value.end = dateRangeChangeEvent?.end;
      setFilter(obj);
      await loadData(residentId, page, size, logType, obj, sort, sortDirection);
    }
  }

  return (
    <>
      <Paper elevation={24} className={classes.root}>
        <Grid container alignItems="center">
          <Button
            onClick={() => history.goBack()}
            className={classes.backBtn}
            startIcon={<NavigateBeforeIcon />}
          >
            Back
          </Button>
          <Typography>Resident eLog</Typography>
        </Grid>
        <Hidden smDown>
          <div className="mainContainer">
            <Tabs
              onChange={handleTabChange}
              value={tabValue}
              variant="fullWidth"
              indicatorColor="primary"
              centered
            >
              <Tab label="Activities Log" {...a11yProps(0)}></Tab>
              <Tab label="Incontinence Bladder" {...a11yProps(1)}></Tab>
              <Tab label="Incontinence BM" {...a11yProps(2)}></Tab>
              <Tab label="Behavior" {...a11yProps(3)}></Tab>
              <Tab label="Visitor Log" {...a11yProps(4)}></Tab>
              <Tab label="Substance Abuse" {...a11yProps(4)}></Tab>
              <Tab label="Incident Report" {...a11yProps(4)}></Tab>
              <Tab label="Intake/Output" {...a11yProps(4)}></Tab>
            </Tabs>
          </div>
        </Hidden>
        <Hidden mdUp>
          <FormControl fullWidth>
            <InputLabel shrink>Log Type</InputLabel>
            <Select value={tabValue} onChange={handleSelectChange}>
              {filters &&
                filters.map((filter) => (
                  <MenuItem value={filter.index}>{filter.label}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </Hidden>
      </Paper>
      {error && !loading &&
      <Paper elevation={24}>
        <RetryMessage onRetry={retry} />
      </Paper>
      }
      {!error &&
        <>
          <ResidentActivityLogs
            logs={logs}
            onSave={onSave}
            onUpdateLog={onUpdateLog}
            onDeleteLog={onDeleteLog}
            hidden={tabValue !== 0}
            canUpdate={canUpdate}
            filter={filter}
            sortOrderBy={sort}
            sortDirection={sortDirection}
            createSortHandler={createSortHandler}
            filterTextChange={filterTextChange}
            onDateRangeChange={onDateRangeChange}
            loading={loading}
            users={users}
            page={page}
            size={size}
            total={total}
            rowsPerPageOptions={rowsPerPageOptions}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onClearFilters={onClearFilters}
          />
          <ResidentIncontinenceBladderLogs
            logs={logs}
            onSave={onSave}
            onUpdateLog={onUpdateLog}
            onDeleteLog={onDeleteLog}
            hidden={tabValue !== 1}
            canUpdate={canUpdate}
            filter={filter}
            sortOrderBy={sort}
            sortDirection={sortDirection}
            createSortHandler={createSortHandler}
            filterTextChange={filterTextChange}
            onDateRangeChange={onDateRangeChange}
            loading={loading}
            users={users}
            page={page}
            size={size}
            total={total}
            rowsPerPageOptions={rowsPerPageOptions}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onClearFilters={onClearFilters}
          />
          <ResidentIncontinenceBMLogs
            residentId={residentId}
            logs={logs}
            onSave={onSave}
            onUpdateLog={onUpdateLog}
            onDeleteLog={onDeleteLog}
            hidden={tabValue !== 2}
            canUpdate={canUpdate}
            filter={filter}
            sortOrderBy={sort}
            sortDirection={sortDirection}
            createSortHandler={createSortHandler}
            filterTextChange={filterTextChange}
            onDateRangeChange={onDateRangeChange}
            loading={loading}
            users={users}
            page={page}
            size={size}
            total={total}
            rowsPerPageOptions={rowsPerPageOptions}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onClearFilters={onClearFilters}
          />
          <ResidentBehaviorLogs
            logs={logs}
            onSave={onSave}
            onUpdateLog={onUpdateLog}
            onDeleteLog={onDeleteLog}
            hidden={tabValue !== 3}
            canUpdate={canUpdate}
            filter={filter}
            sortOrderBy={sort}
            sortDirection={sortDirection}
            createSortHandler={createSortHandler}
            filterTextChange={filterTextChange}
            onDateRangeChange={onDateRangeChange}
            loading={loading}
            users={users}
            page={page}
            size={size}
            total={total}
            rowsPerPageOptions={rowsPerPageOptions}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onClearFilters={onClearFilters}
          />
          <ResidentVisitorLogs
            logs={logs}
            onSave={onSave}
            onUpdateLog={onUpdateLog}
            onDeleteLog={onDeleteLog}
            hidden={tabValue !== 4}
            canUpdate={canUpdate}
            filter={filter}
            sortOrderBy={sort}
            sortDirection={sortDirection}
            createSortHandler={createSortHandler}
            filterTextChange={filterTextChange}
            onDateRangeChange={onDateRangeChange}
            loading={loading}
            users={users}
            page={page}
            size={size}
            total={total}
            rowsPerPageOptions={rowsPerPageOptions}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onClearFilters={onClearFilters}
          />
          <ResidentSubstanceLogs
            logs={logs}
            onSave={onSave}
            onUpdateLog={onUpdateLog}
            onDeleteLog={onDeleteLog}
            hidden={tabValue !== 5}
            canUpdate={canUpdate}
            filter={filter}
            sortOrderBy={sort}
            sortDirection={sortDirection}
            createSortHandler={createSortHandler}
            filterTextChange={filterTextChange}
            onDateRangeChange={onDateRangeChange}
            loading={loading}
            users={users}
            page={page}
            size={size}
            total={total}
            rowsPerPageOptions={rowsPerPageOptions}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onClearFilters={onClearFilters}
          />
          <ResidentIncidentLogs
            logs={logs}
            onSave={onSave}
            onUpdateLog={onUpdateLog}
            onDeleteLog={onDeleteLog}
            hidden={tabValue !== 6}
            canUpdate={canUpdate}
            filter={filter}
            sortOrderBy={sort}
            sortDirection={sortDirection}
            createSortHandler={createSortHandler}
            filterTextChange={filterTextChange}
            onDateRangeChange={onDateRangeChange}
            loading={loading}
            users={users}
            page={page}
            size={size}
            total={total}
            rowsPerPageOptions={rowsPerPageOptions}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onClearFilters={onClearFilters}
          />
          <ResidentIntakeoutputLogs
            logs={logs}
            onSave={onSave}
            onUpdateLog={onUpdateLog}
            onDeleteLog={onDeleteLog}
            canUpdate={canUpdate}
            hidden={tabValue !== 7}
            filter={filter}
            sortOrderBy={sort}
            sortDirection={sortDirection}
            createSortHandler={createSortHandler}
            filterTextChange={filterTextChange}
            onDateRangeChange={onDateRangeChange}
            loading={loading}
            users={users}
            page={page}
            size={size}
            total={total}
            rowsPerPageOptions={rowsPerPageOptions}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onClearFilters={onClearFilters}
          />
        </>
      }

      <ConfirmDialog open={viewConfirmDeleteLog}
                     onClose={() => setViewConfirmDeleteLog(false)}
                     onConfirm={onDeleteLogConfirmed}
                     fullWidth={true}
                     maxWidth={"sm"}
                     title={"Are you sure you want to delete this eLog?"}
                     message={"To delete press the delete button."}
      />
    </>
  );
};

export default ResidentLogs;
