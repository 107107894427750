import apiUtil from "../utils/api.utils";
import config from "../utils/configs";
import { parseFiltersToJson } from "../utils/filter.utils";
const medicalProviderEndpoint = `${config.residentService}/api/v1.0/medicalproviders`;

export const getMedicalProviderTypes = async () => {
  try {
    const response = await apiUtil.client.get(
      `${config.residentService}/api/v1.0/medicalprovidertypes`
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};

export const searchMedicalProviders = async (
  page = 0,
  size = 10,
  filters = [],
  sort = "name",
  sortDirection = "asc"
) => {
  try {
    const filter = parseFiltersToJson(filters);
    let url = `${medicalProviderEndpoint}?page=${page}&size=${size}${filter}`;
    let sortArg = sortDirection === 'asc' ? '+' : '-';
    sortArg += sort;
    url += `&sort=${sortArg}`;
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};

export const createMedicalProvider = async (medicalProvider) => {
  try {
    const response = await apiUtil.client.post(
      medicalProviderEndpoint,
      medicalProvider
    );
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
};

export const getMedicalProvider = async (medicalProviderId) => {
  try {
    const response = await apiUtil.client.get(
      `${medicalProviderEndpoint}/${medicalProviderId}`
    );
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
};

export const updateMedicalProvider = async (
  medicalProviderId,
  medicalProvider
) => {
  try {
    const response = await apiUtil.client.put(
      `${medicalProviderEndpoint}/${medicalProviderId}`,
      medicalProvider
    );
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
};

export const createMedicalProviderPhone = async (medicalProviderId, phone) => {
  try {
    const response = await apiUtil.client.post(
      `${medicalProviderEndpoint}/${medicalProviderId}/phones`,
      phone
    );
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
}

export const updateMedicalProviderPhone = async (medicalProviderId, phoneId, phone) => {
  try {
    const response = await apiUtil.client.put(
      `${medicalProviderEndpoint}/${medicalProviderId}/phones/${phoneId}`,
      phone
    );
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
}

export const deleteMedicalProviderPhone = async (medicalProviderId, phoneId) => {
  try {
    const response = await apiUtil.client.delete(`${medicalProviderEndpoint}/${medicalProviderId}/phones/${phoneId}`);
    return response.data;
  } catch (error) {
    alert(error);
    return [];
  }
}

export const createResidentMedicalProvider = async (residentId, medicalProvider) => {
  try {
    const response = await apiUtil.client
                                  .post(`${config.residentService}/api/v1.0/residents/${residentId}/medicalProviders`,
                                    medicalProvider);
    return response?.data;
  } catch (error) {
    alert(error);
    return null;
  }
}

export const deleteResidentMedicalProvider = async (residentId, medicalProviderId) => {
  try {
    const response = await apiUtil.client
      .delete(`${config.residentService}/api/v1.0/residents/${residentId}/medicalProviders/${medicalProviderId}`);
    return response?.data;
  } catch (error) {
    return null;
  }
}
