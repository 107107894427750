import React from 'react';
import { UserContext } from '../../context/usercontext';
import { useAuth0 } from '../../react-auth0-spa';
import {
  Paper,
  Typography,
  Grid,
  DialogActions,
  Button,
  Tabs,
  Tab,
  LinearProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import GoogleMapReact from 'google-map-react';
import {
  updateUserDetails,
  uploadUserProfilePicture,
} from '../../services/user.service';
import { FormFieldsGenerator, isModelValid } from '../../utils/forms';
import UserModel from './user.model';
import InfoIcon from "@material-ui/icons/Info";
import {PermissionsModal} from "./permissions";

const AnyReactComponent = ({ text }) => (
  <div
    style={{
      color: 'red',
      background: 'transparent',
      padding: '15px 10px',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '100%',
      transform: 'translate(-50%, -50%)',
    }}
  >
    <div class="fas fa-map-marker fa-2x" />
    <Typography
      variant="subtitle2"
      gutterBottom
      gutterTop
      style={{ color: 'black' }}
    >
      {text}
    </Typography>
  </div>
);

const UserProfile = () => {
  const { state, dispatch, user } = React.useContext(UserContext);
  const { loading } = useAuth0();
  const [profileFile, setProfileFile] = React.useState();
  const [tabIndex, setTabIndex] = React.useState(0);
  const [geoIp, setGeoIp] = React.useState();
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(UserModel)));
  const [processing, setProcessing] = React.useState(false);
  const [selectedRole, setSelectedRole] = React.useState();
  const [viewPermissions, setViewPermissions] = React.useState(false);

  React.useEffect(() => {
    if (state.user) {
      mergeUser(state.user);
    }
  }, [state.user]);

  const mergeUser = (user) => {
    const obj = JSON.parse(JSON.stringify(UserModel));
    Object.keys(obj).forEach((k) => {
      obj[k].value = user[k];
    });
    setModel(obj);
    setGeoIp(user.geoIp);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const saveUserDetails = async () => {
    setProcessing(true);
    let data = {}
    Object.keys(model).forEach(key => {
      data[key] = model[key].value
    });
    /*const data = {
      firstName: model.firstName.value,
      lastName: model.lastName.value,
      initals: model.initials.value,
      isSystemAdmin: model.isSystemAdmin.value,
      email: model.email.value,
      nickname: model.nickname.value,
    };*/
    // Check if image changed
    if (profileFile) {
      // get url back from post
      const url = await uploadUserProfilePicture(state?.user?._id, profileFile);
      data.picture = url;
    }
    await updateUserDetails(state?.user?._id, data);
    dispatch({type: 'setUserImage', payload: data.picture });
    setProcessing(false);
  };

  const onModelChange = (e, field, extra) => {
    if (field === 'picture') {
      setProfileFile(extra);
    }
    setModel({ ...model, ...e });
  };

  const handleRoleSelection = locationRole => {
    setSelectedRole(locationRole?.role);
    setViewPermissions(true);
  }


  return (
    <React.Fragment>
      <Paper elevation={24}>
        <Tabs
          value={tabIndex}
          centered
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, index) => setTabIndex(index)}
        >
          <Tab label="Details" />
          <Tab label="Location" />
        </Tabs>
        {tabIndex === 0 && (
          <Grid container style={{ padding: '1rem' }}>
            <Grid xs={12} md={6} item style={{ padding: '0 1rem' }}>
              <div hidden={!processing}>
                <LinearProgress />
                <Typography variant={"subtitle2"}>Updating user....</Typography>
              </div>
              <form noValidate name="userDetails" style={{ width: '100%' }} hidden={processing}>
                <Typography variant="h6">Account Details</Typography>
                <FormFieldsGenerator model={model} onChange={onModelChange} />
                <DialogActions>
                  <Button>Cancel</Button>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!isModelValid(model)}
                    onClick={saveUserDetails}
                  >
                    Save
                  </Button>
                </DialogActions>
              </form>
            </Grid>
            <Grid xs={12} md={6} item>
              <PermissionsModal role={selectedRole}
                                open={viewPermissions}
                                onClose={() => setViewPermissions(false)}
              />
              <Typography variant="h6">Roles</Typography>
              <TableContainer>
                <Table size={"small"}>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Location
                      </TableCell>
                      <TableCell>
                        Role
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {user?.roles?.map(locationRole =>
                        <TableRow>
                          <TableCell>
                            {locationRole?.location?.tenant?.name} - {locationRole?.location?.name}
                          </TableCell>
                          <TableCell>
                            <Button variant={"text"}
                                    color={"primary"}
                                    onClick={()=>handleRoleSelection(locationRole)}
                                    startIcon={
                              <InfoIcon />
                            }>
                              {locationRole?.role?.name}
                            </Button>
                          </TableCell>
                        </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}
        {tabIndex === 1 && (
          <div style={{ padding: '1rem', width: '100%', height: '40rem' }}>
            <Typography variant="h6">Current Location</Typography>
            <Typography variant="subtitle2">
              Your IP Address: {geoIp?.ip}
            </Typography>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: 'AIzaSyDv2vUwDrK1QHgLq_Vps211gTEpHkVbblw',
              }}
              defaultCenter={{
                lat: geoIp?.latitude,
                lng: geoIp?.longitude,
              }}
              defaultZoom={12}
            >
              <AnyReactComponent
                lat={geoIp?.latitude}
                lng={geoIp?.longitude}
                text="Current Location"
              />
            </GoogleMapReact>
          </div>
        )}
      </Paper>
    </React.Fragment>
  );
};

export default UserProfile;
