import React from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableCell,
  Button,
  TableBody,
  TableRow,
  Paper,
  TablePagination,
  Grid,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CreateResidentVital from './create.vital';
import {
  getResidentEVitals,
  createResidentEVital,
  updateResidentEVital,
} from '../../../../services/evital.service';
import moment from 'moment';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
} from 'recharts';
import EditResidentVital from './edit.vital';
import useGlobalNotification from "../../../../hooks/notification.hook";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import {VItalsPrintModal} from "./print/VItalsPrintModal";
import {UserContext} from "../../../../context/usercontext";
import useHasRole from "../../../../hooks/user.role.hook";

const columns = [
  { field: 'date', label: 'Date', isChartable: false },
  { field: 'temperature', label: 'Temperature', isChartable: true },
  { field: 'pulse', label: 'Pulse', isChartable: true },
  { field: 'respiratoryRate', label: 'Respiratory Rate', isChartable: true },
  { field: 'bloodPressure', label: 'BP', isChartable: true },
  { field: 'systolicBloodPressure', label: 'Systolic BP', isChartable: false },
  { field: 'diastolicBloodPressure', label: 'Diastolic BP', isChartable: false },
  { field: 'inr', label: 'INR', isChartable: false },
  { field: 'oxygen', label: '02', isChartable: true },
  { field: 'bloodGlucose', label: 'Blood Glucose', isChartable: true },
  { field: 'heightFeet', label: 'Height (Feet)', isChartable: false },
  { field: 'heightInches', label: 'Height (Inches)', isChartable: false },
  { field: 'weight', label: 'Weight', isChartable: true },
  { field: 'bmi', label: 'BMI', isChartable: true },
  { field: 'painLevel', label: 'Pain Level', isChartable: true },
  { field: 'staff', label: 'Staff', isChartable: false },
  { field: 'comment', label: 'Comments', isChartable: false },
];

const getStripedStyle = (index) => {
  return { background: index % 2 ? '#fafafa' : '#fff' };
};

const generateRow = (vital, columns, index, onSelect) => {
  return (
    <TableRow key={index} style={{ ...getStripedStyle(index) }}>
      {columns?.map((col) => {
          if (col.field === 'date') {
            return (
              <TableCell style={{ textAlign: 'left' }}>
                <Button color={"primary"} onClick={onSelect} variant={"text"} size={"small"} style={{width: 125}}>
                  {moment(vital[col.field]).format('M/DD/YYYY h:mmA')}
                </Button>
              </TableCell>
            );
          } else if (col?.field === "staff") {
            return (
              <TableCell style={{ textAlign: 'left' }}>
                {vital[col?.field]?.firstName || ''} {vital[col?.field]?.lastName || ''}
              </TableCell>
            );
          } else {
            return (
              <TableCell style={{ textAlign: 'left' }}>
                {vital[col.field]}
              </TableCell>
            );
          }
        })}
    </TableRow>
  );
};

const VitalsView = ({ match, canEdit }) => {
  const [cols] = React.useState(JSON.parse(JSON.stringify(columns)));
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [vital, setVital] = React.useState(null);
  const [residentId, setResidentId] = React.useState(null);
  const [vitals, setVitals] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(25);
  const [totalItems, setTotalItems] = React.useState(0);
  const [chartData, setChartData] = React.useState([]);
  const [viewVitalsModal, setViewVitalsModal] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const { getUsers } = React.useContext(UserContext);
  const { addNotification }= useGlobalNotification();
  const { checkPermission } = useHasRole()
  const isReportPrinter = checkPermission("REPORT_PRINTER");

  React.useEffect(() => {
    if (match.params.id && match.params.id !== residentId) {
      setResidentId(match.params.id);
    }
  }, [match.params.id, residentId]);

  React.useEffect(() => {
    if (residentId) {
      getVitals(residentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [residentId]);

  const getVitals = async (residentId, pg = 0, sz = 25) => {
    const users = await getUsers();
    setUsers(users?.sort((a, b) => a?.lastName > b?.lastName ? 1: -1).map(u => ({
      name: `${u.firstName} ${u.lastName} (${u.initials})`,
      _id: u._id
      })
    ));
    const response = await getResidentEVitals(residentId, pg + 1, sz);
    if (response && response.paginator) {
      setVitals(response.content);
      populateChartData(response.content);
      setPage(response?.paginator?.currentPage - 1);
      setTotalItems(response?.paginator?.totalItems);
      setSize(response?.paginator?.perPage);
    }
  };

  const createVital = async (residentId, data) => {
    const response = await createResidentEVital(residentId, data);
    if (!response?.error) {
      const obj = [{ ...response }, ...vitals]?.sort((a, b) => moment(b.date) - moment(a.date));
      setVitals(obj);
      setOpen(false);
      addNotification("Vital created", "success");
      populateChartData(obj)
    } else {
      addNotification("Unable to create vital", "error");
    }
  };

  const populateChartData = (vitals) => {
    if (vitals?.length > 0) {
      const data = vitals
          // ?.filter((v) => v.weight)
          ?.map((v) => {
            const obj = { ...v };
            obj.dateLabel = moment(v.date).format('MM/DD/YYYY');
            return obj;
        })
          ?.reverse();
      setChartData(data);
    } else {
      setChartData([]);
    }
  };

  const onRowSelected = (entity) => {
    setVital(entity);
    setOpenEdit(true);
  };

  const saveVital = async (resId, vitalId, vital) => {
    const response = await updateResidentEVital(resId, vitalId, vital);
    if (!response?.error) {
      const obj = vitals.map((v) => (v._id === response._id ? response : v))?.sort((a, b) => moment(b.date) - moment(a.date));
      setVitals(obj);
      setOpenEdit(false);
      populateChartData(obj);
      addNotification("Vital updated", "success");
    } else {
      addNotification("Unable to update vital", "error");
    }
  };

  const handleChangePage = async (event, pg) => {
    await getVitals(residentId, pg, size);
  };
  const onChangeRowsPerPage = async (event) => {
    await getVitals(residentId, page, event.target.value);
  };

  return (
    <div>
      <CreateResidentVital
        residentId={residentId}
        users={users}
        open={open}
        onClose={() => setOpen(false)}
        onSave={createVital}
      />
      <EditResidentVital
        residentId={residentId}
        vital={vital}
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        onSave={saveVital}
        canEdit={canEdit}
        users={users}
      />
      <Paper elevation={24} style={{ padding: '2rem', marginTop: '1rem' }}>
        <ResponsiveContainer width="100%" height={200}>
          <LineChart data={chartData}
                     width={500}
                     height={200}
                     margin={{
                       top: 10,
                       right: 30,
                       left: 0,
                       bottom: 0,
                     }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis padding={{ left: 10, right: 10 }} dataKey="dateLabel" dateLabel="dateLabel" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type={"monotone"} dataKey="weight" name={"Weight"} stroke="black" />
            <Line type="monotone" dataKey="pulse" name={"Pulse"} stroke="red" />
            <Line type="monotone" dataKey="bloodGlucose" name={"BG"} stroke="blue" />
            <Line type={"monotone"} dataKey={"oxygen"} name={"O2"} stroke={"green"} />
            <Line type={"monotone"} dataKey={"temperature"} name={"Temp"} stroke={"purple"} />
            <Line type={"monotone"} dataKey={"diastolicBloodPressure"} name={"Diastolic BP"} stroke={"orange"} />
            <Line type={"monotone"} dataKey={"systolicBloodPressure"} name={"Systolic BP"} stroke={"gray"} />
          </LineChart>
        </ResponsiveContainer>
      </Paper>
      <Paper elevation={24} style={{ padding: '2rem', marginTop: '1rem' }}>
        <Grid container
              justifyContent={"space-between"}
        >
          <Grid item>
            {canEdit &&
              <Button startIcon={<AddIcon />} onClick={() => setOpen(true)}>
                Add new
              </Button>}
          </Grid>
          <Grid item>
            {isReportPrinter &&
              <Button startIcon={<PictureAsPdfIcon/>}
                      onClick={() => setViewVitalsModal(true)}
                      color={"primary"}>
                Print Pdf
              </Button>
            }
            <VItalsPrintModal open={viewVitalsModal}
                              residentId={residentId}
                              onClose={() => setViewVitalsModal(false)} />
          </Grid>
        </Grid>
        <TableContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={[1, 5, 10, 25, 50, 100]}
            page={page}
            rowsPerPage={size ? size : 0}
            count={totalItems ? totalItems : 0}
            backIconButtonProps={{
              'aria-label': 'previous page',
              disabled: page === 0 ? true : false,
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
              disabled: (page + 1) * size > totalItems ? true : false,
            }}
            onRowsPerPageChange={onChangeRowsPerPage}
            onPageChange={handleChangePage}
          />
          <Table size="small" aria-label="vitals table">
            <TableHead>
              {cols?.map((col) =>
                <TableCell>
                  {col.label}
                </TableCell>)
              }
            </TableHead>
            <TableBody>
              {vitals?.map((vital, i) =>
                  generateRow(vital, cols, i, () => onRowSelected(vital))
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default VitalsView;
