import {ACTIONS} from "./assessments.context";
import {
  addQuestionToSectionInAssessment, copyQuestion, deleteOptionToQuestion, findAndAddOptionToQuestion,
  findAndDeleteQuestionInAssessment, findAndUpdateOptionToQuestion,
  findAndUpdateQuestionInAssessment, orderOption,
  orderQuestion,
} from "./utils";
import filterTemplate from "./assessment.filter.template";

export const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.GET_ASSESSMENTS:
      return {
        ...state,
        assessments: action.payload?.content,
        page: action.payload?.paginator?.currentPage,
        size: action.payload?.paginator?.perPage,
        totalItems: action.payload?.paginator?.totalItems,
        loading: false
      };
    case ACTIONS.SET_ERROR:
      return {...state, error: action?.payload};
    case ACTIONS.SET_LOADING:
      return {...state, loading: action?.payload};
    case ACTIONS.GET_ASSESSMENT:
      return {...state, assessment: action.payload, loading: false }
    case ACTIONS.CREATE_ASSESSMENT:
      return {...state, assessments: [...state.assessments, action.payload], loading: false, showCreateModal: false };
    case ACTIONS.SET_ASSESSMENT_MODEL:
      return {...state, assessmentModel: action.payload};
    case ACTIONS.GET_ASSESSMENT_TYPES:
      return {...state, types: action.payload};
    case ACTIONS.SET_ASSESSMENT_AND_TYPES:
      return {...state, assessment: action.payload.assessment, types: action.payload.types }
    case ACTIONS.SET_SHOW_CREATE_ASSESSMENT_MODAL:
      return {...state, showCreateModal: action.payload};
    case ACTIONS.START_ADD_QUESTION_GROUP: {
      const obj = {...state.assessment};
      if (!obj.questionGroups) {
        obj.questionGroups = []
      }
      obj.questionGroups = [action.payload, ...obj.questionGroups];
      return {...state, assessment: obj};
    }
    case ACTIONS.DELETE_QUESTION_GROUP: {
      return {...state,
        assessment: findAndDeleteQuestionInAssessment(state.assessment, action.payload),
        selectedObject: {}
      };
    }
    case ACTIONS.UPDATING_ASSESSMENT_SUMMARY:
      return {...state, updatingAssessmentSummary: true };
    case ACTIONS.UPDATE_ASSESSMENT: {
      const obj = state.assessments.map(as => as._id === action?.payload?._id ? action.payload: as);
      return {...state,
        assessments: obj,
        assessment: action.payload,
        updatingAssessmentSummary: false};
    }
    case ACTIONS.LOADING:
      return {...state, loading: true}
    case ACTIONS.ADD_SECTION: {
      const assessment = {...state.assessment};
      if (!assessment.questionGroups) {
        assessment.questionGroups = [];
      }
      assessment.questionGroups = [...assessment.questionGroups, action.payload];
      return {...state, assessment: assessment, selectedObject: action.payload }
    }
    case ACTIONS.SELECT_ASSESSMENT_OBJECT:
      return {...state, selectedObject: action.payload}
    case ACTIONS.UPDATE_SECTION_METADATA:
      const updateAssessmentSection = {...state.assessment};
      updateAssessmentSection.questionGroups =
        updateAssessmentSection.questionGroups.map(qg => qg._id === action?.payload?.sectionId ? action?.payload?.response: qg);
      return {...state, assessment: updateAssessmentSection, selectedObject: action?.payload?.response }
    case ACTIONS.DELETE_SECTION:
      const deleteAssessmentObj = {...state.assessment};
      deleteAssessmentObj.questionGroups = deleteAssessmentObj.questionGroups.filter(qg => qg._id !== action.payload);
      return {...state, assessment: deleteAssessmentObj, selectedObject: {} }
    case ACTIONS.ORDER_SECTION_UP:
      return {...state, assessment: action.payload};
    case ACTIONS.ORDER_SECTION_DOWN:
      return {...state, assessment: action.payload}
    case ACTIONS.ADD_QUESTION:
      return {
        ...state,
        assessment:
          addQuestionToSectionInAssessment(state.assessment, action?.payload?.section, action?.payload?.question),
        selectedObject: action.payload?.question
      };
    case ACTIONS.SET_QUESTION_TYPES:
      return {...state, questionTypes: action.payload}
    case ACTIONS.UPDATE_QUESTION: {
      return {...state, assessment: findAndUpdateQuestionInAssessment(state.assessment, action.payload), selectedObject: action.payload};
    }
    case ACTIONS.DELETE_QUESTION: {
      return {...state, assessment: findAndDeleteQuestionInAssessment(state.assessment, action.payload), selectedObject: {}}
    }
    case ACTIONS.ORDER_QUESTION_UP:
      return {...state, assessment: orderQuestion(action.payload)};
    case ACTIONS.ORDER_QUESTION_DOWN:
      return {...state, assessment: orderQuestion(action.payload)}
    case ACTIONS.SHOW_ADD_OPTION_MODAL:
      return {...state, showCreateOptionModel: action.payload }
    case ACTIONS.ADD_OPTION: {
     return {...state, assessment: findAndAddOptionToQuestion(
          state.assessment,
          action?.payload?.question,
          action?.payload?.option),
        showCreateOptionModel: false
      }
    }
    case ACTIONS.UPDATE_OPTION: {
      return {...state, assessment: findAndUpdateOptionToQuestion(
          state.assessment,
          action?.payload?.question,
          action?.payload?.option),
        showCreateOptionModel: false
      }
    }
    case ACTIONS.DELETE_OPTION:
      return {...state, assessment: deleteOptionToQuestion(state?.assessment, action?.payload?.question, action?.payload?.optionId)}
    case ACTIONS.ORDER_OPTION_UP:
      return {...state, assessment: orderOption(action?.payload)};
    case ACTIONS.ORDER_OPTION_DOWN:
      return {...state, assessment: orderOption(action?.payload)};
    case ACTIONS.COPY_QUESTION:
      const { section, question } = action.payload;
      return {...state, assessment: copyQuestion(state.assessment, section, question) }
    case ACTIONS.SET_SORT_ORDER:
      const { field, direction } = action.payload;
      return {...state, sortOrderBy: field, sortDirection: direction };
    case ACTIONS.SET_FILTER:
      return {...state, filter: action.payload }
    case ACTIONS.SET_PAGE:
      return {...state, page: action.payload};
    case ACTIONS.SET_SIZE:
      return {...state, size: action.payload};
    case ACTIONS.RESET_FILTER:
      return {...state, filter: JSON.parse(JSON.stringify(filterTemplate))}
    default:
      return {...state};
  }
}
