import React from 'react';
import {
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  FormGroup,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Transition from '../../../dialog/transition';
import BmModel from './bm.model';
import { isModelValid } from '../../../../utils/forms/form.validator';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {UserContext} from "../../../../context/usercontext";

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: '4rem',
  },
}));

const CreateIncontinenceLog = ({ open, date, onSave, onClose, users }) => {
  const classes = useStyles();
  const { user } = React.useContext(UserContext);
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(BmModel)));

  React.useEffect(() => {
    const setDefaultDate = () => {
      const obj = JSON.parse(JSON.stringify(BmModel));
      obj.date.dirty = false;
      obj.date.value = Date.now();
      obj.hour.dirty = false;
      obj.hour.value = Date.now();
      obj.staff.dropDownItems = users;
      obj.staff.value = user?._id
      setModel(obj);
    };

    if (open && users) {
      setDefaultDate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, users, user]);

  const onModelChange = (e) => {
    const obj = { ...model };
    obj[e.target.name].dirty = true;
    obj[e.target.name].value = e.target.value;
    setModel(obj);
  };

  const onHourChange = (e) => {
    const obj = { ...model };
    obj.hour.dirty = true;
    obj.hour.value = e;
    setModel(obj);
  };

  const onDateChange = (e) => {
    const obj = { ...model };
    obj.date.dirty = true;
    obj.date.value = e;
    setModel(obj);
  };

  const onCreate = () => {
    const log = {
      type: 'bm',
      hour: model.hour.value,
      date: model.date.value,
      pattern: model.pattern.value,
      color: model.color.value,
      consistency: model.consistency.value,
      comments: model.comments.value,
      time: model.time.value,
      staff: model?.staff?.value
    };
    onSave(log);
  };

  return (
    <Dialog open={open} fullScreen TransitionComponent={Transition}>
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => onClose()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Enter Incontinence BM Log
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.content}>
        <form noValidate>
          <FormGroup>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardTimePicker
                label="Hour"
                placeholder="08:00 AM"
                mask="__:__ _M"
                value={model.hour.value}
                ampm={true}
                helperText={
                  model.hour.dirty && model.hour.value === ''
                    ? `Hour is required!`
                    : null
                }
                onChange={onHourChange}
                error={!model.hour.isValid && model.hour.dirty}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                size="small"
                fullWidth
                name="date"
                required={true}
                value={model.date.value}
                label={'Date'}
                format="MM/DD/YYYY"
                onChange={onDateChange}
                allowKeyboardControl="true"
                ampm={false}
                helperText={
                  model.date.dirty && model.date.value === ''
                    ? `Date is required!`
                    : null
                }
                error={!model.date.isValid && model.date.dirty}
              />
            </MuiPickersUtilsProvider>
            <FormControl>
              <InputLabel required id="result-label">
                Bowel Pattern
              </InputLabel>
              <Select
                required
                labelId="pattern-label"
                id="pattern-select"
                value={model.pattern.value}
                name="pattern"
                onChange={onModelChange}
              >
                {model.pattern.dropDownItems &&
                  model.pattern.dropDownItems.map((item) => (
                    <MenuItem value={item.value}>{item.name}</MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel required id="color-label">
                Color
              </InputLabel>
              <Select
                required
                labelId="color-label"
                id="color-select"
                value={model.color.value}
                name="color"
                onChange={onModelChange}
              >
                {model.color.dropDownItems &&
                  model.color.dropDownItems.map((item) => (
                    <MenuItem value={item.value}>{item.name}</MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel required id="consistency-label">
                Consistency
              </InputLabel>
              <Select
                required
                labelId="consistency-label"
                id="consistency-select"
                value={model.consistency.value}
                name="consistency"
                onChange={onModelChange}
              >
                {model.consistency.dropDownItems &&
                  model.consistency.dropDownItems.map((item) => (
                    <MenuItem value={item.value}>{item.name}</MenuItem>
                  ))}
              </Select>
            </FormControl>
            <TextField
              label="Comments"
              name="comments"
              value={model.comments.value}
              onChange={onModelChange}
              multiline
              rows={4}
            />
            <FormControl>
              <InputLabel required id="time-label">
                Time
              </InputLabel>
              <Select
                required
                labelId="time-label"
                id="time-select"
                value={model.time.value}
                name="time"
                onChange={onModelChange}
              >
                {model.time.dropDownItems &&
                  model.time.dropDownItems.map((item) => (
                    <MenuItem value={item.value}>{item.name}</MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel required id="time-label">
                Staff
              </InputLabel>
              <Select
                  required
                  labelId="staff-label"
                  id="staff-select"
                  value={model.staff.value}
                  name="staff"
                  onChange={onModelChange}
              >
                {model?.staff?.dropDownItems?.map(staff =>
                    <MenuItem value={staff?._id}>{staff?.firstName} {staff?.lastName}</MenuItem>
                )}
              </Select>
            </FormControl>
          </FormGroup>
        </form>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onCreate}
          disabled={!isModelValid(model)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateIncontinenceLog;
