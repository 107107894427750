export const BackgroundModel = {
  education: {
    title: "Education",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    date: false
  },
  occupation: {
    title: "Occupation",
    required: false,
    value: null,
    dirty: false,
    isValid: true
  },
  hobbies: {
    title: "Hobbies",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    date: false
  },
  specialInterests: {
    title: "Special Interests",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    date: false
  },
  militaryHistory: {
    title: "Military History",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    date: false
  }
};
