import React from 'react';
import {
  Avatar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
  IconButton,
  ButtonGroup,
  Button,
  TextField,
} from '@material-ui/core';
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';
import {
  green,
  yellow,
  red,
  grey,
  orange,
  blue,
} from '@material-ui/core/colors';
import EditIcon from '@material-ui/icons/Edit';
import { updateResidentEmar } from '../../../services/medication.order.service';

const contrast = 400;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  avatarContainer: {
    marginRight: theme.spacing(3),
  },
  avatarSmall: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  avatarLarge: {
    background: theme.palette.primary,
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  scheduled: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: grey[500],
  },
  readyToPass: {
    background: grey[700],
  },
  given: {
    background: green[contrast],
  },
  late: {
    background: orange[contrast],
  },
  missed: {
    background: red[contrast],
  },
  hold: {
    background: yellow[contrast],
  },
  sa: {
    background: blue[contrast],
  },
  na: {
    background: grey[contrast],
  },
  refused: {
    background: red[contrast],
  },
  destruction: {
    background: yellow[contrast],
  },
  appBar: {},
}));

const MarRow = ({
  event,
  index,
  isEdit,
  selectEditEvent,
  cancelEdit,
  onUpdate,
}) => {
  const classes = useStyles();
  const [editEvent, setEditEvent] = React.useState(-1);
  const [editEventIndex, setEditEventIndex] = React.useState(-1);

  React.useEffect(() => {
    if (isEdit) {
      setEditEvent(JSON.parse(JSON.stringify(event)));
    }
    // eslint-disable-next-line
  }, [isEdit]);

  const updateNote = (e) => {
    const obj = { ...editEvent };
    obj.note = e.target.value;
    setEditEvent(obj);
  };
  const updateStatus = (e) => {
    // alert('you got it')
    const obj = { ...editEvent };
    if (obj) {
      if (obj.status) {
        obj.status.name = e.target.value;
      } else {
        obj.status = { name: e.target.value };
      }
    }
    setEditEvent(obj);
  };
  const updateEmar = async () => {
    // update then pass to parent
    await updateResidentEmar(editEvent.resident._id, editEvent._id, editEvent);
    onUpdate(editEvent);
  };
  const updatePassingQty = (e) => {
    const obj = { ...editEvent };
    obj.passingQty = e.target.value;
    setEditEvent(obj);
  };

  const getItemClass = (event) => {
    if (event.status.name) {
      switch (event.status.name) {
        case 'Scheduled':
          if (canEdit(event)) {
            return classes.readyToPass;
          } else {
            return classes.scheduled;
          }
        case 'Given':
          return classes.given;
        case 'Late':
          return classes.late;
        case 'Missed':
          return classes.missed;
        case 'Hold':
          return classes.hold;
        case 'SA':
          return classes.sa;
        case 'NA':
          return classes.na;
        case 'Refused':
          return classes.refused;
        case 'Destruction':
          return classes.destruction;
        default:
          return classes.scheduled;
      }
    }
  };

  const canEdit = (event) => {
    const current = moment();
    const start = moment(event.startWindow);
    const end = moment(event.endWindow);
    if (current >= start && current < end) {
      return true;
    } else {
      return false;
    }
  };

  const canEditQty = (order) => {
    // Add Logic for roles here
    var now = moment().unix();
    var start = order.startWindow;
    var end = order.endWindow;
    var endDifference = (now - end) / 3600;
    return start < now && endDifference < 12 && order.isQtyEditable;
  };

  const saveEvent = () => {
    updateEmar(editEvent);
    setEditEventIndex(-1);
  };

  return (
    <TableRow>
      <TableCell>
        <Avatar className={[classes.avatarSmall, getItemClass(event)]}>
          <i class="fas fas fa-prescription-bottle"></i>
        </Avatar>
      </TableCell>
      <TableCell>
        <Typography>{moment(event.scheduledTime).format('h:mm a')}</Typography>
      </TableCell>
      <TableCell>
        {event.medicationOrder && event.resident && (
          <Typography
            variant="subtitle2"
            component={RouterLink}
            to={`/residents/${event.resident._id}/emar`}
          >
            {`${event.resident.firstName} ${event.resident.lastName}`}
          </Typography>
        )}
      </TableCell>
      <TableCell>
        {event && event.medication && <span>{event.medication.tradeName}</span>}
      </TableCell>
      <TableCell>
        {event.initials &&
          `${event.initials} - ${moment(event.administeredTimestamp).format(
            'h:mma'
          )}`}
      </TableCell>
      <TableCell>
        {canEdit(event) && !isEdit && (
          <IconButton
            size="small"
            onClick={() => selectEditEvent(event, index)}
          >
            <EditIcon />
          </IconButton>
        )}
        {canEdit(event) && isEdit && (
          <ButtonGroup size="small">
            <Button
              size="small"
              onClick={saveEvent}
              color="primary"
              variant="contained"
            >
              Save
            </Button>
            <Button size="small" onClick={cancelEdit}>
              Cancel
            </Button>
          </ButtonGroup>
        )}
      </TableCell>
      <TableCell>
        {canEdit(event) && isEdit && (
          <TextField
            disabled={!canEditQty(event)}
            value={editEvent.passingQty || ''}
            label="Qty"
            onChange={updatePassingQty}
          />
        )}
        {!isEdit && <span>{event.passingQty}</span>}
      </TableCell>
      <TableCell>
        {index !== editEventIndex && event.medicationOrderEventStatusName}
        {canEdit(event) && isEdit && (
          <FormControl>
            <InputLabel htmlFor="status">Status</InputLabel>
            <Select
              id="status"
              label="Status"
              value={editEvent && editEvent.status ? editEvent.status.name : ''}
              onChange={updateStatus}
            >
              lgUp
              <MenuItem value={'Scheduled'}>Scheduled</MenuItem>
              <MenuItem value={'Given'}>Given</MenuItem>
              <MenuItem value={'Late'}>Late</MenuItem>
              <MenuItem value={'Missed'}>Missed</MenuItem>
              <MenuItem value={'Hold'}>Hold</MenuItem>
              <MenuItem value={'SA'}>Self Administered</MenuItem>
              <MenuItem value={'NA'}>Not Administered</MenuItem>
              <MenuItem value={'Refused'}>Refused</MenuItem>
            </Select>
          </FormControl>
        )}
        {!isEdit && <span>{event.status ? event.status.name : null}</span>}
      </TableCell>
      <TableCell>
        {canEdit(event) && !isEdit && event.note}
        {canEdit(event) && isEdit && (
          <TextField
            id="event-note"
            multiline
            rows={2}
            value={editEvent.note || ''}
            onChange={updateNote}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default MarRow;
