export const MedicalModel = {
  livingWill: {
    title: "Living Will",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [
      { name: "Yes", value: "y" },
      { name: "No", value: "n" }
    ],
    dropDownItemValue: "value",
    dropDownItemLabel: "name",
    date: false
  },
  codeStatus: {
    title: "Code Status",
    required: false,
    value: [],
    dirty: false,
    isValid: true,
    multiSelect: true,
    dropDown: true,
    dropDownItems: [],
    dropDownItemValue: "name",
    dropDownItemLabel: "name",
    date: false,
    showDescription: true
  },
  specialNeeds: {
    title: "Special Needs",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    date: false,
    multiline: true,
    multilineRows: 3
  }
};
