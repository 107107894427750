import React from "react";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import RadioButtonUncheckedRoundedIcon from "@material-ui/icons/RadioButtonUncheckedRounded";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import AddIcon from "@material-ui/icons/Add";
import {
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Typography,
  Button,
  TableContainer, TablePagination,
} from "@material-ui/core";
import { Link as RouterLink } from 'react-router-dom';
import Skeleton from "@material-ui/lab/Skeleton";
import {
  ResidentPhysiciansProvider,
  ResidentPhysiciansContext,
} from "./resident.physicians.context";
import PhysicianLookup from "../../physicians/physician.lookup";
import {
  createResidentPhysician,
  deleteResidentPhysician,
  updateResidentPhysician,
  openPhysicianLookupDialog,
  closePhysicianLookupDialog,
} from "./resident.physicians.actions";
import useHasRole from '../../../hooks/user.role.hook';
import {ResidentContext} from "../../../context/residentcontext";
import {getStripedStyle} from "../../utils/table.utils";
import {displayPhoneNumber} from "../../../utils/phone.display";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  table: {
    width: "100%",
  },
  iconButton: {
    padding: 0,
    margin: 0,
  },
  actionIcon: {
    height: "1.5rem",
    width: "1.5rem",
  },
}));

const ResidentPhysiciansTable = () => {
  const classes = useStyles();
  const residentContext = React.useContext(ResidentContext);
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(5);

  const { state, dispatch, residentId } = React.useContext(
    ResidentPhysiciansContext
  );

  const deactivateResidentPhysician = async (residentPhysician) => {
    await deleteResidentPhysician(
      dispatch,
      residentId,
      residentPhysician?.physician?._id,
      () => {
        residentContext.dispatch({type: 'DELETE_RESIDENT_PHYSICIAN', payload: residentPhysician?.physician?._id})
      }
    );
  };

  const toggleIsPrimary = async (residentPhysician) => {
    await updateResidentPhysician(
      dispatch,
      residentId,
      residentPhysician.physician._id,
      !residentPhysician.isPrimary,
      residentContext.dispatch
    );
  };

  const onPhysicianSelect = async (physician) => {
    await createResidentPhysician(dispatch, residentId, physician._id, residentContext.dispatch);
  };

  const { checkPermission } = useHasRole();
  const canEdit = checkPermission('RESIDENT_UPDATE');

  const sortPhysicians = (a, b) => {
    if (a?.physician && b?.physician) {
      return a.physician?.lastName
    } else {
      return 1;
    }
  }

  const onPageChange = (e, pg) => {
    setPage(pg);
  }

  const onRowsPerPageChange = e => {
    setSize(e.target.value);
    setPage(0);
  }

  const getPhone = residentPhysician => {
    const phones = residentPhysician?.physician?.phones;
    if (phones?.length > 1) {
      return phones?.map(phone =>
        <Typography variant={"subtitle2"}>
          {phone.phoneType}: {displayPhoneNumber(phone?.number)}
        </Typography>)
    } else if (phones?.length === 1) {
      return (
        <Typography variant={"subtitle2"}>
          {phones[0].phoneType}: {displayPhoneNumber(phones[0]?.number)}
        </Typography>
      )
    } else {
      return null;
    }
  }

  const getAddress = residentPhysician => {
    const addresses = residentPhysician?.physician?.addresses;
    if (addresses?.length > 1) {
      return addresses?.map(address =>
        <Typography variant={"subtitle2"}>
          {address.address} {address.address2} {address.city} {address.state}, {address.zipCode}
        </Typography>)
    } else if (addresses?.length === 1) {
      return (
        <Typography variant={"subtitle2"}>
          {addresses[0].address} {addresses[0].address2} {addresses[0].city} {addresses[0].state}, {addresses[0].zipCode}
        </Typography>
      )
    } else {
      return null;
    }
  }

  return (
    <div className={classes.root}>
      <div hidden={!state.loading}>
        <Skeleton variant="text" height={40} />
        <Skeleton variant="text" height={40} />
        <Skeleton variant="text" height={40} />
        <Skeleton variant="text" height={40} />
      </div>
      <div hidden={state.loading}>
        <Typography variant="subtitle1">Physicians</Typography>
        {canEdit &&
        <Button
          startIcon={<AddIcon />}
          onClick={() => dispatch(openPhysicianLookupDialog())}
        >
          Add Physician
        </Button>
        }
        <PhysicianLookup
          open={state.openPhysicianDialog}
          onClose={() => dispatch(closePhysicianLookupDialog())}
          onSelect={onPhysicianSelect}
        />
        <TablePagination component={"div"}
                         count={state?.residentPhysicians?.length}
                         page={page}
                         rowsPerPage={size}
                         rowsPerPageOptions={[5, 10, 25, 50]}
                         onPageChange={onPageChange}
                         onRowsPerPageChange={onRowsPerPageChange}
        />
        <TableContainer>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell width={200} />
                <TableCell>Name</TableCell>
                <TableCell>Specialty</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Provider</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state?.residentPhysicians?.slice(page * size, (page * size) + size)?.sort(sortPhysicians)?.map((residentPhysician, index) => (
                  <TableRow style={getStripedStyle(index)}>
                    <TableCell>
                      <IconButton component={RouterLink} to={`/physicians/${residentPhysician?.physician?._id}`} className={classes.iconButton}>
                        <VisibilityRoundedIcon className={classes.actionIcon} />
                      </IconButton>
                      {canEdit &&
                        <>
                      <IconButton
                        className={classes.iconButton}
                        aria-label="delete"
                        onClick={() =>
                          deactivateResidentPhysician(residentPhysician)
                        }
                      >
                        <DeleteRoundedIcon
                          className={classes.actionIcon}
                          color="error"
                        />
                      </IconButton>
                        <IconButton
                        className={classes.iconButton}
                        aria-label="Toggle primary"
                        onClick={() => toggleIsPrimary(residentPhysician)}
                        >
                      {residentPhysician.isPrimary ? (
                        <CheckCircleRoundedIcon
                        className={classes.actionIcon}
                        color="secondary"
                        />
                        ) : (
                        <RadioButtonUncheckedRoundedIcon />
                        )}
                        <Typography variant="subtitle2">Primary</Typography>
                        </IconButton>
                      </>
                      }
                    </TableCell>
                    <TableCell>
                      {residentPhysician?.physician?.title}{" "}
                      {residentPhysician?.physician?.firstName}{" "}
                      {residentPhysician?.physician?.lastName}
                    </TableCell>
                    <TableCell>
                      {residentPhysician?.physician?.specialty}
                    </TableCell>
                    <TableCell>
                      {getPhone(residentPhysician)}
                    </TableCell>
                    <TableCell>{residentPhysician?.physician?.email}</TableCell>
                    <TableCell>
                      {getAddress(residentPhysician)}
                    </TableCell>
                    <TableCell>{residentPhysician?.physician?.medicalProviders?.map(mp => mp.name).join(', ')}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

const ResidentPhysicians = ({ residentId }) => {
  return (
    <ResidentPhysiciansProvider residentId={residentId}>
      <ResidentPhysiciansTable />
    </ResidentPhysiciansProvider>
  );
};

export default ResidentPhysicians;
