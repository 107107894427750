import React from 'react';
import {
    ACTIONS,
    TimeEntryContext
} from "../../../context/TimeEntryContext/time.entry.context";
import MomentUtils from "@date-io/moment";
import {
    FormGroup, makeStyles,
    TextField,
} from "@material-ui/core";
import moment from "moment/moment";
import ChipInput from "material-ui-chip-input";
import {DatePicker, KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
    form: {
        padding: theme.spacing(1)
    }
}))


export const TimeEntryMobile = () => {
    const { state, dispatch } = React.useContext(TimeEntryContext);
    const { selectedTimeEntry } = state;
    const classes = useStyles();

    React.useEffect(() => {
        setTimeEntryEditModel(JSON.parse(JSON.stringify(selectedTimeEntry)));
        // eslint-disable-next-line
    }, [selectedTimeEntry])

    const setTimeEntryEditModel = obj => {
        dispatch({type: ACTIONS.SET_EDIT_TIME_ENTRY_MODEL, payload: obj});
    }

    const onTotalChanged = (event, e) => {
        let obj = {...state?.editTimeEntryModel};
        obj.totalMinutesInput = e.target.value;
        setTimeEntryEditModel(obj);
    }


    const validateTotalTime = async (event) => {
        let durationInput = event?.totalMinutesInput;
        let hourOnly = false;
        if (Number(durationInput) === Math.floor(durationInput)) {
            hourOnly = true;
            durationInput = `${durationInput}:00`
        }
        let duration = moment.duration(durationInput);
        if (duration?.hours() > 0 || duration?.minutes() > 0) {
            let newEnd = moment(event?.start).clone();
            newEnd.add(duration.hours(), 'hours');
            newEnd.add(duration.minutes(), 'minutes');
            event.end = newEnd;
            const data = {...event}
            data.totalMinutes = duration.asMinutes();
            // await updateTimeEntryAction(dispatch, data);

            let obj = {...state?.editTimeEntryModel};
            obj.end = newEnd;
            if (hourOnly) {
                obj.totalMinutesInput = durationInput;
            }
            setTimeEntryEditModel(obj);
        } else {
            // Not valid, do not update.
            let existingDuration = moment.duration(event?.totalMinutes, "minutes");
            let obj = {...state?.editTimeEntryModel};
            obj.totalMinutesInput = `${existingDuration.hours()}:${existingDuration.minutes().toLocaleString("en-us",
                {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                })
            }`;
            setTimeEntryEditModel(obj);
        }
    }

    const onStartChange = async (event, date) => {

        if (moment(date).isValid()) {
            const startDate = moment(date).set({month: moment(event.start).month(), date: moment(event.start).date()});
            event.start = startDate;
            const data = {...event};
            data.start = startDate;
            let difMinutes = moment.duration(moment(data?.end).diff(startDate));
            data.totalMinutes = difMinutes.asMinutes();
            // await updateTimeEntryAction(dispatch, data);
        }
        const obj = {...state?.editTimeEntryModel};
        if (moment(date).isValid()) {
            const startDate = moment(date).set({month: moment(event.start).month(), date: moment(event.start).date()});
            // get Diff in minutes
            let difMinutes = moment.duration(moment(state?.editTimeEntryModel?.end).diff(startDate));
            obj.totalMinutes = difMinutes.asMinutes();
            obj.totalMinutesInput = `${difMinutes.hours()}:${difMinutes.minutes().toLocaleString("en-us",
                {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                })
            }`;
        }
        setTimeEntryEditModel(obj);
    }

    const onEndChange = async (event, date) => {
        if (date === null || date === undefined) {
            const data = {...event};
            data.end = null;
            data.totalMinutes = null;
            // await updateTimeEntryAction(dispatch, data);
        } else if (moment(date).isValid()) {
            const endDate = moment(date).set({month: moment(event.start).month(), date: moment(event.start).date()});
            event.end = endDate;
            const data = {...event};
            data.end = endDate;
            let difMinutes = moment.duration(moment(endDate).diff(data.start));
            data.totalMinutes = difMinutes.asMinutes();
            // await updateTimeEntryAction(dispatch, data);
        }
        const obj = {...state?.editTimeEntryModel};
        if  (date && moment(date).isValid()) {
            let difMinutes = moment.duration(moment(date).diff(obj.start));
            obj.totalMinutes = difMinutes.asMinutes();
            obj.totalMinutesInput = `${difMinutes.hours()}:${difMinutes.minutes().toLocaleString("en-us",
                {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                })
            }`;
        } else {
            obj.totalMinutes = 0;
            obj.totalMinutesInput = 0;
        }
        setTimeEntryEditModel(obj);
    }

    const onCommentChange = async e => {
        let obj = {...state?.editTimeEntryModel}
        obj.comment = e.target.value;
        // await updateTimeEntryAction(dispatch, obj);
        setTimeEntryEditModel(obj);
    }

    const onEventDateChange = async (date) => {
        const obj = {...state?.editTimeEntryModel};
        obj.date = date;
        // await updateTimeEntryAction(dispatch, obj);
    }

    const handleTagAdd = async tag => {
        const obj = {...state?.editTimeEntryModel};
        obj.tags = [...obj?.tags, tag];
        // await updateTimeEntryAction(dispatch, obj);
        setTimeEntryEditModel(obj);
    }
    const handleTagDelete = async tag => {
        const obj = {...state?.editTimeEntryModel};
        obj.tags = obj.tags?.filter(t => t !== tag);
        // await updateTimeEntryAction(dispatch, obj);
        setTimeEntryEditModel(obj);
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <FormGroup className={classes.form}>
                <DatePicker value={moment(state?.editTimeEntryModel?.date)?.utc()}
                            onChange={onEventDateChange}
                            label={"Date"}
                />
                <KeyboardTimePicker
                    size={"large"}
                    fullWidth
                    mask="__:__ _M"
                    label={"Start"}
                    value={state?.editTimeEntryModel?.start || null}
                    name={"start"}
                    onChange={(date) => onStartChange(state?.editTimeEntryModel, date)}
                />
                <KeyboardTimePicker
                    fullWidth
                    mask="__:__ _M"
                    label={"End"}
                    value={state?.editTimeEntryModel?.end || null}
                    name={"end"}
                    onChange={(date) => onEndChange(state?.editTimeEntryModel, date)}
                />
                <TextField fullWidth
                           onChange={onCommentChange}
                           InputLabelProps={{shrink: true}}
                           label={"Comment"}
                           multiline={true}
                           value={state?.editTimeEntryModel?.comment} />
                <ChipInput
                    color={"primary"}
                    label={"Tags"}
                    fullWidth
                    fullWidthInput
                    blurBehavior={"add"}
                    value={state?.editTimeEntryModel?.tags}
                    onAdd={handleTagAdd}
                    onDelete={handleTagDelete}
                    dataSource={null}
                />
                <TextField label={"Total"}
                           onChange={(e) => onTotalChanged(state?.editTimeEntryModel, e)}
                           onBlur={() => validateTotalTime(state?.editTimeEntryModel)}
                           InputLabelProps={{ shrink: true }}
                           value={state?.editTimeEntryModel?.totalMinutesInput} />
            </FormGroup>
        </MuiPickersUtilsProvider>
    )
}
