import React from 'react';
import { UserContext } from './usercontext';

let UserTenantContext = React.createContext();
let initialState = {
    user: null,
    roles: [],
    permissions:[]
};

let reducer = (state, action) => {
    switch(action.type) {
        case "setRoles":
            return {...state, roles: action.payload};
        case "setPermissions":
            return {...state, permissions: action.payload};
        case "setUser":
            return {...state, user: action.payload}
        default:
            return {...state};
    }
}

const UserTenantContextProvider = ({ children}) => {
    const userContext = React.useContext(UserContext);
    let [state, dispatch] = React.useReducer(reducer, initialState);
    let value = { state, dispatch}

    React.useEffect(() => {
        dispatch({type: 'setUser', payload: userContext.state.user });
        if (userContext.state && userContext.state.user && userContext.state.user) {
            dispatch({type: "setRoles", payload: userContext.state.user.roles});
        } else {
            dispatch({type: "setRoles", payload: []});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ userContext.state.user ]);

    return <UserTenantContext.Provider value={value}>
        {children}
    </UserTenantContext.Provider>
}

let UserTenantContextConsumer = UserTenantContext.Consumer;

export {
    UserTenantContext,
    UserTenantContextProvider,
    UserTenantContextConsumer
}
