import React from "react";
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  DialogContent,
  makeStyles,
  DialogActions,
} from "@material-ui/core";
import Transition from "../../dialog/transition";
import CloseIcon from "@material-ui/icons/Close";
import { FormFieldsGenerator, isModelValid } from '../../../utils/forms'
import ModelTemplate from './diagnosis.model';
import DiagnosisLookup from "../../diagnosis/diagnosis.lookup";
import {ObjectId} from "bson";

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1),
    paddingTop: "4rem"
  },
  dialogContent: {
    marginTop: "4rem"
  },
  btnContainer: {
    paddingTop: ".5rem",
    display: "flex",
    justifyContent: "flex-end"
  }
}));

const CreateResidentDiagnosisModal = ({ open, onClose, onSave }) => {
  const classes = useStyles();
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(ModelTemplate)));

  React.useEffect(() => {

    if (open) {
      setModel(JSON.parse(JSON.stringify(ModelTemplate)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const save = () => {
    if (onSave) {
      onSave({
        _id: new ObjectId(),
        diagnosis: model.diagnosis.value,
        admittingDiagnosis: model.admittingDiagnosis.value,
        startDate: model.startDate.value,
        endDate: model.endDate.value
      });
    }
  };

  const onModelChange = updatedModel => {
    const obj = { ...model, ...updatedModel };
    setModel(obj);
  };

  const onDiagnosisSelected = diagnosis => {
    const obj = {...model};
    obj.diagnosis.value = diagnosis;
    obj.diagnosis.isValid = obj.diagnosis.value ? true: false;
    setModel(obj);
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Create Resident Diagnosis
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.dialogContent} dividers>
        <form noValidate>
          <DiagnosisLookup onSelected={onDiagnosisSelected} />
          <FormFieldsGenerator model={model} onChange={onModelChange} />
        </form>
      </DialogContent>
      <DialogActions>
        <Button color={"primary"}
                size={"small"}
                onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={save}
                size={"small"}
                variant="contained"
                disabled={!isModelValid(model)}
                color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateResidentDiagnosisModal;
