import React from 'react';
import {useAuth0} from "../../react-auth0-spa";


export const AuthPage = () => {

  const { loginWithRedirect } = useAuth0();

  React.useEffect(() => {
    const login = async () => {
      await loginWithRedirect();
    }
    login();
  }, [loginWithRedirect]);

  return (
    <div>
      Authenticating....
    </div>
  )
}
