import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import AllergyLookup from "../../allergy/allergy.lookup";
import DeleteIcon from "@material-ui/icons/Delete";
import {ResidentContext} from "../../../context/residentcontext";
import {createResidentAllergy, deleteResidentAllergy} from "../../../services/resident.service";

const useStyles = makeStyles((theme => ({
  root: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1),
  },
})));

const ResidentAllergies = () => {
  const classes = useStyles();
  const [viewAllergyLookup, setViewAllergyLookup] = React.useState(false);
  const { resident, dispatch } = React.useContext(ResidentContext);
  const [allergies, setAllergies] = React.useState([]);


  React.useState(() => {
    if (resident) {
      setAllergies(resident.allergies);
    } else {
      setAllergies([]);
    }
  }, [resident]);

  const allergySelected = async payload => {




    const response = await createResidentAllergy(resident?._id, payload);
    const updated = [...allergies, ...response];
    setAllergies(updated);
    setViewAllergyLookup(false);
    dispatch({type: 'UPDATE_RESIDENT_ALLERGY', payload: updated})
  }

  const removeAllergy = async allergy => {
    await deleteResidentAllergy(resident?._id, allergy?._id);
    const updated = allergies.filter(al => allergy._id !== al._id );
    setAllergies(updated)
    dispatch({type: 'UPDATE_RESIDENT_ALLERGY', payload: updated})
  }

  return (
    <Paper elevation={24} className={classes.root}>
      <Typography variant={"h6"}>Allergies</Typography>
      <Button size={"small"}
              variant={"contained"}
              color={"primary"}
              onClick={() => setViewAllergyLookup(true)}
              startIcon={<AddIcon />}
      >
        Add
      </Button>
      <AllergyLookup open={viewAllergyLookup}
                     onClose={() => setViewAllergyLookup(false)}
                     onSelect={allergySelected}
      />
      <TableContainer>
        <Table size={"small"}>
          <TableHead>
            <TableCell width={75} />
            <TableCell>
              Name
            </TableCell>
            <TableCell>
              Descriptions
            </TableCell>
          </TableHead>
          <TableBody>
            {allergies?.map(allergy =>
              <TableRow>
                <TableCell>
                  <Button startIcon={<DeleteIcon/>} onClick={() => removeAllergy(allergy)}>Remove</Button>
                </TableCell>
                <TableCell>{allergy.name}</TableCell>
                <TableCell>{allergy.description}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default ResidentAllergies;
