import React from 'react';
import {MoreVertOutlined} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import {onViewCopyFileModalAction, onViewMoveFileModalAction, onViewRenameFileModalAction} from "../context/Actions";
import {ResidentFileContext} from "../context/ResidentFilesContext";
import {Divider, Menu, MenuItem} from "@material-ui/core";
import useHasRole from "../../../../hooks/user.role.hook";
import {CONSTANTS} from "../context/Constants";

export const RowFileMenu = ({file}) => {
    const {dispatch} = React.useContext(ResidentFileContext);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { checkPermission } = useHasRole();
    const canUpdate = checkPermission("RESIDENT_FILE_UPDATE");
    const canHiddenUpdate = checkPermission("RESIDENT_FILE_HIDDEN_UPDATE");


    const onFileOptionsClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    }

    const onCopyClick = (file) => {
        onViewCopyFileModalAction(dispatch, true, file);
        setAnchorEl(null);
    }

    const onMoveClick = (file) => {
        onViewMoveFileModalAction(dispatch, true, file);
        setAnchorEl(null);
    }

    const onRenameClick = (file) => {
        onViewRenameFileModalAction(dispatch, true, file);
        setAnchorEl(null);
    }

    const onPermissionClick = (file) => {
        dispatch({type: CONSTANTS.SET_VIEW_PERMISSION_MODAL, payload: true});
        dispatch({type: CONSTANTS.SET_SELECTED_OBJECT, payload: file});
        setAnchorEl(null);
    }


    if (canUpdate || canHiddenUpdate) {

        return (
            <>
                <IconButton size={"small"}
                            onClick={onFileOptionsClick}
                            aria-controls={`${file?._id}-file-menu`}
                            aria-haspopup="true">
                    <MoreVertOutlined/>
                </IconButton>
                <Menu
                    id={`${file?._id}-file-menu`}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                >
                    <MenuItem onClick={() => onCopyClick(file)}>Copy</MenuItem>
                    <MenuItem onClick={() => onMoveClick(file)}>Move</MenuItem>
                    <MenuItem onClick={() => onRenameClick(file)}>Rename</MenuItem>
                    <Divider/>
                    {canHiddenUpdate && <MenuItem onClick={() => onPermissionClick(file)}>Permissions</MenuItem>}
                </Menu>
            </>
        )
    } else {
        return null;
    }
}
