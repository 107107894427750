import * as constant from './intake.constants';
import ResidentIntakeModel from '../../resident.intake.model';

export const initialState = {
  id: null,
  isNewIntake: false,
  resident: null,
  updating: false,
  model: JSON.parse(JSON.stringify(ResidentIntakeModel)),
  tenant: null,
  tenantLocations: [],
  tenantLocationId: null,
  uploadingPhoto: false,
  step: {
    name: 'details',
    title: 'Details',
    first: true,
    completed: false
  },
  steps: [
    {
      name: 'details',
      title: 'Details',
      first: true,
      completed: false,
      prevIndex: -1
    },
    {
      name: 'photo',
      title: 'Photo',
      completed: false,
      prevIndex: 0
    },
    {
      name: 'medical',
      title: 'Medical Information',
      completed: false,
      prevIndex: 1
    },
    {
      name: 'background',
      title: 'Background Information',
      completed: false,
      prevIndex: 2
    },
    {
      name: 'address',
      title: 'Address & Phone/Email',
      isValid: false,
      prevIndex: 3
    },
    {
      name: 'contacts',
      title: 'Contacts',
      completed: false,
      prevIndex: 4
    },
    {
      name: 'assessment',
      title: 'Intake Assessment',
      isValid: false,
      prevIndex: 5
    },
    {
      name: 'summary',
      title: 'Summary',
      last: true,
      completed: false,
      prevIndex: 6
    }
  ]
}


export const reducer = (state, action) => {
  switch (action.type) {
    case constant.SET_STEP:
      return { ...state, step: action?.payload };
    case constant.SET_STEP_BY_NAME:
      return { ...state, step: state.steps?.find(s => s.name === action?.payload)};
    case constant.SET_RESIDENT_ID:
      return { ...state, id: action?.payload };
    case constant.SET_RESIDENT:
      return { ...state, resident: action?.payload };
    case constant.SET_LOADING:
      return { ...state, loading: action?.payload };
    case constant.SET_UPDATING:
      return {...state, updating: action?.payload};
    case constant.SET_ERROR:
      return { ...state, error: action?.payload };
    case constant.SET_NEW_INTAKE:
      return { ...state, resident: null, model: JSON.parse(JSON.stringify(ResidentIntakeModel)), isNewIntake: action?.payload };
    case constant.SET_TENANT_LOCATION:
      const tenantLocationObj = {...state.model};
      tenantLocationObj.tenantLocationId.value = action?.payload?._id;
      return { ...state, model: tenantLocationObj, tenantLocationId: action?.payload };
    case constant.SET_TENANT_LOCATIONS:
      const tenantLocationsObj = {...state.model};
      tenantLocationsObj.tenantLocationId.dropDownItems = action?.payload;
      return { ...state, model: tenantLocationsObj, tenantLocations: action?.payload };
    case constant.SET_STEP_STATUS:
      const steps = state?.steps?.map(s => {
        const { step, completed } = action?.payload;
        if (s?.name === step) {
          s.completed = completed;
        };
        return s;
      })
      return { ...state, steps };
    // case constant.SET_MODEL:
    //   return {...state, model: action?.payload };
    case constant.MERGE_MODEL_WITH_VALUES:
      return {...state, model: {...state.model, ...action?.payload }};
    case constant.SET_TENANT:
      return {...state, tenant: action?.payload};
    case constant.SET_UPLOADING_PHOTO:
      return {...state, uploadingPhoto: action?.payload };
    case constant.SET_PHOTO:
      const photoObj = {...state.model};
      photoObj.photo.value = action?.payload;
      const photoResident = {...state.resident};
      photoResident.imageUrl = action?.payload;
      return {...state, model: photoObj, resident: photoResident};
    default:
      return { ...state };
  }
};
