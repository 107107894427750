import React from 'react';
import {Document, Page, StyleSheet, Text, View} from "@react-pdf/renderer";
import HeaderWithTenant from "../../../../reports/pdf/components/headerwithtenant";
import moment from "moment-timezone";

const BORDER_COLOR = '#bfbfbf'
const BORDER_STYLE = 'solid'

const styles = StyleSheet.create({
    page: {
        padding: '0 0 84px 0'
    },
    headerSection: {
        backgroundColor: '#2196f3',
        color: '#fff',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: '10vh'
    },
    mainContent: {
        backgroundColor: 'white',
        padding: '0 5px'
    },
    body: {
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableRow: {
        width: '100%',
        margin: "auto",
        flexDirection: "row"
    },
    tableCol1Header: {
        flex: 2,
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderBottomColor: '#000',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCol2Header: {
        flex: 1,
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderBottomColor: '#000',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableColHeader: {
        width: '20px',
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderBottomColor: '#000',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol1: {
        flex: 2,
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol2: {
        flex: 1,
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        textAlign: "center"
    },
    hrContainer: {
        paddingTop: '10px',
        width: '55px',
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0

    },
    tableCol: {
        width: '20px',
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCellHeader: {
        margin: 5,
        fontSize: 8,
        fontWeight: 500
    },
    tableCell: {
        margin: 5,
        fontSize: 8
    },
    titleContainer: {
        paddingTop: '15px'
    },
    pageCount: {
        paddingLeft: '5px',
        paddingTop: '15px'
    },
    headerText: {
        fontSize: '20px'
    },
    bodyText: {
        fontSize: '8px'
    },
    centerAlign: {
        textAlign: 'center'
    },
    infoSection: {
        margin: '10 0'
    },
    infoText: {
        fontSize: '12px',
        padding: '3 0'
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: 0,
        padding: '5px'
    },
    footerContainer: {
        marginTop: '5px',
        fontSize: '8px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'center'
    },
    marContainer: {
        padding: '5px'
    },
    marHeaderText: {

    },
    dosageInsructionContainer: {
        paddingTop: '5px'
    },
    hrText: {
        minHeight: '25px',
        textAlign: 'center',
    },
    marValueCell: {
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderTopWidth: 0
    },
    discontinuedDate: {
        width: '100%',
        padding: '5px',
        backgroundColor: "#9e9e9e",
        color: '#fff'
    },
    alerts: {
        width: '100%',
        padding: '5px',
        backgroundColor: "#e57373",
        color: '#fff'
    },
    hideOverflow: {
        textOverflow: "ellipsis"
    },
    freqContainer: {
        minHeight: '10px',
        textAlign: 'center',
    },
    paddedTableCol: {
        paddingTop: '10px',
        width: '20px',
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
});

export const BMLogReport = ({resident, logs, startDate, endDate}) => {

    return (
        <Document size={"a4"} orientation={"landscape"} >
            <Page size={"A4"} orientation={'landscape'} style={styles.page}>
                <HeaderWithTenant
                    reportTitle={`Incontinence Bowel Movement Report`}
                    reportSubtitle={`${resident?.firstName} ${resident?.lastName}\n${moment(startDate).format("MM/DD/YYYY")} - ${moment(endDate).format("MM/DD/YYYY")}`}
                    location={resident?.location}
                    tenant={resident?.location?.tenant}
                    allUserLocations={false}
                />
                <View style={styles.mainContent}>
                    <View style={styles.table}>
                        <View style={styles.tableRow} fixed>
                            <View style={styles.tableCol1Header}>
                                <Text style={styles.tableCellHeader}>Date</Text>
                            </View>
                            <View style={styles.tableCol2Header}>
                                <Text style={[styles.tableCellHeader, styles.centerAlign]}>Hour</Text>
                            </View>
                            <View style={styles.tableCol2Header}>
                                <Text style={[styles.tableCellHeader, styles.centerAlign]}>Pattern</Text>
                            </View>
                            <View style={styles.tableCol2Header}>
                                <Text style={[styles.tableCellHeader, styles.centerAlign]}>Color</Text>
                            </View>
                            <View style={styles.tableCol2Header}>
                                <Text style={[styles.tableCellHeader, styles.centerAlign]}>Consistency</Text>
                            </View>
                            <View style={styles.tableCol1Header}>
                                <Text style={[styles.tableCellHeader, styles.centerAlign]}>Comment</Text>
                            </View>
                            <View style={styles.tableCol2Header}>
                                <Text style={[styles.tableCellHeader, styles.centerAlign]}>Staff</Text>
                            </View>
                        </View>
                        {logs?.map((log, index) =>
                            <View style={styles.tableRow}>
                                <View style={styles.tableCol1}>
                                    <Text style={[styles.tableCell, styles.bodyText, styles.hideOverflow]}>
                                        {moment(log.date).format("MM/DD/YYYY hh:mm a")}
                                    </Text>
                                </View>
                                <View style={styles.tableCol2}>
                                    <Text style={[styles.tableCell, styles.bodyText, styles.hideOverflow]}>
                                        {moment(log?.hour).format("hh:mm a")}
                                    </Text>
                                </View>
                                <View style={styles.tableCol2}>
                                    <Text style={[styles.tableCell, styles.bodyText, styles.hideOverflow]}>
                                        {log.pattern}
                                    </Text>
                                </View>
                                <View style={styles.tableCol2}>
                                    <Text style={[styles.tableCell, styles.bodyText, styles.hideOverflow]}>
                                        {log.color}
                                    </Text>
                                </View>
                                <View style={styles.tableCol2}>
                                    <Text style={[styles.tableCell, styles.bodyText, styles.hideOverflow]}>
                                        {log.consistency}
                                    </Text>
                                </View>
                                <View style={styles.tableCol1}>
                                    <Text style={[styles.tableCell, styles.bodyText, styles.hideOverflow]}>
                                        {log.comments}
                                    </Text>
                                </View>
                                <View style={styles.tableCol2}>
                                    <Text style={[styles.tableCell, styles.bodyText, styles.hideOverflow]}>
                                        {log?.staff?.firstName} {log?.staff?.lastName}
                                    </Text>
                                </View>
                            </View>
                        )}
                    </View>
                </View>
            </Page>

        </Document>
    )
}
