import React from 'react';
import {
  makeStyles,
  Typography,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemAvatar,
  Avatar,
  Divider,
} from '@material-ui/core';
import moment from 'moment';
import {
  green,
  yellow,
  red,
  grey,
  orange,
  blue,
} from '@material-ui/core/colors';
import { Link as RouterLink } from 'react-router-dom';
import Skeleton from "@material-ui/lab/Skeleton";

const contrast = 400;
const useStyles = makeStyles((theme) => ({
  etar: {},
  etarSelected: {
    background: theme.palette.grey[200],
    borderColor: theme.palette.grey[500],
  },
  root: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  icon: {
    marginRight: '.5rem',
  },
  backBtn: {
    marginRight: '.5rem',
  },
  gridContainer: {
    padding: theme.spacing(1),
  },
  listRoot: {
    backgroundColor: theme.palette.background.paper,
  },
  calendarBtn: {
    margin: theme.spacing(0, 1),
  },
  listItem: {
    borderBottom: '.25px solid',
    borderColor: theme.palette.grey[50],
    height: '10rem',
  },
  avatarContainer: {
    marginRight: theme.spacing(3),
  },
  avatarSmall: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  avatarLarge: {
    background: theme.palette.primary,
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  scheduled: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: grey[500],
  },
  readyToPass: {
    background: grey[700],
  },
  given: {
    background: green[contrast],
  },
  late: {
    background: orange[contrast],
  },
  missed: {
    background: red[contrast],
  },
  hold: {
    background: yellow[contrast],
  },
  sa: {
    background: blue[contrast],
  },
  na: {
    background: grey[contrast],
  },
  refused: {
    background: orange[contrast],
  },
  destruction: {
    background: yellow[contrast],
  },
  status: {
    textAlign: 'center',
    marginTop: theme.spacing(1),
  },
  secondaryActions: {
    position: 'relative',
    right: '0',
    transform: 'translate(0)',
  },
  chartVitalsIndicator: {
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    right: 10,
    bottom: 10,
  }
}));

const MobileEtar = ({ etar, onSelected, isSelected = false, onQuickUpdate }) => {
  const classes = useStyles();
  const [updating, setUpdating] = React.useState(false);

  const canEdit = (event) => {
    const current = moment();
    const start = moment(event.startWindow);
    const end = moment(event.endWindow);
    if (current >= start && current < end) {
      return true;
    } else {
      return false;
    }
  };

  const getItemClass = (event) => {
    if (event && event.status && event.status.name) {
      switch (event.status.name) {
        case 'Scheduled':
          if (canEdit(event)) {
            return classes.readyToPass;
          } else {
            return classes.scheduled;
          }
        case 'Given':
          return classes.given;
        case 'Late':
          return classes.late;
        case 'Missed':
          return classes.missed;
        case 'Hold':
          return classes.hold;
        case 'SA':
          return classes.sa;
        case 'NA':
          return classes.na;
        case 'Refused':
          return classes.refused;
        case 'Destruction':
          return classes.destruction;
        default:
          return classes.scheduled;
      }
    }
  };

  const displayTime = (minutes) => {
    if (minutes >= 60) {
      const hours = minutes / 60;
      return `${hours} hour${minutes > 60 ? 's' : ''}`;
    } else {
      return `${minutes} minutes`;
    }
  };

  const handleQuickUpdate = (e, etar, status) => {
    setUpdating(true);
    e.stopPropagation();
    if (onQuickUpdate) {
      onQuickUpdate(etar, status).then(() => {
        setUpdating(false);
      });
    }
  }

  if (updating) {
    return (
      <ListItem divider
                style={{height: `10vh`}}
      >
        <ListItemAvatar>
          <Skeleton variant={"circle"}
                    height={40}
                    width={40}
          />
        </ListItemAvatar>
        <ListItemText primary={<Skeleton height={30} />}
                      secondary={
                        <>
                          <Skeleton height={10} />
                          <Skeleton height={10} />
                        </>
                      }
        />
      </ListItem>
    )
  }

  return (
    <ListItem button onClick={() => onSelected(etar)}>
      <ListItemAvatar>
        <Avatar className={[classes.avatarSmall, getItemClass(etar)]}>
          <i class="fas fa-band-aid"></i>
        </Avatar>
        {canEdit(etar) && <>
          {etar?.chartVitals?.length < 1 && etar?.status?.name === etar?.defaultQuickStatus &&
            <div style={{marginTop: '1rem'}}>
              <Avatar className={[getItemClass(etar)]}
                      onClick={(event) => handleQuickUpdate(event, etar, 'Scheduled')}
              >
                <i className={"fas fa-check"}></i>
              </Avatar>
            </div>
          }
          {etar?.chartVitals?.length < 1 && etar?.status?.name !== etar?.defaultQuickStatus &&
            <div style={{marginTop: '1rem'}}>
              <Avatar className={[getItemClass(etar)]}
                      onClick={(event) => handleQuickUpdate(event, etar, etar?.defaultQuickStatus)}>
                <i className={["fas fa-circle", getItemClass(etar)]}></i>
              </Avatar>
            </div>
          }
        </>}
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography
            component={RouterLink}
            to={`/residents/${etar?.resident?._id}/etar`}
          >
            {etar?.resident?.firstName} {etar?.resident?.lastName}
          </Typography>
        }
        secondary={
          <>
            <Typography variant="subtitle1">{etar.name}</Typography>
            <Typography variant="subtitle2">{etar.description}</Typography>
            {etar?.time &&
              <Typography variant="subtitle2">
                {displayTime(etar.time)}
              </Typography>
            }
            <Typography
              component={RouterLink}
              style={{
                fontWeight: 'bolder',
                fontSize: '1.25rem',
              }}
            >
              {etar.scheduledTime &&
                moment(etar.scheduledTime).format('h:mm a')}
            </Typography>
          </>
        }
      />
      <Divider/>
      {etar?.chartVitals?.length > 0 &&
        <Avatar className={classes.chartVitalsIndicator}>
          <i className="fas fa-heartbeat"></i>
        </Avatar>
      }
      <ListItemSecondaryAction className={classes.secondaryActions}>
        <Typography variant="subtitle2">{etar?.status?.name}</Typography>
        {etar.administeredUser && etar.administeredUser.initials && (
          <Typography variant="subtitle2">
            {etar.administeredUser.initials} -{' '}
            {moment(etar.administeredTimestamp).format('h:mm a')}
          </Typography>
        )}
      </ListItemSecondaryAction>
      <Divider/>
    </ListItem>
  );
}

export default MobileEtar;
