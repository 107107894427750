export const EPRN_MODEL = {
  _id: {
    hidden: true,
    title: "Medication Name",
    required: true,
    value: null,
    dirty: false,
    isValid: true,
  },
  scheduledTime: {
    title: "Hours",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    time: true
  },
  administeredBy: {
    title: "Administered By",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [],
    dropDownItemValue: "_id",
    dropDownItemLabel: "name",
    date: false
  },
  qty: {
    title: "QTY",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
  },
  reason: {
    title: "Reason",
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiline: true,
    multilineRows: 5
  },
  outcomes: {
    hidden: true,
    value: []
  }
}
