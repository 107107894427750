import React from 'react';
import { Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const NotFound = () => {
  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <Typography variant="h1" component="div">
        404
      </Typography>
      <Typography variant="subtitle1" component="div">
        Page not found
      </Typography>
      <Typography variant="subtitle2" component="div">
        Opps, something must of happened, click{' '}
        <Typography component={RouterLink} to={'/'}>
          here
        </Typography>{' '}
        to go home.
      </Typography>
    </div>
  );
};

export default NotFound;
