import React from 'react';
import {
  Typography,
  makeStyles,
  Tabs,
  Tab,
  Paper,
} from '@material-ui/core';
import VitalsView from './vitals/vitals.view';
import LungSoundView from './lung/lung.sound.view';
import VentilatorSettingsReadingView from './ventilator/ventilator.readings';
import useHasRole from "../../../hooks/user.role.hook";
import {PageTitle} from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    margin: theme.spacing(1, 0),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <Typography>{children}</Typography>
        </div>
      )}
    </div>
  );
}

const ResidentVitals = ({ match }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { checkPermission } = useHasRole();
  const canEdit = checkPermission('RESIDENT_VITALS_UPDATE');
  const canRead = checkPermission('RESIDENT_VITALS_READ');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper elevation={24} className={classes.root}>
      <PageTitle title={"Resident Vitals"} />
      {canRead &&
      <>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        aria-label="full width tabs vitals"
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Vitals" />
        <Tab label="Lung Sound" />
        <Tab label="Ventilator Settings/Readings" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <VitalsView match={match} canEdit={canEdit} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <LungSoundView match={match} canEdit={canEdit} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <VentilatorSettingsReadingView match={match} canEdit={canEdit} />
      </TabPanel>
      </>}
    </Paper>
  );
};

export default ResidentVitals;
