import React from "react";
import {
  TableContainer,
  Paper,
  makeStyles,
  Button,
  TableHead,
  TableRow,
  Table,
  TableCell,
  TableBody,
  Grid,
  TablePagination, Hidden, IconButton, List, ListItem, ListItemText, Typography, ListItemIcon,
} from "@material-ui/core";
import filterTemplate from "./filter.model";
import {
  searchMedications,
  getMedicationUnits,
  getDosageForms,
  getRoutes,
} from "../../services/medication.service";
import { convertToFilters } from "../../utils/filter.utils";
import { Link as RouterLink } from "react-router-dom";
import CreateMedication from "./create.medication";
import useHasRole from "../../hooks/user.role.hook";
import {SortFilterLabel, SortFilterSelectLabel} from "../utils/table.utils";
import AddIcon from "@material-ui/icons/Add";
import {MobileFilterModal} from "../utils/filters/mobile.filter.modal";
import TableLoadingIndicator from "../../utils/indicators/table.loading";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(.25, 0),
    padding: theme.spacing(1),
  },
}));

const getStripedStyle = (index) => {
  return { background: index % 2 ? "#fafafa" : "#fff" };
};

const sizeOptions = [5, 10, 25];

const MedicationList = () => {
  const classes = useStyles();
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [units, setUnits] = React.useState([]);
  const [dosageForms, setDosageForms] = React.useState([]);
  const [routes, setRoutes] = React.useState([]);
  const [medications, setMedications] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [filter, setFilter] = React.useState(
    JSON.parse(JSON.stringify(filterTemplate))
  );
  const [sortOrderBy, setSortOrderBy] = React.useState("tradeName");
  const [sortOrderDirection, setSortOrderDirection] = React.useState("asc");
  const [openCreateMedicationModal, setCreateMedicationModal] = React.useState(false);
  const { checkPermission } = useHasRole();
  const canUpdate = checkPermission("MEDICATION_UPDATE");
  const [showFilter, setShowFilter] = React.useState(false);

  React.useEffect(() => {
    initFilterAndSearch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initFilterAndSearch = async () => {
    let unitz = units;
    if (!unitz || unitz?.length === 0) {
      unitz = await getMedicationUnits();
      setUnits(unitz);
    }
    let dosagez = dosageForms;
    if (!dosagez || dosagez?.length === 0) {
      dosagez = await getDosageForms();
      setDosageForms(dosagez);
    }
    let routez = routes;
    if (!routez || routez?.length === 0) {
      routez = await getRoutes();
      setRoutes(routez);
    }
    const obj = JSON.parse(JSON.stringify(filterTemplate));
    obj.route.dropDownItems = routez;
    obj.unit.dropDownItems = unitz;
    obj.dosageForm.dropDownItems = dosagez;
    setFilter(obj);
    setPage(1);
    setSize(10);
    setSortOrderBy('name');
    setSortOrderDirection('asc')
    await search(1, 25, obj, 'name', 'asc');
  }


  const createSortHandler = async (field) => {
    const sort = field;
    const sortDirection = sortOrderDirection === "asc" ? "desc" : "asc";
    setSortOrderBy(sort);
    setSortOrderDirection(sortDirection);
    await search(page, size, filter, sort, sortDirection);
  };
  const filterTextChange = e => {
    const obj = { ...filter };
    obj[e.target.name].value = e.target.value;
    if (
      String(obj[e.target.name].value).length === 0 ||
      String(obj[e.target.name].value).length >= obj[e.target.name].minValue
    ) {
      search(page, size, obj, sortOrderBy, sortOrderDirection);
    }
    setFilter(obj);
  };

  const filterSelectChange = (e) => {
    const obj = { ...filter };
    obj[e.target.name].value = e.target.value;
    if (
      String(obj[e.target.name].value).length === 0 ||
      String(obj[e.target.name].value).length >= obj[e.target.name].minValue
    ) {
      search(page, size, obj, sortOrderBy, sortOrderDirection);
    }
    setFilter(obj);
  };

  const search = async (pg, sz, filt, sort, sortDirection) => {
    setLoading(true);
    setError(false);
    const filterObj = {...filt};
    Object.keys(filterObj).forEach(f => {
      if (filterObj[f].value === -1) {
        delete filterObj[f]
      }
    });
    const filters = convertToFilters(filterObj);
    const response = await searchMedications(
      pg,
      sz,
      filters,
      sort,
      sortDirection
    );
    if (!response?.canceled && !response?.error) {
      setMedications(response.content);
      setSize(response.paginator.perPage);
      setPage(response.paginator.currentPage);
      setTotal(response.paginator.totalItems);
      setLoading(false);
    } else if (response?.error) {
      setError(true);
      setLoading(false);
    }
  };

  const selectMedication = (medication) => {};

  const onChangeRowsPerPage = async (event) => {
    setSize(event.target.value);
    await search(
      page,
      event.target.value,
      filter,
      sortOrderBy,
      sortOrderDirection
    );
  };
  const handleChangePage = async (event, newPage) => {
    setPage(newPage + 1);
    await search(newPage + 1, size, filter, sortOrderBy, sortOrderDirection);
  };

  const clearFilters = () => {
    initFilterAndSearch()
  };

  const medicationCreated = medication => {
    const obj = [...medications, medication];
    setMedications(obj);
    setCreateMedicationModal(false);
  }

  return (
    <Paper elevation={24} className={classes.root}>
      <Grid container justify="space-between" alignItems="center">
          {canUpdate &&
          <Button onClick={() => setCreateMedicationModal(true)}
                  variant={"contained"}
                  color={"primary"}
                  startIcon={<AddIcon />}
          >
            Add
          </Button>
          }
          <CreateMedication open={openCreateMedicationModal} onClose={() => setCreateMedicationModal(false)} onSave={medicationCreated} />
          <div>
            <Button color={"primary"} onClick={clearFilters} startIcon={<i className="fas fa-ban"></i>}>
              Clear filters
            </Button>
            <IconButton color={"primary"} onClick={() => setShowFilter(true)}>
              <i className="fas fa-filter"></i>
            </IconButton>
          </div>
      </Grid>
        <Hidden smDown>
          <>
            <TablePagination
              component="div"
              rowsPerPageOptions={sizeOptions}
              colSpan={5}
              count={total ? total : 0}
              rowsPerPage={size ? size : 0}
              page={page ? page - 1 : 1}
              backIconButtonProps={{
                "aria-label": "previous page",
                disabled: page === 1 ? true : false,
              }}
              nextIconButtonProps={{
                "aria-label": "next page",
                disabled: (page) * size >= total ? true : false,
              }}
              onChangeRowsPerPage={onChangeRowsPerPage}
              onChangePage={handleChangePage} />
          <TableContainer>
            <Table
              size="small"
              style={{ width: "100%" }}
              className={classes.table}
              id="medicationtable"
            >
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeader}>
                    <SortFilterLabel label={"Name"}
                                     field={"tradeName"}
                                     filter={filter}
                                     sortOrderBy={sortOrderBy}
                                     sortDirection={sortOrderDirection}
                                     createSortHandler={createSortHandler}
                                     filterTextChange={filterTextChange} />
                  </TableCell>
                  <TableCell>
                    <SortFilterLabel label={"Generic Name"}
                                     field={"genericName"}
                                     filter={filter}
                                     sortOrderBy={sortOrderBy}
                                     sortDirection={sortOrderDirection}
                                     createSortHandler={createSortHandler}
                                     filterTextChange={filterTextChange} />
                  </TableCell>
                  <TableCell>
                    <SortFilterLabel label={"NDC Code"}
                                     field={"ndcCode"}
                                     filter={filter}
                                     sortOrderBy={sortOrderBy}
                                     sortDirection={sortOrderDirection}
                                     createSortHandler={createSortHandler}
                                     filterTextChange={filterTextChange} />
                  </TableCell>
                  <TableCell>
                    <SortFilterLabel label={"Strength"}
                                     field={"strength"}
                                     filter={filter}
                                     sortOrderBy={sortOrderBy}
                                     sortDirection={sortOrderDirection}
                                     createSortHandler={createSortHandler}
                                     filterTextChange={filterTextChange} />
                  </TableCell>
                  <TableCell>
                    <SortFilterSelectLabel label={"Unit"}
                                           field={"unit"}
                                           filter={filter}
                                           sortOrderBy={sortOrderBy}
                                           sortDirection={sortOrderDirection}
                                           createSortHandler={createSortHandler}
                                           options={filter?.unit?.dropDownItems}
                                           optionLabel={"name"}
                                           optionValue={"_id"}
                                           onChange={filterSelectChange} />
                  </TableCell>
                  <TableCell>
                    <SortFilterSelectLabel label={"Dosage Form"}
                                           field={"dosageForm"}
                                           filter={filter}
                                           sortOrderBy={sortOrderBy}
                                           sortDirection={sortOrderDirection}
                                           createSortHandler={createSortHandler}
                                           options={filter?.dosageForm?.dropDownItems}
                                           optionLabel={"name"}
                                           optionValue={"_id"}
                                           onChange={filterSelectChange} />
                  </TableCell>
                  <TableCell>
                    <SortFilterSelectLabel label={"Route"}
                                           field={"route"}
                                           filter={filter}
                                           sortOrderBy={sortOrderBy}
                                           sortDirection={sortOrderDirection}
                                           createSortHandler={createSortHandler}
                                           options={filter?.route?.dropDownItems}
                                           optionLabel={"name"}
                                           optionValue={"_id"}
                                           onChange={filterSelectChange} />
                  </TableCell>
                  <TableCell>
                    <SortFilterSelectLabel label={"Is Supply"}
                                           field={"isSupply"}
                                           filter={filter}
                                           sortOrderBy={sortOrderBy}
                                           sortDirection={sortOrderDirection}
                                           createSortHandler={createSortHandler}
                                           options={filter?.isSupply.dropDownItems}
                                           optionLabel={"name"}
                                           optionValue={"value"}
                                           onChange={filterSelectChange} />
                  </TableCell>
                  <TableCell>
                    <SortFilterSelectLabel label={"Is Narcotic"}
                                           field={"isNarcotic"}
                                           filter={filter}
                                           sortOrderBy={sortOrderBy}
                                           sortDirection={sortOrderDirection}
                                           createSortHandler={createSortHandler}
                                           options={filter?.isNarcotic.dropDownItems}
                                           optionLabel={"name"}
                                           optionValue={"value"}
                                           onChange={filterSelectChange} />
                  </TableCell>
                </TableRow>
              </TableHead>
              {!error && loading && <TableLoadingIndicator bodyOnly={true} rows={6} cols={10} /> }
              <TableBody style={{ width: "100%" }}>
                {!error && !loading && medications?.map((medication, i) => {
                    return (
                      <TableRow key={i} style={{ ...getStripedStyle(i) }}>
                        <TableCell scope="row">
                          <Button
                            size="small"
                            component={RouterLink}
                            to={`/medications/${medication._id}`}
                            onClick={() => selectMedication(medication)}
                          >
                            {medication.tradeName}
                          </Button>
                        </TableCell>
                        <TableCell>{medication.genericName}</TableCell>
                        <TableCell>{medication.ndcCode}</TableCell>
                        <TableCell>{medication.strength}</TableCell>
                        <TableCell>
                          {medication.unit ? medication.unit.name : null}
                        </TableCell>
                        <TableCell>
                          {medication.dosageForm
                            ? medication.dosageForm.name
                            : null}
                        </TableCell>
                        <TableCell>
                          {medication.route ? medication.route.name : null}
                        </TableCell>
                        <TableCell>{medication.isSupply ? "Yes" : "No"}</TableCell>
                        <TableCell>
                          {medication.isNarcotic ? "Yes" : "No"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      </Hidden>
      <Hidden smUp>
        <List>
          {medications?.map(medication =>
          <ListItem divider
                    button
                    component={RouterLink}
                    to={`/medications/${medication._id}`}

          >
            <ListItemIcon>
              <i className="fas fa-2x fa-prescription-bottle"></i>
            </ListItemIcon>
            <ListItemText primary={medication?.tradeName}
                          secondary={<>
                            <Typography variant={"subtitle1"}>
                              {medication?.route?.name ?? ''}{" "}
                              {medication?.dosageForm?.name ?? ''}{" "}
                              {medication?.strength ?? ''}{" "}
                              {medication?.unit?.name ?? ''}
                            </Typography>
                            <Typography variant={"subtitle2"}>{medication?.ndcCode}</Typography>
                          </>}
            />
          </ListItem>
          )}
        </List>
      </Hidden>
      <MobileFilterModal open={showFilter}
                         filter={filter}
                         onClose={() => setShowFilter(false)}
                         onFilterTextChange={filterTextChange}
                         onFilterSelectChange={filterSelectChange}
      />
    </Paper>
  );
};

export default MedicationList;
