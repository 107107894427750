import React from 'react';
import {ACTIONS, TimeEntryContext} from "../../../context/TimeEntryContext/time.entry.context";
import moment from "moment";
import {Button, ButtonGroup, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {ChevronLeft, ChevronRight} from "@material-ui/icons";
import {TimeEntryMobileGroups} from "./time.entry.mobile.groups";
import AddIcon from "@material-ui/icons/Add";

export const TimeEntryMobileList = () => {
    const {state, dispatch} = React.useContext(TimeEntryContext);

    const getNextWeek = () => {
        let newWeek = state?.week === 52 ? 1 : state?.week + 1
        dispatch({
            type: ACTIONS.SET_WEEK_YEAR,
            payload: {week: newWeek, year: newWeek === 1 ? state.year + 1 : state.year}
        });
    }
    const getPrevWeek = () => {
        let newWeek = state?.week === 1 ? 52 : state?.week - 1;
        dispatch({
            type: ACTIONS.SET_WEEK_YEAR,
            payload: {week: newWeek, year: newWeek === 52 ? state.year - 1 : state.year}
        });
    }

    const getCurrentWeek = () => {
        dispatch({type: ACTIONS.SET_WEEK_YEAR, payload: {week: moment().weeks(), year: moment().year()}});
    }

    const getWeekName = () => {
        if (moment().week() === state.week) {
            return "Current Week";
        } else {
            let firstDay = moment().set({years: state.year, weeks: state.week}).startOf('w');
            let lastDay = moment().set({years: state.year, weeks: state.week}).endOf('w');

            return `${firstDay?.format("MM/DD")} - ${lastDay?.format("MM/DD/YYYY")}`;
        }
    }

    const handleAddNewClick = () => {
        dispatch({type: ACTIONS.SET_VIEW_TIME_ENTRY_CREATE_MODAL, payload: true});
    }

    return (
        <div>
            <ButtonGroup fullWidth disabled={state.loading} style={{marginBottom: '.25rem'}}>
                <Button color={"primary"}
                        variant={"contained"}
                        startIcon={<ChevronLeft/>}
                        onClick={getPrevWeek}>
                    Prev Week
                </Button>
                <Button onClick={getCurrentWeek}>Current Week</Button>
                <Button color={"primary"}
                        variant={"contained"}
                        endIcon={<ChevronRight/>}
                        onClick={getNextWeek}>
                    Next Week
                </Button>
            </ButtonGroup>
            <ListItem button
                      divider
                      onClick={handleAddNewClick}
            >
                <ListItemIcon>
                    <AddIcon/>
                </ListItemIcon>
                <ListItemText primary={"Add New"}/>
            </ListItem>
            <TimeEntryMobileGroups weekName={getWeekName()}
                                   timeEntries={state?.timeEntries}/>
        </div>
    )
}
