import React from 'react';
import {
  Button, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  Hidden, IconButton,
  List,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {getAgenda} from '../../services/eschedule.service';
import CalendarToolbar from './toolbar';
import {blue, green, grey, orange, red, yellow,} from '@material-ui/core/colors';
import {GlobalNotificationContext} from '../notification/globalnotificationprovider';
import {updateResidentEmar} from '../../services/medication.order.service';
import EmarDialog from './emar/emar.dialog';
import MobileEmar from '../emar/mobile.emar';
import EscheduleRow from './rows/eschedule.row';
import MobileEtar from '../resident/treatments/tar/mobile.tar';
import EtarDialog from '../resident/treatments/tar/etar.dialog';
import {updateResidentETar} from '../../services/treatment.service';
import MobileTask from '../resident/task/mobile.task';
import {UserContext} from "../../context/usercontext";
import {Refresh, RotateLeftTwoTone, WarningTwoTone} from "@material-ui/icons";
import {ListLoading} from "../../utils/indicators/list.loading";
import {updateResidentTask} from "../../services/careplan.service";
import {NotificationsButton} from "../tenantLocation/alerts/NotificationsButton";
import {getFrequencies} from "../../services/frequency.service";

const contrast = 400;

const useStyles = makeStyles((theme) => ({
  root: {},
  header: {
    height: theme.spacing(6),
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column'
  },
  avatarContainer: {
    marginRight: theme.spacing(3),
  },
  avatarSmall: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  avatarLarge: {
    background: theme.palette.primary,
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  scheduled: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: grey[500],
  },
  readyToPass: {
    background: grey[700],
  },
  given: {
    background: green[contrast],
  },
  late: {
    background: orange[contrast],
  },
  missed: {
    background: red[contrast],
  },
  hold: {
    background: yellow[contrast],
  },
  sa: {
    background: blue[contrast],
  },
  na: {
    background: grey[contrast],
  },
  refused: {
    background: red[contrast],
  },
  destruction: {
    background: yellow[contrast],
  },
  appBar: {},
  maxHeight: {
    height: '35rem',
    overflow: 'scroll',
  },
  paginationContainer: {
    alignItems: 'center',
  },
  typeContainer: {
    padding: theme.spacing(0, 1),
  },
  errorContent: {
    padding: theme.spacing(5),
    minHeight: '38.25rem',
    maxHeight: '38.25rem',
    overflowY: 'scroll'
  },
  retryBtn: {
    marginTop: theme.spacing(2),
    alignSelf: "flex-end"
  },
  errorLogo: {
    color: theme.palette.warning.dark,
    fontSize: '2rem'
  },
  loadingContainer: {
    padding: theme.spacing(1)
  },
  skeletonBtnContainer: {
    marginLeft: theme.spacing(.5)
  },
  notificationContainer: {
    background: theme.palette.primary.main
  }
}));


// const filterTemplate = {
//   name: {
//     field: 'name',
//     minValue: 3,
//     value: null,
//     operator: '*=*',
//   },
//   description: {
//     field: 'description',
//     minValue: 3,
//     value: null,
//     operator: '*=*',
//   },
// }

const ESchedule = ({forceMaxHeight = false, showList = false, type = null}) => {
  const classes = useStyles();
  const [eventList, setEventList] = React.useState([]);
  const [showEvent, setShowEvent] = React.useState(false);
  const [showPassMeds, setShowPassMeds] = React.useState(false);
  const [selectedEvent, setSelectedEvent] = React.useState();
  const [date, setDate] = React.useState(new Date());
  const {addNotification} = React.useContext(GlobalNotificationContext);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [total, setTotal] = React.useState(0);
  const [editEventIndex, setEditEventIndex] = React.useState(-1);
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const [showTarDialog, setShowTarDialog] = React.useState(false);
  const [selectedTar, setSelectedTar] = React.useState(null);
  const [searchType, setSearchType] = React.useState('emar');
  const [locateCurrentPage, setLocateCurrentPage] = React.useState(true);
  const {selectedTenant, selectedTenantLocation, getUsers} = React.useContext(UserContext);
  const [error, setError] = React.useState(false);
  const [users, setUsers] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [frequencies, setFrequencies] = React.useState();


  React.useEffect(() => {
    if (type) {
      let e = {target: {value: type}}
      changeType(e)
    }
    // eslint-disable-next-line
  }, [type]);

  React.useEffect(() => {
    if (selectedTenantLocation && selectedTenant) {
      getEvents(new Date(), page, rowsPerPage, searchType, true);
      loadUsers();
    }
    // eslint-disable-next-line
  }, [selectedTenant, selectedTenantLocation,]);

  React.useEffect(() => {
    const getFrequencyData = async () => {
      const frequencyResponse = await getFrequencies();
      if (!frequencyResponse?.error) {
        setFrequencies(frequencyResponse);
      }
    }

    if (!frequencies || frequencies?.length < 1) {
      getFrequencyData();
    }
    // eslint-disable-next-line
  }, []);

  const loadUsers = async () => {
    const userResponse = await getUsers();
    if (!userResponse?.error) {
      setUsers(userResponse);
    }
  }

  const getEvents = async (dt, pg = 0, size = 100, type = null, locateCurrent = false, refreshCache = false) => {
    setLoading(true)
    setError(false);
    setEventList([]);
    const start = moment(dt).startOf('day').unix();
    const end = moment(dt).endOf('day').unix();
    const events = await getAgenda(start, end, pg, size, type, locateCurrent, refreshCache, selectedTenant?._id, selectedTenantLocation?._id);
    if (!events?.canceled && !events?.error) {
      if (locateCurrent) {
        let indexFound = false;
        let items = events.content;
        items = items?.map((item, index) => {
          if (!indexFound && item?.scheduledTime > moment()) {
            item.scrollTo = true;
            indexFound = true;
          }
          return item;
        });
        if (!indexFound) {
          let lastItem = items.at(-1);
          if (lastItem) {
            lastItem.scrollTo = true;
          }
        }
        setEventList(items);
      } else {
        setEventList(events.content);
      }
      setPage(parseInt(events.paginator.currentPage, 0));
      setRowsPerPage(parseInt(events.paginator.perPage, 0));
      setTotal(parseInt(events.paginator.totalItems, 0));
      setLoading(false)
    } else if (events?.error) {
      setError(true);
      setEventList([]);
      setLoading(false)
    }
  };

  const retry = async () => {
    await getEvents(date, page, rowsPerPage, searchType, locateCurrentPage);
  }

  const getDateLabel = () => {
    if (date) {
      return moment(date).format('MM/DD/YYYY');
    } else {
      return null;
    }
  };

  const onToday = async () => {
    await dateChange(new Date());
  };

  const onNext = async () => {
    const obj = moment(date).add(1, 'day');
    await dateChange(obj);
  };
  const onPrev = async () => {
    const obj = moment(date).subtract(1, 'days');
    await dateChange(obj);
  };

  const dateChange = async date => {
    setDate(date);
    setPage(0);
    setRowsPerPage(25);
    await getEvents(date, 0, 10, searchType, false);
  }

  const updateEmar = async (event) => {
    const val = await updateResidentEmar(event.resident._id, event._id, event);
    const obj = eventList.map((e) => {
      if (e._id === event._id) {
        event.administeredTime = val.administeredTime;
        event.initials = val.administeredUser
            ? val.administeredUser.initials
            : null;
        event.administeredUser = val.administeredUser;
        return event;
      } else {
        return e;
      }
    });
    addNotification('Event Status updated', 'success');
    setEventList(obj);
    setShowPassMeds(false);
  };

  const handleChangePage = async (event, newPage) => {
    setLocateCurrentPage(false);
    setPage(parseInt(newPage, 0));
    await getEvents(date, newPage, rowsPerPage, searchType, false);
  };

  const handleChangeRowsPerPage = async (event) => {
    setLocateCurrentPage(false);
    const size = parseInt(event.target.value, 10);
    setRowsPerPage(size);
    setPage(0);
    await getEvents(date, 0, size, searchType, false);
  };

  const selectEditEvent = (event, index) => {
    setEditEventIndex(index);
  };

  const eventSelected = (event, index) => {
    setSelectedIndex(index);
    setSelectedEvent(event);
    setShowPassMeds(true);
  };

  const updateRow = (e) => {
    const obj = eventList.map((event) => (e._id === event._id ? e : event));
    setEventList(obj);
    setEditEventIndex(-1);
    addNotification('Event Status updated', 'success');
  };

  const onTarSelected = (e) => {
    setSelectedTar(e);
    setShowTarDialog(true);
  };


  const onTarUpdated = async (e) => {
    await updateResidentETar(e.resident._id, e.treatment, e._id, e);
    const obj = eventList.map((event) => {
      if (event._id === e._id) {
        e.initials = e.administeredUser ? e.administeredUser.initials : null;
        return e;
      } else {
        return event;
      }
    });
    setEventList(obj);
    setShowTarDialog(false);
  };

  const changeType = async (e) => {
    setSearchType(e.target.value);
    await getEvents(date, 0, 25, e.target.value, false);
  };

  const onEmarQuickUpdate = async (emar, status) => {
    emar.status.name = status;
    const response = await updateResidentEmar(emar?.medicationOrder?.resident, emar._id, emar);
    if (response) {
      const obj = eventList.map(e => {
        if (e?._id === emar?._id) {
          emar.initials = response?.administeredUser ? response?.administeredUser?.initials: null;
          emar.administeredUser = response?.administeredUser;
          emar.administeredTimestamp = response?.administeredTimestamp;
          return emar;
        } else {
          return e;
        }
      });
      setEventList(obj);
    }
  }

  const onETarQuickUpdate = async (etar, status) => {
    if (etar?.resident && etar?.status) {
      const data = {...etar};
      data.status.name = status;
      const response = await updateResidentETar(etar?.resident?._id, data?.treatment, data._id, data);
      if (!response?.error) {
        const obj = eventList.map((event) => {
          if (event._id === etar?._id) {
            event.initials = response?.administeredUser?.initials;
            event.administeredUser = response?.administeredUser;
            event.administeredTime = response?.administeredTime;
          }
          return event;
        });
        setEventList(obj);
      }
    }
  }

  const onQuickTaskUpdate = async (entity, status) => {
    entity.status = status;
    await updateResidentTask(entity?.resident?._id, entity?._id, entity);
  }

  let focusSet = false;

  function focusOnMe(event) {
    if (!focusSet && moment(event?.scheduledTime) > moment()) {
      focusSet = true;
      return true;
    } else {
      return false;
    }
  }

  const handelRefresh = async () => {
    await getEvents(date, page, rowsPerPage, searchType, locateCurrentPage, true);
  }



  return (
      <Paper elevation={24} className={classes.root}>
        <Grid container justifyContent={"space-between"} style={{padding: '.5vh'}}>
          <Typography variant="h6">
            eSchedule - Daily Assignments
          </Typography>
          <div className={classes.notificationContainer}>
            <NotificationsButton />
          </div>
        </Grid>
        <Grid container
              justifyContent={"space-between"}
        >
          <IconButton size={"small"}
                      disabled={loading}
                      onClick={handelRefresh}
          >
            {!loading ? <Refresh />: <CircularProgress size={20} />}
          </IconButton>
          <CalendarToolbar
              label={getDateLabel()}
              onToday={onToday}
              onNext={onNext}
              onPrev={onPrev}
          />
        </Grid>
        <Divider/>
        {loading && !error && <ListLoading rows={5}/>}
        {!loading && !error &&
            <>
              <div className={classes.paginationContainer}>
                <TablePagination
                    component="div"
                    count={total}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[1, 5, 10, 25, 50, 100]}
                />
                <FormControl size="small" fullWidth className={classes.typeContainer}>
                  <Select
                      name="type"
                      displayEmpty
                      placeholder="Type"
                      value={searchType}
                      onChange={changeType}
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value={'emar'}>eMar</MenuItem>
                    <MenuItem value={'etar'}>eTar</MenuItem>
                    <MenuItem value={'etask'}>eTask</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <React.Fragment>
                {showList && (
                    <List className={classes.maxHeight}>
                      {eventList?.map((event, i) => (
                          <div key={event?._id}>
                            {event.type === 'emar' && (
                                <MobileEmar
                                    key={event?._id}
                                    emar={event}
                                    frequencies={frequencies}
                                    onSelected={eventSelected}
                                    isSelected={selectedIndex === i}
                                    quickUpdate={onEmarQuickUpdate}
                                />
                            )}
                            {event.type === 'tar' && (
                                <MobileEtar etar={event}
                                            key={event?._id}
                                            onSelected={onTarSelected}
                                            onQuickUpdate={onETarQuickUpdate}
                                />
                            )}
                            {event.type === 'careplan_task' && (
                                <MobileTask task={event}
                                            key={event?._id}
                                            onTaskUpdated={updateRow}
                                            users={users}
                                            onQuickUpdate={onQuickTaskUpdate} />
                            )}
                            <Divider/>
                          </div>
                      ))}
                    </List>)
                }
                {!showList && (
                    <>
                      <Hidden mdDown>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableCell>Type</TableCell>
                              <TableCell width={100}>Time</TableCell>
                              <TableCell>Resident</TableCell>
                              <TableCell width={400}>Description</TableCell>
                              <TableCell>Administered</TableCell>
                              <TableCell/>
                              <TableCell>Qty</TableCell>
                              <TableCell>Status</TableCell>
                              <TableCell>Note</TableCell>
                            </TableHead>
                            <TableBody>
                              {eventList &&
                                  eventList.map((event, i) => (
                                      <EscheduleRow
                                          event={event}
                                          index={i}
                                          isEdit={i === editEventIndex}
                                          cancelEdit={() => setEditEventIndex(-1)}
                                          selectEditEvent={selectEditEvent}
                                          onUpdate={updateRow}
                                      />
                                  ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Hidden>
                      <Hidden lgUp>
                        <div style={
                          {
                            height: '60vh',
                            paddingBottom: '1vh',
                            overflow: 'hidden',
                            overflowY: 'scroll'
                          }}>
                          <List>
                            {eventList &&
                                eventList.map((event, i) => (
                                    <React.Fragment>
                                      {event.type === 'emar' && (
                                          <MobileEmar
                                              emar={event}
                                              onSelected={eventSelected}
                                              isSelected={selectedIndex === i}
                                              quickUpdate={onEmarQuickUpdate}
                                              autoFocus={() => focusOnMe(event)}
                                          />
                                      )}
                                      {event.type === 'tar' && (
                                          <MobileEtar etar={event}
                                                      onSelected={onTarSelected}
                                                      onQuickUpdate={onETarQuickUpdate}
                                          />
                                      )}
                                      {event.type === 'careplan_task' && (
                                          <MobileTask task={event} onTaskUpdated={updateRow}/>
                                      )}
                                      <Divider/>
                                    </React.Fragment>
                                ))}
                          </List>

                        </div>
                      </Hidden>
                    </>
                )}
              </React.Fragment>

              <Dialog open={showEvent}>
                <DialogTitle>
                  {selectedEvent
                      ? `${selectedEvent.residentFirstName} ${selectedEvent.residentLastName}`
                      : null}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {selectedEvent ? selectedEvent.medicationName : null}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setShowEvent(false)}>Cancel</Button>
                  <Button>View</Button>
                </DialogActions>
              </Dialog>

              <EmarDialog
                  open={showPassMeds}
                  model={selectedEvent}
                  onClose={() => setShowPassMeds(false)}
                  onSave={updateEmar}
              />
              <EtarDialog
                  open={showTarDialog}
                  onClose={() => setShowTarDialog(false)}
                  etar={selectedTar}
                  onSave={onTarUpdated}
              />
            </>
        }
        {error &&
            <div className={classes.errorContent}>
              <Grid container
                    alignItems={"center"}
                    justify={"center"}>
                <WarningTwoTone className={classes.errorLogo}/>
                <Typography variant={"subtitle2"}>Whoops, something went wrong. Please retry to view eSchedule</Typography>
              </Grid>
              <Grid container
                    justify={"flex-end"}
                    className={classes.errorBtnContainer}
              >
                <Button color={"primary"}
                        className={classes.retryBtn}
                        variant={"contained"}
                        onClick={retry}
                        startIcon={<RotateLeftTwoTone/>}>Retry</Button>
              </Grid>
            </div>
        }
      </Paper>
  );
};


export default ESchedule;
