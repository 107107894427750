import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {StatusModel} from "./StatusModel";
import {FormFieldsGenerator, isModelValid} from "../../../../utils/forms";
import SaveIcon from "@material-ui/icons/SaveTwoTone";




export const CreateStatusModal = ({open, onSave, onCancel}) => {
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(StatusModel)))

  React.useEffect(() => {
    setModel(JSON.parse(JSON.stringify(StatusModel)));
  }, [open])

  const handleChange = e => {
    setModel({...model, ...e})
  }

  const handleSave = () => {
    let data = {}
    Object.keys(model).forEach(key => {
      data[key] = model[key].value;
    });
    onSave(data);
  }

  return (
    <Dialog open={open}
            onClose={onCancel}
            fullWidth
            maxWidth={"lg"}
    >
      <DialogTitle>
        Add Status
      </DialogTitle>
      <DialogContent dividers>
        <FormFieldsGenerator model={model} onChange={handleChange} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button variant={"contained"}
                startIcon={<SaveIcon />}
                color={"primary"}
                disabled={!isModelValid(model)}
                onClick={() => handleSave()}>Add</Button>
      </DialogActions>
    </Dialog>
  )
}
