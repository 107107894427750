import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {TransferList} from "./transferlist";

const useStyles = makeStyles((theme) => ({
  root: {
  },
  dialogContent: {
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));


export const RolePermissionModal = ({open, onClose, role, permissions, onSave}) => {
  const classes = useStyles();

  return (
    <Dialog open={open}
            onClose={onClose}
            fullWidth
            maxWidth={"lg"}
            >
      <DialogTitle
        onClose={() => onClose()}>
        {'Role Permissions'}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <TransferList choices={permissions}
                      role={role}
                      onSave={onSave}
        />
      </DialogContent>
      <DialogActions>
        <Button variant={"text"}
                onClick={onClose}
        >Close</Button>
      </DialogActions>
    </Dialog>
  )
}
