import React from 'react';
import FormFieldsGenerator from '../../utils/forms/form.fields.generator';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import MedicalProviderLookup from '../medicalProviders/medical.providers.lookup';
import { Link as RouterLink } from 'react-router-dom';
import modelTemplate from './physician.model';
import * as physicianService from '../../services/physician.service';
import {
  Paper,
  Typography,
  Grid,
  makeStyles,
  Button,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableCell,
  DialogActions,
  Link,
  TableBody,
  TextField,
  Select,
  MenuItem,
} from '@material-ui/core';
import history from '../../utils/history';
import CreateAddress from '../address/create.address';
import StateDropdown from '../utils/dropdowns/state.dropdown';
import { getAllAddressTypes } from '../../services/address.service';
import { isModelValid } from '../../utils/forms/form.validator';
import { getGenders } from '../../services/gender.service';
import EntityPhoneList from "../phone/entity.phone.list";
import {GlobalNotificationContext} from "../notification/globalnotificationprovider";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  icon: {
    marginRight: '.5rem',
  },
  backBtn: {
    marginRight: '.5rem',
  },
  gridContainer: {
    padding: theme.spacing(1),
  },
}));

const Physician = ({ match }) => {
  const classes = useStyles();
  const [physician, setPhysician] = React.useState(null);
  const [addressTypes, setAddressTypes] = React.useState([]);
  const [genders, setGenders] = React.useState([]);
  const [createAddressModal, setCreateAddressModal] = React.useState(false);
  const [editAddress, setEditAddress] = React.useState(null);
  const [editAddressKey, setEditAddressKey] = React.useState(-1);
  const { addNotification } = React.useContext(GlobalNotificationContext)

  const [model, setModel] = React.useState(
    JSON.parse(JSON.stringify(modelTemplate))
  );
  const [providerLookupModal, setProviderLookupModal] = React.useState(false);

  React.useEffect(() => {
    if (match.params.id) {
      merge(match.params.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]);

  const merge = async (physicianId) => {
    const physician = await physicianService.getPhysician(physicianId);
    if (!addressTypes || addressTypes.length === 0) {
      const types = await getAllAddressTypes();
      setAddressTypes(types);
    }
    setPhysician(physician);
    const obj = { ...model };
    obj.title.value = physician.title;
    obj.specialty.value = physician.specialty;
    obj.firstName.value = physician.firstName;
    obj.middleName.value = physician.middleName;
    obj.lastName.value = physician.lastName;
    obj.ssn.value = physician.ssn;
    obj.email.value = physician.email;
    obj.gender.value = physician.gender;
    obj.npi.value = physician.npi;
    obj.upin.value = physician.upin;
    if (!genders || genders.length === 0) {
      const genderResponse = await getGenders();
      setGenders(genderResponse);
      obj.gender.dropDownItems = genderResponse;
    } else {
      obj.gender.dropDownItems = genders;
    }
    setModel(obj);
  };

  const onChange = (e) => {
    const obj = { ...model, ...e };
    setModel(obj);
  };

  const onAddressSave = async (address) => {
    const data = {
      address: address.address,
      address2: address.address2,
      city: address.city,
      state: address.state,
      zipCode: address.zipCode,
      addressType: address.addressType,
    };
    const response = await physicianService.createPhysicianAddress(
      physician._id,
      data
    );
    const obj = { ...physician };
    obj.addresses = [...obj.addresses, response];
    setPhysician(obj);
    setCreateAddressModal(false);
  };

  const onDeleteAddress = async (address, key) => {
    await physicianService.deletePhysicianAddress(physician._id, address._id);
    const obj = { ...physician };
    obj.addresses = obj.addresses.filter((addr) => addr._id !== address._id);
    setPhysician(obj);
    setEditAddress(null);
    setEditAddressKey(-1);
  };

  const onEditAddress = (address, key) => {
    setEditAddress(address);
    setEditAddressKey(key);
  };

  const onEditAddressTypeChange = (e) => {
    const obj = { ...editAddress };
    obj.addressType = e.target.value;
    setEditAddress(obj);
  };

  const onEditAddressChange = (e) => {
    const obj = { ...editAddress };
    obj[e.target.name] = e.target.value;
    setEditAddress(obj);
  };

  const updateAddress = async () => {
    const data = {
      _id: editAddress._id,
      address: editAddress.address,
      address2: editAddress.address2,
      city: editAddress.city,
      state: editAddress.state,
      zipCode: editAddress.zipCode,
      addressType: editAddress.addressType,
    };
    const response = await physicianService.updatePhysicianAddress(
      physician._id,
      editAddress._id,
      data
    );
    const obj = { ...physician };
    obj.addresses = obj.addresses.map((address) => {
      if (address._id === response._id) {
        return response;
      } else {
        return address;
      }
    });
    setPhysician(obj);
    setEditAddressKey(-1);
    addNotification('Address updated', 'success');
  };

  const onProvderSelect = async (medicalProvider) => {
    const response = await physicianService.createPhysicianMedicalProvider(
      physician._id,
      medicalProvider
    );
    const obj = { ...physician };
    obj.medicalProviders = [...obj.medicalProviders, response];
    setPhysician(obj);
    setProviderLookupModal(false);
    addNotification('Medical provider added', 'success');
  };

  const removeMedicalProvider = async (medicalProvider) => {
    const result = await physicianService.deletePhysicianMedicalProvider(
      physician._id,
      medicalProvider._id
    );
    if (result) {
      const obj = { ...physician };
      obj.medicalProviders = obj.medicalProviders.filter(
        (provider) => provider._id !== medicalProvider._id
      );
      setPhysician(obj);
    }
    addNotification('Medical provider deleted', 'success');
  };

  const updatePhysician = async () => {
    const data = {
      _id: physician._id,
      title: model.title.value,
      specialty: model.specialty.value,
      firstName: model.firstName.value,
      middleName: model.middleName.value,
      lastName: model.lastName.value,
      ssn: model.ssn.value,
      email: model.email.value,
      gender: model.gender.value,
      npi: model.npi.value,
      upin: model.upin.value,
    };
    const response = await physicianService.updatePhysician(
      physician._id,
      data
    );
    setPhysician(response);
    addNotification('Physician updated', 'success');
  };

  const createPhone = async phone => {
    const response = await physicianService.createPhysicianPhone(physician._id, phone);
    const obj = {...physician}
    obj.phones = [...obj.phones, response];
    setPhysician(obj);
    addNotification('Phone created', 'success');
  }

  const deletePhone = async phone => {
    await physicianService.deletePhysicianPhone(physician._id, phone?._id);
    const obj = {...physician}
    obj.phones = obj.phones.filter(ph => ph._id !== phone._id);
    setPhysician(obj);
    addNotification('Phone deleted', 'success');
  }

  const updatePhone = async phone => {
    await physicianService.updatePhysicianPhone(physician._id, phone?._id, phone);
    const obj = {...physician}
    obj.phones = obj.phones.map(ph => ph._id === phone._id ? phone: ph);
    setPhysician(obj);
    addNotification('Phone updated', 'success');
  }

  return (
    <Paper elevation={24} className={classes.root}>
      <Grid container>
        <Grid item md={6} xs={12}>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            className={classes.gridContainer}
          >
            <Button
              onClick={() => history.goBack()}
              className={classes.backBtn}
              startIcon={<NavigateBeforeIcon />}
            >
              Back
            </Button>
            <Typography variant="h5">
              Physician {physician?.title} {physician?.firstName} {physician?.lastName}
            </Typography>
          </Grid>
          <Grid className={classes.gridContainer}>
            <form>
              <FormFieldsGenerator model={model} onChange={onChange} />
              <DialogActions>
                <Link>Cancel</Link>
                <Button
                  disabled={!isModelValid(model)}
                  variant="contained"
                  color="primary"
                  onClick={updatePhysician}
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
          <div style={{ width: '100%' }}>
            <Grid
              container
              alignItems="center"
              className={classes.gridContainer}
            >
              <MyLocationIcon
                color="primary"
                style={{ marginRight: '.5rem' }}
              />
              <Typography variant="subtitle2">Address</Typography>
            </Grid>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableCell>
                    <Button onClick={() => setCreateAddressModal(true)}>
                      Add
                    </Button>
                    <CreateAddress
                      open={createAddressModal}
                      onSave={onAddressSave}
                      onClose={() => setCreateAddressModal(false)}
                    />
                  </TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Address 2</TableCell>
                  <TableCell>City</TableCell>
                  <TableCell>State</TableCell>
                  <TableCell>Zip</TableCell>
                </TableHead>
                <TableBody>
                  {physician?.addresses?.map((address, key) => (
                      <TableRow key={key}>
                        {editAddressKey === key && (
                          <React.Fragment>
                            <TableCell style={{ display: 'flex' }}>
                              <Button
                                size="small"
                                onClick={() => setEditAddressKey(-1)}
                              >
                                Cancel
                              </Button>
                              <Button
                                size="small"
                                onClick={() => updateAddress()}
                                variant="contained"
                                color="primary"
                              >
                                Save
                              </Button>
                            </TableCell>
                            <TableCell style={{ padding: 0 }}>
                              <Select
                                labelId="addressType"
                                name="addressType"
                                value={editAddress?.addressType || ''}
                                onChange={onEditAddressTypeChange}
                              >
                                {addressTypes?.map(type =>
                                    <MenuItem value={type.name}>
                                      {type.name}
                                    </MenuItem>
                                )}
                              </Select>
                            </TableCell>
                            <TableCell>
                              <TextField
                                name="address"
                                value={editAddress.address}
                                fullWidth
                                onChange={onEditAddressChange}
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                name="address2"
                                value={editAddress.address2}
                                fullWidth
                                onChange={onEditAddressChange}
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                name="city"
                                fullWidth
                                onChange={onEditAddressChange}
                                value={editAddress.city}
                              />
                            </TableCell>
                            <TableCell>
                              <StateDropdown
                                name="state"
                                fullWidth
                                value={editAddress.state}
                                onChange={onEditAddressChange}
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                name="zipCode"
                                onChange={onEditAddressChange}
                                fullWidth
                                value={editAddress.zipCode}
                              />
                            </TableCell>
                          </React.Fragment>
                        )}
                        {editAddressKey !== key && (
                          <React.Fragment>
                            <TableCell>
                              <Button
                                size="small"
                                onClick={() => onEditAddress(address, key)}
                              >
                                Edit
                              </Button>
                              <Button
                                size="small"
                                variant="contained"
                                color="error"
                                onClick={() => onDeleteAddress(address, key)}
                              >
                                Delete
                              </Button>
                            </TableCell>
                            <TableCell>
                              {address?.addressType}
                            </TableCell>
                            <TableCell>{address.address}</TableCell>
                            <TableCell>{address.address2}</TableCell>
                            <TableCell>{address.city}</TableCell>
                            <TableCell>{address.state}</TableCell>
                            <TableCell>{address.zipCode}</TableCell>
                          </React.Fragment>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div style={{ width: '100%', marginTop: '2rem' }}>
            <Grid
              container
              alignItems="center"
              className={classes.gridContainer}
            >
              <LocalHospitalIcon
                color="primary"
                style={{ marginRight: '.5rem' }}
              />
              <Typography variant="subtitle2">Providers</Typography>
            </Grid>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableCell>
                    <Button onClick={() => setProviderLookupModal(true)}>
                      Add
                    </Button>
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Address</TableCell>
                  <MedicalProviderLookup
                    open={providerLookupModal}
                    onClose={() => setProviderLookupModal(false)}
                    onSelect={onProvderSelect}
                  />
                </TableHead>
                <TableBody>
                  {physician &&
                    physician.medicalProviders &&
                    physician.medicalProviders.map((provider, key) => (
                      <TableRow>
                        <TableCell>
                          <Button
                            size="small"
                            component={RouterLink}
                            to={`/medicalproviders/${provider._id}`}
                          >
                            View
                          </Button>
                          <Button
                            size="small"
                            onClick={() => removeMedicalProvider(provider)}
                          >
                            Remove
                          </Button>
                        </TableCell>
                        <TableCell>{provider.name}</TableCell>
                        <TableCell>
                          {provider.address && (
                            <span>
                              {provider.address.address}{' '}
                              {provider.address.address2}{' '}
                              {provider.address.city} {provider.address.state},{' '}
                              {provider.address.zipCode}
                            </span>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div>
            <EntityPhoneList phones={physician?.phones}
                             createPhone={createPhone}
                             deletePhone={deletePhone}
                             updatePhone={updatePhone}
            />
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Physician;
