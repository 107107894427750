import React from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import {getStripedStyle} from "../../components/utils/table.utils";

const TableLoadingIndicator = ({cols = 5, rows = 10, bodyOnly = false}) => {

  if (!bodyOnly) {
    return (
      <TableContainer>
        <Table size={"small"}>
          <TableHead>
            <TableRow>
              {[...Array(cols)].map(i =>
                <TableCell>
                  <Skeleton height={40}/>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {[...Array(rows)].map((i, index) =>
              <TableRow style={getStripedStyle(index)}>
                {[...Array(cols)].map(i =>
                  <TableCell>
                    <Skeleton height={20}/>
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    )
  } else {
    return (
      <TableBody>
        {[...Array(rows)].map((i, index) =>
          <TableRow style={getStripedStyle(index)}>
            {[...Array(cols)].map(i =>
              <TableCell>
                <Skeleton height={20}/>
              </TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    )
  }
}

export default TableLoadingIndicator;
