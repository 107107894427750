import React from "react";
import {
  getResidentAddresses,
  updateResidentAddress,
  createResidentAddress,
  deleteResidentAddress,
} from "../../../services/resident.service";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import AddIcon from "@material-ui/icons/Add";
import {
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TableContainer,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import CreateAddress from "../../address/create.address";
import EditAddress from "../../address/edit.address";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import RadioButtonUncheckedRoundedIcon from "@material-ui/icons/RadioButtonUncheckedRounded";
import useHasRole from "../../../hooks/user.role.hook";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  table: {
    width: "100%",
  },
  iconButton: {
    padding: 0,
    margin: 0,
  },
  actionIcon: {
    height: "1.5rem",
    width: "1.5rem",
  },
}));

const ResidentAddresses = ({ residentId, token }) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [openCreateAddress, setOpenCreateAddress] = React.useState(false);
  const [residentAddresses, setResidentAddresses] = React.useState([]);
  const [openEditAddress, setOpenEditAddress] = React.useState(false);
  const [selectedResidentAddress, setSelectedResidentAddress] = React.useState(
    null
  );
  const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);
  const { checkPermission } = useHasRole();
  const canEdit = checkPermission('RESIDENT_UPDATE');

  React.useEffect(() => {
    const getAddresses = async () => {
      setLoading(true);
      const phones = await getResidentAddresses(token, residentId);
      setResidentAddresses(phones);
      setLoading(false);
    };
    if (residentId) {
      getAddresses();
    } else {
      setResidentAddresses([]);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [residentId]);

  const editResidentAddress = (residentAddress) => {
    setSelectedResidentAddress(residentAddress);
    setOpenEditAddress(true);
  };
  const openConfirmDeleteDialog = (residentAddress) => {
    setSelectedResidentAddress(residentAddress);
    setOpenConfirmDelete(true);
  };
  const confirmDelete = async () => {
    selectedResidentAddress.isActive = false;
    await deleteResidentAddress(residentId, selectedResidentAddress._id);
    const obj = residentAddresses.filter(
      (residentAddress) => residentAddress._id !== selectedResidentAddress._id
    );
    setResidentAddresses(obj);
    setOpenConfirmDelete(false);
  };

  const saveResidentAddress = async (address) => {
    const response = await createResidentAddress(residentId, address);
    const obj = [...residentAddresses, response];
    setResidentAddresses(obj);
    setOpenCreateAddress(false);
  };

  const updateAddress = async (address) => {
    const updated = await updateResidentAddress(
      residentId,
      address._id,
      address.isCurrent,
      address
    );
    const obj = residentAddresses.map((residentAddress) =>
      residentAddress._id === updated._id ? updated : residentAddress
    );
    setResidentAddresses(obj);
    setOpenEditAddress(false);
  };

  const toggleCurrent = async (residentAddress) => {
    residentAddress.isCurrent = !residentAddress.isCurrent;
    await updateResidentAddress(
      residentId,
      residentAddress._id,
      residentAddress.isCurrent,
      residentAddress
    );
    const obj = residentAddresses.map((ra) => {
      if (residentAddress._id === ra._id) {
        return residentAddress;
      } else {
        return ra;
      }
    });
    setResidentAddresses(obj);
    setOpenEditAddress(false);
  };

  return (
    <div className={classes.root}>
      <div hidden={!loading}>
        <Skeleton variant="text" height={40} />
        <Skeleton variant="text" height={40} />
        <Skeleton variant="text" height={40} />
        <Skeleton variant="text" height={40} />
      </div>
      <div hidden={loading}>
        <Typography variant="subtitle1">Address</Typography>
        {canEdit &&
        <Button
          startIcon={<AddIcon/>}
          onClick={() => setOpenCreateAddress(true)}
        >
          Add Address
        </Button>
        }
        <TableContainer>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Address</TableCell>
                <TableCell>Address 2</TableCell>
                <TableCell>City</TableCell>
                <TableCell>State</TableCell>
                <TableCell>Zip</TableCell>
                <TableCell>Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {residentAddresses?.map((residentAddress) => (
                  <TableRow>
                    <TableCell>
                      <IconButton
                        className={classes.iconButton}
                        onClick={() => editResidentAddress(residentAddress)}
                      >
                        <VisibilityRoundedIcon className={classes.actionIcon} />
                      </IconButton>
                      {canEdit &&
                      <>
                        <IconButton
                          className={classes.iconButton}
                          onClick={() => openConfirmDeleteDialog(residentAddress)}
                        >
                          <DeleteRoundedIcon color="error" />
                        </IconButton>
                        <IconButton
                          className={classes.iconButton}
                          aria-label="Toggle is current"
                          onClick={() => toggleCurrent(residentAddress)}
                        >
                          {residentAddress.isCurrent ? (
                            <CheckCircleRoundedIcon
                              className={classes.actionIcon}
                              color="secondary"
                            />
                          ) : (
                            <RadioButtonUncheckedRoundedIcon />
                          )}
                          <Typography variant="subtitle2">Current</Typography>
                        </IconButton>
                      </>
                      }
                    </TableCell>
                    <TableCell>{residentAddress.address}</TableCell>
                    <TableCell>{residentAddress.address2}</TableCell>
                    <TableCell>{residentAddress.city}</TableCell>
                    <TableCell>{residentAddress.state}</TableCell>
                    <TableCell>{residentAddress.zipCode}</TableCell>
                    <TableCell>{residentAddress.addressType}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog open={openConfirmDelete}>
          <DialogTitle id="alert-dialog-title">
            {"Delete Resident Address?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please confirm that you want to delete this resident's address.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenConfirmDelete(false)}>Cancel</Button>
            <Button color="primary" onClick={() => confirmDelete()}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <CreateAddress
          token={token}
          open={openCreateAddress}
          onClose={() => setOpenCreateAddress(false)}
          onSave={saveResidentAddress}
        />
        {selectedResidentAddress && (
          <EditAddress
            open={openEditAddress}
            address={selectedResidentAddress}
            onUpdate={updateAddress}
            onClose={() => setOpenEditAddress(false)}
            canEdit={canEdit}
          />
        )}
      </div>
    </div>
  );
};

export default ResidentAddresses;
