import React from 'react';
import moment from 'moment';
import {
  Avatar, Button, CircularProgress,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import history from '../../../../utils/history';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import CancelIcon from '@material-ui/icons/Cancel';
import {updateAlert} from "../../../../services/resident.service";
import {RotateLeftTwoTone} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {},
  alert: {
    cursor: 'pointer',
  },
  notDismissed: {
    background: orange[400],
  },
}));

const LateTaskAlert = ({ alert, onViewEntity, onDismissedChange, showResidentName = false  }) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const onView = () => {
    onViewEntity();
    history.push(`/residents/${alert.resident}/tasks`);
  };


  const onDismissedToggle = async () => {
    setError(false);
    setLoading(true);
    const residentId = alert?.resident;
    const alertId = alert?._id;
    const dismissed = !alert?.dismissed;
    const result = await updateAlert(residentId, alertId, {dismissed});
    if (!result?.error) {
      onDismissedChange(result)
    } else {
      setError(true);
    }
    setLoading(false);
  }

  if (loading) {
    return (
      <ListItem className={classes.root}
                button
      >
        <ListItemAvatar>
          <CircularProgress />
        </ListItemAvatar>
        <ListItemText primary={"Processing...."} />
      </ListItem>
    )
  }

  if (!loading && error) {
    return (
      <ListItem className={classes.root}
                button
      >
        <ListItemAvatar>
          <Avatar
            className={[
              !alert?.dismissed ? classes.notDismissed : null,
            ]}
          >
            <i className="fas fa-radiation"></i>
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={"Something went wrong..."} secondary={"Please click retry"} />
        <ListItemSecondaryAction>
          <Button startIcon={<RotateLeftTwoTone />}
                  onClick={onDismissedToggle}
                  color={"primary"}
                  variant={"contained"}
          >
            Retry
          </Button>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }

  return (
    <ListItem divider button onClick={onView}>
      <ListItemAvatar>
        <Avatar className={[!alert.dismissed ? classes.notDismissed : null]}>
          <i class="fas fa-tasks"></i>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={`Late Task ${showResidentName ? `- ${alert?.residentData?.firstName} ${alert?.residentData?.lastName}`: ''}`}
        secondary={
          <>
            {alert && (
              <div>
                {alert.residentData && (
                  <Typography variant="subtitle1">
                    {alert.residentData.firstName} {alert.residentData.lastName}
                  </Typography>
                )}
                {alert.fkEntity && alert.fkEntity.details && (
                  <>
                    <Typography variant="body">
                      {alert.fkEntity.details.careplan}
                    </Typography>
                    {', '}
                    <Typography variant="body">
                      {alert.fkEntity.details.goal}
                    </Typography>
                    {', '}
                    <Typography variant="body">
                      {alert.fkEntity.details.intervention}
                    </Typography>
                  </>
                )}
                {alert.scheduledTime && (
                  <Typography variant="subtitle1">
                    {' '}
                    {moment(alert.scheduledTime).format('MM/DD/YYYY h:mm A')}
                  </Typography>
                )}
              </div>
            )}
          </>
        }
      />
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          aria-label="comments"
          onClick={onDismissedToggle}
        >
          {!alert?.dismissed && <CancelIcon/>}
          {alert?.dismissed && <SettingsBackupRestoreIcon/>}
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default LateTaskAlert;
