import axios from 'axios';

class ApiService {
    client = null;
    token = null;
    tenantId = null;
    locationId = null;

    constructor() {
        this.client = axios.create();
        this.client.defaults.timeout = 36000000; //10min

    }

    getCancelToken = () => {
      return axios.CancelToken.source();
    }

    isCancel = error => {
      return axios.isCancel(error);
    }

    getToken = () => {
        return this.token;
    }
    authorizationHandler = (request) => {
        request.headers['Authorization'] = `Bearer ${this.token}`;
        return request;
    }
    tenantHander = (request) => {
      request.headers['tenant'] = this.tenantId ?? 'NA';
      return request;
    }
    locationHander = (request) => {
      request.headers['location'] = this.locationId ?? 'NA';
      return request;
    }
    setToken = token => {
        this.token = token;
        this.client.interceptors.request.use(request => this.authorizationHandler(request));
    }
    setTenant = tenantId => {
      this.tenantId = tenantId;
      this.client.interceptors.request.use(request => this.tenantHander(request));
    }
    setLocation = locationId => {
      this.locationId = locationId;
      this.client.interceptors.request.use(request => this.locationHander(request));
    }
};
const service = new ApiService();
export default service;
