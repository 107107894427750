const filterTemplate = {
  dosageForm: {
    label: 'Dosage',
    field: "dosageForm",
    operator: "==",
    value: '',
    minValue: 1,
    sort: 6,
    dropDownItems: [],
    dropDownItemLabel: 'name',
    dropDownItemValue: '_id',
  },
  route: {
    label: 'Route',
    field: "route",
    operator: "==",
    value: '',
    minValue: 1,
    sort: 7,
    dropDownItems: [],
    dropDownItemLabel: 'name',
    dropDownItemValue: '_id',
  },
  unit: {
    label: 'Unit',
    field: "unit",
    operator: "==",
    value: '',
    minValue: 1,
    sort: 5,
    dropDownItems: [],
    dropDownItemLabel: 'name',
    dropDownItemValue: '_id',
  },
  isSupply: {
    label: 'Is Supply',
    field: "isSupply",
    operator: "==",
    value: '',
    minValue: 1,
    dropDownItems: [
      {name: 'Yes', value: true},
      {name: 'No', value: false},
    ],
    dropDownItemLabel: 'name',
    dropDownItemValue: 'value',
    sort: 8
  },
  isNarcotic: {
    label: 'Is Narcotic',
    field: "isNarcotic",
    operator: "==",
    value: '',
    minValue: 1,
    dropDownItems: [
      {name: 'Yes', value: true},
      {name: 'No', value: false},
    ],
    dropDownItemLabel: 'name',
    dropDownItemValue: 'value',
    sort: 9
  },
  tradeName: {
    label: 'Name',
    field: "tradeName", operator: "*=*", value: null, minValue: 2, sort: 1 },
  genericName: {
    label: 'Generic Name',
    field: "genericName",
    operator: "*=*",
    value: null,
    minValue: 2,
    sort: 2
  },
  ndcCode: {
    label: 'NDC Code',
    field: "ndcCode", operator: "*=*", value: null, minValue: 2,
    sort: 3
  },
  strength: {
    label: 'Strength',
    field: "strength", operator: "*=*", value: null, minValue: 0,
    sort: 4
  },
};

export default filterTemplate;
