
const addQuestionToSectionInAssessment = (assessment, section, question) => {
  if (question) {
    const obj = {...assessment};
    obj.questionGroups = obj.questionGroups.map(qg => {
      if (qg._id === section._id) {
        if (!qg.questions) {
          qg.questions = [];
        }
        qg.questions = [...qg.questions, question];
      }
      return qg;
    });
    return obj;
  } else {
    return assessment;
  }
}

const findAndUpdateQuestionInAssessment = (assessment, question) => {
  if (assessment && question) {
    const obj = {...assessment};
    obj.questionGroups = obj?.questionGroups?.map(qg => {
      qg.questions = qg?.questions?.map(q => q?._id === question?._id ? question: q);
      return qg;
    });
    return obj;
  } else {
    return assessment;
  }
}

const findAndDeleteQuestionInAssessment = (assessment, questionId) => {
  if (assessment && questionId) {
    const obj = {...assessment};
    obj.questionGroups = obj?.questionGroups?.map(qg => {
      qg.questions = qg?.questions?.filter(q => q?._id !== questionId);
      return qg;
    });
    return obj;
  } else {
    return assessment;
  }
}

const orderQuestion = ({assessment, question, payload}) => {
  if (assessment && question && payload) {
    const section = getQuestionParent(assessment, question?._id);
    const obj = {...assessment}
    obj.questionGroups = obj.questionGroups?.map(qg => {
      if (qg?._id === section?._id) {
        qg.questions = qg?.questions?.map(q => {
          if (q?._id === question?._id) {
            q.sortOrder = payload?.newSortOrder;
          }
          if (q?._id !== question?._id && q?.sortOrder === payload?.newSortOrder) {
            q.sortOrder = payload?.oldSortOrder
          }
          return q;
        });
      }
      return qg;
    });
    return obj;
  }
  return assessment;
}

const getQuestionParent = (assessment, questionId) => {
  return assessment?.questionGroups?.find(qg => qg?.questions?.findIndex(q => q?._id === questionId) > -1);
}

const findAndAddOptionToQuestion = (assessment, question, option ) => {
  const section = getQuestionParent(assessment, question?._id);
  if (assessment && question && option && section) {
    const obj = {...assessment}
    obj.questionGroups = obj?.questionGroups?.map(qg => {
      if (qg?._id === section?._id) {
        qg.questions = qg.questions?.map(q => {
          if (q?._id === question._id) {
            if (!q.options) {
              q.options = []
            }
            q.options = [...q.options, option]
          }
          return q;
        });
      }
      return qg;
    });
    return obj;
  }
  return assessment;
}

const findAndUpdateOptionToQuestion = (assessment, question, option) => {
  const section = getQuestionParent(assessment, question?._id);
  if (assessment && question && option && section) {
    const obj = {...assessment}
    obj.questionGroups = obj?.questionGroups?.map(qg => {
      if (qg?._id === section?._id) {
        qg.questions = qg.questions?.map(q => {
          if (q?._id === question._id) {
            q.options = q?.options?.map(o => o._id === option?._id ? option: o);
          }
          return q;
        });
      }
      return qg;
    });
    return obj;
  }
  return assessment;
}

const deleteOptionToQuestion = (assessment, question, optionId) => {
  const section = getQuestionParent(assessment, question?._id);
  if (assessment && question && optionId && section) {
    const obj = {...assessment}
    obj.questionGroups = obj?.questionGroups?.map(qg => {
      if (qg?._id === section?._id) {
        qg.questions = qg.questions?.map(q => {
          if (q?._id === question._id) {
            q.options = q?.options?.filter(o => o?._id !== optionId);
          }
          return q;
        });
      }
      return qg;
    });
    return obj;
  }
  return assessment;
}

const orderOption = ({assessment, question, option, payload}) => {
  if (assessment && question && option && payload) {
    const section = getQuestionParent(assessment, question?._id);
    const obj = {...assessment}
    obj.questionGroups = obj.questionGroups?.map(qg => {
      if (qg?._id === section?._id) {
        qg.questions = qg?.questions?.map(q => {
          if (q?._id === question?._id) {
            q.options = q?.options?.map(o => {
              if (o?._id === option._id) {
                o.sortOrder = payload?.newSortOrder;
              }
              if (o?._id !== option._id && o?.sortOrder === payload.newSortOrder) {
                o.sortOrder = payload?.oldSortOrder;
              }
              return o;
            });
          }
          return q;
        });
      }
      return qg;
    });
    return obj;
  }
  return assessment;
}

const copyQuestion = (assessment, section, question) => {
  if (assessment && section && question) {
    if (section) {
      const obj = {...assessment};
      obj.questionGroups = obj?.questionGroups?.map(qg => {
        if (qg?._id === section._id) {
          if (!qg.questions) {
            qg.questions = [];

          }
          qg.questions = [...qg.questions, question];
        }
        return qg;
      })
      return assessment;
    }
  } else {
    return assessment;
  }
}

export {
  addQuestionToSectionInAssessment,
  findAndUpdateQuestionInAssessment,
  findAndDeleteQuestionInAssessment,
  orderQuestion,
  getQuestionParent,
  findAndAddOptionToQuestion,
  findAndUpdateOptionToQuestion,
  deleteOptionToQuestion,
  orderOption,
  copyQuestion
}
