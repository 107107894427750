import apiUtil from '../utils/api.utils';
import { parseFiltersToJson } from '../utils/filter.utils';
import configs from '../utils/configs';
import Compressor from 'compressorjs';

export const createResident = async (resident) => {
  try {
    const response = await apiUtil.client.post(`${configs.residentService}/api/v1.0/residents/`, resident);
    return response.data;
  } catch (error) {
    return {error: true, message: error};
  }
};

export const getResidentById = async (residentId) => {
  try {
    const response = await apiUtil.client.get(`${configs.residentService}/api/v1.0/residents/${residentId}`);
    return response.data;
  } catch (error) {
    return { error: true, message: error };
  }
};

export const updateResident = async (residentId, resident) => {
  try {
    const response = await apiUtil.client.put(`${configs.residentService}/api/v1.0/residents/${residentId}`, resident);
    return response.data;
  } catch (error) {
    return { error: true, message: error };
  }
};

export const getResidentImage = async (residentId) => {
  try {
    const response = await apiUtil.client.get(
      `${configs.residentService}/api/v1.0/residents/${residentId}/image`,
      { responseType: 'blob' }
    );
    return response.data;
  } catch (error) {
    if (error && error.message && error.response.status === 404) {
      return null;
    } else {
      alert(error);
      return null;
    }
  }
};

export const constCompressAndCreateIage = async (
  residentId,
  event,
  callBack
) => {
  const formData = new FormData();
  const file = event.target.files[0];
  /*formData.append('file', file);
  const fileName = await createResidentImage(resident._id, formData);
  setResidentImageUrl(buildImageUrlString(fileName));*/
  new Compressor(file, {
    quality: 0.6,
    maxHeight: 300,
    maxWidth: 300,
    checkOrientation: true,
    success: async (compressed) => {
      formData.append('file', compressed, file.name);
      const fileName = await createResidentImage(residentId, formData);
      callBack(fileName);
    },
  });
};

export const createResidentImage = async (residentId, formData) => {
  try {
    const response = await apiUtil.client.post(
      `${configs.residentService}/api/v1.0/residents/${residentId}/image`,
      formData
    );
    return response.data;
  } catch (error) {
    if (error && error.message && error.response.status === 404) {
      return null;
    } else {
      alert(error);
      return null;
    }
  }
};

export const removeResidentImage = async (residentId) => {
  try {
    await apiUtil.client.delete(
      `${configs.residentService}/api/v1.0/residents/${residentId}/image`
    );
    return true;
  } catch (error) {
    if (error && error.message && error.response.status === 404) {
      return null;
    } else {
      alert(error);
      return null;
    }
  }
};

export const createResidentAddress = async (residentId, address) => {
  try {
    const response = await apiUtil.client.post(
      `${configs.residentService}/api/v1.0/residents/${residentId}/addresses`,
      address
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};

export const updateResidentAddress = async (
  residentId,
  addressId,
  isCurrent,
  address
) => {
  try {
    address.isCurrent = isCurrent;
    const response = await apiUtil.client.put(
      `${configs.residentService}/api/v1.0/residents/${residentId}/addresses/${addressId}`,
      address
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};
export const deleteResidentAddress = async (residentId, addressId) => {
  try {
    const response = await apiUtil.client.delete(
      `${configs.residentService}/api/v1.0/residents/${residentId}/addresses/${addressId}`
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};

export const getResidentAddresses = async (token, residentId) => {
  try {
    const response = await apiUtil.client.get(
      `${configs.residentService}/api/v1.0/residents/${residentId}/addresses`
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};

export const getResidentPhones = async (token, residentId) => {
  try {
    const response = await apiUtil.client.get(
      `${configs.residentService}/api/v1.0/residents/${residentId}/phones`
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};

export const createResidentPhone = async (residentId, phone) => {
  try {
    const response = await apiUtil.client.post(
      `${configs.residentService}/api/v1.0/residents/${residentId}/phones`,
      phone
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};

export const updateResidentPhone = async (residentId, phoneId, phone) => {
  try {
    const response = await apiUtil.client.put(
      `${configs.residentService}/api/v1.0/residents/${residentId}/phones/${phoneId}`,
      phone
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};

export const deleteResidentPhone = async (residentId, phoneId) => {
  try {
    const response = await apiUtil.client.delete(
      `${configs.residentService}/api/v1.0/residents/${residentId}/phones/${phoneId}`
    );
    return response.data;
  } catch (error) {}
};

export const getResidents = async (
  page = 0,
  size = 25,
  filters = [],
  sort = 'id',
  sortDirection = 'asc'
) => {
  try {
    let url = `${configs.residentService}/api/v1.0/residents/?page=${
      page + 1
    }&size=${size}`;
    url += parseFiltersToJson(filters);
    let sortArg = sortDirection === 'asc' ? '+' : '-';
    sortArg += sort;
    url += `&sort=${sortArg}`;
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (error) {
    return {
      error: true,
      messge: error
    }
  }
};

export const getResidentPharmacies = async (residentId) => {
  try {
    const response = await apiUtil.client.get(
      `${configs.residentService}/api/v1.0/residents/${residentId}/pharmacies`
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};

export const createResidentPharmacy = async (
  residentId,
  pharmacyId,
  isPrimary
) => {
  try {
    const data = {
      residentId,
      pharmacyId,
      isPrimary,
    };
    const response = await apiUtil.client.post(
      `${configs.residentService}/api/v1.0/residents/${residentId}/pharmacies`,
      data
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};

export const deleteResidentPharmacy = async (residentId, pharmacyId) => {
  try {
    await apiUtil.client.delete(
      `${configs.residentService}/api/v1.0/residents/${residentId}/pharmacies/${pharmacyId}`
    );
  } catch (error) {
    alert(error);
    return null;
  }
};

export const updateResidentPharmacy = async (
  residentId,
  pharmacyId,
  isPrimary
) => {
  try {
    const response = await apiUtil.client.put(
      `${configs.residentService}/api/v1.0/residents/${residentId}/pharmacies/${pharmacyId}`,
      { pharmacyId, isPrimary }
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};

export const getResidentPhysicians = async (residentId) => {
  try {
    const response = await apiUtil.client.get(
      `${configs.residentService}/api/v1.0/residents/${residentId}/physicians`
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};

export const createResidentPhysician = async (
  residentId,
  physicianId,
  isPrimary
) => {
  try {
    const data = {
      residentId,
      physicianId,
      isPrimary,
    };
    const response = await apiUtil.client.post(
      `${configs.residentService}/api/v1.0/residents/${residentId}/physicians`,
      data
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};

export const deleteResidentPhysician = async (residentId, physicianId) => {
  try {
    await apiUtil.client.delete(
      `${configs.residentService}/api/v1.0/residents/${residentId}/physicians/${physicianId}`
    );
  } catch (error) {
    alert(error);
    return null;
  }
};

export const updateResidentPhysician = async (
  residentId,
  physicianId,
  isPrimary
) => {
  try {
    const response = await apiUtil.client.put(
      `${configs.residentService}/api/v1.0/residents/${residentId}/physicians/${physicianId}`,
      { physicianId, isPrimary }
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};

export const getResidentInsurances = async (residentId) => {
  try {
    const response = await apiUtil.client.get(
      `${configs.residentService}/api/v1.0/residents/${residentId}/insurances`
    );
    return response.data;
  } catch (error) {
    return { error: true, message: error }
  }
};
export const createResidentInsurance = async (
  residentId,
  insuranceId,
  isPrimary
) => {
  try {
    const data = {
      insuranceId,
      isPrimary,
    };
    const response = await apiUtil.client.post(
      `${configs.residentService}/api/v1.0/residents/${residentId}/insurances`,
      data
    );
    return response.data;
  } catch (error) {
    return { error: true, message: error }
  }
};

export const updateResidentInsurance = async (
  residentId,
  residentInsuranceId,
  insurance
) => {
  try {
    const response = await apiUtil.client.put(
      `${configs.residentService}/api/v1.0/residents/${residentId}/insurances/${residentInsuranceId}`,
      insurance
    );
    return response.data;
  } catch (error) {
    return { error: true, message: error }
  }
};

export const deleteResidentInsurance = async (residentId, insuranceId) => {
  try {
    await apiUtil.client.delete(
      `${configs.residentService}/api/v1.0/residents/${residentId}/insurances/${insuranceId}`
    );
  } catch (error) {
    return { error: true, message: error }
  }
};

export const getResidentStatuses = async () => {
  try {
    const response = await apiUtil.client.get(
      `${configs.residentService}/api/v1.0/residentsstatus`
    );
    return response.data;
  } catch (err) {
    return { error: true, message: err }
  }
};


let residentAlertsRecheckCancelToken;
export const getRecheckResidentAlerts = async (residentId, page = 1, size = 10) => {
  try {
    if (typeof residentAlertsRecheckCancelToken != typeof undefined) {
      residentAlertsRecheckCancelToken.cancel("Cancel Request");
    }
    residentAlertsRecheckCancelToken = apiUtil.getCancelToken();
    const response = await apiUtil.client.get(
      `${configs.residentService}/api/v1.0/residents/${residentId}/alerts/recheck?page=${page}&size=${size}`,
      {cancelToken: residentAlertsRecheckCancelToken.token}
    );
    return response.data;
  } catch (error) {
    if (apiUtil.isCancel(error)) {
      return { canceled: true };
    } else {
      return { error: true, message: error }
    }
  }
};

let prnAlertCancelToken;
export const getPrnAlerts = async (residentId, fkEntityId, page = 1, size = 10) => {
  try {
    if (typeof prnAlertCancelToken != typeof undefined) {
      prnAlertCancelToken.cancel("Cancel Request");
    }
    prnAlertCancelToken = apiUtil.getCancelToken();
    const response = await apiUtil.client.get(
      `${configs.residentService}/api/v1.0/residents/${residentId}/alerts/recheck?fk=${fkEntityId}&page=${page}&size=${size}`,
      {cancelToken: prnAlertCancelToken.token}
    );
    return response.data;
  } catch (error) {
    if (apiUtil.isCancel(error)) {
      return { canceled: true };
    } else {
      return { error: true, message: error }
    }
  }
};


let residentAlertsMissingCancelToken;
export const getMissingResidentAlerts = async (residentId, page = 1, size = 10) => {
  try {
    if (typeof residentAlertsMissingCancelToken != typeof undefined) {
      residentAlertsMissingCancelToken.cancel("Cancel Request");
    }
    residentAlertsMissingCancelToken = apiUtil.getCancelToken();
    const response = await apiUtil.client.get(
      `${configs.residentService}/api/v1.0/residents/${residentId}/alerts/missed?page=${page}&size=${size}`,
      {cancelToken: residentAlertsMissingCancelToken.token}
    );
    return response.data;
  } catch (error) {
    if (apiUtil.isCancel(error)) {
      return { canceled: true };
    } else {
      return { error: true, message: error }
    }
  }
};


let residentAlertsLateCancelToken;
export const getCurrentLateResidentAlerts = async (residentId, page = 1, size = 10) => {
  try {
    if (typeof residentAlertsLateCancelToken != typeof undefined) {
      residentAlertsLateCancelToken.cancel("Cancel Request");
    }
    residentAlertsLateCancelToken = apiUtil.getCancelToken();
    const response = await apiUtil.client.get(
      `${configs.residentService}/api/v1.0/residents/${residentId}/alerts/current?page=${page}&size=${size}`,
      {cancelToken: residentAlertsLateCancelToken.token}
    );
    return response.data;
  } catch (error) {
    if (apiUtil.isCancel(error)) {
      return { canceled: true };
    } else {
      return { error: true, message: error }
    }
  }
};


let residentAlertsCancelToken;
export const getResidentAlerts = async (residentId) => {
  try {
    if (typeof residentAlertsCancelToken != typeof undefined) {
      residentAlertsCancelToken.cancel("Cancel Request");
    }
    residentAlertsCancelToken = apiUtil.getCancelToken();
    const response = await apiUtil.client.get(
      `${configs.residentService}/api/v1.0/residents/${residentId}/alerts`,
      {cancelToken: residentAlertsCancelToken.token}
    );
    return response.data;
  } catch (error) {
    if (apiUtil.isCancel(error)) {
      return { canceled: true };
    } else {
      return { error: true, message: error }
    }
  }
};

export const updateAlert = async (residentId, alertId, alertEntity) => {
  try {
    const response = await apiUtil.client.put(
      `${configs.residentService}/api/v1.0/residents/${residentId}/alerts/${alertId}`,
      alertEntity
    );
    return response.data;
  } catch (error) {
    return { error: true, message: error };
  }
};

export const dismissAlerts = async (residentId, type) => {
  try {
    const response = await apiUtil.client.delete(
      `${configs.residentService}/api/v1.0/residents/${residentId}/alerts?type=${type}`
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};


export const getResidentNotes = async (residentId, page = 1, size = 5, sort = '-noteDate', filters = null) => {
  try {
    let url = `${configs.residentService}/api/v1.0/residents/${residentId}/notes?page=${page}&size=${size}&sort=${sort}`;
    if (filters && filters.length > 0) {
      url += parseFiltersToJson(filters)
    }
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (error) {
    return { error: true, message: error };
  }
};


export const getResidentNote = async (residentId, residentNoteId) => {
  try {
    const response = await apiUtil.client.get(
      `${configs.residentService}/api/v1.0/residents/${residentId}/notes/${residentNoteId}`
    );
    return response.data;
  } catch (error) {
    return { error: true, message: error };
  }
};

export const updateNote = async (residentId, noteId, note) => {
  try {
    const response = await apiUtil.client.put(
      `${configs.residentService}/api/v1.0/residents/${residentId}/notes/${noteId}`,
      note
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};

export const toggleLocked = async (residentId, noteId, payload) => {
  try {
    const response = await apiUtil.client.put(
      `${configs.residentService}/api/v1.0/residents/${residentId}/notes/${noteId}/locked`,
      {locked: payload}
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};

export const createNote = async (residentId, note) => {
  try {
    const response = await apiUtil.client.post(
      `${configs.residentService}/api/v1.0/residents/${residentId}/notes`,
      note
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};


export const deleteNote = async (residentId, noteId) => {
  try {
    const response = await apiUtil.client.delete(
      `${configs.residentService}/api/v1.0/residents/${residentId}/notes/${noteId}`
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};


export const createNoteComment = async (residentId, noteId, comment) => {
  try {
    const response = await apiUtil.client.post(
      `${configs.residentService}/api/v1.0/residents/${residentId}/notes/${noteId}`,
      comment
    );
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};

export const updateNoteComment = async (residentId, noteId, commentId, comment) => {
  try {
    const response = await apiUtil.client.put(
      `${configs.residentService}/api/v1.0/residents/${residentId}/notes/${noteId}/comments/${commentId}`,
      comment
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const deleteNoteComment = async (residentId, noteId, commentId) => {
  try {
    const response = await apiUtil.client.delete(
      `${configs.residentService}/api/v1.0/residents/${residentId}/notes/${noteId}/comments/${commentId}`
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const uploadNoteFile = async (residentId, noteId, payload) => {
  try {
    const formData = new FormData();
    payload.forEach(f => {
      formData.append('files', f);
    })
    const response = await apiUtil.client.post(
      `${configs.residentService}/api/v1.0/residents/${residentId}/notes/${noteId}/files`,
      formData
    );
    return response.data;
  } catch (error) {
    return null;
  }
}

export const getNoteFile = async (residentId, noteId, fileId) => {
  try {
    const response = await apiUtil.client.get(
      `${configs.residentService}/api/v1.0/residents/${residentId}/notes/${noteId}/files/${fileId}`,
      { responseType: 'blob' }
    );
    return response.data;
  } catch (error) {
    return null;
  }
}

export const deleteNoteFile = async (residentId, noteId, fileId) => {
  try {
    const response = await apiUtil.client.delete(
      `${configs.residentService}/api/v1.0/residents/${residentId}/notes/${noteId}/files/${fileId}`
    );
    return response.data;
  } catch (error) {
    return null;
  }
}

export const fuzzySearch = async (query, tenant, location = '-1', includeDischarged = false) => {
  try {

    let url = `${configs.residentService}/api/v1.0/residents/fuzzy?filter=${query}&tenant=${tenant}&location=${location}`;
   if (includeDischarged) {
     url += "&includedischarged=true";
   }
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (error) {
    return null;
  }
}

export const createResidentAllergy = async (residentId, allergies) => {
  try {
    const response = await apiUtil.client.post(
      `${configs.residentService}/api/v1.0/residents/${residentId}/allergies`,
      allergies
    );
    return response.data;
  } catch (error) {
    return null;
  }
}

export const deleteResidentAllergy = async (residentId, allergyId) => {
  try {
    const response = await apiUtil.client.delete(
      `${configs.residentService}/api/v1.0/residents/${residentId}/allergies/${allergyId}`,
    );
    return response.data;
  } catch (error) {
    return null;
  }
}


export const createResidentTest = async (residentId, test) => {
  try {
    const response = await apiUtil.client.post(
      `${configs.residentService}/api/v1.0/residents/${residentId}/tests`,
      test
    );
    return response.data;
  } catch (error) {
    return null;
  }
}

export const updateResidentTest = async (residentId, testId, test) => {
  try {
    const response = await apiUtil.client.put(
      `${configs.residentService}/api/v1.0/residents/${residentId}/tests/${testId}`,
      test
    );
    return response.data;
  } catch (error) {
    return null;
  }
}

export const deleteResidentTest = async (residentId, testId) => {
  try {
    const response = await apiUtil.client.delete(
      `${configs.residentService}/api/v1.0/residents/${residentId}/tests/${testId}`
    );
    return response.data;
  } catch (error) {
    return null;
  }
}


export const createResidentVaccination = async (residentId, vaccination) => {
  try {
    const response = await apiUtil.client.post(
      `${configs.residentService}/api/v1.0/residents/${residentId}/vaccinations`,
      vaccination
    );
    return response.data;
  } catch (error) {
    return null;
  }
}

export const updateResidentVaccination = async (residentId, vaccinationId, vaccination) => {
  try {
    const response = await apiUtil.client.put(
      `${configs.residentService}/api/v1.0/residents/${residentId}/vaccinations/${vaccinationId}`,
      vaccination
    );
    return response.data;
  } catch (error) {
    return null;
  }
}

export const deleteResidentVaccination = async (residentId, vaccinationId) => {
  try {
    const response = await apiUtil.client.delete(
      `${configs.residentService}/api/v1.0/residents/${residentId}/vaccinations/${vaccinationId}`
    );
    return response.data;
  } catch (error) {
    return null;
  }
}

export const getFiles = async (residentId) => {
  try {
    const response = await apiUtil.client.get(
      `${configs.residentService}/api/v1.0/residents/${residentId}/files`);
    return response.data;
  } catch (error) {
    return {error: true, message: error.response?.data?.message};
  }
}

export const fuzzyResidentFileSearch = async (residentId, term) => {
  try {
    const response = await apiUtil.client.get(
      `${configs.residentService}/api/v1.0/residents/${residentId}/files/search?term=${term}`);
    return response.data;
  } catch (error) {
    return {error: true, message: error.response.data};
  }
}

export const createFolder = async (residentId, file) => {
  try {
    const response = await apiUtil.client.post(
      `${configs.residentService}/api/v1.0/residents/${residentId}/files`, file);
    return response.data;
  } catch (error) {
    if (error?.response?.data) {
      return {error: true, message: error.response?.data?.message};
    } else {
      return {
        error: true,
        status: 400,
        message: 'Unable to create folder',
      }
    }
  }
}

export const createFiles = async (residentId, files, parent = null, tags =[]) => {
  try {
    const formData = new FormData();
    formData.append('type', 'file');
    files.forEach((f, index) => {
      formData.append('files', f);
      if (index === 0) {
        formData.append('tags', tags);
        if (parent) {
          formData.append('parent', parent);
        }
      }
    });
    const response = await apiUtil.client.post(
      `${configs.residentService}/api/v1.0/residents/${residentId}/files`, formData);
    return response.data;
  } catch (error) {
    if (error?.response?.data) {
      return {error: true, message: error.response?.data?.message};
    } else {
      return {
        error: true,
        status: 400,
        message: 'Unable to create file(s)',
        files: [],
        failedUploads: []
      }
    }
  }
}

export const updateFileItem = async (residentId, fileId, file) => {
  try {
    const response = await apiUtil.client.put(
      `${configs.residentService}/api/v1.0/residents/${residentId}/files/${fileId}`, file);
    return response.data;
  } catch (err) {
    if (err.response.data) {
      return {error: true, message: err.response?.data?.message};
    } else {
      return {
        error: true,
        status: 400,
        message: 'Unable to update',
        file: file
      }
    }
  }
}

export const deleteFileItem = async (residentId, fileId) => {
  try {
    const response = await apiUtil.client.delete(
      `${configs.residentService}/api/v1.0/residents/${residentId}/files/${fileId}`);
    return response.data;
  } catch (error) {
    return {error: true, message: error.response?.data?.message};
  }
}

export const deleteFileItems = async (residentId, files) => {
  try {
    const response = await apiUtil.client.post(
      `${configs.residentService}/api/v1.0/residents/${residentId}/files/delete`,
      files);
    return response.data;
  } catch (error) {
    return {
      error: true,
      message: error
    };
  }
}

export const downloadFile = async (residentId, fileId) => {
  try {
    const response = await apiUtil.client.get(
      `${configs.residentService}/api/v1.0/residents/${residentId}/files/${fileId}/download`);
    return response.data;
  } catch (error) {
    return {error: true, message: error.response?.data?.message};
  }
}

export const moveFile = async (residentId, parentId, fileId) => {
  try {
    const response = await apiUtil.client.post(
        `${configs.residentService}/api/v1.0/residents/${residentId}/files/${fileId}/move`,{
          fileId,
          parentId,
        });
    return response.data;
  } catch (error) {
    return {error: true, message: error.response?.data?.message};
  }
}

export const copyFile = async (residentId, parentId, fileId, destination,fileName) => {
  try {
    const response = await apiUtil.client.post(
        `${configs.residentService}/api/v1.0/residents/${residentId}/files/${fileId}/copy`,{
          fileId,
          parentId,
          destination,
          fileName
        });
    return response.data;
  } catch (error) {
    return {error: true, message: error.response?.data?.message};
  }
}


export const getTenantLocationResidents = async (tenantId, locationId) => {
  try {
    const url = `${configs.residentService}/api/v1.0/tenants/${tenantId}/locations/${locationId}/residents`;
    const response = await apiUtil.client.get(url);
    return response?.data;
  } catch (error) {
    return {error: true, message: error.response?.data?.message};
  }
}



