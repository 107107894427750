import React from 'react';
import {
  Button,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Table, TableBody,
  TableCell,
  TableContainer,
  TableHead, TableRow,
  TextField,
  Typography
} from "@material-ui/core";
import EntityPhoneList from "../../phone/entity.phone.list";
import CreateAddress from "../../address/create.address";
import StateDropdown from "../../utils/dropdowns/state.dropdown";
import PhoneTwoToneIcon from "@material-ui/icons/PhoneTwoTone";
import {
  createUserAddress,
  createUserPhone,
  deleteUserAddress,
  deleteUserPhone,
  updateUserAddress, updateUserPhone
} from "../../../services/user.service";
import {getAllAddressTypes} from "../../../services/address.service";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1)
  },
  section: {
    margin: theme.spacing(3, 0),
  },
  addressLogo: {
    margin: theme.spacing(0, 1),
    color: theme.palette.primary.main
  }
}));


const UserContactInfo = ({model, hidden, canEdit}) => {
  const classes = useStyles();
  const [userModel, setUserModel] = React.useState({});
  const [addressTypes, setAddressTypes] = React.useState([{ name: 'Home', value: 'Home'}]);
  const [createAddressModal, setCreateAddressModal] = React.useState(false);
  const [editAddress, setEditAddress] = React.useState(null);
  const [editAddressKey, setEditAddressKey] = React.useState(-1);

  React.useEffect(() => {
    const getTypes = async () => {
      let types = []
      if (addressTypes?.length > 0) {
        types = addressTypes;
      } else {
        const types = await getAllAddressTypes();
        setAddressTypes(types);
      }
      return types;
    };
    if (model) {
      getTypes();
      setUserModel(JSON.parse(JSON.stringify(model)));
    }
    // eslint-disable-next-line
  }, [ model]);

  const onCreatePhone = async phone => {
    const response = await createUserPhone(model?._id?.value, phone);
    const obj = {...userModel};
    obj.phones.value = [...obj.phones.value, response]
    setUserModel(obj);
  }
  const onDeletePhone = async phone => {
    const response = await deleteUserPhone(model?._id?.value, phone?._id);
    if (response?.ok) {
      const obj = {...userModel};
      obj.phones.value = obj.phones.value.filter(p => p._id !== phone?._id);
      setUserModel(obj);
    }
  }
  const onUpdatePhone = async phone => {
    const response = await updateUserPhone(model?._id?.value, phone?._id, phone);
    const obj = {...userModel};
    obj.phones.value = obj.phones.value.map(p => p?._id === response?._id ? response: p);
    setUserModel(obj);
  }

  const onAddressSave = async address => {
    const response = await createUserAddress(model?._id?.value, address);
    const obj = {...userModel};
    obj.address.value = [...obj.address.value, response];
    setUserModel(obj);
    setCreateAddressModal(false);
  }
  const onEditAddressTypeChange = e => {
    const obj = {...editAddress};
    obj.addressType = e.target.value;
    setEditAddress(obj);
  }
  const updateAddress = async () => {
    const entity = await updateUserAddress(model?._id?.value, editAddress?._id, editAddress);
    const obj = {...userModel};
    obj.address.value = obj?.address?.value?.map(a => a?._id === entity?._id ? entity: a);
    setUserModel(obj);
    setEditAddressKey(-1);
  }

  const onEditAddressChange = e => {
    const obj = {...editAddress};
    obj[e.target.name] = e.target.value;
    setEditAddress(obj);
  }

  const onEditAddress = (address, key) => {
    setEditAddressKey(key);
    setEditAddress(address);
  }
  const onDeleteAddress = async address => {
    const response = await deleteUserAddress(model?._id?.value, address?._id);
    if (response.ok) {
      const obj = {...userModel};
      obj.address.value = obj.address.value?.filter(a => a._id !== address?._id);
      setUserModel(obj);
    }
  }

  if (!hidden) {
    return (
      <div className={classes.root}>
          <div className={classes.section}>
            <Grid container justify={"flex-start"} alignItems={"center"}>
              <PhoneTwoToneIcon className={classes.addressLogo} />
              <Typography variant={"subtitle1"}>Address</Typography>
            </Grid>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableCell>
                    {canEdit &&
                    <Button onClick={() => setCreateAddressModal(true)}>
                      Add
                    </Button>}
                    <CreateAddress
                      open={createAddressModal}
                      onSave={onAddressSave}
                      onClose={() => setCreateAddressModal(false)}
                    />
                  </TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Address 2</TableCell>
                  <TableCell>City</TableCell>
                  <TableCell>State</TableCell>
                  <TableCell>Zip</TableCell>
                </TableHead>
                <TableBody>
                  {userModel?.address?.value?.map((address, key) => (
                    <TableRow key={key}>
                      {editAddressKey === key && (
                        <React.Fragment>
                          <TableCell style={{ display: 'flex' }}>
                            <Button
                              size="small"
                              onClick={() => setEditAddressKey(-1)}
                            >
                              Cancel
                            </Button>
                            <Button
                              size="small"
                              onClick={() => updateAddress()}
                              variant="contained"
                              color="primary"
                            >
                              Save
                            </Button>
                          </TableCell>
                          <TableCell style={{ padding: 0 }}>
                            <Select
                              labelId="addressType"
                              name="addressType"
                              value={editAddress.addressType}
                              onChange={onEditAddressTypeChange}
                            >
                              {addressTypes &&
                              addressTypes.map((type) => (
                                <MenuItem value={type.name}>
                                  {type.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </TableCell>
                          <TableCell>
                            <TextField
                              name="address"
                              value={editAddress.address}
                              fullWidth
                              onChange={onEditAddressChange}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              name="address2"
                              value={editAddress.address2}
                              fullWidth
                              onChange={onEditAddressChange}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              name="city"
                              fullWidth
                              onChange={onEditAddressChange}
                              value={editAddress.city}
                            />
                          </TableCell>
                          <TableCell>
                            <StateDropdown
                              name="state"
                              fullWidth
                              value={editAddress.state}
                              onChange={onEditAddressChange}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              name="zipCode"
                              onChange={onEditAddressChange}
                              fullWidth
                              value={editAddress.zipCode}
                            />
                          </TableCell>
                        </React.Fragment>
                      )}
                      {editAddressKey !== key && (
                        <React.Fragment>
                          <TableCell>
                            {canEdit &&
                            <>
                              <Button
                                size="small"
                                onClick={() => onEditAddress(address, key)}
                              >
                                Edit
                              </Button>
                              <Button
                                size="small"
                                variant="contained"
                                color="error"
                                onClick={() => onDeleteAddress(address, key)}
                              >
                                Delete
                              </Button>
                            </>
                            }
                          </TableCell>
                          <TableCell>{address?.addressType}</TableCell>
                          <TableCell>{address.address}</TableCell>
                          <TableCell>{address.address2}</TableCell>
                          <TableCell>{address.city}</TableCell>
                          <TableCell>{address.state}</TableCell>
                          <TableCell>{address.zipCode}</TableCell>
                        </React.Fragment>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <EntityPhoneList phones={userModel?.phones?.value}
                           createPhone={onCreatePhone}
                           deletePhone={onDeletePhone}
                           updatePhone={onUpdatePhone}
                           canEdit={canEdit}
          />
      </div>
    )
  } else {
    return null;
  }
}

export default UserContactInfo;
