import React from 'react';
import {makeStyles, Paper, Tab, Tabs} from "@material-ui/core";
import {ResidentContext} from "../../../context/residentcontext";
import {getResidentAssessment} from "../../../services/assessment.service";
import Assessment from "../../assessment/assessment";
import PdfPreview from "../../assessment/pdf.preview";
import {PageTitle} from "../../utils";
import useHasRole from "../../../hooks/user.role.hook";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 1),
    margin: theme.spacing(.5, 0)
  }
}))

const ResidentAssessment = ({match}) => {
  const classes = useStyles();
  const {resident} = React.useContext(ResidentContext);
  const [residentAssessment, setResidentAssessment] = React.useState();
  const [viewIndex, setViewIndex] = React.useState(0);
  const {checkPermission} = useHasRole();
  const canUpdate = checkPermission("RESIDENT_ASSESSMENT_UPDATE");

  React.useEffect(() => {
    const { residentId, assessmentId } = match?.params;
    if (residentId && assessmentId) {
      getAssessment(residentId, assessmentId);
      setViewIndex(0);
    }
  }, [ match]);

  const getAssessment = async (residentId, assessmentId) => {
    const response = await getResidentAssessment(residentId, assessmentId);
    setResidentAssessment(response)
  }

  const onTabChange = (event, newValue) => {
    setViewIndex(newValue);
  };


  return (
    <Paper elevation={24} className={classes.root}>
      <PageTitle title={residentAssessment?.assessment?.name} />
      <Tabs value={viewIndex} onChange={onTabChange}>
        <Tab label={"PDF View"} />
        {canUpdate && <Tab label={"Preview"} />}
      </Tabs>
      {viewIndex === 0 &&
        <PdfPreview resident={resident} assessmentInstance={residentAssessment} />
      }
      {viewIndex === 1 &&
      <Assessment
        isPreview={false}
        assessmentInstance={residentAssessment}
        assessmentEntity={`${resident?.firstName} ${resident?.lastName}`}
        onAssessmentUpdate={() => {}}
      />}
    </Paper>
  )
}

export default ResidentAssessment;
