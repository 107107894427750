import React from 'react';
import {Grid, makeStyles, TableCell, TableRow, Typography} from "@material-ui/core";
import MarDosageInstruction from "./mar.dosageinstruction";
import MarPrn from "./mar.prn";
import {WarningTwoTone} from "@material-ui/icons";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  alerts: {
    width: '100%',
    padding: theme.spacing(.5),
    backgroundColor: theme.palette.error.light,
    color: '#fff'
  },
  discontinuedDate: {
    width: '100%',
    padding: theme.spacing(.5),
    backgroundColor: theme.palette.grey[500],
    color: '#fff'
  }
}));

const MarMedicationOrder = ({medicationOrder, days, index, timeZone}) => {
  const classes = useStyles();
  return (
    <>
      {medicationOrder?.alerts &&
      <TableRow style={{padding: 0, margin: 0}}>
        <TableCell style={{ padding: '0'}}
                   colSpan={days}
        >
          <Grid container
                alignItems={"center"}
                className={classes.alerts}>
            <WarningTwoTone style={{marginRight: '.25rem'}} />
            <Typography variant={"subtitle1"}>
              Alert: {medicationOrder?.alerts}
            </Typography>
          </Grid>
        </TableCell>
      </TableRow>
      }
      {medicationOrder?.orderStatus?.name === "Discontinued" && medicationOrder?.discontinuedDate &&
      <TableRow>
        <TableCell style={{ padding: '0'}}
                   colSpan={days}
        >
          <Grid container
                alignItems={"center"}
                className={classes.discontinuedDate}>
            <WarningTwoTone style={{marginRight: '.25rem'}} />
            <Typography variant={"subtitle1"}>
              Discontinued: {moment(medicationOrder?.discontinuedDate).format("MM/DD/YYYY")}
            </Typography>
          </Grid>
        </TableCell>
      </TableRow>
      }
      <TableRow>
        <TableCell style={{ padding: '6px 0'}}>
          <Typography variant={"subtitle1"}>
            {medicationOrder?.medication?.tradeName}
          </Typography>
          <Typography variant={"subtitle2"}>
            {medicationOrder?.medication?.strength} {medicationOrder?.medication?.unit?.name}
          </Typography>
          <Typography variant={"subtitle1"} style={{fontSize: '.75rem'}}>
            Direction: {medicationOrder?.direction}
          </Typography>
            <Typography variant={"subtitle1"} style={{fontSize: '.75rem'}}>
            Indication: {medicationOrder?.indication}
          </Typography>
        </TableCell>
        {!medicationOrder?.isPrn &&
        <TableCell style={{padding: '0 16px', borderTop: index === 0 ? 'none' : '.01rem solid rgb(224, 224, 224)'}}>
          {medicationOrder?.dosageInstructions?.map(dosageInstruction =>
            <MarDosageInstruction medicationOrder={medicationOrder} dosageInstruction={dosageInstruction} days={days}/>)}
        </TableCell>
        }
        {medicationOrder?.isPrn &&
        <TableCell>
          <MarPrn medicationOrder={medicationOrder}
                  timeZone={timeZone}
                  days={days} />
        </TableCell>
        }
      </TableRow>
    </>
  )
}

export default MarMedicationOrder;
