import React from 'react';
import {Grid, makeStyles, Paper, Typography, Link} from "@material-ui/core";
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 1),
    margin: theme.spacing(1, 0)
  }
}))

const ResidentContentNotFound = ({ match }) => {
  const classes = useStyles();
  return (
    <Paper elevation={24} className={classes.root}>
      <Grid container
            justify={"center"}
            alignItems={"center"}
      >
        <Typography variant={"h2"}>Whoops, something happend.</Typography>
        <Typography variant={"h2"}><i className="fas fa-sad-tear"></i></Typography>
      </Grid>
      <Grid container
            justify={"center"}
            alignItems={"center"}
      >
        <Typography variant={"body1"}>Click <Link component={RouterLink} to={`/residents/${match?.params?.residentId}`}>here</Link> to go back to resident home</Typography>
      </Grid>
    </Paper>
  )
}

export default ResidentContentNotFound;
