import React from 'react';
import {getTenantCalendarEventsAction, getTenantCalendarTypesAction} from "./actions";

const TenantCalendarContext = React.createContext({});

const defaultState = {
  tenantId: null,
  loadingEvents: false,
  loadingEventsError: false,
  loading: false,
  loadingTypes: false,
  loadingTypesError: false,
  error: false,
  calendarTypes: [],
  events: [],
  viewCreateEventModal: false,
  viewEditEventModal: false,
  viewConfirmDeleteDialog: false,
  viewConfirmDeleteSeriesDialog: false,
  viewCreateCalendarTypeModal: false,
  viewEditCalendarTypeModal: false,
  viewDeleteCalendarTypeModal: false,
  selectedCalendarType: null,
  selectedCalendarSlot: null,
  selectedCalendarEvent: null,
  viewAllFilter: true,
  view: 'week'
};

export const ACTIONS = {
  SET_TENANT_ID: "SET_TENANT_ID",
  GET_CALENDAR_TYPES: "GET_CALENDAR_TYPES",
  SET_CALENDAR_TYPES: "SET_CALENDAR_TYPES",
  TOGGLE_LOADING_EVENTS: "TOGGLE_LOADING_EVENTS",
  TOGGLE_LOADING_EVENTS_ERROR: "TOGGLE_LOADING_EVENTS_ERROR",
  TOGGLE_PENDING_ACTION: "TOGGLE_PENDING_ACTION",
  CREATE_CALENDAR_TYPE: "CREATE_CALENDAR_TYPE",
  UPDATE_CALENDAR_TYPE: "UPDATE_CALENDAR_TYPE",
  DELETE_CALENDAR_TYPE: "DELETE_CALENDAR_TYPE",
  GET_CALENDAR_EVENTS: "GET_CALENDAR_EVENTS",
  SET_CALENDAR_EVENTS: "SET_CALENDAR_EVENTS",
  CREATE_CALENDAR_EVENT: "CREATE_CALENDAR_EVENT",
  UPDATE_CALENDAR_EVENT: "UPDATE_CALENDAR_EVENT",
  DELETE_CALENDAR_EVENT: "DELETE_CALENDAR_EVENT",
  TOGGLE_VIEW_DELETE_CALENDAR_EVENT: "TOGGLE_VIEW_DELETE_CALENDAR_EVENT",
  TOGGLE_VIEW_DELETE_SERIES_CALENDAR_EVENT: "TOGGLE_VIEW_DELETE_SERIES_CALENDAR_EVENT",
  SET_ERROR: "SET_ERROR",
  TOGGLE_FILTER_TYPE: "TOGGLE_FILTER_TYPE",
  TOGGLE_FILTER_VIEW_ALL: "TOGGLE_FILTER_VIEW_ALL",
  SET_USER: "SET_USER",
  SET_VIEW_CREATE_EVENT_MODAL: "SET_VIEW_CREATE_EVENT_MODAL",
  SET_VIEW_EDIT_EVENT_MODAL: "SET_VIEW_EDIT_EVENT_MODAL",
  SET_VIEW_CREATE_CALENDAR_TYPE_MODAL: "SET_VIEW_CREATE_CALENDAR_TYPE_MODAL",
  SET_VIEW_EDIT_CALENDAR_TYPE_MODAL: "SET_VIEW_EDIT_CALENDAR_TYPE_MODAL",
  SET_SELECTED_CALENDAR_TYPE: "SET_SELECTED_CALENDAR_TYPE",
  SET_VIEW_DELETE_CALENDAR_TYPE_MODAL: "SET_VIEW_DELETE_CALENDAR_TYPE_MODAL",
  SET_SELECTED_CALENDAR_SLOT: "SET_SELECTED_CALENDAR_SLOT",
  SET_SELECTED_CALENDAR_EVENT: "SET_SELECTED_CALENDAR_EVENT",
  DELETE_CALENDAR_SERIES: "DELETE_CALENDAR_SERIES",
  TOGGLE_LOADING_TYPES: "TOGGLE_LOADING_TYPES",
  TOGGLE_LOADING_TYPES_ERROR: "TOGGLE_LOADING_TYPES_ERROR",
  SET_VIEW: "SET_VIEW"
}

const reducer = (state, action) => {
  switch (action?.type) {
    case ACTIONS.TOGGLE_PENDING_ACTION:
      return { ...state, pending: action?.payload };
    case ACTIONS.GET_CALENDAR_TYPES:
      return {...state, loadingTypes: true }
    case ACTIONS.SET_CALENDAR_TYPES:
      return {...state, calendarTypes: action.payload?.map(p => {
          p.checked = true;
          return p;
        }), loadingTypes: false }
    case ACTIONS.CREATE_CALENDAR_TYPE:
      return {...state, viewCreateCalendarTypeModal: false, calendarTypes: [...state.calendarTypes, action.payload ]};
    case ACTIONS.UPDATE_CALENDAR_TYPE: {
      let updatedCalendarTypes = [...state.calendarTypes];
      updatedCalendarTypes = updatedCalendarTypes?.map(cal => cal._id === action?.payload?._id ? action.payload : cal);
      return {...state, calendarTypes: updatedCalendarTypes};
    }
    case ACTIONS.TOGGLE_FILTER_TYPE: {
      const types = state?.calendarTypes?.map(t => {
        if (t?._id === action?.payload?._id) {
          t.checked = !t.checked
        };
        return t;
      });

      return {
        ...state,
        viewAllFilter: types?.filter(t=>t.checked).length === state?.calendarTypes?.length,
        calendarTypes: types
      }
    }
    case ACTIONS.TOGGLE_FILTER_VIEW_ALL: {
      const viewAllFilter = !state?.viewAllFilter;
      return {
        ...state,
        viewAllFilter,
        calendarTypes: state?.calendarTypes?.map(type => {
          type.checked = viewAllFilter;
          return type;
        })
      }
    }
    case ACTIONS.DELETE_CALENDAR_TYPE:
      return {...state, calendarTypes: state?.calendarTypes?.filter(c => c._id !== action?.payload) };
    case ACTIONS.GET_CALENDAR_EVENTS:
      return {...state, loading: true };
    case ACTIONS.SET_CALENDAR_EVENTS:
      return {...state, events: action?.payload };
    case ACTIONS.CREATE_CALENDAR_EVENT:
      return {...state, viewCreateEventModal: false, events: [...state.events, ...action?.payload ]};
    case ACTIONS.UPDATE_CALENDAR_EVENT:
      return {
        ...state,
        events: state?.events?.map(event => event._id === action?.payload?._id ? action.payload : event),
        error: false,
        pending: false,
        viewEditEventModal: false
      };
    case ACTIONS.DELETE_CALENDAR_EVENT:
      return {...state,
        events: state?.events?.filter(event => event._id !== action?.payload),
        viewEditEventModal: false,
        viewConfirmDeleteDialog: false
      };
    case ACTIONS.SET_ERROR:
      return {...state, error: action?.payload, loading: false };
    case ACTIONS.SET_USER:
      return {...state, user: action.payload };
    case ACTIONS.SET_VIEW_CREATE_EVENT_MODAL:
      return {...state, viewCreateEventModal: action.payload };
    case ACTIONS.SET_VIEW_EDIT_EVENT_MODAL:
      return {...state, viewEditEventModal: action.payload };
    case ACTIONS.SET_VIEW_CREATE_CALENDAR_TYPE_MODAL:
      return {...state, viewCreateCalendarTypeModal: action.payload };
    case ACTIONS.SET_VIEW_EDIT_CALENDAR_TYPE_MODAL:
      return {...state, viewEditCalendarTypeModal: action.payload };
    case ACTIONS.SET_SELECTED_CALENDAR_TYPE:
      return {...state, selectedCalendarType: action?.payload };
    case ACTIONS.SET_VIEW_DELETE_CALENDAR_TYPE_MODAL:
      return {...state, viewDeleteCalendarTypeModal: action?.payload };
    case ACTIONS.SET_SELECTED_CALENDAR_SLOT:
      return { ...state, selectedCalendarSlot: action?.payload };
    case ACTIONS.SET_SELECTED_CALENDAR_EVENT:
      return {...state, selectedCalendarEvent: action?.payload };
    case ACTIONS.TOGGLE_VIEW_DELETE_CALENDAR_EVENT:
      return {...state, viewConfirmDeleteDialog: action?.payload };
    case ACTIONS.TOGGLE_VIEW_DELETE_SERIES_CALENDAR_EVENT:
      return {...state, viewConfirmDeleteSeriesDialog: action?.payload };
    case ACTIONS.DELETE_CALENDAR_SERIES:
      return {
        ...state,
        viewEditEventModal: false,
        viewConfirmDeleteSeriesDialog: false,
        events: state?.events?.filter(e => e.seriesId !== action?.payload )}
    case ACTIONS.SET_TENANT_ID:
      return { ...state, tenantId: action?.payload };
    case ACTIONS.TOGGLE_LOADING_EVENTS:
      return { ...state, loadingEvents: action?.payload}
    case ACTIONS.TOGGLE_LOADING_EVENTS_ERROR:
      return { ...state, loadingEventsError: action?.payload}
    case ACTIONS.TOGGLE_LOADING_TYPES:
      return {...state, loadingTypes: action?.payload };
    case ACTIONS.TOGGLE_LOADING_TYPES_ERROR:
      return { ...state, loadingTypesError: action?.payload };
    case ACTIONS.SET_VIEW:
      return { ...state, view: action?.payload };
    default:
      return {...state};
  }
}

const TenantCalendarProvider = ({ tenantId, children }) => {
  let [state, dispatch] = React.useReducer(reducer, defaultState);


  const fetchEvents = async (dispatch, id) => {
    dispatch({type: ACTIONS.SET_ERROR, payload: false });
    dispatch({type: ACTIONS.TOGGLE_LOADING_EVENTS, payload: true });
    await Promise.all([
      getTenantCalendarEventsAction(dispatch, id),
      getTenantCalendarTypesAction(dispatch, id)
    ]);
  }

  React.useEffect(() => {
    if (tenantId) {
      fetchEvents(dispatch, tenantId);
    }
  }, [ tenantId ]);

  let value = { state, dispatch, fetchEvents };

  return (
    <TenantCalendarContext.Provider value={value}>
      {children}
    </TenantCalendarContext.Provider>
  )

}

const TenantCalendarConsumer = TenantCalendarContext.Consumer;

export { TenantCalendarContext, TenantCalendarProvider, TenantCalendarConsumer }
