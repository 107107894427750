import React from 'react';
import {Document, Page, View, Text, StyleSheet} from "@react-pdf/renderer";
import HeaderWithTenant from "../../../../../reports/pdf/components/headerwithtenant";
import ResidentCard from "../../../../../reports/pdf/components/residentCard";
import moment from "moment-timezone";
import {getUniqueEventTemplates} from "../../../../../utils/table";
import {PDFETarLogItem} from "./PDFETarLogItem";


const BORDER_COLOR = '#bfbfbf'
const BORDER_STYLE = 'solid'

const styles = StyleSheet.create({
  page: {
    padding: '0 0 84px 0'
  },
  headerSection: {
    backgroundColor: '#2196f3',
    color: '#fff',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '10vh'
  },
  mainContent: {
    backgroundColor: 'white',
    padding: '0 5px'
  },
  body: {
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    width: '100%',
    margin: "auto",
    flexDirection: "row"
  },
  tableCol1Header: {
    width: '125px',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol2Header: {
    width: '55px',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableColHeader: {
    width: '25px',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol1: {
    width: '125px',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol2: {
    width: '55px',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  hrContainer: {
    paddingTop: '10px',
    width: '55px',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0

  },
  tableCol: {
    width: '20px',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: 500
  },
  tableCell: {
    margin: 5,
    fontSize: 10
  },
  titleContainer: {
    paddingTop: '15px'
  },
  pageCount: {
    paddingLeft: '5px',
    paddingTop: '15px'
  },
  headerText: {
    fontSize: '20px'
  },
  bodyText: {
    fontSize: '8px'
  },
  centerAlign: {
    textAlign: 'center'
  },
  infoSection: {
    margin: '10 0'
  },
  infoText: {
    fontSize: '12px',
    padding: '3 0'
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    padding: '5px'
  },
  footerContainer: {
    marginTop: '5px',
    fontSize: '8px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  marContainer: {
    padding: '5px'
  },
  marHeaderText: {

  },
  dosageInsructionContainer: {
    paddingTop: '5px'
  },
  hrText: {
    minHeight: '25px',
    textAlign: 'center',
  },
  marValueCell: {
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0
  },
  discontinuedDate: {
    width: '100%',
    padding: '5px',
    backgroundColor: "#9e9e9e",
    color: '#fff'
  },
  alerts: {
    width: '100%',
    padding: '5px',
    backgroundColor: "#e57373",
    color: '#fff'
  },
  hideOverflow: {
    textOverflow: "ellipsis"
  },
  freqContainer: {
    minHeight: '10px',
    textAlign: 'center',
  },
  paddedTableCol: {
    paddingTop: '10px',
    width: '25px',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
});

export const TreatmentReport = ({days, resident, treatments, month, year}) => {

  const getPrnValue = (treatment, day) => {
    const events = treatment?.events?.filter(event =>
      moment.tz(event.scheduledTime, treatment?.resident?.location?.timeZone ?? "US/Central").date() === +day
    );
    if (events?.length === 1) {
      const event = events[0];
      if (event?.status?.name === "Given" && event?.administeredUser) {
        return (
          <View>
            <Text>{`G\n${event?.administeredUser?.initials}`}</Text>
          </View>
        )
      } else if (event?.status?.name === "Refused") {
        return (
          <View>
            <View>
              <Text>{`R\n${event?.administeredUser?.initials}`}</Text>
            </View>
          </View>
        )
      } else if (event?.status?.name === "Scheduled") {
        return " "
      } else if (event?.status?.name === "Late") {
        return "L"
      } else if (event?.status?.name === "Missed") {
        return "M"
      } else if (event?.status?.name === "NA") {
        return (
          <View>
            <Text>{`NA\n${event?.administeredUser?.initials}`}</Text>
          </View>
        )
      } else if (event?.status?.name === "SA") {
        return (
          <View>
            <Text>{`SA\n${event?.administeredUser?.initials}`}</Text>
          </View>
        )
      }
      return (
        <View>
          <Text>{`${event?.status?.name[0]}\n${event?.administeredUser?.initials}`}</Text>
        </View>
      )
    }
    if (events?.length > 1) {
      return (
        <View>
          <Text>{`${events[0]?.status?.name[0]}\n${events[0]?.administeredUser?.initials}`}</Text>
        </View>
      )
    } else {
      return "X"
    }
  }

  const getValue = (treatment, eventTemplate, day) => {
    const everyXHourFrequencys = [ "QH", "Q2H", "Q3H", "Q4H", "Q6H", "Q8H","Q12H" ];
    let isEveryXHoursFrequency = everyXHourFrequencys?.indexOf(treatment?.frequency?.name) > -1
    let events = [];
    if (isEveryXHoursFrequency) {
      events = treatment?.events?.filter(event =>
          moment.tz(event.scheduledTime, treatment?.resident?.location?.timeZone ?? "US/Central").date() === +day
      );
    } else {
      events = treatment?.events?.filter(event =>
          moment.tz(event.scheduledTime, treatment?.resident?.location?.timeZone ?? "US/Central").date() === +day &&
          moment.tz(event.scheduledTime, treatment?.resident?.location?.timeZone ?? "US/Central").hour() === +eventTemplate.hour &&
          moment.tz(event.scheduledTime, treatment?.resident?.location?.timeZone ?? "US/Central").minute() === +eventTemplate.minute
      );
    }

    if (events?.length > 0) {
      return events?.map(event => <PDFETarLogItem etar={event} treatment={treatment} />)
    } else {
      return (
          <View style={{paddingTop: 5}}>
            <Text style={{fontSize: 8, height: 25}}>X</Text>
          </View>
      )
    }
  }

  const getHR = (eventTemplate, frequency) => {
    switch (frequency?.name) {
      case "QH":
      case "Q2H":
      case "Q3H":
      case "Q4H":
      case "Q6H":
      case "Q12H":
        return frequency.description;
      default:
        const date = moment({hour: eventTemplate?.hour, minute: eventTemplate?.minute});
        return `${date?.format("h:mm a")}`;
    }
  }


  return (
    <Document size={"a4"} orientation={"landscape"} >
      <Page size={"A4"} orientation={'landscape'} style={styles.page}>
        <HeaderWithTenant
          reportTitle={`Treatment Report`}
          reportSubtitle={`${resident?.firstName} ${resident?.lastName}\n${year} - ${moment({month: month - 1}).format("MMMM")}`}
          location={resident?.location}
          tenant={resident?.location?.tenant}
          allUserLocations={false}
        />
        <View style={styles.mainContent}>
          <View style={styles.table}>
            <View style={styles.tableRow} fixed>
              <View style={styles.tableCol1Header}>
                <Text style={styles.tableCellHeader}>Task</Text>
              </View>
              <View style={styles.tableCol2Header}>
                <Text style={[styles.tableCellHeader, styles.centerAlign]}>HR</Text>
              </View>
              {[...Array(days)].map((e, i) => (
                <View key={i} style={styles.tableColHeader}>
                  <Text style={[styles.tableCellHeader, styles.bodyText, styles.centerAlign]}>{i + 1}</Text>
                </View>
              ))}
            </View>
            {treatments?.filter(treatment => !treatment?.isPrn)?.map(treatment =>
              <View style={styles.tableRow}>
                <View style={styles.tableCol1} fixed>
                  <Text style={[styles.tableCell, styles.bodyText, styles.hideOverflow]}>
                    {treatment.name}
                  </Text>
                </View>
                <View style={styles.hrContainer}>
                  {getUniqueEventTemplates(treatment?.eventTemplates)?.map((template, i) =>
                    <View key={i} style={styles.hrText}>
                      <Text style={[styles.tableCell, styles.bodyText, styles.hideOverflow]}>
                        {getHR(template, treatment?.frequency)}
                      </Text>
                    </View>
                  )}
                </View>
                {[...Array(days)].map((e, day) => (
                  <View  style={styles.paddedTableCol}>
                    {getUniqueEventTemplates(treatment?.eventTemplates)?.map((eventTemplate, i) =>
                      <View style={styles.hrText}>
                        {getValue(treatment, eventTemplate, day + 1)}
                      </View>
                    )}
                  </View>
                ))}
              </View>
            )}
            {treatments?.filter(m => m?.isPrn)?.length > 0 &&
              <View>
                {/* eslint-disable-next-line no-sequences */}
                <View style={[styles.marContainer, { borderBottom: '1px solid black'}]}>
                  <Text style={styles.tableCellHeader}>PRN</Text>
                </View>
              </View>
            }
            {treatments?.filter(treatment => treatment?.isPrn)?.map(treatment =>
              <View style={styles.tableRow}>
                <View style={styles.tableCol1} fixed>
                  <Text style={[styles.tableCell, styles.bodyText, styles.hideOverflow]}>
                    {treatment.name}
                  </Text>
                </View>
                <View style={styles.hrContainer}>
                    <View style={styles.hrText}>
                      <Text style={[styles.bodyText]}>NA</Text>
                    </View>
                </View>
                {[...Array(days)].map((e, day) => (
                  <View  style={styles.paddedTableCol}>
                    <View style={styles.hrText}>
                      <Text style={styles.bodyText}>{getPrnValue(treatment, day + 1)}</Text>
                    </View>
                  </View>
                ))}
              </View>
            )}
          </View>
        </View>
        <View style={styles.footer} fixed>
          <View>
            <ResidentCard resident={resident} />
          </View>
          <View style={styles.footerContainer}>
            <View>
              <Text>
                {moment().format("MM/DD/YYYY hh:mm a")}
              </Text>
            </View>
            <View>
              <Text render={({ pageNumber, totalPages }) => (
                `page ${pageNumber} of ${totalPages}`
              )} fixed />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}
