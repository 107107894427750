import React from 'react';
import {
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem, ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import {clockOutAction, TimeEntryContext, toggleTimeout} from "../../context/TimeEntryContext/time.entry.context";
import moment from "moment/moment";
import {AlarmOff, AlarmOn} from "@material-ui/icons";
import MomentUtils from "@date-io/moment";
import {KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";


const useStyles = makeStyles((theme) => ({
  mainContent: {
    textAlign: "center"
  },
  clockBtnContainer: {
    padding: "2rem",
    textAlign: "center"
  },
  clockInBtn: {
    height: "5rem"
  }
}));

export const ClockOutDialog = () => {
  const { state, dispatch } = React.useContext(TimeEntryContext);
  const [clockOutDate, setClockOutDate] = React.useState(new Date());
  const { user, currentTimeClock, viewClockOut } = state;
  const classes = useStyles();

  React.useEffect(() => {
    if (viewClockOut) {
      setClockOutDate(new Date());
    }
  }, [viewClockOut]);

  const onClockOut = async () => {
    const data = {
      user: user?._id,
      date: clockOutDate
    };
    await clockOutAction(dispatch, user?._id, data, state?.currentTimeEntries);
  }

  const onClockInChange = date => {
    setClockOutDate(date);
  }

  return (
    <Dialog open={viewClockOut}
            maxWidth={"md"}
            fullWidth={true}
    >
      <DialogTitle>Clock Out</DialogTitle>
      <DialogContent dividers>
        <List>
          {currentTimeClock?.events?.map(timeEntry =>
            <ListItem button>
              <ListItemIcon>
                {timeEntry?.type === "CLOCK_IN" ? <AlarmOn />: <AlarmOff />}
              </ListItemIcon>
              <ListItemText primary={
                <>
                  {timeEntry?.type === "CLOCK_IN" ? "Time In:": "Time Out"} {moment(timeEntry?.dateTime).format("h:mm a")}
                </>
              }
              />
            </ListItem>
          )}
        </List>
        <div className={classes.clockBtnContainer}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardTimePicker
              fullWidth
              mask="__:__ _M"
              label={"Clock Out"}
              disabled
              value={clockOutDate}
              name={"end"}
              onChange={onClockInChange}
              style={{marginTop: '1rem', marginBottom: '1rem'}}
            />
          </MuiPickersUtilsProvider>
          <Button variant={"contained"}
                  className={classes.clockInBtn}
                  color={"primary"}
                  onClick={onClockOut}
                  size={"large"}>
            Clock Out
          </Button>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant={"text"}
                onClick={() => toggleTimeout(dispatch, false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
