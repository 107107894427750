import React from 'react';
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  makeStyles,
  TextField,
  TableCell, Grid,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

const getStyles = makeStyles((theme) => ({
  root: {
    margin: '0 1rem 0 0',
    padding: theme.spacing(1),
    minWidth: '20rem',
  },
  form: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  formGroup: {
    marginTop: theme.spacing(1),
  },
  formControl: {
    width: '100%',
  },
}));

const months = [];
moment.months().forEach((month, i) => {
  months.push({ value: i + 1, title: month });
});

let initDays = [...Array(31)].map((e, i) => (
    {
      title: i + 1,
      value: i + 1
    }
));

const YearlyEvent = (props) => {
  const classes = getStyles();
  const [eventTemplate, setEventTemplate] = React.useState(props.eventTemplate || {});
  const [passingQty, setPassingQty] =
      React.useState(props.eventTemplate ? props.eventTemplate.passingQty : null);
  const [days, setDays] = React.useState(initDays);
  const [selectedDate, setSelectedDate] = React.useState(moment({hour: 8, minute: 0}));

  React.useEffect(() => {
    if (eventTemplate?.month) {
      const month = moment().month(eventTemplate?.month);
      const daysInMonth = month.daysInMonth();
      let monthDays = [];
      for (let i = 1; i <= daysInMonth; i++) {
        monthDays.push({ title: i, value: i });
      }
      setDays(monthDays);
    }
  }, [eventTemplate.month]);

  React.useEffect(() => {
    setEventTemplate(props.eventTemplate || {});
    setSelectedDate(date => moment(date).set({hour: props.eventTemplate?.hour, minute: props.eventTemplate?.minute}))
  }, [props.eventTemplate]);

  const onMonthChange = (e) => {
    const updated = { ...eventTemplate };
    updated.month = e.target.value;
    updated.day = null;
    setEventTemplate(updated);
    emitUpdate(updated);
  };

  const onDayChange = (e) => {
    const updated = { ...eventTemplate };
    updated.day = e.target.value;
    setEventTemplate(updated);
    emitUpdate(updated);
  };

  const onTimeChange = (date) => {
    setSelectedDate(date);
    const dt = moment(date);
    const hour = moment(dt).format('H');
    const minute = moment(dt).format('mm');
    const updated = { ...eventTemplate, hour, minute };
    setEventTemplate(updated);
    emitUpdate(updated);
  };

  const onQtyChange = (e) => {
    if (!e.target.value || e.target.value === "." || !isNaN(e.target.value)) {
      let val = e.target.value.trim();
      setPassingQty(val);
      const updated = {...eventTemplate, passingQty: val};
      setEventTemplate(updated);
      emitUpdate(updated);
    }
  };

  const emitUpdate = (eventTemplate) => {
    if (props.onChange) {
      props.onChange(eventTemplate);
    }
  };

  return (
    <React.Fragment>
      <TableCell style={{paddingBottom: '1.25rem'}}>
        <Grid container justifyContent={"space-between"}>
          <Grid item xs={8}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="month">Month</InputLabel>
              <Select
                value={eventTemplate.month || null}
                onChange={onMonthChange}
                disabled={!props.edit}
                inputProps={{
                  name: `day`,
                  id: `day`,
                }}
              >
                {months &&
                months.map((month, i) => {
                  return (
                    <MenuItem key={i} value={month.value}>
                      {month.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="day">Day</InputLabel>
              <Select
                  displayEmpty
                  defaultValue = ""
                value={eventTemplate.day || null || ""}
                onChange={onDayChange}
                disabled={!props.edit}
                inputProps={{
                  name: `day`,
                  id: `day`,
                }}
              >
                {days?.map((day, i) => {
                  return (
                    <MenuItem key={i} value={day.value}>
                      {day.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <TimePicker
            fullWidth
            format="h:mm a"
            value={selectedDate}
            onChange={onTimeChange}
            ampm={false}
            disabled={!props.edit}
          />
        </MuiPickersUtilsProvider>
      </TableCell>
      {props?.showQty &&
      <TableCell>
        <TextField
          fullWidth
          value={passingQty}
          onChange={onQtyChange}
          disabled={!props.edit}
        />
      </TableCell>
      }
    </React.Fragment>
  );
};

export default YearlyEvent;
