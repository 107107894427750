import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles} from "@material-ui/core";
import {CareplanContext} from "./context";
import {ACTIONS} from "./context/constants";
import {CareplanModel} from "./careplan.model";
import {FormFieldsGenerator, isModelValid} from "../../../utils/forms";
import CloseIcon from "@material-ui/icons/Close";
import {createCareplanAction} from "./context/actions";


const useStyles = makeStyles((theme) => ({
    root: {
    },
    dialogContent: {
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

export const CreateCareplanDialog = () => {
    const classes = useStyles();
    const {state, dispatch} = React.useContext(CareplanContext);

    React.useEffect(() => {
        if (state?.viewCreateCareplanDialog) {
            const obj = JSON.parse(JSON.stringify(CareplanModel));
            dispatch({type: ACTIONS.SET_MODEL, payload: obj});
        }
        // eslint-disable-next-line
    }, [state?.viewCreateCareplanDialog]);


    const handleClose = () => {
        dispatch({type: ACTIONS.SET_VIEW_CREATE_CAREPLAN_DIALOG, payload: false})
    }

    const onChange = e => {
        const obj = { ...state?.model, ...e };
        dispatch({type: ACTIONS.SET_MODEL, payload: obj});
    }

    const handleCreate = async () => {
        let data = {};
        Object.keys(state?.model).forEach(key => {
            data[key] = state.model[key].value
        });
        await createCareplanAction(dispatch, state?.residentId, data);
    }

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={state?.viewCreateCareplanDialog}
            fullWidth={true}
            maxWidth={'lg'}
        >
            <DialogTitle>
                Create Careplan
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    className={classes.closeButton}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <FormFieldsGenerator model={state?.model}
                                     onChange={onChange} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                <Button onClick={handleCreate}
                        disabled={!isModelValid(state?.model)}
                        variant={"contained"} color={"primary"}>
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    )
}
