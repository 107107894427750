import React from 'react';
import { makeStyles, TextField, Typography} from "@material-ui/core";
import Comment from "./comment";
import NewComment from "./new.comment";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import useGlobalNotification from "../../../hooks/notification.hook";


const useStyles = makeStyles((theme) => ({
  commentHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));

const NoteComments = ({note, onSave}) => {
  const classes = useStyles();
  const [newFocus, setNewFocus] = React.useState(false);
  const [sort, setSort] = React.useState('desc');
  const [comments, setComments] = React.useState();
  const { addNotification } = useGlobalNotification();

  React.useEffect(() => {
    setComments(note?.comments);
  }, [note])

  const setFocus = () => {
    setNewFocus(true);
  }

  const save = payload => {
    setNewFocus(false);
    onSave(payload);
  }

  const toggleSort = () => {
    const sortDirection = sort === 'desc' ? 'asc': 'desc'
    setSort(sortDirection);
    const obj = comments.reverse();
    setComments(obj);
  }

  const onDelete = id => {
    setComments(comments.filter(comment => comment._id !== id));
    addNotification("Comment deleted", "warning");
  }


  return (
    <div style={{width: '100%'}}>
      <div className={classes.commentHeader}>
        <Typography variant={"subtitle2"}>Comments</Typography>
        {comments?.length > 1 &&
        <IconButton onClick={toggleSort}>
          {sort === 'desc' ?  <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        </IconButton>}
      </div>
      {comments?.map((comment, i) => <Comment note={note} index={i} comment={comment} onDelete={onDelete} />)}
      {!newFocus &&
        <TextField onClick={setFocus} fullWidth={true} placeholder={"Add a comment"} size={"small"} variant={"outlined"} />
      }
      {newFocus &&
        <NewComment onSave={save} onCancel={() => setNewFocus(false)} setFocus={newFocus} />
      }
    </div>
  )
}

export default NoteComments;
