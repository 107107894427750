export const FilterTemplate = {
  isActive: {
    field: 'isActive',
    operator: '==',
    dropDownItems: [{
      name: "Active",
      value: true
    }, {
      name: "Inactive",
      value: false
    }],
    dropDownItemLabel: 'name',
    dropDownItemValue: 'value',
    minValue: 1,
    value: "Active"
  },
  treatment: {
    field: "treatment",
    minValue: 3,
    value: null,
    operator: "*=*",
  },
  description: {
    field: "description",
    minValue: 3,
    value: null,
    operator: "*=*",
  },
  isPrn: {
    field: 'isPrn',
    operator: '==',
    dropDownItems: [{
      name: "All",
    }, {
      name: "Yes",
      value: "yes"
    }, {
      name: "No",
      value: "no"
    }],
    dropDownItemLabel: 'name',
    dropDownItemValue: 'value',
    minValue: 1,
  }
}
