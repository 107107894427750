import React from 'react'
import { Typography } from '@material-ui/core'

const PhysicianList = () => {
    return (
        <div>
            <Typography variant="h4">Physician List</Typography>
        </div>
    )
}

export default PhysicianList;