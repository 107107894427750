import React from 'react';
import {List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  deleteIcon: {
    color: theme.palette.error.main
  }
}));

export const MobileLogs = ({logs, selectLog, onDeleteLog, primaryText, secondaryText, canUpdate}) => {
  const classes = useStyles();

  return (
    <List>
      {logs?.map(log =>
        <ListItem divider
                  button
                  onClick={() => selectLog(log)}
        >
          <ListItemText primary={primaryText(log)}
                        secondary={secondaryText(log)}
          />

          {canUpdate &&
          <ListItemSecondaryAction>
            <IconButton edge="end"
                        aria-label="delete" onClick={() => onDeleteLog(log._id)}>
              <DeleteIcon className={classes.deleteIcon} />
            </IconButton>
          </ListItemSecondaryAction>
          }

        </ListItem>
      )}
    </List>
  )
}
