import React from 'react';
import {
  Paper,
  makeStyles,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  Button,
  TableBody,
  TableRow, TablePagination, Chip, Grid, ButtonGroup, Hidden,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import CreateIncidentLog from './create.incident.log';
import IncidentLog from './incident.log';
import {DateRangeSortFilterLabel, SortFilterLabel, SortFilterSelectLabel} from "../../../utils/table.utils";
import TableLoadingIndicator from "../../../../utils/indicators/table.loading";
import {ClearAllTwoTone} from "@material-ui/icons";
import {MobileLogs} from "../mobile/mobile.logs";
import {ListLoading} from "../../../../utils/indicators/list.loading";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import {IncidentReportDialog} from "./print";
import {ResidentContext} from "../../../../context/residentcontext";
import useHasRole from "../../../../hooks/user.role.hook";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(.5, 0),
    padding: theme.spacing(1),
  },
}));

const ResidentIncidentLogs = ({
                                logs,
                                onSave,
                                onUpdateLog,
                                onDeleteLog,
                                date,
                                canUpdate,
                                filter,
                                sortOrderBy,
                                sortDirection,
                                createSortHandler,
                                filterTextChange,
                                onDateRangeChange,
                                loading,
                                users,
                                page,
                                size,
                                total,
                                onChangePage,
                                onChangeRowsPerPage,
                                rowsPerPageOptions,
  onClearFilters,
  ...args
}) => {
  const classes = useStyles();
  const [createIncontinence, setCreateIncontinence] = React.useState(false);
  const [viewLogModal, setViewLogModal] = React.useState(false);
  const [selectedLog, setSelectedLog] = React.useState();
  const [viewIncidentReportDialog, setViewIncidentReportDialog] = React.useState(false);
  const { resident } = React.useContext(ResidentContext);
  const { checkPermission} = useHasRole();
  const isReportPrinter = checkPermission("REPORT_PRINTER");
  const canUpdateIncidentLog = checkPermission("INCIDENT_REPORT_UPDATE");

  const createIncontinenceLog = (log) => {
    onSave(log);
    setCreateIncontinence(false);
  };

  const selectLog = (e) => {
    setSelectedLog(e);
    setViewLogModal(true);
  };

  const onUpdate = (id, log) => {
    onUpdateLog(id, log);
    setViewLogModal(false);
  };

  const handleDeleteLog = id => {
    setViewLogModal(false);
    onDeleteLog(id);
  }

  const handlePrint = log => {
    setSelectedLog(log);
    setViewIncidentReportDialog(true);
  }
  return (
    <Paper elevation={24} className={classes.root} {...args}>
      <Typography variant="h6">Incident Logs</Typography>
      {canUpdate &&
      <Button startIcon={<AddIcon />}
              color={"primary"}
              variant={"contained"}
              onClick={() => setCreateIncontinence(true)}>
        Add New Log
      </Button>}
      <CreateIncidentLog
        open={createIncontinence}
        onSave={createIncontinenceLog}
        onClose={() => setCreateIncontinence(false)}
        date={date}
      />
      <IncidentLog
        open={viewLogModal}
        log={selectedLog}
        onClose={() => setViewLogModal(false)}
        onUpdate={onUpdate}
        canUpdate={canUpdateIncidentLog}
        onDeleteLog={handleDeleteLog}
      />
      <IncidentReportDialog open={viewIncidentReportDialog}
                            onClose={() => setViewIncidentReportDialog(false)}
                            data={selectedLog}
                            resident={resident}
      />
      <Grid container justify={"flex-end"} alignItems={"center"}>
        <Hidden xsDown>
          <Button onClick={onClearFilters}
                  size={"small"}
                  startIcon={<ClearAllTwoTone />}
          >
            Clear Filters
          </Button>
        </Hidden>
        <TablePagination component={"div"}
                         count={total}
                         onChangePage={onChangePage}
                         onChangeRowsPerPage={onChangeRowsPerPage}
                         rowsPerPageOptions={rowsPerPageOptions}
                         page={page - 1}
                         rowsPerPage={size} />
      </Grid>
      <Hidden xsDown>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableCell />
              <TableCell>
                <DateRangeSortFilterLabel  onDateRangeChange={onDateRangeChange}
                                           sortDirection={sortDirection}
                                           sortOrderBy={sortOrderBy}
                                           filter={filter}
                                           fromDate={filter?.incidentDate?.value?.start}
                                           toDate={filter?.incidentDate?.value?.end}
                                           field={"incidentDate"}
                                           label={"Incident Date"} />
              </TableCell>
              <TableCell>
                <DateRangeSortFilterLabel onDateRangeChange={onDateRangeChange}
                                          sortDirection={sortDirection}
                                          sortOrderBy={sortOrderBy}
                                          filter={filter}
                                          fromDate={filter?.date?.value?.start}
                                          toDate={filter?.date?.value?.end}
                                          field={"date"}
                                          label={"Date"} />
              </TableCell>
              <TableCell>
                <SortFilterLabel filterTextChange={filterTextChange}
                                 createSortHandler={createSortHandler}
                                 sortDirection={sortDirection}
                                 sortOrderBy={sortOrderBy}
                                 filter={filter}
                                 field={"category"}
                                 label={"Category"} />
              </TableCell>
              <TableCell>
                <SortFilterLabel filterTextChange={filterTextChange}
                                 createSortHandler={createSortHandler}
                                 sortDirection={sortDirection}
                                 sortOrderBy={sortOrderBy}
                                 filter={filter}
                                 field={"location"}
                                 label={"Location"} />
              </TableCell>
              <TableCell>
                <SortFilterSelectLabel onChange={filterTextChange}
                                       createSortHandler={createSortHandler}
                                       sortDirection={sortDirection}
                                       sortOrderBy={sortOrderBy}
                                       filter={filter}
                                       field={"staff"}
                                       label={"Staff"}
                                       options={users}
                                       optionLabelFunc={(item) => `${item?.firstName} ${item?.lastName}`}
                                       optionValue={"_id"}
                />
              </TableCell>
              <TableCell>
                <SortFilterLabel filterTextChange={filterTextChange}
                                 createSortHandler={createSortHandler}
                                 sortDirection={sortDirection}
                                 sortOrderBy={sortOrderBy}
                                 filter={filter}
                                 field={"nature"}
                                 label={"Nature"} />
              </TableCell>
              <TableCell>
                <SortFilterLabel filterTextChange={filterTextChange}
                                 createSortHandler={createSortHandler}
                                 sortDirection={sortDirection}
                                 sortOrderBy={sortOrderBy}
                                 filter={filter}
                                 field={"description"}
                                 label={"Description"} />
              </TableCell>
              <TableCell>
                <SortFilterLabel filterTextChange={filterTextChange}
                                 createSortHandler={createSortHandler}
                                 sortDirection={sortDirection}
                                 sortOrderBy={sortOrderBy}
                                 filter={filter}
                                 field={"witness"}
                                 label={"Witness"} />
              </TableCell>
              <TableCell>
                <SortFilterLabel filterTextChange={filterTextChange}
                                 createSortHandler={createSortHandler}
                                 sortDirection={sortDirection}
                                 sortOrderBy={sortOrderBy}
                                 filter={filter}
                                 field={"conditionBefore"}
                                 label={"Condition Before"} />
              </TableCell>
              <TableCell>
                <SortFilterLabel filterTextChange={filterTextChange}
                                 createSortHandler={createSortHandler}
                                 sortDirection={sortDirection}
                                 sortOrderBy={sortOrderBy}
                                 filter={filter}
                                 field={"conditionAfter"}
                                 label={"Condition After"} />
              </TableCell>
              <TableCell>Notifications</TableCell>
            </TableHead>
            {!loading &&
            <TableBody>
              {logs?.map((log) => (
                  <TableRow>
                    <TableCell>
                      <ButtonGroup size={"small"}>
                        {isReportPrinter &&
                          <Button variant={"contained"}
                                  startIcon={<PictureAsPdfIcon/>}
                                  color={"primary"}
                                  onClick={() => handlePrint(log)}
                          >
                            Print
                          </Button>
                        }
                        <Button onClick={() => selectLog(log)}
                                variant={"text"}
                                size={"small"}
                                color={"primary"}
                        >
                          View
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                    <TableCell>
                      {moment(log.incidentDate).format('MM/DD/YYYY')}
                    </TableCell>
                    <TableCell>{moment(log.date).format('MM/DD/YYYY')}</TableCell>
                    <TableCell>
                      {log?.category?.map(c => <Chip size={"small"} label={c} /> )}
                    </TableCell>
                    <TableCell>{log.location}</TableCell>
                    <TableCell>{log.staff && `${log.staff.name}`}</TableCell>
                    <TableCell>{log.nature}</TableCell>
                    <TableCell>{log.description}</TableCell>
                    <TableCell>{log.witness}</TableCell>
                    <TableCell>{log.conditionBefore}</TableCell>
                    <TableCell>{log.conditionAfter}</TableCell>
                    <TableCell>
                      {log.notifications ? log.notifications.length : null}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>}
            {loading && <TableLoadingIndicator cols={12} rows={5} bodyOnly={true} /> }
          </Table>
        </TableContainer>
      </Hidden>
      <Hidden smUp>
        {!loading &&
        <MobileLogs logs={logs}
                    primaryText={(log) => log?.category?.map(c =>
                      <Chip color={"primary"} style={{ marginRight: '.25rem'}} size={"small"} label={c} />
                    )}
                    secondaryText={(log) =><>
                      {moment(log?.date).format('MM/DD/YYYY h:mm a')}
                      <Typography variant={"subtitle2"}>{log?.description}</Typography>
                    </>}
                    canUpdate={canUpdateIncidentLog}
                    onDeleteLog={onDeleteLog}
                    selectLog={selectLog}
        />}
        {loading &&
        <ListLoading rows={4} />
        }
      </Hidden>
    </Paper>
  );
};

export default ResidentIncidentLogs;
