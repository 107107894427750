import React, {useCallback} from 'react';
import { getResidents } from './actions/resident.actions';
import filterTemplate from './filterTemplate';
import { convertToFilters } from '../../../utils/filter.utils';
import { getResidentStatuses } from '../../../services/resident.service';
import {UserContext} from "../../../context/usercontext";

let ResidentListContext = React.createContext();
let initialState = {
  displayTable: true,
  tenantId: null,
  tenantLocationId: null,
  page: 0,
  pageCount: 0,
  size: 10,
  sort: 'lastName',
  sortDirection: 'asc',
  filters: [],
  residents: [],
  sizeOptions: [5, 10, 15, 25],
  token: null,
  loading: false,
  error: false,
  errorMessage: null,
  filter: JSON.parse(JSON.stringify(filterTemplate)),
  residentStatuses: [],
};

let reducer = (state, action) => {
  switch (action.type) {
    case 'setToken':
      return { ...state, token: action.payload };
    case 'setTenantId':
      return { ...state, tenantId: action.payload };
    case 'setTenantLocations': {
      return { ...state, tenantLocations: action.payload };
    }
    case 'setLocationTenantId':
      return { ...state, tenantLocationId: action.payload };
    case 'setDisplayType':
      return { ...state, displayTable: action.payload };
    case 'search':
      return { ...state };
    case 'setPage':
      return { ...state, page: action.payload };
    case 'setSize':
      return { ...state, size: action.payload };
    case 'setSort':
      return { ...state, sort: action.payload };
    case 'setSortOrderDirection':
      return { ...state, sortDirection: action.payload };
    case 'setLoading':
      return { ...state, error: false, loading: true };
    case 'setResidents':
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        residents: action?.payload?.content || [],
        page: action?.payload?.paginator?.currentPage - 1 || 0,
        size: action?.payload?.paginator?.perPage || 25,
        total: action?.payload?.paginator?.totalItems || 0,
        pageCount: action?.payload?.paginator?.pageCount || 0
      };
    case 'searchResidentsError':
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action?.payload,
        residents: action?.payload?.data || [],
        page: action?.payload?.paginator?.currentPage - 1 || 0,
        size: action?.payload?.paginator?.perPage || 25,
        total: action?.payload?.paginator?.totalItems || 0,
        pageCount: action?.payload?.paginator?.pageCount || 0
      };
    case 'setFilters': {
      return { ...state, filter: { ...state.filter, ...action.payload } };
    }
    case 'CLEAR_FILTERS': {
      const newFilterObject = JSON.parse(JSON.stringify(filterTemplate));
      newFilterObject.tenantLocationId = state.filter.tenantLocationId;
      return { ...state, filter: {...newFilterObject} };
    }
    case 'updateFilter': {
      return { ...state, filter: action.payload };
    }
    case 'addNewResident': {
      let residents = [];
      if (state?.residents?.findIndex(i => i._id === action?.payload?._id) > -1) {
        residents = state?.residents?.map(resident => resident?._id === action?.payload?._id ? action?.payload: resident);
      } else {
        residents = [action?.payload, ...state?.residents];
      }
      return { ...state, residents: residents };
    }
    case 'setResidentStatuses': {
      return { ...state, residentStatuses: action.payload };
    }
    case 'RESIDENT_UPDATED': {
      return {
        ...state,
        residents: state?.residents?.map(resident => resident?._id === action?.payload?._id ? action.payload: resident)
          ?.filter(r => state?.tenantLocationId === -1 || r?.location?._id === state?.tenantLocationId)
      }
    }
    default:
      return { ...state };
  }
};

const ResidentListProvider = ({
  children,
}) => {
  let [state, dispatch] = React.useReducer(reducer, initialState);
  const updateResidentInList = payload => {
    dispatch({type: 'RESIDENT_UPDATED', payload: payload})
  }
  let value = {
    state,
    dispatch,
    updateResidentList: useCallback(
      (resident) => {
        updateResidentInList(resident);
      }, []
    )
  };
  const { selectedTenant, selectedTenantLocation, tenantLocations } = React.useContext(UserContext);

  React.useEffect(() => {
    if (selectedTenant && selectedTenantLocation && tenantLocations && state?.residentStatuses?.length > 0) {
      dispatch({ type: 'setTenantLocations', payload: tenantLocations});
      dispatch({ type: 'setTenantId', payload: selectedTenant?._id });
      dispatch({ type: 'setLocationTenantId', payload: selectedTenantLocation?._id || -1 });
        fetchResidents(selectedTenantLocation?._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedTenant, selectedTenantLocation, tenantLocations, state?.residentStatuses]);

  const fetchResidents = async (tenantLocationId) => {
    dispatch({ type: 'setLoading' });
    const obj = { ...state.filter };
    const admitted = state?.residentStatuses?.find(s => s.name === 'Admitted');
    obj.residentStatus.value = admitted?._id ?? -1;
    dispatch({type: 'setFilters', payload: obj});
    if (tenantLocationId === -1) {
      obj.tenantLocationId.value = state.tenantLocations.map((location) => location._id);;
    } else {
      obj.tenantLocationId.value = [tenantLocationId];
    }
    // dispatch({ type: 'setFilters', payload: obj });
    const filters = convertToFilters(obj);
    const data = await getResidents(state.page, state.size, filters);
    if (!data?.error) {
      dispatch({ type: 'setResidents', payload: data });
    } else {
      dispatch({type: 'searchResidentsError'})
    }
  };

  React.useEffect(() => {
    // dispatch({type: 'CLEAR_FILTERS'})
    const getStatuses = async () => {
      const result = await getResidentStatuses();
      dispatch({ type: 'setResidentStatuses', payload: !result?.error ? result: [] });
    };
    if (!state.residentStatuses || state.residentStatuses.length === 0) {
      getStatuses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ResidentListContext.Provider value={value}>
      {children}
    </ResidentListContext.Provider>
  );
};

let ResidentListConsumer = ResidentListContext.Consumer;

export { ResidentListContext, ResidentListProvider, ResidentListConsumer };
