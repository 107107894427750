import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import Transition from "../dialog/transition";
import {
  Dialog,
  Button,
  AppBar,
  Grid,
  Toolbar,
  IconButton,
  Typography,
  DialogContent,
  TablePagination,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TextField,
  TableBody,
  Checkbox,
  FormControlLabel,
  DialogActions, Divider, TableContainer
} from "@material-ui/core";
import {createAllergy, getAllergies} from '../../services/allergy.service';
import {GlobalNotificationContext} from "../notification/globalnotificationprovider";
import AddIcon from "@material-ui/icons/Add";
import CreateAllergy from "./create.allergy";
import {convertToFilters} from "../../utils/filter.utils";
import { Link } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1),
    paddingTop: "4rem"
  },
  appBar: {
    background: blue[500]
  },
  dialogContent: {
    marginTop: "4rem"
  }
}));

const filterTemplate = {
  name: {
    field: 'name',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
  description: {
    field: 'description',
    minValue: 3,
    value: null,
    operator: '*=*',
  },
}

const sizeOptions = [1, 5, 10, 25];

const AllergyLookup = ({ open, onClose, onSelect, items }) => {
  const classes = useStyles();
  const { addNotification } = React.useContext(GlobalNotificationContext);
  const [viewCreateAllergy, setViewCreateAllergy] = React.useState(false);
  const [total, setTotal] = React.useState(0);
  const [size, setSize] = React.useState(25);
  const [page, setPage] = React.useState(1);
  const [allergies, setAllergies] = React.useState([]);
  const [sortOrderBy, setSortOrderBy] = React.useState("name");
  const [sortOrderDirection, setSortOrderDirection] = React.useState("asc");
  const [filter, setFilter] = React.useState(JSON.parse(JSON.stringify(filterTemplate)));

  const filterTextChange = e => {
    const obj = { ...filter };
    obj[e.target.name].value = e.target.value;
    setFilter(obj);
    if (
      String(obj[e.target.name].value).length === 0 ||
      String(obj[e.target.name].value).length >= obj[e.target.name].minValue
    ) {
      loadAllergies(page, size, sortOrderBy, sortOrderDirection, obj);
    }
  };

  const clearFilters = () => {
    setFilter(JSON.parse(JSON.stringify(filterTemplate)));
    loadAllergies(1, 25, 'name', '+', JSON.parse(JSON.stringify(filterTemplate)));
  }

  React.useEffect(() => {
    if (open) {
      loadAllergies();
      setFilter(JSON.parse(JSON.stringify(filterTemplate)));
    }
    // eslint-disable-next-line
  }, [ open ]);

  const onChangeRowsPerPage = (e) => {
    setSize(e.target.value);
    loadAllergies(page, e.target.value, sortOrderBy, sortOrderDirection, filter);
  };

  const handleChangePage = (e, newPage) => {
    const pg = newPage + 1;
    setSize(pg);
    loadAllergies(pg, size, sortOrderBy, sortOrderDirection, filter);
  };

  const loadAllergies = async (pg = 1,
                               sz = 25,
                               srt = "name",
                               srtDirection = "+",
                               filter = {}) => {
    const filters = convertToFilters(filter);
    const response = await getAllergies(pg, sz, srt, srtDirection, filters);
    if (response?.content) {
      setAllergies(response.content.map(al => {
        const itemFound = items?.find(i => i._id  === al._id);
        al.isSelected = itemFound ? true: false;
        return al;
      }));
      setSize(response.paginator.perPage);
      setPage(response.paginator.currentPage);
      setTotal(response.paginator.totalItems);
    } else {
      addNotification('Unable to get allergies', 'error')
      setAllergies([])
    }
  }

  const createSortHandler = field => {
    const sort = field;
    const sortDirection = sortOrderDirection === "asc" ? "desc" : "asc";
    setSortOrderBy(sort);
    setSortOrderDirection(sortDirection);
    loadAllergies(page, size, sort, sortDirection, [])
  };

  const selectAllergies = () => {
    onSelect(allergies.filter(allergy => allergy.isSelected));
  }

  const handleClick = allergy => {
    const updated = allergies.map(a => {
      if (a._id === allergy._id) {
        a.isSelected = !a.isSelected;
      }
      return a;
    });
    setAllergies(updated);
  }

  const disableSelect = () => {
    return false;
  }

  const onAllergyCreated = async allergy => {
    const entity = await createAllergy(allergy);
    const obj = [...allergies, entity];
    setAllergies(obj);
    setViewCreateAllergy(false);
  }

  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={Transition}
      onClose={onClose}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Search Allergies
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.dialogContent}>
        <TableContainer>
          <Grid container justify={"space-between"} alignContent={"center"}>
            <div>
              <Button variant={"contained"}
                      color={"primary"}
                      startIcon={<AddIcon />}
                      onClick={() => setViewCreateAllergy(true)}
                      style={{marginRight: '1rem'}}
                      size={"small"}>Create</Button>
              <Button size={"small"}
                      onClick={clearFilters}
                      startIcon={<CloseIcon />}>Clear Filters</Button>
              <CreateAllergy open={viewCreateAllergy}
                             onClose={() => setViewCreateAllergy(false)}
                             onSave={onAllergyCreated} />
            </div>
            <TablePagination
              component="div"
              rowsPerPageOptions={sizeOptions}
              colSpan={5}
              count={total ? total : 0}
              rowsPerPage={size ? size : 0}
              page={page - 1}
              backIconButtonProps={{
                "aria-label": "previous page",
                disabled: page === 1 ? true : false
              }}
              nextIconButtonProps={{
                "aria-label": "next page",
                disabled: page * size >= total ? true : false
              }}
              onChangeRowsPerPage={onChangeRowsPerPage}
              onChangePage={handleChangePage}
            ></TablePagination>
          </Grid>
          <Table
            size="small"
            style={{ width: "100%" }}
            className={classes.table}
            id="pharmacy-table"
          >
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell className={classes.tableHeader}>
                  <TableSortLabel
                    active={sortOrderBy === "name"}
                    direction={sortOrderDirection}
                    onClick={() => createSortHandler("name")}
                  >
                    Name
                  </TableSortLabel>
                  <TextField
                    name="name"
                    margin="dense"
                    size="small"
                    placeholder="Search"
                    variant="outlined"
                    fullWidth={true}
                    value={filter?.name?.value || ''}
                    onChange={filterTextChange}
                  ></TextField>
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  <TableSortLabel
                    active={sortOrderBy === "description"}
                    direction={sortOrderDirection}
                    onClick={() => createSortHandler("description")}
                  >
                    Description
                  </TableSortLabel>
                  <TextField
                    name="description"
                    margin="dense"
                    size="small"
                    placeholder="Search"
                    variant="outlined"
                    fullWidth={true}
                    value={filter?.description?.value || ''}
                    onChange={filterTextChange}
                  ></TextField>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allergies?.map(allergy => (
                <TableRow>
                  <TableCell padding="checkbox">
                    <FormControlLabel
                      control={
                        <Checkbox checked={allergy.isSelected} onChange={() => handleClick(allergy)} value="antoine" />
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Typography component={Link} to={`/allergies/${allergy._id}`}>
                      {allergy.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {allergy.description}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button disabled={disableSelect()}
                variant={"contained"}
                color={"primary"}
                onClick={selectAllergies}>Select</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AllergyLookup;
