import React from 'react';

let NavigationContext = React.createContext();
const initialState = {
  message: 'FROM NAVIGATION CONTEXT',
  resident: null
};

const reducer = (state, action) => {
  switch (action?.type) {
    case 'SET_RESIDENT':
      return {...state, resident: action?.payload}
    default:
      return {...state}
  }
}



const NavigationProvider = ({children}) => {
  let [state, dispatch] = React.useReducer(reducer, initialState);
  const setResident = resident => {
    dispatch({type: 'SET_RESIDENT', payload: resident });
  }
  const value = { state, dispatch, resident: state?.resident, setResident };
  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  )
}

let NavigationConsumer = NavigationContext.Consumer;

export { NavigationContext, NavigationConsumer, NavigationProvider }
