import React from 'react';
import {FormControl, FormGroup, Grid, InputLabel, MenuItem, Select} from "@material-ui/core";
import {DailyOccurrence} from "./DailyOccurrence";

export const MonthlyOccurrence = ({model, onChange}) => {

  const onDayChange = e => {
    const obj = {...model.occurrence};
    obj.value.day = e.target.value;
    onChange(obj);
  }


  return (
      <Grid container>
        <div style={{ flex: 1}}>
          <DailyOccurrence model={model} onChange={onChange} />
        </div>
        <FormGroup style={{ flex: 1}}>
          <FormControl>
            <InputLabel shrink>On day</InputLabel>
            <Select onChange={onDayChange} value={model?.occurrence?.value?.day}>
              {[...Array(31)].map((n, key) => <MenuItem value={key + 1}>{key + 1}</MenuItem>)}
            </Select>
          </FormControl>
        </FormGroup>
      </Grid>
  )
}
