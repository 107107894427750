import React from "react";
import {
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  ListSubheader,
  Input,
  TableContainer,
} from "@material-ui/core";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import RadioButtonUncheckedRoundedIcon from "@material-ui/icons/RadioButtonUncheckedRounded";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import AddIcon from "@material-ui/icons/Add";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link as RouterLink } from "react-router-dom";
import ContactLookup from "../../contacts/contact.lookup";
import {
  getAllContactTypes,
  addResidentContact,
  getResidentContacts,
  updateResidentContact,
  deleteResidentContact as deactivateResidentContact,
} from "../../../services/contact.service";
import useHasRole from '../../../hooks/user.role.hook';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  table: {
    width: "100%",
  },
  iconButton: {
    padding: 0,
    margin: 0,
  },
  actionIcon: {
    height: "1.5rem",
    width: "1.5rem",
  },
  formControl: {
    width: "100%",
  },
}));

const IntakeContacts = ({ residentId }) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [residentContacts, setResidentContacts] = React.useState([]);
  const [addOpen, setAddOpen] = React.useState(false);
  const [contactTypes, setContactTypes] = React.useState([]);

  React.useEffect(() => {
    const getTypes = async () => {
      const types = await getAllContactTypes();
      setContactTypes(types);
    };
    getTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const getContacts = async () => {
      setLoading(true);
      const contacts = await getResidentContacts(residentId);
      setResidentContacts(contacts);
      setLoading(false);
    };

    if (residentId) {
      getContacts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [residentId]);

  const selectContact = async (record) => {
    if (record && record.contact) {
      const contact = await addResidentContact(
        residentId,
        record.contact._id,
        null,
        false);
        const unique = residentContacts.filter(
          (residentContact) =>
            residentContact.contact.id !== contact.contact._id
        );
        const obj = [...unique, contact];
        setResidentContacts(obj);
    }
    setAddOpen(false);
  };

  const toggleIsPrimary = async (resContact) => {
    const isPrimary = !resContact.isPrimary;
    const updated = await updateResidentContact(
      residentId,
      resContact.contact._id,
      resContact.contactType,
      resContact.financialPowerOfAttorny,
      resContact.medicalPowerOfAttorny,
      resContact.responsiblePayer,
      isPrimary
    );
    const obj = residentContacts.map((residentContact) => {
      if (residentContact.contact._id === updated.contact._id) {
        residentContact.isPrimary = updated.isPrimary;
      }
      return residentContact;
    });
    setResidentContacts(obj);
  };

  const deleteResidentContact = async (resContact) => {
    await deactivateResidentContact(
      residentId,
      resContact.contact._id
    );

    const obj = residentContacts.filter(
      (residentContact) => residentContact.contact._id !== resContact.contact._id
    );
    setResidentContacts(obj);
  };

  const contactTypeChange = async (event, residentContact) => {
    const updated = await updateResidentContact(
      residentId,
      residentContact.contact._id,
      event.target.value,
      residentContact.financialPowerOfAttorny,
      residentContact.medicalPowerOfAttorny,
      residentContact.responsiblePayer,
      residentContact.isPrimary
    );
    const obj = residentContacts.map((resContact) => {
      if (resContact.contact._id === updated.contact._id) {
        resContact.type = updated.type;
      }
      return resContact;
    });
    setResidentContacts(obj);
  };

  const handleSwitch = async (event, resContact) => {
    resContact[event.target.name] = event.target.value === "false";
    const updated = await updateResidentContact(
      residentId,
      resContact.contact._id,
      resContact.type,
      resContact.financialPowerOfAttorny,
      resContact.medicalPowerOfAttorny,
      resContact.responsiblePayer,
      resContact.isPrimary
    );
    const obj = residentContacts.map((residentContact) =>
      residentContact.contact._id === updated.contact._id
        ? updated
        : residentContact
    );
    setResidentContacts(obj);
  };

  const { checkPermission } = useHasRole();
  const canEdit = checkPermission('RESIDENT_UPDATE');

  return (
    <div className={classes.root}>
      <div hidden={!loading}>
        <Skeleton variant="text" height={40} />
        <Skeleton variant="text" height={40} />
        <Skeleton variant="text" height={40} />
        <Skeleton variant="text" height={40} />
      </div>
      <div hidden={loading}>
        <Typography variant="subtitle1"> Resident Contacts</Typography>
        {canEdit &&
        <Button startIcon={<AddIcon/>} onClick={() => setAddOpen(true)}>
          Add Contact
        </Button>
        }
        <ContactLookup
          open={addOpen}
          onClose={() => setAddOpen(false)}
          onSelect={selectContact}
        />
        <TableContainer>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell align="left" width={200} />
                <TableCell align="left">First Name</TableCell>
                <TableCell align="left">Last Name</TableCell>
                <TableCell align="left">Email</TableCell>
                <TableCell align="left">Contact Type</TableCell>
                <TableCell align="left">Power of attorney</TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {residentContacts?.map((residentContact) => (
                  <TableRow>
                    <TableCell align="left">
                      <IconButton className={classes.iconButton} component={RouterLink} to={`/contacts/${residentContact.contact._id}`}>
                        <VisibilityRoundedIcon className={classes.actionIcon} />
                      </IconButton>
                      {canEdit &&
                        <>
                      <IconButton
                        className={classes.iconButton}
                        aria-label="delete"
                        onClick={() => deleteResidentContact(residentContact)}
                      >
                        <DeleteRoundedIcon
                          className={classes.actionIcon}
                          color="error"
                        />
                      </IconButton>
                      <IconButton
                        className={classes.iconButton}
                        aria-label="Toggle primary"
                        onClick={() => toggleIsPrimary(residentContact)}
                      >
                        {residentContact.isPrimary ? (
                          <CheckCircleRoundedIcon
                            className={classes.actionIcon}
                            color="secondary"
                          />
                        ) : (
                          <RadioButtonUncheckedRoundedIcon />
                        )}
                        <Typography variant="subtitle2">Primary</Typography>
                      </IconButton>
                        </>}
                    </TableCell>
                    <TableCell align="left">
                      {residentContact.contact.firstName}
                    </TableCell>
                    <TableCell align="left">
                      {residentContact.contact.lastName}
                    </TableCell>
                    <TableCell align="left">
                      {residentContact.contact.email}
                    </TableCell>
                    <TableCell align="left">
                      <FormControl className={classes.formControl}>
                        <Select
                          input={<Input id="grouped-select" />}
                          id="contact-type-select"
                          value={residentContact.type ? residentContact.type._id: null}
                          onChange={(event) =>
                            contactTypeChange(event, residentContact)
                          }
                          disabled={!canEdit}
                        >
                          {contactTypes?.sort((a, b) => a?.name > b?.name ? 1: -1)?.map((contactType) => {
                              return contactType.isCategory ? (
                                <ListSubheader>
                                  {contactType.name}
                                </ListSubheader>
                              ) : (
                                <MenuItem value={contactType._id}>
                                  {contactType.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell align="left">
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Switch
                              name="medicalPowerOfAttorny"
                              checked={residentContact.medicalPowerOfAttorny}
                              onChange={(e) => handleSwitch(e, residentContact)}
                              value={residentContact.medicalPowerOfAttorny}
                              disabled={!canEdit}
                            />
                          }
                          label="Medical"
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              name="financialPowerOfAttorny"
                              checked={residentContact.financialPowerOfAttorny}
                              onChange={(e) => handleSwitch(e, residentContact)}
                              value={residentContact.financialPowerOfAttorny}
                              color="primary"
                              disabled={!canEdit}
                            />
                          }
                          label="Financial"
                        />
                      </FormGroup>
                    </TableCell>
                    <TableCell align="left">
                      <FormControlLabel
                        control={
                          <Switch
                            name="responsiblePayer"
                            checked={residentContact.responsiblePayer}
                            onChange={(e) => handleSwitch(e, residentContact)}
                            value={residentContact.responsiblePayer}
                            color="primary"
                            disabled={!canEdit}
                          />
                        }
                        label="Responsible Payer"
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default IntakeContacts;
