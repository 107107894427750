const model = {
  date: {
    title: 'Date',
    required: true,
    value: Date.now(),
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    time: true,
  },
  intakeMethod: {
    title: 'Intake Method',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [
      { name: 'Oral', value: 'Oral' },
      { name: 'IV Fluids', value: 'IV Fluids' },
    ],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  intakeAmount: {
    title: 'Intake Amount (ml)',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  outputMethod: {
    title: 'Output Method',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [
      { name: 'Vomit', value: 'Vomit' },
      { name: 'Urine', value: 'Urine' },
      { name: 'Drains', value: 'Drains' },
    ],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  outputAmount: {
    title: 'Output Amount (ml)',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  comments: {
    title: 'Comments',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
};

export default model;
