import React from "react";
import moment from "moment";

import {
  makeStyles,
  Paper,
  TableHead,
  Table,
  TableCell,
  TableBody,
  TableRow,
  Typography,
  TableContainer,
  TextField,
  MenuItem,
  FormGroup,
  Button,
  Grid, FormHelperText, ButtonGroup, CircularProgress,
} from "@material-ui/core";
import {getResidentMar, getResidentMarReviewedBy, updateResidentMarReviewedBy} from '../../../../services/mar.service';
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import Months from './month.values';
import MarMedicationOrder from "./mar.medicationorder";
import Skeleton from "@material-ui/lab/Skeleton";
import {AssignmentLateRounded, CheckCircle} from "@material-ui/icons";
import CancelIcon from "@material-ui/icons/Cancel";
import PrintMarDialog from "./print/print.mar.dialog";
import {ResidentContext} from "../../../../context/residentcontext";
import {PageTitle} from "../../../utils";
import useHasRole from "../../../../hooks/user.role.hook";
import {ReviewedByModal} from "./reviewed.by.modal";
import {ApprovedByModal} from "./approved.by.modal";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1),
  },
  container: {
    display: "flex",
  },
  day: {
    flex: 1,
    border: "1px solid black",
    textAlign: "center",
    padding: ".25rem",
  },
  tableDayColumn: {
    minWidth: "50px",
    maxWidth: "50px",
    textAlign: "center",
    borderLeft: "1px solid gray",
    borderRight: "1px solid gray",
  },
  legendItem: {
    display: 'flex',
    padding: theme.spacing(0, .1)
  },
  legendLabel: {

  },
  legendValue: {
    margin: theme.spacing(0, .2)
  },
  late: {
    color: theme.palette.warning.main
  },
  prnAlertContainer: {
    padding: theme.spacing(1),
    width: '100%',
    backgroundColor: theme.palette.grey[200]
  },
  legendContainer: {
    padding: theme.spacing(1)
  },
  contentContainer: {
    padding: theme.spacing(1)
  },
  skeletonDay: {
    borderLeft: '1px solid'
  },
  marReviewedByContainer: {
    padding: theme.spacing(0, 1)
  },
  goodStatus: {
    color: "green"
  }
}));



const ResidentMar = () => {
  const classes = useStyles();
  const [months] = React.useState(Months);
  const [days, setDays] = React.useState(0);
  const [month, setMonth] = React.useState();
  const [resident, setResident] = React.useState();
  const [date, setDate] = React.useState(moment());
  const [year, setYear] = React.useState(moment().get("year"));
  const [years, setYears] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [marReviewData, setMarReviewData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [loadingMarReview, setLoadingMarReview] = React.useState(false);
  const [viewPrintDialog, setViewPrintDialog] = React.useState(false);
  const [viewReviewedByModal, setViewReviewedByModal] = React.useState(false);
  const [viewApprovedByModal, setViewApprovedByModal] = React.useState(false);
  const { state } = React.useContext(ResidentContext);
  const { checkPermission } = useHasRole();
  const isReportPrinter = checkPermission("REPORT_PRINTER");

  React.useEffect(() => {
    if (state?.resident?._id) {
      generateYears(moment().get("year"));
      getData(state?.resident?._id, +moment().month() + 1, +moment().year());
      setResident(state?.resident);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.resident]);

  const generateYears = (cur) => {
    const obj = [];
    for (let min = cur - 5; min <= cur; min++) {
      obj.push(min);
    }
    setYears(obj);
  };

  const getData = async (residentId, month, year) => {
    setLoading(true);
    setLoadingMarReview(true);
    const date = moment().set({ year: year, month: month - 1 });
    setDate(date);
    const d = date.daysInMonth();
    setMonth(month);
    setDays(d);
    getResidentMar(residentId, month, year).then(marResponse => {
      let filtered = marResponse?.filter(medicationOrder => {
        if (medicationOrder?.discontinuedDate) {
          const discontinuedDate = moment(medicationOrder?.discontinuedDate);
          const startDate = moment({date: 1, month: month - 1, year: year});
          if (discontinuedDate < startDate) {
            return false;
          }
        }
        return true;
      });
      setData(filtered);
    }).catch(error => {

    }).finally(() => {
      setLoading(false);
    })
    getResidentMarReviewedBy(residentId, year, month).then(res => {
      setMarReviewData(res);
    }).catch(error => {}).finally(() => {
      setLoadingMarReview(false);
    });
  }

  const changeYear = async (e) => {
    setYear(e.target.value);
    await getData(resident?._id, month, e.target.value);
  };

  const changeMonth = async (e) => {
    setMonth(e.target.value);
    await getData(resident?._id, e.target.value, year);
  };

  const onMarSaved = async data => {
    setLoadingMarReview(true);
    setViewReviewedByModal(false);
    setViewApprovedByModal(false);
    const response =
        await updateResidentMarReviewedBy(resident?._id, moment(date).year(), moment(date).month() + 1, data);
    if (!response?.error) {
      setMarReviewData(response);
    }
    setLoadingMarReview(false);
  }

  return (
      <Paper className={classes.root} elevation={24}>
        <PageTitle title={"MAR"} />
        <Grid container justifyContent="space-between">
          <Grid item className={classes.gridItem}>
            <Grid container>
              <Grid item>
                <form noValidate>
                  <FormHelperText>
                    <Typography variant="h6">MAR: {date.format("MMMM YYYY")}</Typography>
                  </FormHelperText>
                  <FormGroup>
                    <TextField
                        onChange={changeYear}
                        label="Year"
                        fullwidth
                        select
                        value={year | ""}
                    >
                      {years &&
                          years.map((year) => (
                              <MenuItem key={year} value={year}>
                                {year}
                              </MenuItem>
                          ))}
                    </TextField>
                    <TextField
                        onChange={changeMonth}
                        label="Month"
                        fullwidth
                        select
                        value={month | ""}
                    >
                      {months &&
                          months.map((month) => (
                              <MenuItem key={month.val} value={month.val}>
                                {month.name}
                              </MenuItem>
                          ))}
                    </TextField>
                  </FormGroup>
                </form>
              </Grid>
              <Grid item className={classes.marReviewedByContainer}>
                <ButtonGroup size={"small"} variant={"text"}>
                  {loadingMarReview &&
                      <Button color={"primary"}
                              disabled
                              style={{borderRight: "none"}}
                              startIcon={<CircularProgress size={20} />}
                      >
                        Reviewed
                      </Button>
                  }
                  {loadingMarReview &&
                      <Button color={"primary"}
                              disabled
                              style={{borderRight: "none"}}
                              startIcon={<CircularProgress size={20} />}
                      >
                        Approved
                      </Button>
                  }
                  {!loadingMarReview &&
                      <Button color={"primary"}
                              style={{borderRight: "none"}}
                              onClick={() => setViewReviewedByModal(true)}
                              startIcon={marReviewData?.reviewedBy?._id &&
                                  <CheckCircle className={classes.goodStatus} />
                              }
                      >
                        {marReviewData?.reviewedBy?._id ? "Reviewed": "Review"}
                      </Button>
                  }
                  {!loadingMarReview &&
                      <Button color={"primary"}
                              style={{borderRight: "none"}}
                              startIcon={marReviewData?.approvedBy?._id && <CheckCircle className={classes.goodStatus} />}
                              onClick={() => setViewApprovedByModal(true)}>
                        {marReviewData?.approvedBy?._id ? "Approved": "Approve"}
                      </Button>
                  }
                </ButtonGroup>
                <ApprovedByModal open={viewApprovedByModal}
                                 onClose={() => setViewApprovedByModal(false)}
                                 onSave={onMarSaved}
                                 data={marReviewData}
                                 date={date}
                                 residentId={resident?._id} />
                <ReviewedByModal open={viewReviewedByModal}
                                 onClose={() => setViewReviewedByModal(false)}
                                 onSave={onMarSaved}
                                 data={marReviewData}
                                 date={date}
                                 residentId={resident?._id}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Typography align={"center"}
                        variant="h5">
              Medication Administration Record
            </Typography>
          </Grid>
          <Grid item className={classes.gridItem}>
            {isReportPrinter &&
                <>
                  <Button disabled={loading || !data} startIcon={<PictureAsPdfIcon/>}
                          onClick={() => setViewPrintDialog(true)}>Print</Button>
                  <PrintMarDialog open={viewPrintDialog}
                                  days={days}
                                  data={data}
                                  reviewData={marReviewData}
                                  resident={resident}
                                  year={year}
                                  loading={loading}
                                  month={month}
                                  onClose={() => setViewPrintDialog(false)}
                  />
                </>
            }
          </Grid>
        </Grid>
        <Grid container
              className={classes.legendContainer}
        >
          <div className={classes.legendItem}>
            <CheckCircle color={"secondary"} className={classes.legendItem}/>
            <Typography variant={"subtitle2"} className={classes.legendValue}> = Given</Typography>
          </div>
          <div className={classes.legendItem}>
            <CancelIcon color={"error"} className={classes.legendItem}/>
            <Typography variant={"subtitle2"} className={classes.legendValue}> = Missed</Typography>
          </div>
          <div className={classes.legendItem}>
            <AssignmentLateRounded className={[classes.legendItem, classes.late]}/>
            <Typography variant={"subtitle2"} className={classes.legendValue}> = Late</Typography>
          </div>
          <div className={classes.legendItem}>
            <Typography variant={"subtitle2"} className={classes.legendItem}>R</Typography>
            <Typography variant={"subtitle2"} className={classes.legendValue}> = Refused</Typography>
          </div>
          <div className={classes.legendItem}>
            <Typography variant={"subtitle2"} className={classes.legendItem}>H</Typography>
            <Typography variant={"subtitle2"} className={classes.legendValue}> = Hold</Typography>
          </div>
          <div className={classes.legendItem}>
            <Typography variant={"subtitle2"} className={classes.legendItem}>RD</Typography>
            <Typography variant={"subtitle2"} className={classes.legendValue}> = Refused & Destructed</Typography>
          </div>
          <div className={classes.legendItem}>
            <Typography variant={"subtitle2"} className={classes.legendItem}>SA</Typography>
            <Typography variant={"subtitle2"} className={classes.legendValue}> = Self Administered</Typography>
          </div>
          <div className={classes.legendItem}>
            <Typography variant={"subtitle2"} className={classes.legendItem}>NA</Typography>
            <Typography variant={"subtitle2"} className={classes.legendValue}> = Not Administed</Typography>
          </div>
          <div className={classes.legendItem}>
            <Typography variant={"subtitle2"} className={classes.legendItem}>A</Typography>
            <Typography variant={"subtitle2"} className={classes.legendValue}> = Assist</Typography>
          </div>
          <div className={classes.legendItem}>
            <Typography variant={"subtitle2"} className={classes.legendItem}>S</Typography>
            <Typography variant={"subtitle2"} className={classes.legendValue}> = Supervise</Typography>
          </div>
        </Grid>
        <Grid container
              className={classes.contentContainer}>
          {!loading &&
            <TableContainer>
              <Table size={"small"}
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{minWidth: '250px', maxWidth: '225px'}}>Medications</TableCell>
                      <TableCell style={{ padding: '0 16px'}}>
                        <TableContainer>
                          <Table size={"small"}>
                            <TableHead style={{ border: 'none'}}>
                              <TableRow>
                                <TableCell style={{maxWidth: '55px', minWidth: '55px', border: 'none'}}>HR
                                </TableCell>
                                {[...Array(days)].map((e, i) => (
                                    <TableCell className={classes.tableDayColumn}>{i + 1}
                                    </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                          </Table>
                        </TableContainer>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.filter(data => !data?.isPrn)?.sort(
                        (a,b) => a?.medication?.tradeName > b?.medication?.tradeName ? 1: -1)?.map((data, index) =>
                        <MarMedicationOrder medicationOrder={data}
                                            index={index}
                                            days={days}
                        />
                    )}
                    {data?.filter(d => d?.isPrn).length > 0 &&
                        <>
                          <TableRow>
                            <TableCell className={classes.prnAlertContainer} colSpan={days}>
                              <Typography variant={"subtitle1"}>PRNs</Typography>
                            </TableCell>
                          </TableRow>
                        </>}
                    {data?.filter(data => data?.isPrn)?.sort(
                        (a,b) => a?.medication?.tradeName > b?.medication?.tradeName ? 1: -1)?.map((data, index) =>
                        <MarMedicationOrder medicationOrder={data}
                                            index={index}
                                            days={days}
                                            isPrn={true}
                                            timeZone={resident?.location?.timeZone}
                        />)
                    }
                  </TableBody>
                </Table>
              </TableContainer>}
          {loading &&
              <TableContainer>
                <Table size={"small"}>
                  <TableHead>
                    <TableRow>
                      <TableCell width={300}>
                        <Typography variant={"subtitle1"}>Medication</Typography>
                      </TableCell>
                      <TableCell width={100}>
                        <Typography variant={"subtitle1"}>HR</Typography>
                      </TableCell>
                      {[...Array(28)].map((i, index) => <TableCell className={classes.skeletonDay}>{index + 1}</TableCell> )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {[...Array(5)].map(a =>
                        <TableRow>
                          <TableCell width={300}>
                            <Skeleton style={{ height: 40}} />
                            <Skeleton style={{ height: 10}} />
                            <Skeleton style={{ height: 10}} />
                          </TableCell>
                          <TableCell width={100}>
                            <Skeleton style={{ height: 20}} />
                          </TableCell>
                          {[...Array(28)].map(i => <TableCell><Skeleton style={{ height: 20}} /></TableCell> )}
                        </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
          }
        </Grid>
      </Paper>
  );
};

export default ResidentMar;
