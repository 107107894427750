import React from 'react';
import {
  Avatar, Button,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText, Typography
} from "@material-ui/core";
import {RotateLeftTwoTone} from "@material-ui/icons";
import {grey} from '@material-ui/core/colors';
import {makeStyles} from "@material-ui/core/styles";
import {updateAlert} from "../../../../services/resident.service";
import history from "../../../../utils/history";
import moment from "moment-timezone";

const useStyles = makeStyles((theme) => ({
  root: {},
  alert: {
    cursor: 'pointer',
  },
  notDismissed: {
    background: grey[700],
  },
  avatar: {
    background: theme.palette.primary.dark
  }
}));

export const RecheckAlert = ({
                               alert,
                               onViewEntity,
                               onDismissedChange
                             }) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const getSecondary = () => {

    return (
      <>
        <Typography variant={"subtitle1"}>{alert?.residentData?.firstName} {alert?.residentData?.lastName}</Typography>
        <Typography variant={"subtitle2"}>{alert?.fkEntity?.medicationOrder?.medication?.tradeName}</Typography>
        <Typography variant={"subtitle2"}>
          Administered: {moment(alert?.fkEntity?.administeredTimestamp).format("MM/DD/YYYY h:mm a")}
        </Typography>
        <Typography variant={"subtitle2"}>
          Recheck: {moment(alert?.fkEntity?.recheckDateTime).format("MM/DD/YYYY h:mm a")}
        </Typography>
      </>
    )
  }

  const onDismissedToggle = async () => {
    setError(false);
    setLoading(true);
    const residentId = alert?.resident;
    const alertId = alert?._id;
    const dismissed = !alert?.dismissed;
    const result = await updateAlert(residentId, alertId, {dismissed});
    if (!result?.error) {
      onDismissedChange(result)
    } else {
      setError(true);
    }
    setLoading(false);
  }

  const onSelected = () => {
    onViewEntity();
    history.push(`/residents/${alert.resident}/eprns/${alert?.fkEntity?._id}`);
  }

  if (loading) {
    return (
      <ListItem button>
        <ListItemAvatar>
          <CircularProgress/>
        </ListItemAvatar>
        <ListItemText primary={"Processing...."}/>
      </ListItem>
    )
  }

  if (!loading && error) {
    return (
      <ListItem className={classes.root}
                button
      >
        <ListItemAvatar>
          <Avatar
            className={[
              !alert?.dismissed ? classes.notDismissed : null,
            ]}
          >
            <i className="fas fa-radiation"></i>
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={"Something went wrong..."} secondary={"Please click retry"} />
        <ListItemSecondaryAction>
          <Button startIcon={<RotateLeftTwoTone />}
                  onClick={onDismissedToggle}
                  color={"primary"}
                  variant={"contained"}
          >
            Retry
          </Button>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }


  if (!loading && !error) {
    return (
      <ListItem button
                onClick={onSelected}
      >
        <ListItemAvatar>
          <Avatar className={classes.avatar}>
            <i className="fas fa-prescription-bottle"></i>
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={"PRN Recheck"}
                      secondary={getSecondary()} />
      </ListItem>
    )
  }
}
