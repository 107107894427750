import React from 'react';
import Transition from "../dialog/transition";
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
  Toolbar,
  Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Assessment from "../assessment/assessment";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    paddingTop: '4rem',
  },
  dialogContent: {
    marginTop: '4rem',
  },
}));

const PreviewAssessment = ({open, onClose, assessment}) => {
  const classes = useStyles();

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => onClose()}
      TransitionComponent={Transition}
    >
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => onClose()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Assessment Preview</Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.dialogContent} dividers>
        {assessment && <Assessment assessmentInstance={assessment} isPreview={true} onAssessmentUpdate={() => {}}/>}
      </DialogContent>
      <DialogActions>
        <Button color={"primary"} onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default PreviewAssessment;
