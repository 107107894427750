import React from 'react'
import { Button } from '@material-ui/core';

const FileSelector = ({label, onFileSelected,...p}) => {
    return (
        <Button variant="contained" component="label" {...p}>
              {label}
              <input
                type="file"
                style={{ display: "none" }}
                onChange={onFileSelected}
                accept="image/*"
              />
            </Button>
    )
}

export default FileSelector;