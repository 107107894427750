import React from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  makeStyles,
  AppBar,
  Toolbar,
  Typography,
  Select,
  MenuItem,
  FormGroup,
  Divider,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  DialogActions
} from "@material-ui/core";
import Transition from "../../../dialog/transition";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import history from "../../../../utils/history";
import {
  DateTimePicker, KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import prnEventModel from "./model";
import { getResidentPrnOrders } from '../../../../services/medication.order.service';
import OutcomesList from "./outcomes.list";
import {isMedicationOrderOnHold} from "../emar/utils";

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: "4rem",
    padding: theme.spacing(1),
  },
}));

const CreatePrnEvent = ({ open, residentId, date, onClose, onSave }) => {
  const [selectedPrn, setSelectedPrn] = React.useState("");
  const [event, setEvent] = React.useState({ outcomes: []});
  const [medicationOrder, setMedicationOrder] = React.useState(null);
  const [ prns, setPrns ] = React.useState([]);
  const [ loading, setLoading ] = React.useState(false);
  const [ newOutcomes, setNewOutcomes] = React.useState([]);
  const classes = useStyles();

  React.useEffect(() => {
    if (residentId && open) {
      setSelectedPrn("");
      getPrns(residentId)
    }
  }, [ open, date, residentId ]);

  const getPrns = async residentId => {
    setLoading(true);
    let orders = await getResidentPrnOrders(residentId);
    orders = orders?.filter(h => !isMedicationOrderOnHold(h, new Date()));
    setPrns(orders);
    setLoading(false);
  }

  const onPrnSelected = (e) => {
    const val = e.target.value;
    if (val === -1) {
      onClose();
    } else if (val === "create_new") {
      history.push(`/residents/${residentId}/medications?prn=true`);
    } else {
      setSelectedPrn(e.target.value);
      setMedicationOrder(e.target.value._id);
      const obj = JSON.parse(JSON.stringify(prnEventModel))
      obj.administeredTimestamp.value = date;
      obj.recheckDateTime.value = moment(date).add(2, 'hours');
      setEvent(obj);
    }
  };

  const onHourChange = time => {
    const obj = { ...event };
    event.administeredTimestamp.value = time;
    setEvent(obj);
  };

  const onRecheckDateTimeChange = date => {
    const obj = { ...event };
    event.recheckDateTime.value = date;
    setEvent(obj);
  }

  const handleRecheckChange = (e) => {
    const obj = { ...event };
    obj.isRecheck.value = e.target.checked;
    if (e.target.checked) {
      const dt = moment(new Date()).add(6, "hours");
      obj.recheckDateTime.value = dt;
    } else {
      obj.recheckDateTime.value = null;
    }
    setEvent(obj);
  };

  const onChangeEvent = (e) => {
    const obj = { ...event };
    obj[e.target.name].value = e.target.value;
    setEvent(obj);
  };

  const handleGivenStatusChange = (e) => {
    const obj = { ...event };
    obj[e.target.name].value = e.target.checked;
    setEvent(obj);
  };

  const saveEvent = () => {
    const data = {
      medicationOrder: medicationOrder,
      scheduledTime: event.administeredTimestamp.value,
      passingQty: event.passingQty.value,
      isPrn: true,
      status: event.given.value ? { name: "Given" } : { name: "Scheduled" },
      reason: event.reason.value,
      initialOutcome: event.outcomes.value,
      isRecheck: event.isRecheck.value,
      recheckDateTime: event.isRecheck.value ? event.recheckDateTime.value: null,
      outcomes: newOutcomes
    };

    onSave(data);
  }

  const onSaveOutcome = (eventId, outcome) => {
    setNewOutcomes([...newOutcomes, outcome])
  }

  return (
    <Dialog TransitionComponent={Transition} fullScreen open={open} fullWidth>
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Pass PRN Medication
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.content}>
        <form noValidate>
          <FormGroup>
            <Select displayEmpty value={selectedPrn} onChange={onPrnSelected} disabled={loading}>
              <MenuItem disabled value="">
                Select Medication
              </MenuItem>
              <MenuItem value="create_new">Create Prn</MenuItem>
              <MenuItem value={-1}>Cancel</MenuItem>
              <Divider />
              {prns?.map((prn) => (
                  <MenuItem value={prn}>
                    {prn?.medication && (
                      <div style={{ width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Typography variant="h5">
                            {prn?.medication?.tradeName}
                          </Typography>
                          {prn?.medication?.ndcCode && (
                            <Typography
                              variant="caption"
                              style={{ marginLeft: "?.5rem" }}
                            >
                              NDC Code: {prn?.medication?.ndcCode}
                            </Typography>
                          )}
                          {prn?.medication?.genericName && (
                            <Typography
                              variant="caption"
                              style={{ marginLeft: "?.5rem" }}
                            >
                              (Generic Name: {prn?.medication?.genericName})
                            </Typography>
                          )}
                        </div>
                        <Divider />
                        {prn?.medication && (
                          <div>
                            <Typography variant="body2">
                              Dosage Form: {prn?.medication?.dosageForm?.name}
                            </Typography>
                            <Typography variant="body2">
                              Route: {prn?.medication?.route?.name}
                            </Typography>
                            <Typography variant="body2">
                              Strength: {prn?.medication?.strength}{" "}
                              {prn?.medication?.unit?.name}
                            </Typography>
                            <Typography variant="body2">
                              Direction: {prn?.direction}
                            </Typography>
                            <Typography variant="body2">
                              Indication: {prn?.indication}
                            </Typography>
                            <Typography variant="body2">
                              Pill Description: {prn?.pillDescription}
                            </Typography>
                            <Typography variant="body2">
                              Alerts: {prn?.alerts}
                            </Typography>
                          </div>
                        )}
                      </div>
                    )}
                  </MenuItem>
                ))}
            </Select>
          </FormGroup>
          {selectedPrn && (
            <React.Fragment>
              <FormGroup style={{ marginTop: "1rem" }}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDateTimePicker
                    format={"MM/DD/YYYY hh:mm a"}
                    name="hour"
                    label="Hour"
                    onChange={onHourChange}
                    value={event?.administeredTimestamp?.value}
                    required
                  />
                  <TextField
                    name="passingQty"
                    label="Passing Qty"
                    value={event?.passingQty?.value}
                    onChange={onChangeEvent}
                    required
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={event?.given?.value}
                        required
                        onChange={handleGivenStatusChange}
                        name="given"
                      />
                    }
                    label="Given"
                  />
                  <TextField
                    name="reason"
                    label="Reason"
                    value={event?.reason?.value}
                    onChange={onChangeEvent}
                  />
                  <OutcomesList residentId={residentId}
                                canEdit={true}
                                event={
                                  {
                                    outcomes: newOutcomes
                                  }
                                }
                                onSaveOutcome={onSaveOutcome}
                                isNew={true}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={event?.isRecheck?.value}
                        onChange={handleRecheckChange}
                        name="checkedA"
                      />
                    }
                    label="Schedule re-check"
                  />
                  {event?.isRecheck && event?.isRecheck?.value && (
                    <DateTimePicker
                      name="recheckDateTime"
                      label="Schedule re-check"
                      inputVariant="outlined"
                      onChange={onRecheckDateTimeChange}
                      value={event?.recheckDateTime?.value}
                    />
                  )}
                </MuiPickersUtilsProvider>
              </FormGroup>
            </React.Fragment>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={saveEvent}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreatePrnEvent;
