import React from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableCell,
  Button,
  TableBody,
  TableRow, TablePagination,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";
import {
  getResidentLungSounds,
  createResidentLungSounds, updateResidentLungSound, deleteResidentLungSound,
} from "../../../../services/evital.service";
import CreateResidentLungSound from "./create.lung.sound";
import {DeleteBtn} from "../../../utils/buttons/DeleteBtn";
import EditIcon from "@material-ui/icons/Edit";
import ConfirmDialog from "../../../utils/dialogs/confirm.dialog";
import TableLoadingIndicator from "../../../../utils/indicators/table.loading";
import {EditLungSound} from "./EditLungSound";
import {UserContext} from "../../../../context/usercontext";
import useGlobalNotification from "../../../../hooks/notification.hook";

const columns = [
  { field: "date", label: "Date" },
  { field: "ccWaterAir", label: "ccWaterAir" },
  { field: "rul", label: "rul" },
  { field: "lul", label: "lul" },
  { field: "rml", label: "rml" },
  { field: "rll", label: "rll" },
  { field: "lll", label: "lll" },
  { field: "comments", label: "Comments" },
];

const getStripedStyle = (index) => {
  return { background: index % 2 ? "#fafafa" : "#fff" };
};

const LungSoundView = ({ match, canEdit }) => {
  const [cols] = React.useState(JSON.parse(JSON.stringify(columns)));
  const [open, setOpen] = React.useState(false);
  const [viewConfirmDelete, setViewConfirmDelete] = React.useState(false);
  const [selectedLungSound, setSelectedLungSound] = React.useState(null);
  const [viewEditModal, setViewEditModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(25);
  const [totalItems, setTotalItems] = React.useState(0);
  const [residentId, setResidentId] = React.useState(null);
  const [lungSounds, setLungSounds] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const { getUsers } = React.useContext(UserContext);
  const { addNotification } = useGlobalNotification()

  React.useEffect(() => {
    if (match.params.id && match.params.id !== residentId) {
      setResidentId(match.params.id);
    }
  }, [match.params.id, residentId]);

  React.useEffect(() => {
    if (residentId) {
      getLungSounds(residentId);
    }
    // eslint-disable-next-line
  }, [residentId]);

  const handleChangePage = async (event, pg) => {
    setPage(pg + 1);
    await getLungSounds(residentId, pg + 1, size);
  };
  const onChangeRowsPerPage = async (event) => {
    setSize(event.target.value);
    await getLungSounds(residentId, page, event.target.value);
  };

  const getLungSounds = async (residentId, pg, sz) => {
    setLoading(true);
    setError(false);
    if (!users || users?.length < 1) {
      const usersResponse = await getUsers();
      setUsers(usersResponse?.sort((a, b) => a?.lastName > b?.lastName ? 1: -1).map(u => ({
          name: `${u.firstName} ${u.lastName} (${u.initials})`,
          _id: u._id
        })
      ));
    }
    const response = await getResidentLungSounds(residentId, pg, sz);
    if (!response?.error) {
      setPage(response?.paginator?.currentPage);
      setTotalItems(response?.paginator?.totalItems);
      setSize(response?.paginator?.perPage);
      setLungSounds(response.content);
    } else {
      setError(true);
    }
    setLoading(false);
  };

  const createLungSound = async (residentId, data) => {
      const response = await createResidentLungSounds(residentId, data);
      const obj = [{...response}, ...lungSounds];
      setLungSounds(obj);
      setOpen(false);
  }

  const setupDeleteLungSound = lungSound => {
    setSelectedLungSound(lungSound);
    setViewConfirmDelete(true);
  }

  const setupEditLungSound = lungSound => {
    setSelectedLungSound(lungSound);
    setViewEditModal(true);
  }

  const deleteLungSound = async () => {
    const response = await deleteResidentLungSound(residentId, selectedLungSound?._id);
    if (!response?.error) {
      setLungSounds(lungSounds?.filter(l => l?._id !== selectedLungSound?._id));
      setViewConfirmDelete(false);
      setSelectedLungSound(false);
      addNotification("Lung sound record deleted.", "success");
    } else {
      addNotification("Unable to delete record");
    }
  }

  const onUpdateLungSound = async (id, payload) => {
    let lungSound = lungSounds?.find(l => l?._id === id);
    if (lungSound) {
      lungSound = {...lungSound, ...payload};
      const updateResponse = await updateResidentLungSound(residentId, id, lungSound);
      if (!updateResponse?.error) {
        setLungSounds(lungSounds?.map(l => l?._id === updateResponse?._id ? updateResponse: l));
        setViewEditModal(false);
      }
    }
  }

  return (
    <div>
      {canEdit &&
      <Button startIcon={<AddIcon />} onClick={() =>  setOpen(true)}>
        Add new
      </Button>}
      <CreateResidentLungSound
        residentId={residentId}
        open={open}
        onClose={() => setOpen(false)}
        onSave={createLungSound}
        users={users}
      />
      <TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[1, 5, 10, 25, 50, 100]}
          page={page - 1}
          rowsPerPage={size}
          count={totalItems}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onChangePage={handleChangePage}
        />
        <Table size="small" aria-label="vitals table">
          <TableHead>
            <TableRow>
              <TableCell />
              {cols?.map((col) => <TableCell>{col.label}</TableCell>)}
              <TableCell>Staff</TableCell>
            </TableRow>
          </TableHead>
          {!error && loading && <TableLoadingIndicator cols={10} bodyOnly={true} />}
          <TableBody>
            {!loading && !error && lungSounds?.map((vital, i) =>
            <TableRow key={i} style={{ ...getStripedStyle(i) }}>
              <TableCell>
                <DeleteBtn size={"small"}
                           buttonText={"Delete"}
                           onClick={() => setupDeleteLungSound(vital)}
                />
                <Button size={"small"}
                        startIcon={<EditIcon />}
                        onClick={() => setupEditLungSound(vital)}
                >
                  Edit
                </Button>
              </TableCell>
              {columns?.map((col) => {
                if (col.field === "date") {
                  return (
                    <TableCell style={{ textAlign: "left" }}>
                      {moment(vital[col.field]).format("MM/DD/YYYY hh:mm")}
                    </TableCell>
                  );
                }  else {
                  return (
                    <TableCell style={{ textAlign: "left" }}>
                      {vital[col.field]}
                    </TableCell>
                  );
                }
              })}
              <TableCell>
                {vital?.staff?.firstName} {vital?.staff?.lastName}
              </TableCell>
            </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmDialog open={viewConfirmDelete}
                     onClose={() => setViewConfirmDelete(false)}
                     title={"Delete Lung Sound?"}
                     message={"Press delete to remove this lung sound record."}
                     onConfirm={deleteLungSound} />
      <EditLungSound onSave={onUpdateLungSound}
                     onClose={() => setViewEditModal(false)}
                     lungSound={selectedLungSound}
                     users={users}
                     open={viewEditModal}
      />
    </div>
  );
};

export default LungSoundView;
