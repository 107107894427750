import React from "react";
import {
  addEvent, clockOut,
  createTimeClock,
  createTimeClockEvent, deleteEvent, deleteTimeEntry, editTimeEntry, enhanceTotalMinutes,
  getUserTimeEntryForDate
} from "../../services/timeentry.service";
import moment from "moment/moment";
import {AlarmOff, AlarmOn, List} from "@material-ui/icons";
import useHasRole from "../../hooks/user.role.hook";

let TimeEntryContext = React.createContext();

export let TAB_VALUES = {
  TIMECLOCK: "TIMECLOCK",
  SEARCH: "SEARCH"
}

export const CLOCK_TYPE = {
  CLOCKED_IN: "CLOCK_IN",
  CLOCKED_OUT: "CLOCK_OUT",
  TIME_ENTRIES: "TIME_ENTRIES"
}

export const CLOCK_BUTTON_OPTIONS = [{
  label: 'Clock In',
  icon: <AlarmOn />,
  disabled: false,
  type: CLOCK_TYPE.CLOCKED_IN
}, {
  label: 'Clock out',
  icon: <AlarmOff />,
  disabled: true,
  type: CLOCK_TYPE.CLOCKED_OUT
}, {
  label: 'View time entries',
  icon: <List />,
  disabled: false,
  type: CLOCK_TYPE.TIME_ENTRIES
}
];


let initialState = {
  user: null,
  tenant: null,
  year: moment().year(),
  week: moment().weeks(),
  timeEntries: [],
  currentTimeEntries: [],
  viewClockIn: false,
  viewClockOut: false,
  viewTimeClockTable: false,
  loading: false,
  upserting: false,
  error: false,
  tab: TAB_VALUES.TIMECLOCK,
  clockButtonOptions: CLOCK_BUTTON_OPTIONS,
  clockOptionSelectedIndex: 0,
  currentClockStatus: null,
  selectedTimeEntry: null,
  viewConfirmDelete: false,
  viewTimeEntryCreateModal: false,
  editTimeEntryModel: null
};

export let ACTIONS = {
  SET_TENANT: "SET_TENANT",
  SET_USER: "SET_USER",
  SET_VIEW_CLOCK_IN: "SET_VIEW_CLOCK_IN",
  SET_VIEW_CLOCK_OUT: "SET_VIEW_CLOCK_OUT",
  TOGGLE_LOADING: "TOGGLE_LOADING",
  TOGGLE_UPSERTING: "TOGGLE_UPSERTING",
  TOGGLE_ERROR: "TOGGLE_ERROR",
  SET_CURRENT_TIME_ENTRIES: "SET_CURRENT_TIME_ENTRIES",
  SET_VIEW_TIMECLOCK_TABLE: "SET_VIEW_TIMECLOCK_TABLE",
  SET_TIME_ENTRIES: "SET_TIME_ENTRIES",
  SET_YEAR: "SET_YEAR",
  SET_WEEK: "SET_WEEK",
  SET_WEEK_YEAR: "SET_WEEK_YEAR",
  SET_TIMECLOCK: "SET_TIMECLOCK",
  ADD_TIME_ENTRY: "ADD_TIME_ENTRY",
  UPDATE_TIME_ENTRY: "UPDATE_TIME_ENTRY",
  SET_TAB: "SET_TAB",
  SET_CLOCK_OPTION: "SET_CLOCK_OPTION",
  SET_CLOCK_OPTION_SELECTED_INDEX: "SET_CLOCK_OPTION_SELECTED_INDEX",
  SET_CLOCK_STATUS: "SET_CLOCK_STATUS",
  ADD_TO_CURRENT_TIME_ENTRIES: "ADD_TO_CURRENT_TIME_ENTRIES",
  DELETE_TIME_ENTRY: "DELETE_TIME_ENTRY",
  CLOCK_OUT: "CLOCK_OUT",
  SET_TIME_ENTRY: "SET_TIME_ENTRY",
  SET_VIEW_CONFIRM_DELETE: "SET_VIEW_CONFIRM_DELETE",
  SET_VIEW_TIME_ENTRY_CREATE_MODAL: "SET_VIEW_TIME_ENTRY_CREATE_MODAL",
  SET_EDIT_TIME_ENTRY_MODEL: "SET_EDIT_TIME_ENTRY_MODEL"
};

let reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_TENANT:
      return {...state, tenant: action.payload};
    case ACTIONS.SET_USER:
      return {...state, user: action.payload};
    case ACTIONS.SET_VIEW_CLOCK_IN:
      return {...state, viewClockIn: action?.payload};
    case ACTIONS.SET_VIEW_CLOCK_OUT:
      return {...state, viewClockOut: action?.payload};
    case ACTIONS.TOGGLE_UPSERTING:
      return {...state, upserting: action?.payload};
    case ACTIONS.TOGGLE_ERROR:
      return {...state, error: action?.payload };
    case ACTIONS.SET_CURRENT_TIME_ENTRIES:
      return {...state, currentTimeEntries: action?.payload};
    case ACTIONS.SET_VIEW_TIMECLOCK_TABLE:
      return {...state, viewTimeClockTable: action?.payload};
    case ACTIONS.SET_TIME_ENTRIES:
      return {...state, timeEntries: action?.payload};
    case ACTIONS.SET_YEAR:
      return {...state, year: action?.payload};
    case ACTIONS.SET_WEEK:
      return {...state, week: action?.payload};
    case ACTIONS.SET_WEEK_YEAR:
      return {...state, week: action?.payload.week, year: action?.payload?.year};
    case ACTIONS.TOGGLE_LOADING:
      return {...state, loading: action?.payload};
    case ACTIONS.SET_TIMECLOCK:
      let timeEntries = state?.timeEntries?.map(timeEntry => timeEntry?._id === action?.payload?._id ? action.payload: timeEntry);
      return {...state, timeEntries: timeEntries};
    case ACTIONS.ADD_TIME_ENTRY:
      return {...state, timeEntries: [...state?.timeEntries, action?.payload]}
    case ACTIONS.UPDATE_TIME_ENTRY:
      const tes = state.timeEntries?.map(t => t._id === action?.payload?._id ? action?.payload: t);
      const ctes = state?.currentTimeEntries?.map(t => t._id === action?.payload?._id ? action?.payload: t);
      return {...state, timeEntries: tes, currentTimeEntries: ctes};
    case ACTIONS.DELETE_TIME_ENTRY:
      const nonDeleted = state?.timeEntries?.filter(te => te._id !== action.payload);
      const nonDeletedCurrent = state?.currentTimeEntries?.filter(te => te._id !== action.payload);
      return {...state, timeEntries: nonDeleted, currentTimeEntries: nonDeletedCurrent};
    case ACTIONS.SET_TAB:
      return {...state, tab: action?.payload }
    case ACTIONS.SET_CLOCK_OPTION_SELECTED_INDEX:
      return {...state, clockOptionSelectedIndex: action?.payload};
    case ACTIONS.ADD_TO_CURRENT_TIME_ENTRIES:
      return {...state, currentTimeEntries: [...state.currentTimeEntries, action?.payload]};
    case ACTIONS.CLOCK_OUT:
      return {...state, currentTimeEntries: state?.currentTimeEntries?.map(c => {
        if (!c?.end) {
          c.end = action?.payload?.date
        }
        return c;
        })}
    case ACTIONS.SET_TIME_ENTRY:
      return {...state, selectedTimeEntry: action?.payload, editTimeEntryModel: JSON.parse(JSON.stringify(action?.payload))};
    case ACTIONS.SET_VIEW_CONFIRM_DELETE:
      return {...state, viewConfirmDelete: action?.payload};
    case ACTIONS.SET_VIEW_TIME_ENTRY_CREATE_MODAL:
      return {...state, viewTimeEntryCreateModal: action?.payload};
    case ACTIONS.SET_EDIT_TIME_ENTRY_MODEL:
      return {...state, editTimeEntryModel: action?.payload};
    default:
      return { ...state };
  }
};

const TimeEntryProvider = ({ user, tenant, children }) => {
  const { checkPermission } = useHasRole();
  let [state, dispatch] = React.useReducer(reducer, initialState);
  const value = { state, dispatch }
  let isClocker = checkPermission("TIME_ENTRY_CLOCKER");


  React.useEffect(() => {
    if (user) {
      dispatch({type: ACTIONS.SET_USER, payload: user });
    }
  }, [user])

  const checkTimeEntryStatus = async (tenant, u) => {
    if (isClocker) {
      if (tenant?._id && tenant?.features?.timeClock && !u?.features?.timeClock?.disabled) {
        dispatch({type: ACTIONS.SET_USER, payload: u});
        dispatch({type: ACTIONS.SET_TENANT, payload: tenant});
        const response = await getUserTimeEntryForDate(u?._id, moment());
        if (!response?.error) {
          dispatch({type: ACTIONS.SET_CURRENT_TIME_ENTRIES, payload: response});
          if (isClocker && (!response || response?.length === 0)) {
            dispatch({type: ACTIONS.SET_VIEW_CLOCK_IN, payload: true});
          }
        }
      }
    }
  }

  React.useEffect(() => {
    if (tenant && user) {
      checkTimeEntryStatus(tenant, user).then(() => {});
    }
    // eslint-disable-next-line
  }, [tenant, user]);

  return (
    <TimeEntryContext.Provider value={value}>
      {children}
    </TimeEntryContext.Provider>
  )
};

let TimeEntryConsumer = TimeEntryContext.Consumer;

const createTimeEntry = async (dispatch, timeEntry, isClockIn = false) => {
  dispatch({type: ACTIONS.TOGGLE_ERROR, payload: false});
  dispatch({type: ACTIONS.TOGGLE_UPSERTING, payload: true});
  const response = await createTimeClock(timeEntry?.user, timeEntry);
  if (!response?.error) {
    dispatch({type: ACTIONS.SET_VIEW_CLOCK_IN, payload: false});
    const enhanced = enhanceTotalMinutes([response])
    dispatch({type: ACTIONS.ADD_TIME_ENTRY, payload: enhanced[0]});
    if (isClockIn) {
      dispatch({type: ACTIONS.ADD_TO_CURRENT_TIME_ENTRIES, payload: response});
    }


  } else {
    dispatch({type: ACTIONS.TOGGLE_ERROR, payload: true});
  }
  dispatch({type: ACTIONS.TOGGLE_UPSERTING, payload: false});
}

const addTimeEntryEvent = async (dispatch, userId, timeClockId, event) => {
  dispatch({type: ACTIONS.TOGGLE_ERROR, payload: false});
  dispatch({type: ACTIONS.TOGGLE_UPSERTING, payload: true});
  const response = await createTimeClockEvent(userId, timeClockId, event);
  if (!response?.error) {
    dispatch({type: ACTIONS.SET_VIEW_CLOCK_OUT, payload: false});
    dispatch({type: ACTIONS.ADD_EVENT, payload: response});

  } else {
    dispatch({type: ACTIONS.TOGGLE_ERROR, payload: true});
  }

  dispatch({type: ACTIONS.TOGGLE_UPSERTING, payload: false});
}

const toggleTimeout = (dispatch, value) => {
  dispatch({type: ACTIONS.SET_VIEW_CLOCK_OUT, payload: value});
}

const toggleViewTimeClock = (dispatch, value) => {
  dispatch({type: ACTIONS.SET_VIEW_TIMECLOCK_TABLE, payload: value});
}

const addEventToTimeClock = async (dispatch, timeClock, event) => {
  const response = await addEvent(timeClock?._id, event);
  if (!response?.error) {
    timeClock.events = [...timeClock.events, response];
    dispatch({type: ACTIONS.SET_TIMECLOCK, payload: timeClock})
  } else {

  }
}

const clockOutAction = async (dispatch, userId, payload, timeEntries) => {
  let timeOutEntries = timeEntries?.filter(timeEntry => !timeEntry?.end)?.sort((a, b) => a?.start > b?.start ? 1: -1);
  if (timeOutEntries?.length === 1) {
    const response = await clockOut(timeOutEntries[0]._id, payload?.user, payload?.date);
    if (!response?.error) {
      dispatch({type: ACTIONS.CLOCK_OUT, payload: response});
      dispatch({type: ACTIONS.SET_VIEW_CLOCK_OUT, payload: false});
    }
  } else if (timeOutEntries?.length > 1) {
    // What to do about 2? There should not be 2.
    const response = await clockOut(timeOutEntries[0]._id, payload?.user, payload?.date);
    if (!response?.error) {
      dispatch({type: ACTIONS.CLOCK_OUT, payload: response});
      dispatch({type: ACTIONS.SET_VIEW_CLOCK_OUT, payload: false});
    }
  }
}

const updateTimeEntryAction = async (dispatch, timeEntry) => {
  const response = await editTimeEntry(timeEntry?._id, timeEntry);
  if (!response?.error) {
    dispatch({type: ACTIONS.UPDATE_TIME_ENTRY, payload: response});
  }
}

const deleteTimeEntryAction = async (dispatch, timeEntryId) => {
  const response = await deleteTimeEntry(timeEntryId);
  if (!response?.error) {
    dispatch({type: ACTIONS.DELETE_TIME_ENTRY, payload: timeEntryId});

  }
}

const deleteTimeClockEventAction = async (dispatch, timeClock, eventId) => {
  const response = await deleteEvent(timeClock?._id, eventId);
  if (!response?.error) {
    timeClock.events = timeClock?.events?.filter(e => e?._id !== eventId);
    dispatch({type: ACTIONS.SET_TIMECLOCK, payload: timeClock});
  }
}

const addTimeClockDayAction = async (dispatch, timeClockDay) => {
  const response = await createTimeClock(timeClockDay?.user, timeClockDay);
  if (!response?.error) {
    dispatch({type: ACTIONS.ADD_TIME_ENTRY, payload: response});
    dispatch({type: ACTIONS.SET_CURRENT_TIMECLOCK, payload: response});
  } else {
    dispatch({type: ACTIONS.TOGGLE_ERROR, payload: true});
  }
  dispatch({type: ACTIONS.TOGGLE_UPSERTING, payload: false});
}

const updateTimeEntryMobileAction = async (dispatch, timeEntry) => {

}

export {
  TimeEntryProvider,
  TimeEntryConsumer,
  TimeEntryContext,
  toggleTimeout,
  toggleViewTimeClock,
  createTimeEntry,
  addTimeEntryEvent,
  addEventToTimeClock,
  clockOutAction,
  deleteTimeClockEventAction,
  addTimeClockDayAction,
  updateTimeEntryAction,
  deleteTimeEntryAction,
  updateTimeEntryMobileAction
};
