import {Checkbox, Input, ListItemText, MenuItem, Select, TableSortLabel, TextField} from "@material-ui/core";
import React from "react";
import MomentUtils from "@date-io/moment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

export const getStripedStyle = (index) => {
  return { background: index % 2 ? '#fafafa' : '#fff' };
};

export const DateRangeSortFilterLabel = ({ field,
                                           label,
                                           sortOrderBy,
                                           sortDirection,
                                           createSortHandler,
                                           fromDate,
                                           toDate,
                                           onDateRangeChange
                                         }) => {

  const onFromChange = date => {
    onDateRangeChange({ field: field, start: date, end: toDate});
  }

  const onToChange = date => {
    onDateRangeChange({ field: field, start: fromDate, end: date});
  }

  return (
    <>
      <TableSortLabel
        active={sortOrderBy === field}
        direction={sortDirection}
        onClick={() => createSortHandler(field)}
      >
        {label}
      </TableSortLabel>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          size="small"
          fullWidth
          name={field}
          required={true}
          label={'From'}
          format="MM/DD/YYYY"
          allowKeyboardControl="true"
          ampm={false}
          value={fromDate}
          onChange={onFromChange}
        />
        <KeyboardDatePicker
          size="small"
          fullWidth
          name={"field"}
          required={true}
          label={'To'}
          format="MM/DD/YYYY"
          allowKeyboardControl="true"
          ampm={false}
          value={toDate}
          onChange={onToChange}
        />

      </MuiPickersUtilsProvider>
    </>
  )
}


export const SortFilterLabel = ({field, label, sortOrderBy, sortDirection, createSortHandler, filter, filterTextChange }) => {
  return (
    <>
      <TableSortLabel
        active={sortOrderBy === field}
        direction={sortDirection}
        onClick={() => createSortHandler(field)}
      >
        {label}
      </TableSortLabel>
      <TextField
        fullWidth
        name={field}
        margin="dense"
        size="small"
        placeholder="Search"
        variant="outlined"
        value={filter[field]?.value || ''}
        onChange={filterTextChange}
      ></TextField>
    </>
  )
}


export const SortFilterSelectLabel = ({ field,
                                        label,
                                        sortOrderBy,
                                        sortDirection,
                                        createSortHandler,
                                        filter,
                                        onChange,
                                        options,
                                        optionLabel,
                                        optionLabelFunc,
                                        optionValue,
                                        multipleSelect = false
                                      }) => {

  const getStatusName = () => {
    return options?.filter(option => filter[field]?.value?.indexOf(option[optionValue]) > -1)
      .map(option => optionLabel ? option[optionLabel] : optionLabelFunc(option))
      .join(', ');
  }

  return (
    <>
      <TableSortLabel
        active={sortOrderBy === field}
        direction={sortDirection}
        onClick={() => createSortHandler(field)}
      >
        {label}
      </TableSortLabel>
      {multipleSelect &&
        <>
          <Select fullWidth
                  name={field}
                  value={filter[field]?.value}
                  displayEmpty={true}
                  onChange={onChange}
                  input={<Input/>}
                  multiple
                  renderValue={(selected) => getStatusName(selected)}
          >
            <MenuItem value={''}>
              <Checkbox checked={filter[field]?.value?.indexOf("") > -1} />
              <ListItemText primary={'All'} />
            </MenuItem>
            {options?.map(option =>
              <MenuItem value={option[optionValue]}>
                <Checkbox checked={filter[field]?.value?.indexOf(option[optionValue]) > -1} />
                <ListItemText primary={optionLabel ? option[optionLabel] : optionLabelFunc(option)} />
              </MenuItem>)
            }
          </Select>
          </>
      }
      {!multipleSelect &&
        <Select fullWidth
                name={field}
                value={filter[field]?.value}
                displayEmpty={true}
                onChange={onChange}
        >
          <MenuItem value={''}>All</MenuItem>
          {options?.map(option =>
            <MenuItem value={option[optionValue]}>{optionLabel ? option[optionLabel] : optionLabelFunc(option)}
            </MenuItem>)
          }
        </Select>
      }
    </>
  )
}
