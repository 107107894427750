import React from 'react';
import {
  FormControl, InputLabel,
  makeStyles, MenuItem,
  Paper, Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormGroup, Grid, Button, TablePagination, CircularProgress, FormControlLabel, Checkbox
} from "@material-ui/core";
import {PageTitle} from "../utils";
import {
  getTenantLocationNotesPaginiate,
  getTenantLocationNotesPdf
} from "../../services/note.service";
import moment from "moment";
import {UserContext} from "../../context/usercontext";
import {getStripedStyle} from "../utils/table.utils";
import MomentUtils from "@date-io/moment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {getCategories} from "../../services/note.category.service";
import {getTenantUsers} from "../../services/tenant.service";
import TableLoadingIndicator from "../../utils/indicators/table.loading";
import {RetryMessage} from "../notification/retry.message";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import useGlobalNotification from "../../hooks/notification.hook";
import ResidentSearch from "../resident/search/resident.search";
import useGlobalLoadingIndicator from "../../hooks/global.loading.hook";
import {Skeleton} from "@material-ui/lab";
import * as _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '.5rem 0'
  },
  filterContainer: {
    margin: '0 auto'
  },
  btnContainer: {
    padding: '0 1rem'
  },
  paginationContainer: {
    padding: '1rem 0 0 0'
  },
  residentSearchContainer: {
    padding: '0 0 0 1rem'
  }
}));

export const TenantNotes = ({displayBackButton = true}) => {
  const classes = useStyles();
  const { selectedTenant, selectedTenantLocation, tenantLocations } = React.useContext(UserContext);
  const [notes, setNotes] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [category, setCategory] = React.useState(null);
  const [author, setAuthor] = React.useState(null);
  const [authors, setAuthors] = React.useState([]);
  const [resident, setResident] = React.useState();
  const [startDate, setStartDate] = React.useState(moment().startOf("month"));
  const [endDate, setEndDate] = React.useState(moment().add(1, 'day'));
  const [loading, setLoading] = React.useState(false);
  const [loadingPdf, setLoadingPdf] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(25);
  const [totalItems, setTotalItems] = React.useState(0);
  const [clearingFilters, setClearingFilters] = React.useState(false);
  const [includeDx, setIncludeDx] = React.useState(false);
  const { addNotification } = useGlobalNotification();
  const { toggleLoading } = useGlobalLoadingIndicator();

  React.useEffect(() => {
    if (selectedTenant?._id && selectedTenantLocation?._id) {
      getData();
    }
    // eslint-disable-next-line
  }, [selectedTenant?._id, selectedTenantLocation?._id]);

  const getData = async () => {
    const response = await Promise.all([
      getCategories(selectedTenant?._id),
      searchNotes(
        selectedTenant?._id,
        selectedTenantLocation?._id,
        startDate,
        endDate,
        null,
        null,
        null,
        page,
        size,
        '-createdAt'),
      getTenantUsers(selectedTenant?._id)
    ]);
    setCategories(response[0]);
    setAuthors(response[2])
  }

  const clearFilters = async () => {
    setClearingFilters(true);
    const start = moment().startOf('month');
    setPage(1);
    setSize(25);

    setStartDate(start);
    const end = moment().add(1, 'day');
    setEndDate(end);
    setCategory(null);
    setResident(null);
    setAuthor(null);
    await searchNotes(
      selectedTenant?._id,
      selectedTenantLocation?._id,
      start,
      end,
      null,
      null,
      null,
      1,
      25
      );
    setClearingFilters(false);
  }

  const searchNotes = async (tenantId,
                             locationIds,
                             start,
                             end,
                             category,
                             author,
                             residentId,
                             page = 1,
                             size = 1,
                             sort = '-createdAt') => {

    if (moment(start).isValid() && moment(end).isValid()) {
      setLoading(true);
      setIncludeDx(false);
      setError(false);
      setErrorMessage(null);
      if (locationIds === -1) {
        locationIds = tenantLocations?.map(tl => tl?._id)
      }
      const response =
        await getTenantLocationNotesPaginiate(tenantId,
          locationIds,
          category,
          author,
          start,
          end,
          residentId,
          page,
          size,
          sort)
      if (!response?.error) {
        setNotes(response?.content);
        setPage(response?.paginator?.currentPage);
        setSize(response?.paginator?.perPage);
        setTotalItems(response?.paginator?.totalItems);
      } else if (response?.message?.status === 503) {
        setErrorMessage("The report has timed out due to the large quantity of data selected. We recommend selecting a smaller data range to proceed.");
        setError(true);
      } else {
        setErrorMessage("Unable to export notes");
        setError(true);
      }
      setLoading(false);
    }
  }

  const onDateChange = async (date, field) => {
    setPage(1);
    setSize(25);
    if (field === 'start') {
      setStartDate(date);
      await searchNotes(selectedTenant?._id,
        selectedTenantLocation?._id,
        date,
        endDate,
        category,
        author,
        resident?._id,
        1,
        25);
    } else {
      setEndDate(date);
      await searchNotes(selectedTenant?._id,
        selectedTenantLocation?._id,
        startDate,
        date,
        category,
        author,
        resident?._id,
        1,
        25);
    }
  }

  const onCategoryChange = async e => {
    setCategory(e.target.value);
    setPage(1);
    setSize(25);
    await searchNotes(selectedTenant?._id, selectedTenantLocation?._id, startDate, endDate, e.target.value, author, resident?._id, 1, 25);
  }

  const onAuthorChange = async e => {
    setPage(1);
    setSize(25);
    setAuthor(e.target.value);
    await searchNotes(selectedTenant?._id, selectedTenantLocation?._id, startDate, endDate, category, e.target.value, resident?._id, 1, 25);
  }

  const retry = async () => {
    await searchNotes(selectedTenant?._id, selectedTenantLocation?._id, startDate, endDate, category, author, resident?._id, page, size);
  }

  const printNotes = async () => {
    setLoadingPdf(true);
    toggleLoading(true);
    const response = await getTenantLocationNotesPdf(
      selectedTenant?._id,
      selectedTenantLocation?._id === -1 ? tenantLocations?.map(t => t._id) : selectedTenantLocation?._id,
      category,
      author,
      startDate,
      endDate,
      resident?._id,
      includeDx
    );
    if (!response?.error) {
      const link = document.createElement('a');
      const pdf = URL.createObjectURL(response);
      link.href = pdf;
      link.setAttribute(
        'download',
        `${selectedTenant?.name}--notes.pdf`,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

    } else {
      addNotification("Unble to print notes report", "error");
    }
    toggleLoading(false);
    setLoadingPdf(false);
  }

  const onClearedResident = async cleared => {
    setResident(null);
    setPage(1);
    setSize(25);
    await searchNotes(selectedTenant?._id, selectedTenantLocation?._id, startDate, endDate, category, author, null, 1, 25);
  }

  const onResidentSelected = async res => {
    setResident(res);
    setPage(1);
    setSize(25);
    await searchNotes(selectedTenant?._id, selectedTenantLocation?._id, startDate, endDate, category, author, res?._id, 1, 25);
  }

  const onChangeRowsPerPage = async (e) => {
    setSize(e.target.value);
    setPage(1);
    await searchNotes(selectedTenant?._id,
      selectedTenantLocation?._id,
      startDate,
      endDate,
      category,
      author,
      resident?._id,
      1,
      e.target.value,
      null);
  }
  const handleChangePage = async (e, newPage) => {
    setPage(newPage + 1);
    await searchNotes(selectedTenant?._id,
      selectedTenantLocation?._id,
      startDate,
      endDate,
      category,
      author,
      resident?._id,
      newPage + 1,
      size,
      null);
  }

  const handleIncludeDxChange = (event) => {
    setIncludeDx(event.target.checked);
  }

  const enableIncludeDiagnosis = () => {
    // _.uniq(notes?.map())
    let uniqueResidents = _.uniqBy(notes, 'resident._id');
    return uniqueResidents?.length === 1;
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Paper elevation={24}
             className={classes.root}
      >
        <PageTitle title={"Notes"}
                   displayBackButton={displayBackButton}
        >
          <Grid container justify={"flex-end"} className={classes.btnContainer}>
            <Button color={"primary"} onClick={clearFilters}>Clear Filters</Button>
            <Button startIcon={loadingPdf ? <CircularProgress color={"#fff"} size={20} />: <PictureAsPdfIcon />}
                    size={"snall"}
                    variant={"contained"}
                    color={"primary"}
                    disabled={loadingPdf}
                    onClick={printNotes}
            >
              Export
            </Button>
          </Grid>
        </PageTitle>
        <div className={classes.filterContainer}>
          <FormGroup>
            <Grid container>
              <Grid item>
              </Grid>
              <Grid item>
              </Grid>
            </Grid>
          </FormGroup>
        </div>
        <Grid container
              className={classes.paginationContainer}
              justifyContent={"space-between"}
        >
          <Grid item
                className={classes.residentSearchContainer}
                xs={12}
                sm={4}>
            {clearingFilters && <Skeleton width={"100%"} height={60} />}
            {!clearingFilters &&
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!enableIncludeDiagnosis()}
                      onChange={handleIncludeDxChange}
                      checked={includeDx || ''}
                      name="includeDx"
                      color="primary"
                    />
                  }
                  label="Include Diagnosis"
                />
                <ResidentSearch onSelect={onResidentSelected}
                                onClear={onClearedResident}
                                includeDischarged={true}
                />
              </>
            }
          </Grid>
          <Grid item
                xs={12}
                sm={8}>
            <TablePagination component={'div'}
                             rowsPerPageOptions={[1, 5, 10, 25, 50]}
                             rowsPerPage={size}
                             page={page - 1}
                             count={totalItems}
                             onRowsPerPageChange={onChangeRowsPerPage}
                             onPageChange={handleChangePage}
            />
          </Grid>
        </Grid>
        <TableContainer>
          <Table size={"small"} >
            <TableHead>
              <TableRow>
                <TableCell>
                  Resident
                </TableCell>

                <TableCell width={250}>
                    <KeyboardDatePicker
                      fullWidth
                      size="small"
                      name={"start"}
                      required={true}
                      value={startDate}
                      label={"Start"}
                      format="MM/DD/YYYY"
                      onChange={(date) => onDateChange(date, "start")}
                      allowKeyboardControl="true"
                    />
                    <KeyboardDatePicker
                      fullWidth
                      size="small"
                      name={"end"}
                      required={true}
                      value={endDate}
                      label={"End"}
                      format="MM/DD/YYYY"
                      onChange={(date) => onDateChange(date, "end")}
                      allowKeyboardControl="true"
                    />
                </TableCell>
                <TableCell>Subject</TableCell>
                <TableCell>
                  <FormControl fullWidth>
                    <InputLabel shrink>Category</InputLabel>
                    <Select value={category}
                            displayEmpty={true}
                            onChange={onCategoryChange}
                    >
                      <MenuItem value={null}>All</MenuItem>
                      {categories?.map(category => <MenuItem value={category?.name}>{category.name}</MenuItem>)}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell width={300}>Note</TableCell>
                <TableCell>
                  <FormControl fullWidth>
                    <InputLabel shrink>Author</InputLabel>
                    <Select value={author}
                            displayEmpty={true}
                            onChange={onAuthorChange}
                    >
                      <MenuItem value={null}>All</MenuItem>
                      {authors?.map(author => <MenuItem value={author?._id}>{author.firstName} {author?.lastName}</MenuItem>)}
                    </Select>
                  </FormControl>
                </TableCell>
              </TableRow>
            </TableHead>
            {!loading && error &&
              <TableRow>
                <TableCell colSpan={6}>
                  <RetryMessage message={errorMessage} onRetry={retry} severity={"error"} />
                </TableCell>
              </TableRow>
            }
            {loading && !error && <TableLoadingIndicator bodyOnly={true} cols={6} />}
            <TableBody>
              {!loading && !error && notes?.map((note, index) =>
                <TableRow style={getStripedStyle(index)}>
                  <TableCell>
                    {note?.resident?.firstName} {note?.resident?.lastName}
                  </TableCell>
                  <TableCell>
                    {note?.noteDate ?
                      <span>
                        {moment(note?.noteDate).format('MM/DD/YYYY h:mm a')}
                      </span>:
                      <span>
                        {moment(note?.createdAt).format('MM/DD/YYYY h:mm a')}
                      </span>
                    }
                  </TableCell>
                  <TableCell>
                    {note?.subject}
                  </TableCell>
                  <TableCell>
                    {note?.category}
                  </TableCell>
                  <TableCell>
                    <div dangerouslySetInnerHTML={{__html: note?.html}} />
                  </TableCell>
                  <TableCell>
                    {note?.user?.firstName} {note?.user?.lastName}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </MuiPickersUtilsProvider>
  )
}
