import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, IconButton, Typography, withStyles} from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import {FormFieldsGenerator, isModelValid} from "../../../../utils/forms";
import {HoldOrderTemplate} from "./holdModel";
import moment from "moment";
import {UserContext} from "../../../../context/usercontext";

const useStyles = makeStyles((theme) => ({
  content: {
    height: '35vh'
  },
  formContainer: {
    marginTop: '2vh'
  }
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const ConfirmHoldDates = ({open, onClose, onSave, users}) => {
  const classes = useStyles();
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(HoldOrderTemplate)));
  const { user } = React.useContext(UserContext);

  React.useEffect(() => {
    if (open && users) {
      const obj = JSON.parse(JSON.stringify(HoldOrderTemplate));
      obj.startDate.value = moment();
      obj.endDate.value = moment().add(7, 'days');
      obj.createdBy.dropDownItems = users;
      obj.createdBy.value = user?._id;
      setModel(obj);
    }
    // eslint-disable-next-line
  },[open, users]);

  const onFormChange = e => {
    setModel({...model, ...e});
  }

  const save = () => {
    onSave({
      startDate: model?.startDate?.value,
      endDate: model?.endDate?.value,
      comment: model?.comment.value
    });
  }

  return (
    <Dialog open={open}
            onClose={onClose}
            maxWidth={"md"}
            fullWidth
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        {"Order Hold Dates"}
      </DialogTitle>
      <DialogContent className={classes.content} dividers>
        <Typography gutterBottom>
          In order to put a medication order on Hold, you must provide a hold start and end date.
        </Typography>
        <div className={classes.formContainer}>
          <FormFieldsGenerator model={model}
                               onChange={onFormChange} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          Cancel
        </Button>
        <Button variant={"contained"}
                disabled={!isModelValid(model)}
                onClick={save}
                color={"primary"}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
